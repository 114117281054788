/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../Global/Localize'
import { batchStatus, expensePayableStatus } from '../../../Global/enumeration'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import DatePicker from '../../Date/DatePicker'
import Loader from '../../Loaders/Loader'
import Pager from '../../Tables/Pager'
import SimpleTable from '../../Tables/SimpleTable'
import RecordsPerPage from '../../RecordsPerPage/RecordsPerPage'

function ListBatchAuthorization({
  handleBatchSelect,
  showContextMenu,
  canClickOnce,
  onSelect,
  refresh,
  setRefresh,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    status: Object.keys(expensePayableStatus)[3],
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('batchNumber'),
      template: r => <>{r?.paddedBatchNumber}</>,
    },
    {
      label: localize('terminationCategory'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r?.priorityDescription}</>,
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r?.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => <>{r?.authorizedBy}</>,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => <>{formatDate(r?.authorizedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <> {r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <> {formatDate(r?.createdDate)}</>,
    },
  ]

  const loadData = async () => {
    setIsBusy(true)
    const url =
      '/WithdrawalSettlementBatch/find-withdrawal-settlement-batches-by-status-and-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
      if (refresh) setRefresh(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  useEffect(() => {
    if (refresh) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return (
    <>
      <div className="card bg-white rounded relative">
        <div className="card-body">
          <div className="row mb-2 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center col-3">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>
            <div className="col-md-3  d-flex gap-2 align-items-center">
              <label className="text-capitalize">{localize('status')}</label>
              <EnumsServerCombo
                customContainerStyle={{
                  maxWidth: 180,
                  minWidth: 180,
                }}
                enumsKey="ExpensePayableStatus"
                value={reqParams.status}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }
              />
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label className=" text-capitalize" htmlFor="search">
                  {localize('dateRange')}
                </label>
                <DatePicker
                  value={{
                    startDate: reqParams.startDate,
                    endDate: reqParams.endDate,
                  }}
                  onSelect={v => {
                    if (v.custom) {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    } else {
                      setReqParams({
                        ...reqParams,
                        customDate: false,
                        dateRequestsFilter: v.value,
                      })
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center gap-2">
                <label className="text-capitalize" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  defaultValue={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      loadData()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    loadData()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <SimpleTable
            columns={columns}
            data={data?.pageCollection}
            canClickOnce={canClickOnce}
            onClickOnce={r => {
              onSelect(r)
            }}
            contextMenu={r =>
              showContextMenu && (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleBatchSelect(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      if (r?.status !== Object.keys(batchStatus)[3]) {
                        Swal.fire(
                          'Error',
                          `Sorry, but you must first select a batch whose status is audited`,
                          'error'
                        )
                      } else {
                        handleBatchSelect(r, 'authorize')
                      }
                    }}
                  >
                    <i className="uil uil-edit"></i>
                    <span>{localize('authorize')}</span>
                  </div>
                </>
              )
            }
          />
          <div className="mt-2">
            <Pager
              itemsCount={data.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ListBatchAuthorization
