/** @format */
import React, { useState } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'
import InHouseChequeLookup from '../../../../../../Components/Lookup/InHouseChequeLookup'
import { printMedium } from '../../../../../../Global/enumeration'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { downloadReceipt } from '../../../../../../Helpers/blobs'

const InHouseChequePrinting = () => {
  const [chequePrinting, setChequePrinting] = useState(null)

  const [loading, setLoading] = useState(false)
  const [selectedPrintMedium, setSelectedPrintMedium] = useState(
    Object.keys(printMedium)[1]
  )
  const [bankLinkageData, setBankLinkageData] = useState(null)

  const getBankLinkage = async r => {
    const { success, data } = await getData(
      `/BankLinkage/find-by-id?bankLinkageId=${r.bankersChequeLeafBankersChequeBookBankLinkageId}`,
      {},
      false
    )
    if (success) {
      console.log('bankLinkageDetails', data)
      setBankLinkageData({
        bank: data.bankName,
        bankBranch: data.bankBranchName,
        bankAccountNumber: data.bankAccountNumber,
        printMedium: selectedPrintMedium,
      })
    }
  }

  const handleChequePrinting = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/InHouseCheque/print-inhouse-cheque',
      {
        inHouseCheque: { ...chequePrinting, ...bankLinkageData },
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      showNotification(
        'Inhouse Cheque Printing',
        'success',
        'Operation completed successfully!'
      )
      setLoading(false)
      setChequePrinting(null)
      setBankLinkageData(null)

      const url = 'http://localhost:8888'
      const printData = {
        TransactionType: 1,
        PrintPayload: {
          DocumentName: 'Test',
          PrintingMode: 0,
          UseDefaultPrinter: true,
          Data: data.message, // Assuming 'data' is a variable available in the scope
        },
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any additional headers if needed
          },
          body: JSON.stringify(printData),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const result = await response.json()

        // Assuming the response has a 'success' property
        const { success } = result

        // Further processing based on the 'success' value
      } catch (error) {
        // Handle errors during the fetch operation
        console.error('Fetch error:', error.message)
      }
      //downloadReceipt(data.message, 'inhouseChequeReceipt.txt')
    }
    setLoading(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('cheques'),
          localize('chequePrinting'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            <>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center mb-2  ">
                      <label className="col-md-2 text-capitalize">
                        {localize('payee')}
                      </label>
                      <InHouseChequeLookup
                        displayValue={chequePrinting?.payee}
                        onSelect={async r => {
                          setChequePrinting({
                            ...chequePrinting,
                            id: r.id,
                            bankersChequeLeafId: r.bankersChequeLeafId,
                            payee: r.payee,
                            reference: r.reference,
                            amount: r.amount,
                            wordifiedAmount: r.wordifiedAmount,
                            paddedAmount: r.paddedAmount,
                            bankersChequeLeafLeafNumber:
                              r.paddedBankersChequeLeafLeafNumber,
                            debitChartOfAccountName: r.debitChartOfAccountName,
                            bankersChequeLeafBankersChequeBookBankLinkageId:
                              r.bankersChequeLeafBankersChequeBookBankLinkageId,
                          })
                          getBankLinkage(r)
                        }}
                      />
                    </div>
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-3 text-capitalize">
                        {localize('reference')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={chequePrinting?.reference}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-2 text-capitalize">
                        {localize('amount')}
                      </label>
                      <MaskNumber
                        className="form-control me-1"
                        defaultMaskValue={chequePrinting?.amount || 0.0}
                        disabled
                      />
                    </div>
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-3 text-capitalize">
                        {localize('amountInWords')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={chequePrinting?.wordifiedAmount}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-2 text-capitalize">
                        {localize('paddedAmount')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={chequePrinting?.paddedAmount}
                      />
                    </div>
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-3 text-capitalize">
                        {localize('chequeLeafNumber')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={chequePrinting?.bankersChequeLeafLeafNumber}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-2 text-capitalize">
                        {localize('bank')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={bankLinkageData?.bank}
                      />
                    </div>
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-3 text-capitalize">
                        {localize('bankBranch')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={bankLinkageData?.bankBranch}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-2 text-capitalize">
                        {localize('bankAccount#')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={bankLinkageData?.bankAccountNumber}
                      />
                    </div>
                    <div className="col d-flex align-items-center mb-2 ">
                      <label className="col-md-3 text-capitalize">
                        {localize('chartOfAccountName')}
                      </label>
                      <input
                        type="text"
                        className="form-control me-2"
                        value={chequePrinting?.debitChartOfAccountName}
                      />
                    </div>
                  </div>
                  <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
                    <div className="col-md-2 mt-2">
                      <div className="d-flex align-items-center mb-2 me-1">
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="PrintMedium"
                          value={selectedPrintMedium}
                          onChange={e => setSelectedPrintMedium(e.value)}
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        if (!chequePrinting) {
                          showNotification(
                            'Inhouse Cheque Printing',
                            'info',
                            'sorry, but you must first select payee!'
                          )
                        } else {
                          handleChequePrinting()
                        }
                      }}
                      className="btn btn-success px-5"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  )
}
export default InHouseChequePrinting
