/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import Checkbox from '../Checkbox/Checkbox'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import EnumsCombo from '../ComboBox/EnumsCombo'
import { loanCasePropertyGroup } from '../../Global/enumeration'
import DatePicker from '../Date/DatePicker'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
  parseDate,
} from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import GroupedTable from '../Tables/GroupedTable'
import Pager from '../Tables/Pager'

function LoanApplicationLookup({ disabled, displayValue, onLookup }) {
  let today = new Date()
  const initialParams = {
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
    loanCaseFilter: 0,
    startDate: parseDate(today.setDate(today.getDate() - 30)),
    endDate: parseDate(today),
    loanCaseStatus: 48826, // Registered
  }

  const [modalActive, setModalActive] = useState(false)
  const [useDate, setUseDate] = useState(true)
  const [reqParams, setReqParams] = useState(initialParams)
  const [loanApplications, setLoanApplications] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'category',
    column: 'loanRegistrationLoanProductCategoryDescription',
  })

  const [statusLabel, setStatusLabel] = useState('Registered')

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.documentReferenceNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.formattedCustomerAccountNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('business/Group/Employer'),
      template: r => <>{r?.customerEmployerDescription}</>,
    },
    {
      label: localize('amountApplied'),
      template: r => <>{formatCurrency(r?.amountApplied)}</>,
    },
    {
      label: localize('appraisedAmount'),
      template: r => <>{formatCurrency(r?.appraisedAmount)}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('productCategory'),
      template: r => <>{r?.loanRegistrationLoanProductCategoryDescription}</>,
    },
    {
      label: localize('receivedDate'),
      template: r => <>{formatDate(r?.receivedDate, true)}</>,
    },
    {
      label: localize('isBatched'),
      template: r => <>{r?.isBatchedDescription}</>,
    },
    {
      label: localize('systemAppraisalRemarks'),
      template: r => <>{r?.systemAppraisalRemarks}</>,
    },
    {
      label: localize('systemAppraisedAmount'),
      template: r => <>{formatCurrency(r?.systemAppraisedAmount)}</>,
    },
    {
      label: localize('appraisedNetIncome'),
      template: r => <>{formatCurrency(r?.appraisedNetIncome)}</>,
    },
    {
      label: localize('appraisedAmountRemarks'),
      template: r => <>{r?.appraisedAmountRemarks}</>,
    },
    {
      label: localize('monthlyPaybackAmount'),
      template: r => <>{formatCurrency(r?.monthlyPaybackAmount)}</>,
    },
    {
      label: localize('totalPaybackAmount'),
      template: r => <>{formatCurrency(r?.totalPaybackAmount)}</>,
    },
    {
      label: localize('appraisedBy'),
      template: r => <>{r?.appraisedBy}</>,
    },
    {
      label: localize('appraisedDate'),
      template: r => <>{formatDate(r?.appraisedDate)}</>,
    },
    {
      label: localize('approvedAmount'),
      template: r => <>{formatCurrency(r?.approvedAmount)}</>,
    },
    {
      label: localize('approvedAmountRemarks'),
      template: r => <>{r?.approvedAmountRemarks}</>,
    },
    {
      label: localize('approvedPrincipalPayment'),
      template: r => <>{formatCurrency(r?.approvedPrincipalPayment)}</>,
    },
    {
      label: localize('approvedInterestPayment'),
      template: r => <>{formatCurrency(r?.approvedInterestPayment)}</>,
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r?.approvalRemarks}</>,
    },
    {
      label: localize('approvedBy'),
      template: r => <>{r?.approvedBy}</>,
    },
    {
      label: localize('approvedDate'),
      template: r => <>{formatDate(r?.approvedDate)}</>,
    },
    {
      label: localize('verificationRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('topUpAmount'),
      template: r => <>{formatCurrency(r?.auditTopUpAmount)}</>,
    },
    {
      label: localize('verifiedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('verifiedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('cancelledBy'),
      template: r => <>{r?.cancelledBy}</>,
    },
    {
      label: localize('cancellationDate'),
      template: r => <>{formatDate(r?.cancelledDatae)}</>,
    },
    {
      label: localize('cancellationRemarks'),
      template: r => <>{r?.cancellationRemarks}</>,
    },
    {
      label: localize('deferredBy'),
      template: r => <>{r?.deferredBy}</>,
    },
    {
      label: localize('deferredDate'),
      template: r => <>{formatDate(r?.deferredDate)}</>,
    },
    {
      label: localize('deferralRemarks'),
      template: r => <>{r?.deferralRemarks}</>,
    },
    {
      label: localize('batchedBy'),
      template: r => <>{r?.batchedBy}</>,
    },
    {
      label: localize('disbursedAmount'),
      template: r => <>{formatCurrency(r?.disbursedAmount)}</>,
    },
    {
      label: localize('reversalRemarks'),
      template: r => <>{r?.reversalRemarsk}</>,
    },
    {
      label: localize('reversedBy'),
      template: r => <>{r?.reversedBy}</>,
    },
    {
      label: localize('reversedDate'),
      template: r => <>{formatDate(r?.reversedDate)}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerPersonalFileNumber}</>,
    },
    {
      label: localize('customerAccountNumber'),
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: localize('customerMembershipNumber'),
      template: r => <>{formatDate(r?.customerReference2)}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanPurposeDescription}</>,
    },
    {
      label: localize('disbursementMode'),
      template: r => <>{r?.loanDisbursementModeDescription}</>,
    },
    {
      label: localize('savingsProduct'),
      template: r => <>{r?.savingsProductDescription}</>,
    },
    {
      label: localize('annualPercentageRate'),
      template: r => (
        <>{formatAsPercent(r?.loanInterestAnnualPercentageRate)}</>
      ),
    },
    {
      label: localize('interestChargeMode'),
      template: r => <>{r?.loanInterestChargeModeDescription}</>,
    },
    {
      label: localize('interestRecoveryMode'),
      template: r => <>{r?.loanInterestRecoveryModeDescription}</>,
    },
    {
      label: localize('interestCalculationMode'),
      template: r => <>{r?.loanInterestCalculationModeDescription}</>,
    },
    {
      label: `${localize('term')} (${localize('months')})`,
      template: r => <>{r?.loanRegistrationTermInMonths}</>,
    },
    {
      label: localize('gracePeriod'),
      template: r => <>{r?.loanRegistrationGracePeriod}</>,
    },
    {
      label: localize('minimumMembershipPeriod'),
      template: r => <>{r?.loanRegistrationMinimumMembershipPeriod}</>,
    },
    {
      label: localize('paymentFrequencyPerYear'),
      template: r => (
        <>{r?.loanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('standingOrderTrigger'),
      template: r => <>{r?.loanRegistrationStandingOrderTriggerDescription}</>,
    },
    {
      label: localize('guarantorSecurityMode'),
      template: r => <>{r?.loanRegistrationGuarantorSecurityModeDescription}</>,
    },
    {
      label: localize('incomeAssesmentMode'),
      template: r => <>{r?.loanRegistrationIncomeAssessmentModeDescription}</>,
    },
    {
      label: localize('bypassVerification'),
      template: r => (
        <span className="text-capitalize">
          {r?.loanRegistrationBypassAudit?.toString()}
        </span>
      ),
    },
    {
      label: localize('takeHomeType'),
      template: r => <>{r?.takeHomeTypeDescription}</>,
    },
    {
      label: localize('takeHomePercentage'),
      template: r => <>{formatAsPercent(r?.takeHomePercentage)}</>,
    },
    {
      label: localize('takeHomeFixedAmount'),
      template: r => <>{formatCurrency(r?.takeHomeFixedAmount)}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('loanBalance'),
      template: r => <>{formatCurrency(r?.loanProductLoanBalance)}</>,
    },
    {
      label: localize('attachedLoansBalance'),
      template: r => <>{formatCurrency(r?.totalAttachedLoansBalance)}</>,
      label: localize('attachedLoansBalance'),
      template: r => <>{formatCurrency(r?.totalAttachedLoansBalance)}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  useEffect(() => {
    getLoanApplicaitons()
    // eslint-disable-next-line
  }, [
    refresh,
    reqParams?.pageSize,
    reqParams?.pageIndex,
    reqParams.loanCaseStatus,
  ])

  async function getLoanApplicaitons() {
    setIsBusy(true)
    const { success, data } = await getData(
      useDate
        ? '/lookup/LoanApplication/find-loan-case-by-status-and-date-range-filter-in-page'
        : '/lookup/LoanApplication/find-loan-case-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setLoanApplications(data)
    } else {
      setIsBusy(false)
      setLoanApplications(undefined)
    }
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal className="modal-xl" isOpen>
          <ModalHeader className="bg-white text-capitalize">
            {localize(statusLabel || 'all')} {localize('loanApplications')} (
            {loanApplications?.itemsCount || 0} {localize('items')})
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white">
            {isBusy ? (
              <div className="d-flex align-items-center justify-content-center p-5">
                <div className="d-flex align-items-center">
                  <i className="m-3 spinner-border spinner-sm text-black"></i>
                  <span className="text-capitalize fs-4">
                    {localize('pleaseWait')}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor=""
                      style={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      className="text-capitalize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      name=""
                      id=""
                      className="form-select ms-2"
                      style={{ maxWidth: 'fit-content' }}
                      value={reqParams.pageSize}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center">
                    <label htmlFor="" className="text-capitalize me-2">
                      {localize('disregardDateRange')}
                    </label>
                    <Checkbox
                      onCheck={v => setUseDate(!v)}
                      checked={!useDate}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      htmlFor=""
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      enumsKey="loanCaseStatus"
                      value={reqParams?.loanCaseStatus}
                      onChange={({ label, value }) => {
                        setReqParams({ ...reqParams, loanCaseStatus: value })
                        setStatusLabel(label)
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <label htmlFor="" className="text-capitalize me-2">
                      {localize('groupBy')}
                    </label>
                    <EnumsCombo
                      enums={loanCasePropertyGroup}
                      value={groupBy?.column}
                      onChange={({ label, value }) =>
                        setGroupBy({ label: label, column: value })
                      }
                    />
                  </div>
                  {useDate && (
                    <div className="d-flex align-items-center">
                      <label
                        style={{ minWidth: 'fit-content' }}
                        htmlFor=""
                        className="text-capitalize me-2"
                      >
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        value={{
                          startDate: reqParams?.startDate,
                          endDate: reqParams?.endDate,
                        }}
                        onSelect={v => {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        }}
                      />
                    </div>
                  )}
                  <div
                    className="col d-flex align-items-center gap-2"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      htmlFor=""
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{
                        maxWidth: 150,
                        minWidth: 150,
                      }}
                      value={reqParams.loanCaseFilter}
                      enumsKey="loanCaseFilter"
                      onChange={({ value }) =>
                        setReqParams({ ...reqParams, loanCaseFilter: value })
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.filterText}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setRefresh(!refresh)
                        setReqParams({ ...reqParams, pageIndex: 0 })
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>

                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={loanApplications?.pageCollection || []}
                  canSelect
                  onSelect={l => {
                    setModalActive(false)
                    onLookup(l)
                  }}
                />
                <div className="mt-2">
                  <Pager
                    itemsCount={loanApplications?.itemsCount}
                    pageSize={reqParams?.pageSize}
                    pageIndex={reqParams?.pageIndex}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                    pageBatchSize={5}
                  />
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default LoanApplicationLookup
