/** @format */

import React from 'react'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import ChartOfAccountsLookup from '../../../../../../Components/Lookup/ChartOfAccountsLookup'
import TransactionTypeLookup from '../../../../../../Components/Lookup/TransactionTypeLookup'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { chartOfAccountCategory } from '../../../../../../Helpers/constants'

const CashPayment = ({
  transaction,
  setTransaction,
  glAccount,
  setGlAccount,
  setTransactionType,
  transactionType,
}) => {
  const handleSelectGLAccount = account => {
    if (!transactionType?.chartOfAccountCostDistributionRuleId)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select a gl account first.'
      )

    if (account?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected account is locked.'
      )

    if (account?.isControlAccount)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected account is control account.'
      )

    if (account?.isReconciliationAccount)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected account is reconciliation account.'
      )

    if (account?.postAutomaticallyOnly)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected account is post automatically only.'
      )

    if (account?.category !== chartOfAccountCategory.DetailACCOUNT)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected account is not detail account.'
      )

    if (
      account?.costDistributionRuleId !==
      transactionType?.chartOfAccountCostDistributionRuleId
    )
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected account  cost distribution rule is not equal to transaction cost distribution rule'
      )

    setGlAccount(account)
  }

  const handleSelectTransaction = t => {
    if (t?.isLocked)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Selected morgue patient is locked.'
      )

    setTransactionType(t)
  }

  return (
    <div className="row g-3">
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">
          {localize('transactionType')}
        </label>

        <TransactionTypeLookup
          displayValue={transactionType?.description}
          onSelect={handleSelectTransaction}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">{localize('glAccount')}</label>

        <ChartOfAccountsLookup
          displayValue={glAccount?.description}
          onSelect={handleSelectGLAccount}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">{localize('amount')}</label>

        <MaskNumber
          onMaskNumber={e => {
            setTransaction({
              ...transaction,
              amount: e.target.value,
            })
          }}
          defaultMaskValue={transaction?.amount || 0}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <label className="text-capitalize col-3">{localize('reference')}</label>

        <input
          className="form-control"
          type="text"
          onChange={e => {
            setTransaction({
              ...transaction,
              reference: e.target.value,
            })
          }}
          value={transaction?.reference}
        />
      </div>
    </div>
  )
}

export default CashPayment
