/** @format */

/* eslint-disable */
import React from 'react'

export const NotificationCard = ({ notifications }) => {
  return (
    <>
      {(notifications || []).map(item => (
        <div
          key={item?.notificationId}
          className="text-reset notification-item"
        >
          <div className="d-flex align-items-start">
            <div className="avatar-xs me-3">
              <span className="avatar-title bg-primary rounded-circle font-size-16">
                <i className=" uil-chat-bubble-user "></i>
              </span>
            </div>
            <div className="flex-1">
              <h6 className="mt-0 mb-1">{item?.title}</h6>
              <div className="font-size-12 text-muted">
                <p className="mb-1">{item?.description}</p>
                <p className="mb-0">
                  <i className="mdi mdi-clock-outline"></i>
                  {item?.humanizedReceiveTime}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
