/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import Swal from 'sweetalert2'
import localize from '../../Global/Localize'
import {
  SystemTransactionCode,
  journalEntryFilter,
  journalLookupMode,
} from '../../Global/enumeration'
import { showNotification } from '../../Helpers/Notification/ShowNotification'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import { getData, putData } from '../../Helpers/webApi'
import Checkbox from '../Checkbox/Checkbox'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import DatePicker from '../Date/DatePicker'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

function JournalLookup({ handleRefresh, reversal }) {
  const [isBusy, setIsBusy] = useState(false)
  const [lookupMode, setLookupMode] = useState({
    journalLookupMode: Object.keys(journalLookupMode)[1],
  })
  const [reqParams, setReqParams] = useState({
    systemTransactionCode: Object.keys(SystemTransactionCode)[1],
    text: null,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    primaryDescription: null,
    secondaryDescription: null,
    reference: null,
    journalEntryFilter: Object.keys(journalEntryFilter)[2],
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectAllRecords, setSelectAllRecords] = useState(false)
  const [disregardTransactionCode, setDisregardTransactionCode] =
    useState(false)

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <>
          <Checkbox
            checked={selectedData?.find(x => x.id === r?.id)}
            onCheck={e => {
              if (e) {
                setSelectedData([...selectedData, r])
              } else {
                setSelectedData(selectedData.filter(x => x !== r?.id))
              }
            }}
          />
        </>
      ),
    },
    {
      label: localize('transactionDate'),
      template: r => formatDate(r?.journalCreatedDate),
    },
    {
      label: localize('branch'),
      template: r => r?.journalBranchDescription,
    },
    {
      label: localize('primaryDescription'),
      template: r => r?.journalPrimaryDescription,
    },
    {
      label: localize('secondaryDescription'),
      template: r => r?.journalSecondaryDescription,
    },
    {
      label: localize('reference'),
      template: r => r?.journalReference,
    },
    {
      label: localize('amount'),
      template: r => formatCurrency(r?.amount),
      headerTextAlign: 'right',
    },
    {
      label: localize('gLAccountName'),
      template: r => r?.chartOfAccountName,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r?.customerAccountFullAccountNumber,
    },
    {
      label: localize('customerFullName'),
      template: r => r?.customerAccountCustomerFullName,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => r?.customerAccountCustomerReference1,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => r?.customerAccountCustomerReference2,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => r?.customerAccountCustomerReference3,
    },
    {
      label: localize('transactionCode'),
      template: r => r?.journalTransactionCodeDescription,
    },
    {
      label: localize('valueDate'),
      template: r => formatDate(r?.journalValueDate),
    },
    {
      label: localize('applicationUserName'),
      template: r => r?.journalApplicationUserName,
    },
    {
      label: localize('environmentUserName'),
      template: r => r?.journalEnvironmentUserName,
    },
    {
      label: localize('environmentMachineName'),
      template: r => r?.journalEnvironmentMachineName,
    },
    {
      label: localize('environmentDomainName'),
      template: r => r?.journalEnvironmentDomainName,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => r?.journalEnvironmentOSVersion,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => r?.journalEnvironmentMACAddress,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => r?.journalEnvironmentMotherboardSerialNumber,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => r?.journalEnvironmentProcessorId,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => r?.journalEnvironmentIPAddress,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const url =
      lookupMode.journalLookupMode === Object.keys(journalLookupMode)[0]
        ? '/lookup/JournalEntry/find-reversible-journal-entries-by-filter-in-page'
        : '/lookup/JournalEntry/find-reversible-journal-entries-by-date-range-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        disregardTransactionCode: disregardTransactionCode,
        secondaryDescription: reqParams?.secondaryDescription,
        primaryDescription: reqParams?.primaryDescription,
        reference: reqParams?.reference,
      },
      false
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  const handleSelectAll = (entries, value) => {
    if (value) {
      setSelectedData(entries)
    } else {
      setSelectedData([])
    }
  }

  async function handleUpdate() {
    if (selectedData.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one record',
      })

    const url = `/JournalReversalBatch/update-journal-reversal-batch-entries?journalReversalBatchId=${reversal.id}`

    setIsBusy(true)
    const { success } = await putData(
      url,
      {
        systemTransactionCode: reqParams.systemTransactionCode,
        primaryDescription: reqParams.primaryDescription,
        secondaryDescription: reqParams.secondaryDescription,
        reference: reqParams.reference,
        selectedItems: selectedData,
        selectAllJournalEntries: selectAllRecords,
      },
      false
    )

    if (success) {
      handleRefresh(true)
      setSelectAll(false)
      setSelectAllRecords(false)
      setDisregardTransactionCode(false)
      setSelectedData([])
      setModalActive(false)
      showNotification('Update Journal Reversal Batch Entries', 'success')
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.pageIndex,
    reqParams.pageSize,
    reqParams.systemTransactionCode,
    modalActive,
    disregardTransactionCode,
  ])

  return (
    <>
      <div
        className="d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <EnumsServerCombo
          customContainerStyle={{ width: '100%' }}
          enumsKey="SystemTransactionCode"
          value={reqParams?.systemTransactionCode}
          onChange={e => {
            setReqParams({
              ...reqParams,
              systemTransactionCode: e.value,
            })
          }}
        />

        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('journalLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between row mb-3">
                  <div className="d-flex align-items-center col-md-3">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div className="d-flex align-items-center col-md-3 gap-2">
                    <label className="text-capitalize">
                      {localize('transactionCode')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ maxWidth: 250, minWidth: 250 }}
                      enumsKey="SystemTransactionCode"
                      value={reqParams.systemTransactionCode}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          systemTransactionCode: e.value,
                        })
                      }}
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <Checkbox
                        id={'disregardTransactionCode'}
                        checked={disregardTransactionCode}
                        onCheck={value => {
                          setDisregardTransactionCode(value)
                        }}
                      />

                      <label
                        htmlFor="disregardTransactionCode"
                        className="text-capitalize ms-2"
                      >
                        {localize('disregardTransactionCode')}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  col-md-3 gap-3">
                    <label className="text-capitalize col-2">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams.startDate,
                        endDate: reqParams.endDate,
                      }}
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                          })
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-space-between row mb-3">
                  <div className="d-flex align-items-center col-md-3">
                    <label className="text-capitalize col-3 me-2">
                      {localize('lookupMode')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="JournalLookupMode"
                      value={lookupMode.journalLookupMode}
                      onChange={e => {
                        setLookupMode({
                          ...lookupMode,
                          journalLookupMode: e.value,
                        })
                      }}
                    />
                  </div>
                  {lookupMode.journalLookupMode ===
                  Object.keys(journalLookupMode)[1] ? (
                    <div className="col-md-5 offset-4">
                      <div className="d-flex align-items-center gap-2">
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{
                            maxWidth: 200,
                            minWidth: 200,
                          }}
                          enumsKey="JournalEntryFilter"
                          value={reqParams.journalEntryFilter}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              journalEntryFilter: e.value,
                            })
                          }
                        />
                        <div
                          className="d-flex align-items-center"
                          style={{
                            width: '100%',
                          }}
                        >
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-3"
                            value={reqParams.filterText}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                filterText: e.target.value,
                              })
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                loadData()
                              }
                            }}
                          />
                          <button
                            id="FilterButton"
                            type="button"
                            className="btn btn-primary ms-2"
                            onClick={() => {
                              loadData()
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex align-items-center col-md-3">
                        <label className="text-capitalize col-3">
                          {localize('primaryDescription')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-2"
                          value={reqParams.primaryDescription}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              primaryDescription: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center col-md-3">
                        <label className="text-capitalize col-3">
                          {localize('secondaryDescription')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-2"
                          value={reqParams.secondaryDescription}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              secondaryDescription: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center col-md-3">
                        <label className="text-capitalize col-3">
                          {localize('reference')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-2"
                          value={reqParams.reference}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              reference: e.target.value,
                            })
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              loadData()
                            }
                          }}
                        />
                        <button
                          id="FilterButton"
                          type="button"
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            loadData()
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div
                  style={{
                    maxHeight: '50dvh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  className=" py-1"
                >
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />

                <div className="d-flex justify-content-between align-items-center mt-2 p-2 rounded bg-light">
                  <div className="d-flex justify-content-start gap-2 align-item-center">
                    <div className="d-flex align-items-center">
                      <Checkbox
                        id={'toggleJournalSelection'}
                        checked={selectAll}
                        onCheck={value => {
                          setSelectAll(value)
                          handleSelectAll(tableData, value)
                        }}
                      />

                      <label
                        htmlFor="toggleJournalSelection"
                        className="text-capitalize ms-2"
                      >
                        {localize('toggleSelection')}
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        id="selectAllJournalRecords"
                        checked={selectAllRecords}
                        onCheck={value => {
                          setSelectAllRecords(value)
                        }}
                      />

                      <label
                        htmlFor="selectAllJournalRecords"
                        className="text-capitalize ms-2"
                      >
                        {localize('selectAll') + ' ' + data?.itemsCount + ' '}
                        {localize('journalEntries')}
                      </label>
                    </div>
                  </div>
                  <button
                    className="btn btn-success float-end"
                    onClick={handleUpdate}
                  >
                    {localize('update')}
                  </button>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default JournalLookup
