/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  parseDate,
} from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import Loader from '../../Loaders/Loader'
import ModalTemplate from '../../ModalTemplate/ModalTemplate'
import SimpleTable from '../../Tables/SimpleTable'
import Transactions from '../GLAccounts/Transactions'
import NonBlockingLoader from '../../Loaders/NonBlockingLoader'

const JournalEntriesTab = ({ journalId, title }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [modalMode, setModalMode] = useState(null)

  const columns = [
    {
      label: localize('transactionDate'),
      template: r => parseDate(r?.createdDate),
    },
    {
      label: localize('amount'),
      template: r => formatCurrency(r?.amount),
    },
    {
      label: localize('gLAccountName'),
      template: r => r?.chartOfAccountName,
    },
    {
      label: localize('costCenter'),
      template: r => r?.costCenterDescription,
    },
    {
      label: localize('costDistributionRule'),
      template: r => r?.costDistributionRule,
    },
    {
      label: localize('costDistributionPercentage'),
      template: r => formatAsPercent(r?.costDistributionPercentage),
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('valueDate'),
      template: r => parseDate(r?.valueDate, false),
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r?.customerAccountFullAccountNumber,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerAccountCustomerFullName,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerAccountCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.formattedCustomerAccountCustomerAccountNumber,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.customerAccountCustomerPersonalFileNumber,
    },
    {
      label: localize('ref_1Account'),
      template: r => r?.customerAccountCustomerReference1,
    },
    {
      label: localize('ref_2Membership'),
      template: r => r?.customerAccountCustomerReference2,
    },
    {
      label: localize('ref_3PersonalFile'),
      template: r => r?.customerAccountCustomerReference3,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('integrityHash'),
      template: r => r?.integrityHash,
    },
  ]

  const fetchEntries = async () => {
    setIsBusy(true)
    const url = title
      ? `/JournalEntry/find-journal-entry-archives-by-journal-archive-id?journalArchiveId=${journalId}`
      : `/Journal/find-journal-with-journal-entries?journalId=${journalId}`

    const { success, data } = await getData(url, null, false)

    if (success) {
      if (title) setEntries(data?.result)
      else setEntries(data?.journalEntries?.message?.result)
    }

    setIsBusy(false)
  }

  const handleSelectEntry = (entry, mode) => {
    setSelectedEntry(entry)
    setModalMode(mode)
  }

  const handleClose = () => {
    setSelectedEntry(null)
    setModalMode(null)
  }

  useEffect(() => {
    fetchEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId])

  return (
    <>
      {modalMode && (
        <ModalTemplate
          modalClassName="modal-xl"
          modalMode={modalMode}
          handleClose={handleClose}
          hideUpdate
          cancelText="close"
          modalTitle="systemTransactions"
        >
          <Transactions glAccount={selectedEntry} />
        </ModalTemplate>
      )}

      <div
        className="row py-2 relative"
        style={{
          maxHeight: '80dvh',
          overflowY: 'auto',
        }}
      >
        <SimpleTable
          data={entries}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleSelectEntry(r, 'view')
                }}
              >
                <i className="uil uil-eye"></i>
                <span>{localize('view')}</span>
              </div>
            </>
          )}
          columns={columns}
        />

        {isBusy && <NonBlockingLoader />}
      </div>
    </>
  )
}

export default JournalEntriesTab
