/** @format */

import Approval from '../../Pages/FrontOffice/Operations/AccountClosure/Approval/Approval'
import Register from '../../Pages/FrontOffice/Operations/AccountClosure/Register/Register'
import Verification from '../../Pages/FrontOffice/Operations/AccountClosure/Verification/Verification'
import AutomatedChequeCatalogue from '../../Pages/FrontOffice/Operations/Cheques/AutomatedClearing/Catalogue/Catalogue'
import AutomatedChequeClearingJournals from '../../Pages/FrontOffice/Operations/Cheques/AutomatedClearing/Journals/Journals'
import AutomatedChequeProcessing from '../../Pages/FrontOffice/Operations/Cheques/AutomatedClearing/Processing/Processing'
import ChequeBanking from '../../Pages/FrontOffice/Operations/Cheques/External/Banking/Banking'
import ExternalChequeCatalogue from '../../Pages/FrontOffice/Operations/Cheques/External/Catalogue/Catalogue'
import ChequeClearance from '../../Pages/FrontOffice/Operations/Cheques/External/Clearance/Clearance'
import InHouseChequeCatalogue from '../../Pages/FrontOffice/Operations/Cheques/InHouse/Catalogue/Catalogue'
import InHouseChequePrinting from '../../Pages/FrontOffice/Operations/Cheques/InHouse/Printing/Printing'
import InHouseChequeWriting from '../../Pages/FrontOffice/Operations/Cheques/InHouse/Writing/Writing'
import ChequeBooks from '../../Pages/FrontOffice/Operations/CustomerAccounts/ChequeBooks/ChequeBooks'
import Management from '../../Pages/FrontOffice/Operations/CustomerAccounts/Management/Management'
import OverdraftProtection from '../../Pages/FrontOffice/Operations/CustomerAccounts/OverdraftProtection/OverdraftProtection'
import CustomerAccountsRegister from '../../Pages/FrontOffice/Operations/CustomerAccounts/Register/Register'
import Signatories from '../../Pages/FrontOffice/Operations/CustomerAccounts/Signatories/Signatories'
import StandingOrders from '../../Pages/FrontOffice/Operations/CustomerAccounts/StandingOrders/StandingOrders'
import EmolumentsAndAllowancesPaymentProcessing from '../../Pages/FrontOffice/Operations/EmolumentsAndAllowances/paymentProcessing'
import EmolumentsAndAllowancesPaymentSchedules from '../../Pages/FrontOffice/Operations/EmolumentsAndAllowances/paymentSchedules'
import FixedDepositsAuthorization from '../../Pages/FrontOffice/Operations/FixedDeposits/Authorization'
import FixedDepositsCatalogue from '../../Pages/FrontOffice/Operations/FixedDeposits/Catalogue'
import FixedDepositsFixing from '../../Pages/FrontOffice/Operations/FixedDeposits/Fixing'
import FixedDepositsLiquidation from '../../Pages/FrontOffice/Operations/FixedDeposits/Liquidation'
import FixedDepositsTermination from '../../Pages/FrontOffice/Operations/FixedDeposits/Termination'
import FixedDepositsVerification from '../../Pages/FrontOffice/Operations/FixedDeposits/Verification'
import FuneralRiderClaimAuthorization from '../../Pages/FrontOffice/Operations/FuneralRiderClaimPayables/Authorization'
import FuneralRiderClaimOrigination from '../../Pages/FrontOffice/Operations/FuneralRiderClaimPayables/Origination'
import FuneralRiderClaimVerification from '../../Pages/FrontOffice/Operations/FuneralRiderClaimPayables/Verification'
import PayableAndReceivableAuthorization from '../../Pages/FrontOffice/Operations/PayablesReceivables/Authorization/Authorization'
import PayableAndReceivableOrigination from '../../Pages/FrontOffice/Operations/PayablesReceivables/Origination/Origination'
import PayableAndReceivableVerification from '../../Pages/FrontOffice/Operations/PayablesReceivables/Verification/Verification'
import CashRequisitions from '../../Pages/FrontOffice/Operations/PettyCash/CashRequisitions/CashRequisitions'
import PaymentRequests from '../../Pages/FrontOffice/Operations/PettyCash/PaymentRequests/PaymentRequests'
import SundryPayments from '../../Pages/FrontOffice/Operations/PettyCash/SundryPayments/SundryPayments'
import SuperSaverPayablesAuthorization from '../../Pages/FrontOffice/Operations/SuperSaverPayables/Authorization/Authorization'
import Origination from '../../Pages/FrontOffice/Operations/SuperSaverPayables/Origination/Origination'
import SuperSaverPayablesVerification from '../../Pages/FrontOffice/Operations/SuperSaverPayables/Verification/Verification'
import CashRequisition from '../../Pages/FrontOffice/Operations/Teller/CashRequisition/CashRequisition'
import TellerCashTransfer from '../../Pages/FrontOffice/Operations/Teller/CashTransfer/CashTransfer'
import ChequesTransfer from '../../Pages/FrontOffice/Operations/Teller/ChequesTransfer/ChequesTransfer'
import CustomerReceipts from '../../Pages/FrontOffice/Operations/Teller/CustomerReceipts/CustomerReceipts'
import SavingsReceiptsPayments from '../../Pages/FrontOffice/Operations/Teller/SavingsReceiptsPayments/SavingsReceiptsPayments'
import SundryReceiptsPayments from '../../Pages/FrontOffice/Operations/Teller/SundryReceiptsPayments/SundryReceiptsPayments'
import CashDepositRequests from '../../Pages/FrontOffice/Operations/Treasury/CashDepositRequests/CashDepositRequests'
import CashManagementRequests from '../../Pages/FrontOffice/Operations/Treasury/CashManagementRequests/CashManagementRequests'
import TreasuryCashRequisitions from '../../Pages/FrontOffice/Operations/Treasury/CashRequisitions/CashRequisitions'
import CashTransfer from '../../Pages/FrontOffice/Operations/Treasury/CashTransfer/CashTransfer'
import CashWithdrawalRequests from '../../Pages/FrontOffice/Operations/Treasury/CashWithdrawalRequests/CashWithdrawalRequests'
import FiscalCatalogue from '../../Pages/FrontOffice/Operations/Treasury/FiscalCatalogue/FiscalCatalogue'
import Treasuries from '../../Pages/FrontOffice/Setups/Treasury/Treasuries'
import Tellers from '../../Pages/FrontOffice/Setups/Tellers/Tellers'
import PettyCashiers from '../../Pages/FrontOffice/Setups/PettyCashiers/PettyCashiers'
import ChequeTypes from '../../Pages/FrontOffice/Setups/ChequeTypes/ChequeTypes'
import FixedDepositTypes from '../../Pages/FrontOffice/Setups/FixedDepositTypes/FixedDepositTypes'
import BankersChequeBooks from '../../Pages/FrontOffice/Setups/BankersChequeBooks/BankersChequeBooks'
import EmolumentTypes from '../../Pages/FrontOffice/Setups/EmolumentTypes/EmolumentTypes'
import ExternalAgencies from '../../Pages/FrontOffice/Setups/ExternalAgency/ExternalAgencies'
import UnpayReasons from '../../Pages/FrontOffice/Setups/UnpayReasons/UnpayReasons'

const frontOfficeMenus = [
  {
    iconClass: 'uil uil-th',
    label: 'frontOfficeManagement',
    code: 5,
    setup: [
      {
        label: 'treasuries',
        component: <Treasuries />,
        code: 2001,
        parentCode: 5,
      },
      {
        label: 'tellers',
        component: <Tellers />,
        code: 2002,
        parentCode: 5,
      },
      {
        label: 'pettyCashiers',
        component: <PettyCashiers />,
        code: 2003,
        parentCode: 5,
      },
      {
        label: 'chequeTypes',
        component: <ChequeTypes />,
        code: 2004,
        parentCode: 5,
      },
      {
        label: 'externalAgencies',
        component: <ExternalAgencies />,
        code: 2008,
        parentCode: 5,
      },
      {
        label: 'fixedDepositTypes',
        component: <FixedDepositTypes />,
        code: 2005,
        parentCode: 5,
      },
      {
        label: 'unpayReason',
        component: <UnpayReasons />,
        code: 2006,
        parentCode: 5,
      },
      {
        label: 'bankersChequeBook',
        component: <BankersChequeBooks />,
        code: 2007,
        parentCode: 5,
      },
      {
        label: 'emolumentTypes',
        component: <EmolumentTypes />,
        code: 2009,
        parentCode: 5,
      },
    ],
    operations: [
      {
        label: 'treasury',
        children: [
          {
            label: 'cashTransfer',
            code: 2501,
            parentCode: 2500,
            component: <CashTransfer />,
          },
          {
            label: 'cashWithdrawalRequests',
            code: 2502,
            parentCode: 2500,
            component: <CashWithdrawalRequests />,
          },
          {
            label: 'cashDepositRequests',
            code: 2503,
            parentCode: 2500,
            component: <CashDepositRequests />,
          },
          {
            label: 'cashManagementRequests',
            code: 2504,
            parentCode: 2500,
            component: <CashManagementRequests />,
          },
          {
            label: 'cashRequisitions',
            code: 2506,
            parentCode: 2500,
            component: <TreasuryCashRequisitions />,
          },
          {
            label: 'fiscalCatalogue',
            code: 2505,
            parentCode: 2500,
            component: <FiscalCatalogue />,
          },
        ],
      },
      {
        label: 'teller',
        children: [
          {
            label: 'savingsReceipts/Payments',
            code: 2507,
            parentCode: 2507,
            component: <SavingsReceiptsPayments />,
          },
          {
            label: 'sundryReceiptsPayments',
            code: 2508,
            parentCode: 2507,
            component: <SundryReceiptsPayments />,
          },
          {
            label: 'customerReceipts',
            code: 2509,
            parentCode: 2507,
            component: <CustomerReceipts />,
          },
          {
            label: 'chequesTransfer',
            code: '2511',
            parentCode: 2507,
            component: <ChequesTransfer />,
          },
          {
            label: 'cashTransfer',
            code: 2510,
            parentCode: 2507,
            component: <TellerCashTransfer />,
          },
          {
            label: 'cashRequisition',
            code: 2512,
            parentCode: 2507,
            component: <CashRequisition />,
          },
        ],
      },
      {
        label: 'pettyCash',
        children: [
          {
            label: 'sundryPayments',
            code: 2512,
            parentCode: 2511,
            component: <SundryPayments />,
          },
          {
            label: 'cashRequisitions',
            code: 2513,
            parentCode: 2511,
            component: <CashRequisitions />,
          },
          {
            label: 'paymentRequests',
            code: 2514,
            parentCode: 2511,
            component: <PaymentRequests />,
          },
        ],
      },
      {
        label: 'cheques',
        children: [
          {
            label: 'external',
            children: [
              {
                label: 'banking',
                code: 2517,
                parentCode: 2516,
                component: <ChequeBanking />,
              },
              {
                label: 'clearance',
                code: 2518,
                parentCode: 2516,
                component: <ChequeClearance />,
              },
              {
                label: 'catalogue',
                code: 2519,
                parentCode: 2516,
                component: <ExternalChequeCatalogue />,
              },
            ],
          },
          {
            label: 'inHouse',
            children: [
              {
                label: 'writing',
                code: 2521,
                parentCode: 2520,
                component: <InHouseChequeWriting />,
              },
              {
                label: 'printing',
                code: 2522,
                parentCode: 2520,
                component: <InHouseChequePrinting />,
              },
              {
                label: 'catalogue',
                code: 2523,
                parentCode: 2520,
                component: <InHouseChequeCatalogue />,
              },
            ],
          },
          {
            label: 'automatedClearing',
            children: [
              {
                label: 'journals',
                code: 2525,
                parentCode: 2524,
                component: <AutomatedChequeClearingJournals />,
              },
              {
                label: 'processing',
                code: 2526,
                parentCode: 2524,
                component: <AutomatedChequeProcessing />,
              },
              {
                label: 'catalogue',
                code: 2527,
                parentCode: 2524,
                component: <AutomatedChequeCatalogue />,
              },
            ],
          },
        ],
      },
      {
        label: 'fixedDeposits',
        children: [
          {
            label: 'fixing',
            code: 2529,
            parentCode: 2528,
            component: <FixedDepositsFixing />,
          },
          {
            label: 'verification',
            code: 2530,
            parentCode: 2528,
            component: <FixedDepositsVerification />,
          },
          {
            label: 'authorization',
            code: 2531,
            parentCode: 2528,
            component: <FixedDepositsAuthorization />,
          },
          {
            label: 'termination',
            code: 2532,
            parentCode: 2528,
            component: <FixedDepositsTermination />,
          },
          {
            label: 'liquidation',
            code: 2533,
            parentCode: 2528,
            component: <FixedDepositsLiquidation />,
          },
          {
            label: 'cataloque',
            code: 2533,
            parentCode: 2528,
            component: <FixedDepositsCatalogue />,
          },
        ],
      },
      {
        label: 'customerAccounts',
        children: [
          {
            label: 'register',
            parentCode: 2537,
            code: 2538,
            component: <CustomerAccountsRegister />,
          },
          {
            label: 'management',
            parentCode: 2539,
            code: 2538,
            component: <Management />,
          },
          {
            label: 'signatories',
            parentCode: 2540,
            code: 2538,
            component: <Signatories />,
          },
          {
            label: 'chequeBooks',
            parentCode: 2541,
            code: 2538,
            component: <ChequeBooks />,
          },
          {
            label: 'standingOrders',
            parentCode: 2542,
            code: 2538,
            component: <StandingOrders />,
          },
          {
            label: 'overdraftProtection',
            parentCode: 2543,
            code: 2538,
            component: <OverdraftProtection />,
          },
        ],
      },
      {
        label: 'payables/receivables',
        children: [
          {
            description: 'payablesReceivableOrigination',
            label: 'origination',
            code: 2545,
            parentCode: 2544,
            component: <PayableAndReceivableOrigination />,
          },
          {
            description: 'payablesReceivableVerification',
            label: 'verification',
            code: 2546,
            parentCode: 2544,
            component: <PayableAndReceivableVerification />,
          },
          {
            description: 'payablesReceivableAuthorization',
            label: 'authorization',
            code: 2547,
            parentCode: 2544,
            component: <PayableAndReceivableAuthorization />,
          },
        ],
      },

      {
        label: 'emolumentsAndAllowances',
        children: [
          {
            label: 'paymentSchedules',
            code: 2549,
            parentCode: 2548,
            component: <EmolumentsAndAllowancesPaymentSchedules />,
          },
          {
            label: 'paymentProcessing',
            code: 2550,
            parentCode: 2548,
            component: <EmolumentsAndAllowancesPaymentProcessing />,
          },
        ],
      },

      {
        label: 'accountClosure',
        children: [
          {
            label: 'register',
            code: 2550,
            parentCode: 2549,
            component: <Register />,
          },
          {
            label: 'approval',
            code: 2551,
            parentCode: 2549,
            component: <Approval />,
          },
          {
            label: 'verification',
            code: 2552,
            parentCode: 2549,
            component: <Verification />,
          },
        ],
      },
      {
        label: 'funeralRiderClaimPayables',
        children: [
          {
            label: 'origination',
            code: 2560,
            parentCode: 2559,
            component: <FuneralRiderClaimOrigination />,
          },
          {
            label: 'verification',
            code: 2561,
            parentCode: 2559,
            component: <FuneralRiderClaimVerification />,
          },
          {
            label: 'authorization',
            code: 2562,
            parentCode: 2559,
            component: <FuneralRiderClaimAuthorization />,
          },
        ],
      },
      {
        label: 'superSaverPayables',
        children: [
          {
            label: 'origination',
            code: 2556,
            parentCode: 2555,
            component: <Origination />,
          },
          {
            label: 'verification',
            code: 2557,
            parentCode: 2555,
            component: <SuperSaverPayablesVerification />,
          },
          {
            label: 'authorization',
            code: 2558,
            parentCode: 2555,
            component: <SuperSaverPayablesAuthorization />,
          },
        ],
      },
    ],
  },
]

export default frontOfficeMenus
