/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import ViewGLAccountTransactions from '../../../../Components/ReUsables/GLAccounts/ViewGLAccountTransactions/ViewGLAccountTransactions'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import SimpleTableWithSearchAndPaging from '../../../../Components/Tables/SimpleTableWithSearchAndPaging'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import {
  chartOfAccountCategory,
  chartOfAccountType,
} from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import CreateChartOfAccountsModal from './CreateChartOfAccountsModal'

const initialChartOfAccount = {
  accountType: chartOfAccountType.ASSET,
  accountCategory: chartOfAccountCategory.HeaderACCOUNT,
  accountCode: 0,
}

function ChartOfAccounts() {
  const [searchString, setSearchString] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    chartOfAccountType: 1000,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    includeBalances: false,
    updateDepth: false,
  })
  const [data, setData] = useState(null)
  const [account, setAccount] = useState(initialChartOfAccount)
  const [modalMode, setModalMode] = useState(null)

  useEffect(() => {
    loadChartOfAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  async function loadChartOfAccounts() {
    setIsBusy(true)
    setData(null)
    const resp = await getData(
      '/ChartOfAccount/find-chart-of-accounts-by-filter-in-page',
      reqParams,
      false
    )
    if (resp.success) {
      setData(resp.data)
    } else {
      setData(null)
    }

    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('accountName'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedName }} />
      ),
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('costDistributionRule'),
      template: r => <>{r?.costDistributionRuleDescription}</>,
    },
    {
      label: localize('currency'),
      template: r => <>{r?.currencyDescription}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{r?.formattedBalance}</>,
    },
    { label: localize('treeDepth'), template: r => <>{r?.depth}</> },
    {
      label: localize('isControlAcount') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.isControlAccount.toString()}
        </span>
      ),
    },
    {
      label: localize('isReconciliationAccount') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.isReconciliationAccount.toString()}
        </span>
      ),
    },
    {
      label: localize('postAutomaticallyOnly') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.postAutomaticallyOnly.toString()}
        </span>
      ),
    },
    {
      label: localize('isConfidential') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isConfidential.toString()}</span>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('assets'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            includeBalances={reqParams.includeBalances}
            updateDepth={reqParams.updateDepth}
            onIncludeBalances={value => {
              setReqParams({ ...reqParams, includeBalances: value })
            }}
            onUpdateDepth={value => {
              setReqParams({ ...reqParams, updateDepth: value })
            }}
            isBusy={isBusy}
            data={data || []}
            columns={columns}
            pageSize={reqParams?.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps, pageIndex: 0 })
            }
            currentPage={reqParams?.pageIndex}
            onSelect={r => {}}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s, pageIndex: 0 })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            contextMenu={r => (
              <ContextMenu
                row={r}
                onEdit={onEdit}
                onView={onView}
                onViewAuditLogs={onViewAuditLogs}
              />
            )}
          />
        </div>
      ),
      enum: 1000,
    },
    {
      label: localize('liabilities'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            includeBalances={reqParams.includeBalances}
            updateDepth={reqParams.updateDepth}
            onIncludeBalances={value => {
              setReqParams({
                ...reqParams,
                includeBalances: value,
              })
            }}
            isBusy={isBusy}
            onUpdateDepth={value => {
              setReqParams({
                ...reqParams,
                updateDepth: value,
              })
            }}
            data={data || []}
            columns={columns}
            pageSize={reqParams?.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps, pageIndex: 0 })
            }
            currentPage={reqParams?.pageIndex}
            onSelect={r => {}}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s, pageIndex: 0 })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            contextMenu={r => (
              <ContextMenu row={r} onView={onView} onEdit={onEdit} />
            )}
          />
        </div>
      ),
      enum: 2000,
    },
    {
      label: localize('equity') + '/' + localize('capital'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            includeBalances={reqParams.includeBalances}
            updateDepth={reqParams.updateDepth}
            onIncludeBalances={value => {
              setReqParams({
                ...reqParams,
                includeBalances: value,
              })
            }}
            isBusy={isBusy}
            onUpdateDepth={value => {
              setReqParams({
                ...reqParams,
                updateDepth: value,
              })
            }}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps, pageIndex: 0 })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {}}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s, pageIndex: 0 })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            contextMenu={r => (
              <ContextMenu row={r} onView={onView} onEdit={onEdit} />
            )}
          />
        </div>
      ),
      enum: 3000,
    },
    {
      label: localize('income') + '/' + localize('revenue'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            includeBalances={reqParams.includeBalances}
            updateDepth={reqParams.updateDepth}
            onIncludeBalances={value => {
              setReqParams({
                ...reqParams,
                includeBalances: value,
              })
            }}
            isBusy={isBusy}
            onUpdateDepth={value => {
              setReqParams({
                ...reqParams,
                updateDepth: value,
              })
            }}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps, pageIndex: 0 })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {}}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s, pageIndex: 0 })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            contextMenu={r => (
              <ContextMenu row={r} onView={onView} onEdit={onEdit} />
            )}
          />
        </div>
      ),
      enum: 4000,
    },
    {
      label: localize('expenses'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            includeBalances={reqParams.includeBalances}
            updateDepth={reqParams.updateDepth}
            onIncludeBalances={value => {
              setReqParams({
                ...reqParams,
                includeBalances: value,
              })
            }}
            isBusy={isBusy}
            onUpdateDepth={value => {
              setReqParams({
                ...reqParams,
                updateDepth: value,
              })
            }}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={ps =>
              setReqParams({ ...reqParams, pageSize: ps, pageIndex: 0 })
            }
            currentPage={reqParams.pageIndex}
            onSelect={r => {}}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s, pageIndex: 0 })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            contextMenu={r => (
              <ContextMenu row={r} onView={onView} onEdit={onEdit} />
            )}
          />
        </div>
      ),
      enum: 5000,
    },
  ]

  function onAdd() {
    setModalMode('add')
    setAccount(initialChartOfAccount)
  }
  function onEdit(row) {
    if (!row.parentId)
      return showNotification(
        'Operation not allowed',
        'error',
        'You must select a non-root G/L Account!'
      )

    setModalMode('edit')
    setAccount(row)
  }
  function onView(row) {
    setModalMode('view')
    setAccount(row)
  }
  function onViewAuditLogs(row) {
    setModalMode('auditLogs')
    setAccount(row)
  }

  const handleClose = () => {
    setModalMode(null)
    setAccount(initialChartOfAccount)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('GLAccounts'),
          localize('chartOfAccounts'),
        ]}
      />
      <div className="card bg-white">
        <div className="card-body">
          <div className="d-flex">
            <AddNewButton handleClickAddButton={onAdd} />
          </div>

          <div className="pt-3">
            <Tab
              preload
              tabItems={tabItems}
              onNavigate={x => {
                setReqParams({
                  ...reqParams,
                  chartOfAccountType: x.enum,
                  pageIndex: 0,
                })
              }}
            />
          </div>
        </div>
      </div>

      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateChartOfAccountsModal
          mode={modalMode}
          account={account}
          close={handleClose}
          refreshData={loadChartOfAccounts}
        />
      )}
      {modalMode === 'view' && (
        <>
          <ViewGLAccountTransactions
            glAccount={{
              chartOfAccountId: account?.id,
              chartOfAccountName: account?.description,
              chartOfAccountAccountType: account?.typeDescription,
              chartOfAccountCostDistributionRuleDescription:
                account?.costDistributionRuleDescription,
            }}
            handleClose={handleClose}
            mode={modalMode}
          />
        </>
      )}
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={modalMode}
          data={account}
          title={account?.description}
        />
      )}
    </>
  )
}

function ContextMenu({ row, onView, onEdit, onViewAuditLogs }) {
  return (
    <>
      <div className="ctxt-menu-item" onClick={() => onView(row)}>
        <i className="uil uil-eye"></i>
        <span className="text-capitalize">{localize('view')}</span>
      </div>
      <div className="ctxt-menu-item" onClick={() => onEdit(row)}>
        <i className="uil uil-edit-alt"></i>
        <span className="text-capitalize">{localize('edit')}</span>
      </div>
      <div className="ctxt-menu-item" onClick={() => onViewAuditLogs(row)}>
        <i className="uil uil-server"></i>
        <span className="text-capitalize">{localize('viewAuditLogs')}</span>
      </div>
    </>
  )
}

export default ChartOfAccounts
