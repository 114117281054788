/** @format */

import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { EmolumentTypesCommissions } from './EmolumentTypesCommissions'
import { EmolumentTypesSavingsProducts } from './EmolumentTypesSavingsProducts'
import { EmolumentTypeFlags } from './EmolumentTypeFlags'
import React from 'react'
import { formatCurrency } from '../../../../../Helpers/formatters'

export const ViewEmolumentTypeModal = ({
  waitingMetadata,
  selectedEmolumentType,
  setSelectedEmolumentType,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center with-validation">
          <label
            className="col-1 text-capitalize"
            style={{ marginRight: 4.1 + '%' }}
          >
            {localize('name')}
          </label>
          <input
            value={selectedEmolumentType?.description}
            className="form-control"
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center with-validation">
          <label className="col-3 text-capitalize">
            {localize('glAccount')}
          </label>
          <input
            value={selectedEmolumentType?.chartOfAccountName}
            className={'form-control'}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('amount')}</label>
          <input
            className="form-control text-end"
            value={formatCurrency(selectedEmolumentType?.amount,2,2, false)}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('transactionOwnership')}
          </label>
          <input
            value={selectedEmolumentType?.transactionOwnershipDescription}
            disabled
            className="form-control"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('autoWireMedium')}
          </label>
          <input
            value={selectedEmolumentType?.autowireMediumDescription}
            className={'form-control'}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            tabItems={[
              {
                label: localize('applicableCharges'),
                item: (
                  <EmolumentTypesCommissions
                    disabled
                    selectedEmolumentType={selectedEmolumentType}
                    isBusy={waitingMetadata}
                    setSelectedEmolumentType={setSelectedEmolumentType}
                    prefix={'add-applicable'}
                  />
                ),
              },
              {
                label: localize('beneficiaryProducts'),
                item: (
                  <EmolumentTypesSavingsProducts
                    disabled
                    isBusy={waitingMetadata}
                    setSelectedEmolumentType={setSelectedEmolumentType}
                    selectedEmolumentType={selectedEmolumentType}
                    prefix={'add-emolument-type'}
                  />
                ),
              },
              {
                label: localize('flags'),
                item: (
                  <EmolumentTypeFlags
                    disabled
                    selectedEmolumentType={selectedEmolumentType}
                    setSelectedEmolumentType={setSelectedEmolumentType}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
