/** @format */
import {
  SET_ACCOUNT_CARRY_FORWARDS,
  SET_ACCOUNT_DETAILS, SET_ACCOUNT_JOURNAL_ENTRIES, SET_ACCOUNT_REPAYMENT_SCHEDULE, SET_ACCOUNT_STATEMENTS,
  SET_CUSTOMER_ACCOUNT_BALANCES_SUMMARY,
  SET_CUSTOMER_ADDRESS,
  SET_CUSTOMER_CREDIT_SCORES,
  SET_CUSTOMERS_ACCOUNT_LISTINGS,
  SET_SELECTED_CUSTOMER
} from './actions'

export const customerDetailsReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECTED_CUSTOMER:
      return { ...state, selectedCustomer: action.payload };
    case SET_CUSTOMERS_ACCOUNT_LISTINGS:
      return { ...state, customersAccountListings: action.payload };
    case SET_CUSTOMER_CREDIT_SCORES:
      return { ...state, customerCreditScores: action.payload };
    case SET_CUSTOMER_ADDRESS:
      return { ...state, customerAddress: action.payload };
    case SET_CUSTOMER_ACCOUNT_BALANCES_SUMMARY:
      return { ...state, customerAccountBalancesSummary: action.payload };
    default:
      return state;
  }
}

export const accountDetailsReducer = (state, action) => {
  switch (action.type) {
    case SET_ACCOUNT_DETAILS:
      return { ...state, accountDetails: action.payload };
    case SET_ACCOUNT_STATEMENTS:
      return { ...state, accountStatements: action.payload };
    case SET_ACCOUNT_REPAYMENT_SCHEDULE:
      return { ...state, accountRepaymentSchedule: action.payload };
    case SET_ACCOUNT_CARRY_FORWARDS:
      return { ...state, accountCarryForwards: action.payload };
    case SET_ACCOUNT_JOURNAL_ENTRIES:
      return { ...state, accountJournalEntries: action.payload };
    default:
      return state;
  }

}
