/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import BankLinkageLookup from '../../../../../Components/Lookup/BankLinkageLookup'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import LoanRepaymentModeLookup from '../../../../../Components/Lookup/LoanRepaymentModeLookup'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import CustomerAccountPartial from '../../../../../Components/ReUsables/CustomerAccount/CustomerAccountPartial'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  apportionTo,
  apportionedComponent,
  batchEntryStatus,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../../Helpers/extensions'
import { getTodaysDate } from '../../../../../Helpers/formatters'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import ReceptingEntries from './ReceptingEntries'

function Receipting() {
  const [selectedCustomer, setSelectedCustomer] = useState({
    apportionedComponent: Object.keys(apportionedComponent)[2],
    apportionTo: Object.keys(apportionTo)[0],
  })
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [refreshEntry, setRefreshEntry] = useState(false)
  const [selectedBankToBankRequest, setSelectedBankToBankRequest] =
    useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [entries, setEntries] = useState([])

  async function handleSubmit() {
    const { success, data } = await postData(
      '/BankToBankRequest/create-bank-to-bank-request-with-entries',
      {
        bankToBankRequest: {
          ...selectedBankToBankRequest,
          transactionDate: getTodaysDate(),
          paymentDate: getTodaysDate(),
          systemTraceAuditNumber: generateGuid(),
          additionalInfo: selectedBankToBankRequest?.documentReferenceNumber,

          transactionReferenceCode:
            selectedBankToBankRequest?.documentReferenceNumber,
          paymentReferenceCode:
            selectedBankToBankRequest?.documentReferenceNumber,
          accountNumber: selectedBankToBankRequest?.documentReferenceNumber,
          totalAmount: selectedBankToBankRequest?.paymentAmount,
        },
        bankToBankRequestEntryDTOs: entries,
        autoMatch: true,
      },
      false
    )
    if (success) {
      if (success) {
        setRefreshEntry(!refreshEntry)
        setSelectedCustomer({ apportionTo: selectedCustomer?.apportionTo })
        setSelectedAccount(null)
        showNotification('Bank to Bank Receipting', 'success')
      }
    }
  }

  const handleOnSelectCustomer = customer => {
    if (!customer.isLocked) {
      setSelectedCustomer({
        ...selectedCustomer,
        apportionTo: selectedCustomer.apportionTo,
        ...customer,
      })
    } else {
      showNotification(
        'Bank to Bank Receipting',
        'error',
        'Sorry, But the Selected Customer is Locked'
      )
      setSelectedCustomer(null)
    }
  }

  const handleOnSelectAccount = account => {
    if (!account.isLocked) {
      setSelectedAccount({ ...selectedAccount, ...account })
    } else {
      showNotification('Bank to Bank Receipting', 'success')
      showNotification(
        'Bank to Bank Receipting',
        'error',
        'Sorry, But the Selected Customer Aoount is Locked'
      )
      setSelectedAccount(null)
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  async function stageEntry() {
    setEntries([
      ...entries,
      {
        id: generateGuid(),
        status: 1,
        statusDescription: Object.values(batchEntryStatus)[0].name,
        customerAccountId: selectedAccount?.id,
        chartOfAccountId: selectedCustomer?.chartOfAccountId,
        chartOfAccountName: selectedCustomer?.chartOfAccountName,
        chartOfAccountCostDistributionRuleDescription:
          selectedCustomer?.bankToBankRequestChartOfAccountCostDistributionRuleDescription,
        bankToBankRequestId: selectedBankToBankRequest?.id,
        loanRepaymentModeId: selectedCustomer?.loanRepaymentModeId,
        loanRepaymentModeDescription:
          selectedCustomer?.loanRepaymentModeDescription,
        apportionTo: selectedCustomer?.apportionTo,
        apportionToDescription: apportionTo[selectedCustomer?.apportionTo].name,
        apportionedComponent: selectedCustomer?.apportionedComponent,
        apportionedComponentDescription:
          apportionedComponent[selectedCustomer?.apportionedComponent].name,
        apportionedAmount: selectedCustomer?.apportionedAmount,
        remarks: selectedAccount?.remarks,
        transactionAmount: selectedBankToBankRequest?.paymentAmount,
        customerAccountFullAccountNumber: selectedAccount?.fullAccountNumber,
        customerAccountCustomerFullName: selectedCustomer?.fullName,
        customerAccountCustomerReference1: selectedCustomer?.reference1,
        customerAccountCustomerReference2: selectedCustomer?.reference2,
        customerAccountCustomerReference3: selectedCustomer?.reference3,
      },
    ])
    clearFields()
  }

  const handleRemove = entry => {
    const filteredData = entries.filter(d => d.id !== entry.id)
    setEntries(filteredData)
  }

  async function clearFields() {
    setSelectedAccount(null)
    setSelectedCustomer({
      apportionedComponent: Object.keys(apportionedComponent)[2],
      apportionTo: Object.keys(apportionTo)[0],
    })
  }

  const handleEntriesValidationChecks = () => {
    if (selectedCustomer?.apportionTo === '1') {
      if (!selectedCustomer?.customerAccountId) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first Select a Customer'
        )
      } else if (!selectedAccount?.id) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first Select a Customer account'
        )
      } else if (!selectedCustomer?.apportionedAmount) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first enter apportioned amount'
        )
      } else if (!selectedAccount?.remarks) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first enter remarks'
        )
      } else if (!selectedCustomer?.loanRepaymentModeId) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first Select a loan repayment mode!'
        )
      } else {
        stageEntry()
      }
    }
    if (selectedCustomer?.apportionTo === '2') {
      if (!selectedCustomer?.chartOfAccountId) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first Select Chart of Account'
        )
      } else if (!selectedCustomer?.apportionedAmount) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first enter apportioned amount'
        )
      } else if (!selectedCustomer?.loanRepaymentModeId) {
        return showNotification(
          'Bank to Bank Receipting',
          'error',
          'Sorry, But You must first Select a loan repayment mode!'
        )
      } else {
        stageEntry()
      }
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('bankToBankRequests'),
          localize('receipting'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            <div className="row mb-2">
              <div
                className="col-3 d-flex align-items-center ms-2 with-validation"
                validation-message={
                  globalValidationErrors['BankToBankRequest.ChartOfAccountId']
                    ?.message
                }
              >
                <label className="col-2 text-capitalize">
                  {localize('bank')}
                </label>
                <BankLinkageLookup
                  displayValue={selectedBankToBankRequest?.chartOfAccountName}
                  onSelect={r => {
                    clearValidation('BankToBankRequest.ChartOfAccountId')
                    setSelectedBankToBankRequest({
                      ...selectedBankToBankRequest,
                      chartOfAccountId: r.chartOfAccountId,
                      chartOfAccountName: r.chartOfAccountName,
                    })
                  }}
                />
              </div>
              <div
                className="col d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors[
                    'BankToBankRequest.DocumentReferenceNumber'
                  ]?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control ms-2"
                  value={
                    selectedBankToBankRequest?.documentReferenceNumber || ''
                  }
                  onChange={e => {
                    clearValidation('BankToBankRequest.DocumentReferenceNumber')
                    setSelectedBankToBankRequest({
                      ...selectedBankToBankRequest,
                      documentReferenceNumber: e.target.value,
                    })
                  }}
                />
              </div>
              <div
                className="col d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['BankToBankRequest.TotalAmount']
                    ?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('amount')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    selectedBankToBankRequest?.paymentAmount || ''
                  }
                  className="form-control ms-2"
                  onMaskNumber={e => {
                    clearValidation('BankToBankRequest.TotalAmount')
                    setSelectedBankToBankRequest({
                      ...selectedBankToBankRequest,
                      paymentAmount: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center ms-1 ">
                <label className="col-3 text-capitalize">
                  {localize('valueDate')}
                </label>
                <CustomDatePicker
                  defaultDate={selectedBankToBankRequest?.valueDate}
                  name={'valueDate'}
                  onDateChange={date => {
                    setSelectedBankToBankRequest({
                      ...selectedBankToBankRequest,
                      valueDate: date,
                    })
                  }}
                />
              </div>
            </div>
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('apportionment')}
              </div>

              <div className="col-12 d-flex align-items-center mb-2">
                <label className="col-1 text-capitalize">
                  {localize('apportionedTo')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ApportionTo"
                  value={selectedCustomer?.apportionToDescription}
                  onChange={e =>
                    setSelectedCustomer({
                      ...selectedCustomer,
                      apportionTo: e.value,
                    })
                  }
                />
              </div>

              {selectedCustomer?.apportionTo == 1 ? (
                <>
                  <CustomerPartial
                    customer={selectedCustomer}
                    onCustomerLookup={r => {
                      handleOnSelectCustomer(r)
                    }}
                  />
                  <CustomerAccountPartial
                    selectedAccount={selectedAccount}
                    onCustomerAccountLookup={r => {
                      handleOnSelectAccount(r)
                    }}
                    selectedCustomer={selectedCustomer}
                  />
                </>
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('chartOfAccountName')}
                      </label>
                      <ChartOfAccountsLookup
                        displayValue={selectedCustomer?.chartOfAccountName}
                        onSelect={r => {
                          setSelectedCustomer({
                            ...selectedCustomer,
                            chartOfAccountId: r.id,
                            chartOfAccountName: r.description,
                            customerAccountCustomerAccountTypeTargetProductDescription:
                              r.typeDescription,
                            bankToBankRequestChartOfAccountCostDistributionRuleDescription:
                              r.costDistributionRuleDescription,
                            remarks: r.accountName,
                          })
                        }}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('accountType')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={
                          selectedCustomer?.customerAccountCustomerAccountTypeTargetProductDescription ||
                          ''
                        }
                      />
                    </div>
                    <div className="col-6 d-flex align-items-center">
                      <label className="col-2 text-capitalize">
                        {localize('costDistributionRule')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={
                          selectedCustomer?.BankToBankRequestChartOfAccountCostDistributionRuleDescription ||
                          ''
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-2">
                <div
                  className="col d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['ApportionedAmount']?.message
                  }
                >
                  <label className="col-4 text-capitalize">
                    {localize('amount')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={selectedCustomer?.apportionedAmount}
                    className="form-control"
                    onMaskNumber={e => {
                      clearValidation('ApportionedAmount')
                      setSelectedCustomer({
                        ...selectedCustomer,
                        apportionedAmount: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('component')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ApportionedComponent"
                    value={selectedCustomer?.apportionedComponentDescription}
                    onChange={e =>
                      setSelectedCustomer({
                        ...selectedCustomer,
                        apportionedComponent: e.value,
                      })
                    }
                  />
                </div>
                {selectedCustomer?.apportionTo == 1 ? (
                  <div className="col-6 d-flex align-items-center ">
                    <label className="col-2 text-capitalize">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      value={
                        selectedAccount?.customerAccountTypeTargetProductDescription ||
                        ''
                      }
                      onChange={e => {
                        clearValidation('Remarks')
                        setSelectedAccount({
                          ...selectedAccount,
                          remarks: e.target.value,
                        })
                      }}
                    />
                  </div>
                ) : (
                  <div className="col-6 d-flex align-items-center ">
                    <label className="col-2 text-capitalize">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      value={selectedCustomer?.chartOfAccountName || ''}
                      onChange={e => {
                        setSelectedAccount({
                          ...selectedCustomer,
                          remarks: e.target.value,
                        })
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="row mb-2">
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('loanRepaymentMode')}
                  </label>
                  <LoanRepaymentModeLookup
                    displayValue={
                      selectedCustomer?.loanRepaymentModeDescription
                    }
                    onSelect={r => {
                      setSelectedCustomer({
                        ...selectedCustomer,
                        loanRepaymentModeId: r.id,
                        loanRepaymentModeDescription: r.description,
                      })
                    }}
                  />
                </div>
              </div>

              <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
                <button
                  onClick={() => {
                    handleEntriesValidationChecks()
                  }}
                  className="btn btn-success px-5"
                >
                  {localize('add')}
                </button>
              </div>
              <div className="mt-3">
                <Tab
                  tabItems={[
                    {
                      label: localize('entries'),
                      item: (
                        <ReceptingEntries
                          unpostedEntries={entries}
                          handleRemove={handleRemove}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-light d-flex justify-content-end align-items-center p-2"
        style={{ borderRadius: 5 }}
      >
        <button
          onClick={() => {
            handleSubmit()
          }}
          className="btn btn-success text-uppercase ms-3 px-4"
        >
          {localize('update')}
        </button>
      </div>
    </>
  )
}

export default Receipting
