/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import ListingPage from '../../../Components/ListingPage/Index'
import { formatDate } from '../../../Helpers/formatters'
import ChargesExemptionsModal from './Partials/ChargesExemptionsModal'
import { getData } from '../../../Helpers/webApi'
import ViewChargesExemptions from './Partials/ViewChargesExemptions'

function ChargesExemptions() {
  const [mode, setMode] = useState(null)
  const [vieMode, setVieMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [selectedChargeExemption, setSelectedChargeExemption] = useState(null)
  const [chargeExemptionEntries, setChargeExemptionEntries] = useState([])
  const [refreshExemptionEntries, setRefreshExemptionEntries] = useState(false)

  useEffect(() => {
    getChargeExemptionEntries(selectedChargeExemption)
  }, [refreshExemptionEntries])

  const getChargeExemptionEntries = async r => {
    if (r) {
      const { success, data } = await getData(
        '/CommissionExemption/find-commission-exemption-entries-by-commission-exemption-id',
        {
          commissionExemptionId: r.id,
        }
      )
      if (success) {
        setChargeExemptionEntries(data.result || [])
      }
    }
  }

  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('commission'),
      template: r => <>{r.commissionDescription}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <div>
      <ListingPage
        onAdd={() => {
          setChargeExemptionEntries([])
          setSelectedChargeExemption(null)
          setMode('add')
        }}
        refreshAt={refreshAt}
        url="/CommissionExemption/find-commission-exemptions-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        map={[localize('registryManagement'), localize('chargesExemptions')]}
        contextMenu={r => (
          <>
            <div
              onClick={() => {
                setMode('edit')
                setSelectedChargeExemption(r)
                getChargeExemptionEntries(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('edit')}
            </div>
            <div
              onClick={() => {
                setVieMode(true)
                setSelectedChargeExemption(r)
                getChargeExemptionEntries(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('view')}
            </div>
          </>
        )}
      />
      <ChargesExemptionsModal
        mode={mode}
        onSetMode={m => {
          setMode(m)
        }}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
        onHideModal={() => {
          setMode(null)
        }}
        onUpdateRefreshExemptionEntries={r => {
          setRefreshExemptionEntries(!refreshExemptionEntries)
        }}
        setSelectedChargeExemption={setSelectedChargeExemption}
        selectedCommissionExemption={selectedChargeExemption}
        chargeExemptionEntries={chargeExemptionEntries}
        setChargeExemptionEntries={setChargeExemptionEntries}
      />
      <ViewChargesExemptions
        mode={vieMode}
        chargeExemption={selectedChargeExemption}
        onHideModal={() => {
          setVieMode(null)
        }}
        exemptionEntries={chargeExemptionEntries}
      />
    </div>
  )
}

export default ChargesExemptions
