/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { refundNotificationAuditOption } from '../../../../../Global/enumeration'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import RefundNotificationLookup from '../../../../../Components/Lookup/RefundNotificationLookup'
import localize from '../../../../../Global/Localize'
import { postData } from '../../../../../Helpers/webApi'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

const Verification = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [iRefundNotification, setIRefundNotification] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(refundNotificationAuditOption)[0]
  )

  async function verifyRefundNotification() {
    setIsBusy(true)
    const { success } = await postData(
      '/RefundNotification/audit-refund-notification',
      {
        refundNotification: iRefundNotification,
        refundNotificationAuditOption: selectedAuth,
      },
      false,
      'Operation Completed Successfully'
    )
    if (success) {
      setIsBusy(false)
      closeModal()
      //loadTableData()
    } else {
      setIsBusy(false)
    }
  }

  function closeModal() {
    setIRefundNotification(null)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('backOfficeManagement'),
          localize('refundNotifications'),
          localize('verifyRefundNotification'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2">
                  <div className="col-3 d-flex align-items-center ">
                    <label className="col-4 text-capitalize">
                      {localize('customerName')}
                    </label>
                    <RefundNotificationLookup
                      displayValue={
                        iRefundNotification?.customerIndividualFirstName
                      }
                      onSelect={d => {
                        setIRefundNotification({
                          ...iRefundNotification,
                          customerIndividualFirstName: d.customerFullName,
                          customerMembershipClassCustomerType:
                            d.customerMembershipClassCustomerTypeDescription,
                          customerMembershipStatusDescription:
                            d.customerMembershipStatusDescription,
                          customerSerialNumber: d.customerSerialNumber,
                          branchDescription: d.branchDescription,
                          customerEmployerDescription:
                            d.customerEmployerDescription,
                          customerDutyStationDescription:
                            d.customerDutyStationDescription,
                          customerAdministrativeDivisionDescription:
                            d.customerAdministrativeDivisionDescription,
                          customerIndividualIdentityCardNumber:
                            d.customerIndividualIdentityCardNumber,
                          customerMemberNumber: d.customerMemberNumber,
                          customerAccountNumber: d.customerAccountNumber,
                          customerPersonalFileNumber:
                            d.customerPersonalFileNumber,
                          refundTypeDescription: d.refundTypeDescription,
                          remarks: d.remarks,
                          debitCustomerAccountCustomerAccountNumber:
                            d.debitFullAccountNumber,
                          principal: d.principal,
                          interest: d.interest,
                          creditCustomerAccountCustomerAccountNumber:
                            d.creditFullAccountNumber,
                          createdBy: d.createdBy,
                          createdDate: d.createdDate,
                          statusDescription: d.statusDescription,
                          id: d.id,
                        })
                      }}
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('customerType')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        iRefundNotification?.membershipClassCustomerTypeDescription ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('membershipStatus')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        iRefundNotification?.customerMembershipStatusDescription ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('serialNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={iRefundNotification?.customerSerialNumber || ''}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('domicileBranch')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={iRefundNotification?.branchDescription || ''}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('employerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        iRefundNotification?.customerEmployerDescription || ''
                      }
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('duty/Workstation')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        iRefundNotification?.customerDutyStationDescription ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('adminDivision')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        iRefundNotification?.customerAdministrativeDivisionDescription ||
                        ''
                      }
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('identification#')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        iRefundNotification?.customerIndividualIdentityCardNumber ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('memberNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={iRefundNotification?.customerMemberNumber || ''}
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('accountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={iRefundNotification?.customerAccountNumber || ''}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('personalFileNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        iRefundNotification?.customerPersonalFileNumber || ''
                      }
                    />
                  </div>
                </div>

                <div className="div row mb-2">
                  <div className="col-3 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('refundType')}
                    </label>
                    <input
                      defaultValue={iRefundNotification?.refundTypeDescription}
                      className="form-control ms-4"
                      disabled
                    />
                  </div>
                  <div className="col-9 d-flex align-items-center">
                    <label className="col-1 text-capitalize me-2">
                      {localize('remarks')}
                    </label>
                    <input
                      defaultValue={iRefundNotification?.remarks}
                      className="form-control ms-4"
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('fullAccountNumber(DR)')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        iRefundNotification?.debitCustomerAccountCustomerAccountNumber ||
                        ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('principal')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        formatCurrency(iRefundNotification?.principal) || ''
                      }
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('interest')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        formatCurrency(iRefundNotification?.interest) || ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('fullAccountNumber(CR)')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      disabled
                      value={
                        iRefundNotification?.creditCustomerAccountCustomerAccountNumber ||
                        ''
                      }
                    />
                  </div>
                </div>
                <div className="div row mb-2">
                  <div className="col-3 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('createdBy')}
                    </label>
                    <input
                      defaultValue={iRefundNotification?.createdBy || ''}
                      className="form-control ms-4"
                      disabled
                    />
                  </div>
                  <div className="col-3 d-flex align-items-center">
                    <label className="col-3 text-capitalize me-3">
                      {localize('createdDate')}
                    </label>
                    <input
                      defaultValue={
                        formatDate(iRefundNotification?.createdDate) || ''
                      }
                      className="form-control ms-3"
                      disabled
                    />
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <label className="col-2 text-capitalize me-1">
                      {localize('status')}
                    </label>
                    <input
                      defaultValue={
                        iRefundNotification?.statusDescription || ''
                      }
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <div className="col-md-4 mt-2">
                      <div
                        className="d-flex align-items-center mb-1 with-validation"
                        validation-message={
                          globalValidationErrors[
                            'RefundNotification.AuditRemarks'
                          ]?.message
                        }
                      >
                        <label className="text-capitalize col-4 me-1">
                          {localize('auditRemarks')}
                        </label>
                        <input
                          defaultValue={iRefundNotification?.auditRemarks}
                          type="text"
                          className="form-control ms-1 me-2"
                          onChange={e => {
                            clearValidation('RefundNotification.AuditRemarks')
                            setIRefundNotification({
                              ...iRefundNotification,
                              auditRemarks: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <div className="d-flex align-items-center mb-2">
                        <label className="col-4 text-capitalize">
                          {localize('auditOption')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="RefundNotificationAuditOption"
                          onChange={e => setSelectedAuth(e.value)}
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        if (!iRefundNotification) {
                          return Swal.fire({
                            icon: 'info',
                            text: 'Select Refund Notification',
                            title: 'Ooops!',
                            showCloseButton: true,
                          })
                        } else {
                          verifyRefundNotification()
                        }
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Verification
