/** @format */

import Cleave from 'cleave.js/react'
/* eslint-disable */
import React from 'react'

export const MaskPercentage = ({
  onMaskChange,
  disabled,
  defaultMaskValue,
  numeral = true,
  numeralThousandsGroupStyle = 'none',
  numeralDecimalScale = 4,
}) => {
  return (
    <>
      <Cleave
        className="form-control text-end"
        value={defaultMaskValue}
        disabled={disabled}
        options={{
          numeralThousandsGroupStyle,
          numeral,
          numeralDecimalScale,
        }}
        onChange={onMaskChange}
      />
      <button className="input-group-text text-white bg-primary">%</button>
    </>
  )
}
