/** @format */

import React from 'react'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../Helpers/formatters'

const FixedDepositTypeTiersLookup = ({
  onSelect,
  displayValue,
  fixedDepositTypeId,
  fixedDepositTypeDescription,
}) => {
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('fixedDepositTypeTiersLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{fixedDepositTypeDescription}</>
        </>
      }
      url="/lookup/FixedDepositType/find-graduated-scales-by-fixed-deposit-type-id"
      params={{
        fixedDepositTypeId,
      }}
      conditionalField="fixedDepositTypeId"
      columns={[
        {
          label: localize('range(lower)'),
          template: r => <>{formatCurrency(r.rangeLowerLimit)}</>,
        },
        {
          label: localize('range(Upper)'),
          template: r => <>{formatCurrency(r.rangeUpperLimit)}</>,
        },
        {
          label: localize('percentage'),
          template: r => <>{formatAsPercent(r.percentage)}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
      isList={true}
    />
  )
}

export default FixedDepositTypeTiersLookup
