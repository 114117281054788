/** @format */

import React from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'

const EmployeeTypeInvestmentProducts = ({
  disabled,
  isBusy,
  selectedEmployeeType,
  setSelectedEmployeeType,
  prefix,
}) => {
  const handleCheckEmployeeTypeInvestmentProducts = (
    investmentProducts,
    checkValue
  ) => {
    const com = [...selectedEmployeeType?.investmentProductCollection].map(
      comm => {
        if (comm.id === investmentProducts.id) {
          return { ...comm, checked: checkValue }
        }
        return comm
      }
    )

    setSelectedEmployeeType({
      ...selectedEmployeeType,
      investmentProductCollection: com,
    })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedEmployeeType?.investmentProductCollection.map(
            investmentProducts => (
              <li
                key={investmentProducts.id}
                style={{ listStyle: 'none' }}
                className=" d-flex align-items-center"
              >
                <Checkbox
                  id={`${prefix ? prefix : 'com'}-${investmentProducts.id}`}
                  checked={investmentProducts.checked}
                  disabled={disabled}
                  margined
                  onCheck={c => {
                    handleCheckEmployeeTypeInvestmentProducts(
                      investmentProducts,
                      c
                    )
                  }}
                />
                <label
                  htmlFor={`${prefix ? prefix : 'com'}-${
                    investmentProducts.id
                  }`}
                  className={`fw-normal ms-2 cursor-pointer ${
                    investmentProducts.checked ? 'text-primary fw-bold' : ''
                  }`}
                >
                  {investmentProducts.description}
                </label>
              </li>
            )
          )}
        </div>
      )}
    </div>
  )
}

export default EmployeeTypeInvestmentProducts
