/** @format */

import localize from '../../../../../../Global/Localize'
import { DataAttachmentTransactionType } from '../../../../../../Global/enumeration'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
/* eslint-disable */
import React, { useState } from 'react'
import InvestmentProductLookup from '../../../../../../Components/Lookup/InvestmentProductLookup'
import { EnrollmentRequestLookup } from '../../../../../../Components/Lookup/EnrollmentRequestLookup'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const ProspectiveCustomerDataEntryDetails = ({
  initializeDataAttachmentEntryModel,
  setInitializeDataAttachmentEntryModel,
  onSelectDataAttachmentTransactionType,
}) => {
  const [selectedEnrollmentRequest, setSelectedEnrollmentRequest] =
    useState(null)

  const [selectedInvestmentProduct, setSelectedInvestmentProduct] =
    useState(null)

  const handleOnSelectEnrollmentRequest = enrollmentRequest => {
    setSelectedEnrollmentRequest(enrollmentRequest)
    setInitializeDataAttachmentEntryModel({
      ...initializeDataAttachmentEntryModel,
      enrollmentRequestId: enrollmentRequest.id,
    })
  }

  const handleOnSelectInvestmentProduct = investmentProduct => {
    setSelectedInvestmentProduct(investmentProduct)
    setInitializeDataAttachmentEntryModel({
      ...initializeDataAttachmentEntryModel,
      investmentProductId: investmentProduct.id,
    })
  }

  const [
    selectedDataAttachmentTransactionType,
    setSelectedDataAttachmentTransactionType,
  ] = useState(Object.keys(DataAttachmentTransactionType)[0])

  const [focusedInputs, setFocusedInputs] = useState({
    newAbility: true,
    currentBalance: true,
    currentAmount: true,
    newBalance: true,
  })

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerName')}
          </label>
          <EnrollmentRequestLookup
            onSelect={handleOnSelectEnrollmentRequest}
            displayValue={selectedEnrollmentRequest?.fullName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerType')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              selectedEnrollmentRequest?.membershipClassCustomerTypeDescription
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('pin')}</label>
          <input
            className="form-control"
            disabled
            value={selectedEnrollmentRequest?.personalIdentificationNumber}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('electoralZone')}
          </label>
          <input
            className="form-control"
            value={selectedEnrollmentRequest?.electoralZoneDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('employerName')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedEnrollmentRequest?.employerDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('dutyWorkStation')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedEnrollmentRequest?.dutyStationDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('administrativeDivision')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedEnrollmentRequest?.administrativeDivisionDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('identificationNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedEnrollmentRequest?.identificationNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('origin')}</label>
          <input
            className="form-control"
            disabled
            value={selectedEnrollmentRequest?.originDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('originIdentifier')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={selectedEnrollmentRequest?.originIdentifier}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={selectedEnrollmentRequest?.personalFileNumber}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('investmentProduct')}
          </label>
          <InvestmentProductLookup
            displayValue={selectedInvestmentProduct?.description}
            onSelect={handleOnSelectInvestmentProduct}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('productSection')}
          </label>
          <input
            disabled
            value={selectedInvestmentProduct?.productSectionDescription}
            className="form-control"
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('chartOfAccountName')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedInvestmentProduct?.chartOfAccountName}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('checkoffEntryType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedInvestmentProduct?.checkoffEntryTypeDescription}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('transactionType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="DataAttachmentTransactionType"
            value={selectedDataAttachmentTransactionType}
            onChange={e => {
              setSelectedDataAttachmentTransactionType(e.value)
              onSelectDataAttachmentTransactionType(e.value)
            }}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('sequenceNumber')}
          </label>
          <input
            type="number"
            className="form-control"
            onChange={e => {
              setInitializeDataAttachmentEntryModel({
                ...initializeDataAttachmentEntryModel,
                sequenceNumber: e.target.value,
              })
            }}
            sort
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            defaultValue={initializeDataAttachmentEntryModel?.reference}
            onChange={e => {
              setInitializeDataAttachmentEntryModel({
                ...initializeDataAttachmentEntryModel,
                reference: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            defaultValue={initializeDataAttachmentEntryModel?.remarks}
            onChange={e => {
              setInitializeDataAttachmentEntryModel({
                ...initializeDataAttachmentEntryModel,
                remarks: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('currentAmount')}
          </label>
          {focusedInputs.currentAmount ? (
            <input
              onFocus={() => {
                setFocusedInputs({
                  ...focusedInputs,
                  currentAmount: false,
                })
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  currentAmount: 0,
                })
              }}
              value={formatCurrency(
                initializeDataAttachmentEntryModel?.currentAmount,
                2,
                2,
                false
              )}
              className="form-control"
            />
          ) : (
            <MaskNumber
              onMaskNumber={e => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  currentAmount: e.target.value,
                })
              }}
              defaultMaskValue={
                initializeDataAttachmentEntryModel?.currentAmount
              }
            />
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('currentBalance')}
          </label>
          {focusedInputs.currentBalance ? (
            <input
              onFocus={() => {
                setFocusedInputs({
                  ...focusedInputs,
                  currentBalance: false,
                })
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  currentBalance: 0,
                })
              }}
              className={'form-control text-end'}
              value={formatCurrency(
                initializeDataAttachmentEntryModel?.currentBalance,
                2,
                2,
                false
              )}
            />
          ) : (
            <MaskNumber
              defaultMaskValue={
                initializeDataAttachmentEntryModel?.currentBalance
              }
              onMaskNumber={c => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  currentBalance: c.target.value,
                })
              }}
            />
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('currentAbility')}
          </label>
          <MaskNumber
            onMaskNumber={e => {
              setInitializeDataAttachmentEntryModel({
                ...initializeDataAttachmentEntryModel,
                currentAbility: e.target.value,
              })
            }}
            defaultMaskValue={
              initializeDataAttachmentEntryModel?.currentAbility
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('newAmount')}
          </label>
          <MaskNumber
            onMaskNumber={e => {
              setInitializeDataAttachmentEntryModel({
                ...initializeDataAttachmentEntryModel,
                newAmount: e.target.value,
              })
            }}
            defaultMaskValue={initializeDataAttachmentEntryModel?.newAmount}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('newBalance')}
          </label>
          {focusedInputs.newBalance ? (
            <input
              className="form-control text-end"
              value={formatCurrency(
                initializeDataAttachmentEntryModel?.newBalance,
                2,
                2,
                false
              )}
              onFocus={e => {
                setFocusedInputs({
                  ...focusedInputs,
                  newBalance: false,
                })
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  newBalance: 0,
                })
              }}
            />
          ) : (
            <MaskNumber
              onMaskNumber={e => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  newBalance: e.target.value,
                })
              }}
              defaultMaskValue={initializeDataAttachmentEntryModel?.newBalance}
            />
          )}
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('newAbility')}
          </label>
          {focusedInputs.newAbility ? (
            <input
              className="form-control text-end"
              value={formatCurrency(
                initializeDataAttachmentEntryModel?.newAbility,
                2,
                2,
                false
              )}
              onFocus={e => {
                setFocusedInputs({
                  ...focusedInputs,
                  newAbility: false,
                })
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  newAbility: 0,
                })
              }}
            />
          ) : (
            <MaskNumber
              onMaskNumber={r => {
                setInitializeDataAttachmentEntryModel({
                  ...initializeDataAttachmentEntryModel,
                  newAbility: r.target.value,
                })
              }}
              defaultMaskValue={initializeDataAttachmentEntryModel?.newAbility}
            />
          )}
        </div>
      </div>
    </>
  )
}
