/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import AuditLogs from '../../../../../../Components/ReUsables/AuditLogs/AuditLogs'

const ViewAuditLogs = ({ mode, handleClose, data }) => {
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        handleClose={handleClose}
        cancelText="close"
        modalTitle={`${data?.transactionCodeDescription} : ${data?.primaryDescription} -> ${data?.totalValueFormatted}`}
        hideUpdate
        modalClassName="modal-xl"
      >
        <AuditLogs recordId={data?.id} />
      </ModalTemplate>
    </>
  )
}

export default ViewAuditLogs
