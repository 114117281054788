/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../Helpers/webApi'
import SimpleTable from '../../Tables/SimpleTable'
import Loader from '../../Loaders/Loader'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'

const CustomerAccountHistory = ({ customerAccount }) => {
  const [accountHistories, setAccountHistories] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    {
      label: localize('operation'),
      template: r => r.managementActionDescription,
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
    {
      label: localize('reference'),
      template: r => r.reference,
    },
    {
      label: localize('applicationUserName'),
      template: r => r.applicationUserName,
    },
    {
      label: localize('environmentUserName'),
      template: r => r.environmentUserName,
    },
    {
      label: localize('environmentMachineName'),
      template: r => r.environmentMachineName,
    },
    {
      label: localize('environmentDomainName'),
      template: r => r.environmentDomainName,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => r.environmentOSVersion,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => r.environmentMACAddress,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => r.environmentMotherboardSerialNumber,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => r.environmentProcessorId,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => r.environmentIPAddress,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  console.log(customerAccount)
  const fetchCustomerAccountHistory = async () => {
    setIsBusy(true)

    const url =
      '/CustomerAccount/find-customer-account-history-by-customer-account-id'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: customerAccount.id,
      },
      false
    )

    if (success) {
      setAccountHistories(data?.result)
      setIsBusy(false)
    } else setIsBusy(false)
  }

  useEffect(() => {
    if (customerAccount?.id) fetchCustomerAccountHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAccount?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <SimpleTable columns={columns} data={accountHistories} />
      )}
    </>
  )
}

export default CustomerAccountHistory
