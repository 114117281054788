/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import { getData } from '../../../Helpers/webApi'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import Pager from '../../../Components/Tables/Pager'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import Tab from '../../../Components/Tabs/Tab'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import EditInvestmentProducts from './EditInvestmentProducts'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import NonBlockingLoader from '../../../Components/Loaders/NonBlockingLoader'

function Investments() {
  const invProductsColumns = [
    { label: localize('code'), template: r => <>{r?.paddedCode}</> },
    {
      label: localize('billReferenceNumber'),
      template: r => <>{r?.billReferenceNumber}</>,
    },
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('minimumBalance'),
      template: r => <>{formatCurrency(r?.minimumBalance)}</>,
    },
    {
      label: localize('maximumBalance'),
      template: r => <>{formatCurrency(r?.maximumBalance)}</>,
    },
    {
      label: localize('annualPercentageYield'),
      template: r => <>{r?.annualPercentageYield}%</>,
    },
    {
      label: localize('gLAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('GLAccountCostDistributionRule'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('checkoffEntryType'),
      template: r => <>{r?.checkoffEntryTypeDescription}</>,
    },
    {
      label: localize('poolGLAccountName'),
      template: r => <>{r?.poolChartOfAccountName}</>,
    },
    {
      label: localize('poolGLAccountDistributionRule'),
      template: r => (
        <>{r?.poolChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('interestExpenseGLAccountName'),
      template: r => <>{r?.interestExpenseChartOfAccountName}</>,
    },
    {
      label: localize('interestExpenseGLAccountDistributionRule'),
      template: r => (
        <>{r?.interestExpenseChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('interesPayableGLAccountName'),
      template: r => <>{r?.interestPayableChartOfAccountName}</>,
    },
    {
      label: localize('interestPayableGLAccountDistributionRule'),
      template: r => (
        <>{r?.interestPayableChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('poolAmount'),
      template: r => <>{formatCurrency(r?.poolAmount)}</>,
    },
    {
      label: `${localize('maturityPeriod')} (${localize('days')})`,
      template: r => <>{r?.maturityPeriod}</>,
    },
    {
      label: localize('recoveryPriority'),
      template: r => <>{r?.recoveryPriority}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r?.createdDate}</> },
  ]

  // const exColumns = [
  //   {
  //     label: localize('membershipClass'),
  //     template: r => <>{r?.membershipClassDescription}</>,
  //   },
  //   {
  //     label: localize('maximumBalance'),
  //     template: r => <>{formatCurrency(r?.maximumBalance)}</>,
  //   },
  //   {
  //     label: localize('appraisalMultiplier'),
  //     template: r => <>{r?.appraisalMultiplier}</>,
  //   },
  //   { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
  //   {
  //     label: localize('modifiedDate'),
  //     template: r => <>{formatDate(r?.modifiedDate)}</>,
  //   },
  //   { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  //   {
  //     label: localize('createdDate'),
  //     template: r => <>{formatDate(r?.createdDate)}</>,
  //   },
  // ]

  const [search, setSearch] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [invProducts, setInvProducts] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    productSection: 1,
  })
  const [refresh, setRefresh] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  // useEffect(() => {
  //   // loadExemptions()
  //   //eslint-disable-next-line
  // }, [selectedProduct, reqParams, refresh])

  // async function loadExemptions() {
  //   setIsBusy(true)
  //   setExWaiting(true)
  //   const { success, data } = await getData(
  //     '/investmentProduct/find-investment-product-exemptions-by-investment-product-id',
  //     { investmentProductId: selectedProduct?.id },
  //     false
  //   )
  //   if (success) {
  //     setExWaiting(false)
  //     setExemptions(data?.result)
  //   } else {
  //     setExWaiting(false)
  //   }

  //   setIsBusy(false)
  // }

  // const tabItems = [
  //   {
  //     label: localize('exemptions'),
  //     item: (
  //       <>
  //         <SimpleTable
  //           loading={exWaiting}
  //           data={exemptions}
  //           columns={exColumns}
  //         />
  //       </>
  //     ),
  //   },
  //   {
  //     label: localize('flags'),
  //     item: (
  //       <div className="row">
  //         <div className="col">
  //           <div className="d-flex align-items-center gap-2">
  //             <Checkbox checked={selectedProduct?.isRefundable} disabled />
  //             <label className="text-capitalize">
  //               {localize('isRefundable')}?
  //             </label>
  //           </div>
  //           <div className="d-flex align-items-center gap-2 mt-2">
  //             <Checkbox
  //               checked={selectedProduct?.isInstitutionalCapital}
  //               disabled
  //             />
  //             <label className="text-capitalize">
  //               {localize('isInstitutionalCapital')}?
  //             </label>
  //           </div>
  //         </div>
  //         <div className="col">
  //           <div className="d-flex align-items-center gap-2">
  //             <Checkbox checked={selectedProduct?.isPooled} disabled />
  //             <label className="text-capitalize">{localize('isPooled')}?</label>
  //           </div>
  //           <div className="d-flex align-items-center gap-2 mt-2">
  //             <Checkbox checked={selectedProduct?.isLocked} disabled />
  //             <label className="text-capitalize">{localize('isLocked')}?</label>
  //           </div>
  //         </div>
  //         <div className="col">
  //           <div className="d-flex align-items-center gap-2">
  //             <Checkbox checked={selectedProduct?.isSuperSaver} disabled />
  //             <label className="text-capitalize">
  //               {localize('isSuperSaver')}?
  //             </label>
  //           </div>
  //           <div className="d-flex align-items-center gap-2 mt-2">
  //             <Checkbox
  //               checked={selectedProduct?.concealBankToMobileListing}
  //               disabled
  //             />
  //             <label className="text-capitalize">
  //               {localize('concealMBankingListing')}?
  //             </label>
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //   },
  // ]

  useEffect(() => {
    loadInvestmentProducts()
    // eslint-disable-next-line
  }, [reqParams])

  useEffect(() => {
    setSelectedProduct(null)
  }, [reqParams])

  async function loadInvestmentProducts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/investmentProduct/find-investment-product-by-product-section-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setInvProducts(data)
    }

    setIsBusy(false)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalMode('auditLogs')
  }

  const handleCloseModal = () => {
    setModalMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('financialProducts'),
          localize('investmentProducts'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white" style={{ position: 'relative' }}>
          <div className="col mb-3">
            <AddNewButton
              handleClickAddButton={() => {
                setModalMode('add')
                setSelectedProduct('null')
              }}
            />
          </div>
          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <div className="ms-2" style={{ minWidth: 'fit-content' }}>
                <RecordsPerPage
                  className="col-3"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              <div className="d-flex align-items-center gap-2 my-2">
                <label
                  htmlFor=""
                  className="text-capitalize"
                  style={{ minWidth: 'fit-content' }}
                >
                  {localize('productSection')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ProductSection"
                  value={reqParams?.productSection}
                  onChange={e =>
                    setReqParams({ ...reqParams, productSection: e.value })
                  }
                />
              </div>
              <form
                className="d-flex align-items-center col-3 col text-capitalize"
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({ ...reqParams, filterText: search })
                }}
              >
                <label htmlFor="searchTable">{localize('search')}</label>

                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                ></input>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </form>
            </div>
            <div className="p-2">
              <SimpleTable
                columns={invProductsColumns}
                loading={isBusy}
                data={invProducts?.pageCollection}
                // canClickOnce
                // onClickOnce={r => setSelectedProduct(r)}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedProduct(r)
                        setModalMode('view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span className="text-capitalize">
                        {localize('view')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedProduct(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span className="text-capitalize">
                        {localize('edit')}
                      </span>
                    </div>

                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleViewAuditLogs(r)
                      }}
                    >
                      <i className="uil uil-server"></i>
                      <span>{localize('viewAuditLog')}</span>
                    </div>
                  </>
                )}
              />
              <div className="mt-2"></div>

              <Pager
                itemsCount={invProducts?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
                pageBatchSize={5}
              />
            </div>
            {isBusy && <NonBlockingLoader />}

            {/* {selectedProduct && (
                  <div className="mt-0">
                    <Tab tabItems={tabItems} />
                  </div>
                )} */}
          </div>
        </div>
      </div>
      {modalMode && (
        <EditInvestmentProducts
          modalMode={modalMode}
          readonly={modalMode === 'view'}
          editMode={modalMode === 'edit'}
          product={selectedProduct}
          close={handleCloseModal}
          refresh={() => setRefresh(!refresh)}
        />
      )}
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={modalMode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}

export default Investments
