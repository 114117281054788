/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './walkthrough.scss'
function Walkthrough({ pages, activePage, changePage, noActions, preload }) {
  const [skippable, setSkippable] = useState(false)

  useEffect(() => {
    let s = pages[activePage].skippable
    setSkippable(s)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pages])

  return preload ? (
    <div className="wizard clear-fix">
      <div className="steps clear-fix">
        <ul>
          {pages.map((p, i) => (
            <li
              className={activePage === i ? 'nav-item current' : 'nav-item'}
              onClick={() => changePage(i, p)}
              style={{
                cursor: 'pointer',
                pointerEvents: skippable ? 'all' : 'none',
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={`d-flex align-items-center ${
                  activePage === i ? 'nav-link current' : 'nav-link'
                }`}
              >
                <span className="number">{i + 1}</span>
                <div className="text-capitalize" style={{ fontSize: 12 }}>
                  {p.title}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>

      {pages.map((p, i) => (
        <div
          className="content clear-fix"
          style={activePage === i ? {} : { display: 'none' }}
        >
          {p.component}
        </div>
      ))}
    </div>
  ) : (
    <div className="wizard clear-fix">
      <div className="steps clear-fix">
        <ul>
          {pages.map((p, i) => (
            <li
              className={activePage === i ? 'nav-item current' : 'nav-item'}
              onClick={() => changePage(i, p)}
              style={{
                cursor: 'pointer',
                pointerEvents: skippable ? 'all' : 'none',
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={`d-flex align-items-center ${
                  activePage === i ? 'nav-link current' : 'nav-link'
                }`}
              >
                <span className="number">{i + 1}</span>
                <div
                  className="text-capitalize"
                  style={{ fontSize: 12, textTransform: 'capitalize' }}
                >
                  {p.title}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="content clear-fix">{pages[activePage].component}</div>
      {noActions || (
        <div className="actions clear-fix">{pages[activePage].actions}</div>
      )}
    </div>
  )
}

export default Walkthrough
