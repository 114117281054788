/** @format */

import Dashboard from '../../Pages/Dashboard/Dashboard'

export const dashboardMenu = {
  label: 'dashboard',
  component: <Dashboard />,
  code: 0,
  setup: [],
  operations: [],
  hasOpenModal: false,
  parentCode: 0
}
