/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { getData, postData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Tab from '../../../../../Components/Tabs/Tab'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Swal from 'sweetalert2'
import { pettyCashierTransactionType } from '../../../../../Global/enumeration'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import Commissions from '../../../../../Components/Selectables/Plain/Commissions'
import {
  entitiesToBeChecked,
  getCommissions,
} from '../../../../../Helpers/selectablesHelper'
import PettyCashPaymentLookup from '../../../../../Components/Lookup/PettyCashPaymentLookup'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatCurrency } from '../../../../../Helpers/formatters'
import PettyCashierAccountStatement from '../partials/PettyCashierAccountStatement'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'
import Loader from '../../../../../Components/Loaders/Loader'

const SundryPayments = () => {
  const [transactionType, setTransactionType] = useState({
    transactionType: Object.keys(pettyCashierTransactionType)[0],
  })
  const [iPettyCashRequisition, setIPettyCashRequisition] = useState(null)

  const [iPettyCashNewRequestPayment, setIPettyCashNewRequestPayment] =
    useState(null)
  const [
    iPettyCashVerifiedRequestPayment,
    setIPettyCashVerifiedRequestPayment,
  ] = useState(null)
  const [
    iPettyCashRequestTransactionModal,
    setIPettyCashRequestTransactionModal,
  ] = useState(null)

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedCommissions, setSelectedCommissions] = useState([])
  const [loading, setLoading] = useState({
    commissions: false,
  })
  const [commissionsToBeSelected, setCommissionsToBeSelected] = useState([])
  const [tableData, setTableData] = useState([])
  const [pettyCashierChartOfAccountId, setPettyCashierChartOfAccountId] =
    useState(null)
  const [loader, setLoader] = useState(false)

  const columns = [
    {
      label: localize('type'),
      template: r => <>{r.roundingTypeDescription}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.maximumCharge)}</>,
    },
    {
      label: localize('borneBy'),
      template: r => <>{r.chargeBenefactorDescription}</>,
    },
  ]

  useEffect(() => {
    commissionsToBeSelectedd()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPettyCashier = async r => {
    const { success, data } = await getData(
      `/PettyCashier/find-petty-cashier?pettyCashierId=${
        r.pettyCashierId
      }&includeBalance=${true}`,
      false
    )
    if (success) {
      setPettyCashierChartOfAccountId(data.chartOfAccountId)
    }
  }

  async function updatePettyCashRequisition() {
    setLoader(true)
    const { success, data } = await postData(
      '/PettyCashRequisition/add-petty-cash-requisition',
      iPettyCashRequisition,
      false
    )

    if (success) {
      Swal.fire({
        icon: 'success',
        title: 'Operation Completed Successfully',
        html: `Document Reference Number : ${data.documentReferenceNumber}`,
      })
      setIPettyCashRequisition({
        transactionType: Object.keys(pettyCashierTransactionType)[2],
      })
      setGlobalValidationErrors({})
      setLoader(false)
    }
    setLoader(false)
  }

  async function updatePettyNewCashRequest() {
    setLoader(true)
    const { success, data } = await postData(
      '/PettyCashRequest/add-petty-cash-request',
      {
        pettyCashRequest: { ...iPettyCashNewRequestPayment },
        commissions: selectedCommissions,
      },
      false
    )

    if (success) {
      Swal.fire({
        icon: 'success',
        title: 'Operation Completed Successfully',
        html: `Document Reference Number : ${data.documentReferenceNumber}`,
      })
      setIPettyCashRequisition({
        transactionType: Object.keys(pettyCashierTransactionType)[2],
      })
      setIPettyCashNewRequestPayment(null)
      setGlobalValidationErrors({})
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  async function updatePettyAuthorizedCashRequest() {
    setLoader(true)
    const { success, data } = await postData(
      '/PettyCashRequest/pay-sundry-petty-cash-request',
      {
        pettyCashRequest: { ...iPettyCashVerifiedRequestPayment },
        transactionModel: iPettyCashRequestTransactionModal,
        tariffs: tableData,
      },
      false
    )

    if (success) {
      Swal.fire({
        icon: 'success',
        title: 'Operation Completed Successfully',
        html: ``,
      })
      setIPettyCashRequisition({
        transactionType: Object.keys(pettyCashierTransactionType)[2],
      })
      setGlobalValidationErrors({})
      setIPettyCashVerifiedRequestPayment(null)
      setIPettyCashRequestTransactionModal(null)
      setLoader(false)
    }
    setLoader(false)
  }

  const commissionsToBeSelectedd = async () => {
    setLoading({ ...loading, commissions: true })
    try {
      const commissions = await getCommissions()

      setCommissionsToBeSelected(entitiesToBeChecked(commissions || [], []))
      setLoading({ ...loading, commissions: true })
    } catch (error) {
      setLoading({ ...loading, commissions: false })
    }
  }

  const commissionsByPettyCashId = async r => {
    const { success, data } = await getData(
      `/PettyCashRequest/find-commissions-by-petty-cash-request-id?pettyCashRequestId=${r.id}`,
      false
    )
    if (success) {
      setTableData(data.result || [])
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('pettyCashSundryPayments'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {loader ? (
              <Loader />
            ) : (
              <>
                <div className="mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('transaction'),
                        item: (
                          <>
                            <div className="row mb-2">
                              <div className="col d-flex align-items-center mb-2">
                                <label className="col-1 text-capitalize ms-1">
                                  {localize('transactionType')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ width: '100%' }}
                                  value={transactionType?.transactionType}
                                  enumsKey={'PettyCashierTransactionType'}
                                  onChange={e => {
                                    setTransactionType({
                                      transactionType: e.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                            {transactionType?.transactionType == 4 ? (
                              <div className="mt-3">
                                <Tab
                                  tabItems={[
                                    {
                                      label: localize('pettyCashRequisition'),
                                      item: (
                                        <>
                                          <div className="row mb-2">
                                            <div
                                              className="col d-flex align-items-center with-validation"
                                              validation-message={
                                                globalValidationErrors['Amount']
                                                  ?.message
                                              }
                                            >
                                              <label className="col-1 text-capitalize">
                                                {localize('amount')}
                                              </label>
                                              <MaskNumber
                                                className="form-control"
                                                defaultMaskValue={
                                                  iPettyCashRequisition?.amount ||
                                                  0
                                                }
                                                onMaskNumber={e => {
                                                  clearValidation('Amount')
                                                  setIPettyCashRequisition({
                                                    ...iPettyCashRequisition,
                                                    amount: e.target.value,
                                                  })
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row mb-2">
                                            <div
                                              className="col d-flex align-items-center with-validation"
                                              validation-message={
                                                globalValidationErrors[
                                                  'PrimaryDescription'
                                                ]?.message
                                              }
                                            >
                                              <label className="col-1 text-capitalize">
                                                {localize('payee')}
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                  iPettyCashRequisition?.primaryDescription ||
                                                  ''
                                                }
                                                onChange={e => {
                                                  clearValidation(
                                                    'PrimaryDescription'
                                                  )
                                                  setIPettyCashRequisition({
                                                    ...iPettyCashRequisition,
                                                    primaryDescription:
                                                      e.target.value,
                                                  })
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row mb-2">
                                            <div className="col d-flex align-items-center">
                                              <label className="col-1 text-capitalize">
                                                {localize('narration')}
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                  iPettyCashRequisition?.secondaryDescription ||
                                                  ''
                                                }
                                                onChange={e => {
                                                  setIPettyCashRequisition({
                                                    ...iPettyCashRequisition,
                                                    secondaryDescription:
                                                      e.target.value,
                                                  })
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row mb-2">
                                            <div
                                              className="col d-flex align-items-center with-validation"
                                              validation-message={
                                                globalValidationErrors[
                                                  'Reference'
                                                ]?.message
                                              }
                                            >
                                              <label className="col-1 text-capitalize">
                                                {localize('reference')}
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                  iPettyCashRequisition?.reference ||
                                                  ''
                                                }
                                                onChange={e => {
                                                  clearValidation('Reference')
                                                  setIPettyCashRequisition({
                                                    ...iPettyCashRequisition,
                                                    reference: e.target.value,
                                                  })
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            ) : transactionType?.transactionType == 1 ? (
                              <>
                                <div className="mt-3">
                                  <Tab
                                    tabItems={[
                                      {
                                        label: localize('cashPayment'),
                                        item: (
                                          <>
                                            <div className="row mb-2">
                                              <div
                                                className="col d-flex align-items-center with-validation"
                                                validation-message={
                                                  globalValidationErrors[
                                                    'PettyCashRequest.ChartOfAccountId'
                                                  ]?.message
                                                }
                                              >
                                                <label className="col-2 text-capitalize">
                                                  {localize('chartOfAccount')}
                                                </label>
                                                <ChartOfAccountsLookup
                                                  displayValue={
                                                    iPettyCashNewRequestPayment?.chartOfAccountName
                                                  }
                                                  onSelect={r => {
                                                    clearValidation(
                                                      'PettyCashRequest.ChartOfAccountId'
                                                    )
                                                    setIPettyCashNewRequestPayment(
                                                      {
                                                        ...iPettyCashNewRequestPayment,
                                                        chartOfAccountId: r.id,
                                                        chartOfAccountName:
                                                          r.description,
                                                        chartOfAccountAccountTypeDescription:
                                                          r.typeDescription,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </div>
                                              <div className="col d-flex align-items-center">
                                                <label className="col-2 text-capitalize">
                                                  {localize('accountType')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashNewRequestPayment?.chartOfAccountAccountTypeDescription ||
                                                    ''
                                                  }
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div
                                                className="col d-flex align-items-center with-validation"
                                                validation-message={
                                                  globalValidationErrors[
                                                    'PettyCashRequest.Amount'
                                                  ]?.message
                                                }
                                              >
                                                <label className="col-1 text-capitalize">
                                                  {localize('amount')}
                                                </label>
                                                <MaskNumber
                                                  className="form-control"
                                                  defaultMaskValue={
                                                    iPettyCashNewRequestPayment?.amount ||
                                                    0
                                                  }
                                                  onMaskNumber={e => {
                                                    clearValidation(
                                                      'PettyCashRequest.Amount'
                                                    )
                                                    setIPettyCashNewRequestPayment(
                                                      {
                                                        ...iPettyCashNewRequestPayment,
                                                        amount: e.target.value,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div
                                                className="col d-flex align-items-center with-validation"
                                                validation-message={
                                                  globalValidationErrors[
                                                    'PettyCashRequest.PrimaryDescription'
                                                  ]?.message
                                                }
                                              >
                                                <label className="col-1 text-capitalize">
                                                  {localize('payee')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashNewRequestPayment?.primaryDescription ||
                                                    ''
                                                  }
                                                  onChange={e => {
                                                    clearValidation(
                                                      'PettyCashRequest.PrimaryDescription'
                                                    )
                                                    setIPettyCashNewRequestPayment(
                                                      {
                                                        ...iPettyCashNewRequestPayment,
                                                        primaryDescription:
                                                          e.target.value,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div className="col d-flex align-items-center">
                                                <label className="col-1 text-capitalize">
                                                  {localize('narration')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashNewRequestPayment?.secondaryDescription ||
                                                    ''
                                                  }
                                                  onChange={e => {
                                                    setIPettyCashNewRequestPayment(
                                                      {
                                                        ...iPettyCashNewRequestPayment,
                                                        secondaryDescription:
                                                          e.target.value,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div
                                                className="col d-flex align-items-center with-validation"
                                                validation-message={
                                                  globalValidationErrors[
                                                    'PettyCashRequest.Reference'
                                                  ]?.message
                                                }
                                              >
                                                <label className="col-1 text-capitalize">
                                                  {localize('reference')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashNewRequestPayment?.reference ||
                                                    ''
                                                  }
                                                  onChange={e => {
                                                    clearValidation(
                                                      'PettyCashRequest.Reference'
                                                    )
                                                    setIPettyCashNewRequestPayment(
                                                      {
                                                        ...iPettyCashNewRequestPayment,
                                                        reference:
                                                          e.target.value,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>

                                <div className="mt-3">
                                  <Tab
                                    tabItems={[
                                      {
                                        label: localize('applicableCharges'),
                                        item: (
                                          <Commissions
                                            prefix={
                                              'sundry-payment-create-commission'
                                            }
                                            onCheckCommissions={r => {
                                              setCommissionsToBeSelected(r)
                                              setSelectedCommissions(
                                                r.filter(x => x.checked)
                                              )
                                            }}
                                            commissionsToBeSelected={
                                              commissionsToBeSelected
                                            }
                                          />
                                        ),
                                      },
                                    ]}
                                  />
                                </div>
                              </>
                            ) : transactionType?.transactionType == 2 ? (
                              <>
                                <div className="mt-3">
                                  <Tab
                                    tabItems={[
                                      {
                                        label: localize('cashPayment'),
                                        item: (
                                          <>
                                            <div className="row mb-2">
                                              <div
                                                className="col d-flex align-items-center with-validation"
                                                validation-message={
                                                  globalValidationErrors[
                                                    'PettyCashRequest.DocumentReferenceNumber'
                                                  ]?.message
                                                }
                                              >
                                                <label className="col-3 text-capitalize">
                                                  {localize('voucherNumber')}
                                                </label>
                                                <PettyCashPaymentLookup
                                                  displayValue={
                                                    iPettyCashVerifiedRequestPayment?.documentReferenceNumber
                                                  }
                                                  onSelect={r => {
                                                    clearValidation(
                                                      'PettyCashRequest.DocumentReferenceNumber'
                                                    )
                                                    setIPettyCashVerifiedRequestPayment(
                                                      {
                                                        ...iPettyCashVerifiedRequestPayment,
                                                        id: r.id,
                                                        documentReferenceNumber:
                                                          r.documentReferenceNumber,
                                                        chartOfAccountAccountTypeDescription:
                                                          r.chartOfAccountAccountTypeDescription,
                                                        chartOfAccountName:
                                                          r.chartOfAccountName,
                                                        status: r.status,
                                                      }
                                                    )
                                                    commissionsByPettyCashId(r)
                                                    getPettyCashier(r)
                                                    setIPettyCashRequestTransactionModal(
                                                      {
                                                        totalValue: r.amount,
                                                        primaryDescription:
                                                          r.primaryDescription,
                                                        secondaryDescription:
                                                          r.secondaryDescription,
                                                        reference: r.reference,
                                                        moduleNavigationItemCode: 2511,
                                                        transactionCode: 118,
                                                        debitChartOfAccountId:
                                                          r.chartOfAccountId,
                                                      }
                                                    )
                                                  }}
                                                />
                                              </div>
                                              <div className="col d-flex align-items-center">
                                                <label className="col-4 text-capitalize">
                                                  {localize('chartOfAccount')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashVerifiedRequestPayment?.chartOfAccountName ||
                                                    ''
                                                  }
                                                  disabled
                                                />
                                              </div>
                                              <div className="col d-flex align-items-center">
                                                <label className="col-4 text-capitalize">
                                                  {localize('accountType')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashVerifiedRequestPayment?.chartOfAccountAccountTypeDescription ||
                                                    ''
                                                  }
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div className="col d-flex align-items-center">
                                                <label className="col-1 text-capitalize">
                                                  {localize('amount')}
                                                </label>
                                                <MaskNumber
                                                  className="form-control"
                                                  defaultMaskValue={
                                                    iPettyCashRequestTransactionModal?.totalValue
                                                  }
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div className="col d-flex align-items-center">
                                                <label className="col-1 text-capitalize">
                                                  {localize('netPayable')}
                                                </label>
                                                <MaskNumber
                                                  className="form-control"
                                                  defaultMaskValue={
                                                    iPettyCashRequestTransactionModal?.totalValue
                                                  }
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div className="col d-flex align-items-center">
                                                <label className="col-1 text-capitalize">
                                                  {localize('payee')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashRequestTransactionModal?.primaryDescription ||
                                                    ''
                                                  }
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div className="col d-flex align-items-center">
                                                <label className="col-1 text-capitalize">
                                                  {localize('narration')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashRequestTransactionModal?.secondaryDescription ||
                                                    ''
                                                  }
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                            <div className="row mb-2">
                                              <div className="col d-flex align-items-center  ">
                                                <label className="col-1 text-capitalize">
                                                  {localize('reference')}
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  value={
                                                    iPettyCashRequestTransactionModal?.reference ||
                                                    ''
                                                  }
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>
                                <div className="mt-3">
                                  <Tab
                                    tabItems={[
                                      {
                                        label: localize('chargesAndLevies'),
                                        item: (
                                          <SimpleTable
                                            data={tableData}
                                            columns={columns}
                                          />
                                        ),
                                      },
                                    ]}
                                  />
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        ),
                      },
                      {
                        label: localize('pettyCashierChartOfAccountStatement'),
                        item: (
                          <PettyCashierAccountStatement
                            chartOfAccountId={pettyCashierChartOfAccountId}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <button
                      onClick={() => {
                        if (transactionType?.transactionType == 4) {
                          updatePettyCashRequisition()
                        }
                        if (transactionType?.transactionType == 1)
                          updatePettyNewCashRequest()
                        if (transactionType?.transactionType == 2) {
                          updatePettyAuthorizedCashRequest()
                        }
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SundryPayments
