/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import MobileToBankTerminalLookup from '../../../../../Components/Lookup/MobileToBankTerminalLookup'
import Swal from 'sweetalert2'
import { toBase64 } from '../../../../../Helpers/blobs'
import { putData } from '../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import FIleSelectLookup from '../../../../../Components/Lookup/FIleSelectLookup'
function ImportMobileToBankDataModal({
  mode,
  selectedMobileToBankRequest,
  setSelectedMobileToBankRequest,
  close,
  refreshData,
}) {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  async function handleSubmit() {
    const { success } = await putData(
      '/MobileToBankRequest/parse-mobile-to-bank-requests-import',
      selectedMobileToBankRequest,
      true,
      'Operation Completed Successfully'
    )
    if (success) {
      close()
      refreshData()
    }
  }

  function closeModal() {
    close()
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="mobileToBankRequestsCatalogue"
      handleAction={() => {
        if (!selectedMobileToBankRequest?.fileName) {
          Swal.fire('Oooops!', 'Please first select a file', 'info')
          return
        } else {
          handleSubmit()
        }
      }}
      handleClose={closeModal}
      modalClassName="modal-lg"
    >
      <div className="row mb-2">
        <div
          className="col-6 d-flex align-items-center with-validation"
          validation-message={
            globalValidationErrors['BusinessShortCode']?.message
          }
        >
          <label className="col-3 text-capitalize">
            {localize('mobileToBankTerminal')}
          </label>
          <MobileToBankTerminalLookup
            displayValue={
              selectedMobileToBankRequest?.mobileToBankTerminalDescription || ''
            }
            onSelect={v => {
              clearValidation('BusinessShortCode')
              setSelectedMobileToBankRequest(prev => ({
                ...prev,
                mobileToBankTerminalDescription: v.description,
                mobileToBankTerminalId: v.id,
                businessShortCode: v.businessShortCode,
              }))
            }}
          />
        </div>
        <div className="col-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">{localize('select')}</label>
          <FIleSelectLookup
            id={'mobileToBankFileItem'}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            displayValue={selectedMobileToBankRequest?.fileName}
            onSelect={async e => {
              let file = e.target.files[0]
              try {
                let b64 = await toBase64(file)
                let buffer = b64.split(',')[1].toString()
                setSelectedMobileToBankRequest({
                  ...selectedMobileToBankRequest,
                  buffer,
                  fileName: file.name,
                  fileExtension: 'csv',
                })
              } catch {
                Swal.fire('Invalid File', 'Error loading file', 'error')
                return
              }
            }}
          />
        </div>
      </div>
    </ModalTemplate>
  )
}
export default ImportMobileToBankDataModal
