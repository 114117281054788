/** @format */
import localize from '../../../../../../../Global/Localize'
import { PettyCashRequisitionLookup } from '../../../../../../../Components/Lookup/PettyCashRequisitionLookup'
import React from 'react'

export const SundryTransactionSectionPettyCashRequisition = ({
  transactionModel,
  setTransactionModel,
  loading,
}) => {
  return (
    <>
      <div
        className={`${
          loading ? '' : 'd-none'
        } col-12 d-flex align-items-center justify-content-center`}
      >
        <div className="m-3 spinner-border text-dark"></div>
        <span className="fs-4">Please Wait</span>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('voucherNumber')}
            </label>
            <PettyCashRequisitionLookup
              onSelect={r => {
                setTransactionModel({
                  ...transactionModel,
                  selectedPettyCashRequisition: { ...r },
                })
              }}
              displayValue={
                transactionModel?.selectedPettyCashRequisition
                  ?.documentReferenceNumber
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('glAccount')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedPettyCashRequisition
                  ?.pettyCashierChartOfAccountName
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountType')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedPettyCashRequisition
                  ?.pettyCashierChartOfAccountAccountTypeDescription
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('amount')}
            </label>
            <input
              className="form-control"
              disabled
              value={transactionModel?.selectedPettyCashRequisition?.amount}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">{localize('payee')}</label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedPettyCashRequisition
                  ?.primaryDescription
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('narration')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedPettyCashRequisition
                  ?.secondaryDescription
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('reference')}
            </label>
            <input
              className="form-control"
              disabled
              value={transactionModel?.selectedPettyCashRequisition?.reference}
            />
          </div>
        </div>
      </div>
    </>
  )
}
