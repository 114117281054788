/** @format */
import InvestmentProducts from '../../../../Components/Selectables/Plain/InvestmentProducts'

export const CreditTypesBenefactorInvestmentsProducts = ({
  creditTypesBenefactorInvestmentsProducts,
  loading,
  disabled,
  onCheckCreditTypesBenefactorInvestmentsProducts,
  prefix,
}) => {
  return (
    <InvestmentProducts
      disabled={disabled}
      isBusy={loading}
      prefix={prefix}
      investmentProductsToBeSelected={creditTypesBenefactorInvestmentsProducts}
      onCheckInvestmentProduct={r => {
        onCheckCreditTypesBenefactorInvestmentsProducts &&
          onCheckCreditTypesBenefactorInvestmentsProducts(r)
      }}
    />
  )
}
