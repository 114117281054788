/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import localize from '../../Global/Localize'
import DatePicker from '../Date/DatePicker'
import { formatDate } from '../../Helpers/formatters'
import Loader from '../Loaders/Loader'

function MonthlyBalanceSpoolLookup({ displayValue, onSelect }) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const columns = [
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
      sortBy: 'paddedBatchNumber',
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
      sortBy: 'postingPeriodDescription',
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r.durationStartDate)}</>,
      sortBy: 'durationStartDate',
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r.durationEndDate)}</>,
      sortBy: 'durationEndDate',
    },
    {
      label: localize('balanceStatistic'),
      template: r => <>{r.statisticDescription}</>,
      sortBy: 'statisticDescription',
    },
    {
      label: localize('dateFilter'),
      template: r => <>{r.dateFilterDescription}</>,
      sortBy: 'dateFilterDescription',
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
      sortBy: 'reference',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const url =
      '/lookup/MonthlyBalanceSpool/find-customer-account-balance-spools-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: 'all',
          opacity: 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('monthlyBalanceSpoolLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize"
                      htmlFor="lookupRecordsPerPage"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="lookupRecordsPerPage"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>

                  <div className="d-flex align-item-center">
                    <label htmlFor="search" className="me-1 text-capitalize">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                            pageIndex: 0,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                            pageIndex: 0,
                          })
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center float-end my-2">
                    <label className="text-capitalize" htmlFor="filterButton">
                      {localize('search')}
                    </label>

                    <input
                      type="search"
                      id="search"
                      className="form-control col ms-2"
                      value={reqParams.filterText}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="filterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default MonthlyBalanceSpoolLookup
