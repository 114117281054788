/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../Helpers/webApi'
import BreadCrumb from '../BreadCrumb/Index'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

import localize from '../../Global/Localize'
import AddNewButton from '../Buttons/AddNewButton/AddNewButton'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import './listingPage.scss'
import NonBlockingLoader from '../Loaders/NonBlockingLoader'

function ListingPage({
  map,
  columns,
  params,
  url,
  contextMenu,
  useText,
  auxiliaryFilters,
  noAdd,
  onAdd,
  refreshAt,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [selectedValues, setSelectedValues] = useState({})
  const [sortedBy, setSortedBy] = useState(null)
  const [reqParams, setReqParams] = useState(params)
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line
  }, [reqParams, refreshAt])

  useEffect(() => {
    const initialSelectedValues = {}
    auxiliaryFilters &&
      auxiliaryFilters.forEach(filter => {
        initialSelectedValues[filter.param] = filter.defaultFilter
      })
    setSelectedValues(initialSelectedValues)
    // eslint-disable-next-line
  }, [])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <BreadCrumb tree={map} />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  {noAdd || (
                    <div className="mb-3">
                      <AddNewButton handleClickAddButton={onAdd} />
                    </div>
                  )}
                </div>
              </div>

              <div style={{ position: 'relative' }}>
                <div className="row mb-2">
                  {auxiliaryFilters && auxiliaryFilters?.length > 0 && (
                    <>
                      <div className="col-md-8">
                        <div className="float-end">
                          <div className="mb-3">
                            <div className="input-group">
                              <>
                                {auxiliaryFilters.map(
                                  (auxiliaryFilter, index) => (
                                    <select
                                      defaultValue={
                                        selectedValues[auxiliaryFilter.param]
                                      }
                                      key={index}
                                      className="form-select text-capitalize"
                                      id={`${auxiliaryFilter.param}-filter`}
                                      onChange={e => {
                                        const newSelectedValues = {
                                          ...selectedValues,
                                          [auxiliaryFilter.param]:
                                            e.target.value,
                                        }
                                        setSelectedValues(newSelectedValues)
                                        setReqParams({
                                          ...reqParams,
                                          [auxiliaryFilter.param]:
                                            e.target.value,
                                        })
                                      }}
                                    >
                                      {Object.entries(
                                        auxiliaryFilter.filterType
                                      ).map(([key, value]) => (
                                        <option
                                          key={`auxFilter_${key}`}
                                          className="text-capitalize"
                                          value={key}
                                        >
                                          {localize(value.name)}
                                        </option>
                                      ))}
                                    </select>
                                  )
                                )}
                              </>
                              <button
                                id="FilterButton"
                                type="button"
                                className="btn btn-primary"
                              >
                                <i className="uil uil-filter"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  <div className="d-flex align-items-center col-3">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div className="col-6"></div>
                  <form
                    className="d-flex align-items-center col-3 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        [useText ? 'text' : 'filterText']: search,
                      })
                    }}
                  >
                    <label htmlFor="searchTable" className="text-capitalize">
                      {localize('search')}
                    </label>
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                    <button type="submit" className="btn btn-primary ms-2">
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <div className="row col-12 mt-2">
                  <SimpleTable
                    contextMenu={contextMenu}
                    data={tableData}
                    columns={columns}
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <div className="row col-12 mt-2">
                  <Pager
                    {...tableMeta}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </div>
              </div>
              {isBusy && <NonBlockingLoader />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListingPage
