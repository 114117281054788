/** @format */
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { AccountMetadataStatisticsModal } from './AccountMetadataStatisticsModal'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { ViewCustomerAccountStatement } from '../../../../../../Components/ReUsables/Customer/CustomerAccountStatement/ViewCustomerAccountStatement'

function AccountDetailsModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedCustomer,
  selectedCustomerAccount,
  setSelectedCustomerAccount,
}) {
  async function handleSubmit() {
    // const { success } = mode === 'add' ? await postData() : await putData()
    //
    // if (success) {
    //   onSetMode(null)
    //   onUpdateRefreshAt()
    // }
  }

  const [gbv, setGbv] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setGbv(g => {
      delete g[column]
      return g
    })
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={`${localize('accountDetails')} - ${
              selectedCustomerAccount?.fullAccountNumber
            } - ${selectedCustomerAccount?.accountName}`}
            handleAction={handleSubmit}
            hideUpdate
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'view' && (
              <AccountMetadataStatisticsModal
                gbv={gbv}
                clearValidation={clearValidation}
                selectedCustomer={selectedCustomer}
                setSelectedCustomerAccount={setSelectedCustomerAccount}
                selectedCustomerAccount={selectedCustomerAccount}
              />
            )}
            {mode === 'viewCustomerAccountStatement' && (
              <ViewCustomerAccountStatement
                selectedCustomerAccount={selectedCustomerAccount}
                selectedCustomer={selectedCustomer}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default AccountDetailsModal
