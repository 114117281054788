/** @format */

/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
  parseDate,
} from '../../../../../../Helpers/formatters'
import { NavigationContext } from '../../../../../../Contexts/NavigationContext'
import { getData } from '../../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import ViewWireTransferBatch from '../../Origination/WireTransfer/ViewWireTransferBatch'
import { batchStatus } from '../../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import Loader from '../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function WireTransfer({ menuCode, authorize }) {
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [transfers, setTransfers] = useState([])
  const [reqParams, setReqParams] = useState({
    status: authorize ? 8 : 1,
    startDate: parseDate(new Date()),
    endDate: parseDate(new Date()),
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    customDate: false,
    dateRequestFilter: 0,
  })
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [mode, setMode] = useState(null)

  const { navigatedComponent, navigateToComponent } =
    useContext(NavigationContext)

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
    },
    {
      label: localize('batchType'),
      template: r => <>{r.typeDescription}</>,
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('wireTransferType'),
      template: r => <>{r.wireTransferTypeDescription}</>,
    },
    {
      label: localize('gLAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    {
      label: localize('autowire') + '?',
      template: r => (
        <span className="text-capitalize">{r.autowire.toString()}</span>
      ),
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('verifiedBy/RejectedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('verifiedDate/RejectedDate'),
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  useEffect(() => {
    let comps = [...navigatedComponent]
    let ci = comps.findIndex(c => c.code === menuCode)

    if (ci !== -1)
      comps[ci].hasOpenModal =
        mode === 'add' || mode === 'edit' || mode === 'view'
    navigateToComponent(comps)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    loadTransfers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  async function loadTransfers() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/WireTransferBatch/find-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTransfers(data)
    } else {
      setIsBusy(false)
    }
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('BatchProcedures'),
          localize(authorize ? 'authorization' : 'verification'),
          localize('wireTransfer'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between my-3">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize">
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ width: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="BatchStatus"
                    value={reqParams.status}
                    onChange={e =>
                      setReqParams({ ...reqParams, status: e.value })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2 col-3">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    customStyle={{ minWidth: 250 }}
                    onSelect={d =>
                      setReqParams({
                        ...reqParams,
                        customDate: d?.custom,
                        startDate: d?.startDate,
                        endDate: d?.endDate,
                        dateRequestFilter: d?.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize">
                    {localize('search')}
                  </label>
                  <input
                    value={reqParams.filterText}
                    onChange={e =>
                      setReqParams({ ...reqParams, filterText: e.target.value })
                    }
                    type="text"
                    className="form-control ms-2 me-1"
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              {transfers && (
                <>
                  <SimpleTable
                    columns={columns}
                    data={transfers?.pageCollection || []}
                    contextMenu={r => (
                      <>
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            setSelectedBatch(r)
                            setMode('view')
                          }}
                        >
                          <i className="uil uil-search"></i>
                          <span className="text-capitalize">
                            {localize('view')}
                          </span>
                        </div>
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            // eslint-disable-next-line
                            if (r?.status != 1 && !authorize) {
                              return Swal.fire(
                                'Invalid Action',
                                'Sorry but you must first select a batch whose status is pending!',
                                'info'
                              )
                            }
                            // eslint-disable-next-line
                            if (r?.status != 8 && authorize) {
                              return Swal.fire(
                                'Invalid Action',
                                'Sorry but you must first select a batch whose status is audited (verified)!',
                                'info'
                              )
                            }
                            setSelectedBatch(r)
                            setMode('verify')
                          }}
                        >
                          <i className="uil uil-edit-alt"></i>
                          <span className="text-capitalize">
                            {localize(authorize ? 'authorize' : 'verify')}
                          </span>
                        </div>
                      </>
                    )}
                  />
                  <div className="ms-2">
                    <Pager
                      itemsCount={transfers?.itemsCount || 1}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={5}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {mode === 'verify' && (
        <ViewWireTransferBatch
          triggerRefresh={() => setRefresh(!refresh)}
          batch={selectedBatch}
          closeModal={() => {
            setSelectedBatch(null)
            setMode(null)
          }}
          {...{ [authorize ? 'authorize' : 'verify']: true }}
        />
      )}
      {mode === 'view' && (
        <ViewWireTransferBatch
          batch={selectedBatch}
          closeModal={() => {
            setSelectedBatch(null)
            setMode(null)
          }}
          authorize
        />
      )}
    </>
  )
}

export default WireTransfer
