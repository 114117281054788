/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import {
  formatCurrency,
  stripNonNumericCharacters,
} from '../../../../../../Helpers/formatters'

export const IntraAccountApportionments = ({ apportionments,handleOnRemoveCustomerTransferAccount }) => {
  const columns = [
    {
      label: localize('apportionTo'),
      template: r => <>{r.apportionTo}</>,
    },
    {
      label: localize('apportionedComponent'),
      template: r => <>{r.apportionedComponentDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    {
      label: localize('apportionedAmount'),
      template: r => (
        <>{formatCurrency(stripNonNumericCharacters(r.apportionedAmount))}</>
      ),
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{r.carryFowards}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('recoverCarryFowards'),
      template: r => (
        <span className="text-capitalize">
          {r.recoverCarryFowards.toString()}
        </span>
      ),
    },
  ]
  return (
    <SimpleTable
      contextMenu={e => (
        <>
          <div
            onClick={() => {
              handleOnRemoveCustomerTransferAccount(e)
            }}
            className="ctxt-menu-item"
          >
            <i className="uil uil-trash"></i>
            {localize('remove')}
          </div>
        </>
      )}
      columns={columns}
      data={apportionments}
    />
  )
}
