/** @format */
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  CustomerFilter,
  RefundBatchEntryType,
} from '../../../../../../Global/enumeration'
import localize from '../../../../../../Global/Localize'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import RefundTypeLookup from '../../../../../../Components/Lookup/RefundTypeLookup'
import { CustomerAccountsFundsTransferLookup } from '../../../../../../Components/Lookup/CustomerAccountsFundsTransferLookup'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const RefundBatchEntryDetails = ({
  selectedRefundBatch,
  refreshRefundBatchEntries,
}) => {
  const [selectedRefundBatchDetailType, setSelectedRefundBatchDetailType] =
    useState(Object.keys(RefundBatchEntryType)[1])
  return (
    <>
      <div className="row mb-2 p-2">
        <div className="col d-flex align-items-center">
          <label className="col-auto me-2 text-capitalize">
            {localize('entryType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="RefundBatchEntryType"
            value={selectedRefundBatchDetailType}
            onChange={e => {
              setSelectedRefundBatchDetailType(e.value)
            }}
          />
        </div>
      </div>
      <div className="row mb-2 p-2">
        {selectedRefundBatchDetailType ===
          Object.keys(RefundBatchEntryType)[1] && (
          <RefundBatchEntryDetailsStraightThrough
            refreshRefundBatchEntries={refreshRefundBatchEntries}
            selectedRefundBatch={selectedRefundBatch}
            selectedRefundBatchDetailType={Object.keys(RefundBatchEntryType)[1]}
          />
        )}
        {selectedRefundBatchDetailType ===
          Object.keys(RefundBatchEntryType)[0] && (
          <RefundBatchEntryDetailsRefundNotification
            selectedRefundBatch={selectedRefundBatch}
          />
        )}
      </div>
    </>
  )
}

const RefundBatchEntryDetailsStraightThrough = ({
  selectedRefundBatch,
  selectedRefundBatchDetailType,
  refreshRefundBatchEntries,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [entryDetailsModel, setEntryDetailsModel] = useState(null)

  const handleAddRefundTypeBatchEntry = async () => {
    const { success, data } = await postData(
      '/RefundBatch/add-refund-batch-entry',
      {
        RefundBatchId: selectedRefundBatch.id,
        CustomerId: selectedCustomer.id,
        RefundTypeId: entryDetailsModel.refundTypeId,
        Remarks: entryDetailsModel.remarks,
        Principal: entryDetailsModel.SelectedDebitAccount.principal,
        Interest: entryDetailsModel.SelectedDebitAccount.interest,
        DebitCustomerAccountId:
          entryDetailsModel.SelectedDebitAccount.customerAccountId,
        CreditCustomerAccountId:
          entryDetailsModel.SelectedCreditAccount.customerAccountId,
        EntryType: selectedRefundBatchDetailType,
      },
      true,
      'Add Refund Batch Entry'
    )

    if (success) {
      refreshRefundBatchEntries()
      setEntryDetailsModel(null)
      setSelectedCustomer(null)
    }
  }

  const handleAddDebitAccount = r => {
    setEntryDetailsModel({
      ...entryDetailsModel,
      SelectedDebitAccount: {
        customerAccountId: r.id,
        fullAccountNumber: r.fullAccountNumber,
        statusDescription: r.statusDescription,
        remarks: r.remarks,
        customerAccountTypeTargetProductDescription:
          r.customerAccountTypeTargetProductDescription,
        bookBalance: r.bookBalance,
        principalBalance: r.principalBalance,
        interestBalance: r.interestBalance,
        penaltyBalance: r.penaltyBalance,
        principal: r.principal,
        interest: r.interest,
      },
    })
  }

  const handleAddCreditAccount = r => {
    setEntryDetailsModel({
      ...entryDetailsModel,
      SelectedCreditAccount: {
        customerAccountId: r.id,
        fullAccountNumber: r.fullAccountNumber,
        statusDescription: r.statusDescription,
        remarks: r.remarks,
        customerAccountTypeTargetProductDescription:
          r.customerAccountTypeTargetProductDescription,
        bookBalance: r.bookBalance,
        principalBalance: r.principalBalance,
        interestBalance: r.interestBalance,
        penaltyBalance: r.penaltyBalance,
        principal: r.principal,
        interest: r.interest,
      },
    })
  }

  const handleOnAddInterestCreditAccount = e => {
    setEntryDetailsModel({
      ...entryDetailsModel,
      SelectedDebitAccount: {
        ...entryDetailsModel.SelectedDebitAccount,
        interest: stripNonNumericCharacters(e.target.value),
      },
    })
  }

  const handleOnAddPrincipalDebitAccount = e => {
    setEntryDetailsModel({
      ...entryDetailsModel,
      SelectedDebitAccount: {
        ...entryDetailsModel.SelectedDebitAccount,
        principal: stripNonNumericCharacters(e.target.value),
      },
    })
  }

  return (
    <>
      <CustomerPartial
        customer={selectedCustomer}
        onCustomerLookup={r => {
          setSelectedCustomer(r)
          setEntryDetailsModel({
            ...entryDetailsModel,
            SelectedDebitAccount: {
              customerId: r.id,
              customerFullName: r.fullName,
            },
            SelectedCreditAccount: {
              customerId: r.id,
              customerFullName: r.fullName,
            },
          })
        }}
      />
      <div className="row mb-2">
        <div className="col-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('refundType')}
          </label>
          <RefundTypeLookup
            displayValue={entryDetailsModel?.refundTypeDescription}
            onSelect={r => {
              setEntryDetailsModel({
                ...entryDetailsModel,
                refundTypeId: r.id,
                refundTypeDescription: r.description,
              })
            }}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('remarks')}</label>
          <input
            onChange={e => {
              setEntryDetailsModel({
                ...entryDetailsModel,
                remarks: e.target.value,
              })
            }}
            className="form-control"
            value={entryDetailsModel?.remarks}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
          >
            {localize('debit')}
          </div>
          <div className="row mb-2" style={{ marginTop: -10 }}>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <CustomerAccountsFundsTransferLookup
                onSelect={handleAddDebitAccount}
                displayValue={
                  entryDetailsModel?.SelectedDebitAccount?.customerFullName
                }
                accountsFundsTransfer={
                  entryDetailsModel?.SelectedDebitAccount?.fullAccountNumber ||
                  ''
                }
                selectedCustomerAccount={
                  entryDetailsModel?.SelectedDebitAccount
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  entryDetailsModel?.SelectedDebitAccount?.statusDescription ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                className="form-control"
                disabled
                value={entryDetailsModel?.SelectedDebitAccount?.remarks}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('productName')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  entryDetailsModel?.SelectedDebitAccount
                    ?.customerAccountTypeTargetProductDescription || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2" style={{ marginTop: -10 }}>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                disabled
                className="form-control text-end"
                value={formatCurrency(
                  entryDetailsModel?.SelectedDebitAccount?.bookBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                disabled
                className="form-control text-end"
                value={formatCurrency(
                  entryDetailsModel?.SelectedDebitAccount?.principalBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                disabled
                className="form-control text-end"
                value={formatCurrency(
                  entryDetailsModel?.SelectedDebitAccount?.interestBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('penaltyBalance')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  entryDetailsModel?.SelectedDebitAccount?.penaltyBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
          </div>
          <div className="row mb-2" style={{ marginTop: -10 }}>
            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('principal')}
              </label>
              <MaskNumber
                defaultMaskValue={
                  entryDetailsModel?.SelectedDebitAccount?.principal || ''
                }
                onMaskNumber={handleOnAddPrincipalDebitAccount}
              />
            </div>
            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('interest')}
              </label>
              <MaskNumber
                defaultMaskValue={
                  entryDetailsModel?.SelectedCreditAccount?.interest || ''
                }
                onMaskNumber={handleOnAddInterestCreditAccount}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
          >
            {localize('credit')}
          </div>
          <div className="row mb-2" style={{ marginTop: -10 }}>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <CustomerAccountsFundsTransferLookup
                onSelect={handleAddCreditAccount}
                displayValue={
                  entryDetailsModel?.SelectedCreditAccount?.customerFullName
                }
                accountsFundsTransfer={
                  entryDetailsModel?.SelectedCreditAccount?.fullAccountNumber ||
                  ''
                }
                selectedCustomerAccount={
                  entryDetailsModel?.SelectedCreditAccount
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  entryDetailsModel?.SelectedCreditAccount?.statusDescription ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                className="form-control"
                disabled
                value={entryDetailsModel?.SelectedCreditAccount?.remarks}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('productName')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  entryDetailsModel?.SelectedCreditAccount
                    ?.customerAccountTypeTargetProductDescription || ''
                }
              />
            </div>
          </div>
          <div className="row mb-2" style={{ marginTop: -10 }}>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  entryDetailsModel?.SelectedCreditAccount?.bookBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  entryDetailsModel?.SelectedCreditAccount?.principalBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  entryDetailsModel?.SelectedCreditAccount?.interestBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('penaltyBalance')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  entryDetailsModel?.SelectedCreditAccount?.penaltyBalance,
                  2,
                  2,
                  false
                )}
              />
            </div>
          </div>
        </div>
        <div
          className="bg-light d-flex justify-content-end align-items-center p-2 mt-3"
          style={{ borderRadius: 5 }}
        >
          <button
            onClick={handleAddRefundTypeBatchEntry}
            className="btn btn-success text-uppercase ms-3 px-4"
          >
            {localize('add')}
          </button>
        </div>
      </div>
    </>
  )
}

const RefundNotificationLookupByBranch = ({ selectedRefundBatch }) => {
  const [reqParams, setReqParams] = useState({
    branchId: selectedRefundBatch?.branchId,
    startDate: null,
    endDate: null,
    text: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    pageIndex: 0,
    pageSize: 10,
    includeBatchStatus: true,
    customDate: true,
    dateRequestsFilter: 15,
  })

  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('refundType'),
      template: r => <>{r.refundTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('customerAccountNumber'),
      template: r => <>{r.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('debitFullAccountNumber'),
      template: r => <>{r.debitFullAccountNumber}</>,
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r.creditFullAccountNumber}</>,
    },
    { label: localize('totalValue'), template: r => <>{r.totalValue}</> },
    { label: localize('principal'), template: r => <>{r.principal}</> },
    { label: localize('interest'), template: r => <>{r.interest}</> },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('street'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.customerAddressCity}</> },
    { label: localize('email'), template: r => <>{r.customerAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    {
      label: localize('dutyStation'),
      template: r => <>{r.cCustomerDutyStationDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r.customerEmployerDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r.approvalRemarks}</>,
    },
    { label: localize('approvedBy'), template: r => <>{r.approvedBy}</> },
    {
      label: localize('ApprovedDate'),
      template: r => <>{formatDate(r.approvedDate)}</>,
    },
    {
      label: `${localize('verification')}/${localize('rejection')} ${localize(
        'remarks'
      )}`,
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'by'
      )}`,
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'date'
      )}`,
      template: r => <>{r.auditedDate}</>,
    },
    { label: localize('settledBy'), template: r => <>{r.settledBy}</> },
    { label: localize('settledDate'), template: r => <>{r.settledDate}</> },
    {
      label: localize('settlementRemarks'),
      template: r => <>{r.settlementRemarks}</>,
    },
    { label: localize('invalidatedBy'), template: r => <>{r.invalidatedBy}</> },
    {
      label: localize('invalidatedDate'),
      template: r => <>{formatDate(r.invalidatedDate)}</>,
    },
    {
      label: localize('invalidationRemarks'),
      template: r => <>{r.invalidationRemarks}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
  ]

  async function loadData() {
    // TODO: myke check on this 17/08/2023
    const url =
      '/RefundNotification/find-refund-notifications-by-branch-and-status-and-filter-in-page'
    const { success, data } = await getData(url, {
      ...reqParams,
    })
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleCancel = () => {
    setModalActive(false)
  }

  const handleUpdate = () => {
    const url = '/'
  }

  useEffect(() => {
    if (modalActive) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  return (
    <>
      <button
        className="btn btn-primary"
        style={{ margin: 0, transform: 'scale(0.9)' }}
        onClick={() => setModalActive(true)}
      >
        <i className="uil uil-search"></i>
      </button>
      {modalActive && (
        <div
          style={{
            maxHeight: '60dvh',
            overflow: 'auto',
          }}
        >
          <Modal isOpen className="modal-xl">
            <ModalHeader className="bg-white text-capitalize">
              {localize(
                `Refund Notifications Lookup @ ${selectedRefundBatch?.branchDescription}`
              )}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white p-4">
              <div className="row gx-3 mb-2 mt-2 d-flex align-items-center flex-wrap">
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="recordsPerPage"
                      className="text-capitalize"
                      style={{ marginLeft: -10, textTransform: 'capitalize' }}
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="recordsPerPage"
                      className="form-select ms-3"
                      style={{ maxWidth: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label htmlFor="search" className="col-3 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center justify-content-end">
                    <label htmlFor="search" className="text-capitalize me-2">
                      {localize('search')}
                    </label>
                    <div className="d-flex align-items-center">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        value={reqParams?.customerFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            customerFilter: e.value,
                          })
                        }}
                      />
                      <input
                        type="search"
                        id="search"
                        className="form-control col ms-2"
                        value={reqParams.text}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="FilterButton"
                        type="button"
                        className="btn btn-primary"
                        onClick={e => {
                          loadData()
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            loadData()
                          }
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" py-1">
                <SimpleTable
                  columns={columns}
                  data={tableData}
                  onSort={(c, i) => handleSort(c, i)}
                  sortedBy={sortedBy}
                />
              </div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  )
}

const RefundBatchEntryDetailsRefundNotification = ({ selectedRefundBatch }) => {
  return (
    <div className="col-md-4">
      <div className="d-flex align-items-center">
        <label className="text-capitalize">
          {localize('lookupTargetNotification(s)')}
        </label>
        <RefundNotificationLookupByBranch
          selectedRefundBatch={selectedRefundBatch}
        />
      </div>
    </div>
  )
}
