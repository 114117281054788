/** @format */

import React, { useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import EmployeeLookup from '../../../../Components/Lookup/EmployeeLookup'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import FIleSelectLookup from '../../../../Components/Lookup/FIleSelectLookup'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import { getTodaysDate, parseDate } from '../../../../Helpers/formatters'
import { toBase64 } from '../../../../Helpers/blobs'

function CreateEmployeeDisciplinaryCase({
  refresh,
  close,
  mode,
  selectedCase,
}) {
  const [employeeCase, setEmployeeCase] = useState(selectedCase)

  const [isBusy, setIsBusy] = useState(false)

  async function handleAction() {
    setIsBusy(true)
    const { success } = await postData(
      mode === 'add'
        ? '/employeeDisciplinaryCase/create'
        : '/employeeDisciplinaryCase/update',
      employeeCase,
      false
    )
    if (success) {
      Swal.fire(
        'Operation Successful',
        'Employee Disciplinary Case Created',
        'success'
      )
      refresh()
      close()
    }
    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalTitle={localize('employeeDisciplinaryCase')}
      modalMode={mode}
      handleClose={close}
      modalClassName="modal-xl"
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={handleAction}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center gap-5">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-2">
                {localize('employee')}
              </label>

              {mode == 'edit' ? (
                <input
                  className="form-control"
                  disabled
                  value={employeeCase?.employeeCustomerFullName}
                />
              ) : (
                <EmployeeLookup
                  displayValue={employeeCase?.employeeCustomerFullName}
                  onSelect={e =>
                    setEmployeeCase({
                      ...employeeCase,
                      employeeId: e.id,
                      employeeCustomerId: e.customerId,
                      employeeCustomerFullName: e.customerFullName,
                    })
                  }
                />
              )}
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-2">
                {localize('incidentDate')}
              </label>
              <CustomDatePicker
                name={`modalEmployeeDisciplinaryCase-${mode}-incidentDate`}
                maxDate={getTodaysDate()}
                defaultDate={employeeCase?.incidentDate || getTodaysDate()}
                onDateChange={x => {
                  setEmployeeCase({
                    ...employeeCase,
                    incidentDate: x ? parseDate(x) : parseDate(getTodaysDate()),
                  })
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-5 mt-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-2">
                {localize('disciplinaryType')}
              </label>
              <div style={{ width: '100%' }}>
                <EnumsServerCombo
                  value={employeeCase?.type}
                  onChange={e =>
                    setEmployeeCase({ ...employeeCase, type: e.value })
                  }
                  enumsKey="EmployeeDisciplinaryType"
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-2">
                {localize('document')}
              </label>

              <FIleSelectLookup
                accept="application/pdf"
                displayValue={employeeCase?.fileName}
                onSelect={async f => {
                  let file = f.target.files[0]
                  setEmployeeCase({
                    ...employeeCase,
                    document: await toBase64(file),
                    fileName: file?.name,
                  })
                }}
              />
            </div>
          </div>
          <hr />
          <div className="d-flex align-items-center gap-5 mt-2">
            <div className="col d-flex align-items-center gap-1">
              <label htmlFor="" className="text-capitalize col-1">
                {localize('title')}
              </label>
              <input
                type="text"
                value={employeeCase?.fileTitle}
                onChange={e =>
                  setEmployeeCase({
                    ...employeeCase,
                    fileTitle: e.target.value,
                  })
                }
                className="form-control"
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-5 mt-3">
            <div className="col d-flex  gap-1">
              <label htmlFor="" className="text-capitalize col-1">
                {localize('description')}
              </label>
              <textarea
                onChange={e =>
                  setEmployeeCase({
                    ...employeeCase,
                    fileDescription: e.target.value,
                  })
                }
                value={employeeCase?.fileDescription}
                type="text"
                className="form-control"
                rows={5}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-5 mt-3">
            <div className="col d-flex  gap-1">
              <label htmlFor="" className="text-capitalize col-1">
                {localize('remarks')}
              </label>
              <textarea
                type="text"
                className="form-control"
                rows={5}
                value={employeeCase?.remarks}
                onChange={e =>
                  setEmployeeCase({ ...employeeCase, remarks: e.target.value })
                }
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateEmployeeDisciplinaryCase
