/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function TransactionReferences() {
  const columns = [
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate, true)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, false)}</>,
    },
  ]

  const [mode, setMode] = useState(null)
  const [selectedTransactionReference, setSelectedTransactionReference] =
    useState(null)
  const [refresh, setRefresh] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [tableData, setTableData] = useState([])
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadTableData()
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/TransactionReference/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/TransactionReference/update',
            selectedTransactionReference,
            false
          )
        : await postData(
            '/TransactionReference/create',
            selectedTransactionReference,
            false
          )

    if (success) {
      setMode(null)
      setSelectedTransactionReference(null)
      setIsBusy(false)
      loadTableData()
      showNotification('Transaction Reference', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedTransactionReference(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleOnAdd = async () => {
    setMode('add')
    electedDebitType(null)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('miscellaneous'),
          localize('transactionReferences'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleOnAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="employeeTypeFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('edit')
                              setSelectedTransactionReference(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {(mode === 'add' || mode === 'edit') && (
        <ModalTemplate
          modalClassName="modal-lg"
          modalTitle={'transactionReferences'}
          modalMode={mode}
          handleAction={handleSubmit}
          handleClose={closeModal}
          actionText={mode === 'add' ? localize('create') : localize('update')}
          hideUpdate={mode === 'view'}
          footerChildren={
            <>
              <div className="d-flex align-items-center me-3 gap-2">
                <Checkbox
                  id="cTransactionRefIsLocked"
                  onCheck={e => {
                    setSelectedTransactionReference({
                      ...selectedTransactionReference,
                      isLocked: e,
                    })
                  }}
                  checked={selectedTransactionReference?.isLocked}
                />
                <label
                  htmlFor="cTransactionRefIsLocked"
                  className="text-capitalize"
                >
                  {localize('isLocked')}?
                </label>
              </div>
            </>
          }
        >
          {isBusy ? (
            <Loader />
          ) : (
            <div className="row mb-2 g-3">
              <div className="col-md-12">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['Description']?.message
                  }
                >
                  <label className="text-capitalize col-1">
                    {localize('name')}
                  </label>
                  <input
                    defaultValue={selectedTransactionReference?.description}
                    className="form-control"
                    onChange={e => {
                      clearValidation('Description')
                      setSelectedTransactionReference({
                        ...selectedTransactionReference,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </ModalTemplate>
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}
export default TransactionReferences
