/** @format */

/* eslint-disable */
import React, { useEffect, useReducer, useState } from 'react'
import { customerDetailsReducer } from '../../../../Pages/CustomerRelationshipManagement/Operations/Customers/AccountStatements/Reducer/reducer'
import {
  setCustomerAccountBalancesSummary,
  setCustomersAccountListings,
} from '../../../../Pages/CustomerRelationshipManagement/Operations/Customers/AccountStatements/Reducer/actions'
import { getData } from '../../../../Helpers/webApi'
import localize from '../../../../Global/Localize'
import Tab from '../../../Tabs/Tab'
import { ViewCustomerSpecimen } from '../ViewCustomerSpecimen'
import { CustomerAccountPartial } from '../CustomerAccountPartial'
import { CustomerAccountStatements } from './CustomerAccountStatements'

export const ViewCustomerAccountStatement = ({
  selectedCustomerAccount,
  selectedCustomer,
}) => {
  const customerDetails = {
    selectedCustomer: {},
    customersAccountListings: [],
    customerAccountBalancesSummary: {},
  }

  const [loading, setLoading] = useState({
    loadingAccountDetails: false,
    loadingAccountStatements: false,
    loadingRepaymentSchedule: false,
    loadingCarryForwards: false,
    loadingJournalEntries: false,
    loadingCreditScores: false,
  })
  const [customerDetailsState, dispatchCustomerDetails] = useReducer(
    customerDetailsReducer,
    customerDetails
  )
  const [balanceSummaryProductSection, setBalanceSummaryProductSection] =
    useState('0')
  const [customerAccountbeingViewed, setCustomerAccountBeingViewed] =
    useState(null)
  const [refreshCustomerAccounts, setRefreshCustomerAccounts] = useState(false)
  const [customer, setCustomer] = useState(null)

  const fetchCustomer = async () => {
    const { success, data } = await getData(
      '/lookup/customer/find-customer-by-id',
      {
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )
    if (success) {
      setCustomer(data?.customer)
    }
  }

  const fetchCustomerAccountDetails = async customerAccount => {
    setLoading({ ...loading, loadingAccountDetails: true })
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id',
      {
        customerId: customerAccount?.customerId,
        includeBalances: true,
        includeProductDescription: true,
        balancesSummaryProductSection: balanceSummaryProductSection,
      },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingAccountDetails: false })
      dispatchCustomerDetails(setCustomersAccountListings(data))
      dispatchCustomerDetails(
        setCustomerAccountBalancesSummary({
          savingsBalance: data?.totalCustomerSavingsBalance,
          investmentsBalance: data?.totalCustomerRefundableInvestmentsBalance,
          loansBalance: data?.totalCustomerLoansBalance,
          principalArrearsBalance: data?.totalCustomerPrincipalArrearsBalance,
          interestArrearsBalance: data?.totalCustomerInterestArrearsBalance,
          netWorth: data?.totalCustomerNetWorth,
        })
      )
    }
  }

  const handleOnChangeProductSection = r => {
    setBalanceSummaryProductSection(r.value)
  }
  useEffect(() => {
    if (customerAccountbeingViewed) {
      fetchCustomerAccountDetails(customerAccountbeingViewed)
    }
    //eslint-disable-next-line
  }, [balanceSummaryProductSection, refreshCustomerAccounts])

  useEffect(() => {
    if (selectedCustomerAccount) {
      setCustomerAccountBeingViewed(selectedCustomerAccount)
      fetchCustomerAccountDetails(selectedCustomerAccount)
    }
    //eslint-disable-next-line
  }, [selectedCustomerAccount])

  const handleOnSelectCustomerAccount = async customerAccount => {
    setCustomerAccountBeingViewed(customerAccount)
    await fetchCustomerAccountDetails(customerAccount)
  }

  useEffect(() => {
    if (selectedCustomer?.id) {
      fetchCustomer()
    }
    //eslint-disable-next-line
  }, [selectedCustomer?.id])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <CustomerAccountPartial
            customer={customerAccountbeingViewed}
            onCustomerLookup={handleOnSelectCustomerAccount}
            refreshFromServer
          />

          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                preload
                tabItems={[
                  {
                    label: localize('statement'),
                    item: (
                      <CustomerAccountStatements
                        onRefreshStatement={() => {
                          setRefreshCustomerAccounts(!refreshCustomerAccounts)
                        }}
                        customerAccountBeingViewed={customerAccountbeingViewed}
                        setCustomerAccountBeingViewed={
                          setCustomerAccountBeingViewed
                        }
                        selectedCustomer={customer}
                        balanceSummary={
                          customerDetailsState?.customerAccountBalancesSummary
                        }
                        balanceSummaryProductSection={
                          balanceSummaryProductSection
                        }
                        accountStatementsListing={
                          customerDetailsState?.customersAccountListings
                        }
                        loading={loading.loadingAccountDetails}
                        onChangeProductSection={handleOnChangeProductSection}
                      />
                    ),
                  },
                  {
                    label: `${localize('specimen')}(${localize('identity')})`,
                    item: <ViewCustomerSpecimen readOnly customer={customer} />,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
