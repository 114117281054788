/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'

function LiquidateFixedDeposit({
  mode,
  close,
  selectedFixedDeposit,
  setSelectedFixedDeposit,
  loadTableData,
}) {
  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
  ]
  const [selectedEntries, setSelectedEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [tabWaiting, setTabWaiting] = useState(false)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    loadCustomerAccountTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadCustomerAccountTableData = async () => {
    setTabWaiting(true)
    const { success, data } = await getData(
      `/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes?customerId=${
        selectedFixedDeposit.customerAccountCustomerId
      }&includeBalances=${true}&includeProductDescription=${true}`,
      {},
      false
    )
    if (success) {
      setTableData(data.customerLoanAccounts || [])
    }
    setTabWaiting(false)
  }

  function closeModal() {
    close()
  }

  async function updateLiquidation() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/FixedDeposit/pay-fixed-deposit',
      {
        fixedDeposit: {
          customerAccountId: selectedFixedDeposit?.customerAccountId,
          chartOfAccountId: selectedFixedDeposit?.chartOfAccountId,
          interestExpenseChartOfAccountId:
            selectedFixedDeposit?.interestExpenseChartOfAccountId,
          maturityDate: selectedFixedDeposit?.maturityDate,
          fixedDepositTypeId: selectedFixedDeposit?.fixedDepositTypeId,
          status: 1,
          ...selectedFixedDeposit,
        },
        fixedDepositPayables: selectedEntries.map(entry => entry),
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setSelectedFixedDeposit(null)
      closeModal()
      loadTableData()
      showNotification(
        'Fixed Deposit Fixing',
        'success',
        'Operation completed successfully!'
      )
    } else {
      setIsBusy(false)
    }
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="liquidateFixedDeposit"
        handleClose={closeModal}
        modalClassName="modal-xl"
        handleAction={updateLiquidation}
        actionText={'update'}
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('fixedDepositType')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedFixedDeposit?.fixedDepositTypeDescription || ''
                  }
                  disabled
                />
              </div>

              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('fixedDepositNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={selectedFixedDeposit?.paddedSerialNumber || ''}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('fullAccountNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFixedDeposit?.customerAccountFullAccountNumber || ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('customerName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedFixedDeposit?.customerAccountCustomerFullName || ''
                  }
                  disabled
                />
              </div>

              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('customerType')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFixedDeposit?.customerAccountCustomerMembershipClassCustomerTypeDescription ||
                    ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('identityCardNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFixedDeposit?.customerAccountCustomerIndividualIdentityCardNumber ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('accountNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    selectedFixedDeposit?.customerAccountCustomerReference1 ||
                    ''
                  }
                  disabled
                />
              </div>

              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('membershipNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFixedDeposit?.customerAccountCustomerReference2 ||
                    ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('personalFileNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFixedDeposit?.customerAccountCustomerReference3 ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('category')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedFixedDeposit?.categoryDescription || ''}
                  disabled
                />
              </div>

              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('value')}
                </label>
                <MaskNumber
                  className="form-control me-3"
                  defaultMaskValue={selectedFixedDeposit?.value}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('annualPercentageRate')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={formatAsPercent(selectedFixedDeposit?.rate) || ''}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('term(month)')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedFixedDeposit?.term || ''}
                  disabled
                />
              </div>

              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('maturityAction')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={selectedFixedDeposit?.maturityActionDescription || ''}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={selectedFixedDeposit?.remarks || ''}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedFixedDeposit?.createdBy || ''}
                  disabled
                />
              </div>

              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={formatDate(selectedFixedDeposit?.createdDate) || ''}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <Checkbox
                  id="fixedDepositIsLocked"
                  disabled
                  checked={selectedFixedDeposit?.isLocked}
                />

                <label
                  htmlFor="fixedDepositIsLocked"
                  className="text-capitalize col-3"
                >
                  {localize('isLocked')}
                </label>
              </div>
            </div>
          </>
        )}

        <div className="mt-3">
          <Tab
            tabItems={[
              {
                label: localize('fixedDepositPayables'),
                item: (
                  <>
                    {tabWaiting ? (
                      <Loader />
                    ) : (
                      <div className="row col-12 mt-2">
                        <SimpleTable data={tableData} columns={columns} />
                      </div>
                    )}
                  </>
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default LiquidateFixedDeposit
