/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import localize from '../../Global/Localize'
import { MaskNumber } from '../InputMasks/MaskNumber'
import computeMathExpression from '../../Helpers/computeMathExpression'
import { stripNonNumericCharacters } from '../../Helpers/formatters'
import Loader from '../Loaders/Loader'

function FormulaInput({ value, onChange, style = {}, useBlur }) {
  const [calcOpen, setCalcOpen] = useState(false)
  const [expression, setExpression] = useState('')
  const [amount, setAmount] = useState(value)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    onChange && onChange(amount)
    //eslint-disable-next-line
  }, [amount])

  useEffect(() => {
    setAmount(value)
  }, [value])

  async function calculate() {
    setIsBusy(true)
    const computed = await computeMathExpression(expression)
    setAmount(computed)
    setCalcOpen(false)
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="d-flex align-items-center"
        style={{ width: '100%', ...style }}
      >
        <MaskNumber
          defaultMaskValue={amount}
          onMaskNumber={
            useBlur
              ? () => {}
              : e => {
                  setExpression('')
                  setAmount(stripNonNumericCharacters(e.target.value))
                }
          }
          onBlur={
            useBlur
              ? e => {
                  setExpression('')
                  setAmount(stripNonNumericCharacters(e.target.value))
                }
              : () => {}
          }
        />
        <button
          className="btn btn-primary ms-1"
          onClick={() => setCalcOpen(true)}
        >
          <i className="uil uil-calculator"></i>
        </button>
      </div>

      {calcOpen && (
        <Modal isOpen centered>
          <ModalBody className="bg-white">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <h4 className="text-capitalize mb-3">
                  {localize('expression')}
                </h4>
                <textarea
                  value={expression}
                  onChange={e => setExpression(e.target.value)}
                  type="text"
                  className="form-control my-2"
                />
                <button
                  className="btn btn-success px-3 float-end"
                  onClick={e => calculate()}
                >
                  <i className="uil uil-bolt me-2"></i>
                  {localize('calculate')}
                </button>
                <button
                  className="btn btn-danger float-end me-2"
                  onClick={e => setCalcOpen(false)}
                >
                  {localize('cancel')}
                </button>
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default FormulaInput
