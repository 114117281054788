/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { customerType } from '../../../../Helpers/constants'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import EditMembershipClass from '../../MembershipClasses/EditMembershipClass'
import AlternateChannels from '../../MembershipClasses/Partials/AlternateChannels'
import Flags from '../../MembershipClasses/Partials/Flags'
import InvestmentProducts from '../../MembershipClasses/Partials/InvestmentProducts'
import MandatoryCharges from '../../MembershipClasses/Partials/MandatoryCharges'
import SavingsProducts from '../../MembershipClasses/Partials/SavingsProducts'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function MembershipClasses() {
  const [classes, setClasses] = useState([])
  const [selectedClassId, setSelectedClassId] = useState(null)
  const [attachedProducts, setAttachedProducts] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [classToEdit, setClassToEdit] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [gbv, setGbv] = GlobalValidationErrors.use()
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
  })

  const columns = [
    {
      label: localize('code'),
      template: r => <>{r?.paddedCode}</>,
      sortBy: 'code',
    },
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('numberingSeries'),
      template: r => <>{r?.numberingSeriesDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerTypeDescription}</>,
    },
    {
      label: localize('terminationNoticePeriod'),
      template: r => <>{r?.memberTerminationNoticePeriod}</>,
    },
    {
      label: localize('principalInvestmentProduct'),
      template: r => <>{r?.principalInvestmentProductDescription}</>,
    },
    {
      label: localize('principalSavingsProduct'),
      template: r => <>{r?.principalSavingsProductDescription}</>,
    },
    {
      label: localize('initialStatementStartDate'),
      template: r => <>{formatDate(r?.initialStatementStartDate, true)}</>,
    },
    {
      label: localize('kycScoreThreshold'),
      template: r => <>{r?.kycScoreThreshold}</>,
    },
    {
      label: localize('prefix'),
      template: r => <>{r?.prefix}</>,
    },
    {
      label: localize('suffix'),
      template: r => <>{r?.suffix}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('mandatoryCharges'),
      item: (
        <MandatoryCharges
          disabled
          prefix={'view'}
          membershipClassId={selectedClassId}
        />
      ),
    },
    {
      label: localize('investmentProducts'),
      item: (
        <InvestmentProducts
          disabled
          selected={attachedProducts?.investmentProductCollection || []}
          prefix={'view'}
        />
      ),
    },
    {
      label: localize('savingsProducts'),
      item: (
        <SavingsProducts
          disabled
          selected={attachedProducts?.savingsProductCollection || []}
          prefix={'view'}
        />
      ),
    },
    {
      label: localize('alternateChannels'),
      item: (
        <AlternateChannels
          disabled
          membershipClassId={selectedClassId}
          prefix={'view'}
        />
      ),
    },
    {
      label: localize('flags'),
      item: (
        <Flags
          membershipClass={classToEdit}
          onFlag={() => {}}
          disabled
          prefix={'view'}
        />
      ),
    },
  ]

  const handleClose = () => {
    setModalMode(null)
    setSelectedClassId(null)
    setClassToEdit(null)
  }

  const handleClickAddButton = () => {
    setModalMode('add')
    setClassToEdit({
      customerType: customerType.INDIVIDUAL,
      kycScoreThreshold: 0,
      membershipTerminationNoticePeriod: 0,
    })
    setSelectedClassId(null)
  }

  useEffect(() => {
    return () => setGbv({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    loadClasses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  useEffect(() => {
    selectedClassId && loadAttachedProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClassId, classToEdit])

  async function loadClasses() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/membershipClass/find-membership-class-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setClasses(data)
    }

    setIsBusy(false)
  }

  async function loadAttachedProducts() {
    setAttachedProducts(null)
    const { success, data } = await getData(
      '/lookup/membershipClass/find-attached-products-by-membership-class-id',
      { membershipClassId: selectedClassId },
      false
    )
    if (success) {
      setAttachedProducts(data)
    }
  }

  return (
    <>
      {modalMode && (
        <EditMembershipClass
          mode={modalMode}
          membershipClass={classToEdit}
          close={handleClose}
          gbv={gbv}
          clearValidation={clearValidation}
          loadClasses={loadClasses}
          readonly={modalMode === 'view'}
        />
      )}
      <BreadCrumb
        tree={[localize('registryManagement'), localize('membershipClasses')]}
      />
      <div className="card rounded bg-white">
        <div className="card-body">
          <div className="md-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center mt-3">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <form
                  onSubmit={e => {
                    e.preventDefault()

                    setReqParams({ ...reqParams, filterText: search })
                  }}
                  className={`d-flex align-items-center gap-2`}
                >
                  <label className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                  <button className="btn btn-primary" type="submit">
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <SimpleTable
                columns={columns}
                data={classes.pageCollection}
                // canClickOnce
                // onClickOnce={r => {
                //   setSelectedClassId(r?.id)
                //   setClassToEdit(r)
                // }}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setModalMode('view')
                        setClassToEdit(r)
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span className="text-capitalize">
                        {localize('view')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setModalMode('edit')
                        setClassToEdit(r)
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span className="text-capitalize">
                        {localize('edit')}
                      </span>
                    </div>
                  </>
                )}
              />
              <div className="mt-2"></div>
              {classes?.itemsCount && (
                <Pager
                  itemsCount={classes?.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={pageIndex =>
                    setReqParams({ ...reqParams, pageIndex })
                  }
                />
              )}
              {/* {attachedProducts && <Tab tabItems={tabItems} />} */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MembershipClasses
