/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import ListCustomer from '../../../../Components/ReUsables/Customer/ListCustomer'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import EditPayrollNumbers from './EditPayrollNumbers/EditPayrollNumbers'
import ViewPayrollNumbers from './ViewPayrollNumbers/ViewPayrollNumbers'

function PayrollNumbers() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [payrollNumbers, setPayrollNumbers] = useState([])
  const [reload, setReload] = useState(false)
  const [payrollNumber, setPayrollNumber] = useState(null)
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const fetchPayrollNumbers = async () => {
    setIsBusy(true)
    const url = '/PayrollNumber/find-payroll-numbers-by-customer-id'
    const { success, data } = await getData(
      url,
      {
        customerId: selectedCustomer?.id,
      },
      false
    )
    if (success) {
      setPayrollNumbers(data?.result)
    }

    setIsBusy(false)
  }

  const handleOnEditMenuClick = customer => {
    setSelectedCustomer(customer)
    setModalMode('edit')
  }

  const handleCloseModal = () => {
    setSelectedCustomer(null)
    setModalMode(null)
  }

  const handleViewMenuClick = customer => {
    setSelectedCustomer(customer)
    setModalMode('view')
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleAddPayrollNumber = () => {
    if (
      !payrollNumber?.reference ||
      !payrollNumber?.payrollNumberTypeDescription ||
      !payrollNumber?.remarks
    ) {
      GlobalValidationErrors.set({
        Reference: !payrollNumber?.reference && {
          message: 'Reference is required',
        },

        NumberType: !payrollNumber?.payrollNumberTypeId && {
          message: 'Payroll Number Type is required',
        },

        Remarks: !payrollNumber?.remarks && {
          message: 'Remarks is required',
        },
      })
      return
    }

    setPayrollNumbers([
      ...payrollNumbers,
      {
        reference: payrollNumber?.reference,
        remarks: payrollNumber?.remarks,
        payrollNumberTypeDescription:
          payrollNumber?.payrollNumberTypeDescription,
        payrollNumberTypeId: payrollNumber?.payrollNumberTypeId,
        modifiedDate: '',
        modifiedBy: '',
        createdDate: formatDate(new Date()),
      },
    ])

    setSelectedCustomer({
      ...selectedCustomer,
      reference: '',
      remarks: '',
    })
  }

  const handlePayrollRemove = payroll => {
    setPayrollNumbers(
      payrollNumbers.filter(
        p =>
          p.payrollNumberTypeDescription !==
          payroll?.payrollNumberTypeDescription
      )
    )
  }

  const handleUpdatePayroll = async () => {
    setIsBusyUpdating(true)
    const url = '/PayrollNumber/update-payroll-number-collection'

    const { success } = await putData(
      url,
      {
        customerId: selectedCustomer?.id,
        payrollNumberCollection: payrollNumbers.map(p => ({
          customerId: selectedCustomer?.id,
          payrollNumberTypeId: p?.payrollNumberTypeId,
          reference: p.reference,
          remarks: p.remarks,
        })),
      },
      false
    )

    if (success) {
      setPayrollNumbers([])
      setSelectedCustomer({})
      setReload(true)
      setModalMode(null)
      setPayrollNumber(null)
      showNotification('Payroll Numbers', 'success')
    }

    setIsBusyUpdating(false)
  }

  useEffect(() => {
    if (selectedCustomer?.id) fetchPayrollNumbers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer?.id])

  return (
    <>
      {modalMode === 'view' && (
        <ViewPayrollNumbers
          handleClose={handleCloseModal}
          handlePayrollRemove={handlePayrollRemove}
          mode={modalMode}
          payrollNumbers={payrollNumbers}
          selectedCustomer={selectedCustomer}
          isBusy={isBusy}
        />
      )}

      {modalMode === 'edit' && (
        <EditPayrollNumbers
          clearValidation={clearValidation}
          globalValidationErrors={globalValidationErrors}
          handleAddPayrollNumber={handleAddPayrollNumber}
          handleCloseModal={handleCloseModal}
          handlePayrollRemove={handlePayrollRemove}
          handleUpdatePayroll={handleUpdatePayroll}
          modalMode={modalMode}
          payrollNumber={payrollNumber}
          payrollNumbers={payrollNumbers}
          selectedCustomer={selectedCustomer}
          setPayrollNumber={setPayrollNumber}
          setPayrollNumbers={setPayrollNumbers}
          setSelectedCustomer={setSelectedCustomer}
          isBusyUpdating={isBusyUpdating}
        />
      )}

      <BreadCrumb tree={[localize('members'), localize('payrollNumbers')]} />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row">
            <ListCustomer
              reload={reload}
              setReload={setReload}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleOnEditMenuClick(r)
                    }}
                  >
                    <i className="uil uil-edit-alt"></i>
                    <span>{localize('edit')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleViewMenuClick(r)
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                </>
              )}
              hiddenColumnsIndexes={[0, 1]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PayrollNumbers
