/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import moment from 'moment'
import { getData, postData, putData } from '../../Helpers/webApi'
import CurrencyLookup from '../../Components/Lookup/CurrencyLookup'
import { GlobalValidationErrors } from '../../Validation/ValidationState'
import { MaskNumber } from '../../Components/InputMasks/MaskNumber'
import BreadCrumb from '../../Components/BreadCrumb/Index'
import Loader from '../../Components/Loaders/Loader'
import SimpleTable from '../../Components/Tables/SimpleTable'
import Pager from '../../Components/Tables/Pager'
import { showNotification } from '../../Helpers/Notification/ShowNotification'
import AddNewButton from '../../Components/Buttons/AddNewButton/AddNewButton'
import ModalTemplate from '../../Components/ModalTemplate/ModalTemplate'
import RecordsPerPage from '../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function ForexRates() {
  const columns = [
    {
      label: localize('baseCurrency'),
      template: r => <>{r?.baseCurrencyDescription}</>,
    },
    {
      label: localize('baseCurrencyCode'),
      template: r => <>{r?.baseCurrencyISOCode}</>,
    },
    {
      label: localize('quoteCurrency'),
      template: r => <>{r?.quoteCurrencyDescription}</>,
    },
    {
      label: localize('quoteCurrencyCode'),
      template: r => <>{r?.quoteCurrencyISOCode}</>,
    },
    {
      label: localize('effectiveDate'),
      template: r => (
        <div style={{ minWidth: 220, padding: 0 }}>
          {formatDate(r?.effectiveDate, true)}
        </div>
      ),
    },
    {
      label: localize('averageRate'),
      template: r => <>{r?.averageRate}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => (
        <div style={{ minWidth: 220, padding: 0 }}>
          {formatDate(r?.createdDate)}
        </div>
      ),
    },
  ]

  const [mode, setMode] = useState(null)
  const [selectedRate, setSelectedRate] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [showError, setShowError] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableData, setTableData] = useState([])
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/foreignexchangerate/update',
            selectedRate || {
              baseCurrencyId: '',
            },
            false
          )
        : await postData(
            '/foreignexchangerate/create',
            selectedRate || {
              baseCurrencyId: '',
            },
            false
          )

    if (success) {
      setMode(null)
      setSelectedRate(null)
      setIsBusy(false)
      loadTableData()
      showNotification('Forex Rate', 'success')
    }
    setIsBusy(false)
  }

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/foreignExchangeRate/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  const handleAdd = async () => {
    setMode('add')
    setSelectedRate(null)
  }

  function closeModal() {
    setMode(null)
    setSelectedRate(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleViewAuditLogs = item => {
    setSelectedRate(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('systemAdministration'), localize('forexRates')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={selectedRate}
          title={selectedRate?.baseCurrencyDescription}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="forexFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setMode('edit')
                              setSelectedRate(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {(mode === 'add' || mode === 'edit') && (
        <>
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('forexRate')}
            handleClose={closeModal}
            handleAction={handleSubmit}
            actionText={mode === 'add' ? 'create' : 'update'}
            modalClassName="modal-lg"
          >
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2 g-3">
                  <div className="col-md-6">
                    <div
                      className="with-validation d-flex align-items-center mb-2"
                      validation-message={
                        globalValidationErrors['BaseCurrencyId']?.message
                      }
                    >
                      <label className="text-capitalize col-md-3">
                        {localize('baseCurrency')}
                      </label>
                      {mode === 'edit' ? (
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={selectedRate?.baseCurrencyDescription}
                          disabled={mode === 'edit'}
                        />
                      ) : (
                        <CurrencyLookup
                          displayValue={selectedRate?.baseCurrencyDescription}
                          onSelect={v => {
                            clearValidation('BaseCurrencyId')
                            setSelectedRate({
                              ...selectedRate,
                              baseCurrencyId: v.id,
                              baseCurrencyDescription: v.description,
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="with-validation d-flex align-items-center mb-2"
                      validation-message={
                        globalValidationErrors['QuoteCurrencyId']?.message
                      }
                    >
                      <label className="text-capitalize col-md-3">
                        {localize('quoteCurrency')}
                      </label>
                      {mode === 'edit' ? (
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={selectedRate?.quoteCurrencyDescription}
                          disabled={mode === 'edit'}
                        />
                      ) : (
                        <CurrencyLookup
                          displayValue={selectedRate?.quoteCurrencyDescription}
                          onSelect={v => {
                            clearValidation('QuoteCurrencyId')
                            setSelectedRate({
                              ...selectedRate,
                              quoteCurrencyId: v.id,
                              quoteCurrencyDescription: v.description,
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-2 g-3">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-2">
                      <label className="text-capitalize col-md-3">
                        {localize('effectiveDate')}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        defaultValue={moment(
                          selectedRate?.effectiveDate
                        ).format('YYYY-MM-DD')}
                        disabled={mode === 'edit'}
                        onChange={e => {
                          setSelectedRate({
                            ...selectedRate,
                            effectiveDate: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-2">
                      <label className="text-capitalize col-md-3">
                        {localize('averageRate')}
                      </label>
                      <MaskNumber
                        defaultMaskValue={selectedRate?.averageRate}
                        className={`
                       ${showError && 'border border-danger'}
                      `}
                        onMaskNumber={e => {
                          if (Number(e.target.value) > 100) {
                            setShowError(true)
                          } else {
                            setShowError(false)
                          }
                          setSelectedRate({
                            ...selectedRate,
                            averageRate: e.target.value,
                          })
                        }}
                        numeralThousandsGroupStyle="none"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </ModalTemplate>
        </>
      )}
    </>
  )
}

export default ForexRates
