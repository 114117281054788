/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import CreateMorgueService from './CreateMorgueService/CreateMorgueService'
import ViewApplicableCharges from './ViewApplicableCharges/ViewApplicableCharges'

function MorgueServices() {
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [modalMode, setModalMode] = useState(null)
  const [morgueService, setMorgueService] = useState({})
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [search, setSearch] = useState('')

  const columns = [
    { label: 'description', template: r => <>{r?.description}</> },
    {
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: 'createdDate', template: r => <>{formatDate(r?.createdDate)}</> },
  ]

  const fetchData = async () => {
    setIsBusy(true)
    const url = '/MorgueService/find-morgue-services-by-filter-in-page'

    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  const handleAddButton = () => {
    setModalMode('add')
    setMorgueService({})
  }

  const handleSelectService = (r, m) => {
    setMorgueService(r)
    setModalMode(m)
  }

  const handleClose = () => {
    setModalMode(null)
    setMorgueService({})
  }

  useEffect(() => {
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      {modalMode === 'view' && (
        <ViewApplicableCharges
          handleClose={handleClose}
          morgueService={morgueService}
          mode={modalMode}
        />
      )}

      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateMorgueService
          handleClose={handleClose}
          mode={modalMode}
          morgueService={morgueService}
          setMorgueService={setMorgueService}
          fetchData={fetchData}
        />
      )}

      <BreadCrumb
        tree={[localize('morgueManagement'), localize('morgueServices')]}
      />

      <>
        <div className="card rounded">
          <div className="card-body bg-white">
            <div className="row mb-2">
              <div className="col-md-12 mb-4">
                <AddNewButton handleClickAddButton={handleAddButton} />
              </div>
            </div>
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center col-md-4">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div className="offset-4 col-md-3">
                    <form
                      className="d-flex align-items-center justify-content-end"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label htmlFor="searchTable">{localize('search')}</label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        value={search}
                        onChange={e => {
                          setSearch(e.target.value)
                        }}
                      />

                      <button className="btn btn-primary ms-2">
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <SimpleTable
                    columns={columns}
                    data={data.pageCollection}
                    contextMenu={r => (
                      <>
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleSelectService(r, 'view')
                          }}
                        >
                          <i className="uil uil-eye"></i>
                          <span>{localize('viewApplicableCharges')}</span>
                        </div>
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleSelectService(r, 'edit')
                          }}
                        >
                          <i className="uil uil-edit"></i>
                          <span>{localize('edit')}</span>
                        </div>
                      </>
                    )}
                  />
                  <Pager
                    itemsCount={data.itemsCount}
                    pageSize={reqParams.pageSize}
                    pageIndex={reqParams.pageIndex}
                    pageBatchSize={10}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </>
  )
}

export default MorgueServices
