/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'
import { loadMedia } from '../../../../Helpers/Modules/media'
import Swal from 'sweetalert2'
import CreateDocument from './CreateDocument'
import { viewPDF } from '../../../../Helpers/Files/PDF'

function Documents() {
  const [isBusy, setIsBusy] = useState(false)
  const [documents, setDocuments] = useState(null)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
  })
  const [refresh, setRefresh] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [pdf, setPdf] = useState(null)

  useEffect(() => {
    loadDocuments()
    // eslint-disable-next-line
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  async function loadDocuments() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/employeeDocument/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) setDocuments(data)
    setIsBusy(false)
  }

  async function loadMediaItem(id) {
    let mediaItem = null
    setIsBusy(true)
    mediaItem = await loadMedia(id)
    setIsBusy(false)
    if (mediaItem)
      // window.open(
      setPdf(`data:${mediaItem?.contentType};base64,${mediaItem?.content}`)
    //   '_blank'
    // )
    else Swal.fire('No File', 'Cant load file', 'error')
  }

  const columns = [
    { label: localize('title'), template: r => <>{r?.fileTitle}</> },
    { label: localize('mimeType'), template: r => <>{r?.fileMIMEType}</> },
    {
      label: localize('employeeName'),
      template: r => <>{r?.employeeCustomerFullName}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.employeeCustomerIndividualNationalityDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.employeeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.employeeCustomerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => (
        <>{r?.employeeCustomerIndividualMaritalStatusDescription}</>
      ),
    },
    {
      label: localize('designation'),
      template: r => <>{r?.employeeDesignationDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.employeeBranchDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r?.employeeDepartmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r?.employeeEmployeeTypeDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r?.employeeBloodGroupDescription}</>,
    },
    {
      label: localize('nssfNumber'),
      template: r => <>{r?.employeeNationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('nhifNumber'),
      template: r => <>{r?.employeeNationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('pin'),
      template: r => <>{r?.employeeCustomerPersonalIdentificationNumber}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('employees'),
          localize('employeeDocuments'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          <div className="mb-3">
            <button
              className="btn btn-success px-5"
              onClick={() => {
                setSelectedDocument(null)
                setModalMode('add')
              }}
            >
              {localize('create')}
            </button>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex gap-2 align-items-center">
                  <label
                    htmlFor=""
                    style={{ width: 'fit-content' }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                    style={{ width: 'fit-content' }}
                    className="form-select"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <label htmlFor="" className="text-capitalize">
                    {localize('search')}
                  </label>
                  <form
                    className="d-flex align-items-center gap-2"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({ ...reqParams, pageIndex: 0 })
                      setRefresh(!refresh)
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.filterText}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }
                    />

                    <button className="btn btn-primary">
                      <i className="uil uil-search"></i>
                    </button>
                  </form>
                </div>
              </div>
              <SimpleTable
                data={documents?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => loadMediaItem(r?.id)}
                    >
                      <i className="uil uil-eye"></i>
                      <span htmlFor="" className="text-capitalize">
                        {localize('view')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedDocument(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span htmlFor="" className="text-capitalize">
                        {localize('edit')}
                      </span>
                    </div>
                  </>
                )}
              />
              <div className="mt-2">
                <Pager
                  itemsCount={documents?.itemsCount}
                  pageSize={reqParams?.pageSize}
                  pageIndex={reqParams?.pageIndex}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
      {modalMode === 'add' && !selectedDocument && (
        <CreateDocument
          refreshDocuments={() => setRefresh(!refresh)}
          close={() => {
            setModalMode(null)
            setSelectedDocument(null)
          }}
        />
      )}
      {modalMode === 'edit' && selectedDocument && (
        <CreateDocument
          close={() => {
            setModalMode(null)
            setSelectedDocument(null)
          }}
          editMode
          document={selectedDocument}
          refreshDocuments={() => setRefresh(!refresh)}
        />
      )}

      {pdf && viewPDF(pdf, false, () => setPdf(null), null)}
    </>
  )
}

export default Documents
