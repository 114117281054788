/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import SavingsProductLookup from '../../../../../Components/Lookup/SavingsProductLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import SelectableCommissions from '../../../../../Components/Selectables/SelectableCommissions'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'

function WithdrawalSettlementModeModal({
  mode,
  onHideModal,
  setSelectedWithdrawalSettlementMode,
  selectedWithdrawalSettlementMode,
  tabLoading,
  loadTableData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/WithdrawalSettlementMode/create-withdrawal-settlementmode-with-commissions'
        : '/WithdrawalSettlementMode/update-withdrawalSettlementmode-with-commissions'
    const { success } = await postData(
      url,
      {
        withdrawalSettlementMode: selectedWithdrawalSettlementMode || {},
        commissionDTOs:
          selectedWithdrawalSettlementMode?.commissions.filter(
            x => x.checked
          ) || [],
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      closeModal()
      showNotification('Withdrawal Settlement Mode', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    onHideModal()
    setSelectedWithdrawalSettlementMode(null)
    setGlobalValidationErrors({})
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalClassName="modal-lg"
        modalTitle={'withdrawalSettlementMode'}
        modalMode={mode}
        handleAction={handleSubmit}
        handleClose={closeModal}
        actionText={mode === 'add' ? localize('create') : localize('update')}
        hideUpdate={mode === 'view'}
        hideCancel={mode === 'edit'}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                disabled={mode === 'view'}
                id="withdrawalSettlementModeChargeUnconsolidatedBalane"
                onCheck={e => {
                  setSelectedWithdrawalSettlementMode({
                    ...selectedWithdrawalSettlementMode,
                    chargeUnconsolidatedBalance: e,
                  })
                }}
                checked={
                  selectedWithdrawalSettlementMode?.chargeUnconsolidatedBalance
                }
              />
              <label
                htmlFor="withdrawalSettlementModeChargeUnconsolidatedBalane"
                className="text-capitalize"
              >
                {localize('chargeUnconsolidatedBalance')}?
              </label>

              <Checkbox
                disabled={mode === 'view'}
                id="withdrawalSettlementModesSuppressNettingOff"
                onCheck={e => {
                  setSelectedWithdrawalSettlementMode({
                    ...selectedWithdrawalSettlementMode,
                    suppressNettingOff: e,
                  })
                }}
                checked={selectedWithdrawalSettlementMode?.suppressNettingOff}
              />
              <label
                htmlFor="withdrawalSettlementModesSuppressNettingOff"
                className="text-capitalize"
              >
                {localize('suppressNettingOff')}?
              </label>

              {mode === 'edit' || mode === 'view' ? (
                <>
                  <Checkbox
                    disabled={mode === 'view'}
                    id="IsWithdrawalSettlementModesDefault"
                    onCheck={e => {
                      setSelectedWithdrawalSettlementMode({
                        ...selectedWithdrawalSettlementMode,
                        isDefault: e,
                      })
                    }}
                    checked={selectedWithdrawalSettlementMode?.isDefault}
                  />
                  <label
                    htmlFor="IsWithdrawalSettlementModesDefault"
                    className="text-capitalize"
                  >
                    {localize('isDefault')}?
                  </label>
                </>
              ) : (
                ''
              )}
              <Checkbox
                disabled={mode === 'view'}
                id="isWithdrawalSettlementModesLocked"
                onCheck={e => {
                  setSelectedWithdrawalSettlementMode({
                    ...selectedWithdrawalSettlementMode,
                    isLocked: e,
                  })
                }}
                checked={selectedWithdrawalSettlementMode?.isLocked}
              />
              <label
                htmlFor="isWithdrawalSettlementModesLocked"
                className="text-capitalize"
              >
                {localize('isLocked')}?
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2 g-3">
              <div className="col-md-12">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'WithdrawalSettlementMode.Description'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-2">
                    {localize('name')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    defaultValue={selectedWithdrawalSettlementMode?.description}
                    className="form-control"
                    onChange={e => {
                      clearValidation(['WithdrawalSettlementMode.Description'])
                      setSelectedWithdrawalSettlementMode({
                        ...selectedWithdrawalSettlementMode,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'WithdrawalSettlementMode.Description'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-4 ">
                    {localize('consolidationProduct')}
                  </label>
                  {mode === 'view' ? (
                    <input
                      disabled
                      defaultValue={
                        selectedWithdrawalSettlementMode?.consolidationInvestmentProductDescription
                      }
                      className="form-control"
                    />
                  ) : (
                    <InvestmentProductLookup
                      displayValue={
                        selectedWithdrawalSettlementMode?.consolidationInvestmentProductDescription
                      }
                      onSelect={v => {
                        clearValidation([
                          'WithdrawalSettlementMode.ConsolidationInvestmentProductDescription',
                        ])
                        setSelectedWithdrawalSettlementMode(prev => ({
                          ...prev,
                          consolidationInvestmentProductDescription:
                            v.description,
                          consolidationInvestmentProductId: v.id,
                        }))
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'WithdrawalSettlementMode.Description'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-3 me-2">
                    {localize('disbursementProduct')}
                  </label>
                  {mode === 'view' ? (
                    <input
                      disabled
                      defaultValue={
                        selectedWithdrawalSettlementMode?.savingsProductDescription
                      }
                      className="form-control"
                    />
                  ) : (
                    <SavingsProductLookup
                      displayValue={
                        selectedWithdrawalSettlementMode?.savingsProductDescription
                      }
                      onSelect={v => {
                        clearValidation([
                          'WithdrawalSettlementMode.SavingsProductDescription',
                        ])
                        setSelectedWithdrawalSettlementMode(prev => ({
                          ...prev,
                          savingsProductDescription: v.description,
                          savingsProductId: v.id,
                        }))
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {tabLoading ? (
              <Loader />
            ) : (
              <div className="row mb-2">
                <div className="col md-12">
                  <Tab
                    tabItems={[
                      {
                        label: localize('applicableCharges'),
                        item: (
                          <SelectableCommissions
                            disabled={mode === 'view'}
                            setSelectableCommissionItem={
                              setSelectedWithdrawalSettlementMode
                            }
                            selectableCommissionItem={
                              selectedWithdrawalSettlementMode
                            }
                            prefix={'create-debit-type-modal'}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default WithdrawalSettlementModeModal
