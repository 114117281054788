/** @format */

import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'

export const ActiveDirectoryLookup = ({ onSelect, displayValue }) => {
  const columns = [
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    { label: localize('emailAddress'), template: r => <>{r.emailAddress}</> },
    { label: localize('firstName'), template: r => <>{r.firstName}</> },
    { label: localize('lastName'), template: r => <>{r.lastName}</> },
    { label: localize('loginName'), template: r => <>{r.loginName}</> },
    {
      label: localize('distinguishedName'),
      template: r => <>{r.distinguishedName}</>,
    },
  ]

  return (
    <SimpleLookup
      title={localize('activeDirectoryLookup')}
      columns={columns}
      url="/lookup/ActiveDirectory/find-active-directory-principals-by-filter-in-page"
      params={{ text: '', pageIndex: 0, pageSize: 10 }}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
