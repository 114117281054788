/** @format */

import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { stripNonNumericCharacters } from '../../../../../../Helpers/formatters'
/* eslint-disable */
import React, { useState } from 'react'
import { MaskPercentage } from '../../../../../../Components/InputMasks/MaskPercentage'

export const CreateLoanRecoveryExceptionModal = ({
  loanRecoveryExceptionModal,
  setLoanRecoveryExceptionModal,
  selectedCustomerAccount,
  setSelectedCustomerAccount,
  globalValidationErrors,
  clearValidation,
}) => {
  const handleOnCustomerLookupAccount = customerAccount => {
    setSelectedCustomerAccount(customerAccount)
  }

  const [selectedPayoutRecoveryMode, setSelectedPayoutRecoveryMode] =
    useState('1793')

  return (
    <>
      <div className="row">
        <CustomerAccountPartial
          onClearValidation={() => {
            clearValidation('CustomerAccountId')
          }}
          message={globalValidationErrors?.CustomerAccountId?.message}
          customer={selectedCustomerAccount}
          onCustomerLookup={handleOnCustomerLookupAccount}
        />
        <div
          validation-message={
            globalValidationErrors?.PayoutRecoveryMode?.message
          }
          className="col-3 d-flex align-items-center with-validation"
        >
          <label className="col-4 text-capitalize me-2">
            {localize('payoutRecoveryMode')}
          </label>
          <EnumsServerCombo
            value={loanRecoveryExceptionModal?.payoutRecoveryMode}
            customContainerStyle={{ width: '100%' }}
            enumsKey={'PayoutRecoveryMode'}
            onChange={e => {
              setSelectedPayoutRecoveryMode(e.value)
              setLoanRecoveryExceptionModal({
                ...loanRecoveryExceptionModal,
                payoutRecoveryMode: e.value,
              })
            }}
          />
        </div>
        {selectedPayoutRecoveryMode === '1793' && (
          <div
            validation-message={
              globalValidationErrors?.PayoutRecoveryPercentage?.message
            }
            className="col-3 d-flex align-items-center with-validation"
          >
            <label className="col-4 me-2 text-capitalize">
              {localize('payoutRecoveryPercentage')}
            </label>
            <MaskPercentage
              defaultMaskValue={
                loanRecoveryExceptionModal?.payoutRecoveryPercentage
              }
              onMaskNumber={e => {
                setLoanRecoveryExceptionModal({
                  ...loanRecoveryExceptionModal,
                  payoutRecoveryPercentage: stripNonNumericCharacters(
                    e.target.value
                  ),
                })
              }}
            />
          </div>
        )}
        <div
          validation-message={globalValidationErrors?.Remarks?.message}
          className="col-3 d-flex align-items-center with-validation"
        >
          <label className="col-4 me-2 text-capitalize">
            {localize('remarks')}
          </label>
          <input
            className="form-control"
            defaultValue={loanRecoveryExceptionModal?.remarks}
            onChange={e => {
              clearValidation('Remarks')
              setLoanRecoveryExceptionModal({
                ...loanRecoveryExceptionModal,
                remarks: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
