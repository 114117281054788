/** @format */

import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
import React from 'react'
import { ItemRegisterType } from '../../Helpers/constants'
import SimpleLookupWithFilters from './SimpleLookupWithFilters'

export const ItemRegisterLookup = ({ displayValue, onSelect }) => {
  const columns = [
    { label: localize('code'), template: r => <>{r.code}</> },
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.inventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.assetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r.inventoryUnitOfMeasureDescription}</>,
    },
    { label: localize('isLocked'), template: r => <>{r.isLocked}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookupWithFilters
        enumKey={'ItemRegisterType'}
        paramKey={'itemType'}
        displayValue={displayValue}
        onSelect={onSelect}
        url={'/lookup/ItemRegister/find-item-register-by-filter-in-page'}
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        title={localize('itemRegisterLookup')}
        columns={columns}
      />
    </>
  )
}
