/** @format */

import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
/* eslint-disable */
import React from 'react'

export const ChequeBookPaymentVouchersLookup = ({
  onSelect,
  displayValue,
  chequeBookId,
  paymentVoucherDescription,
}) => {
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('paymentVouchers')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{paymentVoucherDescription}</>
        </>
      }
      url="/lookup/ChequeBook/find-payment-vouchers-by-cheque-book-id"
      params={{
        chequeBookId: chequeBookId,
        filterText: '',
        pageSize: 10,
        pageIndex: 0,
      }}
      conditionalField="chequeBookId"
      columns={[
        {
          label: localize('voucherNumber'),
          template: r => <>{r.paddedVoucherNumber}</>,
        },
        {
          label: localize('status'),
          template: r => <>{r.statusDescription}</>,
        },
        { label: localize('payee'), template: r => <>{r.payee}</> },
        {
          label: localize('amount'),
          template: r => <>{formatCurrency(r.amount)}</>,
        },
        { label: localize('writeDate'), template: r => <>{r.writeDate}</> },
        { label: localize('reference'), template: r => <>{r.reference}</> },
        { label: localize('paidBy'), template: r => <>{r.paidBy}</> },
        {
          label: localize('paidDate'),
          template: r => <>{formatDate(r.paidDate)}</>,
        },
        { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
