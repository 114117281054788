/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'

function LoanRequestGuarantors({ loanRequest }) {
  const [guarantors, setGuarantors] = useState([])
  const [fetching, setFetching] = useState(false)
  const [reqParams, setReqParams] = useState({
    loanRequestId: loanRequest?.id,
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
    customerFilter: 0,
  })
  const [refresh, setRefresh] = useState(false)
  const [customerFilter, setCustomerFilter] = useState(0) // memberNumber

  useEffect(() => {
    findLoanGuarantors()
    // eslint-disable-next-line
  }, [reqParams?.pageIndex, reqParams?.pageSize, refresh])

  async function findLoanGuarantors() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/loanRequest/find-loan-request-guarantors-by-loan-case-id-in-page',
      reqParams,
      false
    )

    if (success) {
      setGuarantors(data)
    }
    setFetching(false)
  }

  const columns = [
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r?.customerMembershipStatusDescription}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')} #)`,
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.customerIdentificationNumber}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.loaneeCustomerAddressMobileLine}</>,
    },
    {
      label: localize('totalShares'),
      template: r => <>{formatCurrency(r?.totalShares)}</>,
    },
    {
      label: localize('committedShares'),
      template: r => <>{formatCurrency(r?.committedShares)}</>,
    },
    {
      label: localize('amountGuaranteed'),
      template: r => <>{formatCurrency(r?.amountGuaranteed)}</>,
    },
    {
      label: localize('amountPledged'),
      template: r => <>{formatCurrency(r?.amountPledged)}</>,
    },
    {
      label: localize('appraisalFactor'),
      template: r => <>{r?.appraisalFactor}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanStatus'),
      template: r => <>{r?.loanCaseStatusDescription}</>,
    },
    {
      label: localize('loanBranch'),
      template: r => <>{r?.loanCaseBranchDescription}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanCaseLoanPurposeDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <>
      <div className="my-2 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize">
            {localize('recordsPerPage')}
          </label>
          <select
            className="form-select"
            style={{ maxWidth: 'fit-content' }}
            onChange={v =>
              setReqParams({ ...reqParams, pageSize: v.target.value })
            }
            value={reqParams?.pageSize}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>

        <div className="d-flex align-items-center gap-2">
          <label htmlFor="" className="text-capitalize">
            {localize('search')}
          </label>
          <EnumsServerCombo
            enumsKey="CustomerFilter"
            value={customerFilter}
            onChange={e => setCustomerFilter(e.value)}
          />
          <input
            className="form-control"
            value={reqParams?.filterText}
            onChange={e =>
              setReqParams({ ...reqParams, filterText: e.target.value })
            }
          />
          <button
            className="btn btn-primary"
            onClick={() => setRefresh(!refresh)}
          >
            <i className="uil uil-filter"></i>
          </button>
        </div>
      </div>
      <SimpleTable columns={columns} data={guarantors?.pageCollection || []} />
      <div className="mt-2">
        <Pager
          itemsCount={guarantors?.itemsCount || 0}
          pageSize={reqParams?.pageSize}
          pageIndex={reqParams?.pageIndex}
          onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
        />
      </div>
    </>
  )
}

export default LoanRequestGuarantors
