/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _g = {
  groups: {
    en: 'groups',
    sw: 'vikundi',
  },
  group: {
    en: 'group',
    sw: 'kikundi',
  },
  goTo: {
    en: 'go to',
    sw: 'enda',
  },
  gender: {
    en: 'gender',
    sw: 'jinsia',
  },
  gracePeriod: {
    en: 'grace period',
    sw: 'kipindi cha neema',
  },
  gLAccount: {
    en: 'g/l account',
    sw: 'akaunti ya g/l',
  },
  GLAccounts: {
    en: 'g/l accounts',
    sw: 'mpangilio wa akaunti za fedha',
  },
  gLAccountName: {
    en: 'g/l account name',
    sw: 'jina la akaunti g/l',
  },
  gLAccountCostDistributionRule: {
    en: 'g/l account cost distr. rule',
    sw: 'usambazaji wa gharama g/l',
  },
  GLAccountDetermination: {
    en: 'g/l account determination',
    sw: 'uamuzi wa akaunti ya g/l',
  },
  gLAccountCode: {
    en: 'g/l account code',
    sw: 'nambari ya akaunti ya g/l',
  },
  gLAccountCostDistrRule: {
    en: 'g/l account cost distr. rule',
    sw: 'usambazaji wa gharama g/l',
  },
  'g/LAccountCurrency': {
    en: 'g/l account currency',
    sw: 'sarafu ya akaunti ya g/l',
  },
  guarantorSecurityMode: {
    en: 'guarantor security mode',
    sw: 'hali ya usalama ya mdhamini',
  },
  groupBy: {
    en: 'group by',
    sw: 'kikundi kwa',
  },
  gainLossChartOfAccountName: {
    en: 'gain loss g/l account name',
    sw: 'pata hasara ya jina la akaunti ya g/l',
  },
  globalConfiguration: {
    en: 'global configuration',
    sw: 'usanidi wa kila mahali',
  },
  genderDescription: {
    en: 'gender description',
    sw: 'maelezo ya jinsia',
  },
  groupName: {
    en: 'group name',
    sw: 'jina la kikundi',
  },
  'G/LAccountTransactions': {
    en: 'g/l account transactions',
    sw: 'akaunti ya shughuli za g/l',
  },

  gLAccountTransactions: {
    en: 'g/l account transactions',
    sw: 'akaunti ya shughuli za g/l',
  },

  gLAccountTransactionsArchived: {
    en: 'g/l account transactions (archived)',
    sw: 'akaunti ya shughuli za g/l (zilizohifadhiwa)',
  },
  generateSeries: {
    en: 'generate series',
    sw: 'unda mfululizo',
  },
  generalLedgerAccounts: {
    en: 'general ledger accounts',
    sw: 'akaunti za msingi za uhasibu',
  },
  generalLedger: {
    en: 'general ledger',
    sw: 'msingi wa uhasibu',
  },
  'g/LAccount': {
    en: 'g/l account',
    sw: 'akaunti ya g/l',
  },
  'g/LAccountSummary': {
    en: 'g/l account summary',
    sw: 'muhtasari wa akaunti ya g/l',
  },
  generalLedgerOrigination: {
    en: 'general ledger - origination',
    sw: 'msingi wa uhasibu - kipindi cha neema',
  },
  generalLedgerVerification: {
    en: 'general ledger - verification',
    sw: 'msingi wa uhasibu - kipindi cha neema',
  },
  generalLedgerAuthorization: {
    en: 'general ledger - authorization',
    sw: 'msingi wa uhasibu - kipindi cha neema',
  },
  gLAccountDistributionRule: {
    en: 'g/l account dist. rule',
    sw: 'akaunti ya usambasaji ya g/l',
  },
  guarantorMultiplier: {
    en: 'guarantor multiplier',
    sw: 'kizidishi cha mdhamini',
  },
  'g/LCostDistributionRuleDescription': {
    en: 'g/l cost distribution rule description',
    sw: 'maelezo ya sheria ya usambazaji wa gharama ya g/l',
  },
  generateMonths: {
    en: 'generate months',
    sw: 'unda miezi',
  },
  generalLedgers: {
    en: 'general ledgers',
    sw: 'msingi wa uhasibu',
  },
  guarantorsManagement: {
    en: 'guarantors management',
    sw: 'usimamizi wa wadhamini',
  },
  'guarantorRelieving&Reinstating': {
    en: 'guarantor relieving & reinstating',
    sw: 'kumwondoa na kumrejesha mdhamini',
  },
  guarantorAttachment: {
    en: 'guarantor attachment',
    sw: 'viambatanisho vya mdhamini',
  },
  guarantorSubstitution: {
    en: 'guarantor substitution',
    sw: 'kubadilisha mdhamini',
  },
  guarantorManagement: {
    en: 'guarantor management',
    sw: 'usimamizi wa mdhamini',
  },
  guarantorReleasing: {
    en: 'guarantor releasing',
    sw: 'kumwondoa mdhamini',
  },
  guarantors: {
    en: 'guarantors',
    sw: 'wadhamini',
  },
  genericAccountCount: {
    en: 'generic account count',
    sw: 'idadi ya akaunti ya jumla',
  },
  genericAccountNPACount: {
    en: 'generic account NPA count',
    sw: 'idadi ya akaunti ya jumla NPA',
  },
  genericAccountCountClosed: {
    en: 'generic account count closed',
    sw: 'idadi ya akaunti ya jumla imefungwa',
  },
  genericAccountInArrearsCount: {
    en: 'generic account in arrears count',
    sw: 'idadi ya akaunti ya jumla iliyopitwa na wakati',
  },
  guarantorDetails: {
    en: 'guarantor details',
    sw: 'taarifa za mdhamini',
  },
  guarantorSubstitutionRequest: {
    en: 'guarantor substitution request',
    sw: 'ombi la kubadilisha mdhamini',
  },
  guarantorsAttachment: {
    en: 'guarantors attachment',
    sw: 'viambatanisho vya wadhamini',
  },
  guarantorSubstitutionRequests: {
    en: 'guarantor substitution requests',
    sw: 'ombi la kubadilisha mdhamini',
  },
  guarantorAttachmentHistory: {
    en: 'guarantor attachment history',
    sw: 'historia ya viambatanisho vya mdhamini',
  },
  'guarantorRelieving/Reinstating': {
    en: 'guarantor relieving/reinstating',
    sw: 'kumwondoa/kumrejesha mdhamini',
  },
  'gracePeriod(days)': {
    en: 'grace period (days)',
    sw: 'kipindi cha neema (siku)',
  },
  'g/lAccountStatement': {
    en: 'g/l account statement',
    sw: 'taarifa ya akaunti ya g/l',
  },
  glAccount: {
    en: 'g/l account',
    sw: 'akaunti ya g/l',
  },
  glAccountName: {
    en: 'g/l account name',
    sw: 'jina la akaunti ya g/l',
  },
  glAccountCostDistributionRuleDescription: {
    en: 'g/l account cost distr. rule',
    sw: 'usambazaji wa gharama ya akaunti ya g/l',
  },
  groupType: {
    en: 'group type',
    sw: 'aina ya kikundi',
  },
  groupMembers: {
    en: 'group members',
    sw: 'wanachama wa kikundi',
  },
  groupValueType: {
    en: 'group value type',
    sw: 'aina ya thamani ya kikundi',
  },
  groupPercentageValue: {
    en: 'group percentage value',
    sw: 'asilimia ya thamani ya kikundi',
  },
  groupFixedValue: {
    en: 'group fixed value',
    sw: 'thamani ya kikundi',
  },
  groupMinimumValue: {
    en: 'group minimum value',
    sw: 'thamani ya chini ya kikundi',
  },
  groupValue: {
    en: 'group value',
    sw: 'thamani ya kikundi',
  },
  'g/LAccountName(GoodsRecievedNotInvoiced)': {
    en: 'g/l account name (goods received not invoiced)',
    sw: 'jina la akaunti ya g/l (bidhaa zilizopokelewa bila hati ya malipo)',
  },
  'gLAccountCostDistributionRule(GoodsRecievedNotInvoiced)': {
    en: 'g/l account cost distr. rule (goods received not invoiced)',
    sw: 'usambazaji wa gharama ya akaunti ya g/l (bidhaa zilizopokelewa bila hati ya malipo)',
  },
  'gRNIG/LAccount': {
    en: 'GRNI g/l account',
    sw: 'akaunti ya GRNI g/l',
  },
  GoodsReceivedNotes: {
    en: 'goods received notes',
    sw: 'hati ya malipo ya bidhaa',
  },
  goodsDispatchNotes: {
    en: 'goods dispatch notes',
    sw: 'hati ya kutuma bidhaa',
  },
  goodsDispatchNote: {
    en: 'goods dispatch note',
    sw: 'hati ya kutuma bidhaa',
  },
  goodsDispatchNoteItems: {
    en: 'goods dispatch note items',
    sw: 'bidhaa zilizotumwa',
  },
  'gain-lossChartOfAccountName': {
    en: 'gain-loss g/l account name',
    sw: 'pata hasara ya jina la akaunti ya g/l',
  },
  goodsReceivedNotes: {
    en: 'goods received notes',
    sw: 'hati ya malipo ya bidhaa',
  },
  'gain-lossChartOfAccount': {
    en: 'gain-loss g/l account',
    sw: 'pata hasara ya akaunti ya g/l',
  },
  goodsReceivedNoteLookup: {
    en: 'goods received note lookup',
    sw: 'tafuta hati ya malipo ya bidhaa',
  },
  grnStatus: {
    en: 'GRN status',
    sw: 'hali ya GRN',
  },
  goodsReceivedNote: {
    en: 'goods received note',
    sw: 'hati ya malipo ya bidhaa',
  },
  goodsReceivedNoteItems: {
    en: 'goods received note items',
    sw: 'bidhaa zilizolipwa',
  },
  GLAccountCostDistributionRule: {
    en: 'g/l account cost distr. rule',
    sw: 'usambazaji wa gharama ya akaunti ya g/l',
  },
  goodsReceivedNoteApprovalitems: {
    en: 'goods received note approval items',
    sw: 'bidhaa zilizopokea idhini',
  },
  'glAccountName(goodsReceivedNotInvoiced)': {
    en: 'g/l account name (goods received not invoiced)',
    sw: 'jina la akaunti ya g/l (bidhaa zilizopokelewa bila hati ya malipo)',
  },
  'gLAccountCostDistributionRule(goodsReceivedNotInvoiced)': {
    en: 'g/l account cost distr. rule (goods received not invoiced)',
    sw: 'usambazaji wa gharama ya akaunti ya g/l (bidhaa zilizopokelewa bila hati ya malipo)',
  },
}

export default _g
