/** @format */

import React, { useEffect, useState } from 'react'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import CreateReceivables from './CreateReceivables/CreateReceivables'
import ViewAutoShop from './ViewAutoShop/ViewAutoShop'

const AutoShop = () => {
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
    startDate: null,
    endDate: null,
    customDateFilter: 15,
    autoshopReceivableFilter: 0,
  })

  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('voucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedCustomerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.valueDate, true)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const fetchItems = async () => {
    setIsBusy(true)
    const url =
      '/AutoShopReceivable/find-autoshop-receivable-by-date-and-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setItems(data)
    }
    setIsBusy(false)
  }

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleClose = () => {
    setMode(false)
  }

  useEffect(() => {
    fetchItems()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams?.pageIndex, reqParams?.pageSize])

  return (
    <>
      {mode === 'add' && (
        <CreateReceivables
          mode={mode}
          handleClose={handleClose}
          fetchItems={fetchItems}
        />
      )}

      {mode === 'view' && (
        <ViewAutoShop data={item} handleClose={handleClose} mode={mode} />
      )}

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-2">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center my-2">
                  <label
                    className="text-capitalize"
                    htmlFor="morguePatientLookupPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="morguePatientLookupPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageCount: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="d-flex align-item-center">
                  <label
                    htmlFor="search"
                    className="text-capitalize col-4 me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                          pageIndex: 0,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                          pageIndex: 0,
                        })
                      }
                    }}
                  />
                </div>

                <div className="d-flex align-items-center">
                  <label
                    className="ms-2 text-capitalize me-3"
                    htmlFor="searchTable"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    value={reqParams?.autoshopReceivableFilter}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey={'VehicleFilter'}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        autoshopReceivableFilter: e.value,
                      })
                    }}
                  />
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-3"
                    defaultValue={reqParams.filterText}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchItems()
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchItems()
                      }
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={items?.pageCollection}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setMode('view')
                        setItem(r)
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      {localize('view')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={items?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AutoShop
