/** @format */

import React from 'react'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

export const AccountHistory = ({ accountHistories, isBusy }) => {
  const columns = [
    {
      label: localize('operation'),
      template: r => r.managementActionDescription,
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
    {
      label: localize('reference'),
      template: r => r.reference,
    },
    {
      label: localize('applicationUserName'),
      template: r => r.applicationUserName,
    },
    {
      label: localize('environmentUserName'),
      template: r => r.environmentUserName,
    },
    {
      label: localize('environmentMachineName'),
      template: r => r.environmentMachineName,
    },
    {
      label: localize('environmentDomainName'),
      template: r => r.environmentDomainName,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => r.environmentOSVersion,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => r.environmentMACAddress,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => r.environmentMotherboardSerialNumber,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => r.environmentProcessorId,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => r.environmentIPAddress,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <SimpleTable selectables columns={columns} data={accountHistories} />
      )}
    </>
  )
}
