/** @format */
import LoanProducts from '../../../../Components/Selectables/Plain/LoanProducts'

export const CreditTypesConcessionExemptLoanProducts = ({
  creditTypesConcessionExemptLoanProducts,
  loading,
  disabled,
  onCheckCreditTypesConcessionExemptLoanProducts,
  prefix,
}) => {
  return (
    <LoanProducts
      disabled={disabled}
      prefix={prefix}
      loanProductsToBeSelected={creditTypesConcessionExemptLoanProducts}
      isBusy={loading}
      onCheckLoanProduct={r => {
        onCheckCreditTypesConcessionExemptLoanProducts &&
          onCheckCreditTypesConcessionExemptLoanProducts(r)
      }}
    />
  )
}
