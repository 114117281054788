/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import Loader from '../../../../Components/Loaders/Loader'
import PostingPeriodLookup from '../../../../Components/Lookup/PostingPeriodLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { postData, putData } from '../../../../Helpers/webApi'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'

const HolidayModal = ({
  mode,
  selectedHoliday,
  setSelectedHoliday,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/Holiday/update',
            selectedHoliday || {
              description: '',
            },
            false
          )
        : await postData(
            '/Holiday/create',
            selectedHoliday || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      closeModal()
      loadTableData()
      showNotification('Holidays', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }
  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={'holiday'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cHolidayIsLocked"
              onCheck={e => {
                setSelectedHoliday({
                  ...selectedHoliday,
                  isLocked: e,
                })
              }}
              checked={selectedHoliday?.isLocked}
            />
            <label htmlFor="cHolidayIsLocked" className="text-capitalize">
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 align-items-center g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['PostingPeriodDescription']?.message
                }
              >
                <label className="text-capitalize col-3">
                  {localize('postingPeriod')}
                </label>

                {mode === 'edit' ? (
                  <input
                    defaultValue={selectedHoliday?.postingPeriodDescription}
                    className="form-control"
                    disabled
                  />
                ) : (
                  <PostingPeriodLookup
                    displayValue={selectedHoliday?.postingPeriodDescription}
                    disabled={mode === 'edit'}
                    onSelect={v => {
                      clearValidation('PostingPeriodDescription')
                      clearValidation('DurationStartDate')
                      clearValidation('DurationEndDate')
                      setSelectedHoliday(prev => ({
                        ...prev,
                        postingPeriodDescription: v.description,
                        postingPeriodId: v.id,
                        postingPeriodDurationStartDate: v.durationStartDate,
                        postingPeriodDurationEndDate: v.durationEndDate,
                      }))
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedHoliday?.description}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    clearValidation('Description')
                    setSelectedHoliday({
                      ...selectedHoliday,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['DurationStartDate']?.message
                }
              >
                <label className="text-capitalize col-3">
                  {localize('startDate')}
                </label>
                <CustomDatePicker
                  defaultDate={selectedHoliday?.durationStartDate}
                  name={'durationStartDate'}
                  onDateChange={date => {
                    clearValidation('DurationStartDate')
                    setSelectedHoliday({
                      ...selectedHoliday,
                      durationStartDate: date,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['DurationEndDate']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('endDate')}
                </label>
                <CustomDatePicker
                  defaultDate={selectedHoliday?.durationEndDate}
                  name={'durationEndDate'}
                  onDateChange={date => {
                    clearValidation('DurationEndDate')
                    setSelectedHoliday({
                      ...selectedHoliday,
                      durationEndDate: date,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default HolidayModal
