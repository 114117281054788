/** @format */
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { UnpayReasonsCommissions } from './UnpayReasonsCommissions'
import Loader from '../../../../Components/Loaders/Loader'

export const EditUnpayReasonModal = ({
  selectedUnpayReason,
  setSelectedUnpayReason,
  waitingMetadata,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-2 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={selectedUnpayReason?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedUnpayReason({
                    ...selectedUnpayReason,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('code')}
              </label>
              <input
                className="form-control"
                value={selectedUnpayReason?.code}
                onChange={e => {
                  setSelectedUnpayReason({
                    ...selectedUnpayReason,
                    code: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('applicableCharges'),
                    item: (
                      <UnpayReasonsCommissions
                        isBusy={waitingMetadata}
                        selectedUnpayReason={selectedUnpayReason}
                        setSelectedUnpayReason={setSelectedUnpayReason}
                        prefix={'add-unpay-reason-charges'}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
