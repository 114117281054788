/** @format */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../../Components/Loaders/Loader'
import BankersChequeBooksLookup from '../../../../../../Components/Lookup/BankersChequeBooksLookup'
import BankersChequeLeavesLookup from '../../../../../../Components/Lookup/BankersChequeLeavesLookup'
import BranchLookup from '../../../../../../Components/Lookup/BranchLookup'
import ChartOfAccountsLookup from '../../../../../../Components/Lookup/ChartOfAccountsLookup'
import CustomerAccountLookup from '../../../../../../Components/Lookup/CustomerAccountLookup'
import LoanDisbursementQueueLookup from '../../../../../../Components/Lookup/LoanDisbursementQueueLookup'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { inHouseChequeFunding } from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../../../Helpers/extensions'
import { postData } from '../../../../../../Helpers/webApi'

const InHousetCheque = () => {
  const tBrColumns = [
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('chequeLeaveNumber'),
      template: r => <>{r?.bankersChequeLeafLeafNumber}</>,
    },
    {
      label: localize('chequeBookSerialNumber'),
      template: r => <>{r?.bankersChequeLeafBankersChequeBookSerialNumber}</>,
    },
    {
      label: localize('sourceOfFunds'),
      template: r => <>{r?.fundingDescription}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.debitChartOfAccountName}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.debitCustomerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('payee'),
      template: r => <>{r?.payee}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{r?.amount}</>,
    },
    {
      label: localize('amountInWords'),
      template: r => <>{r?.wordifiedAmount}</>,
    },
    {
      label: localize('paddedAmount'),
      template: r => <>{r?.paddedAmount}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('chargeable') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.chargeable ? r?.chargeable?.toString() : 'false')}
        </span>
      ),
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tempEntries, setTempEntries] = useState([])
  const [tBr, setTBr] = useState({
    funding: Object.keys(inHouseChequeFunding)[1],
    chargeable: false,
  })
  const [checkBookId, setCheckBookId] = useState(null)
  const [checkBookSerialNumber, setCheckBookSerialNumber] = useState(null)

  async function handleUpdateChequeWriting() {
    setIsBusy(true)
    for (let b of tempEntries) {
      delete b.id
    }
    const { success } = await postData(
      '/InHouseCheque/add-inhouse-cheque',
      {
        inHouseCheques: tempEntries,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setTempEntries([])
      showNotification('In-House Cheque Writing', 'success')
    }
    setIsBusy(false)
  }

  const updateAction = () => {
    if (tempEntries.length === 0) {
      showNotification(
        'Inhouse Cheque Writing',
        'info',
        `Sorry, but there are no cheques to update!`
      )
    } else {
      handleUpdateChequeWriting()
    }
  }

  const handleCalculation = () => {
    if (!tBr?.availableBalance || tBr?.balance || tBr?.bookBalance) {
      return showNotification(
        'Inhouse Cheque Writing',
        'info',
        `Sorry, but you must first select source of funds account!`
      )
    } else if (!tBr?.bankersChequeLeafId) {
      return showNotification(
        'Inhouse Cheque Writing',
        'info',
        'Sorry, but you must first select a Cheque Leaf'
      )
    } else if (!tBr?.amount) {
      return showNotification(
        'Inhouse Cheque Writing',
        'info',
        'Sorry, but the amount field is required'
      )
    } else if (!tBr?.branchId) {
      return showNotification(
        'Inhouse Cheque Writing',
        'info',
        'Sorry, but the branch identifier field is required'
      )
    } else if (!tBr?.payee) {
      return showNotification(
        'Inhouse Cheque Writing',
        'info',
        'Sorry, but the payee field is required'
      )
    } else if (!tBr?.reference) {
      return showNotification(
        'Inhouse Cheque Writing',
        'info',
        'Sorry, but the reference field is required'
      )
    } else {
      if (
        tBr?.funding === '4' &&
        tempEntries.find(
          d =>
            d.debitAccountClosureRequestId === tBr?.debitAccountClosureRequestId
        )
      ) {
        return showNotification(
          'Inhouse Cheque Writing',
          'info',
          `Sorry, but account closure request for ${tBr?.customerFullName} has already been captured!`
        )
      } else {
        if (
          tBr?.funding === '1' &&
          tBr?.availableBalance < parseFloat(tBr?.amount.replaceAll(',', ''))
        ) {
          return showNotification(
            'Inhouse Cheque Writing',
            'info',
            `Sorry, but amount entered is more the account balance!`
          )
        } else if (
          tBr?.funding === '2' &&
          tBr?.balance < parseFloat(tBr?.amount.replaceAll(',', ''))
        ) {
          return showNotification(
            'Inhouse Cheque Writing',
            'info',
            `Sorry, but amount entered is more the account balance!`
          )
        } else if (
          tBr?.bookBalance < parseFloat(tBr?.amount.replaceAll(',', ''))
        ) {
          return showNotification(
            'Inhouse Cheque Writing',
            'info',
            `Sorry, but amount entered is more the account balance!`
          )
        } else if (
          tBr?.funding === '8' &&
          tBr?.availableBalance < parseFloat(tBr?.amount.replaceAll(',', ''))
        ) {
          return showNotification(
            'Inhouse Cheque Writing',
            'info',
            `Sorry, but amount entered is more the account balance!`
          )
        } else {
          setTempEntries([...tempEntries, { id: generateGuid(), ...tBr }])
          setTBr({
            funding: Object.keys(inHouseChequeFunding)[1],
          })
        }
      }
    }
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('cheques'),
          localize('chequeWriting'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('sourceOfFunds')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{
                        width: '100%',
                      }}
                      value={tBr?.funding}
                      enumsKey={'InHouseChequeFunding'}
                      onChange={e => {
                        setTBr({
                          ...tBr,
                          funding: e.value,
                          fundingDescription:
                            inHouseChequeFunding[e.value].name,
                        })
                      }}
                    />
                  </div>
                  {tBr?.funding === '1' ? (
                    <>
                      <div className="col d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('accountNumber')}
                        </label>
                        <CustomerAccountLookup
                          displayValue={
                            tBr?.debitCustomerAccountFullAccountNumber || ''
                          }
                          type="text"
                          className="form-control ms-2"
                          onSelect={async r => {
                            if (r?.availableBalance <= 0) {
                              showNotification(
                                'Inhouse Cheque Writing',
                                'info',
                                `Sorry, but the selected account has insuffient balance!`
                              )
                            } else {
                              setTBr({
                                ...tBr,
                                debitCustomerAccountId: r?.id,
                                debitChartOfAccountName:
                                  r?.customerAccountTypeTargetProductChartOfAccountName,
                                debitCustomerAccountFullAccountNumber:
                                  r?.fullAccountNumber,
                                availableBalance: r?.availableBalance,
                                debitChartOfAccountId:
                                  r?.customerAccountTypeTargetProductChartOfAccountId,
                                debitChartOfAccountAccountCode:
                                  r?.customerAccountTypeTargetProductChartOfAccountCode,
                                debitCustomerAccountBranchCode:
                                  r?.customerBranchCode,
                                debitCustomerAccountCustomerSerialNumber:
                                  r?.customerSerialNumber,
                                debitCustomerAccountCustomerAccountTypeProductCode:
                                  r?.customerAccountTypeProductCode,
                                debitCustomerAccountCustomerAccountTypeTargetProductCode:
                                  r?.customerAccountTypeTargetProductCode,
                                customerFullName: r?.customerFullName,
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col d-flex align-items-center mb-2">
                        <label className="col-4 text-capitalize">
                          {localize('availableBalance')}
                        </label>
                        <MaskNumber
                          className="form-control"
                          defaultMaskValue={tBr?.availableBalance}
                          disabled
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {tBr?.funding === '2' ? (
                    <>
                      <div className="col d-flex align-items-center mb-2">
                        <label className="col-4 text-capitalize">
                          {localize('chartOfAccount')}
                        </label>
                        <ChartOfAccountsLookup
                          displayValue={tBr?.debitChartOfAccountName}
                          onSelect={async r => {
                            if (r?.balance <= 0) {
                              showNotification(
                                'Inhouse Cheque Writing',
                                'info',
                                `Sorry, but the selected account has insuffient balance!`
                              )
                            } else {
                              setTBr({
                                ...tBr,
                                debitChartOfAccountId: r?.id,
                                debitChartOfAccountName: r?.description,
                                costDistributionRule:
                                  r?.costDistributionRuleDescription,
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col d-flex align-items-center mb-2">
                        <label className="col-4 text-capitalize">
                          {localize('costDistributionRule')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={tBr?.costDistributionRule}
                          disabled
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {tBr?.funding === '4' ? (
                    <>
                      <div className="col d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('accountNumber')}
                        </label>
                        <CustomerAccountLookup
                          displayValue={
                            tBr?.debitCustomerAccountFullAccountNumber || ''
                          }
                          type="text"
                          className="form-control ms-2"
                          onSelect={async r => {
                            if (r?.bookBalance <= 0) {
                              showNotification(
                                'Inhouse Cheque Writing',
                                'info',
                                `Sorry, but the selected account has insuffient balance!`
                              )
                            } else if (r?.status !== '3') {
                              return showNotification(
                                'In-House Cheque',
                                'info',
                                'Sorry, but you should select an account whose status is CLOSED'
                              )
                            } else {
                              setTBr({
                                ...tBr,
                                debitAccountClosureRequestId: r?.id,
                                debitCustomerAccountId: r?.id,
                                debitChartOfAccountName:
                                  r?.customerAccountTypeTargetProductChartOfAccountName,
                                debitCustomerAccountFullAccountNumber:
                                  r?.fullAccountNumber,
                                bookBalance: r?.bookBalance,
                                debitChartOfAccountId:
                                  r?.customerAccountTypeTargetProductChartOfAccountId,
                                debitChartOfAccountAccountCode:
                                  r?.customerAccountTypeTargetProductChartOfAccountCode,
                                debitCustomerAccountBranchCode:
                                  r?.customerBranchCode,
                                debitCustomerAccountCustomerSerialNumber:
                                  r?.customerSerialNumber,
                                debitCustomerAccountCustomerAccountTypeProductCode:
                                  r?.customerAccountTypeProductCode,
                                debitCustomerAccountCustomerAccountTypeTargetProductCode:
                                  r?.customerAccountTypeTargetProductCode,
                                customerFullName: r?.customerFullName,
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col d-flex align-items-center mb-2">
                        <label className="col-4 text-capitalize">
                          {localize('bookBalance')}
                        </label>
                        <MaskNumber
                          className="form-control"
                          defaultMaskValue={tBr?.bookBalance}
                          disabled
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {tBr?.funding === '8' ? (
                    <>
                      <div className="col d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('loanNumber')}
                        </label>
                        <LoanDisbursementQueueLookup
                          displayValue={tBr?.loanNumber || ''}
                          type="text"
                          className="form-control ms-2"
                          onSelect={async r => {
                            if (r?.availableBalance <= 0) {
                              showNotification(
                                'Inhouse Cheque Writing',
                                'info',
                                `Sorry, but the selected account has insuffient balance!`
                              )
                            } else {
                              setTBr({
                                ...tBr,
                                debitLoanDisbursementQueueId: r?.id,
                                debitChartOfAccountName:
                                  r?.debitChartOfAccountName,
                                loanNumber: r?.paddedSerialNumber,
                                availableLoanBalance: r?.availableBalance,
                                debitChartOfAccountId:
                                  r?.loanCaseLoanProductChartOfAccountId,
                                debitChartOfAccountAccountCode:
                                  r?.chartOfAccountAccountCode,
                                customerFullName: r?.customerFullName,
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col d-flex align-items-center mb-2">
                        <label className="col-4 text-capitalize">
                          {localize('availableBalance')}
                        </label>
                        <MaskNumber
                          className="form-control"
                          defaultMaskValue={tBr?.availableLoanBalance}
                          disabled
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center mb-2  ">
                    <label className="col-4 text-capitalize">
                      {localize('chequeBook')}
                    </label>
                    <BankersChequeBooksLookup
                      displayValue={
                        tBr?.bankersChequeLeafBankersChequeBookSerialNumber
                      }
                      onSelect={async r => {
                        setTBr({
                          ...tBr,
                          bankersChequeLeafBankersChequeBookId: r?.id,
                          bankersChequeLeafBankersChequeBookSerialNumber:
                            r?.paddedSerialNumber,
                        })
                        setCheckBookId(r?.id)
                        setCheckBookSerialNumber(r?.paddedSerialNumber)
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center mb-2">
                    <label className="text-capitalize col-4">
                      {localize('chequeLeaveNumber')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: tBr?.bankersChequeLeafBankersChequeBookId
                          ? 1
                          : 0.6,
                        pointerEvents: tBr?.bankersChequeLeafBankersChequeBookId
                          ? 'all'
                          : 'none',
                      }}
                    >
                      <BankersChequeLeavesLookup
                        displayValue={tBr?.bankersChequeLeafLeafNumber}
                        onSelect={r => {
                          if (r?.statusDescription === 'Active') {
                            if (
                              tempEntries.find(
                                d => d.bankersChequeLeafId === r?.id
                              )
                            ) {
                              showNotification(
                                'Inhouse Cheque Writing',
                                'info',
                                `Sorry, but the cheque leaf selected has alread been captured!`
                              )
                            } else {
                              setTBr({
                                ...tBr,
                                bankersChequeLeafLeafNumber:
                                  r?.paddedLeafNumber,
                                bankersChequeLeafId: r?.id,
                              })
                            }
                          } else {
                            showNotification(
                              'Inhouse Cheque Writing',
                              'info',
                              'sorry, but the selected chequel Leaf has already been used!'
                            )
                          }
                        }}
                        bankersChequeBookId={checkBookId}
                        chequeBookSerialNumber={checkBookSerialNumber}
                      />
                    </div>
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('amount')}
                    </label>
                    <MaskNumber
                      className="form-control"
                      defaultMaskValue={tBr?.amount || 0}
                      onMaskNumber={e => {
                        setTBr({
                          ...tBr,
                          amount: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center mb-2  ">
                    <label className="col-4 text-capitalize">
                      {localize('branch')}
                    </label>
                    <BranchLookup
                      displayValue={tBr?.branchDescription}
                      onSelect={async r => {
                        setTBr({
                          ...tBr,
                          branchId: r?.id,
                          branchDescription: r?.description,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center mb-2">
                    <label className="col-4 text-capitalize">
                      {localize('payee')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={tBr?.payee || ''}
                      onChange={e => {
                        setTBr({
                          ...tBr,
                          payee: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center mb-2">
                    <label className="col-4 text-capitalize">
                      {localize('reference')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={tBr?.reference || ''}
                      onChange={e => {
                        setTBr({
                          ...tBr,
                          reference: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="row  mb-2">
                  <div className="d-flex align-items-center justify-content-end">
                    <>
                      <Checkbox
                        id="isChequeWritingChargeable"
                        onCheck={e => {
                          setTBr({
                            ...tBr,
                            chargeable: e,
                          })
                        }}
                        checked={tBr?.chargeable}
                      />
                      <label
                        htmlFor="isChequeWritingChargeable"
                        className="text-capitalize me-3"
                      >
                        {localize('chargeable')}?
                      </label>
                      <button
                        onClick={() => {
                          handleCalculation()
                        }}
                        className="btn btn-success px-5 mx-0 text-uppercase"
                      >
                        {localize('add')}
                      </button>
                    </>
                  </div>
                </div>

                <div
                  className="p-2 mt-2"
                  style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
                >
                  <div
                    className="bg-white row px-2 text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 'fit-content',
                    }}
                  >
                    {localize('cheques')}
                  </div>
                  <div style={{ marginTop: -15 }}>
                    {
                      <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                        <SimpleTable
                          columns={tBrColumns}
                          data={tempEntries}
                          contextMenu={r => (
                            <>
                              <div
                                className="ctxt-menu-item text-capitalize text-danger"
                                onClick={() => {
                                  let ts = tempEntries
                                  let found = ts.findIndex(t => t.id === r?.id)
                                  ts.splice(found, 1)
                                  setTempEntries(ts)
                                }}
                              >
                                <i className="uil uil-times-circle text-danger text-capitalize"></i>
                                {localize('remove')}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    }
                  </div>
                </div>

                <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
                  <button
                    onClick={() => {
                      updateAction()
                    }}
                    className="btn btn-success px-5"
                  >
                    {localize('update')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default InHousetCheque
