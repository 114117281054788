/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'

function AdministrativeDivisionLookup({ onSelect, displayValue, disabled }) {
  const columns = [
    {
      label: localize('description'),
      template: r => (
        <span
          dangerouslySetInnerHTML={{ __html: r.indentedDescription }}
        ></span>
      ),
      sortBy: 'description',
    },
    { label: localize('type'), template: r => <>{r.typeDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
  ]
  return (
    <>
      <SimpleLookup
        disabled={disabled}
        url="/lookup/AdministrativeDivision/find-by-filter-in-page"
        params={{
          filterText: '',
          pageIndex: 0,
          pageSize: 10,
          updateDepth: true,
          traverseTree: true,
        }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
        title={localize('adminstrativeDivisions')}
      />
    </>
  )
}

export default AdministrativeDivisionLookup
