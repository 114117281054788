/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import WorkstationLookup from '../../../../Components/Lookup/WorkstationLookup'
import { CustomerPartial } from '../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { customerAutoCompleteFilter } from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { dutyStationType, recordStatus } from '../../../../Helpers/constants'
import { putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import CustomerTab from './CustomerTab/CustomerTab'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function DutyWorkStationLinkage() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedCustomData, setSelectedCustomData] = useState([])
  const [reloadData, setReloadData] = useState(false)

  const [workStation, setWorkStation] = useState({
    filterCriteria: Object.keys(customerAutoCompleteFilter)[0],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleReloadData = () => {
    setReloadData(false)
  }

  const handleOnSelectCustomer = r => {
    if (r?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'You cannot select a locked Customer.'
      )

    if (r?.recordStatus !== recordStatus.APPROVED)
      return showNotification(
        'Operation not allowed',
        'error',
        'Customer record status must be in approved status.'
      )

    setSelectedCustomer(r)
    clearValidation('FullName')
  }

  const handleUpdate = async () => {
    if (!selectedCustomer)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select customer'
      )

    setIsBusy(true)
    const { success } = await putData(
      '/Customer/update-customer-duty-station',
      {
        dutyStationId: workStation?.dutyStationId,
        DutyStationDescription: workStation?.DutyStationDescription,
        id: selectedCustomer?.id,
        fullName: selectedCustomer?.fullName,
      },
      false
    )

    if (success) {
      setSelectedCustomer(null)
      setReloadData(true)
      showNotification('Duty/Workstation Linkage', 'success')
    }

    setIsBusy(false)
  }

  const handleSelectAll = (customer, checked) => {
    if (checked) {
      setSelectedCustomData([...customer])
    } else {
      setSelectedCustomData([])
    }
  }

  const handleSelectCustomData = (customer, checked) => {
    if (checked) {
      setSelectedCustomData([...selectedCustomData, customer])
    } else {
      setSelectedCustomData(
        selectedCustomData.filter(c => c.id !== customer.id)
      )
    }
  }

  const handleSelectDutyStation = station => {
    if (station?.type === dutyStationType.HEADER_ENTRY)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a detail entry station.'
      )

    clearValidation('DutyStationDescription')
    setWorkStation({
      ...workStation,
      parentDescription: station?.parentDescription,
      typeDescription: station?.typeDescription,
      remarks: station?.remarks,
      dutyStationId: station?.id,
      DutyStationDescription: station?.description,
    })
  }

  const handleReset = async () => {
    setIsBusy(true)
    const url = '/Customer/reset-customer-duty-station'

    const { success } = await putData(
      url,
      { customerDutyStation: selectedCustomData },
      false
    )

    if (success) {
      setReloadData(true)
      setSelectedCustomData([])
      showNotification('Duty/Workstation Linkage', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('duty/WorkStationLinkage'),
        ]}
      />

      <div className="card relative rounded bg-white">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['DutyStationDescription']?.message
                }
              >
                <label
                  htmlFor="targetStation"
                  className="text-capitalize col-3"
                >
                  {localize('targetStation')}
                </label>
                <WorkstationLookup
                  displayValue={workStation?.parentDescription}
                  onSelect={handleSelectDutyStation}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="type" className="text-capitalize col-3">
                  {localize('type')}
                </label>

                <input
                  type="text"
                  id="type"
                  className="form-control ms-2"
                  defaultValue={workStation?.typeDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="remarks" className="text-capitalize col-3">
                  {localize('remarks')}
                </label>

                <input
                  type="text"
                  id="remarks"
                  className="form-control ms-2"
                  defaultValue={workStation?.remarks || ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('customerDetails')}
            </div>
            <div className="row g-3">
              <CustomerPartial
                refreshFromServer
                customer={selectedCustomer}
                onCustomerLookup={handleOnSelectCustomer}
                message={globalValidationErrors['FullName']?.message}
              />
            </div>
            <div className="row bg-light m-2 rounded py-2">
              <div className="col-md-3 offset-9">
                <button
                  onClick={handleUpdate}
                  className="btn btn-success float-end"
                >
                  {localize('update')}
                </button>
              </div>
            </div>
            <Tab
              tabItems={[
                {
                  label: localize('customers'),
                  item: (
                    <div className="ms-2">
                      <CustomerTab
                        handleReset={() => {
                          handleReset()
                        }}
                        reloadData={reloadData}
                        toggleReloadData={handleReloadData}
                        selectedCustomData={selectedCustomData}
                        workStation={workStation}
                        onSelectAll={handleSelectAll}
                        onSelectedCustomerChange={handleSelectCustomData}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
          {isBusy && <NonBlockingLoader />}
        </div>
      </div>
    </>
  )
}

export default DutyWorkStationLinkage
