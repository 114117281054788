/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'
import MembershipWithdrawalNotificationLookup from '../../../../Components/Lookup/MembershipWithdrawalNotificationLookup'
import AdHocLoanInterestCapitalization from '../../../../Components/ReUsables/AdHocLoanInterestCapitalization/AdHocLoanInterestCapitalization'
import { LoansGuaranteed } from '../../../../Components/ReUsables/Customer/LoansGuaranteed'
import { ViewCustomerInvestmentAccounts } from '../../../../Components/ReUsables/Customer/ViewCustomerInvestmentAccounts'
import { ViewCustomerLoanAccounts } from '../../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import { ViewCustomerSavingsAccounts } from '../../../../Components/ReUsables/Customer/ViewCustomerSavingsAccounts'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import {
  membershipWithdrawalRegistrationOption,
  withdrawalNotificationCategory,
} from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { withdrawalNotificationStatus } from '../../../../Helpers/constants'
import { formatCurrency } from '../../../../Helpers/formatters'
import { getData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import AdHocStandingOrderExecutionModal from '../Catalogue/AddCatalogueModal/AdHocStandingOrderExecutionModal/AdHocStandingOrderExecutionModal'

function Approval() {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyLoadingAccounts, setIsBusyLoadingAccounts] = useState(false)
  const [customer, setCustomer] = useState({})
  const [adHocStandingOrder, setAdHocStandingOrder] = useState({})
  const [showAdHocStandingOrderModal, setShowAdHocStandingOrderModal] =
    useState(false)
  const [showAdHocModal, setShowAdHocModal] = useState(false)
  const [loanAccount, setLoanAccount] = useState({})
  const [customerSavingsAccounts, setCustomerSavingsAccounts] = useState([])
  const [customerLoanAccounts, setCustomerLoanAccounts] = useState([])
  const [customerInvestmentAccounts, setCustomerInvestmentAccounts] = useState(
    []
  )
  const [balance, setBalance] = useState({
    netPartialRefundable: '',
    netLoansBalance: '',
    netRefundable: '',
    netInvestmentsBalance: '',
    backOfficeInvestmentsBalance: '',
    backOfficeLoansBalance: '',
  })
  const [customerLoansGuarantor, setCustomerLoansGuarantor] = useState([])
  const [approval, setApproval] = useState({
    accountClosureApprovalOption: Object.keys(
      membershipWithdrawalRegistrationOption
    )[0],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const deceased = Object.keys(withdrawalNotificationCategory)[0]
  const voluntary = Object.keys(withdrawalNotificationCategory)[1]
  const retiree = Object.keys(withdrawalNotificationCategory)[2]

  const handleSelectWithdrawalNotification = r => {
    if (
      r?.status !==
      (withdrawalNotificationStatus.REGISTERED ||
        withdrawalNotificationStatus.DEFERRED)
    )
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only select a registered or deferred withdrawal notification'
      )

    setCustomer(r)
    clearValidation('WithdrawalNotification.Id')
  }

  const getCustomerAccounts = async () => {
    setIsBusyLoadingAccounts(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes',
      {
        customerId: customer?.customerId,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerSavingsAccounts(data.customerSavingsAccounts)
      setCustomerLoanAccounts(data.customerLoanAccounts)
      setCustomerInvestmentAccounts(data.customerInvestmentAccounts)
      setBalance({
        netPartialRefundable: data?.netPartialRefundable,
        netLoansBalance: data?.netLoansBalance,
        netRefundable: data?.netRefundable,
        netInvestmentsBalance: data?.netInvestmentsBalance,
        backOfficeInvestmentsBalance: data?.backOfficeInvestmentsBalance,
        backOfficeLoansBalance: data?.backOfficeLoansBalance,
      })
    }

    setIsBusyLoadingAccounts(false)
  }

  const getCustomerGuaranteedLoans = async () => {
    setIsBusyLoadingAccounts(true)

    const { success, data } = await getData(
      '/LoanCase/find-loan-guarantor-by-customer-id',
      {
        customerId: customer?.customerId,
        includeOutstandingBalance: true,
        filterClearedLoans: true,
      },
      false
    )
    if (success) {
      setCustomerLoansGuarantor(data?.result)
    }

    setIsBusyLoadingAccounts(false)
  }

  const handleApprove = async () => {
    setIsBusy(true)
    const url = '/WithdrawalNotification/approve-withdrawal-notification'

    const { success } = await putData(
      url,
      {
        withdrawalNotification: {
          approvalRemarks: approval?.remarks,
          id: customer?.id,
          paddedBatchNumber: customer?.paddedBatchNumber,
        },
        membershipWithdrawalApprovalOption:
          approval?.accountClosureApprovalOption,
      },
      false
    )

    if (success) {
      setApproval({
        accountClosureApprovalOption: Object.keys(
          membershipWithdrawalRegistrationOption
        )[0],
      })
      setCustomer({})
      setCustomerSavingsAccounts([])
      setCustomerLoanAccounts([])
      setCustomerInvestmentAccounts([])
      setBalance({
        netPartialRefundable: '',
        netLoansBalance: '',
        netRefundable: '',
        netInvestmentsBalance: '',
        backOfficeInvestmentsBalance: '',
        backOfficeLoansBalance: '',
      })
      showNotification('Approve Membership Withdrawal', 'success')
      setCustomerLoansGuarantor([])
      setApproval({
        accountClosureApprovalOption: Object.keys(
          membershipWithdrawalRegistrationOption
        )[0],
      })
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [])

  useEffect(() => {
    if (customer?.customerId) {
      getCustomerAccounts()
      getCustomerGuaranteedLoans()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.customerId])

  const tabItems = [
    {
      label: localize('loanAccounts'),
      item: (
        <div className="relative">
          {isBusyLoadingAccounts && <NonBlockingLoader />}
          <ViewCustomerLoanAccounts
            customerLoanAccounts={customerLoanAccounts}
            handleAdHocLoan={r => {
              setLoanAccount(r)
              setShowAdHocModal(true)
            }}
            hiddenColumnsIndexes={[0]}
          />
        </div>
      ),
    },
    {
      label: localize('savingsAccounts'),
      item: (
        <div className="relative">
          {isBusyLoadingAccounts && <NonBlockingLoader />}
          <ViewCustomerSavingsAccounts
            customerSavingsAccounts={customerSavingsAccounts}
          />
        </div>
      ),
    },
    {
      label: localize('investmentAccounts'),
      item: (
        <div className="relative">
          {isBusyLoadingAccounts && <NonBlockingLoader />}
          <ViewCustomerInvestmentAccounts
            customerInvestmentAccounts={customerInvestmentAccounts}
            handleExecuteStandingOrder={r => {
              setAdHocStandingOrder(r)
              setShowAdHocStandingOrderModal(true)
            }}
          />
        </div>
      ),
    },
    {
      label: localize('loanGuaranteed'),
      item: (
        <>
          <LoansGuaranteed loanGuarantors={customerLoansGuarantor} />
        </>
      ),
    },
  ]
  return (
    <>
      {showAdHocModal && (
        <AdHocLoanInterestCapitalization
          handleClose={() => {
            setShowAdHocModal(false)
          }}
          loanAccount={loanAccount}
        />
      )}
      {showAdHocStandingOrderModal && (
        <AdHocStandingOrderExecutionModal
          handleClose={() => {
            setShowAdHocStandingOrderModal(false)
          }}
          adHocStandingOrder={adHocStandingOrder}
        />
      )}

      <BreadCrumb
        tree={[localize('membershipWithdrawal'), localize('approval')]}
      />
      <div className="card bg-white rounded relative">
        <div className="card-body">
          <div className="row g-3 mb-2">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['WithdrawalNotification.Id']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <MembershipWithdrawalNotificationLookup
                displayValue={customer?.customerFullName || ''}
                handleSelect={handleSelectWithdrawalNotification}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  customer?.customerMembershipClassCustomerTypeDescription || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipStatus')}
              </label>
              <input
                type="text"
                className="form-control"
                value={customer?.customerMembershipStatusDescription || ''}
              />
            </div>
          </div>
          <div className="row g-3 mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipWdr.Date')}
              </label>
              <CustomDatePicker
                defaultDate={customer?.customerMembershipTerminationDate}
                disabled
                name={'membershipTerminationDate'}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('domicileBranch')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerBranchDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerEmployerDescription || ''}
              />
            </div>
          </div>
          <div className="row g-3 mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('dutyWorkStation')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerDutyStationDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('adminDivision')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  customer?.customerAdministrativeDivisionDescription || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identificationNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerIdentificationNumber || ''}
              />
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.formattedCustomerMemberNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.paddedCustomerAccountNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerPersonalFileNumber || ''}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('transactionReference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.transactionReferenceDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('terminationCategory')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.categoryDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('terminationRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.remarks || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.createdBy || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('createdDate')}
                </label>
                <CustomDatePicker
                  defaultDate={customer?.createdDate}
                  name={'createdDate'}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('status')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.statusDescription || ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3">
              <Tab tabItems={tabItems} />
            </div>
          </div>
          <div className="bg-light rounded mt-3 p-2">
            {customer && customer.terminationCategory !== deceased && (
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('deposits(Refundable)')}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={formatCurrency(
                        customer?.terminationCategory === voluntary ||
                          customer?.terminationCategory === retiree
                          ? balance.netInvestmentsBalance
                          : balance.backOfficeInvestmentsBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('loansBalance')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        customer?.terminationCategory === voluntary ||
                          customer?.terminationCategory === retiree
                          ? balance.netLoansBalance
                          : balance.backOfficeLoansBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('netRefundable')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        customer?.terminationCategory === voluntary ||
                          customer?.terminationCategory === retiree
                          ? balance?.netRefundable
                          : balance?.netPartialRefundable,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex mt-3 gap-3  align-items-center justify-content-end bg-light rounded p-2">
              <div className="d-flex align-items-center gap-2">
                <div
                  className="d-flex align-items-center gap-1 with-validation"
                  validation-message={
                    globalValidationErrors['WithdrawalNotification.Remarks']
                      ?.message
                  }
                >
                  <label className="text-capitalize">
                    {localize('approvalRemarks')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    value={approval?.remarks || ''}
                    onChange={e => {
                      clearValidation('WithdrawalNotification.Remarks')
                      setApproval({
                        ...approval,
                        remarks: e.target.value,
                      })
                    }}
                  />
                </div>
                <EnumsServerCombo
                  customContainerStyle={{ maxWidth: 150 }}
                  enumsKey="AccountClosureApprovalOption"
                  value={approval.accountClosureApprovalOption}
                  onChange={e => {
                    setApproval({
                      ...approval,
                      accountClosureApprovalOption: e.value,
                    })
                  }}
                />
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn btn-success" onClick={handleApprove}>
                  {localize('update')}
                </button>
              </div>
            </div>
          </div>
        </div>
        {isBusy && <NonBlockingLoader />}
      </div>
    </>
  )
}

export default Approval
