/** @format */

import _a from '../Global/Localization/_a'
import _b from '../Global/Localization/_b'
import _c from '../Global/Localization/_c'
import _d from '../Global/Localization/_d'
import _e from '../Global/Localization/_e'
import _f from '../Global/Localization/_f'
import _g from '../Global/Localization/_g'
import _h from '../Global/Localization/_h'
import _i from '../Global/Localization/_i'
import _j from '../Global/Localization/_j'
import _k from '../Global/Localization/_k'
import _l from '../Global/Localization/_l'
import _m from '../Global/Localization/_m'
import _n from '../Global/Localization/_n'
import _o from '../Global/Localization/_o'
import _p from '../Global/Localization/_p'
import _q from '../Global/Localization/_q'
import _r from '../Global/Localization/_r'
import _s from '../Global/Localization/_s'
import _t from '../Global/Localization/_t'
import _u from '../Global/Localization/_u'
import _v from '../Global/Localization/_v'
import _w from '../Global/Localization/_w'
import _x from '../Global/Localization/_x'
import _y from '../Global/Localization/_y'
import _z from '../Global/Localization/_z'

const dictionary = {
  ..._a,
  ..._b,
  ..._c,
  ..._d,
  ..._e,
  ..._f,
  ..._g,
  ..._h,
  ..._i,
  ..._j,
  ..._k,
  ..._l,
  ..._m,
  ..._n,
  ..._o,
  ..._p,
  ..._q,
  ..._r,
  ..._s,
  ..._t,
  ..._u,
  ..._v,
  ..._w,
  ..._x,
  ..._y,
  ..._z,
}
export default dictionary
