/** @format */

import React from 'react'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import JournalEntries from '../JournalEntries'

const ViewJournalEntries = ({ handleClose, entry }) => {
  return (
    <ModalTemplate
      modalMode="view"
      modalClassName="modal-xl"
      modalTitle="journalEntries"
      handleClose={handleClose}
      hideUpdate
      cancelText="close"
    >
      <JournalEntries journalEntries={entry?.journalEntries || []} />
    </ModalTemplate>
  )
}

export default ViewJournalEntries
