/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import MandatoryCharges from './MandatoryParams/MandatoryCharges'
import { getData, putData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'

function MandatoryParameters({ customer }) {
  const [params, setParams] = useState({ mc: [], ip: [], sp: [], ac: [] })
  const [debitTypes, setDebitTypes] = useState([])
  const [invProducts, setInvProducts] = useState([])
  const [savProducts, setSavProducts] = useState([])
  const [alternateChannels, setAlternateChannels] = useState([])

  useEffect(() => {
    loadDebitTypes()
    loadInvestementProducts()
    loadSavingsProducts()
    loadAltChannels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadDebitTypes() {
    const { success, data } = await getData('/debitType/find-debit-types')
    if (success) {
      setDebitTypes(data)
    }
  }

  async function loadInvestementProducts() {
    const { success, data } = await getData(
      '/investmentProduct/find-investment-products'
    )
    if (success) {
      setInvProducts(data)
    }
  }

  async function loadSavingsProducts() {
    const { success, data } = await getData(
      '/savingsProduct/find-savings-products'
    )
    if (success) {
      setSavProducts(data)
    }
  }

  async function loadAltChannels() {
    const { success, data } = await getData(
      '/MembershipClass/find-membership-class-alternate-channels-by-membership-class-id',
      { membershipClassId: customer?.membershipClassId }
    )
    if (success) {
      setAlternateChannels(data?.result)
    }
  }

  async function updateMandatoryParams() {
    const { success } = await putData(
      `/customer/update-mandatory-parameters-by-customer-id?customerId=${customer?.id}&moduleNavigationItemCode=7676`,
      {
        mandatoryDebitTypes: params?.mc,
        mandatoryInvestmentProducts: params?.ip,
        mandatorySavingsProducts: params?.sp,
        mandatoryAlternateChannels: params?.ac,
      }
    )
    if (success) {
      Swal.fire(
        'Operation Successful',
        'Mandatory Parameters Updated',
        'success'
      )
    }
  }

  const tabItems = [
    {
      label: localize('mandatoryCharges'),
      item: (
        <MandatoryCharges
          all={debitTypes}
          selected={params?.mc}
          onSelect={d => {
            const isContained = !!params?.mc.find(de => de.id === d.id)
            if (isContained) {
              let selected = [...params?.mc]
              let ind = selected.findIndex(s => s.id === d.id)
              selected.splice(ind, 1)
              setParams({ ...params, mc: selected })
            } else {
              setParams({ ...params, mc: [...params?.mc, d] })
            }
          }}
        />
      ),
    },
    {
      label: localize('investmentProducts'),
      item: (
        <MandatoryCharges
          all={invProducts}
          selected={params?.ip}
          onSelect={d => {
            const isContained = !!params?.ip.find(de => de.id === d.id)
            if (isContained) {
              let selected = [...params?.ip]
              let ind = selected.findIndex(s => s.id === d.id)
              selected.splice(ind, 1)
              setParams({ ...params, ip: selected })
            } else {
              setParams({ ...params, ip: [...params?.ip, d] })
            }
          }}
        />
      ),
    },
    {
      label: localize('savingsProducts'),
      item: (
        <MandatoryCharges
          all={savProducts}
          selected={params?.sp}
          onSelect={d => {
            const isContained = !!params?.sp.find(de => de.id === d.id)
            if (isContained) {
              let selected = [...params?.sp]
              let ind = selected.findIndex(s => s.id === d.id)
              selected.splice(ind, 1)
              setParams({ ...params, sp: selected })
            } else {
              setParams({ ...params, sp: [...params?.sp, d] })
            }
          }}
        />
      ),
    },
    {
      label: localize('alternateChannels'),
      item: (
        <MandatoryCharges
          displayColumn={'alternateChannelTypeDescription'}
          all={alternateChannels}
          selected={params?.ac}
          onSelect={d => {
            const isContained = !!params?.ac.find(de => de.id === d.id)
            if (isContained) {
              let selected = [...params?.ac]
              let ind = selected.findIndex(s => s.id === d.id)
              selected.splice(ind, 1)
              setParams({ ...params, ac: selected })
            } else {
              setParams({ ...params, ac: [...params?.ac, d] })
            }
          }}
        />
      ),
    },
  ]

  return (
    <div>
      <Tab tabItems={tabItems} />
      <div className="my-2 d-flex align-items-center justify-content-end">
        <button
          className="btn btn-success px-5"
          onClick={updateMandatoryParams}
        >
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default MandatoryParameters
