/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import {
  creditReferenceBureauQueryStatus,
  identityCardType,
} from '../../../../../../Global/enumeration'
import { getData, postData } from '../../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import EnumsCombo from '../../../../../../Components/ComboBox/EnumsCombo'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'

function ConsumerScore() {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, false)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('reportType'),
      template: r => <>{r.reportTypeDescription}</>,
      sortBy: 'reportTypeDescription',
    },
    {
      label: localize('identityType'),
      template: r => <>{r.identityTypeDescription}</>,
      sortBy: 'identityTypeDescription',
    },
    {
      label: localize('identityNumber'),
      template: r => <>{r.identityNumber}</>,
      sortBy: 'identityNumber',
    },
    {
      label: localize('creditScore'),
      template: r => <>{formatCurrency(r.creditScore)}</>,
    },
    {
      label: localize('hasError'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.hasError.toString())}
        </span>
      ),
      sortBy: 'hasError',
    },
    {
      label: localize('mobileScore'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.mobileScore.toString())}
        </span>
      ),
      sortBy: 'mobileScore',
    },
    {
      label: localize('asAt'),
      template: r => <>{formatDate(r.asAt)}</>,
      sortBy: 'asAt',
    },
    {
      label: localize('category'),
      template: r => <>{r.category}</>,
      sortBy: 'category',
    },
    {
      label: localize('apiCode'),
      template: r => <>{r.apiCode}</>,
    },
    {
      label: localize('apiCodeDescription'),
      template: r => <>{r.apiCodeDescription}</>,
    },
    {
      label: localize('transactionId'),
      template: r => <>{r.transactionId}</>,
    },
    {
      label: localize('thirdPartyWalletResponse'),
      template: r => <>{r.thirdPartyWalletResponse}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 3,
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedConsumerScoreQuery, setSelectedConsumerScoreQuery] =
    useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setIsBusy(true)
    const { success } = await postData(
      '/CreditReferenceBureau/add-new-consumer-score-query',
      selectedConsumerScoreQuery || {
        identityNumber: '',
      },
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedConsumerScoreQuery(null)
      setRefreshAt(!refreshAt)
    } else {
      setIsBusy(false)
    }
  }

  function closeModal() {
    setMode(null)
    setSelectedConsumerScoreQuery(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/CreditReferenceBureau/find-consumer-score-queries-by-status-date-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
      setError(true)
    }
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedConsumerScoreQuery({
      identityType: Object.keys(identityCardType)[1],
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('backOfficeManagement'),
          localize('consumerScoreQueries'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  {error ? (
                    <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                      <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                      <div className="fs-5 text-danger">
                        {localize('failedToLoadData')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <AddNewButton handleClickAddButton={handleAdd} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex align-items-center col-2">
                          <label
                            htmlFor="recordsPerPage"
                            className=""
                            style={{
                              marginLeft: -10,
                              textTransform: 'capitalize',
                            }}
                          >
                            {localize('recordsPerPage')}
                          </label>
                          <select
                            className="form-select ms-3"
                            style={{ maxWidth: 'fit-content' }}
                            defaultValue={reqParams.pageSize}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                pageIndex: 0,
                                pageSize: e.target.value,
                              })
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="d-flex align-items-center col-2">
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="CreditReferenceBureauQueryStatus"
                            value={reqParams.status}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                status: e.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              className="text-capitalize  col-md-3"
                              htmlFor="searchByDate"
                            >
                              {localize('dateRange')}
                            </label>
                            <DatePicker
                              onSelect={v => {
                                if (v.custom) {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: true,
                                    startDate: v.startDate,
                                    endDate: v.endDate,
                                  })
                                } else {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: false,
                                    dateRequestsFilter: v.value,
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                        <form
                          className="d-flex align-items-center col-4 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              text: search,
                            })
                          }}
                        >
                          <label
                            className="ms-2 text-capitalize"
                            htmlFor="searchTable"
                          >
                            {localize('search')}
                          </label>
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.text}
                            onChange={e => {
                              setSearch(e.target.value)
                              setReqParams({
                                ...reqParams,
                                text: e.target.value,
                              })
                            }}
                          />
                          <button
                            id="consumerScoreQueryFilterButton"
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                              loadTableData()
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </form>
                      </div>
                      <div className="row col-12 mt-2">
                        <SimpleTable data={tableData} columns={columns} />
                      </div>
                      <div className="row col-12 mt-2">
                        <Pager
                          {...tableMeta}
                          onPageChange={p =>
                            setReqParams({ ...reqParams, pageIndex: p })
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <>
          <ModalTemplate
            modalMode={mode}
            modalTitle="consumerScoreQueries"
            handleClose={closeModal}
            handleAction={handleSubmit}
            modalClassName="modal-lg"
            actionText={mode === 'add' ? 'create' : ''}
          >
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-3 text-capitalize">
                  {localize('identityType')}
                </label>
                <EnumsServerCombo
                  enumsKey="IdentityCardType"
                  value={selectedConsumerScoreQuery?.identityTypeDescription}
                  onChange={e =>
                    setSelectedConsumerScoreQuery({
                      ...selectedConsumerScoreQuery,
                      identityType: e.value,
                    })
                  }
                />
              </div>
              <div
                className="col d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['IdentityNumber']?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('identityNumber')}
                </label>
                <input
                  type="text"
                  className="form-control ms-2"
                  value={selectedConsumerScoreQuery?.identityNumber || ''}
                  onChange={e => {
                    clearValidation('IdentityNumber')
                    setSelectedConsumerScoreQuery({
                      ...selectedConsumerScoreQuery,
                      identityNumber: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </ModalTemplate>
        </>
      )}
    </>
  )
}

export default ConsumerScore
