/** @format */
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import {
  CreditBatchFileType,
  CreditBatchType,
  NonLoanProductCode,
  RoundingType,
  TransactionOwnership,
} from '../../../../Global/enumeration'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import RevenueTaxLookup from '../../../../Components/Lookup/RevenueTaxLookup'
import PayrollNumberTypeLookup from '../../../../Components/Lookup/PayrollNumberTypeLookup'
import InvestmentProductLookup from '../../../../Components/Lookup/InvestmentProductLookup'
import SavingsProductLookup from '../../../../Components/Lookup/SavingsProductLookup'
import Swal from 'sweetalert2'
import moment from 'moment'
import { postData } from '../../../../Helpers/webApi'
import { ACTIONS } from '../Reducers/reducer'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { CreditTypesTabContainers } from './Tabs/CreditTypesTabContainers'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'

function CreditTypesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  creditTypeModel,
  creditTypeState,
  setCreditTypeModel,
  creditTypeDispatcher,
  onTabNavigateCreditTypeModal,
}) {
  const [
    selectedUnallocatedFundsCustomerAccountTypeProductCode,
    setSelectedUnallocatedFundsCustomerAccountTypeProductCode,
  ] = useState('3')
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    setGbv({})
  }, [mode])

  const [gbv, setGbv] = GlobalValidationErrors.use()

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const handleToggleSelection = selection => {
    if (selection.status && selection.productSection === 0) {
      creditTypeDispatcher({
        type: ACTIONS.SELECT_ALL_FOSA_LOAN_PRODUCTS,
      })
    } else if (selection.status && selection.productSection === 1) {
      creditTypeDispatcher({
        type: ACTIONS.SELECT_ALL_BOSA_LOAN_PRODUCTS,
      })
    } else {
      creditTypeDispatcher({
        type: ACTIONS.NULL_SELECTION_CREDIT_TYPE_LOAN_PRODUCTS,
      })
    }
  }

  const packagedCreditTypeWithMetadata = () => {
    let creditType = { ...creditTypeModel }

    creditType.creditTypeFlags = creditTypeState.creditTypeFlags.reduce(
      (acc, curr) => {
        acc[curr.value] = curr.checked
        return acc
      },
      {}
    )

    return {
      CreditTypeDTO: creditType,
      CommissionDTOs: creditTypeState.creditTypeCommissions.filter(
        x => x.checked
      ),
      DirectDebitDTOs: creditTypeState.creditTypeDirectDebits.filter(
        x => x.checked
      ),
      AttachedProductsTuple: {
        InvestmentProductCollection:
          creditTypeState.creditTypeInvestmentProducts.filter(x => x.checked),
        LoanProductCollection: creditTypeState.creditTypeLoanProducts.filter(
          x => x.checked
        ),
        SavingsProductCollection:
          creditTypeState.creditTypeSavingsProducts.filter(x => x.checked),
      },
      ConcessionExemptProductsTuple: {
        InvestmentProductCollection:
          creditTypeState.creditTypeConcessionExemptionInvestmentProducts.filter(
            x => x.checked
          ),
        LoanProductCollection:
          creditTypeState.creditTypeConcessionExemptionLoanProducts.filter(
            x => x.checked
          ),
        SavingsProductCollection:
          creditTypeState.creditTypeConcessionExemptionSavingsProducts.filter(
            x => x.checked
          ),
      },
      BenefactorProductsTuple: {
        InvestmentProductCollection:
          creditTypeState.creditTypeBenefactorInvestmentProducts.filter(
            x => x.checked
          ),
      },
    }
  }

  async function handleSubmit(finalData) {
    setIsBusy(true)
    const { success } = await postData(
      `${
        mode === 'add'
          ? '/CreditType/create-credit-type-with-meta-data'
          : '/CreditType/update-credit-type-with-meta-data'
      }`,
      finalData,
      false
    )

    if (success) {
      setIsBusy(false)
      onSetMode(null)
      onUpdateRefreshAt(moment(new Date()).format('YYMMMDDHHmmssa'))
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Add Credit Types',
        showCloseButton: true,
      })
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <ModalHeader className="bg-white text-capitalize">
              {localize(mode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('creditTypes')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div
                      validation-message={
                        gbv?.['CreditTypeDTO.Description']?.message
                      }
                      className="col d-flex align-items-center with-validation"
                    >
                      <label className="col-4 text-capitalize">
                        {localize('name')}
                      </label>
                      <input
                        className="form-control"
                        defaultValue={creditTypeModel?.description}
                        onChange={e => {
                          clearValidation('CreditTypeDTO.Description')
                          setCreditTypeModel({
                            ...creditTypeModel,
                            description: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('roundingType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="RoundingType"
                        value={creditTypeModel?.roundingType}
                        onChange={e => {
                          setCreditTypeModel({
                            ...creditTypeModel,
                            roundingType: e.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('transactionOwnership')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="TransactionOwnership"
                        value={creditTypeModel?.transactionOwnership}
                        onChange={e => {
                          setCreditTypeModel({
                            ...creditTypeModel,
                            transactionOwnership: e.value,
                          })
                        }}
                      />
                    </div>
                    <div
                      validation-message={
                        gbv?.['CreditTypeDTO.ChartOfAccountName']?.message
                      }
                      className="col d-flex align-items-center with-validation"
                    >
                      <label className="col-4 text-capitalize">
                        {localize('chartOfAccountName')}
                      </label>
                      <ChartOfAccountsLookup
                        displayValue={creditTypeModel?.chartOfAccountName}
                        onSelect={r => {
                          clearValidation('CreditTypeDTO.ChartOfAccountName')
                          setCreditTypeModel({
                            ...creditTypeModel,
                            chartOfAccountName: r.description,
                            chartOfAccountId: r.id,
                            chartOfAccountCostDistributionRuleId:
                              r.costDistributionRuleId,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('creditBatchType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CreditBatchType"
                        value={creditTypeModel?.batchType}
                        onChange={e => {
                          setCreditTypeModel({
                            ...creditTypeModel,
                            batchType: e.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('revenueTax')}
                      </label>
                      <RevenueTaxLookup
                        displayValue={creditTypeModel?.revenueTaxDescription}
                        onSelect={r => {
                          setCreditTypeModel({
                            ...creditTypeModel,
                            revenueTaxDescription: r.description,
                            revenueTaxId: r.id,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('creditBatchFileType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CreditBatchFileType"
                        value={creditTypeModel?.batchFileType}
                        onChange={e => {
                          setCreditTypeModel({
                            ...creditTypeModel,
                            batchFileType: e.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('payrollNumberType')}
                      </label>
                      <PayrollNumberTypeLookup
                        displayValue={
                          creditTypeModel?.payrollNumberTypeDescription
                        }
                        onSelect={r => {
                          setCreditTypeModel({
                            ...creditTypeModel,
                            payrollNumberTypeDescription: r.description,
                            payrollNumberTypeId: r.id,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('unallocatedFundsDestination')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="NonLoanProductCode"
                        value={
                          creditTypeModel?.unallocatedFundsCustomerAccountTypeProductCodeDescription
                        }
                        onChange={e => {
                          setSelectedUnallocatedFundsCustomerAccountTypeProductCode(
                            e.target.value
                          )
                          setCreditTypeModel({
                            ...creditTypeModel,
                            unallocatedFundsCustomerAccountTypeProductCode:
                              e.value,
                          })
                        }}
                      />
                    </div>
                    <div
                      validation-message={
                        gbv?.[
                          'CreditTypeDTO.UnallocatedFundsCustomerAccountTypeTargetProductDescription'
                        ]?.message
                      }
                      className="col d-flex align-items-center with-validation"
                    >
                      <label className="col-4 text-capitalize">
                        {localize('unallocatedFundsProduct')}
                      </label>
                      {selectedUnallocatedFundsCustomerAccountTypeProductCode ===
                      '3' ? (
                        <InvestmentProductLookup
                          displayValue={
                            creditTypeModel?.unallocatedFundsCustomerAccountTypeTargetProductDescription
                          }
                          onSelect={r => {
                            clearValidation(
                              'CreditTypeDTO.UnallocatedFundsCustomerAccountTypeTargetProductDescription'
                            )
                            setCreditTypeModel({
                              ...creditTypeModel,
                              unallocatedFundsCustomerAccountTypeTargetProductDescription:
                                r.description,
                              unallocatedFundsCustomerAccountTypeTargetProductId:
                                r.id,
                              unallocatedFundsCustomerAccountTypeTargetProductChartOfAccountId:
                                r.chartOfAccountId,
                            })
                          }}
                        />
                      ) : (
                        <SavingsProductLookup
                          displayValue={
                            creditTypeModel?.unallocatedFundsCustomerAccountTypeTargetProductDescription
                          }
                          onSelect={r => {
                            setCreditTypeModel({
                              ...creditTypeModel,
                              unallocatedFundsCustomerAccountTypeTargetProductDescription:
                                r.description,
                              unallocatedFundsCustomerAccountTypeTargetProductId:
                                r.id,
                              unallocatedFundsCustomerAccountTypeTargetProductChartOfAccountId:
                                r.chartOfAccountId,
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12 mt-3">
                      <CreditTypesTabContainers
                        mode={mode}
                        creditTypeDispatcher={creditTypeDispatcher}
                        creditTypeState={creditTypeState}
                        onNavigateCreditTypeTabContainer={tabNumber => {
                          onTabNavigateCreditTypeModal(tabNumber)
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter className="bg-white">
              <button
                className="btn btn-danger text-uppercase"
                onClick={onHideModal}
              >
                {localize('cancel')}
              </button>
              <button
                onClick={() => {
                  handleSubmit(packagedCreditTypeWithMetadata())
                }}
                className={`btn btn-success text-uppercase px-4`}
                type="button"
              >
                {localize(mode === 'add' ? 'create' : 'update')}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  )
}

export default CreditTypesModal
