/** @format */

import React, { useEffect } from 'react'
import { useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import CreateOverDraftProtection from './CreateOverDraftProtection/CreateOverDraftProtection'
import VerifyOverdraftProtection from './VerifyOverdraftProtection/VerifyOverdraftProtection'

const OverdraftProtection = () => {
  const [overDrafts, setOverDrafts] = useState([])
  const [overDraft, setOverDraft] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: localize('recordStatus'),
    column: 'recordStatusDescription',
    value: 0,
  })
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    customerFilter: 0,
    text: '',
    includeProductDescription: true,
  })
  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('overdraftLimit'),
      template: r => <>{formatCurrency(r?.overdraftLimit)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => (
        <>{r?.customerAccountCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('customerType'),
      template: r => (
        <>{r?.customerAccountCustomerMembershipClassCustomerTypeDescription}</>
      ),
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'statusDescription' },
    { value: 1, column: 'createdBy' },
    { value: 2, column: 'modifiedBy' },
  ]

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleClose = () => {
    setMode(null)
    setOverDraft({})
  }

  const handleSelectOverDraft = (r, mode) => {
    if (mode === 'verify') {
    }

    setOverDraft(r)
    setMode(mode)
  }

  const fetchOverDrafts = async () => {
    const url =
      '/CustomerAccount/find-customer-accounts-overdraft-protection-by-filter-in-page'

    setIsBusy(true)

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setOverDrafts(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchOverDrafts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={mode}
          data={overDraft}
          y
          title={`${localize('overdraftProtection')} : ${
            overDraft?.customerAccountCustomerAccountTypeTargetProductDescription
          } -> ${overDraft?.customerFullName}`}
        />
      )}

      {(mode === 'add' || mode === 'edit') && (
        <CreateOverDraftProtection
          mode={mode}
          fetchOverdrafts={fetchOverDrafts}
          handleClose={handleClose}
          overdraftId={mode === 'edit' ? overDraft?.id : null}
        />
      )}

      {mode === 'verify' && (
        <VerifyOverdraftProtection
          fetchOverdrafts={fetchOverDrafts}
          handleClose={handleClose}
          mode={mode}
          overdraftId={overDraft?.id}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('customerAccounts'),
          localize('overdraftProtectionCatalogue'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 180,
                      minWidth: 180,
                    }}
                    value={groupBy?.value}
                    enumsKey="CustomerAccountOverdraftProtectionPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>

                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        customerFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchOverDrafts()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchOverDrafts()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={overDrafts?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectOverDraft(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      {localize('edit')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectOverDraft(r, 'verify')
                      }}
                    >
                      <i className="mdi mdi-check-decagram"></i>
                      {localize('verify')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectOverDraft(r, 'auditLogs')
                      }}
                    >
                      <i className="uil uil-server"></i>
                      {localize('viewAuditLogs')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={overDrafts?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default OverdraftProtection
