/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../ModalTemplate/ModalTemplate'
import AuditLogs from '../AuditLogs/AuditLogs'

const ViewAuditLogs = ({ mode, handleClose, data, title }) => {
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        handleClose={handleClose}
        cancelText="Close"
        modalTitle={
          title ||
          `${data?.transactionCodeDescription} : ${data?.primaryDescription} -> ${data?.totalValueFormatted}`
        }
        hideUpdate
        modalClassName="modal-xl"
      >
        <AuditLogs recordId={data?.id} />
      </ModalTemplate>
    </>
  )
}

export default ViewAuditLogs
