/** @format */

import React, { useState } from 'react'
import { postData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import {
  formatCurrency,
  getTodaysDate,
} from '../../../../../../../Helpers/formatters'
import { PurchaseOrderEntries } from './PurchaseOrderEntries'
import { SupplierLookup } from '../../../../../../../Components/Lookup/SupplierLookup'
import { QuotationQuotationItemsLookup } from '../../../../../../../Components/Lookup/QuotationQuotationItemsLookup'
import { QuotationBySupplierLookup } from '../../../../../../../Components/Lookup/QuotationBySupplierLookup'

export const CreatePurchaseOrderModal = ({
  purchaseOrderModel,
  setPurchaseOrderModel,
  isBusy,
  purchaseOrderEntries,
  setPurchaseOrderEntries,
}) => {
  const [purchaseOrderItemModel, setPurchaseOrderItemModel] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOnAddPurchaseOrder = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/PurchaseOrder/add-purchase-order-entries',
      {
        QuotationItem: purchaseOrderItemModel?.quotationItem,
        Quantity: purchaseOrderItemModel.quantity,
        QuotationItems: purchaseOrderEntries || [],
      },
      false,
    )
    if (success) {
      setPurchaseOrderEntries([...purchaseOrderEntries, data])
      setPurchaseOrderItemModel(null)
    }
    setLoading(false)
  }

  const handleOnRemovePurchaseOrder = e => {
    setPurchaseOrderEntries(purchaseOrderEntries.filter(x => x.id !== e.id))
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('supplier')}
              </label>
              <SupplierLookup
                displayValue={purchaseOrderModel?.supplierDescription}
                onSelect={r => {
                  setPurchaseOrderModel({
                    ...purchaseOrderModel,
                    supplierDescription: r.description,
                    supplierId: r.id,
                    supplierAddressEmail: r.addressEmail,
                    supplierAddressMobileLine: r.addressMobileLine,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <CustomDatePicker
                name="expectedDate"
                defaultDate={purchaseOrderModel?.expectedDate}
                onDateChange={date => {
                  setPurchaseOrderModel({
                    ...purchaseOrderModel,
                    expectedDate: date,
                  })
                }}
                maxDate={getTodaysDate()}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control"
                disabled
                value={purchaseOrderModel?.supplierAddressMobileLine}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control"
                disabled
                value={purchaseOrderModel?.supplierAddressEmail}
              />
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120,
              }}
            >
              {localize('itemDetails')}
            </div>
            <div className="row mb-3" style={{ marginTop: -10 }}>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('itemDescription')}
                </label>
                <QuotationBySupplierLookup
                  supplierDescription={purchaseOrderModel?.supplierDescription}
                  supplierId={
                    purchaseOrderModel?.supplierId ||
                    '00000000-0000-0000-0000-000000000000'
                  }
                  displayValue={
                    purchaseOrderItemModel?.quotation?.paddedReferenceNumber
                  }
                  onSelect={r => {
                    setPurchaseOrderItemModel({
                      ...purchaseOrderItemModel,
                      quotation: { ...r },
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('item')}
                </label>
                <QuotationQuotationItemsLookup
                  onSelect={e => {
                    console.log(e)
                    setPurchaseOrderItemModel({
                      ...purchaseOrderItemModel,
                      quotationItem: e,
                    })
                  }}
                  displayValue={
                    purchaseOrderItemModel?.quotationItem
                      ?.itemRegisterDescription
                  }
                  quotationId={purchaseOrderItemModel?.quotation?.id}
                  quotationDescription={
                    purchaseOrderItemModel?.quotation?.supplierDescription
                  }
                />
              </div>
            </div>
            <div className="row mb-3" style={{ marginTop: -10 }}>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('assetType')}
                </label>
                <input
                  className="form-control"
                  disabled
                  value={
                    purchaseOrderItemModel?.quotationItem
                      ?.itemRegisterAssetTypeDescription || ''
                  }
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('unitOfMeasure')}
                </label>
                <input
                  className="form-control"
                  disabled
                  value={
                    purchaseOrderItemModel?.quotationItem
                      ?.itemRegisterInventoryUnitOfMeasureDescription || ''
                  }
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: -10 }}>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('estimatedUnitCost')}
                </label>
                <input
                  className="form-control text-end"
                  disabled
                  value={formatCurrency(
                    purchaseOrderItemModel?.quotationItem?.unitCost || '',
                    2,
                    2,
                    false,
                  )}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-3">
                  {localize('quantity')}
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={purchaseOrderItemModel?.quantity || ''}
                  onChange={e =>
                    setPurchaseOrderItemModel({
                      ...purchaseOrderItemModel,
                      quantity: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div
                className="bg-light d-flex justify-content-end align-items-center p-2 mt-2"
                style={{ borderRadius: 5 }}
              >
                <button
                  onClick={handleOnAddPurchaseOrder}
                  className="btn btn-success text-uppercase ms-3 px-4"
                >
                  {localize('add')}
                </button>
              </div>
            )}
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 120,
                  marginBottom: -10,
                }}
              >
                {localize('purchaseOrderItems')}
              </div>
              <PurchaseOrderEntries
                entries={purchaseOrderEntries}
                handleOnRemove={handleOnRemovePurchaseOrder}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
