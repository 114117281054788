/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import localize from './../../../../Global/Localize'
import CreateSupplyCategory from './Partials/CreateSupplyCategory'

const SupplyCategories = () => {
  const [isBusy, setIsBusy] = useState(true)
  const [mode, setMode] = useState('')
  const [tableData, setTableData] = useState([])
  const [tableMeta, setTableMeta] = useState({})
  const [supplyCategories, setSupplyCategories] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
  })

  useEffect(() => {
    loadSupplyCategories()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadSupplyCategories() {
    setIsBusy(true)

    const url = '/SupplyCategory/find-supply-categories-by-filter-in-page'

    const { data, success } = await getData(url, reqParams, false)
    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('description'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleClose = () => {
    setMode('')
    setSupplyCategories(null)
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <CreateSupplyCategory
          setMode={setMode}
          mode={mode}
          loadTableData={loadSupplyCategories}
          handleClose={handleClose}
          supplyCategories={supplyCategories}
          setSupplyCategories={setSupplyCategories}
        />
      )}
      <BreadCrumb
        tree={[
          localize('procurementAndAssetManagement'),
          localize('supplyCategories'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-start mb-3">
            {<AddNewButton handleClickAddButton={() => setMode('add')} />}
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex  align-items-center justify-content-between">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />

                <form
                  className="d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setRefresh(!refresh)
                  }}
                >
                  <label className="text-capitalize" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    defaultValue={reqParams.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <button
                    id="supplyFilterBtn"
                    className="btn btn-primary ms-1"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="row d-flex mt-3">
                <SimpleTable
                  contextMenu={r => (
                    <>
                      <div
                        onClick={async () => {
                          setMode('edit')
                          setSupplyCategories(r)
                        }}
                        className="ctxt-menu-item text-capitalize"
                      >
                        <i className="uil uil-edit"></i>
                        {localize('edit')}
                      </div>
                    </>
                  )}
                  data={tableData}
                  columns={columns}
                />
              </div>

              <div className="mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SupplyCategories
