/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import ViewGLAccountTransactions from '../../../../../Components/ReUsables/GLAccounts/ViewGLAccountTransactions/ViewGLAccountTransactions'

const FiscalCatalogue = () => {
  const [catalogues, setCatalogues] = useState([])
  const [catalogue, setCatalogue] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'Branch',
    value: 0,
    column: 'branchDescription',
  })
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    fiscalCountFilter: 3,
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('transactionCode'),
      template: r => r.transactionCodeDescription,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('primaryDescription'),
      template: r => r.primaryDescription,
    },
    {
      label: localize('secondaryDescription'),
      template: r => r.secondaryDescription,
    },
    { label: localize('reference'), template: r => r.reference },
    {
      label: localize('glAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('transactionSource'),
      template: r => r.treasuryTrailSourceDescription,
    },
    {
      label: localize('transactionDestination'),
      template: r => r.treasuryTrailDestinationDescription,
    },
    {
      label: localize('denominationOneThousandValue'),
      template: r => formatCurrency(r.denominationOneThousandValue),
    },
    {
      label: localize('denominationFiveHundredValue'),
      template: r => formatCurrency(r.denominationFiveHundredValue),
    },
    {
      label: localize('denominationTwoHundredValue'),
      template: r => formatCurrency(r.denominationTwoHundredValue),
    },
    {
      label: localize('denominationOneHundredValue'),
      template: r => formatCurrency(r.denominationOneHundredValue),
    },
    {
      label: localize('denominationFiftyValue'),
      template: r => formatCurrency(r.denominationFiftyValue),
    },
    {
      label: localize('denominationFourtyValue'),
      template: r => formatCurrency(r.denominationFourtyValue),
    },
    {
      label: localize('denominationTwentyValue'),
      template: r => formatCurrency(r.denominationTwentyValue),
    },
    {
      label: localize('denominationTenValue'),
      template: r => formatCurrency(r.denominationTenValue),
    },
    {
      label: localize('denominationFiveValue'),
      template: r => formatCurrency(r.denominationFiveValue),
    },
    {
      label: localize('denominationOneValue'),
      template: r => formatCurrency(r.denominationOneValue),
    },
    {
      label: localize('denominationFiftyCentValue'),
      template: r => formatCurrency(r.denominationFiftyCentValue),
    },
    {
      label: localize('totalValue'),
      template: r => formatCurrency(r.totalValue),
    },
    {
      label: localize('postingPeriod'),
      template: r => r.postingPeriodDescription,
    },
    { label: localize('modifiedBy'), template: r => r.modifiedBy },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r.modifiedDate),
    },
    { label: localize('createdBy'), template: r => r.createdBy },
  ]

  const groupingColumns = [
    { value: 0, column: 'branchDescription' },
    { value: 1, column: 'postingPeriodDescription' },
    { value: 2, column: 'chartOfAccountName' },
    { value: 3, column: 'transactionCodeDescription' },
    { value: 4, column: 'createdBy' },
  ]

  const fetchCatalogues = async () => {
    setIsBusy(true)

    const url =
      '/FiscalCount/find-fiscal-counts-by-date-range-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setCatalogues(data)
    }

    setIsBusy(false)
  }

  const handleSelectCatalogue = catalogue => {
    setCatalogue(catalogue)
    setMode('view')
  }

  const handleClose = () => {
    setMode(null)
    setCatalogue({})
  }

  useEffect(() => {
    fetchCatalogues()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'view' && (
        <ViewGLAccountTransactions
          glAccount={catalogue}
          handleClose={handleClose}
          mode={mode}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('treasury'),
          localize('fiscalCatalogue'),
        ]}
      />

      <div className="card rounded bg-white">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row d-flex align-items-center justify-content-between mb-3 gap-1">
                <div className="d-flex align-items-center col">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-1"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>

                <div className="d-flex col align-items-center">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>

                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 130,
                      minWidth: 130,
                    }}
                    value={groupBy?.value}
                    enumsKey="FiscalCountPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>

                <div className="d-flex align-items-center col">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                  />
                </div>
                <div
                  className="d-flex align-items-center gap-1 col-md-5"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label className="text-capitalize me-1">
                    {localize('search')}
                  </label>

                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 130,
                      minWidth: 130,
                    }}
                    value={reqParams?.fiscalCountFilter}
                    enumsKey="FiscalCountFilter"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, fiscalCountFilter: value })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchCatalogues()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchCatalogues()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>

              <GroupedTable
                columns={columns}
                data={catalogues?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => handleSelectCatalogue(r)}
                    >
                      <i className="uil uil-eye"></i>
                      <span className="text-capitalize">
                        {localize('viewglAccountTransactions')}
                      </span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={catalogues?.itemsCount}
                {...reqParams}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default FiscalCatalogue
