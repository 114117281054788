/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import ViewMobileToBankDataModal from '../ViewMobileToBankDataModal'
import VerifyMobileToBankDataModal from './VerifyMobileToBankDataModal'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Verification() {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
      sortBy: 'paddedSerialNumber',
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
      sortBy: 'recordStatusDescription',
    },
    {
      label: localize('mobileToBankTerminal'),
      template: r => <>{r?.mobileToBankTerminalDescription}</>,
      sortBy: 'mobileToBankTerminalDescription',
    },
    {
      label: localize('mSISDN'),
      template: r => <>{r?.msisdn}</>,
      sortBy: 'msisdn',
    },
    {
      label: localize('transactionAmount'),
      template: r => <>{formatCurrency(r?.transAmount)}</>,
      sortBy: 'transAmount',
    },
    {
      label: localize('transactionId'),
      template: r => <>{r?.transID}</>,
      sortBy: 'transID',
    },
    {
      label: localize('billReferenceNumber'),
      template: r => <>{r?.billRefNumber}</>,
      sortBy: 'billRefNumber',
    },
    {
      label: localize('transactionTime'),
      template: r => <>{formatDate(r?.formattedTransTime, false)}</>,
      sortBy: 'transTime',
    },
    {
      label: localize('kYCInformation'),
      template: r => <>{r?.kycInfo}</>,
      sortBy: 'kycInfo',
    },
    {
      label: localize('businessShortCode'),
      template: r => <>{r?.businessShortCode}</>,
      sortBy: 'businessShortCode',
    },
    {
      label: localize('thirdPartyTransactionId'),
      template: r => <>{r?.thirdPartyTransID}</>,
      sortBy: 'thirdPartyTransID',
    },
    {
      label: localize('invoiceNumber'),
      template: r => <>{r?.invoiceNumber}</>,
      sortBy: 'invoiceNumber',
    },
    {
      label: localize('organisationAccountBalance'),
      template: r => <>{r?.orgAccountBalance}</>,
      sortBy: 'orgAccountBalance',
    },
    {
      label: localize('senderType'),
      template: r => <>{r?.senderTypeDescription}</>,
      sortBy: 'senderTypeDescription',
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
      sortBy: 'remarks',
    },
    {
      label: localize('verifiedBy'),
      template: r => <>{r?.auditedBy}</>,
      sortBy: 'auditedBy',
    },
    {
      label: localize('verifiedDate'),
      template: r => <>{r?.auditedDate}</>,
      sortBy: 'auditedDate',
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
      sortBy: 'modifiedBy',
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
      sortBy: 'modifiedDate',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    status: 2,
    filterText: '',
    mobileToBankRequestFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('kycInfo'),
    value: 3,
    column: 'kycInfo',
  })

  const [mode, setMode] = useState(null)
  const [selectedMobileToBankRequest, setSelectedMobileToBankRequest] =
    useState()
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.pageIndex,
    reqParams.pageSize,
    reqParams.filterText,
    reqParams.startDate,
    reqParams.endDate,
  ])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/MobileToBankRequest/find-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
    }
    setIsBusy(false)
  }

  const groupingColumns = [
    {
      value: 0,
      column: 'msisdn',
    },
    {
      value: 1,
      column: 'TransTime',
    },
    {
      value: 2,
      column: 'billRefNumber',
    },
    {
      value: 3,
      column: 'kycInfo',
    },
    {
      value: 4,
      column: 'statusDescription',
    },
    {
      value: 5,
      column: 'modifiedBy',
    },
    {
      value: 6,
      column: 'recordStatusDescription',
    },
    {
      value: 7,
      column: 'auditedBy',
    },
    {
      value: 8,
      column: 'reconciliationTypeDescription',
    },
    {
      value: 9,
      column: 'senderTypeDescription',
    },
  ]

  function closeModal() {
    setMode(null)
    setSelectedMobileToBankRequest(null)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('mobileToBank'),
          localize('mobileToBankRequestsVerification'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex align-items-center">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <label className="me-2 text-capitalize">
                      {localize('groupBy')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        width: 150,
                        minWidth: 150,
                      }}
                      value={groupBy?.value}
                      enumsKey="MobileToBankRequestPropertyGroup"
                      onChange={({ label, value }) =>
                        setGroupBy({
                          label,
                          value,
                          column: groupingColumns[value]?.column,
                        })
                      }
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize me-2"
                      htmlFor="searchByDate"
                    >
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams?.startDate,
                        endDate: reqParams?.endDate,
                      }}
                      onSelect={v => {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      }}
                    />
                  </div>
                  <div className="d-flex align-item-center gap-2">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                          pageIndex: 0,
                        })
                      }}
                    >
                      <label
                        className="ms-2 text-capitalize"
                        htmlFor="searchTable-cat"
                      >
                        {localize('search')}
                      </label>
                      <EnumsServerCombo
                        className="ms-2"
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="MobileToBankRequestFilter"
                        value={reqParams?.mobileToBankRequestFilter}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            mobileToBankRequestFilter: e.value,
                          })
                        }
                      />
                      <input
                        type="text"
                        id="searchTable-m2b-reconciliation"
                        className="form-control ms-2"
                        value={search}
                        onChange={e => {
                          setSearch(e.target.value)
                        }}
                      />
                      <button type="submit" className="btn btn-primary ms-2">
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                </div>

                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={data?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setMode('view')
                          setSelectedMobileToBankRequest(r)
                        }}
                      >
                        <i className="uil uil-eye"></i>
                        {localize('view')}
                      </div>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setMode('verify')
                          setSelectedMobileToBankRequest(r)
                        }}
                      >
                        <i className="uil uil-unlock-alt"></i>
                        {localize('verify')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(r)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
                <div className="mb-2"></div>
                <Pager
                  itemsCount={data?.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedSerialNumber}
        />
      )}
      {mode === 'verify' ? (
        <VerifyMobileToBankDataModal
          selectedMobileToBankRequest={selectedMobileToBankRequest}
          setSelectedMobileToBankRequest={setSelectedMobileToBankRequest}
          mode={mode}
          close={() => setMode(null)}
          loadData={loadData}
        />
      ) : (
        mode === 'view' && (
          <ViewMobileToBankDataModal
            selectedMobileToBankRequest={selectedMobileToBankRequest}
            mode={mode}
            close={() => setMode(null)}
          />
        )
      )}
    </>
  )
}

export default Verification
