/** @format */

import { ViewCustomerSpecimen } from '../../../../../../../../../../Components/ReUsables/Customer/ViewCustomerSpecimen'

export const CustomerPreviewSpecimen = ({ customer }) => {
  return (
    <>
      <ViewCustomerSpecimen customer={customer} readOnly />
    </>
  )
}
