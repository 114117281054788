/** @format */

import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskPercentage } from '../../../../Components/InputMasks/MaskPercentage'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Tab from '../../../../Components/Tabs/Tab'
import { AddChargeSplit } from './Partials/AddChargeSplit'
import React from 'react'
import Loader from '../../../../Components/Loaders/Loader'
import { chargeType } from '../../../../Helpers/constants'

export const EditLevyModal = ({
  selectedLevy,
  setSelectedLevy,
  isBusy,
  mode,
  levySplits,
  setLevySplits,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['LevyDTO.Description']?.message
              }
            >
              <label className="col-1 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedLevy?.description}
                onChange={e => {
                  clearValidation('LevyDTO.Description')
                  setSelectedLevy({
                    ...selectedLevy,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('chargeType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="ChargeType"
                value={selectedLevy?.chargeType}
                onChange={e =>
                  setSelectedLevy({ ...selectedLevy, chargeType: e.value })
                }
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['LevyDTO.ChargePercentage']?.message
              }
            >
              {selectedLevy?.chargeType === chargeType?.PERCENTAGE ? (
                <>
                  <label className="col-3 text-capitalize">
                    {localize('percentage')}
                  </label>
                  <MaskPercentage
                    defaultMaskValue={selectedLevy?.chargePercentage}
                    onMaskChange={e =>
                      setSelectedLevy({
                        ...selectedLevy,
                        chargePercentage: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <>
                  <label className="col-3 text-capitalize">
                    {localize('charge')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={selectedLevy?.chargeFixedAmount}
                    onMaskNumber={e => {
                      setSelectedLevy({
                        ...selectedLevy,
                        chargeFixedAmount: e.target.value,
                      })
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('chargeSplitting'),
                    item: (
                      <AddChargeSplit
                        mode={mode}
                        setLevySplits={setLevySplits}
                        levySplits={levySplits}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
