/** @format */
/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import {
  Gender,
  IdentityCardType,
  NextOfKinRelationship,
  VitalStatus,
} from '../../../../Global/enumeration'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { NextOfKinPreference } from './NextOfKinPreference'
import Swal from 'sweetalert2'
import { MaskPercentage } from '../../../../Components/InputMasks/MaskPercentage'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { object, string } from 'yup'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'

export const NextOfKinDetails = ({ selectedCustomer, onAddNewNextOfKin }) => {
  const [nextOfKinDetailModel, setNextOfKinDetailModel] = useState(null)
  const [nextOfKinDetailEntries, setNextOfKinDetailEntries] = useState([])
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const runValidation = async model => {
    let nextOfKinSchema = object({
      firstName: string().required('First Name is required'),
      lastName: string().required('Last Name is required'),
    })

    try {
      await nextOfKinSchema.validate(model, { abortEarly: false })
      return true
    } catch (e) {
      const validations = e.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message
        return acc
      }, {})
      setValidationBag(validations)
    }
  }

  function clearValidation(column) {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const addNextOfKinPreference = async () => {
    if (!nextOfKinDetailModel) {
      Swal.fire({
        icon: 'error',
        text: 'Missing Next Of Kin Details',
        title: 'Edit Next Of Kin',
        showCloseButton: true,
      })
      return
    }

    const modelValid = await runValidation(nextOfKinDetailModel)

    if (modelValid) {
      if (nextOfKinDetailModel.isNominee) {
        let totalAllocated = nextOfKinDetailEntries
          .filter(x => x.isNominee)
          .reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.nominatedPercentage,
            0
          )

        if (
          parseFloat(nextOfKinDetailModel.nominatedPercentage) +
            parseFloat(totalAllocated) >
          100
        ) {
          Swal.fire({
            icon: 'error',
            text: 'Invalid apportionment - greater than 100%',
            title: 'Edit Next Of Kin',
            showCloseButton: true,
          })
          return
        }
      }

      setNextOfKinDetailEntries(prevEntries => {
        const updatedEntries = [
          ...prevEntries,
          {
            ...nextOfKinDetailModel,
            clientId: prevEntries.length + 1,
            customerId: selectedCustomer.id,
            fullName:
              `${nextOfKinDetailModel?.firstName} ${nextOfKinDetailModel?.lastName}` ||
              '',
            vitalStatusDescription:
              VitalStatus[
                nextOfKinDetailModel.vitalStatus
                  ? nextOfKinDetailModel.vitalStatus
                  : 0
              ].name,
            relationshipDescription:
              NextOfKinRelationship[
                nextOfKinDetailModel.relationship
                  ? nextOfKinDetailModel.relationship
                  : 0
              ].name,
            genderDescription:
              Gender[
                nextOfKinDetailModel.gender ? nextOfKinDetailModel.gender : 0
              ].name,
            identityCardTypeDescription:
              IdentityCardType[
                nextOfKinDetailModel.identityCardType
                  ? nextOfKinDetailModel.identityCardType
                  : 0
              ].name,
            isNextOfKin: nextOfKinDetailModel.isNextOfKin || false,
            isNominee: nextOfKinDetailModel.isNominee || false,
            isBenevolentSpouse:
              nextOfKinDetailModel.isBenevolentSpouse || false,
            isBenevolentChild: nextOfKinDetailModel.isBenevolentChild || false,
          },
        ]
        onAddNewNextOfKin(updatedEntries)
        return updatedEntries
      })

      setNextOfKinDetailModel(null)
    }
  }

  const handleOnRemoveNextOfKin = r => {
    setNextOfKinDetailEntries(
      nextOfKinDetailEntries.filter(x => x.clientId !== r.clientId)
    )
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('vitalStatus')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="VitalStatus"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                vitalStatus: e.value,
              })
            }}
            value={nextOfKinDetailModel?.vitalStatus || 0}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('Salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Salutation"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                salutation: e.value,
              })
            }}
            value={nextOfKinDetailModel?.salutation || 0}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">{localize('gender')}</label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                gender: e.value,
              })
            }}
            value={nextOfKinDetailModel?.gender || 0}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('relationship')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="NextOfKinRelationship"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                relationship: e.value,
              })
            }}
            value={nextOfKinDetailModel?.relationship || 0}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div
          validation-message={validationBag?.firstName}
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-5 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            className="form-control"
            value={nextOfKinDetailModel?.firstName || ''}
            onChange={e => {
              clearValidation('firstName')
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                firstName: e.target.value,
              })
            }}
          />
        </div>
        <div
          validation-message={validationBag?.lastName}
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-5 text-capitalize">
            {localize('otherNames')}
          </label>
          <input
            className="form-control"
            value={nextOfKinDetailModel?.lastName || ''}
            onChange={e => {
              clearValidation('lastName')
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                lastName: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('birthDate')}
          </label>
          <input
            className="form-control"
            type="date"
            value={nextOfKinDetailModel?.birthDate || ''}
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                birthDate: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('educationLevel')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="EducationLevel"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                educationLevel: e.value,
              })
            }}
            value={nextOfKinDetailModel?.educationLevel || 0}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                identityCardType: e.value,
              })
            }}
            value={nextOfKinDetailModel?.identityCardType || 0}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('identityCardNumber')}
          </label>
          <MaskNumber
            numeralThousandsGroupStyle={'none'}
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                identityCardNumber: e.target.value,
              })
            }}
            className="form-control"
            value={nextOfKinDetailModel?.identityCardNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('identityCardSerialNumber')}
          </label>
          <MaskNumber
            numeralThousandsGroupStyle={'none'}
            value={nextOfKinDetailModel?.identityCardSerialNumber || ''}
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                identityCardSerialNumber: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('nominated')}
          </label>
          <MaskPercentage
            defaultMaskValue={nextOfKinDetailModel?.nominatedPercentage || ''}
            onMaskChange={c => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                nominatedPercentage: c.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('addressLine1')}
          </label>
          <input
            className="form-control"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressAddressLine1: e.target.value,
              })
            }}
            value={nextOfKinDetailModel?.addressAddressLine1 || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('addressLine2')}
          </label>
          <input
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressAddressLine2: e.target.value,
              })
            }}
            className="form-control"
            value={nextOfKinDetailModel?.addressAddressLine2 || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">{localize('city')}</label>
          <input
            className="form-control"
            value={nextOfKinDetailModel?.addressCity || ''}
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressCity: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('postalCode')}
          </label>
          <input
            className="form-control"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressPostalCode: e.target.value,
              })
            }}
            value={nextOfKinDetailModel?.addressPostalCode || ''}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('physicalAddress')}
          </label>
          <input
            className="form-control"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressStreet: e.target.value,
              })
            }}
            value={nextOfKinDetailModel?.addressStreet || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('mobileLine')}
          </label>
          <input
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressMobileLine: e.target.value,
              })
            }}
            className="form-control"
            value={nextOfKinDetailModel?.addressMobileLine || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('landLine')}
          </label>
          <input
            className="form-control"
            value={nextOfKinDetailModel?.addressLandLine || ''}
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressLandLine: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-5 text-capitalize">{localize('email')}</label>
          <input
            className="form-control"
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                addressEmail: e.target.value,
              })
            }}
            value={nextOfKinDetailModel?.addressEmail || ''}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-3 d-flex align-items-center">
          <label className="col-5 text-capitalize">
            {localize('vitalStatusDate')}
          </label>
          <input
            className="form-control"
            type="date"
            value={nextOfKinDetailModel?.vitalStatusDate || ''}
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                vitalStatusDate: e.target.value,
              })
            }}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label className="col-5 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            value={nextOfKinDetailModel?.remarks || ''}
            onChange={e => {
              setNextOfKinDetailModel({
                ...nextOfKinDetailModel,
                remarks: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div
        className="bg-light d-flex justify-content-end align-items-center p-2"
        style={{ borderRadius: 5 }}
      >
        <div className="d-flex align-content-end justify-content-end">
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'is-next-of-kin'}
              checked={nextOfKinDetailModel?.isNextOfKin || ''}
              onCheck={v =>
                setNextOfKinDetailModel({
                  ...nextOfKinDetailModel,
                  isNextOfKin: v,
                })
              }
            />
            <label htmlFor="is-next-of-kin" className="text-capitalize ms-2">
              {localize('nextOfKin')}?
            </label>
          </div>
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'is-nominee'}
              checked={nextOfKinDetailModel?.isNominee || ''}
              onCheck={v =>
                setNextOfKinDetailModel({
                  ...nextOfKinDetailModel,
                  isNominee: v,
                })
              }
            />
            <label htmlFor="is-nominee" className="text-capitalize ms-2">
              {localize('nominee')}?
            </label>
          </div>
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'is-benevolent-spouse'}
              checked={nextOfKinDetailModel?.isBenevolentSpouse || ''}
              onCheck={v =>
                setNextOfKinDetailModel({
                  ...nextOfKinDetailModel,
                  isBenevolentSpouse: v,
                })
              }
            />
            <label
              htmlFor="is-benevolent-spouse"
              className="text-capitalize ms-2"
            >
              {localize('benevolentSpouse')}?
            </label>
          </div>
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'is-benevolent-child'}
              checked={nextOfKinDetailModel?.isBenevolentChild || ''}
              onCheck={v =>
                setNextOfKinDetailModel({
                  ...nextOfKinDetailModel,
                  isBenevolentChild: v,
                })
              }
            />
            <label
              htmlFor="is-benevolent-child"
              className="text-capitalize ms-2"
            >
              {localize('benevolentChild')}?
            </label>
          </div>
        </div>
        <button
          onClick={() => {
            addNextOfKinPreference()
            onAddNewNextOfKin(nextOfKinDetailEntries)
          }}
          className="btn btn-success text-uppercase ms-3 px-4"
        >
          {localize('add')}
        </button>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('nextOfKinPreferences')}
        </div>
      </div>
      <NextOfKinPreference
        addMode
        removeNextOfKin={r => {
          handleOnRemoveNextOfKin(r)
        }}
        nextOfKinPreference={nextOfKinDetailEntries}
      />
    </>
  )
}
