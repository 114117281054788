/** @format */

/* eslint-disable */
import React from 'react'

export const PleaseWait = () => {
  return (
    <div
      style={{
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span className="me-3">Please wait...</span>
      <div className="progress" style={{ width: '50%' }}>
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style={{ width: '100%' }}
        ></div>
      </div>
    </div>
  )
}
