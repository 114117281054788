/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { CustomerFilter } from '../../../../Global/enumeration'
import { getData, putData } from '../../../../Helpers/webApi'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import DatePicker from '../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Swal from 'sweetalert2'
import swal from 'sweetalert2'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function ChannelServiceEnquiries() {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedServiceEnquiries, setSelectedServiceEnquiries] = useState([])
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  const [mode, setMode] = useState(null)

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedServiceEnquiries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedServiceEnquiries([...selectedServiceEnquiries, r])
            } else {
              setSelectedServiceEnquiries(
                selectedServiceEnquiries.filter(s => s.id !== r.id)
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerPersonalFileNumber}</>,
    },
    {
      label: localize('serviceType'),
      template: r => <>{r?.serviceTypeDescription}</>,
    },
    { label: localize('origin'), template: r => <>{r?.originDescription}</> },
    {
      label: localize('transactionCode'),
      template: r => <>{r?.transactionCode}</>,
    },
    { label: localize('fulFilledBy'), template: r => <>{r?.fulFilledBy}</> },
    {
      label: localize('fulFilledDate'),
      template: r => <>{formatDate(r?.fulFilledDate)}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageCount: 10,
    pageIndex: 0,
    customerFilter: Object.keys(CustomerFilter)[0],
    status: 0,
    startDate: null,
    dateRequestsFilter: 15,
    serviceType: 1,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  useEffect(() => {
    loadTableData()
  }, [reqParams])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AlternateChannel/find-alternate-channel-service-enquiries-by-status-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const handleOnFulFill = async () => {
    setIsBusy(true)
    if (selectedServiceEnquiries.length === 0) {
      Swal.fire(
        'Alternate Channel Service Enquiries',
        'Please select at least one Journal Voucher',
        'warning'
      )
      return
    }
    const { success, data } = await putData(
      '/AlternateChannel/fulfill-alternate-channel-service-enquiries',
      { AlternateChannelServiceEnquiries: selectedServiceEnquiries },
      false
    )
    if (success) {
      swal.fire(
        'Alternate Channel Service Enquiries',
        'Operation Completed Successfully',
        'success'
      )
      setIsBusy(false)
      setSelectedServiceEnquiries([])
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('channelActivationRequests'),
          localize('archived'),
        ]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.customerFullName}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <button
                        onClick={handleOnFulFill}
                        className="btn btn-success waves-effect waves-light text-capitalize me-2"
                      >
                        <i className="mdi mdi-decagram-outline"></i>
                        {localize('fulfill')}
                      </button>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-3 d-flex align-items-center">
                      <label className="col-3">Service Type</label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="ServiceEnquiryType"
                        value={reqParams.serviceType}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            serviceType: e.value,
                            pageIndex: 0,
                          })
                        }}
                        sort
                      />
                    </div>
                    <div className="col-md-3 d-flex align-items-center">
                      <label className="col-3">Status</label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="AlternateChannelServiceEnquiryStatusFilter"
                        value={reqParams.status}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            status: e.value,
                            pageIndex: 0,
                          })
                        }}
                        sort
                      />
                    </div>
                  </div>
                  <div className="row">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-3">
                      <div
                        className="d-flex align-items-center me-5"
                        style={{ width: '300px' }}
                      >
                        <label className="col-3 text-capitalize">
                          {localize('pickDate')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <EnumsServerCombo
                        className="ms-2"
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        value={reqParams.customerFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                            pageIndex: 0,
                          })
                        }}
                        sort
                      />
                    </div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                            })
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                      contextMenu={e => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(e)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      itemsCount={tableMeta?.itemsCount}
                      pageSize={reqParams?.pageCount}
                      pageIndex={reqParams?.pageIndex}
                      pageBatchSize={5}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChannelServiceEnquiries
