/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { getData } from '../../../../Helpers/webApi'
import Loader from '../../../../Components/Loaders/Loader'

function MobileToBankRequestEntries({
  selectedMobileToBankRequest,
  mode,
  handleOnCheckEntry,
  removeSelectedEntries,
  entryStatus,
  setApportioned,
  setShortage,
  refreshEntry,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedEntries, setSelectedEntries] = useState([])
  const [toggleSelection, setToggleSelection] = useState(false)
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: entryStatus,
    filterText: '',
    mobileToBankRequestEntryFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  useEffect(() => {
    if (mode === 'reconcile') {
      handleOnCheckEntry(selectedEntries)
    }
  }, [selectedEntries, refreshEntry])

  const columns = [
    {
      label: mode === 'reconcile' ? localize('select') : '',
      template: r =>
        mode === 'reconcile' ? (
          <Checkbox
            checked={selectedEntries.find(obj => obj.id === r.id)}
            onCheck={v => {
              addNewCustomerExemptEntry(r, v)
            }}
          />
        ) : (
          <></>
        ),
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('apportionTo'),
      template: r => <>{r.apportionToDescription}</>,
    },
    {
      label: localize('apportionedComponent'),
      template: r => <>{r.apportionedComponentDescription}</>,
    },
    {
      label: localize('apportionedAmount'),
      template: r => <>{formatCurrency(r.apportionedAmount)}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerAccountProduct'),
      template: r => (
        <>{r.customerAccountCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },

    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },

    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const onCheckEntry = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedEntries([])
      setSelectedEntries([...entries])
    } else {
      setSelectedEntries([])
      setToggleSelection(checkStatus)
    }
  }

  const addNewCustomerExemptEntry = (customer, checkStatus) => {
    if (checkStatus) {
      if (!selectedEntries.some(obj => obj.id === customer.id)) {
        setSelectedEntries(prev => {
          return [...selectedEntries, customer]
        })
      }
    } else {
      setSelectedEntries(prev => {
        return prev.filter(obj => obj.id !== customer.id)
      })
    }
  }

  const loodMobileToBankRequestEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/MobileToBankRequest/find-entries-by-mobile-to-bank-request-id-in-page`,
      {
        mobileToBankRequestId: selectedMobileToBankRequest.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      console.log(data)
      setEntries(data.pageCollection)
      setApportioned(data.totalApportioned)
      setShortage(data.totalShortage)
    } else {
      setEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    console.log('apa....')
    loodMobileToBankRequestEntries()
  }, [
    selectedMobileToBankRequest?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
    refreshEntry,
  ])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row px-3">
            <div className="d-flex align-items-center col-2">
              <label
                htmlFor="recordsPerPage"
                className=""
                style={{ marginLeft: -10, textTransform: 'capitalize' }}
              >
                {localize('recordsPerPage')}
              </label>
              <select
                name=""
                id="recordsPerPage"
                className="form-select ms-1"
                style={{ maxWidth: 'fit-content' }}
                defaultValue={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageIndex: 0,
                    pageSize: e.target.value,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <SimpleTable selectables columns={columns} data={entries} />
          {mode === 'reconcile' && (
            <>
              <div className="row mb-3 g-3 mt-2">
                <div className="col-md-6">
                  <div className="d-flex align-items-center mb-2">
                    <Checkbox
                      id="toggle-selection"
                      checked={toggleSelection}
                      onCheck={v => {
                        onCheckEntry(v)
                      }}
                    />
                    <label
                      htmlFor="toggle-selection"
                      className="text-capitalize ms-2"
                    >
                      {localize('toggleSelection')}
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="d-flex justify-content-end align-items-end">
                    <button
                      onClick={removeSelectedEntries}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('remove')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
export default MobileToBankRequestEntries
