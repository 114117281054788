/** @format */

import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'
import localize from '../../../../../Global/Localize'

export const EmolumentTypeFlags = ({
  selectedEmolumentType,
  setSelectedEmolumentType,
}) => {
  return (
    <>
      <div
        className="row mt-2"
        style={{ maxHeight: '300px', overflowY: 'auto' }}
      >
        <div className="d-flex align-items-center col-3">
          <Checkbox
            id={`view-${selectedEmolumentType.isLocked}`}
            checked={selectedEmolumentType.isLocked}
            onCheck={e => {
              setSelectedEmolumentType({
                ...selectedEmolumentType,
                isLocked: e,
              })
            }}
          />
          <label
            htmlFor={`view-${selectedEmolumentType.isLocked}`}
            className={`fw-normal ms-2 cursor-pointer text-capitalize ${
              selectedEmolumentType.isLocked ? 'text-primary fw-bold' : ''
            }`}
          >
            {localize('isLocked')}
          </label>
        </div>
        <div className="d-flex align-items-center col-3">
          <Checkbox
            id={`view-${selectedEmolumentType.autowire}`}
            checked={selectedEmolumentType.autowire}
            onCheck={e => {
              setSelectedEmolumentType({
                ...selectedEmolumentType,
                autowire: e,
              })
            }}
          />
          <label
            htmlFor={`view-${selectedEmolumentType.autowire}`}
            className={`fw-normal ms-2 cursor-pointer text-capitalize ${
              selectedEmolumentType.autowire ? 'text-primary fw-bold' : ''
            }`}
          >
            {localize('autoWire')}
          </label>
        </div>
      </div>
    </>
  )
}
