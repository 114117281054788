/** @format */

import Tab from '../../../../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../../../../Global/Localize'
import { CustomerPreviewIndividualParticulars } from './CustomerPreview/CustomerPreviewIndividualParticulars'
import { CustomerPreviewAddress } from './CustomerPreview/CustomerPreviewAddress'
import { CustomerPreviewSpecimen } from './CustomerPreview/CustomerPreviewSpecimen'

export const CustomerPreview = ({ selectedCustomer }) => {
  return (
    <>
      <Tab
        tabItems={[
          {
            label: localize('individualParticulars'),
            item: (
              <CustomerPreviewIndividualParticulars
                customer={selectedCustomer}
              />
            ),
          },
          {
            label: localize('address'),
            item: <CustomerPreviewAddress customer={selectedCustomer} />,
          },
          {
            label: localize('specimen'),
            item: <CustomerPreviewSpecimen customer={selectedCustomer} />,
          },
        ]}
      />
    </>
  )
}
