/** @format */

/* eslint-disable */
import React from 'react'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import DebitBatchEntries from '../../../../../../../Components/ReUsables/BatchEntries/DebitBatchEntries/DebitBatchEntries'
import localize from '../../../../../../../Global/Localize'
import { batchEntryStatus } from '../../../../../../../Global/enumeration'
import { formatDate } from '../../../../../../../Helpers/formatters'

const AuthorizeDebitBatch = ({
  batch,
  handleClose,
  mode,
  setBatch,
  handleAuthorizeBatch,
  clearValidation,
  globalValidationErrors,
  isBusy,
}) => {
  return (
    <ModalTemplate
      modalTitle="debitBatch"
      handleAction={handleAuthorizeBatch}
      cancelText="close"
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
      disableClose={isBusy}
      disableCreate={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-items-center justify-content-end gap-4">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['Remarks']?.message}
            >
              <label className="text-capitalize col-4">
                {localize('remarks')}
              </label>
              <input
                disabled={isBusy}
                type="text"
                className="form-control"
                value={batch?.authorizationRemarks || ''}
                onChange={e => {
                  clearValidation('Remarks')
                  setBatch({
                    ...batch,
                    authorizationRemarks: e.target.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center gap-2">
              <label className="text-capitalize">
                {localize('authOption')}
              </label>
              <EnumsServerCombo
                disabled={isBusy}
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchAuthOption"
                value={batch?.batchAuthOption}
                onChange={e => {
                  setBatch({
                    ...batch,
                    batchAuthOption: e.value,
                  })
                }}
                sort
              />
            </div>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('debitType')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={batch?.debitTypeDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('priority')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={batch?.priorityDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={batch?.reference || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={batch?.createdBy || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formatDate(batch?.createdDate) || ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('batchEntries')}
            </div>
            <div className="row mt-4">
              <DebitBatchEntries
                debitBatchId={batch?.id}
                status={Object.keys(batchEntryStatus)[0]}
                showSelect={false}
                hiddenColumns={[0]}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AuthorizeDebitBatch
