/** @format */

/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { NavigationContext } from '../../../../../../Contexts/NavigationContext'
import localize from '../../../../../../Global/Localize'
import { batchStatus } from '../../../../../../Global/enumeration'
import {
  formatCurrency,
  formatDate,
  parseDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import AddWireTransferBatch from './AddWireTransferBatch'
import EditWireTransferBatch from './EditWireTransferBatch'
import ViewWireTransferBatch from './ViewWireTransferBatch'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'

function WireTransfer({ menuCode }) {
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [transfers, setTransfers] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 1,
    startDate: parseDate(new Date()),
    endDate: parseDate(new Date()),
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    customDate: false,
    dateRequestFilter: 0,
  })
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [mode, setMode] = useState(null)

  const { navigatedComponent, navigateToComponent } =
    useContext(NavigationContext)

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
    },
    {
      label: localize('batchType'),
      template: r => <>{r.typeDescription}</>,
    },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('wireTransferType'),
      template: r => <>{r.wireTransferTypeDescription}</>,
    },
    {
      label: localize('gLAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    {
      label: localize('autowire') + '?',
      template: r => (
        <span className="text-capitalize">{r.autowire.toString()}</span>
      ),
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('verifiedBy/RejectedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('verifiedDate/RejectedDate'),
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  useEffect(() => {
    let comps = [...navigatedComponent]
    let ci = comps.findIndex(c => c.code === menuCode)

    if (ci !== -1)
      comps[ci].hasOpenModal =
        mode === 'add' || mode === 'edit' || mode === 'view'
    navigateToComponent(comps)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    loadTransfers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  async function loadTransfers() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/WireTransferBatch/find-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTransfers(data)
    }

    setIsBusy(false)
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('BatchProcedures'),
          localize('origination'),
          localize('wireTransfer'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center mb-2">
                <AddNewButton
                  handleClickAddButton={() => {
                    setSelectedBatch(null)
                    setMode('add')
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between my-3">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="BatchStatus"
                    value={reqParams.status}
                    onChange={e =>
                      setReqParams({ ...reqParams, status: e.value })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize me-2 col-3">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    customStyle={{ minWidth: 250 }}
                    onSelect={d =>
                      setReqParams({
                        ...reqParams,
                        customDate: d?.custom,
                        startDate: d?.startDate,
                        endDate: d?.endDate,
                        dateRequestFilter: d?.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize">
                    {localize('search')}
                  </label>
                  <input
                    value={reqParams.filterText}
                    onChange={e =>
                      setReqParams({ ...reqParams, filterText: e.target.value })
                    }
                    type="text"
                    className="form-control ms-2 me-1"
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              {transfers && (
                <>
                  <SimpleTable
                    columns={columns}
                    data={transfers?.pageCollection || []}
                    contextMenu={r => (
                      <>
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            setSelectedBatch(r)
                            setMode('view')
                          }}
                        >
                          <i className="uil uil-search"></i>
                          <span className="text-capitalize">
                            {localize('view')}
                          </span>
                        </div>
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            // eslint-disable-next-line
                            if (r?.status == 1 || r?.status == 16) {
                              setSelectedBatch(r)
                              setMode('edit')
                            } else {
                              Swal.fire(
                                'Invalid Operation',
                                'Sorry, but you must first select a general ledger whose status is pending/deferred!',
                                'info'
                              )
                            }
                          }}
                        >
                          <i className="uil uil-edit-alt"></i>
                          <span className="text-capitalize">
                            {localize('edit')}
                          </span>
                        </div>
                      </>
                    )}
                  />
                  <div className="ms-2">
                    <Pager
                      itemsCount={transfers?.itemsCount || 1}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={5}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {mode === 'add' && (
        <AddWireTransferBatch
          closeModal={() => setMode(null)}
          onCreateBatch={() => {
            setMode(null)
            setRefresh(!refresh)
          }}
        />
      )}
      {mode === 'edit' && (
        <EditWireTransferBatch
          batch={selectedBatch}
          closeModal={() => {
            setSelectedBatch(null)
            setMode(null)
          }}
        />
      )}
      {mode === 'view' && (
        <ViewWireTransferBatch
          batch={selectedBatch}
          closeModal={() => {
            setSelectedBatch(null)
            setMode(null)
          }}
        />
      )}
    </>
  )
}

export default WireTransfer
