/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'

const CreateEmploymentSector = ({
  modalMode,
  isBusy,
  handleAdd,
  handleUpdate,
  handleClose,
  setSelectedSector,
  selectedSector,
  gbv,
  clearValidation,
}) => {
  return (
    <ModalTemplate
      modalMode={modalMode}
      modalTitle="employmentSectors"
      disableClose={isBusy}
      disableCreate={isBusy}
      handleClose={handleClose}
      handleAction={modalMode === 'add' ? handleAdd : handleUpdate}
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            id="editEmpSectorIsLocked"
            onCheck={v => setSelectedSector({ ...selectedSector, isLocked: v })}
            checked={selectedSector?.isLocked}
          />
          <label
            htmlFor="editEmpSectorIsLocked"
            className="text-capitalize me-3"
          >
            {localize('isLocked')} ?
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div
            className="col-md-12 with-validation d-flex align-items-center"
            validation-message={gbv?.Description?.message}
          >
            <label className="text-capitalize col-3">{localize('name')}</label>
            <input
              value={selectedSector?.description}
              type="text"
              className="form-control"
              onChange={e => {
                clearValidation('Description')
                setSelectedSector({
                  ...selectedSector,
                  description: e.target.value,
                })
              }}
            />
          </div>
          <div className="col-md-12 with-validation d-flex align-items-center my-2">
            <label className="text-capitalize col-3">
              {localize('medianIncome')}
            </label>
            <MaskNumber
              defaultMaskValue={selectedSector?.medianIncome}
              onMaskNumber={e =>
                setSelectedSector({
                  ...selectedSector,
                  medianIncome: e.target.value,
                })
              }
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateEmploymentSector
