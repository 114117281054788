/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Walkthrough from '../../../../../Components/Walkthrough/Walkthrough'
import AppraisalPartial from '../AppraisalServerSide/AppraisalPartial'
import { postData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import LoanRequest from '../Appraisal/LoanRequest'
import LoaningRemarksLookup from '../../../../../Components/Lookup/LoaningRemarksLookup'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import RelationshipManagerLookup from '../../../../../Components/Lookup/RelationshipManagerLookup'
import LoanDisbursementBatchLookup from '../../../../../Components/Lookup/LoanDisbursementBatchLookup'
import { CreditTypeLookup } from '../../../../../Components/Lookup/CreditTypeLookup'
import LoanDisbursementModeLookup from '../../../../../Components/Lookup/LoanDisbursementModeLookup'

function Approval() {
  const [activePage, setActivePage] = useState(0)
  const [loanCase, setLoanCase] = useState(null)
  const [termInMonths, setTermInMonths] = useState(
    loanCase?.loanRegistrationTermInMonths
  )
  const [amountApplied, setAmountApplied] = useState(loanCase?.amountApplied)
  const [incomeAdjustments, setIncomeAdjustments] = useState([])
  const [income, setIncome] = useState(0)
  const [isBusy, setIsBusy] = useState(false)
  const [assessment, setAssessment] = useState(null)
  const [offsetableLoans, setOffsetableLoans] = useState([])
  const [voluntaryDeductibles, setVoluntaryDeductibles] = useState([])
  const [amountAppraised, setAmountAppraised] = useState(0)
  const [assessLoan, setAssessLoan] = useState(false)
  const [considerCheckOffDeductions, setConsiderCheckOffDeductions] =
    useState(false)
  const [loanGuarantors, setLoanGuarantors] = useState([])

  const [loanCaseAppraisalOption, setLoanCaseAppraisalOption] = useState(1)
  const [loanCaseAppraisalRemarks, setLoanCaseAppraisalRemarks] = useState('')

  const [loanDisbursementMode, setLoanDisbursementMode] = useState(null)
  const [relationshipManager, setRelationshipManager] = useState(null)
  const [creditType, setCreditType] = useState(null)
  const [loanDisbursementBatch, setLoanDisbursementBatch] = useState(null)

  useEffect(() => {
    setRelationshipManager({
      name: assessment?.loanCase?.relationshipManagerEmployeeCustomerFullName,
      id: assessment?.loanCase?.relationshipManagerId,
    })
    setCreditType({
      description: assessment?.loanCase?.creditTypeDescription,
      id: assessment?.loanCase?.creditTypeId,
    })
    setLoanDisbursementBatch({
      paddedBatchNumber: assessment?.loanCase?.paddedBatchNumber,
    })
    setLoanDisbursementMode({
      id: assessment?.appraisal?.loanDisbursementMode?.id,
      description: assessment?.appraisal?.loanDisbursementMode?.description,
    })
  }, [assessment])

  async function approveLoanCase(loanApprovalOption, approvalRemarks) {
    const { success } = await postData('/loanOrigination/approve-loan-case', {
      loanCase,
      loanApprovalOption,
      approvalRemarks,
      assessment,
      loanDisbursementModeId: loanDisbursementMode?.id,
      relationshipManagerId: relationshipManager?.id,
      creditTypeId: creditType?.id,
      loanDisbursementBatchId: loanDisbursementBatch?.id,
      loanDisbursementBatchBatchNumber: loanDisbursementBatch?.batchNumber,
    })
    if (success) {
      Swal.fire('Operation Successful', 'Loan Approved', 'success')
      setLoanCase(null)
      setActivePage(0)
    }
  }

  const pages = [
    {
      title: localize('loanCase'),
      component: (
        <>
          <LoanRequest
            loanCase={loanCase}
            onSelect={l => {
              setLoanCase(l)
            }}
            setLoanCase={(p, v) => setLoanCase({ ...loanCase, [p]: v })}
            onChangeLoanTerm={t => {
              setTermInMonths(t)
            }}
            // onChangeReceivedDate={d => {
            //   setLoanCase({ ...loanCase, receivedDate: d })
            // }}
            onChangeAmountApplied={a => setAmountApplied(a)}
          />
        </>
      ),
      skippable: !!loanCase,
    },
    {
      title: localize('assessment'),
      component: (
        <AppraisalPartial
          approval
          loanCase={loanCase}
          onChangeIncomeAdjustments={i => setIncomeAdjustments(i)}
          onChangeIncome={i => setIncome(i)}
          income={income}
          isBusy={isBusy}
          assessment={assessment}
          onChangeOffsetables={x => setOffsetableLoans(x)}
          onChangeAmountAppraised={x => setAmountAppraised(x)}
          onChangeVoluntaryDeductibles={v => setVoluntaryDeductibles(v)}
          onChangeConsiderCheckOffDeductions={v =>
            setConsiderCheckOffDeductions(v)
          }
          onChangeGuarantors={g => setLoanGuarantors(g)}
          reAssessLoan={() => setAssessLoan(!assessLoan)}
          onAppraiseLoan={approveLoanCase}
        />
      ),
      skippable: true,
    },
    {
      title: localize('approval'),
      component: (
        <>
          <div className="row my-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-5 text-capitalize">
                {localize('disbursementMode')}
              </label>
              <LoanDisbursementModeLookup
                displayValue={loanDisbursementMode?.description}
                onSelect={d => setLoanDisbursementMode(d)}
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-5 text-capitalize">
                {localize('relationshipManager')}
              </label>
              <RelationshipManagerLookup
                displayValue={relationshipManager?.name}
                onSelect={r =>
                  setRelationshipManager({
                    id: r?.id,
                    name: r?.employeeCustomerFullName,
                  })
                }
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-5 text-capitalize">
                {localize('creditType')}
              </label>
              <CreditTypeLookup
                displayValue={creditType?.description}
                onSelect={c => setCreditType(c)}
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-5 text-capitalize">
                {localize('disbursementBatch')} #
              </label>
              <LoanDisbursementBatchLookup
                displayValue={loanDisbursementBatch?.paddedBatchNumber}
                onSelect={d => setLoanDisbursementBatch(d)}
              />
            </div>
          </div>
          <div className="mt-3 p-2 rounded border border-secondary bg-light d-flex align-items-center gap-2 justify-content-end">
            <label htmlFor="" className="text-capitalize">
              {localize('approvalAction')}
            </label>
            <div style={{ minWidth: 170 }}>
              <EnumsServerCombo
                value={loanCaseAppraisalOption}
                onChange={a => setLoanCaseAppraisalOption(a.value)}
                enumsKey={'LoanApprovalOption'}
              />
            </div>
            {loanCaseAppraisalOption != 1 && (
              <div style={{ maxWidth: '40%' }}>
                <LoaningRemarksLookup
                  displayValue={loanCaseAppraisalRemarks}
                  onChange={x => setLoanCaseAppraisalRemarks(x)}
                />
              </div>
            )}
            <button
              className="btn btn-success px-5 ms-2"
              onClick={() =>
                approveLoanCase(
                  loanCaseAppraisalOption,
                  loanCaseAppraisalOption != 1 ? loanCaseAppraisalRemarks : ''
                )
              }
            >
              {localize('update')}
            </button>
          </div>
        </>
      ),
      skippable: true,
    },
  ]

  useEffect(() => {
    loanCase && termInMonths && loadAssessment()
    // eslint-disable-next-line
  }, [
    loanCase,
    income,
    incomeAdjustments,
    termInMonths,
    amountApplied,
    offsetableLoans,
    amountAppraised,
    voluntaryDeductibles,
    considerCheckOffDeductions,
    assessLoan,
    loanGuarantors,
  ])

  async function loadAssessment() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/loanOrigination/assess-loan-case',
      {
        customerId: loanCase?.customerId,
        loanProductId: loanCase?.loanProductId,
        loanCaseId: loanCase?.id,
        incomeAdjustments,
        income,
        termInMonths,
        amountApplied,
        attachedLoans: offsetableLoans,
        amountApproved: amountAppraised,
        loanCaseVoluntaryDeductibles: voluntaryDeductibles,
        strictlySelfGuarantee: loanCase?.strictlySelfGuarantee,
        considerCheckOffDeductions,
        loanGuarantors,
        isApprovalStage: true,
      },
      true
    )
    if (success) {
      setAssessment(data)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanOrigination'),
          localize('loanApproval'),
        ]}
      />
      <div className="card card-body bg-white">
        <Walkthrough
          preload
          pages={pages}
          activePage={activePage}
          changePage={p => setActivePage(p)}
        />
      </div>
    </>
  )
}

export default Approval
