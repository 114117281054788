/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import ElectoralZonesLookup from '../../../../../Components/Lookup/ElectoralZonesLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { electoralZoneType } from '../../../../../Helpers/constants'
import Loader from '../../../../../Components/Loaders/Loader'

const CreateElectoralZone = ({
  handleClose,
  mode,
  handlePost,
  gve,
  clearValidation,
  selectedZone,
  setSelectedZone,
  isBusy,
}) => {
  const handleSelectElectoralZone = zone => {
    if (zone?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Electoral Zone is locked'
      )

    if (selectedZone?.type === electoralZoneType.DETAIL_ENTRY)
      return showNotification(
        'Operation not allowed',
        'error',
        'Cannot select a detail entry zone as parent'
      )

    setSelectedZone({
      ...selectedZone,
      parentId: zone?.id,
      parentDescription: zone?.description,
    })
  }
  return (
    <ModalTemplate
      handleAction={handlePost}
      modalMode={mode}
      modalTitle="electoralZones"
      handleClose={handleClose}
      disableClose={isBusy}
      disableCreate={isBusy}
      footerChildren={
        <>
          {(mode === 'edit' || mode === 'add') && (
            <div className="d-flex align-items-center me-5">
              <Checkbox
                id="electoralZonesIsLocked"
                checked={selectedZone?.isLocked}
                onCheck={v => setSelectedZone({ ...selectedZone, isLocked: v })}
                disabled={isBusy}
              />
              <label
                className="text-capitalize ms-2"
                htmlFor="electoralZonesIsLocked"
              >
                {localize('isLocked')}
              </label>
            </div>
          )}
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col">
            <div className="d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('parent')}
              </label>
              <ElectoralZonesLookup
                displayValue={selectedZone?.parentDescription}
                onSelect={handleSelectElectoralZone}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <label className="col-3 text-capitalize">
                {localize('type')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="ElectoralZoneType"
                value={selectedZone?.type}
                onChange={e =>
                  setSelectedZone({
                    ...selectedZone,
                    type: e.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={gve?.Description?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedZone?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedZone({
                    ...selectedZone,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <label className="col-3 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={selectedZone?.remarks}
                onChange={e =>
                  setSelectedZone({
                    ...selectedZone,
                    remarks: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateElectoralZone
