/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import { loanCaseFilter } from '../../../../../Global/enumeration'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import { useEffect } from 'react'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Pager from '../../../../../Components/Tables/Pager'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Tab from '../../../../../Components/Tabs/Tab'
import DisbursementSchedules from '../../../../../Components/ReUsables/Loan/DisbursementSchedules/DisbursementSchedules'
import LoanHistory from '../../../../../Components/ReUsables/Loan/LoanHistory/LoanHistory'
import LoanDeductions from '../../../../../Components/ReUsables/Loan/LoanDeductions/LoanDeductions'
import { useRef } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

const DisbursementQueues = () => {
  const [isBusy, setIsBusy] = useState(false)
  const ref = useRef(null)
  const [disbursementQueues, setDisbursementQueues] = useState([])
  const [showBottom, setShowBottom] = useState(false)
  const [selectedLoanCase, setSelectedLoanCase] = useState({})
  const [groupBy, setGroupBy] = useState({
    label: localize('branch'),
    column: 'loanCaseBranchDescription',
  })

  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    loanCaseFilter: Object.keys(loanCaseFilter)[0],
    text: '',
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('serialNumber'),
      template: r => r.paddedSerialNumber,
    },
    {
      label: localize('description'),
      template: r => r.description,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('loanCaseDocumentReferenceNumber'),
      template: r => r.loanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanCaseDisbursedAmount'),
      template: r => formatCurrency(r.loanCaseDisbursedAmount),
    },
    {
      label: localize('availableBalance'),
      template: r => r => formatCurrency(r.availableBalance),
    },
    {
      label: localize('customerFullName'),
      template: r => r.customerFullName,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => r.formattedLoanCaseCustomerMemberNumber,
    },
    {
      label: localize('loanProduct'),
      template: r => r.loanCaseLoanProductDescription,
    },
    {
      label: localize('loanRegistrationLoanProductCategory'),
      template: r => r.loanCaseLoanRegistrationLoanProductCategoryDescription,
    },
    {
      label: localize('loanPurpose'),
      template: r => r.loanCaseLoanPurposeDescription,
    },
    {
      label: localize('loanInterestAnnualPercentageRate'),
      template: r =>
        formatAsPercent(r.loanCaseLoanInterestAnnualPercentageRate),
    },
    {
      label: localize('loanCaseLoanRegistrationTermInMonths'),
      template: r => r.loanCaseLoanRegistrationTermInMonths,
    },
    {
      label: localize('loanCaseCustomerMembershipClassCustomerTypeDescription'),
      template: r => r.loanCaseCustomerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('loanCaseCustomerReference1'),
      template: r => r.loanCaseCustomerReference1,
    },
    {
      label: localize('loanCaseCustomerReference2'),
      template: r => r.loanCaseCustomerReference2,
    },
    {
      label: localize('loanCaseReference'),
      template: r => r.loanCaseReference,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
  ]

  const groupingColumns = [
    {
      label: localize('branch'),
      column: 'loanCaseBranchDescription',
    },
    {
      label: localize('loanPurpose'),
      column: 'loanCaseLoanPurposeDescription',
    },
    {
      label: localize('loanRepaymentMode'),
      column: 'loanCaseLoanRepaymentModeDescription',
    },
    {
      label: localize('LoanDisbursementMode'),
      column: 'loanCaseLoanDisbursementModeDescription',
    },
  ]

  const fetchDisbursementQueues = async () => {
    setIsBusy(true)
    const url = '/LoanCase/find-loan-disbursement-queues-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false, null)

    if (success) {
      setIsBusy(false)
      setDisbursementQueues(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    fetchDisbursementQueues()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    if (showBottom) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [showBottom])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanDisbursement'),
          localize('disbursementQueues'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row gx-3 mb-2 mt-2 d-flex align-items-center flex-wrap">
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="recordsPerPage"
                      className="text-capitalize"
                      style={{ marginLeft: -10, textTransform: 'capitalize' }}
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="recordsPerPage"
                      className="form-select ms-3"
                      style={{ maxWidth: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="d-flex align-items-center">
                    <label className="col-3">{localize('groupBy')}</label>
                    <select
                      className="form-select text-capitalize"
                      defaultValue={groupBy.column}
                      onChange={e =>
                        setGroupBy({
                          label: groupingColumns.find(
                            f => f.column === e.target.value
                          )?.label,
                          column: e.target.value,
                        })
                      }
                    >
                      {groupingColumns.map(({ label, column }) => (
                        <option
                          className="text-capitalize"
                          key={`group-by-${column}-in-disbursement-queues`}
                          value={column}
                        >
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label htmlFor="search" className="col-3 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>

                <div className="col-md-4">
                  <div className="d-flex align-items-center justify-content-end">
                    <label htmlFor="search" className="text-capitalize me-2">
                      {localize('search')}
                    </label>

                    <div className="d-flex align-items-center">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="LoanCaseFilter"
                        value={reqParams.loanCaseFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            loanCaseFilter: e.value,
                            pageIndex: 0,
                          })
                        }}
                      />

                      <input
                        type="search"
                        id="search"
                        className="form-control ms-2"
                        value={reqParams.text}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="FilterButton"
                        type="button"
                        className="btn btn-primary"
                        onClick={e => {
                          fetchDisbursementQueues()
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            fetchDisbursementQueues()()
                          }
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <GroupedTable
                groupBy={groupBy}
                columns={columns}
                data={disbursementQueues?.pageCollection}
                canClickOnce
                onClickOnce={r => {
                  setSelectedLoanCase(r)
                  setShowBottom(true)
                }}
              />
              <div className="mb-2"></div>
              <Pager
                itemsCount={disbursementQueues.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>

      {showBottom && (
        <div ref={ref}>
          <Tab
            tabItems={[
              {
                label: localize('disbursementSchedules'),
                item: (
                  <DisbursementSchedules
                    loanDisbursementQueueId={selectedLoanCase?.id}
                  />
                ),
              },
              {
                label: localize('loanDeductions'),
                item: <LoanDeductions loanCaseId={selectedLoanCase?.id} />,
              },
              {
                label: localize('loanHistory'),
                item: <LoanHistory loanCaseId={selectedLoanCase?.id} />,
              },
            ]}
          />
        </div>
      )}
    </>
  )
}

export default DisbursementQueues
