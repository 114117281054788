/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function BranchLookup({ onSelect, displayValue, disabled }) {
  const columns = [
    {
      label: localize('code'),
      template: r => <>{r?.paddedCode}</>,
      sortBy: 'paddedCode',
    },
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('numberingSeries'),
      template: r => <>{r?.numberingSeriesDescription}</>,
      sortBy: 'numberingSeriesDescription',
    },
    {
      label: localize('company'),
      template: r => <>{r?.companyDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.addressCity}</> },
    { label: localize('emailAddress'), template: r => <>{r?.addressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        disabled={disabled}
        title={localize('branchLookup')}
        url="/lookup/Branch/find-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default BranchLookup
