/** @format */
import localize from '../../../../../../Global/Localize'
import Tab from '../../../../../../Components/Tabs/Tab'
import React from 'react'
import { ViewAddress } from './ViewAddress'
import { ViewCompanyMiscellaneous } from './ViewCompanyMiscellaneous'
import { ViewWorkingTimePreferences } from './ViewWorkingTimePreferences'
import { ViewCompanyFlags } from './ViewCompanyFlags'

export const ViewCompanyModal = ({ selectedCompany }) => {
  return (
    <>
      <>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">{localize('name')}</label>
            <input
              className="form-control"
              value={selectedCompany?.description}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('currency')}
            </label>
            <input
              className="form-control"
              value={selectedCompany?.currencyDescription}
              disabled
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('registrationNumber')}
            </label>
            <input
              className="form-control"
              value={selectedCompany?.registrationNumber}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">{localize('PIN')}</label>
            <input
              className="form-control"
              value={selectedCompany?.personalIdentificationNumber}
              disabled
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('mission')}
            </label>
            <input
              className="form-control"
              value={selectedCompany?.mission}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('vision')}
            </label>
            <input
              className="form-control"
              value={selectedCompany?.vision}
              disabled
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label
              style={{ width: 12.3 + '%' }}
              className="col-1 text-capitalize"
            >
              {localize('motto')}
            </label>
            <input
              className="form-control"
              value={selectedCompany?.motto}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('address'),
                  item: <ViewAddress selectedCompany={selectedCompany} />,
                },
                {
                  label: localize('miscellaneous'),
                  item: (
                    <ViewCompanyMiscellaneous
                      selectedCompany={selectedCompany}
                    />
                  ),
                },
                {
                  label: localize('workingTime'),
                  item: (
                    <ViewWorkingTimePreferences
                      selectedCompany={selectedCompany}
                    />
                  ),
                },
                {
                  label: localize('flags'),
                  item: <ViewCompanyFlags selectedCompany={selectedCompany} />,
                },
              ]}
            />
          </div>
        </div>
      </>
    </>
  )
}
