/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import ListBatchOrigination from '../../../../../Components/ReUsables/BatchOrigination/ListBatchOrigination'
import localize from '../../../../../Global/Localize'
import {
  QueuePriority,
  withdrawalNotificationCategory,
} from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../../Helpers/webApi'
import ViewBatchModal from '../Partials/ViewBatchModal/ViewBatchModal'
import CreateBatchOriginationModal from './CreateBatchOriginationModal/CreateBatchOriginationModal'
import { batchStatus } from '../../../../../Helpers/constants'

function BatchOrigination() {
  const [modalMode, setModalMode] = useState(null)
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)
  const [batchOrigination, setBatchOrigination] = useState({
    category: Object.keys(withdrawalNotificationCategory)[1],
    priority: Object.keys(QueuePriority)[3],
  })
  const [refresh, setRefresh] = useState(false)

  const handleAddButtonClick = () => {
    setModalMode('add')
    setBatchOrigination({
      category: Object.keys(withdrawalNotificationCategory)[1],
      priority: Object.keys(QueuePriority)[3],
    })
  }

  const handleClose = () => {
    setModalMode(null)
    setBatchOrigination(null)
  }

  const handleCreateBatchOrigination = async () => {
    setIsBusyUpdating(true)
    const url =
      modalMode === 'add'
        ? '/WithdrawalSettlementBatch/create-withdrawal-settlement-batch'
        : '/WithdrawalSettlementBatch/update-withdrawal-settlement-batch'

    if (modalMode === 'add') {
      const { success } = await postData(url, batchOrigination, false)
      if (success) {
        handleClose()
        setRefresh(true)
        showNotification('Membership Withdrawal SettlementBatches', 'success')
      }
    } else {
      const { success } = await putData(url, batchOrigination, false)
      if (success) {
        handleClose()
        setRefresh(true)
        showNotification('Membership Withdrawal SettlementBatches', 'success')
      }
    }

    setIsBusyUpdating(false)
  }

  const handleBatchSelect = (batch, mode) => {
    if (
      mode === 'edit' &&
      batch?.status !== (batchStatus.PENDING || batchStatus.DEFERRED)
    )
      return showNotification(
        'Operation not allowed',
        'error',
        'Sorry, but you must first select a batch whose status is pending/deferred!'
      )

    setModalMode(mode)
    setBatchOrigination(batch)
  }

  return (
    <>
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateBatchOriginationModal
          handleCancel={handleClose}
          mode={modalMode}
          batchOrigination={batchOrigination}
          setBatchOrigination={setBatchOrigination}
          handleCreateBatchOrigination={handleCreateBatchOrigination}
          isBusyUpdating={isBusyUpdating}
        />
      )}
      {modalMode === 'view' && (
        <ViewBatchModal
          batchOrigination={batchOrigination}
          handleClose={handleClose}
          title="batchOrigination"
          mode={modalMode}
        />
      )}

      <BreadCrumb
        tree={[
          localize('settlement'),
          localize('membershipWithdrawalSettlementBatches'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <ListBatchOrigination
            handleAddButtonClick={handleAddButtonClick}
            handleBatchSelect={handleBatchSelect}
            showAddButton
            showContextMenu
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    </>
  )
}

export default BatchOrigination
