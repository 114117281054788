/** @format */

import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { putData } from '../../../../../Helpers/webApi'

const EditInventory = ({
  mode,
  closeModal,
  loadData,
  inventory,
  setInventory,
}) => {
  const [loading, setLoading] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setLoading(true)
    const { success } = await putData(
      '/Inventory/update-inventory',
      inventory,
      false
    )

    if (success) {
      showNotification('Inventory', 'success')
      setLoading(false)
      setInventory(null)
      setGlobalValidationErrors({})
      loadData()
      handleCloseModal()
    }
    setLoading(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  function handleCloseModal() {
    closeModal()
    setGlobalValidationErrors({})
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="inventory"
        handleClose={handleCloseModal}
        handleAction={handleSubmit}
        modalClassName="modal-xl"
        actionText={mode === 'edit' ? 'update' : ''}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-md-1 text-capitalize">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inventory?.itemRegisterDescription || ''}
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-md-2 text-capitalize">
                  {localize('category')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    inventory?.itemRegisterInventoryCategoryDescription || ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-md-2 text-capitalize">
                  {localize('package')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    inventory?.itemRegisterInventoryPackageTypeDescription || ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('unitCost')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterEstimatedUnitCost}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('unitPrice')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.unitPrice}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('unitOfMeasure')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    inventory?.itemRegisterInventoryUnitOfMeasureDescription
                  }
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('mainSupplier')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.supplierDescription}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('chartOfAccountName')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    inventory?.itemRegisterInventoryCategoryChartOfAccountName
                  }
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('reorderPoint')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterReorderPoint}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('maximumOrder')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterMaximumOrder}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('unitsPerPack')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterUnitsPerPack}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('palletPi')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterPalletTI}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('palletHi')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterPalletHI}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('unitNetWeight')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterUnitNetWeight}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('unitGrossWeigh')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterUnitGrossWeight}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('leadDays')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterLeadDays}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('economicOrder')}
                </label>
                <MaskNumber
                  defaultMaskValue={inventory?.itemRegisterEconomicOrder}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-md-2 text-capitalize">
                  {localize('monthlyDemand')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inventory?.itemRegisterMonthlyDemand}
                  disabled
                />
              </div>
              <div
                className="col d-flex align-items-center with-validation"
                validation-message={globalValidationErrors['Remarks']?.message}
              >
                <label className="col-md-2 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inventory?.remarks || ''}
                  onChange={e => {
                    clearValidation('Remarks')
                    setInventory({
                      ...inventory,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EditInventory
