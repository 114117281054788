/** @format */
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Tab from '../../../../../../Components/Tabs/Tab'
import { AccountMetadataStatement } from './AccountMetadata/AccountMetadataStatement'
import {
  ProductCode,
  ProductSection,
} from '../../../../../../Global/enumeration'
import { AccountMetadataRepaymentSchedule } from './AccountMetadata/AccountMetadataRepaymentSchedule'
import { AccountMetadataCarryForwards } from './AccountMetadata/AccountMetadataCarryForwards'
import { AccountMetadataActiveFixedDeposit } from './AccountMetadata/AccountMetadataActiveFixedDeposit'
import { AccountMetadataUnclearedCheques } from './AccountMetadata/AccountMetadataUnclearedCheques'
import { getData } from '../../../../../../Helpers/webApi'
import { FixedDepositTypeLookup } from '../../../../../../Components/Lookup/FixedDepositTypeLookup'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import Swal from 'sweetalert2'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import GroupedTable from '../../../../../../Components/Tables/GroupedTable'

export const AccountMetadataModal = ({
  selectedCustomerAccount,
  selectedCustomer,
  setSelectedCustomerAccount,
  gbv,
  clearValidation,
}) => {
  let tabs = []
  const [accountMetaDataModel, setAccountMetaDataModel] = useState({
    statementType: '0',
  })

  const [selectedPrintingCustomerAccount, setSelectedPrintingCustomerAccount] =
    useState(null)
  const [chargeForPrinitng, setChargeForPrinitng] = useState(false)

  const [loading, setLoading] = useState({
    fixedDeposit: false,
  })

  const [selectedFixedDepositType, setSelectedFixedDepositType] = useState(null)
  const [activeFixedDeposits, setActiveFixedDeposits] = useState([])
  const [unclearedCheques, setUnclearedCheques] = useState([])

  if (selectedCustomerAccount) {
    if (
      selectedCustomerAccount.customerAccountTypeProductCode ===
      Object.keys(ProductCode)[1]
    ) {
      tabs = [
        {
          label: localize('statement'),
          item: (
            <AccountMetadataStatement
              selectedPrintingCustomerAccount={selectedPrintingCustomerAccount}
              onChargeForPrinting={v => {
                setChargeForPrinitng(v)
              }}
              selectedCustomerAccount={selectedCustomerAccount}
              selectedCustomer={selectedCustomer}
            />
          ),
        },
        {
          label: localize('repaymentSchedule'),
          item: (
            <AccountMetadataRepaymentSchedule
              selectedCustomerAccount={selectedCustomerAccount}
            />
          ),
        },
        {
          label: localize('carryForwards'),
          item: (
            <AccountMetadataCarryForwards
              selectedCustomerAccount={selectedCustomerAccount}
            />
          ),
        },
      ]
    } else if (
      selectedCustomerAccount.customerAccountTypeProductCode ===
      Object.keys(ProductCode)[0]
    ) {
      tabs = [
        {
          label: localize('statement'),
          item: (
            <AccountMetadataStatement
              selectedPrintingCustomerAccount={selectedPrintingCustomerAccount}
              onChargeForPrinting={v => {
                setChargeForPrinitng(v)
              }}
              selectedCustomer={selectedCustomer}
              selectedCustomerAccount={selectedCustomerAccount}
            />
          ),
        },
        {
          label: localize('activeFixedDeposit'),
          item: (
            <AccountMetadataActiveFixedDeposit
              loading={loading.fixedDeposit}
              fixedDeposits={activeFixedDeposits}
              selectedCustomerAccount={selectedCustomerAccount}
            />
          ),
        },
        {
          label: localize('unclearedCheques'),
          item: (
            <AccountMetadataUnclearedCheques
              loading={loading.unclearedCheques}
              unclearedCheques={unclearedCheques}
              selectedCustomerAccount={selectedCustomerAccount}
            />
          ),
        },
      ]
    } else {
      tabs = [
        {
          label: localize('statement'),
          item: (
            <AccountMetadataStatement
              selectedCustomer={selectedCustomer}
              selectedPrintingCustomerAccount={selectedPrintingCustomerAccount}
              onChargeForPrinting={v => {
                setChargeForPrinitng(v)
              }}
              selectedCustomerAccount={selectedCustomerAccount}
            />
          ),
        },
      ]
    }
  }

  const fetchActiveFixedDeposits = async () => {
    setLoading({ ...loading, fixedDeposit: true })
    const { success, data } = await getData(
      '/FixedDeposit/find-fixed-deposits-by-customer-account-id',
      {
        customerAccountId: selectedCustomerAccount?.id,
        includeProductDescription: false,
      },
      false,
      null
    )
    if (success) {
      setActiveFixedDeposits(data.result)
      setLoading({ ...loading, fixedDeposit: false })
    }
  }

  const fetchExternalUnclearedCheques = async () => {
    setLoading({ ...loading, unclearedCheques: true })
    const { success, data } = await getData(
      '/ExternalCheque/find-uncleared-external-cheques-by-customer-account-id',
      {
        customerAccountId: selectedCustomerAccount?.id,
      },
      false,
      null
    )
    if (success) {
      setUnclearedCheques(data.result)
      setLoading({ ...loading, unclearedCheques: false })
    }
  }

  const handleOnChangedSelectedCustomerAccount = customerAccount => {
    if (selectedCustomerAccount) {
      if (
        selectedCustomerAccount.customerAccountTypeProductCode ===
        Object.keys(ProductCode)[1]
      ) {
        setSelectedPrintingCustomerAccount(null)
      } else if (
        selectedCustomerAccount.customerAccountTypeProductCode ===
        Object.keys(ProductCode)[0]
      ) {
        fetchActiveFixedDeposits()
        fetchExternalUnclearedCheques()
        setSelectedPrintingCustomerAccount(selectedCustomerAccount)
      } else {
        setSelectedPrintingCustomerAccount(null)
      }
    }
  }

  const handleOnChangeStatementType = r => {
    setAccountMetaDataModel({
      ...accountMetaDataModel,
      statementType: r.value,
    })

    setSelectedCustomerAccount({
      ...selectedCustomerAccount,
      customerAccountStatementType: r.value,
    })

    if (r.value == '2' && selectedFixedDepositType) {
      setSelectedCustomerAccount({
        ...selectedCustomerAccount,
        customerAccountStatementTypeProductId: selectedFixedDepositType.id,
        customerAccountStatementTypeChartOfAccountId:
          selectedFixedDepositType.chartOfAccountId,
      })
    }
  }

  const handleOnSelectedFixedDepositType = r => {
    setSelectedFixedDepositType({
      ...selectedFixedDepositType,
      description: r.description,
      id: r.id,
    })
    fetchActiveFixedDeposits()
  }

  useEffect(() => {
    handleOnChangedSelectedCustomerAccount()
  }, [selectedCustomerAccount])

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 120,
          }}
        >
          {localize('statementDetails')}
        </div>
        <div className="row mb-2" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('statementType')}
            </label>
            <EnumsServerCombo
              value={accountMetaDataModel?.statementType || ''}
              customContainerStyle={{ width: '100%' }}
              enumsKey={'CustomerAccountStatementType'}
              onChange={handleOnChangeStatementType}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountName')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.accountName}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.fullAccountNumber}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.statusDescription}
            />
          </div>
        </div>
        <div className="row mt-2" style={{ marginTop: -10 }}>
          <div className="col-3 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.remarks}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('productName')}
            </label>
            <input
              disabled
              className="form-control"
              value={
                selectedCustomerAccount?.customerAccountTypeTargetProductDescription
              }
            />
          </div>
        </div>
        <div className="row mt-2">
          {accountMetaDataModel?.statementType === '2' && (
            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('fixedDepositType')}
              </label>
              <FixedDepositTypeLookup
                displayValue={selectedFixedDepositType?.description}
                onSelect={handleOnSelectedFixedDepositType}
              />
            </div>
          )}
          {chargeForPrinitng && (
            <>
              <div
                validation-message={
                  gbv?.SelectedPrintingCustomerAccount?.message
                }
                className="col-3 d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('printingCustomerAccountFee')}
                </label>
                <CustomerAccountsByCustomerIdLookup
                  accountsFundsTransfer={
                    selectedPrintingCustomerAccount?.fullAccountNumber || ''
                  }
                  displayValue={
                    selectedPrintingCustomerAccount?.customerFullName
                  }
                  selectedCustomerAccount={selectedCustomerAccount}
                  onSelect={r => {
                    setSelectedPrintingCustomerAccount(r)
                    clearValidation('SelectedPrintingCustomerAccount')
                  }}
                />
              </div>
              {selectedCustomerAccount.customerAccountTypeProductCode ===
                Object.keys(ProductCode)[1] && (
                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('accountStatus')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={selectedPrintingCustomerAccount?.statusDescription}
                  />
                </div>
              )}
              {selectedCustomerAccount.customerAccountTypeProductCode ===
                Object.keys(ProductCode)[1] && (
                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('accountRemarks')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={selectedPrintingCustomerAccount?.remarks}
                  />
                </div>
              )}
              {selectedCustomerAccount.customerAccountTypeProductCode ===
                Object.keys(ProductCode)[1] && (
                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('productName')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      selectedPrintingCustomerAccount?.customerAccountTypeTargetProductDescription
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="row mb-2 mt-3">
        <div className="col-md-12">
          <Tab preload tabItems={tabs} />
        </div>
      </div>
    </>
  )
}

export const CustomerAccountsByCustomerIdLookup = ({
  displayValue,
  selectedCustomerAccount,
  accountsFundsTransfer,
  onSelect,
}) => {
  const [prevCustomerAccount, setPrevCustomerAccount] = useState(
    selectedCustomerAccount
  )

  const [reqParams, setReqParams] = useState({
    customerId: selectedCustomerAccount?.customerId,
    includeBalances: true,
    includeProductDescription: true,
  })

  if (
    selectedCustomerAccount &&
    prevCustomerAccount !== selectedCustomerAccount
  ) {
    setPrevCustomerAccount(selectedCustomerAccount)
    setReqParams({
      ...reqParams,
      customerId: selectedCustomerAccount.customerId,
    })
  }

  const handleOnActivatedModal = r => {
    if (!selectedCustomerAccount) {
      Swal.fire('Error', 'You must first select a source account', 'error')
      setModalActive(false)
    } else {
      setModalActive(true)
    }
  }

  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (selectedCustomerAccount && modalActive) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  const [groupBy, setGroupBy] = useState({
    label: localize('productCode'),
    column: 'customerAccountTypeProductCodeDescription',
  })

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('penaltyBalance'),
      template: r => <>{r.penaltyBalance}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('availableBalance'),
      template: r => <>{formatCurrency(r.availableBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestBalance)}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r.loanClassificationDescription}</>,
    },
    {
      label: localize('loanClassificationDate'),
      template: r => <>{r.loanClassificationDate}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => <>{formatCurrency(r.loanCaseTotalAmount)}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{r.loanBalanceExpected}</>,
    },
    {
      label: localize('LoanPrepaidAmount'),
      template: r => <>{r.loanPrepaidAmount}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{r.loanDefaultAmount}</>,
    },
    {
      label: localize('loanArrearsTimeline'),
      template: r => <>{r.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => <>{r.loanLossProvisionPercentage}</>,
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => <>{r.loanLossProvisionAmount}</>,
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => <>{r.loanCaseDisbursedDate}</>,
    },
    {
      label: localize('loanGracePeriod'),
      template: r => <>{r.loanCaseLoanRegistrationGracePeriod}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{r.loanLastPaymentDueDate}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r.loanLastPayment}</>,
    },
    {
      label: localize('loanTermMonths'),
      template: r => <>{r.loanCaseLoanRegistrationTermInMonths}</>,
    },
    {
      label: localize('loanAnnualPercentage'),
      template: r => <>{r.loanCaseLoanInterestAnnualPercentageRate}</>,
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r => (
        <>{r.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => <>{r.loanCaseLoanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('loanRoundingType'),
      template: r => <>{r.loanCaseLoanRegistrationRoundingTypeDescription}</>,
    },
    {
      label: localize('availableOverdraftLimit'),
      template: r => <>{r.availableOverdraftLimit}</>,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{r.carryForwardsBalance}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    {
      label: localize('accountStatus'),
      column: 'statusDescription',
    },
    {
      label: localize('recordStatus'),
      column: 'recordStatusDescription',
    },
    {
      label: localize('createdBy'),
      column: 'createdBy',
    },
    {
      label: localize('modifiedBy'),
      column: 'modifiedBy',
    },
    {
      label: localize('productCode'),
      column: 'customerAccountTypeProductCodeDescription',
    },
    {
      label: localize('productName'),
      column: 'customerAccountTypeTargetProductDescription',
    },
    {
      label: localize('productSection'),
      column: 'customerAccountTypeTargetProductSectionDescription',
    },
    {
      label: localize('branch'),
      column: 'branchDescription',
    },
    {
      label: localize('customerType'),
      column: 'customerMembershipClassCustomerTypeDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'customerIndividualIdentityCardTypeDescription',
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-savings-product-code',
      reqParams
    )
    if (success) {
      setIsBusy(false)
      setData(data.result)
      setTableData(data.result)
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={handleOnActivatedModal}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            // maxWidth: '61%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {accountsFundsTransfer}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={handleOnActivatedModal}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('customerAccountsLookup')} - {displayValue}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setModalActive(false)
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          {isBusy ? (
            <ModalBody className="bg-white">{localize('localize')}</ModalBody>
          ) : (
            <ModalBody className="bg-white p-2">
              <div className="d-flex align-items-center justify-content-space-between ">
                <div className="d-flex align-items-center float-right my-2 col-4">
                  <label
                    className="text-capitalize"
                    htmlFor="LoanProductLookupPageSize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    id="LoanProductLookupPageSize"
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('groupBy')}
                  </label>
                  <select
                    className="form-select text-capitalize"
                    style={{ width: 'fit-content' }}
                    defaultValue={groupBy.column}
                    onChange={e =>
                      setGroupBy({
                        label: groupingColumns.find(
                          f => f.column === e.target.value
                        )?.label,
                        column: e.target.value,
                      })
                    }
                  >
                    {groupingColumns.map(({ label, column }) => (
                      <option
                        key={`LoanProductLookup_${column}`}
                        className="text-capitalize"
                        value={column}
                      >
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-auto me-3 text-capitalize">
                    {localize('productSection')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ProductSection"
                    value={reqParams.productSection}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        productSection: e.value,
                      })
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}></div>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <div className="d-flex align-items-center float-right my-2">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <input
                      defaultValue={reqParams.filterText}
                      onChange={e => setSearch(e.target.value)}
                      type="search"
                      className="form-control ms-3"
                      id="searchTable"
                    />
                  </div>
                </form>
              </div>
              <div className=" py-1">
                <GroupedTable
                  canSelect
                  onSelect={r => {
                    setModalActive(false)
                    onSelect(r)
                  }}
                  groupBy={groupBy}
                  columns={columns}
                  data={data}
                />
              </div>
            </ModalBody>
          )}
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
