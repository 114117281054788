/** @format */
import localize from '../../../../../Global/Localize'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import React from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import Tab from '../../../../../Components/Tabs/Tab'
import { EmolumentTypesCommissions } from './EmolumentTypesCommissions'
import { EmolumentTypesSavingsProducts } from './EmolumentTypesSavingsProducts'
import { EmolumentTypeFlags } from './EmolumentTypeFlags'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

export const AddEmolumentTypeModal = ({
  setSelectedEmolumentType,
  selectedEmolumentType,
  waitingMetadata,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['EmolumentType.Description']?.message
              }
            >
              <label
                className="col-1 text-capitalize"
                style={{ marginRight: 4.1 + '%' }}
              >
                {localize('name')}
              </label>
              <input
                value={selectedEmolumentType?.description}
                className="form-control"
                onChange={e => {
                  clearValidation('EmolumentType.Description')
                  setSelectedEmolumentType({
                    ...selectedEmolumentType,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['EmolumentType.ChartOfAccountId']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('glAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={selectedEmolumentType?.chartOfAccountName}
                onSelect={r => {
                  clearValidation('EmolumentType.ChartOfAccountId')
                  setSelectedEmolumentType({
                    ...selectedEmolumentType,
                    chartOfAccountId: r?.id,
                    chartOfAccountName: r?.name,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['EmolumentType.Amount']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('amount')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedEmolumentType?.amount}
                onMaskNumber={e => {
                  clearValidation('EmolumentType.Amount')
                  setSelectedEmolumentType({
                    ...selectedEmolumentType,
                    amount: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('transactionOwnership')}
              </label>
              <EnumsServerCombo
                value={selectedEmolumentType?.transactionOwnership}
                customContainerStyle={{ width: '100%' }}
                enumsKey={'TransactionOwnership'}
                onChange={e => {
                  setSelectedEmolumentType({
                    ...selectedEmolumentType,
                    transactionOwnership: e.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('autoWireMedium')}
              </label>
              <EnumsServerCombo
                value={selectedEmolumentType?.autowireMedium}
                customContainerStyle={{ width: '100%' }}
                enumsKey={'EmolumentAutowireMedium'}
                onChange={e => {
                  setSelectedEmolumentType({
                    ...selectedEmolumentType,
                    autowireMedium: e.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('applicableCharges'),
                    item: (
                      <EmolumentTypesCommissions
                        selectedEmolumentType={selectedEmolumentType}
                        isBusy={waitingMetadata}
                        setSelectedEmolumentType={setSelectedEmolumentType}
                        prefix={'add-applicable'}
                      />
                    ),
                  },
                  {
                    label: localize('beneficiaryProducts'),
                    item: (
                      <EmolumentTypesSavingsProducts
                        isBusy={waitingMetadata}
                        setSelectedEmolumentType={setSelectedEmolumentType}
                        selectedEmolumentType={selectedEmolumentType}
                        prefix={'add-emolument-type'}
                      />
                    ),
                  },
                  {
                    label: localize('flags'),
                    item: (
                      <EmolumentTypeFlags
                        selectedEmolumentType={selectedEmolumentType}
                        setSelectedEmolumentType={setSelectedEmolumentType}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
