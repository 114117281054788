/** @format */

import React, { useState } from 'react'
import { postData } from '../../../../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import AssetLookup from '../../../../../Components/Lookup/AssetLookup'
import DepartmentLookup from '../../../../../Components/Lookup/DepartmentLookup'
import EmployeeLookup from '../../../../../Components/Lookup/EmployeeLookup'
import LocationLookup from '../../../../../Components/Lookup/LocationLookup'
import Swal from 'sweetalert2'
import Loader from '../../../../../Components/Loaders/Loader'
import { generateGuid } from '../../../../../Helpers/extensions'

const MoveAsset = ({ mode, close, loadTableData }) => {
  const tBrColumns = [
    {
      label: localize('tag'),
      template: r => <>{r.assetTagNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.assetItemRegisterDescription}</>,
    },
    {
      label: localize('sourceDepartment'),
      template: r => <>{r.sourceDepartmentDescription}</>,
    },
    {
      label: localize('destinationDepartment'),
      template: r => <>{r.destinationDepartmentDescription}</>,
    },
    {
      label: localize('sourceLocation'),
      template: r => <>{r.sourceLocationDescription}</>,
    },
    {
      label: localize('destinationlocation'),
      template: r => <>{r.destinationLocationDescription}</>,
    },
    {
      label: localize('sender'),
      template: r => <>{r.sender}</>,
    },
    {
      label: localize('recipient'),
      template: r => <>{r.recipient}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tempEntries, setTempEntries] = useState([])
  const [tBr, setTBr] = useState(null)
  const [entryMode, setEntryMode] = useState('add')
  // const [AssetMovement, setAssetMovement] = useState(null)

  async function createAssetMovement() {
    setIsBusy(true)
    for (let b of tempEntries) {
      delete b.id
    }

    const { success } = await postData(
      '/Asset/add-asset-movement-histories',
      [...tempEntries],
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Move Asset',
        showCloseButton: true,
      })
    } else {
      setIsBusy(false)
    }
  }

  function closeModal() {
    close()
  }

  return (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white text-capitalize">
        {localize(mode)}
        <i className="uil uil-angle-right mx-2"></i>
        <span className="text-muted fs-6 text-uppercase">
          {localize('assetMovements')}
        </span>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="bg-white">
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-1">
                  {localize('asset')}
                </label>
                <AssetLookup
                  displayValue={tBr?.assetItemRegisterDescription || ''}
                  onSelect={r => {
                    setTBr({
                      ...tBr,
                      assetId: r.id,
                      assetItemRegisterDescription: r.itemRegisterDescription,
                      assetTagNumber: r.tagNumber,
                      sourceDepartmentId: r.departmentId,
                      sourceDepartmentDescription: r.departmentDescription,
                      sender: r.senderDescription,
                      sourceLocationId: r.locationId,
                      sourceLocationDescription: r.locationDescription,
                      typeDescription: r.itemRegisterAssetTypeDescription,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-2">
                  {localize('currentDepartment')}
                </label>
                <input
                  className="form-control"
                  defaultValue={tBr?.sourceDepartmentDescription}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-2">
                  {localize('destinationDepartment')}
                </label>
                <DepartmentLookup
                  displayValue={tBr?.destinationDepartmentDescription || ''}
                  onSelect={r => {
                    setTBr({
                      ...tBr,
                      destinationDepartmentId: r.id,
                      destinationDepartmentDescription: r.description,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-2">
                  {localize('currentCustodian')}
                </label>
                <input
                  className="form-control"
                  defaultValue={tBr?.sender}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-2">
                  {localize('newCustodian')}
                </label>
                <EmployeeLookup
                  displayValue={tBr?.recipient || ''}
                  onSelect={r => {
                    setTBr({
                      ...tBr,
                      recipient: r.customerFullName,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-2">
                  {localize('currentLocation')}
                </label>
                <input
                  className="form-control"
                  defaultValue={tBr?.sourceLocationDescription}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-2">
                  {localize('newLocation')}
                </label>
                <LocationLookup
                  displayValue={tBr?.destinationLocationDescription || ''}
                  onSelect={r => {
                    setTBr({
                      ...tBr,
                      destinationLocationId: r.id,
                      destinationLocationDescription: r.description,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="text-capitalize col-md-1">
                  {localize('remarks')}
                </label>
                <input
                  className="form-control"
                  onChange={e =>
                    setTBr({
                      ...tBr,
                      remarks: e.target.value,
                    })
                  }
                  defaultValue={tBr?.remarks || ''}
                />
              </div>
            </div>

            <div className="row  mb-2">
              <div className="d-flex align-items-center justify-content-end">
                {entryMode === 'edit' && (
                  <button
                    onClick={() => {
                      setEntryMode('add')
                      setTBr({})
                    }}
                    className="text-uppercase btn btn-outline-danger px-4"
                  >
                    {localize('cancel')}
                  </button>
                )}
                {entryMode === 'add' && (
                  <button
                    onClick={() => {
                      if (!tBr.assetItemRegisterDescription) {
                        Swal.fire({
                          icon: 'info',
                          text: 'asset field is empty',
                          title: 'Move Asset',
                        })
                      }
                      setTempEntries([
                        ...tempEntries,
                        { id: generateGuid(), ...tBr },
                      ])
                      setTBr(null)
                    }}
                    className="btn btn-success px-5 mx-0 text-uppercase"
                  >
                    {localize('add')}
                  </button>
                )}
              </div>
            </div>

            <div
              className="p-2 mt-2"
              style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
            >
              <div
                className="bg-white row px-2 text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('movements')}
              </div>
              <div style={{ marginTop: -15 }}>
                {
                  <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                    <SimpleTable
                      columns={tBrColumns}
                      data={tempEntries}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize text-danger"
                            onClick={() => {
                              let ts = tempEntries
                              let found = ts.findIndex(t => t.id === r.id)
                              ts.splice(found, 1)
                              setTempEntries(ts)
                            }}
                          >
                            <i className="uil uil-times-circle text-danger text-capitalize"></i>
                            {localize('remove')}
                          </div>
                        </>
                      )}
                    />
                  </div>
                }
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="bg-white">
        <button
          className="text-uppercase btn btn-danger px-3"
          onClick={closeModal}
        >
          {localize('cancel')}
        </button>
        <button
          onClick={createAssetMovement}
          className="text-uppercase px-4 btn btn-success"
        >
          {localize('move')}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default MoveAsset
