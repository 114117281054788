/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import Loader from '../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const PerformancePeriodModal = ({
  mode,
  selectedPerformancePeriod,
  setSelectedPerformancePeriod,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/EmployeeAppraisalPeriod/update',
            selectedPerformancePeriod,
            false
          )
        : await postData(
            '/EmployeeAppraisalPeriod/create',
            selectedPerformancePeriod,
            false
          )

    if (success) {
      setIsBusy(false)
      closeModal()
      loadTableData()
      showNotification('Performance Periods', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={'performancePeriod'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            {mode == 'edit' ? (
              <>
                <Checkbox
                  id="cPerformancePeriodIsActive"
                  onCheck={e => {
                    setSelectedPerformancePeriod({
                      ...selectedPerformancePeriod,
                      isActive: e,
                    })
                  }}
                  checked={selectedPerformancePeriod?.isActive}
                />
                <label
                  htmlFor="cPerformancePeriodIsActive"
                  className="text-capitalize"
                >
                  {localize('isActive')}?
                </label>
                <div className="mx-3"></div>
                <Checkbox
                  id="cPerformancePeriodIsLocked"
                  onCheck={e => {
                    setSelectedPerformancePeriod({
                      ...selectedPerformancePeriod,
                      isLocked: e,
                    })
                  }}
                  checked={selectedPerformancePeriod?.isLocked}
                />
                <label
                  htmlFor="cPerformancePeriodIsLocked"
                  className="text-capitalize"
                >
                  {localize('isLocked')}?
                </label>
                <div className="mx-3"></div>
              </>
            ) : (
              ''
            )}
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 g-3">
            <div className="col-md-12">
              <div
                className="with-validation d-flex align-items-center mb-1"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  defaultValue={selectedPerformancePeriod?.description}
                  className="form-control"
                  onChange={e => {
                    clearValidation('Description')
                    setSelectedPerformancePeriod({
                      ...selectedPerformancePeriod,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('startDate')}
                </label>
                <CustomDatePicker
                  defaultDate={selectedPerformancePeriod?.durationStartDate}
                  name={'durationStartDate'}
                  onDateChange={date => {
                    setSelectedPerformancePeriod({
                      ...selectedPerformancePeriod,
                      durationStartDate: date,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center gap-2"
                validation-message={
                  globalValidationErrors['DurationEndDate']?.message
                }
              >
                <label htmlFor="cEndDate" className="col-3 text-capitalize">
                  {localize('endDate')}
                </label>
                <CustomDatePicker
                  defaultDate={selectedPerformancePeriod?.durationEndDate}
                  name={'durationEndDate'}
                  onDateChange={date => {
                    clearValidation('DurationEndDate')
                    setSelectedPerformancePeriod({
                      ...selectedPerformancePeriod,
                      durationEndDate: date,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default PerformancePeriodModal
