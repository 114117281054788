/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
  parseDate,
} from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import EnumsCombo from '../../../../../Components/ComboBox/EnumsCombo'
import { loanCasePropertyGroup } from '../../../../../Global/enumeration'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Pager from '../../../../../Components/Tables/Pager'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import ViewAuditLogs from '../../../../AccountsManagement/Operations/TransactionsJournal/SystemTransactions/ViewAuditLogs/ViewAuditLogs'
import ViewLoanCase from './ViewLoanCase'

const LoanApplications = () => {
  let today = new Date()
  const initialParams = {
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
    loanCaseFilter: 0,
    startDate: parseDate(today.setDate(today.getDate() - 30)),
    endDate: parseDate(today),
    loanCaseStatus: 0,
  }

  const [reqParams, setReqParams] = useState(initialParams)
  const [loanApplications, setLoanApplications] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [selectedLoanProduct, setSelectedLoanProduct] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [groupBy, setGroupBy] = useState({
    label: 'category',
    column: 'loanRegistrationLoanProductCategoryDescription',
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.documentReferenceNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.formattedCustomerAccountNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedCustomerMemberNumber}</>,
    },
    {
      label: localize('business/Group/Employer'),
      template: r => <>{r?.customerEmployerDescription}</>,
    },
    {
      label: localize('amountApplied'),
      template: r => <>{formatCurrency(r?.amountApplied)}</>,
    },
    {
      label: localize('appraisedAmount'),
      template: r => <>{formatCurrency(r?.appraisedAmount)}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('productCategory'),
      template: r => <>{r?.loanRegistrationLoanProductCategoryDescription}</>,
    },
    {
      label: localize('receivedDate'),
      template: r => <>{formatDate(r?.receivedDate, true)}</>,
    },
    {
      label: localize('isBatched'),
      template: r => <>{r?.isBatchedDescription}</>,
    },
    {
      label: localize('systemAppraisalRemarks'),
      template: r => <>{r?.systemAppraisalRemarks}</>,
    },
    {
      label: localize('systemAppraisedAmount'),
      template: r => <>{formatCurrency(r?.systemAppraisedAmount)}</>,
    },
    {
      label: localize('appraisedNetIncome'),
      template: r => <>{formatCurrency(r?.appraisedNetIncome)}</>,
    },
    {
      label: localize('appraisedAmountRemarks'),
      template: r => <>{r?.appraisedAmountRemarks}</>,
    },
    {
      label: localize('monthlyPaybackAmount'),
      template: r => <>{formatCurrency(r?.monthlyPaybackAmount)}</>,
    },
    {
      label: localize('totalPaybackAmount'),
      template: r => <>{formatCurrency(r?.totalPaybackAmount)}</>,
    },
    {
      label: localize('appraisedBy'),
      template: r => <>{r?.appraisedBy}</>,
    },
    {
      label: localize('appraisedDate'),
      template: r => <>{formatDate(r?.appraisedDate)}</>,
    },
    {
      label: localize('approvedAmount'),
      template: r => <>{formatCurrency(r?.approvedAmount)}</>,
    },
    {
      label: localize('approvedAmountRemarks'),
      template: r => <>{r?.approvedAmountRemarks}</>,
    },
    {
      label: localize('approvedPrincipalPayment'),
      template: r => <>{formatCurrency(r?.approvedPrincipalPayment)}</>,
    },
    {
      label: localize('approvedInterestPayment'),
      template: r => <>{formatCurrency(r?.approvedInterestPayment)}</>,
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r?.approvalRemarks}</>,
    },
    {
      label: localize('approvedBy'),
      template: r => <>{r?.approvedBy}</>,
    },
    {
      label: localize('approvedDate'),
      template: r => <>{formatDate(r?.approvedDate)}</>,
    },
    {
      label: localize('verificationRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('topUpAmount'),
      template: r => <>{formatCurrency(r?.auditTopUpAmount)}</>,
    },
    {
      label: localize('verifiedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('verifiedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('cancelledBy'),
      template: r => <>{r?.cancelledBy}</>,
    },
    {
      label: localize('cancellationDate'),
      template: r => <>{formatDate(r?.cancelledDatae)}</>,
    },
    {
      label: localize('cancellationRemarks'),
      template: r => <>{r?.cancellationRemarks}</>,
    },
    {
      label: localize('deferredBy'),
      template: r => <>{r?.deferredBy}</>,
    },
    {
      label: localize('deferredDate'),
      template: r => <>{formatDate(r?.deferredDate)}</>,
    },
    {
      label: localize('deferralRemarks'),
      template: r => <>{r?.deferralRemarks}</>,
    },
    {
      label: localize('batchedBy'),
      template: r => <>{r?.batchedBy}</>,
    },
    {
      label: localize('disbursedAmount'),
      template: r => <>{formatCurrency(r?.disbursedAmount)}</>,
    },
    {
      label: localize('reversalRemarks'),
      template: r => <>{r?.reversalRemarsk}</>,
    },
    {
      label: localize('reversedBy'),
      template: r => <>{r?.reversedBy}</>,
    },
    {
      label: localize('reversedDate'),
      template: r => <>{formatDate(r?.reversedDate)}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerPersonalFileNumber}</>,
    },
    {
      label: `${localize('Ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: `${localize('Ref')}_2 (${localize('membership')} #)`,
      template: r => <>{formatDate(r?.customerReference2)}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanPurposeDescription}</>,
    },
    {
      label: localize('disbursementMode'),
      template: r => <>{r?.loanDisbursementModeDescription}</>,
    },
    {
      label: localize('savingsProduct'),
      template: r => <>{r?.savingsProductDescription}</>,
    },
    {
      label: localize('annualPercentageRate'),
      template: r => (
        <>{formatAsPercent(r?.loanInterestAnnualPercentageRate)}</>
      ),
    },
    {
      label: localize('interestChargeMode'),
      template: r => <>{r?.loanInterestChargeModeDescription}</>,
    },
    {
      label: localize('interestRecoveryMode'),
      template: r => <>{r?.loanInterestRecoveryModeDescription}</>,
    },
    {
      label: localize('interestCalculationMode'),
      template: r => <>{r?.loanInterestCalculationModeDescription}</>,
    },
    {
      label: `${localize('term')} (${localize('months')})`,
      template: r => <>{r?.loanRegistrationTermInMonths}</>,
    },
    {
      label: localize('gracePeriod'),
      template: r => <>{r?.loanRegistrationGracePeriod}</>,
    },
    {
      label: localize('minimumMembershipPeriod'),
      template: r => <>{r?.loanRegistrationMinimumMembershipPeriod}</>,
    },
    {
      label: localize('paymentFrequencyPerYear'),
      template: r => (
        <>{r?.loanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('standingOrderTrigger'),
      template: r => <>{r?.loanRegistrationStandingOrderTriggerDescription}</>,
    },
    {
      label: localize('guarantorSecurityMode'),
      template: r => <>{r?.loanRegistrationGuarantorSecurityModeDescription}</>,
    },
    {
      label: localize('incomeAssesmentMode'),
      template: r => <>{r?.loanRegistrationIncomeAssessmentModeDescription}</>,
    },
    {
      label: localize('bypassVerification'),
      template: r => (
        <span className="text-capitalize">
          {r?.loanRegistrationBypassAudit?.toString()}
        </span>
      ),
    },
    {
      label: localize('takeHomeType'),
      template: r => <>{r?.takeHomeTypeDescription}</>,
    },
    {
      label: localize('takeHomePercentage'),
      template: r => <>{formatAsPercent(r?.takeHomePercentage)}</>,
    },
    {
      label: localize('takeHomeFixedAmount'),
      template: r => <>{formatCurrency(r?.takeHomeFixedAmount)}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('loanBalance'),
      template: r => <>{formatCurrency(r?.loanProductLoanBalance)}</>,
    },
    {
      label: localize('attachedLoansBalance'),
      template: r => <>{formatCurrency(r?.totalAttachedLoansBalance)}</>,
      label: localize('attachedLoansBalance'),
      template: r => <>{formatCurrency(r?.totalAttachedLoansBalance)}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  async function getLoanApplicaitons() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanCase/find-loan-case-by-status-and-date-range-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setLoanApplications(data)
    } else {
      setIsBusy(false)
      setLoanApplications(undefined)
    }
  }

  useEffect(() => {
    getLoanApplicaitons()
    // eslint-disable-next-line
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  return (
    <>
      <div>
        <BreadCrumb
          tree={[
            localize('creditManagement'),
            localize('loanOrigination'),
            localize('loanApplications'),
          ]}
        />
        <div className="card">
          <div className="card-body bg-white">
            {isBusy ? (
              <div className="d-flex align-items-center justify-content-center p-5">
                <div className="d-flex align-items-center">
                  <i className="m-3 spinner-border spinner-sm text-black"></i>
                  <span className="text-capitalize fs-4">
                    {localize('pleaseWait')}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="my-2 d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex align-items-center col"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-1"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      className="form-select"
                      value={reqParams.pageSize}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div
                    className="d-flex align-items-center col"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label htmlFor="" className="text-capitalize me-1">
                      {localize('groupBy')}
                    </label>
                    <EnumsCombo
                      enums={loanCasePropertyGroup}
                      value={groupBy?.column}
                      onChange={({ label, value }) =>
                        setGroupBy({ label: label, column: value })
                      }
                    />
                  </div>
                  <div
                    className="d-flex align-items-center col"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label htmlFor="" className="text-capitalize me-1">
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      enumsKey="loanCaseStatusFilter"
                      value={reqParams?.loanCaseStatus}
                      onChange={({ value }) =>
                        setReqParams({ ...reqParams, loanCaseStatus: value })
                      }
                    />

                    {/* <EnumsCombo
                enums={loanCaseStatusFilter}
                value={reqParams?.loanCaseStatus}
                onChange={({ value }) =>
                  setReqParams({ ...reqParams, loanCaseStatus: value })
                }
              /> */}
                  </div>
                  <div
                    className="d-flex align-items-center col"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      htmlFor=""
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize  me-1"
                    >
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams?.startDate,
                        endDate: reqParams?.endDate,
                      }}
                      onSelect={v => {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      }}
                    />
                  </div>
                  <div
                    className="d-flex align-items-center col gap-1"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      htmlFor=""
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize"
                    >
                      {localize('search')}
                    </label>
                    <div>
                      <EnumsServerCombo
                        value={reqParams.loanCaseFilter}
                        enumsKey="loanCaseFilter"
                        onChange={({ value }) =>
                          setReqParams({ ...reqParams, loanCaseFilter: value })
                        }
                      />
                      {/* <EnumsCombo
                  enums={loanCaseFilter}
                  value={reqParams.loanCaseFilter}
                  onChange={({ value }) =>
                    setReqParams({ ...reqParams, loanCaseFilter: value })
                  }
                /> */}
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.filterText}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setRefresh(!refresh)
                        setReqParams({ ...reqParams, pageIndex: 0 })
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                {loanApplications && (
                  <>
                    <GroupedTable
                      groupBy={groupBy}
                      columns={columns}
                      data={loanApplications?.pageCollection}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setModalMode('view')
                              setSelectedLoanProduct(r)
                            }}
                          >
                            <i className="uil uil-search-alt"></i>
                            <span className="text-capitalize">
                              {localize('view')}
                            </span>
                          </div>
                          <div className="ctxt-menu-item">
                            <i className="uil uil-chart-line"></i>
                            <span className="text-capitalize">
                              {localize('appraisalAnalysis')}
                            </span>
                          </div>
                          <div className="ctxt-menu-item">
                            <i className="uil uil-sync"></i>
                            <span className="text-capitalize">
                              {localize('reOpen')}
                            </span>
                          </div>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setModalMode('auditLogs')
                              setSelectedLoanProduct(r)
                            }}
                          >
                            <i className="uil uil-window"></i>
                            <span className="text-capitalize">
                              {localize('viewAuditLog')}
                            </span>
                          </div>
                        </>
                      )}
                    />
                    <div className="mt-2">
                      <Pager
                        itemsCount={loanApplications?.itemsCount}
                        pageSize={reqParams?.pageSize}
                        pageIndex={reqParams?.pageIndex}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                        pageBatchSize={5}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {modalMode === 'auditLogs' && selectedLoanProduct && (
        <ViewAuditLogs
          data={selectedLoanProduct}
          handleClose={() => {
            setModalMode(null)
            setSelectedLoanProduct(null)
          }}
        />
      )}
      {modalMode === 'view' && selectedLoanProduct && (
        <ViewLoanCase
          refreshFromServer
          loanCase={selectedLoanProduct}
          onClose={() => {
            setSelectedLoanProduct(null)
            setModalMode(null)
          }}
        />
      )}
    </>
  )
}

export default LoanApplications
