/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'

function CustomerAccountsList({ customer }) {
  useEffect(() => {
    getAccounts()
    // eslint-disable-next-line
  }, [customer])
  const [fetching, setFetching] = useState(false)
  const [accounts, setAccounts] = useState([])

  const columns = [
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r?.bookBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r?.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r?.interestBalance)}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r?.loanClassificationDescription}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => <>{formatCurrency(r?.loanCaseDisbursedAmount)}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r?.loanBalanceExpected)}</>,
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r?.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r?.loanDefaultAmount)}</>,
    },
    {
      label: localize('loanArrearsTimeline') + `(${localize('days')})`,
      template: r => <>{r?.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvision') + `(${localize('percentage')})`,
      template: r => <>{r?.loanLossProvisionPercentage}</>,
    },
    {
      label: localize('loanLossProvision') + `(${localize('amount')})`,
      template: r => <>{formatCurrency(r?.loanLossProvisionAmount)}</>,
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => <>{formatDate(r?.loanCaseDisbursedDate)}</>,
    },
    {
      label: localize('loanGracePeriod'),
      template: r => <>{r?.loanCaseLoanRegistrationGracePeriod}</>,
    },
    {
      label: localize('lastLoanRepaymentDueDate'),
      template: r => <>{formatDate(r?.loanLastPaymentDueDate)}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{formatCurrency(r?.loanLastPayment)}</>,
    },
    {
      label: localize('loanTerm') + `(${localize('months')})`,
      template: r => <>{r?.loanCaseLoanRegistrationTermInMonths}</>,
    },
    {
      label: localize('loanAnnualPercentageRate'),
      template: r => <>{r?.loanCaseLoanInterestAnnualPercentageRate}</>,
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r => (
        <>{r?.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => <>{r?.loanCaseLoanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('carryForwadsBalance'),
      template: r => <>{formatCurrency(r?.carryForwadsBalance)}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  async function getAccounts() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/CustomerAccount/find-customer-accounts-by-customer-id',
      {
        customerId: customer?.id,
        includeBalances: true,
        includeProductDescription: true,
        balancesSummaryProductSection: 0,
      },
      false
    )
    if (success) {
      setAccounts(data?.pageCollection || [])
      setFetching(false)
    }
  }

  return (
    <div>
      {fetching ? (
        <div className="d-flex align-items-center justify-content-center p-5">
          <div className="spinner-border text-dark"></div>
        </div>
      ) : (
        <GroupedTable
          groupBy={{
            label: localize('productSection'),
            column: 'customerAccountTypeTargetProductSectionDescription',
          }}
          columns={columns}
          data={accounts}
        />
      )}
    </div>
  )
}

export default CustomerAccountsList
