/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'
function ReportLookup({ onSelect, displayValue, disabled }) {
  const columns = [
    {
      label: localize('reportName'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r.indentedReportName }}></span>
      ),
    },
    {
      label: localize('category'),
      template: r => <>{r.categoryDescription}</>,
    },
    { label: localize('reportPath'), template: r => <>{r.reportPath}</> },
    { label: localize('reportQuery'), template: r => <>{r.reportQuery}</> },
    { label: localize('ssrsHost'), template: r => <>{r.reportHost}</> },
    { label: localize('ssrsPort'), template: r => <>{r.reportPort}</> },
    { label: localize('depth'), template: r => <>{r.depth}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        disabled={disabled}
        url="/ReportLookup/find-by-filter-in-page"
        params={{
          filterText: '',
          pageIndex: 0,
          pageSize: 10,
          updateDepth: false,
          traverseTree: false,
        }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
        title={localize('userDefinedReportLookup')}
      />
    </>
  )
}
export default ReportLookup
