/** @format */

import Cleave from 'cleave.js/react'
/* eslint-disable */
import React from 'react'

export const MaskNumber = ({
  onMaskNumber,
  onMaskKeyDown,
  disabled,
  invalid,
  defaultMaskValue,
  className,
  numeralThousandsGroupStyle = 'thousand',
  onBlur,
}) => {
  return (
    <Cleave
      disabled={disabled}
      className={`form-control ${className} ${invalid ? 'is-invalid' : ''}`}
      style={{ textAlign: 'right' }}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: numeralThousandsGroupStyle,
        numericOnly: true,
        stripLeadingZeroes: true,
        numeralDecimalMark: '.',
        numeralDecimalScale: 2,
        
      }}
      value={defaultMaskValue}
      defaultValue={defaultMaskValue}
      onChange={onMaskNumber}
      onBlur={onBlur}
      onKeyDown={e => {
        if (e.key === '-' || e.key === '+') {
          e.preventDefault()
        } else {
          if (onMaskKeyDown) {
            onMaskKeyDown(e)
          }
          onMaskNumber(e)
        }
      }}
    />
  )
}
