/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _i = {
  isoCode: {
    en: 'iso code',
    sw: 'kodi iso',
  },
  isoNumber: {
    en: 'iso number',
    sw: 'nambari iso',
  },
  initiate: {
    en: 'Initiate',
    sw: 'Anzisha',
  },
  importData: {
    en: 'Import Data',
    sw: 'Ingiza Data',
  },
  isLocked: {
    en: 'is locked',
    sw: 'imefungwa',
  },
  items: {
    en: 'items',
    sw: 'bidhaa',
  },
  internetAddress: {
    en: 'internet address',
    sw: 'anwani ya mtandao',
  },
  immediateMemberNumber: {
    en: 'immediate member number',
    sw: 'nambari ya mwanachama wa moja kwa moja',
  },
  immediateAccountNumber: {
    en: 'immediate account number',
    sw: 'nambari ya akaunti ya haraka',
  },
  interestExpenseChartOfAccountName: {
    en: 'interest expense g/l account name',
    sw: 'chati ya gharama ya riba ya jina la akaunti',
  },
  interestExpenseChartOfAccountCostDistributionRuleDescription: {
    en: 'interest expense g/l account cost distr. rule',
    sw: 'sheria ya usambazaji wa Gharama ya Riba ya Akaunti ya Chati ya maslahi ya maelezo',
  },
  initialLeafNumber: {
    en: 'initial leaf number',
    sw: 'idadi ya karatasi ya kwanza',
  },
  interestPayableChartOfAccountName: {
    en: 'interest payable g/l account name',
    sw: 'chati ya malipo ya riba ya Jina la akaunti',
  },
  interestPayableChartOfAccountCostDistributionRuleDescription: {
    en: 'interest payable g/l account cost distribution rule description',
    sw: 'chati inayolipwa ya riba ya g/l',
  },
  isDefault: {
    en: 'is default',
    sw: 'ni chaguo msingi',
  },
  isRegistry: {
    en: 'is registry',
    sw: 'ni usajili',
  },
  isDeductible: {
    en: 'is deductible',
    sw: 'inakatwa',
  },
  isActive: {
    en: 'is active',
    sw: 'ni amilifu',
  },
  incomeAdjustments: {
    en: 'income adjustments',
    sw: 'marekebisho ya mapato',
  },
  individualType: {
    en: 'individual type',
    sw: 'aina ya mtu binafsi',
  },
  identityCardType: {
    en: 'identity card type',
    sw: 'aina ya kitambulisho',
  },
  identityCardNumber: {
    en: 'identity card number',
    sw: 'nambari ya kitambulisho',
  },
  identityCardSerialNumber: {
    en: 'identity card serial number',
    sw: 'nambari ya mfululizo ya kitambulisho',
  },
  interestArreasGracePeriod: {
    en: 'interest arrears grace period',
    sw: 'kipindi cha msamaha cha malimbikizo ya riba',
  },
  initialMemberNumber: {
    en: 'initial member number',
    sw: 'nambari ya kwanza ya mwanachama',
  },
  initialStatementDate: {
    en: 'initial statement date',
    sw: 'tarehe ya taarifa ya awali',
  },
  inhibitGuaranteeing: {
    en: 'inhibit guaranteeing',
    sw: 'kuzuia dhamana',
  },
  isTaxExempt: {
    en: 'is tax exempt',
    sw: 'ni msamaha wa kodi',
  },
  indefinate: {
    en: 'indefinate',
    sw: 'isiyo na kikomo',
  },
  investments: {
    en: 'investments',
    sw: 'uwekeshaji',
  },
  indefinateCharges: {
    en: 'indefinate charges',
    sw: 'gharama isiyo na kikomo',
  },
  initialAccountNumber: {
    en: 'initial account number',
    sw: 'nambari ya akaunti ya awali',
  },
  isClosed: {
    en: 'is closed',
    sw: 'imefungwa',
  },
  interestArrearsGracePeriod: {
    en: 'interest arrears grace period',
    sw: 'kipindi cha msamaha cha malimbikizo ya riba',
  },
  investementProducts: {
    en: 'investement products',
    sw: 'bidhaa uwekeshaji',
  },
  individualFirstName: {
    en: 'individual first name',
    sw: 'jina la kwanza',
  },
  individualLastName: {
    en: 'individual last name',
    sw: 'jina la mwisho',
  },
  isDividendComputed: {
    en: 'is dividend computed',
    sw: 'inakokotolewa mgao',
  },
  identificationNumber: {
    en: 'identification number',
    sw: 'nambari ya kitambulisho',
  },
  initiatingBranch: {
    en: 'initiating branch',
    sw: 'tawi anzishi',
  },
  interestChartOfAccountExpenseName: {
    en: 'interest g/l account expense name',
    sw: 'gharama ya akaunti',
  },
  isLeaveTypeDeductible: {
    en: 'is leave type deductible',
    sw: 'ni aina ya kuondoka inayokatwa',
  },
  income: {
    en: 'income',
    sw: 'mapato',
  },
  isControlAcount: {
    en: 'is control account',
    sw: 'ni akaunti ya udhibiti',
  },
  isReconciliationAccount: {
    en: 'is reconciliation account',
    sw: 'ni akaunti ya upatanisho',
  },
  isConfidential: {
    en: 'is confidential',
    sw: 'ni siri',
  },
  installments: {
    en: 'installments',
    sw: 'awamu',
  },
  installmentsBasisValueDescription: {
    en: 'installments basis value description',
    sw: 'maelezo ya thamani ya awamu',
  },
  installmentsBasisValue: {
    en: 'installment basis value',
    sw: 'installment basis value',
  },
  interestExpenseGLAccount: {
    en: 'interest expense g/l account',
    sw: 'gharama ya riba akaunti ya g/l',
  },
  interestExpenseGLAccountName: {
    en: 'interest expense g/l account name',
    sw: 'gharama ya riba g/l jina la akaunti',
  },
  interestExpenseGLAccountCostDistributionRule: {
    en: 'interest expense g/l account cost distr. rule',
    sw: 'gharama ya riba g/l kanuni ya usambazaji wa gharama ya akaunti',
  },
  interestPayableGLAccountName: {
    en: 'interest payable g/l account name',
    sw: 'malipo ya riba g/l jina la akaunti',
  },
  interestExpenseGLAccountDistributionRule: {
    en: 'interest expense g/l account distribution rule',
    sw: 'gharama ya riba kanuni ya usambazaji wa akaunti ya g/l',
  },
  interesPayableGLAccountName: {
    en: 'interest payable g/l account name',
    sw: 'riba inayolipwa jina la akaunti ya g/l',
  },
  interestPayableGLAccountDistributionRule: {
    en: 'interest payable g/l account distribution rule',
    sw: 'kanuni ya usambazaji wa akaunti ya g/l ya riba',
  },
  interestCalculationMode: {
    en: 'interest calculation mode',
    sw: 'hali ya kuhesabu riba',
  },
  interestPayableGLAccountCostDistributionRule: {
    en: 'interest payable g/l account cost distr. rule',
    sw: 'kanuni ya usambazaji wa gharama ya akaunti ya g/l ya riba',
  },
  internet: {
    en: 'internet',
    sw: 'mtandao',
  },
  investmentProductLookup: {
    en: 'investment product lookup',
    sw: 'utafutaji wa bidhaa za uwekezaji',
  },
  isOccupied: {
    en: 'is occupied',
    sw: 'imekaliwa',
  },
  isStatutory: {
    en: 'is statutory',
    sw: 'ni kisheria',
  },
  indefiniteCharges: {
    en: 'indefinite charges',
    sw: 'malipo yasiyo na kikomo',
  },
  interestPayableGLAccount: {
    en: 'interest payable g/l account',
    sw: 'akaunti ya g/l inayolipa riba',
  },
  investmentProducts: {
    en: 'investment products',
    sw: 'bidhaa za uwekezaji',
  },
  isSuperSaver: {
    en: 'is super saver',
    sw: ' ni okoaji kuu',
  },
  isPooled: {
    en: 'is pooled',
    sw: 'imeunganishwa',
  },
  isInstitutionalCapital: {
    en: 'is institutional capital',
    sw: 'ni mtaji wa taasisi',
  },
  isRefundable: {
    en: 'is refundable',
    sw: 'yaweza rejeshwa',
  },
  investmentsMultiplier: {
    en: 'investments multiplier',
    sw: 'kizidishi cha uwekezaji',
  },
  interestChargeMode: {
    en: 'interest charge mode',
    sw: 'hali ya malipo ya riba',
  },
  interestRecoveryMode: {
    en: 'interest recovery mode',
    sw: 'hali ya kurejesha maslahi',
  },
  incomeAssessmentMode: {
    en: 'income assessment mode',
    sw: 'hali ya tathmini ya mapato',
  },
  interestReceivedGLAccountName: {
    en: 'interest received g/l account name',
    sw: 'riba iliyopokelewa jina la akaunti ya g/l',
  },
  interestReceivedGLAccountCostDistributionRule: {
    en: 'interest received g/l account cost distr. rule',
    sw: 'riba iliyopokelewa g/l sheria ya usambazaji wa gharama ya akaunti',
  },
  interestReceivableGLAccountName: {
    en: 'interest receivable g/l account name',
    sw: 'jina la akaunti ya g/l inayopokea riba',
  },
  interestReceivableGLAccountCostDistributionRule: {
    en: 'interest receivable g/l account Cost distribution rule',
    sw: 'riba inayopokelewa akaunti ya g/l Kanuni ya gharama ya usambazaji',
  },
  interestChargedGLAccountName: {
    en: 'interest charged g/l account name',
    sw: 'jina la akaunti ya g/l inayotozwa riba',
  },
  interestChargedGLAccountCostDistributionRule: {
    en: 'interest charged g/l account cost distribution rule',
    sw: 'kanuni ya usambazaji wa gharama ya akaunti ya g/l inayotozwa riba',
  },
  IncomeAdjustment: {
    en: 'income adjustment',
    sw: 'rekebisho la pato',
  },
  isTangible: {
    en: 'is tangible',
    sw: 'ni ya kuonekana',
  },
  identityCardSerial: {
    en: 'identity card serial',
    sw: 'nambari ya mfululizo ya kitambulisho',
  },
  interestExpenseChartOfAccountCostDistributionRule: {
    en: 'interest expense g/l account cost distr. rule',
    sw: 'sheria ya usambazaji wa gharama ya akaunti ya g / l',
  },
  interestPayableChartOfAccountCostDistributionRule: {
    en: 'interest payable g/l account cost distr. rule',
    sw: 'chati inayolipwa ya riba ya akaunti ya g/l',
  },
  isAdministrative: {
    en: 'is administrative',
    sw: 'ni ya kiutawala',
  },
  identification: {
    en: 'identification',
    sw: 'kitambulisho',
  },
  identityCard: {
    en: 'identity card',
    sw: 'kadi ya utambulisho',
  },
  includeCustomerAccount: {
    en: 'include customer account',
    sw: 'ni pamoja na akaunti ya mteja',
  },
  investmentAccounts: {
    en: 'investment accounts',
    sw: 'akaunti za uwekezaji',
  },
  isNextOfKin: {
    en: 'is next of kin',
    sw: 'ni jamaa wa karibu',
  },
  isNominee: {
    en: 'is nominee',
    sw: 'ni mteule',
  },
  isBenevolentSpouse: {
    en: 'is benevolent spouse',
    sw: 'ni mwenzi mpendwa',
  },
  isBenevolentChild: {
    en: 'is benevolent child',
    sw: 'ni mtoto mpendwa',
  },
  identityCardTypeDescription: {
    en: 'identity card type description',
    sw: 'maelezo ya aina ya kadi ya kitambulisho',
  },
  interestReceivedChartOfAccountName: {
    en: 'interest received g/l account name',
    sw: 'jina la akaunti ya g/l iliyopokelewa riba',
  },
  interestReceivedChartOfAccountCostDistributionRuleDescription: {
    en: 'interest received g/l account cost distr. rule description',
    sw: 'riba iliyopokelewa g/l akaunti ya gharama ya usambazaji wa sheria maelezo',
  },
  interestReceivableChartOfAccountName: {
    en: 'interest receivable g/l account name',
    sw: 'jina la akaunti ya g/l inayopokea riba',
  },
  interestReceivableChartOfAccountCostDistributionRuleDescription: {
    en: 'interest receivable g/l account cost distr. rule description',
    sw: 'riba inayopokelewa akaunti ya g/l Kanuni ya gharama ya usambazaji maelezo',
  },
  interestChargedChartOfAccountName: {
    en: 'interest charged g/l account name',
    sw: 'jina la akaunti ya g/l inayotozwa riba',
  },
  interestChargedChartOfAccountCostDistributionRuleDescription: {
    en: 'interest charged g/l account cost distribution rule description',
    sw: 'kanuni ya usambazaji wa gharama ya akaunti ya g/l inayotozwa riba maelezo',
  },
  individual: {
    en: 'individual',
    sw: 'mtu binafsi',
  },
  individualParticulars: {
    en: 'individual particulars',
    sw: 'habari za mtu binafsi',
  },
  investementAccounts: {
    en: 'investement accounts',
    sw: 'akaunti za uwekezaji',
  },
  inactivityTimeout: {
    en: 'inactivity timeout',
    sw: 'muda wa kutokuwa na shughuli',
  },
  interestBalance: {
    en: 'interest balance',
    sw: 'baki ya riba',
  },
  identitySerial: {
    en: 'identity serial',
    sw: 'nambari ya serial ya kitambulisho',
  },
  invalidatedBy: {
    en: 'invalidated by',
    sw: 'imebatilishwa na',
  },
  invalidatedDate: {
    en: 'invalidated date',
    sw: 'tarehe iliyobatilishwa',
  },
  invalidationRemarks: {
    en: 'invalidation remarks',
    sw: 'maoni ya kubatilisha',
  },
  indexFinger: {
    en: 'index finger',
    sw: 'kidole cha shahada',
  },
  identityType: {
    en: 'identity type',
    sw: 'aina ya kitambulisho',
  },
  identityNumber: {
    en: 'identity number',
    sw: 'nambari ya kitambulisho',
  },
  identitySerialNumber: {
    en: 'identity serial number',
    sw: 'nambari ya mfululizo ya kitambulisho',
  },
  idNumber: {
    en: 'id number',
    sw: 'nambari ya kitambulisho',
  },
  idFront: {
    en: 'id front',
    sw: 'mbele ya kitambulisho',
  },
  idBack: {
    en: 'id back',
    sw: 'nyuma ya kitambulisho',
  },
  invalidate: {
    en: 'invalidate',
    sw: 'batilisha',
  },
  immediateSuperiorIdNumber: {
    en: 'immediate superior id number',
    sw: 'nambari ya kitambulisho moja kwa moja',
  },
  immediateSuperiorName: {
    en: 'immediate superior name',
    sw: 'jina la juu la moja kwa moja',
  },
  immediateSuperiorSignatureDate: {
    en: 'immediate superior signature date',
    sw: 'tarehe ya saini ya juu ya moja kwa moja',
  },
  immediateSuperiorNames: {
    en: 'immediate superior names',
    sw: 'majina ya juu ya moja kwa moja',
  },
  internalAccountTransfer: {
    en: 'internal account transfer',
    sw: 'uhamisho wa akaunti ya ndani',
  },
  intraAccountTransfer: {
    en: 'intra account transfer',
    sw: 'uhamisho wa akaunti',
  },
  identityCardFront: {
    en: 'identity card front',
    sw: 'mbele ya kitambulisho',
  },
  identityCardBack: {
    en: 'identity card back',
    sw: 'nyuma ya kitambulisho',
  },
  integrityHash: {
    en: 'integrity hash',
    sw: 'hashi ya uadilifu',
  },
  indefinite: {
    en: 'indefinite',
    sw: 'bila kikomo',
  },
  'identityCardSerial#': {
    en: 'Identity Card Serial #',
    sw: 'Nambari ya Mfululizo wa Kitambulisho',
  },
  interAccountTransfer: {
    en: 'inter account transfer',
    sw: 'uhamisho wa akaunti',
  },
  inputter: {
    en: 'inputter',
    sw: 'pembejeo',
  },
  indicator: {
    en: 'indicator',
    sw: 'kiashiria',
  },
  interestReceivedGLAccountCostDistrRule: {
    en: 'interest received g/l account cost distr. rule',
    sw: 'riba iliyopokelewa ya g/l',
  },
  interestReceivableGLAccountCostDistrRule: {
    en: 'interest receivable g/l account cost distr. rule',
    sw: 'riba inayopokelewa ya g/l',
  },
  invoiceNumber: {
    en: 'invoice number',
    sw: 'nambari ya ankara',
  },
  institutionCode: {
    en: 'institution code',
    sw: 'nambari ya taasisi',
  },
  institutionName: {
    en: 'institution name',
    sw: 'jina la taasisi',
  },
  interestRecivableGLAccount: {
    en: 'interest recievable g/l account',
    sw: 'riba inayopokelewa akaunti ya g/l',
  },
  interestReceivedGLAccount: {
    en: 'interest received g/l account',
    sw: 'riba iliyopokelewa akaunti ya g/l',
  },
  interestChargedGLAccount: {
    en: 'interest charged g/l account',
    sw: 'riba iliyotozwa akaunti ya g/l',
  },
  investmentProduct: {
    en: 'investment product',
    sw: 'bidhaa ya uwekezaji',
  },
  itemName: {
    en: 'item name',
    sw: 'jina la kitu',
  },
  import: {
    en: 'import',
    sw: 'ingiza',
  },
  interest: {
    en: 'interest',
    sw: 'riba',
  },
  interAccountTransferBatches: {
    en: 'inter account transfer batches',
    sw: 'vifurushi vya uhamisho wa akaunti',
  },
  ignoreNegativeYield: {
    en: 'ignore negative yield',
    sw: 'puuza mazao hasi',
  },
  investment: {
    en: 'investment',
    sw: 'uwekezaji',
  },
  interestExpenseGlCostDistributionRuleDescription: {
    en: 'interest expense g/l cost distribution rule description',
    sw: 'gharama ya g/l ya gharama ya riba',
  },
  interestPayableGlCostDistributionRuleDescription: {
    en: 'interest payable g/l cost distribution rule description',
    sw: 'gharama ya g/l ya gharama ya riba',
  },
  individualIdentityCardNumber: {
    en: 'individual identity card number',
    sw: 'nambari ya kitambulisho cha mtu binafsi',
  },
  'identification#': {
    en: 'identification #',
    sw: 'kitambulisho #',
  },
  inhibitPosting: {
    en: 'inhibit posting',
    sw: 'zuia kuchapisha',
  },
  indefiniteLoanCharges: {
    en: 'indefinite loan charges',
    sw: 'gharama za mkopo zisizo na kikomo',
  },
  intraAccountLoanTransfer: {
    en: 'intra-account loan transfer',
    sw: 'uhamisho wa mkopo wa akaunti',
  },
  identityVerification: {
    en: 'identity verification',
    sw: 'uthibitisho wa kitambulisho',
  },
  identityScrub: {
    en: 'identity scrub',
    sw: 'safisha kitambulisho',
  },
  identityVerificationQueries: {
    en: 'identity verification queries',
    sw: 'maswali ya uthibitisho wa kitambulisho',
  },
  identityScrubQueries: {
    en: 'identity scrub queries',
    sw: 'maswali ya utambulisho',
  },
  incomeAssesmentMode: {
    en: 'income assesment mode',
    sw: 'hali ya tathmini ya mapato',
  },
  interestPart: {
    en: 'interest part',
    sw: 'sehemu ya riba',
  },
  incomeQualification: {
    en: 'income qualification',
    sw: 'ustahiki wa mapato',
  },
  'investmentAccounts(consolidate)': {
    en: 'investment accounts (consolidate)',
    sw: 'akaunti za uwekezaji (unganisha)',
  },
  incomeEstimationAmount: {
    en: 'income estimation amount',
    sw: 'kiasi cha makadirio ya mapato',
  },
  isGuarantor: {
    en: 'is guarantor',
    sw: 'ni mdhamini',
  },
  isYourAccount: {
    en: 'is your account',
    sw: 'akaunti yako',
  },
  'identity#': {
    en: 'identity #',
    sw: 'kitambulisho #',
  },
  incomeAdjustment: {
    en: 'income adjustment',
    sw: 'marekebisho ya mapato',
  },
  isBatched: {
    en: 'is batched',
    sw: 'ni kundi',
  },
  interestAttachedFormula: {
    en: 'interest attached formula',
    sw: 'fomula ya riba iliyowekwa',
  },
  interestAttached: {
    en: 'interest attached',
    sw: 'riba iliyowekwa',
  },
  interestCalculationModeDescription: {
    en: 'interest calculation mode description',
    sw: 'maelezo ya hali ya kuhesabu riba',
  },
  interestReceivedChartOfAccountCostDistributionRule: {
    en: 'interest received g/l cost distribution rule',
    sw: 'riba iliyopokelewa g/l kanuni ya usambazaji wa gharama',
  },
  interestReceivableChartOfAccountCostDistributionRule: {
    en: 'interest receivable g/l cost distribution rule',
    sw: 'riba inayopokelewa g/l kanuni ya usambazaji wa gharama',
  },
  interestChargedChartOfAccountCostDistributionRule: {
    en: 'interest charged g/l cost distribution rule',
    sw: 'riba iliyotozwa g/l kanuni ya usambazaji wa gharama',
  },
  interestPayment: {
    en: 'interest payment',
    sw: 'malipo ya riba',
  },
  'investmentProducts(benefactor)': {
    en: 'investment products (benefactor)',
    sw: 'bidhaa za uwekezaji (mwenye fadhila)',
  },
  interestAmount: {
    en: 'interest amount',
    sw: 'kiasi cha riba',
  },
  instantMessaging: {
    en: 'instant messaging',
    sw: 'uandishi wa papo hapo',
  },
  interestCapitalizationMonths: {
    en: 'interest capitalization(months)',
    sw: 'riba ya mtaji(miezi)',
  },
  interestArrearsAmount: {
    en: 'interest arrears amount',
    sw: 'kiasi cha malimbikizo ya riba',
  },
  identity: {
    en: 'identity',
    sw: 'kitambulisho',
  },
  interestCharged: {
    en: 'interest charged',
    sw: 'riba iliyotozwa',
  },
  interestPaid: {
    en: 'interest paid',
    sw: 'riba iliyolipwa',
  },
  initialVoucherNumber: {
    en: 'initial voucher number',
    sw: 'nambari ya awali ya vocha',
  },
  isPreselected: {
    en: 'is preselected',
    sw: 'imechaguliwa mapema',
  },
  isPayable: {
    en: 'is payable',
    sw: 'inayolipika',
  },
  inHouse: {
    en: 'in house',
    sw: 'ndani',
  },
  inHouseChequeWriting: {
    en: 'in house cheque writing',
    sw: 'kuandika hundi ndani',
  },
  inHouseChequePrinting: {
    en: 'in house cheque printing',
    sw: 'uchapishaji wa hundi ndani',
  },
  inHouseChequeCatalogue: {
    en: 'in house cheque catalogue',
    sw: 'orodha ya hundi ndani',
  },
  isBodyHTML: {
    en: 'is body HTML',
    sw: 'ni mwili wa HTML',
  },
  inHouseCheckPrinting: {
    en: 'in house check printing',
    sw: 'uchapishaji wa hundi ndani',
  },
  inhouseCheques: {
    en: 'inhouse cheques',
    sw: 'hundi za ndani',
  },
  isPrinted: {
    en: 'is printed',
    sw: 'imechapishwa',
  },
  isTransferred: {
    en: 'is transferred',
    sw: 'imehamishwa',
  },
  isBanked: {
    en: 'is banked',
    sw: 'imewekewa benki',
  },
  inHouseChequeLookup: {
    en: 'in house cheque lookup',
    sw: 'utafutaji wa hundi ndani',
  },
  itemRegister: {
    en: 'item register',
    sw: 'kuandikisha kitu',
  },
  isThirdPartyNotified: {
    en: 'is third party notified',
    sw: 'mtu wa tatu amearifiwa',
  },
  issuingNetwork: {
    en: 'issuing network',
    sw: 'mtandao wa utoaji',
  },
  isOneOff: {
    en: 'is one off',
    sw: 'ni moja kwa moja',
  },
  insuranceRelief: {
    en: 'insurance relief',
    sw: 'msamaha wa bima',
  },
  ipnEnabled: {
    en: 'ipn enabled',
    sw: 'ipn imeamilishwa',
  },
  ipnStatus: {
    en: 'ipn status',
    sw: 'hali ya ipn',
  },
  ipnResponse: {
    en: 'ipn response',
    sw: 'jibu la ipn',
  },
  insuranceReliefAmount: {
    en: 'insurance relief amount',
    sw: 'kiasi cha msamaha wa bima',
  },
  inventoryCategory: {
    en: 'inventory category',
    sw: 'aina ya hisa',
  },
  itemType: {
    en: 'item type',
    sw: 'aina ya kitu',
  },
  inventoryCategoryLookup: {
    en: 'inventory category lookup',
    sw: 'utafutaji wa aina ya hisa',
  },
  itemIsLocked: {
    en: 'item is locked',
    sw: 'kitu kimefungwa',
  },
  inventoryCategories: {
    en: 'inventory categories',
    sw: 'kategoria ya mali',
  },
  inventoryPackageType: {
    en: 'inventory package type',
    sw: 'aina ya pakiti za mahali',
  },
  inventoryPackageTypes: {
    en: 'inventory package types',
    sw: 'aina ya pakiti za mahali',
  },
  inventoryUnitsOfMeasure: {
    en: 'inventory units of measure',
    sw: 'vipimo vya hesabu',
  },
  itemsRegister: {
    en: 'items register',
    sw: 'kuandikisha bidhaa',
  },
  InventoryCategories: {
    en: 'Inventory Categories',
    sw: 'kategoria za hesabu',
  },
  InventoryPackageTypes: {
    en: 'Inventory Package Types',
    sw: 'aina ya pakiti za mahali',
  },
  InventoryUnitsOfMeasure: {
    en: 'Inventory Units Of Measure',
    sw: 'Vipimo vya hesabu',
  },
  ItemsRegister: {
    en: 'Items Register',
    sw: 'Kuandikisha bidhaa',
  },
  inventory: {
    en: 'inventory',
    sw: 'hisabati',
  },
  isPrimary: {
    en: 'is primary',
    sw: 'ni msingi',
  },
  itemDetails: {
    en: 'item details',
    sw: 'maelezo ya bidhaa',
  },
  itemDescription: {
    en: 'item description',
    sw: 'maelezo ya bidhaa',
  },
  item: {
    en: 'item',
    sw: 'bidhaa',
  },
  itemRegisterLookup: {
    en: 'item register lookup',
    sw: 'utafutaji wa kuandikisha kitu',
  },
  invoices: {
    en: 'invoices',
    sw: 'ankara',
  },
  isFlaggedForDisposal: {
    en: 'is flagged for disposal',
    sw: 'imeashiria kwa ajili ya kutupwa',
  },
  inventoryCategoryIsLocked: {
    en: 'inventory category is locked',
    sw: 'aina ya hisa imefungwa',
  },
  incidentDate: {
    en: 'incident date',
    sw: 'tarehe ya tukio',
  },
  invoiceDate: {
    en: 'invoice date',
    sw: 'tarehe ya ankara',
  },
  invoiceItems: {
    en: 'invoice items',
    sw: 'bidhaa za ankara',
  },
  'invoice#': {
    en: 'invoice #',
    sw: 'ankara #',
  },
  invoiceApprovalItems: {
    en: 'invoice approval items',
    sw: 'bidhaa za idhini ya ankara',
  },
  impact: {
    en: 'impact',
    sw: 'athari',
  },
  inventoryCatalogueLookup: {
    en: 'inventory catalogue lookup',
    sw: 'utafutaji wa orodha ya hesabu',
  },
  itemsOnPage: {
    en: 'items',
    sw: ' ',
  },
  interestRateValue: {
    en: 'interest rate value',
    sw: 'thamani ya kiwango cha riba',
  },
  includeBalances: {
    en: 'include balances',
    sw: 'ni pamoja na salio',
  },
}
export default _i
