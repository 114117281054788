/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import LoanProductLookupPartial from '../../../../../../Components/ReUsables/LoanProducts/LoanProductLookupPartial'
import localize from '../../../../../../Global/Localize'
import CustomerSalaryHistory from '../CustomerSalaryHistory'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import FormulaInput from '../../../../../../Components/Formula/FormulaInput'
import CustomerPayoutHistory from '../CustomerPayoutHistory'
import { checkIfIsAdministrative } from '../../../../../../Helpers/Modules/customer'
import Tab from '../../../../../../Components/Tabs/Tab'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import {
  evaluateEligibleIncomeDeductions,
  evaluateLoaneeIncome,
  evaluateQualification,
} from '../../../../../../Helpers/Modules/loan'
import { getData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'

function Qualification({
  customer,
  loanProduct,
  onLoanSelected,
  onChangeTermInMonths,
  noLoanInfo,
  useLoanCase,
  loanCase,
  onChangeAbilityToPayOverLoanTerm,
}) {
  const [selectedLoanProduct, setSelectedLoanProduct] = useState(loanProduct)
  const [customerIsAdministrative, setCustomerIsAdministrative] =
    useState(false)
  const [latestIncome, setLatestIncome] = useState(0)
  const [latestIncomeValue, setLatestIncomeValue] = useState(0)
  const [incomeAdjustments, setIncomeAdjustments] = useState([])
  const [incomeTotals, setIncomeTotals] = useState({
    allowances: 0,
    clearedEffects: 0,
    deductions: 0,
  })
  const [basicIncome, setBasicIncome] = useState(0)
  const [totalIncome, setTotalIncome] = useState(0)
  const [takeHomeRetention, setTakeHomeRetention] = useState(0)
  const [abilityToPayPerPeriod, setAbilityToPayPerPeriod] = useState(0)
  const [termInMonths, setTermInMonths] = useState(0)
  const [qualification, setQualification] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    loanProduct && loanProduct?.id && loadLoan()
  }, [loanProduct?.id])

  useEffect(() => {
    onChangeAbilityToPayOverLoanTerm &&
      onChangeAbilityToPayOverLoanTerm(qualification?.totalLoanAmount || 0)
  }, [qualification?.totalLoanAmount])

  useEffect(() => {
    setTermInMonths(useLoanCase ? loanCase?.loanRegistrationTermInMonths : 0)
  }, [loanCase])

  async function loadLoan() {
    const { success, data } = await getData(
      '/loanProduct/find-loan-product-by-id',
      { loanProductId: loanProduct?.id },
      false
    )
    if (success) setSelectedLoanProduct(data?.loanProduct)
  }

  useEffect(() => {
    calculateLatestIncome()

    getBasicIncome()
    // eslint-disable-next-line
  }, [selectedLoanProduct])

  useEffect(() => {
    loadIncomeAdjustments()
  }, [customer, selectedLoanProduct, loanCase])

  useEffect(() => {
    let totals = { allowances: 0, deductions: 0, clearedEffects: 0 }
    for (let adjustment of incomeAdjustments) {
      // eslint-disable-next-line
      if (adjustment.type == 64222) {
        totals.allowances += +adjustment.amount
      }

      // eslint-disable-next-line
      if (adjustment.type == 64223) {
        totals.deductions += +adjustment.amount
      }

      // eslint-disable-next-line
      if (adjustment.type == 64224) {
        totals.clearedEffects += +adjustment.amount
      }
    }

    setIncomeTotals(totals)
    // eslint-disable-next-line
  }, [incomeAdjustments])

  useEffect(() => {
    onChangeTermInMonths(termInMonths)
    // eslint-disable-next-line
  }, [termInMonths])

  useEffect(() => {
    getTotalIncome()
    getTakeHomeRetention()
    // eslint-disable-next-line
  }, [basicIncome, incomeTotals])

  useEffect(() => {
    getAbilityToPayPerPeriod()
    // eslint-disable-next-line
  }, [basicIncome, takeHomeRetention, incomeTotals])

  useEffect(() => {
    evaluateLoanQualification()
    // eslint-disable-next-line
  }, [abilityToPayPerPeriod, termInMonths])

  function getTotalIncome() {
    if (selectedLoanProduct?.loanRegistrationLoanProductSection == 0) {
      setTotalIncome(basicIncome)
    } else if (selectedLoanProduct?.loanRegistrationLoanProductSection == 1) {
      setTotalIncome(
        basicIncome + incomeTotals.allowances - incomeTotals.deductions
      )
    }
  }

  function getBasicIncome() {
    setBasicIncome(
      selectedLoanProduct?.preloadAssessedLoaneeIncome
        ? selectedLoanProduct?.loanRegistrationIncomeAssessmentMode == 1
          ? +latestIncomeValue
          : +latestIncome
        : 0
    )
  }

  function getAbilityToPayPerPeriod() {
    let val =
      basicIncome -
      takeHomeRetention +
      +incomeTotals.allowances +
      incomeTotals.clearedEffects -
      incomeTotals.deductions
    setAbilityToPayPerPeriod(Math.max(0, val))
  }

  function getTakeHomeRetention() {
    let takeHomeType = selectedLoanProduct?.takeHomeType

    let takeHomePercentage = +selectedLoanProduct?.takeHomePercentage
    let takeHomeFixedAmount = +selectedLoanProduct?.takeHomeFixedAmount

    let takeHomeValue = 0

    if (takeHomeType == 1) {
      takeHomeValue = (basicIncome * takeHomePercentage) / 100
    } else if (takeHomeType == 2) {
      takeHomeValue = takeHomeFixedAmount
    }

    setTakeHomeRetention(takeHomeValue)
  }

  async function calculateLatestIncome() {
    setIsBusy(true)
    // eslint-disable-next-line
    if (selectedLoanProduct?.loanRegistrationIncomeAssessmentMode == 1) {
      const payload = await evaluateLoaneeIncome(
        customer?.id,
        selectedLoanProduct?.id
      )
      payload && setLatestIncomeValue(payload?.average)
    }
    setIsBusy(false)
  }

  async function evaluateLoanQualification() {
    setIsBusy(true)
    let qualification = await evaluateQualification(
      selectedLoanProduct?.loanInterestCalculationMode,
      termInMonths,
      selectedLoanProduct?.loanRegistrationPaymentFrequencyPerYear,
      selectedLoanProduct?.loanInterestAnnualPercentageRate,
      abilityToPayPerPeriod,
      selectedLoanProduct?.loanRegistrationRoundingType
    )

    if (qualification) {
      setQualification(qualification)
    }
    setIsBusy(false)
  }

  const incomeColumns = [
    { label: 'name', template: r => <>{r?.description}</> },
    { label: 'type', template: r => <>{r?.typeDescription}</> },
    {
      label: 'amount',
      template: r => (
        <FormulaInput
          style={{ maxWidth: 200 }}
          value={Number(r?.amount)}
          onChange={value => {
            let clonedAdj = [...incomeAdjustments]
            let foundIndex = clonedAdj.findIndex(x => x.id === r.id)
            let foundItem = clonedAdj[foundIndex]
            if (foundItem) {
              foundItem.amount = value
            }
            setIncomeAdjustments(clonedAdj)
          }}
        />
      ),
    },
  ]

  async function checkCustomerIsAdmin() {
    setIsBusy(true)
    let admin = await checkIfIsAdministrative(customer?.id)
    setCustomerIsAdministrative(admin)
    setIsBusy(false)
  }

  async function loadIncomeAdjustments() {
    setIsBusy(true)
    let iAdj = await evaluateEligibleIncomeDeductions(
      customer?.id,
      selectedLoanProduct?.id,
      loanCase?.id
    )
    setIncomeAdjustments(iAdj)
    setIsBusy(false)
  }

  const tabItems = [
    {
      label: localize('incomeAdjustments'),
      icon: 'uil uil-edit',
      item: isBusy ? (
        <Loader />
      ) : (
        <>
          {loanCase?.documentReferenceNumber} {loanCase?.id}
          <SimpleTable
            selectables
            columns={incomeColumns}
            data={incomeAdjustments}
          />
        </>
      ),
      forAll: true,
    },
    {
      label: localize('payoutsHistory'),
      item: (
        <CustomerPayoutHistory
          customer={customer}
          loanProduct={selectedLoanProduct}
          onGetPayoutHistories={v =>
            !customerIsAdministrative && setLatestIncome(v)
          }
        />
      ),
      forAll: true,
    },
    {
      label: localize('salaryHistory'),
      item: (
        <CustomerSalaryHistory
          customer={customer}
          loanProduct={selectedLoanProduct}
          onGetLatestIncome={v =>
            customerIsAdministrative && setLatestIncome(v)
          }
        />
      ),
      forAll: false,
    },
  ]

  useEffect(() => {
    onLoanSelected(selectedLoanProduct)
    // eslint-disable-next-line
  }, [selectedLoanProduct])

  useEffect(() => {
    checkCustomerIsAdmin()
    // eslint-disable-next-line
  }, [customer])

  return customer ? (
    <>
      {noLoanInfo || (
        <>
          <h4 className="text-capitalize mb-3 mt-2">{localize('loanInfo')}</h4>
          <LoanProductLookupPartial
            loanProduct={selectedLoanProduct}
            onLookup={l => setSelectedLoanProduct(l)}
            customer={customer}
            onChangeAmounts={() => {}}
            latestIncome={latestIncome}
            onChangeTerm={(term, check) => check && setTermInMonths(term)}
          />
        </>
      )}
      <h4 className="text-capitalize mb-3 mt-2">{localize('qualification')}</h4>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('latestIncome')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              //eslint-disable-next-line
              selectedLoanProduct?.loanRegistrationIncomeAssessmentMode == 1
                ? latestIncomeValue
                : latestIncome,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('incomeAssesmentMode')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={
              selectedLoanProduct?.loanRegistrationIncomeAssessmentModeDescription
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize(
              // eslint-disable-next-line
              selectedLoanProduct?.loanRegistrationLoanProductSection == 0
                ? 'netIncome'
                : 'basicIncome'
            )}
          </label>
          <FormulaInput
            useBlur
            onChange={value => setBasicIncome(+value)}
            value={basicIncome}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('totalAllowances')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(incomeTotals.allowances, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('totalDeductions')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(incomeTotals.deductions, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('totalClearedEffects')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(incomeTotals.clearedEffects, 2, 2, false)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('totalIncome')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(totalIncome, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('takeHomeValue')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(takeHomeRetention, 2, 2, false)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('abilityToPayPerPeriod')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(abilityToPayPerPeriod, 2, 2, false)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('abilityToPayOverLoanTerm')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              qualification?.totalLoanAmount || 0,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanPart')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              qualification?.totalPrincipalPayment || 0,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('interestPart')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              qualification?.totalInterestPayment || 0,
              2,
              2,
              false
            )}
          />
        </div>
      </div>
      <div className="mt-3">
        <Tab
          preload
          tabItems={
            customerIsAdministrative
              ? tabItems
              : tabItems.filter(x => x.forAll === true)
          }
        />
      </div>
    </>
  ) : (
    <>
      <h4 className="card-title">No Customer</h4>
      <p className="card-title-desc">
        You can only select a customer to proceed
      </p>
      <div className="">
        <div className="alert alert-danger fade show" role="alert">
          <h4 className="alert-heading">Customer Not Found!</h4>
          <p>Please select a customer in the previous page</p>
          <hr />
          <p className="mb-0">You can only select a customer to proceed</p>
        </div>
      </div>
    </>
  )
}

export default Qualification
