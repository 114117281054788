/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import Checkbox from '../Checkbox/Checkbox'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'
import NonBlockingLoader from '../Loaders/NonBlockingLoader'

function WorkstationLookup({ onSelect, displayValue, disabled }) {
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    updateDepth: false,
    traverseTree: false,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    {
      label: 'description',
      template: r => (
        <span
          dangerouslySetInnerHTML={{ __html: r?.indentedDescription }}
        ></span>
      ),
    },
    { label: 'type', template: r => <>{r?.typeDescription}</> },
    { label: 'depth', template: r => <>{r?.depth}</> },
    { label: 'remarks', template: r => <>{r?.remarks}</> },
    {
      label: 'isLocked' + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    { label: 'createdBy', template: r => <>{r?.createdBy}</> },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  async function loadData() {
    const url = '/lookup/Workstation/find-by-filter-in-page'

    setIsBusy(true)
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('duty/workstationLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3 relative">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center  my-2 col-4">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <Checkbox
                  id={'traverseTreeDutyWorkStations'}
                  checked={reqParams.traverseTree}
                  onCheck={value =>
                    setReqParams({
                      ...reqParams,
                      traverseTree: value,
                      updateDepth: value,
                    })
                  }
                />
                <label
                  htmlFor="traverseTreeDutyWorkStations"
                  className="text-capitalize me-2"
                >
                  {localize('traverseTree')} ?
                </label>
              </div>

              <form
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({
                    ...reqParams,
                    filterText: search,
                  })
                }}
                className="d-flex justify-content-end align-items-center col-md-4 col-lg-3 my-2"
              >
                <label className="text-capitalize" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  type="search"
                  className="form-control ms-3"
                  id="searchTable"
                />
                <button
                  type="submit"
                  className="btn btn-primary ms-1 text-capitalize"
                >
                  <i className="uil uil-filter"></i>
                </button>
              </form>
            </div>
            <div className=" py-1">
              <SimpleTable
                columns={columns}
                data={tableData}
                onSelect={r => {
                  setModalActive(false)
                  onSelect(r)
                }}
                canSelect
                onSort={(c, i) => handleSort(c, i)}
                sortedBy={sortedBy}
              />
            </div>
            <Pager
              itemsCount={data.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={5}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
            {isBusy && <NonBlockingLoader />}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default WorkstationLookup
