/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'

function CurrencyLookup({ onSelect, displayValue }) {
  return (
    <>
      <SimpleLookup
        title={localize('currencyLookup')}
        url="/lookup/Currency/find-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={[
          {
            label: localize('name'),
            template: r => <>{r.description}</>,
            sortBy: 'description',
          },
          {
            label: localize('symbol'),
            template: r => <>{r.symbol}</>,
          },
          {
            label: localize('isoCode'),
            template: r => <>{r.isoCode}</>,
            sortBy: 'isoCode',
          },
          {
            label: localize('isLocked'),
            template: r => (
              <span className="text-capitalize">{r.isLocked.toString()}</span>
            ),
          },
        ]}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default CurrencyLookup
