/** @format */

import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { getData, postData, putData } from '../../../../../../Helpers/webApi'
import { downloadBase64File } from '../../../../../../Helpers/blobs'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import NestedGroupedTable from '../../../../../../Components/Tables/NestedGroupedTable'
import { COLORS } from '../../../../../../Helpers/constants'

export const MergedStatementsEntries = ({
  selectedCustomer,
  selectedPrintingCustomerAccount,
  selectedCustomerAccount,
}) => {
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    text: '',
    journalEntryFilter: '2',
    tallyDebitsCredits: false,
    fetchJournalEntries: true,
    dateRequestsFilter: 15,
    customDate: false,
  })
  const [mergedEntries, setMergedEntries] = useState([])

  const [finishedPrinting, setFinishedPrinting] = useState(true)
  const [collapseExpand, setCollapseExpand] = useState(false)
  const [chargeForPrinting, setChargeForPrinting] = useState(false)

  const [loadingCustomerStatements, setLoadingCustomerStatements] =
    useState(false)
  const [search, setSearch] = useState('')
  const [finishedEmailing, setFinishedEmailing] = useState(false)

  const columns = [
    {
      label: localize('transactionDate'),
      template: r => <>{formatDate(r.journalCreatedDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.journalPrimaryDescription}</>,
    },
    { label: localize('debit'), template: r => <>{formatCurrency(r.debit)}</> },
    {
      label: localize('credit'),
      template: r => <>{formatCurrency(r.credit)}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    {
      label: localize('interestCharged'),
      template: r => <>{formatCurrency(r.interestDebit)}</>,
    },
    {
      label: localize('interestPaid'),
      template: r => <>{formatCurrency(r.interestCredit)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r.interestRunningBalance)}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r.journalTransactionCodeDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.journalSecondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.journalReference}</> },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.journalValueDate)}</>,
    },
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('localCurrency'),
      template: r => <>{r.journalLocalCurrencyDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.formattedCustomerAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerReference3}</>,
    },
  ]
  const [groupBy, setGroupBy] = useState({
    label: localize('productSection'),
    column: 'customerAccountProductSectionGroupingDescriptor',
  })
  const [secondaryGroupBy, setSecondaryGroupBy] = useState({
    label: localize('accountNumber'),
    column: 'customerAccountNumberGroupingDescriptor',
  })
  const fetchMergedStatements = async () => {
    setLoadingCustomerStatements(true)
    const { success, data } = await getData(
      '/JournalEntry/find-general-ledger-transactions-by-customer-id-and-date-range',
      {
        ...reqParams,
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )

    if (success) {
      setMergedEntries(data)
      setLoadingCustomerStatements(false)
    }
  }

  useEffect(() => {
    if (selectedCustomer) {
      fetchMergedStatements()
    }
  }, [selectedCustomer])

  const handleOnPrint = async () => {
    setFinishedPrinting(false)
    if (!selectedPrintingCustomerAccount && chargeForPrinting) {
      Swal.fire({
        icon: 'error',
        text: 'Select a customer account to be charged',
        title: 'Printing Customer Account Statement',
        showCloseButton: true,
      })
      setFinishedPrinting(true)
      return
    }

    const { success, data } = await postData(
      '/CustomerAccount/print-customer-account-statement',
      {
        selectedPrintingCustomerAccount:
          selectedPrintingCustomerAccount || selectedCustomerAccount
            ? selectedCustomerAccount
            : {},
        selectedCustomerAccount: selectedCustomerAccount || {},
        selectedCustomer: selectedCustomer,
        chargeForPrinting: chargeForPrinting,
        startDate: reqParams.customDate ? reqParams.startDate : new Date(),
        endDate: reqParams.customDate ? reqParams.endDate : new Date(),
        customDate: reqParams.customDate,
        dateRequestsFilter: reqParams.dateRequestsFilter,
      },
      false,
      null
    )
    if (success) {
      setFinishedPrinting(true)
      downloadBase64File(data.content, data.fileType)
    }
  }

  const handleOnEmail = () => {
    Swal.fire({
      title: 'Email Statement',
      text: 'Do you want to e-mail statement?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: COLORS.BLUE,
      cancelButtonColor: COLORS.RED,
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.isConfirmed) {
        sendEmail()
        setFinishedEmailing(true)
        showSuccessModal()
      }
    })
  }

  const showSuccessModal = () => {
    Swal.fire({
      icon: 'success',
      title: 'Operation Successful',
      text: 'Sending E-mail Statement',
    })
  }
  const sendEmail = async () => {
    setFinishedEmailing(false)
    if (!selectedCustomerAccount) {
      Swal.fire({
        icon: 'error',
        text: 'Select a customer account',
        title: 'Emailing Customer Account Statement',
        showCloseButton: true,
      })
      setFinishedEmailing(true)
      return
    }

    await putData(
      '/lookup/Utility/execute-on-demand-electronic-statement-order',
      {
        customerAccountDTOs: [selectedCustomerAccount],
        startDate: reqParams.customDate ? reqParams.startDate : new Date(),
        endDate: reqParams.customDate ? reqParams.endDate : new Date(),
      },
      false,
      null
    )
  }
  return (
    <>
      <div className="row p-2">
        <div className="bg-light p-2 m-1 mb-2" style={{ borderRadius: 5 }}>
          {finishedPrinting ? (
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize">
                {localize('pickDate')}
              </label>
              <DatePicker
                onSelect={v => {
                  if (v.custom) {
                    setReqParams({
                      ...reqParams,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                    })
                  } else {
                    setReqParams({
                      ...reqParams,
                      customDate: false,
                      dateRequestsFilter: v.value,
                    })
                  }
                }}
              />
              <EnumsServerCombo
                value={reqParams?.journalEntryFilter || ''}
                customContainerStyle={{ width: '100%' }}
                enumsKey={'JournalEntryFilter'}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    journalEntryFilter: e.value,
                    pageIndex: 0,
                  })
                }}
              />
              <form
                className="d-flex align-items-center col-3 col"
                onSubmit={e => {
                  e.preventDefault()
                  setReqParams({ ...reqParams, text: search })
                }}
              >
                <input
                  style={{ width: 200 }}
                  type="search"
                  className="form-control"
                  placeholder={localize('search') + '...'}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    fetchMergedStatements()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </form>
              <>
                <button onClick={handleOnPrint} className="btn btn-primary">
                  {localize('print')}
                </button>
                <button onClick={handleOnEmail} className="btn btn-primary">
                  {localize('email')}
                </button>
              </>
            </div>
          ) : (
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="m-3 spinner-border text-dark"></div>
              <span className="fs-4">Please Wait</span>
            </div>
          )}
        </div>
        {loadingCustomerStatements ? (
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="m-3 spinner-border text-dark"></div>
            <span className="fs-4">Please Wait</span>
          </div>
        ) : (
          <>
            <NestedGroupedTable
              columns={columns}
              data={mergedEntries}
              groupBy={groupBy}
              groupBySecondary={secondaryGroupBy}
            />
            <div className="d-flex align-content-end justify-content-end">
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id={'collapse-expand'}
                  checked={collapseExpand}
                  onCheck={v => setCollapseExpand(v)}
                />
                <label
                  htmlFor="collapse-expand"
                  className="text-capitalize ms-2"
                >
                  {localize('collapse/expand')}?
                </label>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
