/** @format */

import { TreeItem } from './TreeItem'
import { useState } from 'react'

export const AccessControlTree = ({
  data,
  handleOnSelectMenu,
  selectedNavigation,
  setSelectedNavigation,
  navigation
}) => {
  const [selectedMenu, setSelectedMenu] = useState(null)

  const onSelectMenu = r => {
    handleOnSelectMenu(r)
  }

  return (
    <ul className="tree">
      {data?.map((item, index) => (
        <TreeItem
          selectedNavigation={selectedNavigation}
          setSelectedNavigation={setSelectedNavigation}
          isSelected={selectedMenu === item.menuCode && selectedNavigation === item.navigationCode}
          onSelectMenu={onSelectMenu}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          key={index}
          item={item}
        />
      ))}
    </ul>
  )
}
