/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

const AssetTypeLookup = ({ displayValue, onSelect }) => {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('depreciation'),
      template: r => <>{r?.depreciationMethodDescription}</>,
    },
    {
      label: localize('usefulLife'),
      template: r => <>{r?.usefulLife}</>,
    },
    {
      label: localize('isTangible') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isTangible.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, true)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        title={localize('assetTypeLookup')}
        url={'/AssetType/find-asset-types-by-filter-in-page'}
        params={{
          text: '',
          pageIndex: 0,
          pageSize: 10,
        }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default AssetTypeLookup
