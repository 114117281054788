/** @format */

import { generateGuid } from '../extensions'
import { getData, postData } from '../webApi'

export async function evaluateLoaneeIncome(customerId, loanProductId) {
  const { success, data } = await getData(
    '/lookup/loanCase/evaluate-loanee-income',
    { customerId, loanProductId },
    false
  )
  if (success) {
    return data
  }
  return null
}

export async function evaluateLoanGuarantorCommitments(
  customerId,
  loanProductId,
  loaneeCustomerId
) {
  const { success, data } = await getData(
    '/lookup/loanCase/evaluate-loan-guarantor-commitments',
    { customerId, loanProductId, loaneeCustomerId },
    false
  )

  if (success) {
    return data
  }
}

export async function evaluateEligibleIncomeDeductions(
  customerId,
  loanProductId,
  loanCaseId
) {
  const { success, data } = await getData(
    '/lookup/loanCase/evaluate-eligible-income-deductions',
    { customerId, loanProductId, loanCaseId },
    false
  )
  if (success) {
    return data?.result
  }
  return null
}

export async function evaluateOffsetableLoans(customerId, loanProductId, loanCaseId) {
  const { success, data } = await getData(
    '/lookup/loanCase/evaluate-offsetable-loans',
    { customerId, loanProductId, loanCaseId },
    false
  )
  if (success) {
    return data?.result
  }
  return null
}

export async function evaluateQualification(
  interestCalculationMode,
  termInMonths,
  paymentFrequencyPerYear,
  APR,
  pmt,
  roundingType
) {
  const { success, data } = await getData(
    '/lookup/journal/evaluate-qualification',
    {
      interestCalculationMode,
      termInMonths,
      paymentFrequencyPerYear,
      APR,
      pmt,
      roundingType,
    },
    false
  )
  if (success) {
    return data
  }
  return null
}

export async function repaymentSchedule(
  termInMonths,
  paymentFrequencyPerYear,
  gracePeriod,
  interestCalculationMode,
  APR,
  PV,
  roundingType,
  dtDate,
  rebaseDueDate
) {
  const { success, data } = await getData(
    '/lookup/journal/repayment-schedule',
    {
      termInMonths,
      paymentFrequencyPerYear,
      gracePeriod,
      interestCalculationMode,
      APR,
      PV,
      roundingType,
      dtDate,
      rebaseDueDate,
    },
    false
  )
  if (success) {
    return data?.result
  }
  return null
}

export async function evaluateLoanCaseDeductions(loanCase, loanDisbursementMode, attachedLoans, loanCaseVoluntaryDeductibles,) {
  const { success, data } = await postData('/lookup/loanCase/evaluate-loan-case-deductions', { loanCase, loanDisbursementMode, attachedLoans, loanCaseVoluntaryDeductibles, }, false)
  if (success) {
    return data?.result
  }
  return null
}

export async function findLoanDisbursementModeByCustomer(customerId) {
  const { success, data } = await getData(
    '/lookup/loanCase/find-loan-disbursement-mode-by-customer-id',
    { customerId },
    false
  )
  if (success) {
    return (data)
  }
  return null
}

export function getSystemRecommendation(loanCase, abilityToPayOverLoanTerm, maximumEntitled, totalLoanCaseSecurity, strictlySelfGuarantee) {
  let options = []
  // * FOSA BLOCK
  if (loanCase?.loanRegistrationLoanProductSection == 0) {
    options.push([Number(loanCase?.amountApplied), "Based on Amount Applied"])
    options.push([Number(abilityToPayOverLoanTerm), "Based on Income Qualification"])

    if (!loanCase?.loanRegistrationMicrocredit) {
      if (loanCase?.LoanRegistrationConsiderInvestmentsBalanceForIncomeBasedLoanAppraisal) {
        options.push([Number(maximumEntitled), "Based on Deposits Qualification"])
      }
    }
  }
  // * BOSA Block
  else if (loanCase?.loanRegistrationLoanProductSection == 1) {
    options.push([Number(loanCase?.amountApplied), "Based on Amount Applied"])
    options.push([Number(abilityToPayOverLoanTerm), "Based on Income Qualification"])
    options.push([Number(totalLoanCaseSecurity), "Based on Security Qualification"])

    if (!loanCase.loanRegistrationMicrocredit) {
      if (!loanCase.loanRegistrationDisregardDepositsBalanceForInvestmentBasedLoanAppraisal) {
        if (!strictlySelfGuarantee) {
          options.push([Number(maximumEntitled), "Based on Deposits Qualification"])
        }
      }
    }
  }


  let orderedEnumerable = options.sort((a, b) => a[0] > b[0])
  let targetKVP = orderedEnumerable[0] || null
  if (targetKVP) {
    let systemRecommendation = (targetKVP[0] / 100) * 100

    systemRecommendation = (systemRecommendation < 0) ? 0 : systemRecommendation

    return { systemRecommendation, remarks: targetKVP[1] }
  } return { systemRecommendation: '--', remarks: '--' }

}
