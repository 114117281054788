/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../../Helpers/extensions'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import TransactionTypes from './TransactionTypes/TransactionTypes'

const CustomizeAlternateChannel = ({
  handleClose,
  channel,
  mode,
  fetchAlternateChannels,
  setChannel,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [transactionTypeEntries, setTransactionTypeEntries] = useState([])
  const [dailyLimit, setDailyLimit] = useState(0)
  const [transaction, setTransaction] = useState({})
  const [selectedEntries, setSelectedEntries] = useState([])
  const [isLocked, setIsLocked] = useState(false)
  const [isBusyLoadingEntries, setIsBusyLoadingEntries] = useState(false)

  const findAlternateChannelConfigurationOverrides = async () => {
    setIsBusyLoadingEntries(true)

    const url =
      '/AlternateChannelConfiguration/find-alternate-channel-configuration-entry-overrides-by-alternate-channel-id'

    const { success, data } = await getData(
      url,
      {
        alternateChannelConfigurationId: channel?.id,
      },
      false
    )

    if (success) {
      setTransactionTypeEntries(data?.result)
    }

    setIsBusyLoadingEntries(false)
  }

  const handleCustomizeChannel = async () => {
    setIsBusy(true)

    const url = '/AlternateChannel/update-alternate-channel'

    putData(
      url,
      {
        ...channel,
        dailyLimit,
        isLocked,
      },
      false
    ).then(() => {
      const url = `/AlternateChannelConfiguration/update-alternate-channel-configuration-entry-overrides?alternateChannelId=${channel?.id}`

      postData(
        url,
        [...transactionTypeEntries].map(t => ({
          ...t,
          alternateChannelId: channel?.id,
          alternateChannelType: channel?.type,
          alternateChannelIsLocked: isLocked,
          alternateChannelRecordStatus: channel?.recordStatus,
          transactionType: t?.transactionType,
          limit: t?.limit,
          isLocked: t?.isLocked,
          id: channel?.id,
        })),
        false
      )
        .then(() => {
          fetchAlternateChannels()
          handleClose()
          showNotification('Customize alternate channel', 'success')
          setChannel({})
          setDailyLimit(0.0)
          setTransactionTypeEntries([])
          setTransaction({})
          setSelectedEntries([])
          setIsLocked(false)
          handleClose()
        })

        .finally(() => {
          setIsBusy(false)
        })
    })
  }

  const handleAddEntries = () => {
    if (transaction?.limit === 0.0)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please enter a value greater than 0'
      )

    if (
      transactionTypeEntries?.find(
        t => t?.transactionType === transaction?.transactionType
      )
    )
      return showNotification(
        'Operation not allowed!',
        'error',
        'Transaction type already exists!'
      )

    const newTransactionTypeEntries = [
      ...transactionTypeEntries,
      {
        ...transaction,
        id: generateGuid(),
        isLocked: transaction?.isLocked ?? false,
      },
    ]

    setTransactionTypeEntries(newTransactionTypeEntries)
    setTransaction({
      ...transaction,
      isLocked: false,
      limit: 0.0,
    })
  }

  const handleLockUnlockTransactionType = () => {
    if (selectedEntries?.length === 0)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select an item to lock/unlock!'
      )

    const newTransactionTypeEntries = transactionTypeEntries.map(t => {
      if (selectedEntries?.find(s => s?.id === t?.id))
        return { ...t, isLocked: !t?.isLocked }

      return t
    })

    selectedEntries?.forEach(s => {
      const index = newTransactionTypeEntries.findIndex(t => t?.id === s?.id)

      const url = `/AlternateChannelConfiguration/toggle-alternate-channel-configuration-entry-override?alternateChannelConfigurationId=${newTransactionTypeEntries[index]?.id}`
      postData(url, {}, false).then(() => {
        showNotification('Lock/Unlock transaction type', 'success')
      }, false)
    })

    setTransactionTypeEntries(newTransactionTypeEntries)
    setSelectedEntries([])
  }

  const handleRemoveTransactionType = () => {
    if (selectedEntries?.length === 0)
      return showNotification(
        'Operation not allowed!',
        'error',
        'Please select an item to remove!'
      )

    const newTransactionTypeEntries = transactionTypeEntries.filter(
      t => !selectedEntries?.find(s => s?.id === t?.id)
    )

    setTransactionTypeEntries(newTransactionTypeEntries)
    setSelectedEntries([])
  }

  const handleSelectTransactionType = (transactionType, value) => {
    if (value) {
      if (selectedEntries?.find(t => t?.id === transactionType?.id))
        return showNotification(
          'Operation not allowed!',
          'error',
          'Transaction type already exists!'
        )

      setSelectedEntries([...selectedEntries, transactionType])
      setTransaction({
        ...transaction,
        isLocked: false,
        limit: 0.0,
      })
    } else {
      setSelectedEntries(
        selectedEntries.filter(t => t?.id !== transactionType?.id)
      )

      setTransaction({
        ...transaction,
        isLocked: false,
        limit: 0.0,
      })
    }
  }

  useEffect(() => {
    findAlternateChannelConfigurationOverrides()
    setDailyLimit(channel?.dailyLimit)
    setIsLocked(channel?.isLocked)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      modalTitle="customizeAlternateChannel"
      modalClassName="modal-xl"
      handleAction={handleCustomizeChannel}
      disableCreate={isBusy}
      disableClose={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              checked={isLocked}
              onCheck={value => setIsLocked(value)}
              id={'isLockedAlternateChannel'}
              disabled={isBusy}
            />

            <label
              className="text-capitalize"
              htmlFor={'isLockedAlternateChannel'}
            >
              {localize('isLocked') + ' '} ?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('alternateChannelType')}
              </label>
              <input
                className="form-control"
                type="text"
                disabled
                value={channel?.typeDescription}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('fullAccountNumber')}
              </label>
              <input
                className="form-control"
                type="text"
                disabled
                value={channel?.fullAccountNumber}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-4">{localize('pan')}</label>
              <input
                className="form-control"
                type="text"
                disabled
                value={channel?.maskedCardNumber}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('dailyLimit')}
              </label>
              <MaskNumber
                defaultMaskValue={channel?.dailyLimit}
                onMaskNumber={e => setDailyLimit(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('transactionTypeEntries'),
                  item: isBusyLoadingEntries ? (
                    <Loader />
                  ) : (
                    <TransactionTypes
                      transactionTypeEntries={transactionTypeEntries}
                      setTransactionTypeEntries={setTransactionTypeEntries}
                      transaction={transaction}
                      setTransaction={setTransaction}
                      channel={channel}
                      handleAddEntries={handleAddEntries}
                      handleLockUnlockTransactionType={
                        handleLockUnlockTransactionType
                      }
                      handleRemoveTransactionType={handleRemoveTransactionType}
                      handleSelectTransactionType={handleSelectTransactionType}
                      selectedEntries={selectedEntries}
                    />
                  ),
                  icon: 'uil uil-edit',
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CustomizeAlternateChannel
