/** @format */
import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import InvoiceEntriesItems from './InvoiceEntriesItems'

const ApproveInvoice = ({
  mode,
  closeModal,
  invoice,
  setInvoice,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [selectedAuth, setSelectedAuth] = useState(0)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/Invoice/find-invoices-items-by-invoice-id-filter-in-page`,
      {
        invoiceId: invoice?.workflowRecordId,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  const findInvoiceById = async () => {
    const { success, data } = await getData(
      `/Invoice/find-invoice?invoiceId=${invoice?.workflowRecordId}`,
      {},
      false
    )
    if (success) {
      setInvoice({ ...invoice, data })
    }
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [invoice?.workflowRecordId, reqParams.pageSize, reqParams.pageIndex])

  useEffect(() => {
    findInvoiceById()
    // eslint-disable-next-line
  }, [invoice?.workflowRecordId])

  const closePage = () => {
    closeModal()
    setGlobalValidationErrors({})
  }

  const approveinvoice = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/WorkFlow/approve-invoice-workflow-item',
      {
        ...invoice,
        approvalOption: selectedAuth,
        usedBiometrics: false,
      },
      false
    )

    if (success) {
      setInvoice(null)
      setIsBusy(false)
      closePage()
      loadTableData()
      showNotification('Approve Invoice', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={'approve'}
      modalTitle="invoice"
      handleClose={closePage}
      modalClassName="modal-xl"
      handleAction={approveinvoice}
      actionText={'approve'}
      footerChildren={
        <>
          <div
            className="d-flex align-items-center mb-1 with-validation px-4 mt-3"
            validation-message={
              globalValidationErrors['WorkflowItem.Remarks']?.message
            }
          >
            <label className="text-capitalize col-3">
              {localize('remarks')}
            </label>
            <input
              defaultValue={invoice?.remarks}
              type="text"
              className="form-control ms-2"
              onChange={e => {
                clearValidation('WorkflowItem.Remarks')
                setInvoice({
                  ...invoice,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center mb-2 px-4 mt-3">
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="WorkflowApprovalOption"
              value={selectedAuth}
              onChange={e => setSelectedAuth(e.value)}
            />
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control ms-2"
                disabled
                defaultValue={invoice?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-md-1 text-capitalize">
                {localize('supplier')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.supplierDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.supplierAddressMobileLine}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('creditPeriod')}
              </label>
              <input
                className="form-control"
                disabled
                value={invoice?.supplierCreditPeriod}
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-md-1 text-capitalize">
                {localize('invoiceDate')}
              </label>
              <input
                className="form-control ms-3"
                defaultValue={invoice?.invoiceDate}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={invoice?.branchDescription}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('createdBy')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={invoice?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-md-1 text-capitalize">
                {localize('createdDate')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={formatDate(invoice?.createdDate)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('invoiceNumber')}
              </label>
              <input
                className="form-control ms-3"
                value={invoice?.invoiceNumber}
                disabled
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize">{localize('document')}</label>
              <input
                className="form-control ms-3"
                value={invoice?.fileName}
                disabled
              />
            </div>

            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-md-1 text-capitalize">
                {localize('title')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.fileTitle}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.remarks}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center ">
              <label className="text-capitalize me-2">
                {localize('description')}
              </label>
              <textarea
                className="form-control"
                disabled
                value={invoice?.fileDescription}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-md-4 d-flex align-items-center mb-1 ">
              <label className="text-capitalize col-md-1 me-2">
                {localize('age')}
              </label>
              <input
                className="form-control ms-4"
                disabled
                defaultValue={invoice?.age}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center mb-2  ">
              <label className="col-md-1 text-capitalize me-2">
                {localize('daysRemaining')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.daysRemaining}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('invoiceItems'),
                  item: (
                    <InvoiceEntriesItems
                      mode={mode}
                      entries={existingEntries}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ApproveInvoice
