/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import {
  bankReconciliationAdjustmentType,
  bankReconciliationEntryStatus,
} from '../../../../../../Global/enumeration'
import { getTodaysDate, parseDate } from '../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import TransactionsTab from './TransactionsTab/TransactionsTab'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

const Discrepancies = ({ bankReconciliationPeriodId }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [discrepancy, setDiscrepancy] = useState({
    bankReconciliationAdjustmentType: Object.keys(
      bankReconciliationAdjustmentType
    )[0],
    bankReconciliationEntryStatus: Object.keys(
      bankReconciliationEntryStatus
    )[0],
    valueDate: parseDate(getTodaysDate()),
  })
  const [transactions, setTransactions] = useState([])
  const [selectedTransactions, setSelectedTransactions] = useState([])
  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const handleSelectTransaction = (transaction, value) => {
    if (value) {
      setSelectedTransactions([...selectedTransactions, transaction])
    } else {
      setSelectedTransactions(
        selectedTransactions.filter(x => x.id !== transaction.id)
      )
    }
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const fetchTransactions = async () => {
    setIsBusy(true)
    const url =
      '/BankReconciliationPeriod/find-bank-reconciliation-discrepancies-by-bank-reconciliation-period-id'

    const { data, success } = await getData(
      url,
      {
        bankReconciliationPeriodId,
      },
      false
    )

    if (success) {
      setTransactions(data?.result)
    }

    setIsBusy(false)
  }

  const handleCreate = async () => {
    if (!bankReconciliationPeriodId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a bank reconciliation period!',
      })

    setIsBusy(true)
    const url =
      '/BankReconciliationPeriod/create-bank-reconciliation-discrepancy'

    const { success } = await putData(
      url,
      {
        bankReconciliationPeriodId,
        adjustmentType: discrepancy.bankReconciliationAdjustmentType,
        amount: discrepancy?.amount,
        valueDate: discrepancy?.valueDate,
        remarks: discrepancy?.remarks,
      },
      false
    )

    if (success) {
      fetchTransactions()
      setDiscrepancy({
        bankReconciliationAdjustmentType: Object.keys(
          bankReconciliationAdjustmentType
        )[0],
        bankReconciliationEntryStatus: Object.keys(
          bankReconciliationEntryStatus
        )[0],
        valueDate: parseDate(getTodaysDate()),
      })
      showNotification('Create Bank Reconciliation Discrepancy', 'success')
    }
    setIsBusy(false)
  }

  const handleUpdate = async () => {
    if (!selectedTransactions.length)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a transaction!',
      })

    setIsBusy(true)
    const url = '/BankReconciliationPeriod/update-bank-reconciliation-entries'

    const { success } = await putData(
      url,
      {
        bankReconciliationEntries: selectedTransactions,
        bankReconciliationEntryStatus:
          discrepancy?.bankReconciliationEntryStatus,
      },
      false
    )

    if (success) {
      fetchTransactions()
      setSelectedTransactions([])
      showNotification('Update Bank Reconciliation Entries', 'success')
    }
    setIsBusy(false)
  }

  const evaluateValueDate = async () => {
    setIsBusy(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url, null, false)
    if (success && data.result) {
      setUpdateDate(false)
      setDiscrepancy({
        ...discrepancy,
        valueDate: parseDate(valueDate),
      })
    } else {
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (bankReconciliationPeriodId) fetchTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankReconciliationPeriodId])

  useEffect(() => {
    if (valueDate) evaluateValueDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="row g-3 my-3">
              <div className="col-md-4">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['AdjustmentType']?.message
                  }
                >
                  <label
                    htmlFor="adjustmentType"
                    className="text-capitalize col-4"
                  >
                    {localize('adjustmentType')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="BankReconciliationAdjustmentType"
                    value={discrepancy?.bankReconciliationAdjustmentType}
                    onChange={e => {
                      clearValidation('AdjustmentType')
                      setDiscrepancy({
                        ...discrepancy,
                        bankReconciliationAdjustmentType: e.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={globalValidationErrors['Amount']?.message}
                >
                  <label htmlFor="value" className="text-capitalize col-4">
                    {localize('value')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={discrepancy?.amount || 0}
                    onMaskNumber={e => {
                      clearValidation('Amount')
                      setDiscrepancy({
                        ...discrepancy,
                        amount: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['ValueDate']?.message
                  }
                >
                  <label htmlFor="valueDate" className="text-capitalize col-4">
                    {localize('valueDate')}
                  </label>
                  <CustomDatePicker
                    name="discrepancyValueDate"
                    defaultDate={valueDate}
                    onDateChange={date => {
                      setValueDate(date)
                    }}
                    setDateToNull={updateDate}
                    setUpdateDate={setUpdateDate}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="d-flex align-items-center with-validation"
                  validation-message={
                    globalValidationErrors['Remarks']?.message
                  }
                >
                  <label htmlFor="remarks" className="text-capitalize col-4">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={discrepancy?.remarks || ''}
                    onChange={e => {
                      clearValidation('Remarks')
                      setDiscrepancy({
                        ...discrepancy,
                        remarks: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row my-4 rounded mx-1">
              <div className="col bg-light p-2">
                <div className="d-flex align-items-center justify-content-end">
                  <button className="btn btn-success" onClick={handleCreate}>
                    {localize('create')}
                  </button>
                </div>
              </div>
            </div>

            <Tab
              tabItems={[
                {
                  label: localize('transactions'),
                  item: (
                    <TransactionsTab
                      transactions={transactions}
                      selectedTransactions={selectedTransactions}
                      handleSelectTransaction={handleSelectTransaction}
                    />
                  ),
                },
              ]}
            />

            <div className="row mt-3 bg-light mx-1 p-2 rounded">
              <div className="col d-flex align-items-center justify-content-end gap-2">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="bankReconciliationEntryStatus"
                    className="text-capitalize me-2"
                  >
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="BankReconciliationEntryStatus"
                    value={discrepancy?.bankReconciliationEntryStatus}
                    onChange={e => {
                      setDiscrepancy({
                        ...discrepancy,
                        bankReconciliationEntryStatus: e.value,
                      })
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <button className="btn btn-success" onClick={handleUpdate}>
                    {localize('update')}
                  </button>
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </>
  )
}

export default Discrepancies
