/** @format */

import React, { useEffect, useState } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { viewPDF } from '../../../../../../Helpers/Files/PDF'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../Helpers/webApi'
import SalaryPaySlipEntries from './SalaryPaySlipEntries'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'

const SalaryPaySlips = ({ paySlip }) => {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('cardNumber'),
      template: r => <>{r?.paddedSalaryCardCardNumber}</>,
    },
    {
      label: localize('salaryGroup'),
      template: r => <>{r?.salaryCardSalaryGroupDescription}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r?.salaryPeriodPostingPeriodDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r?.salaryPeriodMonthDescription}</>,
    },
    {
      label: localize('netPay'),
      template: r => <>{formatCurrency(r?.netPay)}</>,
    },
    {
      label: localize('salaryPeriodStatus'),
      template: r => <>{r?.salaryPeriodStatusDescription}</>,
    },
    {
      label: localize('employee'),
      template: r => <>{r?.salaryCardEmployeeCustomerFullName}</>,
    },
    {
      label: localize('account#'),
      template: r => <>{r?.salaryCardEmployeeCustomerReference1}</>,
    },
    {
      label: localize('membership#'),
      template: r => <>{r?.salaryCardEmployeeCustomerReference2}</>,
    },
    {
      label: localize('personalFile#'),
      template: r => <>{r?.salaryCardEmployeeCustomerReference3}</>,
    },
    {
      label: localize('nationality'),
      template: r => (
        <>{r?.salaryCardEmployeeCustomerIndividualNationalityDescription}</>
      ),
    },
    {
      label: localize('identityCardType'),
      template: r => (
        <>
          {r?.salaryCardEmployeeCustomerIndividualIdentityCardTypeDescription}
        </>
      ),
    },
    {
      label: localize('identityCardNumber'),
      template: r => (
        <>{r?.salaryCardEmployeeCustomerIndividualIdentityCardNumber}</>
      ),
    },
    {
      label: localize('gender'),
      template: r => (
        <>{r?.salaryCardEmployeeCustomerIndividualGenderDescription}</>
      ),
    },
    {
      label: localize('maritalStatus'),
      template: r => (
        <>{r?.salaryCardEmployeeCustomerIndividualMaritalStatusDescription}</>
      ),
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSalaryCardEmployeeCustomerSerialNumber}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r?.salaryCardEmployeeDesignationDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.salaryCardEmployeeBranchDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r?.salaryCardEmployeeDepartmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r?.salaryCardEmployeeEmployeeTypeDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r?.salaryCardEmployeeBloodGroupDescription}</>,
    },
    {
      label: localize('NSSFNumber'),
      template: r => (
        <>{r?.salaryCardEmployeeNationalSocialSecurityFundNumber}</>
      ),
    },
    {
      label: localize('NHIFNumber'),
      template: r => (
        <>{r?.salaryCardEmployeeNationalHospitalInsuranceFundNumber}</>
      ),
    },
    {
      label: localize('pin'),
      template: r => (
        <>{r?.salaryCardEmployeeCustomerPersonalIdentificationNumber}</>
      ),
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('isTaxExempt') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isTaxExempt?.toString())}
        </span>
      ),
    },
    {
      label: localize('taxExemption'),
      template: r => <>{formatCurrency(r?.taxExemption)}</>,
    },
    {
      label: localize('taxReliefAmount'),
      template: r => <>{formatCurrency(r?.taxReliefAmount)}</>,
    },
    {
      label: localize('insuranceReliefAmount'),
      template: r => <>{formatCurrency(r?.insuranceReliefAmount)}</>,
    },
    {
      label: localize('nhifReliefAmount'),
      template: r => <>{formatCurrency(r?.nHIFReliefAmount)}</>,
    },
    {
      label: localize('maximumProvidentFundReliefAmount'),
      template: r => <>{formatCurrency(r?.maximumProvidentFundReliefAmount)}</>,
    },
    {
      label: localize('maximumInsuranceReliefAmount'),
      template: r => <>{formatCurrency(r?.maximumInsuranceReliefAmount)}</>,
    },
    {
      label: localize('maximumNhifReliefAmount'),
      template: r => <>{formatCurrency(r?.maximumNHIFReliefAmount)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  const [entryMode, setEntryMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [paySlips, setPaySlips] = useState([])
  const [paySlipEntry, setPaySlipEntry] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 1,
    filterText: '',
    employeeFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [entryClicked, setEntryClicked] = useState(false)
  const [entriesCount, setEntriesCount] = useState(null)
  const [printOut, setPrintOut] = useState(null)
  const [refresh, setRefresh] = useState(false)

  const loadSalaryPaySlips = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/PaySlip/find-by-salary-id-and-filter-in-page`,
      { salaryPeriodId: paySlip?.id, ...reqParams },
      false
    )
    if (success) {
      setIsBusy(false)
      setPaySlips(data.pageCollection)
      setEntriesCount(data.itemsCount)
    } else {
      setIsBusy(false)
      setPaySlips([])
    }
  }

  useEffect(() => {
    loadSalaryPaySlips()
    // eslint-disable-next-line
  }, [paySlip?.id, reqParams.pageIndex, reqParams.pageSize, refresh])

  const close = () => {
    setEntryMode(null)
  }

  const prinPaySlip = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/Base64/print-payslip?payslipId=${r?.id}&payslipEntryId=${paySlip.id}`,
      {},
      false,
      null
    )
    if (success) {
      setPrintOut(
        `data:${data?.contentType ?? 'application/pdf'};base64,${data?.content}`
      )
    }
    setIsBusy(false)
  }

  const emailSlip = async r => {
    setIsBusy(true)
    const { success } = await putData(
      `/RecurringBatch/execute-electronic-pay-slip`,
      {
        r,
      },
      false
    )
    if (success) {
      showNotification('Salary Payslip', 'success')
    }
    setIsBusy(false)
  }

  const handleEmailingAction = async r => {
    if (r?.status !== '2') {
      return showNotification(
        'Salary Payslip',
        'info',
        'Sorry, but only a POSTED payslip can be emailed!'
      )
    } else if (r?.salaryCardEmployeeElectronicPayslipEnabled) {
      return showNotification(
        'Salary Payslip',
        'info',
        `Sorry, but electronic payslip is not enabled for ${r?.salaryCardEmployeeCustomerFullName}`
      )
    } else {
      setEntryMode('email')
      emailSlip(r)
    }
  }
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>
            <div className="d-flex align-item-center gap-2">
              <label className="me-1 text-capitalize">
                {localize('entries')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={entriesCount}
              />
            </div>

            <div className="d-flex align-items-center  gap-2">
              <label className="text-capitalize" htmlFor="searchBystatus">
                {localize('status')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="PaySlipStatus"
                value={reqParams?.status}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }
              />
            </div>

            <div className="d-flex align-item-center gap-2">
              <form
                className="d-flex align-items-center"
                onSubmit={e => {
                  e.preventDefault()
                  setRefresh(!refresh)
                }}
              >
                <label className="text-capitalize me-2" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="EmployeeFilter"
                  value={reqParams?.employeeFilter}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      employeeFilter: e.value,
                    })
                  }
                />
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  defaultValue={reqParams.filterText}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                      pageIndex: 0,
                    })
                  }
                />
              </form>
              <button
                id="salaryPayslipsFilterButton"
                className="btn btn-primary ms-1"
                onClick={() => setRefresh(!refresh)}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
          <SimpleTable
            columns={columns}
            data={paySlips}
            contextMenu={r => (
              <>
                <div
                  onClick={async () => {
                    setEntryMode('print')
                    prinPaySlip(r)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-print"></i>
                  {localize('print')}
                </div>
                <div
                  onClick={async () => {
                    handleEmailingAction(r)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-fast-mail"></i>
                  {localize('email')}
                </div>
                <div
                  onClick={async () => {
                    setEntryMode('view')
                    setPaySlipEntry(r)
                    setEntryClicked(true)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-eye"></i>
                  {localize('view')}
                </div>
              </>
            )}
          />
          <div className="row mb-2 ">
            <div className="col-md-12">
              <div className="d-flex align-items-center mb-2"></div>
              <Pager
                itemsCount={entriesCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </div>
          </div>
        </>
      )}
      {entryMode === 'view' && (
        <SalaryPaySlipEntries
          paySlip={paySlipEntry}
          entryClicked={entryClicked}
          mode={entryMode}
          close={close}
        />
      )}
      {printOut && viewPDF(printOut, false, () => setPrintOut(null), null)}
    </>
  )
}

export default SalaryPaySlips
