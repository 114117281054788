/** @format */

import Tab from '../../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../../Global/Localize'
import React from 'react'
import { CustomerReceiptTransaction } from './TransactionSection/CustomerReceiptTransaction'
import { TellerChartOfAccountStatementPreview } from '../TellerPreview/TellerChartOfAccountStatementPreview'
import { CustomerPreviewSection } from './TransactionSection/CustomerPreviewSection'
import { CustomerAccounts } from '../CustomerPreview/CustomerAccounts'

export const CustomerReceiptContainer = ({
  transactionModel,
  setTransactionModel,
  onAddApportionMent,
  onRemoveApportionment,
  resetEntries,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            preload
            tabItems={[
              {
                label: localize('transaction'),
                item: (
                  <CustomerReceiptTransaction
                    onRemoveApportionment={onRemoveApportionment}
                    resetEntries={resetEntries}
                    onAddApportionMent={onAddApportionMent}
                    transactionModel={transactionModel}
                    setTransactionModel={setTransactionModel}
                  />
                ),
              },
              {
                label: localize('tellerGlAccountStatement'),
                item: <TellerChartOfAccountStatementPreview />,
              },
              {
                label: localize('customerPreview'),
                item: (
                  <CustomerPreviewSection
                    selectedCustomer={transactionModel?.selectedCustomer}
                  />
                ),
              },
              {
                label: localize('customerAccounts'),
                item: (
                  <CustomerAccounts
                    selectedCustomer={transactionModel?.selectedCustomer}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
