/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _h = {
  heads: {
    en: 'Heads',
    sw: 'Vichwa Vya Mishahara',
  },
  humanResourceManagement: {
    en: 'human resource management',
    sw: 'usimamizi wa rasilimali',
  },
  holidays: {
    en: 'holidays',
    sw: 'likizo',
  },
  historyManagement: {
    en: 'History Management',
    sw: 'Usimamizi Wa Historia',
  },
  headerEntry: {
    en: 'Header Entry',
    sw: 'igizo la kichwa',
  },
  hudumaNumber: {
    en: 'Huduma Number',
    sw: 'nambari ya huduma',
  },
  hireDate: {
    en: 'hire date',
    sw: 'tarehe ya kuajiriwa',
  },
  hierarchies: {
    en: 'hierarchies',
    sw: 'madaraja',
  },
  hierachy: {
    en: 'hierachy',
    sw: 'daraja',
  },
  hasError: {
    en: 'has error',
    sw: 'kuna hitilafu',
  },
  highestDaysInArrears: {
    en: 'highest days in arrears',
    sw: 'siku za juu zilizopitwa na wakati',
  },
  hasFraud: {
    en: 'has fraud',
    sw: 'kuna udanganyifu',
  },
  helpDesk: {
    en: 'help desk',
    sw: 'dawati la msaada',
  },
  history: {
    en: 'history',
    sw: 'historia',
  },
  humanResources: {
    en: 'human resources',
    sw: 'rasilimali watu',
  },
  holiday: {
    en: 'holiday',
    sw: 'likizo',
  },
}
export default _h
