/** @format */

import { formatDate } from '../../../../Helpers/formatters'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'

export const OperationsACL = ({ operationsAcl }) => {
  const columns = [
    {
      label: localize('operation'),
      template: r => <>{r.systemPermissionTypeDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return <SimpleTable selectables columns={columns} data={operationsAcl} />
}
