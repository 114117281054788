/** @format */

import { postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { CreateQuotationModal } from './CreateQuotationModal'
import { useEffect, useState } from 'react'
import swal from 'sweetalert2'
import { ViewQuotationModal } from './ViewQuotationModal'
import { EditQuotationModal } from './EditQuotationModal'

function QuotationModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedQuotation,
  setSelectedQuotation,
}) {
  const [quotationEntries, setQuotationEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    if (mode === 'add') {
      return handleOnAddQuotationWithQuotationItems()
    }
    if (mode === 'edit') {
      return handleOnEditQuotationWithQuotationItems()
    }
  }

  useEffect(() => {
    setQuotationEntries([])
  }, [mode])


  const handleOnAddQuotationWithQuotationItems = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Quotation/add-quotation-with-quotation-items',
      {
        QuotationItems: quotationEntries,
        Quotation: { ...selectedQuotation, createdDate: new Date() },
        Base64Document: selectedQuotation?.document,
        FileName: selectedQuotation?.fileName,
        FileExtension: selectedQuotation?.fileExtension,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      swal.fire(
        'Create Quotation',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const handleOnEditQuotationWithQuotationItems = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Quotation/update-quotation-with-quotation-items',
      {
        QuotationItems: quotationEntries,
        Quotation: { ...selectedQuotation, createdDate: new Date() },
        Base64Document: selectedQuotation?.document,
        FileName: selectedQuotation?.fileName,
        FileExtension: selectedQuotation?.fileExtension,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      swal.fire(
        'Create Quotation',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('quotations')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            disableCreate={isBusy}
            hideUpdate={mode === 'view'}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateQuotationModal
                isBusy={isBusy}
                setIsBusy={setIsBusy}
                quotationEntries={quotationEntries}
                setQuotationEntries={setQuotationEntries}
                selectedQuotation={selectedQuotation}
                setSelectedQuotation={setSelectedQuotation}
              />
            )}
            {mode === 'view' && (
              <ViewQuotationModal
                selectedQuotation={selectedQuotation}
                setSelectedQuotation={setSelectedQuotation}
              />
            )}
            {mode === 'edit' && (
              <EditQuotationModal
                isBusy={isBusy}
                setIsBusy={setIsBusy}
                quotationEntries={quotationEntries}
                setQuotationEntries={setQuotationEntries}
                selectedQuotation={selectedQuotation}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default QuotationModal
