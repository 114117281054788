/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../Global/Localize'
import Checkbox from '../Checkbox/Checkbox'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from './Pager'
import SimpleTable from './SimpleTable'
import NonBlockingLoader from '../Loaders/NonBlockingLoader'

function SimpleTableWithSearchAndPaging({
  data,
  columns,
  onSearch,
  searchString,
  onPageNavigate,
  currentPage,
  pageSize,
  onPageSizeChanged,
  onSelect,
  isBusy,
  contextMenu,
  canClickOnce,
  onClickOnce,
  noSelect,
  hidePageSize,
  selectables,
  includeBalances,
  updateDepth,
  onUpdateDepth,
  onIncludeBalances,
  showIncludeBalances = true,
  showUpdateDepth = true,
}) {
  const [search, setSearch] = useState('')

  return (
    <div style={{ position: 'relative' }}>
      <div className="row">
        <div className="d-flex align-items-center col-3 text-capitalize">
          {hidePageSize || (
            <>
              <RecordsPerPage
                reqParams={{
                  pageSize: pageSize,
                }}
                setReqParams={p => {
                  onPageSizeChanged(p.pageSize)
                }}
              />
            </>
          )}
        </div>

        <div className="col-3 d-flex align-items-center gap-2">
          <Checkbox
            id="include-Balances-in-chart-of-accounts"
            checked={includeBalances}
            onCheck={value => onIncludeBalances(value)}
          />
          <label
            htmlFor="include-Balances-in-chart-of-accounts"
            className="text-capitalize"
          >
            {localize('includeBalances')} ?
          </label>
        </div>

        <div className="col-3 d-flex align-items-center gap-2">
          <Checkbox
            id="update-depth-in-chart-of-accounts"
            checked={updateDepth}
            onCheck={value => onUpdateDepth(value)}
          />
          <label
            htmlFor="update-depth-in-chart-of-accounts"
            className="text-capitalize"
          >
            {localize('updateDepth')} ?
          </label>
        </div>

        <form
          className="d-flex align-items-center col-3 col text-capitalize"
          onSubmit={e => {
            e.preventDefault()
            onSearch(search)
          }}
        >
          <label htmlFor="searchTable" className="text-capitalize">
            {localize('search')}
          </label>
          <input
            type="search"
            id="searchTable"
            className="form-control ms-2"
            defaultValue={searchString}
            onChange={e => setSearch(e.target.value)}
          ></input>
          <button
            type="button"
            className="btn btn-primary ms-2"
            onClick={() => {
              onSearch(search)
            }}
          >
            <i className="uil uil-filter"></i>
          </button>
        </form>
      </div>
      <div className="pt-2">
        <SimpleTable
          selectables={selectables}
          columns={columns}
          data={data?.pageCollection}
          canSelect
          onSelect={noSelect ? () => {} : onSelect}
          contextMenu={contextMenu}
          canClickOnce={canClickOnce}
          onClickOnce={onClickOnce}
        />
      </div>

      <div className="pt-2">
        <Pager
          itemsCount={data ? data?.itemsCount : 10}
          pageSize={pageSize}
          pageIndex={currentPage}
          pageBatchSize={5}
          onPageChange={p => onPageNavigate(p)}
        />
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default SimpleTableWithSearchAndPaging
