/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import BankLinkageLookup from '../../../../../../Components/Lookup/BankLinkageLookup'
import TellerCashRequisitionLookup from '../../../../../../Components/Lookup/TellerCashRequisitionLookup'
import TellerLookup from '../../../../../../Components/Lookup/TellerLookup'
import TreasuryLookup from '../../../../../../Components/Lookup/TreasuryLookup'
import TallyCounter from '../../../../../../Components/TallyCounter/TallyCounter'
import localize from '../../../../../../Global/Localize'
import {
  tellerCashRequisitionAuthStatus,
  treasuryTransactionType,
} from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { MONEY } from '../../../../../../Helpers/constants'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'

const TransactionDetails = ({
  treasuryTrailType,
  setTreasuryTrailType,
  handleSelectTreasury,
  handleSelectGLAccount,
}) => {
  const [total, setTotal] = useState(0)
  const [tally, setTally] = useState({})
  const [transaction, setTransaction] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [resetValues, setResetValues] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [currentTreasury, setCurrentTreasury] = useState({})
  const [cashRequisition, setCashRequisition] = useState({})

  const auth = useAuth()

  const fetchCurrentTreasury = async () => {
    const url = '/Treasury/find-treasury-branch-id'

    const { success, data } = await getData(
      url,
      {
        branchId: auth?.user?.profile.cbs_EmployeeBranchId,
      },
      false
    )

    if (success) {
      setTransaction({
        ...transaction,
        treasuryTrailSourceId: data?.id,
        treasuryTrailSourceDescription: data?.description,
        treasuryTrailDestinationDescription: null,
        treasuryTrailDestinationId: null,
      })

      setCurrentTreasury(data)
      handleSelectTreasury(data)
    }
  }

  const getTotal = total => {
    setTotal(total)
  }

  const getTally = tally => {
    setTally(tally)
  }

  const toggleVerifyModal = () => {
    setShowModal(!showModal)
  }

  const handleVerify = () => {
    if (!transaction?.treasuryTrailSourceId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select source treasury or bank first',
      })

    if (!transaction?.treasuryTrailDestinationId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select destination treasury or bank first',
      })

    if (!transaction?.primaryDescription)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter primary description',
      })

    if (!transaction?.secondaryDescription)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter secondary description',
      })

    if (!transaction?.reference)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter reference',
      })

    if (total === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter amount greater than 0',
      })

    toggleVerifyModal()
  }

  const handleSourceTreasuryLookup = treasury => {
    setTransaction({
      ...transaction,
      treasuryTrailSourceId: null,
      treasuryTrailSourceDescription: null,
    })

    if (treasury?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Treasury is locked',
      })

    setTransaction({
      ...transaction,
      treasuryTrailSourceId: treasury?.id,
      treasuryTrailSourceDescription: treasury?.description,
    })

    handleSelectTreasury(treasury)
  }

  const handleDestinationBankLinkageLookup = bankLinkage => {
    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: null,
      treasuryTrailDestinationDescription: null,
    })

    if (!transaction?.treasuryTrailSourceId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select source treasury first',
      })

    if (bankLinkage?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Bank linkage is locked',
      })

    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: bankLinkage?.id,
      treasuryTrailDestinationDescription: `${bankLinkage?.bankName} - ${bankLinkage?.bankBranchName} - ${bankLinkage?.branchDescription}`,
    })

    handleSelectGLAccount(bankLinkage)
  }

  const handleOriginBankLinkageLookup = bankLinkage => {
    setTransaction({
      ...transaction,
      treasuryTrailSourceId: null,
      treasuryTrailSourceDescription: null,
    })

    if (bankLinkage?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Bank linkage is locked',
      })

    setTransaction({
      ...transaction,
      treasuryTrailSourceId: bankLinkage?.id,
      treasuryTrailSourceDescription: `${bankLinkage?.bankName} - ${bankLinkage?.bankBranchName} - ${bankLinkage?.branchDescription}`,
    })
  }

  const handleDestinationTellerLookup = teller => {
    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: null,
      treasuryTrailDestinationDescription: null,
    })

    if (!transaction?.treasuryTrailSourceId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select source treasury first',
      })

    if (teller?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Teller is locked',
      })

    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: teller?.id,
      treasuryTrailDestinationDescription: teller?.description,
    })

    handleSelectGLAccount(teller)
  }

  const handleDestinationTreasuryLookup = treasury => {
    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: null,
      treasuryTrailDestinationDescription: null,
    })

    if (!transaction?.treasuryTrailSourceId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select source bank first',
      })

    if (treasury?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Treasury is locked',
      })

    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: treasury?.id,
      treasuryTrailDestinationDescription: treasury?.description,
    })

    handleSelectGLAccount(treasury)
  }

  const handleDestinationTreasuryToTreasuryLookup = treasury => {
    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: null,
      treasuryTrailDestinationDescription: null,
    })

    if (treasury?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Treasury is locked',
      })

    if (!transaction?.treasuryTrailSourceId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select source treasury first',
      })

    if (transaction?.treasuryTrailSourceId === treasury?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You cannot transfer money to the same treasury',
      })

    setTransaction({
      ...transaction,
      treasuryTrailDestinationId: treasury?.id,
      treasuryTrailDestinationDescription: treasury?.description,
    })
    handleSelectGLAccount(treasury)
  }

  const getTallyByTotal = money => {
    return tally[money] ? tally[money]?.tallyByTotal : 0.0
  }

  const handleUpdate = async () => {
    if (!transaction?.treasuryTrailSourceId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select source treasury or bank first',
      })

    if (!transaction?.treasuryTrailDestinationId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select destination treasury or bank first',
      })

    if (!transaction?.primaryDescription)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter primary description',
      })

    if (!transaction?.secondaryDescription)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter secondary description',
      })

    if (!transaction?.reference)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter reference',
      })

    if (total === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter amount greater than 0',
      })

    setIsBusy(true)

    const url = '/TreasuryManagementRequest/add-treasury-management-request'

    const { success } = await postData(
      url,
      {
        treasuryTransactionType: treasuryTrailType,
        treasuryTrailSourceId: transaction?.treasuryTrailSourceId,
        totalValue: total,
        treasuryTrailSourceDescription:
          transaction?.treasuryTrailSourceDescription,
        treasuryTrailDestinationId: transaction?.treasuryTrailDestinationId,
        treasuryTrailDestinationDescription:
          transaction?.treasuryTrailDestinationDescription,
        denominationOneThousandValue: getTallyByTotal(MONEY[0]?.name),
        denominationFiveHundredValue: getTallyByTotal(MONEY[1]?.name),
        denominationTwoHundredValue: getTallyByTotal(MONEY[2]?.name),
        denominationOneHundredValue: getTallyByTotal(MONEY[3]?.name),
        denominationFiftyValue: getTallyByTotal(MONEY[4]?.name),
        denominationFourtyValue: getTallyByTotal(MONEY[5]?.name),
        denominationTwentyValue: getTallyByTotal(MONEY[6]?.name),
        denominationTenValue: getTallyByTotal(MONEY[7]?.name),
        denominationFiveValue: getTallyByTotal(MONEY[8]?.name),
        denominationOneValue: getTallyByTotal(MONEY[9]?.name),
        denominationFiftyCentValue: getTallyByTotal(MONEY[10]?.name),
        primaryDescription: transaction?.primaryDescription,
        secondaryDescription: transaction?.secondaryDescription,
        reference: transaction?.reference,
      },
      false
    )

    if (success) {
      showNotification('treasuryCashTransfer', 'success')
      setResetValues(true)
      if (
        treasuryTrailType?.toString() ===
        Object.keys(treasuryTransactionType)[0]
      ) {
        setTransaction({
          ...transaction,
          treasuryTrailSourceId: null,
          treasuryTrailSourceDescription: null,
          treasuryTrailDestinationDescription: currentTreasury?.description,
          treasuryTrailDestinationId: currentTreasury?.id,
          reference: '',
          primaryDescription: '',
          secondaryDescription: '',
        })
      } else {
        setTransaction({
          ...transaction,
          treasuryTrailDestinationId: null,
          treasuryTrailDestinationDescription: null,
          reference: '',
          primaryDescription: '',
          secondaryDescription: '',
        })
      }
      setTotal(0)
      setTally({})
      setIsBusy(false)
      toggleVerifyModal()
    } else {
      setIsBusy(false)
    }
  }

  const handleDestinationCashRequisitionLookup = cashRequisition => {
    if (!transaction?.treasuryTrailSourceId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select source treasury first',
      })

    if (
      cashRequisition?.status !==
      Object.keys(tellerCashRequisitionAuthStatus)[1]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Sorry, you can only select  authorized cash requisition',
      })

    setCashRequisition(cashRequisition)
    setTransaction({
      ...transaction,
      primaryDescription: cashRequisition?.primaryDescription,
      secondaryDescription: cashRequisition?.secondaryDescription,
      reference: cashRequisition?.reference,
      treasuryTrailDestinationId: cashRequisition?.id,
      treasuryTrailDestinationDescription: cashRequisition?.tellerDescription,
    })
  }

  useEffect(() => {
    if (auth?.user?.profile.cbs_EmployeeBranchId) fetchCurrentTreasury()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <>
      {showModal && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleClose={toggleVerifyModal}
          modalTitle="treasuryCashTransfer"
          handleAction={handleUpdate}
          disableAction={isBusy}
        >
          {isBusy ? (
            <Loader />
          ) : (
            <h5>
              Do you want to proceed and create an authorization request for
              {' ' +
                Object.entries(treasuryTransactionType)?.filter(
                  ([key, value]) => key === treasuryTrailType?.toString()
                )[0][1]?.name +
                ' '}
              ?
            </h5>
          )}
        </ActionModal>
      )}

      <div className="row">
        <div className="col-md-5">
          <TallyCounter
            getTotal={getTotal}
            getTally={getTally}
            resetValues={resetValues}
            setResetValues={setResetValues}
            denomination={cashRequisition}
          />
        </div>
        <div className="col-md-7">
          <div className="row g-3">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-2 pe-2">
                  {localize('transactionType')}
                </label>
                <EnumsServerCombo
                  sort
                  value={treasuryTrailType}
                  customContainerStyle={{
                    width: '100%',
                  }}
                  enumsKey="TreasuryTransactionType"
                  onChange={({ value }) => {
                    setTreasuryTrailType(Number(value))

                    if (value === Object.keys(treasuryTransactionType)[0]) {
                      setTransaction({
                        ...transaction,
                        treasuryTrailSourceId: null,
                        treasuryTrailSourceDescription: null,
                        treasuryTrailDestinationId: currentTreasury?.id,
                        treasuryTrailDestinationDescription:
                          currentTreasury?.description,
                      })
                    } else {
                      setTransaction({
                        ...transaction,
                        treasuryTrailDestinationId: null,
                        treasuryTrailDestinationDescription: null,
                      })

                      fetchCurrentTreasury()
                    }
                  }}
                />
              </div>
            </div>
            {treasuryTrailType === 1 ? (
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('bank')}
                  </label>

                  <BankLinkageLookup
                    onSelect={handleOriginBankLinkageLookup}
                    displayValue={transaction?.treasuryTrailSourceDescription}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4 pe-2">
                    {localize('treasury')}
                  </label>

                  <TreasuryLookup
                    onSelect={handleSourceTreasuryLookup}
                    displayValue={transaction?.treasuryTrailSourceDescription}
                  />
                </div>
              </div>
            )}
            {treasuryTrailType === 2 ? (
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('bank')}
                  </label>

                  <BankLinkageLookup
                    onSelect={handleDestinationBankLinkageLookup}
                    displayValue={
                      transaction?.treasuryTrailDestinationDescription
                    }
                  />
                </div>
              </div>
            ) : treasuryTrailType === 4 ? (
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-3">
                    {localize('teller')}
                  </label>

                  <TellerLookup
                    onSelect={handleDestinationTellerLookup}
                    displayValue={
                      transaction?.treasuryTrailDestinationDescription
                    }
                  />
                </div>
              </div>
            ) : treasuryTrailType === 8 ? (
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('treasury') + ' ' + localize('(destination)')}
                  </label>

                  <TreasuryLookup
                    onSelect={handleDestinationTreasuryToTreasuryLookup}
                    displayValue={
                      transaction?.treasuryTrailDestinationDescription
                    }
                  />
                </div>
              </div>
            ) : treasuryTrailType === 1 ? (
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4 pe-2">
                    {localize('treasury')}
                  </label>

                  <TreasuryLookup
                    onSelect={handleDestinationTreasuryLookup}
                    displayValue={
                      transaction?.treasuryTrailDestinationDescription
                    }
                  />
                </div>
              </div>
            ) : (
              treasuryTrailType === 16 && (
                <div className="col-md-6">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4 pe-2">
                      {localize('teller')}
                    </label>
                    <TellerCashRequisitionLookup
                      displayValue={
                        transaction?.treasuryTrailDestinationDescription
                      }
                      onSelect={handleDestinationCashRequisitionLookup}
                    />
                  </div>
                </div>
              )
            )}
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('totalAmount')}
                </label>

                <div className={`form-control text-end bg-light`}>
                  {formatCurrency(total)}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('primaryDescription')}
                </label>

                <input
                  className={`form-control`}
                  value={transaction?.primaryDescription}
                  onChange={e =>
                    setTransaction({
                      ...transaction,
                      primaryDescription: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('secondaryDescription')}
                </label>

                <input
                  className={`form-control`}
                  value={transaction?.secondaryDescription}
                  onChange={e =>
                    setTransaction({
                      ...transaction,
                      secondaryDescription: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('reference')}
                </label>

                <input
                  className={`form-control`}
                  value={transaction?.reference}
                  onChange={e =>
                    setTransaction({
                      ...transaction,
                      reference: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mt-5 bg-light rounded py-2 px-2  d-flex justify-content-end">
              <button onClick={handleVerify} className="btn btn-success">
                {localize('update')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionDetails
