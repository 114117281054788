/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import { getData } from '../../../Helpers/webApi'
import { useEffect } from 'react'
import localize from '../../../Global/Localize'
import Checkbox from '../../Checkbox/Checkbox'
import SimpleTable from '../../Tables/SimpleTable'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../Helpers/formatters'
import Pager from '../../Tables/Pager'
import Loader from '../../Loaders/Loader'

const ListSavingsProducts = ({
  handleSelectSavings,
  hiddenColumns = [],
  handleUpdate,
  showUpdateButton = true,
  limitTableHeight,
  resetValues,
  setResetValues,
  actionText,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [savings, setSavings] = useState([])
  const [selectAllSavings, setSelectAllSavings] = useState(false)
  const [selectedSavings, setSelectedSavings] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedSavings.find(c => c.id === r.id)}
          onCheck={v => {
            if (v) {
              setSelectedSavings([...selectedSavings, r])
            } else {
              setSelectedSavings(selectedSavings.filter(c => c.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('code'),
      template: r => r.paddedCode,
    },
    {
      label: localize('name'),
      template: r => r.description,
    },
    {
      label: localize('minimumBalance'),
      template: r => formatCurrency(r.minimumBalance),
    },
    {
      label: localize('operatingBalance'),
      template: r => formatCurrency(r.operatingBalance),
    },
    {
      label: localize('annualPercentageYield'),
      template: r => formatAsPercent(r.annualPercentageYield),
    },
    {
      label: localize('maximumAllowedWithdrawal'),
      template: r => formatCurrency(r.maximumAllowedWithdrawal),
    },
    {
      label: localize('withdrawalNoticePeriod'),
      template: r => r.withdrawalNoticePeriod,
    },
    {
      label: localize('withdrawalNoticeAmount'),
      template: r => formatCurrency(r.withdrawalNoticeAmount),
    },
    {
      label: localize('withdrawalInterval'),
      template: r => r.withdrawalInterval,
    },
    {
      label: localize('g/LAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('gLAccountCostDistrRule'),
      template: r => r.chartOfAccountCostDistributionRuleDescription,
    },
    {
      label: localize('isDefault'),
      template: r => r.isDefault?.toString(),
    },
    {
      label: localize('isLocked'),
      template: r => r.isLocked?.toString(),
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const columnsToDisplay = columns?.filter(
    (c, i) => !hiddenColumns?.includes(i)
  )

  const fetchSavings = async () => {
    setIsBusy(true)
    const url = '/SavingsProduct/find-savings-product-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setSavings(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectAllSavings = (savings, v) => {
    if (v) {
      setSelectedSavings(savings)
    } else {
      setSelectedSavings([])
    }
  }

  useEffect(() => {
    fetchSavings()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    handleSelectSavings(selectedSavings)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSavings])

  useEffect(() => {
    if (resetValues) {
      setSelectedSavings([])
      setResetValues(false)
      setSelectAllSavings(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 py-3 px-2">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="recordsPerPage"
                  className="text-capitalize"
                  style={{ marginLeft: -10, textTransform: 'capitalize' }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  id="recordsPerPage"
                  className="form-select ms-3"
                  style={{ maxWidth: 'fit-content' }}
                  defaultValue={reqParams.pageSize}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 offset-md-5">
              <div className="d-flex align-items-center justify-content-end">
                <label className="text-capitalize me-2">
                  {localize('search')}
                </label>
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    if (e.target.value === '') {
                      fetchSavings()
                    }

                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchSavings()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchSavings()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              maxHeight: limitTableHeight ? '50dvh' : 'none',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable
              columns={columnsToDisplay}
              data={savings?.pageCollection}
            />
          </div>

          <div className="mb-2"></div>
          <Pager
            itemsCount={savings.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          <div className="py-3 row px-1 bg-light mt-3 mx-2 rounded">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start gap-3 align-items-center">
                <div className="d-flex align-items-center">
                  <Checkbox
                    checked={selectAllSavings}
                    onCheck={v => {
                      setSelectAllSavings(v)
                      handleSelectAllSavings(savings?.pageCollection, v)
                    }}
                    id="selectAllSavings"
                  />

                  <label
                    htmlFor="selectAllSavings"
                    className="ms-2 text-capitalize"
                  >
                    {localize('togglePageSelection')}
                  </label>
                </div>
              </div>

              {showUpdateButton && (
                <div className="d-flex align-items-center float-end">
                  <button
                    onClick={handleUpdate}
                    className="btn btn-success float-end"
                  >
                    {localize(actionText || 'update')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ListSavingsProducts
