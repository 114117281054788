/** @format */

import React from 'react'
import localize from '../../Global/Localize'

function NonBlockingLoader() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.5)',
        // backdropFilter: 'blur(2px)',
      }}
    >
      <div className="shadow shadow-lg bg-white p-2 gap-2 rounded d-flex align-items-center px-3">
        <i
          className="spinner-border spinner-border-sm"
          style={{ fontSize: 12 }}
        ></i>
        <span className="text-capitalize">{localize('pleaseWait')}</span>
      </div>
    </div>
  )
}

export default NonBlockingLoader
