/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../../../Global/Localize'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
function CreditInfo({ selectedCreditInformationQuery }) {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('averagePrincipalMobileLoansActive')}
          </label>
          <MaskNumber
            defaultMaskValue={
              selectedCreditInformationQuery?.averagePrincipalMobileLoansActive
            }
            className="form-control ms-2"
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('averagePrincipalMobileLoansClosed')}
          </label>
          <MaskNumber
            defaultMaskValue={
              selectedCreditInformationQuery?.averagePrincipalMobileLoansClosed
            }
            className="form-control ms-2"
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('genericAccountCount')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.genericAccountCount || ''}
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('genericAccountCountClosed')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.genericAccountCountClosed || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('genericAccountInArrearsCount')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.genericAccountInArrearsCount || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('genericAccountNPACount')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.genericAccountNPACount || ''}
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mobileAccountCountActive')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.mobileAccountCountActive || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mobileAccountCountClosed')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.mobileAccountCountClosed || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mobileAccountInArrearsCount')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery?.mobileAccountInArrearsCount || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4 d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('mobileAccountNPACount')}
          </label>
          <input
            className="form-control ms-2"
            value={selectedCreditInformationQuery?.mobileAccountNPACount || ''}
            disabled
          />
        </div>
        <div className="col-4 d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('totalMonthlyInstalmentGeneric')}
          </label>
          <MaskNumber
            defaultMaskValue={
              selectedCreditInformationQuery?.totalMonthlyInstalmentGeneric
            }
            className="form-control ms-2"
            disabled
          />
        </div>
      </div>
    </>
  )
}
export default CreditInfo
