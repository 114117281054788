/** @format */

import { createContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { getData } from '../Helpers/webApi'
// import Logo from '../assets/images/logo-light.png'

export const UserContext = createContext(null)

export function UserContextProvider({ children }) {
  const auth = useAuth()

  const [loggedInUser, setLoggedInUser] = useState(null)
  const [authenticated, setAuthenticated] = useState(false)
  const [locked, setLocked] = useState(false)
  const [underSize, setUnderSize] = useState(false)
  // const [isBusy, setIsBusy] = useState(false)
  // const [refreshAuth, setRefreshAuth] = useState(false)

  async function invalidateSessionKey() {
    await getData('/AccessControl/invalidate-session-key', false)
  }

  async function lockUser() {
    await getData('/AccessControl/lock-user', false, false, null, false)
  }

  async function handleLock() {
    await lockUser()
    setLocked(true)
  }

  function UnLock() {
    setLocked(false)
  }

  async function handleLogOut() {
    await invalidateSessionKey()
    setLocked(false)
    setAuthenticated(false)
    auth.signoutRedirect()
  }

  useEffect(() => {
    if (auth.isAuthenticated) {
      setLoggedInUser(auth?.user?.profile)
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
      setLoggedInUser(null)
    }
  }, [auth])

  function handleResize(e) {
    let win = e.target
    setUnderSize(win.innerWidth < 1000)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (underSize) {
    return (
      <div className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center gap-5">
        {/* <img src={Logo} alt="" style={{ width: '20vw' }} className="" /> */}
        <div className="text-primary d-flex bg-white shadow-lg align-items-center rounded gap-3 p-4">
          <i className="uil uil-exclamation-triangle fs-2"></i>
          <span>
            Please resize your screen. <br />
            Content may not fit properly.
          </span>
        </div>
      </div>
    )
  }

  if (auth.isLoading || !!auth.activeNavigator)
    return (
      <div className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center gap-5">
        {/* <img src={Logo} alt="" style={{ width: '20vw' }} className="" /> */}
        <div className="d-flex align-items-center justify-content-center gap-2 shadow shadow-lg p-2 px-3 rounded bg-white">
          <i
            className="spinner-border spinner-border-sm"
            style={{ fontSize: 12 }}
          ></i>
          <span className="text-capitalize">please wait</span>
        </div>
      </div>
    )

  if (!auth.isAuthenticated) {
    auth.signinRedirect()
    return <>Not Authenticated</>
  }

  if (auth.isAuthenticated)
    return (
      <>
        <UserContext.Provider
          value={{
            loggedInUser,
            locked,
            authenticated,
            setLoggedInUser,
            UnLock,
            handleLock,
            handleLogOut,
            auth,
          }}
        >
          {children}
        </UserContext.Provider>
      </>
    )
}
