/** @format */

import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const AccountMetadataStandingOrders = ({ loading, data }) => {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('trigger'),
      template: r => <>{r.triggerDescription}</>,
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r.beneficiaryFullAccountNumber}</>,
    },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r.beneficiaryProductDescription}</>,
    },
    {
      label: localize('beneficiaryAccountName'),
      template: r => <>{r.beneficiaryCustomerAccountAccountName}</>,
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r.durationStartDate)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r.durationEndDate)}</>,
    },
    {
      label: localize('frequency'),
      template: r => <>{r.scheduleFrequencyDescription}</>,
    },
    {
      label: localize('expectedRunDate'),
      template: r => <>{formatDate(r.scheduleExpectedRunDate)}</>,
    },
    {
      label: localize('actualRunDate'),
      template: r => <>{formatDate(r.scheduleActualRunDate)}</>,
    },
    {
      label: localize('disbursedDate'),
      template: r => (
        <>{formatDate(r.beneficiaryCustomerAccountLoanCaseDisbursedDate)}</>
      ),
    },
    {
      label: localize('disbursedAmount'),
      template: r => (
        <>
          {formatCurrency(r.beneficiaryCustomerAccountLoanCaseDisbursedAmount)}
        </>
      ),
    },
    {
      label: localize('paymentPerPeriod'),
      template: r => <>{formatCurrency(r.paymentPerPeriod)}</>,
    },
    {
      label: localize('princiapl'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    {
      label: localize('capitalizedInterest'),
      template: r => <>{formatCurrency(r.capitalizedInterest)}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{r.chargePercentage}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{r.chargeFixedAmount}</>,
    },
    {
      label: localize('beneficiaryCustomerName'),
      template: r => <>{r.beneficiaryCustomerAccountCustomerFullName}</>,
    },
    {
      label: `${localize('beneficiary')} ${localize('accountNumber')}`,
      template: r => <>{r.beneficiaryCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('beneficiary')} ${localize('memberNumber')}`,
      template: r => <>{r.beneficiaryCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('beneficiary')} ${localize('pfNumber')}`,
      template: r => <>{r.beneficiaryCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r.benefactorProductDescription}</>,
    },
    {
      label: localize('benefactorCustomerName'),
      template: r => <>{r.benefactorCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('benefactorAccountName'),
      template: r => <>{r.benefactorCustomerAccountAccountName}</>,
    },
    {
      label: `${localize('benefactor')} ${localize('accountNumber')}`,
      template: r => <>{r.benefactorCustomerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('benefactor')} ${localize('memberNumber')}`,
      template: r => <>{r.benefactorCustomerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('benefactor')} ${localize('pfNumber')}`,
      template: r => <>{r.benefactorCustomerAccountCustomerReference3}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('chargeable'),
      template: r => (
        <span className="text-capitalize">{r?.chargeable.toString()}</span>
      ),
    },
    { label: localize('isLocked'), template: r => <>{r.isLocked}</> },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable columns={columns} data={data} />
      )}
    </>
  )
}
