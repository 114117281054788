/** @format */

/* eslint-disable */
import React from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import LoanBatchEntries from '../../../../../../Components/ReUsables/BatchEntries/LoanBatchEntries/LoanBatchEntries'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'

const VerifyLoanBatch = ({
  loan,
  handleClose,
  mode,
  handleVerifyBatch,
  globalValidationErrors,
  clearValidation,
  setLoan,
  isBusy,
}) => {
  return (
    <>
      <ModalTemplate
        modalTitle="loanDisbursementBatch"
        cancelText="close"
        modalMode={mode}
        handleClose={handleClose}
        modalClassName="modal-xl"
        handleAction={handleVerifyBatch}
        disableClose={isBusy}
        disableCreate={isBusy}
        footerChildren={
          <div className="d-flex align-items-center justify-content-end gap-3">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors?.['AuditRemarks']?.message
              }
            >
              <label className="text-capitalize">{localize('remarks')}</label>
              <input
                type="text"
                className="form-control ms-2"
                disabled={isBusy}
                onChange={e => {
                  clearValidation('AuditRemarks')
                  setLoan({
                    ...loan,
                    auditRemarks: e.target.value,
                  })
                }}
              />
            </div>
            <EnumsServerCombo
              customContainerStyle={{
                maxWidth: 100,
                minWidth: 100,
              }}
              enumsKey="BatchAuditOption"
              disabled={isBusy}
              value={loan?.batchAuditOption}
              onChange={e => {
                setLoan({
                  ...loan,
                  batchAuditOption: e.value,
                })
              }}
              sort
            />
          </div>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row g-3">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('batchNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.paddedBatchNumber}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('batchTotal')}
                  </label>
                  <input
                    type="text"
                    className="form-control text-end"
                    value={formatCurrency(loan?.batchTotal, null, null, false)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('category')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.loanProductCategoryDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('branch')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.branchDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('disbursementMode')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.loanDisbursementModeDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('reference')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.reference}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('createdBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.createdBy}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('createdDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formatDate(loan?.createdDate)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-4">
                    {localize('status')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={loan?.statusDescription}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2  row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('batchEntries')}
              </div>
              <div className="row mt-4">
                <LoanBatchEntries
                  showSelect={false}
                  hiddenColumns={[0]}
                  loanDisbursementBatchId={loan?.id}
                  showView
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default VerifyLoanBatch
