/** @format */
import LoanRequests from '../../Pages/BackOffice/Operations/LoanOrigination/LoanRequests/LoanRequests'
import Registration from '../../Pages/BackOffice/Operations/LoanOrigination/Registration/Registration'
import Approval from '../../Pages/BackOffice/Operations/LoanOrigination/Approval/Approval'
import Verification from '../../Pages/BackOffice/Operations/LoanOrigination/Verification/Verification'
import Cancellation from '../../Pages/BackOffice/Operations/LoanOrigination/Cancellation/Cancellation'
import LoanApplications from '../../Pages/BackOffice/Operations/LoanOrigination/LoanApplications/LoanApplications'
import BatchVerification from '../../Pages/BackOffice/Operations/LoanDisbursement/BatchVerification/BatchVerification'
import BatchAuthorization from '../../Pages/BackOffice/Operations/LoanDisbursement/BatchAuthorization/BatchAuthorization'
import DisbursementQueues from '../../Pages/BackOffice/Operations/LoanDisbursement/DisbursementQueues/DisbursementQueues'
import BatchOrigination from '../../Pages/BackOffice/Operations/LoanDisbursement/BatchOrigination/BatchOrigination'
import RestructuringVerification from '../../Pages/BackOffice/Operations/LoanRestructuring/Verification/Verification'
import RestructuringApproval from '../../Pages/BackOffice/Operations/LoanRestructuring/Approval/Approval'
import RestructuringCatalogue from '../../Pages/BackOffice/Operations/LoanRestructuring/Catalogue/Catalogue'
import ConsolidationAndNettingOff from '../../Pages/BackOffice/Operations/GuarantorsManagement/Consolidation&NettingOff/Consolidation&NettingOff'
import Attachment from '../../Pages/BackOffice/Operations/GuarantorsManagement/Attachment/Attachment'
import Substitution from '../../Pages/BackOffice/Operations/GuarantorsManagement/Substitution/Substitution'
import Relieving from '../../Pages/BackOffice/Operations/GuarantorsManagement/Relieving/Relieving'
import Management from '../../Pages/BackOffice/Operations/GuarantorsManagement/Management/Management'
import Releasing from '../../Pages/BackOffice/Operations/GuarantorsManagement/Releasing/Releasing'
import Periods from '../../Pages/BackOffice/Operations/CheckOffDataProcessing/Periods/Periods'
import Processing from '../../Pages/BackOffice/Operations/CheckOffDataProcessing/Processing/Processing'
import Closing from '../../Pages/BackOffice/Operations/CheckOffDataProcessing/Closing/Closing'
import CheckoffDataCatalogue from '../../Pages/BackOffice/Operations/CheckOffDataProcessing/Catalogue/Catalogue'
import LoanInterestCharging from '../../Pages/BackOffice/Operations/RecurringProcedures/LoanInterestCharging/LoanInterestCharging'
import LoanInterestChargingAdHoc from '../../Pages/BackOffice/Operations/RecurringProcedures/LoanInterestCharging(AdHoc)/LoanInterestCharging(AdHoc)'
import IndefiniteLoanCharges from '../../Pages/BackOffice/Operations/RecurringProcedures/IndefiniteLoanCharges/IndefiniteLoanCharges'
import LoanInterestAdjustment from '../../Pages/BackOffice/Operations/RecurringProcedures/LoanInterestAdjustment/LoanInterestAdjustment'
import LoanArrearagesRecovery from '../../Pages/BackOffice/Operations/RecurringProcedures/LoanArrearagesRecovery/LoanArrearagesRecovery'
import LoanRecoveryExceptions from '../../Pages/BackOffice/Operations/RecurringProcedures/LoanRecoveryExceptions/LoanRecoveryExceptions'
import CarryForwardsAdjustment from '../../Pages/BackOffice/Operations/RecurringProcedures/CarryForwardsAdjustment/CarryForwardsAdjustment'
import CarryForwardsInstallments from '../../Pages/BackOffice/Operations/RecurringProcedures/CarryForwardsInstallments/CarryForwardsInstallments'
import LoanAgingSpools from '../../Pages/BackOffice/Operations/RecurringProcedures/LoanAgingSpools/LoanAgingSpools'
import IntraAccountLoanTransfer from '../../Pages/BackOffice/Operations/RecurringProcedures/intraAccountLoanTransfer/intraAccountLoanTransfer'
import LoanCaseLinkage from '../../Pages/BackOffice/Operations/RecurringProcedures/LoanCaseLinkage/LoanCaseLinkage'
import RefundsProcessingApproval from '../../Pages/BackOffice/Operations/RefundsProcessing/Approval/Approval'
import RefundsProcessingVerification from '../../Pages/BackOffice/Operations/RefundsProcessing/Verification/Verification'
import RefundsProcessingSettlementBatchOrigination from '../../Pages/BackOffice/Operations/RefundsProcessing/Settlement/BatchOrigination/BatchOrigination'
import RefundsProcessingSettlementBatchVerification from '../../Pages/BackOffice/Operations/RefundsProcessing/Settlement/BatchVerification/BatchVerification'
import RefundsProcessingSettlementBatchAuthorization from '../../Pages/BackOffice/Operations/RefundsProcessing/Settlement/BatchAuthorization/BatchAuthorization'
import IdentityVerification from '../../Pages/BackOffice/Operations/CreditReferenceBureau/Querying/IdentityVerification/IdentityVerification'
import DelinquencyStatus from '../../Pages/BackOffice/Operations/CreditReferenceBureau/Querying/DelinquencyStatus/DelinquencyStatus'
import ConsumerScore from '../../Pages/BackOffice/Operations/CreditReferenceBureau/Querying/ConsumerScore/ConsumerScore'
import IdentityScrub from '../../Pages/BackOffice/Operations/CreditReferenceBureau/Querying/IdentityScrub/IdentityScrub'
import CreditReport from '../../Pages/BackOffice/Operations/CreditReferenceBureau/Querying/CreditReport/CreditReport'
import CreditInformation from '../../Pages/BackOffice/Operations/CreditReferenceBureau/Querying/CreditInformation/CreditInformation'
import CreditReferenceBureauSubmission from '../../Pages/BackOffice/Operations/CreditReferenceBureau/Submission/Submission'
import RefundsProcessingCatalogue from '../../Pages/BackOffice/Operations/RefundsProcessing/Catalogue/RefundsProcessingCatalogue'
import Prequalification2 from '../../Pages/BackOffice/Operations/LoanOrigination/PreQualification/Prequalification2'
import AppraisalServerSide from '../../Pages/BackOffice/Operations/LoanOrigination/AppraisalServerSide/AppraisalServerSide'
import LoanPurposes from '../../Pages/BackOffice/Setups/LoanPurposes/LoanPurposes'
import LoaningRemarks from '../../Pages/BackOffice/Setups/LoaningRemarks/LoaningRemarks'
import IncomeAdjustments from '../../Pages/BackOffice/Setups/IncomeAdjustments/IncomeAdjustments'
import LoanRepaymentModes from '../../Pages/BackOffice/Setups/LoanRepaymentModes/LoanRepaymentModes'
import RefundTypes from '../../Pages/BackOffice/Setups/RefundTypes/RefundTypes'
import RiskClassificationModes from '../../Pages/BackOffice/Setups/RiskClassificationModes/RiskClassificationModes'
import DataAttachmentRemarks from '../../Pages/BackOffice/Setups/DataAttachmentRemarks/DataAttachmentRemarks'
import LoanDisbursementModes from '../../Pages/BackOffice/Setups/LoanDisbursementModes/LoanDisbursementModes'

const backOfficeMenus = [
  {
    iconClass: 'uil uil-horizontal-align-left',
    label: 'creditManagement',
    code: 5000,
    setup: [
      {
        label: 'loanPurposes',
        component: <LoanPurposes />,
        code: 5001,
        parentCode: 5000,
      },
      {
        label: 'incomeAdjustments',
        component: <IncomeAdjustments />,
        code: 5002,
        parentCode: 5000,
      },
      {
        label: 'loaningRemarks',
        component: <LoaningRemarks />,
        code: 5003,
        parentCode: 5000,
      },
      {
        label: 'dataAttachmentRemarks',
        component: <DataAttachmentRemarks />,
        code: 5004,
        parentCode: 5000,
      },
      {
        label: 'loanDisbursementModes',
        component: <LoanDisbursementModes />,
        code: 5005,
        parentCode: 5000,
      },
      {
        label: 'refundTypes',
        component: <RefundTypes />,
        code: 5006,
        parentCode: 5000,
      },
      {
        label: 'riskClassificationModes',
        component: <RiskClassificationModes />,
        code: 5007,
        parentCode: 5000,
      },
      {
        label: 'loanRepaymentModes',
        component: <LoanRepaymentModes />,
        code: 5008,
        parentCode: 5000,
      },
    ],
    operations: [
      {
        label: 'loanOrigination',
        children: [
          {
            label: 'pre-qualification',
            component: <Prequalification2 menuCode={5501} />,
            parentCode: 5500,
            code: 5501,
            description: 'loanPre-qualification',
          },
          {
            label: 'loanRequests',
            component: <LoanRequests menuCode={5502} />,
            parentCode: 5500,
            code: 5502,
            description: 'loanRequests',
          },
          {
            label: 'registration',
            component: <Registration menuCode={5503} />,
            parentCode: 5500,
            code: 5503,
            description: 'loanRegistration',
          },
          {
            label: 'appraisal',
            component: <AppraisalServerSide />,
            parentCode: 5500,
            code: 5504,
            description: 'loanAppraisal',
          },
          {
            label: 'approval',
            component: <Approval menuCode={5505} />,
            parentCode: 5500,
            code: 5505,
            description: 'loanApproval',
          },
          {
            label: 'verification',
            component: <Verification menuCode={5506} />,
            parentCode: 5500,
            code: 5506,
            description: 'loanVerification',
          },
          {
            label: 'cancellation',
            component: <Cancellation menuCode={5507} />,
            parentCode: 5500,
            code: 5507,
            description: 'loanCancellation',
          },
          {
            label: 'loanApplications',
            component: <LoanApplications menuCode={5508} />,
            parentCode: 5500,
            code: 5508,
            description: 'loanApplications',
          },
        ],
      },
      {
        label: 'loanDisbursement',
        children: [
          {
            label: 'batchOrigination',
            component: <BatchOrigination menuCode={5510} />,
            parentCode: 5510,
            code: 5510,
            description: 'batchOrigination',
          },
          {
            label: 'batchVerification',
            component: <BatchVerification menuCode={5511} />,
            parentCode: 5509,
            code: 5511,
            description: 'batchVerification',
          },
          {
            label: 'batchAuthorization',
            component: <BatchAuthorization menuCode={5512} />,
            parentCode: 5509,
            code: 5512,
            description: 'batchAuthorization',
          },
          {
            label: 'disbursementQueues',
            component: <DisbursementQueues menuCode={5513} />,
            parentCode: 5509,
            code: 5513,
            description: 'disbursementQueues',
          },
        ],
      },
      {
        label: 'LoanRestructuring',
        children: [
          {
            label: 'catalogue',
            component: <RestructuringCatalogue menuCode={5514} />,
            parentCode: 5514,
            code: 5515,
            description: 'loanRescheduleRequests',
          },
          {
            label: 'verification',
            component: <RestructuringVerification menuCode={5515} />,
            parentCode: 5514,
            code: 5516,
            description: 'verifyLoanRescheduleRequest',
          },
          {
            label: 'approval',
            component: <RestructuringApproval menuCode={5517} />,
            parentCode: 5514,
            code: 5517,
            description: 'approveLoanRescheduleRequest',
          },
        ],
      },
      {
        label: 'guarantorsManagement',
        parentCode: 0,
        children: [
          {
            label: 'consolidation&NettingOff',
            component: <ConsolidationAndNettingOff menuCode={5519} />,
            parentCode: 5518,
            code: 5519,
            description: 'fundsConsolidation&LoansNettingOff',
          },
          {
            label: 'attachment',
            component: <Attachment menuCode={5520} />,
            parentCode: 5518,
            code: 5520,
            description: 'guarantorAttachment',
          },
          {
            label: 'substitution',
            component: <Substitution menuCode={5521} />,
            parentCode: 5518,
            code: 5521,
            description: 'guarantorSubstitution',
          },
          {
            label: 'relievingReinstating',
            component: <Relieving menuCode={5522} />,
            parentCode: 5518,
            code: 5522,
            description: 'guarantorRelieving&Reinstating',
          },
          {
            label: 'management',
            component: <Management menuCode={5523} />,
            parentCode: 5518,
            code: 5523,
            description: 'guarantorManagement',
          },
          {
            label: 'releasing',
            component: <Releasing menuCode={5524} />,
            parentCode: 5518,
            code: 5524,
            description: 'guarantorReleasing',
          },
        ],
      },
      {
        label: 'checkOffDataProcessing',
        parentCode: 0,
        children: [
          {
            label: 'periods',
            component: <Periods menuCode={5526} />,
            parentCode: 5525,
            code: 5526,
            description: 'checkOffPeriods',
          },
          {
            label: 'processing',
            component: <Processing menuCode={5527} />,
            parentCode: 5525,
            code: 5527,
            description: 'checkOffProcessing',
          },
          {
            label: 'closing',
            component: <Closing menuCode={5528} />,
            parentCode: 5525,
            code: 5528,
            description: 'checkOffDataPeriodClosing',
          },
          {
            label: 'catalogue',
            component: <CheckoffDataCatalogue menuCode={5529} />,
            parentCode: 5525,
            code: 5529,
            description: 'checkOffDataCatalogue',
          },
        ],
      },
      {
        label: 'recurringProcedures',
        parentCode: 0,
        children: [
          {
            label: 'loanInterestCharging',
            component: <LoanInterestCharging menuCode={5531} />,
            parentCode: 5530,
            code: 5531,
            description: 'loanInterestCharging',
          },
          {
            label: 'loanInterestChargingAdHoc',
            component: <LoanInterestChargingAdHoc menuCode={5532} />,
            parentCode: 5530,
            code: 5532,
            description: 'loanInterestChargingAdHoc',
          },
          {
            label: 'indefiniteLoanCharges',
            component: <IndefiniteLoanCharges menuCode={5533} />,
            parentCode: 5530,
            code: 5533,
            description: 'indefiniteLoanCharges',
          },
          {
            label: 'loanInterestAdjustment',
            component: <LoanInterestAdjustment menuCode={5534} />,
            parentCode: 5530,
            code: 5534,
            description: 'loanInterestAdjustment',
          },
          {
            label: 'loanArrearagesRecovery',
            component: <LoanArrearagesRecovery menuCode={5535} />,
            parentCode: 5530,
            code: 5535,
            description: 'loanArrearagesRecovery',
          },
          {
            label: 'loanRecoveryExceptions',
            component: <LoanRecoveryExceptions menuCode={5536} />,
            parentCode: 5530,
            code: 5536,
            description: 'loanRecoveryExceptions',
          },
          {
            label: 'carryForwardsAdjustment',
            component: <CarryForwardsAdjustment menuCode={5537} />,
            parentCode: 5530,
            code: 5537,
            description: 'carryForwardsAdjustment',
          },
          {
            label: 'carryForwardsInstallments',
            component: <CarryForwardsInstallments menuCode={5538} />,
            parentCode: 5530,
            code: 5538,
            description: 'carryForwardsInstallments',
          },
          {
            label: 'loanAgingSpools',
            component: <LoanAgingSpools menuCode={5539} />,
            parentCode: 5530,
            code: 5539,
            description: 'loanAgingSpools',
          },
          {
            label: 'intraAccountLoanTransfer',
            component: <IntraAccountLoanTransfer menuCode={5540} />,
            parentCode: 5530,
            code: 5540,
            description: 'intraAccountLoanTransfer',
          },
          {
            label: 'loanCaseLinkage',
            component: <LoanCaseLinkage menuCode={5541} />,
            parentCode: 5530,
            code: 5541,
            description: 'loanCaseLinkage',
          },
        ],
      },
      {
        label: 'refundsProcessing',
        parentCode: 0,
        children: [
          {
            label: 'catalogue',
            component: <RefundsProcessingCatalogue menuCode={5543} />,
            parentCode: 0,
            code: 5543,
            description: 'refundsProcessingCatalogue',
          },
          {
            label: 'approval',
            component: <RefundsProcessingApproval menuCode={5544} />,
            parentCode: 5542,
            code: 5544,
            description: 'approveRefundsProcessing',
          },
          {
            label: 'verification',
            component: <RefundsProcessingVerification menuCode={5545} />,
            parentCode: 5542,
            code: 5545,
            description: 'verifyRefundsProcessing',
          },
          {
            label: 'settlement',
            children: [
              {
                label: 'batchOrigination',
                component: (
                  <RefundsProcessingSettlementBatchOrigination
                    menuCode={5547}
                  />
                ),
                parentCode: 5546,
                code: 5547,
                description: 'settlementBatchOrigination',
              },
              {
                label: 'batchVerification',
                component: (
                  <RefundsProcessingSettlementBatchVerification
                    menuCode={5548}
                  />
                ),
                parentCode: 5546,
                code: 5548,
                description: 'settlementBatchVerification',
              },
              {
                label: 'batchAuthorization',
                component: (
                  <RefundsProcessingSettlementBatchAuthorization
                    menuCode={5549}
                  />
                ),
                parentCode: 5546,
                code: 5549,
                description: 'settlementBatchAuthorization',
              },
            ],
          },
        ],
      },
      {
        label: 'creditReferenceBureau',
        parentCode: 0,
        children: [
          {
            label: 'querying',
            children: [
              {
                label: 'identityVerification',
                component: <IdentityVerification menuCode={5552} />,
                parentCode: 5551,
                code: 5552,
                description: 'identityVerification',
              },
              {
                label: 'delinquencyStatus',
                component: <DelinquencyStatus menuCode={5553} />,
                parentCode: 5551,
                code: 5553,
                description: 'delinquencyStatus',
              },
              {
                label: 'consumerScore',
                component: <ConsumerScore menuCode={5554} />,
                parentCode: 5551,
                code: 5554,
                description: 'consumerScore',
              },
              {
                label: 'identityScrub',
                component: <IdentityScrub menuCode={5555} />,
                parentCode: 5551,
                code: 5555,
                description: 'identityScrub',
              },
              {
                label: 'creditReport',
                component: <CreditReport menuCode={5556} />,
                parentCode: 5551,
                code: 5556,
                description: 'creditReport',
              },
              {
                label: 'creditInformation',
                component: <CreditInformation menuCode={5557} />,
                parentCode: 5551,
                code: 5557,
                description: 'creditInformation',
              },
            ],
          },
          {
            label: 'submission',
            component: <CreditReferenceBureauSubmission menuCode={5558} />,
            parentCode: 5551,
            code: 5558,
            description: 'creditReferenceBureauSubmission',
          },
        ],
      },
    ],
  },
]

export default backOfficeMenus
