/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import { getData } from '../../../../Helpers/webApi'
import { formatDate } from '../../../../Helpers/formatters'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import ReactSelect from 'react-select'
import Pager from '../../../../Components/Tables/Pager'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import CreateEmployee from './CreateEmployee'
import ViewEmployee from './ViewEmployee'

function Register() {
  const [modalMode, setModalMode] = useState(null)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [employees, setEmployees] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [groupBy, setGroupBy] = useState({
    label: 'branch',
    column: 'branchDescription',
  })
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    employeeFilter: 0,
    text: '',
  })

  const columns = [
    { label: localize('name'), template: r => <>{r?.customerFullName}</> },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.paddedCustomerAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerPersonalFileNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.customerIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r?.designationDescription}</>,
    },
    {
      label: localize('department'),
      template: r => <>{r?.departmentDescription}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r?.employeeTypeDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r?.costCenterDescription}</>,
    },
    {
      label: localize('bloodGroup'),
      template: r => <>{r?.bloodGroupDescription}</>,
    },
    {
      label: localize('NSSF') + ' #',
      template: r => <>{r?.nationalSocialSecurityFundNumber}</>,
    },
    {
      label: localize('NHIF') + '#',
      template: r => <>{r?.nationalHospitalInsuranceFundNumber}</>,
    },
    {
      label: localize('personalIdentificationNumber'),
      template: r => <>{r?.customerPersonalIdentificationNumber}</>,
    },
    {
      label: localize('employmentStatus'),
      template: r => <>{r?.employmentStatusDescription}</>,
    },
    {
      label: localize('hireDate'),
      template: r => <>{formatDate(r?.hireDate, true)}</>,
    },
    {
      label: localize('releaseDate'),
      template: r => <>{formatDate(r?.releaseDate, true)}</>,
    },
    {
      label: localize('inactivityTimeout'),
      template: r => <>{r?.inactivityTimeout}</>,
    },
    {
      label: localize('customSystemInitializationTime'),
      template: r => <>{r?.timeDurationStartTime}</>,
    },
    {
      label: localize('customSystemLockTime'),
      template: r => <>{r?.timeDurationEndTime}</>,
    },
    {
      label: localize('enforceSystemInitialization/LockTime'),
      template: r => (
        <span className="text-capitalize">
          {r?.enforceSystemInitializationLockTime.toString()}
        </span>
      ),
    },
    {
      label: localize('printingMode'),
      template: r => <>{r?.printingModeDescription}</>,
    },
    {
      label: localize('onlineNotificationEnabled') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.onlineNotificationsEnabled.toString()}
        </span>
      ),
    },
    {
      label: localize('enforceBiometricsForLogin') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.enforceBiometricsForLogin.toString()}
        </span>
      ),
    },
    {
      label: localize('useDefaultPrinter') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.useDefaultPrinter.toString()}
        </span>
      ),
    },
    {
      label: localize('electronicPaySlipEnabled') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.electronicPayslipEnabled?.toString()}
        </span>
      ),
    },
    {
      label: localize('enforceTextBased2FA') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.enforceTextBased2FA?.toString()}
        </span>
      ),
    },
    {
      label: localize('appUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { label: 'lockedOut (system)', column: 'applicationIsLockedOut' },
    { label: 'bloodGroup', column: 'bloodGroupDescription' },
    { label: 'branch', column: 'branchDescription' },
    { label: 'gender', column: 'customerIndividualGenderDescription' },
    {
      label: 'maritalStatus',
      column: 'customerIndividualMaritalStatusDescription',
    },
    {
      label: 'nationality',
      column: 'customerIndividualNationalityDescription',
    },
    { label: 'salutation', column: 'customerIndividualSalutationDescription' },
    { label: 'department', column: 'departmentDescription' },
    { label: 'designation', column: 'designationDescription' },
    { label: 'employeeType', column: 'employeeTypeDescription' },
    { label: 'employmentStatus', column: 'employmentStatusDescription' },
    { label: 'locked (employee)', column: 'isLocked' },
    { label: 'printingMode', column: 'printingModeDescription' },
  ]

  useEffect(() => {
    loadEmployees()
    // eslint-disable-next-line
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  async function loadEmployees() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/employeeRegister/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) setEmployees(data)
    setIsBusy(false)
  }

  return (
    <>
      <div>
        <BreadCrumb
          tree={[
            localize('humanResourceManagement'),
            localize('employees'),
            localize('register'),
          ]}
        />
        <div className="card">
          <div className="card-body bg-white">
            <div
              className=" d-flex align-items-center justify-content-between gap-2 mb-2"
              onClick={() => {
                setSelectedEmployee(null)
                setModalMode('add')
              }}
            >
              <button className="btn btn-success px-5">
                {localize('create')}
              </button>
            </div>
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className=" d-flex align-items-center justify-content-between gap-2 my-3">
                  <div className="d-flex align-items-center gap-2">
                    <label
                      htmlFor=""
                      style={{ minWidth: 'max-content' }}
                      className="text-capitalize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      style={{ minWidth: 'max-content' }}
                      className="form-select"
                      value={reqParams?.pageSize}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <label htmlFor="" className="text-capitalize">
                      {localize('groupBy')}
                    </label>
                    <ReactSelect
                      className="text-capitalize"
                      options={groupingColumns.map(x => ({
                        label: localize(x.label),
                        value: x.column,
                      }))}
                      value={{ ...groupBy, value: groupBy.column }}
                      onChange={e => setGroupBy({ ...e, column: e.value })}
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <label htmlFor="" className="text-capitalize">
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      enumsKey="EmployeeFilter"
                      value={reqParams?.employeeFilter}
                      onChange={e =>
                        setReqParams({ ...reqParams, employeeFilter: e.value })
                      }
                      customContainerStyle={{ minWidth: 200, maxWidth: 200 }}
                    />
                    <form
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({ ...reqParams, pageIndex: 0 })
                        setRefresh(!refresh)
                      }}
                    >
                      <input
                        type="text"
                        value={reqParams?.text}
                        onChange={e =>
                          setReqParams({ ...reqParams, text: e.target.value })
                        }
                        className="form-control"
                      />
                    </form>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setReqParams({ ...reqParams, pageIndex: 0 })
                        setRefresh(!refresh)
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <GroupedTable
                  columns={columns}
                  data={employees?.pageCollection}
                  groupBy={groupBy}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setSelectedEmployee(r)
                          setModalMode('view')
                        }}
                      >
                        <i className="uil uil-eye"></i>
                        <span htmlFor="" className="text-capitalize">
                          {localize('view')}
                        </span>
                      </div>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setSelectedEmployee(r)
                          setModalMode('edit')
                        }}
                      >
                        <i className="uil uil-edit"></i>
                        <span htmlFor="" className="text-capitalize">
                          {localize('edit')}
                        </span>
                      </div>
                      <div className="ctxt-menu-item">
                        <i className="uil uil-window"></i>
                        <span htmlFor="" className="text-capitalize">
                          {localize('auditLogs')}
                        </span>
                      </div>
                    </>
                  )}
                />
                <div className="mt-3">
                  <Pager
                    itemsCount={employees?.itemsCount}
                    pageSize={reqParams?.pageSize}
                    pageIndex={reqParams?.pageIndex}
                    pageBatchSize={reqParams?.pageBatchSize}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {modalMode === 'view' && selectedEmployee && (
        <ModalTemplate
          modalTitle={`${localize('employee')} - ${
            selectedEmployee?.customerFullName
          }`}
          handleClose={() => {
            setModalMode(null)
            setSelectedEmployee(null)
          }}
          modalMode={localize('view')}
          modalClassName="modal-xl"
          hideUpdate
        >
          <ViewEmployee selectedEmployee={selectedEmployee} />
        </ModalTemplate>
      )}
      {modalMode === 'edit' && selectedEmployee && (
        <ModalTemplate
          modalTitle={localize('employee')}
          handleClose={() => {
            setModalMode(null)
            setSelectedEmployee(null)
          }}
          modalMode={localize('edit')}
          modalClassName="modal-xl"
          hideUpdate
          hideFooter
        >
          <CreateEmployee
            handleClose={() => {
              setModalMode(null)
              setSelectedEmployee(null)
            }}
            refreshEmployees={() => setRefresh(!refresh)}
            editMode
            selectedEmployee={selectedEmployee}
          />
        </ModalTemplate>
      )}
      {modalMode === 'add' && !selectedEmployee && (
        <ModalTemplate
          modalTitle={localize('employee')}
          modalMode={localize('add')}
          modalClassName="modal-xl"
          hideUpdate
          hideFooter
          handleClose={() => {
            setModalMode(null)
            setSelectedEmployee(null)
          }}
        >
          <CreateEmployee
            handleClose={() => {
              setModalMode(null)
              setSelectedEmployee(null)
            }}
            refreshEmployees={() => setRefresh(!refresh)}
          />
        </ModalTemplate>
      )}
    </>
  )
}

export default Register
