/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import ListCustomer from '../../../../Components/ReUsables/Customer/ListCustomer'
import { getData } from '../../../../Helpers/webApi'
import { BiometricExemptionsDetails } from './Partials/BiometricExemptionsDetails'
import BiometricExemptionsModal from './Partials/BiometricExemptionsModal'
import Tab from '../../../../Components/Tabs/Tab'
import Loader from '../../../../Components/Loaders/Loader'

function BiometricExemptions() {
  const [isBusy, setIsBusy] = useState(false)
  const [biometricExemptions, setBiometricExemptions] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)

  const handleOnEdit = r => {
    setMode('edit')
    setSelectedRow(r)
  }

  const getBiometricExemptions = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/BiometricExemption/find-biometric-exemption-collection-by-customer-id',
      {
        customerId: r.id,
      },
      false
    )
    if (success) {
      setBiometricExemptions(data.result || [])
    }
    setIsBusy(false)
  }

  const handleOnView = r => {
    getBiometricExemptions(r)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('biometricExemptions')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <ListCustomer
                    contextMenu={r => (
                      <>
                        <div
                          onClick={() => {
                            handleOnView(r)
                          }}
                          className="ctxt-menu-item"
                        >
                          <i className="uil uil-search"></i>
                          {localize('view')}
                        </div>
                        <div
                          onClick={() => {
                            handleOnEdit(r)
                          }}
                          className="ctxt-menu-item"
                        >
                          <i className="uil uil-edit-alt"></i>
                          {localize('edit')}
                        </div>
                      </>
                    )}
                    onSelect={c => {}}
                  />
                  <Tab
                    tabItems={[
                      {
                        label: localize('biometricExemptions'),
                        item: (
                          <BiometricExemptionsDetails
                            data={biometricExemptions}
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <BiometricExemptionsModal
        mode={mode}
        onSetMode={m => {
          setMode(m)
        }}
        onHideModal={() => {
          setMode(null)
        }}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
        selectedCustomer={selectedRow}
      />
    </>
  )
}

export default BiometricExemptions
