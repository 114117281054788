/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import Loader from '../../../../../../Components/Loaders/Loader'
import VehicleLookup from '../../../../../../Components/Lookup/VehicleLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { vehiclePersonnelType } from '../../../../../../Helpers/constants'
import { getTodaysDate, parseDate } from '../../../../../../Helpers/formatters'
import { postData, putData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import BadgeSpecimen from './BadgeSpecimen/BadgeSpecimen'

const AddPersonnel = ({ handleClose, mode, personnelData, fetchPersonnel }) => {
  const [personnel, setPersonnel] = useState({
    type: vehiclePersonnelType.DRIVER,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [customer, setCustomer] = useState({})
  const [vehicle, setVehicle] = useState({})
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectCustomer = c => {
    if (c?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected customer is locked'
      )

    setCustomer(c)
  }

  const handleSelectVehicle = v => {
    if (v?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected vehicle is locked'
      )

    setVehicle({
      ...v,
      vehicleRegistrationNumber: v?.registrationNumber,
      vehicleId: v?.id,
    })
  }

  const handleCreate = async () => {
    setIsBusy(true)
    const url = '/VehiclePersonnel/create'

    const { success, data } = await postData(
      url,
      {
        ...customer,
        ...vehicle,
        ...personnel,
      },
      false
    )

    if (success) {
      if (personnel?.badgeBuffer) {
        const { success } = await postData(
          '/base64/post-image',
          {
            sku: data?.badgeImageId,
            fileType: 'PSVBadgeImage',
            fileRemarks: data?.fullName,
            content: personnel.badgeBuffer,
          },
          false
        )
        if (success) {
          showNotification('Create Vehicle Personnel', 'success')
          fetchPersonnel()
          setPersonnel({
            type: vehiclePersonnelType.DRIVER,
          })
          handleClose()
        }
      }
    }
    setIsBusy(false)
  }

  const handleEdit = async () => {
    setIsBusy(true)
    const url = '/VehiclePersonnel/update'

    const { success, data } = await putData(
      url,
      {
        id: personnelData?.id,
        ...customer,
        ...vehicle,
        ...personnel,
      },
      false
    )

    if (success) {
      if (personnel?.badgeBuffer) {
        const { success } = await postData(
          '/base64/post-image',
          {
            sku: data?.badgeImageId,
            fileType: 'PSVBadgeImage',
            fileRemarks: data?.fullName,
            content: personnel.badgeBuffer,
          },
          false
        )
        if (success) {
          showNotification('Update Vehicle Personnel', 'success')
          fetchPersonnel()
          setPersonnel({
            type: vehiclePersonnelType.DRIVER,
          })
          handleClose()
        }
      }
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (mode === 'edit' && personnelData?.id) {
      setCustomer({
        id: personnelData?.customerId,
        customerId: personnelData?.customerId,
        customerFullName: personnelData?.customerFullName,
      })
      setVehicle({
        id: personnelData?.vehicleId,
        vehicleId: personnelData?.vehicleId,
        registrationNumber: personnelData?.vehicleRegistrationNumber,
        fleetNumber: personnelData?.vehicleFleetNumber,
        documentReferenceNumber: personnelData?.vehicleDocumentReferenceNumber,
        remarks: personnelData?.vehicleRemarks,
        VehicleRegistrationNumber: personnelData?.vehicleRegistrationNumber,
      })
      setPersonnel({
        badgeNumber: personnelData?.badgeNumber,
        badgeExpiryDate: parseDate(personnelData?.badgeExpiryDate),
        type: personnelData?.type,
        badgeImageId: personnelData?.badgeImageId,
        isLocked: personnelData?.isLocked,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, personnelData?.id])

  console.log(customer)

  return (
    <ModalTemplate
      disableCreate={isBusy}
      modalMode={mode === 'add' ? 'create' : 'edit'}
      modalClassName="modal-xl"
      handleAction={mode === 'add' ? handleCreate : handleEdit}
      modalTitle={mode === 'add' ? 'createPersonnel' : 'editPersonnel'}
      handleClose={handleClose}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              id={'isLockedVehiclePersonnel'}
              checked={personnel?.isLocked}
              onCheck={v => setPersonnel(p => ({ ...p, isLocked: v }))}
            />
            <label
              className="ms-2 text-capitalize"
              htmlFor={'isLockedVehiclePersonnel'}
            >
              {localize('isLocked') + ' ?'}
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerPartial
            customer={customer}
            onCustomerLookup={handleSelectCustomer}
            message={globalValidationErrors['CustomerFullName']?.message}
            refreshFromServer
          />
          <div className="row gy-2">
            <div
              className="col-md-3 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['VehicleDescription']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('vehicle')}
              </label>
              <VehicleLookup
                displayValue={vehicle?.registrationNumber}
                onSelect={handleSelectVehicle}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('fleetNumber')}
              </label>
              <input
                className="form-control ms-2"
                value={vehicle?.fleetNumber}
                disabled
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('documentReferenceNumber')}
              </label>
              <input
                className="form-control ms-2"
                value={vehicle?.documentReferenceNumber}
                disabled
              />
            </div>
            <div className="col-md-3  d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={vehicle?.remarks}
                disabled
              />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('type')}
              </label>
              <EnumsServerCombo
                enumsKey="VehiclePersonnelType"
                value={personnel?.type}
                sort
                customContainerStyle={{ width: '100%' }}
                onChange={e => {
                  setPersonnel(p => ({ ...p, type: e?.value }))
                }}
              />
            </div>
            <div
              className="col-md-3  d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['BadgeNumber']?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('badgeNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={personnel?.badgeNumber}
                onChange={e => {
                  clearValidation('BadgeNumber')
                  setPersonnel(p => ({ ...p, badgeNumber: e?.target?.value }))
                }}
              />
            </div>
            <div className="col-md-3  d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('badgeExpiryDate')}
              </label>
              <CustomDatePicker
                name="personnelBadgeExpiryDate"
                onDateChange={date =>
                  setPersonnel(p => ({ ...p, badgeExpiryDate: date }))
                }
                defaultDate={personnel?.badgeExpiryDate}
                minDate={getTodaysDate()}
              />
            </div>
          </div>
        </>
      )}
      <div className="mt-4">
        <Tab
          tabItems={[
            {
              label: localize('badge'),
              item: (
                <BadgeSpecimen
                  readOnly={!customer?.id}
                  refetchFromServer
                  vehiclePersonnel={{
                    ...personnel,
                    id: personnelData?.id,
                  }}
                  handleClose={handleClose}
                  setVehiclePersonnel={setPersonnel}
                />
              ),
            },
          ]}
        />
      </div>
    </ModalTemplate>
  )
}

export default AddPersonnel
