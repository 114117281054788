/** @format */

import localize from '../../../../../../../../Global/Localize'
import { MaskNumber } from '../../../../../../../../Components/InputMasks/MaskNumber'
import { ChequeBookLookup } from '../../../../../../../../Components/Lookup/PaymentVoucherLookup'
/* eslint-disable */
import React, { useState } from 'react'
import { ChequeBookPaymentVouchersLookup } from '../../../../../../../../Components/Lookup/ChequeBookPaymentVouchersLookup'
import CustomDatePicker from '../../../../../../../../Components/Date/custom-date-picker'
import { GlobalValidationErrors } from '../../../../../../../../Validation/ValidationState'

export const CustomerAccountTransactionPaymentVoucher = ({
  transactionModel,
  setTransactionModel,
  handleOnChangePaymentVoucherAmount,
  selectedTransactionType,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const [selectedChequeBook, setSelectedChequeBook] = useState(null)
  const clearValidation = key => {
    setValidationBag(g => {
      delete g[key]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [selectedTransactionType])

  return (
    <>
      <div className="row mb-3">
        <div
          validation-message={
            validationBag?.['SelectedPaymentVoucher.ChequeBookId']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('chequeBook')} #:
          </label>
          <ChequeBookLookup
            onSelect={r => {
              clearValidation('SelectedPaymentVoucher.ChequeBookId')
              setSelectedChequeBook(r)
              setTransactionModel({
                ...transactionModel,
                paymentVoucher: {
                  ...transactionModel.paymentVoucher,
                  paddedChequeBookSerialNumber: r.paddedChequeBookSerialNumber,
                  chequeBookId: r.id,
                },
              })
            }}
            displayValue={selectedChequeBook?.paddedSerialNumber}
          />
        </div>
        <div
          validation-message={
            validationBag?.['SelectedPaymentVoucher.VoucherNumber']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('voucher')} #:
          </label>
          <ChequeBookPaymentVouchersLookup
            chequeBookId={selectedChequeBook?.id}
            paymentVoucherDescription={selectedChequeBook?.paddedSerialNumber}
            displayValue={transactionModel?.paymentVoucher?.paddedVoucherNumber}
            onSelect={b => {
              clearValidation('SelectedPaymentVoucher.VoucherNumber')
              setTransactionModel({
                ...transactionModel,
                paymentVoucher: {
                  ...transactionModel.paymentVoucher,
                  paddedVoucherNumber: b.paddedVoucherNumber,
                  voucherNumber: b.voucherNumber,
                },
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div
          validation-message={
            validationBag?.['SelectedPaymentVoucher.Payee']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">{localize('payee')}</label>
          <input
            className="form-control"
            defaultValue={transactionModel?.paymentVoucher?.payee}
            onChange={e => {
              clearValidation('SelectedPaymentVoucher.Payee')
              setTransactionModel({
                ...transactionModel,
                paymentVoucher: {
                  ...transactionModel.paymentVoucher,
                  payee: e.target.value,
                },
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('writeDate')}
          </label>
          <CustomDatePicker
            onDateChange={e => {
              clearValidation('SelectedPaymentVoucher.WriteDate')
              setTransactionModel({
                ...transactionModel,
                paymentVoucher: {
                  ...transactionModel.paymentVoucher,
                  writeDate: e,
                },
              })
            }}
            defaultDate={
              transactionModel?.paymentVoucher?.writeDate || new Date()
            }
            name={'writeDate'}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div
          validation-message={validationBag?.['WithdrawalAmount']?.message}
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">{localize('amount')}</label>
          <MaskNumber
            onBlur={handleOnChangePaymentVoucherAmount}
            defaultMaskValue={transactionModel?.cashWithdrawalAmount}
            onMaskNumber={e => {
              clearValidation('WithdrawalAmount')
              setTransactionModel({
                ...transactionModel,
                cashWithdrawalAmount: e.target.value,
              })
            }}
          />
        </div>
        <div
          validation-message={validationBag?.['Reference']?.message}
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            onChange={e => {
              clearValidation('Reference')
              setTransactionModel({
                ...transactionModel,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
