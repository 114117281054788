/** @format */

import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { RefundBatchEntriesViewMode } from './RefundBatchEntries'

export const VerifyRefundBatchModal = ({
  clearValidation,
  selectedRefundBatch,
  verificationModels,
  validationErrors,
  setVerificationModels,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('batchNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.paddedBatchNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('priority')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.priorityDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('branch')}</label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.branchDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postingPeriod')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.postingPeriodDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('referenceMonth')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.monthDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('valueDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.valueDate}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.reference}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('createdBy')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.createdBy}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('createdDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedRefundBatch?.createdDate)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('verifiedBy')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.auditedBy}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('verifiedDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.auditedDate}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('verificationRemarks')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.auditRemarks}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('authorizedBy')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.authorizedBy}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('authorizedDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.authorizedDate}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('authorizationRemarks')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.authorizationRemarks}
          />
        </div>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('batchDetails')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <RefundBatchEntriesViewMode
            validationErrors={validationErrors}
            verificationModels={verificationModels}
            setVerificationModels={setVerificationModels}
            clearValidation={clearValidation}
            mode={'verify'}
            selectedRefundBatchEntry={selectedRefundBatch}
          />
        </div>
      </div>
    </>
  )
}
