/** @format */

import React from 'react'
import localize from '../../../../../../Global/Localize'
import AccountClosureRequestLookup from '../../../../../../Components/Lookup/AccountClosureRequestLookup'

const CustomerAccountClosure = ({
  customer,
  onCustomerAccountLookup,
  status,
}) => {
  return (
    <>
      <div className={`row g-3`}>
        <div className="col-md-3 d-flex align-items-center with-validation">
          <label className="col-4 me-2 text-capitalize">
            {localize('fullAccountNumber')}
          </label>

          <AccountClosureRequestLookup
            onSelect={onCustomerAccountLookup}
            displayValue={customer?.customerAccountFullAccountNumber}
            status={status}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountStatus')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountStatusDescription}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountRemarks')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountRemarks}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('productName')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountTypeTargetProductDescription}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerName')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountCustomerFullName}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountCustomerTypeDescription}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.paddedCustomerAccountCustomerSerialNumber}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('employerName')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountCustomerEmployerDescription}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('identificationNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountCustomerIdentificationNumber}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('ref_1Account')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountCustomerReference1 || ''}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('ref_2Membership')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountCustomerReference2 || ''}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('ref_3PersonalFile')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customer?.customerAccountCustomerReference3 || ''}
          />
        </div>
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('reason')}
            </label>
            <input
              type="text"
              className="form-control ms-1"
              disabled
              value={customer?.reason}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerAccountClosure
