/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import localize from '../../Global/Localize'
import { showNotification } from '../../Helpers/Notification/ShowNotification'
import { withdrawalNotificationStatus } from '../../Helpers/constants'
import { formatDate } from '../../Helpers/formatters'
import { getData, putData } from '../../Helpers/webApi'
import Checkbox from '../Checkbox/Checkbox'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import DatePicker from '../Date/DatePicker'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

function MembershipWithdrawalNotificationsLookup({
  branch,
  category,
  withdrawalSettlementBatchId,
  setRefresh,
  categoryDescription,
  branchId,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    status: withdrawalNotificationStatus.VERIFIED,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    endDate: null,
    customDate: false,
    dateRequestsFilter: 15,
    customerFilter: 0,
    includeBatchStatus: true,
  })

  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectedData, setSelectedData] = useState([])

  const columns = [
    {
      label: 'select',
      template: r => (
        <>
          <Checkbox
            checked={selectedData?.find(x => x?.id === r?.id)}
            onCheck={e => {
              if (e) {
                setSelectedData([...selectedData, r])
              } else {
                setSelectedData(selectedData.filter(x => x.id !== r?.id))
              }
            }}
          />
        </>
      ),
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: 'serialNumber',
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: 'branch',
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: 'status',
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: 'terminationCategory',
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: 'settlementMode',
      template: r => <>{r?.withdrawalSettlementModeDescription}</>,
    },
    {
      label: 'customerName',
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: 'gender',
      template: r => <>{r?.customerIndividualGenderDescription}</>,
    },
    {
      label: 'maritalStatus',
      template: r => <>{r?.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: 'individualIdentityCardNumber',
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: 'nationality',
      template: r => <>{r?.customerIndividualNationalityDescription}</>,
    },
    {
      label: 'serialNumber',
      template: r => <>{r?.paddedCustomerSerialNumber}</>,
    },
    {
      label: 'addressAddressLine1',
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: 'addressAddressLine2',
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: 'physicalAddress',
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: 'postalCode',
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    {
      label: 'city',
      template: r => <>{r?.customerAddressCity}</>,
    },
    {
      label: 'emailAddress',
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: 'landLine',
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: 'mobileLine',
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: 'duty/Workstation',
      template: r => <>{r?.customerDutyStationDescription}</>,
    },
    {
      label: 'employer',
      template: r => <>{r?.customerEmployerDescription}</>,
    },
    {
      label: 'terminationRemarks',
      template: r => <>{r?.remarks}</>,
    },
    {
      label: 'maturityDate',
      template: r => <>{formatDate(r?.maturityDate, true)}</>,
    },
    {
      label: 'approvalRemarks',
      template: r => <>{r?.approvalRemarks}</>,
    },
    {
      label: 'approvedBy',
      template: r => <>{r?.approvedBy}</>,
    },
    {
      label: 'approvedDate',
      template: r => <>{formatDate(r?.approvedDate)}</>,
    },
    {
      label: 'auditRemarks',
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: 'auditedBy',
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: 'auditedDate',
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: 'settledBy',
      template: r => <>{r?.settledBy}</>,
    },
    {
      label: 'settledDate',
      template: r => <>{formatDate(r?.settledDate, true)}</>,
    },
    {
      label: 'settlementRemarks',
      template: r => <>{r?.settlementRemarks}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)

    const url =
      '/lookup/WithdrawalNotifications/find-withdrawal-notifications-by-branch-and-status-and-filter-in-page'
    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        category,
        branchId,
      },
      false
    )
    if (success) {
      setData(data)
      setTableData(data?.pageCollection)
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleUpdate = async () => {
    const url =
      '/WithdrawalSettlementBatch/update-withdrawal-settlement-batch-entries'

    if (selectedData.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select at least one entry to update!',
      })

      return
    }

    setIsBusy(true)
    const { success } = await putData(
      url,
      {
        withdrawalSettlementBatchId,
        withdrawalSettlementBatchEntries: selectedData?.map(notification => ({
          ...notification,
          withdrawalNotificationId: notification.id,
          withdrawalNotificationSerialNumber: notification.serialNumber,
          withdrawalSettlementBatchId,
          withdrawalNotificationIsBatched: notification?.isBatched,
          withdrawalNotificationBatchNumber: notification?.batchNumber,
          withdrawalNotificationBatchedBy: notification?.batchedBy,
          withdrawalNotificationCategory: notification?.category,
          Reference: notification?.remarks,
        })),
      },
      false
    )

    if (success) {
      setModalActive(false)
      setSelectedData([])
      loadData()
      setRefresh(true)
      showNotification('Membership Withdrawal SettlementBatches', 'success')
      if (selectAll) setSelectAll(false)
    }

    setIsBusy(false)
  }

  const handleCancel = () => {
    setModalActive(false)
    setSelectedData([])
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  return (
    <>
      <button
        className="btn btn-primary"
        style={{ margin: 0, transform: 'scale(0.9)' }}
        onClick={() => setModalActive(true)}
      >
        <i className="uil uil-search"></i>
      </button>
      {modalActive && (
        <div
          style={{
            maxHeight: '60dvh',
            overflow: 'auto',
          }}
        >
          <Modal isOpen className="modal-xl">
            <ModalHeader className="bg-white text-capitalize">
              {`${categoryDescription} ${localize(
                'membershipWithdrawalNotificationsLookup'
              )} @ ${branch}`}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white p-2">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3 p-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center col-md-3">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                      <label className="col-3">Status </label>
                      <EnumsServerCombo
                        customContainerStyle={{
                          maxWidth: 150,
                          minWidth: 150,
                        }}
                        enumsKey="WithdrawalNotificationStatus"
                        value={reqParams.status}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            groupBy: e.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-3 d-flex align-items-center gap-2">
                      <label className="text-capitalize" htmlFor="search">
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        value={{
                          startDate: reqParams.startDate,
                          endDate: reqParams.endDate,
                        }}
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>
                    <form
                      className="d-flex align-items-center col-md-4 gap-2"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                          pageIndex: 0,
                        })
                      }}
                    >
                      <label htmlFor="searchTable" className="text-capitalize">
                        Search
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ maxWidth: 180, minWidth: 180 }}
                        enumsKey="CustomerFilter"
                        value={reqParams?.customerFilter}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                          })
                        }}
                      />
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      />

                      <button
                        onClick={() => loadData()}
                        className="btn btn-primary"
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className=" py-1">
                    <SimpleTable
                      columns={columns}
                      data={tableData}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Checkbox
                      id="toggle"
                      className="ms-4"
                      onCheck={v => {
                        setSelectAll(v)
                        if (v) {
                          setSelectedData(tableData)
                        } else {
                          setSelectedData([])
                        }
                      }}
                      checked={selectAll}
                    />
                    <label htmlFor="toggle" className="text-capitalize me-3">
                      {localize('toggleSelection')} ?
                    </label>
                  </div>
                  <Pager
                    itemsCount={data.itemsCount}
                    pageSize={reqParams.pageSize}
                    pageIndex={reqParams.pageIndex}
                    pageBatchSize={5}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />
                </>
              )}
            </ModalBody>

            <ModalFooter className="bg-white p-0">
              <button
                disabled={isBusy}
                className="btn btn-success"
                onClick={handleUpdate}
              >
                {localize('update')}
              </button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  )
}

export default MembershipWithdrawalNotificationsLookup
