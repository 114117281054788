/** @format */
/* eslint-disable */
import React, { useEffect, useReducer, useState } from 'react'
import { customerDetailsReducer } from '../AccountStatements/Reducer/reducer'
import {
  setCustomerAccountBalancesSummary,
  setCustomerCreditScores,
  setCustomersAccountListings,
  setSelectedCustomer,
} from '../AccountStatements/Reducer/actions'
import { getData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../../Components/Tabs/Tab'
import { ViewCustomerAddress } from '../../../../../Components/ReUsables/Customer/ViewCustomerAddress'
import { ViewCustomerSpecimen } from '../../../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import { AccountStatementsArchivedListing } from './Partials/AccountStatementsArchivedListing'

const AccountStatementsArchived = () => {
  const customerDetails = {
    selectedCustomer: {},
    customersAccountListings: [],
    customerCreditScores: [],
    customerAddress: {},
    customerAccountBalancesSummary: {},
  }

  const accountDetails = {
    accountDetails: {},
    accountStatements: [],
    accountRepaymentSchedule: [],
    accountCarryForwards: [],
    accountJournalEntries: [],
  }

  const [loading, setLoading] = useState({
    loadingAccountDetails: false,
    loadingAccountStatements: false,
    loadingRepaymentSchedule: false,
    loadingCarryForwards: false,
    loadingJournalEntries: false,
    loadingCreditScores: false,
  })
  const [customerDetailsState, dispatchCustomerDetails] = useReducer(
    customerDetailsReducer,
    customerDetails
  )

  const [balanceSummaryProductSection, setBalanceSummaryProductSection] =
    useState('0')

  useEffect(() => {
    if (customerDetailsState.selectedCustomer.id) {
      fetchCustomerAccountDetails(customerDetailsState?.selectedCustomer)
    }
    //eslint-disable-next-line
  }, [balanceSummaryProductSection])

  const handleOnSelectCustomer = async customer => {
    dispatchCustomerDetails(setSelectedCustomer(customer))
    await fetchCustomerAccountDetails(customer)
    await fetchCustomerCreditScores(customer)
  }

  const fetchCustomerAccountDetails = async customer => {
    setLoading({ ...loading, loadingAccountDetails: true })
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id',
      {
        customerId: customer.id,
        includeBalances: true,
        includeProductDescription: true,
        balancesSummaryProductSection: balanceSummaryProductSection,
      },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingAccountDetails: false })
      dispatchCustomerDetails(setCustomersAccountListings(data))
      dispatchCustomerDetails(
        setCustomerAccountBalancesSummary({
          savingsBalance: data?.totalCustomerSavingsBalance,
          investmentsBalance: data?.totalCustomerRefundableInvestmentsBalance,
          loansBalance: data?.totalCustomerLoansBalance,
          principalArrearsBalance: data?.totalCustomerPrincipalArrearsBalance,
          interestArrearsBalance: data?.totalCustomerInterestArrearsBalance,
          netWorth: data?.totalCustomerNetWorth,
        })
      )
    }
  }

  const fetchCustomerCreditScores = async customer => {
    setLoading({ ...loading, loadingCreditScores: true })
    const { success, data } = await getData(
      '/Mobileloan/find-credit-scores-by-customer-id',
      { customerId: customer.id },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingCreditScores: false })
      dispatchCustomerDetails(setCustomerCreditScores(data.result))
    }
  }

  const handleOnChangeProductSection = r => {
    setBalanceSummaryProductSection(r.value)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('customers'),
          localize('accountStatements'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <CustomerPartial
                onCustomerLookup={handleOnSelectCustomer}
                customer={customerDetailsState.selectedCustomer}
              />
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('mobileLine')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      customerDetailsState?.selectedCustomer?.addressMobileLine
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize me-1">
                    {localize('reference1')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerDetailsState?.selectedCustomer?.reference1}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize me-1">
                    {localize('reference2')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerDetailsState?.selectedCustomer?.reference2}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize me-1">
                    {localize('reference3')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerDetailsState?.selectedCustomer?.reference3}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <Tab
                    preload
                    tabItems={[
                      {
                        label: localize('accountsListing'),
                        item: (
                          <AccountStatementsArchivedListing
                            selectedCustomer={
                              customerDetailsState?.selectedCustomer
                            }
                            balanceSummary={
                              customerDetailsState?.customerAccountBalancesSummary
                            }
                            balanceSummaryProductSection={
                              balanceSummaryProductSection
                            }
                            accountStatementsListing={
                              customerDetailsState?.customersAccountListings
                            }
                            loading={loading.loadingAccountDetails}
                            onChangeProductSection={
                              handleOnChangeProductSection
                            }
                          />
                        ),
                      },

                      {
                        label: localize('address'),
                        item: (
                          <ViewCustomerAddress
                            customer={customerDetailsState.selectedCustomer}
                          />
                        ),
                      },
                      {
                        label: `${localize('specimen')}(${localize(
                          'identity'
                        )})`,
                        item: <ViewCustomerSpecimen readOnly />,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountStatementsArchived
