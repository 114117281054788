export const CUSTOMER_METADATA_ACTIVE_TABS={
  SPECIMEN:1,
  LOANS_GUARANTEED:2,
  INDIVIDUAL_PARTICULARS:3,
  ADDRESS:4,
  SAVINGS_ACCOUNT:5,
  LOAN_ACCOUNTS:6,
  INVESTMENT_ACCOUNTS:7,
  PAYROLL_NUMBERS:8,
  CREDIT_TYPES:9,
  REFEREES:10,
  ASSOCIATES:11,
  BANK_ACCOUNTS:12,
}