/** @format */
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import React from 'react'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import Loader from '../../../../Components/Loaders/Loader'

export const LoanRepaymentModeMappings = ({
  repaymentModeMappings,
  waitingMetadata,
}) => {
  const columns = [
    {
      label: localize('loanProduct'),
      template: r => <>{r.loanProductDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const groupBy = {
    label: 'Business/Group/Employer',
    column: 'employerDescription',
  }

  return (
    <>
      {waitingMetadata ? (
        <Loader />
      ) : (
        <>
          <GroupedTable
            groupBy={groupBy}
            columns={columns}
            data={repaymentModeMappings}
          />
        </>
      )}
    </>
  )
}
