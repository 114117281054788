/** @format */

import { AccountAlertsPreference } from './AccountAlertsPreference'

export const ViewAccountAlertPreference = ({ accountAlertPreferences }) => {
  return (
    <AccountAlertsPreference
      accountAlertsPreference={accountAlertPreferences}
    />
  )
}
