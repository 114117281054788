/** @format */

import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import React from 'react'
import { formatCurrency, formatDate } from '../../Helpers/formatters'

export const FuneralRiderClaimPayableLookup = ({ displayValue, onSelect }) => {
  const columns = [
    {
      label: localize('claimType'),
      template: r => <>{r.funeralRiderClaimClaimTypeDescription}</>,
    },
    { label: localize('beneficiary'), template: r => <>{r.beneficiary}</> },
    { label: localize('deceased'), template: r => <>{r.deceased}</> },
    {
      label: localize('reference'),
      template: r => <>{r.paddedReferenceNumber}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('member'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.funeralRiderClaimCustomerReference1}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.funeralRiderClaimFuneralRiderClaimantName}</>,
    },
    {
      label: localize(
        'idNo'
      ),
      template: r => (
        <>{r.funeralRiderClaimFuneralRiderClaimantIdentityCardNumber}</>
      ),
    },
    {
      label: localize('mobileNo'),
      template: r => <>{r.funeralRiderClaimFuneralRiderClaimantMobileNumber}</>,
    },
    {
      label: localize('paymentStatus'),
      template: r => <>{r.paymentStatusDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        columns={columns}
        title={localize('funeralRiderClaimPayableLookup')}
        url="/lookup/FuneralRiderClaim/find-funeral-rider-claims-payables-by-record-status-payment-status-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}
