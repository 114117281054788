/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Tab from '../../../../../Components/Tabs/Tab'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import CustomerAccountBalanceSpoolRequestEntries from './CustomerAccountBalanceSpoolRequestEntries'
function ViewCustomerAccountBalanceSpoolDataModal({
  mode,
  selectedCustomerAccountBalanceSpoolRequest,
  close,
}) {
  const [entryCountValue, setEntryCountValue] = useState(null)
  function closeModal() {
    close()
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="spooledMonthlyAccountBalances"
        handleClose={closeModal}
        modalClassName="modal-xl"
        actionText={mode === 'view' ? 'refresh' : ''}
        footerChildren={
          <>
            <div className="col-2 d-flex align-items-center">
              <label className="col-3 me-2 text-capitalize">
                {localize('entries')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={entryCountValue || ''}
              />
            </div>
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('batchNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.paddedBatchNumber ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('postingPeriod')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.postingPeriodDescription ||
                ''
              }
            />
          </div>

          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('balanceStatistics')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.statisticDescription ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('dateFilter')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.dateFilterDescription ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('startDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.durationStartDate ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('endDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.durationEndDate ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.reference || ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.createdBy || ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequest?.createdDate || ''
              }
            />
          </div>
        </div>

        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <CustomerAccountBalanceSpoolRequestEntries
                    showLoader={false}
                    selectedCustomerAccountBalanceSpoolRequest={
                      selectedCustomerAccountBalanceSpoolRequest
                    }
                    mode={mode}
                    setEntryCount={setEntryCountValue}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewCustomerAccountBalanceSpoolDataModal
