/** @format */

/* eslint-disable */
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

const CreateChannelReconciliationPeriods = ({
  handleClose,
  handleAction,
  mode,
  periods,
  setPeriods,
  isLoading,
}) => {
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalTitle={
        mode === 'add'
          ? localize('channelReconciliationPeriod')
          : localize('channelReconciliationPeriod')
      }
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            onCheck={x => {
              setPeriods({
                ...periods,
                disregardPAN: x,
              })
            }}
            checked={periods?.disregardPAN}
            id="disregardPAN/CardNumber"
          />
          <label htmlFor="disregardPAN/CardNumber" className="text-capitalize">
            {localize('disregardPAN/CardNumber' + '?')}
          </label>
        </div>
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-md-6 mb-2">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="alternateChannelType"
                  className="text-capitalize col-4"
                >
                  {localize('alternateChannelType')}
                </label>
                <EnumsServerCombo
                  className="col"
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="AlternateChannelTypeFilter"
                  value={periods?.alternateChannelType}
                  onChange={e => {
                    setPeriods({
                      ...periods,
                      alternateChannelType: e.value,
                    })
                  }}
                  sort
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex align-items-center">
                <label htmlFor="dateRange" className="text-capitalize col-4">
                  {localize('dateRange')}
                </label>
                <DatePicker
                  customOnly
                  className="col-4"
                  value={{
                    startDate: periods?.startDate || periods.durationStartDate,
                    endDate: periods?.endDate || periods.durationEndDate,
                  }}
                  onSelect={e => {
                    setPeriods({
                      ...periods,
                      startDate: e.startDate,
                      endDate: e.endDate,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-2">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="transactionBackingStore"
                  className="text-capitalize col-4"
                >
                  {localize('transactionBackingStore')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="TransactionBackingStore"
                  value={periods?.transactionBackingStore}
                  onChange={e => {
                    setPeriods({
                      ...periods,
                      transactionBackingStore: e.value,
                    })
                  }}
                  sort
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="discreteSetDifferenceMode"
                  className="text-capitalize col-4"
                >
                  {localize('discreteSetDifferenceMode')}
                </label>
                <div className="col">
                  <EnumsServerCombo
                    className="col ms-1"
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="SetDifferenceMode"
                    value={periods?.setDifferenceMode}
                    onChange={e => {
                      setPeriods({
                        ...periods,
                        setDifferenceMode: e.value,
                      })
                    }}
                    sort
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="remarks" className="text-capitalize col-4">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={periods.remarks}
                  onChange={e =>
                    setPeriods({
                      ...periods,
                      remarks: e?.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}
export default CreateChannelReconciliationPeriods
