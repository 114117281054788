/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import LoanRequest from '../Appraisal/LoanRequest'
import Walkthrough from '../../../../../Components/Walkthrough/Walkthrough'
import { postData } from '../../../../../Helpers/webApi'
import AppraisalPartial from './AppraisalPartial'
import Swal from 'sweetalert2'

function AppraisalServerSide() {
  const [activePage, setActivePage] = useState(0)
  const [loanCase, setLoanCase] = useState(null)
  const [termInMonths, setTermInMonths] = useState(
    loanCase?.loanRegistrationTermInMonths
  )
  const [amountApplied, setAmountApplied] = useState(loanCase?.amountApplied)
  const [incomeAdjustments, setIncomeAdjustments] = useState([])
  const [income, setIncome] = useState(0)
  const [isBusy, setIsBusy] = useState(false)
  const [assessment, setAssessment] = useState(null)
  const [offsetableLoans, setOffsetableLoans] = useState([])
  const [voluntaryDeductibles, setVoluntaryDeductibles] = useState([])
  const [amountAppraised, setAmountAppraised] = useState(0)
  const [assessLoan, setAssessLoan] = useState(false)
  const [considerCheckOffDeductions, setConsiderCheckOffDeductions] =
    useState(false)
  const [loanGuarantors, setLoanGuarantors] = useState([])

  async function appraiseLoan(loanAppraisalOption, appraisalRemarks) {
    const { success } = await postData('/loanOrigination/appraise-loan-case', {
      loanCase,
      loanAppraisalOption,
      appraisalRemarks,
      assessment,
    })
    if (success) {
      Swal.fire('Operation Successful', 'Loan Appraised', 'success')
      setLoanCase(null)
      setActivePage(0)
    }
  }

  const pages = [
    {
      title: localize('loanCase'),
      component: (
        <>
          <LoanRequest
            loanCase={loanCase}
            onSelect={l => {
              setLoanCase(l)
            }}
            setLoanCase={(p, v) => setLoanCase({ ...loanCase, [p]: v })}
            onChangeLoanTerm={t => {
              setTermInMonths(t)
            }}
            // onChangeReceivedDate={d => {
            //   setLoanCase({ ...loanCase, receivedDate: d })
            // }}
            onChangeAmountApplied={a => setAmountApplied(a)}
          />
        </>
      ),
      skippable: !!loanCase,
    },
    {
      title: localize('assessment'),
      component: (
        <AppraisalPartial
          loanCase={loanCase}
          onChangeIncomeAdjustments={i => setIncomeAdjustments(i)}
          onChangeIncome={i => setIncome(i)}
          income={income}
          isBusy={isBusy}
          assessment={assessment}
          onChangeOffsetables={x => setOffsetableLoans(x)}
          onChangeAmountAppraised={x => setAmountAppraised(x)}
          onChangeVoluntaryDeductibles={v => setVoluntaryDeductibles(v)}
          onChangeConsiderCheckOffDeductions={v =>
            setConsiderCheckOffDeductions(v)
          }
          onChangeGuarantors={g => setLoanGuarantors(g)}
          reAssessLoan={() => setAssessLoan(!assessLoan)}
          onAppraiseLoan={appraiseLoan}
        />
      ),
      skippable: true,
    },
  ]

  useEffect(() => {
    loanCase && termInMonths && loadAssessment()
    // eslint-disable-next-line
  }, [
    loanCase,
    income,
    incomeAdjustments,
    termInMonths,
    amountApplied,
    offsetableLoans,
    amountAppraised,
    voluntaryDeductibles,
    considerCheckOffDeductions,
    assessLoan,
    loanGuarantors,
  ])

  async function loadAssessment() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/loanOrigination/assess-loan-case',
      {
        customerId: loanCase?.customerId,
        loanProductId: loanCase?.loanProductId,
        loanCaseId: loanCase?.id,
        incomeAdjustments,
        income,
        termInMonths,
        amountApplied,
        attachedLoans: offsetableLoans,
        amountAppraised,
        loanCaseVoluntaryDeductibles: voluntaryDeductibles,
        strictlySelfGuarantee: loanCase?.strictlySelfGuarantee,
        considerCheckOffDeductions,
        loanGuarantors,
      },
      true
    )
    if (success) {
      setAssessment(data)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanOrigination'),
          localize('loanAppraisal'),
        ]}
      />
      <div className="card card-body bg-white">
        <Walkthrough
          preload
          pages={pages}
          activePage={activePage}
          changePage={p => setActivePage(p)}
        />
      </div>
    </>
  )
}

export default AppraisalServerSide
