/** @format */
import localize from '../../../../../../Global/Localize'
import { CreditTypeLookup } from '../../../../../../Components/Lookup/CreditTypeLookup'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import { useEffect } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CreateCheckOffDataPeriodModal = ({
  checkOffDataPeriodModel,
  setCheckOffDataPeriodModel,
  globalValidationErrors,
  clearValidation,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const getCurrentPostingPeriod = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      null,
      false
    )
    if (success) {
      setCheckOffDataPeriodModel({
        ...checkOffDataPeriodModel,
        postingPeriodId: data.id,
        postingPeriodDescription: data.description,
      })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    getCurrentPostingPeriod()
  }, [])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('postingPeriod')}
                </label>
                <PostingPeriodLookup
                  displayValue={
                    checkOffDataPeriodModel?.postingPeriodDescription
                  }
                  onSelect={r => {
                    setCheckOffDataPeriodModel({
                      ...checkOffDataPeriodModel,
                      postingPeriodId: r.id,
                      postingPeriodDescription: r.description,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('referenceMonth')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="Month"
                  value={checkOffDataPeriodModel?.month}
                  onChange={e => {
                    setCheckOffDataPeriodModel({
                      ...checkOffDataPeriodModel,
                      month: e.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('creditType')}
                </label>
                <CreditTypeLookup
                  displayValue={checkOffDataPeriodModel?.creditTypeDescription}
                  onSelect={r => {
                    setCheckOffDataPeriodModel({
                      ...checkOffDataPeriodModel,
                      creditTypeId: r.id,
                      creditTypeDescription: r.description,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-4 d-flex align-items-center with-validation"
                validation-message={globalValidationErrors?.Remarks?.message}
              >
                <label className="col-3 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  value={checkOffDataPeriodModel?.remarks}
                  className="form-control"
                  onChange={e => {
                    clearValidation('Remarks')
                    setCheckOffDataPeriodModel({
                      ...checkOffDataPeriodModel,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        </>
      )}
    </>
  )
}
