/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getData, postData } from '../../../Helpers/webApi'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import moment from 'moment'
import CompanyLookup from '../../../Components/Lookup/CompanyLookup'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import Swal from 'sweetalert2'
import NumberingSeriesLookup from '../../../Components/Lookup/NumberingSeriesLookup'
import { MaskNumber } from '../../../Components/InputMasks/MaskNumber'
import { showNotification } from '../../../Helpers/Notification/ShowNotification'
import Loader from '../../../Components/Loaders/Loader'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'

function EditBranchModal({
  mode,
  editMode,
  close,
  selectedBranch,
  loadTableData,
}) {
  const tBrColumns = [
    { label: localize('code'), template: r => <>{r.code}</> },
    { label: localize('name'), template: r => <>{r.description}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [tempLocations, setTempLocations] = useState([])
  const [tBr, setTBr] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [locationMode, setLocationMode] = useState('add')
  const [branch, setBranch] = useState(editMode ? selectedBranch : {})
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [showError, setShowError] = useState(false)
  const [showLatitudeError, setShowLatitudeError] = useState(false)
  const [showLongitudeError, setShowLongitudeError] = useState(false)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  async function loadData() {
    if (editMode) {
      setIsBusy(true)
      const { success, data } = await getData(
        '/Branch/find-location-by-branch-id',
        { branchId: selectedBranch.id },
        false
      )
      if (success) {
        setIsBusy(false)
        setTempLocations(data)
      } else {
        setIsBusy(false)
      }
    }
  }

  async function createBranch() {
    setIsBusy(true)
    for (let b of tempLocations) {
      delete b.id
    }
    const { success } = await postData(
      '/Branch/add-branch-with-branch-locations',
      {
        branch,
        locations: tempLocations,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      showNotification('Branches', 'success')
    }
    setIsBusy(false)
  }

  async function editBranch() {
    setIsBusy(true)
    for (let b of tempLocations) {
      if (b.id.toString().split('-').length < 5) delete b.id
    }
    console.log('apaa', tempLocations)
    const { success } = await postData(
      '/Branch/update-branch-with-branch-locations',
      {
        branch,
        locations: tempLocations,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      close()
      showNotification('Branches', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    close()
    setBranch(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={localize('branch')}
      handleClose={closeModal}
      handleAction={editMode ? editBranch : createBranch}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalClassName="modal-xl"
      footerChildren={
        <>
          <Checkbox
            id="branchOverrideAccountNumberFormatting"
            onCheck={e => {
              setBranch({
                ...branch,
                overrideAccountNumberFormatting: e,
              })
            }}
            checked={branch?.overrideAccountNumberFormatting}
          />
          <label
            htmlFor="branchOverrideAccountNumberFormatting"
            className="text-capitalize mb-3"
          >
            {localize('overrideAccountNumberFormatting')}?
          </label>
          <div className="mx-3"></div>

          <Checkbox
            id="isBranchLocked"
            onCheck={e => {
              setBranch({
                ...branch,
                isLocked: e,
              })
            }}
            checked={branch?.isLocked}
          />
          <label htmlFor="isBranchLocked" className="text-capitalize mb-3">
            {localize('isLocked')}?
          </label>
          <div className="mx-3"></div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-md-4">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Branch.Description']?.message
                }
              >
                <label className="text-capitalize col-4">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  className="form-control  "
                  defaultValue={
                    editMode ? selectedBranch?.description : branch?.description
                  }
                  onChange={e => {
                    clearValidation(['Branch.Description'])
                    setBranch({ ...branch, description: e.target.value })
                  }}
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('addressLine') + '1'}
                </label>
                <input
                  type="text"
                  className="form-control  "
                  defaultValue={
                    editMode
                      ? selectedBranch?.addressAddressLine1
                      : branch?.addressAddressLine1
                  }
                  onChange={e =>
                    setBranch({
                      ...branch,
                      addressAddressLine1: e.target.value,
                    })
                  }
                />
              </div>

              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('city')}
                </label>
                <input
                  type="text"
                  className="form-control  "
                  defaultValue={
                    editMode ? selectedBranch?.addressCity : branch?.addressCity
                  }
                  onChange={e =>
                    setBranch({ ...branch, addressCity: e.target.value })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('landLine')}
                </label>
                <input
                  type="tel"
                  className="form-control  "
                  defaultValue={
                    editMode
                      ? selectedBranch?.addressLandLine
                      : branch?.addressLandLine
                  }
                  onChange={e =>
                    setBranch({ ...branch, addressLandLine: e.target.value })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('longitude')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    editMode
                      ? selectedBranch?.addressLocationLongitude
                      : branch?.addressLocationLongitude
                  }
                  className={`form-control
                     ${showLongitudeError && 'border border-danger'}
                    `}
                  onMaskNumber={e => {
                    if (Number(e.target.value) > 181) {
                      setShowLongitudeError(true)
                    } else {
                      setShowLongitudeError(false)
                    }
                    setBranch({
                      ...branch,
                      addressLocationLongitude: e.target.value,
                    })
                  }}
                  numeralThousandsGroupStyle="none"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Branch.CompanyId']?.message
                }
              >
                <label className="text-capitalize col-4">
                  {localize('company')}
                </label>
                <CompanyLookup
                  displayValue={branch?.companyDescription}
                  onSelect={v => {
                    clearValidation(['Branch.CompanyId'])
                    setBranch(prev => ({
                      ...prev,
                      companyDescription: v.description,
                      companyId: v.id,
                    }))
                  }}
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('addressLine' + '2')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={
                    editMode
                      ? selectedBranch?.addressAddressLine2
                      : branch?.addressAddressLine2
                  }
                  onChange={e =>
                    setBranch({
                      ...branch,
                      addressAddressLine2: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('postalCode')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={
                    editMode
                      ? selectedBranch?.addressPostalCode
                      : branch?.addressPostalCode
                  }
                  onChange={e =>
                    setBranch({
                      ...branch,
                      addressPostalCode: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('mobileLine')}
                </label>
                <input
                  type="tel"
                  className="form-control"
                  defaultValue={
                    editMode
                      ? selectedBranch?.addressMobileLine
                      : branch?.addressMobileLine
                  }
                  onChange={e =>
                    setBranch({
                      ...branch,
                      addressMobileLine: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="col">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['Branch.NumberingSeriesId']?.message
                  }
                >
                  <label className="text-capitalize col-4">
                    {localize('numberingSeries')}
                  </label>
                  <NumberingSeriesLookup
                    displayValue={branch?.numberingSeriesDescription}
                    className="form-control"
                    onSelect={v => {
                      if (v.isLocked) {
                        showNotification(
                          'Branches',
                          'info',
                          'sorry, but the selected numbering series is locked!'
                        )
                      } else {
                        clearValidation(['Branch.NumberingSeriesId'])
                        setBranch(prev => ({
                          ...prev,
                          numberingSeriesDescription: v.description,
                          numberingSeriesId: v.id,
                        }))
                      }
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('physicalAddress')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={
                    editMode
                      ? selectedBranch?.addressStreet
                      : branch?.addressStreet
                  }
                  onChange={e =>
                    setBranch({ ...branch, addressStreet: e.target.value })
                  }
                />
              </div>

              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('emailAddress')}
                </label>
                <input
                  type="email"
                  className="form-control"
                  defaultValue={
                    editMode
                      ? selectedBranch?.addressEmail
                      : branch?.addressEmail
                  }
                  onChange={e =>
                    setBranch({ ...branch, addressEmail: e.target.value })
                  }
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-4">
                  {localize('latitude')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    editMode
                      ? selectedBranch?.addressLocationLatitude
                      : branch?.addressLocationLatitude
                  }
                  className={`form-control
                     ${showLatitudeError && 'border border-danger'}
                    `}
                  onMaskNumber={e => {
                    if (Number(e.target.value) > 91) {
                      setShowLatitudeError(true)
                    } else {
                      setShowLatitudeError(false)
                    }

                    setBranch({
                      ...branch,
                      addressLocationLatitude: e.target.value,
                    })
                  }}
                  numeralThousandsGroupStyle="none"
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('locationDetails')}
            </div>
            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-1">
                    {localize('code')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={tBr.code || 0}
                    className={`form-control ms-3
                     ${showError && 'border border-danger'}
                    `}
                    onMaskNumber={e => {
                      if (Number(e.target.value) > 100) {
                        setShowError(true)
                      } else {
                        setShowError(false)
                      }

                      setTBr({ ...tBr, code: e.target.value })
                    }}
                    numeralThousandsGroupStyle="none"
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-1">
                    {localize('name')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3"
                    onChange={e =>
                      setTBr({ ...tBr, description: e.target.value })
                    }
                    value={tBr.description || ''}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {locationMode === 'edit' && (
                    <button
                      onClick={() => {
                        setLocationMode('add')
                        setTBr({})
                      }}
                      className="text-uppercase btn btn-outline-danger px-4"
                    >
                      {localize('cancel')}
                    </button>
                  )}
                  {locationMode === 'edit' && (
                    <button
                      onClick={() => {
                        let locationList = [...tempLocations]
                        let newList = []
                        for (let b of locationList) {
                          if (b.id === tBr.id) {
                            newList.push(tBr)
                          } else {
                            newList.push(b)
                          }
                        }
                        setTempLocations(newList)
                        setLocationMode('add')
                        setTBr({})
                      }}
                      className="btn btn-success ms-2 text-uppercase px-4"
                    >
                      {localize('update')}
                    </button>
                  )}
                  {locationMode === 'add' && (
                    <button
                      onClick={() => {
                        if (!tBr.code) {
                          Swal.fire({
                            icon: 'info',
                            text: 'Location Code is Empty',
                            title: 'Branch Locations',
                            showCloseButton: true,
                          })
                        } else if (!tBr.description) {
                          Swal.fire({
                            icon: 'info',
                            text: 'Location Name is Empty',
                            title: 'Branch Locations',
                            showCloseButton: true,
                          })
                        } else {
                          setTempLocations([
                            ...tempLocations,
                            { id: moment().format('YYMMDDHHmmssSS'), ...tBr },
                          ])
                          setTBr({})
                        }
                      }}
                      className="btn btn-success px-5 mx-0 text-uppercase"
                    >
                      {localize('add')}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              className="p-2 mt-2"
              style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
            >
              <div
                className="bg-white row px-2 text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('locations')}
              </div>
              <div style={{ marginTop: -15 }}>
                {isBusy ? (
                  <div className="text-capitalize fw-bold">
                    {localize('pleaseWait') + '...'}
                  </div>
                ) : (
                  <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                    <SimpleTable
                      columns={tBrColumns}
                      data={tempLocations}
                      contextMenu={r => (
                        <div
                          className="ctxt-menu-item text-capitalize text-danger"
                          onClick={() => {
                            let ts = tempLocations
                            let found = ts.findIndex(t => t.id === r.id)
                            ts.splice(found, 1)
                            setTempLocations(ts)
                          }}
                        >
                          <i className="uil uil-times-circle text-danger text-capitalize"></i>
                          {localize('remove')}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}
export default EditBranchModal
