/** @format */
import SavingsProducts from '../../../../Components/Selectables/Plain/SavingsProduct'

export const CreditTypesConcessionExemptSavingsProduct = ({
  creditTypeConcessionExemptSavingsProduct,
  loading,
  disabled,
  onCheckCreditTypeConcessionExemptSavingsProduct,
  prefix,
}) => {
  return (
    <SavingsProducts
      disabled={disabled}
      isBusy={loading}
      prefix={prefix}
      onCheckSavingsProduct={r => {
        onCheckCreditTypeConcessionExemptSavingsProduct &&
          onCheckCreditTypeConcessionExemptSavingsProduct(r)
      }}
      savingsProductToBeSelected={creditTypeConcessionExemptSavingsProduct}
    />
  )
}
