/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _j = {
  joinDate: {
    en: 'join date',
    sw: 'tarehe ya kujiunga',
  },
  joint: {
    en: 'joint',
    sw: 'pamoja',
  },
  journalEntries: {
    en: 'journal entries',
    sw: 'ingizo la jarida',
  },
  journalCreatedDate: {
    en: 'journal created date',
    sw: 'tarehe ya jarida iliyoundwa',
  },
  journalBranchDescription: {
    en: 'journal branch',
    sw: 'tawi la kumbukumbu',
  },
  journalPrimaryDescription: {
    en: 'journal primary description',
    sw: 'maelezo ya msingi ya jarida',
  },
  journalSecondaryDescription: {
    en: 'journal secondary description',
    sw: 'maelezo ya sekondari ya jarida',
  },
  journalReference: {
    en: 'journal reference',
    sw: 'rejelo la jarida',
  },
  journalTotalValue: {
    en: 'journal total value',
    sw: 'jumla ya thamani ya jarida',
  },
  journalApplicationUserName: {
    en: 'journal application user name',
    sw: 'jina la mtumiaji wa programu',
  },
  journalEnvironmentUserName: {
    en: 'journal env. user name',
    sw: 'jina la mtumiaji wa jarida',
  },
  journalEnvironmentMachineName: {
    en: 'journal env. machine name',
    sw: 'jina la mashine ya jarida',
  },
  journalEnvironmentDomainName: {
    en: 'journal env. domain name',
    sw: 'jina la kikoa cha jarida',
  },
  journalEnvironmentOSVersion: {
    en: 'journal env. os version',
    sw: 'toleo la os la jarida',
  },
  journalEnvironmentMACAddress: {
    en: 'journal env. mac address',
    sw: 'anwani ya mac ya jarida',
  },
  journalEnvironmentMotherboardSerialNumber: {
    en: 'journal env. motherboard serial number',
    sw: 'jarida env. nambari ya seriali ya motherboard',
  },
  journalEnvironmentProcessorId: {
    en: 'journal env. processor id',
    sw: 'kitambulisho cha processor',
  },
  journalEnvironmentIPAddress: {
    en: 'journal env. ip address',
    sw: 'anwani ya ip ya jarida',
  },
  journalVoucherOrigination: {
    en: 'voucher (origination)',
    sw: 'vocha (asili)',
  },
  journalEntry: {
    en: 'journal entry',
    sw: 'ingizo la jarida',
  },
  january: {
    en: 'january',
    sw: 'januari',
  },
  june: {
    en: 'june',
    sw: 'juni',
  },
  july: {
    en: 'july',
    sw: 'julai',
  },
  jointly: {
    en: 'jointly',
    sw: 'pamoja',
  },
  journalLookup: {
    en: 'journal lookup',
    sw: 'tafuta jarida',
  },
  journalVouchers: {
    en: 'journal vouchers',
    sw: 'vocha za jarida',
  },
  journalVouchersOrigination: {
    en: 'journal voucher (origination)',
    sw: 'journal voucher (origination)',
  },
  journalReversalBatches: {
    en: 'journal reversal batches',
    sw: 'kikundi cha kugeuza jarida',
  },
  journalVouchersVerification: {
    en: 'journal voucher (verification)',
    sw: 'journal voucher (verification)',
  },
  journalVouchersAuthorization: {
    en: 'journal voucher (authorization)',
    sw: 'journal voucher (authorization)',
  },
  journal: {
    en: 'journal',
    sw: 'jarida',
  },
  journals: {
    en: 'journals',
    sw: 'majarida',
  },
  journalVoucher: {
    en: 'journal voucher',
    sw: 'vocha ya jarida',
  },
  journalReversalBatch: {
    en: 'journal reversal batch',
    sw: 'kikundi cha kugeuza jarida',
  },
}
export default _j
