/** @format */

/* eslint-disable */
import React from 'react'
import Partnership from './Membership/Partnership'
import Corporation from './Membership/Corporation'

function MembershipDetails({ customer }) {
  // eslint-disable-next-line eqeqeq
  return customer?.membershipClassCustomerType == 1 ? (
    <Partnership customer={customer} />
  ) : // eslint-disable-next-line eqeqeq
  customer?.membershipClassCustomerType == 2 ? (
    <Corporation customer={customer} />
  ) : (
    <>
      {customer?.membershipClassCustomerType}
      <div className="alert alert-info">
        <h2 className="text-info">Not Applicable</h2>
        <p className="text-info">
          This page is for only Partnership and Corporations.
        </p>
        <p className="text-capitalize text-primary">
          <ul>
            <li>Move to next Page</li>
          </ul>
        </p>
      </div>
    </>
  )
}

export default MembershipDetails
