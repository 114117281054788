/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import {
  ChargeType,
  ProductCode,
  RoundingType,
} from '../../../../../Global/enumeration'
import rightAlignFragment, {
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import DirectDebitsModal from './DirectDebitsModal'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function DirectDebits() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('productCode'),
      template: r => <>{r?.customerAccountTypeProductCodeDescription}</>,
      sortBy: 'customerAccountTypeProductCodeDescription',
    },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
      sortBy: 'customerAccountTypeTargetProductDescription',
    },

    {
      label: rightAlignFragment(localize('maximumCharge')),
      template: r => (
        <>{rightAlignFragment(formatCurrency(r?.maximumCharge))}</>
      ),
      sortBy: 'maximumCharge',
    },
    {
      label: localize('roundingType'),
      template: r => <>{r?.roundingTypeDescription}</>,
      sortBy: 'roundingTypeDescription',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  const [selectedDirectDebit, setSelectedDirectDebit] = useState(null)
  const [mode, setMode] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [loadTabData, setLoadTabData] = useState(false)
  const [tBr, setTBr] = useState(null)
  const [tempTiers, setTempTiers] = useState([])
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DirectDebit/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const loadDirectDebitGraduatedScalesData = async r => {
    setLoadTabData(true)
    const { success, data } = await getData(
      '/DirectDebit/find-direct-debit-graduated-scales-by-direct-debit-id',
      { directDebitId: r.id },
      false
    )
    if (success) {
      setTempTiers(data)
    }
    setLoadTabData(false)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedDirectDebit({
      customerAccountTypeProductCode: Object.keys(ProductCode)[0],
      roundingType: Object.keys(RoundingType)[0],
    })
    setTBr({
      chargeType: Object.keys(ChargeType)[0],
      chargeTypeDescription: Object.values(ChargeType)[0].name,
    })
  }

  function closeModal() {
    setMode(null)
    setSelectedDirectDebit(null)
    setGlobalValidationErrors({})
    setTempTiers([])
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('miscellaneous'),
          localize('directDebits'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center ">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              text: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="payrollNumberTypesFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setSelectedDirectDebit(r)
                              loadDirectDebitGraduatedScalesData(r)
                              setTBr({
                                chargeType: Object.keys(ChargeType)[0],
                              })
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <DirectDebitsModal
          mode={mode}
          closeModal={closeModal}
          selectedDirectDebit={selectedDirectDebit}
          setSelectedDirectDebit={setSelectedDirectDebit}
          loadTableData={loadTableData}
          loadTabData={loadTabData}
          tBr={tBr}
          setTBr={setTBr}
          tempTiers={tempTiers}
          setTempTiers={setTempTiers}
        />
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}
export default DirectDebits
