/** @format */

import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { postData, putData } from '../../../../Helpers/webApi'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import { LoanProductLookup } from '../../../../Components/Lookup/LoanProductLookup'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import { MaskPercentage } from '../../../../Components/InputMasks/MaskPercentage'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import localize from '../../../../Global/Localize'
import LoanDisbursementModeLookup from '../../../../Components/Lookup/LoanDisbursementModeLookup'

const MobileLoansModal = ({
  mode,
  selectedMobileLoan,
  setSelectedMobileLoan,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showPercentageError, setShowPercentageError] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/MobileLoan/update-mobile-loan',
            selectedMobileLoan || {
              description: '',
            },
            false
          )
        : await postData(
            '/MobileLoan/create',
            selectedMobileLoan || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      closeModal()
      loadTableData()
      showNotification('Mobile Loans', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const close = () => {
    closeModal()
    setGlobalValidationErrors({})
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalTitle={'mobileLoan'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={close}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cMobileLoanIsLocked"
              onCheck={e => {
                setSelectedMobileLoan({
                  ...selectedMobileLoan,
                  isLocked: e,
                })
              }}
              checked={selectedMobileLoan?.isLocked}
            />
            <label htmlFor="cMobileLoanIsLocked" className="text-capitalize">
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 g-3">
            <div className="col-md-12">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-1">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedMobileLoan?.description}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    clearValidation('Description')
                    setSelectedMobileLoan({
                      ...selectedMobileLoan,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['LoanProductId']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('loanProduct')}
                </label>
                <LoanProductLookup
                  displayValue={selectedMobileLoan?.loanProductDescription}
                  onSelect={v => {
                    clearValidation('LoanProductId')
                    clearValidation('TermInMonths')
                    setSelectedMobileLoan(prev => ({
                      ...prev,
                      loanProductDescription: v.description,
                      loanProductId: v.id,
                      termInMonths: v.loanRegistrationTermInMonths,
                      annualPercentageRate: v.loanInterestAnnualPercentageRate,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['TermInMonths']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('term')}
                </label>
                <MaskNumber
                  defaultMaskValue={selectedMobileLoan?.termInMonths}
                  className={`
                       ${showError && 'border border-danger'}
                      `}
                  onMaskNumber={e => {
                    if (
                      Number(e.target.value) > 100 ||
                      Number(e.target.value) < 0
                    ) {
                      setShowError(true)
                    } else {
                      setShowError(false)
                    }
                    clearValidation('TermInMonths')
                    setSelectedMobileLoan({
                      ...selectedMobileLoan,
                      termInMonths: e.target.value,
                    })
                  }}
                  numeralThousandsGroupStyle="none"
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 align-items-center g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['LoanDisbursementModeId']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('disbursementMode')}
                </label>
                <LoanDisbursementModeLookup
                  displayValue={
                    selectedMobileLoan?.loanDisbursementModeDescription
                  }
                  onSelect={v => {
                    clearValidation('LoanDisbursementModeId')
                    setSelectedMobileLoan(prev => ({
                      ...prev,
                      loanDisbursementModeDescription: v.description,
                      loanDisbursementModeId: v.id,
                    }))
                  }}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['AnnualPercentageRate']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('annualPercentageRate')}
                </label>
                <MaskPercentage
                  disabled={false}
                  defaultMaskValue={selectedMobileLoan?.annualPercentageRate}
                  className={`
                       ${showPercentageError && 'border border-danger'}
                      `}
                  onMaskChange={e => {
                    if (
                      Number(e.target.value) > 100 ||
                      Number(e.target.value) < 0
                    ) {
                      setShowPercentageError(true)
                    } else {
                      setShowPercentageError(false)
                    }
                    clearValidation('AnnualPercentageRate')
                    setSelectedMobileLoan({
                      ...selectedMobileLoan,
                      annualPercentageRate: e.target.value,
                    })
                  }}
                  numeralThousandsGroupStyle="none"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default MobileLoansModal
