/** @format */
import localize from '../../../../../../../Global/Localize'
import AccountClosureRequestLookup from '../../../../../../../Components/Lookup/AccountClosureRequestLookup'
import { postData } from '../../../../../../../Helpers/webApi'
import React, { useState } from 'react'
import { formatCurrency } from '../../../../../../../Helpers/formatters'

export const SundryTransactionSectionAccountClosurePayment = ({
  transactionModel,
  loading,
  setTransactionModel,
}) => {
  const [accountClosureMetadata, setAccountClosureMetadata] = useState(null)

  const fetchPaymentAmount = async r => {
    const { success, data } = await postData(
      '/lookup/Teller/fetch-net-payable-account-closure',
      {
        ...r,
      },
      false,
      null
    )
    if (success) {
      setAccountClosureMetadata(data)
    }
  }

  return (
    <>
      <div
        className={`${
          loading ? '' : 'd-none'
        } col-12 d-flex align-items-center justify-content-center`}
      >
        <div className="m-3 spinner-border text-dark"></div>
        <span className="fs-4">Please Wait</span>
      </div>
      <div className={`${loading ? 'd-none' : ''}`}>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <AccountClosureRequestLookup
              displayValue={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountFullAccountNumber
              }
              onSelect={r => {
                fetchPaymentAmount(r)
                setTransactionModel({
                  ...transactionModel,
                  selectedAccountClosureRequest: { ...r },
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountStatusDescription
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountRemarks
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('customer')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountCustomerFullName
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">{localize('type')}</label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountCustomerTypeDescription
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('identificationNo')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountCustomerIdentificationNumber
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountNumber')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountCustomerReference1
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountCustomerReference2
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('pfNumber')}
            </label>
            <input
              className="form-control"
              disabled
              value={
                transactionModel?.selectedAccountClosureRequest
                  ?.customerAccountCustomerReference3
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('reason')}
            </label>
            <input
              className="form-control"
              disabled
              value={transactionModel?.selectedAccountClosureRequest?.reason}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('bookBalance')}
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                accountClosureMetadata?.bookBalance,
                2,
                2,
                false
              )}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('netPayable')}
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                accountClosureMetadata?.paymentAmount,
                2,
                2,
                false
              )}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">{localize('payee')}</label>
            <input
              className="form-control"
              value={transactionModel?.cashPaymentTransactionModel?.payee || ''}
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    payee: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('narration')}
            </label>
            <input
              value={
                transactionModel?.cashPaymentTransactionModel?.narration || ''
              }
              className="form-control"
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    narration: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('reference')}
            </label>
            <input
              value={
                transactionModel?.cashPaymentTransactionModel?.reference || ''
              }
              className="form-control"
              onChange={e => {
                setTransactionModel({
                  ...transactionModel,
                  cashPaymentTransactionModel: {
                    ...transactionModel.cashPaymentTransactionModel,
                    reference: e.target.value,
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
