/** @format */

import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import React, { useEffect, useRef, useState } from 'react'
import { BatchImportsEntries } from './Partials/BatchImportsEntries'
import ImportButton from '../../../../../Components/Buttons/ImportButton/ImportButton'
import { AlternateChannelReconciliationPeriodLookup } from '../../../../../Components/Lookup/AlternateChannelReconciliationLookup'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'
import { toBase64 } from '../../../../../Helpers/blobs'
import Swal from 'sweetalert2'
import { AlternateChannelReconciliationPeriodImportModal } from './Partials/AlternateChannelReconciliationPeriodImportModal'
import swal from 'sweetalert2'

function ChannelReconciliationProcessing() {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: '1',
    reconciliationOutcome: '0',
    filterText: '',
    discrepancyFilter: '1',
    pageIndex: 0,
    pageSize: 10,
  })
  const [selectedAlternateChannelPeriod, setSelectedAlternateChannelPeriod] =
    useState(null)
  const [showImportModal, setShowImportModal] = useState(false)
  const [newUpload, setNewUpload] = useState(false)
  const [fileName, setFileName] = useState('')
  const [mismatchedEntries, setMismatchedEntries] = useState([])

  const ref = useRef(null)

  const handleOnSelectAlternateChannelPeriod = r => {
    setSelectedAlternateChannelPeriod({ ...r })
  }

  const handleClickImportButton = () => {
    setShowImportModal(true)
    setNewUpload(true)
  }

  const handleFileUploadToServer = async () => {
    setIsBusy(true)
    setShowImportModal(false)
    const url = `/AlternateChannelReconciliationPeriod/parse-alternate-channel-reconciliation-import`

    const { success, data } = await putData(
      url,
      {
        alternateChannelReconciliationId: selectedAlternateChannelPeriod?.id,
        fileName: fileName?.fileName,
        buffer: fileName?.buffer,
      },
      false,
    )

    if (success) {
      swal.fire('Alternate Channel Reconciliation Period', 'Entries Imported Successfully', 'success')
      setFileName('')
      setMismatchedEntries(data?.result)
      setShowImportModal(false)
      setNewUpload(false)
      return fetchDiscrepancies()
    }
    setIsBusy(false)
  }

  const handleUpload = async () => {
    ref?.current?.click()

    ref.current.onchange = async e => {
      let file = e.target.files[0]
      try {
        let b64 = await toBase64(file)
        let buffer = b64.split(',')[1].toString()
        setFileName({
          buffer,
          fileName: file.name,
          fileExtension: 'csv',
        })
      } catch {
        Swal.fire('Invalid File', 'Error loading file', 'error')
        return
      }
    }
  }

  const fetchDiscrepancies = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AlternateChannelReconciliationPeriod/find-alternate-channel-reconciliation-discrepancies-by-alternate-channel-reconciliation-period-id-and-filter-in-page',
      {
        ...reqParams,
        alternateChannelReconciliationPeriodId:
          selectedAlternateChannelPeriod?.id,
      },
      false,
    )
    if (success) {
      setMismatchedEntries(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (fileName) handleFileUploadToServer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName, selectedAlternateChannelPeriod?.id, newUpload])

  useEffect(() => {
    fetchDiscrepancies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams,selectedAlternateChannelPeriod?.id])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('channelReconciliation'),
          localize('processing'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('batchNumber')}
                      </label>
                      <AlternateChannelReconciliationPeriodLookup
                        displayValue={
                          selectedAlternateChannelPeriod?.paddedBatchNumber
                        }
                        onSelect={handleOnSelectAlternateChannelPeriod}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('startDate')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={formatDate(
                          selectedAlternateChannelPeriod?.durationStartDate,
                          true,
                        )}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('endDate')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={formatDate(
                          selectedAlternateChannelPeriod?.durationEndDate,
                          true,
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('alternateChannelType')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedAlternateChannelPeriod?.alternateChannelTypeDescription
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('transactionBackingStore')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedAlternateChannelPeriod?.transactionBackingStoreDescription
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('discreteSetDifferenceMode')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedAlternateChannelPeriod?.setDifferenceModeDescription
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-1 text-capitalize">
                        {localize('remarks')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={selectedAlternateChannelPeriod?.remarks}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="p-2 mt-4"
                        style={{
                          border: 'solid 1px #0000001f',
                          borderRadius: 10,
                          position: 'relative',
                        }}
                      >
                        <div
                          className="bg-white px-2 row text-capitalize"
                          style={{
                            position: 'relative',
                            top: -20,
                            left: 30,
                            maxWidth: 120,
                          }}
                        >
                          {localize('batchImports')}
                        </div>
                        <BatchImportsEntries
                          entries={mismatchedEntries}
                          setReqParams={setReqParams}
                          reqParams={reqParams}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <div
                      className="bg-light d-flex justify-content-end align-items-center p-2"
                      style={{ borderRadius: 5 }}
                    >
                      <button className="btn btn-success text-uppercase ms-3 px-4 ">
                        {localize('remove')}
                      </button>
                      <button className="btn btn-success text-uppercase ms-3 px-4 me-3">
                        {localize('export')}
                      </button>
                      <ImportButton
                        handleClickImportButton={handleClickImportButton}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showImportModal && (
        <AlternateChannelReconciliationPeriodImportModal
          handleCloseCreditBatchImportModal={() => setShowImportModal(false)}
          handleUpload={handleUpload}
          ref={ref}
        />
      )}

      <input
        type="file"
        ref={ref}
        className="d-none"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </>
  )
}

export default ChannelReconciliationProcessing
