/** @format */

export const CONFIG = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  AUTHORITY: process.env.REACT_APP_AUTHORITY,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  POST_LOGOUT_REDIRECT_URI: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  RESPONSE_MODE: process.env.REACT_APP_RESPONSE_MODE,
  SCOPES: process.env.REACT_APP_SCOPES,
  AUTHENTICATION_ENABLED: process.env.REACT_APP_ENABLE_AUTHENTICATION,
  COMPANION_APP_URL: process.env.REACT_APP_COMPANION_APP_URL,
  SIGNAL_R_APP_URL: process.env.REACT_APP_SIGNAL_R_URL,
  SIGN_IN_REDIRECT: 'signinRedirect',
  SIGN_OUT_REDIRECT: 'signoutRedirect',
  SESSION_MACHINE_INFO: 'X-MACHINE',
  ADMIN: 'admin',
  REFRESH_MENU_EVENT: 'onRefreshMenus',
}

export const COLORS = {
  BLUE: '#3085d6',
  LIGHT_BLUE: '#00bfff',
  DEEP_BLUE: '#0000ff',
  RED: '#d33',
  LIGHT_RED: '#ff0000',
  GREEN: '#4caf50',
  LIGHT_GREEN: '#00ff00',
  YELLOW: '#ffeb3b',
  LIGHT_YELLOW: '#ffff00',
}

export const SESSION_KEY = `oidc.user:${CONFIG.AUTHORITY}:${CONFIG.CLIENT_ID}`

export const TALLY_MODES = {
  TALLY_BY_COUNT: 'tallyByCount',
  TALLY_BY_TOTAL: 'tallyByTotal',
}

export const MONEY = [
  {
    id: '1000x',
    value: 1000,
    name: '1000x',
    serverValue: 'denominationOneThousandValue',
  },
  {
    id: '500x',
    value: 500,
    name: '500x',
    serverValue: 'denominationFiveHundredValue',
  },
  {
    id: '200x',
    value: 200,
    name: '200x',
    serverValue: 'denominationTwoHundredValue',
  },
  {
    id: '100x',
    value: 100,
    name: '100x',
    serverValue: 'denominationOneHundredValue',
  },
  {
    id: '50x',
    value: 50,
    name: '50x',
    serverValue: 'denominationFiftyValue',
  },
  {
    id: '40x',
    value: 40,
    name: '40x',
    serverValue: 'denominationFourtyValue',
  },
  {
    id: '20x',
    value: 20,
    name: '20x',
    serverValue: 'denominationTwentyValue',
  },
  {
    id: '10x',
    value: 10,
    name: '10x',
    serverValue: 'denominationTenValue',
  },
  {
    id: '5x',
    value: 5,
    name: '5x',
    serverValue: 'denominationFiveValue',
  },
  {
    id: '1x',
    value: 1,
    name: '1x',
    serverValue: 'denominationOneValue',
  },
  {
    id: '0.5x',
    value: 0.5,
    name: '0.5x',
    serverValue: 'denominationFiftyCentValue',
  },
]

export const SYSTEM_PERMISSION_TYPES = {
  SAVINGS_WITHDRAWAL_AUTHORIZATION: 44992,
  CREDIT_BATCH_AUTHORIZATION: 44994,
  JOURNAL_VOUCHER_AUTHORIZATION: 44995,
  REFUND_BATCH_AUTHORIZATION: 44996,
  DEBIT_BATCH_AUTHORIZATION: 44997,
  LOAN_DISBURSEMENT_BATCH_AUTHORIZATION: 45036,
  JOURNAL_REVERSAL_BATCH_AUTHORIZATION: 45037,
  INTER_ACCOUNT_TRANSFER_BATCH_AUTHORIZATION: 45041,
  EXPENSE_PAYABLES_AUTHORIZATION: 45042,
  SALARY_PERIOD_VERIFICATION: 45143,
  SALARY_PERIOD_AUTHORIZATION: 44999,
  BOSA_LOAN_VERIFICATION_CANCELLATION: 45011,
  BOSA_LOAN_APPRAISAL: 45012,
  BOSA_LOAN_APPROVAL: 45013,
  BOSA_LOAN_REGISTRATION: 45014,
  BOSA_LOAN_RESTRUCTURING: 45017,
  FOSA_LOAN_VERIFICATION_CANCELLATION: 45007,
  FOSA_LOAN_APPRAISAL: 45008,
  FOSA_LOAN_APPROVAL: 45009,
  FOSA_LOAN_REGISTRATION: 45010,
  FOSA_LOAN_RESTRUCTURING: 45016,
  ADMINISTRATIVE_CUSTOMER_VIEWING: 45002,
  MEMBERSHIP_WITHDRAWAL_PROCESSING: 45003,
  OPERATIONS_MAKER: 45004,
  OPERATIONS_CHECKER: 45005,
  ALTERNATE_CHANNELS_RECONCILIATION: 45018,
  ALTERNATE_CHANNEL_LINKING_MOBILE_BANKING: 45019,
  ALTERNATE_CHANNEL_REPLACEMENT_MOBILE_BANKING: 45020,
  ALTERNATE_CHANNEL_RENEWAL_MOBILE_BANKING: 45021,
  ALTERNATE_CHANNEL_DELINKING_MOBILE_BANKING: 45022,
  ALTERNATE_CHANNEL_STOPPAGE_MOBILE_BANKING: 45055,
  CUSTOMER_ACCOUNT_ACTIVATION: 45023,
  CUSTOMER_ACCOUNT_FREEZING: 45024,
  CUSTOMER_ACCOUNT_REMARK: 45025,
  PAYMENT_VOUCHER_MANAGEMENT: 45026,
  MICROCREDIT_APPORTIONMENT: 45027,
  TRUNCATED_CHEQUES_PROCESSING: 45028,
  LEAVE_MANAGEMENT: 45029,
  CREDIT_BATCH_VERIFICATION: 45030,
  JOURNAL_VOUCHER_VERIFICATION: 45031,
  REFUND_BATCH_VERIFICATION: 45032,
  DEBIT_BATCH_VERIFICATION: 45033,
  WIRE_TRANSFER_BATCH_VERIFICATION: 45034,
  LOAN_DISBURSEMENT_BATCH_VERIFICATION: 45035,
  JOURNAL_REVERSAL_BATCH_VERIFICATION: 45038,
  CREDIT_BATCH_DISCREPANCY_RESOLUTION: 45039,
  INTER_ACCOUNT_TRANSFER_BATCH_VERIFICATION: 45040,
  EXPENSE_PAYABLES_VERIFICATION: 45043,
  INTRA_ACCOUNT_TRANSFER: 45044,
  FIXED_DEPOSIT_TERMINATION: 45046,
  FIXED_DEPOSIT_LIQUIDATION: 45047,
  FIXED_DEPOSIT_VERIFICATION: 45060,
  FIXED_DEPOSIT_AUTHORIZATION: 45144,
  MOBILE_TO_BANK_RECONCILIATION: 45048,
  SUPPRESS_MAKER_CHECKER_VALIDATION: 45049,
  INTRA_ACCOUNT_APPORTIONMENT_INVESTMENT_TO_SAVINGS: 45050,
  INTRA_ACCOUNT_APPORTIONMENT_INVESTMENT_TO_LOAN: 45051,
  INTRA_ACCOUNT_APPORTIONMENT_INVESTMENT_TO_INVESTMENT: 45052,
  GENERAL_LEDGER_VERIFICATION: 45053,
  GENERAL_LEDGER_AUTHORIZATION: 45054,
  SUNDRY_CASH_PAYMENT_AUTHORIZATION: 45056,
  CUSTOMER_ACCOUNT_VERIFICATION: 45057,
  MEMBER_VERIFICATION: 45058,
  ALTERNATE_CHANNEL_VERIFICATION: 45059,
  GOODS_DISPATCH_NOTE_ORIGINATION: 45066,
  GOODS_DISPATCH_NOTE_CANCELLATION: 45067,
  ASSET_DISPOSAL_VERIFICATION: 45070,
  ASSET_DISPOSAL_AUTHORIZATION: 45071,
  SUPER_SAVER_PAYABLE_VERIFICATION: 45072,
  SUPER_SAVER_PAYABLE_AUTHORIZATION: 45073,
  FUNERAL_RIDER_CLAIM_PAYABLE_VERIFICATION: 45074,
  FUNERAL_RIDER_CLAIM_PAYABLE_AUTHORIZATION: 45075,
  EMPLOYEE_APPRAISER: 45077,
  MEMBER_STATEMENT_PRINTING: 45078,
  STANDING_ORDER_EDITING: 45079,
  EMPLOYEE_EXIT_VERIFICATION: 45080,
  EMPLOYEE_EXIT_AUTHORIZATION: 45081,
  CARRY_FORWARDS_MANAGEMENT: 45087,
  CARRY_FORWARD_INSTALLMENT_EDITING: 45088,
  MOBILE_TO_BANK_RECONCILIATION_VERIFICATION: 45089,
  MEMBER_REGISTRATION: 45090,
  MEMBER_EDITING_MODIFICATION: 45091,
  SAVINGS_DEPOSIT_AUTHORIZATION: 45092,
  EXTERNAL_CHEQUE_CLEARANCE: 45093,
  CUSTOMER_ACCOUNT_SIGNING_INSTRUCTIONS: 45094,
  LOAN_INTEREST_ADJUSTMENT: 45095,
  ENROLLMENT_REQUEST_ORIGINATION: 45096,
  ENROLLMENT_REQUEST_VERIFICATION: 45097,
  ENROLLMENT_REQUEST_AUTHORIZATION: 45098,
  BANKER_S_CHEQUE_MANAGEMENT: 45099,
  BIOMETRIC_REGISTER_MANAGEMENT: 45100,
  EXEMPTIONS_EXCEPTIONS_MANAGEMENT: 45103,
  DEBIT_CARD_REGISTER_MANAGEMENT: 45104,
  EMPLOYEE_REFERRALS_MANAGEMENT: 45105,
  EMPLOYEE_PAYSLIP_PRINTING: 45106,
  BANK_TO_BANK_RECONCILIATION_VERIFICATION: 45107,
  BANK_TO_BANK_RECONCILIATION: 45108,
  LOAN_DISBURSEMENT_QUEUE_POSTING: 45109,
  LOAN_DISBURSEMENT_QUEUE_TRANSFER: 45110,
  PARTIALLY_OPEN_MONTH_POSTING: 45111,
  CASH_MANAGEMENT_REQUEST_AUTHORIZATION: 45112,
  CHANNEL_ACTIVATION_REQUEST_VERIFICATION: 45113,
  BULK_MESSAGE_BATCH_VERIFICATION: 45114,
  BULK_MESSAGE_BATCH_AUTHORIZATION: 45115,
  CONFIDENTIAL_G_L_ACCOUNT_VIEWING: 45116,
  CHANNEL_ACTIVATION_REQUEST_ORIGINATION: 45117,
  PETTY_CASH_REQUEST_AUTHORIZATION: 45118,
  WITHDRAWAL_SETTLEMENT_BATCH_VERIFICATION: 45119,
  WITHDRAWAL_SETTLEMENT_BATCH_AUTHORIZATION: 45120,
  EMOLUMENT_BATCH_VERIFICATION: 45121,
  EMOLUMENT_BATCH_AUTHORIZATION: 45122,
  PETTY_CASH_REQUISITION_AUTHORIZATION: 45123,
  CUSTOMER_ACCOUNT_OVERDRAFT_PROTECTION_MANAGEMENT: 45124,
  ALTERNATE_CHANNEL_PIN_RESET: 45125,
  ALTERNATE_CHANNEL_UNBLOCKING: 45126,
  ACCRUED_INTEREST_BATCH_VERIFICATION: 45127,
  ACCRUED_INTEREST_BATCH_AUTHORIZATION: 45128,
  ALTERNATE_CHANNEL_LINKING_AGENCY_BANKING: 45129,
  ALTERNATE_CHANNEL_REPLACEMENT_AGENCY_BANKING: 45130,
  ALTERNATE_CHANNEL_RENEWAL_AGENCY_BANKING: 45131,
  ALTERNATE_CHANNEL_DELINKING_AGENCY_BANKING: 45132,
  ALTERNATE_CHANNEL_STOPPAGE_AGENCY_BANKING: 45133,
  ALTERNATE_CHANNEL_LINKING_ATM: 45134,
  ALTERNATE_CHANNEL_REPLACEMENT_ATM: 45135,
  ALTERNATE_CHANNEL_RENEWAL_ATM: 45136,
  ALTERNATE_CHANNEL_DELINKING_ATM: 45137,
  ALTERNATE_CHANNEL_STOPPAGE_ATM: 45138,
  BIOMETRIC_REGISTRATION: 45139,
  BIOMETRIC_EXEMPTION_MANAGEMENT: 45140,
  LOAN_RESCHEDULE_VERIFICATION: 45141,
  LOAN_RESCHEDULE_AUTHORIZATION: 45142,
  EMPLOYEE_TEMPORARY_ROLE_VERIFICATION: 45145,
  INTRA_ACCOUNT_LOAN_TRANSFER: 45146,
  REFUND_PROCESSING: 45147,
}

export const GeneralLedgerTransactionType = {
  CASH_RECEIPT: '1',
  CHEQUE_RECEIPT: '2',
  CASH_PAYMENT: '4',
  CASH_PICKUP: '8',
  SUNDRY_PAYMENT: '16',
  CASH_PAYMENT_ACCOUNT_CLOSURE: '32',
  FUNERAL_RIDER_CLAIM: '64',
  PETTY_CASH_REQUISITION: '128',
}
export const GeneralCashTransactionCategory = {
  SUNDRY: '0',
}

export const ApportionTo = {
  CUSTOMER_ACCOUNT: '1',
  GENERAL_LEDGER_ACCOUNT: '2',
}

export const ApportionedComponent = {
  PRINCIPAL: '1',
  INTEREST: '2',
  AGGREGATE: '4',
}

export const CashTransferTransactionType = {
  TELLER_TO_TREASURY: '1',
  TELLER_TO_TELLER: '2',
  TELLER_END_OF_DAY: '4',
  TELLER_POS_TO_TREASURY: '8',
}

export const ELECTRONIC_JOURNAL_STATUS = {
  OPEN: 1,
  CLOSED: 2,
}

export const ExpensePayableType = {
  DEBIT_GL_ACCOUNT: '1',
  CREDIT_GL_ACCOUNT: '2',
  DEBIT_SUPPLIER: '4',
  CREDIT_SUPPLIER: '8',
  DEBIT_ASSET: '16',
  CREDIT_ASSET: '32',
  DEBIT_CUSTOMER_ACCOUNT: '64',
  CREDIT_CUSTOMER_ACCOUNT: '128',
}

export const ExpensePayableEntry = {
  GL_ACCOUNT: '1',
  SUPPLIER: '2',
  ASSET: '4',
  CUSTOMER_ACCOUNT: '8',
}

export const ItemRegisterType = {
  ASSET: '1',
  INVENTORY: '2',
}

export const ExpensePayableStatus = {
  PENDING: '1',
  POSTED: '2',
  REJECTED: '4',
  VERIFIED: '8',
  DEFERRED: '16',
}

export const alternateChannelManagementAction = {
  CHANNEL_LINKING: {
    value: 48830,
    label: 'Channel Linking',
  },
  REPLACEMENT: {
    value: 48831,
    label: 'Channel Replacement',
  },
  RENEWAL: {
    value: 48832,
    label: 'Channel Renewal',
  },
  DELINKING: {
    value: 48836,
    label: 'Channel Delinking',
  },
  STOPPAGE: {
    value: 48837,
    label: 'Channel Stoppage',
  },
}

export const productCode = {
  SAVINGS: 1,
  LOAN: 2,
  INVESTMENT: 3,
}

export const alternateChannelType = {
  ALL: 0,
  SACCOLINK: 1,
  SPARROW: 2,
  MCOOPCASH: 4,
  SPOTCASH: 8,
  CITIUS: 16,
  AGENCYBANKING: 32,
  PESAPEPE: 64,
  ABCBANK: 128,
  BROKER: 256,
  INTERSWITCH: 512,
}

export const recordAuthOption = {
  APPROVE: 1,
  REJECT: 2,
}

export const debitCardStatus = {
  NEW: 0,
  EDITED: 1,
  APPROVED: 2,
  REJECTED: 3,
  LINKED: 4,
}

export const MicroCreditGroupType = {
  ROSCA: '1',
  ASCA: '2',
  TableBanking: '4',
}

export const MeetingFrequency = {
  SEMI_ANNUAL: '2',
  TRI_ANNUAL: '3',
  QUARTELY: '4',
  BI_MONTHLY: '6',
  MONTHLY: '12',
  SEMI_MONTHLY: '24',
  BI_WEEKLY: '26',
  WEEKLY: '52',
}

export const MeetingDayOfWeek = {
  SUNDAY: '0',
  MONDAY: '1',
  TUESDAY: '2',
  WEDNESDAY: '3',
  THURSDAY: '4',
  FRIDAY: '5',
  SATURDAY: '6',
}

export const MicroCreditGroupMemberDesignation = {
  CHAIR_PERSON: '1',
  DEPUTY_CHAIR_PERSON: '2',
  SECRETARY: '4',
  DEPUTY_SECRETART: '8',
  TREASURER: '16',
  ORDINARY_MEMBER: '32',
  PATRON: '64',
}

export const funeralHomeTransactionType = {
  CASH_RECEIPT: {
    label: 'Cash Receipt',
    value: '0',
  },
  CASH_RECEIPT_PATIENT: {
    label: 'Patient Cash Receipt',
    value: '1',
  },
  CASH_PAYMENT: {
    label: 'Cash Payment',
    value: '2',
  },
  PATIENT_REFUND: {
    label: 'Patient Refund',
    value: '3',
  },
}

export const chartOfAccountCategory = {
  HeaderACCOUNT: '4096',
  DetailACCOUNT: '4097',
}

export const morguePatientSampleRecordStatus = {
  TAKEN: '0',
  RETURNED: '1',
  DISPOSED: '2',
}

export const vehicleFleetProductType = {
  SAVINGS_PRODUCT: '0',
  INVESTMENT_PRODUCT: '1',
}

export const vehiclePersonnelType = {
  DRIVER: '0',
  CONDUCTOR: '1',
}

export const WORK_FLOW_TYPE = {
  REQUISITION_ORIGINATION: '0',
  PURCHASE_ORDER_ORIGINATION: '1',
  GOODS_RECEIVED_NOTE_ORIGINATION: '2',
  INVOICE_ORIGINATION: '3',
  LEAVE_APPLICATION_ORIGINATION: '4',
  PURCHASE_REQUISITION_ORIGINATION: '5',
  DIRECT_PURCHASE_ORIGINATION: '6',
}

export const apportionTo = {
  CUSTOMER_ACCOUNT: '1',
  GENERAL_LEDGER_ACCOUNT: '2',
}

export const apportionedComponent = {
  PRINCIPAL: '1',
  INTEREST: '2',
  AGGREGATE: '4',
}

export const journalEntryReferenceType = {
  MORGUE_PATIENT: '0',
  SUPPLIER: '1',
  ITEM_REGISTER: '2',
  VEHICLE_PRODUCT: '3',
  FIXED_DEPOSIT: '4',
  SALARY_NET_PAY: '5',
}

export const TellerType = {
  EMPLOYEE: '0',
  ATM_TERMINAL: '1',
  POS_TERMINAL_IN_HOUSE: '2',
  POS_TERMINAL_AGENT: '3',
}

export const EmploymentStatus = {
  ACTIVE: '0',
  RESIGNED: '1',
  DISMISSED: '2',
  RETIRED: '3',
  RETRENCHED: '4',
}

export const employerType = {
  HEADER_ENTRY: 0,
  DETAIL_ENTRY: 1,
}
export const dutyStationType = {
  HEADER_ENTRY: 0,
  DETAIL_ENTRY: 1,
}

export const administrativeDivisionType = {
  HEADER_ENTRY: 0,
  DETAIL_ENTRY: 1,
}

export const customerType = {
  INDIVIDUAL: '0',
  PARTNERSHIP: '1',
  CORPORATION: '2',
  GROUP: '3',
}

export const electoralZoneType = {
  HEADER_ENTRY: '0',
  DETAIL_ENTRY: '1',
}

export const reportTemplateCategory = {
  HEADER_ENTRY: '4096',
  DETAIL_ENTRY: '4097',
}

export const maturityDaysCategory = {
  BUSINESS_DAYS: '0',
  WORKING_DAYS: '1',
}

export const chequeTypeChargeRecoveryMode = {
  ON_CHEQUE_DEPOSIT: '0',
  ON_CHEQUE_CLEARANCE: '1',
}

export const TransactionOwnership = {
  BeneficiaryBranch: '0',
  InitiatingBranch: '1',
}

export const EmolumentAutowireMedium = {
  Mpesa: '0',
}

export const ExternalAgencyType = {
  MPESA: '0',
  COOP_BANK: '1',
  WESTERN_UNION: '2',
  MONEYGRAM: '3',
  AIRTEL_MONEY: '4',
  NATIONAL_BANK: '5',
  EQUITY_BANK: '6',
  FAMILY_BANK: '7',
  KENYA_COMMERCIAL_BANK: '8',
}

export const pdfStatementType = {
  CUSTOMER_ACCOUNT: '0',
  GENERAL_LEDGER_ACCOUNT: '1',
}

export const LoanPurposeCategory = {
  HEADER_ENTRY: '4096',
  DETAIL_ENTRY: '4097',
}

export const IncomeAdjustmentType = {
  ALLOWANCE: '64222',
  DEDUCTION: '64223',
  CLEARED_EFFECT: '64224',
}

export const standingOrderTrigger = {
  PAYOUT: '0',
  CHECK_OFF: '1',
  SCHEDULE: '2',
  SWEEP: '3',
  MICROLOAN: '4',
  AD_HOC: '5',
  OTHER: '6',
}

export const LoanClassification = {
  PERFORMING: 0,
  WATCH: 1,
  SUBSTANDARD: 2,
  DOUBTFUL: 3,
  LOSS: 4,
}

export const batchEntryStatus = {
  PENDING: '1',
  POSTED: '2',
  REJECTED: '4',
  REVERSED: '8',
  EXCEPTION_RAISED: '16',
}

export const queuePriority = {
  LOWEST: '0',
  VERY_LOW: '1',
  LOW: '2',
  NORMAL: '3',
  ABOVE_NORMAL: '4',
  HIGH: '5',
  VERY_HIGH: '6',
  HIGHEST: '7',
}

export const guarantorAttachmentMode = {
  PRO_RATA_PERCENTAGE: '0',
  EQUAL_PORTIONS: '1',
  MANUAL_ALLOCATION: '2',
}

export const chargeType = {
  PERCENTAGE: '1',
  FIXED_AMOUNT: '2',
}

export const employeeFilter = {
  MEMBER_NUMBER: '0',
  ACCOUNT_NUMBER: '1',
  PERSONAL_FILE_NUMBER: '2',
  PERSONAL_IDENTIFICATION_NUMBER: '3',
  FIRST_NAME: '4',
  LAST_NAME: '5',
  IDENTITY_CARD_NUMBER: '6',
  ADDRESS_LINE_1: '7',
  ADDRESS_LINE_2: '8',
  PHYSICAL_ADDRESS: '9',
  POSTAL_CODE: '10',
  CITY: '11',
  EMAIL: '12',
  LAND_LINE: '13',
  MOBILE_LINE: '14',
  NSSF_NUMBER: '18',
  NHIF_NUMBER: '19',
  REMARKS: '20',
  APPLICATION_USER_NAME: '21',
}

export const roomType = {
  ORDINARY: '0',
  VIP: '1',
}

export const userNameProvider = {
  USER_DEFINED: '0',
  ACTIVE_DIRECTORY: '1',
}

export const roundingType = {
  NO_ROUNDING: '0',
  TO_EVEN: '1',
  MIDPOINT_AWAY_FROM_ZERO: '2',
  CEILING: '3',
  FLOOR: '4',
  CEILING_TO_THE_NEAREST_TEN: '5',
  FLOOR_TO_THE_NEAREST_FIFTY: '6',
}

export const recordStatus = {
  NEW: '0',
  EDITED: '1',
  APPROVED: '2',
  REJECTED: '3',
  ALL: '4',
}

export const itemRegisterType = {
  ASSET: '0',
  INVENTORY: '1',
}

export const chartOfAccountType = {
  ASSET: '1000',
  LIABILITY: '2000',
  EQUITY: '3000',
  INCOME: '4000',
  EXPENSE: '5000',
}

export const customerAutoCompleteFilter = {
  MEMBER_NUMBER: '0',
  ACCOUNT_NUMBER: '1',
  PERSONAL_FILE_NUMBER: '2',
  IDENTITY_CARD_NUMBER: '3',
  MOBILE_LINE: '4',
  REFERENCE1: '23',
}

export const withdrawalNotificationStatus = {
  REGISTERED: '1',
  APPROVED: '2',
  VERIFIED: '4',
  WITHDRAWAL_SETTLED: '8',
  DEFERRED: '16',
  DEATH_CLAIM_SETTLED: '32',
  INVALIDATED: '64',
}

export const batchStatus = {
  PENDING: '1',
  POSTED: '2',
  REJECTED: '4',
  VERIFIED: '8',
  DEFERRED: '16',
}

export const loanGuarantorStatus = {
  ATTACHED: '0',
  RELEASED: '1',
  PARTIALLY_RELEASED: '2',
}
