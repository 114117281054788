/** @format */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../Components/Date/DatePicker'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import TerminateFixedDeposit from './partials/TerminateFixedDeposit'

const FixedDepositsTermination = () => {
  const columns = [
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('type'),
      template: r => r.fixedDepositTypeDescription,
    },
    {
      label: localize('category'),
      template: r => r.categoryDescription,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r.customerAccountFullAccountNumber,
    },
    {
      label: localize('productName'),
      template: r => r.productDescription,
    },
    {
      label: localize('customerName'),
      template: r => r.customerAccountCustomerFullName,
    },
    {
      label: localize('value'),
      template: r => formatCurrency(r.value),
    },
    {
      label: localize('term(month)'),
      template: r => r.term,
    },
    {
      label: localize('annualPercentageRate'),
      template: r => formatAsPercent(r.rate),
    },
    {
      label: localize('maturityAction'),
      template: r => r.maturityActionDescription,
    },
    {
      label: localize('maturityDate'),
      template: r => formatDate(r.maturityDate),
    },
    {
      label: localize('expectedInterest'),
      template: r => formatCurrency(r.expectedInterest),
    },
    {
      label: localize('totalExpected'),
      template: r => formatCurrency(r.totalExpected),
    },
    {
      label: localize('isPayable'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.isPayable?.toString())}
        </span>
      ),
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => r.auditRemarks,
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => r.auditedBy,
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => formatDate(r.auditedDate),
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => r.authorizationRemarks,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => r.authorizeddBy,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => formatDate(r.authorizedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedFixedDeposit, setSelectedFixedDeposit] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setLoading(true)
    const { success, data } = await getData(
      '/FixedDeposit/find-revocable-fixed-deposits-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setLoading(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('fixedDepositTermination'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="row">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-3"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center  me-4">
                        <label
                          className="text-capitalize col-3"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-6 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label
                        className="col-1 ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        value={reqParams?.customerFilter}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                          })
                        }
                      />
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="fixedDepositTerminationFilterButton"
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('terminate')
                              setSelectedFixedDeposit(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-lock-alt"></i>
                            {localize('terminate')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <TerminateFixedDeposit
          selectedFixedDeposit={selectedFixedDeposit}
          setSelectedFixedDeposit={setSelectedFixedDeposit}
          mode={mode}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      )}
    </>
  )
}
export default FixedDepositsTermination
