/** @format */

import localize from '../../../../Global/Localize'
import LoanPurposeLookup from '../../../../Components/Lookup/LoanPurposeLookup'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import React from 'react'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'

export const CreateLoanPurposesModal = ({
  setSelectedLoanPurpose,
  selectedLoanPurpose,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('parentPurpose')}
              </label>
              <LoanPurposeLookup
                canSelectHeader
                displayValue={selectedLoanPurpose?.parentDescription}
                onSelect={e => {
                  setSelectedLoanPurpose({
                    ...selectedLoanPurpose,
                    parentId: e.id,
                    parentDescription: e.description,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('category')}
              </label>
              <EnumsServerCombo
                value={selectedLoanPurpose?.category}
                customContainerStyle={{ width: '100%' }}
                enumsKey={'LoanPurposeCategory'}
                onChange={e => {
                  setSelectedLoanPurpose({
                    ...selectedLoanPurpose,
                    category: e.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('code')}
              </label>
              <MaskNumber
                numeralThousandsGroupStyle="none"
                defaultMaskValue={selectedLoanPurpose?.code}
                onMaskNumber={e => {
                  setSelectedLoanPurpose({
                    ...selectedLoanPurpose,
                    code: e.target.value,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedLoanPurpose?.description}
                onChange={e => {
                  setSelectedLoanPurpose({
                    ...selectedLoanPurpose,
                    description: e.target.value,
                  })
                  clearValidation('Description')
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
