/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../ModalTemplate/ModalTemplate'
import JournalEntriesTab from '../../JournalEntries/JournalEntriesTab'
import localize from '../../../../Global/Localize'
import { formatCurrency, parseDate } from '../../../../Helpers/formatters'
import Tab from '../../../Tabs/Tab'

function ViewSystemTransaction({
  transaction,
  handleClose,
  mode,
  title,
  modalTitle,
}) {
  return (
    <>
      <ModalTemplate
        modalClassName="modal-xl"
        modalTitle={modalTitle || 'systemTransaction'}
        hideUpdate
        modalMode={mode}
        cancelText="close"
        handleClose={handleClose}
      >
        <div className="row gy-3 mb-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="transactionCode"
                className="text-capitalize col-4"
              >
                {localize('transactionCode')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="transactionCode"
                value={transaction?.transactionCodeDescription}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="branch" className="text-capitalize col-4">
                {localize('branch')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="branch"
                value={transaction?.branchDescription}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="postingPeriod" className="text-capitalize col-4">
                {localize('postingPeriod')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="postingPeriod"
                value={transaction?.postingPeriodDescription}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="primary" className="text-capitalize col-4">
                {localize('primaryDescription')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="primary"
                value={transaction?.primaryDescription}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="secondary" className="text-capitalize col-4">
                {localize('secondaryDescription')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="secondary"
                value={transaction?.secondaryDescription}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="reference" className="text-capitalize col-4">
                {localize('reference')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="reference"
                value={transaction?.reference}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="totalValue" className="text-capitalize col-4">
                {localize('totalValue')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2 text-end"
                id="totalValue"
                value={formatCurrency(
                  transaction?.totalValue,
                  null,
                  null,
                  false
                )}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="valueDate" className="text-capitalize col-4">
                {localize('valueDate')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="valueDate"
                value={parseDate(transaction?.valueDate, true)}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="localCurrency" className="text-capitalize col-4">
                {localize('localCurrency')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="localCurrency"
                value={transaction?.localCurrencyDescription}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="applicationUserName"
                className="text-capitalize col-4"
              >
                {localize('applicationUserName')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="applicationUserName"
                value={transaction?.applicationUserName}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentUserName"
                className="text-capitalize col-4"
              >
                {localize('environmentUserName')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentUserName"
                value={transaction?.environmentUserName}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentMachineName"
                className="text-capitalize col-4"
              >
                {localize('environmentMachineName')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentMachineName"
                value={transaction?.environmentMachineName}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentDomainName"
                className="text-capitalize col-4"
              >
                {localize('environmentDomainName')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentDomainName"
                value={transaction?.environmentDomainName}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentOSVersion"
                className="text-capitalize col-4"
              >
                {localize('environmentOSVersion')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentOSVersion"
                value={transaction?.environmentOSVersion}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentMACAddress"
                className="text-capitalize col-4"
              >
                {localize('environmentMACAddress')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentMACAddress"
                value={transaction?.environmentMACAddress}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentMotherboardSerialNumber"
                className="text-capitalize col-4"
              >
                {localize('environmentMotherboardSerialNumber')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentMotherboardSerialNumber"
                value={transaction?.environmentMotherboardSerialNumber}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentProcessorId"
                className="text-capitalize col-4"
              >
                {localize('environmentProcessorId')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentProcessorId"
                value={transaction?.environmentProcessorId}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="environmentIPAddress"
                className="text-capitalize col-4"
              >
                {localize('environmentIPAddress')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="environmentIPAddress"
                value={transaction?.environmentIPAddress}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="createdDate" className="text-capitalize col-4">
                {localize('createdDate')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="createdDate"
                value={parseDate(transaction?.createdDate)}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="integrityHash" className="text-capitalize col-4">
                {localize('integrityHash')}
              </label>
              <input
                type="text"
                disabled
                className="form-control ms-2"
                id="integrityHash"
                value={transaction?.integrityHash}
              />
            </div>
          </div>
        </div>
        <Tab
          tabItems={[
            {
              label: localize(title || 'journalEntries'),
              item: <JournalEntriesTab journalId={transaction?.id} />,
            },
          ]}
        />
      </ModalTemplate>
    </>
  )
}

export default ViewSystemTransaction
