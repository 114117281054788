/** @format */
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import EmployeeLookup from '../../../../Components/Lookup/EmployeeLookup'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

export const AddMicroCreditOfficerModal = ({
  setSelectedMicroCreditOfficer,
  selectedMicroCreditOfficer,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.EmployeeId?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('employee')}
              </label>
              <EmployeeLookup
                displayValue={
                  selectedMicroCreditOfficer?.employeeCustomerFullName
                }
                onSelect={e => {
                  clearValidation('EmployeeId')
                  setSelectedMicroCreditOfficer({
                    ...selectedMicroCreditOfficer,
                    employeeId: e.id,
                    employeeDesignationDescription: e.designationDescription,
                    employeeBranchDescription: e.branchDescription,
                    employeeDepartmentDescription: e.departmentDescription,
                    employmentStatusDescription: e.employmentStatusDescription,
                    employeeTypeDescription: e.employeeTypeDescription,
                    employeeCustomerFullName: e.customerFullName,
                    employeeCustomerId: e.id,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('designation')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedMicroCreditOfficer?.employeeDesignationDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedMicroCreditOfficer?.employeeBranchDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={
                  selectedMicroCreditOfficer?.employeeDepartmentDescription
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('employeeType')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedMicroCreditOfficer?.employeeTypeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('employmentStatus')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedMicroCreditOfficer?.employmentStatusDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                onChange={e => {
                  setSelectedMicroCreditOfficer({
                    ...selectedMicroCreditOfficer,
                    remarks: e.target.value,
                  })
                }}
                value={selectedMicroCreditOfficer?.remarks}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
