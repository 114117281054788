/** @format */

/* eslint-disable */
import React, { useState, useContext } from 'react'

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import languages from '../../Global/languages'
import { LanguageContext } from '../../Contexts/LanguageContext'

function LanguageDropdown() {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const [menu, setMenu] = useState(false)

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block language-switch"
      >
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={selectedLanguage.flag}
            alt={selectedLanguage.flag}
            height="16"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end bg-white" end>
          {Object.entries(languages).map(l => (
            <DropdownItem
              key={l[0]}
              onClick={() => {
                setSelectedLanguage(l[1])
              }}
              className={`notify-item ${
                selectedLanguage.label === l[1].label ? 'active' : 'none'
              }`}
            >
              <img src={l[1].flag} alt="Minible" className="me-1" height="12" />
              <span className="align-middle">{l[1].label}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default LanguageDropdown
