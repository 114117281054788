/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  batchAuthOptions,
  BatchEntryStatus,
  CreditBatchEntryFilter,
} from '../../../../../../Global/enumeration'
import { getData } from '../../../../../../Helpers/webApi'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { FiltersContainer } from '../../../../../../Components/Tables/FiltersContainer'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'

export const BatchEntriesVAuthorizationAuthorize = ({
  selectedBatchEntry,
  refreshBatchEntries,
  onSelectBatchEntry,
  verificationModels,
  setVerificationModels,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    creditBatchId: selectedBatchEntry?.id,
    status: Object.keys(BatchEntryStatus)[0],
    filterText: '',
    creditBatchEntryFilter: Object.keys(CreditBatchEntryFilter)[19],
    pageIndex: 0,
    pageSize: 10,
  })
  const [batchEntries, setBatchEntries] = useState([])

  const fetchBatchEntries = async id => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batch-entries-by-credit-batch-id-in-page',
      { ...reqParams, creditBatchId: id },
      false
    )
    if (success) {
      setIsBusy(false)
      setBatchEntries(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    if (selectedBatchEntry) {
      fetchBatchEntries(selectedBatchEntry?.id)
    }
  }, [
    selectedBatchEntry?.id,
    refreshBatchEntries,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    { label: localize('beneficiary'), template: r => <>{r.beneficiary}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    { label: localize('balance'), template: r => <>{r.balance}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },

    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <FiltersContainer>
            <div className="d-flex align-item-center">
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchEntryStatus"
                value={reqParams?.status}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }
              />
            </div>
            <div className="d-flex align-item-center">
              <label className="text-capitalize me-3">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CreditBatchEntryFilter"
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    creditBatchEntryFilter: e.value,
                  })
                }}
                sort
              />
            </div>
            <div className="d-flex align-items-center">
              <input
                className="form-control"
                value={reqParams?.filterText}
                onChange={e => {
                  setReqParams({ ...reqParams, filterText: e.target.value })
                }}
              />
            </div>
          </FiltersContainer>
          <SimpleTable columns={columns} data={batchEntries.pageCollection} />
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('apportioned')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  batchEntries.totalApportioned,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('shortage')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(batchEntries.totalShortage, 2, 2, false)}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('entries')}
              </label>
              <input
                className="form-control"
                disabled
                value={batchEntries.statusEntries}
              />
            </div>
          </div>
          <Pager
            itemsCount={batchEntries?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3 bg-light p-2">
            <div className="d-flex align-items-center me-5">
              <label className="text-capitalize ms-2 me-3">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                value={verificationModels?.authorizationRemarks}
                onChange={e => {
                  setVerificationModels({
                    ...verificationModels,
                    authorizationRemarks: e.target.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label className=" text-capitalize col-3 me-3">
                {localize('action')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchAuthOption"
                onChange={e => {
                  setVerificationModels({
                    ...verificationModels,
                    authOption: e.value,
                  })
                }}
                sort
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export const BatchEntriesAuthorizationViewMode = ({
  selectedBatchEntry,
  refreshBatchEntries,
  onSelectBatchEntry,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    creditBatchId: selectedBatchEntry?.id,
    status: Object.keys(BatchEntryStatus)[0],
    filterText: '',
    creditBatchEntryFilter: Object.keys(CreditBatchEntryFilter)[19],
    pageIndex: 0,
    pageSize: 10,
  })
  const [batchEntries, setBatchEntries] = useState([])

  const fetchBatchEntries = async id => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batch-entries-by-credit-batch-id-in-page',
      { ...reqParams, creditBatchId: id },
      false
    )
    if (success) {
      setIsBusy(false)
      setBatchEntries(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    if (selectedBatchEntry) {
      fetchBatchEntries(selectedBatchEntry?.id)
    }
  }, [
    selectedBatchEntry.id,
    refreshBatchEntries,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    { label: localize('beneficiary'), template: r => <>{r.beneficiary}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    { label: localize('balance'), template: r => <>{r.balance}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },

    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <FiltersContainer>
            <div className="d-flex align-item-center">
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchEntryStatus"
                value={reqParams?.status}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }
              />
            </div>
            <div className="d-flex align-item-center">
              <label className="text-capitalize me-3">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CreditBatchEntryFilter"
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    creditBatchEntryFilter: e.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="input-group">
                <input
                  className="form-control"
                  value={reqParams?.filterText}
                  onChange={e => {
                    setReqParams({ ...reqParams, filterText: e.target.value })
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    fetchBatchEntries(selectedBatchEntry?.id)
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </FiltersContainer>
          <SimpleTable columns={columns} data={batchEntries.pageCollection} />
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('apportioned')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(
                  batchEntries.totalApportioned,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('shortage')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatCurrency(batchEntries.totalShortage, 2, 2, false)}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('entries')}
              </label>
              <input
                className="form-control"
                disabled
                value={batchEntries.statusEntries}
              />
            </div>
          </div>
          <Pager
            itemsCount={batchEntries?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}
