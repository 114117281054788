/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
function CRMRecuringProcedureEntries({
  selectedRecuringProcedure,
  setEntriesTotal,
  setDisplayedEntries,
  reload,
  setReload,
}) {
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 1,
    filterText: '',
    recurringBatchEntryFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [search, setSearch] = useState('')
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccount?.fullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccount?.customerFullName}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerAccount?.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerAccount?.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerAccount?.customerReference3}</>,
    },

    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r.standingOrder?.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorCustomerName'),
      template: r => (
        <>{r.standingOrder?.benefactorCustomerAccountCustomerFullName}</>
      ),
    },
    {
      label: localize('benefactorRef_1(account#)'),
      template: r => (
        <>{r.standingOrder?.benefactorCustomerAccountCustomerReference1}</>
      ),
    },
    {
      label: localize('benefactorRef_2(membership#)'),
      template: r => (
        <>{r.standingOrder?.benefactorCustomerAccountCustomerReference2}</>
      ),
    },
    {
      label: localize('benefactorRef_3(personalFile#)'),
      template: r => (
        <>{r.standingOrder?.benefactorCustomerAccountCustomerReference3}</>
      ),
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r.standingOrder?.beneficiaryFullAccountNumber}</>,
    },
    {
      label: localize('beneficiaryCustomerName'),
      template: r => (
        <>{r.standingOrder?.beneficiaryCustomerAccountCustomerFullName}</>
      ),
    },
    {
      label: localize('beneficiaryRef_1(account#)'),
      template: r => (
        <>{r.standingOrder?.beneficiaryCustomerAccountCustomerReference1}</>
      ),
    },
    {
      label: localize('beneficiaryRef_2(membership#)'),
      template: r => (
        <>{r.standingOrder?.beneficiaryCustomerAccountCustomerReference2}</>
      ),
    },
    {
      label: localize('beneficiaryRef_3(personalFile#)'),
      template: r => (
        <>{r.standingOrder?.beneficiaryCustomerAccountCustomerReference3}</>
      ),
    },

    {
      label: localize('employee'),
      template: r => <>{r.paySlip?.salaryCardEmployeeCustomerFullName}</>,
    },
    {
      label: localize('employeeRef_1(account#)'),
      template: r => <>{r.paySlip?.salaryCardEmployeeCustomerReference1}</>,
    },
    {
      label: localize('employeeRef_2(membership#)'),
      template: r => <>{r.paySlip?.salaryCardEmployeeCustomerReference2}</>,
    },
    {
      label: localize('employeeRef_3(personalFile#)'),
      template: r => <>{r.paySlip?.salaryCardEmployeeCustomerReference3}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCase?.loanProductDocumentReferenceNumber}</>,
    },
    {
      label: localize('eStatementStartDate'),
      template: r => <>{formatDate(r.electronicStatementStartDate)}</>,
    },
    {
      label: localize('eStatementEndDate'),
      template: r => <>{formatDate(r.electronicStatementEndDate)}</>,
    },
    {
      label: localize('eStatementSender'),
      template: r => <>{r.electronicStatementSender}</>,
    },
    {
      label: localize('interestCapitalizationMonths'),
      template: r => <>{r.interestCapitalizationMonths}</>,
    },
    {
      label: localize('standingOrderExecutionMonths'),
      template: r => <>{r.standingOrderExecutionMonths}</>,
    },
    {
      label: localize('recoverIndefiniteCharge'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.recoverIndefiniteCharges.toString())}
        </span>
      ),
    },
    {
      label: localize('enforCeiling'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.enforceCeiling.toString())}
        </span>
      ),
    },
    {
      label: localize('adhocArrearsRecoveryAvailableBalance'),
      template: r => (
        <>{formatCurrency(r.adhocArrearsRecoveryAvailableBalance)}</>
      ),
    },
    {
      label: localize('adhocInterestCapitalizationBalance'),
      template: r => (
        <>{formatCurrency(r.adhocInterestCapitalizationBalance)}</>
      ),
    },
    {
      label: localize('adhocInterestCapitalizationAnnualPercentageRate'),
      template: r => (
        <>
          {formatAsPercent(r.adhocInterestCapitalizationAnnualPercentageRate)}
        </>
      ),
    },
    {
      label: localize('exceptionMessage'),
      template: r => <>{r.exceptionMessage}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const loadRecuringProcedureEntries = async () => {
    const { success, data } = await getData(
      `/RecurringBatch/find-recurring-batch-entries-by-recurring-batch-id-in-page`,
      {
        recurringBatchId: selectedRecuringProcedure.id,
        ...reqParams,
      }
    )
    if (success) {
      setEntries(data)
      setEntriesTotal(data.totalItems)
      setDisplayedEntries(data.itemsCount)
      if (reload) setReload(false)
    } else {
      setEntries([])
    }
  }

  useEffect(() => {
    loadRecuringProcedureEntries()
  }, [
    selectedRecuringProcedure?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
    reload,
  ])

  return (
    <>
      <div className="row px-3 mb-2">
        <div className="d-flex align-items-center col-2">
          <label
            htmlFor="recordsPerPage"
            className=""
            style={{ marginLeft: -10, textTransform: 'capitalize' }}
          >
            {localize('recordsPerPage')}
          </label>
          <select
            id="recordsPerPage"
            className="form-select ms-1"
            style={{ maxWidth: 'fit-content' }}
            defaultValue={reqParams.pageSize}
            onChange={e => {
              setReqParams({
                ...reqParams,
                pageIndex: 0,
                pageSize: e.target.value,
              })
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="100">200</option>
            <option value="100">300</option>
            <option value="100">400</option>
            <option value="100">500</option>
            <option value="100">1000</option>
          </select>
        </div>
        <div className="d-flex align-items-center col-2">
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            value={reqParams?.status}
            enumsKey={'BatchEntryStatus'}
            onChange={e => {
              setReqParams({
                ...reqParams,
                status: e.value,
              })
            }}
          />
        </div>
        <form
          className="d-flex align-items-center col-8 col"
          onSubmit={e => {
            e.preventDefault()
            setReqParams({
              ...reqParams,
              filterText: search,
            })
          }}
        >
          <label className="ms-2 me-2 text-capitalize" htmlFor="searchTable">
            {localize('search')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            value={reqParams?.recurringBatchEntryFilter}
            enumsKey={'RecurringBatchEntryFilter'}
            onChange={e => {
              setReqParams({
                ...reqParams,
                recurringBatchEntryFilter: e.value,
              })
            }}
          />
          <input
            type="search"
            id="searchTable"
            className="form-control ms-2"
            defaultValue={reqParams.filterText}
            onChange={e => {
              setSearch(e.target.value)
              setReqParams({
                ...reqParams,
                filterText: e.target.value,
              })
            }}
          />
          <button
            id="bankToBankRequestFilterButton"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              loadRecuringProcedureEntries()
            }}
          >
            <i className="uil uil-filter"></i>
          </button>
        </form>
      </div>
      <SimpleTable
        columns={columns}
        data={entries.pageCollection}
        selectables
      />
      <Pager
        itemsCount={entries.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}
export default CRMRecuringProcedureEntries
