/** @format */

export const TellerTypes = {
  0: { name: 'employee', value: 0 },
  1: { name: 'atmTerminal', value: 1 },
  2: { name: 'posTerminalInHouse', value: 2 },
  3: { name: 'posTerminalAgent', value: 3 },
}

export const IncomeAdjustmentType = {
  64222: { name: 'allowance' },
  64223: { name: 'deduction' },
  64224: { name: 'clearedEffect' },
}

export const ItemRegisterType = {
  1: { name: 'Asset' },
  2: { name: 'Inventory' },
}

export const RecordStatus = {
  0: { name: 'New' },
  1: { name: 'Edited' },
  2: { name: 'Approved' },
  3: { name: 'Rejected' },
  4: { name: 'All' },
}

export const CustomerTypes = {
  0: { name: 'Individual' },
  1: { name: 'Partnership/Joint' },
  2: { name: 'Corporation' },
  3: { name: 'MicroCredit/Group' },
}

export const employerType = {
  0: { name: 'Header Entry' },
  1: { name: 'Detail Entry' },
}

export const CustomerFilter = {
  0: { name: 'Member Number' },
  1: { name: 'Account Number' },
  2: { name: 'Personal File Number' },
  3: { name: 'Identity Card Number' },
  4: { name: 'Mobile Line' },
  5: { name: 'Personal Identification Number' },
  6: { name: 'Individual First Name' },
  7: { name: 'Individual Last Name' },
  8: { name: 'Non-Individual Description' },
  9: { name: 'Non-Individual Registration Number' },
  10: { name: 'Address Line 1' },
  11: { name: 'Address Line 2' },
  12: { name: 'Street' },
  13: { name: 'Postal Code' },
  14: { name: 'City' },
  15: { name: 'Email' },
  16: { name: 'LandLine' },
  17: { name: 'Huduma Number' },
  18: { name: 'Branch Description' },
  19: { name: 'Membership Class Description' },
  20: { name: 'Employer Description' },
  21: { name: 'Duty-Station Description' },
  22: { name: 'Electoral Zone Description' },
  23: { name: 'Reference 1' },
  24: { name: 'Reference 2' },
  25: { name: 'Reference 3' },
}

export const CustomerStatementPassword = {
  0: {
    name: 'identificationNumber',
  },
  1: {
    name: 'serialNumber',
  },
  2: {
    name: 'personalIdentificationNumber',
  },
  3: {
    name: 'reference1',
  },
  4: {
    name: 'reference2',
  },
  5: {
    name: 'reference3',
  },
}

export const chartOfAccountType = {
  1000: { name: 'Asset' },
  2000: { name: 'Liability' },
  3000: { name: 'Equity/Capital' },
  4000: { name: 'Income/Revenue' },
  5000: { name: 'Expense' },
}

export const chartOfAccountCategory = {
  4096: { name: 'Header Account (Non-Postable)' },
  4097: { name: 'Detail Account (Postable)' },
}

export const employeeFilters = {
  0: { name: 'Member Number' },
  1: { name: 'Account Number' },
  2: { name: 'Personal File Number' },
  3: { name: 'Personal Identification Number' },
  4: { name: 'First Name' },
  5: { name: 'Last Name' },
  6: { name: 'Identity Card Number' },
  7: { name: 'Address Line 1' },
  8: { name: 'Address Line 2' },
  9: { name: 'Physical Address' },
  10: { name: 'Postal Code' },
  11: { name: 'City' },
  12: { name: 'Email' },
  13: { name: 'Land Line' },
  14: { name: 'Mobile Line' },
  15: { name: 'Ref_1 (Account #)' },
  16: { name: 'Ref_2 (Membership #)' },
  17: { name: 'Ref_3 (Personal File #)' },
  18: { name: 'NSSF Number' },
  19: { name: 'NHIF Number' },
  20: { name: 'Remarks' },
  21: { name: 'User Name' },
}

export const maturityDaysCategory = {
  0: { name: 'businessDays' },
  1: { name: 'workingDays' },
}

export const chequeTypeRecoveryMode = {
  0: { name: 'onChequeDeposit' },
  1: { name: 'onChequeClearance' },
}

export const externalAgencyType = {
  0: { name: 'mpesa' },
  1: { name: 'coopBank' },
  2: { name: 'moneyGram' },
  3: { name: 'westernUnion' },
}

export const TransactionOwnership = {
  0: { name: 'beneficiaryBranch' },
  1: { name: 'initiatingBranch' },
}

export const EmolumentAutowireMedium = {
  0: { name: 'Mpesa' },
}

export const PointOfSaleAgencyFilter = {
  0: { name: 'Description' },
  1: { name: 'Commercial Activity' },
  2: { name: 'Member Number' },
  3: { name: 'Account Number' },
  4: { name: 'Personal File Number' },
  5: { name: 'Identity Card Number' },
  6: { name: 'Mobile Line' },
  7: { name: 'Personal Identification Number' },
  8: { name: 'First Name' },
  9: { name: 'Last Name' },
  10: { name: 'Org. Name' },
  11: { name: 'Org. Registration Number' },
  12: { name: 'Address Line 1' },
  13: { name: 'Address Line 2' },
  14: { name: 'Physical Address' },
  15: { name: 'Postal Code' },
  16: { name: 'City' },
  17: { name: 'Email' },
  18: { name: 'Land Line' },
  19: { name: 'Huduma Number' },
  20: { name: 'Branch' },
  21: { name: 'Business/Group/Employer' },
  22: { name: 'Duty/Work Station' },
  23: { name: 'Ref_1 (Account #)' },
  24: { name: 'Ref_2 (Membership #)' },
  25: { name: 'Ref_3 (Personal File #)' },
}
export const SystemTransactionCode = {
  0: { name: '' },
  1: { name: 'Cash Withdrawal (Customer)' },
  2: { name: 'Cash Deposit (Customer)' },
  3: { name: 'Cheque Deposit (Customer)' },
  4: { name: 'Payment Voucher' },
  5: { name: 'Journal Voucher' },
  6: { name: 'Payout' },
  7: { name: 'Bank to Treasury' },
  8: { name: 'Treasury to Bank' },
  9: { name: 'Treasury to Teller' },
  10: { name: 'Treasury to Treasury' },
  11: { name: 'Sacco-Link' },
  12: { name: 'Sparrow' },
  13: { name: 'MCo-op Cash' },
  14: { name: 'Inter-Acccount Transfer' },
  15: { name: 'Fiscal Period Closing' },
  16: { name: 'SkyWorld' },
  17: { name: 'Cash Receipt (Customer)' },
  18: { name: 'Cash Receipt (Sundry)' },
  19: { name: 'Cheque Receipt (Sundry)' },
  20: { name: 'Cash Payment (Sundry)' },
  21: { name: 'Loan Disbursement' },
  22: { name: 'External Cheque (Clearance)' },
  23: { name: 'Loan Restructuring' },
  24: { name: 'Interest Capitalization' },
  25: { name: 'SpotCash' },
  26: { name: 'Fixed Deposit (Fixing)' },
  27: { name: 'Salary' },
  28: { name: 'Check-Off' },
  29: { name: 'Cash Pickup' },
  30: { name: 'Standing Order' },
  31: { name: 'Savings Charges' },
  32: { name: 'Loan Charges' },
  33: { name: 'Truncated Cheque' },
  34: { name: 'Legacy Balance' },
  35: { name: 'Statement Fee' },
  36: { name: 'Archive Balance' },
  37: { name: 'Refund' },
  38: { name: 'Sundry Debit' },
  39: { name: 'Balance Adjustment' },
  40: { name: 'Bank Reconciliation' },
  41: { name: 'Teller End-Of-Day' },
  42: { name: 'Teller Cash Transfer' },
  43: { name: 'Sundry Payment' },
  44: { name: 'Payables/Receivables' },
  45: { name: 'Intra-Acccount Transfer' },
  46: { name: 'Membership Withdrawal' },
  47: { name: 'Loan Guarantee' },
  48: { name: 'Guarantor Attachment' },
  49: { name: 'Guarantor Substitution' },
  50: { name: 'Loan Offsetting' },
  51: { name: 'Agency Banking' },
  52: { name: 'Mobile To Bank' },
  53: { name: 'In-House Cheque' },
  54: { name: 'PesaPepe' },
  55: { name: 'Guarantor Relieving' },
  56: { name: 'Balance Pooling' },
  57: { name: 'PesaPepe Mobile Loan' },
  58: { name: 'General Ledger' },
  59: { name: 'ABC Bank' },
  60: { name: 'Cash Withdrawal (Account Closure)' },
  61: { name: 'Wire Transfer' },
  62: { name: 'Citius' },
  63: { name: 'e-Statement' },
  64: { name: 'Loan Request' },
  65: { name: 'Purchase Payables' },
  66: { name: 'Asset Disposals' },
  67: { name: 'Mobile To Bank (Sender ACK)' },
  68: { name: 'Super Saver Payables' },
  69: { name: 'Funeral Rider Claim' },
  70: { name: 'Account Activation' },
  71: { name: 'Asset Depreciations' },
  73: { name: 'Account Closure' },
  74: { name: 'Account Freezing' },
  75: { name: 'Account Dormant' },
  76: { name: 'Loan Registration' },
  77: { name: 'Alternate Channel Linking' },
  78: { name: 'Alternate Channel Replacement' },
  79: { name: 'Alternate Channel Renewal' },
  80: { name: 'Alternate Channel Delinking' },
  81: { name: 'Alternate Channel Stoppage' },
  82: { name: 'Leave Approval' },
  83: { name: 'Customer Modification' },
  84: { name: 'Interest Adjustment' },
  85: { name: 'External Cheque (Transfer)' },
  86: { name: 'External Cheque (Banking)' },
  87: { name: 'Broker' },
  88: { name: 'Customer Creation' },
  89: { name: 'Balance C/F' },
  90: { name: 'Balance B/F' },
  91: { name: 'Arrearages Recovery' },
  92: { name: 'Death Claim Settlement' },
  93: { name: 'Loan Instalment' },
  94: { name: 'Interswitch' },
  95: { name: 'B2C Non-Customer Wire Transfer' },
  96: { name: 'Microcredit' },
  97: { name: 'Debit Card Registration' },
  98: { name: 'Funeral Home' },
  99: { name: 'Teller (POS) Cash Transfer' },
  100: { name: 'Inventory' },
  101: { name: 'Mobile To Bank (Till Number)' },
  102: { name: 'Bank To Bank' },
  103: { name: 'Electronic Payslip' },
  104: { name: 'Loan Appraisal' },
  105: { name: 'Loan Approval' },
  106: { name: 'Loan Audit' },
  107: { name: 'Loan Cancellation' },
  108: { name: 'Loan Request Guarantor' },
  109: { name: 'Fixed Deposit (Termination)' },
  110: { name: 'Fixed Deposit (Liquidation)' },
  111: { name: 'Alternate Channel Activation Request' },
  112: { name: 'Loan Deferral' },
  113: { name: 'Teller to Petty Cashier' },
  114: { name: 'Cash Payment (M-Pesa Agent)' },
  115: { name: 'Cash Payment (Coo-p Bank Agent)' },
  116: { name: 'Cash Receipt (M-Pesa Agent)' },
  117: { name: 'Cash Receipt (Co-op Bank Agent)' },
  118: { name: 'Petty Cash Payment' },
  119: { name: 'Save As You Earn' },
  120: { name: 'Emolument/Allowance' },
  121: { name: 'Petty Cash Requisition' },
  122: { name: 'Vehicle Collection' },
  123: { name: 'Autoshop Receivables' },
  124: { name: 'Interactive Messaging' },
}

export const DisbursementCategory = {
  0: { name: 'Internal' },
  1: { name: 'External' },
}

export const ProductSection = {
  0: { name: 'FOSA' },
  1: { name: 'BOSA' },
}
export const LoanPurposeCategory = {
  4096: { name: 'Header Entry' },
  4097: { name: 'Detail Entry' },
}
export const LeaveDaysCategory = {
  0: { name: 'Working Days (Monday-to-Saturday)' },
  1: { name: 'Business Days (Monday-to-Friday)' },
  2: { name: 'Calendar Days' },
}

export const EmployeeAppraisalTargetAnswerType = {
  0: { name: 'Text' },
  2: { name: 'Number' },
}

export const EmployeeCategory = {
  1: { name: 'Full Time' },
  2: { name: 'Part Time' },
  4: { name: 'Contract' },
}

// TODO: Melvin - Remove this is a duplicate of  standingOrderTrigger
export const StandingOrderTrigger = {
  0: { name: 'payout Salary' },
  1: { name: 'check Off' },
  2: { name: 'schedule' },
  3: { name: 'sweep' },
  4: { name: 'microloan' },
  5: { name: 'adhoc' },
  6: { name: 'other' },
}

export const LoanClassification = {
  0: { name: 'performing' },
  1: { name: 'watch' },
  2: { name: 'subStandard' },
  3: { name: 'doubtful' },
  4: { name: 'loss' },
}

export const ElectoralZoneType = {
  0: { name: 'headerEntry' },
  1: { name: 'detailEntry' },
}

export const CompanyRecoveryPriorities = {
  0: { name: 'BosaLoans' },
  1: { name: 'BosaInvestments' },
  2: { name: 'BosaSavings' },
  3: { name: 'FosaLoans' },
  4: { name: 'FosaInvestmnets' },
  5: { name: 'FosaSavings' },
  6: { name: 'DirectDebits' },
  7: { name: 'AdHocStandingOrders' },
  8: { name: 'LoanPenalties' },
}

export const BankersChequeLeafManagementAction = {
  0: { name: 'flag' },
  1: { name: 'uNflag' },
}

export const ChargeType = {
  1: { name: 'Percentage' },
  2: { name: 'Fixed Amount' },
}

export const ProductCode = {
  1: { name: 'Savings' },
  2: { name: 'Loan' },
  3: { name: 'Investment' },
}

export const LoanProductPropertyGroup = {
  0: { name: 'productCategory' },
  1: { name: 'interestCalculationMode' },
  2: { name: 'interestChargeMode' },
  3: { name: 'interestRecoveryMode' },
}

export const RoundingType = {
  0: { name: 'No Rounding' },
  1: { name: 'Midpoint to Even' },
  2: { name: 'Midpoint Away from Zero' },
  3: { name: 'Round Up' },
  4: { name: 'Round Down' },
  5: { name: 'Round Up To The NearestTen' },
  6: { name: 'Round Down To The Nearest Fifty' },
}

export const savingsProductKnownChargeType = {
  0: { name: 'Cash Withrawal' },
  1: { name: 'Cash Withdrawal Fee (Without Notice)' },
  2: { name: 'Cash Withdrawal Fee (Payment Voucher)' },
  3: { name: 'Cash Withdrawal Fee (Permature Interval)' },
  4: { name: 'Cash Withdrawal Fee (Below Minimum Balance)' },
  5: { name: 'cash Withdrawal Fee (Account Closure)' },
  6: { name: 'Cash Deposit Fee' },
  7: { name: 'Statement Printing Fee' },
  8: { name: 'Standing Order Fee' },
  9: { name: 'Payroll Processing Fee' },
  10: { name: 'Cheque Book Fee' },
  11: { name: 'Dynamic Fee' },
}

export const chargeBenefactor = {
  0: { name: 'Customer' },
  1: { name: 'Institution' },
}

export const SystemTransactionType = {
  61681: { name: 'Pay As You Earn (PAYE) Fee' },
  61682: { name: 'National Social Security Fund (NSSF) Fee' },
  61683: { name: 'National Hospital Insurance Fund (NHIF) Fee' },
  61684: { name: 'Provident Fund Fee' },
  61703: { name: 'Member Funeral Rider Claim Fee' },
  61704: { name: 'Spouse Funeral Rider Claim Fee' },
  61705: { name: 'Customer Account Activation Fee' },
  61706: { name: 'Fringe Benefit Tax' },
}

export const DynamicChargeRecoveryMode = {
  1280: { name: 'Upfront' },
  1281: { name: 'Periodic' },
  1282: { name: 'Carry Forward' },
}

export const DynamicChargeRecoverySource = {
  1536: { name: 'Loan Account' },
  1537: { name: 'Savings Account' },
}

export const DynamicChargeInstallmentsBasisValue = {
  0: { name: 'Approved Loan Amount' },
  1: { name: 'Attached Loans Amount' },
}

export const LoanCaseDeductionCategory = {
  0: { name: 'Unknown' },
  1: { name: 'Disbursement Fee' },
  2: { name: 'Indefinite Fee' },
  3: { name: 'Interest Paid Upfront' },
  4: { name: 'Penalty Offsetting' },
  5: { name: 'Interest Offsetting' },
  6: { name: 'Principal Offsetting' },
  7: { name: 'Carry Forwards Offsetting' },
  8: { name: 'Clearance Fee' },
  9: { name: 'Refinance Fee' },
  10: { name: 'Deductible' },
  11: { name: 'Deductible Fee' },
  12: { name: 'Investments Topup' },
  13: { name: 'Investments Topup Fee' },
  14: { name: 'Insurance Fee' },
}

export const AlternateChannelTypeFilter = {
  0: { name: 'All' },
  1: { name: 'SaccoLink' },
  2: { name: 'Sparrow' },
  4: { name: 'MCoopCash' },
  8: { name: 'SpotCash' },
  16: { name: 'Citius' },
  32: { name: 'AgencyBanking' },
  64: { name: 'PesaPepe' },
  128: { name: 'AbcBank' },
  256: { name: 'Broker' },
  512: { name: 'Interswitch' },
}

export const AlternateChannelKnownChargeType = {
  0: { name: 'Linking Fee' },
  1: { name: 'Replacement Fee' },
  2: { name: 'Renewal Fee' },
  3: { name: 'Withdrawal Fee' },
  4: { name: 'Deposit Fee' },
  5: { name: 'Mini Statement Fee' },
  6: { name: 'Balance Inquiry Fee' },
  7: { name: 'Airtime Fee' },
  8: { name: 'PIN Reset Fee' },
  9: { name: 'Sacco Link Deposit Fee (Coop-Bank Agent)' },
  10: { name: 'Sacco Link Withdrawal Fee (Coop-Bank ATM)' },
  11: { name: 'Sacco Link Withdrawal Fee (via Non-Coop-Bank ATM)' },
  12: { name: 'Sacco Link Withdrawal Fee (via Account-To-MPESA)' },
  13: { name: 'Sacco Link Withdrawal Fee (Coop-Bank Agent)' },
  14: { name: 'Sacco Link Purchase Fee (Goods & Services via Mobile)' },
  15: { name: 'Sacco Link Balance Inquiry Fee (Coop-Bank Agent)' },
  16: { name: 'Sacco Link Balance Inquiry Fee (via Mobile)' },
  17: { name: 'Sacco Link Mini Statement Fee (Coop-Bank Agent)' },
  18: { name: 'Sacco Link Mini Statement Fee (via Mobile)' },
  19: { name: 'Guarantorship Inquiry Fee' },
  20: { name: 'Purchase Fee (Goods & Services)' },
  21: { name: 'Interswitch Purchase Fee (Goods & Services with Cash Back)' },
  22: { name: 'Interswitch Withdrawal Fee (Agency Banking)' },
  23: { name: 'Interswitch Deposit Fee (Agency Banking)' },
  24: { name: 'Interswitch Balance Inquiry Fee (Agency Banking)' },
  25: { name: 'Interswitch Mini Statement Fee (Agency Banking)' },
  26: { name: 'Pesalink Fee' },
  27: { name: 'Inter-Account Transfer Fee' },
  28: { name: 'Statement Fee' },
  29: { name: 'Utility Payment Fee' },
  30: { name: 'Interswitch Transfer Fee' },
  31: { name: 'Intra-Account Transfer Fee' },
  32: { name: 'FOSA To Bank Transfer Fee' },
  33: { name: 'Interswitch Paycode Fee' },
  34: { name: 'Withdrawal Fee (via Verve Member ATM)' },
  35: { name: 'Sacco Link Withdrawal Fee (via Branch)' },
  36: { name: 'Sacco Link Utility Payment Fee (via ATM)' },
  37: { name: 'Sacco Link Utility Payment Fee (via Agent)' },
}

export const CoopBankAlternateChannelKnownChargeType = {
  0: { name: 'Linking Fee' },
  1: { name: 'Replacement Fee' },
  2: { name: 'Renewal Fee' },
  7: { name: 'Airtime Fee' },
  9: { name: 'Sacco Link Deposit Fee (Coop-Bank Agent)' },
  10: { name: 'Sacco Link Withdrawal Fee (Coop-Bank ATM)' },
  11: { name: 'Sacco Link Withdrawal Fee (via Non-Coop-Bank ATM)' },
  12: { name: 'Sacco Link Withdrawal Fee (via Account-To-MPESA)' },
  13: { name: 'Sacco Link Withdrawal Fee (Coop-Bank Agent)' },
  14: { name: 'Sacco Link Purchase Fee (Goods & Services via Mobile)' },
  15: { name: 'Sacco Link Balance Inquiry Fee (Coop-Bank Agent)' },
  16: { name: 'Sacco Link Balance Inquiry Fee (via Mobile)' },
  17: { name: 'Sacco Link Mini Statement Fee (Coop-Bank Agent)' },
  18: { name: 'Sacco Link Mini Statement Fee (via Mobile)' },
  20: { name: 'Purchase Fee (Goods & Services)' },
  26: { name: 'Pesalink Fee' },
  27: { name: 'Inter-Account Transfer Fee' },
  35: { name: 'Sacco Link Withdrawal Fee (via Branch)' },
  36: { name: 'Sacco Link Utility Payment Fee (via ATM)' },
  37: { name: 'Sacco Link Utility Payment Fee (via Agent)' },
}

export const PesaPepeAlternateChannelKnownChargeType = {
  0: { name: 'Linking Fee' },
  1: { name: 'Replacement Fee' },
  2: { name: 'Renewal Fee' },
  3: { name: 'Withdrawal Fee' },
  4: { name: 'Deposit Fee' },
  5: { name: 'Mini Statement Fee' },
  6: { name: 'Balance Inquiry Fee' },
  7: { name: 'Airtime Fee' },
  8: { name: 'PIN Reset Fee' },
  19: { name: 'Guarantorship Inquiry Fee' },
  20: { name: 'Purchase Fee (Goods & Services)' },
  26: { name: 'Pesalink Fee' },
  27: { name: 'Inter-Account Transfer Fee' },
  31: { name: 'Intra-Account Transfer Fee' },
  33: { name: 'Interswitch Paycode Fee' },
  34: { name: 'Withdrawal Fee (via Verve Member ATM)' },
}

export const checkoffEntryType = [
  { name: 'sInterest' },
  { name: 'sInvest' },
  { name: 'sLoan' },
  { name: 'sRisk' },
  { name: 'sShare' },
  { name: 'wCont' },
  { name: 'sLoanInterest' },
  { name: 'wLoan' },
  { name: 'sSavings' },
]

export const loanProductKnownChargeType = {
  53498: { name: 'loanClearanceFeeOnDisbursement' },
  [53498 + 3]: { name: 'loanArrearsFee' },
  [53498 + 4]: { name: 'loanClearanceFeeOnExternalChequePayment' },
  [53498 + 5]: { name: 'loanClearanceChargesOnFixedDepositLiquidation' },
  [53498 + 7]: { name: 'loanDeductiblesFee' },
  [53498 + 8]: { name: 'LoanAppraisalDepositsTopUpFee' },
  [53498 + 9]: { name: 'LoanRefinanceCharges' },
}

export const loanProductChargeBasisValue = {
  0: { name: 'Principal Balance' },
  1: { name: 'Book Balance (Principal + Interest)' },
  2: { name: 'Disbursed Amount' },
  99: { name: '' },
}

export const loanDeductibleProduct = {
  1: { name: 'savingProduct' },
  [1 + 2]: { name: 'investmentProduct' },
  [1 + 3]: { name: 'generalLedgerAccount' },
}

export const auxiliaryLoanCondition = {
  1: { name: 'SubjectToNotHavingOutstandingBalance' },
  2: { name: 'SubjectToHavingLoanInProcessApproved' },
  4: { name: 'SubjectToHavingLoanInProcessAudited' },
  8: { name: 'SubjectToHavingLoanInProcessAppraised' },
  16: { name: 'SubjectToExistingInConditionalLendingList' },
  32: { name: 'SubjectToHavingDividendsPayabale' },
  64: { name: 'SubjectToHavingFixedDeposits' },
}

export const salutation = {
  0: {
    name: '',
  },
  1: {
    name: 'Mr',
  },
  2: {
    name: 'Mrs',
  },
  3: {
    name: 'Miss',
  },
  4: {
    name: 'Dr',
  },
  5: {
    name: 'Prof',
  },
  6: {
    name: 'Rev',
  },
  7: {
    name: 'Eng',
  },
  8: {
    name: 'Hon',
  },
  9: {
    name: 'Cllr',
  },
  10: {
    name: 'Sir',
  },
  11: {
    name: 'Ms',
  },
  12: {
    name: 'Fr',
  },
  13: {
    name: 'Sr',
  },
}

export const roomType = {
  0: {
    name: 'Ordinary',
  },
  1: {
    name: 'VIP',
  },
}

export const CreditBatchType = {
  56026: { name: 'Payout' },
  56027: { name: 'CheckOff' },
  56028: { name: 'CashPickup' },
  56029: { name: 'SundryPayments' },
  56030: { name: 'SaveAsYouEarn' },
  56031: { name: 'DividendPayout' },
}

export const CreditBatchFileType = {
  0: { name: 'Standard' },
  1: { name: 'TSC (KE)' },
  2: { name: 'County (KE)' },
  3: { name: 'Ministry (KE)' },
  4: { name: 'Distributed Payroll' },
}

export const NonLoanProductCode = {
  1: { name: 'Savings' },
  3: { name: 'Investment' },
}

export const systemGeneralLedgerAccountCode = {
  48826: { name: 'payablesControl' },
  48827: { name: 'externalChequesControl' },
  48830: { name: 'electronicFundsTransferControl' },
  48831: { name: 'profitLossAppropriation' },
  48835: { name: 'saccoLinkSettlement' },
  48844: { name: 'saccoLinkPosSettlement' },
  48838: { name: 'coopBankSettlementMCoopCash' },
  48839: { name: 'externalChequesInHand' },
  48840: { name: 'pesaPepeSettlementB2C' },
  48841: { name: 'legacyBalancesControl' },
  48843: { name: 'spotCashSettlement' },
  48855: { name: 'truncatedChequesSettlement' },
  48857: { name: 'institutionSettlement' },
  48858: { name: 'agentCommissionSettlement' },
  48859: { name: 'pesaPepeSettlementAirtime' },
  48860: { name: 'abcBankSettlement' },
  48861: { name: 'employerContributionNssf' },
  48862: { name: 'employerContributionProvidentFund' },
  48863: { name: 'pesaPepeSettlementSms' },
  48864: { name: 'pesaPepeSettlementCentrinoCharges' },
  48865: { name: 'superSaverWithholdingTax' },
  48866: { name: 'superSaverInterest' },
  48867: { name: 'funeralRiderExpense' },
  48868: { name: 'thirdPartyWalletSettlement' },
  48869: { name: 'fikiwaAggregateSettlement' },
  48870: { name: 'fikiwaSMSSettlement' },
  48871: { name: 'fikiwaUSSDSettlement' },
  48872: { name: 'fikiwaAirtimeSettlement' },
  48873: { name: 'wireTransferSettlementNoncustomer' },
  48875: { name: 'interswitchATMSettlement' },
  48877: { name: 'interswitchPOSSettlement' },
  48878: { name: 'mobileWalletPurchaseGoodsAndServicesSettlement' },
  48879: { name: 'mobileWalletPesalinkSettlement' },
  48880: { name: 'coopPesalinkSettlement' },
  48881: { name: 'interswitchWalletSettlement' },
  48882: { name: 'bankToBankSettlement' },
  48883: { name: 'fringeBenefitTaxSettlement' },
}

export const vehicleFleetProductType = {
  0: { name: 'savingsProduct' },
  1: { name: 'investmentProduct' },
}

export const administrativeDivisionType = {
  0: { name: 'headerEntry' },
  1: { name: 'detailEntry' },
}

export const alternateChannelType = {
  1: { name: 'saccoLink' },
  2: { name: 'sparrow' },
  4: { name: 'mCoopCash' },
  8: { name: 'spotCash' },
  16: { name: 'citius' },
  32: { name: 'agencyBanking' },
  64: { name: 'pesaPepe' },
  128: { name: 'abcBank' },
  256: { name: 'broker' },
  512: { name: 'interswitch' },
}

export const messageGroupTarget = {
  1: { name: 'Employer' },
  2: { name: 'Custom' },
  4: { name: 'Role' },
}

export const customerMembershipStatus = {
  0: { name: 'Active' },
  1: { name: 'Terminated' },
  2: { name: 'Partially Terminated' },
  3: { name: 'Blocked' },
  4: { name: 'Non-Active' },
  5: { name: 'Retired' },
  6: { name: 'Dormant' },
  7: { name: 'Resigned' },
  8: { name: 'Defaulter' },
  9: { name: 'Creditor' },
  10: { name: 'Deceased' },
  11: { name: 'Ex-Company' },
  12: { name: 'Withdrawn' },
  13: { name: 'Withdrawal Pending' },
}

export const ConfigurableBankToMobileTransactionRequest = {
  22: { name: 'Debit Advice' },
  24: { name: 'Balance Enquiry' },
  25: { name: 'Mini Statement' },
  26: { name: 'Loan Request' },
  28: { name: 'Airtime Purchase' },
  29: { name: 'Stop Channel' },
  34: { name: 'Loans Guaranteed' },
  35: { name: 'My Loan Guarantors' },
  36: { name: 'Purchase Goods & Services' },
  43: { name: 'Pesalink Transfers' },
  45: { name: 'Coop Pesalink To Account' },
  46: { name: 'Intra-Account Transfer' },
  47: { name: 'Interswitch Banks Request' },
  48: { name: 'Interswitch Transfer To Bank' },
  49: { name: 'Interswitch Transfer to MPESA' },
  50: { name: 'Coop Pesalink To Phone' },
  51: { name: 'Interswitch Paycode' },
  999: { name: 'Unknown' },
}

export const ConfigurableBrokerTransactionRequest = {
  10: { name: 'Enrollment' },
  13: { name: 'Balance Enquiry' },
  18: { name: 'Mini Statement' },
  19: { name: 'Know Your Customer' },
  20: { name: 'Cash Deposit' },
  22: { name: 'Cash Withdrawal' },
  23: { name: 'Float Balance' },
  24: { name: 'Commission Balance' },
  26: { name: 'KYC Update' },
  34: { name: 'Fingerprint Specimen Update' },
  36: { name: 'Update Education Register' },
  37: { name: 'Education Registers' },
  51: { name: 'Account Transfer' },
  60: { name: 'Vehicle Collection Deposit' },
  61: { name: 'Teller Statement Enquiry' },
  67: { name: 'Alternate Channels (via Agency Banking)' },
  999: { name: 'Unknown' },
}

export const ConfigurableInterSwitchTransactionType = {
  0: { name: 'Goods & Services' },
  1: { name: 'Cash withdrawal' },
  9: { name: 'Goods & Services With Cash Back' },
  20: { name: 'Returns' },
  21: { name: 'Deposit' },
  30: { name: 'Available Balance Inquiry' },
  31: { name: 'Balance Inquiry' },
  38: { name: 'Mini-statement Inquiry' },
  998: { name: 'Paycode' },
  999: { name: 'Unknown' },
}

export const ConfigurableSaccoLinkTransactionRequest = {
  1: { name: 'MPESA B2C' },
  2: { name: 'Bill Payment' },
  3: { name: 'Utility Payment' },
  4: { name: 'KPLC Postpaid' },
  5: { name: 'KPLC Prepaid' },
  6: { name: 'DSTV' },
  7: { name: 'ZUKU' },
  8: { name: 'Safaricom Airtime Purchase' },
  9: { name: 'Pesalink' },
  10: { name: 'POS Cash Deposit' },
  12: { name: 'Cardless ATM withdrawal' },
  13: { name: 'Safaricom C2B' },
  14: { name: 'Ministatement Sacco Direct' },
  15: { name: 'Airtel Airtime Purchase' },
  16: { name: 'Balance Enquiry Sacco Direct' },
  17: { name: 'Nairobi Water Bill Payment' },
  18: { name: 'Bank Account to Sacco Account' },
  19: { name: 'Sacco Account to Bank Account' },
  20: { name: 'AirtelB2C' },
  21: { name: 'TELKOMB2C' },
  22: { name: 'Sacco Account To Virtual Card' },
  23: { name: 'Virtual card to SACCO' },
  24: { name: 'Sacco to Other Sacco' },
  25: { name: 'Other Sacco to Sacco' },
  26: { name: 'Telkom C2B' },
  27: { name: 'VISA Purchases' },
  987: { name: 'Legacy Cash Deposit' },
  988: { name: 'Legacy Cash Withdrawal (Coop ATM)' },
  989: { name: 'Legacy Cash Withdrawal (Non-Coop ATM)' },
  990: { name: 'Legacy Cash Withdrawal (Account To MPESA)' },
  991: { name: 'Legacy Cash Withdrawal (Agent)' },
  992: { name: 'Legacy Cash Withdrawal (Goods & Services Via Card)' },
  993: { name: 'Legacy Cash Withdrawal (Goods & Services Via Mobile)' },
  994: { name: 'Cash Withdrawal (ATM)' },
  995: { name: 'Cash Withdrawal (POS)' },
  996: { name: 'Cash Withdrawal (Branch)' },
  997: { name: 'Balance Enquiry' },
  998: { name: 'Mini Statement' },
  999: { name: 'Unknown' },
}

export const ConfigurableSpotCashMobileBankingTransactionType = {
  5: { name: 'Savings Deposit' },
  6: { name: 'Loan Deposit' },
  7: { name: 'Share Deposit' },
  100: { name: 'Transaction Reversal' },
  700: { name: 'Savings Balance Enquiry' },
  701: { name: 'Share Capital Balance' },
  702: { name: 'Loan Balance' },
  703: { name: 'NWD Balance' },
  704: { name: 'Mini Statement' },
  705: { name: 'Deposit' },
  706: { name: 'Withdrawal' },
  707: { name: 'Airtime' },
  708: { name: 'Inter-Account Transfer' },
  709: { name: 'Account Statement' },
  710: { name: 'ELoan' },
  712: { name: 'Verve E-cash Kenya' },
  713: { name: 'GOTV Kenya' },
  714: { name: 'KPLC PREPAID SECONDARY' },
  715: { name: 'Kisumu Water' },
  716: { name: 'Nairobi Water' },
  717: { name: 'NHIF' },
  718: { name: 'ZUKU' },
  719: { name: 'Multichoice Kenya' },
  720: { name: 'Startimes Kenya' },
  721: { name: 'Safaricom Kenya' },
  722: { name: 'KPLC PREPAID PRIMARY' },
  723: { name: 'Custom Bills' },
  724: { name: 'FOSA To Bank Transfer' },
  735: { name: 'KPLC POST PAID' },
  737: { name: 'Deposit' },
  995: { name: 'Registration' },
  996: { name: 'Refer A Friend' },
  997: { name: 'Mail Statement' },
  998: { name: 'Member Registration' },
  999: { name: 'Unknown' },
}

// TODO: Melvin to correct the cases

export const journalEntryFilter = {
  0: { name: 'Branch' },
  1: { name: 'Total Value' },
  2: { name: 'Primary Description' },
  3: { name: 'Secondary Description' },
  4: { name: 'Reference' },
  5: { name: 'App. User Name' },
  6: { name: 'Env. User Name' },
  7: { name: 'Env. Machine Name' },
  8: { name: 'Env. Domain Name' },
  9: { name: 'Env. OS Version' },
  10: { name: 'Env. MAC Address' },
  11: { name: 'Env. Motherboard Serial #' },
  12: { name: 'Env. Processor Id' },
  13: { name: 'Env. IP Address' },
  14: { name: 'G/L Account Name' },
  15: { name: 'First Name' },
  16: { name: 'Last Name' },
  17: { name: 'Member Number' },
  18: { name: 'Account Number' },
  19: { name: 'Personal File Number' },
  20: { name: 'Identity Card Number' },
  21: { name: 'Ref_1 (Account #)' },
  22: { name: 'Ref_2 (Membership #)' },
  23: { name: 'Ref_3 (Personal File #)' },
}

export const transactionDateFilter = {
  1: { name: 'Value Date' },
  2: { name: 'Created Date' },
}

export const individualType = {
  0: { name: 'N/A' },
  1: { name: 'Adult' },
  2: { name: 'Minor' },
  3: { name: 'Adult Taxpayer' },
}

export const nationality = [
  'N/A',
  'Kenya',
  'Uganda',
  'Tanzania',
  'Rwanda',
  'Burundi',
  'Sudan',
  'South Sudan',
  'Malawi',
  'Zimbabwe',
  'Zambia',
  'Somalia',
  'Djibouti',
  'Ethiopia',
  'Diaspora',
]

export const gender = {
  0: { name: 'Unknown' },
  1: { name: 'Male' },
  2: { name: 'Female' },
  3: { name: 'NonBinary' },
}

export const educationLevel = {
  0: { name: 'N/A' },
  1: { name: 'Early Childhood' },
  2: { name: 'Primary' },
  3: { name: 'Lower Secondary' },
  4: { name: 'Upper Secondary' },
  5: { name: 'Post Secondary Non-Tertiary' },
  6: { name: 'Short-Cycle Tertiary' },
  7: { name: 'Bachelor Or Equivalent' },
  8: { name: 'Master Or Equivalent' },
  9: { name: 'Doctoral Or Equivalent' },
  10: { name: 'Not Elsewhere Classified' },
}

export const identityCardType = {
  0: { name: 'N/A' },
  1: { name: 'National ID' },
  2: { name: 'Passport' },
  3: { name: 'Alien ID' },
  4: { name: 'Birth Certificate' },
  5: { name: 'Military ID' },
  255: { name: 'Other' },
}

export const termsOfService = {
  0: { name: 'Unknown' },
  1: { name: 'Temporary' },
  2: { name: 'Permanent' },
  3: { name: 'Contract' },
}

export const maritalStatus = {
  0: { name: 'Unknown' },
  1: { name: 'Married' },
  2: { name: 'Single' },
  3: { name: 'Divorced' },
  4: { name: 'Separated' },
  5: { name: 'Widower' },
  6: { name: 'Widow' },
}

export const customerDisabilityType = [
  'N/A',
  'Mobility and Physical Impairments',
  'Spinal Cord Disability',
  'Head Injuries / Brain Disability',
  'Vision Disability',
  'Hearing Disability',
  'Cognitive Disabilities',
  'Psychological Disorders',
  'Invisible Disabilities',
]

export const meetingFrequency = {
  2: { name: 'Semi-Annual (every 6 months)' },
  3: { name: 'Tri-Annual (every 4 months)' },
  4: { name: 'Quarterly (every 3 months)' },
  6: { name: 'Bi-Monthly (every 2 months)' },
  12: { name: 'Monthly' },
  24: { name: 'Semi-Monthly (twice a month)' },
  26: { name: 'Bi-Weekly (every 2 weeks)' },
  52: { name: 'Weekly' },
}

export const meetingDayOfWeek = {
  0: { name: 'Sunday' },
  1: { name: 'Monday' },
  2: { name: 'Tuesday' },
  3: { name: 'Wednesday' },
  4: { name: 'Thursday' },
  5: { name: 'Friday' },
  6: { name: 'Saturday' },
}

export const partnershipRelationship = {
  0: { name: '' },
  1: { name: 'Father' },
  2: { name: 'Mother' },
  3: { name: 'Brother' },
  4: { name: 'Sister' },
  5: { name: 'Wife' },
  6: { name: 'Husband' },
  7: { name: 'Son' },
  8: { name: 'Daughter' },
  9: { name: 'Trustee' },
  10: { name: 'Director' },
  11: { name: 'Partner' },
  12: { name: 'Other' },
}

export const corporationMemberType = {
  0: { name: 'Internal' },
  1: { name: 'External' },
}

export const DepreciationMethod = {
  1: { name: 'Straight Line' },
  2: { name: 'Sum-Of-Years Digits' },
  4: { name: 'Fixed-Declining Balance' },
  8: { name: 'Double-Declining Balance' },
  16: { name: 'Variable Double-Declining Balance' },
}

export const VitalStatus = {
  0: { name: '' },
  1: { name: 'alive' },
  2: { name: 'deceased' },
}

export const Gender = {
  0: { name: '' },
  1: { name: 'male' },
  2: { name: 'female' },
  3: { name: 'nonBinary' },
}

export const IdentityCardType = {
  0: { name: '' },
  1: { name: 'nationalId' },
  2: { name: 'passport' },
  3: { name: 'alienId' },
  4: { name: 'birthCertificate' },
  5: { name: 'militaryId' },
  255: { name: 'other' },
}

export const EducationLevel = {
  0: { name: '' },
  1: { name: 'Early Childhood' },
  2: { name: 'Primary' },
  3: { name: 'Lower Secondary' },
  4: { name: 'Upper Secondary' },
  5: { name: 'Post Secondary Non-Tertiary' },
  6: { name: 'Short-Cycle Tertiary' },
  7: { name: 'Bachelor Or Equivalent' },
  8: { name: 'Master Or Equivalent' },
  9: { name: 'Doctoral Or Equivalent' },
  10: { name: 'Not Elsewhere Classified' },
}

export const NextOfKinRelationship = {
  0: { name: '' },
  1: {
    name: 'Father',
  },
  2: {
    name: 'Mother',
  },
  3: {
    name: 'Brother',
  },
  4: {
    name: 'Sister',
  },
  5: {
    name: 'Wife',
  },
  6: {
    name: 'Husband',
  },
  7: {
    name: 'Son',
  },
  8: {
    name: 'Daughter',
  },
  9: {
    name: 'Trustee',
  },
  10: {
    name: 'Father-in-Law',
  },
  11: {
    name: 'Mother-in-Law',
  },
  12: {
    name: 'Granddaughter',
  },
  13: {
    name: 'Grandson',
  },
  14: {
    name: 'Uncle',
  },
  15: {
    name: 'Auntie',
  },
  16: {
    name: 'Nephew',
  },
  17: {
    name: 'Niece',
  },
  18: {
    name: 'Fiance',
  },
  19: {
    name: 'Fiancée',
  },
  20: {
    name: 'Brother-in-Law',
  },
  21: {
    name: 'Cohabiter',
  },
  22: {
    name: 'Cousin',
  },
  23: {
    name: 'Grandfather',
  },
  24: {
    name: 'Grandmother',
  },
  25: {
    name: 'Guardian',
  },
  26: {
    name: 'Sister-in-law',
  },
  27: {
    name: 'Stepdaughter',
  },
  28: {
    name: 'Stepfather',
  },
  29: {
    name: 'Stepmother',
  },
  100: {
    name: 'Other',
  },
}

export const customerAssociateRelationship = [
  '',
  'Family Member',
  'Business Partner',
  'Business Subsidiary',
]

export const customerAutoCompleteFilter = {
  0: { name: 'Member Number' },
  1: { name: 'Account Number' },
  2: { name: 'Personal File Number' },
  3: { name: 'Identity Card Number' },
  4: { name: 'Mobile Line' },
}

// TODO: melvin remove this
export const CustomerMembershipStatus = {
  0: { name: 'Active' },
  1: { name: 'Terminated' },
  2: { name: 'Withdrawn (Partially)' },
  3: { name: 'Blocked' },
  4: { name: 'Non-Active' },
  5: { name: 'Retired' },
  6: { name: 'Dormant' },
  7: { name: 'Resigned' },
  8: { name: 'Defaulter' },
  9: { name: 'Creditor' },
  10: { name: 'Deceased' },
  11: { name: 'Ex-Company' },
  12: { name: 'Withdrawn' },
  13: { name: 'Withdrawal Pending' },
}

export const QueuePriority = {
  0: { name: 'Lowest' },
  1: { name: 'Very Low' },
  2: { name: 'Low' },
  3: { name: 'Normal' },
  4: { name: 'Above Normal' },
  5: { name: 'High' },
  6: { name: 'Very High' },
  7: { name: 'Highest' },
}

export const FileMovementStatus = {
  0: { name: 'unknown' },
  51898: { name: 'Dispatched' },
  51899: { name: 'Received' },
}

export const CustomerDocumentType = {
  0: { name: 'General Document' },
  1: { name: 'Collateral Document' },
}

export const RecordAuthOption = {
  1: { name: 'Approve' },
  2: { name: 'Reject' },
}

export const enrollmentRequestFilterDescriptions = [
  'Personal Identification Number',
  'Payroll Numbers',
  'First Name',
  'Last Name',
  'Identity Card Number',
  'Org. Name',
  'Org. Registration Number',
  'Address Line 1',
  'Address Line 2',
  'Physical Address',
  'Postal Code',
  'City',
  'Email',
  'Land Line',
  'Mobile Line',
]

export const months = {
  1: { name: 'January' },
  2: { name: 'February' },
  3: { name: 'March' },
  4: { name: 'April' },
  5: { name: 'May' },
  6: { name: 'June' },
  7: { name: 'July' },
  8: { name: 'August' },
  9: { name: 'September' },
  10: { name: 'October' },
  11: { name: 'November' },
  12: { name: 'December' },
}

export const populationRegisterFilter = {
  0: { name: 'Identity Number (Query)' },
  1: { name: 'Identity Serial Number (Query)' },
  2: { name: 'ID Number' },
  3: { name: 'Serial Number' },
  4: { name: 'Gender' },
  5: { name: 'First Name' },
  6: { name: 'Other Name' },
  7: { name: 'Surname' },
  8: { name: 'Pin' },
  9: { name: 'Citizenship' },
  10: { name: 'Family' },
  11: { name: 'Clan' },
  12: { name: 'Ethnic Group' },
  13: { name: 'Occupation' },
  14: { name: 'Place of Birth' },
  15: { name: 'Place of Death' },
  16: { name: 'Place of Live' },
  17: { name: 'Reg Office' },
  18: { name: 'Remarks' },
}

export const populationRegisterQueryStatus = {
  0: { name: 'New' },
  1: { name: 'Authorized' },
  2: { name: 'Charged' },
  3: { name: 'Queried' },
  4: { name: 'Rejected' },
}

export const populationRegisterQueryAuthOption = {
  1: { name: 'authorize' },
  2: { name: 'reject' },
}

export const FingerDigit = {
  1: { name: 'Thumb (Left Hand)' },
  2: { name: 'Index Finger (Left Hand)' },
  3: { name: 'Middle Finger (Left Hand)' },
  4: { name: 'Ring Finger (Left Hand)' },
  5: { name: 'Pinky Finger (Left Hand)' },
  6: { name: 'Thumb (Right Hand)' },
  7: { name: 'Index Finger (Right Hand)' },
  8: { name: 'Middle Finger (Right Hand)' },
  9: { name: 'Ring Finger (Right Hand)' },
  10: { name: 'Pinky Finger (Right Hand)' },
}

export const membershipWithdrawalRegistrationOption = {
  1: { name: 'Register' },
  2: { name: 'Defer' },
}

export const expensePayableStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Rejected' },
  8: { name: 'Verified' },
  16: { name: 'Deferred' },
}

export const batchStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Rejected' },
  8: { name: 'Verified' },
  16: { name: 'Deferred' },
}

export const withdrawalNotificationCategory = {
  1792: { name: 'Deceased' },
  1793: { name: 'Voluntary' },
  1794: { name: 'Retiree' },
  1795: { name: 'Partial (BOSA)' },
}

export const accountClosureApprovalOption = {
  1: { name: 'Approve' },
  2: { name: 'Defer' },
}

export const withdrawalNotificationStatus = {
  1: { name: 'Registered' },
  2: { name: 'Approved' },
  4: { name: 'Verified' },
  8: { name: 'Withdrawal Settled' },
  16: { name: 'Deferred' },
  32: { name: 'Death Claim Settled' },
  64: { name: 'Invalidated' },
}

export const membershipWithdrawalAuditOption = {
  1: { name: 'Verify' },
  2: { name: 'Defer' },
}

export const originationVerificationAuthorizationStatus = {
  0: { name: 'Pending' },
  1: { name: 'Verified' },
  2: { name: 'Posted' },
  3: { name: 'Rejected' },
}

export const funeralRiderClaimType = {
  1: { name: 'Member Claim' },
  2: { name: 'Spouse Claim' },
}

export const BatchAuditOption = {
  1: { name: 'Post' },
  2: { name: 'Defer' },
}

export const requestOrigin = {
  0: { name: 'Internal' },
  1: { name: 'External' },
  2: { name: 'Case360' },
  3: { name: 'enVision' },
  4: { name: 'Agency Banking' },
  5: { name: 'Alfresco' },
}

export const signatoryRelationship = {
  0: { name: 'Unknown' },
  1: { name: 'Father' },
  2: { name: 'Mother' },
  3: { name: 'Brother' },
  4: { name: 'Sister' },
  5: { name: 'Wife' },
  6: { name: 'Husband' },
  7: { name: 'Son' },
  8: { name: 'Daughter' },
  9: { name: 'Trustee' },
  10: { name: 'Other' },
}

export const systemTransactionCodeFilter = {
  0: { name: 'All' },
  1: { name: 'Cash Withdrawal (Customer)' },
  2: { name: 'Cash Deposit (Customer)' },
  3: { name: 'Cheque Deposit (Customer)' },
  4: { name: 'Payment Voucher' },
  5: { name: 'Journal Voucher' },
  6: { name: 'Payout' },
  7: { name: 'Bank to Treasury' },
  8: { name: 'Treasury to Bank' },
  9: { name: 'Treasury to Teller' },
  10: { name: 'Treasury to Treasury' },
  11: { name: 'Sacco-Link' },
  12: { name: 'Sparrow' },
  13: { name: 'MCo-op Cash' },
  14: { name: 'Inter-Acccount Transfer' },
  15: { name: 'Fiscal Period Closing' },
  16: { name: 'SkyWorld' },
  17: { name: 'Cash Receipt (Customer)' },
  18: { name: 'Cash Receipt (Sundry)' },
  19: { name: 'Cheque Receipt (Sundry)' },
  20: { name: 'Cash Payment (Sundry)' },
  21: { name: 'Loan Disbursement' },
  22: { name: 'External Cheque (Clearance)' },
  23: { name: 'Loan Restructuring' },
  24: { name: 'Interest Capitalization' },
  25: { name: 'SpotCash' },
  26: { name: 'Fixed Deposit (Fixing)' },
  27: { name: 'Salary' },
  28: { name: 'Check-Off' },
  29: { name: 'Cash Pickup' },
  30: { name: 'Standing Order' },
  31: { name: 'Savings Charges' },
  32: { name: 'Loan Charges' },
  33: { name: 'Truncated Cheque' },
  34: { name: 'Legacy Balance' },
  35: { name: 'Statement Fee' },
  36: { name: 'Archive Balance' },
  37: { name: 'Refund' },
  38: { name: 'Sundry Debit' },
  39: { name: 'Balance Adjustment' },
  40: { name: 'Bank Reconciliation' },
  41: { name: 'Teller End-Of-Day' },
  42: { name: 'Teller Cash Transfer' },
  43: { name: 'Sundry Payment' },
  44: { name: 'Payables/Receivables' },
  45: { name: 'Intra-Acccount Transfer' },
  46: { name: 'Membership Withdrawal' },
  47: { name: 'Loan Guarantee' },
  48: { name: 'Guarantor Attachment' },
  49: { name: 'Guarantor Substitution' },
  50: { name: 'Loan Offsetting' },
  51: { name: 'Agency Banking' },
  52: { name: 'Mobile To Bank' },
  53: { name: 'In-House Cheque' },
  54: { name: 'PesaPepe' },
  55: { name: 'Guarantor Relieving' },
  56: { name: 'Balance Pooling' },
  57: { name: 'PesaPepe Mobile Loan' },
  58: { name: 'General Ledger' },
  59: { name: 'ABC Bank' },
  60: { name: 'Cash Withdrawal (Account Closure)' },
  61: { name: 'Wire Transfer' },
  62: { name: 'Citius' },
  63: { name: 'e-Statement' },
  64: { name: 'Loan Request' },
  65: { name: 'Purchase Payables' },
  66: { name: 'Asset Disposals' },
  67: { name: 'Mobile To Bank (Sender ACK)' },
  68: { name: 'Super Saver Payables' },
  69: { name: 'Funeral Rider Claim' },
  70: { name: 'Account Activation' },
  71: { name: 'Asset Depreciations' },
  73: { name: 'Account Closure' },
  74: { name: 'Account Freezing' },
  75: { name: 'Account Dormant' },
  76: { name: 'Loan Registration' },
  77: { name: 'Alternate Channel Linking' },
  78: { name: 'Alternate Channel Replacement' },
  79: { name: 'Alternate Channel Renewal' },
  80: { name: 'Alternate Channel Delinking' },
  81: { name: 'Alternate Channel Stoppage' },
  82: { name: 'Leave Approval' },
  83: { name: 'Customer Modification' },
  84: { name: 'Interest Adjustment' },
  85: { name: 'External Cheque (Transfer)' },
  86: { name: 'External Cheque (Banking)' },
  87: { name: 'Broker' },
  88: { name: 'Customer Creation' },
  89: { name: 'Balance C/F' },
  90: { name: 'Balance B/F' },
  91: { name: 'Arrearages Recovery' },
  92: { name: 'Death Claim Settlement' },
  93: { name: 'Loan Instalment' },
  94: { name: 'Interswitch' },
  95: { name: 'B2C Non-Customer Wire Transfer' },
  96: { name: 'Microcredit' },
  97: { name: 'Debit Card Registration' },
  98: { name: 'Funeral Home' },
  99: { name: 'Teller (POS) Cash Transfer' },
  100: { name: 'Inventory' },
  101: { name: 'Mobile To Bank (Till Number)' },
  102: { name: 'Bank To Bank' },
  103: { name: 'Electronic Payslip' },
  104: { name: 'Loan Appraisal' },
  105: { name: 'Loan Approval' },
  106: { name: 'Loan Audit' },
  107: { name: 'Loan Cancellation' },
  108: { name: 'Loan Request Guarantor' },
  109: { name: 'Fixed Deposit (Termination)' },
  110: { name: 'Fixed Deposit (Liquidation)' },
  111: { name: 'Alternate Channel Activation Request' },
  112: { name: 'Loan Deferral' },
  113: { name: 'Treasury to Petty Cashier' },
  114: { name: 'Cash Payment (M-Pesa Agent)' },
  115: { name: 'Cash Payment (Coo-p Bank Agent)' },
  116: { name: 'Cash Receipt (M-Pesa Agent)' },
  117: { name: 'Cash Receipt (Co-op Bank Agent)' },
}

export const journalFilter = {
  0: { name: 'Posting Period' },
  1: { name: 'Branch' },
  2: { name: 'Total Value' },
  3: { name: 'Primary Description' },
  4: { name: 'Secondary Description' },
  5: { name: 'Reference' },
  6: { name: 'Application User Name' },
  7: { name: 'Environment User Name' },
  8: { name: 'Environment Machine Name' },
  9: { name: 'Environment Domain Name' },
  10: { name: 'Environment OS Version' },
  11: { name: 'Environment MAC Address' },
  12: { name: 'Environment Motherboard Serial Number' },
  13: { name: 'Environment Processor Id' },
  14: { name: 'Environment IP Address' },
}

export const mobileToBankCollectionFilter = {
  0: { name: 'Name' },
  1: { name: 'Reference' },
  2: { name: 'First Name' },
  3: { name: 'Last Name' },
  4: { name: 'Org. Name' },
  5: { name: 'Reference 1' },
  6: { name: 'Reference 2' },
  7: { name: 'Reference 3' },
}

export const batchAuthOptions = {
  1: { name: 'Post' },
  2: { name: 'Defer' },
  4: { name: 'Reject' },
}

export const recordAuthOption = {
  1: { name: 'Approve' },
  2: { name: 'Reject' },
}

export const verifyRecordStatus = {
  2: { name: 'Approve' },
  3: { name: 'Reject' },
}

export const mobileToBankRequestStatusFilter = {
  0: { name: 'Unmatched' },
  1: { name: 'Auto-Matched (Customer Account)' },
  2: { name: 'Recon-Matched' },
  3: { name: 'Exception Raised' },
  4: { name: 'Posted' },
  5: { name: 'Auto-Matched (Channel Activation)' },
  9999: { name: 'All' },
}

export const mobileToBankRequestFilter = {
  0: { name: 'MSISDN' },
  1: { name: 'Business Short Code' },
  2: { name: 'Invoice Number' },
  3: { name: 'Trans ID' },
  4: { name: 'Trans Amount' },
  5: { name: 'Third Party Trans ID' },
  6: { name: 'Trans Time' },
  7: { name: 'Bill Ref Number' },
  8: { name: 'KYC Info' },
  9: { name: 'Remarks' },
}

export const interestCalculationMode = {
  512: { name: 'Reducing Balance' },
  513: { name: 'Straight-Line' },
  514: { name: 'Amortization (Straight-Line)' },
  515: { name: 'Amortization (Diminishing Balance)' },
  516: { name: 'Fixed Interest' },
}

export const paymentFrequencyPerYear = {
  1: { name: 'Annual' },
  2: { name: 'Semi-Annual (Every 6 Months)' },
  3: { name: 'Quarterly (Every 3 Months)' },
  4: { name: 'Tri-Annual (Every 4 Months)' },
  6: { name: 'Bi-Monthly (Every 2 Months)' },
  12: { name: 'Monthly' },
  24: { name: 'Semi-Monthly (Twice a Month)' },
  26: { name: 'Bi-Weekly (Every 2 Weeks)' },
  52: { name: 'Weekly' },
  365: { name: 'Daily' },
}

export const interestChargeMode = {
  710: { name: 'Periodic (Repayment Schedule)' },
  768: { name: 'Upfront' },
  769: { name: 'Periodic (Outstanding Balance)' },
}

export const interestRecoveryMode = {
  1280: { name: 'Upfront' },
  1281: { name: 'Periodic' },
  1282: { name: 'Carry Forward' },
}

export const loanProductCategory = {
  0: { name: 'Short-Term' },
  1: { name: 'Long-Term' },
  2: { name: 'Mid-Term' },
}

export const auditLogFilter = {
  0: { name: 'Event Type' },
  1: { name: 'Table Name' },
  2: { name: 'Record Id' },
  3: { name: 'Narration' },
  4: { name: 'App. DomainName' },
  5: { name: 'App. User Name' },
  6: { name: 'Env. User Name' },
  7: { name: 'Env. Machine Name' },
  8: { name: 'Env. Domain Name' },
  9: { name: 'Env. OS Version' },
  10: { name: 'Env. MAC Address' },
  11: { name: 'Env. Motherboard Serial Number' },
  12: { name: 'Env. Processor Id' },
  13: { name: 'Env. IP Address' },
}

export const UserNameProvider = {
  0: { name: 'User-Defined' },
  1: { name: 'Active Directory' },
}

export const SystemPermissionType = {
  44992: { name: 'Savings Withdrawal Authorization' },
  44994: { name: 'Credit Batch Authorization' },
  44995: { name: 'Journal Voucher Authorization' },
  44996: { name: 'Refund Batch Authorization' },
  44997: { name: 'Debit Batch Authorization' },
  45036: { name: 'Loan Disbursement Batch Authorization' },
  45037: { name: 'Journal Reversal Batch Authorization' },
  45041: { name: 'Inter-Account Transfer Batch Authorization' },
  45042: { name: 'Expense Payables Authorization' },
  45143: { name: 'Salary Period Verification' },
  44999: { name: 'Salary Period Authorization' },
  45011: { name: 'BOSA Loan Verification/Cancellation' },
  45012: { name: 'BOSA Loan Appraisal' },
  45013: { name: 'BOSA Loan Approval' },
  45014: { name: 'BOSA Loan Registration' },
  45017: { name: 'BOSA Loan Restructuring' },
  45007: { name: 'FOSA Loan Verification/Cancellation' },
  45008: { name: 'FOSA Loan Appraisal' },
  45009: { name: 'FOSA Loan Approval' },
  45010: { name: 'FOSA Loan Registration' },
  45016: { name: 'FOSA Loan Restructuring' },
  45002: { name: 'Administrative Customer Viewing' },
  45003: { name: 'Membership Withdrawal Processing' },
  45004: { name: 'Operations Maker' },
  45005: { name: 'Operations Checker' },
  45018: { name: 'Alternate Channels Reconciliation' },
  45019: { name: 'Alternate Channel Linking (Mobile Banking)' },
  45020: { name: 'Alternate Channel Replacement (Mobile Banking)' },
  45021: { name: 'Alternate Channel Renewal (Mobile Banking)' },
  45022: { name: 'Alternate Channel Delinking (Mobile Banking)' },
  45055: { name: 'Alternate Channel Stoppage (Mobile Banking)' },
  45023: { name: 'Customer Account Activation' },
  45024: { name: 'Customer Account Freezing' },
  45025: { name: 'Customer Account Remark' },
  45026: { name: 'Payment Voucher Management' },
  45027: { name: 'Microcredit Apportionment' },
  45028: { name: 'Truncated Cheques Processing' },
  45029: { name: 'Leave Management' },
  45030: { name: 'Credit Batch Verification' },
  45031: { name: 'Journal Voucher Verification' },
  45032: { name: 'Refund Batch Verification' },
  45033: { name: 'Debit Batch Verification' },
  45034: { name: 'Wire Transfer Batch Verification' },
  45035: { name: 'Loan Disbursement Batch Verification' },
  45038: { name: 'Journal Reversal Batch Verification' },
  45039: { name: 'Credit Batch Discrepancy Resolution' },
  45040: { name: 'Inter-Account Transfer Batch Verification' },
  45043: { name: 'Expense Payables Verification' },
  45044: { name: 'Intra-Account Transfer' },
  45045: { name: 'Fixed Deposit Fixing' },
  45046: { name: 'Fixed Deposit Termination' },
  45047: { name: 'Fixed Deposit Liquidation' },
  45060: { name: 'Fixed Deposit Verification' },
  45144: { name: 'Fixed Deposit Authorization' },
  45048: { name: 'Mobile To Bank Reconciliation' },
  45049: { name: 'Suppress Maker/Checker Validation' },
  45050: { name: 'Intra-Account Apportionment (Investment-to-Savings)' },
  45051: { name: 'Intra-Account Apportionment (Investment-to-Loan)' },
  45052: { name: 'Intra-Account Apportionment (Investment-to-Investment)' },
  45053: { name: 'General Ledger Verification' },
  45054: { name: 'General Ledger Authorization' },
  45056: { name: 'Sundry Cash Payment Authorization' },
  45057: { name: 'Customer Account Verification' },
  45058: { name: 'Member Verification' },
  45059: { name: 'Alternate Channel Verification' },
  45066: { name: 'Goods Dispatch Note Origination' },
  45067: { name: 'Goods Dispatch Note Cancellation' },
  45070: { name: 'Asset Disposal Verification' },
  45071: { name: 'Asset Disposal Authorization' },
  45072: { name: 'Super Saver Payable Verification' },
  45073: { name: 'Super Saver Payable Authorization' },
  45074: { name: 'Funeral Rider Claim Payable Verification' },
  45075: { name: 'Funeral Rider Claim Payable Authorization' },
  45077: { name: 'Employee Appraiser' },
  45078: { name: 'Member Statement Printing' },
  45079: { name: 'Standing Order Editing' },
  45080: { name: 'Employee Exit Verification' },
  45081: { name: 'Employee Exit Authorization' },
  45087: { name: 'Carry Forwards Management' },
  45088: { name: 'Carry Forward Installment Editing' },
  45089: { name: 'Mobile To Bank Reconciliation Verification' },
  45090: { name: 'Member Registration' },
  45091: { name: 'Member Editing/Modification' },
  45092: { name: 'Savings Deposit Authorization' },
  45093: { name: 'External Cheque Clearance' },
  45094: { name: 'Customer Account Signing Instructions' },
  45095: { name: 'Loan Interest Adjustment' },
  45096: { name: 'Enrollment Request Origination' },
  45097: { name: 'Enrollment Request Verification' },
  45098: { name: 'Enrollment Request Authorization' },
  45099: { name: "Banker's Cheque Management" },
  45100: { name: 'Biometric Register Management' },
  45103: { name: 'Exemptions & Exceptions Management' },
  45104: { name: 'Debit Card Register Management' },
  45105: { name: 'Employee Referrals Management' },
  45106: { name: 'Employee Payslip Printing' },
  45107: { name: 'Bank To Bank Reconciliation Verification' },
  45108: { name: 'Bank To Bank Reconciliation' },
  45109: { name: 'Loan Disbursement Queue Posting' },
  45110: { name: 'Loan Disbursement Queue Transfer' },
  45111: { name: 'Partially-Open Month Posting' },
  45112: { name: 'Cash Management Request Authorization' },
  45113: { name: 'Channel Activation Request Verification' },
  45114: { name: 'Bulk Message Batch Verification' },
  45115: { name: 'Bulk Message Batch Authorization' },
  45116: { name: 'Confidential G/L Account Viewing' },
  45117: { name: 'Channel Activation Request Origination' },
  45118: { name: 'Petty Cash Request Authorization' },
  45119: { name: 'Withdrawal Settlement Batch Verification' },
  45120: { name: 'Withdrawal Settlement Batch Authorization' },
  45121: { name: 'Emolument Batch Verification' },
  45122: { name: 'Emolument Batch Authorization' },
  45123: { name: 'Petty Cash Requisition Authorization' },
  45124: { name: 'Customer Account Overdraft Protection Management' },
  45125: { name: 'Alternate Channel PIN Reset' },
  45126: { name: 'Alternate Channel Unblocking' },
  45127: { name: 'Accrued Interest Batch Verification' },
  45128: { name: 'Accrued Interest Batch Authorization' },
  45129: { name: 'Alternate Channel Linking (Agency Banking' },
  45130: { name: 'Alternate Channel Replacement (Agency Banking)' },
  45131: { name: 'Alternate Channel Renewal (Agency Banking)' },
  45132: { name: 'Alternate Channel Delinking (Agency Banking)' },
  45133: { name: 'Alternate Channel Stoppage (Agency Banking)' },
  45134: { name: 'Alternate Channel Linking (ATM)' },
  45135: { name: 'Alternate Channel Replacement (ATM)' },
  45136: { name: 'Alternate Channel Renewal (ATM)' },
  45137: { name: 'Alternate Channel Delinking (ATM)' },
  45138: { name: 'Alternate Channel Stoppage (ATM)' },
  45139: { name: 'Biometric Registration' },
  45140: { name: 'Biometric Exemption Management' },
  45141: { name: 'Loan Reschedule Verification' },
  45142: { name: 'Loan Reschedule Authorization' },
  45145: { name: 'Employee Temporary Role Verification' },
  45146: { name: 'Intra-Account Loan Transfer' },
  45147: { name: 'Refund Processing' },
}

export const reportSpoolDateFilter = {
  1: { name: 'Value Date' },
  2: { name: 'Created Date' },
  4: { name: 'Date Range' },
}

export const batchEntryStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Rejected' },
  8: { name: 'Reversed' },
  16: { name: 'Exception Raised' },
}

export const mobileToBankRequestAuthOption = {
  0: { name: 'Verify' },
  1: { name: 'Reject' },
}
export const apportionedComponent = {
  1: { name: 'Principal' },
  2: { name: 'Interest' },
  4: { name: 'Aggregate' },
}

export const apportionTo = {
  1: { name: 'Customer Account' },
  2: { name: 'G/L Account' },
}

export const bankToBankRequestStatus = {
  0: { name: 'Unmatched' },
  1: { name: 'Auto-Matched' },
  2: { name: 'Recon-Matched' },
  3: { name: 'Exception Raised' },
  4: { name: 'Posted' },
}

export const bankToBankRequestFilter = {
  0: { name: 'Transaction Reference Code' },
  1: { name: 'Document Reference Number' },
  2: { name: 'Bank Code' },
  3: { name: 'Branch Code' },
  4: { name: 'Payment Amount' },
  5: { name: 'Remarks' },
  6: { name: 'G/L Account Name' },
  7: { name: 'Serial Number' },
}

export const bankReconciliationAdjustmentType = {
  0: { name: 'Bank Account Debit' },
  1: { name: 'Bank Account Credit' },
}

export const bankReconciliationEntryStatus = {
  0: { name: 'Unreconciled' },
  1: { name: 'Reconciled' },
}

export const bankReconciliationAjustmentType = {
  0: { name: 'Bank Account Debit' },
  1: { name: 'Bank Account Credit' },
}

export const bankReconciliationPeriodAuthOption = {
  1: { name: 'Post' },
  2: { name: 'Reject' },
}

export const discrepancyFilter = {
  0: { name: 'Batch Number' },
  1: { name: 'Column 1' },
  2: { name: 'Column 2' },
  3: { name: 'Column 3' },
  4: { name: 'Column 4' },
  5: { name: 'Column 5' },
  6: { name: 'Column 6' },
  7: { name: 'Column 7' },
  8: { name: 'Column 8' },
  9: { name: 'Remarks' },
}

export const standingOrderTrigger = {
  0: { name: 'Payout/Salary' },
  1: { name: 'Check-Off' },
  2: { name: 'Schedule' },
  3: { name: 'Sweep' },
  4: { name: 'Microloan' },
  5: { name: 'Ad hoc' },
  6: { name: 'Other' },
}

export const loaneeIncomeAssessmentMode = {
  0: { name: 'Manual' },
  1: { name: 'Automated' },
}

export const loanDeductibleBasisValue = {
  0x000: { name: 'Disbursed Amount' },
  [0x000 + 1]: { name: 'Net of Deductions' },
}

export const aggregateCheckOffRecoveryMode = {
  0x0000: { name: 'Outstanding Balance' },
  0x0001: { name: 'Per Standing Order' },
}

export const directDebitRequestFilter = {
  0: { name: 'Ref Number' },
  1: { name: 'DR Account' },
  2: { name: 'CR Account' },
  3: { name: 'Policy Number' },
  4: { name: 'Serial Number' },
  5: { name: 'Personal Identification Number' },
  6: { name: 'First Name' },
  7: { name: 'Last Name' },
  8: { name: 'Identity Card Number' },
  9: { name: 'Org. Name' },
  10: { name: 'Org. Registration Number' },
  11: { name: 'Address Line 1' },
  12: { name: 'Address Line 2' },
  13: { name: 'Physical Address' },
  14: { name: 'Postal Code' },
  15: { name: 'City' },
  16: { name: 'Email' },
  17: { name: 'Land Line' },
  18: { name: 'Mobile Line' },
  19: { name: 'Member Number' },
  20: { name: 'Account Number' },
  21: { name: 'Personal File Number' },
  22: { name: 'Ref_1 (Account #)' },
  23: { name: 'Ref_2 (Membership #)' },
  24: { name: 'Ref_3 (Personal File #)' },
}

export const directDebitRequestStatus = {
  0: { name: 'Pending' },
  1: { name: 'Processed' },
}

export const directDebitRequestPropertyGroup = {
  0: { name: 'Type' },
  1: { name: 'Status' },
}

export const dividendComputationFilter = {
  0: { name: 'Posting Period' },
  1: { name: 'Serial Number' },
  2: { name: 'Personal Identification Number' },
  3: { name: 'First Name' },
  4: { name: 'Last Name' },
  5: { name: 'Identity Card Number' },
  6: { name: 'Org. Name' },
  7: { name: 'Org. Registration Number' },
  8: { name: 'Address Line 1' },
  9: { name: 'Address Line 2' },
  10: { name: 'Physical Address' },
  11: { name: 'Postal Code' },
  12: { name: 'City' },
  13: { name: 'Email' },
  14: { name: 'Land Line' },
  15: { name: 'Mobile Line' },
  16: { name: 'Account Number' },
  17: { name: 'Member Number' },
  18: { name: 'Personal File Number' },
}

export const bankToBankRequestAuthOption = {
  0: { name: 'Verify' },
  1: { name: 'Reject' },
}

export const customerAccountBalanceStatistic = {
  0: { name: 'Opening Balance' },
  1: { name: 'Closing Balance' },
  2: { name: 'Lowest Balance' },
  3: { name: 'Highest Balance' },
  4: { name: 'Average Balance' },
  5: { name: 'Operating Balance' },
  6: { name: 'Daily Balance' },
}

export const creditDiscrepancyAuthOption = {
  1: { name: 'Match' },
  2: { name: 'Reject' },
}

export const customerAccountStatus = {
  0: { name: 'Active' },
  1: { name: 'Inactive' },
  2: { name: 'Dormant' },
  3: { name: 'Closed' },
  4: { name: 'Remarked' },
}

export const DiscrepancyFilter = {
  0: { name: 'Column 1' },
  1: { name: 'Column 2' },
  2: { name: 'Column 3' },
  3: { name: 'Column 4' },
  4: { name: 'Column 5' },
  5: { name: 'Column 6' },
  6: { name: 'Column 7' },
  7: { name: 'Column 8' },
  8: { name: 'Remarks' },
}

// TODO:Melvin -remove this duplication  01/08/2023
export const BatchEntryStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Rejected' },
  8: { name: 'Reversed' },
  16: { name: 'Exception Raised' },
}
export const ApportionedComponent = {
  1: { name: 'Principal' },
  2: { name: 'Interest' },
  4: { name: 'Aggregate' },
}

export const payoutRecoveryMode = {
  0x700: { name: 'Per Standing Order' },
  [0x700 + 1]: { name: 'Outstanding Percentage' },
  [0x700 + 2]: { name: 'Repayment Schedule' },
  [0x700 + 3]: { name: 'Interest Balance' },
}

export const chequeTruncationRequestFilter = {
  0: { name: 'Ref Number' },
  1: { name: 'DR Account' },
  2: { name: 'CR Account' },
  3: { name: 'MICR' },
  4: { name: 'Cheque Number' },
  5: { name: 'Inputter' },
  6: { name: 'Serial Number' },
  7: { name: 'Personal Identification Number' },
  8: { name: 'First Name' },
  9: { name: 'Last Name' },
  10: { name: 'Identity Card Number' },
  11: { name: 'Org. Name' },
  12: { name: 'Org. Registration Number' },
  13: { name: 'Address Line 1' },
  14: { name: 'Address Line 2' },
  15: { name: 'Physical Address' },
  16: { name: 'Postal Code' },
  17: { name: 'City' },
  18: { name: 'Email' },
  19: { name: 'Land Line' },
  20: { name: 'Mobile Line' },
  21: { name: 'Member Number' },
  22: { name: 'Account Number' },
  23: { name: 'Personal File Number' },
  24: { name: 'Ref_1 (Account #)' },
  25: { name: 'Ref_2 (Membership #)' },
  26: { name: 'Ref_3 (Personal File #)' },
}

export const chequeTruncationRequestPropertyGroup = {
  0: { name: 'Type' },
  1: { name: 'Status' },
}

export const chequeTruncationRequestStatus = {
  0: { name: 'Pending' },
  1: { name: 'Processed' },
}
export const standingOrderExecutionFilter = {
  1: { name: 'Employer' },
  2: { name: 'Customer' },
  4: { name: 'Credit Type' },
}

export const guarantorSecurityMode = {
  0: { name: 'Income' },
  1: { name: 'Investments' },
}

export const budgetAdjustmentType = {
  0: { name: 'Addition' },
  1: { name: 'Deduction' },
}

export const JournalVoucherStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Rejected' },
  8: { name: 'Audited' },
  16: { name: 'Authorized' },
  32: { name: 'Exception Raised' },
  64: { name: 'Deferred' },
}

export const JournalVoucherType = {
  0: { name: 'Debit G/L Account' },
  1: { name: 'Credit G/L Account' },
  2: { name: 'Debit Customer Account' },
  3: { name: 'Credit Customer Account' },
}

export const JournalVoucherEntryType = {
  0: { name: 'G/L Account' },
  1: { name: 'Customer' },
}

export const wireTransferBatchType = {
  0: { name: 'B2C (Customer)' },
  1: { name: 'B2C (Non-Customer)' },
  2: { name: 'EFT (Customer Account)' },
  3: { name: 'EFT (Loan Disbursement Queue)' },
}

export const budgetManagementCategory = {
  0: { name: 'Header Entry' },
  1: { name: 'Detail Entry' },
}
export const wireTransferBatchEntryFilter = {
  0: { name: 'Serial Number' },
  1: { name: 'Personal Identification Number' },
  2: { name: 'First Name' },
  3: { name: 'Last Name' },
  4: { name: 'Identity Card Number' },
  5: { name: 'Org. Name' },
  6: { name: 'Org. Registration Number' },
  7: { name: 'Address Line 1' },
  8: { name: 'Address Line 2' },
  9: { name: 'Physical Address' },
  10: { name: 'Postal Code' },
  11: { name: 'City' },
  12: { name: 'Email' },
  13: { name: 'Land Line' },
  14: { name: 'Mobile Line' },
  15: { name: 'Ref_1 (Account #)' },
  16: { name: 'Ref_2 (Membership #)' },
  17: { name: 'Ref_3 (Personal File #)' },
  18: { name: 'Payee' },
  19: { name: 'Account Number' },
  20: { name: 'Reference' },
  21: { name: 'Callback Payload' },
}

export const interAccountTransferBatchType = {
  0: { name: 'Sundry Transfer' },
  1: { name: 'Institutional Capital Transfer' },
}

export const apportionFrom = {
  1: { name: 'Customer Account' },
  2: { name: 'G/L Account' },
}

export const journalLookupMode = {
  0: { name: 'Primary Fields' },
  1: { name: 'Other Fields' },
}

export const generalLedgerEntryType = {
  1: { name: 'G/L Account' },
  2: { name: 'Customer' },
}

export const JournalVoucherEntryStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Rejected' },
  8: { name: 'Reversed' },
}

export const CreditBatchEntryFilter = {
  0: { name: 'Serial Number' },
  1: { name: 'Personal Identification Number' },
  2: { name: 'First Name' },
  3: { name: 'Last Name' },
  4: { name: 'Identity Card Number' },
  5: { name: 'Org. Name' },
  6: { name: 'Org. Registration Number' },
  7: { name: 'Address Line 1' },
  8: { name: 'Address Line 2' },
  9: { name: 'Physical Address' },
  10: { name: 'Postal Code' },
  11: { name: 'City' },
  12: { name: 'Email' },
  13: { name: 'Land Line' },
  14: { name: 'Mobile Line' },
  15: { name: 'Ref_1 (Account #)' },
  16: { name: 'Ref_2 (Membership #)' },
  17: { name: 'Ref_3 (Personal File #)' },
  18: { name: 'Beneficiary' },
  19: { name: 'Reference' },
}

export const JournalVoucherAuthOption = {
  1: { name: 'Post' },
  2: { name: 'Defer' },
  3: { name: 'Reject' },
}

export const creditReferenceBureauQueryStatus = {
  0: { name: 'New' },
  1: { name: 'Charged' },
  2: { name: 'Charge Declined' },
  3: { name: 'Queried' },
}

export const AuditLogFilter = {
  0: { name: 'Event Type' },
  1: { name: 'Table Name' },
  2: { name: 'App. DomainName' },
  3: { name: 'App. User Name' },
  4: { name: 'Env. Machine Name' },
  5: { name: 'Env. Domain Name' },
  6: { name: 'Env. OS Version' },
  7: { name: 'Env. MAC Address' },
  8: { name: 'Env. Motherboard Serial Number' },
  9: { name: 'Env. Processor Id' },
  10: { name: 'Env. IP Address' },
}

export const loanCaseFilter = {
  0: {
    name: 'Member Number',
  },
  1: {
    name: 'Account Number',
  },
  2: {
    name: 'Personal File Number',
  },
  3: {
    name: 'Identity Card Number',
  },
  4: {
    name: 'Mobile Line',
  },
  5: {
    name: 'Personal Identification Number',
  },
  6: {
    name: 'First Name',
  },
  7: {
    name: 'Last Name',
  },
  8: {
    name: 'Org. Name',
  },
  9: {
    name: 'Org. Registration Number',
  },
  10: {
    name: 'Address Line 1',
  },
  11: {
    name: 'Address Line 2',
  },
  12: {
    name: 'Physical Address',
  },
  13: {
    name: 'Postal Code',
  },
  14: {
    name: 'City',
  },
  15: {
    name: 'Email',
  },
  16: {
    name: 'Land Line',
  },
  17: {
    name: 'Huduma Number',
  },
  18: {
    name: 'Branch',
  },
  19: {
    name: 'Membership Class',
  },
  20: {
    name: 'Business/Group/Employer',
  },
  21: {
    name: 'Duty/Work Station',
  },
  22: {
    name: 'Electoral Zone',
  },
  23: {
    name: 'Ref_1 (Account #)',
  },
  24: {
    name: 'Ref_2 (Membership #)',
  },
  25: {
    name: 'Ref_3 (Personal File #)',
  },
  26: {
    name: 'Loan Serial Number',
  },
  27: {
    name: 'Loan Number',
  },
  28: {
    name: 'Loan Product',
  },
  29: {
    name: 'Loan Purpose',
  },
  30: {
    name: 'Loan Disbursement Mode',
  },
  31: {
    name: 'Reference',
  },
}
export const LoanRescheduleRequestStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Rejected' },
  8: { name: 'Verified' },
}

export const LoanRescheduleRequestProcessingOption = {
  1: { name: 'Post' },
  2: { name: 'Reject' },
}

export const loanCaseStatus = {
  48826: { name: 'Registered' },
  48827: { name: 'Appraised' },
  48828: { name: 'Approved' },
  48829: { name: 'Disbursed' },
  48830: { name: 'Rejected' },
  48831: { name: 'Deferred' },
  48832: { name: 'Verified' },
  48833: { name: 'Restructured' },
  48834: { name: 'Reversed' },
  48835: { name: 'Legacy' },
  48836: { name: 'Deferred Internally' },
}

export const loanCaseStatusFilter = {
  0: { name: 'All' },
  48826: { name: 'Registered' },
  48827: { name: 'Appraised' },
  48828: { name: 'Approved' },
  48829: { name: 'Disbursed' },
  48830: { name: 'Rejected' },
  48831: { name: 'Deferred' },
  48832: { name: 'Verified' },
  48833: { name: 'Restructured' },
  48834: { name: 'Reversed' },
  48835: { name: 'Legacy' },
  48836: { name: 'Deferred Internally' },
}

export const loanCasePropertyGroup = {
  branchDescription: { name: 'Branch' },
  loanProductDescription: { name: 'Product' },
  paddedBatchNumber: { name: 'Batch Number' },
  statusDescription: { name: 'Status' },
  loanInterestCalculationModeDescription: { name: 'Interest Calculation Mode' },
  loanRegistrationLoanProductSectionDescription: { name: 'Section' },
  loanRegistrationLoanProductCategoryDescription: { name: 'Category' },
}

export const creditReferenceBureauReportReason = {
  1: { name: 'New credit application' },
  2: { name: 'Review of existing credit' },
  3: { name: 'Verify customer details' },
  4: { name: 'Direct customer request' },
}

export const creditReferenceBureauReportType = {
  1: { name: 'Identity Verification' },
  2: { name: 'Delinquency Report' },
  3: { name: 'Check Credit Score' },
  4: { name: 'Full Report (PDF)' },
  6: { name: 'Identity Scrub' },
  8: { name: 'Credit Info' },
}
export const DataAttachmentPeriodStatus = {
  1: { name: 'Open' },
  2: { name: 'Closed' },
  4: { name: 'Suspended' },
}

export const DataAttachmentEntryType = {
  0: { name: 'Existing Customer' },
  1: { name: 'Prospective Customer' },
}

export const DataAttachmentTransactionType = {
  1: { name: 'Fresh Loan' },
  2: { name: 'Adjust Balance' },
  3: { name: 'Variation' },
  4: { name: 'New Member' },
  5: { name: 'Special Adjustments' },
  6: { name: 'Stop Deduction' },
  7: { name: 'Shares Deposit' },
  8: { name: 'Risk Fund' },
  9: { name: 'Entrance Fee' },
}

export const guarantorAttachmentMode = {
  0: { name: 'Pro Rata Percentange' },
  1: { name: 'Equal Portions' },
  2: { name: 'Manual Allocation' },
}

export const refundNotificationRegistrationOption = {
  1: { name: 'Register' },
  2: { name: 'Defer' },
}

export const refundNotificationApprovalOption = {
  1: { name: 'Approve' },
  2: { name: 'Defer' },
}

export const refundNotificationAuditOption = {
  1: { name: 'Verify' },
  2: { name: 'Defer' },
}

export const loanGuarantorSubstitutionRegistrationOption = {
  1: { name: 'Register' },
  2: { name: 'Defer' },
}

export const loanGuarantorSubstitutionRequestStatus = {
  1: { name: 'Registered' },
  2: { name: 'Deferred' },
}

export const RefundBatchEntryType = {
  0: { name: 'Refund Notification' },
  1: { name: 'Straight Through' },
}

export const refundNotificationStatus = {
  1: { name: 'Registered' },
  2: { name: 'Approved' },
  4: { name: 'Verified' },
  8: { name: 'Deferred' },
  16: { name: 'Settled' },
  32: { name: 'Invalidated' },
}

export const loanTransferType = {
  0: { name: 'Exact Principal & Interest Transfer' },
  1: { name: 'Excess Principal Transfer' },
  2: { name: 'Excess Interest Transfer' },
}

export const loanGuarantorAttachmentHistoryEntryStatus = {
  0: { name: 'Attached' },
  1: { name: 'Relieved' },
}

export const payrollNumberFilter = {
  0: { name: 'Number Type' },
  1: { name: 'Reference' },
  2: { name: 'Serial Number' },
  3: { name: 'Personal Identification Number' },
  4: { name: 'First Name' },
  5: { name: 'Last Name' },
  6: { name: 'Identity Card Number' },
  7: { name: 'Org. Name' },
  8: { name: 'Org. Registration Number' },
  9: { name: 'Address Line 1' },
  10: { name: 'Address Line 2' },
  11: { name: 'Physical Address' },
  12: { name: 'Postal Code' },
  13: { name: 'City' },
  14: { name: 'Email' },
  15: { name: 'Land Line' },
  16: { name: 'Mobile Line' },
  17: { name: 'Reference 1' },
  18: { name: 'Reference 2' },
  19: { name: 'Reference 3' },
}

export const recurringBatchFilter = {
  0: { name: 'Batch Number' },
  1: { name: 'Type' },
  2: { name: 'Month' },
  3: { name: 'Reference' },
  4: { name: 'Created By' },
}

export const ticketCategory = {
  0: { name: 'Inquiry' },
  1: { name: 'Request' },
  2: { name: 'Appreciation' },
  3: { name: 'Complaint' },
  4: { name: 'Criticism' },
  5: { name: 'Payment' },
  6: { name: 'Receipt' },
}

export const ticketStatus = {
  0: { name: 'Open' },
  1: { name: 'Assigned' },
  2: { name: 'In Progress' },
  3: { name: 'Closed' },
}

export const ticketMode = {
  0: { name: 'Phone' },
  1: { name: 'Email' },
  2: { name: 'In-person' },
  3: { name: 'SMS' },
}

export const ticketPriority = {
  0: { name: 'Low' },
  1: { name: 'Normal' },
  2: { name: 'High' },
  3: { name: 'Urgent' },
  4: { name: 'Critical' },
}

export const ticketReferenceType = {
  0: { name: 'UnKnown' },
  1: { name: 'Customer' },
  2: { name: 'Employer' },
}

export const ticketResolveOption = {
  0: { name: 'In Progress' },
  1: { name: 'Close' },
}

export const notificationJobStatus = {
  1: { name: 'Pending' },
  2: { name: 'Posted' },
  4: { name: 'Exception Raised' },
}

export const pettyCashierTransactionType = {
  1: { name: 'Petty Cash Payment (New Request)' },
  2: { name: 'Petty Cash Payment (Authorized Request)' },
  4: { name: 'Petty Cash Requisition' },
}

export const pettyCashRequisitionAuthStatus = {
  1: { name: 'Pending' },
  2: { name: 'Authorized' },
  4: { name: 'Rejected' },
  8: { name: 'Paid' },
}
export const treasuryTransactionType = {
  1: { name: 'Bank to Treasury' },
  2: { name: 'Treasury to Bank' },
  4: { name: 'Treasury to Teller' },
  8: { name: 'Treasury to Treasury' },
  16: { name: 'Treasury to Teller (Cash Requisition)' },
}

export const treasuryTrailCategory = {
  0: { name: 'Received' },
  1: { name: 'Sent' },
}

export const treasuryManagementRequestStatus = {
  1: { name: 'Pending' },
  2: { name: 'Authorized' },
  4: { name: 'Rejected' },
  8: { name: 'Posted' },
}

export const cashWithdrawalRequestAuthStatus = {
  1: { name: 'Pending' },
  2: { name: 'Authorized' },
  4: { name: 'Rejected' },
  8: { name: 'Paid' },
}

export const accountClosureRequestStatus = {
  1: { name: 'Registered' },
  2: { name: 'Approved' },
  4: { name: 'Verified' },
  8: { name: 'Settled' },
  16: { name: 'Deferred' },
}

export const fixedDepositCategory = {
  0: { name: 'Term Deposit' },
  1: { name: 'Call Deposit' },
  2: { name: 'Smart Deposit' },
}

export const fixedDepositMaturityAction = {
  0: { name: 'Pay Principal & Interest Due' },
  1: { name: 'Pay Interest Due & Roll-over Principal' },
  2: { name: 'Roll-over Principal & Interest Due' },
}

export const fixedDepositAuthOption = {
  1: { name: 'Post' },
  2: { name: 'Reject' },
}

export const fixedDepositStatus = {
  1: { name: 'Running' },
  2: { name: 'Paid' },
  4: { name: 'Revoked' },
  8: { name: 'New' },
  16: { name: 'Rejected' },
  32: { name: 'Running (With Lien)' },
  64: { name: 'Verified' },
}

export const tellerCashTransactionAuthOption = {
  1: { name: 'Authorize' },
  2: { name: 'Reject' },
}

export const tellerCashRequisitionAuthStatus = {
  1: { name: 'Pending' },
  2: { name: 'Authorized' },
  4: { name: 'Rejected' },
  8: { name: 'Posted' },
}

export const verificationOption = {
  1: { name: 'Verify' },
  2: { name: 'Reject' },
}

export const authorizationOption = {
  1: { name: 'Post' },
  2: { name: 'Reject' },
}

export const superSaverPayableStatus = {
  0: { name: 'Pending' },
  1: { name: 'Verified' },
  2: { name: 'Posted' },
  3: { name: 'Rejected' },
}

export const customerAccountRemarkType = {
  0: { name: 'Actionable' },
  1: { name: 'Informational' },
}

export const customerAccountManagementAction = {
  48833: { name: 'Account Activation' },
  48834: { name: 'Account Freezing' },
  48835: { name: 'Account Remark' },
  48838: { name: 'Account Closure' },
  48839: { name: 'Account Signing Instructions' },
  48840: { name: 'Account Statement Printing' },
  48841: { name: 'Partial Withdrawal' },
}

export const chequeBookType = {
  0: { name: 'In-House' },
  1: { name: 'External' },
}

export const standingOrderDurationSpecification = {
  0: { name: 'Date Range' },
  1: { name: 'Duration (Months)' },
}

export const chargeType = {
  1: { name: 'Percentage' },
  2: { name: 'Fixed Amount' },
}

export const inHouseChequeFunding = {
  1: { name: 'Debit Customer Account' },
  2: { name: 'Debit G/L Account' },
  4: { name: 'Debit Customer Account (Closure)' },
  8: { name: 'Debit Loan Disbursement Queue' },
}

export const printMedium = {
  0: { name: 'Receipt' },
  1: { name: 'Cheque Leaf' },
}

export const salaryHeadType = {
  61680: { name: 'Basic Pay Earning (Full-Time)' },
  61681: { name: 'N.S.S.F Deduction' },
  61682: { name: 'N.H.I.F Deduction' },
  61683: { name: 'P.A.Y.E Deduction' },
  61684: { name: 'Provident Fund Deduction (Statutory)' },
  61686: { name: 'Loan Deduction' },
  61687: { name: 'Investment Deduction' },
  61688: { name: 'Other Earning' },
  61689: { name: 'Other Deduction' },
  61690: { name: 'Provident Fund Deduction (Voluntary)' },
  61691: { name: 'Basic Pay Earning (Part-Time)' },
  61692: { name: 'Basic Pay Earning (Contract)' },
}

export const alternateChannelReconciliationPeriodStatus = {
  1: { name: 'Open' },
  2: { name: 'Closed' },
  4: { name: 'Suspended' },
}

export const TransactionBackingStore = {
  0: { name: 'Current' },
  1: { name: 'Archive' },
}

export const SetDifferenceMode = {
  1: { name: 'RRN exists in File but not System' },
  2: { name: 'RRN exists in System but not File' },
  4: { name: 'STAN exists in File but not System' },
  8: { name: 'STAN exists in System but not File' },
  16: { name: 'Callback Payload exists in File but not System' },
  32: { name: 'Callback Payload exists in System but not File' },
}

export const salaryPeriodAuthOption = {
  1: { name: 'Post' },
  2: { name: 'Suspend' },
}

export const unitOfMeasureBaseUnits = {
  0: { name: 'Oz' },
  1: { name: 'Lbs' },
  2: { name: 'Each' },
  3: { name: '50-lb bag' },
}

export const reportTemplateSpreadSheetCellValueComputationMode = {
  0: { name: 'Sum Line Item Balances' },
  1: { name: 'Aggregate Line Item Balances' },
}
