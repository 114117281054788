/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import Pager from '../../../../../Components/Tables/Pager'
import Swal from 'sweetalert2'

const ChequesTransfer = () => {
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
  })
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [chequesTransfer, setChequesTransfer] = useState([])
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })

  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadData()
  }, [reqParams, refreshAt])

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={chequesTransfer.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setChequesTransfer([...chequesTransfer, r])
            } else {
              setChequesTransfer(chequesTransfer.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    { label: localize('chequeNumber'), template: r => <>{r.number}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    { label: localize('drawer'), template: r => <>{r.drawer}</> },
    { label: localize('drawerBank'), template: r => <>{r.drawerBank}</> },
    {
      label: localize('drawerBankBranch'),
      template: r => <>{r.drawerBankBranch}</>,
    },
    {
      label: localize('writeDate'),
      template: r => <>{formatDate(r.writeDate)}</>,
    },
    {
      label: localize('chequeType'),
      template: r => <>{r.chequeTypeDescription}</>,
    },
    {
      label: localize('maturityDate'),
      template: r => <>{formatDate(r.maturityDate)}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNo'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFile'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const loadData = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/Teller/fetch-teller-untransferred-cheques',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const handleOnTransferCheque = async () => {
    setIsBusy(true)
    if (chequesTransfer.length === 0) {
      Swal.fire(
        'Cheques Transfer',
        'Please select at least one cheque',
        'warning'
      )
      setIsBusy(false)
      return
    }
    const { success, data } = await postData(
      '/lookup/Teller/transfer-external-cheques',
      { SelectedExternalCheques: chequesTransfer },
      false
    )
    if (success) {
      await Swal.fire({
        icon: 'success',
        title: 'Operation Successful',
        text: 'Teller Cheques Transfer',
      }).then(result => {
        if (result.isConfirmed) {
          setChequesTransfer([])
          setRefreshAt(!refreshAt)
        }
      })
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('tellers'),
          localize('chequesTransfer'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <button
                          onClick={handleOnTransferCheque}
                          className="btn btn-success waves-effect waves-light text-capitalize me-2"
                        >
                          <i className="mdi mdi-check-decagram me-2"></i>
                          {localize('update')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        name=""
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 60 }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChequesTransfer
