/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { MaskPercentage } from '../../../../../Components/InputMasks/MaskPercentage'
import Loader from '../../../../../Components/Loaders/Loader'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import { LoanProductLookup } from '../../../../../Components/Lookup/LoanProductLookup'
import SavingsProductLookup from '../../../../../Components/Lookup/SavingsProductLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { ChargeType } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { generateGuid } from '../../../../../Helpers/extensions'
import rightAlignFragment, {
  formatAsPercent,
  formatCurrency,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const DirectDebitsModal = ({
  mode,
  closeModal,
  selectedDirectDebit,
  setSelectedDirectDebit,
  loadTableData,
  loadTabData,
  tBr,
  setTBr,
  tempTiers,
  setTempTiers,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const [tiersCalculationLoader, setTiersCalculationLoader] = useState(false)
  const tBrColumns = [
    {
      label: rightAlignFragment(localize('range(lowerLimit)')),
      template: r => <>{formatCurrency(r?.rangeLowerLimit)}</>,
    },
    {
      label: rightAlignFragment(localize('range(upperLimit)')),
      template: r => <>{formatCurrency(r?.rangeUpperLimit)}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r?.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r?.chargePercentage)}</>,
    },
    {
      label: rightAlignFragment(localize('fixedAmount')),
      template: r => <>{formatCurrency(r?.chargeFixedAmount)}</>,
    },
  ]
  async function handleSubmit() {
    const url =
      mode === 'edit'
        ? '/DirectDebit/update-direct-debit-with-graduated-scales'
        : '/DirectDebit/create-direct-debit-with-graduated-scales'
    setIsBusy(true)
    const { success } = await postData(
      url,
      {
        directDebit: selectedDirectDebit,
        directDebitGraduatedScales: tempTiers,
      },
      false
    )

    if (success) {
      closeModal()
      showNotification('Direct Debits', 'success')
      loadTableData()
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleOnAddDirectDebitTypeTier = async () => {
    if (tBr?.chargeType == 1) {
      setTiersCalculationLoader(true)
      const { success, data } = await postData(
        '/DirectDebit/add-direct-debit-graduated-scale',
        {
          directDebitGraduatedScales: tempTiers || [],
          directDebitGraduatedScale: tBr || {},
        },
        false
      )
      if (success) {
        setTiersCalculationLoader(false)
        setTempTiers([
          ...tempTiers,
          {
            ...data.directDebitGraduatedScale,
            id: generateGuid(),
          },
        ])
        setTBr({ chargeType: Object.keys(ChargeType)[0] })
      }
      setTiersCalculationLoader(false)
    } else {
      if (!selectedDirectDebit?.maximumCharge) {
        showNotification(
          'Direct Debits',
          'info',
          'sorry, you must first provide the maximum charge'
        )
        return
      } else {
        if (
          Number(stripNonNumericCharacters(tBr?.chargeFixedAmount)) >
          Number(stripNonNumericCharacters(selectedDirectDebit?.maximumCharge))
        ) {
          showNotification(
            'Direct Debits',
            'info',
            'sorry, but the amount entered is greater than the maximum charge'
          )
          return
        } else {
          setTiersCalculationLoader(true)
          const { success, data } = await postData(
            '/DirectDebit/add-direct-debit-graduated-scale',
            {
              directDebitGraduatedScales: tempTiers || [],
              directDebitGraduatedScale: tBr || {},
            },
            false
          )
          if (success) {
            setTiersCalculationLoader(false)
            setTempTiers([...tempTiers, data.directDebitGraduatedScale])
            setTBr({ chargeType: Object.keys(ChargeType)[0] })
          }
          setTiersCalculationLoader(false)
        }
      }
    }
  }
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalTitle={'directDebits'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cDirectDebitIsLocked"
              onCheck={e => {
                setSelectedDirectDebit({
                  ...selectedDirectDebit,
                  isLocked: e,
                })
              }}
              checked={selectedDirectDebit?.isLocked}
            />
            <label htmlFor="cDirectDebitIsLocked" className="text-capitalize">
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 g-3">
            <div className="col-md-12">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['DirectDebit.Description']?.message
                }
              >
                <label className="text-capitalize col-1">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedDirectDebit?.description}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    clearValidation('DirectDebit.Description')
                    setSelectedDirectDebit({
                      ...selectedDirectDebit,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-2">
                  {localize('productCode')}
                </label>
                <EnumsServerCombo
                  disabled={mode === 'edit'}
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ProductCode"
                  value={selectedDirectDebit?.customerAccountTypeProductCode}
                  onChange={e =>
                    setSelectedDirectDebit({
                      ...selectedDirectDebit,
                      customerAccountTypeProductCode: e.value,
                      customerAccountTypeProductCodeDescription: e.label,
                    })
                  }
                />
              </div>
            </div>

            {mode === 'edit' ? (
              <>
                <div className="col-md-6">
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-1 me-2">
                      {localize('product')}
                    </label>
                    <input
                      defaultValue={
                        selectedDirectDebit?.customerAccountTypeTargetProductDescription
                      }
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {selectedDirectDebit?.customerAccountTypeProductCode ===
                  '1' && (
                  <div className="col-md-6">
                    <div
                      className="with-validation d-flex align-items-center mb-2"
                      validation-message={
                        globalValidationErrors[
                          'DirectDebit.CustomerAccountTypeTargetProductId'
                        ]?.message
                      }
                    >
                      <label className="text-capitalize col-1 me-2">
                        {localize('product')}
                      </label>
                      <SavingsProductLookup
                        displayValue={
                          selectedDirectDebit?.customerAccountTypeTargetProductDescription
                        }
                        onSelect={v => {
                          if (v.isLocked) {
                            return showNotification(
                              'Direct Debit',
                              'info',
                              'sorry, but the selected entry is locked'
                            )
                          } else {
                            clearValidation(
                              'DirectDebit.CustomerAccountTypeTargetProductId'
                            )
                            setSelectedDirectDebit(prev => ({
                              ...prev,
                              customerAccountTypeTargetProductDescription:
                                v.description,
                              customerAccountTypeTargetProductId: v.id,
                            }))
                          }
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedDirectDebit?.customerAccountTypeProductCode ===
                  '2' && (
                  <div className="col-md-6">
                    <div
                      className="with-validation d-flex align-items-center mb-2"
                      validation-message={
                        globalValidationErrors[
                          'DirectDebit.CustomerAccountTypeTargetProductId'
                        ]?.message
                      }
                    >
                      <label className="text-capitalize col-1 me-2">
                        {localize('product')}
                      </label>
                      <LoanProductLookup
                        displayValue={
                          selectedDirectDebit?.customerAccountTypeTargetProductDescription
                        }
                        onSelect={v => {
                          if (v.isLocked) {
                            return showNotification(
                              'Direct Debit',
                              'info',
                              'sorry, but the selected entry is locked'
                            )
                          } else {
                            clearValidation(
                              'DirectDebit.CustomerAccountTypeTargetProductId'
                            )
                            setSelectedDirectDebit(prev => ({
                              ...prev,
                              customerAccountTypeTargetProductDescription:
                                v.description,
                              customerAccountTypeTargetProductId: v.id,
                            }))
                          }
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedDirectDebit?.customerAccountTypeProductCode ===
                  '3' && (
                  <div className="col-md-6">
                    <div
                      className="with-validation d-flex align-items-center mb-2"
                      validation-message={
                        globalValidationErrors[
                          'DirectDebit.CustomerAccountTypeTargetProductId'
                        ]?.message
                      }
                    >
                      <label className="text-capitalize col-1 me-2">
                        {localize('product')}
                      </label>

                      <InvestmentProductLookup
                        displayValue={
                          selectedDirectDebit?.customerAccountTypeTargetProductDescription
                        }
                        onSelect={v => {
                          if (v.isLocked) {
                            return showNotification(
                              'Direct Debit',
                              'info',
                              'sorry, but the selected entry is locked'
                            )
                          } else {
                            clearValidation(
                              'DirectDebit.CustomerAccountTypeTargetProductId'
                            )
                            setSelectedDirectDebit(prev => ({
                              ...prev,
                              customerAccountTypeTargetProductDescription:
                                v.description,
                              customerAccountTypeTargetProductId: v.id,
                            }))
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="row mb-2 g-3 align-items-center">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-2">
                  {localize('maximumCharge')}
                </label>
                <MaskNumber
                  defaultMaskValue={selectedDirectDebit?.maximumCharge || ''}
                  onMaskNumber={c => {
                    setSelectedDirectDebit({
                      ...selectedDirectDebit,
                      maximumCharge: c.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <label className="text-capitalize col-1 me-2">
                  {localize('roundingType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="RoundingType"
                  value={selectedDirectDebit?.roundingType}
                  onChange={e =>
                    setSelectedDirectDebit({
                      ...selectedDirectDebit,
                      roundingType: e.value,
                      roundingTypeDescription: e.label,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {loadTabData ? (
            <Loader />
          ) : (
            <div className="row col-12 mt-2">
              <Tab
                tabItems={[
                  {
                    label: localize('tiers'),
                    item: (
                      <>
                        <div
                          className="p-2 mt-4"
                          style={{
                            border: 'solid 1px #0000001f',
                            borderRadius: 10,
                            position: 'relative',
                          }}
                        >
                          <div
                            className="bg-white px-2 row text-capitalize"
                            style={{
                              position: 'relative',
                              top: -20,
                              left: 30,
                              maxWidth: 'fit-content',
                            }}
                          >
                            {localize('tierDetails')}
                          </div>
                          <div className="row mb-3 g-3 align-items-center">
                            <div className="col-md-4">
                              <div className="with-validation d-flex align-items-center mb-2">
                                <label className="text-capitalize col-3 me-2">
                                  {localize('range(lowerLimit)')}
                                </label>
                                <MaskNumber
                                  customContainerStyle={{ width: '100%' }}
                                  defaultMaskValue={tBr?.rangeLowerLimit || ''}
                                  onMaskNumber={c => {
                                    setTBr({
                                      ...tBr,
                                      rangeLowerLimit: c.target.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="d-flex align-items-center mb-2">
                                <label className="text-capitalize col-2">
                                  {localize('chargeType')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ width: '100%' }}
                                  enumsKey="ChargeType"
                                  value={tBr?.chargeType}
                                  onChange={e =>
                                    setTBr({
                                      ...tBr,
                                      chargeType: e.value,
                                      chargeTypeDescription: e.label,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            {tBr?.chargeType === '1' && (
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-2">
                                    {localize('chargePercentage')}
                                  </label>
                                  <MaskPercentage
                                    defaultMaskValue={
                                      tBr?.chargePercentage || ''
                                    }
                                    onMaskChange={e =>
                                      setTBr({
                                        ...tBr,
                                        chargePercentage: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {tBr?.chargeType === '2' && (
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-2">
                                    {localize('chargeFixedAmount')}
                                  </label>
                                  <MaskNumber
                                    defaultMaskValue={
                                      tBr?.chargeFixedAmount || ''
                                    }
                                    onMaskNumber={c => {
                                      setTBr({
                                        ...tBr,
                                        chargeFixedAmount: c.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row mb-3 g-3">
                            <div className="d-flex align-items-center justify-content-end">
                              <button
                                onClick={() => {
                                  handleOnAddDirectDebitTypeTier()
                                }}
                                className="btn btn-success px-5 mx-0 text-uppercase"
                              >
                                {localize('add')}
                              </button>
                            </div>
                          </div>

                          <div
                            className="p-2 mt-2"
                            style={{
                              border: 'solid 1px #0000001f',
                              borderRadius: 5,
                            }}
                          >
                            <div
                              className="bg-white row px-2 text-capitalize"
                              style={{
                                position: 'relative',
                                top: -20,
                                left: 30,
                                maxWidth: 'fit-content',
                              }}
                            >
                              {localize('tiers')}
                            </div>

                            {tiersCalculationLoader ? (
                              <Loader />
                            ) : (
                              <div style={{ marginTop: -15 }}>
                                {
                                  <div
                                    style={{
                                      maxHeight: 300,
                                      overflowY: 'scroll',
                                    }}
                                  >
                                    <SimpleTable
                                      columns={tBrColumns}
                                      data={tempTiers}
                                      contextMenu={r => (
                                        <>
                                          {tempTiers[tempTiers.length - 1] &&
                                          r?.id ===
                                            tempTiers[tempTiers.length - 1]
                                              ?.id ? (
                                            <div
                                              className="ctxt-menu-item text-capitalize text-danger"
                                              onClick={() => {
                                                let ts = tempTiers
                                                let found = ts.findIndex(
                                                  t => t.id === r?.id
                                                )
                                                ts.splice(found, 1)
                                                setTempTiers(ts)
                                              }}
                                            >
                                              <i className="uil uil-times-circle text-danger text-capitalize"></i>
                                              {localize('remove')}
                                            </div>
                                          ) : null}
                                        </>
                                      )}
                                    />
                                  </div>
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          )}
        </>
      )}
    </ModalTemplate>
  )
}

export default DirectDebitsModal
