/** @format */

/* eslint-disable */
import React from 'react'
import jsonToCsv from '../../Helpers/Functions/jsonToCsv'
import ExportButton from '../Buttons/ExportButton/ExportButton'
import Swal from 'sweetalert2'

const ExportMismatchedEntries = ({ data }) => {
  const handleExportCsv = async () => {
    if (data.length === 0)
      return Swal.fire({
        icon: 'warning',
        title: 'Operation not allowed',
        text: 'No data to export',
      })

    const csv = jsonToCsv(data)

    const element = document.createElement('a')
    const file = new Blob([csv], { type: 'text/csv' })
    element.href = URL.createObjectURL(file)
    element.download = 'mismatched-entries.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  return <ExportButton handleExportButton={handleExportCsv} />
}

export default ExportMismatchedEntries
