/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import LoanDisbursmentModeModal from './LoanDisbursmentModeModal'
import { DisbursementCategory } from '../../../../Global/enumeration'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function LoanDisbursementModes() {
  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('disbursementCategoryDescription'),
      template: r => <>{r?.disbursementCategoryDescription}</>,
    },
    {
      label: localize('disbursementTargetDescription'),
      template: r => <>{r?.disbursementTargetDescription}</>,
    },
    {
      label: localize('recoverSundryArrearages'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.recoverSundryArrearages.toString())}
        </span>
      ),
    },
    {
      label: localize('isDefault') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isDefault.toString())}
        </span>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [mode, setMode] = useState(null)
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedLoanDisbursementMode, setSelectedLoanDisbursementMode] =
    useState({})
  const [refresh, setRefresh] = useState(null)
  const [tabLoading, setTabLoading] = useState(false)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadTableData()
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/LoanDisbursementMode/find-loan-disbursement-mode-by-filter-in-page-async',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const fetchLoanDisbursementModeMetadata = async id => {
    setTabLoading(true)
    const { success, data } = await getData(
      '/LoanDisbursementMode/find-loan-disbursement-mode-metadata',
      { loanDisbursementModeId: id },
      false
    )
    if (success) {
      setTabLoading(false)
      return data
    } else {
      setTabLoading(false)
      return null
    }
  }

  const handleOnAdd = async () => {
    setMode('add')
    const metadata = await fetchLoanDisbursementModeMetadata(
      '00000000-0000-0000-0000-000000000000'
    )
    setSelectedLoanDisbursementMode({
      disbursementCategory: Object.keys(DisbursementCategory)[0],
      commissions: metadata?.commissions || [],
    })
  }

  const handleOnEdit = async r => {
    setMode('edit')
    const metadata = await fetchLoanDisbursementModeMetadata(r?.id)
    setSelectedLoanDisbursementMode({
      ...r,
      commissions: metadata?.commissions || [],
    })
  }

  const handleOnView = async r => {
    setMode('view')
    const metadata = await fetchLoanDisbursementModeMetadata(r?.id)
    setSelectedLoanDisbursementMode({
      ...r,
      commissions: metadata?.commissions || [],
    })
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('backofficemanagement'),
          localize('loanDisbursementModes'),
        ]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleOnAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="employeeTypeFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              handleOnView(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={async () => {
                              handleOnEdit(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {(mode === 'add' || mode === 'edit') && (
        <LoanDisbursmentModeModal
          selectedLoanDisbursementMode={selectedLoanDisbursementMode}
          setSelectedLoanDisbursementMode={setSelectedLoanDisbursementMode}
          mode={mode}
          onHideModal={() => {
            setMode(null)
          }}
          tabLoading={tabLoading}
          loadTableData={loadTableData}
        />
      )}
    </>
  )
}
export default LoanDisbursementModes
