/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import { getData } from '../../../../../Helpers/webApi'
import { formatDate } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
function BankToBankRequestCustomerAccounts({ selectedCustomer, showLoader }) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageCount: 10,
    includeBalances: true,
    includeProductDescription: true,
  })
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.id) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer?.id])

  const [groupBy, setGroupBy] = useState({
    label: localize('recordStatus'),
    column: 'customerRecordStatusDescription',
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    { label: localize('accountName'), template: r => <>{r.accountName}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },

    {
      label: localize('customerMemberNumber'),
      template: r => <>{r.paddedCustomerMemberNumber}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerReference3}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    {
      label: localize('branch'),
      column: 'branchDescription',
    },
    {
      label: localize('createdBy'),
      column: 'createdBy',
    },
    {
      label: localize('productCode'),
      column: 'customerAccountTypeProductCodeDescription',
    },
    {
      label: localize('productName'),
      column: 'customerAccountTypeTargetProductDescription',
    },
    {
      label: localize('productSection'),
      column: 'customerAccountTypeTargetProductSectionDescription',
    },
    {
      label: localize('adminDivision'),
      column: 'customerAdministrativeDivisionDescription',
    },
    {
      label: localize('dutyStation'),
      column: 'customerDutyStationDescription',
    },
    {
      label: localize('businessGroupEmployer'),
      column: 'customerEmployerDescription',
    },
    {
      label: localize('gender'),
      column: 'customerIndividualGenderDescription',
    },
    {
      label: localize('identityCardType'),
      column: 'customerIndividualIdentityCardTypeDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'customerIndividualMaritalStatusDescription',
    },
    {
      label: localize('nationality'),
      column: 'customerIndividualNationalityDescription',
    },
    {
      label: localize('salutation'),
      column: 'customerIndividualSalutationDescription',
    },
    {
      label: localize('customerType'),
      column: 'customerMembershipClassCustomerTypeDescription',
    },
    {
      label: localize('maritalStatus'),
      column: 'loanInterestRecoveryModeDescription',
    },
    {
      label: localize('modifiedBy'),
      column: 'modifiedBy',
    },
    {
      label: localize('recordStatus'),
      column: 'customerRecordStatusDescription',
    },
    {
      label: localize('recruitedBy'),
      column: 'createdBy',
    },
    {
      label: localize('accountStatus'),
      column: 'statusDescription',
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-filter-in-page',
      { customerId: selectedCustomer.id, ...reqParams },
      false
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  return (
    <>
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <>
                  <div className="row mb-2 mx-0">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-3 d-flex align-items-center">
                      <label className="col-3  text-capitalize">
                        {localize('groupBy')}
                      </label>
                      <select
                        className="form-select text-capitalize"
                        defaultValue={groupBy.column}
                        style={{ maxWidth: 'fit-content' }}
                        onChange={e =>
                          setGroupBy({
                            label: groupingColumns.find(
                              f => f.column === e.target.value
                            )?.label,
                            column: e.target.value,
                          })
                        }
                      >
                        {groupingColumns.map(({ label, column }) => (
                          <option className="text-capitalize" value={column}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-2"></div>
                    <form
                      className="d-flex align-items-center col-4 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label className="text-capitalize" htmlFor="searchTable">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                  </div>

                  <GroupedTable
                    groupBy={groupBy}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                </>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default BankToBankRequestCustomerAccounts
