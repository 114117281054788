/** @format */

import React from 'react'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import UserDefinedReportsLookup from '../../../../Components/Lookup/UserDefinedReportsLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const CreateReport = ({
  handleClose,
  mode,
  selectedReport,
  handleAction,
  isBusy,
  setSelectedReport,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectReport = report => {
    clearValidation('ParentReportName')
    setSelectedReport({
      ...selectedReport,
      parentId: report?.id,
      indentedReportName: report?.reportName,
    })
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={'report'}
      modalClassName="modal-xl"
      handleClose={handleClose}
      handleAction={handleAction}
      disableClose={isBusy}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div
            className="col-md-6 d-flex align-items-center with-validation"
            validation-message={
              globalValidationErrors['ParentReportName']?.message
            }
          >
            <label className="col-2 text-capitalize">
              {localize('parentReport')}
            </label>
            <UserDefinedReportsLookup
              displayValue={
                mode === 'edit'
                  ? selectedReport?.reportName
                  : selectedReport?.indentedReportName
              }
              onSelect={handleSelectReport}
            />
          </div>
          <div
            className="col-md-6 d-flex align-items-center with-validation"
            validation-message={globalValidationErrors['ReportName']?.message}
          >
            <label className="col-2 text-capitalize">
              {localize('reportName')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedReport?.reportName}
              onChange={e => {
                clearValidation('ReportName')
                setSelectedReport({
                  ...selectedReport,
                  reportName: e.target.value,
                })
              }}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('category')}
            </label>
            <EnumsServerCombo
              enumsKey="ReportTemplateCategory"
              value={selectedReport?.category}
              onChange={e => {
                setSelectedReport({
                  ...selectedReport,
                  category: e.value,
                })
              }}
              customContainerStyle={{
                width: '100%',
              }}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('reportPath')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedReport?.reportPath}
              onChange={e => {
                setSelectedReport({
                  ...selectedReport,
                  reportPath: e.target.value,
                })
              }}
            />
          </div>
          <div className="col-md-12 d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('reportQuery')}
            </label>
            <input
              type="text"
              className="form-control"
              value={selectedReport?.reportQuery}
              onChange={e => {
                setSelectedReport({
                  ...selectedReport,
                  reportQuery: e.target.value,
                })
              }}
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateReport
