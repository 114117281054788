/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import SimpleTableWithSearchAndPaging from '../../../../Components/Tables/SimpleTableWithSearchAndPaging'
import { getData, postData } from '../../../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ReportTemplateLookup from '../../../../Components/Lookup/ReportTemplateLookup'
import FIleSelectLookup from '../../../../Components/Lookup/FIleSelectLookup'
import { generateGuid } from '../../../../Helpers/extensions'
import { reportTemplateCategory } from '../../../../Helpers/constants'
import { reportTemplateSpreadSheetCellValueComputationMode } from '../../../../Global/enumeration'
import Tab from '../../../../Components/Tabs/Tab'
import { formatDate } from '../../../../Helpers/formatters'

function EditReportsTemplate({ mode, close, template, refreshTmps }) {
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState(null)
  const [searchString, setSearchString] = useState('')
  const [reqParams, setReqParams] = useState({
    chartOfAccountType: 1000,
    filterText: '',
    pageIndex: 0,
    pageSize: 100000,
  })
  const [cTemp, setCTemp] = useState(template)
  const [entries, setEntries] = useState([])

  const absChk = generateGuid()
  const lockedChk = generateGuid()

  const columns = [
    {
      label: `${localize('select')}?`,
      template: r => (
        <label
          htmlFor={`editReportTemplate${r.id}`}
          className=" fw-normal"
          style={{ transform: 'translateY(6px)', padding: 0, margin: -10 }}
        >
          <Checkbox
            id={`editReportTemplate${r.id}`}
            checked={!!entries.find(e => e.chartOfAccountId === r.id)}
            onCheck={v => {
              const isFound = !!entries.find(e => e.chartOfAccountId === r.id)
              if (isFound) {
                let ent = [...entries].filter(e => e.chartOfAccountId !== r.id)
                let childrenIds = [...ent]
                  .filter(e => e.parentId === r.id)
                  .map(c => c.chartOfAccountId)
                let noChildren = []
                for (let e of ent) {
                  let found = childrenIds.find(c => c === e.id)
                  if (found) {
                  } else {
                    noChildren.push(e)
                  }
                }
                setEntries(noChildren)
              } else {
                const children = (data?.pageCollection || []).filter(
                  e => e.parentId === r.id && e.category.toString() === '4097'
                )

                setEntries([
                  ...entries,
                  { ...r, chartOfAccountId: r.id },
                  ...children.map(c => ({ ...c, chartOfAccountId: c.id })),
                ])
              }
            }}
          />
        </label>
      ),
    },
    {
      label: localize('accountName'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          <span dangerouslySetInnerHTML={{ __html: r.indentedName }} />
        </label>
      ),
    },
    {
      label: localize('category'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          {r.categoryDescription}
        </label>
      ),
    },
    {
      label: localize('costDistributionRule'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          {r.costDistributionRuleDescription}
        </label>
      ),
    },
    {
      label: localize('currency'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          {r.currencyDescription}
        </label>
      ),
    },
    {
      label: localize('bookBalance'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          {r.formattedBalance}
        </label>
      ),
    },
    {
      label: localize('treeDepth'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>{r.depth}</label>
      ),
    },
    {
      label: localize('isControlAcount') + '?',
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          <span className="text-capitalize">
            {r.isControlAccount.toString()}
          </span>
        </label>
      ),
    },
    {
      label: localize('isReconciliationAccount') + '?',
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          <span className="text-capitalize">
            {r.isReconciliationAccount.toString()}
          </span>
        </label>
      ),
    },
    {
      label: localize('postAutomaticallyOnly') + '?',
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          <span className="text-capitalize">
            {r.postAutomaticallyOnly.toString()}
          </span>
        </label>
      ),
    },
    {
      label: localize('isConfidential') + '?',
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          <span className="text-capitalize">{r.isConfidential.toString()}</span>
        </label>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          <span className="text-capitalize">{r.isLocked.toString()}</span>
        </label>
      ),
    },
    {
      label: localize('modifiedBy'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>{r.modifiedBy}</label>
      ),
    },
    {
      label: localize('modifiedDate'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          {formatDate(r.modifiedDate)}
        </label>
      ),
    },
    {
      label: localize('createdBy'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>{r.createdBy}</label>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => (
        <label htmlFor={`editReportTemplate${r.id}`}>
          {formatDate(r.createdDate)}
        </label>
      ),
    },
  ]

  const tabItems = [
    {
      label: localize('assets'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            selectables
            hidePageSize
            isBusy={isBusy}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={p => {
              setReqParams({ ...reqParams, pageSize: p })
            }}
            currentPage={reqParams.pageIndex}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            noSelect
          />
        </div>
      ),
      enum: 1000,
    },
    {
      label: localize('liabilities'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            selectables
            hidePageSize
            isBusy={isBusy}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={p => {
              setReqParams({ ...reqParams, pageSize: p })
            }}
            currentPage={reqParams.pageIndex}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            noSelect
          />
        </div>
      ),
      enum: 2000,
    },
    {
      label: localize('equity') + '/' + localize('capital'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            selectables
            hidePageSize
            isBusy={isBusy}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={p => {
              setReqParams({ ...reqParams, pageSize: p })
            }}
            currentPage={reqParams.pageIndex}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            noSelect
          />
        </div>
      ),
      enum: 3000,
    },
    {
      label: localize('income') + '/' + localize('revenue'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            selectables
            hidePageSize
            isBusy={isBusy}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={p => {
              setReqParams({ ...reqParams, pageSize: p })
            }}
            currentPage={reqParams.pageIndex}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            noSelect
          />
        </div>
      ),
      enum: 4000,
    },
    {
      label: localize('expenses'),
      item: (
        <div className="p-2">
          <SimpleTableWithSearchAndPaging
            selectables
            hidePageSize
            isBusy={isBusy}
            data={data || []}
            columns={columns}
            pageSize={reqParams.pageSize}
            onPageSizeChanged={p => {
              setReqParams({ ...reqParams, pageSize: p })
            }}
            currentPage={reqParams.pageIndex}
            searchString={searchString}
            onSearch={s => {
              setReqParams({ ...reqParams, filterText: s })
              setSearchString(s)
            }}
            onPageNavigate={p => setReqParams({ ...reqParams, pageIndex: p })}
            noSelect
          />
        </div>
      ),
      enum: 5000,
    },
  ]

  useEffect(() => {
    loadChartOfAccounts()
    loadEntries()
    //eslint-disable-next-line
  }, [reqParams])

  async function loadChartOfAccounts() {
    setIsBusy(true)
    setData(null)
    const resp = await getData(
      '/ChartOfAccount/find-chart-of-accounts-by-filter-in-page',
      reqParams,
      false
    )
    if (resp.success) {
      setIsBusy(false)
      setData(resp.data)
    } else {
      setIsBusy(false)
      setData(null)
    }
  }

  async function loadEntries() {
    const { success, data } = await getData(
      '/reportTemplate/find-by-report-template-entry-by-report-template-id',
      { reportTemplateId: cTemp?.id },
      false
    )
    if (success) {
      setEntries(data?.result ? data?.result : [])
    }
  }

  const editMode = mode === 'edit'

  async function handleUpdate() {
    const { success } = await postData(
      '/reportTemplate/update-report-template-with-entries',
      { reportTemplate: cTemp, reportTemplateEntryDTOs: entries },
      false,
      'Report Template Updated Successfuly!'
    )
    if (success) {
      close()
      refreshTmps()
    }
  }
  async function handleCreate() {
    const { success } = await postData(
      '/reportTemplate/create-report-template-with-entries',
      { reportTemplate: cTemp, reportTemplateEntryDTOs: entries },
      false,
      'Report Entry Created!'
    )
    if (success) {
      close()
      refreshTmps()
    }
  }
  return (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white text-capitalize">
        {localize(mode)}
        <i className="uil uil-angle-right mx-2"></i>
        <span className="text-muted fs-6 text-uppercase">
          {localize('reportTemplates')}
        </span>

        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={close}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody className="bg-white">
        <div className="row">
          <div className="col">
            <div className="d-flex align-items-center mb-2">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('parentTemplate')}
              </label>
              <ReportTemplateLookup
                onSelect={p =>
                  setCTemp({
                    ...cTemp,
                    parentId: p.id,
                    parentDescription: p.description,
                  })
                }
                displayValue={cTemp?.parentDescription}
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('category')}
              </label>
              <select
                value={cTemp?.category}
                onChange={e => setCTemp({ ...cTemp, category: e.target.value })}
                className="form-select"
              >
                {Object.entries(reportTemplateCategory).map(
                  ([value, { name }]) => (
                    <option value={value}>{name}</option>
                  )
                )}
              </select>
            </div>
            <div className="d-flex align-items-center mb-2">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('valueComputationMode')}
              </label>
              <select
                value={cTemp?.spreadsheetCellValueComputationMode}
                onChange={e =>
                  setCTemp({
                    ...cTemp,
                    spreadsheetCellValueComputationMode: e.target.value,
                  })
                }
                className="form-select"
              >
                {Object.entries(
                  reportTemplateSpreadSheetCellValueComputationMode
                ).map(([value, { name }]) => (
                  <option value={value}>{name}</option>
                ))}
              </select>
            </div>
            <div className="d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('templateFileName')}
              </label>
              <FIleSelectLookup
                onSelect={async f => {
                  let b64 = await toBase64(f.target.files[0])
                  setCTemp({ ...cTemp, templateFileName: b64 })
                }}
                id={generateGuid()}
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center mb-2">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('name')}
              </label>
              <input
                value={cTemp?.description}
                onChange={e =>
                  setCTemp({ ...cTemp, description: e.target.value })
                }
                type="text"
                className="form-control"
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('spreadSheetCellReference')}
              </label>
              <input
                value={cTemp?.spreadsheetCellReference}
                onChange={e =>
                  setCTemp({
                    ...cTemp,
                    spreadsheetCellReference: e.target.value,
                  })
                }
                type="text"
                className="form-control"
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('valueComputationTag')}
              </label>
              <input
                value={cTemp?.spreadsheetCellValueComputationTag}
                onChange={e =>
                  setCTemp({
                    ...cTemp,
                    spreadsheetCellValueComputationTag: e.target.value,
                  })
                }
                type="text"
                className="form-control"
              />
            </div>
            <div className="d-flex align-items-center mb-2">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('templateFileTitle')}
              </label>
              <input
                value={cTemp?.templateFileTitle}
                onChange={e =>
                  setCTemp({ ...cTemp, templateFileTitle: e.target.value })
                }
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 'fit-content',
            }}
          >
            {localize('attachedGLAccounts')}
          </div>
          <div style={{ marginTop: -10 }}>
            <Tab
              tabItems={tabItems}
              onNavigate={x => {
                setReqParams({ ...reqParams, chartOfAccountType: x.enum })
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="bg-white">
        <div className="d-flex align-items-center">
          <Checkbox id={absChk} onCheck={() => {}} />
          <label htmlFor={absChk} className="text-capitalize ms-1">
            {localize('absoluteCellValue')}?
          </label>
        </div>
        <div className="mx-4 d-flex align-items-center">
          <Checkbox
            id={lockedChk}
            checked={cTemp?.isLocked}
            onCheck={v => setCTemp({ ...cTemp, isLocked: v })}
          />
          <label htmlFor={lockedChk} className="text-capitalize ms-1">
            {localize('isLocked')}?
          </label>
        </div>
        <button className="btn btn-danger text-uppercase" onClick={close}>
          {localize('cancel')}
        </button>
        <button
          className="btn btn-success text-uppercase px-4"
          onClick={editMode ? handleUpdate : handleCreate}
        >
          {localize(editMode ? 'update' : 'create')}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default EditReportsTemplate
