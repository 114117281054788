/** @format */

import localize from '../../../../../Global/Localize'
import React from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Loader from '../../../../../Components/Loaders/Loader'
import { formatAsPercent } from '../../../../../Helpers/formatters'

export const RiskClassificationSchedules = ({
  riskClassificationSchedules,
  setRiskClassificationSchedules,
  mode,
  waitingMetadata,
}) => {
  const columns = [
    {
      label: localize('daysPastDueLowerLimit'),
      template: r => <>{r.daysPastDueLowerLimit}</>,
    },
    {
      label: localize('daysPastDueUpperLimit'),
      template: r => <>{r.daysPastDueUpperLimit}</>,
    },
    {
      label: localize('classification'),
      template: r => <>{r.classificationDescription}</>,
    },
    {
      label: localize('provisioning'),
      template: r => <>{formatAsPercent(r.provisioning)}</>,
    },
  ]

  return (
    <>
      {waitingMetadata ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            columns={columns}
            data={riskClassificationSchedules}
            contextMenu={e =>
              mode !== 'view' && (
                <>
                  <div
                    onClick={() => {
                      setRiskClassificationSchedules(
                        riskClassificationSchedules.filter(r => r.id !== e.id),
                      )
                    }}
                    className="ctxt-menu-item"
                  >
                    <i className="uil uil-trash"></i>
                    {localize('remove')}
                  </div>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}
