/** @format */
import Loader from '../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'

export const FixedDepositTypeLevies = ({
  disabled,
  isBusy,
  selectedFixedDepositType,
  setSelectedFixedDepositType,
  prefix,
}) => {
  const handleOnCheckFixedDepositTypeLevies = (levies, checkValue) => {
    const com = [...selectedFixedDepositType?.levies].map(comm => {
      if (comm.id === levies.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    setSelectedFixedDepositType({
      ...selectedFixedDepositType,
      levies: com,
    })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedFixedDepositType?.levies.map(levies => (
            <li
              key={levies.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${levies.id}`}
                checked={levies.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleOnCheckFixedDepositTypeLevies(levies, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${levies.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  levies.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {levies.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
