/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import DepartmentLookup from '../../../../Components/Lookup/DepartmentLookup'
import PerformancePillarLookup from '../../../../Components/Lookup/PerformancePillarLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const PerformanceObjectiveModal = ({
  mode,
  selectedPerformanceObjective,
  setSelectedPerformanceObjective,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/PerformanceObjective/update',
            selectedPerformanceObjective || {
              description: '',
            },
            false
          )
        : await postData(
            '/PerformanceObjective/create',
            selectedPerformanceObjective || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      closeModal()
      loadTableData()
      showNotification('Performance Objectives', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalClassName="modal-lg"
      modalTitle={'performanceObjective'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cPerformanceObjIsLocked"
              onCheck={e => {
                setSelectedPerformanceObjective({
                  ...selectedPerformanceObjective,
                  isLocked: e,
                })
              }}
              checked={selectedPerformanceObjective?.isLocked}
            />
            <label
              htmlFor="cPerformanceObjIsLocked"
              className="text-capitalize"
            >
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3 g-3">
            <div className="col-md-12">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedPerformanceObjective?.description}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    clearValidation('Description')
                    setSelectedPerformanceObjective({
                      ...selectedPerformanceObjective,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3 g-3">
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['PerformancePillarId']?.message
                }
              >
                <label className="text-capitalize col-4">
                  {localize('performancePillar')}
                </label>
                <PerformancePillarLookup
                  displayValue={
                    selectedPerformanceObjective?.performancePillarDescription
                  }
                  onSelect={v => {
                    clearValidation('PerformancePillarId')
                    setSelectedPerformanceObjective(prev => ({
                      ...prev,
                      performancePillarDescription: v.description,
                      performancePillarId: v.id,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['DepartmentId']?.message
                }
              >
                <label className="text-capitalize col-3">
                  {localize('department')}
                </label>
                <DepartmentLookup
                  displayValue={
                    selectedPerformanceObjective?.departmentDescription
                  }
                  onSelect={v => {
                    clearValidation('DepartmentId')
                    setSelectedPerformanceObjective(prev => ({
                      ...prev,
                      departmentDescription: v.description,
                      departmentId: v.id,
                    }))
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default PerformanceObjectiveModal
