/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import Loader from '../../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { putData } from '../../../../../../../Helpers/webApi'

const AddNewReversalModal = ({
  mode,
  reversal,
  setReversal,
  handleAction,
  handleClose,
  globalValidationErrors,
  setGlobalValidationErrors,
  isBusy,
}) => {
  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)

  const evaluateValueDate = async () => {
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url, {}, false)
    if (success && data.result) {
      setUpdateDate(false)
      setReversal({
        ...reversal,
        valueDate,
      })
    } else {
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
  }

  useEffect(() => {
    if (valueDate) evaluateValueDate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="reversalBatch"
      handleAction={handleAction}
      handleClose={handleClose}
      actionText="create"
      disableClose={isBusy}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('valueDate')}
              </label>
              <CustomDatePicker
                name={'reversalValueDate'}
                onDateChange={date => {
                  setValueDate(date)
                }}
                setDateToNull={updateDate}
                setUpdateDate={setUpdateDate}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['Remarks']?.message}
            >
              <label className="col-2 text-capitalize">
                {localize('remarks')}
              </label>
              <textarea
                className="form-control"
                value={reversal?.Remarks}
                onChange={e => {
                  clearValidation('Remarks')
                  setReversal({
                    ...reversal,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default AddNewReversalModal
