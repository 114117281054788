/** @format */
import SavingsProducts from '../../../../Components/Selectables/Plain/SavingsProduct'

export const CreditTypesOffsetableSavingsProduct = ({
  creditTypeSavingsProducts,
  loading,
  disabled,
  prefix,
  onCheckCreditTypesSavingsProduct,
}) => {
  return (
    <SavingsProducts
      prefix={prefix}
      savingsProductToBeSelected={creditTypeSavingsProducts}
      isBusy={loading}
      disabled={disabled}
      onCheckSavingsProduct={r => {
        onCheckCreditTypesSavingsProduct && onCheckCreditTypesSavingsProduct(r)
      }}
    />
  )
}
