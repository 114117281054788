/** @format */

/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect } from 'react'
import Webcam from 'react-webcam'

import './specimen.scss'
import localize from '../../../../../Global/Localize'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { toBase64 } from '../../../../../Helpers/blobs'
import Swal from 'sweetalert2'

function SpecimenMediaItem({
  base64,
  readOnly,
  itemKey,
  onChange,
  isBusy = true,
}) {
  const [webcam, setWebcam] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [photo, setPhoto] = useState(null)
  const webcamRef = useRef(null)
  const [hasError, setHasError] = useState(false)

  const capture = useCallback(() => {
    const imgBase64 = webcamRef.current.getScreenshot()
    setPhoto(imgBase64)
    setViewModal(false)
  }, [webcamRef])

  useEffect(() => {
    if (photo) {
      setWebcam(false)
      onChange(itemKey, photo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo])

  return (
    <>
      <div className="specimen-item">
        <img
          onClick={() => {}}
          src={base64}
          alt=""
          style={{
            width: '100%',
            height: 250,
            background: '#0002',
            border: 'none',
            borderRadius: 5,
            objectFit: 'contain',
          }}
        />

        {isBusy || (
          <div className="edit-specimen-item bg-white d-flex align-items-center justify-content-center">
            {hasError || (
              <div className="option" onClick={() => setViewModal(true)}>
                <i className="uil uil-eye"></i>
                <span className="text-capitalize">{localize('view')}</span>
              </div>
            )}
            {readOnly || (
              <>
                <div className="option" onClick={() => setWebcam(true)}>
                  <i className="uil uil-camera"></i>
                  <span className="text-capitalize">{localize('camera')}</span>
                </div>
                <label className="option" htmlFor={itemKey}>
                  <i className="uil uil-folder-upload"></i>
                  <span className="text-capitalize">{localize('browse')}</span>
                </label>
                <input
                  id={itemKey}
                  type="file"
                  style={{ display: 'none' }}
                  multiple={false}
                  accept="image/*"
                  onChange={async e => {
                    let file = e.target.files[0]
                    try {
                      let b64 = await toBase64(file)
                      setPhoto(b64)
                    } catch {
                      Swal.fire('Invalid File', 'Error loading file', 'error')
                      return
                    }
                  }}
                />
              </>
            )}
          </div>
        )}
        {isBusy && (
          <div className="image-loading">
            <div role="status" className="m-1 spinner-border text-white">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>

      <div
        className={`view-specimen ${viewModal ? 'shown' : ''}`}
        onClick={() => {
          setViewModal(false)
        }}
      >
        <div className="close-it" onClick={() => setViewModal(false)}>
          <i className="uil uil-times"></i>
        </div>
        <img
          onLoad={() => setHasError(false)}
          onError={() => setHasError(true)}
          onClick={e => e.stopPropagation()}
          src={base64}
          alt=""
          style={{
            // width: '80vw',
            // height: '80vh',
            // maxHeight: '90vh',
            // minHeight: '80vh',
            // maxWidth: '90vw',
            // minWidth: '80vw',
            // maxHeight: '80%',
            // maxWidth: '80%',

            // maxWidth: '90%',
            // width: '80%',
            borderRadius: 5,
            objectFit: 'contain',
            boxShadow: 'black 0 0 50px',
          }}
        />
      </div>

      {webcam && (
        <Modal isOpen className="modal-lg">
          <ModalHeader className="bg-white text-capitalize">
            {localize('capture')}
          </ModalHeader>
          <ModalBody className="bg-white d-flex align-items-center justify-content-center">
            <Webcam ref={webcamRef} />
          </ModalBody>
          <ModalFooter className="bg-white">
            <button
              onClick={() => setWebcam(false)}
              className="btn btn-danger px-4"
            >
              {localize('cancel')}
            </button>
            <button onClick={capture} className="btn btn-success px-4">
              {localize('capture')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default SpecimenMediaItem
