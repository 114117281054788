/** @format */

import { postData } from './webApi'

export default async function computeMathExpression(mathExpression) {
  const { data } = await postData(
    '/lookup/utility/evaluate-math-expression',
    { expressionString1: mathExpression },
    false
  )
  return Number(data)
}
