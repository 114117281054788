/** @format */

import React from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import FormulaInput from '../../../../../../Components/Formula/FormulaInput'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'

const Transaction = ({
  transaction,
  setTransaction,
  apportionments,
  setApportionments,
  isBusyLoadingApportionments,
  apportioned,
  shortage,
  handleRemove,
  selectedApportionments,
  handleSelectApportionment,
  setNewTellerBalance,
  previousTellerBalance,
  setTotalValue,
  setShortage,
  setApportioned,
  totalValue,
}) => {
  const handleEvaluateExpression = (value, r) => {
    if (!value || value === undefined) return

    const newApportionments = apportionments?.map(x => {
      if (x?.journalEntryReferenceId === r?.journalEntryReferenceId) {
        return {
          ...x,
          apportionedAmount: value,
        }
      }
      return x
    })

    setApportionments(newApportionments)
    setApportioned(
      newApportionments?.reduce(
        (a, b) => Number(a) + Number(b?.apportionedAmount),
        0
      )
    )
    setShortage(
      totalValue -
        newApportionments?.reduce(
          (a, b) => Number(a) + Number(b?.apportionedAmount),
          0
        )
    )
  }

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <>
          <Checkbox
            checked={selectedApportionments?.find(
              x => x?.productDescription === r?.productDescription
            )}
            onCheck={value => {
              handleSelectApportionment(r, value)
            }}
          />
        </>
      ),
    },
    {
      label: localize('productName'),
      template: r => <>{r?.productDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r?.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r?.secondaryDescription}</>,
    },
    {
      label: localize('formula'),
      template: r => (
        <FormulaInput
          useBlur
          onChange={value => handleEvaluateExpression(value, r)}
          style={{
            minWidth: 200,
          }}
        />
      ),
    },
    { label: localize('reference'), template: r => <>{r?.reference}</> },
    {
      label: localize('apportionedAmount'),
      template: r => <>{formatCurrency(r?.apportionedAmount)}</>,
    },
  ]

  const handleInputChange = e => {
    setTransaction({
      ...transaction,
      transactionAmount: e.target.rawValue,
    })
    setNewTellerBalance(
      Number(previousTellerBalance) - Number(e.target.rawValue)
    )
    setTotalValue(Number(e.target.rawValue))
    setShortage(Number(e.target.rawValue) - Number(apportioned))
  }

  return (
    <>
      <div className="row g-3">
        <div className="col-md-12 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('transactionAmount')}
          </label>
          <MaskNumber
            defaultMaskValue={transaction?.transactionAmount}
            onBlur={handleInputChange}
            onMaskNumber={e => {
              setTransaction({
                ...transaction,
                transactionAmount: e.target.rawValue,
              })
            }}
          />
        </div>
        <div className="col-md-12 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('paymentMode')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{
              width: '100%',
            }}
            value={transaction?.paymentMode}
            onChange={e => {
              setTransaction({
                ...transaction,
                paymentMode: e.value,
              })
            }}
            enumsKey="VehicleCollectionPaymentMode"
          />
        </div>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2  row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('apportionments')}
        </div>
        {isBusyLoadingApportionments ? (
          <Loader />
        ) : (
          <SimpleTable data={apportionments} columns={columns} />
        )}

        <div className="mt-4 bg-light rounded gap-3 p-2 d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center">
            <label className="col-5 text-capitalize">
              {localize('totalAmount')}
            </label>
            <input
              className="form-control text-end"
              value={formatCurrency(apportioned, null, null, false)}
              disabled
            />
          </div>
          <div className="d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('shortage')}
            </label>
            <input
              className="form-control text-end"
              value={formatCurrency(shortage, null, null, false)}
              disabled
            />
          </div>
          <button className="btn btn-danger" onClick={handleRemove}>
            {localize('remove')}
          </button>
        </div>
      </div>
    </>
  )
}

export default Transaction
