/** @format */

import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import TallyCounter from '../../../../../Components/TallyCounter/TallyCounter'
import Swal from 'sweetalert2'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { postData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { MONEY } from '../../../../../Helpers/constants'
import Loader from '../../../../../Components/Loaders/Loader'

const CashRequisition = () => {
  const [total, setTotal] = useState(0)
  const [tally, setTally] = useState(null)
  const [resetValues, setResetValues] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [requisition, setRequisition] = useState({
    amount: 0.0,
    primaryDescription: '',
    secondaryDescription: '',
    reference: '',
  })

  const getTallyByTotal = money => {
    return tally[money] ? tally[money]?.tallyByTotal : 0.0
  }

  const handleCreate = async () => {
    if (!requisition?.primaryDescription)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter primary description',
      })

    if (!requisition?.secondaryDescription)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter secondary description',
      })

    if (!requisition?.reference)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please enter reference',
      })

    if (total === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Amount must be greater than 0.00',
      })

    setIsBusy(true)

    const url = '/TellerCashRequisition/add-teller-cash-requision'

    const { success } = await postData(
      url,
      {
        ...requisition,
        totalValue: total,
        denominationOneThousandValue: getTallyByTotal(MONEY[0]?.name),
        denominationFiveHundredValue: getTallyByTotal(MONEY[1]?.name),
        denominationTwoHundredValue: getTallyByTotal(MONEY[2]?.name),
        denominationOneHundredValue: getTallyByTotal(MONEY[3]?.name),
        denominationFiftyValue: getTallyByTotal(MONEY[4]?.name),
        denominationFourtyValue: getTallyByTotal(MONEY[5]?.name),
        denominationTwentyValue: getTallyByTotal(MONEY[6]?.name),
        denominationTenValue: getTallyByTotal(MONEY[7]?.name),
        denominationFiveValue: getTallyByTotal(MONEY[8]?.name),
        denominationOneValue: getTallyByTotal(MONEY[9]?.name),
        denominationFiftyCentValue: getTallyByTotal(MONEY[10]?.name),
      },
      false
    )

    if (success) {
      showNotification('Create Cash Requisition', 'success')
      setResetValues(true)
      setRequisition({
        amount: 0.0,
        primaryDescription: '',
        secondaryDescription: '',
        reference: '',
      })
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('teller'),
          localize('cashRequisition'),
        ]}
      />
      <div className="card rounded bg-white">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <div className="col-md-4">
                  <TallyCounter
                    getTotal={total => setTotal(total)}
                    getTally={tally => setTally(tally)}
                    resetValues={resetValues}
                    setResetValues={setResetValues}
                  />
                </div>
                <div className="col-md-8">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <div className="d-flex align-items-center">
                        <label className="text-capitalize col-3">
                          {localize('amount')}
                        </label>
                        <input
                          type="text"
                          className="form-control text-end"
                          value={formatCurrency(total, null, null, false)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex align-items-center">
                        <label className="text-capitalize col-3">
                          {localize('primaryDescription')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={requisition?.primaryDescription}
                          onChange={e => {
                            setRequisition({
                              ...requisition,
                              primaryDescription: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex align-items-center">
                        <label className="text-capitalize col-3">
                          {localize('secondaryDescription')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={requisition?.secondaryDescription}
                          onChange={e => {
                            setRequisition({
                              ...requisition,
                              secondaryDescription: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex align-items-center">
                        <label className="text-capitalize col-3">
                          {localize('reference')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={requisition?.reference}
                          onChange={e => {
                            setRequisition({
                              ...requisition,
                              reference: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 bg-light rounded p-2">
                      <div className="d-flex align-items-center justify-content-end">
                        <button
                          className="btn btn-success"
                          onClick={handleCreate}
                        >
                          {localize('create')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CashRequisition
