/** @format */

/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import './contextmenu.scss'

function ContextMenuContainer({ children, position, hide }) {
  const ref = useRef(null)

  useEffect(() => {
    const centrinoContext = document.querySelector('#centContext')
    centrinoContext.addEventListener('contextmenu', alterContext)
    return () => {
      centrinoContext.removeEventListener('contextmenu', alterContext)
    }
  }, [])

  const [useFloatingMenu, setuseFloatingMenu] = useState(false)
  useEffect(() => {
    loadSetting()
  }, [])
  async function loadSetting() {
    let val = await localStorage.getItem('useFloatingMenu')
    setuseFloatingMenu(val === 'true')
  }

  function alterContext(e) {
    e.preventDefault()
  }

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      hide()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    useFloatingMenu || (
      <div
        ref={ref}
        onClick={hide}
        id="centContext"
        className="bg-white context-menu"
        style={{ left: position[0], top: position[1] }}
      >
        {children}
      </div>
    )
  )
}

export default ContextMenuContainer
