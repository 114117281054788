/** @format */

import flatpickr from 'flatpickr'
/* eslint-disable */
import 'flatpickr/dist/flatpickr.min.css'
import React, { useEffect, useRef } from 'react'
import { parseDate } from '../../Helpers/formatters'
import './date-picker.scss'

export default function CustomDatePicker({
  defaultDate,
  name,
  onDateChange,
  enableTime,
  minDate,
  maxDate,
  className,
  disabled,
  placeholder,
  setDateToNull,
  setUpdateDate,
}) {
  const ref = useRef()
  const datePicker = useRef(null)

  useEffect(() => {
    datePicker.current = flatpickr(`#${name}`, {
      onClose: (selectedDates, dateStr) => {
        if (!selectedDates?.length) {
          onDateChange(null)
        } else {
          onDateChange(parseDate(dateStr))
        }
      },
      defaultDate: defaultDate,
      enableTime: enableTime || false,
      minuteIncrement: 1,
      minDate,
      maxDate: maxDate,
    })
  }, [defaultDate, enableTime, minDate, maxDate, name, onDateChange])

  useEffect(() => {
    if (setDateToNull && datePicker?.current) {
      datePicker?.current?.clear()
      setUpdateDate(false)
    }
  }, [setDateToNull, setUpdateDate])

  return (
    <div className="custom-date-picker">
      <input
        className={`form-control ${className} ${
          disabled ? 'bg-light' : ''
        } flatpickr flatpickr-input active`}
        type="text"
        placeholder={placeholder}
        readOnly="readonly"
        id={name}
        disabled={disabled}
        ref={ref}
      />
      {!disabled && (
        <i
          className="uil uil-calendar-alt calendar fs-5 text-primary"
          onClick={() => datePicker?.current?.open()}
        ></i>
      )}
    </div>
  )
}
