/** @format */

import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const AccountMetadataReferees = ({ loading, data }) => {
  const columns = [
    { label: localize('name'), template: r => <>{r.witnessFullName}</> },
    {
      label: localize('gender'),
      template: r => <>{r.witnessIndividualGenderDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.witnessIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.witnessAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.witnessAddressAddressLine2}</>,
    },
    { label: localize('street'), template: r => <>{r.witnessAddressStreet}</> },
    {
      label: localize('postalCode'),
      template: r => <>{r.witnessAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.witnessAddressCity}</> },
    { label: localize('email'), template: r => <>{r.witnessAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.witnessAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.witnessAddressMobileLine}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable data={data} columns={columns} />
      )}
    </>
  )
}
