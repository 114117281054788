/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import { useState } from 'react'
import localize from '../../../../Global/Localize'
import { AddRefundTypesModal } from './AddRefundTypesModal'
import { EditRefundTypesModal } from './EditRefundTypesModal'
import { ViewRefundTypesModal } from './ViewRefundTypesModal'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'

function RefundTypesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  waitingMetadata,
  selectedRefundType,
  setSelectedRefundType,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addRefundType()
    }
    if (mode === 'edit') {
      return editRefundType()
    }
  }

  const addRefundType = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/RefundType/add-refund-type-with-commissions',
      {
        commissions:
          selectedRefundType?.commissions.filter(x => x.checked) || [],
        refundType: { ...selectedRefundType },
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Refund Type',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  const editRefundType = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/RefundType/update-refund-type-with-commissions',
      {
        commissions:
          selectedRefundType?.commissions.filter(x => x.checked) || [],
        refundType: { ...selectedRefundType },
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Refund Type',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit' || mode === 'view') && (
        <ModalTemplate
          modalMode={mode}
          modalTitle={localize('refundType')}
          handleAction={handleSubmit}
          handleClose={onHideModal}
          modalClassName={'modal-xl'}
          disableCreate={isBusy}
        >
          {mode === 'add' && (
            <AddRefundTypesModal
              isBusy={isBusy}
              waitingMetadata={waitingMetadata}
              selectedRefundType={selectedRefundType}
              setSelectedRefundType={setSelectedRefundType}
            />
          )}
          {mode === 'edit' && (
            <EditRefundTypesModal
              isBusy={isBusy}
              waitingMetadata={waitingMetadata}
              selectedRefundType={selectedRefundType}
              setSelectedRefundType={setSelectedRefundType}
            />
          )}
          {mode === 'view' && (
            <ViewRefundTypesModal
              selectedRefundType={selectedRefundType}
              waitingMetadata={waitingMetadata}
            />
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default RefundTypesModal
