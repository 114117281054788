/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'

const VehiclePersonnelLookup = ({ onSelect, displayValue }) => {
  const columns = [
    {
      label: localize('serial'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('customerBranch'),
      template: r => <>{r.customerBranchDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r.customerIdentificationNumber}</>,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => <>{r.customerMemberNumber}</>,
    },
    {
      label: localize('customerAccountNumber'),
      template: r => <>{r.customerAccountNumber}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAdress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    {
      label: localize('vehicleRegistrationNumber'),
      template: r => <>{r.vehicleRegistrationNumber}</>,
    },
    {
      label: localize('badgeImage'),
      template: r => <>{r.badgeNumber}</>,
    },
    {
      label: localize('badgeExpiryDate'),
      template: r => <>{formatDate(r.badgeExpiryDate, true)}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.isLocked.toString())}
        </span>
      ),
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate, true)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, true)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        title={localize('vehiclePersonnelLookup')}
        url={'/VehiclePersonnel/find-by-filter-in-page'}
        params={{
          text: '',
          pageIndex: 0,
          pageSize: 10,
        }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default VehiclePersonnelLookup
