/** @format */

import React, { useState } from 'react'
import { getData, postData } from '../../../../../Helpers/webApi'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import FuneralRiderClaimsLookup from '../../../../../Components/Lookup/FuneralRiderClaimsLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../Components/Loaders/Loader'
const AddFuneralRiderClaimPayable = ({
  mode,
  close,
  loadTableData,
  setMode,
}) => {
  const [loading, setLoading] = useState(false)
  const [commissionAmount, setCommissionAmount] = useState(null)
  const [
    selectedFuneralRiderClaimPayable,
    setSelectedFuneralRiderClaimPayable,
  ] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setLoading(true)
    const { success } = await postData(
      '/FuneralRiderClaim/add-new-funeral-rider-claim-payable',
      { ...selectedFuneralRiderClaimPayable, amount: commissionAmount },
      false
    )

    if (success) {
      showNotification(
        'Funeral Rider Claim Payables',
        'success',
        'Operation completed successfully!'
      )
      setLoading(false)
      setMode(null)
      setSelectedFuneralRiderClaimPayable(null)
      setGlobalValidationErrors({})
      loadTableData()
    }
    setLoading(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const getCommisionsForSystemTransactionType = async r => {
    setLoading(true)
    const { success, data } =
      r.claimType === '1'
        ? await getData(
            `/Commission/get-commissions-for-system-transaction-type?systemTransactionType=${61703}`,
            {},
            false
          )
        : await getData(
            `/Commission/get-commissions-for-system-transaction-type?systemTransactionType=${61704}`,
            {},
            false
          )
    if (success) {
      setCommissionAmount(data?.result[0].maximumCharge || 0)
    }
    setLoading(false)
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="funeralRiderClaimPayable"
        handleClose={close}
        handleAction={handleSubmit}
        modalClassName="modal-xl"
        actionText={mode === 'add' ? 'create' : ''}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div
                className="col d-flex align-items-center mb-2 me-4 with-validation"
                validation-message={
                  globalValidationErrors['FuneralRiderClaimId']?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('funeralRiderClaim')}
                </label>
                <FuneralRiderClaimsLookup
                  displayValue={
                    selectedFuneralRiderClaimPayable?.customerFullName
                  }
                  onSelect={r => {
                    clearValidation('FuneralRiderClaimId')
                    getCommisionsForSystemTransactionType(r)
                    setSelectedFuneralRiderClaimPayable({
                      ...selectedFuneralRiderClaimPayable,
                      funeralRiderClaimId: r.id,
                      customerFullName: r.customerFullName,
                      funeralRiderClaimCustomerReference1: r.customerReference1,
                      funeralRiderClaimFuneralRiderClaimantName:
                        r.funeralRiderClaimantName,
                      funeralRiderClaimCustomerAddressMobileLine:
                        r.customerAddressMobileLine,
                      funeralRiderClaimFuneralRiderClaimantMobileNumber:
                        r.funeralRiderClaimantMobileNumber,
                      funeralRiderClaimCustomerIndividualIdentityCardNumber:
                        r.funeralRiderClaimantIdentityCardNumber,
                      funeralRiderClaimFuneralRiderClaimantIdentityCardNumber:
                        r.funeralRiderClaimantIdentityCardNumber,
                      amount: commissionAmount,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-2 me-4 text-capitalize">
                  {localize('amount')}
                </label>
                <MaskNumber
                  className="form-control me-3 ms-4"
                  defaultMaskValue={commissionAmount || 0}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('memberAccountNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFuneralRiderClaimPayable?.funeralRiderClaimCustomerReference1 ||
                    ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('claimantName')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantName ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('memberMobileNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantMobileNumber ||
                    ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('claimantNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantMobileNumber ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('memberIdentityNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFuneralRiderClaimPayable?.funeralRiderClaimCustomerIndividualIdentityCardNumber ||
                    ''
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('claimantIdentityNumber')}
                </label>
                <input
                  type="text"
                  className="form-control me-3"
                  value={
                    selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantIdentityCardNumber ||
                    ''
                  }
                  disabled
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-1 text-capitalize me-5">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control ms-3 me-3"
                  value={selectedFuneralRiderClaimPayable?.remarks || ''}
                  onChange={e => {
                    setSelectedFuneralRiderClaimPayable({
                      ...selectedFuneralRiderClaimPayable,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}
export default AddFuneralRiderClaimPayable
