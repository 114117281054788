/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import ListBatchVerification from '../../../../../Components/ReUsables/BatchVerification/ListBatchVerification'
import localize from '../../../../../Global/Localize'
import {
  QueuePriority,
  withdrawalNotificationCategory,
} from '../../../../../Global/enumeration'
import ViewBatchModal from '../Partials/ViewBatchModal/ViewBatchModal'
import VerifyBatchVerificationModal from './VerifyBatchVerificationModal/VerifyBatchVerificationModal'

function BatchVerification() {
  const [isBusy, setIsBusy] = useState(false)
  const [batch, setBatch] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [batchOrigination, setBatchVerification] = useState({
    category: Object.keys(withdrawalNotificationCategory)[1],
    priority: Object.keys(QueuePriority)[3],
  })
  const [refresh, setRefresh] = useState(false)

  const handleAddButtonClick = () => {
    setModalMode('add')
    setBatch(null)
  }

  const handleClose = () => {
    setModalMode(null)
    setBatchVerification(null)
  }

  const handleBatchSelect = (batch, mode) => {
    setModalMode(mode)
    setBatchVerification(batch)
  }

  return (
    <>
      {modalMode === 'verify' && (
        <>
          <VerifyBatchVerificationModal
            mode={modalMode}
            handleClose={handleClose}
            batchOrigination={batchOrigination}
            reload={value => {
              setRefresh(value)
            }}
          />
        </>
      )}
      {modalMode === 'view' && (
        <ViewBatchModal
          mode={modalMode}
          batchOrigination={batchOrigination}
          handleClose={handleClose}
          title="batchVerification"
        />
      )}
      <BreadCrumb
        tree={[
          localize('settlement'),
          localize('verifyMembershipWithdrawalSettlementBatches'),
        ]}
      />

      <ListBatchVerification
        handleBatchSelect={handleBatchSelect}
        showAddButton
        showContextMenu
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default BatchVerification
