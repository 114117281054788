/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../../../Helpers/formatters'
import { putData } from '../../../../../Helpers/webApi'
import { ProductCode } from '../../../../../Global/enumeration'
import swal from 'sweetalert2'
import Loader from '../../../../../Components/Loaders/Loader'

const LoanInterestAdjustment = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
  const [loanInterestAdjustmentModel, setLoanInterestAdjustmentModel] =
    useState(null)

  const handleOnClickUpdate = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/CustomerAccount/adjust-customer-account-loan-interest',
      {
        CustomerAccountId: selectedCustomerAccount.id,
        AdjustmentType: loanInterestAdjustmentModel?.adjustmentType,
        Amount: loanInterestAdjustmentModel?.amount,
        Reference: loanInterestAdjustmentModel?.reference,
      },
      false,
      'Loan Interest Adjustment'
    )

    if (success) {
      setIsBusy(false)
      setLoanInterestAdjustmentModel(null)
      setSelectedCustomerAccount(null)
    } else {
      setIsBusy(false)
    }
  }

  const handleOnCustomerLookupAccount = customerAccount => {
    if (
      customerAccount.customerAccountTypeProductCode !==
      Object.keys(ProductCode)[1]
    ) {
      swal.fire(
        'Error',
        'Customer Account Type must be a Loan Account',
        'error'
      )
      return
    }
    setSelectedCustomerAccount(customerAccount)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('recurringProcedures'),
          localize('loanInterestAdjustment'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <CustomerAccountPartial
                    customer={selectedCustomerAccount}
                    onCustomerLookup={handleOnCustomerLookupAccount}
                  />
                  <div className="row mb-3">
                    <div className="col-3 d-flex align-items-center">
                      <label className="col-4 text-capitalize me-2">
                        {localize('adjustmentType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey={'LoanInterestAdjustmentType'}
                        onChange={e => {
                          setLoanInterestAdjustmentModel({
                            ...loanInterestAdjustmentModel,
                            adjustmentType: e.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <label className="col-4 me-2 text-capitalize">
                        {localize('amount')}
                      </label>
                      <MaskNumber
                        defaultMaskValue={loanInterestAdjustmentModel?.amount}
                        onMaskNumber={e => {
                          setLoanInterestAdjustmentModel({
                            ...loanInterestAdjustmentModel,
                            amount: stripNonNumericCharacters(e.target.value),
                          })
                        }}
                      />
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <label className="col-4 me-2 text-capitalize">
                        {localize('reference')}
                      </label>
                      <input
                        className="form-control"
                        onChange={e => {
                          setLoanInterestAdjustmentModel({
                            ...loanInterestAdjustmentModel,
                            reference: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-content-end justify-content-end">
                    <div className="d-flex align-items-center me-5">
                      <div className="d-flex align-content-end justify-content-end">
                        <button
                          onClick={handleOnClickUpdate}
                          className="btn btn-success text-uppercase ms-3 px-4"
                        >
                          {localize('update')}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoanInterestAdjustment
