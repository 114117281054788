/** @format */

/* eslint-disable */
import React from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function ApplicableCharges({
  disabled,
  commissions,
  prefix,
  selectedCommissions,
  handleSelectCommissions,
}) {
  return (
    <div className="px-4">
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {commissions?.map(commission => (
          <li
            key={commission.id}
            className=" d-flex align-items-center list-unstyled"
          >
            <Checkbox
              id={`${prefix ? prefix : 'com'}-${commission.id}`}
              checked={selectedCommissions?.find(
                comm => comm.id === commission.id
              )}
              disabled={disabled}
              onCheck={c => {
                handleSelectCommissions(commission, c)
              }}
            />
            <label
              htmlFor={`${prefix ? prefix : 'com'}-${commission.id}`}
              className={`fw-normal ms-2 cursor-pointer ${
                selectedCommissions?.find(comm => comm.id === commission.id)
                  ? 'text-primary fw-bold'
                  : ''
              }`}
            >
              {commission?.description}
            </label>
          </li>
        ))}
      </div>
    </div>
  )
}

export default ApplicableCharges
