/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../Global/Localize'
import ExportMismatchedEntries from '../../../../MismatchedEntries/MismatchedEntries'
import SimpleTable from '../../../../Tables/SimpleTable'

const MismatchedImportEntries = ({ mismatchedEntries }) => {
  const columns = [
    {
      label: localize('column1'),
      template: r => r.column1,
    },
    {
      label: localize('column2'),
      template: r => r.column2,
    },
    {
      label: localize('column3'),
      template: r => r.column3,
    },
    {
      label: localize('column4'),
      template: r => r.column4,
    },
    {
      label: localize('column5'),
      template: r => r.column5,
    },
    {
      label: localize('column6'),
      template: r => r.column6,
    },
    {
      label: localize('column7'),
      template: r => r.column7,
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
  ]

  return (
    <>
      <SimpleTable columns={columns} data={mismatchedEntries || []} />

      <div className="row">
        <div className="col">
          <div className="d-flex align-items-center float-end">
            <ExportMismatchedEntries data={mismatchedEntries} />
          </div>
        </div>
      </div>
    </>
  )
}

export default MismatchedImportEntries
