/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import Tab from '../../../../Components/Tabs/Tab'
import BankToBankRequestEntries from './BankToBankRequestEntries'
import Swal from 'sweetalert2'
import { putData } from '../../../../Helpers/webApi'
function ViewBankToBankDataModal({
  mode,
  selectedBankToBankRequest,
  close,
  entryStatus,
}) {
  const [apportionedAmount, setApportionedAmount] = useState(null)
  const [shortageAmount, setShortageAmount] = useState(null)
  function closeModal() {
    close()
  }
  function handleReset() {
    const { success } = putData(
      '/BankToBankRequest/unpost-bank-to-bank-request',
      { bankToBankRequestId: selectedBankToBankRequest.id },
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      closeModal(null)
    }
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('bankToBankRequests')}
        handleClose={closeModal}
        modalClassName="modal-xl"
        handleAction={() => {
          if (selectedBankToBankRequest.status == 0) {
            Swal.fire(
              'Ooops!',
              'Sorry, but a unmatched entry can not be reset',
              'info'
            )
          } else {
            handleReset()
          }
        }}
        actionText={mode === 'view' ? 'reset' : ''}
        footerChildren={
          <>
            {mode === 'view' ? (
              <>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('apportioned')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={apportionedAmount || ''}
                  />
                </div>

                <div className="col-3 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('shortage')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={shortageAmount || ''}
                  />
                </div>
                <button
                  className="btn btn-primary text-capitalize"
                  onClick={() => {
                    alert('printing')
                  }}
                >
                  {localize('print')}
                </button>
              </>
            ) : (
              ''
            )}
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionReferenceCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.transactionReferenceCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.transactionDate || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('totalAmount')}
            </label>
            <input
              type="number"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.paymentAmount || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('currency')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.currency || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('documentReferenceNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.documentReferenceNumber || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('bankCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.bankCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('branchCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.branchCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('paymentDate')}
            </label>
            <input
              type="date"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.paymentDate || ''}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('paymentReferenceCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.paymentReferenceCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('paymentCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.paymentCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('paymentMode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.paymentMode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('paymentAmount')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatCurrency(selectedBankToBankRequest?.paymentAmount) || ''
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('additionalInfo')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.additionalInfo || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.accountNumber || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.accountName || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('institutionCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.institutionCode || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('institutionName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.institutionName || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('status')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.statusDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('recordStatus')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.recordStatusDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('exceptionMessage')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.exceptionMessage || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('modifiedBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.modifiedBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('modifiedDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={formatDate(selectedBankToBankRequest?.modifiedDate) || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.createdBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={formatDate(selectedBankToBankRequest?.createdDate) || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('serialNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.paddedSerialNumber || ''}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('chartOfAccountName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedBankToBankRequest?.chartOfAccountName || ''}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('costDistributionRule')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedBankToBankRequest?.chartOfAccountCostDistributionRuleDescription ||
                ''
              }
            />
          </div>
        </div>
        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <BankToBankRequestEntries
                    showLoader={false}
                    selectedBankToBankRequest={selectedBankToBankRequest}
                    mode={mode}
                    entryStatus={entryStatus}
                    setApportioned={setApportionedAmount}
                    setShortage={setShortageAmount}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewBankToBankDataModal
