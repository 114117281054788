/** @format */

import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import {
  formatAsPercent,
  formatCurrency,
} from '../../../../../Helpers/formatters'

export const ChargeTierEntries = ({
  selectedCharge,
  setSelectedCharge,
  mode,
}) => {
  const columns = [
    {
      label: localize('rangeLowerLimit'),
      template: r => <>{r.rangeLowerLimit}</>,
    },
    {
      label: localize('rangeUpperLimit'),
      template: r => <>{formatCurrency(r.rangeUpperLimit)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('chargeType'),
      template: r => <>{r?.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r.chargePercentage)}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{formatCurrency(r.chargeFixedAmount)}</>,
      headerTextAlign: 'right',
    },
  ]

  return (
    <>
      <SimpleTable
        columns={columns}
        data={selectedCharge?.graduatedScales}
        contextMenu={e => (
          <>
            {mode !== 'view' && (
              <div
                onClick={() => {
                  setSelectedCharge({
                    ...selectedCharge,
                    graduatedScales: selectedCharge?.graduatedScales.filter(
                      x => x.id !== e.id,
                    ),
                  })
                }}
                className="ctxt-menu-item"
              >
                <i className="uil uil-trash"></i>
                {localize('remove')}
              </div>
            )}
          </>
        )}
      />
    </>
  )
}
