/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import SimpleTable from '../../../Components/Tables/SimpleTable'

function EducationAttendees({
  mode,
  selectedAttendees,
  setSelectedAttendees,
  onSelectAll,
  handleSelect,
  selectedCustomers,
  handleRemove,
}) {
  const [toggleSelection, setToggleSelection] = useState(false)

  const columns = [
    // {
    //   label: '',
    //   template: r =>
    //     mode === 'edit' ? (
    //       <Checkbox
    //         checked={selectedCustomers.find(obj => obj.id === r.id)}
    //         onCheck={v => {
    //           handleSelect(r, v)
    //         }}
    //       />
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      label: localize('customerType'),
      template: r => <>{r.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    { label: localize('name'), template: r => <>{r.customerFullName}</> },
    {
      label: localize('ref_1Account'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2Membership'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3PersonalFile'),
      template: r => <>{r.customerReference3}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('dutyWorkStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.customerAddressCity}</> },
    { label: localize('email'), template: r => <>{r.customerAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
  ]

  useEffect(() => {
    onSelectAll(selectedAttendees, toggleSelection)
    //eslint-disable-next-line
  }, [toggleSelection, selectedAttendees])

  return (
    <>
      <SimpleTable
        selectables
        contextMenu={r => (
          <>
            <div
              onClick={() => {
                handleRemove(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash text-danger"></i>
              {localize('remove')}
            </div>
          </>
        )}
        columns={columns}
        data={selectedAttendees}
      />
      {mode === 'edit' && (
        <>
          {/* <div className="d-flex align-items-end me-5 mt-2">
            <Checkbox
              id="toggle-selection"
              checked={toggleSelection}
              onCheck={v => {
                setToggleSelection(v)
              }}
            />
            <label htmlFor="toggle-selection" className="text-capitalize ms-2">
              {localize('toggleSelection')}
            </label>
          </div>  */}
        </>
      )}
    </>
  )
}
export default EducationAttendees
