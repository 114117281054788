/** @format */

import { postData } from '../../../../Helpers/webApi'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { CreateTemporaryRole } from './CreateTemporaryRole'
import { EditTemporaryRole } from './EditTemporaryRole'
import { VerifyTemporaryRole } from './VerifyTemporaryRole'
import { VerifyTemporaryRoleAction } from './VerifyTemporaryRoleAction'
import { useEffect, useState } from 'react'
import { RecordAuthOption } from '../../../../Global/enumeration'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import moment from 'moment'
import swal from 'sweetalert2'

function TemporaryRoleModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  setRoles,
  roles,
  waitingMetadata,
  selectedTemporaryRole,
  setSelectedTemporaryRole,
  suppressedPermissionsTemporaryRoles,
  temporaryRoleEntity,
  setSuppressedPermissionsTemporaryRoles,
}) {
  const [isBusy, setIsBusy] = useState(false)

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [mode])

  const [verifyTemporaryRoleActionModel, setVerifyTemporaryRoleActionModel] =
    useState({
      action: Object.keys(RecordAuthOption)[0],
      remarks: '',
    })

  async function handleSubmit() {
    if (mode === 'add') {
      addEmployeeTemporaryRole()
    }
    if (mode === 'edit') {
      editEmployeeTemporaryRole()
    }
    if (mode === 'verify') {
      verifyEmployeeTemporaryRole()
    }
  }

  const addEmployeeTemporaryRole = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/lookup/Role/add-employee-temporary-role',
      {
        EmployeeId: selectedTemporaryRole?.employeeId || '',
        RoleName:
            roles
            .filter(x => x.checked)
            .map(role => role.description)
            .toString() || '',
        Reason: selectedTemporaryRole?.reason || '',
        DurationStartDate:
          selectedTemporaryRole?.durationStartDate ||
          moment().format('YYYY-MM-DD'),
        DurationEndDate:
          selectedTemporaryRole?.durationEndDate ||
          moment().format('YYYY-MM-DD'),
        TimeDurationStartTime:
          selectedTemporaryRole?.timeDurationStartTime ||
          moment().format('HH:mm:ss'),
        TimeDurationEndTime:
          selectedTemporaryRole?.timeDurationEndTime ||
          moment().format('HH:mm:ss'),
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Employee Temporary Role',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editEmployeeTemporaryRole = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/lookup/Role/update-employee-suppressed-permissions',
      {
        EmployeeTemporaryRole: {
          Id: selectedTemporaryRole?.id || '',
          EmployeeId: selectedTemporaryRole?.employeeId || '',
          RoleName: selectedTemporaryRole?.roleName || '',
          Reason: selectedTemporaryRole?.reason || '',
          DurationStartDate: selectedTemporaryRole?.durationStartDate || '',
          DurationEndDate: selectedTemporaryRole?.durationEndDate || '',
          TimeDurationStartTime:
            selectedTemporaryRole?.timeDurationStartTime || '',
          TimeDurationEndTime: selectedTemporaryRole?.timeDurationEndTime || '',
        },
        employeeTemporaryRoleId: selectedTemporaryRole?.id || '',
        employeeSuppressedPermissionCollection:
          suppressedPermissionsTemporaryRoles || [],
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Employee Temporary Role',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const verifyEmployeeTemporaryRole = async () => {
    setIsBusy(true)
    const { success } = await postData(
      `/lookup/Role/update-employee-temporary-role/?recordAuthOption=${verifyTemporaryRoleActionModel?.action}`,
      {
        ...temporaryRoleEntity,
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Verify Employee Temporary Role',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('temporaryRoles')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            disableCreate={isBusy}
            footerChildren={
              mode === 'verify' && (
                <VerifyTemporaryRoleAction
                  isBusy={isBusy}
                  setVerifyTemporaryRoleActionModel={
                    setVerifyTemporaryRoleActionModel
                  }
                  validationBag={validationBag}
                  clearValidation={clearValidation}
                  verifyTemporaryRoleActionModel={
                    verifyTemporaryRoleActionModel
                  }
                />
              )
            }
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateTemporaryRole
                isBusy={isBusy}
                roles={roles}
                setRoles={setRoles}
                waitingMetadata={waitingMetadata}
                clearGlobalValidation={clearValidation}
                globalValidation={validationBag}
                selectedTemporaryRole={selectedTemporaryRole}
                setSelectedTemporaryRole={setSelectedTemporaryRole}
              />
            )}
            {mode === 'edit' && (
              <EditTemporaryRole
                isBusy={isBusy}
                globalValidation={validationBag}
                clearGlobalValidation={clearValidation}
                onAddSuppressedPermissionDetails={c => {
                  setSuppressedPermissionsTemporaryRoles(c)
                }}
                suppressedPermissionsTemporaryRoles={
                  suppressedPermissionsTemporaryRoles
                }
                setSuppressedPermissionsTemporaryRoles={
                  setSuppressedPermissionsTemporaryRoles
                }
                setSelectedTemporaryRole={setSelectedTemporaryRole}
                selectedTemporaryRole={selectedTemporaryRole}
              />
            )}
            {mode === 'verify' && (
              <VerifyTemporaryRole
                isBusy={isBusy}
                suppressedPermissionsTemporaryRoles={
                  suppressedPermissionsTemporaryRoles
                }
                temporaryRoleEntity={temporaryRoleEntity}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default TemporaryRoleModal
