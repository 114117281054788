/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function PostingPeriodLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('postingPeriodLookup')}
      url="/lookup/PostingPeriod/find-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      useText
      columns={[
        { label: localize('name'), template: r => <>{r.description}</> },
        {
          label: localize('duration'),
          template: r => (
            <>
              {formatDate(r.durationStartDate, true)}
              {` ${localize('to')} `}
              {formatDate(r.durationEndDate, true)}
            </>
          ),
        },
        {
          label: localize('isDividendComputed') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isDividendComputed.toString())}
            </span>
          ),
          sortBy: 'isDividendComputed',
        },
        {
          label: localize('dividendComputedBy'),
          template: r => <>{r.dividendComputedBy}</>,
        },
        {
          label: localize('dividendComputedDate'),
          template: r => <>{formatDate(r.dividendComputedDate, true)}</>,
        },
        {
          label: localize('isClosed') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isClosed.toString())}
            </span>
          ),
          sortBy: 'isClosed',
        },
        { label: localize('closedBy'), template: r => <>{r.closedBy}</> },
        {
          label: localize('closedDate'),
          template: r => <>{formatDate(r.closedDate, true)}</>,
        },
        {
          label: localize('isActive') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isActive.toString())}
            </span>
          ),
          sortBy: 'isActive',
        },
        {
          label: localize('isLocked') + '?',
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked.toString())}
            </span>
          ),
          sortBy: 'isLocked',
        },
        { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default PostingPeriodLookup
