/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { getData, putData } from '../../../../Helpers/webApi'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'
import Swal from 'sweetalert2'

function WellKnownChargesSection({ product, readonly }) {
  const [commissions, setCommissions] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [selectedCommissions, setSelectedCommissions] = useState([])
  const [savingsProductKnownChargeType, setSavingsProductKnownChargeType] =
    useState(0)
  const [chargeBorneBy, setChargeBorneBy] = useState(0)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    loadCommissions()

    // eslint-disable-next-line
  }, [refresh])

  useEffect(() => {
    loadSelectedCommissions()
    // eslint-disable-next-line
  }, [savingsProductKnownChargeType, product?.id, refresh])

  async function loadCommissions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/commission/find-commissions',
      null,
      false
    )
    if (success) {
      setCommissions(
        data.sort((a, b) => a.description.localeCompare(b.description))
      )
    }
    setIsBusy(false)
  }

  async function loadSelectedCommissions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/savingsProduct/find-commissions-by-savings-product-id',
      {
        savingsProductId: product?.id,
        savingsProductKnownChargeType,
      },
      false
    )
    if (success) {
      setSelectedCommissions(data?.result || [])
    }
    setIsBusy(false)
  }

  function handleCheckCommission(c) {
    let scs = [...selectedCommissions]
    const foundIndex = scs.findIndex(sc => sc.id === c.id)
    if (foundIndex > -1) {
      scs.splice(foundIndex, 1)
    } else {
      scs.push(c)
    }
    setSelectedCommissions(scs)
  }

  async function handleUpdateCommissions() {
    setIsBusy(true)
    const { success } = await putData(
      `/savingsProduct/update-commissions-by-savings-product-id?savingsProductId=${product?.id}
      &savingsProductKnownChargeType=${savingsProductKnownChargeType}&savingsProductChargeBenefactor=${chargeBorneBy}`,
      selectedCommissions,
      false,
      null
    )
    if (success) {
      Swal.fire('Operation Successful', 'Charges Updated', 'success')
      setRefresh(!false)
    }
    setIsBusy(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="row">
        <div className="col">
          <div className="d-flex align-items-center">
            <label
              htmlFor=""
              className="text-capitalize me-2"
              style={{ minWidth: 'fit-content' }}
            >
              {localize('chargeType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="SavingsProductKnownChargeType"
              value={savingsProductKnownChargeType}
              onChange={({ value }) => setSavingsProductKnownChargeType(value)}
            />
          </div>
        </div>
        {readonly || (
          <div className="col">
            <div className="d-flex align-items-center">
              <label
                htmlFor=""
                className="text-capitalize me-2"
                style={{ minWidth: 'fit-content' }}
              >
                {localize('chargeBorneBy')}
              </label>
              <EnumsServerCombo
                value={chargeBorneBy}
                onChange={({ value }) => setChargeBorneBy(value)}
                customContainerStyle={{ width: '100%' }}
                enumsKey="ChargeBenefactor"
              />
            </div>
          </div>
        )}
      </div>
      <div className="edit-sp-sel-container border border-dark border-1 p-2 ">
        {commissions &&
          commissions.map(c => {
            const isFound = selectedCommissions.find(sc => sc.id === c.id)
            return (
              <div className="edit-sp-selectable">
                <Checkbox
                  // disabled={readonly}
                  id={`editSP${c.id}`}
                  onCheck={readonly ? () => {} : () => handleCheckCommission(c)}
                  checked={!!isFound}
                />
                <label
                  htmlFor={`editSP${c.id}`}
                  className={`selectab ${
                    !!isFound ? 'text-primary fw-bold' : ''
                  }`}
                >
                  {c.description}{' '}
                  {!!isFound && `(${isFound.chargeBenefactorDescription})`}
                </label>
              </div>
            )
          })}
      </div>
      <hr />
      <div className="d-flex align-items-center justify-content-end">
        <button
          className="btn btn-success px-5"
          onClick={handleUpdateCommissions}
        >
          {localize('update')}
        </button>
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default WellKnownChargesSection
