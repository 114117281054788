/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import CustomersLookup from '../../../../Components/Lookup/CustomersLookup'
import DesignationLookup from '../../../../Components/Lookup/DesignationLookup'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'
import DepartmentLookup from '../../../../Components/Lookup/DepartmentLookup'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import CostCentreLookup from '../../../../Components/Lookup/CostCentreLookup'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import Tab from '../../../../Components/Tabs/Tab'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import DependantsPartial from './DependantsPartial'
import EmployeeTypeLookup from '../../../../Components/Lookup/EmployeeTypeLookup'
import Loader from '../../../../Components/Loaders/Loader'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'

function CreateEmployee({
  refreshEmployees,
  showCloseButton = true,
  handleClose,
  editMode = false,
  selectedEmployee,
}) {
  const [employee, setEmployee] = useState(editMode ? selectedEmployee : null)
  const [isBusy, setIsBusy] = useState(false)

  const tabItems = [
    {
      label: localize('emergencyContacts'),
      item: (
        <>
          <DependantsPartial />
        </>
      ),
      icon: 'uil uil-edit',
    },
    {
      label: localize('Dependants'),
      item: (
        <>
          <DependantsPartial dependant />
        </>
      ),
      icon: 'uil uil-edit',
    },
  ]

  useEffect(() => {
    editMode && fetchEmployee()
    // eslint-disable-next-line
  }, [])

  async function fetchEmployee() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/employeeRegister/find-by-id',
      { employeeId: selectedEmployee?.id },
      false
    )
    if (success) {
      setEmployee(data)
    }
    setIsBusy(false)
  }

  async function createEmployee() {
    setIsBusy(true)
    const { success } = await postData(
      '/employeeRegister/create-employee',
      employee,
      false
    )
    if (success) {
      Swal.fire('Operation Successful', `Employee Created!`, 'success')
      handleClose()
      refreshEmployees()
    }
    setIsBusy(false)
  }

  async function updateEmployee() {
    setIsBusy(true)
    const { success } = await putData(
      '/employeeRegister/update-employee',
      { ...employee, another: 'uuui' },
      false
    )
    if (success) {
      Swal.fire('Operation Successful', `Employee Updated!`, 'success')
      handleClose()
      refreshEmployees()
    }
    setIsBusy(false)
  }

  return isBusy ? (
    <Loader />
  ) : (
    <div>
      <div className="p-2">
        <div className="row mb-3">
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('customer')}
            </label>
            <CustomersLookup
              displayValue={employee?.customerFullName}
              onSelect={c =>
                setEmployee({
                  ...employee,
                  customerId: c?.id,
                  customerFullName: c.fullName,
                })
              }
              disabled={editMode}
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('designation')}
            </label>
            <DesignationLookup
              displayValue={employee?.designationDescription}
              onSelect={c =>
                setEmployee({
                  ...employee,
                  designationId: c?.id,
                  designationDescription: c.description,
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('branch')}
            </label>
            <BranchLookup
              displayValue={employee?.branchDescription}
              onSelect={c =>
                setEmployee({
                  ...employee,
                  branchId: c?.id,
                  branchDescription: c.description,
                })
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('department')}
            </label>
            <DepartmentLookup
              displayValue={employee?.departmentDescription}
              onSelect={c =>
                setEmployee({
                  ...employee,
                  departmentId: c?.id,
                  departmentDescription: c.description,
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('employeeType')}
            </label>
            <EmployeeTypeLookup
              displayValue={employee?.employeeTypeDescription}
              onSelect={c =>
                setEmployee({
                  ...employee,
                  employeeTypeId: c?.id,
                  employeeTypeDescription: c.description,
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('bloodGroup')}
            </label>
            <div style={{ width: '100%' }}>
              <EnumsServerCombo
                enumsKey="BloodGroup"
                value={employee?.bloodGroup}
                onChange={e =>
                  setEmployee({ ...employee, bloodGroup: e.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('PIN')}
            </label>
            <input
              type="text"
              className="form-control"
              value={employee?.customerPersonalIdentificationNumber}
              onChange={e =>
                setEmployee({
                  ...employee,
                  customerPersonalIdentificationNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('NSSFNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              value={employee?.nationalSocialSecurityFundNumber}
              onChange={e =>
                setEmployee({
                  ...employee,
                  nationalSocialSecurityFundNumber: e.target.value,
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('NHIFNumber')}
            </label>
            <input
              type="text"
              className="form-control"
              value={employee?.nationalHospitalInsuranceFundNumber}
              onChange={e =>
                setEmployee({
                  ...employee,
                  nationalHospitalInsuranceFundNumber: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('costCenter')}
            </label>
            <CostCentreLookup
              displayValue={employee?.costCenterDescription}
              onSelect={c =>
                setEmployee({
                  ...employee,
                  costCenterId: c?.id,
                  costCenterDescription: c.description,
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('hireDate')}
            </label>
            <CustomDatePicker
              name={'createemployeehiredate'}
              defaultDate={employee?.hireDate}
              onDateChange={date => {
                setEmployee({
                  ...employee,
                  hireDate: date,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('releaseDate')}
            </label>
            <CustomDatePicker
              name={'createemployeereleaseDate'}
              defaultDate={employee?.releaseDate}
              onDateChange={date => {
                setEmployee({
                  ...employee,
                  releaseDate: date,
                })
              }}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('printingMode')}
            </label>
            <div style={{ width: '100%' }}>
              <EnumsServerCombo
                enumsKey="PrintingMode"
                value={employee?.printingMode}
                onChange={e =>
                  setEmployee({ ...employee, printingMode: e.value })
                }
              />
            </div>
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('inactivityTimeout')}
            </label>
            <div style={{ width: '100%' }}>
              <input
                type="number"
                className="form-control"
                value={employee?.inactivityTimeout}
                onChange={e =>
                  setEmployee({
                    ...employee,
                    inactivityTimeout: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              value={employee?.remarks}
              onChange={e =>
                setEmployee({ ...employee, remarks: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('systemInitializationTime')}
            </label>
            <div style={{ width: '100%' }}>
              <input
                type="time"
                className="form-control"
                value={employee?.timeDurationStartTime}
                onChange={e =>
                  setEmployee({
                    ...employee,
                    timeDurationStartTime: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col d-flex align-items-center gap-2">
            <label htmlFor="" className="text-capitalize col-4">
              {localize('systemLockTime')}
            </label>
            <div style={{ width: '100%' }}>
              <input
                type="time"
                className="form-control"
                value={employee?.timeDurationEndTime}
                onChange={e =>
                  setEmployee({
                    ...employee,
                    timeDurationEndTime: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col d-flex align-items-center gap-2"></div>
        </div>
      </div>
      <div className="my-2">
        <Tab tabItems={tabItems} preload />
      </div>
      <div className="rounded px-2 py-3 d-flex align-items-center justify-content-end bg-light gap-2 border border-secondary">
        <div className="col-8">
          <div className="d-flex align-items-center row">
            <div className="col-2"></div>
            <div className="d-flex align-items-center gap-1 col-4">
              <Checkbox
                id="createEmployeeEnforceSystemInitializationCHK"
                checked={employee?.enforceSystemInitializationLockTime}
                onCheck={v =>
                  setEmployee({
                    ...employee,
                    enforceSystemInitializationLockTime: v,
                  })
                }
              />
              <label
                htmlFor="createEmployeeEnforceSystemInitializationCHK"
                className="text-capitalize"
              >
                {localize('enforceSystemInitialization/LockTime')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-1 col-3">
              <Checkbox
                id="createEmployeeenableTextBased2FACHK"
                checked={employee?.enforceTextBased2FA}
                onCheck={v =>
                  setEmployee({ ...employee, enforceTextBased2FA: v })
                }
              />
              <label
                htmlFor="createEmployeeenableTextBased2FACHK"
                className="text-capitalize"
              >
                {localize('enableTextBased2FA')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-1 col-3">
              <Checkbox
                id="createEmployeeEnforceBiometricsForLoginCHK"
                checked={employee?.enforceBiometricsForLogin}
                onCheck={v =>
                  setEmployee({ ...employee, enforceBiometricsForLogin: v })
                }
              />
              <label
                htmlFor="createEmployeeEnforceBiometricsForLoginCHK"
                className="text-capitalize"
              >
                {localize('enforceBiometricsForLogin')}?
              </label>
            </div>
          </div>
          <div className="d-flex align-items-center row">
            <div className="col-2"></div>
            <div className="d-flex align-items-center gap-1 col-4">
              <Checkbox
                id="createEmployeeOnlineNotificationsEnabledCHK"
                checked={employee?.onlineNotificationsEnabled}
                onCheck={v =>
                  setEmployee({ ...employee, onlineNotificationsEnabled: v })
                }
              />
              <label
                htmlFor="createEmployeeOnlineNotificationsEnabledCHK"
                className="text-capitalize"
              >
                {localize('onlineNotificationsEnabled')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-1 col-3">
              <Checkbox
                id="createEmployeeUseDefaultPrinterCHK"
                checked={employee?.useDefaultPrinter}
                onCheck={v =>
                  setEmployee({ ...employee, useDefaultPrinter: v })
                }
              />
              <label
                htmlFor="createEmployeeUseDefaultPrinterCHK"
                className="text-capitalize"
              >
                {localize('useDefaultPrinter')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-1 col-3">
              <Checkbox
                id="createEmployeeElectronicPayslipEnabledCHK"
                checked={employee?.electronicPayslipEnabled}
                onCheck={v =>
                  setEmployee({ ...employee, electronicPayslipEnabled: v })
                }
              />
              <label
                htmlFor="createEmployeeElectronicPayslipEnabledCHK"
                className="text-capitalize"
              >
                {localize('electronicPayslipEnabled')}?
              </label>
            </div>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-end gap-3">
            {showCloseButton && (
              <button className="btn btn-danger px-5" onClick={handleClose}>
                {localize('cancel')}
              </button>
            )}
            <button
              className="btn btn-success px-5"
              onClick={editMode ? updateEmployee : createEmployee}
            >
              {localize(editMode ? 'update' : 'create')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateEmployee
