/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import { getData } from '../../../../../../Helpers/webApi'

const Levies = ({
  selectedEntries,
  handleSelectEntry,
  resetValues,
  setResetValues,
}) => {
  const [levies, setLevies] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const fetchLevies = async () => {
    setIsBusy(true)

    const url = '/Levy/find-levies'

    const { success, data } = await getData(url, {}, false)

    if (success) {
      setLevies(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchLevies()
  }, [])

  useEffect(() => {
    if (resetValues) {
      setLevies([])
      setResetValues(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return isBusy ? (
    <Loader />
  ) : (
    levies?.map(levy => (
      <div
        className="d-flex align-items-center justify-content-start gap-3"
        key={`levies-item-register-${levy?.id}`}
      >
        <Checkbox
          checked={selectedEntries?.find(x => x?.id === levy?.id)}
          onCheck={value => handleSelectEntry(levy, value)}
          id={`levies-item-register-${levy?.id}-checkbox`}
        />

        <label
          className="text-capitalize"
          htmlFor={`levies-item-register-${levy?.id}-checkbox`}
        >
          {levy?.description}
        </label>
      </div>
    ))
  )
}

export default Levies
