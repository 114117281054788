/** @format */
import localize from '../../../../Global/Localize'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'

export const AddPettyCashierModal = ({
  selectedPettyCashier,
  setSelectedPettyCashier,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-1 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={selectedPettyCashier?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedPettyCashier({
                    ...selectedPettyCashier,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.ChartOfAccountId?.message}
            >
              <label className="col-2 text-capitalize">
                {localize('glAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={selectedPettyCashier?.chartOfAccountName}
                onSelect={r => {
                  clearValidation('ChartOfAccountId')
                  setSelectedPettyCashier({
                    ...selectedPettyCashier,
                    chartOfAccountId: r.id,
                    chartOfAccountName: r.name,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.BranchId?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <BranchLookup
                displayValue={selectedPettyCashier?.branchDescription}
                onSelect={r => {
                  clearValidation('BranchId')
                  setSelectedPettyCashier({
                    ...selectedPettyCashier,
                    branchId: r.id,
                    branchDescription: r.description,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
