/** @format */
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { ExemptedCustomers } from './ExemptedCustomers'
/* eslint-disable */
import React from 'react'

function ViewExemptedCustomers({
  mode,
  onHideModal,
  chargeExemption,
  exemptionEntries,
}) {
  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <div>
            <ModalHeader className="bg-white text-capitalize">
              {localize('view')}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('chargeExemptions')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-1 text-capitalize">
                    {localize('name')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    defaultValue={chargeExemption?.description}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-1 text-capitalize">
                    {localize('charge')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    defaultValue={chargeExemption?.commissionDescription}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <Tab
                  tabItems={[
                    {
                      label: localize('exemptedCustomers'),
                      item: (
                        <ExemptedCustomers
                          mode={'view'}
                          exemptedCustomers={exemptionEntries}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <button className="btn btn-danger px-4" onClick={onHideModal}>
                {localize('cancel')}
              </button>
            </ModalFooter>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ViewExemptedCustomers
