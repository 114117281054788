/** @format */

import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import {
  ProductCode,
  RecordStatus,
  customerAccountStatus,
} from '../../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'
import { useEffect } from 'react'

const CreateSuperSaverPayable = ({
  mode,
  handleClose,
  customerAccount,
  setCustomerAccount,
  handleCreateSuperSaverPayable,
  loading,
}) => {
  // /SuperSaverPayable/find-customer-super-saver-payable
  const [superSaverPayable, setSuperSaverPayable] = useState({})
  const [isBusy, setIsBusy] = useState(false)

  const fetchCustomerSuperSaverPayable = async () => {
    setIsBusy(true)

    const url = '/SuperSaverPayable/find-customer-super-saver-payable'

    const { success, data } = await getData(
      url,
      {
        customerId: customerAccount?.customerId,
        productId: customerAccount?.customerAccountTypeTargetProductId,
      },
      false
    )

    if (success) {
      setSuperSaverPayable(data)
      setCustomerAccount({
        ...customerAccount,
        withholdingTaxAmount: data?.wDTax,
        amount: data?.interest,
      })
      setIsBusy(false)
    } else setIsBusy(false)
  }

  const handleCustomerAccountLookup = r => {
    if (r?.status !== Object.keys(customerAccountStatus)[0])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only select an active customer account',
      })

    if (r?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only select customer accounts with record status approved',
      })

    if (r?.customerAccountTypeProductCode !== Object.keys(ProductCode)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only select an Investment account',
      })

    setCustomerAccount({
      ...r,
      remarks: '',
    })
  }

  useEffect(() => {
    if (customerAccount?.customerAccountTypeTargetProductId)
      fetchCustomerSuperSaverPayable()
    // eslint-disable-next-line
  }, [customerAccount?.customerAccountTypeTargetProductId])

  return (
    <ModalTemplate
      ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      actionText="create"
      handleAction={handleCreateSuperSaverPayable}
      modalTitle="createSuperSaverPayable"
      modalClassName="modal-xl"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            onCustomerLookup={handleCustomerAccountLookup}
            productCode="3"
            customer={customerAccount}
            hideMargin
          />

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-2">
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('bookBalance')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end ms-2"
                      disabled
                      value={formatCurrency(
                        customerAccount?.bookBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('interest')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end ms-2"
                      disabled
                      value={formatCurrency(
                        superSaverPayable?.interest,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('withholdingTaxAmount')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end ms-2"
                      disabled
                      value={formatCurrency(
                        superSaverPayable?.wdTax,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('remarks')}
                    </label>

                    <input
                      type="text"
                      className="form-control ms-2"
                      onChange={e => {
                        setCustomerAccount({
                          ...customerAccount,
                          createRemarks: e.target.value,
                        })
                      }}
                      value={customerAccount?.createRemarks}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateSuperSaverPayable
