/** @format */

/* eslint-disable */
import React from 'react'
import ListSavingsProducts from '../../SavingsProducts/ListSavingsProducts'

const SavingsProductTab = ({
  handleSelectSavings,
  handleUpdate,
  resetValues,
  setResetValues,
  actionText,
}) => {
  return (
    <div class>
      <ListSavingsProducts
        handleSelectSavings={handleSelectSavings}
        handleUpdate={handleUpdate}
        showUpdateButton
        limitTableHeight
        resetValues={resetValues}
        setResetValues={setResetValues}
        actionText={actionText}
      />
    </div>
  )
}

export default SavingsProductTab
