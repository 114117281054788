/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../Loaders/Loader'
import ModalTemplate from '../../../../ModalTemplate/ModalTemplate'
import Tab from '../../../../Tabs/Tab'
import LoanGuarantors from '../../LoanGuarantors/LoanGuarantors'

const AdjustGuarantorStatus = ({
  customerId,
  loanCaseId,
  handleClose,
  mode,
}) => {
  const [customer, setCustomer] = useState(null)
  const [selectedGuarantors, setSelectedGuarantors] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const fetchCustomer = async () => {
    setIsBusy(true)
    const url = '/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: customerId,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomer(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectGuarantor = async (guarantor, value) => {
    if (value) {
      setSelectedGuarantors([...selectedGuarantors, guarantor])
    } else {
      setSelectedGuarantors(
        selectedGuarantors?.filter(x => x?.id !== guarantor?.id)
      )
    }
  }

  const handleSelectAllGuarantors = async (guarantors, value) => {
    if (value) {
      setSelectedGuarantors(guarantors)
    } else {
      setSelectedGuarantors([])
    }
  }

  const toggleReleaseAttach = async () => {
    setIsBusy(true)
    const url = '/LoanCase/toggle-release-loan-guarantors'

    const { success } = await postData(
      url,

      selectedGuarantors?.map(guarantor => ({
        loanCaseId: loanCaseId,
        ...guarantor,
      })),
      false
    )

    if (success) {
      handleClose()
      setIsBusy(false)
      showNotification('Adjust Guarantor Status', 'success')
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    if (customerId) fetchCustomer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  return (
    <ModalTemplate
      modalTitle="adhocLoanGuarantorStatusAdjustment"
      actionText="release/attach"
      cancelText="close"
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      handleAction={toggleReleaseAttach}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-3 d-flex align-items-center">
              <label className="col-4 me-2 text-capitalize">
                {localize('fullAccountNumber')}
              </label>
              <input
                value={customer?.fullAccountNumber}
                type="text"
                className="form-control"
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountStatus')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.statusDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountRemarks')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.remarks || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  customer?.customerAccountTypeTargetProductDescription || ''
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.customerFullName || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.customerEmployerDescription || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  customer?.customerMembershipClassCustomerTypeDescription || ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerSerialNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.paddedCustomerSerialNumber || ''}
              />
            </div>
          </div>
          <div className="row">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identificationNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.customerIdentificationNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.formattedCustomerMemberNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.formattedCustomerAccountNumber || ''}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={customer?.customerPersonalFileNumber || ''}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('productSection')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  customer?.customerAccountTypeTargetProductSectionDescription ||
                  ''
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('bookBalance')}
              </label>
              <input
                type="text"
                className="form-control ms-2 text-end"
                disabled
                value={formatCurrency(customer?.bookBalance, null, null, false)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('principalBalance')}
              </label>
              <input
                type="text"
                className="form-control ms-2 text-end"
                disabled
                value={formatCurrency(
                  customer?.principalBalance,
                  null,
                  null,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('interestBalance')}
              </label>
              <input
                type="text"
                className="form-control ms-2 text-end"
                disabled
                value={formatCurrency(
                  customer?.interestBalance,
                  null,
                  null,
                  false
                )}
              />
            </div>
          </div>
          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('loanGuarantors'),
                  item: (
                    <LoanGuarantors
                      loanCaseId={loanCaseId}
                      handleSelectGuarantor={handleSelectGuarantor}
                      handleSelectAllGuarantors={handleSelectAllGuarantors}
                      selectedGuarantors={selectedGuarantors}
                    />
                  ),
                  icon: 'uil uil-edit',
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AdjustGuarantorStatus
