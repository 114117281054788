/** @format */

import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import React from 'react'
import { GroupApportionmentTransaction } from './GroupApportionmentTransaction'

export const GroupApportionmentContainer = ({
  transactionModel,
  setTransactionModel,
  onAddApportionMent,
  onRemoveApportionment,
  resetEntries,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            preload
            tabItems={[
              {
                label: localize('apportionment'),
                item: (
                  <GroupApportionmentTransaction
                    onRemoveApportionment={onRemoveApportionment}
                    resetEntries={resetEntries}
                    onAddApportionMent={onAddApportionMent}
                    transactionModel={transactionModel}
                    setTransactionModel={setTransactionModel}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
