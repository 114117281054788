/** @format */

import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import { getTodaysDate } from '../../../../../../../Helpers/formatters'
import { EditPurchaseRequisitionItems } from './EditPurchaseRequisitionItems'

export const EditPurchaseRequisitionModal = ({
  selectedPurchaseRequisition,
  setSelectedPurchaseRequisition,
  purchaseRequisitionEntries,
  isBusy,
  setIsBusy,
  setPurchaseRequisitionEntries,
}) => {
  const [purchaseRequisitionItemModel, setPurchaseRequisitionItemModel] =
    useState(null)
  const [loading, setLoading] = useState(false)

  const handleAddPurchaseRequisition = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/PurchaseRequisition/add-purchase-requisition-entries',
      {
        Description: purchaseRequisitionItemModel?.description,
        Reason: purchaseRequisitionItemModel?.reason,
        Quantity: purchaseRequisitionItemModel?.quantity,
        PurchaseRequisitionItems: purchaseRequisitionEntries,
      },
      false,
    )
    if (success) {
      setPurchaseRequisitionEntries([...purchaseRequisitionEntries, data])
      setPurchaseRequisitionItemModel(null)
    }
    setLoading(false)
  }

  const [reqParams, setReqParams] = useState({
    purchaseRequisitionId: selectedPurchaseRequisition?.id,
    text: '',
    pageIndex: 0,
    pageSize: 100,
  })
  const fetchPurchaseRequisitionItems = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PurchaseRequisition/find-purchase-requisitions-items-by-purchase-requisition-id-filter-in-page',
      {
        ...reqParams,
        purchaseRequisitionId: selectedPurchaseRequisition?.id,
      },
      false,
    )
    if (success) {
      setPurchaseRequisitionEntries([...data?.pageCollection])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchPurchaseRequisitionItems()
  }, [selectedPurchaseRequisition?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('reference')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPurchaseRequisition?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPurchaseRequisition?.departmentDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <CustomDatePicker
                name="expectedDate"
                defaultDate={selectedPurchaseRequisition?.expectedDate}
                onDateChange={date => {
                  setSelectedPurchaseRequisition({
                    ...selectedPurchaseRequisition,
                    expectedDate: date,
                  })
                }}
                maxDate={getTodaysDate()}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedPurchaseRequisition?.branchDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 120,
                }}
              >
                {localize('itemDetails')}
              </div>
              <div className="row mb-3" style={{ marginTop: -10 }}>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-1">
                    {localize('description')}
                  </label>
                  <input
                    className="form-control"
                    value={purchaseRequisitionItemModel?.description || ''}
                    onChange={e => {
                      setPurchaseRequisitionItemModel({
                        ...purchaseRequisitionItemModel,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3" style={{ marginTop: -10 }}>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-1">
                    {localize('reason')}
                  </label>
                  <input
                    className="form-control"
                    value={purchaseRequisitionItemModel?.reason || ''}
                    onChange={e => {
                      setPurchaseRequisitionItemModel({
                        ...purchaseRequisitionItemModel,
                        reason: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3" style={{ marginTop: -10 }}>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-1">
                    {localize('quantity')}
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    onChange={e => {
                      setPurchaseRequisitionItemModel({
                        ...purchaseRequisitionItemModel,
                        quantity: e.target.value,
                      })
                    }}
                    value={purchaseRequisitionItemModel?.quantity || ''}
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div
                  className="bg-light d-flex justify-content-end align-items-center p-2"
                  style={{ borderRadius: 5 }}
                >
                  <button
                    onClick={handleAddPurchaseRequisition}
                    className="btn btn-success text-uppercase ms-3 px-4"
                  >
                    {localize('add')}
                  </button>
                </div>
              )}
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 120,
                    marginBottom: -10,
                  }}
                >
                  {localize('requisitionItems')}
                </div>
                <EditPurchaseRequisitionItems
                  entries={{ pageCollection: purchaseRequisitionEntries }}
                  onRefresh={() => {
                    fetchPurchaseRequisitionItems()
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
