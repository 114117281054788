/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import MenuItem from './MenuItem'
import localize from '../../Global/Localize'

function MenuItemWithChild({ menu, disabled, depth }) {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <div
        style={{
          pointerEvents: disabled ? 'none' : 'all',
          paddingLeft: depth * 15,
        }}
        className={`menu-item with-child ${menuOpen ? 'open' : ''} ${
          disabled ? 'disabled' : ''
        }`}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <span style={{ textTransform: 'capitalize' }}>
          {localize(menu.label)}
        </span>
        <i className={`uil uil-angle-${menuOpen ? 'up' : 'down'}`}></i>
      </div>
      {menuOpen && (
        <div className="menu-item-dropdown">
          {menu.children.map((menu, i) =>
            menu.children ? (
              <MenuItemWithChild
                depth={depth + 1}
                key={`inner-menu-item-${menu.label}-${i}`}
                menu={menu}
              />
            ) : (
              <MenuItem
                depth={depth + 1}
                key={`inner-menu-item-${menu.label}-${i}`}
                menu={menu}
              />
            )
          )}
        </div>
      )}
    </>
  )
}

export default MenuItemWithChild
