/** @format */

import React from 'react'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'

const ViewPatientSample = ({ mode, handleClose, sample }) => {
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      modalTitle="viewMorguePatientSample"
      hideUpdate
    >
      <div className="row g-3">
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('morguePatient')}
          </label>
          <input
            className="form-control"
            value={sample?.morguePatientFullName}
            disabled
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('technician')}
          </label>
          <input
            className="form-control"
            value={sample?.morgueTechnicianFullName}
            disabled
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('returnedBy')}
          </label>
          <input className="form-control" value={sample?.returnedBy} disabled />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('disposedBy')}
          </label>
          <input className="form-control" value={sample?.disposedBy} disabled />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('returnedDate')}
          </label>
          <input
            className="form-control"
            value={formatDate(sample?.returnedDate)}
            disabled
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('disposedDate')}
          </label>
          <input
            className="form-control"
            value={formatDate(sample?.disposedDate)}
            disabled
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('recordStatus')}
          </label>
          <input
            className="form-control"
            value={formatDate(sample?.recordStatusDescription)}
            disabled
          />
        </div>

        <div className="col-md-12 d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('description')}
          </label>
          <textarea
            className="form-control"
            value={sample?.description}
            disabled
          />
        </div>
        <div className="col-md-12 d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('remarks')}</label>
          <textarea className="form-control" value={sample?.remarks} disabled />
        </div>
        <div className="col-md-12 d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('additionalRemarks')}
          </label>
          <textarea
            className="form-control"
            value={sample?.additionalRemarks}
            disabled
          />
        </div>
      </div>
    </ModalTemplate>
  )
}

export default ViewPatientSample
