/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import LoanCasePartial from './LoanCasePartial'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

const Cancellation = () => {
  const [loanCase, setLoanCase] = useState(null)
  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanOrigination'),
          localize('loanCancellation'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white">
          <LoanCasePartial onSelectLoanCase={l => setLoanCase(l)} />
          <div className="mt-3 bg-light p-4 d-flex justify-content-end rounded">
            <div className="col-5 ">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="col-3 text-capitalize">
                  {localize('remarks')}
                </label>
                <input type="text" className="form-control" />
                <button
                  style={{ opacity: 0, pointerEvents: 'none' }}
                  className="btn btn-success px-4 ms-2"
                >
                  {localize('update')}
                </button>
              </div>
              <div className="d-flex align-items-center mt-2">
                <label htmlFor="" className="col-3 text-capitalize">
                  {localize('cancellationAction')}
                </label>
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ width: '100%' }}
                >
                  <div style={{ width: '100%' }}>
                    <EnumsServerCombo enumsKey="LoanCancellationOption" />
                  </div>
                  <button className="btn btn-success px-4">
                    {localize('update')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cancellation
