/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function RelationshipManagerLookup({ displayValue, onSelect, disabled }) {
  const columns = [
    {
      label: 'employeeName',
      template: r => r.employeeCustomerFullName,
    },
    {
      label: 'nationality',
      template: r => r.employeeCustomerIndividualNationalityDescription,
    },
    {
      label: 'identityCardNumber',
      template: r => r.employeeCustomerIndividualIdentityCardNumber,
    },
    {
      label: 'gender',
      template: r => r.employeeCustomerIndividualGenderDescription,
    },
    {
      label: 'maritalStatus',
      template: r => r.employeeCustomerIndividualMaritalStatusDescription,
    },
    {
      label: 'serialNumber',
      template: r => r.paddedEmployeeCustomerSerialNumber,
    },
    {
      label: 'designation',
      template: r => r.employeeDesignationDescription,
    },
    {
      label: 'branch',
      template: r => r.employeeBranchDescription,
    },
    {
      label: 'department',
      template: r => r.employeeDepartmentDescription,
    },
    {
      label: 'employeeType',
      template: r => r.employeeEmployeeTypeDescription,
    },
    {
      label: 'employeeStatus',
      template: r => r.employeeEmploymentStatusDescription,
    },
    {
      label: 'remarks',
      template: r => r.remarks,
    },
    {
      label: 'isLocked',
      template: r => r.isLocked.toString(),
    },
    {
      label: 'createdBy',
      template: r => r.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r.createdDate),
    },
  ]
  return (
    <>
      <SimpleLookup
        disabled={disabled}
        useText
        columns={columns}
        url="/lookup/RelationshipManager/find-relationship-managers-by-filter-in-page"
        params={{
          text: '',
          pageIndex: 0,
          pageSize: 10,
          employeeFilter: 0,
        }}
        displayValue={displayValue}
        onSelect={onSelect}
        title={localize('relationshipManager')}
      />
    </>
  )
}

export default RelationshipManagerLookup
