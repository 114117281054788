/** @format */

// Verification
/** @format */

import localize from '../../../../../Global/Localize'
import LoanRescheduleRequestLookup from '../../../../../Components/Lookup/LoanRescheduleRequestLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { useState, useEffect } from 'react'
import { getData, postData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import { generateGuid } from '../../../../../Helpers/extensions'
import {
  formatDate,
  formatCurrency,
  getTodaysDate,
} from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

const Verification = () => {
  const [verification, setVerification] = useState({})
  const [reqParams, setReqParams] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [createParams, setCreateParams] = useState({})
  const [customerAccount, setCustomerAccount] = useState({})
  const [repaymentRescheduleTableData, setRepaymentScheduleTableData] =
    useState({})
  const [customerData, setCustomerData] = useState({})
  const [refresh, setRefresh] = useState(false)

  const loadCustomerData = async customerParams => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account',
      customerParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setCustomerData(data)
      setCustomerAccount({
        termInMonths: verification?.numberOfPeriods,
        paymentFrequencyPerYear:
          data?.loanCaseLoanRegistrationPaymentFrequencyPerYear,
        customerData: verification?.customerData,
        interestCalculationMode: data?.loanCaseLoanInterestCalculationMode,
        roundingType:
          customerData?.customerAccountTypeTargetProductRoundingType,
        apr: data?.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate,
        pv: data?.principalBalance,
        dtDate: getTodaysDate(),
        rebaseDueDate: true,
      })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    handleFetchRepaymentScheduleData(customerAccount)
  }, [customerAccount, refresh])

  const handleFetchRepaymentScheduleData = async repaymentScheduleParams => {
    setIsBusy(true)
    const url = '/Journal/repayment-schedule'

    const { data, success } = await postData(
      url,
      {
        repaymentScheduleParams,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setRepaymentScheduleTableData(
        data?.result.map(d => ({ ...d, id: generateGuid() }))
      )
    }
    setIsBusy(false)
  }

  const handleCreateVerification = async () => {
    setIsBusy(true)
    const url = '/LoanRescheduleRequest/audit-loan-reschedule-request'

    const { success } = await postData(
      url,

      { createParams },
      false,
      'Loan verification completed successfully.'
    )

    if (success) {
      setIsBusy(false)
      setVerification({})
      setCreateParams({})
      setCustomerAccount({})
      setCustomerData({})
    }
    setIsBusy(false)
  }

  const handleSelectCustomerAccount = async x => {
    const customerParams = {
      customerAccountId: x?.customerAccountId,
      includeBalances: true,
      includeProductDescription: true,
    }
    await loadCustomerData(customerParams)
    setVerification(x)
    setRefresh(true)
    setReqParams({
      ...reqParams,
      id: x?.id,
    })
    setCreateParams({
      loanRescheduleRequest: {},
      processingOption: 1,
    })
  }

  const columns = [
    {
      label: localize('period'),
      template: r => <>{r?.period}</>,
    },
    {
      label: localize('dueDate'),
      template: r => <>{formatDate(r?.dueDate)}</>,
    },
    {
      label: localize('startingBalance'),
      template: r => <>{formatCurrency(r?.startingBalance)}</>,
    },
    {
      label: localize('payment'),
      template: r => <>{formatCurrency(r?.payment)}</>,
    },
    {
      label: localize('interestPayment'),
      template: r => <>{formatCurrency(r?.interestPayment)}</>,
    },
    {
      label: localize('principalPayment'),
      template: r => <>{formatCurrency(r?.principalPayment)}</>,
    },
    {
      label: localize('endingBalance'),
      template: r => <>{formatCurrency(r?.endingBalance)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('rescheduling details'),
      item: (
        <div className="p-2 mb-2">
          <div className="row g-3 mb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('loanProduct')}
                </label>
                <input
                  type="text"
                  className="form-control col-xm"
                  disabled
                  value={
                    customerData?.customerAccountTypeTargetProductDescription
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('interestCalculationMode')}
                </label>
                <input
                  type="text"
                  className="form-control col-sm"
                  disabled
                  value={
                    customerData?.loanCaseLoanInterestCalculationModeDescription
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('annualPercentageRate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate !==
                    undefined
                      ? customerData.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate +
                        '%'
                      : ''
                  }
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('paymentFrequencyPerYear')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription
                  }
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mb-2">
            <div className="col">
              <div className="d-flex align-items-center with-validation">
                <label className="text-capitalize col-6">
                  {localize('paymentPerPeriod')}
                </label>
                <input
                  type="number"
                  className="form-control text-end"
                  value={verification?.paymentPerPeriod}
                  disabled
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('numberOfPeriods')}
                </label>
                <input
                  type="number"
                  className="form-control"
                  disabled
                  value={verification?.numberOfPeriods}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center with-validation">
                <label className="text-capitalize col-6">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={verification?.reference}
                  disabled
                />
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col">
              <div className="d-flex align-items-center with-validation">
                <label className="text-capitalize col-6">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={verification?.createdBy}
                  disabled
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-6">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={verification?.createdDate}
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center with-validation">
                <label className="text-capitalize col-6">
                  {localize('status')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={verification?.statusDescription}
                />
              </div>
            </div>
            <div className="col"></div>
          </div>
          <div
            className="mt-2 row"
            style={{
              position: 'relative',
              border: 'solid 1px #0000001f',
              borderRadius: '5px',
              padding: '20px',
            }}
          >
            <div
              className="bg-white row px-2 text-capitalize mb-4"
              style={{
                position: 'absolute',
                top: '-8px',
                left: '30px',
                maxWidth: 'fit-content',
              }}
            >
              {localize('newRepaymentSchedule')}
            </div>
            <SimpleTable
              columns={columns}
              className="mt-4"
              data={repaymentRescheduleTableData || []}
            />
          </div>
        </div>
      ),
    },
  ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanRestructuring'),
          localize('verification'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3 mb-2 d-flex align-items-center">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="fullAccountNumber"
                      className="text-capitalize col-6"
                    >
                      {localize('fullAccountNumber')}
                    </label>
                    <LoanRescheduleRequestLookup
                      displayValue={verification?.fullAccountNumber}
                      statusFilter={1}
                      onSelect={async x => {
                        handleSelectCustomerAccount(x)
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('accountStatus')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={verification?.customerAccountStatusDescription}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('accountRemarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={verification?.customerAccountRemarks}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('productName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        customerData?.customerAccountTypeTargetProductDescription
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-2">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('customerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={verification?.customerFullName}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('customerType')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        verification?.customerAccountCustomerMembershipClassCustomerTypeDescription
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('customerSerial#')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={verification?.customerAccountCustomerSerialNumber}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('employerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={customerData?.customerEmployerDescription}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-2">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('identification#')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        verification?.customerAccountCustomerIndividualIdentityCardNumber
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('memberNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={verification?.customerAccountCustomerMemberNumber}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('accountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={verification?.customerAccountCustomerAccountNumber}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('personalFileNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        verification?.customerAccountCustomerPersonalFileNumber
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-4">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('bookBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={customerData?.bookBalance}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('principalBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={customerData?.principalBalance}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('interestBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={customerData?.interestBalance}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-6">
                      {localize('loanClassification')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={customerData?.loanClassificationDescription}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <Tab preload tabItems={tabItems} />
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        auditRemarks: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="LoanRescheduleRequestProcessingOption"
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center ms-2">
                  <button
                    onClick={handleCreateVerification}
                    className="btn btn-success waves-effect waves-light text-capitalize"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    {localize('update')}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Verification
