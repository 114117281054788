/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData } from '../../../../../Helpers/webApi'

function LoanCaseHistory({ loanCase }) {
  const [loan, setLoan] = useState(loanCase)
  const [fetching, setFetching] = useState(false)
  const [history, setHistory] = useState([])

  async function getHistory() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/loanCase/find-loan-case-history-by-loan-case-id',
      { loanCaseId: loan?.id },
      false
    )
    if (success) {
      setFetching(false)
      setHistory(data?.result)
    } else {
      setFetching(false)
    }
  }

  useEffect(() => {
    getHistory()
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('appUserName'),
      template: r => <>{r?.applicationUsername}</>,
    },
    {
      label: localize('envUserName'),
      template: r => <>{r?.environmentUserName}</>,
    },
    {
      label: localize('envMachineName'),
      template: r => <>{r?.environmentMachineName}</>,
    },
    {
      label: localize('envDomainName'),
      template: r => <>{r?.environmentDomainName}</>,
    },
    {
      label: localize('envOperatingSystemVersion'),
      template: r => <>{r?.environmentOSVersion}</>,
    },
    {
      label: localize('envMACAddress'),
      template: r => <>{r?.environmentMACAddress}</>,
    },
    {
      label: localize('envMotherboardSerialNumber'),
      template: r => <>{r?.environmentMotherboardSerialNumber}</>,
    },
    {
      label: localize('envProcessorId'),
      template: r => <>{r?.environmentProcessorId}</>,
    },
    {
      label: localize('envIpAddress'),
      template: r => <>{r?.environmentIPAddress}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <>
      <SimpleTable columns={columns} data={history} />
    </>
  )
}

export default LoanCaseHistory
