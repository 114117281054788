/** @format */
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
/* eslint-disable */
import React, { useState } from 'react'
import Tab from '../../../../../../Components/Tabs/Tab'
import { AccountMetadataArchivedStatement } from './AccountMetadata/AccountMetadataArchivedStatement'

export const AccountStatementsArchivedMetadataModal = ({
  selectedCustomerAccount,
  selectedCustomer,
  setSelectedCustomerAccount,
  gbv,
  clearValidation,
}) => {
  let tabs = [
    {
      label: localize('statement'),
      item: (
        <AccountMetadataArchivedStatement
          selectedCustomerAccount={selectedCustomerAccount}
          selectedCustomer={selectedCustomer}
        />
      ),
    },
  ]
  const [accountMetaDataModel, setAccountMetaDataModel] = useState({
    statementType: '0',
  })

  const handleOnChangeStatementType = r => {
    setAccountMetaDataModel({
      ...accountMetaDataModel,
      statementType: r.value,
    })

    setSelectedCustomerAccount({
      ...selectedCustomerAccount,
      customerAccountStatementType: r.value,
    })
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 120,
          }}
        >
          {localize('statementDetails')}
        </div>
        <div className="row mb-2" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('statementType')}
            </label>
            <EnumsServerCombo
              value={accountMetaDataModel?.statementType || ''}
              customContainerStyle={{ width: '100%' }}
              enumsKey={'CustomerAccountStatementType'}
              onChange={handleOnChangeStatementType}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountName')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.accountName}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.fullAccountNumber}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.statusDescription}
            />
          </div>
        </div>
        <div className="row mt-2" style={{ marginTop: -10 }}>
          <div className="col-3 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              className="form-control"
              disabled
              value={selectedCustomerAccount?.remarks}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('productName')}
            </label>
            <input
              disabled
              className="form-control"
              value={
                selectedCustomerAccount?.customerAccountTypeTargetProductDescription
              }
            />
          </div>
        </div>
      </div>

      <div className="row mb-2 mt-3">
        <div className="col-md-12">
          <Tab preload tabItems={tabs} />
        </div>
      </div>
    </>
  )
}
