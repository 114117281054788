/** @format */

import { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatDate, getTomorrowsDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import AddSurvey from './AddSurvey/AddSurvey'
import ViewSurvey from './ViewSurvey/ViewSurvey'
import DispatchSurvey from './DispatchSurvey/DispatchSurvey'

const initialSurveyParams = {
  endDate: getTomorrowsDate(),
}
const Surveys = () => {
  const [surveys, setSurveys] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [survey, setSurvey] = useState(initialSurveyParams)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const columns = [
    {
      label: localize('description'),
      template: r => r?.description,
    },
    {
      label: localize('entryQuestion'),
      template: r => r?.entryQuestion,
    },
    {
      label: localize('entryAnswer'),
      template: r => r?.entryAnswer,
    },
    {
      label: localize('closingRemark'),
      template: r => r?.closingRemark,
    },
    {
      label: localize('endDate'),
      template: r => formatDate(r?.endDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
  ]

  const fetchSurveys = async () => {
    setIsBusy(true)

    const url = '/Survey/find-surveys-by-date-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setSurveys(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleClickAddButton = () => {
    setMode('add')
    setSurvey(initialSurveyParams)
  }

  const handleClose = () => {
    setMode(null)
    setSurvey(initialSurveyParams)
  }

  const handleSelectSurvey = (r, m) => {
    setMode(m)
    setSurvey(r)
  }

  const handleCreateSurvey = async () => {}

  useEffect(() => {
    fetchSurveys()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <AddSurvey
          mode={mode}
          handleClose={handleClose}
          handleCreateSurvey={handleCreateSurvey}
          survey={survey}
          setSurvey={setSurvey}
          fetchSurveys={fetchSurveys}
        />
      )}

      {mode === 'view' && (
        <ViewSurvey handleClose={handleClose} survey={survey} mode={mode} />
      )}

      {mode === 'dispatch' && (
        <DispatchSurvey
          handleClose={handleClose}
          mode={mode}
          survey={survey}
          fetchSurveys={fetchSurveys}
        />
      )}

      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('messaging'),
          localize('surveys'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="d-flex justify-content-start align-items-center mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div className="d-flex align-items-center col-3">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    htmlFor=""
                    className="text-capitalize me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchSurveys()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchSurveys()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={surveys?.pageCollection}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectSurvey(r, 'view')
                      }}
                    >
                      <i className="mdi mdi-eye"></i>
                      {localize('view')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectSurvey(r, 'dispatch')
                      }}
                    >
                      <i className="mdi mdi-send"></i>
                      {localize('dispatch')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectSurvey(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      {localize('edit')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={surveys?.itemsCount}
                {...reqParams}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Surveys
