/** @format */

import localize from '../../../../../../../Global/Localize'
import { CreditTypeLookup } from '../../../../../../../Components/Lookup/CreditTypeLookup'
import {
  ChargeType,
  CreditBatchType,
  months,
  QueuePriority,
} from '../../../../../../../Global/enumeration'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
import PostingPeriodLookup from '../../../../../../../Components/Lookup/PostingPeriodLookup'
import ChartOfAccountsLookup from '../../../../../../../Components/Lookup/ChartOfAccountsLookup'
import { MaskPercentage } from '../../../../../../../Components/InputMasks/MaskPercentage'
import CostCentreLookup from '../../../../../../../Components/Lookup/CostCentreLookup'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import { useState } from 'react'
import { getData, putData } from '../../../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'

export const CreateCreditBatchModal = ({
  finalCreateBatchModel,
  validationBag,
  clearValidation,
  setFinalCreateBatchModel,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedConcessionType, setSelectedConcessionType] = useState(
    Object.keys(ChargeType)[0]
  )
  const [selectedQueuePriority, setSelectedQueuePriority] = useState(
    Object.keys(QueuePriority)[3]
  )
  const handleOnChangeValueDate = async e => {
    setIsBusy(true)
    const { success, data } = await putData(
      `/PostingPeriod/evaluate-value-date-validity/?valueDate=${e}`,
      {},
      false
    )
    if (success) {
      setFinalCreateBatchModel({
        ...finalCreateBatchModel,
        valueDate: e,
      })
    } else {
      setFinalCreateBatchModel({
        ...finalCreateBatchModel,
        valueDate: null,
      })
    }
    setIsBusy(false)
  }

  const getAttachedGeneralLedgerAccount = async e => {
    const { success, data } = await getData(
      '/ChartOfAccount/find-general-ledger-account',
      {
        chartOfAccountId: e.chartOfAccountId,
      },
      false
    )
    if (success) {
      return data
    } else {
      return null
    }
  }

  const handleOnSelectCreditType = async r => {
    const attachedChartOfAccount = await getAttachedGeneralLedgerAccount(r)
    clearValidation('CreditTypeId')
    clearValidation('ChartOfAccountId')
    setCreditBatchModel({
      ...creditBatchModel,
      creditTypeId: r.id,
      creditTypeDescription: r.description,
      chartOfAccountName: attachedChartOfAccount.name,
      chartOfAccountId: attachedChartOfAccount.id,
    })
    setFinalCreateBatchModel({
      ...finalCreateBatchModel,
      creditTypeId: r.id,
      creditTypeDescription: r.description,
      chartOfAccountName: attachedChartOfAccount.name,
      chartOfAccountId: attachedChartOfAccount.id,
    })
  }

  const [creditBatchModel, setCreditBatchModel] = useState({
    concessionType: '',
    concessionPercentage: 0,
    concessionFixedAmount: 0,
    creditTypeId: '',
    branchId: '',
    postingPeriodId: '',
    chartOfAccountId: '',
    costCenterId: '',
    totalValue: 0,
    type: Object.keys(CreditBatchType)[0],
    month: Object.keys(months)[0],
    valueDate: '',
    reference: '',
    priority: '',
    preserveAccountBalance: false,
    checkOffCutOffDate: '',
    fuzzyMatching: false,
    enforceCheckoffCutOffDate: false,
  })

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="row mb-3">
              <div
                validation-message={validationBag?.CreditTypeId?.message}
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('creditType')}
                </label>
                <CreditTypeLookup
                  displayValue={creditBatchModel?.creditTypeDescription}
                  onSelect={handleOnSelectCreditType}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('batchType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="CreditBatchType"
                  value={creditBatchModel?.type}
                  onChange={e => {
                    setCreditBatchModel({ ...creditBatchModel, type: e.value })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      type: e.value,
                    })
                  }}
                />
              </div>
              <div
                validation-message={validationBag?.TotalValue?.message}
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('totalValue')}
                </label>
                <MaskNumber
                  defaultMaskValue={creditBatchModel?.totalValue}
                  onMaskNumber={e => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      totalValue: e.target.value,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      totalValue: e.target.value,
                    })
                    clearValidation('TotalValue')
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('postingPeriod')}
                </label>
                <PostingPeriodLookup
                  displayValue={creditBatchModel?.postingPeriodDescription}
                  onSelect={r => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      postingPeriodId: r.id,
                      postingPeriodDescription: r.description,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      postingPeriodId: r.id,
                      postingPeriodDescription: r.description,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('referenceMonth')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="Month"
                  value={creditBatchModel?.month}
                  onChange={e => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      month: e.value,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      month: e.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('valueDate')}
                </label>
                <CustomDatePicker
                  name={'date-picker-value-date'}
                  onDateChange={handleOnChangeValueDate}
                  defaultDate={finalCreateBatchModel?.valueDate}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div
                validation-message={validationBag?.ChartOfAccountId?.message}
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('chartOfAccountName')}
                </label>
                <ChartOfAccountsLookup
                  displayValue={creditBatchModel?.chartOfAccountName}
                  onSelect={e => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      chartOfAccountName: e.name,
                      chartOfAccountId: e.id,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      chartOfAccountName: e.name,
                      chartOfAccountId: e.id,
                    })
                    clearValidation('ChartOfAccountId')
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('concessionType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="ChargeType"
                  value={selectedConcessionType}
                  onChange={e => {
                    setSelectedConcessionType(e.value)
                    setCreditBatchModel({
                      ...creditBatchModel,
                      concessionType: e.value,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      concessionType: e.value,
                    })
                  }}
                />
              </div>
              <div
                validation-message={
                  validationBag?.ConcessionPercentage?.message
                }
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('concession')}
                </label>
                {selectedConcessionType === Object.keys(ChargeType)[0] ? (
                  <MaskPercentage
                    defaultMaskValue={creditBatchModel?.concessionPercentage}
                    onMaskChange={e => {
                      setCreditBatchModel({
                        ...creditBatchModel,
                        concessionPercentage: e.target.value,
                      })
                      setFinalCreateBatchModel({
                        ...finalCreateBatchModel,
                        concessionPercentage: e.target.value,
                      })
                      clearValidation('ConcessionPercentage')
                    }}
                  />
                ) : (
                  <MaskNumber
                    defaultMaskValue={creditBatchModel?.concessionFixedAmount}
                    onMaskNumber={e => {
                      setCreditBatchModel({
                        ...creditBatchModel,
                        concessionFixedAmount: e.target.value,
                      })
                      setFinalCreateBatchModel({
                        ...finalCreateBatchModel,
                        concessionFixedAmount: e.target.value,
                      })
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('costCentre')}
                </label>
                <CostCentreLookup
                  displayValue={creditBatchModel?.costCenterDescription}
                  onSelect={e => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      costCenterDescription: e.description,
                      costCenterId: e.id,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      costCenterDescription: e.description,
                      costCenterId: e.id,
                    })
                  }}
                />
              </div>
              <div
                validation-message={validationBag?.Reference?.message}
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  className="form-control"
                  defaultValue={creditBatchModel?.reference}
                  onChange={e => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      reference: e.target.value,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      reference: e.target.value,
                    })
                    clearValidation('Reference')
                  }}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('checkOffCutOffDate')}
                </label>
                <CustomDatePicker
                  defaultDate={creditBatchModel?.checkOffCutOffDate}
                  name={'check-cut-off-date'}
                  onDateChange={e => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      checkOffCutOffDate: e,
                    })
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      checkOffCutOffDate: e,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-4 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('priority')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="QueuePriority"
                  value={selectedQueuePriority}
                  onChange={e => {
                    setCreditBatchModel({
                      ...creditBatchModel,
                      priority: e.value,
                    })
                    setSelectedQueuePriority(e.value)
                    setFinalCreateBatchModel({
                      ...finalCreateBatchModel,
                      priority: e.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        </>
      )}
    </>
  )
}
