/** @format */

/* eslint-disable */
import React from 'react'
import Swal from 'sweetalert2'
import ExportButton from '../../../../Components/Buttons/ExportButton/ExportButton'
import { getData } from '../../../../Helpers/webApi'

const ExportAuditLogs = ({ reqParams, isBusy = false, setIsBusy }) => {
  const handleExport = async () => {
    setIsBusy(true)
    const url = '/AuditLog/export-audit-logs-by-date-range'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      let newBlobString = `data:text/csv;base64,${data?.content}`
      const element = document.createElement('a')
      element.href = newBlobString
      element.download = `AuditLogs_${new Date().toLocaleDateString()}.csv`
      document.body.appendChild(element)
      element.click()
      element.remove()
    } else {
      Swal.fire('Error', 'Error exporting data', 'error')
    }
    setIsBusy(false)
  }

  return (
    <>{isBusy ? <></> : <ExportButton handleExportButton={handleExport} />}</>
  )
}

export default ExportAuditLogs
