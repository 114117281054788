/** @format */
import localize from '../../../../Global/Localize'
import { CreditTypeLookup } from '../../../../Components/Lookup/CreditTypeLookup'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import React from 'react'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'

export const CreateLoanRepaymentModeModal = ({
  selectedLoanRepaymentMode,
  setSelectedLoanRepaymentMode,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-2 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedLoanRepaymentMode?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedLoanRepaymentMode({
                    ...selectedLoanRepaymentMode,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('standingOrderTrigger')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="StandingOrderTrigger"
                value={selectedLoanRepaymentMode?.standingOrderTrigger}
                onChange={e =>
                  setSelectedLoanRepaymentMode({
                    ...selectedLoanRepaymentMode,
                    standingOrderTrigger: e.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('creditType')}
              </label>
              <CreditTypeLookup
                displayValue={selectedLoanRepaymentMode?.creditTypeDescription}
                onSelect={e => {
                  setSelectedLoanRepaymentMode({
                    ...selectedLoanRepaymentMode,
                    creditTypeId: e.id,
                    creditTypeDescription: e.description,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
