/** @format */

import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import { getData } from '../../../../../Helpers/webApi'
import { useEffect } from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import ViewStandingOrder from './ViewStandingOrder/ViewStandingOrder'
import CreateStandingOrder from './CreateStandingOrder/CreateStandingOrder'
import EditStandingOrder from './EditStandingOrder/EditStandingOrder'

const StandingOrders = () => {
  const [standingOrders, setStandingOrders] = useState([])
  const [standingOrder, setStandingOrder] = useState(null)
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    customerAccountFilter: 0,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    customerFilter: 0,
    excludeLocked: true,
    includeProductDescription: true,
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('trigger'),
      template: r => <>{r?.triggerDescription}</>,
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r?.beneficiaryFullAccountNumber}</>,
    },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r?.beneficiaryProductDescription}</>,
    },
    {
      label: localize('beneficiaryAccountName'),
      template: r => <>{r?.beneficiaryCustomerAccountAccountName}</>,
    },
    {
      label: localize('creditType'),
      template: r => <>{r?.creditTypeDescription}</>,
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r?.durationStartDate)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r?.durationEndDate)}</>,
    },
    {
      label: localize('duration(Months)'),
      template: r => <>{r?.durationInMonths}</>,
    },
    {
      label: localize('frequency'),
      template: r => <>{r?.scheduleFrequencyDescription}</>,
    },
    {
      label: localize('expectedRunDate'),
      template: r => <>{formatDate(r?.scheduleExpectedRunDate)}</>,
    },
    {
      label: localize('actualRunDate'),
      template: r => <>{formatDate(r?.scheduleActualRunDate)}</>,
    },
    {
      label: localize('disbursedDate'),
      template: r => (
        <>{formatDate(r?.beneficiaryCustomerAccountLoanCaseDisbursedDate)}</>
      ),
    },
    {
      label: localize('disbursedAmount'),
      template: r => (
        <>
          {formatCurrency(r?.beneficiaryCustomerAccountLoanCaseDisbursedAmount)}
        </>
      ),
    },
    {
      label: localize('paymentPerPeriod'),
      template: r => <>{formatCurrency(r?.paymentPerPeriod)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r?.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r?.interest)}</>,
    },
    {
      label: localize('capitalizedInterest'),
      template: r => <>{formatCurrency(r?.capitalizedInterest)}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r?.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r?.chargePercentage)}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{formatCurrency(r?.chargeFixedAmount)}</>,
    },
    {
      label: localize('beneficiaryCustomerName'),
      template: r => <>{r?.beneficiaryCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.beneficiaryCustomerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r?.beneficiaryCustomerAccountCustomerReference2}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.beneficiaryCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r?.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r?.benefactorProductDescription}</>,
    },
    {
      label: localize('benefactorCustomerName'),
      template: r => <>{r?.benefactorCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('benefactorAccountName'),
      template: r => <>{r?.benefactorCustomerAccountAccountName}</>,
    },
    {
      label: localize('benefactorAccountNumber'),
      template: r => <>{r?.benefactorCustomerAccountCustomerReference1}</>,
    },
    {
      label: localize('benefactorMembershipNumber'),
      template: r => <>{r?.benefactorCustomerAccountCustomerReference2}</>,
    },
    {
      label: localize('benefactorPersonalFileNumber'),
      template: r => <>{r?.benefactorCustomerAccountCustomerReference3}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('chargeable') + ' ?',
      template: r => <>{r?.chargeable?.toString()}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  const fetchStandingOrders = async () => {
    setIsBusy(true)

    const url = '/StandingOrder/find-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setStandingOrders(data)
    }

    setIsBusy(false)
  }

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleSelectStandingOrder = (standingOrder, mode) => {
    setStandingOrder(standingOrder)
    setMode(mode)
  }

  const handleClose = () => {
    setMode(null)
    setStandingOrder(null)
  }

  useEffect(() => {
    fetchStandingOrders()
    // eslint-disable-next-line
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      {mode === 'view' && (
        <ViewStandingOrder
          onClose={handleClose}
          standingOrderId={standingOrder?.id}
        />
      )}
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('customerAccounts'),
          localize('standingOrders'),
        ]}
      />

      {mode === 'add' && (
        <CreateStandingOrder
          mode={mode}
          fetchStandingOrders={fetchStandingOrders}
          handleClose={handleClose}
        />
      )}

      {mode === 'edit' && (
        <EditStandingOrder
          fetchStandingOrders={fetchStandingOrders}
          handleClose={handleClose}
          mode={mode}
          standingOrderId={standingOrder?.id}
        />
      )}

      {mode === 'auditLogs' && (
        <ViewAuditLogs
          data={standingOrder}
          handleClose={handleClose}
          mode={mode}
          title={`${standingOrder?.triggerDescription} : ${standingOrder?.beneficiaryProductDescription} -> ${standingOrder?.beneficiaryCustomerAccountCustomerFullName}`}
        />
      )}

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-3">
              <AddNewButton handleClickAddButton={handleClickAddButton} />
            </div>
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row d-flex align-items-center justify-content-between mb-3 mt-3 gap-1">
                <div className="d-flex align-items-center col-md-3">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        pageSize: e.target.value,
                        pageIndex: 0,
                      })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>

                <div className="col-md-2 d-flex align-items-center">
                  <Checkbox
                    id="excludeLockedStandingOrders"
                    checked={reqParams.excludeLocked}
                    onCheck={value =>
                      setReqParams({
                        ...reqParams,
                        excludeLocked: value,
                        pageIndex: 0,
                      })
                    }
                  />

                  <label
                    htmlFor="excludeLockedStandingOrders"
                    className="ms-2 text-capitalize"
                  >
                    {localize('excludeLocked')}
                  </label>
                </div>

                <div className="col-md-3 d-flex align-items-center">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.customerAccountFilter}
                    enumsKey="StandingOrderCustomerAccountFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        customerAccountFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                </div>

                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        customerFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchStandingOrders()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchStandingOrders()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={standingOrders?.pageCollection}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectStandingOrder(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('view')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectStandingOrder(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span>{localize('edit')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectStandingOrder(r, 'auditLogs')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('viewAuditLogs')}</span>
                    </div>
                  </>
                )}
              />
              <div className="mt-2">
                <Pager
                  itemsCount={standingOrders.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default StandingOrders
