/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { AddChequeTypeModal } from './AddChequeTypeModal'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { EditChequeTypeModal } from './EditChequeTypeModal'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Tab from '../../../../Components/Tabs/Tab'
import { ChequeTypeCommissions } from './ChequeTypeCommissions'
import { ChequeTypeOffsetableLoanProducts } from './ChequeTypeOffsetableLoanProducts'
import { ChequeTypeInvestmentProducts } from './ChequeTypeInvestmentProducts'

function ChequeTypesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedChequeType,
  setSelectedChequeType,
  waitingMetadata,
}) {
  const [isBusy, setIsBusy] = useState()
  async function handleSubmit() {
    if (mode === 'add') {
      return addChequeType()
    }
    if (mode === 'edit') {
      return editChequeType()
    }
  }

  const addChequeType = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/ChequeType/add-cheque-type-with-commissions-and-attached-products',
      {
        ChequeType: selectedChequeType || {},
        Commissions:
          selectedChequeType.commissions.filter(x => x.checked) || [],
        ProductCollectionInfo: {
          InvestmentProductCollection:
            selectedChequeType?.investmentProducts.filter(x => x.checked) || [],
          LoanProductCollection:
            selectedChequeType?.loanProducts.filter(x => x.checked) || [],
        },
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Add Cheque Type',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editChequeType = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/ChequeType/update-cheque-type-with-commissions-and-attached-products',
      {
        ChequeType: selectedChequeType || {},
        Commissions:
          selectedChequeType.commissions.filter(x => x.checked) || [],
        ProductCollectionInfo: {
          InvestmentProductCollection:
            selectedChequeType?.investmentProducts.filter(x => x.checked) || [],
          LoanProductCollection:
            selectedChequeType?.loanProducts.filter(x => x.checked) || [],
        },
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Edit Cheque Type',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('chequeType')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
            disableCreate={isBusy}
            hideUpdate={mode === 'view'}
            footerChildren={
              mode !== 'view' && (
                <div className="d-flex align-content-end justify-content-end">
                  <div className="d-flex align-items-center me-5">
                    <Checkbox
                      id={'preserveAccountBalance'}
                      checked={selectedChequeType?.preserveAccountBalance}
                      onCheck={v =>
                        setSelectedChequeType({
                          ...selectedChequeType,
                          preserveAccountBalance: v,
                        })
                      }
                    />
                    <label
                      htmlFor="preserveAccountBalance"
                      className="text-capitalize ms-2"
                    >
                      {localize('preserveAccountBalance')}?
                    </label>
                  </div>
                  <div className="d-flex align-items-center me-5">
                    <Checkbox
                      id={'isLocked'}
                      checked={selectedChequeType?.isLocked}
                      onCheck={v =>
                        setSelectedChequeType({
                          ...selectedChequeType,
                          isLocked: v,
                        })
                      }
                    />
                    <label htmlFor="isLocked" className="text-capitalize ms-2">
                      {localize('isLocked')}?
                    </label>
                  </div>
                </div>
              )
            }
          >
            {mode === 'add' && (
              <AddChequeTypeModal
                isBusy={isBusy}
                setSelectedChequeType={setSelectedChequeType}
                selectedChequeType={selectedChequeType}
              />
            )}
            {mode === 'edit' && (
              <EditChequeTypeModal
                setSelectedChequeType={setSelectedChequeType}
                selectedChequeType={selectedChequeType}
                isBusy={isBusy}
              />
            )}
            {mode === 'view' && (
              <>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-1 text-capitalize">
                      {localize('name')}
                    </label>
                    <input
                      className="form-control"
                      value={selectedChequeType?.description}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('maturityDaysCategory')}
                    </label>
                    <input
                      className="form-control"
                      value={
                        selectedChequeType?.maturityDaysCategoryDescription
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('maturityPeriod')}
                    </label>
                    <input
                      className={'form-control'}
                      value={selectedChequeType?.maturityPeriod}
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('chargeRecoveryMode')}
                    </label>
                    <input
                      className="form-control"
                      value={selectedChequeType?.chargeRecoveryModeDescription}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <Tab
                      preload
                      tabItems={[
                        {
                          label: localize('applicableCharges'),
                          item: (
                            <ChequeTypeCommissions
                              disabled
                              waiting={waitingMetadata}
                              setSelectedChequeType={setSelectedChequeType}
                              selectedChequeType={selectedChequeType}
                              prefix={'view-cheque-type-modal'}
                            />
                          ),
                        },
                        {
                          label: localize('offsetableLoanProducts'),
                          item: (
                            <ChequeTypeOffsetableLoanProducts
                              disabled
                              waiting={waitingMetadata}
                              setSelectedChequeType={setSelectedChequeType}
                              selectedChequeType={selectedChequeType}
                              prefix={'view-cheque-type-modal'}
                            />
                          ),
                        },
                        {
                          label: localize('offsetableInvestmentProducts'),
                          item: (
                            <ChequeTypeInvestmentProducts
                              disabled
                              waiting={waitingMetadata}
                              setSelectedChequeType={setSelectedChequeType}
                              selectedChequeType={selectedChequeType}
                              prefix={'view-cheque-type-modal'}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </>
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default ChequeTypesModal
