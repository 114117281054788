/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'

export const Address = ({
  disableInputs,
  address,
  setAddress,
  editMode,
  validationBag,
  setValidationBag,
}) => {
  return (
    <div className="mt-3">
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('addressLine1')}
          </label>
          {disableInputs ? (
            <input
              className="form-control"
              disabled
              defaultValue={address?.addressLine1}
            />
          ) : (
            <div className="d-flex flex-column w-100">
              <input
                className="form-control"
                defaultValue={
                  editMode
                    ? address?.addressLine1
                    : address?.AddressAddressLine1
                }
                onChange={v => {
                  if (editMode) {
                    const value = v.target.value
                    setAddress({
                      ...address,
                      addressLine1: value,
                    })
                  } else {
                    setAddress({
                      ...address,
                      AddressAddressLine1: v.target.value,
                    })
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('addressLine2')}
          </label>
          {disableInputs ? (
            <input
              className="form-control"
              disabled
              defaultValue={address?.addressLine2}
            />
          ) : (
            <div className="d-flex flex-column w-100">
              <input
                className="form-control"
                defaultValue={
                  editMode
                    ? address?.addressLine2
                    : address?.AddressAddressLine2
                }
                onChange={v => {
                  if (editMode) {
                    const value = v.target.value
                    setAddress(prev => ({
                      ...prev,
                      addressAddressLine2: value,
                    }))
                  } else {
                    setAddress({
                      ...address,
                      AddressAddressLine2: v.target.value,
                    })
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('physicalAddress')}
          </label>
          {disableInputs ? (
            <input
              className="form-control"
              disabled
              defaultValue={address?.street}
            />
          ) : (
            <div className="d-flex flex-column w-100">
              <input
                className="form-control"
                defaultValue={
                  editMode ? address?.street : address?.AddressStreet
                }
                onChange={v => {
                  if (editMode) {
                    const value = v.target.value
                    setAddress({
                      ...address,
                      addressStreet: value,
                    })
                  } else {
                    setAddress({
                      ...address,
                      AddressStreet: v.target.value,
                    })
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('landLine')}
          </label>
          {disableInputs ? (
            <input
              className="form-control"
              disabled
              defaultValue={address?.landLine}
            />
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                <input
                  className={`form-control ${
                    validationBag?.AddressLandLine?.isDirty && 'is-invalid'
                  }`}
                  defaultValue={
                    editMode ? address?.landLine : address?.AddressLandLine
                  }
                  onKeyDown={r => {
                    if (validationBag?.AddressLandLine?.isDirty) {
                      setValidationBag(prevBag => ({
                        ...prevBag,
                        AddressLandLine: {
                          ...prevBag.AddressLandLine,
                          isDirty: false,
                        },
                      }))
                    }
                  }}
                  onChange={v => {
                    if (editMode) {
                      const value = v.target.value
                      setAddress({
                        ...address,
                        addressLandLine: value,
                      })
                    } else {
                      setAddress({
                        ...address,
                        AddressLandLine: v.target.value,
                      })
                    }
                  }}
                />
                {validationBag?.AddressLandLine?.isDirty && (
                  <div className="invalid-tooltip">
                    {validationBag?.AddressLandLine?.message}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('mobileLine')}
          </label>
          {disableInputs ? (
            <input
              disabled
              className="form-control"
              defaultValue={address?.mobileLine}
            />
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                <input
                  className={`form-control ${
                    validationBag?.AddressMobileLine?.isDirty && 'is-invalid'
                  }`}
                  defaultValue={
                    editMode ? address?.mobileLine : address?.AddressMobileLine
                  }
                  onKeyDown={r => {
                    if (validationBag?.AddressMobileLine?.isDirty) {
                      setValidationBag(prevBag => ({
                        ...prevBag,
                        AddressMobileLine: {
                          ...prevBag.AddressMobileLine,
                          isDirty: false,
                        },
                      }))
                    }
                  }}
                  onChange={v => {
                    if (editMode) {
                      const value = v.target.value
                      setAddress({
                        ...address,
                        addressMobileLine: value,
                      })
                    } else {
                      setAddress({
                        ...address,
                        AddressMobileLine: v.target.value,
                      })
                    }
                  }}
                />
                {validationBag?.AddressMobileLine?.isDirty && (
                  <div className="invalid-tooltip">
                    {validationBag?.AddressMobileLine?.message}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('internetAddress')}
          </label>
          {disableInputs ? (
            <input
              className="form-control"
              disabled
              defaultValue={address?.internet}
            />
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                <input
                  className={`form-control ${
                    validationBag?.AddressInternet?.isDirty && 'is-invalid'
                  }`}
                  defaultValue={
                    editMode ? address?.internet : address?.AddressInternet
                  }
                  onKeyDown={r => {
                    if (validationBag?.AddressInternet?.isDirty) {
                      setValidationBag(prevBag => ({
                        ...prevBag,
                        AddressInternet: {
                          ...prevBag.AddressInternet,
                          isDirty: false,
                        },
                      }))
                    }
                  }}
                  onChange={v => {
                    if (editMode) {
                      const value = v.target.value
                      setAddress({
                        ...address,
                        addressInternet: value,
                      })
                    } else {
                      setAddress({
                        ...address,
                        AddressInternet: v.target.value,
                      })
                    }
                  }}
                />
                {validationBag?.AddressInternet?.isDirty && (
                  <div className="invalid-tooltip">
                    {validationBag?.AddressInternet?.message}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('city')}</label>
          {disableInputs ? (
            <input
              className="form-control"
              disabled
              defaultValue={address?.city}
            />
          ) : (
            <div className="d-flex flex-column w-100">
              <input
                className="form-control"
                defaultValue={editMode ? address?.street : address?.AddressCity}
                onChange={v => {
                  if (editMode) {
                    const value = v.target.value
                    setAddress({
                      ...address,
                      addressCity: value,
                    })
                  } else {
                    setAddress({
                      ...address,
                      AddressCity: v.target.value,
                    })
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('postalCode')}
          </label>
          {disableInputs ? (
            <input
              disabled
              className="form-control"
              defaultValue={address?.postalCode}
            />
          ) : (
            <div className="d-flex flex-column w-100">
              <input
                className="form-control"
                defaultValue={
                  editMode ? address?.postalCode : address?.AddressPostalCode
                }
                onChange={v => {
                  if (editMode) {
                    const value = v.target.value
                    setAddress({
                      ...address,
                      addressPostalCode: value,
                    })
                  } else {
                    setAddress({
                      ...address,
                      AddressPostalCode: v.target.value,
                    })
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('emailAddress')}
          </label>
          {disableInputs ? (
            <input
              disabled
              className="form-control"
              defaultValue={address?.email}
            />
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                <input
                  className={`form-control ${
                    validationBag?.AddressEmail?.isDirty && 'is-invalid'
                  }`}
                  onKeyDown={r => {
                    if (validationBag?.AddressEmail?.isDirty) {
                      setValidationBag(prevBag => ({
                        ...prevBag,
                        AddressEmail: {
                          ...prevBag.AddressEmail,
                          isDirty: false,
                        },
                      }))
                    }
                  }}
                  defaultValue={
                    editMode ? address?.email : address?.AddressEmail
                  }
                  onChange={v => {
                    if (editMode) {
                      const value = v.target.value
                      setAddress({
                        ...address,
                        addressEmail: value,
                      })
                    } else {
                      setAddress({
                        ...address,
                        AddressEmail: v.target.value,
                      })
                    }
                  }}
                />
                {validationBag?.AddressEmail?.isDirty && (
                  <div className="invalid-tooltip">
                    {validationBag?.AddressEmail?.message}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label
            className="col-4 text-capitalize"
            style={{ wordBreak: 'break-word' }}
          >
            {localize('addressLocationLatitude')}
          </label>
          {disableInputs ? (
            <input
              type="number"
              disabled
              className="form-control"
              defaultValue={address?.locationLatitude}
            />
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                <input
                  type="number"
                  className={`form-control`}
                  defaultValue={
                    editMode
                      ? address?.locationLatitude
                      : address?.AddressLocationLatitude
                  }
                  onChange={v => {
                    if (editMode) {
                      const value = v.target.value
                      setAddress({
                        ...address,
                        addressLocationLatitude: value,
                      })
                    } else {
                      setAddress({
                        ...address,
                        AddressLocationLatitude: v.target.value,
                      })
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="col-4 d-flex align-items-center">
          <label
            className="col-4 text-capitalize"
            style={{ wordBreak: 'break-word' }}
          >
            {localize('addressLocationLongitude')}
          </label>
          {disableInputs ? (
            <input
              type="number"
              disabled
              className="form-control"
              defaultValue={address?.locationLongitude}
            />
          ) : (
            <>
              <div className="d-flex flex-column w-100">
                <input
                  type="number"
                  className={`form-control`}
                  defaultValue={
                    editMode
                      ? address?.locationLongitude
                      : address?.AddressLocationLongitude
                  }
                  onChange={v => {
                    if (editMode) {
                      const value = v.target.value
                      setAddress({
                        ...address,
                        addressLocationLongitude: value,
                      })
                    } else {
                      setAddress({
                        ...address,
                        AddressLocationLongitude: v.target.value,
                      })
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
