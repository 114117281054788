/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../../../Components/Date/DatePicker'
import Loader from '../../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import Pager from '../../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { getData } from '../../../../../../../Helpers/webApi'

const MorgueStatement = ({ mode, handleClose, morguePatient }) => {
  const [transactions, setTransactions] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    journalEntryFilter: 0,
    tallyDebitsCredits: true,
  })

  const trColumns = [
    { label: 'branch', template: r => <>{r?.branchDescription}</> },
    {
      label: 'transactionDate',
      template: r => <>{formatDate(r?.journalCreatedDate)}</>,
    },
    {
      label: 'primaryDescription',
      template: r => <>{r?.journalPrimaryDescription}</>,
    },
    { label: 'debit', template: r => <>{formatCurrency(r?.debit)}</> },
    { label: 'credit', template: r => <>{formatCurrency(r?.credit)}</> },
    {
      label: 'runningBalance',
      template: r => <>{formatCurrency(r?.runningBalance)}</>,
    },
    {
      label: 'secondaryDescription',
      template: r => <>{r?.journalSecondaryDescription}</>,
    },
    { label: 'reference', template: r => <>{r?.journalReference}</> },
    {
      label: 'fullAccountNumber',
      template: r => <>{r?.customerAccountNumber}</>,
    },
    {
      label: 'customerName',
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: 'accountNumber',
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: 'membershipNumber',
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: 'personalFileNumber',
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: 'transactionCode',
      template: r => <>{r?.journalTransactionCodeDescription}</>,
    },
    {
      label: 'valueDate',
      template: r => <>{formatDate(r?.journalValueDate, true)}</>,
    },
    {
      label: 'localCurrency',
      template: r => <>{r?.journalLocalCurrencyDescription}</>,
    },
    { label: 'appUserName', template: r => <>{r?.applicationUserName}</> },
  ]

  const fetchGeneralLedgerTransactions = async () => {
    if (!morguePatient?.bedRoomChartOfAccountId && !morguePatient?.id) return

    setIsBusy(true)

    const url =
      '/JournalEntry/find-general-ledger-transactions-by-reference-id-and-date-range-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        chartOfAccountId: morguePatient?.bedRoomChartOfAccountId,
        referenceId: morguePatient?.id,
      },
      false
    )

    if (success) {
      setTransactions(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchGeneralLedgerTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex, morguePatient?.id])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="morgueStatement"
      disableCreate
      handleClose={handleClose}
      modalClassName="modal-xl"
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3 gap-1">
            <div className="d-flex align-items-center">
              <label
                style={{
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                className="text-capitalize"
              >
                {localize('recordsPerPage')}
              </label>
              <select
                className="form-select ms-1"
                style={{ maxWidth: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageSize: e.target.value,
                    pageIndex: 0,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('tallyDebitsCredits')}
              </label>
              <Checkbox
                checked={reqParams?.tallyDebitsCredits}
                onCheck={value =>
                  setReqParams({
                    ...reqParams,
                    tallyDebitsCredits: value,
                    pageIndex: 0,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center">
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('dateRange')}
              </label>
              <DatePicker
                value={{
                  startDate: reqParams?.startDate,
                  endDate: reqParams?.endDate,
                }}
                onSelect={v => {
                  setReqParams({
                    ...reqParams,
                    customDate: true,
                    startDate: v.startDate,
                    endDate: v.endDate,
                    pageIndex: 0,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center gap-1"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-1"
              >
                {localize('search')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                value={reqParams?.journalEntryFilter}
                enumsKey="JournalEntryFilter"
                onChange={({ value }) =>
                  setReqParams({
                    ...reqParams,
                    journalEntryFilter: value,
                    pageIndex: 0,
                  })
                }
              />
              <input
                type="text"
                className="form-control"
                value={reqParams?.text}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    text: e.target.value,
                    pageIndex: 0,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    fetchGeneralLedgerTransactions()
                  }
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  fetchGeneralLedgerTransactions()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>

          <SimpleTable
            columns={trColumns}
            data={transactions?.pageCollection}
          />
          <Pager
            itemsCount={transactions?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={5}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />

          <div className="bg-light rounded p-2">
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-light px-2  row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('accountSummary')}
              </div>

              <div className="row">
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-3">
                    {localize('balanceB/F')}
                  </label>
                  <input
                    type="text"
                    className="form-control text-end"
                    value={formatCurrency(
                      transactions?.balanceBroughtFoward,
                      null,
                      null,
                      false
                    )}
                    disabled
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-3">
                    {localize('debits')}
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control text-end"
                    value={formatCurrency(
                      transactions?.totalDebits,
                      null,
                      null,
                      false
                    )}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-3">
                    {localize('credits')}
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control text-end"
                    value={formatCurrency(
                      transactions?.totalCredits,
                      null,
                      null,
                      false
                    )}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label className="text-capitalize col-3">
                    {localize('balanceC/F')}
                  </label>
                  <input
                    type="text"
                    disabled
                    className="form-control text-end"
                    value={formatCurrency(
                      transactions?.balanceCarriedForward,
                      null,
                      null,
                      false
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default MorgueStatement
