/** @format */

import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const ViewPurchaseRequisitionApprovals = ({ entries }) => {
  const columns = [
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('decision'), template: r => <>{r.decision}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('usedBiometrics'),
      template: r => (
        <span className="text-capitalize">{r?.usedBiometrics?.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, true)}</>,
    },
  ]

  return (
    <>
      <SimpleTable data={entries} columns={columns} />
    </>
  )
}
