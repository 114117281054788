/** @format */
import BranchLookup from '../../../../../../Components/Lookup/BranchLookup'
import localize from '../../../../../../Global/Localize'
import { months, QueuePriority } from '../../../../../../Global/enumeration'
import { putData } from '../../../../../../Helpers/webApi'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const CreateRefundBatchModal = ({
  validationBag,
  clearValidation,
  finalRefundBatchModel,
  setFinalRefundBatchModal,
}) => {
  const handleOnChangeValueDate = async e => {
    const { success, data } = await putData(
      `/PostingPeriod/evaluate-value-date-validity/?valueDate=${e}`,
      {},
      false
    )
    if (success) {
      setFinalRefundBatchModal({
        ...finalRefundBatchModel,
        valueDate: e,
      })
    } else {
      setFinalRefundBatchModal({
        ...finalRefundBatchModel,
        valueDate: null,
      })
    }
  }
  return (
    <>
      <div className="row mb-3">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.BranchId?.message}
        >
          <label className="col-3 text-capitalize">{localize('branch')}</label>
          <BranchLookup
            displayValue={finalRefundBatchModel?.branchDescription}
            onSelect={r => {
              clearValidation('BranchId')
              setFinalRefundBatchModal({
                ...finalRefundBatchModel,
                branchId: r.id,
                branchDescription: r.description,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('referenceMonth')}
          </label>          
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Month"
            value={finalRefundBatchModel?.month}
            onChange={e => {
              setFinalRefundBatchModal({
                ...finalRefundBatchModel,
                month: e.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('valueDate')}
          </label>
          <CustomDatePicker
            name={'date-picker-value-date'}
            onDateChange={handleOnChangeValueDate}
            defaultDate={finalRefundBatchModel?.valueDate}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postingPeriod')}
          </label>
          <PostingPeriodLookup
            displayValue={finalRefundBatchModel?.postingPeriodDescription}
            onSelect={r => {
              setFinalRefundBatchModal({
                ...finalRefundBatchModel,
                postingPeriodId: r.id,
                postingPeriodDescription: r.description,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('priority')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="QueuePriority"
            value={finalRefundBatchModel?.priority}
            onChange={e => {
              setFinalRefundBatchModal({
                ...finalRefundBatchModel,
                priority: e.value,
              })
            }}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.Reference?.message}
        >
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            value={finalRefundBatchModel?.reference}
            className="form-control"
            onChange={e => {
              clearValidation('Reference')
              setFinalRefundBatchModal({
                ...finalRefundBatchModel,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
