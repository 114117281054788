/** @format */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import { MaskPercentage } from '../../../../Components/InputMasks/MaskPercentage'
import Loader from '../../../../Components/Loaders/Loader'
import CustomersLookup from '../../../../Components/Lookup/CustomersLookup'
import { FixedDepositTypeLookup } from '../../../../Components/Lookup/FixedDepositTypeLookup'
import FixedDepositTypeTiersLookup from '../../../../Components/Lookup/FixedDepositTypeTiersLookup'
import CustomerAccountPartial from '../../../../Components/ReUsables/CustomerAccount/CustomerAccountPartial'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import {
  fixedDepositCategory,
  fixedDepositMaturityAction,
} from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatCurrency } from '../../../../Helpers/formatters'
import { getData, postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const FixedDepositsFixing = () => {
  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r?.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r?.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r?.bookBalance)}</>,
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r?.principalBalance)}</>,
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r?.interestBalance)}</>,
    },
  ]
  const [isBusy, setIsBusy] = useState(false)
  const [iFixedDepositFixing, setIFixedDepositFixing] = useState({
    category: Object.keys(fixedDepositCategory)[0],
    maturityAction: Object.keys(fixedDepositMaturityAction)[0],
    value: 0,
    rate: 0,
    term: 0,
  })

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedSavingsAccount, setSelectedSavingsAccount] = useState(null)
  const [recruiterName, setRecruiterName] = useState(null)
  const [tableData, setTableData] = useState([])
  const [selectedEntries, setSelectedEntries] = useState([])
  const [tabWaiting, setTabWaiting] = useState(false)
  const [enforceFixedDepositBands, setEnforceFixedDepositBands] =
    useState(false)
  const [iFixedDepositTypeGraduatedScale, setIFixedDepositTypeGraduatedScale] =
    useState(null)
  const [fixedDepositTypeDescription, setFixedDepositTypeDescription] =
    useState(null)
  const [fixedDepositTypeId, setFixedDepositTypeId] = useState(null)
  const [extectedInterest, setExpectedInterest] = useState(0)
  const [showExpectedInterest, setShowExpectedInterest] = useState(false)

  const loadFixedDepositPayablesData = async r => {
    setTabWaiting(true)
    const { success, data } = await getData(
      `/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes?customerId=${
        r?.customerId
      }&includeBalances=${true}&includeProductDescription=${true}`,
      {},
      false
    )
    if (success) {
      setTableData(
        data.customerLoanAccounts.filter(item => item.bookBalance < 0) || []
      )
    }
    setTabWaiting(false)
  }

  const loadUserBranchData = async r => {
    const { success, data } = await getData(
      `/Branch/find-by-id?branchId=${r?.loggedInUserBranchId}`,
      {},
      false
    )
    if (success) {
      setEnforceFixedDepositBands(data.companyEnforceFixedDepositBands)
    }
  }

  const handleOnSelectSavingsAccount = async account => {
    if (account.status === '0' && account.recordStatus === '2') {
      if (account.customerAccountTypeProductCode !== '1') {
        return showNotification(
          'Fixed Deposit Fixing',
          'info',
          'Select a Savings Account'
        )
      } else {
        setSelectedSavingsAccount({
          ...selectedSavingsAccount,
          ...account,
        })
      }
    } else {
      showNotification(
        'Fixed Deposit Fixing',
        'info',
        'Sorry, but you must first select an approved account whose status is active'
      )
      setSelectedSavingsAccount(null)
    }
  }

  async function updateFixedDepositFixing() {
    setIsBusy(true)
    if (iFixedDepositFixing?.category === '1') {
      iFixedDepositFixing.term = 10
    }
    const { success } = await postData(
      '/FixedDeposit/invoke-fixed-deposit',
      {
        fixedDeposit: {
          customerAccountId: selectedSavingsAccount.id,
          ...iFixedDepositFixing,
        },
        fixedDepositPayables: selectedEntries.map(entry => entry),
        fixedDepositTypeGraduatedScale: iFixedDepositTypeGraduatedScale,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setIFixedDepositFixing({ category: Object.keys(fixedDepositCategory)[0] })
      setSelectedSavingsAccount(null)
      setGlobalValidationErrors({})
      showNotification('Fixed Deposit Fixing', 'success')
    } else {
      setIsBusy(false)
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    getExpectedInterest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    iFixedDepositFixing?.value,
    iFixedDepositFixing?.rate,
    iFixedDepositFixing?.term,
  ])

  const getExpectedInterest = async (value, apr, term) => {
    setExpectedInterest(0)
    setShowExpectedInterest(true)
    const { success, data } = await getData(
      `/FixedDeposit/get-fixed-deposit-expected-interest?value=${value}&annualPercentageRate=${apr}&termInMonths=${term}`,
      {},
      false
    )
    if (success) {
      setExpectedInterest(data.result)
      setShowExpectedInterest(false)
    }
    setShowExpectedInterest(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('fixedDepositFixing'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <CustomerAccountPartial
                  selectedAccount={selectedSavingsAccount}
                  onCustomerAccountLookup={r => {
                    handleOnSelectSavingsAccount(r)
                    loadFixedDepositPayablesData(r)
                    loadUserBranchData(r)
                  }}
                  productCode={1}
                  pageScreen={'fd'}
                />

                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('category')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      value={iFixedDepositFixing?.category}
                      enumsKey={'FixedDepositCategory'}
                      onChange={e => {
                        setIFixedDepositFixing({
                          ...iFixedDepositFixing,
                          category: e.value,
                        })
                      }}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['FixedDeposit.Value']?.message
                    }
                  >
                    <label className="col-4 text-capitalize">
                      {localize('value')}
                    </label>
                    <MaskNumber
                      className="form-control ms-2"
                      defaultMaskValue={iFixedDepositFixing?.value || 0}
                      onMaskNumber={e => {
                        clearValidation('FixedDeposit.Value')
                        setIFixedDepositFixing({
                          ...iFixedDepositFixing,
                          value: e.target.value,
                        })
                      }}
                      onBlur={() => {
                        getExpectedInterest(
                          iFixedDepositFixing?.value,
                          iFixedDepositFixing?.rate,
                          iFixedDepositFixing?.term
                        )
                      }}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['FixedDeposit.Rate']?.message
                    }
                  >
                    <label className="col-4 text-capitalize me-2">
                      {localize('annualPercentageRate')}
                    </label>
                    <MaskPercentage
                      defaultMaskValue={iFixedDepositFixing?.rate || 0}
                      onMaskChange={e => {
                        clearValidation('FixedDeposit.Rate')
                        setIFixedDepositFixing({
                          ...iFixedDepositFixing,
                          rate: e.target.value,
                        })
                      }}
                      onBlur={() => {
                        getExpectedInterest(
                          iFixedDepositFixing?.value,
                          iFixedDepositFixing?.rate,
                          iFixedDepositFixing?.term
                        )
                      }}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['FixedDeposit.Remarks']?.message
                    }
                  >
                    <label className="col-4 text-capitalize">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      value={iFixedDepositFixing?.remarks || ''}
                      onChange={e => {
                        clearValidation('FixedDeposit.Remarks')
                        setIFixedDepositFixing({
                          ...iFixedDepositFixing,
                          remarks: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div
                    className="col-3 d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['FixedDeposit.FixedDepositTypeId']
                        ?.message
                    }
                  >
                    <label className="col-4 text-capitalize">
                      {localize('fixedDepositType')}
                    </label>

                    <div
                      style={{
                        width: '100%',
                        opacity: selectedSavingsAccount?.id ? 1 : 0.6,
                        pointerEvents: selectedSavingsAccount?.id
                          ? 'all'
                          : 'none',
                      }}
                    >
                      <FixedDepositTypeLookup
                        displayValue={
                          iFixedDepositFixing?.fixedDepositTypeDescription
                        }
                        onSelect={r => {
                          setIFixedDepositTypeGraduatedScale(null)
                          setExpectedInterest(0)
                          if (r.isLocked) {
                            showNotification(
                              'Fixed Deposit Fixing',
                              'info',
                              'sorry, but the selected type is locked'
                            )
                          } else {
                            clearValidation('FixedDeposit.FixedDepositTypeId')
                            setIFixedDepositFixing({
                              ...iFixedDepositFixing,
                              fixedDepositTypeId: r?.id,
                              fixedDepositTypeDescription: r?.description,
                              term: 0,
                              rate: 0,
                            })
                            setFixedDepositTypeId(r?.id)
                            setFixedDepositTypeDescription(r?.description)
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="text-capitalize col-4 me-2">
                      {localize('fixedDepositTypeTiers')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity:
                          enforceFixedDepositBands &&
                          iFixedDepositFixing?.fixedDepositTypeId
                            ? 1
                            : 0.6,
                        pointerEvents:
                          enforceFixedDepositBands &&
                          iFixedDepositFixing?.fixedDepositTypeId
                            ? 'all'
                            : 'none',
                      }}
                    >
                      <FixedDepositTypeTiersLookup
                        displayValue={
                          iFixedDepositTypeGraduatedScale?.fixedDepositTypeDescription
                        }
                        onSelect={v => {
                          setIFixedDepositTypeGraduatedScale(prev => ({
                            ...prev,
                            fixedDepositTypeDescription:
                              v.fixedDepositTypeDescription,
                            fixedDepositTypeId: v.fixedDepositTypeId,
                            fixedDepositTypeMonths: v.fixedDepositTypeMonths,
                            percentage: v.percentage,
                            rangeLowerLimit: v.rangeLowerLimit,
                            rangeUpperLimit: v.rangeUpperLimit,
                          }))
                          setIFixedDepositFixing({
                            ...iFixedDepositFixing,
                            term: v.fixedDepositTypeMonths,
                            rate: v.percentage,
                          })
                          getExpectedInterest(
                            iFixedDepositFixing?.value,
                            iFixedDepositFixing?.rate,
                            iFixedDepositFixing?.term
                          )
                        }}
                        fixedDepositTypeId={fixedDepositTypeId}
                        fixedDepositTypeDescription={
                          fixedDepositTypeDescription
                        }
                      />
                    </div>
                  </div>

                  {iFixedDepositFixing?.category === '0' ||
                  iFixedDepositFixing?.category === '2' ? (
                    <div
                      className="col d-flex align-items-center with-validation"
                      validation-message={
                        globalValidationErrors['FixedDeposit.Term']?.message
                      }
                    >
                      <label className="col-4 text-capitalize">
                        {localize('term(Months)')}
                      </label>
                      <MaskNumber
                        className="form-control ms-2"
                        defaultMaskValue={iFixedDepositFixing?.term || 0}
                        onMaskNumber={e => {
                          clearValidation('FixedDeposit.Term')
                          setIFixedDepositFixing({
                            ...iFixedDepositFixing,
                            term: e.target.value,
                          })
                        }}
                        onBlur={() => {
                          getExpectedInterest(
                            iFixedDepositFixing?.value,
                            iFixedDepositFixing?.rate,
                            iFixedDepositFixing?.term
                          )
                        }}
                        numeralThousandsGroupStyle="none"
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {iFixedDepositFixing?.category === '0' ? (
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize me-2">
                        {localize('maturityAction')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{
                          width: '100%',
                        }}
                        value={iFixedDepositFixing?.maturityAction}
                        enumsKey={'FixedDepositMaturityAction'}
                        onChange={e => {
                          setIFixedDepositFixing({
                            ...iFixedDepositFixing,
                            maturityAction: e.value,
                          })
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="row mb-2">
                  <div className="col-3 d-flex align-items-center ">
                    <label className="col-4 text-capitalize">
                      {localize('recruitedBy')}
                    </label>
                    <CustomersLookup
                      displayValue={recruiterName?.custName}
                      onSelect={r => {
                        setIFixedDepositFixing({
                          ...iFixedDepositFixing,
                          recruitedBy: r?.paddedSerialNumber,
                        })
                        setRecruiterName({ custName: r?.fullName })
                      }}
                    />
                  </div>
                  <div className="col-3 d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('expectedInterest')}
                    </label>
                    <input
                      style={{ textAlign: 'end' }}
                      type="text"
                      className="form-control ms-2"
                      value={formatCurrency(extectedInterest, 2, 2, false)}
                      disabled
                    />
                  </div>

                  {showExpectedInterest ? (
                    <div className="spinner-border m-2" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('fixedDepositPayables'),
                        item: (
                          <>
                            {tabWaiting ? (
                              <Loader />
                            ) : (
                              <div className="row col-12 mt-2">
                                <SimpleTable
                                  data={tableData}
                                  columns={columns}
                                />
                              </div>
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <button
                      onClick={() => {
                        if (!selectedSavingsAccount) {
                          return Swal.fire({
                            icon: 'info',
                            text: 'Select Customer Source Account First',
                            title: 'Ooops!',
                            showCloseButton: true,
                          })
                        } else {
                          updateFixedDepositFixing()
                        }
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default FixedDepositsFixing
