/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import BranchLookup from '../../../../../Components/Lookup/BranchLookup'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Loader from '../../../../../Components/Loaders/Loader'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Pager from '../../../../../Components/Tables/Pager'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Swal from 'sweetalert2'

const CashRequisitions = () => {
  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
              //onSelectItem([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r.id))
              //onSelectItem(selectedEntries.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('VoucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('payee'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('narration'),
      template: r => <>{r.secondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    {
      label: localize('paidBy'),
      template: r => <>{r.paidBy}</>,
    },
    {
      label: localize('paidDate'),
      template: r => <>{formatDate(r.paidDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    pettyCashierId: null,
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
    status: 1,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [selectedCashRequisition, setSelectedCashRequisition] = useState(null)
  const [search, setSearch] = useState('')
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedEntries, setSelectedEntries] = useState([])
  const [toggleSelection, setToggleSelection] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, reqParams.pettyCashierId])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PettyCashRequisition/find-petty-cash-requisitions-by-petty-cashier-id-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const getPettyCashierByBranchId = async r => {
    const { success, data } = await getData(
      `/PettyCashier/find-petty-cashier-by-branch-id?branchId=${
        r.id
      }&includeBalance=${true}`,
      false
    )
    if (success) {
      setReqParams({ ...reqParams, pettyCashierId: data.id })
    }
  }

  const onCheckEntries = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedEntries([])
      //setSelectedEntries([...entries])
    } else {
      setSelectedEntries([])
      setToggleSelection(checkStatus)
    }
  }

  const authorizeEntries = async () => {
    if (selectedEntries.length === 0) {
      Swal.fire({
        icon: 'info',
        text: 'sorry, but you must first select a pending petty cash requisition(s)',
        title: 'Ooops!',
        showCloseButton: true,
      })
      return
    }
    const { success } = await postData(
      '/PettyCashRequisition/authorize-petty-cash-requisitions',
      {
        pettyCashRequisitions: selectedEntries.map(entry => entry),
        pettyCashTransactionAuthOption: 1,
        authorizationRemarks: 'Authorized',
      },
      false,
      `Operation Completed Successfully`
    )
    if (success) {
      loadTableData()
      setSelectedEntries([])
    }
  }

  const rejectEntries = async () => {
    if (selectedEntries.length === 0) {
      Swal.fire({
        icon: 'info',
        text: 'sorry, but you must first select a pending petty cash requisition(s)',
        title: 'Ooops!',
        showCloseButton: true,
      })
      return
    }
    const { success } = await postData(
      '/PettyCashRequisition/authorize-petty-cash-requisitions',
      {
        pettyCashRequisitions: selectedEntries.map(entry => entry),
        pettyCashTransactionAuthOption: 2,
        authorizationRemarks: 'Rejected',
      },
      true,
      `Operation Completed Successfully`
    )
    if (success) {
      loadTableData()
      setSelectedEntries([])
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('pettyCashRequisitions'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col me-2">
                      <div className="d-flex align-items-center">
                        <label
                          className="text-capitalize col-md-1"
                          htmlFor="searchByBranchId"
                        >
                          {localize('branch')}
                        </label>
                        <BranchLookup
                          displayValue={
                            selectedCashRequisition?.pettyCashierBranchDescription
                          }
                          onSelect={r => {
                            setSelectedCashRequisition({
                              pettyCashierBranchDescription: r.description,
                            })
                            getPettyCashierByBranchId(r)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-1"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <div className="d-flex align-items-center col-2">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        value={reqParams?.status}
                        enumsKey={'PettyCashRequisitionAuthStatus'}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            status: e.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center  me-4">
                        <label
                          className="text-capitalize col-4"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-4 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label
                        className="ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="delinquencyStatusQueryFilterButton"
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>

                  <div className="row col-12 mt-2">
                    <SimpleTable data={tableData} columns={columns} />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                  <div className="row mb-1 g-3 mt-2">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-2">
                        <Checkbox
                          id="toggle-selection"
                          checked={toggleSelection}
                          onCheck={v => {
                            onCheckEntries(v)
                          }}
                        />
                        <label
                          htmlFor="toggle-selection"
                          className="text-capitalize ms-2"
                        >
                          {localize('toggleSelection')}
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="d-flex justify-content-end align-items-end">
                        <button
                          onClick={rejectEntries}
                          className="btn btn-success text-uppercase ms-4 px-4"
                        >
                          {localize('reject')}
                        </button>
                        <button
                          onClick={authorizeEntries}
                          className="btn btn-success text-uppercase ms-4 px-4"
                        >
                          {localize('authorize')}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CashRequisitions
