/** @format */
import localize from '../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import React, { useState } from 'react'
import { MaskPercentage } from '../../../../../Components/InputMasks/MaskPercentage'
import { RiskClassificationSchedules } from './RiskClassificationSchedules'
import { postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import { LoanClassification } from '../../../../../Helpers/constants'
import { generateGuid } from '../../../../../Helpers/extensions'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'

export const AddRiskClassificationSchedule = ({
  setRiskClassificationSchedules,
  riskClassificationSchedules,
  mode,
  waitingMetadata,
}) => {
  const [riskClassificationScheduleModel, setRiskClassificationScheduleModel] =
    useState({ classification: LoanClassification.PERFORMING })
  const [isBusy, setIsBusy] = useState(false)

  const addRiskClassificationSchedule = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/RiskClassificationMode/add-risk-classification-mode-schedule-setup',
      {
        RiskClassificationSchedule: { ...riskClassificationScheduleModel },
        RiskClassificationSchedules: riskClassificationSchedules || [],
      },
      false,
    )
    if (success) {
      setRiskClassificationSchedules([
        ...riskClassificationSchedules,
        { ...data?.riskClassificationSchedule, id: generateGuid() },
      ])
      setRiskClassificationScheduleModel({
        classification: LoanClassification.PERFORMING,
      })
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('scheduleDetails')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-2 text-capitalize">
              {localize('daysPastDueLowerLimit')}
            </label>
            <MaskNumber
              defaultMaskValue={
                riskClassificationScheduleModel?.daysPastDueLowerLimit || ''
              }
              numeralThousandsGroupStyle="none"
              onMaskNumber={e => {
                setRiskClassificationScheduleModel({
                  ...riskClassificationScheduleModel,
                  daysPastDueLowerLimit: e.target.value,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-2">
              {localize('classification')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="LoanClassification"
              value={riskClassificationScheduleModel?.classification}
              onChange={e =>
                setRiskClassificationScheduleModel({
                  ...riskClassificationScheduleModel,
                  classification: e.value,
                })
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-2">
              {localize('provisioning')}
            </label>
            <MaskPercentage
              numeralThousandsGroupStyle="none"
              onMaskChange={e => {
                setRiskClassificationScheduleModel({
                  ...riskClassificationScheduleModel,
                  provisioning: e.target.value,
                })
              }}
              defaultMaskValue={
                riskClassificationScheduleModel?.provisioning || ''
              }
            />
          </div>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div
              className="bg-light d-flex justify-content-end align-items-center p-2"
              style={{ borderRadius: 5 }}
            >
              <button
                onClick={addRiskClassificationSchedule}
                className="btn btn-success text-uppercase ms-3 px-4"
              >
                {localize('add')}
              </button>
            </div>
          </>
        )}

        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-1 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 200,
              marginBottom: -10,
            }}
          >
            {localize('riskClassificationSchedules')}
          </div>
          <RiskClassificationSchedules
            mode={mode}
            waitingMetadata={waitingMetadata}
            riskClassificationSchedules={riskClassificationSchedules}
            setRiskClassificationSchedules={setRiskClassificationSchedules}
          />
        </div>
      </div>
    </>
  )
}
