/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../ModalTemplate/ModalTemplate'
import { useAuth } from 'react-oidc-context'

const UserProfileModal = ({ handleClose, handleAction, mode }) => {
  const auth = useAuth()

  return (
    <ModalTemplate
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText="Ok"
      modalTitle="Profile"
    >
      <h3 style={{ margin: 0 }}>My Account</h3>
      <div className="d-flex align-items-center mt-2">
        <div>
          <i className="uil uil-user-circle" style={{ fontSize: '48px' }}></i>
        </div>
        <div className="px-2">
          <h5>Name</h5>
          <p style={{ margin: 0 }}>
            {auth?.user?.profile?.cbs_RoleDescription}
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h4>Branch Details</h4>
        <p className="mb-2">
          {auth?.user?.profile?.cbs_EmployeeBranchDescription}
        </p>
        <p className="mb-2">
          {auth?.user?.profile?.cbs_EmployeeDepartmentDescription}
        </p>
      </div>
    </ModalTemplate>
  )
}

export default UserProfileModal
