/* @format */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import { MaskPercentage } from '../../../../Components/InputMasks/MaskPercentage'
import Loader from '../../../../Components/Loaders/Loader'
import Commissions from '../../../../Components/Selectables/Plain/Commissions'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import {
  entitiesToBeChecked,
  getCommissions,
} from '../../../../Helpers/selectablesHelper'
import { getData, putData } from '../../../../Helpers/webApi'

function ChargeDeterminationWellKnownCharges() {
  const [commissions, setCommissions] = useState([])
  const [loadingCommissions, setLoadingCommissions] = useState(false)
  const [selectedChargeType, setSelectedChargeType] = useState('1')
  const [selectedTransactionType, setSelectedTransactionType] =
    useState('61682')
  const [chargeModel, setChargeModel] = useState({
    type: '',
    percentage: '',
    fixedAmount: '',
  })
  const [modalWaiting, setModalWaiting] = useState(false)
  const [selectedCommissions, setSelectedCommissions] = useState([])
  const [labels, setLabels] = useState({
    labelOne: 'employersContribution',
    labelTwo: 'contributionValues',
  })
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit(finalData) {
    setIsBusy(true)
    setModalWaiting(true)
    const { success, data } = await putData(
      '/Commission/map-system-transaction-type-to-commissions',
      finalData,
      false
    )

    if (success) {
      setIsBusy(false)
      setModalWaiting(false)
      showNotification('Well Known Charges', 'success')
    }
    setIsBusy(false)
    setModalWaiting(false)
  }

  const specialTransactionTypes = ['61682', '61684', '61683', '61706']

  useEffect(() => {
    retrieveApplicableCharges(selectedTransactionType)
  }, [])

  const getLabels = transactionType => {
    switch (transactionType) {
      case '61682':
        setLabels({
          labelOne: 'employersContribution',
          labelTwo: 'contributionValues',
        })
        break
      case '61684':
        setLabels({
          labelOne: 'employersContribution',
          labelTwo: 'contributionValues',
        })
        break
      case '61683':
        setLabels({
          labelOne: 'nhifRelief',
          labelTwo: 'reliefValue',
        })
        break
      case '61706':
        setLabels({
          labelOne: 'marketInterestRate',
          labelTwo: 'interestRateValue',
        })
        break
      default:
        return {}
    }
  }

  const retrieveApplicableCharges = async transactionType => {
    setIsBusy(true)
    let predefinedCharges = {}
    setLoadingCommissions(true)
    const { success, data } = await getData(
      '/Commission/get-commissions-for-system-transaction-type',
      { systemTransactionType: transactionType },
      false
    )
    const commissions = await getCommissions()
    if (success) {
      setIsBusy(false)
      data.result.forEach((val, idx) => {
        if (specialTransactionTypes.includes(val.systemTransactionType)) {
          predefinedCharges.type = val.complementType
          predefinedCharges.percentage = val.complementPercentage
          predefinedCharges.fixedAmount = val.complementFixedAmount
        }
      })
      setChargeModel(predefinedCharges)
      setCommissions(entitiesToBeChecked(commissions, data.result))
    } else {
      setIsBusy(false)
    }
    setLoadingCommissions(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('chargesDetermination'),
          localize('wellKnownCharges'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-auto me-2 text-capitalize">
                        {localize('transactionType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="SystemTransactionType"
                        value={selectedTransactionType}
                        onChange={event => {
                          setSelectedTransactionType(event.value)
                          retrieveApplicableCharges(event.value)
                          getLabels(event.value)
                        }}
                      />
                    </div>
                  </div>
                  {specialTransactionTypes.includes(
                    selectedTransactionType
                  ) && (
                    <div className="row mb-3">
                      <div className="col d-flex align-items-center">
                        <label className="col-auto me-2 text-capitalize">
                          {localize(labels?.labelOne)}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="ChargeType"
                          value={chargeModel?.type}
                          onChange={e => {
                            setChargeModel({
                              ...chargeModel,
                              type: e.value,
                            })
                            setSelectedChargeType(e.value)
                          }}
                        />
                      </div>
                      {selectedChargeType === '1' ? (
                        <>
                          <div className="col d-flex align-items-center">
                            <label className="col-auto me-2 text-capitalize">
                              {localize(labels?.labelTwo)}
                            </label>
                            <div className="input-group">
                              <MaskPercentage
                                value={
                                  chargeModel?.percentage
                                    ? chargeModel?.percentage
                                    : ''
                                }
                                onMaskChange={e => {
                                  setChargeModel({
                                    ...chargeModel,
                                    percentage: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col d-flex align-items-center">
                            <label className="col-auto me-2 text-capitalize">
                              {localize(labels?.labelTwo)}
                            </label>
                            <div className="input-group">
                              <MaskNumber
                                defaultMaskValue={chargeModel?.fixedAmount || 0}
                                className="form-control"
                                onMaskNumber={c => {
                                  setChargeModel({
                                    ...chargeModel,
                                    fixedAmount: c.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  <div className="col-md-12">
                    <Tab
                      tabItems={[
                        {
                          label: localize('applicableCharges'),
                          item: (
                            <Commissions
                              onCheckCommissions={c => {
                                setSelectedCommissions(c.filter(x => x.checked))
                                setCommissions(c)
                              }}
                              prefix={'create-commissions'}
                              isBusy={loadingCommissions}
                              commissionsToBeSelected={commissions}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      onClick={async () => {
                        await handleSubmit({
                          systemTransactionType: selectedTransactionType,
                          commissions: selectedCommissions,
                          charges: chargeModel,
                        })
                      }}
                      className={`btn btn-success text-uppercase px-4 ${
                        modalWaiting ? 'disabled' : ''
                      }`}
                      type="button"
                    >
                      {modalWaiting ? (
                        <i className="m-1 spinner-border spinner-sm text-light"></i>
                      ) : (
                        localize('update')
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChargeDeterminationWellKnownCharges
