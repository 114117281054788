/** @format */

/* eslint-disable */
import React, { useEffect, useReducer, useState } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import Pager from '../../../Components/Tables/Pager'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import {
  getCommissions,
  getDirectDebits,
  getInvestmentProducts,
  getLoanProducts,
  getSavingsProduct,
} from '../../../Helpers/selectablesHelper'
import CreditTypesModal from './Partials/CreditTypesModal'
import { CreditTypesTabContainers } from './Partials/Tabs/CreditTypesTabContainers'
import {
  getCommissionsToBeSelected,
  getCreditTypeAttachedInvestmentProductsToBeSelected,
  getCreditTypeAttachedLoanProductsToBeSelected,
  getCreditTypeAttachedSavingsProductsToBeSelected,
  getCreditTypeBenefactorInvestmentProducts,
  getCreditTypeConcessionExemptionInvestmentProducts,
  getCreditTypeConcessionExemptionLoanProducts,
  getCreditTypeConcessionExemptionSavingsProducts,
  getDirectDebitsToBeSelected,
} from './Reducers/action'
import { creditTypeReducer } from './Reducers/reducer'

function CreditTypes() {
  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRuleDescription'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('chartOfAccountCurrencyDescription'),
      template: r => <>{r?.chartOfAccountCurrencyDescription}</>,
    },
    {
      label: localize('revenueTax'),
      template: r => <>{r?.revenueTaxDescription}</>,
    },
    {
      label: localize('revenueTaxRate'),
      template: r => <>{r?.revenueTaxTaxRate}</>,
    },
    {
      label: localize('payrollNumber'),
      template: r => <>{r?.payrollNumberTypeDescription}</>,
    },
    {
      label: localize('transactionOwnership'),
      template: r => <>{r?.transactionOwnershipDescription}</>,
    },
    {
      label: localize('batchType'),
      template: r => <>{r?.batchTypeDescription}</>,
    },
    {
      label: localize('batchFileType'),
      template: r => <>{r?.batchFileTypeDescription}</>,
    },
    {
      label: localize('unallocatedFundsProductName'),
      template: r => (
        <>{r?.unallocatedFundsCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('roundingType'),
      template: r => <>{r?.roundingTypeDescription}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)

  const initialState = {
    creditTypeCommissions: [],
    creditTypeDirectDebits: [],
    creditTypeInvestmentProducts: [],
    creditTypeSavingsProducts: [],
    creditTypeLoanProducts: [],
    creditTypeConcessionExemptionInvestmentProducts: [],
    creditTypeConcessionExemptionSavingsProducts: [],
    creditTypeConcessionExemptionLoanProducts: [],
    creditTypeBenefactorInvestmentProducts: [],
    creditTypeFlags: [],
    loading: false,
  }

  const [creditTypeState, dispatch] = useReducer(
    creditTypeReducer,
    initialState
  )
  const [navigatedTab, setNavigatedTab] = useState(0)
  const [selectedCreditType, setSelectedCreditType] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [navigatedTabs, setNavigatedTabs] = useState([])
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  const [clickedOnce, setClickedOnce] = useState(false)

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditType/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }

    setIsBusy(false)
  }

  const checkIfTabNavigated = item => {
    if (!navigatedTabs.includes(item)) {
      setNavigatedTabs(prevTabs => [...prevTabs, item])
    }
  }

  const prepareCreditTypeForEditing = async creditTypeBeingEdited => {
    setMode('edit')
    setClickedOnce(false)
    setSelectedCreditType(creditTypeBeingEdited)
    setNavigatedTabs(prevTabs => [...prevTabs, 0])
    setSelectedRow(creditTypeBeingEdited)
    dispatch({
      type: ACTIONS.LOADING,
      payload: true,
    })

    dispatch({
      type: ACTIONS.GET_CREDIT_TYPE_COMMISSIONS,
      payload: await getCommissionsToBeSelected(creditTypeBeingEdited),
    })

    dispatch({
      type: ACTIONS.LOADING,
      payload: false,
    })
  }

  const getCreditTypeMetaData = async (navigatedTab, selectedRow) => {
    if (mode === 'edit') {
      checkIfTabNavigated(navigatedTab)
      switch (navigatedTab) {
        case 0:
          if (!navigatedTabs.includes(0)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })

            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_COMMISSIONS,
              payload: await getCommissionsToBeSelected(selectedRow),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }
          break
        case 1:
          if (!navigatedTabs.includes(1)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })

            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_DIRECT_DEBITS,
              payload: await getDirectDebitsToBeSelected(selectedRow),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }
          break
        case 2:
          if (!navigatedTabs.includes(2)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_LOAN_PRODUCTS,
              payload: await getCreditTypeAttachedLoanProductsToBeSelected(
                selectedRow
              ),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }

          break
        case 3:
          if (!navigatedTabs.includes(3)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_LOAN_PRODUCTS,
              payload: await getCreditTypeConcessionExemptionLoanProducts(
                selectedRow
              ),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }

          break
        case 4:
          if (!navigatedTabs.includes(4)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_INVESTMENT_PRODUCTS,
              payload:
                await getCreditTypeAttachedInvestmentProductsToBeSelected(
                  selectedRow
                ),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }
          break
        case 5:
          if (!navigatedTabs.includes(5)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_INVESTMENT_PRODUCTS,
              payload: await getCreditTypeConcessionExemptionInvestmentProducts(
                selectedRow
              ),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }

          break
        case 6:
          if (!navigatedTabs.includes(6)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_SAVINGS_PRODUCTS,
              payload: await getCreditTypeAttachedSavingsProductsToBeSelected(
                selectedRow
              ),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }
          break
        case 7:
          if (!navigatedTabs.includes(7)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_SAVINGS_PRODUCTS,
              payload: await getCreditTypeConcessionExemptionSavingsProducts(
                selectedRow
              ),
            })

            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }
          break
        case 8:
          if (!navigatedTabs.includes(8)) {
            dispatch({
              type: ACTIONS.LOADING,
              payload: true,
            })
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_BENEFACTOR_INVESTMENT_PRODUCTS,
              payload: await getCreditTypeBenefactorInvestmentProducts(
                selectedRow
              ),
            })
            dispatch({
              type: ACTIONS.LOADING,
              payload: false,
            })
          }
          break
        case 9:
          if (!navigatedTabs.includes(9)) {
            dispatch({
              type: ACTIONS.GET_CREDIT_TYPE_FLAGS,
              payload: mapCreditTypeFlags(selectedRow),
            })
          }
          break
        default:
          break
      }
    } else {
      switch (navigatedTab) {
        case 0:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })

          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_COMMISSIONS,
            payload: await getCommissionsToBeSelected(selectedRow),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 1:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })

          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_DIRECT_DEBITS,
            payload: await getDirectDebitsToBeSelected(selectedRow),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 2:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_LOAN_PRODUCTS,
            payload: await getCreditTypeAttachedLoanProductsToBeSelected(
              selectedRow
            ),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 3:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_LOAN_PRODUCTS,
            payload: await getCreditTypeConcessionExemptionLoanProducts(
              selectedRow
            ),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 4:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_INVESTMENT_PRODUCTS,
            payload: await getCreditTypeAttachedInvestmentProductsToBeSelected(
              selectedRow
            ),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 5:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_INVESTMENT_PRODUCTS,
            payload: await getCreditTypeConcessionExemptionInvestmentProducts(
              selectedRow
            ),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 6:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_SAVINGS_PRODUCTS,
            payload: await getCreditTypeAttachedSavingsProductsToBeSelected(
              selectedRow
            ),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 7:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_SAVINGS_PRODUCTS,
            payload: await getCreditTypeConcessionExemptionSavingsProducts(
              selectedRow
            ),
          })

          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 8:
          dispatch({
            type: ACTIONS.LOADING,
            payload: true,
          })
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_BENEFACTOR_INVESTMENT_PRODUCTS,
            payload: await getCreditTypeBenefactorInvestmentProducts(
              selectedRow
            ),
          })
          dispatch({
            type: ACTIONS.LOADING,
            payload: false,
          })

          break
        case 9:
          dispatch({
            type: ACTIONS.GET_CREDIT_TYPE_FLAGS,
            payload: mapCreditTypeFlags(selectedRow),
          })
          break
        default:
          break
      }
    }
  }

  const setupCreateCreditType = async () => {
    dispatch({
      type: ACTIONS.LOADING,
      payload: true,
    })

    dispatch({
      type: ACTIONS.CREATE_CREDIT_TYPE_INVESTMENT_PRODUCTS,
      payload: await getInvestmentProducts(),
    })

    dispatch({
      type: ACTIONS.CREATE_CREDIT_TYPE_SAVINGS_PRODUCTS,
      payload: await getSavingsProduct(),
    })

    dispatch({
      type: ACTIONS.CREATE_CREDIT_TYPE_LOAN_PRODUCTS,
      payload: await getLoanProducts(),
    })

    dispatch({
      type: ACTIONS.CREATE_CREDIT_TYPE_DIRECT_DEBITS,
      payload: await getDirectDebits(),
    })

    dispatch({
      type: ACTIONS.CREATE_CREDIT_TYPE_COMMISSIONS,
      payload: await getCommissions(),
    })
    dispatch({
      type: ACTIONS.LOADING,
      payload: false,
    })

    dispatch({
      type: ACTIONS.GET_CREDIT_TYPE_FLAGS,
      payload: CreditTypeFlags,
    })
  }

  const mapCreditTypeFlags = row => {
    return CreditTypeFlags.map(flag => {
      return {
        ...flag,
        checked: row.creditTypeFlags[flag.value] || false,
      }
    })
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('accountsManagement'), localize('creditTypes')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <AddNewButton
                          handleClickAddButton={() => {
                            setMode('add')
                            setSelectedCreditType(null)
                            setSelectedRow(null)
                            setupCreateCreditType()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <RecordsPerPage
                      className="col-3"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-6"></div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                          pageIndex: 0,
                        })
                      }}
                    >
                      <label htmlFor="searchTable" className="text-capitalize">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                      <button
                        type="submit"
                        className="btn btn-primary ms-2"
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={() => {
                              prepareCreditTypeForEditing(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      canClickOnce
                      onClickOnce={r => {
                        setClickedOnce(true)
                        setSelectedRow(r)
                        getCreditTypeMetaData(navigatedTab, r)
                      }}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 mt-3">
                      {selectedRow && clickedOnce && (
                        <>
                          <CreditTypesTabContainers
                            mode={mode}
                            creditTypeState={creditTypeState}
                            onNavigateCreditTypeTabContainer={tabNumber => {
                              setNavigatedTab(tabNumber)
                              getCreditTypeMetaData(tabNumber, selectedRow)
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreditTypesModal
        onTabNavigateCreditTypeModal={n => {
          if (mode === 'edit') {
            getCreditTypeMetaData(n, selectedRow)
          }
        }}
        mode={mode}
        onHideModal={() => {
          setMode(null)
          setNavigatedTabs([])
        }}
        onSetMode={m => {
          setMode(m)
        }}
        creditTypeDispatcher={dispatch}
        onUpdateRefreshAt={r => setRefreshAt(r)}
        setCreditTypeModel={setSelectedCreditType}
        creditTypeModel={selectedCreditType}
        creditTypeState={creditTypeState}
      />
    </>
  )
}

export default CreditTypes
