/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import localize from '../../../Global/Localize'
import Pager from '../../../Components/Tables/Pager'
import Tab from '../../../Components/Tabs/Tab'
import CreateEducationRegisterModal from './CreateEducationRegisterModal'
import EditEducationRegisterModal from './EditEducationRegisterModal'
import ViewAttendees from './ViewAttendees'
import Swal from 'sweetalert2'
import AddCustomer from '../Members/Partials/Customers/AddCustomer'
import trimObject from '../../../Helpers/trimObject'
import Loader from '../../../Components/Loaders/Loader'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'

function EducationRegister() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
      sortBy: 'postingPeriodDescription',
    },
    {
      label: localize('venue'),
      template: r => <>{r.educationVenueDescription}</>,
      sortBy: 'educationVenueDescription',
    },
    {
      label: localize('duration'),
      template: r => (
        <>
          {formatDate(r.durationStartDate, true)}
          {` ${localize('to')} `}
          {formatDate(r.durationEndDate, true)}
        </>
      ),
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('enforceAuthorizationToken') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.enforceAuthorizationToken.toString())}
        </span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [refreshData, setRefreshData] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [selectedEducationRegister, setSelectedEducationRegister] =
    useState(null)
  const [educationAttendees, setEducationAttandees] = useState([])
  const [search, setSearch] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [currentPostingPeriodId, setCurrentPostingPeriodId] = useState(null)
  const [currentPostingPeriodDescription, setCurrentPostingPeriodDescription] =
    useState(null)
  const [additionalRemarks, setAdditionalRemarks] = useState(null)

  useEffect(() => {
    loadTableData()
    findCurrentPostingPeriod()
  }, [reqParams, refreshData])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/EducationRegister/find-education-register-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setError(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
      setTableData([])
    }
    setIsBusy(false)
  }

  async function findCurrentPostingPeriod() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      null,
      false
    )
    if (success) {
      setCurrentPostingPeriodId(data.id || null)
      setCurrentPostingPeriodDescription(data.description || null)
    }
    setIsBusy(false)
  }

  const loadEducationAttendees = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/EducationRegister/find-education-attendees-by-education-register-id?educationRegisterId=${r.id}`,
      false
    )
    if (success) {
      setEducationAttandees(data.result)
    } else {
      setEducationAttandees([])
    }
    setIsBusy(false)
  }

  const handleAddToDataTable = () => {
    const customerExists = educationAttendees.find(
      cust => cust.customerId === selectedCustomer.id
    )

    if (customerExists) {
      Swal.fire({
        icon: 'info',
        text: 'Selected Customer Already Exists',
        title: 'Ooops!',
        showCloseButton: true,
      })
      return
    }
    setEducationAttandees([
      ...educationAttendees,
      {
        customerId: selectedCustomer.id,
        educationRegisterId: selectedEducationRegister?.id,
        customerFullName: selectedCustomer.fullName,
        customerMembershipClassCustomerType:
          selectedEducationRegister?.membershipClassCustomerType,
        customerSerialNumber: selectedCustomer.serialNumber,
        customerIndividualSalutation: selectedCustomer.individualSalutation,
        customerIndividualFirstName: selectedCustomer.individualFirstName,
        customerIndividualLastName: selectedCustomer.individualLastName,
        customerIndividualGender: selectedCustomer.individualGender,
        customerIndividualMaritalStatus:
          selectedCustomer.individualMaritalStatus,
        id: selectedCustomer.id,
        customerMembershipClassCustomerTypeDescription:
          selectedCustomer.membershipClassDescription,
        paddedCustomerSerialNumber: selectedCustomer.paddedSerialNumber,
        customerReference1: selectedCustomer.reference1,
        customerReference2: selectedCustomer.reference2,
        customerReference3: selectedCustomer.reference3,
        customerIndividualGenderDescription:
          selectedCustomer.individualGenderDescription,
        customerIndividualMaritalStatusDescription:
          selectedCustomer.individualMaritalStatusDescription,
        customerIndividualIdentityCardNumber:
          selectedCustomer.individualIdentityCardNumber,
        customerIndividualNationalityDescription:
          selectedCustomer.individualNationalityDescription,
        customerDutyStationDescription: selectedCustomer.dutyStationDescription,
        customerAddressAddressLine1: selectedCustomer.addressAddressLine1,
        customerAddressAddressLine2: selectedCustomer.addressAddressLine2,
        customerAddressStreet: selectedCustomer.addressStreet,
        customerAddressPostalCode: selectedCustomer.addressPostalCode,
        customerAddressCity: selectedCustomer.addressCity,
        customerAddressEmail: selectedCustomer.addressEmail,
        customerAddressLandLine: selectedCustomer.addressLandLine,
        customerAddressMobileLine: selectedCustomer.addressMobileLine,
        remarks: additionalRemarks?.remarks,
        createdBy: selectedCustomer.createdBy,
        createdDate: selectedCustomer.createdDate,
      },
    ])
  }

  const handleRemove = customer => {
    const filteredData = educationAttendees.filter(
      d => d.customerId !== customer.customerId
    )
    setEducationAttandees(filteredData)
  }

  const handleSelect = (cust, checked) => {
    if (checked) {
      setSelectedCustomers([...selectedCustomers, cust])
    } else {
      const newCustomers = selectedCustomers.filter(
        data => data?.customerId !== cust?.customerId
      )

      setSelectedCustomers(newCustomers)
    }
  }

  const onSelectAll = (customers, checked) => {
    if (checked) {
      setSelectedCustomers(customers)
    } else {
      setSelectedCustomers([])
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('membersEducationRegister'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  {error ? (
                    <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                      <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                      <div className="fs-5 text-danger">
                        {localize('failedToLoadData')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <AddNewButton
                              handleClickAddButton={() => {
                                setMode('add')
                                setSelectedEducationRegister({
                                  postingPeriodId: currentPostingPeriodId,
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex align-items-center col-3">
                          <label
                            htmlFor="recordsPerPage"
                            className="text-capitalize"
                            style={{ marginLeft: -10 }}
                          >
                            {localize('recordsPerPage')}
                          </label>
                          <select
                            className="form-select ms-3"
                            style={{ maxWidth: 'fit-content' }}
                            defaultValue={reqParams.pageSize}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                pageIndex: 0,
                                pageSize: e.target.value,
                              })
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="col-6"></div>
                        <form
                          className="d-flex align-items-center col-3 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                            })
                          }}
                        >
                          <label htmlFor="searchTable">
                            {localize('search')}
                          </label>
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.filterText}
                            onChange={e => setSearch(e.target.value)}
                          ></input>
                        </form>
                      </div>
                      <div className="row col-12 mt-2">
                        <SimpleTable
                          data={tableData}
                          columns={columns}
                          canClickOnce
                          onClickOnce={async r => {
                            setSelectedEducationRegister(r)
                            loadEducationAttendees(r)
                          }}
                          contextMenu={r => (
                            <>
                              <div
                                className="ctxt-menu-item text-capitalize"
                                onClick={() => {
                                  setMode('edit')
                                  setSelectedEducationRegister(r)
                                  loadEducationAttendees(r)
                                }}
                              >
                                <i className="uil uil-edit-alt"></i>
                                {localize('edit')}
                              </div>
                            </>
                          )}
                        />
                      </div>
                      <div className="row col-12 mt-2">
                        <Pager
                          {...tableMeta}
                          onPageChange={p => {
                            setReqParams({ ...reqParams, pageIndex: p })
                          }}
                        />
                      </div>
                      <div className="row col-12 mt-2">
                        {selectedEducationRegister && (
                          <Tab
                            tabItems={[
                              {
                                label: localize('attendees'),
                                item: (
                                  <EducationAttendees
                                    showLoader={false}
                                    clientData={educationAttendees}
                                    //educationAttendees={educationAttendees}
                                    //setEducationAttandees={setEducationAttandees}
                                  />
                                ),
                              },
                            ]}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode === 'add' ? (
        <CreateEducationRegisterModal
          selectedEducationRegister={selectedEducationRegister}
          mode={mode}
          close={() => setMode(null)}
          refreshData={() => {
            setRefreshData(!refreshData)
          }}
          currentPostingPeriodDescription={currentPostingPeriodDescription}
          currentPostingPeriodId={currentPostingPeriodId}
        />
      ) : (
        mode === 'edit' && (
          <EditEducationRegisterModal
            onCustomerInfoChanged={c => setSelectedCustomer(c)}
            onSetAttendeeRemarks={c => setAdditionalRemarks(c)}
            selectedEducationRegister={selectedEducationRegister}
            setSelectedEducationRegister={setSelectedEducationRegister}
            mode={mode}
            close={() => setMode(null)}
            refreshData={() => {
              setRefreshData(!refreshData)
            }}
            educationAttendees={educationAttendees}
            setEducationAttandees={setEducationAttandees}
            onCustomerChange={customer => {
              setSelectedCustomer(customer)
            }}
            handleAddToDataTable={handleAddToDataTable}
            handleRemove={handleRemove}
            selectedCustomers={selectedCustomers}
            handleSelect={handleSelect}
            onSelectAll={onSelectAll}
          />
        )
      )}
    </>
  )
}

const EducationAttendees = ({ clientData, showLoader }) => {
  const columns = [
    {
      label: localize('ref_1Account'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('ref_2Membership'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('ref_3PersonalFile'),
      template: r => <>{r.customerReference3}</>,
    },
    { label: localize('name'), template: r => <>{r.customerFullName}</> },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.customerIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('dutyWorkStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.customerAddressCity}</> },
    { label: localize('email'), template: r => <>{r.customerAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [mode, setMode] = useState(null)
  const [foundCustomer, setFoundCustomer] = useState(null)
  const [minimized, setMinimized] = useState(false)

  return (
    <>
      <SimpleTable
        loading={showLoader}
        data={clientData}
        columns={columns}
        selectables
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={async () => {
                setMode('view')
                const { success, data } = await getData(
                  '/customer/find-customer-by-id',
                  { customerId: r?.customerId },
                  false
                )
                if (success) {
                  setFoundCustomer(data?.customer)
                }
              }}
            >
              <i className="uil uil-eye"></i>
              {localize('view')}
            </div>
          </>
        )}
      />
      {mode && foundCustomer && (
        <AddCustomer
          readOnly={mode === 'view'}
          mode={mode}
          customer={foundCustomer}
          closeModal={() => setMode(null)}
          onMinimize={setMinimized}
        />
      )}
    </>
  )
}
export default EducationRegister
