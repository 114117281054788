/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import { getData } from '../../../../../../../Helpers/webApi'

function StandingOrderTab({
  customerId,
  onSelectAll,
  handleSelect,
  selectedStandingOrders,
}) {
  const [standingOrders, setStandingOrders] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const columns = [
    {
      label: localize('select'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('createdDate'),
      template: r => (
        <Checkbox
          checked={selectedStandingOrders.find(x => x.id === r.id)}
          onChange={v => handleSelect(r, v)}
        />
      ),
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
    },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r.beneficiaryProductDescription}</>,
    },
    {
      label: localize('expectedPrincipal'),
      template: r => <>{r.expectedPrincipal}</>,
    },
    {
      label: localize('expectedInterest'),
      template: r => <>{r.ExpectedInterest}</>,
    },
    {
      label: localize('actualInterest'),
      template: r => <>{r.actualInterest}</>,
    },
    {
      label: localize('residualPrincipal'),
      template: r => <>{r.actualPrincipal}</>,
    },
    {
      label: localize('residualInterest'),
      template: r => <>{r.residualPrincipal}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{r.chargePercentage}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{r.chargeFixedAmount}</>,
    },
    {
      label: localize('trigger'),
      template: r => <>{r.triggerDescription}</>,
    },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r.beneficiaryCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('beneficiaryName'),
      template: r => <>{r.benefactorCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r.benefactorProductDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.beneficiaryName}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r.transactionCodeDescription}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const fetchStandingOrders = async () => {
    const url = '/StandingOrder/find-by-beneficiary-customer-account-id'

    const { success, data } = await getData(url, {
      beneficiaryCustomerAccountId: customerId,
      includeProductDescription: true,
    })

    if (success) {
      setStandingOrders(data.result)
    }
  }

  useEffect(() => {
    if (customerId) fetchStandingOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  useEffect(() => {
    onSelectAll(standingOrders, selectAll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll])

  return (
    <>
      <SimpleTable data={standingOrders} columns={columns} />
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <Checkbox
              onCheck={e => {
                setSelectAll(e)
              }}
              id="toggleSelection"
              checked={selectAll}
            />
            <label htmlFor="toggleSelection" className="ms-2">
              {localize('toggleSelection')}
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default StandingOrderTab
