/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { ProductCode } from '../../../../../Global/enumeration'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import DebitTypeModal from './DebitTypeModal'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function DebitTypes() {
  const columns = [
    { label: localize('name'), template: r => <>{r?.description}</> },
    {
      label: localize('productCode'),
      template: r => <>{r?.customerAccountTypeProductCodeDescription}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedDebitType, setSelectedDebitType] = useState(null)
  const [mode, setMode] = useState(null)
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [tabLoading, setTabLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadTableData()
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DebitType/find-debit-type-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const fetchDebitTypeMetadata = async id => {
    setTabLoading(true)
    const { success, data } = await getData(
      '/DebitType/find-debit-type-metadata',
      { debitTypeId: id },
      false
    )
    if (success) {
      setTabLoading(false)
      return data
    } else {
      setTabLoading(false)
      return null
    }
  }

  const handleOnAdd = async () => {
    setMode('add')
    const metadata = await fetchDebitTypeMetadata(
      '00000000-0000-0000-0000-000000000000'
    )
    setSelectedDebitType({
      customerAccountTypeProductCode: Object.keys(ProductCode)[0],
      commissions: metadata?.commissions || [],
    })
  }

  const handleOnEdit = async r => {
    setMode('edit')
    const metadata = await fetchDebitTypeMetadata(r?.id)
    setSelectedDebitType({
      ...r,
      commissions: metadata?.commissions || [],
    })
  }

  const handleOnView = async r => {
    setMode('view')
    const metadata = await fetchDebitTypeMetadata(r?.id)
    setSelectedDebitType({
      ...r,
      commissions: metadata?.commissions || [],
    })
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('miscellaneous'),
          localize('debitTypes'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleOnAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="employeeTypeFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              handleOnView(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={async () => {
                              handleOnEdit(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {mode && (
        <DebitTypeModal
          selectedDebitType={selectedDebitType}
          setSelectedDebitType={setSelectedDebitType}
          mode={mode}
          onHideModal={() => {
            setMode(null)
          }}
          tabLoading={tabLoading}
          loadTableData={loadTableData}
        />
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={() => {
            setMode(null)
          }}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}
export default DebitTypes
