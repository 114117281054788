/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

function LoanGuarantors({ loanCase }) {
  const [guarantors, setGuarantors] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    findLoanGuarantors()
    // eslint-disable-next-line
  }, [])

  async function findLoanGuarantors() {
    setFetching(true)
    const { success, data } = await getData(
      '/loanCase/find-loan-guarantor-by-loan-case-id',
      { loanCaseId: loanCase?.id },
      false
    )

    if (success) {
      setFetching(false)
      setGuarantors(data?.result || [])
    } else {
      setFetching(false)
    }
  }

  const columns = [
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r?.customerMembershipStatusDescription}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')} #)`,
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.customerIdentificationNumber}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.loaneeCustomerAddressMobileLine}</>,
    },
    {
      label: localize('totalShares'),
      template: r => <>{formatCurrency(r?.totalShares)}</>,
    },
    {
      label: localize('committedShares'),
      template: r => <>{formatCurrency(r?.committedShares)}</>,
    },
    {
      label: localize('amountGuaranteed'),
      template: r => <>{formatCurrency(r?.amountGuaranteed)}</>,
    },
    {
      label: localize('amountPledged'),
      template: r => <>{formatCurrency(r?.amountPledged)}</>,
    },
    {
      label: localize('appraisalFactor'),
      template: r => <>{r?.appraisalFactor}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanStatus'),
      template: r => <>{r?.loanCaseStatusDescription}</>,
    },
    {
      label: localize('loanBranch'),
      template: r => <>{r?.loanCaseBranchDescription}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanCaseLoanPurposeDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <>
      <SimpleTable columns={columns} data={guarantors} />
    </>
  )
}

export default LoanGuarantors
