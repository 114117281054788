/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import AccruedInterestEntries from './AccruedInterestEntries'
function ViewAccruedInterestDataModal({
  mode,
  selectedAccruedInterest,
  close,
}) {
  const [apportionedAmount, setApportionedAmount] = useState(null)
  const [entriesTotal, setEntriesTotal] = useState(null)
  function closeModal() {
    close()
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="accruedInterestBatches"
        handleClose={closeModal}
        modalClassName="modal-xl"
        actionText={mode === 'view' ? 'refresh' : ''}
        footerChildren={
          <>
            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('apportioned')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={apportionedAmount || ''}
              />
            </div>

            <div className="col-3 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('entries')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={entriesTotal || ''}
              />
            </div>
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('batch#')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.paddedBatchNumber || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('referenceMonth')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.monthDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('valueDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={formatDate(selectedAccruedInterest?.valueDate) || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-4 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('monthlyBalanceSpool')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedAccruedInterest?.customerAccountBalanceSpoolReference ||
                ''
              }
            />
          </div>
          <div className="col-8 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedAccruedInterest?.customerAccountBalanceSpoolReference ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.createdBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.createdDate || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('priority')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.priorityDescription || ''}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('verifiedBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.auditedBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('verifiedDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.auditedDate || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('verificationRemarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.auditRemarks || ''}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('authorizedBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.authorizedBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('authorizedDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.authorizedDate || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('authorizationRemarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedAccruedInterest?.authorizationRemarks || ''}
            />
          </div>
        </div>
        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <AccruedInterestEntries
                    selectedAccruedInterest={selectedAccruedInterest}
                    setApportioned={setApportionedAmount}
                    setEntriesTotal={setEntriesTotal}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewAccruedInterestDataModal
