/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { DiscrepancyFilter } from '../../../../../../../Global/enumeration'
import { getData } from '../../../../../../../Helpers/webApi'
import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import { FiltersContainer } from '../../../../../../../Components/Tables/FiltersContainer'
import Pager from '../../../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'

export const MismatchedImportEntriesViewMode = ({ selectedBatchEntry }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [prevSelectedBatchEntry, setPrevSelectedBatchEntry] =
    useState(selectedBatchEntry)

  const [discrepancies, setDiscrepancies] = useState([])

  const [reqParams, setReqParams] = useState({
    creditBatchId: selectedBatchEntry?.id,
    filterText: '',
    discrepancyFilter: Object.keys(DiscrepancyFilter)[0],
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('column1'), template: r => <>{r.column1}</> },
    { label: localize('column2'), template: r => <>{r.column2}</> },
    { label: localize('column3'), template: r => <>{r.column3}</> },
    { label: localize('column4'), template: r => <>{r.column4}</> },
    { label: localize('column5'), template: r => <>{r.column5}</> },
    { label: localize('column6'), template: r => <>{r.column6}</> },
    { label: localize('column7'), template: r => <>{r.column7}</> },
    { label: localize('column8'), template: r => <>{r.column8}</> },
    { label: localize('column9'), template: r => <>{r.column9}</> },
    { label: localize('column10'), template: r => <>{r.column10}</> },
    { label: localize('column11'), template: r => <>{r.column11}</> },
    { label: localize('column12'), template: r => <>{r.column12}</> },
    { label: localize('column13'), template: r => <>{r.column13}</> },
    { label: localize('column14'), template: r => <>{r.column14}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('postedBy'), template: r => <>{r.postedBy}</> },
    { label: localize('postedDate'), template: r => <>{r.postedDate}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  const fetchBatchEntries = async r => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batch-discrepancies-by-credit-batch-id-in-page',
      { ...reqParams, creditBatchId: r.id },
      false
    )
    if (success) {
      setDiscrepancies(data)
    }
    setIsBusy(false)
  }

  if (prevSelectedBatchEntry.id !== selectedBatchEntry.id) {
    setPrevSelectedBatchEntry(selectedBatchEntry)
    fetchBatchEntries(selectedBatchEntry)
  }

  useEffect(() => {
    fetchBatchEntries(selectedBatchEntry)
    // eslint-disable-next-line
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-5">
              <label className="text-capitalize me-3">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="DiscrepancyFilter"
                onChange={e => {
                  setReqParams({ ...reqParams, discrepancyFilter: e.value })
                }}
                sort
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <div className="input-group">
                <input
                  className="form-control"
                  onChange={e => {
                    setReqParams({ ...reqParams, filterText: e.target.value })
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    fetchBatchEntries(selectedBatchEntry)
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <SimpleTable columns={columns} data={discrepancies.pageCollection} />
          <div className="mt-3">
            <Pager
              itemsCount={discrepancies?.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </div>
        </>
      )}
    </>
  )
}
