/** @format */

import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Tab from '../../../../Components/Tabs/Tab'
import { RefundTypeCommissions } from './RefundTypeCommissions'
import React from 'react'
import Loader from '../../../../Components/Loaders/Loader'

export const EditRefundTypesModal = ({
  selectedRefundType,
  setSelectedRefundType,
  waitingMetadata,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['RefundType.Description']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedRefundType?.description}
                onChange={e => {
                  clearValidation('RefundType.Description')
                  setSelectedRefundType({
                    ...selectedRefundType,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('transactionOwnership')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="TransactionOwnership"
                value={selectedRefundType?.transactionOwnership}
                onChange={e =>
                  setSelectedRefundType({
                    ...selectedRefundType,
                    transactionOwnership: e.value,
                  })
                }
              />
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <Tab
                  tabItems={[
                    {
                      label: localize('applicableCharges'),
                      item: (
                        <RefundTypeCommissions
                          setSelectedRefundType={setSelectedRefundType}
                          selectedRefundType={selectedRefundType}
                          prefix={'refund-type-add'}
                          waiting={waitingMetadata}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
