/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import Tab from '../../../../../Components/Tabs/Tab'
import { SundryReceiptsPaymentsContainer } from './Partials/SundryReceiptsPaymentsContainer'
import { TellerChartOfAccountStatement } from './Partials/TellerChartOfAccountStatement'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import { SundrySummary } from './Partials/SundrySummary'
import { GeneralLedgerTransactionType } from '../../../../../Helpers/constants'
import { postData } from '../../../../../Helpers/webApi'

const SundryReceiptsPayments = () => {
  const [transactionModel, setTransactionModel] = useState({
    GeneralTransactionType: GeneralLedgerTransactionType.CASH_PAYMENT,
  })
  const [loading, setLoading] = useState(false)
  const [tariffs, setTariffs] = useState([])

  useEffect(() => {
    getChargesAndLevies()
  }, [
    transactionModel?.GeneralTransactionType,
    transactionModel?.selectedCreditBatchEntry,
  ])

  const getChargesAndLevies = async () => {
    const { success, data } = await postData(
      '/lookup/Teller/compute-sundry-tariffs',
      {
        SelectedCustomerAccount: transactionModel?.SelectedCustomerAccount,
        SelectedCreditBatchEntry: transactionModel.selectedCreditBatchEntry,
        GeneralTransactionType: transactionModel?.GeneralTransactionType,
      },
      false,
      null
    )
    if (success) {
      setTariffs(data.result)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('teller'),
          localize('sundryReceiptsPayments'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <Tab
                    preload
                    tabItems={[
                      {
                        label: localize('transaction'),
                        item: (
                          <SundryReceiptsPaymentsContainer
                            tariffs={tariffs}
                            loading={loading}
                            transactionModel={transactionModel}
                            setTransactionModel={setTransactionModel}
                          />
                        ),
                      },
                      {
                        label: localize('tellerChartOfAccountStatement'),
                        item: (
                          <TellerChartOfAccountStatement
                            onFetchTellerStatements={e => {}}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="col-md-12">
                  <SundrySummary
                    generalTransactionType={transactionModel?.GeneralTransactionType}
                    setLoading={setLoading}
                    transactionModel={transactionModel}
                    setTransactionModel={setTransactionModel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SundryReceiptsPayments
