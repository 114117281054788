/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import { postData, putData } from '../../../../../Helpers/webApi'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../../Components/Loaders/Loader'
import CreateHeads from './CreateHeads'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'

function Heads() {
  const [isBusy, setIsBusy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [salaryHeads, setSalaryHeads] = useState([])
  const [heads, setHeads] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })

  useEffect(() => {
    loadSalaryHeads()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refresh])

  async function loadSalaryHeads() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/SalaryHead/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setSalaryHeads(data)
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('isOneOff'),
      template: r => <>{r?.isOneOff.toString()}</>,
    },
    {
      label: localize('productCode'),
      template: r => <>{r?.customerAccountTypeProductCodeDescription}</>,
    },
    {
      label: localize('targetProduct'),
      template: r => <>{r?.productChartOfAccountName}</>,
    },
    {
      label: localize('glAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('glAccountCostDistributionRuleDescription'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleCreateOrUpdate = async () => {
    setRefresh(false)
    const url =
      modalMode === 'add' ? '/SalaryHead/create' : '/SalaryHead/update'

    if (modalMode === 'add') {
      setLoading(true)
      const { success } = await postData(
        url,
        heads,
        false,
        'Operation successful'
      )

      if (success) {
        setRefresh(true)
        setLoading(false)
        handleClose()
        showNotification('Salary Heads created successfully', 'success')
      }

      setLoading(false)
    } else if (modalMode === 'edit') {
      setLoading(true)

      const { success } = await putData(
        url,
        heads,
        false,
        'Operation successful'
      )
      if (success) {
        setRefresh(true)
        setLoading(false)
        handleClose()
        showNotification('Salary Heads Updated Successfully', 'success')
      }

      setLoading(false)
    }
  }

  const handleClose = () => {
    setModalMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResource'),
          localize('salary'),
          localize('salaryHeads'),
        ]}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateHeads
          handleAction={handleCreateOrUpdate}
          handleClose={handleClose}
          mode={modalMode}
          heads={heads}
          setHeads={setHeads}
          refresh={refresh}
          setRefresh={setRefresh}
          isLoading={loading}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-start mb-4">
              <AddNewButton
                handleClickAddButton={() => {
                  setModalMode('add')
                  setHeads({})
                }}
              />
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3">
                <RecordsPerPage
                  className="col-3"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="col  d-flex align-items-center justify-content-end gap-2">
                  <div className="d-flex align-items-center ">
                    <label className=" text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <input
                        type="search"
                        className="form-control ms-2"
                        defaultValue={reqParams?.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                    <button
                      type="button"
                      className="btn btn-primary ms-1"
                      onClick={loadSalaryHeads}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <SimpleTable
                  columns={columns}
                  data={salaryHeads?.pageCollection}
                  contextMenu={x => (
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setModalMode('edit')
                        setHeads(x)
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      {localize('edit')}
                    </div>
                  )}
                />
              </div>
              <Pager
                itemsCount={salaryHeads?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Heads
