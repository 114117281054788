/** @format */

import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import DesignationLookup from '../../../../Components/Lookup/DesignationLookup'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'
import DepartmentLookup from '../../../../Components/Lookup/DepartmentLookup'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import Tab from '../../../../Components/Tabs/Tab'
import Loader from '../../../../Components/Loaders/Loader'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { Address } from '../../../../Components/Miscalleneous/Address'
import SpecimenMediaItem from '../../../Registry/Members/Partials/Tabs/SpecimenMediaItem'

function CreateTempEmployee({
  modalMode,
  close,
  temporaryEmployeeId,
  refreshEmployees,
}) {
  const [employee, setEmployee] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    if (temporaryEmployeeId && (modalMode === 'view' || modalMode === 'edit')) {
      loadEmployee()
    }
    // eslint-disable-next-line
  }, [])

  async function loadEmployee() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/temporaryEmployee/find-by-id',
      { temporaryEmployeeId, includeSpecimen: true },
      false
    )
    if (success) {
      setEmployee(data)
    }
    setIsBusy(false)
  }

  const tabItems = [
    {
      label: localize('particulars'),
      icon: modalMode === 'view' ? null : 'uil-edit',
      item: (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('individualType')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="IndividualType"
                  disabled={modalMode === 'view'}
                  value={employee?.individualType}
                  onChange={({ value }) =>
                    setEmployee({ ...employee, individualType: value })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('nationality')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="Nationality"
                  disabled={modalMode === 'view'}
                  value={employee?.individualNationality}
                  onChange={({ value }) =>
                    setEmployee({ ...employee, individualNationality: value })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('gender')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="Gender"
                  disabled={modalMode === 'view'}
                  value={employee?.individualGender}
                  onChange={({ value }) =>
                    setEmployee({ ...employee, individualGender: value })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('salutation')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="Salutation"
                  disabled={modalMode === 'view'}
                  value={employee?.individualSalutation}
                  onChange={({ value }) =>
                    setEmployee({ ...employee, individualSalutation: value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('firstName')}
              </label>
              <div className="w-100">
                <input
                  disabled={modalMode === 'view'}
                  type="text"
                  className="form-control"
                  value={employee?.individualFirstName}
                  onChange={e =>
                    setEmployee({
                      ...employee,
                      individualFirstName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('otherNames')}
              </label>
              <div className="w-100">
                <input
                  disabled={modalMode === 'view'}
                  type="text"
                  className="form-control"
                  value={employee?.individualLastName}
                  onChange={e =>
                    setEmployee({
                      ...employee,
                      individualLastName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('birthDate')}
              </label>
              <div className="w-100">
                <CustomDatePicker
                  disabled={modalMode === 'view'}
                  defaultDate={employee?.individualBirthDate}
                  onDateChange={e =>
                    setEmployee({ ...employee, individualBirthDate: e })
                  }
                  name="createTempEmployeeBirthDate"
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('educationLevel')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="EducationLevel"
                  disabled={modalMode === 'view'}
                  value={employee?.individualEducationLevel}
                  onChange={({ value }) =>
                    setEmployee({
                      ...employee,
                      individualEducationLevel: value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('identityCardType')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="IdentityCardType"
                  disabled={modalMode === 'view'}
                  value={employee?.individualIdentityCardType}
                  onChange={({ value }) =>
                    setEmployee({
                      ...employee,
                      individualIdentityCardType: value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('identityCard')} #
              </label>
              <div className="w-100">
                <input
                  disabled={modalMode === 'view'}
                  type="text"
                  className="form-control"
                  value={employee?.individualIdentityCardNumber}
                  onChange={e =>
                    setEmployee({
                      ...employee,
                      individualIdentityCardNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('identityCardSerial')} #
              </label>
              <div className="w-100">
                <input
                  disabled={modalMode === 'view'}
                  type="text"
                  className="form-control"
                  value={employee?.individualIdentityCardSerialNumber}
                  onChange={e =>
                    setEmployee({
                      ...employee,
                      individualIdentityCardSerialNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('huduma')} #
              </label>
              <div className="w-100">
                <input
                  type="text"
                  value={employee?.individualHudumaNumber}
                  className="form-control"
                  onChange={e =>
                    setEmployee({
                      ...employee,
                      individualHudumaNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('maritalStatus')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="MaritalStatus"
                  disabled={modalMode === 'view'}
                  value={employee?.individualMaritalStatus}
                  onChange={({ value }) =>
                    setEmployee({ ...employee, individualMaritalStatus: value })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="col-4 text-capitalize">
                {localize('disabilityType')}
              </label>
              <div className="w-100">
                <EnumsServerCombo
                  enumsKey="CustomerDisabilityType"
                  disabled={modalMode === 'view'}
                  value={employee?.individualDisabilityType}
                  onChange={({ value }) =>
                    setEmployee({
                      ...employee,
                      individualDisabilityType: value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2"></div>
            <div className="col d-flex align-items-center gap-2"></div>
          </div>
        </>
      ),
    },
    {
      label: localize('address'),
      icon: modalMode === 'view' ? null : 'uil-edit',
      item: (
        <Address
          disableInputs={modalMode === 'view'}
          editMode
          address={{
            addressLine1: employee?.addressAddressLine1,
            addressLine2: employee?.addressAddressLine2,
            street: employee?.addressStreet,
            postalCode: employee?.addressPostalCode,
            city: employee?.addressCity,
            email: employee?.addressEmail,
            landLine: employee?.addressLandLine,
            mobileLine: employee?.addressMobileLine,
          }}
          setAddress={a => {
            console.log('address', a)
            setEmployee({ ...employee, ...a })
          }}
        />
      ),
    },
    {
      label: localize('specimen'),
      icon: 'uil-camera',
      item: (
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="col">
            <label className="p-1 text-capitalize">
              {localize('passport')}
            </label>
            <SpecimenMediaItem
              key="passport"
              itemKey="selectPassport"
              onChange={(key, photo) =>
                setEmployee({ ...employee, passport: photo })
              }
              isBusy={false}
              readOnly={modalMode === 'view'}
              base64={employee?.passport}
            />
          </div>
          <div className="col">
            <label className="p-1 text-capitalize">
              {localize('signature')}
            </label>
            <SpecimenMediaItem
              key="signature"
              itemKey="selectSignature"
              onChange={(key, photo) =>
                setEmployee({ ...employee, signature: photo })
              }
              isBusy={false}
              readOnly={modalMode === 'view'}
              base64={employee?.signature}
            />
          </div>
          <div className="col">
            <label className="p-1 text-capitalize">
              {localize('identityCard')} ({localize('front')})
            </label>
            <SpecimenMediaItem
              key="idFront"
              itemKey="selectIdFront"
              onChange={(key, photo) =>
                setEmployee({ ...employee, idFront: photo })
              }
              base64={employee?.idFront}
              isBusy={false}
              readOnly={modalMode === 'view'}
            />
          </div>
          <div className="col">
            <label className="p-1 text-capitalize">
              {localize('identityCard')} ({localize('back')})
            </label>
            <SpecimenMediaItem
              key="idBack"
              itemKey="selectIdBack"
              onChange={(key, photo) =>
                setEmployee({ ...employee, idBack: photo })
              }
              base64={employee?.idBack}
              isBusy={false}
              readOnly={modalMode === 'view'}
            />
          </div>
        </div>
      ),
    },
  ]

  async function createEmployee() {
    setIsBusy(true)
    const { success } = await postData(
      '/temporaryEmployee/create',
      employee,
      false
    )
    if (success) {
      Swal.fire('Operation Successful', 'Temporary Employee Created', 'success')
      close()
      refreshEmployees()
    }
    setIsBusy(false)
  }

  async function updateEmployee() {
    setIsBusy(true)
    const { success } = await putData(
      '/temporaryEmployee/update',
      employee,
      false
    )
    if (success) {
      Swal.fire('Operation Successful', 'Temporary Employee Updated', 'success')
      close()
      refreshEmployees()
    }
    setIsBusy(false)
  }

  return (
    <ModalTemplate
      hideCancel={isBusy}
      disableClose={isBusy}
      hideUpdate={isBusy || modalMode === 'view'}
      modalClassName="modal-xl"
      modalMode={modalMode}
      actionText={modalMode === 'add' ? localize('create') : localize('update')}
      handleClose={close}
      modalTitle={localize('temporaryEmployee')}
      handleAction={modalMode === 'add' ? createEmployee : updateEmployee}
    >
      {isBusy ? (
        <Loader message="Loading Temporary Employee" />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('designation')}
              </label>
              <DesignationLookup
                disabled={modalMode === 'view'}
                displayValue={employee?.designationDescription}
                onSelect={d =>
                  setEmployee({
                    ...employee,
                    designationDescription: d.description,
                    designationId: d.id,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('branch')}
              </label>
              <BranchLookup
                disabled={modalMode === 'view'}
                displayValue={employee?.branchDescription}
                onSelect={d =>
                  setEmployee({
                    ...employee,
                    branchDescription: d.description,
                    branchId: d.id,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('department')}
              </label>
              <DepartmentLookup
                disabled={modalMode === 'view'}
                displayValue={employee?.departmentDescription}
                onSelect={d =>
                  setEmployee({
                    ...employee,
                    departmentDescription: d.description,
                    departmentId: d.id,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('bloodGroup')}
              </label>
              <div style={{ width: '100%' }}>
                <EnumsServerCombo
                  disabled={modalMode === 'view'}
                  enumsKey="BloodGroup"
                  value={employee?.bloodGroup}
                  onChange={({ value }) =>
                    setEmployee({ ...employee, bloodGroup: value })
                  }
                />
              </div>
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('PIN')}
              </label>
              <input
                disabled={modalMode === 'view'}
                type="text"
                className="form-control"
                value={employee?.personalIdentificationNumber}
                onChange={e =>
                  setEmployee({
                    ...employee,
                    personalIdentificationNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('NSSFNumber')}
              </label>
              <input
                disabled={modalMode === 'view'}
                type="text"
                className="form-control"
                value={employee?.nationalSocialSecurityFundNumber}
                onChange={e =>
                  setEmployee({
                    ...employee,
                    nationalSocialSecurityFundNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('NHIFNumber')}
              </label>
              <input
                disabled={modalMode === 'view'}
                type="text"
                className="form-control"
                value={employee?.nationalHospitalInsuranceFundNumber}
                onChange={e =>
                  setEmployee({
                    ...employee,
                    nationalHospitalInsuranceFundNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('hireDate')}
              </label>
              <CustomDatePicker
                disabled={modalMode === 'view'}
                name="createTempEmployeeHireDate"
                defaultDate={employee?.hireDate}
                onDateChange={e =>
                  setEmployee({
                    ...employee,
                    hireDate: e,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('releaseDate')}
              </label>
              <CustomDatePicker
                disabled={modalMode === 'view'}
                name="createTempEmployeeReleaseDate"
                defaultDate={employee?.releaseDate}
                onDateChange={e =>
                  setEmployee({
                    ...employee,
                    releaseDate: e,
                  })
                }
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('remarks')}
              </label>
              <input
                disabled={modalMode === 'view'}
                type="text"
                className="form-control"
                value={employee?.remarks}
                onChange={e =>
                  setEmployee({
                    ...employee,
                    remarks: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center gap-2"></div>
            <div className="col d-flex align-items-center gap-2"></div>
          </div>
          <Tab preload tabItems={tabItems} />{' '}
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateTempEmployee
