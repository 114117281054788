/** @format */
import InvestmentProducts from '../../../../Components/Selectables/Plain/InvestmentProducts'

export const CreditTypesConcessionExemptInvestmentProducts = ({
  creditTypeInvestmentProducts,
  loading,
  disabled,
  onCheckCreditTypeConcessionExemptionInvestmentProduct,
  prefix,
}) => {
  return (
    <InvestmentProducts
      disabled={disabled}
      isBusy={loading}
      prefix={prefix}
      investmentProductsToBeSelected={creditTypeInvestmentProducts}
      onCheckInvestmentProduct={r => {
        onCheckCreditTypeConcessionExemptionInvestmentProduct &&
          onCheckCreditTypeConcessionExemptionInvestmentProduct(r)
      }}
    />
  )
}
