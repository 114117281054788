/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'

const ViewVehicle = ({ handleClose, mode, vehicleData }) => {
  const [customer, setCustomer] = useState({})
  const [vehicle, setVehicle] = useState({})
  const [vehicleProducts, setVehicleProducts] = useState([])
  const [isBusyLoadingProducts, setIsBusyLoadingProducts] = useState(false)

  const columns = [
    {
      label: 'productType',
      template: r => r?.vehicleFleetProductProductTypeDescription,
    },
    {
      label: 'productName',
      template: r => r?.vehicleFleetProductDescription,
    },
    { label: 'fullAccountNumber', template: r => r?.targetProductDescription },
    { label: 'contribution', template: r => r?.contribution },
    { label: 'remarks', template: r => r?.remarks },
    { label: 'createdDate', template: r => formatDate(r?.createdDate) },
  ]

  const findVehicleProducts = async () => {
    setIsBusyLoadingProducts(true)
    const url = `/Vehicle/find-vehicle-products-by-vehicle-id?vehicleId=${vehicleData?.id}`
    const { success, data } = await getData(url, {}, false)
    if (success) {
      setVehicleProducts(data?.result)
    }

    setIsBusyLoadingProducts(false)
  }

  useEffect(() => {
    if (mode === 'view' && vehicleData?.id) {
      setVehicle({
        ...vehicleData,
        vehicleRouteId: vehicleData?.vehicleRouteId,
        vehicleRouteDescription: vehicleData?.vehicleRouteDescription,
        customerId: vehicleData?.customerId,
        customerFullName: vehicleData?.fullName,
      })

      setCustomer({
        customerId: vehicleData?.customerId,
        customerFullName: vehicleData?.customerFullName,
        id: vehicleData?.customerId,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, vehicleData?.id])

  useEffect(() => {
    if (vehicleData?.id) {
      findVehicleProducts()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, vehicleData?.id])

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      modalTitle="viewVehicle"
      modalClassName="modal-xl"
      hideUpdate
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <label className="text-capitalize">
              {localize('isLocked') + ' ?'}
            </label>
            <Checkbox disabled checked={vehicle?.isLocked} />
          </div>
        </>
      }
    >
      <CustomerPartial customer={customer} refreshFromServer mode={'edit'} />

      <div className="row gy-2">
        <div className="col-md-3 d-flex align-items-center with-validation">
          <label className="col-4 text-capitalize">
            {localize('vehicleRoute')}
          </label>
          <input
            className="form-control"
            value={vehicle?.vehicleRouteDescription}
            disabled
          />
        </div>
        <div className="col-md-3 d-flex align-items-center with-validation">
          <label className="col-4 text-capitalize">
            {localize('registrationNumber')}
          </label>
          <input
            className="form-control ms-2"
            value={vehicle?.registrationNumber}
            disabled
          />
        </div>
        <div className="col-md-3 d-flex align-items-center with-validation">
          <label className="col-4 text-capitalize">
            {localize('fleetNumber')}
          </label>
          <input
            className="form-control ms-2"
            value={vehicle?.fleetNumber}
            disabled
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('documentReferenceNumber')}
          </label>
          <input
            className="form-control ms-2"
            value={vehicle?.documentReferenceNumber}
            disabled
          />
        </div>
        <div className="col-md-12 d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('remarks')}</label>
          <input
            type="text"
            className="form-control"
            value={vehicle?.remarks}
            disabled
          />
        </div>
      </div>

      <div className="mt-4">
        <Tab
          tabItems={[
            {
              label: localize('vehicleProducts'),
              item: (
                <>
                  <div
                    className="p-2 mt-4"
                    style={{
                      border: 'solid 1px #0000001f',
                      borderRadius: 10,
                      position: 'relative',
                    }}
                  >
                    <div
                      className="bg-white px-2  row text-capitalize"
                      style={{
                        position: 'relative',
                        top: -20,
                        left: 30,
                        maxWidth: 'fit-content',
                      }}
                    >
                      {localize('vehicleProducts')}
                    </div>

                    {isBusyLoadingProducts ? (
                      <Loader />
                    ) : (
                      <>
                        <SimpleTable columns={columns} data={vehicleProducts} />
                      </>
                    )}
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </ModalTemplate>
  )
}

export default ViewVehicle
