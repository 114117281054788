/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../Global/Localize'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
function FuneralRiderSinatories({
  customer,
  setSelectedFuneralRiderClaim,
  immediateSuperiorIdentityCardNoValidationMessage,
  immediateSuperiorNameNoValidationMessage,
  areaChiefNameValidationMessage,
  areaDelegateNameValidationMessage,
  areaBoardMemberNameValidationMessage,
  clearValidation,
  mode,
}) {
  return (
    <>
      <div className="row mb-2">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={areaChiefNameValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('chiefNames')}
          </label>
          <input
            type="text"
            disabled={mode === 'view'}
            className="form-control"
            value={customer?.areaChiefName || ''}
            onChange={e => {
              clearValidation('AreaChiefName')
              setSelectedFuneralRiderClaim({
                ...customer,
                areaChiefName: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-6 text-capitalize">
            {localize('chiefIdNumber')}
          </label>
          <input
            type="text"
            disabled={mode === 'view'}
            className="form-control"
            value={customer?.areaChiefIdentityCardNumber || ''}
            onChange={e => {
              setSelectedFuneralRiderClaim({
                ...customer,
                areaChiefIdentityCardNumber: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('signatureDate')}
          </label>
          <CustomDatePicker
            disabled={mode === 'view'}
            defaultDate={customer?.areaChiefSignatureDate}
            name={'areaChiefSignatureDate'}
            onDateChange={date => {
              setSelectedFuneralRiderClaim({
                ...customer,
                areaChiefSignatureDate: date,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={immediateSuperiorNameNoValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('immediateSuperiorNames')}
          </label>
          <input
            type="text"
            disabled={mode === 'view'}
            className="form-control"
            value={customer?.immediateSuperiorName || ''}
            onChange={e => {
              clearValidation('ImmediateSuperiorName')
              setSelectedFuneralRiderClaim({
                ...customer,
                immediateSuperiorName: e.target.value,
              })
            }}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={immediateSuperiorIdentityCardNoValidationMessage}
        >
          <label className="col-6 text-capitalize">
            {localize('immediateSuperiorIdNumber')}
          </label>
          <input
            type="text"
            disabled={mode === 'view'}
            className="form-control"
            value={customer?.immediateSuperiorIdentityCardNumber || ''}
            onChange={e => {
              clearValidation('ImmediateSuperiorIdentityCardNumber')
              setSelectedFuneralRiderClaim({
                ...customer,
                immediateSuperiorIdentityCardNumber: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('signatureDate')}
          </label>
          <CustomDatePicker
            disabled={mode === 'view'}
            defaultDate={customer?.immediateSuperiorSignatureDate}
            name={'immediateSuperiorSignatureDate'}
            onDateChange={date => {
              setSelectedFuneralRiderClaim({
                ...customer,
                immediateSuperiorSignatureDate: date,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={areaDelegateNameValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('delegateNames')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.areaDelegateName || ''}
            onChange={e => {
              clearValidation('AreaDelegateName')
              setSelectedFuneralRiderClaim({
                ...customer,
                areaDelegateName: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-6 text-capitalize">
            {localize('delegatePfNumber')}
          </label>
          <input
            type="text"
            disabled={mode === 'view'}
            className="form-control"
            value={customer?.areaDelegatePersonalFileNumber || ''}
            onChange={e => {
              setSelectedFuneralRiderClaim({
                ...customer,
                areaDelegatePersonalFileNumber: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('signatureDate')}
          </label>
          <CustomDatePicker
            disabled={mode === 'view'}
            defaultDate={customer?.areaDelegateSignatureDate}
            name={'areaDelegateSignatureDate'}
            onDateChange={date => {
              setSelectedFuneralRiderClaim({
                ...customer,
                areaDelegateSignatureDate: date,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={areaBoardMemberNameValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('boardMemberNames')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.areaBoardMemberName || ''}
            onChange={e => {
              clearValidation('AreaBoardMemberName')
              setSelectedFuneralRiderClaim({
                ...customer,
                areaBoardMemberName: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-6 text-capitalize">
            {localize('boardMemberPfNumber')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.areaBoardMemberPersonalFileNumber || ''}
            onChange={e => {
              setSelectedFuneralRiderClaim({
                ...customer,
                areaBoardMemberPersonalFileNumber: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('signatureDate')}
          </label>
          <CustomDatePicker
            disabled={mode === 'view'}
            defaultDate={customer?.areaBoardMemberSignatureDate}
            name={'areaBoardMemberSignatureDate'}
            onDateChange={date => {
              setSelectedFuneralRiderClaim({
                ...customer,
                areaBoardMemberSignatureDate: date,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
export default FuneralRiderSinatories
