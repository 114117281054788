/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ExportButton from '../../../../../../../Components/Buttons/ExportButton/ExportButton'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import { batchEntryStatus } from '../../../../../../../Helpers/constants'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../../Helpers/webApi'
import ViewTemplateBasedReportItem from './ViewTemplateBasedReportItem/ViewTemplateBasedReportItem'

function BatchEntries({ entry }) {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [modalMode, setModalMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    status: Object.keys(batchEntryStatus)[1],
  })

  const columns = [
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('name'),
      template: r => r?.description,
    },
    {
      label: localize('cellReference'),
      template: r => r?.cellReference,
    },
    {
      label: localize('cellValue'),
      template: r => formatCurrency(r?.cellValue),
    },
    {
      label: localize('absoluteCellValue') + '?',
      template: r => r?.absoluteCellValue?.toString(),
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchEntries = async () => {
    setIsBusy(true)
    const url = '/ReportTemplate/find-report-spool-entries-by-filter-in-page'
    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        reportSpoolId: entry.id,
      },
      false
    )

    if (success) {
      setEntries(data)
    }

    setIsBusy(false)
  }

  const handleMenuClick = (r, mode) => {
    setModalMode(mode)
    setSelectedEntry(r)
  }

  const handleExport = async () => {
    // TODO: myke- add export functionality 19/07/2023
  }

  const handleResetAll = async () => {
    if (entries?.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'There are no entries to reset',
      })
    }

    if (entry?.status !== batchEntryStatus.POSTED)
      return showNotification(
        'Operation not allowed',
        'error',
        'You can only reset posted batches.'
      )

    const url = '/ReportTemplate/unpost-report-spool-entries'

    setIsBusy(true)

    const { success } = await putData(
      url,
      {
        reportSpoolId: entry.id,
      },
      false
    )

    if (success) {
      fetchEntries()
      showNotification('Report Spool Entries Reset', 'success')
    }

    setIsBusy(false)
  }

  const handleResetEntry = async r => {
    setIsBusy(true)
    const url = '/ReportTemplate/unpost-report-spool-entry'

    const { success } = await putData(
      url,
      {
        reportSpoolEntryId: r?.id,
      },
      false
    )

    if (success) {
      fetchEntries()
      showNotification('Report Spool Entry Reset', 'success')
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setModalMode(null)
    setSelectedEntry(null)
  }

  useEffect(() => {
    fetchEntries()
    // eslint-disable-next-line
  }, [reqParams.pageIndex, reqParams.pageSize, entry])

  return (
    <>
      {modalMode && (
        <ViewTemplateBasedReportItem
          handleClose={handleClose}
          mode={modalMode}
          selectedEntry={selectedEntry}
        />
      )}

      <div className="d-flex align-items-center row mb-3"></div>
      {isBusy ? (
        <Loader />
      ) : (
        <div className="p-2">
          <div className="d-flex align-items-center row mb-3">
            <div className="col-md-3 d-flex align-items-center">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-evenly">
              <label htmlFor="search" className="text-capitalize">
                {localize('entries')}
              </label>
              <input
                type="text"
                disabled
                value={entries?.statusEntries}
                className="form-control"
                style={{
                  maxWidth: 'fit-content',
                }}
              />
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end gap-2">
              <div className="d-flex align-items-center">
                <label className="text-capitalize me-1" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 140,
                    minWidth: 140,
                  }}
                  enumsKey="BatchEntryStatus"
                  value={reqParams?.status}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchEntries()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: '50dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable
              columns={columns}
              data={entries?.pageCollection}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleMenuClick(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleResetEntry(r)
                    }}
                  >
                    <i className="uil uil-history-alt"></i>
                    <span>{localize('reset')}</span>
                  </div>
                </>
              )}
            />
          </div>
          <Pager
            itemsCount={entries.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />

          <div className="mt-3 bg-light rounded p-2 d-flex gap-2 align-items-center justify-content-end">
            <button className="btn btn-danger" onClick={handleResetAll}>
              <span className="me-2">{localize('reset')}</span>
            </button>
            <ExportButton handleExportButton={handleExport} />
          </div>
        </div>
      )}
    </>
  )
}

export default BatchEntries
