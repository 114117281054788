/** @format */

import { useState } from 'react'
import localize from '../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../Helpers/formatters'
import Checkbox from '../../Checkbox/Checkbox'
import SimpleTable from '../../Tables/SimpleTable'
import ViewCustomerAccountStatementModal from './CustomerAccountStatement/ViewCustomerAccountStatementModal'

export const ViewCustomerLoanAccounts = ({
  customerLoanAccounts,
  handleAdHocLoan,
  hideContextMenu,
  hiddenColumnsIndexes = [],
  selectedLoanAccounts,
  handleSelectLoanAccount,
  canShowStatement,
}) => {
  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedLoanAccounts?.find(acc => acc?.id === r?.id)}
          onCheck={value => handleSelectLoanAccount(r, value)}
        />
      ),
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => (
        <>{r?.customerAccountTypeTargetProductSectionDescription}</>
      ),
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r?.bookBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('principalBalance'),
      template: r => <>{formatCurrency(r?.principalBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('interestBalance'),
      template: r => <>{formatCurrency(r?.interestBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanNumber'),
      template: r => <>{r?.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r?.loanClassificationDescription}</>,
    },
    {
      label: localize('loanAmount'),
      template: r => <>{formatCurrency(r?.loanCaseTotalAmount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r?.loanBalanceExpected)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r?.loanPrepaidAmount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r?.loanDefaultAmount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => <>{r?.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvision(Percentage)'),
      template: r => <>{formatAsPercent(r?.loanLossProvisionPercentage)}</>,
    },
    {
      label: localize('loanLossProvision(Amount)'),
      template: r => <>{formatCurrency(r?.loanLossProvisionAmount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => <>{formatDate(r?.loanCaseDisbursedDate, true)}</>,
    },
    {
      label: localize('loanGracePeriod'),
      template: r => <>{r?.loanCaseLoanRegistrationGracePeriod}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{formatDate(r?.loanLastPaymentDueDate, true)}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r?.loanLastPayment}</>,
    },
    {
      label: localize('loanTerm(Months)'),
      template: r => <>{r?.loanCaseLoanRegistrationTermInMonths}</>,
    },
    {
      label: localize('loanAnnualPercentageRate'),
      template: r => (
        <>
          {formatAsPercent(r?.loanCaseLoanInterestCalculationModeDescription)}
        </>
      ),
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r => (
        <>{r?.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => <>{r?.loanCaseLoanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => <>{formatCurrency(r?.carryForwardsBalance)}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r?.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const columnsToDisplay = columns?.filter(
    (_c, i) => hiddenColumnsIndexes?.indexOf(i) === -1
  )

  const [account, setAccount] = useState({})
  const [showStatement, setShowStatement] = useState(false)

  const handleViewStatement = r => {
    setAccount(r)
    setShowStatement(true)
  }

  const handleClose = () => {
    setShowStatement(false)
    setAccount({})
  }

  return (
    <>
      {showStatement && (
        <ViewCustomerAccountStatementModal
          customer={{
            id: account?.customerId,
          }}
          customerAccount={account}
          handleClose={handleClose}
        />
      )}
      <SimpleTable
        columns={columnsToDisplay}
        data={customerLoanAccounts}
        contextMenu={r => (
          <>
            {!hideContextMenu && (
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleAdHocLoan(r)
                }}
              >
                <i className="uil uil-bolt text-warning"></i>
                <span>{localize('capitalize')}</span>
              </div>
            )}
            {canShowStatement && (
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleViewStatement(r)
                }}
              >
                <i className="uil uil-eye"></i>
                <span>{localize('view')}</span>
              </div>
            )}
          </>
        )}
      />
    </>
  )
}
