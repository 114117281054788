/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../Global/Localize'
import {
  formatDate,
  getTwoWeeksFromDate,
  parseDate,
} from '../../../Helpers/formatters'
import { getData, postData, putData } from '../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import {
  IdentityCardType,
  populationRegisterFilter,
  populationRegisterQueryAuthOption,
  populationRegisterQueryStatus,
} from '../../../Global/enumeration'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import Pager from '../../../Components/Tables/Pager'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { CustomerPartial } from '../../../Components/ReUsables/Customer/CustomerPartial'
import { ViewCustomerSpecimen } from '../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import Tab from '../../../Components/Tabs/Tab'
import Swal from 'sweetalert2'
import DatePicker from '../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'

function AdhocPopulationRegisterQuery() {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, false)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('errorResponse'),
      template: r => <>{r.errorResponse}</>,
      sortBy: 'errorResponse',
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
      sortBy: 'branchDescription',
    },
    {
      label: localize('identityType'),
      template: r => <>{r.identityTypeDescription}</>,
      sortBy: 'identityTypeDescription',
    },
    {
      label: localize('identityNumber'),
      template: r => <>{r.identityNumber}</>,
      sortBy: 'identityNumber',
    },
    {
      label: localize('identitySerialNumber'),
      template: r => <>{r.identitySerialNumber}</>,
      sortBy: 'identitySerialNumber',
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
      sortBy: 'remarks',
    },
    {
      label: localize('idNumber'),
      template: r => <>{r.idNumber}</>,
      sortBy: 'idNumber',
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.serialNumber}</>,
      sortBy: 'serialNumber',
    },
    {
      label: localize('gender'),
      template: r => <>{r.gender}</>,
      sortBy: 'gender',
    },
    {
      label: localize('firstName'),
      template: r => <>{r.firstName}</>,
      sortBy: 'firstName',
    },
    {
      label: localize('otherName'),
      template: r => <>{r.otherName}</>,
      sortBy: 'otherName',
    },
    {
      label: localize('surname'),
      template: r => <>{r.surname}</>,
      sortBy: 'surname',
    },
    {
      label: localize('pin'),
      template: r => <>{r.pin}</>,
      sortBy: 'pin',
    },
    {
      label: localize('citizenship'),
      template: r => <>{r.citizenship}</>,
      sortBy: 'citizenship',
    },
    {
      label: localize('family'),
      template: r => <>{r.family}</>,
      sortBy: 'family',
    },
    {
      label: localize('clan'),
      template: r => <>{r.clan}</>,
      sortBy: 'clan',
    },
    {
      label: localize('ethnicGroup'),
      template: r => <>{r.ethnicGroup}</>,
      sortBy: 'ethnicGroup',
    },
    {
      label: localize('occupation'),
      template: r => <>{r.occupation}</>,
      sortBy: 'occupation',
    },
    {
      label: localize('placeOfBirth'),
      template: r => <>{r.placeOfBirth}</>,
      sortBy: 'placeOfBirth',
    },
    {
      label: localize('placeOfDeath'),
      template: r => <>{r.placeOfDeath}</>,
      sortBy: 'placeOfDeath',
    },
    {
      label: localize('placeOfLive'),
      template: r => <>{r.placeOfLive}</>,
      sortBy: 'placeOfLive',
    },
    {
      label: localize('registrationOffice'),
      template: r => <>{r.regOffice}</>,
      sortBy: 'regOffice',
    },
    {
      label: localize('dateOfBirth'),
      template: r => <>{formatDate(r.dateOfBirth, true)}</>,
      sortBy: 'dateOfBirth',
    },
    {
      label: localize('dateOfBirthFromPassport'),
      template: r => <>{formatDate(r.dateOfBirthFromPassport, true)}</>,
      sortBy: 'dateOfBirthFromPassport',
    },
    {
      label: localize('dateOfDeath'),
      template: r => <>{formatDate(r.dateOfDeath, true)}</>,
      sortBy: 'dateOfDeath',
    },
    {
      label: localize('dateOfIssue'),
      template: r => <>{formatDate(r.dateOfIssue, true)}</>,
      sortBy: 'dateOfIssue',
    },
    {
      label: localize('dateOfExpiry'),
      template: r => <>{formatDate(r.dateOfExpiry, true)}</>,
      sortBy: 'dateOfExpiry',
    },
    {
      label: localize('authorizedBy'),
      template: r => <>{r.authorizedBy}</>,
      sortBy: 'authorizedBy',
    },
    {
      label: localize('authorizationRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
      sortBy: 'authorizationRemarks',
    },
    {
      label: localize('authorizedDate'),
      template: r => <>{formatDate(r.authorizedDate, false)}</>,
    },
    {
      label: localize('thirdPartyResponse'),
      template: r => <>{r.thirdPartyResponse}</>,
      sortBy: 'thirdPartyResponse',
    },
    {
      label: localize('thirdPartyWalletResponse'),
      template: r => <>{r.thirdPartyWalletResponse}</>,
      sortBy: 'thirdPartyWalletResponse',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
      sortBy: 'createdBy',
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 0,
    filterText: '',
    populationRegisterFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [
    selectedAdhocPopulationRegisterQuery,
    setSelectedAdhocPopulationRegisterQuery,
  ] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(populationRegisterQueryAuthOption)[0]
  )

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'add'
        ? await postData(
            '/PopulationRegister/create-adhoc-population-register-query',
            selectedAdhocPopulationRegisterQuery,
            false,
            'Operation Completed Successfully'
          )
        : await putData(
            '/PopulationRegister/authorize-adhoc-population-register-query',
            {
              adhocPopulationRegisterQuery: {
                ...selectedAdhocPopulationRegisterQuery,
                /**@TODO: stands for the branchId of the logged in user */
                branchId: '0e58e292-bcf3-ed11-849a-402343441bbc',
              },
              populationRegisterQueryAuthOption: selectedAuth,
            },
            false,
            'Operation Completed Successfully'
          )

    if (success) {
      setMode(null)
      setSelectedAdhocPopulationRegisterQuery(null)
      setRefreshAt(!refreshAt)
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedAdhocPopulationRegisterQuery(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/PopulationRegister/find-adhoc-population-register-queries-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
    }
    setIsBusy(false)
  }

  const handleAdd = async () => {
    setMode('add')
    /**@TODO: alex-stands for the branchId of the logged in user-07/13/2023 */
    setSelectedAdhocPopulationRegisterQuery({
      branchId: '0e58e292-bcf3-ed11-849a-402343441bbc',
    })
  }

  const handleAdhocRequeryRegister = reg => {
    setIsBusy(false)
    const { success } = putData(
      '/PopulationRegister/requery-adhoc-population-register-query',
      reg,
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setMode(null)
      setSelectedAdhocPopulationRegisterQuery(null)
      setRefreshAt(!refreshAt)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('adhocRegisterQueries'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton handleClickAddButton={handleAdd} />
                  </div>
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  {error ? (
                    <div className="col-12 d-flex align-items-center justify-content-center text-danger">
                      <i className="uil uil-exclamation-circle m-2 fs-4"></i>
                      <div className="fs-5 text-danger">
                        {localize('failedToLoadData')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="d-flex align-items-center col-2">
                          <label
                            htmlFor="recordsPerPage"
                            className=""
                            style={{
                              marginLeft: -10,
                              textTransform: 'capitalize',
                            }}
                          >
                            {localize('recordsPerPage')}
                          </label>
                          <select
                            className="form-select ms-3"
                            style={{ maxWidth: 'fit-content' }}
                            defaultValue={reqParams.pageSize}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                pageIndex: 0,
                                pageSize: e.target.value,
                              })
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div className="d-flex align-items-center col-2">
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="PopulationRegisterQueryStatus"
                            value={reqParams?.status}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                status: e.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              className="text-capitalize"
                              htmlFor="searchByDate"
                            >
                              {localize('dateRange')}
                            </label>
                            <DatePicker
                              onSelect={v => {
                                if (v.custom) {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: true,
                                    startDate: v.startDate,
                                    endDate: v.endDate,
                                  })
                                } else {
                                  setReqParams({
                                    ...reqParams,
                                    customDate: false,
                                    dateRequestsFilter: v.value,
                                  })
                                }
                              }}
                            />
                          </div>
                        </div>
                        <form
                          className="d-flex align-items-center col-4 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                            })
                          }}
                        >
                          <label
                            className="text-capitalize"
                            htmlFor="searchTable"
                          >
                            {localize('search')}
                          </label>
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="PopulationRegisterFilter"
                            value={reqParams?.populationRegisterFilter}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                populationRegisterFilter: e.value,
                              })
                            }
                          />
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.filterText}
                            onChange={e => {
                              setSearch(e.target.value)
                              setReqParams({
                                ...reqParams,
                                filterText: e.target.value,
                              })
                            }}
                          />
                          <button
                            id="AdhocQueryFilterButton"
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              loadTableData()
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </form>
                      </div>
                      <div className="row col-12 mt-2">
                        <SimpleTable
                          contextMenu={r => (
                            <>
                              <div
                                onClick={async () => {
                                  setMode('view')
                                  setSelectedAdhocPopulationRegisterQuery({
                                    identityTypeDescription:
                                      r.identityTypeDescription,
                                    identityNumber: r.identityNumber,
                                    identitySerialNumber:
                                      r.identitySerialNumber,
                                    statusDescription: r.statusDescription,
                                    remarks: r.remarks,
                                  })
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-eye"></i>
                                {localize('view')}
                              </div>
                              <div
                                onClick={async () => {
                                  if (r.status == 0) {
                                    setMode('authorize')
                                    setSelectedAdhocPopulationRegisterQuery({
                                      id: r.id,
                                      identityType: r.identityType,
                                      identityTypeDescription:
                                        r.identityTypeDescription,
                                      identityNumber: r.identityNumber,
                                      identitySerialNumber:
                                        r.identitySerialNumber,
                                      status: r.status,
                                      statusDescription: r.statusDescription,
                                      remarks: r.remarks,
                                    })
                                  } else {
                                    Swal.fire(
                                      'Ooops!',
                                      'Sorry, but you have to select an entry whose status is new',
                                      'info'
                                    )
                                  }
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-unlock-alt"></i>
                                {localize('authorize')}
                              </div>
                              <div
                                onClick={async () => {
                                  if (r.errorResponse === null) {
                                    Swal.fire(
                                      'Ooops!',
                                      'Sorry, but you must first select an ad hoc population register query that has an error response',
                                      'info'
                                    )
                                  } else {
                                    setMode('requery')
                                    handleAdhocRequeryRegister(r)
                                  }
                                }}
                                className="ctxt-menu-item"
                              >
                                <i className="uil uil-corner-down-left"></i>
                                {localize('requery')}
                              </div>
                            </>
                          )}
                          data={tableData}
                          columns={columns}
                        />
                      </div>
                      <div className="row col-12 mt-2">
                        <Pager
                          {...tableMeta}
                          onPageChange={p =>
                            setReqParams({ ...reqParams, pageIndex: p })
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && mode !== 'requery' && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize(mode)}
            <i className="uil uil-angle-right mx-2"></i>
            <span className="text-muted fs-6 text-uppercase">
              {localize('adhocRegisterQueries')}
            </span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white">
            <div className="row">
              <div className="col-12">
                <div className="card bg-white">
                  <div className="card-body">
                    <div className="row mb-3 g-3">
                      <div className="col-md-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize me-3">
                          {localize('status')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-3"
                          disabled
                          defaultValue={
                            selectedAdhocPopulationRegisterQuery?.statusDescription ||
                            ''
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex align-items-center mb-2">
                          <label className="text-capitalize col-3 me-3">
                            {localize('identityCardType')}
                          </label>
                          {mode !== 'add' ? (
                            <>
                              <input
                                value={
                                  selectedAdhocPopulationRegisterQuery?.identityTypeDescription
                                }
                                disabled={mode === 'view'}
                                type="text"
                                className="form-control ms-3"
                              />
                            </>
                          ) : (
                            <EnumsServerCombo
                              customContainerStyle={{ width: '100%' }}
                              enumsKey="IdentityCardType"
                              value={
                                selectedAdhocPopulationRegisterQuery?.identityTypeDescription
                              }
                              onChange={e =>
                                setSelectedAdhocPopulationRegisterQuery({
                                  ...selectedAdhocPopulationRegisterQuery,
                                  identityType: e.value,
                                })
                              }
                              disabled={mode !== 'add'}
                            />
                          )}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div
                          className={`${
                            mode === 'add' ? 'with-validation' : ''
                          } d-flex align-items-center mb-2`}
                          validation-message={
                            globalValidationErrors['IdentityNumber']?.message
                          }
                        >
                          <label className="text-capitalize col-4 me-3">
                            {localize('identityCardNumber')}
                          </label>
                          <input
                            defaultValue={
                              selectedAdhocPopulationRegisterQuery?.identityNumber
                            }
                            disabled={mode !== 'add'}
                            type="text"
                            className="form-control ms-4"
                            onChange={e => {
                              clearValidation('IdentityNumber')
                              setSelectedAdhocPopulationRegisterQuery({
                                ...selectedAdhocPopulationRegisterQuery,
                                identityNumber: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex align-items-center mb-2">
                          <label className="text-capitalize col-4 me-3">
                            {localize('identityCardSerialNumber')}
                          </label>
                          <input
                            defaultValue={
                              selectedAdhocPopulationRegisterQuery?.identitySerialNumber
                            }
                            disabled={mode === 'view'}
                            type="text"
                            className="form-control ms-4"
                            onChange={e => {
                              setSelectedAdhocPopulationRegisterQuery({
                                ...selectedAdhocPopulationRegisterQuery,
                                identitySerialNumber: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 g-3">
                      <div className="col-md-12">
                        <div
                          className={`${
                            mode === 'add' ? 'with-validation' : ''
                          } d-flex align-items-center mb-2`}
                          validation-message={
                            globalValidationErrors['Remarks']?.message
                          }
                        >
                          <label className="text-capitalize col-1 me-3">
                            {localize('remarks')}
                          </label>
                          <input
                            disabled={mode !== 'add'}
                            defaultValue={
                              selectedAdhocPopulationRegisterQuery?.remarks
                            }
                            type="text"
                            className="form-control ms-3"
                            onChange={e => {
                              clearValidation('Remarks')
                              setSelectedAdhocPopulationRegisterQuery({
                                ...selectedAdhocPopulationRegisterQuery,
                                remarks: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {mode === 'view' ? (
                      <>
                        <div className="row mb-2">
                          <div className="col-4 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('idNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.idNumber ||
                                ''
                              }
                            />
                          </div>
                          <div className="col-8 d-flex align-items-center">
                            <label className="col-2 text-capitalize">
                              {localize('errorResponse')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.errorResponse ||
                                ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('serialNumber')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.serialNumber ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('gender')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.gender ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('firstName')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.firstName ||
                                ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('otherName')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.otherName ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('surname')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.surname ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('pin')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.pin || ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('citizenship')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.citizenship ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('family')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.family ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('clan')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.clan || ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('ethnicGroup')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.ethnicGroup ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('occupation')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.occupation ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('placeOfBirth')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.placeOfBirth ||
                                ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('placeOfDeath')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.placeOfDeath ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('placeOfLive')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.placeOfLive ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('regOffice')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.regOffice ||
                                ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfBirth')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.dateOfBirth ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfBirthFromPassport')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.dateOfBirthFromPassport ||
                                ''
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfDeath')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.dateOfDeath ||
                                ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-4 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfIssue')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.dateOfIssue ||
                                ''
                              }
                            />
                          </div>
                          <div className="col-md-4 d-flex align-items-center">
                            <label className="col-4 text-capitalize">
                              {localize('dateOfExpiry')}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              disabled
                              value={
                                selectedAdhocPopulationRegisterQuery?.dateOfExpiry ||
                                ''
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    {mode === 'view' ? (
                      <div className="row mb-3">
                        <div className="col md-12">
                          <Tab
                            tabItems={[
                              {
                                label: localize('specimen'),
                                item: (
                                  <ViewCustomerSpecimen
                                    customer={
                                      selectedAdhocPopulationRegisterQuery?.customerId
                                    }
                                  />
                                ),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="bg-white">
            {mode !== 'view' ? (
              <>
                {mode === 'authorize' ? (
                  <>
                    <div className="col-md-3">
                      <div className="d-flex align-items-center mb-1">
                        <label className="text-capitalize col-4 me-2">
                          {localize('authorizationRemarks')}
                        </label>
                        <input
                          defaultValue={
                            selectedAdhocPopulationRegisterQuery?.authorizationRemarks
                          }
                          type="text"
                          className="form-control ms-4"
                          onChange={e => {
                            setSelectedAdhocPopulationRegisterQuery({
                              ...selectedAdhocPopulationRegisterQuery,
                              authorizationRemarks: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="d-flex align-items-center mb-2">
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="PopulationRegisterQueryAuthOption"
                          onChange={e =>
                            setSelectedAuth({
                              populationRegisterQueryAuthOption: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <button
                  className="btn btn-danger text-capitalize"
                  onClick={closeModal}
                >
                  {localize('cancel')}
                </button>
                <button
                  className="btn btn-success text-capitalize"
                  onClick={handleSubmit}
                >
                  {localize(mode === 'add' ? 'create' : 'update')}
                </button>
              </>
            ) : (
              <button
                className="btn btn-danger text-capitalize"
                onClick={closeModal}
              >
                {localize('cancel')}
              </button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default AdhocPopulationRegisterQuery
