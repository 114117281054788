/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'
import localize from '../../Global/Localize'

function DividendCodeLookup({ displayValue, onSelect, disabled }) {
  const columns = [
    { label: 'code', template: r => <>{r.code}</> },
    { label: 'name', template: r => <>{r.description}</> },
    {
      label: 'isLocked',
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    { label: 'createdDate', template: r => <>{formatDate(r.createdDate)}</> },
  ]
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filter: '',
  })
  return (
    <>
      <SimpleLookup
        disabled={disabled}
        displayValue={displayValue}
        columns={columns}
        params={reqParams}
        url={'/lookup/dividendCode/find-by-filter-in-page'}
        title={localize('dividendCode')}
        onSelect={onSelect}
      />
    </>
  )
}

export default DividendCodeLookup
