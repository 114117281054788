/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { budgetAdjustmentType } from '../../../../../Global/enumeration'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../Helpers/webApi'
import CreateSupplements from './CreateSupplements'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'

function Supplements() {
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [sortedBy, setSortedBy] = useState(null)
  const [tableData, setTableData] = useState([])
  const [modalMode, setModalMode] = useState(null)
  const [suppliment, setSuppliment] = useState({})
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    customDate: false,
    dateRequestsFilter: 1,
  })

  const columns = [
    {
      label: localize('sourceBudget'),
      template: r => <>{r?.sourceBudgetHierarchyDescription}</>,
    },
    {
      label: localize('destinationBudget'),
      template: r => <>{r?.destinationBudgetHierarchyDescription}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r?.postingPeriodDescription}</>,
    },
    {
      label: localize('description'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r?.valueDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const fetchSupplementsData = async () => {
    setIsBusy(true)
    const url = '/Budget/find-budget-supplements-by-filter-in-page'
    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setData(data)
    }
    setIsBusy(false)
  }

  const handleAddButtonClick = () => {
    setModalMode('add')
  }

  const handleClose = () => {
    setModalMode(null)
  }

  const handleCreateSupplements = async () => {
    const url = '/Budget/create-budget-supplement'

    const { success } = await postData(
      url,

      { suppliment },
      false,
      'Operation Successful'
    )

    if (success) {
      handleClose()
      fetchSupplementsData()
      setSuppliment({
        budgetAdjustmentType: Object.keys(budgetAdjustmentType)[0],
      })
      showNotification('Budget Supplement', 'success')
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const evaluateValueDate = async () => {
    setIsBusy(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${suppliment?.valueDate}`

    const { success, data } = await putData(url, false)

    if (success && !data?.result) {
      setIsBusy(false)
      setSuppliment({
        ...suppliment,
        valueDate: null,
      })

      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (suppliment?.valueDate) evaluateValueDate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliment?.valueDate])

  useEffect(() => {
    fetchSupplementsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('budgetManagement'),
          localize('budgetSupplements'),
        ]}
      />
      {modalMode === 'add' && (
        <CreateSupplements
          handleAction={handleCreateSupplements}
          handleClose={handleClose}
          mode={modalMode}
          suppliment={suppliment}
          setSuppliment={setSuppliment}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex mb-4">
                <AddNewButton handleClickAddButton={handleAddButtonClick} />
              </div>
              <div className="d-flex mb-4">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="col-4 ms-4 d-flex align-items-center">
                  <label htmlFor="date" className="text-capitalize col-3">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                  <label className="text-capitalize" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <form
                    className="d-flex align-items-center"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                        pageIndex: 0,
                      })
                    }}
                  >
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-3"
                      defaultValue={reqParams?.filterText}
                      onChange={e => setSearch(e.target.value)}
                    ></input>
                  </form>
                  <button
                    id="FilterButton"
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchSupplementsData()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <SimpleTable
                  columns={columns}
                  data={data?.pageCollection || []}
                  onSort={(c, i) => handleSort(c, i)}
                />
              </div>
              <Pager
                itemsCount={data?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Supplements
