/** @format */

import React from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'

const MovementCatalogue = () => {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.inventoryItemRegisterDescription}</>,
    },
    {
      label: localize('details'),
      template: r => <>{r.details}</>,
    },
    {
      label: localize('quantity'),
      template: r => <>{formatCurrency(r.quantity)}</>,
    },
    {
      label: localize('unitCost'),
      template: r => <>{formatCurrency(r.unitCost)}</>,
    },
    {
      label: localize('balance'),
      template: r => <>{formatCurrency(r.balance)}</>,
    },
    {
      label: localize('source'),
      template: r => <>{r.source}</>,
    },
    {
      label: localize('destination'),
      template: r => <>{r.destination}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <ListingPage
        noAdd
        map={[
          localize('procurementAndAssetsManagement'),
          localize('inventory'),
          localize('movementCatalogue'),
        ]}
        columns={columns}
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        url="/InventoryMovementHistory/find-inventory-movement-histories-by-filter-in-page"
      />
    </>
  )
}

export default MovementCatalogue
