/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

const PackageTypeLookup = ({ onSelect, displayValue }) => {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, true)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        title={localize('packageTypeLookup')}
        url={
          '/inventoryPackageType/find-inventory-package-types-by-filter-in-page'
        }
        params={{
          text: '',
          pageIndex: 0,
          pageSize: 10,
        }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default PackageTypeLookup
