/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import CustomerLookupPartial from '../../../../../Components/ReUsables/Customer/CustomerLookupPartial'
import localize from '../../../../../Global/Localize'
import { LoanProductLookup } from '../../../../../Components/Lookup/LoanProductLookup'
import LoanPurposeLookup from '../../../../../Components/Lookup/LoanPurposeLookup'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Tab from '../../../../../Components/Tabs/Tab'
import LoanGuarantors from '../LoanApplications/LoanGuarantors'

function AddLoanRequest() {
  const [customer, setCustomer] = useState(null)
  const [loanProduct, setLoanProduct] = useState(null)
  const [loanPurpose, setLoanPurpose] = useState(null)

  const tabItems = [
    {
      label: localize('guarantors'),
      item: <LoanGuarantors loanProduct={null} />,
      icon: 'uil uil-edit',
    },
    { label: localize('standingOrders'), item: <>standingOrders</> },
    { label: localize('payoutsHistory'), item: <>payoutsHistory</> },
    { label: localize('loanRequests'), item: <>loanRequests</> },
  ]

  return (
    <>
      <CustomerLookupPartial onLookup={c => setCustomer(c)} />
      <hr />
      <div className="d-flex align-items-center my-2 gap-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('loanProduct')}
          </label>

          <LoanProductLookup
            onSelect={l => setLoanProduct(l)}
            displayValue={loanProduct?.description}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('loanProductSection')}
          </label>
          <input type="text" className="form-control" disabled />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('annualPercentageRate')}
          </label>
          <input type="text" className="form-control" disabled />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('interestCalculationMode')}
          </label>
          <input type="text" className="form-control" disabled />
        </div>
      </div>
      <div className="d-flex align-items-center mt-2 gap-3 mb-4">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('loanPurpose')}
          </label>

          <LoanPurposeLookup
            onSelect={l => setLoanPurpose(l)}
            displayValue={loanPurpose?.description}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('receivedDate')}
          </label>
          <CustomDatePicker />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('amountApplied')}
          </label>
          <MaskNumber />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('term')} ({localize('months')})
          </label>
          <input type="number" className="form-control" />
        </div>
      </div>
      <Tab tabItems={tabItems} />
    </>
  )
}

export default AddLoanRequest
