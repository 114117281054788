/** @format */

import localize from '../../../Global/Localize'
import Tab from '../../../Components/Tabs/Tab'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import {
  alternateChannelType,
  ConfigurableBankToMobileTransactionRequest,
  ConfigurableBrokerTransactionRequest,
  ConfigurableInterSwitchTransactionType,
  ConfigurableSaccoLinkTransactionRequest,
  ConfigurableSpotCashMobileBankingTransactionType,
} from '../../../Global/enumeration'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import { MaskNumber } from '../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../Helpers/formatters'
import { getData, postData } from '../../../Helpers/webApi'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import Swal from 'sweetalert2'
import { generateGuid } from '../../../Helpers/extensions'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'

export const AlternateChannelConfiguration = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedAlternateChannelType, setSelectedAlternateChannelType] =
    useState('64')
  const [
    selectedAlternateChannelTransactionType,
    setSelectedAlternateChannelTransactionType,
  ] = useState(ConfigurableBankToMobileTransactionRequest)
  const [
    alternateChannelConfigurationEntries,
    setAlternateChannelConfigurationEntries,
  ] = useState([])

  const [
    selectedAlternateChannelTransactionTypeDetail,
    setSelectedAlternateChannelTransactionTypeDetail,
  ] = useState('24')
  const [
    alternateChannelConfigurationModel,
    setAlternateChannelConfigurationModel,
  ] = useState(null)

  const [
    alternateChannelConfigurationEntryModel,
    setAlternateChannelConfigurationEntryModel,
  ] = useState({
    transactionType: selectedAlternateChannelTransactionTypeDetail,
    transactionTypeDescription:
      selectedAlternateChannelTransactionType[
        selectedAlternateChannelTransactionTypeDetail
      ].name,
    limit: '',
    isLocked: '',
  })

  const specialAlternateChannelTypeTransactions = ['256', '512', '64', '1', '8']

  useEffect(() => {
    getAlternateChannelConfigurationEntries(selectedAlternateChannelType)
  }, [])

  async function handleSubmit(finalData) {
    setIsBusy(true)
    const { success, data } = await postData(
      '/AlternateChannelConfiguration/update-alternate-channel-configuration-with-entries',
      finalData,
      false
    )

    if (success) {
      setIsBusy(false)
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Update Alternate Channel Configuration',
        showCloseButton: true,
      })
    } else {
      setIsBusy(false)
    }
  }

  const getAlternateChannelConfigurationEntries =
    async alternateChannelType => {
      setIsBusy(true)
      const { success, data } = await getData(
        '/AlternateChannelConfiguration/get-alternate-channel-configuration-entries',
        {
          alternateChannelType: alternateChannelType,
        },
        false
      )
      if (success) {
        setIsBusy(false)
        setAlternateChannelConfigurationEntries(data.entries || [])
        if (data.alternateChannel.description) {
          setAlternateChannelConfigurationModel({
            ...alternateChannelConfigurationModel,
            description: data.alternateChannel.description,
            dailyLimit: data.alternateChannel.dailyLimit,
            id: data.alternateChannel.id,
            type: data.alternateChannel.type,
          })
        } else {
          setAlternateChannelConfigurationModel(null)
        }
      } else {
        setIsBusy(false)
      }
    }

  const overrideAlternateChannelType = alternateChannelType => {
    switch (alternateChannelType) {
      case '256':
        setSelectedAlternateChannelTransactionType(
          ConfigurableBrokerTransactionRequest
        )
        setSelectedAlternateChannelTransactionTypeDetail('13')
        break
      case '512':
        setSelectedAlternateChannelTransactionType(
          ConfigurableInterSwitchTransactionType
        )
        setSelectedAlternateChannelTransactionTypeDetail('31')
        break
      case '64':
        setSelectedAlternateChannelTransactionType(
          ConfigurableBankToMobileTransactionRequest
        )
        setSelectedAlternateChannelTransactionTypeDetail('24')
        break
      case '1':
        setSelectedAlternateChannelTransactionType(
          ConfigurableSaccoLinkTransactionRequest
        )
        setSelectedAlternateChannelTransactionTypeDetail('9')
        break
      case '8':
        setSelectedAlternateChannelTransactionType(
          ConfigurableSpotCashMobileBankingTransactionType
        )
        setSelectedAlternateChannelTransactionTypeDetail('700')
        break
      default:
        break
    }
  }

  const addAlternateChannelConfigurationEntry = () => {
    if (alternateChannelConfigurationEntryModel.limit.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Add Alternate Channel Configuration Entry',
        text: 'Please enter a limit',
      })
      return
    }

    if (
      alternateChannelConfigurationEntries.findIndex(
        x =>
          x.transactionTypeDescription ===
          alternateChannelConfigurationEntryModel.transactionTypeDescription
      ) > -1
    ) {
      Swal.fire({
        icon: 'error',
        title: `${alternateChannelConfigurationEntryModel.transactionTypeDescription} already exists`,
        text: 'Duplicate entry',
      })
      return
    }

    const newAlternateChannelConfigurationEntry = {
      transactionType: alternateChannelConfigurationEntryModel.transactionType,
      transactionTypeDescription:
        alternateChannelConfigurationEntryModel.transactionTypeDescription,
      limit: alternateChannelConfigurationEntryModel.limit,
      isLocked: alternateChannelConfigurationEntryModel.isLocked
        ? alternateChannelConfigurationEntryModel.isLocked
        : false,
      clientId: generateGuid(),
    }
    setAlternateChannelConfigurationEntries([
      ...alternateChannelConfigurationEntries,
      newAlternateChannelConfigurationEntry,
    ])
    setAlternateChannelConfigurationEntryModel({
      transactionType: selectedAlternateChannelTransactionTypeDetail,
      transactionTypeDescription:
        selectedAlternateChannelTransactionType[
          selectedAlternateChannelTransactionTypeDetail
        ].name,
      limit: '',
      isLocked: '',
    })
  }

  const onChangeLockedStatus = r => {
    const newAlternateChannelConfigurationEntries = [
      ...alternateChannelConfigurationEntries,
    ]
    const index = newAlternateChannelConfigurationEntries.findIndex(
      x => x.transactionType === r.transactionType
    )
    newAlternateChannelConfigurationEntries[index].isLocked =
      !newAlternateChannelConfigurationEntries[index].isLocked
    setAlternateChannelConfigurationEntries(
      newAlternateChannelConfigurationEntries
    )
  }

  const onRemoveEntry = entry => {
    setAlternateChannelConfigurationEntries(
      alternateChannelConfigurationEntries.filter(item => {
        const itemClientId = item.clientId || item.id
        const entryClientId = entry.clientId || entry.id
        return itemClientId !== entryClientId
      })
    )
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('alternateChannelConfiguration'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-auto me-2 text-capitalize">
                        {localize('type')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="AlternateChannelType"
                        value={selectedAlternateChannelType}
                        onChange={c => {
                          setAlternateChannelConfigurationEntries([])
                          overrideAlternateChannelType(c.value)
                          getAlternateChannelConfigurationEntries(c.value)
                          setSelectedAlternateChannelType(c.value)
                          setAlternateChannelConfigurationModel({
                            ...alternateChannelConfigurationModel,
                            type: c.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-auto me-2 text-capitalize">
                        {localize('description')}
                      </label>
                      <input
                        className="form-control"
                        defaultValue={
                          alternateChannelConfigurationModel?.description
                        }
                        onChange={c => {
                          setAlternateChannelConfigurationModel({
                            ...alternateChannelConfigurationModel,
                            description: c.target.value,
                          })
                        }}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-auto me-2 text-capitalize">
                        {localize('dailyLimit')}
                      </label>
                      <MaskNumber
                        defaultMaskValue={
                          alternateChannelConfigurationModel
                            ? alternateChannelConfigurationModel.dailyLimit
                            : ''
                        }
                        onMaskNumber={c => {
                          setAlternateChannelConfigurationModel({
                            ...alternateChannelConfigurationModel,
                            dailyLimit: stripNonNumericCharacters(
                              c.target.value
                            ),
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <Tab
                      tabItems={[
                        {
                          label: localize('transactionTypeEntries'),
                          item: (
                            <TransactionTypeEntries
                              onAddAlternateChannelConfigurationEntry={
                                addAlternateChannelConfigurationEntry
                              }
                              onChangeLockedStatus={onChangeLockedStatus}
                              setSelectedAlternateChannelTransactionTypeDetail={
                                setSelectedAlternateChannelTransactionTypeDetail
                              }
                              onRemoveEntry={onRemoveEntry}
                              alternateChannelConfigurationEntries={
                                alternateChannelConfigurationEntries
                              }
                              showTransactionTypeEntrySelect={specialAlternateChannelTypeTransactions.includes(
                                selectedAlternateChannelType
                              )}
                              selectedAlternateChannelTransactionTypeDetail={
                                selectedAlternateChannelTransactionTypeDetail
                              }
                              selectedAlternateChannelTransactionType={
                                selectedAlternateChannelTransactionType
                              }
                              alternateChannelConfigurationEntryModel={
                                alternateChannelConfigurationEntryModel
                              }
                              setAlternateChannelConfigurationEntryModel={
                                setAlternateChannelConfigurationEntryModel
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      onClick={() => {
                        handleSubmit({
                          AlternateChannelConfiguration:
                            alternateChannelConfigurationModel,
                          AlternateChannelConfigurationEntries:
                            alternateChannelConfigurationEntries,
                        })
                      }}
                      className="btn btn-success text-uppercase px-4"
                      type="button"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function TransactionTypeEntries({
  selectedAlternateChannelTransactionType,
  selectedAlternateChannelTransactionTypeDetail,
  setSelectedAlternateChannelTransactionTypeDetail,
  showTransactionTypeEntrySelect,
  alternateChannelConfigurationEntries,
  onAddAlternateChannelConfigurationEntry,
  onRemoveEntry,
  alternateChannelConfigurationEntryModel,
  setAlternateChannelConfigurationEntryModel,
  onChangeLockedStatus,
}) {
  return (
    <>
      <div className="row mt-3 mb-3" style={{ marginTop: -10 }}>
        {showTransactionTypeEntrySelect && (
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize me-3">
              {localize('transactionType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="SelectedAlternateChannelTransactionType"
              value={selectedAlternateChannelTransactionTypeDetail}
              onChange={e => {
                setSelectedAlternateChannelTransactionTypeDetail(e.value)
                setAlternateChannelConfigurationEntryModel({
                  ...alternateChannelConfigurationEntryModel,
                  transactionType: e.value,
                  transactionTypeDescription:
                    selectedAlternateChannelTransactionType[e.value].name,
                })
              }}
            />
          </div>
        )}
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize me-3">
            {localize('limit')}
          </label>
          <MaskNumber
            defaultMaskValue={alternateChannelConfigurationEntryModel?.limit}
            onMaskNumber={c => {
              setAlternateChannelConfigurationEntryModel({
                ...alternateChannelConfigurationEntryModel,
                limit: c.target.value,
              })
            }}
          />
        </div>
      </div>
      <div
        className="bg-light d-flex justify-content-end align-items-center p-2"
        style={{ borderRadius: 5 }}
      >
        <div className="d-flex align-items-center me-5">
          <Checkbox
            id="alternate-channel-locked"
            checked={alternateChannelConfigurationEntryModel?.isLocked}
            onCheck={v =>
              setAlternateChannelConfigurationEntryModel({
                ...alternateChannelConfigurationEntryModel,
                isLocked: v,
              })
            }
          />
          <label
            htmlFor="alternate-channel-locked"
            className="text-capitalize ms-2"
          >
            {`${localize('isLocked')}?`}
          </label>
        </div>
        <button
          onClick={onAddAlternateChannelConfigurationEntry}
          className="btn btn-success text-uppercase ms-3 px-4"
        >
          {localize('add')}
        </button>
      </div>

      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 120,
            marginBottom: -10,
          }}
        >
          {localize('transactionTypeEntries')}
        </div>
        <TransactionTypeEntriesDetails
          addMode
          onRemoveTransactionTypeEntry={onRemoveEntry}
          onChangeLockedStatus={onChangeLockedStatus}
          alternateChannelConfigurationEntries={
            alternateChannelConfigurationEntries
          }
        />
      </div>
    </>
  )
}

function TransactionTypeEntriesDetails({
  addMode,
  onRemoveTransactionTypeEntry,
  alternateChannelConfigurationEntries,
  onChangeLockedStatus,
}) {
  const columns = [
    {
      label: localize('transactionType'),
      template: r => <>{r?.transactionTypeDescription}</>,
    },
    {
      label: localize('limit'),
      template: r => <>{r?.limit}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
  ]

  return (
    <SimpleTable
      contextMenu={r =>
        addMode && (
          <>
            <div
              onClick={() => {
                onRemoveTransactionTypeEntry(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-times-circle text-danger text-capitalize"></i>
              {localize('remove')}
            </div>
            <div
              onClick={() => {
                onChangeLockedStatus(r)
              }}
              className="ctxt-menu-item"
            >
              <i
                className={`uil ${
                  r.isLocked ? 'uil-unlock' : 'uil-lock'
                }  text-capitalize`}
              ></i>
              {localize(r.isLocked ? 'unlock' : 'lock')}
            </div>
          </>
        )
      }
      columns={columns}
      data={alternateChannelConfigurationEntries}
    />
  )
}
