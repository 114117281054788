/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import AddressV2 from '../../../../../Components/Miscalleneous/AddressV2'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { formatDate, parseDate } from '../../../../../Helpers/formatters'
import Tab from '../../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData, putData } from '../../../../../Helpers/webApi'
import { generateGuid } from '../../../../../Helpers/extensions'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

function NextOfKin({ enrollment, readOnly }) {
  const columns = [
    { label: 'vitalStatus', template: r => <>{r?.vitalStatusDescription}</> },
    { label: 'name', template: r => <>{r?.fullName}</> },
    { label: 'birthDate', template: r => <>{formatDate(r?.birthDate)}</> },
    { label: 'relationship', template: r => <>{r?.relationshipDescription}</> },
    { label: 'gender', template: r => <>{r?.genderDescription}</> },
    {
      label: 'identityCardType',
      template: r => <>{r?.identityCardTypeDescription}</>,
    },
    {
      label: `${localize('identityCardNumber')}`,
      template: r => <>{r?.identityCardNumber}</>,
    },
    {
      label: `${localize('identityCardSerial')} #`,
      template: r => <>{r?.identityCardSerialNumber}</>,
    },
    {
      label: 'nominatedPercentage',
      template: r => <>{r?.nominatedPercentage}</>,
    },
    {
      label: 'vitalStatusDate',
      template: r => <>{formatDate(r?.vitalStatusDate)}</>,
    },
    {
      label: `${localize('addressLine')} 1`,
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: `${localize('addressLine')} 2`,
      template: r => <>{r?.addressAddressLine2}</>,
    },
    { label: 'physicalAddress', template: r => <>{r?.addressStreet}</> },
    { label: 'postalCode', template: r => <>{r?.addressPostalCode}</> },
    { label: 'city', template: r => <>{r?.addressCity}</> },
    { label: 'emailAddress', template: r => <>{r?.addressEmail}</> },
    { label: 'landLine', template: r => <>{r?.addressLandLine}</> },
    { label: 'mobileLine', template: r => <>{r?.addressMobileLine}</> },
    { label: 'interntAddress', template: r => <>{r?.addressInternet}</> },
    { label: 'remarks', template: r => <>{r?.remarks}</> },
    {
      label: `${localize('nextOfKin')}?`,
      template: r => (
        <span className="text-capitalize">{(!!r?.isNextOfKin).toString()}</span>
      ),
    },
    {
      label: `${localize('nominee')}?`,
      template: r => (
        <span className="text-capitalize">{(!!r?.isNominee).toString()}</span>
      ),
    },
    {
      label: `${localize('benevolent')} (${localize('spouse')})`,
      template: r => (
        <span className="text-capitalize">
          {(!!r?.isBenevolentSpouse).toString()}
        </span>
      ),
    },
    {
      label: `${localize('benevolent')} (${localize('child')})`,
      template: r => (
        <span className="text-capitalize">
          {(!!r?.isBenevolentChild).toString()}
        </span>
      ),
    },
  ]

  const [current, setCurrent] = useState({})
  const [noks, setNoks] = useState([])

  useEffect(() => {
    loadNextOfKins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadNextOfKins() {
    const { success, data } = await getData(
      '/enrollmentRequest/find-enrollment-request-nextofkins-by-enrollment-request-id',
      { enrollmentRequestId: enrollment?.id }
    )
    if (success) {
      setNoks(data?.result || [])
    }
  }

  function addNOK() {
    let staged = { ...current, id: generateGuid() }
    let nList = [...noks]
    nList.push(staged)
    setNoks(nList)
    setCurrent({})
  }

  function removeNOK(r) {
    let staged = [...noks]
    // eslint-disable-next-line eqeqeq
    let ind = staged.findIndex(n => n.id == r.id)
    staged.splice(ind, 1)
    setNoks(staged)
  }

  async function updateInfo() {
    await putData(
      `/enrollmentRequest/update-enrollment-request-next-of-kins-by-enrollment-request-id?enrollmentRequestId=${enrollment?.id}`,
      noks,
      true,
      'Next Of Kin(s) Updated!!'
    )
    await loadNextOfKins()
  }

  return readOnly ? (
    <>
      <SimpleTable data={noks} columns={columns} />
    </>
  ) : (
    <div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('vitalStatus')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="VitalStatus"
            value={current?.vitalStatus}
            onChange={e => setCurrent({ ...current, vitalStatus: e.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Salutation"
            value={current?.salutation}
            onChange={e => setCurrent({ ...current, salutation: e.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('gender')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            value={current?.gender}
            onChange={e => setCurrent({ ...current, gender: e.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('relationship')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="NextOfKinRelationship"
            value={current?.relationship}
            onChange={e => setCurrent({ ...current, relationship: e.value })}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('firstName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={current?.firstName}
            onChange={e =>
              setCurrent({ ...current, firstName: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('otherNames')}
          </label>
          <input
            type="text"
            className="form-control"
            value={current?.lastName}
            onChange={e => setCurrent({ ...current, lastName: e.target.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('birthDate')}
          </label>
          <input
            type="date"
            className="form-control"
            value={parseDate(current?.birthDate)}
            onChange={e =>
              setCurrent({ ...current, birthDate: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('educationLevel')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="EducationLevel"
            value={current?.educationLevel}
            onChange={e => setCurrent({ ...current, educationLevel: e.value })}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            value={current?.identityCardType}
            onChange={e =>
              setCurrent({ ...current, identityCardType: e.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCard')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={current?.identityCardNumber}
            onChange={e =>
              setCurrent({ ...current, identityCardNumber: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCardSerial')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={current?.identityCardSerialNumber}
            onChange={e =>
              setCurrent({
                ...current,
                identityCardSerialNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('nominated')} %
          </label>
          <input
            type="number"
            className="form-control"
            value={current?.nominatedPercentage}
            onChange={e =>
              setCurrent({ ...current, nominatedPercentage: e.target.value })
            }
          />
        </div>
      </div>
      <div className="row mb-2 ">
        <div className="col-3 d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('vitalStatusDate')}
          </label>
          <input
            type="date"
            className="form-control"
            value={parseDate(current?.vitalStatusDate)}
            onChange={e =>
              setCurrent({ ...current, vitalStatusDate: e.target.value })
            }
          />
        </div>
        <div className="col-6 d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-2">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            value={current?.remarks}
            onChange={e => setCurrent({ ...current, remarks: e.target.value })}
          />
        </div>
      </div>

      <div className="bg-light p-4 my-2" style={{ borderRadius: 10 }}>
        <AddressV2
          onChange={a => setCurrent({ ...current, ...a })}
          parent={current}
        />
      </div>
      <div className="bg-light p-2 d-flex align-items-center justify-content-end">
        <div className="d-flex align-items-center mx-3">
          <Checkbox
            checked={current?.isNextOfKin}
            onCheck={v => setCurrent({ ...current, isNextOfKin: v })}
            id="nokNOK"
          />
          <label htmlFor="nokNOK" className="text-capitalize ms-2">
            {localize('nextOfKin')}?
          </label>
        </div>
        <div className="d-flex align-items-center mx-3">
          <Checkbox
            checked={current?.isNominee}
            onCheck={v => setCurrent({ ...current, isNominee: v })}
            id="nokNominee"
          />
          <label htmlFor="nokNominee" className="text-capitalize ms-2">
            {localize('nominee')}?
          </label>
        </div>
        <div className="d-flex align-items-center mx-3">
          <Checkbox
            checked={current?.isBenevolentSpouse}
            onCheck={v => setCurrent({ ...current, isBenevolentSpouse: v })}
            id="nokSpouse"
          />
          <label htmlFor="nokSpouse" className="text-capitalize ms-2">
            {localize('benevolent')} ({localize('spouse')})?
          </label>
        </div>
        <div className="d-flex align-items-center mx-3">
          <Checkbox
            checked={current?.isBenevolentChild}
            onCheck={v => setCurrent({ ...current, isBenevolentChild: v })}
            id="nokChild"
          />
          <label htmlFor="nokChild" className="text-capitalize ms-2">
            {localize('benevolent')} ({localize('child')})?
          </label>
        </div>
        <button onClick={addNOK} className="btn btn-info px-5 ms-3">
          {localize('add')}
        </button>
      </div>
      <div className="m-y2">
        <Tab
          tabItems={[
            {
              label: localize('nextOfKinCollection'),
              item: (
                <SimpleTable
                  data={noks}
                  columns={columns}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item text-danger"
                        onClick={() => removeNOK(r)}
                      >
                        <i className="uil uil-times-circle"></i>
                        <span className="text-capitalize">
                          {localize('remove')}
                        </span>
                      </div>
                    </>
                  )}
                />
              ),
            },
          ]}
        />
      </div>
      <div
        className="bg-light d-flex align-items-center justify-content-end p-2 mt-2"
        style={{ borderRadius: 10 }}
      >
        <button className="btn btn-success px-5" onClick={updateInfo}>
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default NextOfKin
