/** @format */

import React, { useState } from 'react'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import { formatDate } from '../../Helpers/formatters'
import localize from '../../Global/Localize'

const GoodsReceivedNoteLookup = ({
  onSelect,
  displayValue,
  supplierId,
  supplierDescription,
}) => {
  const [reqParams, setReqParams] = useState({
    recordStatus: 2,
    status: 2,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('goodsReceivedNoteLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{supplierDescription}</>
        </>
      }
      url="/lookup/GoodsReceivedNote/find-goods-received-notes-by-supplier-id-and-statuses-filter-in-page"
      params={{
        supplierId,
        ...reqParams,
      }}
      conditionalField="supplierId"
      columns={[
        {
          label: localize('reference#'),
          template: r => <>{r.paddedReferenceNumber}</>,
        },
        {
          label: localize('recordStatus'),
          template: r => <>{r.recordStatusDescription}</>,
        },
        {
          label: localize('grnStatus'),
          template: r => <>{r.statusDescription}</>,
        },
        {
          label: localize('branch'),
          template: r => <>{r.branchDescription}</>,
        },
        {
          label: localize('remarks'),
          template: r => <>{r.remarks}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default GoodsReceivedNoteLookup
