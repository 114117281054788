/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ListEmployers from '../../Employers/ListEmployers/ListEmployers'

const EmployersTab = ({
  handleSelectedEmployersChange,
  handleSelectAllEmployers,
  handleUpdateEmployers,
  resetValues,
  setResetValues,
}) => {
  const [selectedEmployers, setSelectedEmployers] = useState([])

  const handleSelectAll = (employers, value) => {
    if (value) {
      setSelectedEmployers(employers.pageCollection)
    } else {
      setSelectedEmployers([])
    }
  }

  const handleSelectEmployers = (employer, value) => {
    if (value) {
      setSelectedEmployers([...selectedEmployers, employer])
    } else {
      setSelectedEmployers(selectedEmployers.filter(c => c.id !== employer.id))
    }
  }

  const toggleSelectAllEmployers = value => {
    handleSelectAllEmployers(value)
  }

  useEffect(() => {
    handleSelectedEmployersChange(selectedEmployers)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployers])

  useEffect(() => {
    if (resetValues) {
      setSelectedEmployers([])
      setResetValues(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      <ListEmployers
        showToggleButtons
        showUpdateButton
        handleSelectAll={handleSelectAll}
        selectedEmployers={selectedEmployers}
        handleSelectEmployers={handleSelectEmployers}
        handleUpdate={handleUpdateEmployers}
        toggleSelectAllEmployers={toggleSelectAllEmployers}
      />
    </>
  )
}

export default EmployersTab
