/** @format */

import React, { useState, useEffect } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import LocationLookup from '../../../../../../Components/Lookup/LocationLookup'
import { ItemRegisterLookup } from '../../../../../../Components/Lookup/ItemRegisterLookup'
import { generateGuid } from '../../../../../../Helpers/extensions'
import DirectPurchaseEntriesItems from './DirectPurchaseEntriesItems'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

const AddDirectPurchase = ({
  edit,
  mode,
  closeModal,
  directPurchase,
  setDirectPurchase,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [entry, setEntry] = useState(null)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/DirectPurchase/find-direct-purchase-item-by-direct-purchase-id-and-filter-in-page`,
      {
        directPurchaseId: directPurchase?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [directPurchase?.id, reqParams.pageSize, reqParams.pageIndex])

  const addEntryToTheDB = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/DirectPurchase/update-direct-purchase-items',
      { directPurchaseId: directPurchase?.id, directPurchaseItems: [entry] },
      false
    )
    if (success) {
      showNotification('Direct Purchase', 'success')
      loadEntries()
      setEntry(null)
    }
    setIsBusy(false)
  }

  async function postDirectPurchase() {
    setIsBusy(true)
    const { success } = await postData(
      '/DirectPurchase/add-direct-purchase',
      {
        directPurchase: { directPurchase, remarks: directPurchase?.remarks },
        directPurchaseItems: entries,
      },
      false
    )
    if (success) {
      showNotification('Direct Purchase', 'success')
      closePage()
      loadTableData()
      setEntry(null)
    }
    setIsBusy(false)
  }

  async function editDirectPurchase() {
    setIsBusy(true)
    const { success } = await postData(
      '/DirectPurchase/update-direct-purchase',
      {
        directPurchase: {
          ...directPurchase,
          id: directPurchase.id,
          remarks: directPurchase?.remarks,
        },
        directPurchaseItems: entries,
      },
      false
    )
    if (success) {
      showNotification('Direct Purchase', 'success')
      closePage()
      loadTableData()
      setEntry(null)
    }
    setIsBusy(false)
  }

  const onSelectItem = (cust, checked) => {
    if (checked) {
      setEntries([...entries, cust])
    } else {
      const newEntry = entries.filter(
        data => data?.directPurchaseId !== cust?.directPurchaseId
      )
      setEntries(newEntry)
    }
  }

  async function stageEntry() {
    if (entries.find(d => d.itemRegisterId === entry.itemRegisterId)) {
      showNotification(
        'Direct Purchase',
        'info',
        'Sorry, but the selected item is already added !'
      )
    } else {
      setEntries([
        ...entries,
        {
          directPurchaseId: directPurchase?.id,
          itemRegisterId: entry?.itemRegisterId,
          itemRegisterDescription: entry?.itemRegisterDescription,
          itemRegisterItemTypeDescription:
            entry?.itemRegisterItemTypeDescription,
          itemRegisterInventoryCategoryDescription:
            entry?.itemRegisterInventoryCategoryDescription,
          itemRegisterAssetTypeDescription:
            entry?.itemRegisterAssetTypeDescription,
          locationId: entry?.locationId,
          locationDescription: entry?.locationDescription,
          quantity: entry?.quantity,
          unitCost: entry?.unitCost,
          model: entry?.model,
          manufacturer: entry?.manufacturer,
          serialNumber: entry?.serialNumber,
          remarks: entry?.remarks,
        },
      ])
      setEntry(null)
      setEntry({ quantity: '', unitCost: '' })
    }
  }

  const handleRemove = entry => {
    const filteredData = entries.filter(d => d.id !== entry.id)
    setEntries(filteredData)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const closePage = () => {
    setGlobalValidationErrors({})
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={edit ? 'edit' : 'add'}
      modalTitle="directPurchase"
      handleClose={closePage}
      modalClassName="modal-xl"
      actionText={edit ? 'update' : 'create'}
      handleAction={() => {
        if (mode === 'add') {
          postDirectPurchase()
        } else {
          editDirectPurchase()
        }
      }}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center mb-2 with-validation"
              validation-message={
                globalValidationErrors['DirectPurchase.Remarks']?.message
              }
            >
              <label className="text-capitalize col me-3">
                {localize('remarks')}
              </label>
              <input
                className="form-control ms-4"
                onChange={e => {
                  clearValidation('DirectPurchase.Remarks')
                  setDirectPurchase({
                    ...directPurchase,
                    remarks: e.target.value,
                  })
                }}
                defaultValue={directPurchase?.remarks || ''}
              />
            </div>
          </div>

          <>
            <div
              className="p-2 mt-2"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('itemDetails')}
              </div>

              <>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize me-3">
                      {localize('item')}
                    </label>
                    <ItemRegisterLookup
                      displayValue={entry?.itemRegisterDescription}
                      onSelect={r => {
                        setEntry({
                          ...entry,
                          itemRegisterId: r.id,
                          itemRegisterDescription: r.description,
                          itemRegisterItemTypeDescription:
                            r.itemTypeDescription,
                          itemRegisterInventoryCategoryDescription:
                            r.inventoryCategoryDescription,
                          itemRegisterAssetTypeDescription:
                            r.assetTypeDescription,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-1 text-capitalize me-4">
                      {localize('location')}
                    </label>
                    <LocationLookup
                      displayValue={entry?.locationDescription}
                      onSelect={r => {
                        setEntry({
                          ...entry,
                          locationId: r.id,
                          locationDescription: r.description,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize">
                      {localize('quantity')}
                    </label>
                    <MaskNumber
                      className="form-control ms-3"
                      defaultMaskValue={entry?.quantity}
                      onMaskNumber={e => {
                        setEntry({
                          ...entry,
                          quantity: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-1 text-capitalize me-2">
                      {localize('unitCost')}
                    </label>
                    <MaskNumber
                      className="form-control ms-3"
                      defaultMaskValue={entry?.unitCost}
                      onMaskNumber={e => {
                        setEntry({
                          ...entry,
                          unitCost: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize">
                      {localize('model')}
                    </label>
                    <input
                      className="form-control ms-3"
                      onChange={e =>
                        setEntry({
                          ...entry,
                          model: e.target.value,
                        })
                      }
                      value={entry?.model || ''}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-1 text-capitalize me-2">
                      {localize('manufacturer')}
                    </label>
                    <input
                      className="form-control ms-3"
                      onChange={e =>
                        setEntry({
                          ...entry,
                          manufacturer: e.target.value,
                        })
                      }
                      value={entry?.manufacturer || ''}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize">
                      {localize('serialNumber')}
                    </label>
                    <input
                      className="form-control ms-3"
                      onChange={e =>
                        setEntry({
                          ...entry,
                          serialNumber: e.target.value,
                        })
                      }
                      value={entry?.serialNumber || ''}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-1 text-capitalize me-2">
                      {localize('remarks')}
                    </label>
                    <input
                      className="form-control ms-3"
                      onChange={e =>
                        setEntry({
                          ...entry,
                          remarks: e.target.value,
                        })
                      }
                      value={entry?.remarks || ''}
                    />
                  </div>
                </div>
              </>
            </div>

            <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
              <button
                onClick={() => {
                  if (mode === 'add') {
                    if (!entry?.itemRegisterDescription) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please select item'
                      )
                    } else if (!entry?.locationDescription) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please select location'
                      )
                    } else if (!entry?.quantity) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please enter quantity'
                      )
                    } else if (!entry?.unitCost) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please enter unitCost'
                      )
                    } else if (!entry?.model) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please enter model'
                      )
                    } else if (!entry?.manufacturer) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please enter manufacturer'
                      )
                    } else if (!entry?.serialNumber) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please enter serialNumber'
                      )
                    } else if (!entry?.remarks) {
                      showNotification(
                        'Direct Purchase',
                        'info',
                        'please enter remarks'
                      )
                    } else {
                      stageEntry()
                    }
                  } else {
                    addEntryToTheDB()
                  }
                }}
                className="btn btn-success px-5"
              >
                {localize('add')}
              </button>
            </div>
            <div className="mt-3">
              <Tab
                tabItems={[
                  {
                    label: localize('items'),
                    item: (
                      <DirectPurchaseEntriesItems
                        edit={edit}
                        unpostedEntries={entries}
                        handleRemove={handleRemove}
                        mode={mode}
                        entries={existingEntries}
                        onSelectItem={onSelectItem}
                        loadEntries={loadEntries}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        </>
      )}
    </ModalTemplate>
  )
}

export default AddDirectPurchase
