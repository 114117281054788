/** @format */

import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'
import React from 'react'

export const QuotationQuotationItemsLookup = ({
  quotationDescription,
  quotationId,
  onSelect,
  displayValue,
}) => {
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('quotationItemsLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{quotationDescription}</>
        </>
      }
      url="/Quotation/find-quotations-items-by-quotation-id-filter-in-page"
      params={{
        quotationId: quotationId,
        text: '',
        pageIndex: 0,
        pageSize: 10,
      }}
      conditionalField="quotationId"
      columns={[
        {
          label: localize('itemType'),
          template: r => <>{r.itemRegisterItemTypeDescription}</>,
        },
        {
          label: localize('item'),
          template: r => <>{r.itemRegisterDescription}</>,
        },
        {
          label: localize('inventoryCategory'),
          template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
        },
        {
          label: localize('assetType'),
          template: r => <>{r.itemRegisterAssetTypeDescription}</>,
        },
        {
          label: localize('unitOfMeasure'),
          template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
        },
        { label: localize('quantity'), template: r => <>{r.quantity}</> },
        { label: localize('unitCost'), template: r => <>{r.unitCost}</> },
        { label: localize('totalCost'), template: r => <>{r.totalCost}</> },
        {
          label: localize('daysToDeliver'),
          template: r => <>{r.daysToDeliver}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
