/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerAccountPartial } from '../../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import { putData } from '../../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../../../Components/Loaders/Loader'

const FlagUnFlagVoucher = ({
  voucher,
  setVoucher,
  mode,
  handleClose,
  fetchVouchers,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  const handleFlagUnFlagVoucher = async () => {
    setIsBusy(true)

    const url = '/ChequeBook/flag-payment-voucher'

    const { success } = await putData(
      url,
      {
        ...voucher,
      },
      false
    )

    if (success) {
      fetchVouchers()
      handleClose()
      showNotification('Flag/UnFlag Payment Voucher', 'success')
    }
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="flag/UnFlagPaymentVoucher"
      modalClassName="modal-xl"
      handleAction={handleFlagUnFlagVoucher}
      handleClose={handleClose}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial
            customer={{
              id: voucher?.chequeBookCustomerAccountId,
            }}
            readOnly
            refreshFromServer
          />
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('paymentVoucherDetails')}
            </div>

            <div className="row g-3">
              <div className="d-flex align-items-center col-md-4">
                <label className="text-capitalize col-4">
                  {localize('voucherNumber')}
                </label>
                <input
                  className="form-control"
                  value={voucher?.paddedVoucherNumber}
                  disabled
                />
              </div>
              <div className="d-flex align-items-center col-md-4">
                <label className="text-capitalize col-2">
                  {localize('status')}
                </label>
                <input
                  className="form-control"
                  value={voucher?.statusDescription}
                  disabled
                />
              </div>
              <div className="d-flex align-items-center col-md-4">
                <label className="text-capitalize col-4">
                  {localize('chequeBookNumber')}
                </label>
                <input
                  className="form-control"
                  value={voucher?.paddedChequeBookSerialNumber}
                  disabled
                />
              </div>
              <div className="d-flex align-items-center col-md-4">
                <label className="text-capitalize col-4">
                  {localize('action')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    width: '100%',
                  }}
                  value={voucher?.paymentVoucherManagementAction}
                  enumsKey="PaymentVoucherManagementAction"
                  onChange={({ value }) =>
                    setVoucher({
                      ...voucher,
                      paymentVoucherManagementAction: value,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center col-md-8">
                <label className="text-capitalize col-1">
                  {localize('remarks')}
                </label>
                <input
                  className="form-control"
                  value={voucher?.reference}
                  onChange={e => {
                    setVoucher({
                      ...voucher,
                      reference: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default FlagUnFlagVoucher
