/** @format */

import localize from '../../../../../Global/Localize'
/* eslint-disable */
import React from 'react'

export const FingerPrintSpecimen = ({ specimen }) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('thumb')}(${localize('leftHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('indexFinger')}(${localize('leftHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('middleFinger')}(${localize('leftHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('ringFinger')}(${localize('leftHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('pinkyFinger')}(${localize('leftHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('thumb')}(${localize('rightHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('indexFinger')}(${localize('rightHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('middleFinger')}(${localize('rightHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('ringFinger')}(${localize('rightHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
        <div className="col">
          <div
            className="p-2 mt-2"
            style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
          >
            <div
              className="bg-white row px-2 text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {`${localize('pinkyFinger')}(${localize('rightHand')})`}
            </div>
            <img
              src=""
              alt=""
              srcSet=""
              style={{
                width: '100%',
                height: '100%',
                minHeight: 250,
                background: '#0002',
                border: 'none',
                borderRadius: 5,
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3 mt-3">
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('preferredFingerDigit')}
          </label>
          <input className="form-control" disabled />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">{localize('remarks')}</label>
          <input className="form-control" disabled />
        </div>
      </div>
    </>
  )
}
