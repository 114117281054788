/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _q = {
  quoteCurrency: {
    en: 'quote currency',
    sw: 'nukuu sarafu',
  },
  quoteCurrencyCode: {
    en: 'quote currency code',
    sw: 'msimbo',
  },
  question: {
    en: 'question',
    sw: 'swali',
  },
  querying: {
    en: 'querying',
    sw: 'kuuliza',
  },
  qualification: {
    en: 'qualification',
    sw: 'sifa',
  },
  quickText: {
    en: 'quick text',
    sw: 'maandishi ya haraka',
  },
  quickEmail: {
    en: 'quick email',
    sw: 'barua pepe ya haraka',
  },
  quantity: {
    en: 'quantity',
    sw: 'wingi',
  },
  quotations: {
    en: 'quotations',
    sw: 'nukuu',
  },
  quantityRequested: {
    en: 'quantity requested',
    sw: 'wingi ulioombwa',
  },
  quotationDate: {
    en: 'quotation date',
    sw: 'tarehe ya nukuu',
  },
  quotationItems: {
    en: 'quotation items',
    sw: 'vitengo vya nukuu',
  },
}
export default _q
