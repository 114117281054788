/** @format */
import React, { useState } from 'react'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import { formatDate } from '../../Helpers/formatters'
import localize from '../../Global/Localize'

const PurchaseOrderLookup = ({
  onSelect,
  displayValue,
  supplierId,
  supplierDescription,
}) => {
  const [reqParams, setReqParams] = useState({
    recordStatus: 2,
    status: 1,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('purchaseOrderLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{supplierDescription}</>
        </>
      }
      url="/lookup/PurchaseOrder/find-purchase-orders-by-supplier-id-and-statuses-filter-in-page"
      params={{
        supplierId,
        ...reqParams,
      }}
      conditionalField="supplierId"
      columns={[
        {
          label: localize('referenceNumber'),
          template: r => <>{r.paddedReferenceNumber}</>,
        },
        {
          label: localize('expectedDate'),
          template: r => <>{formatDate(r.expectedDate)}</>,
        },
        {
          label: localize('recordStatus'),
          template: r => <>{r.recordStatusDescription}</>,
        },
        {
          label: localize('status'),
          template: r => <>{r.statusDescription}</>,
        },
        {
          label: localize('branch'),
          template: r => <>{r.branchDescription}</>,
        },
        { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default PurchaseOrderLookup
