/** @format */

import React, { useState } from 'react'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'

export const AlternateChannelReconciliationPeriodImportModal = ({
  handleUpload,
  handleCloseCreditBatchImportModal,
}) => {
  const [showAdvanced, setShowAdvanced] = useState(false)

  return (
    <ActionModal
      modalTitle="alternateChannelReconciliationPeriod"
      handleClose={handleCloseCreditBatchImportModal}
      handleAction={handleUpload}
      confirmText="upload"
      cancelText="cancel"
    >
      <div className="row">
        <div className="col-12">
          <h5 className="text-center">
            See advanced for alternate channel reconciliation period file format.
          </h5>
        </div>

        <div className="col-12">
          <h6
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="text-decoration-underline"
            style={{ cursor: 'pointer', transition: 'all 0.3s ease' }}
          >
            Show advanced
          </h6>
        </div>

        {showAdvanced && (
          <div className="col-12 d-flex align-items-start flex-column rounded p-2">
            <p>Row 1 = Header Record</p>
            <p>Row 1, Column 1 = RetrievalReferenceNumber/SystemAuditTraceNumber/CallbackPayload</p>
            <p>Row 1, Column 2 = PrimaryAccountNumber</p>
            <p>Row 1, Column 3 = Amount</p>
            <p>Row 1, Column 4 = Your Reference</p>
          </div>
        )}
      </div>
    </ActionModal>
  )
}
