/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'

function ExemptionsSection({ product, readonly }) {
  const [exemptions, setExemptions] = useState([])
  const [exemption, setExemption] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('minimumBalance'),
      headerTextAlign: 'end',
      template: r => <>{formatCurrency(r.minimumBalance)}</>,
    },
    {
      label: localize('operatingBalance'),
      headerTextAlign: 'end',
      template: r => <>{formatCurrency(r.operatingBalance)}</>,
    },
    {
      label: localize('annualPercentageYield'),
      template: r => <>{r.annualPercentageYield}%</>,
    },
    {
      label: localize('maximumWithdrawal'),
      headerTextAlign: 'end',
      template: r => <>{formatCurrency(r.maximumAllowedWithdrawal)}</>,
    },
    {
      label: localize('maximumAllowedDeposit'),
      headerTextAlign: 'end',
      template: r => <>{formatCurrency(r.maximumAllowedDeposit)}</>,
    },
    {
      label: `${localize('withdrawalNoticePeriod')} (${localize('days')})`,
      template: r => <>{r.withdrawalNoticePeriod}</>,
    },
    {
      label: localize('withdrawalNoticeAmount'),
      headerTextAlign: 'end',
      template: r => <>{formatCurrency(r.withdrawalNoticeAmount)}</>,
    },
    {
      label: `${localize('withdrawalInterval')} (${localize('days')})`,
      template: r => <>{r.withdrawalInterval}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  useEffect(() => {
    loadExemptions()
    // eslint-disable-next-line
  }, [refresh])

  async function loadExemptions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/savingsProduct/find-savings-product-exemption-by-savings-product-id',
      { savingsProductId: product?.id },
      false,
      false,
      false
    )
    if (success) {
      setExemptions(data?.result)
    }
    setIsBusy(false)
  }

  async function handleAddExemption() {
    setIsBusy(true)
    const { success } = await postData(
      '/savingsProduct/add-savings-product-exemption',
      { ...exemption, savingsProductId: product?.id },
      false,
      false
    )
    if (success) {
      setExemption({})
      Swal.fire('Operation Successful', 'Exemption Added', 'success')
      setRefresh(!refresh)
    }

    setIsBusy(false)
  }

  async function handleRemoveExemption(r) {
    let exs = [...exemptions]
    let ind = exs.findIndex(i => i.id === r.id)
    exs.splice(ind, 1)
    if (ind > -1) {
      setIsBusy(true)
      const { success } = await putData(
        '/savingsProduct/update-savings-product-exemption-by-savings-product-id/' +
          product?.id,
        [...exs],
        false,
        false
      )
      if (success) {
        Swal.fire(
          'Operation Successful',
          'Exemptions Updated: ' + exs.length,
          'success'
        )
        setRefresh(!refresh)
      }

      setIsBusy(false)
    } else {
      Swal.fire('Error', "Can't find the exemption", 'error')
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      {readonly || (
        <div className="">
          <div className="row mb-2">
            <div className="col d-flex align-items-center ">
              <label className="text-capitalize col-4">
                {localize('branch')}
              </label>
              <BranchLookup
                displayValue={exemption?.branchDescription}
                onSelect={b =>
                  setExemption({
                    ...exemption,
                    branchDescription: b?.description,
                    branchId: b?.id,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('operatingBalance')}
              </label>
              <MaskNumber
                type="number"
                className="form-control"
                defaultMaskValue={exemption?.operatingBalance}
                onMaskNumber={e =>
                  setExemption({
                    ...exemption,
                    operatingBalance: stripNonNumericCharacters(e.target.value),
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('withdrawalNoticeAmount')}
              </label>
              <MaskNumber
                defaultMaskValue={exemption?.withdrawalNoticeAmount}
                onMaskNumber={e =>
                  setExemption({
                    ...exemption,
                    withdrawalNoticeAmount: stripNonNumericCharacters(
                      e.target.value
                    ),
                  })
                }
                type="number"
                className="form-control"
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('maximumAllowedWithdrawal')}
              </label>
              <MaskNumber
                defaultMaskValue={exemption?.maximumAllowedWithdrawal}
                onMaskNumber={e =>
                  setExemption({
                    ...exemption,
                    maximumAllowedWithdrawal: stripNonNumericCharacters(
                      e.target.value
                    ),
                  })
                }
                type="number"
                className="form-control"
              />
            </div>
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('minimumBalance')}
              </label>
              <MaskNumber
                defaultMaskValue={exemption?.minimumBalance}
                onMaskNumber={e =>
                  setExemption({
                    ...exemption,
                    minimumBalance: stripNonNumericCharacters(e.target.value),
                  })
                }
                type="number"
                className="form-control"
              />
            </div>
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('withdrawalInterval')} ({localize('days')})
              </label>
              <input
                value={exemption?.withdrawalInterval}
                onChange={e =>
                  setExemption({
                    ...exemption,
                    withdrawalInterval: e.target.value,
                  })
                }
                type="number"
                className="form-control"
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('maximumAllowedDeposit')}
              </label>
              <MaskNumber
                defaultMaskValue={exemption?.maximumAllowedDeposit}
                onMaskNumber={e =>
                  setExemption({
                    ...exemption,
                    maximumAllowedDeposit: stripNonNumericCharacters(
                      e.target.value
                    ),
                  })
                }
                type="number"
                className="form-control"
              />
            </div>
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('withdrawalNoticePeriod')} ({localize('days')})
              </label>
              <input
                value={exemption?.withdrawalNoticePeriod}
                onChange={e =>
                  setExemption({
                    ...exemption,
                    withdrawalNoticePeriod: e.target.value,
                  })
                }
                type="number"
                className="form-control"
              />
            </div>
            <div className="col d-flex align-items-center ">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('annualPercentageYield')}
              </label>
              <input
                value={exemption?.annualPercentageYield}
                onChange={e =>
                  setExemption({
                    ...exemption,
                    annualPercentageYield: e.target.value,
                  })
                }
                type="number"
                className="form-control"
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={handleAddExemption}
                className="btn btn-success text-uppercase px-4"
              >
                <i className="uil uil-plus-circle me-2"></i>
                {localize('add')}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="row mb-2 p-2">
        <SimpleTable
          data={exemptions}
          columns={columns}
          contextMenu={
            readonly
              ? null
              : r => (
                  <>
                    <div
                      className="ctxt-menu-item text-danger"
                      onClick={async () => {
                        let res = await Swal.fire({
                          title: 'Confirm',
                          text: 'Are you sure you want to remove this exemption?',
                          showCancelButton: true,
                          confirmButtonText: 'Remove',
                          cancelButtonText: 'Cancel',
                          icon: 'question',
                        })
                        if (res.isConfirmed) {
                          handleRemoveExemption(r)
                        }
                      }}
                    >
                      <i className="uil uil-times-circle"></i>
                      <span className="text-capitalize">
                        {localize('remove')}
                      </span>
                    </div>
                  </>
                )
          }
        />
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default ExemptionsSection
