/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import { StandingOrderTrigger } from '../../../Global/enumeration'
import Checkbox from '../../Checkbox/Checkbox'

const StandingOrderTriggers = ({
  handleSelectTriggers,
  handleUpdate,
  resetValues,
  setResetValues,
  id,
}) => {
  const triggers = Object.entries(StandingOrderTrigger).map(trigger => ({
    valueDescription: trigger[1]?.name,
    value: trigger[0],
  }))

  const [selectedTriggers, setSelectedTriggers] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAllTriggers = (triggers, value) => {
    if (value) {
      setSelectedTriggers(triggers)
    } else {
      setSelectedTriggers([])
    }
  }

  const handleSelectIndividualTriggers = (trigger, value) => {
    if (value) {
      setSelectedTriggers([...selectedTriggers, trigger])
    } else {
      setSelectedTriggers(
        selectedTriggers.filter(t => t.value !== trigger.value)
      )
    }
  }

  useEffect(() => {
    handleSelectTriggers(selectedTriggers)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTriggers])

  useEffect(() => {
    if (resetValues) {
      setSelectedTriggers([])
      setResetValues(false)
      setSelectAll(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      <div className="py-4 px-2">
        <div className="d-flex flex-column align-items-start justify-content-start">
          {triggers.map(trigger => (
            <div className="d-flex align-items-center" key={trigger.value}>
              <Checkbox
                id={`selectTriggers-${trigger.value}-${id}`}
                checked={selectedTriggers.find(t => t.value === trigger?.value)}
                onCheck={v => handleSelectIndividualTriggers(trigger, v)}
              />
              <label
                htmlFor={`selectTriggers-${trigger.value}-${id}`}
                className="ms-2 text-capitalize"
              >
                {trigger?.valueDescription}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="py-3 row px-1 bg-light mt-3 mx-2 rounded">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex justify-content-start gap-3 align-items-center">
            <div className="d-flex align-items-center">
              <Checkbox
                id="selectAllTriggers"
                checked={selectAll}
                onCheck={v => {
                  setSelectAll(v)
                  handleSelectAllTriggers(triggers, v)
                }}
              />

              <label
                htmlFor="selectAllTriggers"
                className="ms-2 text-capitalize"
              >
                {localize('toggleSelection')}
              </label>
            </div>
          </div>

          <div className="d-flex align-items-center float-end">
            <button
              onClick={handleUpdate}
              className="btn btn-success float-end"
            >
              {localize('update')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default StandingOrderTriggers
