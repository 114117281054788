/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { ViewQuotationEntries } from './ViewQuotationEntries'
import { getData } from '../../../../../../Helpers/webApi'

export const ViewQuotationModal = ({ selectedQuotation }) => {
  const [reqParams, setReqParams] = useState({
    quotationId: selectedQuotation?.id,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [quotationEntries, setQuotationEntries] = useState([])
  const fetchQuotationItems = async () => {
    const { success, data } = await getData(
      '/Quotation/find-quotations-items-by-quotation-id-filter-in-page',
      {
        ...reqParams,
        quotationId: selectedQuotation?.id,
      },
      false
    )
    if (success) {
      setQuotationEntries(data)
    }
  }

  useEffect(() => {
    fetchQuotationItems()
  }, [selectedQuotation?.id])


  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedQuotation?.reference}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('supplier')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedQuotation?.supplierDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('mobilePhone')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedQuotation?.supplierAddressMobileLine}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('quotationDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedQuotation?.quotationDate, true)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('validityPeriod')}
          </label>
          <input
            type="number"
            className="form-control"
            value={selectedQuotation?.period}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('branch')}</label>
          <input
            className="form-control"
            value={selectedQuotation?.documentNumber}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('createdBy')}
          </label>
          <input
            className="form-control"
            value={selectedQuotation?.createdBy}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('createdDate')}
          </label>
          <input
            className="form-control"
            value={formatDate(selectedQuotation?.createdDate, true)}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('documentNo')}
          </label>
          <input
            className="form-control"
            value={selectedQuotation?.documentNumber}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('document')}
          </label>
          <input
            className="form-control"
            value={selectedQuotation?.document}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('title')}</label>
          <input
            className="form-control"
            value={selectedQuotation?.title}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('description')}
          </label>
          <input
            className="form-control"
            value={selectedQuotation?.description}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            value={selectedQuotation?.remarks}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120,
              }}
            >
              {localize('quotationItems')}
            </div>
            <ViewQuotationEntries
              entries={quotationEntries}
              setReqParams={setReqParams}
              reqParams={reqParams}
            />
          </div>
        </div>
      </div>
    </>
  )
}
