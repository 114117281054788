/** @format */

import usFlag from '../assets/images/flags/us.jpg'
import keFlag from '../assets/images/flags/kenya.png'

const languages = {
  en: {
    label: 'English',
    flag: usFlag,
    code: 'en',
  },
  sw: {
    label: 'Swahili',
    flag: keFlag,
    code: 'sw',
  },
}

export default languages
