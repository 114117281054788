/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../Helpers/formatters'
import Pager from '../../../../Components/Tables/Pager'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import CreateSupplierTypes from './Partials/CreateSupplierTypes'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import { type } from '@testing-library/user-event/dist/type'

const SupplierTypes = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [supplierTypes, setSupplierTypes] = useState([])
  const [types, setTypes] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    getSupplierTypesTableData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refresh])

  const getSupplierTypesTableData = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/SupplierType/find-supplier-types-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setSupplierTypes(data)
    }

    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('g/LAccountName'),
      template: r => <>{r?.chartOfAccountAccountName}</>,
    },
    {
      label: localize('gLAccountCostDistributionRule'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('g/LAccountName(GoodsRecievedNotInvoiced)'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize(
        'gLAccountCostDistributionRule(GoodsRecievedNotInvoiced)'
      ),
      template: r => (
        <>
          {
            r?.goodsReceivedNotInvoicedChartOfAccountCostDistributionRuleDescription
          }
        </>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => <>{r?.isLocked.toString()}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleCreateOrUpdate = async () => {
    setRefresh(false)
    const url =
      modalMode === 'add'
        ? '/SupplierType/add-supplier-type'
        : '/SupplierType/update-supplier-type'

    if (modalMode === 'add') {
      if (
        (!types.description,
        !types.chartOfAccountName || !types.chartOfAccountName)
      ) {
        GlobalValidationErrors.set({
          description: !types?.description && {
            message: 'The Name field is required.',
          },
          glAccount: !type?.chartOfAccountName && {
            message: 'The G/L Account identifier is invalid!',
          },
          goodsRecievedNotInvoicedGl:
            !types?.goodsReceivedNotInvoicedChartOfAccountName && {
              message:
                'The G/L Account ( Goods Recieved Not Invoiced ) identifier is invalid!',
            },
        })
        return
      }

      setLoading(true)
      const { success } = await postData(
        url,
        types,
        false,
        'Operation successful'
      )

      if (success) {
        setRefresh(true)
        setLoading(false)
        handleClose()
        showNotification('Supplier Type', 'success')
      }

      setLoading(false)
    } else if (modalMode === 'edit') {
      setLoading(true)
      setRefresh(false)

      const { success } = await putData(
        url,
        types,
        false,
        'Operation successful'
      )
      if (success) {
        handleClose()
        setRefresh(true)
        setLoading(false)
        showNotification('Supplier Type', 'success')
      }

      setLoading(false)
    }
  }

  const handleClose = () => {
    setModalMode(null)
  }

  const handleViewAuditLogs = item => {
    setTypes(item)
    setModalMode('auditLogs')
  }

  const clearValidationErrors = () => {
    clearValidation('description')
    clearValidation('goodsRecievedNotInvoicedGl')
    clearValidation('glAccount')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('supplierTypes'),
        ]}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateSupplierTypes
          handleAction={handleCreateOrUpdate}
          handleClose={handleClose}
          mode={modalMode}
          types={types}
          setTypes={setTypes}
          refresh={refresh}
          setRefresh={setRefresh}
          isLoading={loading}
          globalValidationErrors={globalValidationErrors}
          clearValidation={clearValidation}
        />
      )}
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={modalMode}
          data={types}
          title={types?.description}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-start mb-4">
              <AddNewButton
                handleClickAddButton={() => {
                  setModalMode('add')
                  setTypes({})
                  setRefresh(false)
                  clearValidationErrors()
                }}
              />
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3">
                <RecordsPerPage
                  className="col"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="col  d-flex align-items-center justify-content-end gap-2">
                  <div className="d-flex align-items-center ">
                    <label className=" text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <input
                        type="search"
                        className="form-control ms-2"
                        defaultValue={reqParams?.text}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                    <button
                      type="button"
                      className="btn btn-primary ms-1"
                      onClick={getSupplierTypesTableData}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <SimpleTable
                  columns={columns}
                  data={supplierTypes?.pageCollection}
                  contextMenu={x => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setModalMode('edit')
                          setTypes(x)
                          clearValidationErrors()
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(x)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
              </div>
              <Pager
                itemsCount={supplierTypes?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SupplierTypes
