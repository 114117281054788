/** @format */
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import React, { useEffect, useState } from 'react'
import IncomeAdjustmentsModal from './IncomeAdjustmentsModal'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import { getData } from '../../../../Helpers/webApi'
import { IncomeAdjustmentType } from '../../../../Helpers/constants'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function IncomeAdjustments() {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedIncomeAdjustment, setSelectedIncomeAdjustment] = useState(null)

  const columns = [
    { label: localize('paddedCode  '), template: r => <>{r?.paddedCode}</> },
    {
      label: localize('description'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('typeDescription'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const [sortedBy, setSortedBy] = useState(null)

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/IncomeAdjustments/find-income-adjustment-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleOnEdit = r => {
    setSelectedIncomeAdjustment({ ...r })
    setMode('edit')
  }

  const handleOnAdd = async r => {
    setSelectedIncomeAdjustment({
      type: IncomeAdjustmentType.ALLOWANCE,
    })
    setMode('add')
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('creditManagement'), localize('incomeAdjustments')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body" style={{ position: 'relative' }}>
              <div className="row mb-2">
                <div className="col-md-4 gap-2">
                  <div className="mb-3">
                    <AddNewButton handleClickAddButton={handleOnAdd} />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />

                <form
                  className="d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      text: search,
                      pageIndex: 0,
                    })
                  }}
                >
                  <label className={'text-capitalize'} htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    defaultValue={reqParams.text}
                    onChange={e => setSearch(e.target.value)}
                  ></input>
                  <button
                    type="button"
                    className="btn btn-primary ms-1"
                    onClick={() => {
                      setReqParams({
                        ...reqParams,
                        text: search,
                        pageIndex: 0,
                      })
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="row col-12 mt-2">
                <SimpleTable
                  contextMenu={e => (
                    <>
                      <div
                        onClick={() => {
                          handleOnEdit(e)
                        }}
                        className="ctxt-menu-item"
                      >
                        <i className="uil uil-edit"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(e)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                  data={tableData}
                  columns={columns}
                  onSort={(c, i) => handleSort(c, i)}
                  sortedBy={sortedBy}
                />
              </div>
              <div className="row col-12 mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
              {isBusy && <NonBlockingLoader />}
            </div>
          </div>
        </div>
      </div>
      <IncomeAdjustmentsModal
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        setSelectedIncomeAdjustment={setSelectedIncomeAdjustment}
        selectedIncomeAdjustment={selectedIncomeAdjustment}
        onSetMode={m => setMode(m)}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default IncomeAdjustments
