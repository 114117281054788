/** @format */

/* eslint-disable */
import React from 'react'
import '../selectables.scss'
import Checkbox from '../../Checkbox/Checkbox'
import Loader from '../../Loaders/Loader'

function Levies({ disabled, isBusy, leviesToBeSelected, onCheckLevy, prefix }) {
  const handleCheckLevies = (investmentProducts, checkValue) => {
    const com = [...leviesToBeSelected].map(comm => {
      if (comm.id === investmentProducts.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckLevy(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {leviesToBeSelected?.map(levy => (
            <li
              key={levy.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'levy'}-${levy.id}`}
                checked={levy.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckLevies(levy, c)
                }}
              />
              <label
                className={`fw-normal ms-2 cursor-pointer ${
                  levy.checked ? 'text-primary fw-bold' : ''
                }`}
                htmlFor={`${prefix ? prefix : 'levy'}-${levy.id}`}
              >
                {levy.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}

export default Levies
