/** @format */

import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import React from 'react'
import { GroupApportionmentSection } from './GroupApportionmentSection'

export const GroupApportionmentTransaction = ({
  transactionModel,
  setTransactionModel,
  onAddApportionMent,
  onRemoveApportionment,
  resetEntries,
}) => {
  return (
    <>
      <Tab
        preload
        tabItems={[
          {
            label: `${localize('apportionment')} (${localize(
              'loan',
            )} / ${localize('investment')} / ${localize(
              'savings',
            )} / ${localize('glAccount')})`,
            item: (
              <GroupApportionmentSection
                onAddApportionMent={onAddApportionMent}
                onRemoveApportionment={onRemoveApportionment}
                transactionModel={transactionModel}
                resetEntries={resetEntries}
                setTransactionModel={setTransactionModel}
              />
            ),
          },
        ]}
      />
    </>
  )
}
