/** @format */

import React from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import SimpleTable from './../../../Components/Tables/SimpleTable'
import { useEffect } from 'react'
import { getData } from '../../../Helpers/webApi'
import { useState } from 'react'
import { formatDate } from '../../../Helpers/formatters'
import Pager from '../../../Components/Tables/Pager'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../Components/Loaders/Loader'
import CreateRiskRegister from './Partials/CreateRiskRegister'
import ViewRiskRegister from './Partials/ViewRiskRegister'
import RecordsPerPage from './../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

const RisksRegister = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState({})
  const [tableMeta, setTableMeta] = useState({})
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [riskRegister, setRiskRegister] = useState({})
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  useEffect(
    () => {
      fetchRisksRegister()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reqParams]
  )

  async function fetchRisksRegister() {
    setIsBusy(true)
    const url = '/RiskRegister/find-by-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }
  const columns = [
    {
      label: localize('occurrenceDate'),
      template: r => <>{formatDate(r?.occurrenceDate, true)}</>,
    },
    {
      label: localize('description'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('frequency'),
      template: r => <>{r?.frequencyDescription}</>,
    },
    {
      label: localize('impact'),
      template: r => <>{r?.impactDescription}</>,
    },
    {
      label: localize('severity'),
      template: r => <>{r?.severityDescription}</>,
    },
    {
      label: localize('owner'),
      template: r => <>{r?.owner}</>,
    },
    {
      label: localize('mitigation'),
      template: r => <>{r?.mitigation}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate, true)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, true)}</>,
    },
  ]
  const handleClose = () => {
    setMode(null)
    setRiskRegister({})
  }

  const handleSelectRegister = (r, mode) => {
    setRiskRegister(r)
    setMode(mode)
  }

  const handleViewAuditLogs = item => {
    setRiskRegister(item)
    setMode('auditLogs')
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('systemAdministration'),
          localize('risksAndAudit'),
          localize('risksRegister'),
        ]}
      />
      {(mode === 'add' || mode === 'edit') && (
        <CreateRiskRegister
          setMode={setMode}
          mode={mode}
          fetchRisksRegister={fetchRisksRegister}
          handleClose={handleClose}
          riskRegister={riskRegister}
          setRiskRegister={setRiskRegister}
        />
      )}
      {mode === 'view' && (
        <ViewRiskRegister
          mode={mode}
          handleClose={handleClose}
          riskRegister={riskRegister}
        />
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={mode}
          data={riskRegister}
          title={riskRegister?.description}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-md-4">
              <div className="mb-3">
                <AddNewButton handleClickAddButton={() => setMode('add')} />
              </div>
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row d-flex align-items-center justify-content-between mb-3">
                <RecordsPerPage
                  className="col-4"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <form
                  className="d-flex align-items-center col-4"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                      pageIndex: 0,
                    })
                  }}
                >
                  <label className="ms-2 text-capitalize" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    defaultValue={reqParams.filterText}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                  />
                  <button
                    id="delinquencyStatusQueryFilterButton"
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <SimpleTable
                columns={columns}
                data={tableData}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectRegister(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('view')}</span>
                    </div>
                    <div
                      onClick={() => {
                        handleSelectRegister(r, 'edit')
                      }}
                      className="ctxt-menu-item text-capitalize"
                    >
                      <i className="uil uil-edit"></i>
                      {localize('edit')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleViewAuditLogs(r)
                      }}
                    >
                      <i className="uil uil-server"></i>
                      <span>{localize('viewAuditLog')}</span>
                    </div>
                  </>
                )}
              />
              <div className="row col-12 mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default RisksRegister
