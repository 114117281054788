/** @format */

/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import { NavigationContext } from '../../Contexts/NavigationContext'
import localize from '../../Global/Localize'
import { CONFIG } from '../../Helpers/constants'
import authHelper from '../../Helpers/authHelper'
import signalRService from '../../Helpers/signalR'

function MenuItem({ menu, depth }) {
  const {
    navigateToComponent,
    activeComponent,
    setNavigatedMenuCode,
    setNavigatedParentCode,
    setActiveComponent
  } = useContext(NavigationContext)

  const [enabled, setEnabled] = useState(isAccessAllowed)

  function isAccessAllowed() {
    if (CONFIG.AUTHENTICATION_ENABLED === 'false') {
      return true
    }

    if (
      CONFIG.AUTHENTICATION_ENABLED === 'true' &&
      !!authHelper?.currentUserProfile?.profile?.client_menus
        ?.split(',')
        ?.find(m => Number(m) === Number(menu.code))
    ) {
      return true
    }

    if (authHelper?.currentUserProfile?.profile?.name === CONFIG.ADMIN) {
      return true
    }
  }

  useEffect(() => {
    const handleRefreshMenus = event => {
      const enabledAuth = CONFIG.AUTHENTICATION_ENABLED === 'true'
      const isFound = event.detail?.find(mn => Number(mn) === Number(menu.code))
      setEnabled(enabledAuth && isFound)
    }

    signalRService.addEventListener(
      CONFIG.REFRESH_MENU_EVENT,
      handleRefreshMenus
    )

    return () => {
      signalRService.removeEventListener(
        CONFIG.REFRESH_MENU_EVENT,
        handleRefreshMenus
      )
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div
      title={`${localize(menu?.label).toUpperCase()} (${menu?.code})`}
      style={{
        pointerEvents: enabled ? 'all' : 'none',
        textTransform: 'capitalize',
        paddingLeft: depth * 15
      }}
      className={`menu-item ${
        menu.component === activeComponent ? 'active' : ''
      } ${enabled ? '' : 'disabled'}`}
      onClick={() => {
        navigateToComponent(c => {
          if (!menu?.component) return c
          let selected = menu.component
          // eslint-disable-next-line eqeqeq
          let isFound = c.find(comp => comp.component == selected)
          if (isFound) {
            return c
          } else {
            return [...c, menu]
          }
        })
        setNavigatedMenuCode(menu.code)
        setNavigatedParentCode(menu.parentCode)
        setActiveComponent(menu.component)
      }}
    >
      {localize(menu.label)}
    </div>
  )
}

export default MenuItem
