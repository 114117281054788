/** @format */

import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

const SalaryGroupLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('salaryGroupLookup')}
      url="/lookup/SalaryGroup/find-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate, false)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default SalaryGroupLookup
