/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _r = {
  recommendation: {
    en: 'Recommendation',
    sw: 'Mapendekezo',
  },
  register: {
    en: 'Register',
    sw: 'Kujiandikisha',
  },
  referrals: {
    en: 'Referrals',
    sw: 'Marejeo',
  },
  roster: {
    en: 'Roster',
    sw: 'Orodha',
  },
  regularDayProgram: {
    en: 'Regular Day Program',
    sw: 'Programu Ya Siku Ya Kawaida',
  },
  registryManagement: {
    en: 'registry management',
    sw: 'usajili',
  },
  recoverTextAlertTariffsFromPrincipalSavingsProduct: {
    en: 'recover SMS tarrifs from principal savings product',
    sw: 'kurejesha ushuru wa SMS kutoka kwa bidhaa kuu ya akiba',
  },
  reports: {
    en: 'reports',
    sw: 'ripoti',
  },
  roles: {
    en: 'roles',
    sw: 'majukumu',
  },
  riskAndAudit: {
    en: 'risk and audit',
    sw: 'hatari na ukaguzi',
  },
  riskRegister: {
    en: 'risk register',
    sw: 'rejista ya hatari',
  },
  registrationNumber: {
    en: 'registration number',
    sw: 'nambari ya usajili',
  },
  recordsPerPage: {
    en: 'records per page',
    sw: 'rekodi kwa kila ukurasa',
  },
  rangeLowerLimit: {
    en: 'lower limit',
    sw: 'kiwango cha chini',
  },
  rangeUpperLimit: {
    en: 'upper limit',
    sw: 'kiwango cha juu',
  },
  reference: {
    en: 'reference',
    sw: 'rejeo',
  },
  remarks: {
    en: 'remarks',
    sw: 'maoni',
  },
  recoverIndefiniteCharges: {
    en: 'recover indefinite charges',
    sw: 'kurejesha malipo kwa muda usiojulikana',
  },
  refundTypes: {
    en: 'refund types',
    sw: 'aina za kurejesha pesa',
  },
  riskClassificationModes: {
    en: 'risk classification modes',
    sw: 'njia za uainishaji wa hatari',
  },
  recoverSundryArrearages: {
    en: 'recover arrearages',
    sw: 'rejesha malimbikizo',
  },
  riskClassificationSchedules: {
    en: 'risk classification schedules',
    sw: 'ratiba za uainishaji wa hatari',
  },
  recordStatus: {
    en: 'record status',
    sw: 'hali ya rekodi',
  },
  registrationSerialNumber: {
    en: 'registration serial number',
    sw: 'nambari ya serial ya usajili',
  },
  recruitedBy: {
    en: 'recruited by',
    sw: 'kuajiriwa na',
  },
  retirementAge: {
    en: 'retirement age',
    sw: 'umri wa kustaafu',
  },
  revenueTaxes: {
    en: 'revenue taxes',
    sw: 'kodi ya malipo',
  },
  reportTemplates: {
    en: 'report templates',
    sw: 'ripoti violezo',
  },
  roundingTypeDescription: {
    en: 'rounding type',
    sw: 'aina ya ufupisho',
  },
  riskClassificationScheduleDetails: {
    en: 'risk classification schedule details',
    sw: 'maelezo ya uanishaji wa riba',
  },
  rptDisplayName: {
    en: 'rpt display name',
    sw: 'jina la kuonyesha',
  },
  recoveryPriority: {
    en: 'recovery priority',
    sw: 'kipaumbele cha urejeshaji',
  },
  recoveryModeDescription: {
    en: 'recovery mode',
    sw: 'hali ya kurejesha',
  },
  requireActivation: {
    en: 'require activation',
    sw: 'inahitaji uamilisho',
  },
  revenue: {
    en: 'revenue',
    sw: 'mapato',
  },
  roundingType: {
    en: 'rounding type',
    sw: 'aina ya ufupisho',
  },
  range: {
    en: 'range',
    sw: 'msafa',
  },
  recoverySourceDescription: {
    en: 'recovery source',
    sw: 'chanzo cha kurejesha',
  },
  recoveryMode: {
    en: 'recovery mode',
    sw: 'hali ya kurejesha',
  },
  roomDescription: {
    en: 'room description',
    sw: 'maelezo ya chumba',
  },
  roomType: {
    en: 'room type',
    sw: 'aina ya chumba',
  },
  roomIsLocked: {
    en: 'room is locked',
    sw: 'chumba kimefungwa',
  },
  room: {
    en: 'room',
    sw: 'chumba',
  },
  risksAndAudit: {
    en: 'risks and audit',
    sw: 'hatari na ukaguzi',
  },
  risksRegister: {
    en: 'risks register',
    sw: 'regista ya hatari',
  },
  reconciliationAccount: {
    en: 'reconciliation account',
    sw: 'akaunti ya upatanisho',
  },
  revenueTax: {
    en: 'revenue tax',
    sw: 'kodi ya mapato',
  },
  recoverySource: {
    en: 'recovery source',
    sw: 'chanzo cha kurejesha',
  },
  references: {
    en: 'references',
    sw: 'marejeo',
  },
  revenueTaxDescription: {
    en: 'revenue tax description',
    sw: 'maelezo ya kodi ya mapato',
  },
  revenueTaxTaxRate: {
    en: 'revenue tax tax rate',
    sw: 'kiwango cha kodi ya mapato',
  },
  relationshipManagers: {
    en: 'relationship managers',
    sw: 'wasimamizi wa uhusiano',
  },
  relationshipManager: {
    en: 'relationship manager',
    sw: 'msimamizi wa uhusiano',
  },
  relationship: {
    en: 'relationship',
    sw: 'uhusiano',
  },
  releaseDate: {
    en: 'release date',
    sw: 'tarehe ya kutolewa',
  },
  relationshipDescription: {
    en: 'relationship description',
    sw: 'maelezo ya uhusiano',
  },
  role: {
    en: 'role',
    sw: 'jukumu',
  },
  receive: {
    en: 'receive',
    sw: 'pokea',
  },
  recall: {
    en: 'recall',
    sw: 'kumbuka',
  },
  registration: {
    en: 'registration',
    sw: 'usajili',
  },
  registrationSerial: {
    en: 'registration serial',
    sw: 'nambari ya usajili',
  },
  rejectionRemarks: {
    en: 'rejection remarks',
    sw: 'maoni ya kukataa',
  },
  rejectedBy: {
    en: 'rejected by',
    sw: 'kukataliwa na',
  },
  rejectedDate: {
    en: 'rejected date',
    sw: 'tarehe ya kukataliwa',
  },
  referees: {
    en: 'referees',
    sw: 'wahakiki',
  },
  referee: {
    en: 'referee',
    sw: 'mhakiki',
  },
  refereeCollection: {
    en: 'referee collection',
    sw: 'ukusanyaji wa wahakiki',
  },
  registrationDate: {
    en: 'registration date',
    sw: 'tarehe ya usajili',
  },
  receiveTextAlert: {
    en: 'receive text alert',
    sw: 'pokea arifa ya maandishi',
  },
  receiveEmailAlert: {
    en: 'receive email alert',
    sw: 'pokea arifa ya barua pepe',
  },
  refundable: {
    en: 'refundable',
    sw: 'inayoweza kurejeshwa',
  },
  registrationAction: {
    en: 'registration action',
    sw: 'hatua ya usajili',
  },
  reference1: {
    en: 'account number',
    sw: 'nambari ya akaunti',
  },
  reference2: {
    en: 'member number',
    sw: 'nambari ya uanachama',
  },
  reference3: {
    en: 'personal file number.',
    sw: 'nambari ya faili',
  },
  recipient: {
    en: 'recipient',
    sw: 'mpokeaji',
  },
  receiveDate: {
    en: 'receive date',
    sw: 'tarehe ya kupokea',
  },
  ringFinger: {
    en: 'ring finger',
    sw: 'kidole cha pete',
  },
  rightHand: {
    en: 'right hand',
    sw: 'mkono wa kulia',
  },
  ref_1Account: {
    en: 'account number',
    sw: 'nambari ya akaunti',
  },
  ref_2Membership: {
    en: 'membership no.',
    sw: 'nambari ya uanachama',
  },
  ref_3PersonalFile: {
    en: 'personal file number.',
    sw: 'nambari ya faili',
  },
  registrationOffice: {
    en: 'registration office',
    sw: 'ofisi ya usajili',
  },
  remove: {
    en: 'remove',
    sw: 'ondoa',
  },
  regOffice: {
    en: 'registration office',
    sw: 'ofisi ya usajili',
  },
  reversal: {
    en: 'reversal',
    sw: 'marejesho',
  },
  recurringProcedures: {
    en: 'recurring procedures',
    sw: 'taratibu zinazojirudia',
  },
  reconciliation: {
    en: 'reconciliation',
    sw: 'upatanisho',
  },
  receipting: {
    en: 'receipting',
    sw: 'kupokea',
  },
  'ref_1(Account #)': {
    en: 'account number',
    sw: 'nambari ya akaunti',
  },
  'ref_2(Membership #)': {
    en: 'membership no.',
    sw: 'nambari ya uanachama',
  },
  'ref_3(Personal File #)': {
    en: 'personal file number.',
    sw: 'nambari ya faili',
  },
  runningBalance: {
    en: 'running balance',
    sw: 'pesa iliyosalia',
  },
  refresh: {
    en: 'refresh',
    sw: 'sasisha',
  },
  reportTemplate: {
    en: 'report template',
    sw: 'ripoti kiolezo',
  },
  responsibilities: {
    en: 'responsibilities',
    sw: 'majukumu',
  },
  roleName: {
    en: 'role name',
    sw: 'jina la jukumu',
  },
  roleLookup: {
    en: 'role lookup',
    sw: 'tafuta jukumu',
  },
  reason: {
    en: 'reason',
    sw: 'sababu',
  },
  referenceMonth: {
    en: 'reference month',
    sw: 'mwezi wa rejeo',
  },
  reconciledItems: {
    en: 'reconciled items',
    sw: 'vitengo vilivyopatanishwa',
  },
  reconciledBalance: {
    en: 'reconciled balance',
    sw: 'pesa iliyopatanishwa',
  },
  reconciliationPeriodAuthOption: {
    en: 'reconciliation period auth option',
    sw: 'chaguo la idhini ya kipindi cha upatanisho',
  },
  reportTemplateLookup: {
    en: 'report template lookup',
    sw: 'tafuta kiolezo cha ripoti',
  },
  resultCode: {
    en: 'result code',
    sw: 'nambari',
  },
  refNumber: {
    en: 'ref number',
    sw: 'nambari ya rejeleo',
  },
  rejectIfOwing: {
    en: 'reject if owing',
    sw: 'kataa ikiwa inadaiwa',
  },
  requestForVerification: {
    en: 'request for verification',
    sw: 'itisha uhakiki',
  },
  recoverInterestUpfront: {
    en: 'recover interest upfront',
    sw: 'lipa riba mbele',
  },
  revenueTaxRate: {
    en: 'revenue tax rate',
    sw: 'kiwango cha kodi ya mapato',
  },
  recoverCarryFowards: {
    en: 'recover carry fowards',
    sw: 'lipa salio lililobaki',
  },
  reversalBatch: {
    en: 'reversal batch',
    sw: 'kundi la marejesho',
  },
  rejection: {
    en: 'rejection',
    sw: 'kukataa',
  },
  rejected: {
    en: 'rejected',
    sw: 'imekataliwa',
  },
  residualPrincipal: {
    en: 'residual principal',
    sw: 'msingi uliobaki',
  },
  residualInterest: {
    en: 'residual interest',
    sw: 'riba iliyobaki',
  },
  relievingReinstating: {
    en: 'relieving/reinstating',
    sw: 'kupunguza/kurejesha',
  },
  releasing: {
    en: 'releasing',
    sw: 'kutolewa',
  },
  refundsProcessing: {
    en: 'refunds processing',
    sw: 'usindikaji wa marejesho',
  },
  reportType: {
    en: 'report type',
    sw: 'aina ya ripoti',
  },
  referenceNumber: {
    en: 'reference number',
    sw: 'nambari ya rejeleo',
  },
  repaymentSchedule: {
    en: 'repayment schedule',
    sw: 'ratiba ya malipo',
  },
  receivedDate: {
    en: 'received date',
    sw: 'tarehe ya kupokea',
  },
  refundType: {
    en: 'refund type',
    sw: 'aina ya marejesho',
  },
  refundNotificationsCatalogue: {
    en: 'refund notifications catalogue',
    sw: 'orodha ya arifa za marejesho',
  },
  reportReason: {
    en: 'report reason',
    sw: 'sababu ya ripoti',
  },
  reportedName: {
    en: 'reported name',
    sw: 'jina lililoripotiwa',
  },
  returnBookBalance: {
    en: 'return book balance',
    sw: 'rejesha salio',
  },
  reversedDate: {
    en: 'reversed date',
    sw: 'tarehe ya kurejeshwa',
  },
  reversedBy: {
    en: 'reversed by',
    sw: 'kurejeshwa na',
  },
  reversalRemarks: {
    en: 'reversal remarks',
    sw: 'maoni ya kurejeshwa',
  },
  recordID: {
    en: 'record id',
    sw: 'kitambulisho cha rekodi',
  },
  refundNotifications: {
    en: 'refund notifications',
    sw: 'arifa za marejesho',
  },
  refundBatch: {
    en: 'refund batch',
    sw: 'kundi la marejesho',
  },
  refundBatchLookup: {
    en: 'refund batch lookup',
    sw: 'tafuta kundi la marejesho',
  },
  refundTypeLookup: {
    en: 'refund type lookup',
    sw: 'tafuta aina ya marejesho',
  },
  relieve: {
    en: 'relieve',
    sw: 'punguza',
  },
  relive: {
    en: 'relieve',
    sw: 'punguza',
  },
  reschedulingDetails: {
    en: 'rescheduling details',
    sw: 'maelezo ya upangaji upya',
  },
  refundNotificationLookup: {
    en: 'refund notification lookup',
    sw: 'tafuta arifa ya marejesho',
  },
  'registrationSerial#': {
    en: 'registration serial #',
    sw: 'nambari ya usajili #',
  },
  'reference#': {
    en: 'reference #',
    sw: 'rejeo #',
  },
  referenceType: {
    en: 'reference type',
    sw: 'aina ya rejeo',
  },
  resolvedDate: {
    en: 'resolved date',
    sw: 'tarehe ya kutatuliwa',
  },
  resolvedBy: {
    en: 'resolved by',
    sw: 'kutatuliwa na',
  },
  resolution: {
    en: 'resolution',
    sw: 'azimio',
  },
  recipients: {
    en: 'recipients',
    sw: 'wanaopokea',
  },
  recoverIndefiniteCharge: {
    en: 'recover indefinite charge',
    sw: 'lipa malipo yasiyo na kikomo',
  },
  repayments: {
    en: 'repayments',
    sw: 'malipo',
  },
  requireProductSelection: {
    en: 'require product selection',
    sw: 'inahitaji uchaguzi wa bidhaa',
  },
  reportName: {
    en: 'report name',
    sw: 'jina la ripoti',
  },
  reportPath: {
    en: 'report path',
    sw: 'njia ya ripoti',
  },
  reportQuery: {
    en: 'report query',
    sw: 'swali la ripoti',
  },
  responses: {
    en: 'responses',
    sw: 'majibu',
  },
  response: {
    en: 'response',
    sw: 'jibu',
  },
  reset: {
    en: 'reset',
    sw: 'futa',
  },
  requestType: {
    en: 'request type',
    sw: 'aina ya ombi',
  },
  requestCategory: {
    en: 'request category',
    sw: 'kategori ya ombi',
  },
  refAccountNumber: {
    en: 'account number',
    sw: 'nambari ya akaunti ya rejeo',
  },
  Remarks: {
    en: 'remarks',
    sw: 'maoni',
  },
  rate: {
    en: 'rate',
    sw: 'kiwango',
  },
  Registered: {
    en: 'registered',
    sw: 'imesajiliwa',
  },
  'range(lower)': {
    en: 'range(lower)',
    sw: 'kiwango cha chini',
  },
  'range(Upper)': {
    en: 'range(upper)',
    sw: 'kiwango cha juu',
  },
  reasonForReturn: {
    en: 'reason for return',
    sw: 'sababu ya kurejeshwa',
  },
  rematchVoucher: {
    en: 'rematch voucher',
    sw: 'inganisha vocha',
  },
  rearImage: {
    en: 'rear image',
    sw: 'picha ya nyuma',
  },
  resetPin: {
    en: 'reset pin',
    sw: 'badili nambari ya siri',
  },
  reorderPoint: {
    en: 'reorder point',
    sw: 'kiwango cha upya',
  },
  registrationTime: {
    en: 'registration time',
    sw: 'muda wa usajili',
  },
  returnedBy: {
    en: 'returned by',
    sw: 'kurejeshwa na',
  },
  returnedDate: {
    en: 'returned date',
    sw: 'tarehe ya kurejeshwa',
  },
  'receipts/Payments': {
    en: 'receipts/payments',
    sw: 'malipo',
  },
  releaseMorguePatient: {
    en: 'release morgue patient',
    sw: 'achilia maiti',
  },
  releasedBy: {
    en: 'released by',
    sw: 'aliyetoa',
  },
  releasedDate: {
    en: 'released date',
    sw: 'tarehe ya kutolewa',
  },
  release: {
    en: 'release',
    sw: 'achilia',
  },
  requisition: {
    en: 'requisition',
    sw: 'ombi',
  },
  requisitionItemsLookup: {
    en: 'requisition items lookup',
    sw: 'tafuta bidhaa',
  },
  recepientName: {
    en: 'recepient name',
    sw: 'jina la mpokeaji',
  },
  requisitionLookup: {
    en: 'requisition lookup',
    sw: 'tafuta ombi',
  },
  residualValue: {
    en: 'residual value',
    sw: 'thamani iliyobaki',
  },
  receivableEntries: {
    en: 'receivable entries',
    sw: 'vitengo vya kupokea',
  },
  requisitions: {
    en: 'requisitions',
    sw: 'ombi',
  },
  requisitionItems: {
    en: 'requisition items',
    sw: 'bidhaa',
  },
  refunded: {
    en: 'refunded',
    sw: 'imerejeshwa',
  },
  receipient: {
    en: 'receipient',
    sw: 'mpokeaji',
  },
  referralDetails: {
    en: 'referral details',
    sw: 'maelezo ya rejeo',
  },
  requiredDepartmentalApprovals: {
    en: 'required departmental approvals',
    sw: 'inahitaji uchaguzi wa katika',
  },
  recordId: {
    en: 'record id',
    sw: 'kitambulisho cha rekodi',
  },
  requiredApprovers: {
    en: 'required approvers',
    sw: 'waidhinishaji wanaohitajika',
  },
  removeBatchEntries: {
    en: 'remove batch entries',
    sw: 'ondoa vitengo vya kundi',
  },
  revenueTaxLookup: {
    en: 'revenue tax lookup',
    sw: 'tafuta kodi ya mapato',
  },
  reportDisplayName: {
    en: 'report display name',
    sw: 'jina la kuonyesha',
  },
  recoverArrearages: {
    en: 'recover arrearages',
    sw: 'lipa malimbikizo',
  },
  refundBatches: {
    en: 'refund batches',
    sw: 'kundi la marejesho',
  },
  removeReversalBatchEntries: {
    en: 'remove reversal batch entries',
    sw: 'ondoa vitengo vya kurejeshwa',
  },
  reliefValue: {
    en: 'relief value',
    sw: 'kiwango cha punguzo',
  },
}

export default _r
