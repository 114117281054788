/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import Checkbox from '../../../Checkbox/Checkbox'
import SimpleTable from '../../../Tables/SimpleTable'
import AdjustGuarantorStatus from './AdjustGuarantorStatus/AdjustGuarantorStatus'

const LoanAccounts = ({
  data,
  selectedLoanAccounts,
  selectAllLoanAccounts,
  handleSelectLoanAccount,
  showContextMenu,
  canSelect,
}) => {
  const [selectAll, setSelectAll] = useState(false)
  const [mode, setMode] = useState(null)
  const [loan, setLoan] = useState({})

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedLoanAccounts?.find(x => x?.id === r?.id)}
          onCheck={value => {
            handleSelectLoanAccount(r, value)
          }}
        />
      ),
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r?.fullAccountNumber,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('productName'),
      template: r => r?.customerAccountTypeTargetProductDescription,
    },
    {
      label: localize('bookBalance'),
      template: r => formatCurrency(r?.bookBalance),
      headerTextAlign: 'right',
    },
    {
      label: localize('principalBalance'),
      template: r => formatCurrency(r?.principalBalance),
      headerTextAlign: 'right',
    },
    {
      label: localize('interestBalance'),
      template: r => formatCurrency(r?.interestBalance),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanNumber'),
      template: r => r?.loanCaseDocumentReferenceNumber,
    },
    {
      label: localize('loanClassification'),
      template: r => r?.loanClassificationDescription,
    },
    {
      label: localize('loanAmount'),
      template: r => formatCurrency(r?.loanCaseTotalAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => formatCurrency(r?.loanBalanceExpected),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => formatCurrency(r?.loanPrepaidAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => formatCurrency(r?.loanDefaultAmount),
      headerTextAlign: 'right',
    },

    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => r?.loanDefaultTimeline,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => formatAsPercent(r?.loanLossProvisionPercentage),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => formatCurrency(r?.loanLossProvisionAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanDisbursedDate'),
      template: r => formatDate(r?.loanCaseDisbursedDate),
    },
    {
      label: localize('loanGracePeriod'),
      template: r => r?.loanCaseLoanRegistrationGracePeriod,
    },
    {
      label: localize('loanLastPaymentDueDate'),
      template: r => formatDate(r?.loanLastPaymentDueDate, false),
    },
    {
      label: localize('loanLastPayment'),
      template: r => formatCurrency(r?.loanLastPayment, false),
    },
    {
      label: localize('loanTerm(Months)'),
      template: r => r?.loanCaseLoanRegistrationTermInMonths,
    },
    {
      label: localize('loanAnnualPercentageRate'),
      template: r =>
        formatAsPercent(r?.loanCaseLoanInterestAnnualPercentageRate),
    },
    {
      label: localize('loanPaymentFrequencyPerYear'),
      template: r =>
        r?.loanCaseLoanRegistrationPaymentFrequencyPerYearDescription,
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => r?.loanCaseLoanInterestCalculationModeDescription,
    },
    {
      label: localize('carryForwardsBalance'),
      template: r => formatCurrency(r?.carryForwardsBalance),
    },
    {
      label: localize('recordStatus'),
      template: r => r?.recordStatusDescription,
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const columnsToDisplay = columns.filter((_col, i) => i !== 0)

  const handleSelect = (r, mode) => {
    setMode(mode)
    setLoan(r)
  }

  const handleClose = () => {
    setMode(null)
    setLoan(null)
  }

  return (
    <>
      {mode === 'adjust-guarantor-status' && (
        <AdjustGuarantorStatus
          customerId={loan?.id}
          loanCaseId={loan?.loanCaseId}
          handleClose={handleClose}
          mode={mode}
        />
      )}

      <SimpleTable
        data={data}
        columns={canSelect ? columns : columnsToDisplay}
        contextMenu={r =>
          showContextMenu && (
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                handleSelect(r, 'adjust-guarantor-status')
              }}
            >
              <i className="uil uil-bolt text-warning"></i>
              <span>{localize('adjustGuarantorStatus')}</span>
            </div>
          )
        }
      />

      {canSelect && (
        <div className=" bg-light d-flex justify-content-start align-items-center gap-3 rounded p-2 mt-3">
          <div className="d-flex align-items-center">
            <Checkbox
              id="selectAllLoanAccounts"
              checked={selectAll}
              onCheck={value => {
                setSelectAll(value)
                selectAllLoanAccounts(data, value)
              }}
            />
            <label htmlFor="selectAllLoanAccounts" className="ms-2">
              {localize('toggleSelection')}
            </label>
          </div>
        </div>
      )}
    </>
  )
}

export default LoanAccounts
