/** @format */

import React from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import ListSuperSaverPayables from '../Partials/ListSuperSaverPayables/ListSuperSaverPayables'
import { useState } from 'react'
import CreateSuperSaverPayable from './CreateSuperSaverPayable/CreateSuperSaverPayable'
import { postData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'

const Origination = () => {
  const [mode, setMode] = useState(null)
  const [reload, setReload] = useState(false)
  const [customerAccount, setCustomerAccount] = useState({})
  const [isBusy, setIsBusy] = useState(false)

  const handleClose = () => {
    setMode(null)
    setCustomerAccount({})
  }

  const handleClickAddButton = () => {
    setMode('add')
    setCustomerAccount({})
  }

  const handleCreateSuperSaverPayable = async () => {
    if (!customerAccount?.id)
      return showNotification(
        'Operation not allowed ',
        'error',
        'Customer account is required'
      )

    if (!customerAccount?.createRemarks)
      return showNotification(
        'Operation not allowed ',
        'error',
        'Remarks is required'
      )

    setIsBusy(true)

    const url = '/SuperSaverPayable/add-super-saver-payable'

    const { success } = await postData(
      url,
      {
        ...customerAccount,
        customerAccountId: customerAccount?.id,
        remarks: customerAccount?.createRemarks,
      },
      false
    )

    if (success) {
      setReload(true)
      handleClose()
      setIsBusy(false)
      showNotification('Create Super Saver Payable', 'success')
    } else setIsBusy(false)
  }

  return (
    <>
      {mode === 'add' && (
        <CreateSuperSaverPayable
          mode={mode}
          handleClose={handleClose}
          customerAccount={customerAccount}
          setCustomerAccount={setCustomerAccount}
          handleCreateSuperSaverPayable={handleCreateSuperSaverPayable}
          loading={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('superSaverPayables'),
        ]}
      />

      <div className="card rounded bg-white">
        <div className="card-body">
          <ListSuperSaverPayables
            showCreateButton
            handleClickAddButton={handleClickAddButton}
            reload={reload}
            setReload={setReload}
          />
        </div>
      </div>
    </>
  )
}

export default Origination
