/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { useEffect, useState } from 'react'
import { AddMicroCreditOfficerModal } from './AddMicroCreditOfficerModal'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { postData } from '../../../../Helpers/webApi'
import { EditMicroCreditOfficerModal } from './EditMicroCreditOfficerModal'
import swal from 'sweetalert2'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function MicroCreditOfficersModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedMicroCreditOfficer,
  setSelectedMicroCreditOfficer,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      await addMicroCreditOfficer()
    }
    if (mode === 'edit') {
      await editMicroCreditOfficer()
    }
  }

  const addMicroCreditOfficer = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/MicroCreditOfficer/add-micro-credit-officer',
      {
        ...selectedMicroCreditOfficer,
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedMicroCreditOfficer(null)
      swal.fire(
        'Add Micro Credit Officer',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editMicroCreditOfficer = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/MicroCreditOfficer/update-micro-credit-officer',
      {
        ...selectedMicroCreditOfficer,
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedMicroCreditOfficer(null)
      swal.fire(
        'Add Micro Credit Officer',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('microCreditOfficer')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
            disableCreate={isBusy}
            footerChildren={
              <div className="d-flex align-content-end justify-content-end">
                <div className="d-flex align-items-center me-5">
                  <Checkbox
                    id={'is-locked-micro-credit-officer-modal'}
                    checked={selectedMicroCreditOfficer?.isLocked}
                    onCheck={v =>
                      setSelectedMicroCreditOfficer({
                        ...selectedMicroCreditOfficer,
                        isLocked: v,
                      })
                    }
                  />
                  <label
                    htmlFor="is-locked-micro-credit-officer-modal"
                    className="text-capitalize ms-2"
                  >
                    {localize('isLocked')}?
                  </label>
                </div>
              </div>
            }
          >
            {mode === 'add' && (
              <AddMicroCreditOfficerModal
                isBusy={isBusy}
                selectedMicroCreditOfficer={selectedMicroCreditOfficer}
                setSelectedMicroCreditOfficer={setSelectedMicroCreditOfficer}
              />
            )}
            {mode === 'edit' && (
              <EditMicroCreditOfficerModal
                selectedMicroCreditOfficer={selectedMicroCreditOfficer}
                setSelectedMicroCreditOfficer={setSelectedMicroCreditOfficer}
                isBusy={isBusy}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default MicroCreditOfficersModal
