/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _v = {
  verify: {
    en: 'Verify',
    sw: 'Thibitisha',
  },
  verification: {
    en: 'Verification',
    sw: 'Uthibitishaji',
  },
  vision: {
    en: 'vision',
    sw: 'maono',
  },
  vehicleFleetManagement: {
    en: 'vehicle fleet management',
    sw: 'usimamizi wa msafara',
  },
  validityPeriod: {
    en: 'validity period',
    sw: 'kipindi cha uthibitishaji',
  },
  valueComputationMode: {
    en: 'value computation mode',
    sw: 'hali ya kukokotoa thamani',
  },
  valueComputationTag: {
    en: 'value computation tag',
    sw: 'lebo ya hesabu ya thamani',
  },
  vehicleRoutes: {
    en: 'vehicle routes',
    sw: 'njia za gari',
  },
  vehicleFleetProducts: {
    en: 'vehicle fleet products',
    sw: 'bidhaa za msafara',
  },
  vitalStatus: {
    en: 'vital status',
    sw: 'hali muhimu',
  },
  vitalStatusDate: {
    en: 'vital status date',
    sw: 'tarehe muhimu ya hali ',
  },
  vitalStatusDescription: {
    en: 'vital status description',
    sw: 'maelezo muhimu ya hali ',
  },
  venue: {
    en: 'venue',
    sw: 'eneo',
  },
  view: {
    en: 'view',
    sw: 'angalia',
  },
  verificationRemarks: {
    en: 'verification remarks',
    sw: 'maoni ya uthibitishaji',
  },
  verifiedBy: {
    en: 'verified by',
    sw: 'imethibitishwa na',
  },
  verificationDate: {
    en: 'verification date',
    sw: 'tarehe ya uthibitishaji',
  },
  verifiedRemarks: {
    en: 'verified remarks',
    sw: 'maoni ya uthibitishaji',
  },
  verifiedDate: {
    en: 'verified date',
    sw: 'tarehe iliyothibitishwa',
  },
  verified: {
    en: 'verified',
    sw: 'imethibitishwa',
  },
  voucher: {
    en: 'voucher',
    sw: 'kadi ya malipo',
  },
  'verification/RejectionRemarks': {
    en: 'verification/rejection remarks',
    sw: 'Maoni ya uthibitishaji/kukataliwa',
  },
  valueDate: {
    en: 'value date',
    sw: 'tarehe ya thamani',
  },
  value: {
    en: 'value',
    sw: 'thamani',
  },
  validateAppraisalProductsBalance: {
    en: 'validate appraisal products balance',
    sw: 'thibitisha salio la bidhaa za tathmini',
  },
  validateLoanAppraisalProductsBalance: {
    en: 'validate loan appraisal products balance',
    sw: 'thibitisha salio la bidhaa za tathmini ya mkopo',
  },
  'verifiedBy/RejectedBy': {
    en: 'verified by/rejected by',
    sw: 'imethibitishwa na/kukataliwa na',
  },
  'verifiedDate/RejectedDate': {
    en: 'verified date/rejected date',
    sw: 'tarehe ya uthibitishaji/tarehe ya kukataliwa',
  },
  voucherNumber: {
    en: 'voucher number',
    sw: 'nambari ya kadi ya malipo',
  },
  voucherType: {
    en: 'voucher type',
    sw: 'aina ya kadi ya malipo',
  },
  validateInterestCapitalization: {
    en: 'validate interest capitalization',
    sw: 'thibitisha uwekaji wa riba kwenye mtaji',
  },
  validateIndefiniteCharges: {
    en: 'validate indefinite charges',
    sw: 'thibitisha malipo yasiyo na kikomo',
  },
  voucherVerification: {
    en: 'voucher (verification)',
    sw: 'voucher (verification)',
  },
  voucherAuthorization: {
    en: 'voucher (authorization)',
    sw: 'voucher (authorization)',
  },
  voucherOrigination: {
    en: 'voucher (origination)',
    sw: 'voucher (origination)',
  },
  'verified/RejectedBy': {
    en: 'verified/rejected by',
    sw: 'imethibitishwa/kukataliwa na',
  },
  'verified/RejectedDate': {
    en: 'verified/rejected date',
    sw: 'tarehe ya uthibitishaji/tarehe ya kukataliwa',
  },
  verifyOption: {
    en: 'verify option',
    sw: 'chaguo la uthibitishaji',
  },
  verifyMembershipWithdrawal: {
    en: 'verify membership withdrawal',
    sw: 'thibitisha uondoaji wa uanachama',
  },
  verifyLoanRescheduleRequest: {
    en: 'verify loan reschedule request',
    sw: 'thibitisha ombi la kubadilisha mkopo',
  },
  verifyRefundsProcessing: {
    en: 'verify refunds processing',
    sw: 'thibitisha usindikaji wa marejesho',
  },
  viewIdentityVerification: {
    en: 'view identity verification',
    sw: 'angalia uthibitishaji wa kitambulisho',
  },
  viewAuditLog: {
    en: 'view audit log',
    sw: 'angalia kumbukumbu ya ukaguzi',
  },
  verifyRefundNotification: {
    en: 'verify refund notification',
    sw: 'thibitisha taarifa ya marejesho',
  },
  verifiedName: {
    en: 'verified name',
    sw: 'jina lililothibitishwa',
  },
  'view-audit-log': {
    en: 'view-audit-log',
    sw: 'angalia kumbukumbu ya ukaguzi',
  },
  viewBulkMessageBatch: {
    en: 'view bulk message batch',
    sw: 'angalia ujumbe wa wingi',
  },
  verifyBulkMessageBatch: {
    en: 'verify bulk message batch',
    sw: 'thibitisha ujumbe wa wingi',
  },
  visualStatistics: {
    en: 'visual statistics',
    sw: 'takwimu za kuona',
  },
  viewCustomerAccountStatement: {
    en: 'view customer account statement',
    sw: 'angalia taarifa ya akaunti ya mteja',
  },
  validateBiometricSpecimen: {
    en: 'validate biometric specimen',
    sw: 'thibitisha kipimo cha kibayolojia',
  },
  viewSurvey: {
    en: 'view survey',
    sw: 'angalia utafiti',
  },
  variance: {
    en: 'variance',
    sw: 'tofauti',
  },
  varianceType: {
    en: 'variance type',
    sw: 'aina ya tofauti',
  },
  varianceKind: {
    en: 'variance kind',
    sw: 'aina ya tofauti',
  },
  VoucherNumber: {
    en: 'voucher number',
    sw: 'nambari ya Kadi ya malipo',
  },
  verificationAction: {
    en: 'verification action',
    sw: 'hatua ya uthibitishaji',
  },
  verifyAccountClosure: {
    en: 'verify account closure',
    sw: 'thibitisha kufungwa kwa akaunti',
  },
  verifyFixedDeposit: {
    en: 'verify fixed deposit',
    sw: 'thibitisha amana zisizohamishika',
  },
  viewglAccountTransactions: {
    en: 'view g/l account transactions',
    sw: 'angalia shughuli za akaunti ya g/l',
  },
  voucherEntries: {
    en: 'voucher entries',
    sw: 'ingizo la vocha',
  },
  voucherEntryDetails: {
    en: 'voucher entry details',
    sw: 'taarifa za ingizo la vocha',
  },
  viewAuditLogs: {
    en: 'view audit logs',
    sw: 'angalia kumbukumbu ya ukaguzi',
  },
  'verification/rejectionRemarks': {
    en: 'verification/rejection remarks',
    sw: 'maoni ya uthibitishaji/kukataliwa',
  },
  'verified/rejectedBy': {
    en: 'verified/rejected by',
    sw: 'imethibitishwa/kukataliwa na',
  },
  'verified/rejectedDate': {
    en: 'verified/rejected date',
    sw: 'tarehe ya uthibitishaji/tarehe ya kukataliwa',
  },
  verifyOverdraftProtection: {
    en: 'verify overdraft protection',
    sw: 'thibitisha ulinzi wa mkopo',
  },
  viewExternalCheque: {
    en: 'view external cheque',
    sw: 'angalia hundi ya nje',
  },
  validFrom: {
    en: 'valid from',
    sw: 'halali kutoka',
  },
  valueType: {
    en: 'value type',
    sw: 'aina ya thamani',
  },
  volutaryDeductibles: {
    en: 'volutary deductibles',
    sw: 'makato ya hiari',
  },
  viewCustomer: {
    en: 'view customer',
    sw: 'angalia mteja',
  },
  vehiclePersonnel: {
    en: 'vehicle personnel',
    sw: 'wafanyakazi wa gari',
  },
  vehicleRegistrationNumber: {
    en: 'vehicle registration number',
    sw: 'nambari ya usajili wa gari',
  },
  vehicle: {
    en: 'vehicle',
    sw: 'gari',
  },
  vehicles: {
    en: 'vehicles',
    sw: 'magari',
  },
  vehicleLookup: {
    en: 'vehicle lookup',
    sw: 'tafuta gari',
  },
  vehicleRoute: {
    en: 'vehicle route',
    sw: 'njia ya gari',
  },
  vehicleProducts: {
    en: 'vehicle products',
    sw: 'bidhaa za gari',
  },
  vehicleProductDetails: {
    en: 'vehicle product details',
    sw: 'taarifa za bidhaa za gari',
  },
  vehicleFleetProductLookup: {
    en: 'vehicle fleet product lookup',
    sw: 'tafuta bidhaa za msafara za gari',
  },
  viewVehicle: {
    en: 'view vehicle',
    sw: 'angalia gari',
  },
  viewDisciplinaryCase: {
    en: 'view disciplinary case',
    sw: 'angalia kesi ya nidhamu',
  },
  vehiclePersonnelLookup: {
    en: 'vehicle personnel lookup',
    sw: 'tafuta wafanyakazi wa gari',
  },
  viewDocument: {
    en: 'view document',
    sw: 'angalia hati',
  },
  viewRiskRegister: {
    en: 'risk register',
    sw: 'angalia rejista ya hatari',
  },
  vehicleSerialNumber: {
    en: 'vehicle serial number',
    sw: 'nambari ya gari',
  },
  vehicleCollections: {
    en: 'vehicle collections',
    sw: 'makusanyo ya gari',
  },
  vehicleStatements: {
    en: 'vehicle statements',
    sw: 'taarifa za gari',
  },
  vehicleDetails: {
    en: 'vehicle details',
    sw: 'maelezo ya gari',
  },
  vehicleFleet: {
    en: 'vehicle fleet',
    sw: 'msafara za magari',
  },
  vehicleProductType: {
    en: 'vehicle product type',
    sw: 'aina ya bidhaa ya gari',
  },
  vehicleProductName: {
    en: 'vehicle product name',
    sw: 'jina la bidhaa ya gari',
  },
  viewReceivables: {
    en: 'view receivables',
    sw: 'angalia zinazopatikana',
  },
  viewPersonnel: {
    en: 'view personnel',
    sw: 'angalia wafanyakazi',
  },
  viewFile: {
    en: 'view file',
    sw: 'angalia faili',
  },
  viewSubstitutes: {
    en: 'view substitutes',
    sw: 'angalia mbadala',
  },
  viewGuarantors: {
    en: 'view guarantors',
    sw: 'angalia wadhamini',
  },
  viewApplicableCharges: {
    en: 'view applicable charges',
    sw: 'angalia malipo yanayotumika',
  },
}
export default _v
