/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData, putData } from '../../../../../Helpers/webApi'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../Global/Localize'
import {
  loanProductChargeBasisValue,
  loanProductKnownChargeType,
} from '../../../../../Global/enumeration'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

function Charges({ mode, loan }) {
  const [isBusy, setIsBusy] = useState(false)
  const readOnly = mode === 'view'
  const [commissions, setCommissions] = useState([])
  const [chargeType, setChargeType] = useState(53498)
  const [chargeBasisValue, setChargeBasisValue] = useState(1)
  const [wellKnownCharges, setWellKnownCharges] = useState([])
  const [dynamicCharges, setDynamicCharges] = useState([])
  const [selectedIndefiniteCharges, setSelectedIndefiniteCharges] = useState([])

  async function loadCommissions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/commission/find-commissions',
      null,
      false
    )
    if (success) {
      setCommissions(
        data.sort((a, b) => a.description.localeCompare(b.description))
      )
    }
    setIsBusy(false)
  }

  async function loadIndefiniteCharges() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/dynamicCharge/find-dynamic-charges',
      null,
      false
    )
    if (success) {
      setDynamicCharges(
        data.result.sort((a, b) => a.description.localeCompare(b.description))
      )
    }
    setIsBusy(false)
  }

  async function findCommissions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-commissions-by-loan-product-id',
      { loanProductId: loan?.id, loanProductKnownChargeType: chargeType },
      false
    )
    if (success) {
      setWellKnownCharges(data.result)
    }
    setIsBusy(false)
  }

  async function updateWKC() {
    setIsBusy(true)
    await putData(
      `/loanProduct/update-commissions-by-loan-product-id?loanProductId=${loan?.id}&loanProductKnownChargeType=${chargeType}&loanProductChargeBasisValue=${chargeBasisValue}`,
      wellKnownCharges,
      false,
      'Well Known Charges Updated!'
    )
    await findCommissions()
    setIsBusy(false)
  }

  async function loadSelectedIndefiniteCharges() {
    setIsBusy(true)
    const { success, data } = await getData(
      `/loanProduct/find-dynamic-charges-by-loan-product-id?loanProductId=${loan?.id}`,
      null,
      false
    )
    if (success) {
      setSelectedIndefiniteCharges(data.result)
    }
    setIsBusy(false)
  }

  async function updateSelectedIndefiniteCharges() {
    setIsBusy(true)
    await putData(
      `/loanProduct/update-dynamic-charges-by-loan-product-id?loanProductId=${loan?.id}`,
      selectedIndefiniteCharges,
      false,
      'Dynamic Charges Updated!'
    )
    await loadSelectedIndefiniteCharges()
    setIsBusy(false)
  }

  useEffect(() => {
    loadCommissions()
    loadIndefiniteCharges()
    loadSelectedIndefiniteCharges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    findCommissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeType])

  return loan?.id ? (
    <div style={{ position: 'relative' }}>
      <div className="row">
        <div className="col bg-light m-2 p-3" style={{ borderRadius: 10 }}>
          <h5 className="text-capitalize">{localize('wellKnownCharges')}</h5>
          <div className="p-2">
            <div className="d-flex align-items-center gap-2">
              <label
                htmlFor=""
                className="text-capitalize col-2"
                style={{ minWidth: 'max-content' }}
              >
                {localize('chargeType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="LoanProductKnownChargeType"
                onChange={e => setChargeType(e.value)}
                value={chargeType}
              />
            </div>
            <div className="d-flex align-items-center gap-2 mt-2">
              <label htmlFor="" className="text-capitalize col-2">
                {localize('chargeBasisValue')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="LoanProductChargeBasisValue"
                value={chargeBasisValue}
                onChange={e => setChargeBasisValue(e.value)}
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: '70vh',
              overflowY: 'scroll',
              border: 'solid 1px black',
              borderRadius: 5,
            }}
            className="p-2 bg-white mx-2 mt-2"
          >
            {commissions.map(c => (
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  disabled={readOnly}
                  id={c?.id}
                  onCheck={v => {
                    if (v) {
                      setWellKnownCharges([...wellKnownCharges, c])
                    } else {
                      setWellKnownCharges(
                        wellKnownCharges.filter(w => w.id !== c.id)
                      )
                    }
                  }}
                  checked={wellKnownCharges.find(w => w.id === c.id)}
                />
                <label
                  htmlFor={c?.id}
                  className={`text-capitalize fw-normal ${
                    wellKnownCharges.find(w => w.id === c.id)
                      ? 'text-primary fw-bold'
                      : ''
                  }`}
                >
                  {wellKnownCharges.find(w => w.id === c.id)
                    ? wellKnownCharges.find(w => w.id === c.id)
                        .extendedChargeBasisValueDescription
                    : c?.extendedChargeBasisValueDescription}
                </label>
              </div>
            ))}
          </div>
          {readOnly || (
            <div className="mt-3 mx-2 d-flex align-items-center justify-content-end">
              <button onClick={updateWKC} className="btn btn-success px-5 ms-3">
                {localize('update')}
              </button>
            </div>
          )}
        </div>
        <div
          className="col bg-light m-2 p-3 "
          style={{
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h5 className="text-capitalize">{localize('indefiniteCharges')}</h5>
          <div
            style={{
              flex: 1,
              overflowY: 'scroll',
              border: 'solid 1px black',
              borderRadius: 5,
            }}
            className="p-2 bg-white my-3"
          >
            {dynamicCharges
              .sort((a, b) => a.extendedDescription > b.extendedDescription)
              .map(c => (
                <div className="d-flex align-items-center gap-2">
                  <Checkbox
                    disabled={readOnly}
                    id={c?.id}
                    checked={selectedIndefiniteCharges.find(s => s.id === c.id)}
                    onCheck={v => {
                      if (v) {
                        setSelectedIndefiniteCharges([
                          ...selectedIndefiniteCharges,
                          c,
                        ])
                      } else {
                        setSelectedIndefiniteCharges(
                          selectedIndefiniteCharges.filter(w => w.id !== c.id)
                        )
                      }
                    }}
                  />
                  <label
                    htmlFor={c?.id}
                    className={`text-capitalize fw-normal ${
                      selectedIndefiniteCharges.find(w => w.id === c.id)
                        ? 'text-primary fw-bold'
                        : ''
                    }`}
                  >
                    {c?.extendedDescription}
                  </label>
                </div>
              ))}
          </div>
          {readOnly || (
            <div className=" mx-2 d-flex align-items-center justify-content-end">
              <button
                onClick={updateSelectedIndefiniteCharges}
                className="btn btn-success px-5"
              >
                {localize('update')}
              </button>
            </div>
          )}
        </div>
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  ) : (
    <div className="">
      <div className="alert alert-danger fade show" role="alert">
        <h4 className="alert-heading">Loan Product Not Found!</h4>
        <p>Please add a loan in the previous page</p>
        <hr />
        <p className="mb-0">You can only add a loan product to proceed</p>
      </div>
    </div>
  )
}

export default Charges
