/** @format */

import React, { useState } from 'react'
import { postData } from '../../../../../../../Helpers/webApi'
import Loader from '../../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../../Global/Localize'
import DepartmentLookup from '../../../../../../../Components/Lookup/DepartmentLookup'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import { getTodaysDate } from '../../../../../../../Helpers/formatters'
import { PurchaseRequisitionEntries } from './PurchaseRequisitionEntries'

export const CreatePurchaseRequisitionModal = ({
  purchaseRequisitionModel,
  setPurchaseRequisitionModel,
  isBusy,
  purchaseRequisitionEntries,
  setPurchaseRequisitionEntries,
}) => {
  const [purchaseRequisitionItemModel, setPurchaseRequisitionItemModel] =
    useState(null)
  const [loading, setLoading] = useState(false)

  const handleAddPurchaseRequisition = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/PurchaseRequisition/add-purchase-requisition-entries',
      {
        Description: purchaseRequisitionItemModel?.description,
        Reason: purchaseRequisitionItemModel?.reason,
        Quantity: purchaseRequisitionItemModel?.quantity,
        PurchaseRequisitionItems: purchaseRequisitionEntries,
      },
      false,
    )
    if (success) {
      setPurchaseRequisitionEntries([...purchaseRequisitionEntries, data])
      setPurchaseRequisitionItemModel(null)
    }
    setLoading(false)
  }

  const handleOnRemoveRequisitionEntries = e => {
    setPurchaseRequisitionEntries(
      purchaseRequisitionEntries.filter(x => x.id !== e.id),
    )
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('department')}
              </label>
              <DepartmentLookup
                displayValue={purchaseRequisitionModel?.department?.description}
                onSelect={r => {
                  setPurchaseRequisitionModel({
                    ...r,
                    department: { ...r },
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('expectedDate')}
              </label>
              <CustomDatePicker
                name="expectedDate"
                defaultDate={purchaseRequisitionModel?.expectedDate}
                onDateChange={date => {
                  setPurchaseRequisitionModel({
                    ...purchaseRequisitionModel,
                    expectedDate: date,
                  })
                }}
                maxDate={getTodaysDate()}
              />
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120,
              }}
            >
              {localize('itemDetails')}
            </div>
            <div className="row mb-3" style={{ marginTop: -10 }}>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-1">
                  {localize('description')}
                </label>
                <input
                  className="form-control"
                  value={purchaseRequisitionItemModel?.description || ''}
                  onChange={e => {
                    setPurchaseRequisitionItemModel({
                      ...purchaseRequisitionItemModel,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3" style={{ marginTop: -10 }}>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-1">
                  {localize('reason')}
                </label>
                <input
                  className="form-control"
                  value={purchaseRequisitionItemModel?.reason || ''}
                  onChange={e => {
                    setPurchaseRequisitionItemModel({
                      ...purchaseRequisitionItemModel,
                      reason: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-3" style={{ marginTop: -10 }}>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-1">
                  {localize('quantity')}
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={e => {
                    setPurchaseRequisitionItemModel({
                      ...purchaseRequisitionItemModel,
                      quantity: e.target.value,
                    })
                  }}
                  value={purchaseRequisitionItemModel?.quantity || ''}
                />
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div
                className="bg-light d-flex justify-content-end align-items-center p-2"
                style={{ borderRadius: 5 }}
              >
                <button
                  onClick={handleAddPurchaseRequisition}
                  className="btn btn-success text-uppercase ms-3 px-4"
                >
                  {localize('add')}
                </button>
              </div>
            )}
            <div
              className="p-2 mt-4"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 120,
                  marginBottom: -10,
                }}
              >
                {localize('requisitionItems')}
              </div>
              <PurchaseRequisitionEntries
                entries={purchaseRequisitionEntries}
                handleOnRemove={handleOnRemoveRequisitionEntries}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
