/** @format */

import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../../Components/Loaders/Loader'
import AddressV2 from '../../../../../../../Components/Miscalleneous/AddressV2'
import { CustomerAccountPartial } from '../../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import Tab from '../../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../../Global/Localize'
import { getData } from '../../../../../../../Helpers/webApi'
import Specimen from '../../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'

const AccountPreview = ({ customerAccountId }) => {
  const [customerAccount, setCustomerAccount] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyFetchingCustomer, setIsBusyFetchingCustomer] = useState(false)

  const fetchCustomerAccount = async () => {
    if (
      customerAccountId === '00000000-0000-0000-0000-000000000000' ||
      !customerAccountId
    )
      return

    setIsBusy(true)

    const url = '/lookup/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId,
        includeBalances: false,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccount(data)
      setCustomer(data?.customer)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const fetchCustomer = async () => {
    setIsBusyFetchingCustomer(true)

    const url = '/lookup/Customer/find-customer'

    const { success, data } = await getData(
      url,
      {
        customerId: customerAccount?.customerId,
      },
      false
    )

    if (success) {
      setCustomer(data)
      setIsBusyFetchingCustomer(false)
    }

    setIsBusyFetchingCustomer(false)
  }

  useEffect(
    () => {
      if (customerAccountId) fetchCustomerAccount()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerAccountId]
  )

  useEffect(() => {
    if (customerAccount?.customerId) fetchCustomer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAccount?.customerId])

  return (
    <>
      <Tab
        preload
        tabItems={[
          {
            label: localize('details'),
            item:
              isBusy || isBusyFetchingCustomer ? (
                <Loader />
              ) : (
                <CustomerAccountPartial
                  customer={{
                    id: customer?.id,
                  }}
                  refreshFromServer
                  readOnly
                />
              ),
          },
          {
            label: localize('address'),
            item:
              isBusy || isBusyFetchingCustomer ? (
                <Loader />
              ) : (
                <AddressV2
                  parent={{
                    id: customer?.id,
                  }}
                  refreshFromServer
                  readOnly
                />
              ),
          },
          {
            label: localize('specimen'),
            item:
              isBusy || isBusyFetchingCustomer ? (
                <Loader />
              ) : (
                <Specimen
                  customer={{
                    id: customer?.id,
                  }}
                  refreshFromServer
                  readOnly
                />
              ),
          },
        ]}
      />
    </>
  )
}

export default AccountPreview
