/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { formatDate } from '../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'

function Departments() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('isRegistry') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isRegistry?.toString())}
        </span>
      ),
      sortBy: 'isRegistry',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [mode, setMode] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Department/find-department-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedDepartment(null)
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/Department/update-department',
            selectedDepartment || {
              description: '',
            },
            false
          )
        : await postData(
            '/Department/create-department',
            selectedDepartment || {
              description: '',
            },
            false
          )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedDepartment(null)
      loadTableData()
      showNotification('Departments', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedDepartment(null)
    setGlobalValidationErrors({})
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('humanResourceManagement'), localize('departments')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="holidayFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setMode('edit')
                              setSelectedDepartment(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />

                    {mode && (
                      <ModalTemplate
                        modalClassName="modal-lg"
                        modalTitle={'department'}
                        modalMode={mode}
                        handleAction={handleSubmit}
                        handleClose={closeModal}
                        actionText={mode === 'add' ? 'create' : 'update'}
                        footerChildren={
                          <>
                            <div className="d-flex align-items-center me-3 gap-2">
                              {mode == 'edit' ? (
                                <>
                                  <Checkbox
                                    id="isRegistryDepartment"
                                    onCheck={e => {
                                      setSelectedDepartment({
                                        ...selectedDepartment,
                                        isRegistry: e,
                                      })
                                    }}
                                    checked={selectedDepartment?.isRegistry}
                                  />
                                  <label
                                    htmlFor="isRegistryDepartment"
                                    className="text-capitalize"
                                  >
                                    {localize('isRegistry')}?
                                  </label>
                                  <div className="mx-3"></div>
                                </>
                              ) : (
                                ''
                              )}
                              <Checkbox
                                id="cDepartIsLocked"
                                onCheck={e => {
                                  setSelectedDepartment({
                                    ...selectedDepartment,
                                    isLocked: e,
                                  })
                                }}
                                checked={selectedDepartment?.isLocked}
                              />
                              <label
                                htmlFor="cDepartIsLocked"
                                className="text-capitalize"
                              >
                                {localize('isLocked')}?
                              </label>
                            </div>
                          </>
                        }
                      >
                        {isBusy ? (
                          <Loader />
                        ) : (
                          <>
                            <div className="row mb-3 g-3">
                              <div className="col-md-12">
                                <div
                                  className="with-validation d-flex align-items-center mb-2"
                                  validation-message={
                                    globalValidationErrors['Description']
                                      ?.message
                                  }
                                >
                                  <label className="text-capitalize col-1">
                                    {localize('name')}
                                  </label>
                                  <input
                                    defaultValue={
                                      selectedDepartment?.description
                                    }
                                    type="text"
                                    className="form-control"
                                    onChange={e => {
                                      clearValidation('Description')
                                      setSelectedDepartment({
                                        ...selectedDepartment,
                                        description: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </ModalTemplate>
                    )}
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p => {
                        setReqParams({ ...reqParams, pageIndex: p })
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Departments
