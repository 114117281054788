/** @format */

import { PleaseWait } from '../../../../../Components/Loaders/PleaseWait'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'

export const TextAlertsCommissions = ({
  disabled,
  isBusy,
  commissionsToBeSelected,
  onCheckCommissions,
  prefix,
}) => {
  const handleCheckCommissions = (commission, checkValue) => {
    const com = [...commissionsToBeSelected].map(comm => {
      if (comm.id === commission.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckCommissions(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {commissionsToBeSelected.map(commission => (
            <li
              key={commission.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${commission.id}`}
                checked={commission.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckCommissions(commission, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${commission.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  commission.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {commission?.extendedChargeBenefactorDescription}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
