/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import './cent-select.scss'

function EnumsCombo({
  onChange,
  isList,
  value,
  enums,
  customContainerStyle = {},
  ...otherProps
}) {
  const mappedOptions = Object.entries(enums).map(([value, { name }]) => ({
    label: name,
    value,
  }))

  const [val, setVal] = useState(null)

  useEffect(() => {
    let enumm = { label: enums[value]?.name, value }
    setVal(enumm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  return (
    <div
      style={{
        minWidth: 150,
        maxWidth: 'fit-content',
        ...customContainerStyle,
      }}
    >
      <Select
        {...otherProps}
        options={mappedOptions}
        value={val}
        onChange={onChange}
        className={'cent-select ' + otherProps?.className}
      />
    </div>
  )
}

export default EnumsCombo
