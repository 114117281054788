/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../../Helpers/webApi'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import { ViewRequisitionEntries } from './ViewRequisitionEntries'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { RequisitionApprovals } from './RequisitionApprovals'

export const ViewRequisitionModal = ({ selectedRequisition }) => {
  const [reqParams, setReqParams] = useState({
    quotationId: selectedRequisition?.id,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [requisitionApprovals, setRequisitionApprovals] = useState([])
  const [quotationEntries, setQuotationEntries] = useState([])
  const fetchRequisitionItems = async () => {
    const { success, data } = await getData(
      '/Requisition/find-requisitions-items-by-requisition-id-filter-in-page',
      {
        ...reqParams,
        requisitionId: selectedRequisition?.id,
      },
      false,
    )
    if (success) {
      setQuotationEntries(data)
    }
  }

  useEffect(() => {
    fetchRequisitionItems()
  }, [selectedRequisition?.id])

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('department')}
          </label>
          <input
            value={selectedRequisition?.departmentDescription}
            disabled
            className={'form-control'}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('expectedDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedRequisition?.expectedDate, true)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('reason')}</label>
          <input
            className="form-control"
            value={selectedRequisition?.reason}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <Tab
              tabItems={[
                {
                  label: localize('requisitionItems'),
                  item: (
                    <ViewRequisitionEntries
                      entries={quotationEntries}
                      setReqParams={setReqParams}
                      reqParams={reqParams}
                    />
                  ),
                },
                {
                  label: localize('approvals'),
                  item: <RequisitionApprovals entries={requisitionApprovals} />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}
