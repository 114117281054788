/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { generateGuid } from '../../../../../Helpers/extensions'
import Loader from '../../../../../Components/Loaders/Loader'

function CustomerCreditScores({ customer }) {
  const [scores, setScores] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  async function getScores() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/mobileLoan/find-credit-scores-by-customer-id',
      { customerId: customer?.id },
      false
    )
    if (success) {
      setIsBusy(false)
      setScores(data?.result || [])
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    customer && getScores()
    // eslint-disable-next-line
  }, [customer])

  const columns = [
    { label: 'loanCode', template: r => <>{r?.paddedMobileLoanCode}</> },
    { label: 'loanName', template: r => <>{r?.mobileLoanDescription}</> },
    {
      label: localize('loanTerm') + `(${localize('months')})`,
      template: r => <>{r?.mobileLoanTermInMonths}</>,
    },
    { label: 'loanProduct', template: r => <>{r?.loanProductDescription}</> },
    {
      label: 'productSection',
      template: r => <>{r?.loanProductSectionDescription}</>,
    },
    {
      label: 'productCategory',
      template: r => <>{r?.loanProductCategoryDescription}</>,
    },
    { label: 'minimumGuarantors', template: r => <>{r?.minimumGuarantors}</> },
    {
      label: 'averageNetSalary',
      template: r => <>{formatCurrency(r?.averageNetSalary)}</>,
    },
    {
      label: 'amountQualified',
      template: r => <>{formatCurrency(r?.amountQualified)}</>,
    },
    { label: 'remarks', template: r => <>{r?.remarks}</> },
    {
      label: localize('returnBookBalance') + '?',
      template: r => <>{r?.returnBookBalanceValueDescription}</>,
    },
    {
      label: `${localize('isLocked')}?`,
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
  ]

  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <SimpleTable
          columns={columns}
          data={scores.map(s => ({ id: generateGuid(), ...s }))}
        />
      )}
    </div>
  )
}

export default CustomerCreditScores
