/** @format */

import { postData, putData } from '../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { ViewCustomer } from '../../../../../Components/ReUsables/Customer/ViewCustomer'
import { useState } from 'react'
import { ModifyBiometricExemptionsDetails } from './ModifyBiometricExemptionsDetails'
import { SystemTransactionCode } from '../../../../../Global/enumeration'

function BiometricExemptionsModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedCustomer,
}) {
  const [selectedBiometricExemptions, setSelectedBiometricExemptions] =
    useState([])
  const [biometricExemptionModel, setBiometricExemptionModel] = useState({
    transactionCode: Object.keys(SystemTransactionCode)[1],
    transactionCodeDescription: SystemTransactionCode[1].name,
    customerId: selectedCustomer?.id,
  })
  const [defaultTransactionCode, setDefaultTransactionCode] = useState(
    Object.keys(SystemTransactionCode)[1]
  )

  const handleOnRemoveSelectedBiometricExemptions = biometricExemption => {
    setSelectedBiometricExemptions(
      selectedBiometricExemptions.filter(
        biometricExemptionDetail =>
          biometricExemptionDetail.transactionCode !==
          biometricExemption.transactionCode
      )
    )
  }

  const handleOnAddSelectedBiometricExemptions = () => {
    if (
      selectedBiometricExemptions.find(
        biometricExemption =>
          biometricExemption.transactionCode ===
          biometricExemptionModel.transactionCode
      )
    ) {
      return
    }

    setSelectedBiometricExemptions([
      ...selectedBiometricExemptions,
      {
        ...biometricExemptionModel,
        customerId: selectedCustomer?.id,
      },
    ])

    setBiometricExemptionModel({
      transactionCode: Object.keys(SystemTransactionCode)[1],
      transactionCodeDescription: SystemTransactionCode[1].name,
      customerId: selectedCustomer?.id,
    })
  }

  async function handleSubmit() {
    const { success } = await postData(
      '/BiometricExemption/update-biometric-exemption-collection',
      {
        CustomerId: selectedCustomer?.id,
        BiometricExemptionCollection: selectedBiometricExemptions,
      },
      true,
      "Operation Completed Successfully"
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('biometricExemptions')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            <ViewCustomer customer={selectedCustomer} />
            <ModifyBiometricExemptionsDetails
              setDefaultTransactionCode={setDefaultTransactionCode}
              defaultTransactionCode={defaultTransactionCode}
              setBiometricExemptionModel={setBiometricExemptionModel}
              biometricExemptionModel={biometricExemptionModel}
              onAddBiometricExemption={handleOnAddSelectedBiometricExemptions}
              onRemoveBiometricExemption={
                handleOnRemoveSelectedBiometricExemptions
              }
              selectedBiometricExemptions={selectedBiometricExemptions}
            />
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default BiometricExemptionsModal
