/** @format */

export function getRandomNumber(arr = [], min = 0, max = 1000) {
  let randomNumber

  do {
    randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
  } while (arr.includes(randomNumber))

  return randomNumber
}
