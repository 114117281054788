/** @format */

import ChargeDeterminationAlternateChannels from '../../Pages/AccountsManagement/ChargesDetermination/AlternateChannels/Index'
import ChargesDeterminationIndefinite from '../../Pages/AccountsManagement/ChargesDetermination/Indefinite/Index'
import ChargeDeterminationTextAlerts from '../../Pages/AccountsManagement/ChargesDetermination/TextAlerts/Index'
import ChargeDeterminationWellKnownCharges from '../../Pages/AccountsManagement/ChargesDetermination/WellKnown/Index'
import CreditTypes from '../../Pages/AccountsManagement/CreditTypes/Index'
import Investments from '../../Pages/AccountsManagement/FinancialProducts/Investments'
import Loans from '../../Pages/AccountsManagement/FinancialProducts/Loans/Loans'
import Savings from '../../Pages/AccountsManagement/FinancialProducts/Savings'
import ReconciliationCatalogue from '../../Pages/AccountsManagement/Operations/BankReconciliation/Catalogue/Catalogue'
import Closing from '../../Pages/AccountsManagement/Operations/BankReconciliation/Closing/Closing'
import Periods from '../../Pages/AccountsManagement/Operations/BankReconciliation/Periods/Periods'
import Processing from '../../Pages/AccountsManagement/Operations/BankReconciliation/Processing/Processing'
import BankCatalogue from '../../Pages/AccountsManagement/Operations/BankToBank/Catalogue/Catalogue'
import { default as BankReceipting } from '../../Pages/AccountsManagement/Operations/BankToBank/Receipting/Receipting'
import BankReconciliation from '../../Pages/AccountsManagement/Operations/BankToBank/Reconciliation/Reconciliation'
import BankVerification from '../../Pages/AccountsManagement/Operations/BankToBank/Verification/Verification'
import AuthorizationAccruedInterest from '../../Pages/AccountsManagement/Operations/BatchProcedures/Authorization/AccruedInterest/AccruedInterest'
import AuthorizationCredit from '../../Pages/AccountsManagement/Operations/BatchProcedures/Authorization/Credit/Index'
import AuthorizationDebit from '../../Pages/AccountsManagement/Operations/BatchProcedures/Authorization/Debit/Debit'
import AuthorizationInterAccountTransfer from '../../Pages/AccountsManagement/Operations/BatchProcedures/Authorization/InterAccountTransfer/InterAccountTransfer'
import AuthorizationReversal from '../../Pages/AccountsManagement/Operations/BatchProcedures/Authorization/Reversal/Reversal'
import AuthorizationVoucher from '../../Pages/AccountsManagement/Operations/BatchProcedures/Authorization/Voucher/Index'
import AccruedInterest from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/AccruedInterest/AccruedInterest'
import Index from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/Credit'
import Debit from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/Debit/Debit'
import GeneralLedger from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/GeneralLedger/GeneralLedger'
import InterAccountTransfer from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/InterAccountTransfer/InterAccountTransfer'
import Reversal from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/Reversal/Reversal'
import Voucher from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/Voucher/Index'
import WireTransfer from '../../Pages/AccountsManagement/Operations/BatchProcedures/Origination/WireTransfer/WireTransfer'
import VerificationAccruedInterest from '../../Pages/AccountsManagement/Operations/BatchProcedures/Verification/AccruedInterest/AccruedInterest'
import VerificationCredit from '../../Pages/AccountsManagement/Operations/BatchProcedures/Verification/Credit/Index'
import VerificationDebit from '../../Pages/AccountsManagement/Operations/BatchProcedures/Verification/Debit/Debit'
import VerificationInterAccountTransfer from '../../Pages/AccountsManagement/Operations/BatchProcedures/Verification/InterAccountTransfer/InterAccountTransfer'
import VerificationReversal from '../../Pages/AccountsManagement/Operations/BatchProcedures/Verification/Reversal/Reversal'
import VerificationVoucher from '../../Pages/AccountsManagement/Operations/BatchProcedures/Verification/Voucher/Index'
import VerificationWireTransfer from '../../Pages/AccountsManagement/Operations/BatchProcedures/Verification/WireTransfer/WireTransfer'
import Appropriation from '../../Pages/AccountsManagement/Operations/BudgetManagement/Appropriation/Appropriation'
import Hierarchies from '../../Pages/AccountsManagement/Operations/BudgetManagement/Hierarchies/Hierarchies'
import Supplements from '../../Pages/AccountsManagement/Operations/BudgetManagement/Supplements/Supplements'
import CheckTruncationCatalogue from '../../Pages/AccountsManagement/Operations/ChequeTruncationRequests/Catalogue/Catalogue'
import DirectDebitCatalogue from '../../Pages/AccountsManagement/Operations/DirectDebitRequests/Catalogue/Catalogue'
import Logs from '../../Pages/AccountsManagement/Operations/DirectDebitRequests/Logs/Logs'
import MobileCatalogue from '../../Pages/AccountsManagement/Operations/MobileToBank/Catalogue/Catalogue'
import MobileToBankCollection from '../../Pages/AccountsManagement/Operations/MobileToBank/Collections/MobileToBankCollection'
import MobileReconciliation from '../../Pages/AccountsManagement/Operations/MobileToBank/Reconciliation/Reconciliation'
import MobileVerification from '../../Pages/AccountsManagement/Operations/MobileToBank/Verification/Verification'
import CustomerLoansNetting from '../../Pages/AccountsManagement/Operations/RecurringProcedures/CustomerLoansNetting/CustomerLoansNetting'
import MatchingJointly from '../../Pages/AccountsManagement/Operations/RecurringProcedures/DiscrepancyMatching/MatchingJointly/MatchingJointly'
import MatchingSingly from '../../Pages/AccountsManagement/Operations/RecurringProcedures/DiscrepancyMatching/MatchingSingly/MatchingSingly'
import DividendsComputation from '../../Pages/AccountsManagement/Operations/RecurringProcedures/DividendsComputation/DividendsComputation'
import DynamicSavingsFees from '../../Pages/AccountsManagement/Operations/RecurringProcedures/DynamicSavingsFees/DynamicSavingsFees'
import IntraAccountTransfer from '../../Pages/AccountsManagement/Operations/RecurringProcedures/IntraAccountTransfer/IntraAccountTransfer'
import MonthlyAccountBalance from '../../Pages/AccountsManagement/Operations/RecurringProcedures/MonthlyAccountBalance/MonthlyAccountBalance'
import PostingPeriodClosing from '../../Pages/AccountsManagement/Operations/RecurringProcedures/PostingPeriodClosing/PostingPeriodClosing'
import StandingOrderExecution from '../../Pages/AccountsManagement/Operations/RecurringProcedures/StandingOrderExecution/StandingOrderExecution'
import GLAccountTransactions from '../../Pages/AccountsManagement/Operations/TransactionsJournal/GLAccountTransactions/GLAccountTransactions'
import GLAccountTransactionsArchived from '../../Pages/AccountsManagement/Operations/TransactionsJournal/GLAccountTransactionsArchived/GLAccountTransactionsArchived'
import SystemTransactions from '../../Pages/AccountsManagement/Operations/TransactionsJournal/SystemTransactions/SystemTransactions'
import SystemTransactionsArchived from '../../Pages/AccountsManagement/Operations/TransactionsJournal/SystemTransactionsArchived/SystemTransactionsArchived'
import TemplateBasedReports from '../../Pages/AccountsManagement/Operations/TransactionsJournal/TemplateBasedReports/TemplateBasedReports'
import ReportTemplates from '../../Pages/AccountsManagement/Setup/ReportTemplates/ReportTemplates'
import RevenueTaxes from '../../Pages/AccountsManagement/RevenueTaxes'
import Charges from '../../Pages/AccountsManagement/Setup/Charges/Charges'
import AccountDetermination from '../../Pages/AccountsManagement/Setup/ChartOfAccounts/AccountsDetermination/AccountDetermination'
import ChartOfAccounts from '../../Pages/AccountsManagement/Setup/ChartOfAccounts/ChartOfAccounts'
import CostCentres from '../../Pages/AccountsManagement/Setup/CostCentres/CostCentres'
import CostDistributionRules from '../../Pages/AccountsManagement/Setup/CostDistributionRules/CostDistributionRules'
import Levies from '../../Pages/AccountsManagement/Setup/Levies/Levies'
import BankLinkages from '../../Pages/AccountsManagement/Setup/Miscellaneous/BankLinkages'
import DebitTypes from '../../Pages/AccountsManagement/Setup/Miscellaneous/DebitTypes/DebitTypes'
import DirectDebits from '../../Pages/AccountsManagement/Setup/Miscellaneous/DirectDebits/DirectDebits'
import MobileToBankTerminals from '../../Pages/AccountsManagement/Setup/Miscellaneous/MobileToBankTerminal/MobileToBankTerminals'
import PayrollNumberTypes from '../../Pages/AccountsManagement/Setup/Miscellaneous/PayrollNumberTypes'
import TransactionReferences from '../../Pages/AccountsManagement/Setup/Miscellaneous/TransactionReferences'
import WireTransferTypes from '../../Pages/AccountsManagement/Setup/Miscellaneous/WireTransferTypes/WireTransferTypes'
import WithdrawalSettlementModes from '../../Pages/AccountsManagement/Setup/Miscellaneous/WithdrawalSettlementMode/WithdrawalSettlementModes'

const accountsMenus = [
  {
    iconClass: 'uil uil-money-bill',
    label: 'accountsManagement',
    code: 6,
    setup: [
      {
        label: 'GLAccounts',
        children: [
          {
            label: 'costCentres',
            component: <CostCentres menuCode={6002} />,
            parentCode: 6,
            code: 6002,
            description: 'costCentres',
          },

          {
            label: 'costDistributionRules',
            component: <CostDistributionRules menuCode={6003} />,
            parentCode: 6,
            code: 6003,
            description: 'costDistributionRules',
          },

          {
            label: 'chartOfAccounts',
            component: <ChartOfAccounts menuCode={6004} />,
            parentCode: 6,
            code: 6004,
            description: 'chartOfAccounts',
          },

          {
            label: 'GLAccountDetermination',
            component: <AccountDetermination menuCode={6005} />,
            parentCode: 6,
            code: 6005,
            description: 'GLAccountDetermination',
          },

          {
            label: 'reportTemplates',
            component: <ReportTemplates menuCode={6006} />,
            parentCode: 6,
            code: 6006,
            description: 'reportTemplates',
          },
        ],
      },
      {
        label: 'leviesAndCharges',
        children: [
          {
            label: 'levies',
            component: <Levies menuCode={6008} />,
            parentCode: 6,
            code: 6008,
            description: 'levies',
          },

          {
            label: 'charges',
            component: <Charges menuCode={6009} />,
            parentCode: 6,
            code: 6009,
            description: 'charges',
          },
          {
            label: 'revenueTaxes',
            component: <RevenueTaxes menuCode={6010} />,
            parentCode: 6,
            code: 6010,
            description: 'revenueTaxes',
          },

          {
            label: 'chargesDetermination',
            children: [
              {
                label: 'wellKnown',
                component: (
                  <ChargeDeterminationWellKnownCharges menuCode={6012} />
                ),
                parentCode: 6,
                code: 6012,
                description: 'wellKnownCharges',
              },

              {
                label: 'indefinite',
                component: <ChargesDeterminationIndefinite menuCode={6013} />,
                parentCode: 6,
                code: 6013,
                description: 'indefiniteCharges',
              },

              {
                label: 'textAlerts',
                component: <ChargeDeterminationTextAlerts menuCode={6014} />,
                parentCode: 6,
                code: 6014,
                description: 'textAlertsCharges',
              },

              {
                label: 'alternateChannels',
                component: (
                  <ChargeDeterminationAlternateChannels menuCode={6015} />
                ),
                parentCode: 6,
                code: 6015,
                description: 'alternateChannelsCharges',
              },
            ],
          },
        ],
      },
      {
        label: 'financialProducts',
        children: [
          {
            label: 'savings',
            component: <Savings menuCode={6017} />,
            parentCode: 6,
            code: 6017,
            description: 'savingsProducts',
          },

          {
            label: 'investments',
            component: <Investments menuCode={6018} />,
            parentCode: 6,
            code: 6018,
            description: 'investmentProducts',
          },

          {
            label: 'loans',
            component: <Loans menuCode={6019} />,
            parentCode: 6,
            code: 6019,
            description: 'loanProducts',
          },
        ],
      },
      {
        label: 'miscellaneous',
        children: [
          {
            label: 'payrollNumberTypes',
            component: <PayrollNumberTypes menuCode={6021} />,
            parentCode: 6,
            code: 6021,
            description: 'payrollNumberTypes',
          },

          {
            label: 'bankLinkages',
            component: <BankLinkages menuCode={6022} />,
            parentCode: 6,
            code: 6022,
            description: 'bankLinkages',
          },

          {
            label: 'directDebits',
            component: <DirectDebits menuCode={6025} />,
            parentCode: 6,
            code: 6025,
            description: 'directDebits',
          },

          {
            label: 'creditTypes',
            component: <CreditTypes menuCode={6023} />,
            parentCode: 6,
            code: 6023,
            description: 'creditTypes',
          },

          {
            label: 'debitTypes',
            component: <DebitTypes menuCode={6024} />,
            parentCode: 6,
            code: 6024,
            description: 'debitTypes',
          },

          {
            label: 'wireTransferTypes',
            component: <WireTransferTypes menuCode={6026} />,
            parentCode: 6,
            code: 6026,
            description: 'wireTransferTypes',
          },

          {
            label: 'mobileToBankTerminals',
            component: <MobileToBankTerminals menuCode={6027} />,
            parentCode: 6,
            code: 6027,
            description: 'mobileToBankTerminals',
          },

          {
            label: 'withdrawalSettlementModes',
            component: <WithdrawalSettlementModes menuCode={6028} />,
            parentCode: 6,
            code: 6028,
            description: 'withdrawalSettlementModes',
          },

          {
            label: 'transactionReferences',
            component: <TransactionReferences menuCode={6029} />,
            parentCode: 6,
            code: 6029,
            description: 'transactionReferences',
          },
        ],
      },
    ],
    operations: [
      {
        label: 'transactionsJournal',
        children: [
          {
            label: 'systemTransactions',
            component: <SystemTransactions menuCode={6501} />,
            parentCode: 6,
            code: 6501,
            description: 'systemTransactionsJournal',
          },

          {
            label: 'gLAccountTransactions',
            component: <GLAccountTransactions menuCode={6502} />,
            parentCode: 6,
            code: 6502,
            description: 'gLAccountTransactions',
          },

          {
            label: 'templateBasedReports',
            component: <TemplateBasedReports menuCode={6503} />,
            parentCode: 6,
            code: 6503,
            description: 'templateBasedReports',
          },

          {
            label: 'systemTransactionsArchived',
            component: <SystemTransactionsArchived menuCode={6504} />,
            parentCode: 6,
            code: 6504,
            description: 'systemTransactionsArchived',
          },

          {
            label: 'gLAccountTransactionsArchived',
            component: <GLAccountTransactionsArchived menuCode={6505} />,
            parentCode: 6,
            code: 6505,
            description: 'gLAccountTransactionsArchived',
          },
        ],
      },
      {
        label: 'batchProcedures',
        children: [
          {
            label: 'origination',
            children: [
              {
                label: 'voucher',
                component: <Voucher menuCode={6508} />,
                description: 'journalVouchersOrigination',
                parentCode: 6,
                code: 6508,
              },
              {
                label: 'credit',
                description: 'creditBatchesOrigination',
                component: <Index menuCode={6509} />,
                parentCode: 6,
                code: 6509,
              },

              {
                label: 'debit',
                component: <Debit menuCode={6510} />,
                parentCode: 6,
                code: 6510,
                description: 'debitBatches',
              },

              {
                label: 'wireTransfer',
                description: 'wireTransferOrigination',
                component: <WireTransfer menuCode={6511} />,
                parentCode: 6,
                code: 6511,
              },

              {
                label: 'reversal',
                component: <Reversal menuCode={6512} />,
                parentCode: 6,
                code: 6512,
                description: 'journalReversalBatches',
              },

              {
                label: 'interAccountTransfer',
                component: <InterAccountTransfer menuCode={6513} />,
                parentCode: 6,
                code: 6513,
                description: 'interAccountTransferBatches',
              },

              {
                label: 'generalLedger',
                description: 'generalLedgers',
                component: <GeneralLedger menuCode={6514} />,
                parentCode: 6,
                code: 6514,
              },
              {
                label: 'accruedInterest',
                component: <AccruedInterest menuCode={6515} />,
                parentCode: 6,
                code: 6515,
                description: 'accruedInterestBatches',
              },
            ],
          },

          {
            label: 'verification',
            children: [
              {
                label: 'voucher',
                description: 'journalVouchersVerification',
                component: <VerificationVoucher menuCode={6517} />,
                parentCode: 6,
                code: 6517,
              },

              {
                label: 'credit',
                description: 'creditBatchesVerification',
                component: <VerificationCredit menuCode={6518} />,
                parentCode: 6,
                code: 6518,
              },

              {
                label: 'debit',
                component: <VerificationDebit menuCode={6519} />,
                parentCode: 6,
                code: 6519,
                description: 'debitBatches',
              },

              {
                label: 'wireTransfer',
                description: 'wireTransferBatches',
                component: <VerificationWireTransfer menuCode={6520} />,
                parentCode: 6,
                code: 6520,
              },

              {
                label: 'reversal',
                component: <VerificationReversal menuCode={6521} />,
                parentCode: 6,
                code: 6521,
                description: 'journalReversalBatches',
              },

              {
                label: 'interAccountTransfer',
                component: <VerificationInterAccountTransfer menuCode={6522} />,
                parentCode: 6,
                code: 6522,
                description: 'interAccountTransferBatches',
              },

              {
                label: 'generalLedger',
                description: 'generalLedgers',
                component: <GeneralLedger verification menuCode={6523} />,
                parentCode: 6,
                code: 6523,
              },

              {
                label: 'accruedInterest',
                component: <VerificationAccruedInterest menuCode={6524} />,
                parentCode: 6,
                code: 6524,
                description: 'accruedInterestBatches',
              },
            ],
          },

          {
            label: 'authorization',
            children: [
              {
                label: 'voucher',
                component: <AuthorizationVoucher menuCode={6526} />,
                parentCode: 6,
                code: 6526,
                description: 'journalVouchersAuthorization',
              },

              {
                label: 'credit',
                component: <AuthorizationCredit menuCode={6527} />,
                parentCode: 6,
                code: 6527,
                description: 'creditBatchesAuthorization',
              },
              {
                label: 'debit',
                component: <AuthorizationDebit menuCode={6528} />,
                parentCode: 6,
                code: 6528,
                description: 'debitBatches',
              },

              {
                label: 'wireTransfer',
                description: 'wireTransferBatches',
                component: (
                  <VerificationWireTransfer authorize menuCode={6529} />
                ),
                parentCode: 6,
                code: 6529,
              },

              {
                label: 'reversal',
                component: <AuthorizationReversal menuCode={6530} />,
                parentCode: 6,
                code: 6530,
                description: 'journalReversalBatches',
              },

              {
                label: 'interAccountTransfer',
                component: (
                  <AuthorizationInterAccountTransfer menuCode={6531} />
                ),
                parentCode: 6,
                code: 6531,
                description: 'interAccountTransferBatches',
              },

              {
                label: 'generalLedger',
                description: 'generalLedgers',
                component: <GeneralLedger authorization menuCode={6532} />,
                parentCode: 6,
                code: 6532,
              },

              {
                label: 'accruedInterest',
                component: <AuthorizationAccruedInterest menuCode={6533} />,
                parentCode: 6,
                code: 6533,
                description: 'accruedInterestBatches',
              },
            ],
          },
        ],
      },

      {
        label: 'recurringProcedures',
        children: [
          {
            label: 'dynamicSavingsFees',
            component: <DynamicSavingsFees menuCode={6535} />,
            parentCode: 6534,
            code: 6535,
            description: 'dynamicSavingsFees',
          },

          {
            label: 'standingOrderExecution',
            component: <StandingOrderExecution menuCode={6536} />,
            parentCode: 6534,
            code: 6536,
            description: 'standingOrderExecution',
          },

          {
            label: 'intraAccountTransfer',
            component: <IntraAccountTransfer menuCode={6537} />,
            parentCode: 6,
            code: 6537,
            description: 'intraAccountTransfer',
          },

          {
            label: 'postingPeriodClosing',
            component: <PostingPeriodClosing menuCode={6538} />,
            parentCode: 6534,
            code: 6538,
            description: 'postingPeriodClosing',
          },

          {
            label: 'monthlyAccountBalance',
            component: <MonthlyAccountBalance menuCode={6539} />,
            parentCode: 6534,
            code: 6539,
            description: 'monthlyAccountBalance',
          },

          {
            label: 'dividendsComputations',
            component: <DividendsComputation menuCode={6540} />,
            parentCode: 6534,
            code: 6540,
            description: 'dividendsComputations',
          },

          {
            label: 'customerLoansNettingOff',
            component: <CustomerLoansNetting menuCode={6541} />,
            parentCode: 6534,
            code: 6541,
            description: 'customerLoansNettingOff',
          },
          {
            label: 'discrepancyMatching',
            children: [
              {
                label: 'matchingSingly',
                component: <MatchingSingly menuCode={6543} />,
                parentCode: 6542,
                code: 6543,
                description: 'discrepancyMatchingSingly',
              },

              {
                label: 'matchingJointly',
                component: <MatchingJointly menuCode={6544} />,
                parentCode: 6,
                code: 6544,
                description: 'discrepancyMatchingJointly',
              },
            ],
          },
        ],
      },

      {
        label: 'mobileToBank',
        children: [
          {
            label: 'catalogue',
            component: <MobileCatalogue menuCode={6546} />,
            parentCode: 6,
            code: 6546,
            description: 'mobileToBankRequestsCatalogue',
          },

          {
            label: 'reconciliation',
            component: <MobileReconciliation menuCode={6547} />,
            parentCode: 6,
            code: 6547,
            description: 'mobileToBankRequestsReconciliation',
          },
          {
            label: 'verification',
            component: <MobileVerification menuCode={6548} />,
            parentCode: 6,
            code: 6548,
            description: 'mobileToBankRequestsVerification',
          },
          {
            label: 'collections',
            component: <MobileToBankCollection menuCode={6549} />,
            parentCode: 6,
            code: 6549,
            description: 'mobileToBankRequestsCollections',
          },
        ],
      },

      {
        label: 'bankToBank',
        children: [
          {
            label: 'catalogue',
            component: <BankCatalogue menuCode={6551} />,
            parentCode: 6,
            code: 6551,
            description: 'bankToBankRequestsCatalogue',
          },

          {
            label: 'reconciliation',
            component: <BankReconciliation menuCode={6552} />,
            parentCode: 6,
            code: 6552,
            description: 'bankToBankRequestsReconciliation',
          },

          {
            label: 'receipting',
            component: <BankReceipting menuCode={6553} />,
            parentCode: 6,
            code: 6553,
            description: 'bankToBankRequestsReceipting',
          },

          {
            label: 'verification',
            component: <BankVerification menuCode={6554} />,
            parentCode: 6,
            code: 6554,
            description: 'bankToBankRequestsVerification',
          },
        ],
      },

      {
        label: 'bankReconciliation',
        children: [
          {
            label: 'periods',
            component: <Periods menuCode={6556} />,
            parentCode: 6,
            code: 6556,
            description: 'bankReconciliationPeriods',
          },
          {
            label: 'processing',
            component: <Processing menuCode={6557} />,
            parentCode: 6,
            code: 6557,
            description: 'bankReconciliationProcessing',
          },

          {
            label: 'closing',
            component: <Closing menuCode={6558} />,
            parentCode: 6,
            code: 6558,
            description: 'bankReconciliationClosing',
          },

          {
            label: 'catalogue',
            component: <ReconciliationCatalogue menuCode={6559} />,
            parentCode: 6,
            code: 6559,
            description: 'bankReconciliationCatalogue',
          },
        ],
      },

      {
        label: 'budgetManagement',
        children: [
          {
            label: 'hierarchies',
            component: <Hierarchies menuCode={6561} />,
            parentCode: 6,
            code: 6561,
            description: 'budgetHierarchies',
          },

          {
            label: 'appropriation',
            component: <Appropriation menuCode={6562} />,
            parentCode: 6,
            code: 6562,
            description: 'budgetAppropriation',
          },

          {
            label: 'supplements',
            component: <Supplements menuCode={6563} />,
            parentCode: 6,
            code: 6563,
            description: 'budgetSupplements',
          },
        ],
      },

      {
        label: 'checkTruncationRequests',
        children: [
          {
            label: 'catalogue',
            component: <CheckTruncationCatalogue menuCode={6564} />,
            parentCode: 6,
            code: 6564,
            description: 'checkTruncationRequestsCatalogue',
          },
        ],
      },

      {
        label: 'directDebitRequests',
        children: [
          {
            label: 'catalogue',
            component: <DirectDebitCatalogue menuCode={6566} />,
            parentCode: 6,
            code: 6566,
            description: 'directDebitRequestsCatalogue',
          },

          {
            label: 'logs',
            component: <Logs menuCode={6568} />,
            parentCode: 6,
            code: 6568,
            description: 'directDebitRequestsLogs',
          },
        ],
      },
    ],
  },
]

export default accountsMenus
