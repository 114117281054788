/** @format */

import React from 'react'

export const ViewQRCode = ({ base64, show, setShow }) => {
  return (
    <>
      <div
        className={`view-qr-code ${show ? 'shown' : ''}`}
        onClick={() => {
          setShow(false)
        }}
      >
        <div className="close-it" onClick={() => setShow(false)}>
          <i className="uil uil-times"></i>
        </div>
        <img
          onClick={e => e.stopPropagation()}
          src={base64}
          alt=""
          style={{
            borderRadius: 5,
            objectFit: 'contain',
            boxShadow: 'black 0 0 50px',
            width: 250,
            height: 250,
          }}
        />
      </div>
    </>
  )
}
