/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../../Components/Loaders/Loader'
import AddressV2 from '../../../../../../../Components/Miscalleneous/AddressV2'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
import { getData } from '../../../../../../../Helpers/webApi'
import MorgueServices from '../../../../Partials/MorgueServices'
import IndividualParticulars from '../IndividualParticulars/IndividualParticulars'
import ListNextOfKin from '../NextOfKinDetails/ListNextOfKin'

const ViewMorguePatient = ({ patientData, mode, handleClose }) => {
  const [bed, setBed] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [patient, setPatient] = useState({})
  const [nextOfKins, setNextOfKins] = useState([])

  const fetchNextOfKinsDetails = async () => {
    setIsBusy(true)

    const url =
      '/MorguePatient/find-morgue-patient-next-of-kins-by-morgue-patient-id'

    const { success, data } = await getData(
      url,
      {
        morguePatientId: patientData?.id,
      },
      false
    )

    if (success) {
      setNextOfKins(data?.result)
    }

    setIsBusy(false)
  }

  const fetchBed = async () => {
    const url = '/Bed/find-bed'
    const { success, data } = await getData(
      url,
      {
        bedId: patientData?.bedId,
      },
      false
    )

    if (success) {
      setBed(data)
    }
  }

  useEffect(() => {
    if (patientData?.id) {
      fetchBed()
      fetchNextOfKinsDetails()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData?.id])

  useEffect(() => {
    if (patientData?.id) {
      setPatient({
        individualType: patientData?.individualType,
        gender: patientData?.individualGender,
        nationality: patientData?.individualNationality,
        identityCardType: patientData?.individualIdentityCardType,
        salutation: patientData?.individualSalutation,
        maritalStatus: patientData?.individualMaritalStatus,
        firstName: patientData?.individualFirstName,
        lastName: patientData?.individualLastName,
        identityCardNumber: patientData?.individualIdentityCardNumber,
        identityCardSerialNumber:
          patientData?.individualIdentityCardSerialNumber,
        birthDate: patientData?.individualBirthDate,
        deathTime: patientData?.deathTime,
        registrationTime: patientData?.registrationTime,
        deathDate: patientData?.deathDate,
        registrationDate: patientData?.registrationDate,
        additionalNotes: patientData?.additionalNotes,
        propertyNotes: patientData?.propertyNotes,
        genderDescription: patientData?.individualGenderDescription,
        salutationDescription: patientData?.individualSalutationDescription,
        identityCardTypeDescription:
          patientData?.individualIdentityCardTypeDescription,
      })
      fetchBed()
      fetchNextOfKinsDetails()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData?.id])

  return (
    <ModalTemplate
      modalMode={mode}
      actionText="update"
      disableCreate={isBusy}
      handleClose={handleClose}
      hideUpdate
      modalTitle={localize('releaseMorguePatient')}
      modalClassName="modal-xl"
      footerChildren={
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex align-items-center">
            <Checkbox
              disabled
              id="isLockedReleaseMorguePatient"
              checked={patient?.isLocked}
            />

            <label
              htmlFor="isLockedReleaseMorguePatient"
              className="text-capitalize"
            >
              {localize('isLocked') + ' ?'}
            </label>
          </div>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">
                {localize('room')}
              </label>
              <input
                className="form-control"
                value={bed?.roomDescription}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">
                {localize('type')}
              </label>
              <input
                className="form-control"
                value={bed?.roomTypeDescription}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">{localize('bed')}</label>
              <input
                className="form-control"
                value={bed?.description}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">
                {localize('status')}
              </label>
              <input
                className="form-control"
                value={patientData?.recordStatusDescription}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">
                {localize('createdBy')}
              </label>
              <input
                className="form-control"
                value={patientData?.createdBy}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">
                {localize('releasedBy')}
              </label>
              <input
                className="form-control"
                value={patientData?.releasedBy}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">
                {localize('createdDate')}
              </label>
              <input
                className="form-control"
                value={formatDate(patientData?.createdDate)}
                disabled
              />
            </div>
            <div className="col-md-4 d-flex align-item-center">
              <label className="col-4 text-capitalize">
                {localize('releasedDate')}
              </label>
              <input
                className="form-control"
                value={formatDate(patientData?.releasedDate)}
                disabled
              />
            </div>
          </div>
          <div className="mt-4">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('individualParticulars'),
                  item: (
                    <IndividualParticulars
                      patient={patient}
                      setPatient={setPatient}
                      readOnly
                    />
                  ),
                },
                {
                  label: localize('address'),
                  item: (
                    <AddressV2
                      parent={patient}
                      onChange={value => setPatient(value)}
                      readOnly
                    />
                  ),
                },
                {
                  label: localize('nextOfKinDetails'),
                  item: <ListNextOfKin nextOfKins={nextOfKins} />,
                },
                {
                  label: localize('morgueServices'),
                  item: <MorgueServices patientData={patientData} />,
                },
                {
                  label: localize('extraInformation'),
                  item: (
                    <div className="row g-3">
                      <div className="col-md-12 d-flex align-items-center">
                        <label className="text-capitalize col-1">
                          {localize('additionalNotes')}
                        </label>
                        <textarea
                          className="form-control"
                          value={patient?.additionalNotes}
                          disabled
                        />
                      </div>
                      <div className="col-md-12 d-flex align-items-center">
                        <label className="text-capitalize col-1">
                          {localize('propertyNotes')}
                        </label>
                        <textarea
                          className="form-control"
                          disabled
                          value={patient?.propertyNotes}
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ViewMorguePatient
