/** @format */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { customerAssociateRelationship } from '../../../../../Global/enumeration'
import { parseDate } from '../../../../../Helpers/formatters'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

function Associates({ associates, readOnly, onChange }) {
  const [collection, setCollection] = useState(associates)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const columns = [
    { label: 'relationship', template: r => <>{r.relationshipDescription}</> },
    { label: 'name', template: r => <>{r.associateFullName}</> },
    {
      label: localize('accountNumber'),
      template: r => <>{r.associateReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.associateReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.associateReference3}</>,
    },
    {
      label: 'gender',
      template: r => <>{r.associateIndividualGenderDescription}</>,
    },
    {
      label: 'identityCardType',
      template: r => <>{r.associateIdentityCardTypeDescription}</>,
    },
    {
      label: 'identityCardNumber',
      template: r => <>{r.associateIdentityCardNumber}</>,
    },
    {
      label: `${localize('addressLine')} 1`,
      template: r => <>{r.associateAddressAddressLine1}</>,
    },
    {
      label: `${localize('addressLine')} 2`,
      template: r => <>{r.associateAddressAddressLine2}</>,
    },
    {
      label: `physicalAddress`,
      template: r => <>{r.associateAddressStreet}</>,
    },
    { label: `postalCode`, template: r => <>{r.associateAddressPostalCode}</> },
    { label: `city`, template: r => <>{r.associateAddressCity}</> },
    { label: `emailAddress`, template: r => <>{r.associateAddressEmail}</> },
    { label: `landLine`, template: r => <>{r.associateAddressLandLine}</> },
    { label: `mobileLine`, template: r => <>{r.associateAddressMobileLine}</> },
    { label: `remarks`, template: r => <>{r.remarks}</> },
  ]

  useEffect(() => {
    if (!readOnly) {
      onChange(collection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection])

  return readOnly ? (
    <SimpleTable data={associates} columns={columns} />
  ) : (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerName')}
          </label>
          <CustomersLookup
            onSelect={c => setSelectedCustomer(c)}
            displayValue={selectedCustomer?.fullName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.membershipClassCustomerTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerMember')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.memberNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employer')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.employerDescription}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('duty/Workstation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.dutyStationDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identification')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.identificationNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('adminDivision')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.administrativeDivisonDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('registrationDate')}
          </label>
          <input
            type="date"
            className="form-control"
            disabled
            value={parseDate(selectedCustomer?.registrationDate)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.accountNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.membershipNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('pfNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.personalFileNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedCustomer?.remarks}
          />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="CustomerAssociateRelationship"
            onChange={e => {
              setSelectedCustomer({
                ...selectedCustomer,
                relationship: e.value,
                relationshipDescription: customerAssociateRelationship[e.value],
              })
            }}
            value={
              selectedCustomer?.relationship || customerAssociateRelationship[0]
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-2 text-capitalize">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedCustomer?.newRemarks}
            onChange={e =>
              setSelectedCustomer({
                ...selectedCustomer,
                newRemarks: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="bg-light d-flex align-items-center justify-content-end my-2 p-2">
        <button
          className="btn btn-success px-3"
          onClick={() => {
            let c = [...collection]
            if (
              collection.find(cus => cus.customerId === selectedCustomer?.id)
            ) {
              return Swal.fire('error', 'associate exists', 'error')
            }
            let entry = {
              id: selectedCustomer?.id,
              customerId: selectedCustomer?.id,
              associateId: selectedCustomer?.id,
              associateFullName: selectedCustomer?.fullName,
              associateIndividualIdentityCardTypeDescription:
                selectedCustomer?.identityCardTypeDescription,
              associateIndividualIdentityCardNumber:
                selectedCustomer?.individualIdentityCardNumber,
              associateIndividualGenderDescription:
                selectedCustomer?.individualGenderDescription,
              associateAddressAddressLine1:
                selectedCustomer?.addressAddressLine1,
              associateAddressAddressLine2:
                selectedCustomer?.addressAddressLine2,
              associateAddressStreet: selectedCustomer?.addressStreet,
              associateAddressPostalCode: selectedCustomer?.addressPostalCode,
              associateAddressCity: selectedCustomer?.addressCity,
              associateAddressEmail: selectedCustomer?.addressEmail,
              associateAddressLandLine: selectedCustomer?.addressLandLine,
              associateAddressMobileLine: selectedCustomer?.addressMobileLine,
              associateReference1: selectedCustomer?.accountNumber,
              associateReference2: selectedCustomer?.memberNumber,
              associateReference3: selectedCustomer?.personalFileNumber,
              relationship: selectedCustomer?.relationship,
              relationshipDescription:
                selectedCustomer?.relationshipDescription,
              remarks: selectedCustomer?.newRemarks,
            }
            c.push(entry)
            setCollection(c)
          }}
        >
          {localize('add')}
        </button>
      </div>
      <div
        className="p-2 mt-3"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('associateCollection')}
        </div>
        <SimpleTable
          data={associates}
          columns={columns}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-capitalize text-danger"
                onClick={() => {
                  let col = [...collection]
                  let i = col.findIndex(c => c.id === r.id)
                  col.splice(i, 1)
                  setCollection(col)
                }}
              >
                <i className="uil uil-times-circle"></i>
                <span className="text-capitalize">{localize('remove')}</span>
              </div>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default Associates
