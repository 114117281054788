/** @format */

import React, { useState } from 'react'
import Swal from 'sweetalert2'
import ListingPage from '../../../../../Components/ListingPage/Index'
import { loadMedia } from '../../../../../Helpers/Modules/media'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import localize from './../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
  getTodaysDate,
} from './../../../../../Helpers/formatters'
import { postData, putData } from './../../../../../Helpers/webApi'
import CreateDisciplinaryCase from './Partials/CreateDisciplinaryCase'
import ViewDisciplinaryCase from './Partials/ViewDisciplinaryCase'
import Loader from '../../../../../Components/Loaders/Loader'

const DisciplinaryCases = () => {
  const [disciplinaryCase, setDisciplinaryCase] = useState({
    incidentDate: getTodaysDate(),
  })
  const [refresh, setRefresh] = useState(false)
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyDownloading, setIsBusyDownloading] = useState(false)

  const handleExportDocument = async r => {
    setIsBusyDownloading(true)
    const media = await loadMedia(r?.id)

    if (media) {
      const linkSource = `data:${media?.contentType};base64,${media?.content}`
      const downloadLink = document.createElement('a')
      const fileName = media?.fileName
      downloadLink.href = linkSource
      downloadLink.download = fileName
      document.body.appendChild(downloadLink) // Required for this to work in some browsers
      downloadLink.click()
      downloadLink.remove()
    } else Swal.fire('Operation failed', 'Cant load file', 'error')

    setIsBusyDownloading(false)
  }

  async function handleSubmit() {
    setIsBusy(true)
    const url = '/VehiclePersonnelDisciplinaryCase/create'

    const { success } = await postData(url, disciplinaryCase, false)
    if (success) {
      showNotification(
        'Create Disciplinary Case',
        'success',
        'Operation completed successfully!'
      )

      setMode(null)
      setDisciplinaryCase(null)
    }
    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)

    const { success } = await putData(
      '/VehiclePersonnelDisciplinaryCase/update',
      disciplinaryCase,
      false
    )
    if (success) {
      showNotification('Update Disciplinary Case', 'success')
      setMode(null)
      setDisciplinaryCase(null)
    }
    setIsBusy(false)
  }

  function handleClose() {
    setMode(null)
    setDisciplinaryCase({})
  }

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleSelectCase = (r, mode) => {
    if (mode === 'viewDocument') {
      handleExportDocument(r)
      return
    }

    setDisciplinaryCase(r)
    setMode(mode)
  }

  const columns = [
    {
      label: localize('type'),
      template: r => <>{r.vehiclePersonnelTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.vehiclePersonnelCustomerFullName}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r.vehiclePersonnelCustomerIdentificationNumber}</>,
    },
    {
      label: localize('customerType'),
      template: r => (
        <>{r.vehiclePersonnelCustomerMembershipClassCustomerTypeDescription}</>
      ),
    },
    {
      label: localize('vehicleRegistrationNumber'),
      template: r => <>{r.vehiclePersonnelVehicleRegistrationNumber}</>,
    },
    {
      label: localize('badgeImage'),
      template: r => <>{r.vehiclePersonnelBadgeNumber}</>,
    },
    {
      label: localize('badgeExpiryDate'),
      template: r => <>{formatDate(r.vehiclePersonnelBadgeExpiryDate, true)}</>,
    },
    {
      label: localize('incidentDate'),
      template: r => <>{formatDate(r.incidentDate, true)}</>,
    },
    {
      label: localize('court'),
      template: r => <>{r.court}</>,
    },
    {
      label: localize('courtDate'),
      template: r => <>{formatDate(r.courtDate, true)}</>,
    },
    {
      label: localize('cashBail'),
      template: r => <>{formatCurrency(r.cashBail)}</>,
    },
    {
      label: localize('sentForRefunds'),
      template: r => <>{formatCurrency(r.sentForRefunds)}</>,
    },
    {
      label: localize('refunded'),
      template: r => <>{formatCurrency(r.refunded)}</>,
    },
    {
      label: localize('fileTitle'),
      template: r => <>{r.fileName}</>,
    },
    {
      label: localize('fileMIMEType'),
      template: r => <>{r.fileMIMEType}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{r.modifiedDate}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, true)}</>,
    },
  ]

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <CreateDisciplinaryCase
          handleClose={handleClose}
          isBusy={isBusy}
          mode={mode}
          disciplinaryCase={disciplinaryCase}
          setDisciplinaryCase={setDisciplinaryCase}
          fetchDisciplinaryCases={() => setRefresh(!refresh)}
          handleAction={mode === 'add' ? handleSubmit : handleEdit}
        />
      )}
      {mode === 'view' && (
        <ViewDisciplinaryCase
          handleClose={handleClose}
          mode={mode}
          disciplinaryCase={disciplinaryCase}
          setDisciplinaryCase={setDisciplinaryCase}
          fetchDisciplinaryCases={() => setRefresh(!refresh)}
        />
      )}

      <>
        <>
          {isBusyDownloading ? (
            <div className="card bg-white rounded">
              <div className="card-body">
                <Loader />
              </div>
            </div>
          ) : (
            <ListingPage
              useText
              url="/VehiclePersonnelDisciplinaryCase/find-by-filter-in-page"
              map={[localize('fleet'), localize('disciplinaryCases')]}
              columns={columns}
              onAdd={handleClickAddButton}
              params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
              refreshAt={refresh}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectCase(r, 'viewDocument')
                    }}
                  >
                    <i className="uil uil-books"></i>
                    <span>{localize('viewDocument')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectCase(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectCase(r, 'edit')
                    }}
                  >
                    <i className="uil uil-edit-alt"></i>
                    <span>{localize('edit')}</span>
                  </div>
                </>
              )}
            />
          )}
        </>
      </>
    </>
  )
}

export default DisciplinaryCases
