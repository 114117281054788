/** @format */

import { CreditTypesApplicableCharges } from '../CreditTypesApplicableCharges'
import { CreditTypesApplicableDirectDebits } from '../CreditTypesApplicableDirectDebits'
import { CreditTypesOffsetableLoanProducts } from '../CreditTypesOffsetableLoanProducts'
import { CreditTypesConcessionExemptLoanProducts } from '../CreditTypesConcessionExemptLoanProducts'
import { CreditTypesOffsetableInvestmentProducts } from '../CreditTypesOffsetableInvestmentProducts'
import { CreditTypesConcessionExemptInvestmentProducts } from '../CreditTypesConcessionExemptInvestmentProducts'
import { CreditTypesOffsetableSavingsProduct } from '../CreditTypesOffsetableSavingsProduct'
import { CreditTypesConcessionExemptSavingsProduct } from '../CreditTypesConcessionExemptSavingsProduct'
import { CreditTypesBenefactorInvestmentsProducts } from '../CreditTypesBenefactorInvestmentsProducts'
import { CreditTypesFlags } from '../CreditTypesFlags'
/* eslint-disable */
import React from 'react'
import Tab from '../../../../../Components/Tabs/Tab'
import { ACTIONS } from '../../Reducers/reducer'

export const CreditTypesTabContainers = ({
  onNavigateCreditTypeTabContainer,
  creditTypeState,
  mode,
  creditTypeDispatcher,
}) => {
  const tabsInViewMode = [
    {
      label: 'Applicable Charges',
      number: 0,
      item: (
        <CreditTypesApplicableCharges
          disabled
          key={0}
          creditTypeCommissions={creditTypeState.creditTypeCommissions}
          loading={creditTypeState.loading}
        />
      ),
    },
    {
      label: 'Applicable Direct Debits',
      number: 1,
      item: (
        <CreditTypesApplicableDirectDebits
          disabled
          key={1}
          loading={creditTypeState.loading}
          creditTypeDirectDebits={creditTypeState.creditTypeDirectDebits}
        />
      ),
    },
    {
      label: 'Offsetable Loan Products',
      number: 2,
      item: (
        <CreditTypesOffsetableLoanProducts
          disabled
          key={2}
          loading={creditTypeState.loading}
          creditTypeOffsetableLoanProducts={
            creditTypeState.creditTypeLoanProducts
          }
        />
      ),
    },
    {
      label: 'Concession-Exempt Loan Products',
      number: 3,
      item: (
        <CreditTypesConcessionExemptLoanProducts
          disabled
          key={3}
          loading={creditTypeState.loading}
          creditTypesConcessionExemptLoanProducts={
            creditTypeState.creditTypeConcessionExemptionLoanProducts
          }
        />
      ),
    },
    {
      label: 'Offsetable InvestmentProducts',
      number: 4,
      item: (
        <CreditTypesOffsetableInvestmentProducts
          disabled
          key={4}
          loading={creditTypeState.loading}
          creditTypesInvestmentProducts={
            creditTypeState.creditTypeInvestmentProducts
          }
        />
      ),
    },
    {
      label: 'Concession-Exempt Investment Products',
      number: 5,
      item: (
        <CreditTypesConcessionExemptInvestmentProducts
          disabled
          key={5}
          creditTypeInvestmentProducts={
            creditTypeState.creditTypeConcessionExemptionInvestmentProducts
          }
          loading={
            creditTypeState.loadingCreditTypeConcessionExemptionInvestmentProducts
          }
        />
      ),
    },
    {
      label: 'Offsetable Savings Products',
      number: 6,
      item: (
        <CreditTypesOffsetableSavingsProduct
          disabled
          key={6}
          loading={creditTypeState.loading}
          creditTypeSavingsProducts={creditTypeState.creditTypeSavingsProducts}
        />
      ),
    },
    {
      label: 'Concession-Exempt Savings Products',
      number: 7,
      item: (
        <CreditTypesConcessionExemptSavingsProduct
          disabled
          key={7}
          loading={creditTypeState.loading}
          creditTypeConcessionExemptSavingsProduct={
            creditTypeState.creditTypeConcessionExemptionSavingsProducts
          }
        />
      ),
    },
    {
      label: 'Benefactor Investment Products',
      number: 8,
      item: (
        <CreditTypesBenefactorInvestmentsProducts
          disabled
          key={8}
          creditTypesBenefactorInvestmentsProducts={
            creditTypeState.creditTypeBenefactorInvestmentProducts
          }
          loading={creditTypeState.loading}
        />
      ),
    },
    {
      label: 'Flags',
      number: 9,
      item: (
        <CreditTypesFlags
          prefix={'list'}
          key={9}
          creditTypeFlags={creditTypeState.creditTypeFlags}
        />
      ),
    },
  ]

  const tabsInCreateEditMode = [
    {
      label: 'Applicable Charges',
      number: 0,
      item: (
        <CreditTypesApplicableCharges
          prefix={
            mode === 'add'
              ? 'create-applicable-charges'
              : 'edit-applicable-charges'
          }
          onCheckCreditTypeApplicableCharges={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_COMMISSIONS,
              payload: c,
            })
          }}
          loading={creditTypeState.loading}
          key={10}
          creditTypeCommissions={creditTypeState.creditTypeCommissions}
        />
      ),
    },
    {
      label: 'Applicable Direct Debits',
      number: 1,
      item: (
        <CreditTypesApplicableDirectDebits
          prefix={
            mode === 'add'
              ? 'create-applicable-direct-debit'
              : 'edit-applicable-direct-debit'
          }
          onCheckCreditTypeApplicableDirectDebits={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_DIRECT_DEBITS,
              payload: c,
            })
          }}
          loading={creditTypeState.loading}
          key={11}
          creditTypeDirectDebits={creditTypeState.creditTypeDirectDebits}
        />
      ),
    },
    {
      label: 'Offsetable Loan Products',
      number: 2,
      item: (
        <CreditTypesOffsetableLoanProducts
          prefix={
            mode === 'add'
              ? 'create-offset-applicable-charges'
              : 'edit-offset-applicable-charges'
          }
          loading={creditTypeState.loading}
          //@TODO do toggle selection
          // onToggleSelection={selection => {
          //   handleToggleSelection(selection)
          // }}
          onCheckCreditTypeOffsetableLoanProducts={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_LOAN_PRODUCTS,
              payload: c,
            })
          }}
          mode={mode}
          key={12}
          creditTypeOffsetableLoanProducts={
            creditTypeState.creditTypeLoanProducts
          }
        />
      ),
    },
    {
      label: 'Concession-Exempt Loan Products',
      number: 3,
      item: (
        <CreditTypesConcessionExemptLoanProducts
          prefix={
            mode === 'add'
              ? 'create-exempt-applicable-charges'
              : 'edit-exempt-applicable-charges'
          }
          loading={creditTypeState.loading}
          onCheckCreditTypesConcessionExemptLoanProducts={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_LOAN_PRODUCTS,
              payload: c,
            })
          }}
          key={13}
          creditTypesConcessionExemptLoanProducts={
            creditTypeState.creditTypeConcessionExemptionLoanProducts
          }
        />
      ),
    },
    {
      label: 'Offsetable InvestmentProducts',
      number: 4,
      item: (
        <CreditTypesOffsetableInvestmentProducts
          prefix={
            mode === 'add'
              ? 'create-offset-investment-products'
              : 'edit-offset-investment-products'
          }
          onCheckCreditTypesInvestmentProducts={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_INVESTMENT_PRODUCTS,
              payload: c,
            })
          }}
          loading={creditTypeState.loading}
          key={14}
          creditTypesInvestmentProducts={
            creditTypeState.creditTypeInvestmentProducts
          }
        />
      ),
    },
    {
      label: 'Concession-Exempt Investment Products',
      number: 5,
      item: (
        <CreditTypesConcessionExemptInvestmentProducts
          prefix={
            mode === 'add'
              ? 'create-exempt-investment-products'
              : 'edit-exempt-investment-products'
          }
          onCheckCreditTypeConcessionExemptionInvestmentProduct={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_INVESTMENT_PRODUCTS,
              payload: c,
            })
          }}
          loading={creditTypeState.loading}
          key={15}
          creditTypeInvestmentProducts={
            creditTypeState.creditTypeConcessionExemptionInvestmentProducts
          }
        />
      ),
    },
    {
      label: 'Offsetable Savings Products',
      number: 6,
      item: (
        <CreditTypesOffsetableSavingsProduct
          prefix={
            mode === 'add'
              ? 'create-offset-savings-products'
              : 'edit-offset-savings-products'
          }
          onCheckCreditTypesSavingsProduct={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_SAVINGS_PRODUCTS,
              payload: c,
            })
          }}
          loading={creditTypeState.loading}
          key={16}
          creditTypeSavingsProducts={creditTypeState.creditTypeSavingsProducts}
        />
      ),
    },
    {
      label: 'Concession-Exempt Savings Products',
      number: 7,
      item: (
        <CreditTypesConcessionExemptSavingsProduct
          prefix={
            mode === 'add'
              ? 'create-exemption-savings-products'
              : 'edit-exemption-savings-products'
          }
          onCheckCreditTypeConcessionExemptSavingsProduct={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_SAVINGS_PRODUCTS,
              payload: c,
            })
          }}
          loading={creditTypeState.loading}
          key={17}
          creditTypeConcessionExemptSavingsProduct={
            creditTypeState.creditTypeConcessionExemptionSavingsProducts
          }
        />
      ),
    },
    {
      label: 'Benefactor Investment Products',
      number: 8,
      item: (
        <CreditTypesBenefactorInvestmentsProducts
          prefix={
            mode === 'add'
              ? 'create-benefactor-investment-products'
              : 'edit-benefactor-investment-products'
          }
          onCheckCreditTypesBenefactorInvestmentsProducts={c => {
            creditTypeDispatcher({
              type: ACTIONS.GET_CREDIT_TYPE_BENEFACTOR_INVESTMENT_PRODUCTS,
              payload: c,
            })
          }}
          loading={creditTypeState.loading}
          key={18}
          creditTypesBenefactorInvestmentsProducts={
            creditTypeState.creditTypeBenefactorInvestmentProducts
          }
        />
      ),
    },
    {
      label: 'Flags',
      number: 9,
      item: (
        <CreditTypesFlags
          prefix={'edit'}
          key={19}
          mode={mode}
          creditTypeDispatcher={creditTypeDispatcher}
          creditTypeFlags={creditTypeState.creditTypeFlags}
        />
      ),
    },
  ]
  return (
    <Tab
      tabItems={
        mode === 'edit' || 'add' ? tabsInCreateEditMode : tabsInViewMode
      }
      onNavigate={navTab => {
        onNavigateCreditTypeTabContainer(navTab.number)
      }}
    />
  )
}
