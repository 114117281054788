/** @format */

import localize from '../../../../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import {
  BatchEntryStatus,
  CreditBatchEntryFilter,
} from '../../../../../../../Global/enumeration'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../../Components/Tables/Pager'
import { getData, putData } from '../../../../../../../Helpers/webApi'
import { formatCurrency } from '../../../../../../../Helpers/formatters'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'

export const BatchEntries = ({
  selectedBatchEntry,
  refreshBatchEntries,
  onSelectBatchEntry,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedBatchEntries, setSelectedBatchEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    creditBatchId: selectedBatchEntry?.id,
    status: Object.keys(BatchEntryStatus)[0],
    filterText: '',
    creditBatchEntryFilter: Object.keys(CreditBatchEntryFilter)[19],
    pageIndex: 0,
    pageSize: 10,
  })
  const [batchEntries, setBatchEntries] = useState([])

  const fetchBatchEntries = async id => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batch-entries-by-credit-batch-id-in-page',
      { ...reqParams, creditBatchId: id },
      false
    )
    if (success) {
      setBatchEntries(data)
    }
    setIsBusy(false)
  }

  const handleOnRemoveSelectedBatchEntries = async () => {
    if (selectedBatchEntries.length === 0) {
      await Swal.fire({
        title: 'Warning',
        text: 'Please Select At Least One Journal Voucher Entry To Remove',
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
      return
    }
    setIsBusy(true)
    const { success, data } = await putData(
      '/CreditBatch/remove-credit-batch-entries',
      {
        CreditBatchEntries: selectedBatchEntries,
      },
      false,
      'Remove Batch Entries'
    )
    if (success) {
      fetchBatchEntries(selectedBatchEntry?.id)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (selectedBatchEntry) {
      fetchBatchEntries(selectedBatchEntry?.id)
    }
  }, [
    selectedBatchEntry?.id,
    refreshBatchEntries,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedBatchEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedBatchEntries([...selectedBatchEntries, r])
              onSelectBatchEntry([...selectedBatchEntries, r])
            } else {
              setSelectedBatchEntries(
                selectedBatchEntries.filter(s => s.id !== r.id)
              )
              onSelectBatchEntry(
                selectedBatchEntries.filter(s => s.id !== r.id)
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    { label: localize('beneficiary'), template: r => <>{r.beneficiary}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    { label: localize('balance'), template: r => <>{r.balance}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },

    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
              <div className="d-flex align-items-center me-5">
                <label className="text-capitalize col-3 me-2">
                  {localize('status')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="BatchEntryStatus"
                  value={reqParams?.status}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center me-5">
                <label className="text-capitalize me-3">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="CreditBatchEntryFilter"
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      creditBatchEntryFilter: e.value,
                    })
                  }}
                  sort
                />
              </div>
              <div className="d-flex align-items-center me-5">
                <input
                  className="form-control"
                  value={reqParams?.filterText}
                  onChange={e => {
                    setReqParams({ ...reqParams, filterText: e.target.value })
                  }}
                />
              </div>
            </div>
          </div>

          <SimpleTable columns={columns} data={batchEntries.pageCollection} />
          <div
            className="bg-light d-flex justify-content-end align-items-center p-2 mt-3 mb-3"
            style={{ borderRadius: 5 }}
          >
            <button
              onClick={handleOnRemoveSelectedBatchEntries}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('remove')}
            </button>
          </div>

          <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('apportioned')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  batchEntries.totalApportioned,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('shortage')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(batchEntries.totalShortage, 2, 2, false)}
              />
            </div>
            <div className="d-flex align-items-center me-5">
              <label htmlFor="" className="text-capitalize ms-2 me-2">
                {localize('entries')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={batchEntries.statusEntries}
              />
            </div>
          </div>
          <Pager
            itemsCount={batchEntries?.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export const BatchEntriesViewMode = ({
  selectedBatchEntry,
  refreshBatchEntries,
  onSelectBatchEntry,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    creditBatchId: selectedBatchEntry?.id,
    status: Object.keys(BatchEntryStatus)[0],
    filterText: '',
    creditBatchEntryFilter: Object.keys(CreditBatchEntryFilter)[19],
    pageIndex: 0,
    pageSize: 10,
  })
  const [batchEntries, setBatchEntries] = useState([])

  const fetchBatchEntries = async id => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batch-entries-by-credit-batch-id-in-page',
      { ...reqParams, creditBatchId: id },
      false
    )
    if (success) {
      setBatchEntries(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (selectedBatchEntry) {
      fetchBatchEntries(selectedBatchEntry?.id)
    }
  }, [
    selectedBatchEntry.id,
    refreshBatchEntries,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r.interest)}</>,
    },
    { label: localize('beneficiary'), template: r => <>{r.beneficiary}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.productDescription}</>,
    },
    { label: localize('balance'), template: r => <>{r.balance}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },

    {
      label: localize('accountNumber'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r.costCenterDescription}</>,
    },
    {
      label: localize('exceptionMessage'),
      template: r => <>{r.exceptionMessage}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
              <div className="d-flex align-item-center me-5">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="BatchEntryStatus"
                  value={reqParams?.status}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                    })
                  }
                />
              </div>
              <div className="d-flex align-item-center me-5">
                <label className="text-capitalize me-3">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="CreditBatchEntryFilter"
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      creditBatchEntryFilter: e.value,
                    })
                  }}
                  sort
                />
              </div>
              <div className="d-flex align-items-center me-5">
                <div className="input-group">
                  <input
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e => {
                      setReqParams({ ...reqParams, filterText: e.target.value })
                    }}
                  />
                  <button
                    id="FilterButton"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      fetchBatchEntries(selectedBatchEntry?.id)
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
            </div>
            <SimpleTable columns={columns} data={batchEntries.pageCollection} />
            <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
              <div className="d-flex align-items-center me-5">
                <label htmlFor="" className="text-capitalize ms-2 me-2">
                  {localize('apportioned')}
                </label>
                <input
                  className="form-control text-end"
                  disabled
                  value={formatCurrency(
                    batchEntries.totalApportioned,
                    2,
                    2,
                    false
                  )}
                />
              </div>
              <div className="d-flex align-items-center me-5">
                <label htmlFor="" className="text-capitalize ms-2 me-2">
                  {localize('shortage')}
                </label>
                <input
                  className="form-control text-end"
                  disabled
                  value={formatCurrency(
                    batchEntries.totalShortage,
                    2,
                    2,
                    false
                  )}
                />
              </div>
              <div className="d-flex align-items-center me-5">
                <label htmlFor="" className="text-capitalize ms-2 me-2">
                  {localize('entries')}
                </label>
                <input
                  className="form-control"
                  disabled
                  value={batchEntries.statusEntries}
                />
              </div>
            </div>
            <Pager
              itemsCount={batchEntries?.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </>
        </>
      )}
    </>
  )
}
