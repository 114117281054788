/** @format */

import React, { useState, useEffect } from 'react'
import { formatDate } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import Tab from '../../../../../Components/Tabs/Tab'
import SalaryGroupEntries from './Partials/SalaryGroupEntries'
import EditSalaryGroupModal from './Partials/EditSalaryGroupModal'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

const Groups = () => {
  const columns = [
    {
      label: localize('description'),
      template: r => <>{r.description}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [salaryGroupEntries, setSalaryGroupEntries] = useState([])
  const [selectedSalaryGroup, setSelectedSalaryGroup] = useState(null)
  const [mode, setMode] = useState(null)
  const [entriesLoader, setEntriesLoader] = useState(false)

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/SalaryGroup/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadTableData()
  }, [reqParams])

  const loadSalaryGroupEntries = async r => {
    setEntriesLoader(true)
    const { success, data } = await getData(
      `/SalaryGroup/find-salary-group-entries-by-group-id?salaryGroupId=${r.id}`,
      {},
      false
    )
    if (success) {
      setSalaryGroupEntries(data.result)
      setEntriesLoader(false)
    }
    setEntriesLoader(false)
  }

  const handleAddAction = () => {
    setMode('add')
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('humanResourceManagement'), localize('salaryGroups')]}
      />

      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleAddAction} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-6"></div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label htmlFor="searchTable" className="text-capitalize">
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                      <button
                        id="salaryGroupsFilterButton"
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      canClickOnce
                      onClickOnce={async r => {
                        await loadSalaryGroupEntries(r)
                      }}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setSelectedSalaryGroup(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    {entriesLoader ? (
                      <Loader />
                    ) : (
                      <Tab
                        tabItems={[
                          {
                            label: localize('salaryGroupEntries'),
                            item: (
                              <SalaryGroupEntries
                                showLoader={null}
                                entries={salaryGroupEntries}
                              />
                            ),
                          },
                        ]}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <EditSalaryGroupModal
          mode={mode}
          selectedSalaryGroup={selectedSalaryGroup}
          editMode={mode === 'edit'}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      )}
    </>
  )
}

export default Groups
