/** @format */

import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

const LocationLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('locationLookup')}
      url="/lookup/Location/find-location-by-filter-in-page"
      params={{
        filterText: '',
        pageIndex: 0,
        pageSize: 10,
      }}
      columns={[
        {
          label: localize('code'),
          template: r => <>{r.paddedCode}</>,
        },
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
        },
        {
          label: localize('branch'),
          template: r => <>{r.branchDescription}</>,
        },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">{r.isLocked?.toString()}</span>
          ),
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default LocationLookup
