/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import CreateHierarchy from './CreateHierarchy/CreateHierarchy'
import { postData, putData } from '../../../../../Helpers/webApi'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Hierarchies() {
  const [isBusy, setIsBusy] = useState(false)
  const [hierarchies, setHierarchies] = useState([])
  const [hierarchy, setHierarchy] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    traverseTree: true,
    updateDepth: true,
    pageIndex: 0,
    pageSize: 10,
  })

  useEffect(() => {
    loadHierarchies()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refresh])

  async function loadHierarchies() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Budget/find-budget-hierarchies-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setHierarchies(data)
    }

    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('name'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedDescription }} />
      ),
      sortBy: 'indentedDescription',
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('gLAccountName'),
      template: r => <>{r?.chartOfAccountAccountName}</>,
    },
    {
      label: localize('gLAccountDistributionRule'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    { label: localize('depth'), template: r => <>{r?.depth}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const handleCreateOrUpdate = async () => {
    setIsBusy(true)
    const url =
      modalMode === 'add'
        ? '/Budget/create-budget-hierachy'
        : '/Budget/update-budget-hierachy'

    if (modalMode === 'add') {
      setIsBusy(true)
      const { success } = await postData(
        url,
        hierarchy,
        false,
        'Hierarchy Created Successfully.'
      )

      if (success) {
        setIsBusy(false)
        handleClose()
        setRefresh(true)
      }

      setIsBusy(false)
    } else if (modalMode === 'edit') {
      setIsBusy(true)
      const { success } = await putData(
        url,
        hierarchy,
        false,
        'Hierarchy Updated Successfully'
      )
      if (success) {
        setIsBusy(false)
        handleClose()
        setRefresh(true)
      }
      setIsBusy(false)
    }
  }

  const handleClose = () => {
    setRefresh(true)

    setModalMode(null)
  }

  const handleViewAuditLogs = item => {
    setHierarchy(item)
    setModalMode('auditLogs')
  }

  const handleCloseModal = () => {
    setModalMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('budgetManagement'),
          localize('hierarchies'),
        ]}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateHierarchy
          handleAction={handleCreateOrUpdate}
          handleClose={handleClose}
          mode={modalMode}
          hierarchy={hierarchy}
          setHierarchy={setHierarchy}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={modalMode}
          data={hierarchy}
          title={hierarchy?.description}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-start mb-4">
                  <AddNewButton
                    handleClickAddButton={() => setModalMode('add')}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <RecordsPerPage
                  className="col"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />

                <div className="col d-flex align-items-center">
                  <label
                    htmlFor="traverseTree"
                    className="col-3 text-capitalize"
                  >
                    {localize('traverseTree') + '?'}
                  </label>
                  <Checkbox
                    id="traverseTree"
                    checked={reqParams.traverseTree}
                    onCheck={value => {
                      setReqParams({
                        ...reqParams,
                        traverseTree: value,
                        updateDepth: value,
                      })
                    }}
                  />
                </div>
                <div className="col  d-flex align-items-center justify-content-end gap-2">
                  <div className="d-flex align-items-center ">
                    <label className=" text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                          pageIndex: 0,
                        })
                      }}
                    >
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                    <button
                      id="FilterButton"
                      type="button"
                      className="btn btn-primary ms-1"
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <SimpleTable
                  columns={columns}
                  data={hierarchies?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setModalMode('edit')
                          setHierarchy(r)
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(r)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
              </div>
              <Pager
                itemsCount={hierarchies?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Hierarchies
