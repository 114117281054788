/** @format */

import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import React, { useEffect, useState } from 'react'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { WORK_FLOW_TYPE } from '../../../../Helpers/constants'
import { getData, postData } from '../../../../Helpers/webApi'
import Tab from '../../../../Components/Tabs/Tab'
import { Roles } from './Partials/Roles'
import '../../../../Components/DragnDrop/basicDND.scss'
import { Settings } from './Partials/Settings'
import { Reorder } from 'framer-motion'
import swal from 'sweetalert2'

function Default() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedWorkFlow, setSelectedWorkFlow] = useState({
    workflowType: WORK_FLOW_TYPE.LEAVE_APPLICATION_ORIGINATION,
  })
  const [workflowSettings, setWorkflowSettings] = useState([])
  const [approversList, setApproversList] = useState([])

  const handleOnChangeSelectedWorkFlow = async e => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/WorkFlow/retrieve-workflow-entries',
      {
        workFlowType: e.value,
      },
      false
    )
    if (success) {
      setWorkflowSettings(data)
      setApproversList(
        data?.requiredApproversList.map((i, index) => ({
          label: i,
          id: `required-approvers-list  ${index}`,
        }))
      )
    }
    setIsBusy(false)
  }

  const onChangeRequiredApprovers = (role, newRequiredApprovers) => {
    let workflowBeingUpdated = [
      ...workflowSettings?.workflowSettingEntries,
    ].find(x => x.id === role.id)

    if (workflowBeingUpdated) {
      workflowBeingUpdated.requiredApprovers = newRequiredApprovers
    }

    const index = workflowSettings?.workflowSettingEntries?.findIndex(
      x => x.id === role.id
    )

    let newWorkflowSettingsEntries = [
      ...workflowSettings.workflowSettingEntries,
    ]

    if (index !== -1) {
      newWorkflowSettingsEntries[index] = workflowBeingUpdated
    }

    setWorkflowSettings({
      ...workflowSettings,
      workflowSettingEntries: newWorkflowSettingsEntries,
    })
  }

  useEffect(() => {
    return () => {
      handleOnChangeSelectedWorkFlow({
        value: WORK_FLOW_TYPE.LEAVE_APPLICATION_ORIGINATION,
      })
    }
  }, [])

  const updateWorkFlowSettings = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/WorkFlow/update-workflow-settings',
      {
        WorkflowSetting: workflowSettings?.workflowSetting,
        WorkflowType: selectedWorkFlow?.workflowType,
        WorkflowSettingEntries: workflowSettings?.workflowSettingEntries.filter(
          x => x.checked
        ),
        ApprovedPriorities: approversList.map(obj => obj.label),
      },
      false
    )
    if (success) {
      swal.fire(
        'Edit Workflow Settings',
        'Operation Completed Successfully',
        'success'
      )
    }
    setIsBusy(false)
  }

  const handleOnCheckRole = (updatedRoleList, role, checked) => {
    let updateRequiredApproversList = [
      ...workflowSettings.requiredApproversList,
    ]

    if (checked) {
      if (!updateRequiredApproversList.includes(role.roleName)) {
        updateRequiredApproversList.push(role.roleName)
      }
    } else {
      updateRequiredApproversList = updateRequiredApproversList.filter(
        roleName => roleName !== role.roleName
      )
    }

    setWorkflowSettings({
      ...workflowSettings,
      workflowSettingEntries: updatedRoleList,
      requiredApproversList: updateRequiredApproversList,
    })
    setApproversList(
      updateRequiredApproversList?.map((i, index) => ({
        label: i,
        id: `required-approvers-list  ${index}`,
      }))
    )
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('systemAdministration'),
          localize('default'),
          localize('workflow'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-1 text-capitalize">
                        {localize('type')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="WorkFlowType"
                        value={selectedWorkFlow?.workflowType}
                        onChange={e => {
                          setSelectedWorkFlow({
                            ...selectedWorkFlow,
                            workflowType: e.value,
                          })
                          handleOnChangeSelectedWorkFlow(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <Tab
                        preload
                        tabItems={[
                          {
                            label: localize('roles'),
                            item: (
                              <Roles
                                isBusy={isBusy}
                                prefix={'rls'}
                                handleOnChangeRequiredApprovers={
                                  onChangeRequiredApprovers
                                }
                                onCheckRole={(
                                  updateRoleList,
                                  role,
                                  checkValue
                                ) => {
                                  handleOnCheckRole(
                                    updateRoleList,
                                    role,
                                    checkValue
                                  )
                                }}
                                rolesToBeSelected={
                                  workflowSettings?.workflowSettingEntries || []
                                }
                              />
                            ),
                          },
                          {
                            label: localize('priority'),
                            item: (
                              <div
                                style={{
                                  width: '100%',
                                  pointerEvents: 'auto',
                                  opacity: 1,
                                }}
                              >
                                <Reorder.Group
                                  values={approversList || []}
                                  className={`form-control basicdnd-container ${'y'}-axis`}
                                  axis={'y'}
                                  onReorder={setApproversList}
                                  layout
                                >
                                  {approversList.map((item, index) => (
                                    <Reorder.Item
                                      value={item}
                                      key={item.id}
                                      className="basicdnd-item"
                                    >
                                      {item.label}
                                    </Reorder.Item>
                                  ))}
                                </Reorder.Group>
                              </div>
                            ),
                          },
                          {
                            label: localize('settings'),
                            item: (
                              <Settings
                                setWorkflowSetting={setWorkflowSettings}
                                workflowSetting={workflowSettings}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-content-end justify-content-end">
                    <div className="d-flex align-items-center me-5">
                      <button
                        onClick={updateWorkFlowSettings}
                        className="btn btn-success px-5"
                      >
                        {localize('update')}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Default
