/** @format */

import localize from '../../../../../../../../Global/Localize'
import { ViewCustomerSpecimen } from '../../../../../../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import { MaskNumber } from '../../../../../../../../Components/InputMasks/MaskNumber'
import { GlobalValidationErrors } from '../../../../../../../../Validation/ValidationState'
import { useEffect } from 'react'

export const CustomerAccountTransactionCashWithdrawal = ({
  transactionModel,
  setTransactionModel,
  selectedTransactionType,
  handleOnBlur,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = key => {
    setValidationBag(g => {
      delete g[key]
      return g
    })
  }


  useEffect(() => {
    setValidationBag({})
  }, [selectedTransactionType])
  return (
    <div className="mt-3">
      <div className="row mb-2">
        <div validation-message={validationBag?.WithdrawalAmount?.message} className="with-validation col d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('amount')}</label>
          <MaskNumber
            defaultMaskValue={transactionModel?.cashWithdrawalAmount}
            onMaskNumber={e => {
              clearValidation('WithdrawalAmount')
              setTransactionModel({
                ...transactionModel,
                cashWithdrawalAmount: e.target.value,
              })
            }}
            onBlur={handleOnBlur}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div validation-message={validationBag?.Reference?.message} className="col d-flex align-items-center with-validation">
          <label className="col-1 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            defaultValue={transactionModel?.reference}
            onChange={e => {
              clearValidation('Reference')
              setTransactionModel({
                ...transactionModel,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3 mt-2">
        <div className="col-md-12">
          <ViewCustomerSpecimen readOnly />
        </div>
      </div>
    </div>
  )
}
