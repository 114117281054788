/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import localize from '../../Global/Localize'
import {
  SystemTransactionCode,
  loanCaseFilter,
  loanCaseStatus,
} from '../../Global/enumeration'
import { showNotification } from '../../Helpers/Notification/ShowNotification'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../Helpers/formatters'
import { getData, postData } from '../../Helpers/webApi'
import Checkbox from '../Checkbox/Checkbox'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import DatePicker from '../Date/DatePicker'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

const LoanCaseLookup = ({ batch, showUpdate, handleReloadData }) => {
  const auth = useAuth()
  const [threshold, setThreshold] = useState(null)
  const [selectedCases, setSelectedCases] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: Object.keys(loanCaseStatus)[6],
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    text: '',
    loanCaseFilter: Object.keys(loanCaseFilter)[0],
    includeBatchStatus: true,
    loanProductCategory: batch?.loanProductCategory,
    loanDisbursementModeId: batch?.loanDisbursementModeId,
    branchId: batch?.branchId,
  })

  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <>
          <Checkbox
            checked={selectedCases?.find(c => c?.id === r?.id)}
            onCheck={value => {
              if (value) {
                setSelectedCases([...selectedCases, r])
              } else {
                setSelectedCases(selectedCases.filter(c => c?.id !== r?.id))
              }
            }}
          />
        </>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('serialNumber'),
      template: r => r?.documentReferenceNumber,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.formattedCustomerAccountNumber,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label:
        localize('business') +
        '/' +
        localize('group') +
        '/' +
        localize('employer'),
      template: r => r?.customerEmployerDescription,
    },
    {
      label: localize('loanProduct'),
      template: r => r?.loanProductDescription,
    },
    {
      label: localize('amountApplied'),
      template: r => formatCurrency(r?.amountApplied),
    },
    {
      label: localize('deferralRemarks'),
      template: r => r?.deferralRemarks,
    },
    {
      label: localize('appraisalRemarks'),
      template: r => r?.appraisalRemarks,
    },
    {
      label: localize('approvalRemarks'),
      template: r => r?.approvalRemarks,
    },
    {
      label: localize('auditRemarks'),
      template: r => r?.auditRemarks,
    },
    {
      label: localize('cancellationRemarks'),
      template: r => r?.cancellationRemarks,
    },
    {
      label: localize('disbursementRemarks'),
      template: r => r?.disbursementRemarks,
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('appraisedAmount'),
      template: r => formatCurrency(r?.appraisedAmount),
    },
    {
      label: localize('productCategory'),
      template: r => r?.roanRegistrationLoanProductCategoryDescription,
    },
    {
      label: localize('receivedDate'),
      template: r => formatDate(r?.receivedDate),
    },
    {
      label: localize('isBatched') + ' ?',
      template: r => r?.isBatchedDescription,
    },
    {
      label: localize('systemAppraisalRemarks'),
      template: r => r?.systemAppraisalRemarks,
    },
    {
      label: localize('systemAppraisedAmount'),
      template: r => formatCurrency(r?.systemAppraisedAmount),
    },
    {
      label: localize('appraisedNetIncome'),
      template: r => formatCurrency(r?.appraisedNetIncome),
    },
    {
      label: localize('appraisedAmountRemarks'),
      template: r => r?.appraisedAmountRemarks,
    },
    {
      label: localize('monthlyPaybackAmount'),
      template: r => formatCurrency(r?.monthlyPaybackAmount),
    },
    {
      label: localize('totalPaybackAmount'),
      template: r => formatCurrency(r?.totalPaybackAmount),
    },
    {
      label: localize('appraisedBy'),
      template: r => r?.appraisedBy,
    },
    {
      label: localize('appraisedDate'),
      template: r => formatDate(r?.appraisedDate),
    },
    {
      label: localize('approvedAmount'),
      template: r => formatCurrency(r?.approvedAmount),
    },
    {
      label: localize('approvedAmountRemarks'),
      template: r => r?.approvedAmountRemarks,
    },
    {
      label: localize('approvedPrincipalPayment'),
      template: r => formatCurrency(r?.approvedPrincipalPayment),
    },
    {
      label: localize('approvedInterestPayment'),
      template: r => formatCurrency(r?.approvedInterestPayment),
    },
    {
      label: localize('approvedBy'),
      template: r => r?.approvedBy,
    },
    {
      label: localize('approvedDate'),
      template: r => formatDate(r?.approvedDate),
    },
    {
      label: localize('auditTopUpAmount'),
      template: r => formatCurrency(r?.auditTopUpAmount),
    },
    {
      label: localize('auditedBy'),
      template: r => r?.auditedBy,
    },
    {
      label: localize('auditedDate'),
      template: r => formatDate(r?.auditedDate),
    },
    {
      label: localize('cancelledBy'),
      template: r => r?.cancelledBy,
    },
    {
      label: localize('cancelledDate'),
      template: r => formatDate(r?.cancelledDate),
    },
    {
      label: localize('deferredBy'),
      template: r => r?.deferredBy,
    },
    {
      label: localize('deferredDate'),
      template: r => formatDate(r?.deferredDate),
    },
    {
      label: localize('batchedBy'),
      template: r => r?.batchedBy,
    },
    {
      label: localize('disbursedAmount'),
      template: r => formatCurrency(r?.disbursedAmount),
    },
    {
      label: localize('disbursedBy'),
      template: r => r?.disbursedBy,
    },
    {
      label: localize('disbursedDate'),
      template: r => formatDate(r?.disbursedDate),
    },
    {
      label: localize('reversalRemarks'),
      template: r => r?.reversalRemarks,
    },
    {
      label: localize('reversedBy'),
      template: r => r?.reversedBy,
    },
    {
      label: localize('reversedDate'),
      template: r => formatDate(r?.reversedDate),
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('customerMembershipClassCustomerType'),
      template: r => r?.customerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.customerPersonalFileNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.customerReference1,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.customerReference2,
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanPurposeDescription,
    },
    {
      label: localize('loanRepaymentMode'),
      template: r => r?.loanRepaymentModeDescription,
    },
    {
      label: localize('loanDisbursementMode'),
      template: r => r?.loanDisbursementModeDescription,
    },
    {
      label: localize('savingsProduct'),
      template: r => r?.savingsProductDescription,
    },
    {
      label: localize('loanInterestAnnualPercentageRate'),
      template: r => formatAsPercent(r?.loanInterestAnnualPercentageRate),
    },
    {
      label: localize('loanInterestChargeMode'),
      template: r => r?.loanInterestChargeModeDescription,
    },
    {
      label: localize('loanInterestRecoveryMode'),
      template: r => r?.loanInterestRecoveryModeDescription,
    },
    {
      label: localize('loanInterestCalculationMode'),
      template: r => r?.loanInterestCalculationModeDescription,
    },
    {
      label: localize('loanRegistrationTermInMonths'),
      template: r => r?.loanRegistrationTermInMonths,
    },
    {
      label: localize('loanRegistrationGracePeriod'),
      template: r => r?.loanRegistrationGracePeriod,
    },
    {
      label: localize('loanRegistrationMinimumMembershipPeriod'),
      template: r => r?.loanRegistrationMinimumMembershipPeriod,
    },
    {
      label: localize('loanRegistrationPaymentFrequencyPerYear'),
      template: r => r?.loanRegistrationPaymentFrequencyPerYearDescription,
    },
    {
      label: localize('loanRegistrationStandingOrderTrigger'),
      template: r => r?.loanRegistrationStandingOrderTriggerDescription,
    },
    {
      label: localize('loanRegistrationGuarantorSecurityMode'),
      template: r => r?.loanRegistrationGuarantorSecurityModeDescription,
    },
    {
      label: localize('loanRegistrationIncomeAssessmentMode'),
      template: r => r?.loanRegistrationIncomeAssessmentModeDescription,
    },
    {
      label: localize('loanRegistrationBypassAudit'),
      template: r => r?.loanRegistrationBypassAudit,
    },
    {
      label: localize('takeHomeType'),
      template: r => r?.takeHomeTypeDescription,
    },
    {
      label: localize('takeHomePercentage'),
      template: r => formatAsPercent(r?.takeHomePercentage),
    },
    {
      label: localize('takeHomeFixedAmount'),
      template: r => formatCurrency(r?.takeHomeFixedAmount),
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('isLocked') + ' ?',
      template: r => r.isLocked?.toString(),
    },
    {
      label: localize('loanProductLoanBalance'),
      template: r => formatCurrency(r?.loanProductLoanBalance),
    },
    {
      label: localize('totalAttachedLoansBalance'),
      template: r => formatCurrency(r?.totalAttachedLoansBalance),
    },
    {
      label: localize('serialNumber'),
      template: r => r?.paddedSerialNumber,
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
  ]

  async function getThresholdCollection() {
    setIsBusy(true)
    const url =
      '/lookup/Designation/find-transaction-threshold-by-designation-id-threshold-type'
    const { success, data } = await getData(
      url,
      {
        designationId: auth?.user?.profile?.cbs_EmployeeDesignationId,
        transactionThresholdType: Object.keys(SystemTransactionCode)[21],
      },
      false
    )
    if (success) {
      setThreshold(data?.result)
    }

    setIsBusy(false)
  }

  async function loadData() {
    setIsBusy(true)
    const url =
      '/lookup/LoanCase/find-loan-cases-by-loan-product-category-and-status-and-filter-in-page-async'
    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        approvedAmountThreshold: threshold?.[0]?.threshold,
      },
      false
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleCancel = () => {
    setModalActive(false)
  }

  const handleUpdate = async () => {
    if (selectedCases.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select at least one loan case to update',
      })

    const url = `/LoanDisbursementBatch/update-loan-disbursement-batch-entries`

    setIsBusy(true)
    const { success } = await postData(
      url,
      {
        loanDisbursementBatchId: batch?.id,
        loanDisbursementBatchEntries: selectedCases.map(entry => ({
          ...entry,
          loanDisbursementBatchId: batch?.id,
          loanCaseId: entry?.id,
          LoanCaseDocumentReferenceNumber: entry?.documentReferenceNumber,
          LoanCaseApprovedAmount: entry?.approvedAmount,
          LoanCaseIsBatched: entry?.isBatched,
          LoanCaseBatchNumber: entry?.batchNumber,
          LoanCaseLoanRegistrationLoanProductCategory:
            entry?.loanRegistrationLoanProductCategory,
          Reference: entry?.reference,
        })),
      },
      false
    )

    if (success) {
      showNotification('Loan Disbursement Batch', 'success')

      setSelectAll(false)
      setSelectedCases([])
      handleCancel()
      if (handleReloadData) {
        handleReloadData(true)
      }
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (modalActive) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  useEffect(() => {
    getThresholdCollection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <button
        className="btn btn-primary"
        style={{ margin: 0, transform: 'scale(0.9)' }}
        onClick={() => setModalActive(true)}
      >
        <i className="uil uil-search"></i>
      </button>
      {modalActive && (
        <div
          style={{
            maxHeight: '60dvh',
            overflow: 'auto',
          }}
        >
          <Modal isOpen className="modal-xl">
            <ModalHeader className="bg-white text-capitalize">
              {localize(
                `${batch?.loanDisbursementModeDescription} - ${
                  batch?.loanProductCategoryDescription
                }  Loan Applications Lookup @ ${
                  batch?.branchDescription
                } :  Approved AMT <= ${formatCurrency(
                  threshold?.[0]?.threshold,
                  null,
                  null,
                  false
                )}`
              )}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white p-4">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row gx-3 mb-3 mt-2 d-flex align-items-center justify-content-between">
                    <div className="col-md-3">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="col-md-2">
                      <div className="d-flex align-items-center">
                        <label
                          htmlFor="status"
                          className="text-capitalize me-2"
                        >
                          {localize('status')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: 150 }}
                          enumsKey="LoanCaseStatus"
                          value={reqParams.status}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              status: e.value,
                              pageIndex: 0,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 d-flex align-items-center">
                      <label htmlFor="search" className="col-3 text-capitalize">
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        value={{
                          startDate: reqParams.startDate,
                          endDate: reqParams.endDate,
                        }}
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-4">
                      <div className="d-flex align-items-center justify-content-end">
                        <label
                          htmlFor="search"
                          className="text-capitalize me-2"
                        >
                          {localize('search')}
                        </label>

                        <div className="d-flex align-items-center">
                          <EnumsServerCombo
                            customContainerStyle={{
                              maxWidth: 150,
                              minWidth: 150,
                            }}
                            enumsKey="LoanCaseFilter"
                            value={reqParams.loanCaseFilter}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                loanCaseFilter: e.value,
                                pageIndex: 0,
                              })
                            }}
                          />

                          <input
                            type="search"
                            id="search"
                            className="form-control ms-2"
                            value={reqParams.text}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                text: e.target.value,
                              })
                            }}
                          />
                          <button
                            id="FilterButton"
                            type="button"
                            className="btn btn-primary ms-2"
                            onClick={e => {
                              loadData()
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                loadData()
                              }
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" py-1">
                    <SimpleTable
                      columns={columns}
                      data={tableData}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <Pager
                    itemsCount={data.itemsCount}
                    pageSize={reqParams.pageSize}
                    pageIndex={reqParams.pageIndex}
                    pageBatchSize={5}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />

                  {showUpdate && (
                    <div className="bg-light d-flex p-2 justify-content-between align-items-center gap-4">
                      <div className="d-flex align-items-center">
                        <Checkbox
                          id="selectAllLoanCases"
                          checked={selectAll}
                          onCheck={value => {
                            setSelectAll(value)

                            if (value) {
                              setSelectedCases(data.pageCollection)
                            } else {
                              setSelectedCases([])
                            }
                          }}
                        />
                        <label
                          htmlFor="selectAllLoanCases"
                          className="text-capitalize ms-2"
                        >
                          {localize('toggleSelection')}
                        </label>
                      </div>
                      <button
                        className="btn btn-success"
                        onClick={handleUpdate}
                      >
                        {localize('update')}
                      </button>
                    </div>
                  )}
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  )
}

export default LoanCaseLookup
