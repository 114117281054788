/** @format */

import { IndefiniteCharges } from '../../../../../../Components/Selectables/Plain/IndefiniteCharges'
/* eslint-disable */
import React from 'react'

export const IntraAccountTransferIndefiniteCharges = ({
  indefiniteCharges,
  setIndefiniteCharges,
}) => {
  return (
    <IndefiniteCharges
      indefiniteChargesToSelect={indefiniteCharges}
      onCheckIndefiniteCharge={c => {
        setIndefiniteCharges(c)
      }}
      prefix={'indefinite-charges'}
    />
  )
}
