/**
 * eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

/**
 * eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import './tab.scss'

function Tab({ tabItems, onNavigate, preload }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  return (
    <>
      <ul className="nav nav-pills">
        {tabItems.map(
          (t, i) =>
            t && (
              <li
                className="nav-item cursor-pointer"
                onClick={() => {
                  setSelectedTabIndex(i)
                  onNavigate && onNavigate(t)
                }}
                key={i}
              >
                {/* eslint-disable-next-line */}
                <a
                  className={`nav-link d-flex gap-2 ${
                    i === selectedTabIndex ? 'active' : ''
                  } cent-tab-pill`}
                >
                  <span className="d-block">
                    <i className={t?.icon ?? 'uil uil-table'}></i>
                  </span>

                  <span className="d-none d-sm-block text-capitalize">
                    {t.label}
                  </span>
                </a>
              </li>
            )
        )}
      </ul>
      {preload &&
        tabItems.map(
          (t, i) =>
            t && (
              <div
                className="mt-1 tab-content cent-tab-content rounded"
                key={i}
                style={{ display: i === selectedTabIndex ? 'block' : 'none' }}
              >
                {t.item}
              </div>
            )
        )}

      {!preload && (
        <div
          className="mt-1 tab-content cent-tab-content rounded"
          key={selectedTabIndex}
        >
          {tabItems[selectedTabIndex]?.item}
        </div>
      )}
    </>
  )
}

export default Tab
