/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import AdHocLoanInterestCapitalization from '../../../../../Components/ReUsables/AdHocLoanInterestCapitalization/AdHocLoanInterestCapitalization'
import { LoansGuaranteed } from '../../../../../Components/ReUsables/Customer/LoansGuaranteed'
import { ViewCustomerInvestmentAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerInvestmentAccounts'
import { ViewCustomerLoanAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import { ViewCustomerSavingsAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerSavingsAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { withdrawalNotificationCategory } from '../../../../../Global/enumeration'
import { formatDate } from '../../../../../Helpers/formatters'
import AdHocStandingOrderExecutionModal from '../AddCatalogueModal/AdHocStandingOrderExecutionModal/AdHocStandingOrderExecutionModal'

function ViewCatalogueModal({
  modalMode,
  handleAction,
  handleClose,
  customer,
  customerSavingsAccounts,
  customerLoanAccounts,
  customerInvestmentAccounts,
  customerLoansGuarantor,
  balance,
}) {
  const [showAdHocModal, setShowAdHocModal] = useState(false)
  const [loanAccount, setLoanAccount] = useState({})
  const [adHocStandingOrder, setAdHocStandingOrder] = useState({})
  const [showAdHocStandingOrderModal, setShowAdHocStandingOrderModal] =
    useState(false)

  const deceased = Object.keys(withdrawalNotificationCategory)[0]
  const voluntary = Object.keys(withdrawalNotificationCategory)[1]
  const retiree = Object.keys(withdrawalNotificationCategory)[2]

  const tabItems = [
    {
      label: localize('loanAccounts'),
      item: (
        <>
          <ViewCustomerLoanAccounts
            customerLoanAccounts={customerLoanAccounts}
            handleAdHocLoan={r => {
              setLoanAccount(r)
              setShowAdHocModal(true)
            }}
            hiddenColumnsIndexes={[0]}
            canShowStatement
          />
        </>
      ),
    },
    {
      label: localize('savingsAccounts'),
      item: (
        <>
          <ViewCustomerSavingsAccounts
            customerSavingsAccounts={customerSavingsAccounts}
            canShowStatement
          />
        </>
      ),
    },
    {
      label: localize('investmentAccounts'),
      item: (
        <>
          <ViewCustomerInvestmentAccounts
            customerInvestmentAccounts={customerInvestmentAccounts}
            handleExecuteStandingOrder={r => {
              setAdHocStandingOrder(r)
              setShowAdHocStandingOrderModal(true)
            }}
            customer={customer}
            canShowStatement
          />
        </>
      ),
    },
    {
      label: localize('loanGuaranteed'),
      item: (
        <>
          <LoansGuaranteed loanGuarantors={customerLoansGuarantor} />
        </>
      ),
    },
  ]

  return (
    <>
      {showAdHocModal && (
        <AdHocLoanInterestCapitalization
          handleClose={() => {
            setShowAdHocModal(false)
          }}
          loanAccount={loanAccount}
        />
      )}
      {showAdHocStandingOrderModal && (
        <AdHocStandingOrderExecutionModal
          handleClose={() => {
            setShowAdHocStandingOrderModal(false)
          }}
          adHocStandingOrder={adHocStandingOrder}
        />
      )}

      <ModalTemplate
        modalTitle={localize('catalogue')}
        modalMode={modalMode}
        handleAction={handleAction}
        handleClose={handleClose}
        modalClassName="modal-xl"
        hideUpdate
      >
        <>
          <div className="row g-3 mb-2">
            <div className="col d-flex align-items-center with-validation">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerFullName}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerType')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerMembershipClassCustomerTypeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipStatus')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerMembershipStatusDescription}
              />
            </div>
          </div>
          <div className="row g-3 mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('membershipWdr.Date')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerMembershipTerminationDate}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('domicileBranch')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerBranchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('employerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerEmployerDescription}
              />
            </div>
          </div>
          <div className="row g-3 mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('dutyWorkStation')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerDutyStationDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('adminDivision')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerAdministrativeDivisionDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('identificationNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerIdentificationNumber}
              />
            </div>
          </div>
          <div className="row mb-2 g-3">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.formattedCustomerMemberNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.paddedCustomerAccountNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customer?.customerPersonalFileNumber}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('transactionReference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.transactionReferenceDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('terminationCategory')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.categoryDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('terminationRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.remarks}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('createdBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.createdBy}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('createdDate')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formatDate}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('status')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.statusDescription}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('approvedBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.approvedBy}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('approvalDate')}
                </label>
                <input
                  type="text"
                  value={formatDate(customer?.approvedDate)}
                  name={'approvalDate'}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('approvalRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.approvalRemarks}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('verifiedBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.auditedBy}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className=" d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('verifiedDate')}
                </label>
                <input
                  type="text"
                  value={formatDate(customer?.auditedDate)}
                  name={'auditedDate'}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('verifiedRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.auditedRemarks}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('settledBy')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.settledBy}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('settledDate')}
                </label>
                <input
                  type="text"
                  value={formatDate(customer?.settledDate)}
                  disabled
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('settledRemarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.settlementRemarks}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('settlementType')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={customer?.settlementTypeDescription}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3">
              <Tab tabItems={tabItems} />
            </div>
          </div>

          {customer && customer.terminationCategory !== deceased && (
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('deposits(Refundable)')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={
                      customer?.terminationCategory === voluntary ||
                      customer?.terminationCategory === retiree
                        ? balance?.netInvestmentsBalance
                        : balance?.backOfficeInvestmentsBalance
                    }
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('loansBalance')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={
                      customer?.terminationCategory === voluntary ||
                      customer?.terminationCategory === retiree
                        ? balance?.netLoansBalance
                        : balance?.backOfficeLoansBalance
                    }
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('netRefundable')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={
                      customer?.terminationCategory === voluntary ||
                      customer?.terminationCategory === retiree
                        ? balance?.netRefundable
                        : balance?.netPartialRefundable
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </ModalTemplate>
    </>
  )
}

export default ViewCatalogueModal
