/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import NonBlockingLoader from '../../Loaders/NonBlockingLoader'
import RecordsPerPage from '../../RecordsPerPage/RecordsPerPage'
import GroupedTable from '../../Tables/GroupedTable'
import Pager from '../../Tables/Pager'

function ListCustomer({
  onSelect,
  canClickOnce,
  contextMenu,
  onClickOnce,
  canSelect,
  hiddenColumnsIndexes = [],
  reload,
  setReload,
}) {
  const [data, setData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('Membership Class'),
    value: 14,
    column: 'membershipClassDescription',
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.fullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.formattedMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.formattedAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.personalFileNumber}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r?.membershipStatusDescription}</>,
    },
    {
      label: localize('individualType'),
      template: r => <>{r?.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r?.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{formatDate(r?.individualBirthDate, true)}</>,
    },
    {
      label: localize('employmentSector'),
      template: r => <>{r?.individualEmploymentSectorDescription}</>,
    },
    {
      label: localize('employmentDate'),
      template: r => <>{formatDate(r?.individualEmploymentDate, true)}</>,
    },
    {
      label: localize('employmentDesignation'),
      template: r => <>{r?.individualEmploymentDesignation}</>,
    },
    {
      label: localize('employmentTermsOfService'),
      template: r => <>{r?.individualEmploymentTermsOfServiceDescription}</>,
    },
    {
      label: localize('educationLevel'),
      template: r => <>{r?.individualEducationLevelDescription}</>,
    },
    {
      label: localize('membershipClass'),
      template: r => <>{r?.membershipClassDescription}</>,
    },
    {
      label: localize('disabilityType'),
      template: r => <>{r?.individualDisabilityTypeDescription}</>,
    },
    {
      label: localize('hudumaNumber'),
      template: r => <>{r?.individualHudumaNumber}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r?.nonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('registrationSerialNumber'),
      template: r => <>{r?.nonIndividualRegistrationSerialNumber}</>,
    },
    {
      label: localize('meetingFrequency'),
      template: r => <>{r?.nonIndividualMeetingFrequencyDescription}</>,
    },
    {
      label: localize('meetingDay'),
      template: r => <>{r?.nonIndividualMeetingDayOfWeekDescription}</>,
    },
    {
      label: localize('meetingTime'),
      template: r => <>{r?.nonIndividualMeetingTime}</>,
    },
    {
      label: localize('meetingPlace'),
      template: r => <>{r?.nonIndividualMeetingPlace}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },

    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.addressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: localize('monthlyContribution'),
      template: r => <>{r?.monthlyContribution}</>,
    },

    {
      label: `${localize('reference1')} ${localize('account')}`,
      template: r => <>{r?.reference1}</>,
    },
    {
      label: `${localize('reference2')} ${localize('membership')}`,
      template: r => <>{r?.reference2}</>,
    },
    {
      label: `${localize('reference3')} ${localize('personalFile')}`,
      template: r => <>{r?.reference3}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('duty') + '/' + localize('workStation'),
      template: r => <>{r?.dutyStationDescription}</>,
    },
    {
      label: localize('electoralZone'),
      template: r => <>{r?.electoralZoneDescription}</>,
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      template: r => <>{r?.employerDescription}</>,
    },
    {
      label: localize('retirementAge'),
      template: r => <>{r?.individualRetirementAge}</>,
    },
    {
      label: localize('terminationDate'),
      template: r => <>{r?.membershipTerminationDate}</>,
    },
    {
      label: localize('age'),
      template: r => <>{r?.age}</>,
    },
    {
      label: `${localize('membershipPeriod')}(${localize('months')})`,
      template: r => <>{r?.membershipPeriod}</>,
    },
    {
      label: localize('adminDivision'),
      template: r => <>{r?.administrativeDivisionDescription}</>,
    },
    {
      label: localize('joinDate'),
      template: r => <>{formatDate(r?.registrationDate)}</>,
    },
    {
      label: `${localize('isTaxExempt')}`,
      template: r => <>{r?.isTaxExempt?.toString()}</>,
    },
    {
      label: `${localize('inhibitGuaranteeing')}`,
      template: r => <>{r?.inhibitGuaranteeing?.toString()}</>,
    },
    {
      label: `${localize('inhibitPosting')}`,
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: localize('recruitedBy'),
      template: r => <>{r?.recruitedBy}</>,
    },

    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'membershipStatusDescription' },
    { value: 1, column: 'individualIdentityCardTypeDescription' },
    { value: 2, column: 'individualSalutationDescription' },
    { value: 3, column: 'individualGenderDescription' },
    { value: 4, column: 'individualMaritalStatusDescription' },
    {
      value: 5,
      column: 'individualNationalityDescription',
    },
    {
      value: 6,
      column: 'individualDisabilityType',
    },
    { value: 7, column: 'branchDescription' },
    { value: 8, column: 'dutyStationDescription' },
    { value: 9, column: 'employerDescription' },
    { value: 10, column: 'administrativeDivisionDescription' },
    { value: 11, column: 'employmentSectorDescription' },
    { value: 12, column: 'electoralZoneDescription' },
    { value: 14, column: 'membershipClassDescription' },
  ]

  const customerColumns = columns?.filter(
    (_c, i) => hiddenColumnsIndexes?.indexOf(i) === -1
  )

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/customer/find-customers-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
      if (reload) {
        setReload(false)
      }
    }

    setIsBusy(false)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    if (reload) loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <div className="relative">
      <div className="mb-3 ms-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center ">
          <RecordsPerPage reqParams={reqParams} setReqParams={setReqParams} />
        </div>
        <div className="d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('groupBy')}</label>
          <EnumsServerCombo
            sort
            customContainerStyle={{
              maxWidth: 200,
              minWidth: 200,
            }}
            value={groupBy?.value}
            enumsKey="CustomerPropertyGroup"
            onChange={({ label, value }) =>
              setGroupBy({
                label,
                value,
                column: groupingColumns[value]?.column,
              })
            }
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-end gap-2"
          style={{ maxWidth: 'fit-content' }}
        >
          <label
            style={{ minWidth: 'fit-content' }}
            className="text-capitalize me-2"
          >
            {localize('search')}
          </label>
          <EnumsServerCombo
            sort
            customContainerStyle={{
              maxWidth: 180,
              minWidth: 180,
            }}
            value={reqParams?.customerFilter}
            enumsKey="CustomerFilter"
            onChange={({ value }) =>
              setReqParams({ ...reqParams, customerFilter: value })
            }
          />
          <input
            type="text"
            className="form-control"
            value={reqParams?.filterText}
            onChange={e =>
              setReqParams({
                ...reqParams,
                filterText: e.target.value,
              })
            }
            onKeyDown={e => {
              if (e.key === 'Enter') loadData()
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              loadData()
            }}
          >
            <i className="uil uil-filter"></i>
          </button>
        </div>
      </div>
      <GroupedTable
        groupBy={groupBy}
        columns={customerColumns}
        data={data?.pageCollection}
        canSelect={canSelect}
        onSelect={onSelect}
        canClickOnce={canClickOnce}
        onClickOnce={onClickOnce}
        contextMenu={contextMenu}
      />
      <Pager
        itemsCount={data.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default ListCustomer
