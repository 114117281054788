/** @format */

import React, { useState } from 'react'
import Swal from 'sweetalert2'
import ListingPage from '../../../../../../Components/ListingPage/Index'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'
import { loadMedia } from '../../../../../../Helpers/Modules/media'
import { formatDate } from '../../../../../../Helpers/formatters'
import AddDocument from './AddDocument/AddDocument'

const Documents = () => {
  const [refreshAt, setRefreshAt] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [document, setDocument] = useState({})
  const params = {
    text: '',
    pageSize: 10,
    pageIndex: 0,
  }

  const columns = [
    { label: localize('title'), template: r => <>{r?.fileTitle}</> },
    { label: localize('mimeType'), template: r => <>{r?.fileMIMEType}</> },
    {
      label: localize('morguePatient'),
      template: r => <>{r?.morguePatientFullName}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.morguePatientIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.morguePatientIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => (
        <>{r?.morguePatientIndividualIdentityCardTypeDescription}</>
      ),
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.morguePatientIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r?.morguePatientIndividualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.morguePatientIndividualNationalityDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedMorguePatientSerialNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.morguePatientAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.morguePatientAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.morguePatientAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.morguePatientAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.morguePatientAddressCity}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleSelectDocument = (r, mode) => {
    if (mode === 'view') loadMediaItem(r?.id)

    setDocument(r)
    setMode(mode)
  }

  const handleClose = () => {
    setMode(null)
  }

  async function loadMediaItem(id) {
    try {
      setIsBusy(true)
      let mediaItem = await loadMedia(id)

      if (mediaItem?.contentType) {
        const newBlobString = `data:${mediaItem?.contentType};base64,${mediaItem?.content}`
        const pdfWindow = window.open(newBlobString, '_blank')

        if (!pdfWindow) {
          Swal.fire(
            'Popup Blocked',
            'Please allow pop-ups to view the PDF.',
            'error'
          )
        }
      } else {
        Swal.fire('No File', "Can't load file", 'error')
      }
    } catch (error) {
      console.error('Error loading media:', error)
      Swal.fire('Error', 'An error occurred while loading the media', 'error')
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <AddDocument
          mode={mode}
          fetchDocuments={() => setRefreshAt(true)}
          handleClose={handleClose}
          documentData={document}
        />
      )}

      {isBusy ? (
        <div className="card bg-white">
          <div className="card-body">
            <Loader />
          </div>
        </div>
      ) : (
        <ListingPage
          map={[
            localize('morgueManagement'),
            localize('morguePatients'),
            localize('morguePatientDocuments'),
          ]}
          columns={columns}
          url="/MorguePatientDocument/find-morgue-patient-document-by-filter-in-page"
          onAdd={handleClickAddButton}
          useText
          params={params}
          refreshAt={refreshAt}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleSelectDocument(r, 'view')
                }}
              >
                <i className="uil uil-eye"></i>
                <span>{localize('view')}</span>
              </div>
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleSelectDocument(r, 'edit')
                }}
              >
                <i className="uil uil-edit-alt"></i>
                <span>{localize('edit')}</span>
              </div>
            </>
          )}
        />
      )}
    </>
  )
}

export default Documents
