/** @format */

export const AccountStatisticsTabs = {
  AccountsListing: 0,
  CreditScores: 1,
  LoansGuaranteed: 2,
  LoanGuarantors: 3,
  LoanApplications: 4,
  PayoutsHistory: 5,
  LoanProductExemptions: 6,
  BankAccountBalances: 7,
  IndividualParticulars: 8,
  Address: 9,
  Referees: 10,
  Specimen: 11,
  MergedStatements:12
}

export const SelectedCustomerAccountStatisticsTab = {
  ManagementHistory: 0,
  Signatories: 1,
  StandingOrders: 2,
  AlternateChannels: 3,
  UnclearedCheques: 4,
  FixedDeposits: 5,
}
