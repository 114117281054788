/** @format */

import React, { useEffect, useState } from 'react'
import localize from './../../../../Global/Localize'
import SimpleTable from './../../../../Components/Tables/SimpleTable'
import { getData } from '../../../../Helpers/webApi'
import Pager from '../../../../Components/Tables/Pager'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from './../../../../Components/ComboBox/EnumsServerCombo'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import CreateItemRegister from './Partials/CreateItemRegister'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import { itemRegisterType } from '../../../../Helpers/constants'

const ItemsRegister = () => {
  const [tableData, setTableData] = useState({})
  const [tableMeta, setTableMeta] = useState({})
  const [mode, setMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [registerItem, setRegisterItem] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [itemType, setItemType] = useState(2)
  const [reqParams, setReqParams] = useState({
    itemType: 2,
    filter: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const assetColumns = [
    {
      label: localize('code'),
      template: r => <>{r?.code}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r?.assetTypeDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r?.inventoryCategoryDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r?.inventoryUnitOfMeasureDescription}</>,
    },
    {
      label: localize('estimatedUnitCost'),
      template: r => <>{r?.estimatedUnitCost}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <>{<span className="text-capitalize">{r?.isLocked.toString()}</span>}</>
      ),
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const inventoryColumns = [
    {
      label: localize('code'),
      template: r => <>{r?.code}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r?.inventoryCategoryDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r?.inventoryUnitOfMeasureDescription}</>,
    },
    {
      label: localize('package'),
      template: r => <>{r?.inventoryPackageTypeDescription}</>,
    },
    {
      label: localize('estimatedUnitCost'),
      template: r => <>{formatCurrency(r?.estimatedUnitCost)}</>,
    },
    {
      label: localize('sellingPrice'),
      template: r => <>{formatCurrency(r?.sellingPrice)}</>,
    },
    {
      label: localize('reorderPoint'),
      template: r => <>{r?.reorderPoint}</>,
    },
    {
      label: localize('maximumOrder'),
      template: r => <>{r?.maximumOrder}</>,
    },
    {
      label: localize('unitsPerPack'),
      template: r => <>{r?.unitsPerPack}</>,
    },
    {
      label: localize('palletTI'),
      template: r => <>{r?.palletTI}</>,
    },
    {
      label: localize('palletHI'),
      template: r => <>{r?.palletHI}</>,
    },
    {
      label: localize('unitOfWeight'),
      template: r => <>{r?.unitOfWeight}</>,
    },
    {
      label: localize('unitGrossWeight'),
      template: r => <>{r?.unitGrossWeight}</>,
    },
    {
      label: localize('leadDays'),
      template: r => <>{r?.leadDays}</>,
    },
    {
      label: localize('economicOrder'),
      template: r => <>{r?.economicOrder}</>,
    },
    {
      label: localize('monthlyDemand'),
      template: r => <>{r?.monthlyDemand}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <>{<span className="text-capitalize">{r?.isLocked.toString()}</span>}</>
      ),
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    fetchRegisteredItems()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, reqParams.itemType, refresh])

  async function fetchRegisteredItems() {
    setIsBusy(true)
    const url = '/ItemRegister/find-invoices-by-filter-in-page'
    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  function handleClose() {
    setMode(null)
    setRegisterItem({})
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetManagement'),
          localize('itemsRegister'),
        ]}
      />
      {(mode === 'add' || mode === 'edit') && (
        <CreateItemRegister
          setMode={setMode}
          mode={mode}
          loadTableData={fetchRegisteredItems}
          handleClose={handleClose}
          registerItem={registerItem}
          setRegisterItem={setRegisterItem}
          itemType={itemType}
          setItemType={setItemType}
        />
      )}

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-start mb-3">
            {<AddNewButton handleClickAddButton={() => setMode('add')} />}
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex mb-3 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label className="text-capitalize">
                    {localize('itemType')}
                  </label>
                  <EnumsServerCombo
                    enumsKey="ItemRegisterType"
                    className="ms-2"
                    value={reqParams?.itemType}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        itemType: e.value,
                      })
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <form
                    className="d-flex align-items-center"
                    onSubmit={e => {
                      e.preventDefault()
                      setRefresh(!refresh)
                    }}
                  >
                    <label htmlFor="searchTable">Search</label>
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2 me-1"
                      defaultValue={reqParams?.filter}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          filter: e.target.value,
                          pageIndex: 0,
                        })
                      }
                    />
                  </form>
                  <button
                    id="itemRegisterFilter"
                    className="btn btn-primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>

              <div className="row d-flex">
                <SimpleTable
                  contextMenu={r => (
                    <>
                      <div
                        onClick={async () => {
                          setMode('edit')
                          setRegisterItem(r)
                        }}
                        className="ctxt-menu-item text-capitalize"
                      >
                        <i className="uil uil-edit"></i>
                        {localize('edit')}
                      </div>
                    </>
                  )}
                  columns={
                    reqParams.itemType === itemRegisterType.ASSET
                      ? assetColumns
                      : inventoryColumns
                  }
                  data={tableData}
                />
              </div>
              <div className="mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ItemsRegister
