/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import WorkstationLookup from '../../../../../Components/Lookup/WorkstationLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'

const CreateWorkStation = ({
  handleClose,
  modalMode,
  isBusy,
  selectedStation,
  setSelectedStation,
  handleCreate,
  handleUpdate,
  clearValidation,
  gbv,
}) => {
  return (
    <ModalTemplate
      modalMode={modalMode}
      handleAction={modalMode === 'add' ? handleCreate : handleUpdate}
      modalTitle="dutyWorkStations"
      handleClose={handleClose}
      disableClose={isBusy}
      disableCreate={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3">
            <Checkbox
              id="workStationDutyIsLocked"
              onCheck={value =>
                setSelectedStation({ ...selectedStation, isLocked: value })
              }
              checked={selectedStation?.isLocked}
              disabled={isBusy}
            />
            <label
              className={'text-capitalize ms-1 me-2 '}
              htmlFor="workStationDutyIsLocked"
            >
              {localize('isLocked')} ?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('parent')}
              </label>
              <WorkstationLookup
                displayValue={selectedStation?.parentDescription}
                onSelect={r =>
                  setSelectedStation({
                    ...selectedStation,
                    parentId: r.id,
                    parentDescription: r.description,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={gbv?.Description?.message}
            >
              <label className="text-capitalize col-3">
                {localize('name')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedStation?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedStation({
                    ...selectedStation,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={gbv?.Type?.message}
            >
              <label className="text-capitalize col-3">
                {localize('type')}
              </label>
              <EnumsServerCombo
                value={selectedStation?.type}
                customContainerStyle={{ width: '100%' }}
                onChange={e => {
                  clearValidation('Type')
                  setSelectedStation({
                    ...selectedStation,
                    type: e.value,
                  })
                }}
                enumsKey="DutyStationType"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={selectedStation?.remarks}
                onChange={e =>
                  setSelectedStation({
                    ...selectedStation,
                    remarks: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateWorkStation
