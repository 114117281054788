/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'
import { CustomerPartial } from '../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import {
  RecordStatus,
  customerAutoCompleteFilter,
} from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { getData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Specimen from '../Partials/Customers/AddCustomerScreens/Specimen'

function DomicileBranchLinkage() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [domicileBranch, setDomicileBranch] = useState({
    filterCriteria: Object.keys(customerAutoCompleteFilter)[0],
    includeCustomerAccounts: false,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleOnSelectCustomer = r => {
    if (r?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Customer is locked!',
      })

    if (r?.recordStatus !== Object.keys(RecordStatus)[2])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Customer record status must be in approved status.',
      })

    setSelectedCustomer(r)
  }

  const getUsersCurrentBranch = async () => {
    setIsBusy(true)
    const url = '/AccessControl/find-current-user'

    const { data, success } = await getData(url, null, false)

    if (success) {
      setDomicileBranch({
        ...domicileBranch,
        addressCity: data?.employeeBranchAddressCity,
        companyDescription: data?.employeeBranchCompanyDescription,
        addressStreet: data?.employeeCustomerAddressStreet,
        branchDescription: data?.employeeBranchDescription,
        branchId: data?.employeeBranchId,
      })
    }

    setIsBusy(false)
  }

  const handleUpdate = async () => {
    setIsBusy(true)
    const { success } = await putData(
      '/Customer/update-customer-branch',
      {
        customer: {
          ...domicileBranch,
          ...selectedCustomer,
        },
        includeCustomerAccounts: domicileBranch.includeCustomerAccounts,
      },
      false
    )

    if (success) {
      setDomicileBranch({
        filterCriteria: Object.keys(customerAutoCompleteFilter)[0],
        includeCustomerAccounts: false,
      })
      setSelectedCustomer(null)
      showNotification('Domicile Branch Linkage', 'success')
      getUsersCurrentBranch()
    }

    setIsBusy(false)
  }

  useEffect(() => {
    getUsersCurrentBranch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('domicileBranchLinkage'),
        ]}
      />

      <div className="card rounded bg-white relative">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['Customer.BranchDescription']?.message
                }
              >
                <label htmlFor="targetBranch" className="text-capitalize col-3">
                  {localize('targetBranch')}
                </label>
                <BranchLookup
                  displayValue={domicileBranch?.branchDescription || ''}
                  onSelect={branch => {
                    clearValidation('Customer.BranchDescription')
                    setDomicileBranch({
                      ...domicileBranch,
                      addressCity: branch?.addressCity,
                      companyDescription: branch?.companyDescription,
                      addressStreet: branch?.addressStreet,
                      branchDescription: branch?.description,
                      branchId: branch?.id,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="city" className="text-capitalize col-1">
                  {localize('city')}
                </label>

                <input
                  type="text"
                  id="city"
                  className="form-control ms-2"
                  defaultValue={domicileBranch?.addressCity || ''}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="company" className="text-capitalize col-3">
                  {localize('company')}
                </label>

                <input
                  type="text"
                  id="company"
                  className="form-control ms-2"
                  defaultValue={domicileBranch?.companyDescription || ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('customerDetails')}
            </div>
            <div className="row g-3">
              <CustomerPartial
                customer={selectedCustomer}
                onCustomerLookup={r => {
                  handleOnSelectCustomer(r)
                  clearValidation('Customer.FullName')
                }}
                message={globalValidationErrors['Customer.FullName']?.message}
              />
            </div>
            <div className="bg-light m-2 rounded py-2 d-flex align-items-center justify-content-end">
              <div className=" offset-7 float-end">
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="includeCustomerAccounts"
                    checked={domicileBranch?.includeCustomerAccounts || false}
                    onCheck={e => {
                      setDomicileBranch({
                        ...domicileBranch,
                        includeCustomerAccounts: e,
                      })
                    }}
                  />
                  <label
                    className="text-capitalize ms-2 me-4"
                    htmlFor="includeCustomerAccounts"
                  >
                    {localize('includeCustomerAccounts')} ?
                  </label>
                </div>
              </div>
              <div>
                <button
                  onClick={handleUpdate}
                  className="btn btn-success float-end"
                >
                  {localize('update')}
                </button>
              </div>
            </div>
            <Tab
              tabItems={[
                {
                  label: localize('specimen'),
                  item: (
                    <>
                      <Specimen readOnly customer={selectedCustomer} />
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
        {isBusy && <NonBlockingLoader />}
      </div>
    </>
  )
}

export default DomicileBranchLinkage
