/** @format */

import React, { useEffect, useState } from 'react'
import PieChart from '../../../../../Charts/ChartJS/PieChart/PieChart'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'

const MorgueBeds = () => {
  const [statistics, setStatistics] = useState({})
  const [isBusy, setIsBusy] = useState(false)

  const fetchStatistics = async () => {
    setIsBusy(true)
    const url = '/Bed/find-bed-occupancy-statistics'

    const { success, data } = await getData(url, {}, false)

    if (success) {
      setStatistics(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchStatistics()
  }, [])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('morgueManagement'),
          localize('visualStatistics'),
          localize('morgueBeds'),
        ]}
      />

      <div className="card row rounded bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <div className="col-md-6 d-flex justify-content-center p-3">
              <PieChart
                datasetsData={[statistics?.vacant, statistics?.occupied]}
                labels={[localize('occupied'), localize('vacant')]}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MorgueBeds
