/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SavingsProducts from '../../../../../Components/Selectables/Plain/SavingsProduct'
import InvestmentProducts from '../../../../../Components/Selectables/Plain/InvestmentProducts'
import Tab from '../../../../../Components/Tabs/Tab'
import PostingPeriodLookup from '../../../../../Components/Lookup/PostingPeriodLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { getData, putData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import {
  customerAccountBalanceStatistic,
  transactionDateFilter,
} from '../../../../../Global/enumeration'
import { formatDate } from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
function CreateCustomerAccountBalanceSpoolDataModal({
  mode,
  onHideModal,
  onSetMode,
  customerAccountBalanceSpoolRequestModel,
  setCustomerAccountBalanceSpoolRequestModel,
  investmentProductsToBeSelected,
  savingsProductsToBeSelected,
  setInvestmentProductsToBeSelected,
  setSavingsToBeSelected,
  loadData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedInvestmentProducts, setSelectedInvestmentProducts] = useState(
    []
  )
  const [selectedSavingsProducts, setSelectedSavingsProducts] = useState([])

  async function handleSubmit(finalData) {
    setIsBusy(true)
    const { success } = await putData(
      '/CustomerAccountBalanceSpool/spool-customer-account-balances',
      finalData,
      false,
      'Operation Completed Successfully'
    )
    if (success) {
      setIsBusy(false)
      onSetMode(null)
      loadData()
    }
  }

  function closeModal() {
    onHideModal()
  }

  async function findCurrentPostingPeriod(finalData) {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      false
    )
    if (success) {
      setIsBusy(true)
      setCustomerAccountBalanceSpoolRequestModel({
        postingPeriodId: data.id,
        postingPeriodDescription: data.description,
        durationStartDate: data.durationStartDate,
        durationEndDate: data.durationEndDate,
        statistic: Object.keys(customerAccountBalanceStatistic)[0],
        dateFilter: Object.keys(transactionDateFilter)[0],
      })
    }
  }

  useEffect(() => {
    findCurrentPostingPeriod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {mode && (
        <ModalTemplate
          modalMode={mode}
          modalTitle="spooledMonthlyAccountBalances"
          handleClose={closeModal}
          modalClassName="modal-xl"
          handleAction={() => {
            let finalData = {
              customerAccountBalanceSpool: {
                ...customerAccountBalanceSpoolRequestModel,
              },
              savingsProductDTOs: selectedSavingsProducts,
              investmentProductDTOs: selectedInvestmentProducts,
            }
            handleSubmit(finalData).then(r => {})
          }}
          actionText={mode === 'add' ? 'create' : ''}
        >
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('postingPeriod')}
              </label>
              <PostingPeriodLookup
                displayValue={
                  customerAccountBalanceSpoolRequestModel?.postingPeriodDescription
                }
                onSelect={r => {
                  setCustomerAccountBalanceSpoolRequestModel({
                    ...customerAccountBalanceSpoolRequestModel,
                    postingPeriodId: r.id,
                    postingPeriodDescription: r.description,
                    durationStartDate: r.durationStartDate,
                    durationEndDate: r.durationEndDate,
                  })
                }}
              />
            </div>

            <div className="col d-flex align-items-center ms-1 ">
              <label className="col-3 text-capitalize">
                {localize('startDate')}
              </label>
              <CustomDatePicker
                defaultDate={
                  customerAccountBalanceSpoolRequestModel?.durationStartDate
                }
                name={'durationStartDate'}
                onDateChange={date => {
                  setCustomerAccountBalanceSpoolRequestModel({
                    ...customerAccountBalanceSpoolRequestModel,
                    durationStartDate: date,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center ms-1 ">
              <label className="col-3 text-capitalize">
                {localize('endDate')}
              </label>
              <CustomDatePicker
                defaultDate={
                  customerAccountBalanceSpoolRequestModel?.durationEndDate
                }
                name={'durationEndDate'}
                onDateChange={date => {
                  setCustomerAccountBalanceSpoolRequestModel({
                    ...customerAccountBalanceSpoolRequestModel,
                    durationEndDate: date,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('balanceStatistics')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerAccountBalanceStatistic"
                value={
                  customerAccountBalanceSpoolRequestModel?.statisticDescription
                }
                onChange={e =>
                  setCustomerAccountBalanceSpoolRequestModel({
                    ...customerAccountBalanceSpoolRequestModel,
                    statistic: e.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('dateFilter')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="TransactionDateFilter"
                value={
                  customerAccountBalanceSpoolRequestModel?.dateFilterDescription
                }
                onChange={e =>
                  setCustomerAccountBalanceSpoolRequestModel({
                    ...customerAccountBalanceSpoolRequestModel,
                    dateFilter: e.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('reference')}
              </label>
              <input
                type="text"
                className="form-control"
                value={customerAccountBalanceSpoolRequestModel?.reference || ''}
                onChange={e => {
                  setCustomerAccountBalanceSpoolRequestModel({
                    ...customerAccountBalanceSpoolRequestModel,
                    reference: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row col-12 mt-2">
            <div className="col md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('savingsProducts'),
                    item: (
                      <SavingsProducts
                        prefix={'create-customer-acc-bal-spool-savings-product'}
                        savingsProductToBeSelected={savingsProductsToBeSelected}
                        onCheckSavingsProduct={r => {
                          setSavingsToBeSelected(r)
                          setSelectedSavingsProducts(r.filter(x => x.checked))
                        }}
                      />
                    ),
                  },
                  {
                    label: localize('investmentProducts'),
                    item: (
                      <InvestmentProducts
                        prefix={'create-customer-acc-bal-spool-inv-product'}
                        investmentProductsToBeSelected={
                          investmentProductsToBeSelected
                        }
                        onCheckInvestmentProduct={r => {
                          setInvestmentProductsToBeSelected(r)
                          setSelectedInvestmentProducts(
                            r.filter(x => x.checked)
                          )
                        }}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </ModalTemplate>
      )}
    </>
  )
}
export default CreateCustomerAccountBalanceSpoolDataModal
