/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import Loader from '../../../../../../Components/Loaders/Loader'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import {
  treasuryManagementRequestStatus,
  treasuryTrailCategory,
} from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'
import AuthorizeCashTransferRequest from './AuthorizeCashTransferRequest/AuthorizeCashTransferRequest'

const initialRequest = {
  treasuryManagementRequestAuthOption: 1,
}

const CashTransferRequests = ({
  treasury,
  setTransferRequest,
  setTreasuryTrailCategory,
}) => {
  const [requests, setRequests] = useState([])
  const [request, setRequest] = useState(initialRequest)
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [waitingPost, setWaitingPost] = useState(false)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    status: 1,
    treasuryTrailCategory: Object.keys(treasuryTrailCategory)[0],
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('treasuryTrailType'),
      template: r => r?.treasuryTrailTypeDescription,
    },
    {
      label: localize('transactionSource'),
      template: r => r?.treasuryTrailSourceDescription,
    },
    {
      label: localize('transactionDestination'),
      template: r => r?.treasuryTrailDestinationDescription,
    },
    {
      label: localize('primaryDescription'),
      template: r => r?.primaryDescription,
    },
    {
      label: localize('secondaryDescription'),
      template: r => r?.secondaryDescription,
    },
    { label: localize('reference'), template: r => r?.reference },
    { label: localize('variance'), template: r => formatCurrency(r?.variance) },
    {
      label: localize('varianceType'),
      template: r => r?.varianceKindDescription,
    },
    {
      label: localize('oneThousands'),
      template: r => formatCurrency(r?.denominationOneThousandValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('fiveHundreds'),
      template: r => formatCurrency(r?.denominationFiveHundredValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('twoHundreds'),
      template: r => formatCurrency(r?.denominationTwoHundredValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('oneHundreds'),
      template: r => formatCurrency(r?.denominationOneHundredValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('fifties'),
      template: r => formatCurrency(r?.denominationFiftyValue),
    },
    {
      label: localize('forties'),
      template: r => formatCurrency(r?.denominationFourtyValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('twenties'),
      template: r => formatCurrency(r?.denominationTwentyValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('tens'),
      template: r => formatCurrency(r?.denominationTenValue),
    },
    {
      label: localize('fives'),
      template: r => formatCurrency(r?.denominationFiveValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('ones'),
      template: r => formatCurrency(r?.denominationOneValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('fiftyCents'),
      template: r => formatCurrency(r?.denominationFiftyCentValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('totalValue'),
      template: r => formatCurrency(r?.totalValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('postingPeriod'),
      template: r => r?.postingPeriodDescription,
    },
    {
      label: localize('authorization') + ' ' + localize('rejectionRemarks'),
      template: r => r?.authorizationRemarks,
    },
    {
      label: localize('authorized') + localize('rejectedBy'),
      template: r => r?.authorizedBy,
    },
    {
      label: localize('authorizedDate') + localize('rejectedDate'),
      template: r => formatDate(r?.authorizedDate),
    },
    { label: localize('postedBy'), template: r => r?.postedBy },
    { label: localize('postedDate'), template: r => formatDate(r?.postedDate) },
    { label: localize('createdBy'), template: r => r?.createdBy },
  ]

  const fetchRequests = async () => {
    if (!treasury?.id) {
      setRequests([])
      return
    }

    setIsBusy(true)

    const url =
      reqParams.treasuryTrailCategory === Object.keys(treasuryTrailCategory)[0]
        ? '/TreasuryManagementRequest/find-treasury-management-requests-by-treasury-trail-destination-id-and-filter-in-page'
        : '/TreasuryManagementRequest/find-treasury-management-requests-by-treasury-trail-sourced-id-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        treasuryTrailSourcedId: treasury?.id,
        treasuryTrailDestinationId: treasury?.id,
      },
      false
    )

    if (success) {
      setRequests(data)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
    setRequest(initialRequest)
  }

  const handleSelectRequest = (r, action) => {
    if (
      action === 'authorize' &&
      r?.status !== Object.keys(treasuryManagementRequestStatus)[0]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only authorize pending requests!',
      })

    if (
      action === 'post' &&
      r?.status !== Object.keys(treasuryManagementRequestStatus)[1]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'You can only post verified requests!',
      })

    setRequest({
      ...r,
      ...initialRequest,
    })
    setMode(action)
  }

  const handlePost = async () => {
    setWaitingPost(true)

    const url = '/TreasuryManagementRequest/post-treasury-management-request'

    const { success } = await postData(
      url,
      {
        treasuryManagementRequest: request,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      handleClose()
      fetchRequests()
      showNotification('Post treasury management request', 'success')
      //TODO: myke - ask whether to print 14/09/2023
    }

    setWaitingPost(false)
  }

  const handleClickOnce = r => {
    setTransferRequest(r)
  }

  useEffect(() => {
    if (treasury?.id) fetchRequests()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.pageIndex,
    reqParams.treasuryTrailDestinationId,
    reqParams.treasuryTrailSourcedId,
    reqParams.pageSize,
    treasury?.id,
  ])

  return (
    <>
      {mode === 'authorize' && (
        <AuthorizeCashTransferRequest
          handleClose={handleClose}
          mode={mode}
          request={request}
          setRequest={setRequest}
          fetchRequests={fetchRequests}
        />
      )}

      {mode === 'post' && (
        <ActionModal
          cancelText="no"
          confirmText="yes"
          handleAction={handlePost}
          modalTitle="treasuryCashTransfer"
          handleClose={handleClose}
          disableAction={waitingPost}
        >
          {waitingPost ? (
            <Loader />
          ) : (
            <h5>
              Do you want to proceed and post the authorized cash management
              post request?
            </h5>
          )}
        </ActionModal>
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3 gap-1">
            <div className="d-flex align-items-center">
              <label
                style={{
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                className="text-capitalize"
              >
                {localize('recordsPerPage')}
              </label>
              <select
                className="form-select ms-1"
                style={{ maxWidth: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e => {
                  setReqParams({ ...reqParams, pageSize: e.target.value })
                  setReqParams({ ...reqParams, pageIndex: 0 })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('category')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 130,
                  minWidth: 130,
                }}
                value={reqParams?.treasuryTrailCategory}
                enumsKey="TreasuryTrailCategory"
                onChange={({ value }) => {
                  setReqParams({
                    ...reqParams,
                    treasuryTrailCategory: value,
                    pageIndex: 0,
                  })
                  setTreasuryTrailCategory(value)
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('status')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 130,
                  minWidth: 130,
                }}
                value={reqParams?.status}
                enumsKey="TreasuryManagementRequestStatus"
                onChange={({ value }) =>
                  setReqParams({ ...reqParams, status: value, pageIndex: 0 })
                }
              />
            </div>
            <div className="d-flex align-items-center">
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('dateRange')}
              </label>
              <DatePicker
                value={{
                  startDate: reqParams?.startDate,
                  endDate: reqParams?.endDate,
                }}
                onSelect={v => {
                  setReqParams({
                    ...reqParams,
                    customDate: true,
                    startDate: v.startDate,
                    endDate: v.endDate,
                    pageIndex: 0,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center gap-1"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-1"
              >
                {localize('search')}
              </label>
              <input
                type="text"
                className="form-control"
                value={reqParams?.filterText}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                    pageIndex: 0,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    fetchRequests()
                  }
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  fetchRequests()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
          <SimpleTable
            columns={columns}
            data={requests?.pageCollection}
            canClickOnce
            onClickOnce={r => handleClickOnce(r)}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleSelectRequest(r, 'authorize')
                  }}
                >
                  <i className="mdi mdi-check-decagram"></i>
                  <span>{localize('authorize')}</span>
                </div>

                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleSelectRequest(r, 'post')
                  }}
                >
                  <i className="mdi mdi-lock-open-check-outline"></i>
                  <span>{localize('post')}</span>
                </div>
              </>
            )}
          />
          <Pager
            itemsCount={requests.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default CashTransferRequests
