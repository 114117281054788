/** @format */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { AddTreasuryModal } from './AddTreasuryModal'
import { EditTreasuryModal } from './EditTreasuryModal'
import { useEffect, useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function TreasuryModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedTreasury,
  setSelectedTreasury,
}) {
  const [isBusy, setIsBusy] = useState(false)

  const addTreasury = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/Treasury/add-treasury',
      { ...(selectedTreasury || {}) },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire(
        'Create Treasury',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editTreasury = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/Treasury/update-treasury',
      { ...(selectedTreasury || {}) },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      swal.fire('Edit Treasury', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('treasury')}
            handleAction={() => {
              mode === 'add' ? addTreasury() : editTreasury()
            }}
            handleClose={onHideModal}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
            footerChildren={
              <div className="d-flex align-content-end justify-content-end">
                <div className="d-flex align-items-center me-5">
                  <Checkbox
                    id={'isLocked'}
                    checked={selectedTreasury?.isLocked}
                    onCheck={v =>
                      setSelectedTreasury({ ...selectedTreasury, isLocked: v })
                    }
                  />
                  <label htmlFor="isLocked" className="text-capitalize ms-2">
                    {localize('isLocked')}?
                  </label>
                </div>
              </div>
            }
          >
            {mode === 'add' && (
              <AddTreasuryModal
                isBusy={isBusy}
                selectedTreasury={selectedTreasury}
                setSelectedTreasury={setSelectedTreasury}
              />
            )}
            {mode === 'edit' && (
              <EditTreasuryModal
                isBusy={isBusy}
                selectedTreasury={selectedTreasury}
                setSelectedTreasury={setSelectedTreasury}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default TreasuryModal
