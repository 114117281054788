/** @format */
import localize from '../../../../../../Global/Localize'
/* eslint-disable */
import React, { useState } from 'react'
import Tab from '../../../../../../Components/Tabs/Tab'
import { AccountMetadataActiveFixedDeposit } from './AccountMetadata/AccountMetadataActiveFixedDeposit'
import { AccountMetadataUnclearedCheques } from './AccountMetadata/AccountMetadataUnclearedCheques'
import { getData, postData } from '../../../../../../Helpers/webApi'
import { AccountMetadataManagementHistory } from './AccountMetadata/AccountMetadataManagementHistory'
import { AccountMetadataSignatories } from './AccountMetadata/AccountMetadataSignatories'
import { AccountMetadataStandingOrders } from './AccountMetadata/AccountMetadataStandingOrders'
import { AccountMetadataAlternateChannels } from './AccountMetadata/AccountMetadataAlternateChannels'
import { SelectedCustomerAccountStatisticsTab } from '../Reducer/constants'

export const AccountMetadataStatisticsModal = ({
  selectedCustomerAccount,
  selectedCustomer,
  setSelectedCustomerAccount,
  gbv,
  clearValidation,
}) => {
  const [loading, setLoading] = useState({
    fixedDeposit: false,
    unclearedCheques: false,
    standingOrders: false,
    alternateChannels: false,
    managementHistory: false,
  })

  const [accountSignatories, setAccountSignatories] = useState([])
  const [alternateChannels, setAlternateChannels] = useState([])
  const [activeFixedDeposits, setActiveFixedDeposits] = useState([])
  const [statementsWithExternalCheques, setStatementsWithExternalCheques] =
    useState([])
  const [managementHistory, setManagementHistory] = useState([])

  let tabs = [
    {
      tab: SelectedCustomerAccountStatisticsTab.ManagementHistory,
      label: localize('managementHistory'),
      item: (
        <AccountMetadataManagementHistory
          data={managementHistory}
          loading={loading.managementHistory}
        />
      ),
    },
    {
      tab: SelectedCustomerAccountStatisticsTab.Signatories,
      label: localize('signatories'),
      item: (
        <AccountMetadataSignatories
          loading={loading.signatories}
          data={accountSignatories}
        />
      ),
    },
    {
      tab: SelectedCustomerAccountStatisticsTab.StandingOrders,
      label: localize('standingOrders'),
      item: (
        <AccountMetadataStandingOrders
          loading={loading.standingOrders}
          data={statementsWithExternalCheques.standingOrders}
        />
      ),
    },
    {
      tab: SelectedCustomerAccountStatisticsTab.AlternateChannels,
      label: localize('alternateChannels'),
      item: (
        <AccountMetadataAlternateChannels
          data={alternateChannels}
          loading={loading.alternateChannels}
        />
      ),
    },
    {
      label: localize('unclearedCheques'),
      item: (
        <AccountMetadataUnclearedCheques
          loading={loading.unclearedCheques}
          unclearedCheques={statementsWithExternalCheques.externalCheques}
        />
      ),
    },
    {
      tab: SelectedCustomerAccountStatisticsTab.FixedDeposits,
      label: localize('fixedDeposits'),
      item: (
        <AccountMetadataActiveFixedDeposit
          fixedDeposits={activeFixedDeposits}
          loading={loading.fixedDeposit}
        />
      ),
    },
  ]

  const fetchExternalUnclearedChequesAndStandingOrders = async () => {
    setLoading({ ...loading, unclearedCheques: true, standingOrders: true })
    const { success, data } = await postData(
      '/CustomerAccount/retrieve-customer-standing-order',
      {
        ...selectedCustomerAccount,
      },
      false,
      null
    )
    if (success) {
      setStatementsWithExternalCheques(data)
      setLoading({ ...loading, unclearedCheques: false, standingOrders: false })
    }
  }

  const fetchCustomerStatisticsMetadata = async activeTab => {
    await fetchCustomerAccountsManagementHistory()
    switch (activeTab) {
      case SelectedCustomerAccountStatisticsTab.AlternateChannels:
        await fetchCustomerAccountAlternateChannels()
        break
      case SelectedCustomerAccountStatisticsTab.FixedDeposits:
        await fetchCustomerAccountFixedDeposits()
        break
      case SelectedCustomerAccountStatisticsTab.Signatories:
        await fetchCustomerAccountSignatories()
        break
      case SelectedCustomerAccountStatisticsTab.StandingOrders:
        await fetchExternalUnclearedChequesAndStandingOrders()
        break
      case SelectedCustomerAccountStatisticsTab.ManagementHistory:
        await fetchCustomerAccountsManagementHistory()
        break
      case SelectedCustomerAccountStatisticsTab.UnclearedCheques:
        await fetchExternalUnclearedChequesAndStandingOrders()
        break
      default:
        break
    }
  }

  const fetchCustomerAccountAlternateChannels = async customer => {
    setLoading({ ...loading, alternateChannels: true })
    const { success, data } = await getData(
      '/AlternateChannel/find-alternate-channel-by-customer-account-id',
      {
        customerAccountId: selectedCustomerAccount.id,
      },
      false,
      null
    )
    if (success) {
      setAlternateChannels(data.result)
      setLoading({ ...loading, alternateChannels: false })
    }
  }
  const fetchCustomerAccountFixedDeposits = async customer => {
    setLoading({ ...loading, fixedDeposit: true })
    const { success, data } = await getData(
      '/FixedDeposit/find-fixed-deposits-by-customer-account-id',
      {
        customerAccountId: selectedCustomerAccount?.id,
        includeProductDescription: false,
      },
      false,
      null
    )
    if (success) {
      setActiveFixedDeposits(data.result)
      setLoading({ ...loading, fixedDeposit: false })
    }
  }
  const fetchCustomerAccountSignatories = async customer => {
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account-signatories-by-customer-account-id',
      {
        customerAccountId: selectedCustomerAccount.id,
      },
      false,
      null
    )
    if (success) {
      setAccountSignatories(data.result)
    }
  }
  const fetchCustomerAccountsManagementHistory = async customer => {
    setLoading({ ...loading, managementHistory: true })
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account-history-by-customer-account-id',
      {
        customerAccountId: selectedCustomerAccount.id,
      },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, managementHistory: false })
      setManagementHistory(data.result)
    }
  }

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-12">
          <Tab
            onNavigate={r => {
              fetchCustomerStatisticsMetadata(r.tab)
            }}
            preload
            tabItems={tabs}
          />
        </div>
      </div>
    </>
  )
}
