/** @format */

import React, { useContext, useState, useEffect } from 'react'
import localize from '../../Global/Localize'
import { NavigationContext } from '../../Contexts/NavigationContext'
import authHelper from '../../Helpers/authHelper'

import './shortcut.scss'
import Swal from 'sweetalert2'

function Shortcuts() {
  const items = {
    accounts: [
      {
        code: 6501,
        icon: 'uil uil-graph-bar',
      },
      {
        code: 6502,
        icon: 'mdi mdi-chart-bell-curve-cumulative',
      },
      {
        code: 6503,
        icon: 'mdi mdi-book-open-page-variant-outline',
      },
    ],
    tellering: [
      {
        code: 2507,
        icon: 'mdi mdi-paper-roll-outline',
      },
      {
        code: 2508,
        icon: 'mdi mdi-paper-roll',
      },
      {
        code: 2509,
        icon: 'mdi mdi-paper-roll-outline',
      },
    ],
    dashboard: [
      {
        code: 9512,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 9514,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 9517,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    registry: [
      {
        code: 4501,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 4514,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 4502,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    loaning: [
      {
        code: 5501,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 5503,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 5508,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    guarantors: [
      {
        code: 5520,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 5521,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 5524,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    utilities: [
      {
        code: 2538,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 9515,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 9519,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    messaging: [
      {
        code: 9506,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 9503,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 9504,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    procurement: [
      {
        code: 7502,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 7511,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 7514,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    fileTracking: [
      {
        code: 4535,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 4534,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 4537,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    administration: [
      {
        code: 1502,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 1503,
        icon: 'mdi mdi-file-chart-outline',
      },
      {
        code: 1505,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
    system: [
      {
        code: 9520,
        icon: 'mdi mdi-file-chart-outline',
      },
    ],
  }

  const {
    navigateToComponent,
    activeComponent,
    setNavigatedMenuCode,
    setNavigatedParentCode,
    setActiveComponent,
    flattenedMenus,
  } = useContext(NavigationContext)

  const [enteredCode, setEnteredCode] = useState('')
  const [menuDescription, setMenuDescription] = useState(null)

  function handleNavigate(e) {
    e.preventDefault()
    // eslint-disable-next-line
    let menu = flattenedMenus.find(m => m.code == enteredCode)
    if (menu) {
      const isAllowed = !!authHelper?.currentUserProfile?.profile?.client_menus
        ?.split(',')
        ?.find(m => Number(m) === Number(enteredCode))

      if (isAllowed) {
        navigateToComponent(c => {
          if (!menu?.component) return c
          let selected = menu.component
          // eslint-disable-next-line eqeqeq
          let isFound = c.find(comp => comp.component == selected)
          if (isFound) {
            return c
          } else {
            return [...c, menu]
          }
        })
        setNavigatedMenuCode(menu.code)
        setNavigatedParentCode(menu.parentCode)
        setActiveComponent(menu.component)
      } else {
        Swal.fire(
          'Unauthorized',
          'You are not allowed to view this module',
          'error'
        )
      }
    } else {
      Swal.fire('Not Found', 'Invalid Module Code', 'error')
    }
  }

  useEffect(() => {
    let menu = flattenedMenus.find(m => m.code == enteredCode)
    if (menu) {
      const isAllowed = !!authHelper?.currentUserProfile?.profile?.client_menus
        ?.split(',')
        ?.find(m => Number(m) === Number(enteredCode))

      setMenuDescription({ isAllowed, label: menu.description ?? menu?.label })
    } else {
      setMenuDescription(null)
    }
    // eslint-disable-next-line
  }, [enteredCode])

  return (
    <div>
      <div className="shortcut d-flex gap-2">
        {Object.entries(items).map(m => (
          <div
            className="shortcut-group"
            style={{
              display: m[1].every(
                menu =>
                  !authHelper?.currentUserProfile?.profile?.client_menus
                    ?.split(',')
                    ?.find(m => Number(m) === Number(menu.code))
              )
                ? 'none'
                : 'block',
            }}
          >
            <div
              className="shortcut-label text-capitalize text-muted p-2"
              style={{
                fontSize: 'small',
                minWidth: 'max-content',
                // textAlign: 'center',
              }}
            >
              {localize(m[0])}
            </div>
            <div className="shortcut-list d-flex flex-column gap-2">
              {m[1].map(menuEq => {
                let menu = flattenedMenus.find(m => m.code == menuEq.code)

                const isAllowed =
                  !!authHelper?.currentUserProfile?.profile?.client_menus
                    ?.split(',')
                    ?.find(m => Number(m) === Number(menuEq.code))

                return menu ? (
                  <div
                    style={{
                      cursor: 'pointer',
                      pointerEvents: isAllowed ? 'auto' : 'none',
                      opacity: isAllowed ? 1 : 0.3,
                    }}
                    tag="button"
                    className={`shortcut-item ${
                      menu.component === activeComponent
                        ? 'shortcut-active'
                        : ''
                    } fw-bold d-flex align-items-center gap-2 p-2 rounded`}
                    title={`${localize(menu?.label).toUpperCase()} (${
                      menu.code
                    })`}
                    onClick={() => {
                      // eslint-disable-next-line
                      if (menu) {
                        if (isAllowed) {
                          navigateToComponent(c => {
                            if (!menu?.component) return c
                            let selected = menu.component
                            // eslint-disable-next-line eqeqeq
                            let isFound = c.find(
                              comp => comp.component == selected
                            )
                            if (isFound) {
                              return c
                            } else {
                              return [...c, menu]
                            }
                          })
                          setNavigatedMenuCode(menu.code)
                          setNavigatedParentCode(menu.parentCode)
                          setActiveComponent(menu.component)
                        } else {
                          Swal.fire(
                            'Error',
                            'You are not allowed to view this module',
                            'error'
                          )
                        }
                      }
                    }}
                  >
                    <i className={`shortcut-icon ${menuEq?.icon}`}></i>
                    <div
                      className="shortcut-label text-capitalize"
                      style={{
                        fontSize: 'x-small',
                      }}
                    >
                      {
                        localize(
                          menu?.label
                        ) /* {localize(menu?.label).length > 18
                        ? localize(menu?.label).substring(0, 18) + '...'
                        : localize(menu?.label)} */
                      }
                    </div>
                  </div>
                ) : (
                  <></>
                )
              })}
            </div>
          </div>
        ))}
      </div>
      <hr />
      <div className="px-2">
        <div className="text-capitalize text-muted mb-2">
          {localize('menuCode')}
        </div>
        <form
          onSubmit={handleNavigate}
          className="d-flex align-items-center gap-2 mb-2"
        >
          <input
            value={enteredCode}
            onChange={e => setEnteredCode(e.target.value)}
            type="search"
            style={{ width: 200 }}
            className={`form-control border-${
              menuDescription?.isAllowed ? 'success' : 'primary'
            }`}
          />
          <button
            className={`btn ${
              menuDescription?.isAllowed ? 'btn-success' : 'btn-primary'
            }`}
          >
            <span className="text-capitalize">{localize('goTo')}</span>
            <i className="uil uil-angle-right-b"></i>
          </button>
        </form>
        <div
          className={`text-capitalize ${
            menuDescription?.isAllowed ? 'text-success' : 'text-primary'
          }`}
          style={{ fontSize: 'small' }}
        >
          {menuDescription?.label ? localize(menuDescription?.label) : ''}
        </div>
      </div>
    </div>
  )
}

export default Shortcuts
