/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { LoansGuaranteed } from '../../../../../Components/ReUsables/Customer/LoansGuaranteed'
import { ViewCustomerInvestmentAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerInvestmentAccounts'
import { ViewCustomerLoanAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import { ViewCustomerSavingsAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerSavingsAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { formatDate } from '../../../../../Helpers/formatters'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../Components/Loaders/Loader'

function InvalidateCatalogue({
  modalMode,
  handleClose,
  notification,
  customerSavingsAccounts,
  customerLoanAccounts,
  customerInvestmentAccounts,
  customerLoansGuarantor,
  loadData,
}) {
  const [remarks, setRemarks] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const tabItems = [
    {
      label: localize('savingsAccounts'),
      item: (
        <>
          <ViewCustomerSavingsAccounts
            customerSavingsAccounts={customerSavingsAccounts}
          />
        </>
      ),
    },
    {
      label: localize('loanAccounts'),
      item: (
        <>
          <ViewCustomerLoanAccounts
            customerLoanAccounts={customerLoanAccounts}
            hideContextMenu
            hiddenColumnsIndexes={[0]}
          />
        </>
      ),
    },
    {
      label: localize('investmentAccounts'),
      item: (
        <>
          <ViewCustomerInvestmentAccounts
            customerInvestmentAccounts={customerInvestmentAccounts}
            hideContextMenu
          />
        </>
      ),
    },
    {
      label: localize('loanGuaranteed'),
      item: (
        <>
          <LoansGuaranteed loanGuarantors={customerLoansGuarantor} />
        </>
      ),
    },
  ]

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleInvalidateCatalogue = async () => {
    setIsBusy(true)
    const url = '/WithdrawalNotification/invalidate-withdrawal-notification'

    const { success } = await putData(
      url,
      {
        withdrawalNotification: {
          ...notification,
          invalidationRemarks: remarks,
        },
      },
      false
    )

    if (success) {
      handleClose()
      loadData()
      showNotification('Catalogue', 'success')
    }

    setIsBusy(false)
  }
  return (
    <>
      <ModalTemplate
        modalTitle={localize('catalogue')}
        modalMode={modalMode}
        handleAction={handleInvalidateCatalogue}
        handleClose={handleClose}
        modalClassName="modal-xl"
        disableClose={isBusy}
        disableCreate={isBusy}
        footerChildren={
          <div className="col-md-4 offset-md-8">
            <div
              className="d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['WithdrawalNotification.Remarks']
                  ?.message
              }
            >
              <label className="col-4 text-capitalize">
                {localize('invalidationRemarks')}
              </label>

              <input
                disabled={isBusy}
                type="text"
                className="form-control"
                value={remarks}
                onChange={e => {
                  clearValidation('WithdrawalNotification.Remarks')
                  setRemarks(e.target.value)
                }}
              />
            </div>
          </div>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row g-3 mb-2">
              <div className="col d-flex align-items-center with-validation">
                <label className="col-4 text-capitalize">
                  {localize('customerName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerFullName}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('customerType')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    notification?.customerMembershipClassCustomerTypeDescription
                  }
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('membershipStatus')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerMembershipStatusDescription}
                />
              </div>
            </div>
            <div className="row g-3 mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('membershipWdr.Date')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerMembershipTerminationDate}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('domicileBranch')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerBranchDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('employerName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerEmployerDescription}
                />
              </div>
            </div>
            <div className="row g-3 mb-2">
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('dutyWorkStation')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerDutyStationDescription}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('adminDivision')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    notification?.customerAdministrativeDivisionDescription
                  }
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('identificationNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerIdentificationNumber}
                />
              </div>
            </div>
            <div className="row mb-2 g-3">
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('memberNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.formattedCustomerMemberNumber}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('accountNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.paddedCustomerAccountNumber}
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-4 text-capitalize">
                  {localize('personalFileNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={notification?.customerPersonalFileNumber}
                />
              </div>
            </div>
            <div className="row g-3">
              <div className="col-md-4">
                <div className=" d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('transactionReference')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.transactionReferenceDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className=" d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('terminationCategory')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.categoryDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('terminationRemarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.remarks}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('createdBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.createdBy}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('createdDate')}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={formatDate(notification?.createdDate)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('status')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.statusDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('status')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.statusDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('approvedBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.approvedBy}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('approvalDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formatDate(notification?.approvedDate)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('approvalRemarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.approvalRemarks}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('verifiedBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.auditedBy}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className=" d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('verifiedDate')}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={formatDate(notification?.auditedDate)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('verifiedRemarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.auditRemarks}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('settledBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.settledBy}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('settledDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formatDate(notification?.settledDate)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('settledRemarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.settlementRemarks}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('settlementType')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={notification?.settlementTypeDescription}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3">
                <Tab tabItems={tabItems} />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default InvalidateCatalogue
