/** @format */

import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { AuthorizedBranches } from './Partials/AuthorizedBranches'

export const ViewUserModal = ({
  selectedUser,
  waitingMetadata,
  isBusy,
}) => {
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('employee')}
              </label>
              <input
                className={'form-control'}
                value={selectedUser?.customerFullName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={selectedUser?.employeeBranchDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('designation')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeDesignationDescription}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('department')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeDepartmentDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('emailAddress')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedUser?.employeeCustomerAddressEmail}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('role')}
              </label>
              <input
                value={selectedUser?.roleDescription}
                disabled
                className="form-control"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('userName')}
              </label>
              <input
                className="form-control"
                value={selectedUser?.description}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('authorizedBranches'),
                    item: (
                      <AuthorizedBranches
                        selectedUser={selectedUser}
                        disabled
                        isBusy={waitingMetadata}
                        prefix={'view-user-authorized-branches'}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
