/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import Pager from '../../../../../Components/Tables/Pager'
import DatePicker from '../../../../../Components/Date/DatePicker'
import { directDebitRequestFilter } from '../../../../../Global/enumeration'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import ViewLogs from './ViewLogs'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'

function Logs() {
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [selectedLog, setSelectedLog] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    filterText: '',
    directDebitRequestLogFilter: Object.keys(directDebitRequestFilter)[22],
    pageIndex: 0,
    pageSize: 10,
    customDate: true,
    dateRequestsFilter: 15,
  })

  const columns = [
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.customerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerAccountCustomerAccountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.customerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('mISAccount#'),
      template: r => <>{r?.misAccountNumber}</>,
    },
    {
      label: localize('mISAccountDetails'),
      template: r => <>{r?.misAccountDetails}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.status}</>,
    },
    {
      label: localize('loanRef'),
      template: r => <>{r?.loanRef}</>,
    },
    {
      label: localize('payerName'),
      template: r => <>{r?.payerName}</>,
    },
    {
      label: localize('payerBankCode'),
      template: r => <>{r?.payerBankCode}</>,
    },
    {
      label: localize('payerBranchCode'),
      template: r => <>{r?.payerBranchCode}</>,
    },
    {
      label: localize('payerAccountNo'),
      template: r => <>{r?.payerAccountNo}</>,
    },
    {
      label: localize('payerMobile#'),
      template: r => <>{r?.payerMobileNumber}</>,
    },
    {
      label: localize('originatorBankCode'),
      template: r => <>{r?.originatorBankCode}</>,
    },
    {
      label: localize('originatorBranchCode'),
      template: r => <>{r?.originatorBranchCode}</>,
    },
    {
      label: localize('originatorAccount'),
      template: r => <>{r?.originatorAccount}</>,
    },
    {
      label: localize('originatorCode'),
      template: r => <>{r?.originatorCode}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r?.startDate)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r?.endDate)}</>,
    },
    {
      label: localize('dueDate'),
      template: r => <>{formatDate(r?.dueDate)}</>,
    },
    {
      label: localize('dateOfEntry'),
      template: r => <>{r?.dateOfEntry}</>,
    },
    {
      label: localize('cancelledBy'),
      template: r => <>{r?.cancelledBy}</>,
    },
    {
      label: localize('dateCancelled'),
      template: r => <>{r?.dateCancelled}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    {
      label: localize('collectionType'),
      template: r => <>{r?.collectionType}</>,
    },
    {
      label: localize('transactionId'),
      template: r => <>{r?.transactionId}</>,
    },
    {
      label: localize('isCancelled'),
      template: r => <>{r?.isCancelled.toString()}</>,
    },
    {
      label: localize('frequency'),
      template: r => <>{r?.frequency}</>,
    },
    {
      label: localize('inputter'),
      template: r => <>{r?.inputter}</>,
    },
    {
      label: localize('verifiedBy'),
      template: r => <>{r?.verifiedBy}</>,
    },
    {
      label: localize('policy1'),
      template: r => <>{r?.policy1}</>,
    },
    {
      label: localize('policy2'),
      template: r => <>{r?.policy2}</>,
    },
    {
      label: localize('reasonCancelled'),
      template: r => <>{r?.reasonCancelled}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{r?.modifiedDate}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const fetchLogsData = async () => {
    setIsBusy(true)
    const url =
      '/DirectDebitRequestLog/find-direct-debits-logs-by-filter-in-page'

    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  const handleOpenModal = () => {
    setModalMode('view')
  }

  const handleCloseModal = () => {
    setModalMode(null)
  }

  useEffect(() => {
    fetchLogsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('directDebitRequests'),
          localize('directDebitRequestsLogs'),
        ]}
      />
      {modalMode === 'view' && (
        <ViewLogs
          handleAction={handleCloseModal}
          handleClose={handleCloseModal}
          mode={modalMode}
          logs={selectedLog}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row d-flex justify-content-between mb-4">
                <RecordsPerPage
                  className="col-3"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center col">
                  <label
                    className="text-capitalize"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {localize('dateRange')}
                  </label>
                  <div className="col-6">
                    <DatePicker
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                          })
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col d-flex align-items-center space-between">
                  <label className="text-capitalize" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    className="ms-2"
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="DirectDebitRequestFilter"
                    value={reqParams.directDebitRequestFilter}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        directDebitRequestFilter: e.value,
                      })
                    }
                    sort
                  />
                  <form
                    className="d-flex align-items-center col-4"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => setSearch(e.target.value)}
                    ></input>
                  </form>
                  <button
                    id="FilterButton"
                    type="button"
                    className="btn btn-primary ms-2"
                    onClick={() => {
                      fetchLogsData()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <SimpleTable
                  columns={columns}
                  data={data?.pageCollection}
                  contextMenu={r => (
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        handleOpenModal()
                        setSelectedLog(r)
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      {localize('view')}
                    </div>
                  )}
                />
              </div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Logs
