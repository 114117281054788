/** @format */

/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'

import './cent-select.scss'
import { getData } from '../../Helpers/webApi'
import { MemoContext } from '../../Contexts/MemoContext'

function EnumsServerCombo({
  enumsKey,
  value,
  onChange,
  customContainerStyle = {},
  sort,
  disabled,
  className,
}) {
  const [loading, setLoading] = useState(false)

  const { enumsCache, setEnumsCache } = useContext(MemoContext)

  async function getEnums() {
    const cached = enumsCache[enumsKey]

    if (cached) {
      setEnums(cached)
    } else {
      setLoading(true)
      const { success, data } = await getData(
        '/utility/find-enumerations-by-key',
        { key: enumsKey },
        false
      )
      if (success) {
        const mapped = data.map(({ value, description }) => ({
          label: description,
          value,
        }))
        setEnumsCache(e => ({ ...e, [enumsKey]: mapped }))
        setEnums(mapped)
      }
      setLoading(false)
    }
  }

  const [enums, setEnums] = useState([])
  const [val, setVal] = useState(null)

  useEffect(() => {
    getEnums()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    let enumm = [...enums].find(e => e.value == value)

    setVal(enumm)
    // eslint-disable-next-line
  }, [value, enums])

  return loading ? (
    <div>...</div>
  ) : (
    <div
      style={{
        minWidth: 'fit-content',
        ...customContainerStyle,
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? 0.6 : 1,
      }}
    >
      <Select
        options={
          sort
            ? enums.sort((a, b) =>
                sort === 'desc'
                  ? b.label.localeCompare(b.label)
                  : a.label.localeCompare(b.label)
              )
            : enums
        }
        value={val}
        style={{ position: 'relative', maxWidth: 20 }}
        onChange={onChange}
        menuPlacement="auto"
        menuPosition="fixed"
        className={`cent-select ${className}`}
        disabled={disabled}
      />
    </div>
  )
}

export default EnumsServerCombo
