/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import { getData } from '../../../../../Helpers/webApi'
import Pager from '../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

const AgencyAndWebPortalRequest = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [requestLogs, setRequestLogs] = useState(null)
  const [error, setError] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})
  const [groupBy, setGroupBy] = useState({
    label: 'Status',
    value: 2,
    column: 'statusDescription',
  })
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    dateRequestsFilter: 15,
    customDate: false,
  })

  const fetchRequestLog = async () => {
    setIsBusy(true)
    const url = '/BrokerRequest/find-by-date-range-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setRequestLogs(data)
    } else {
      setError(true)
      setIsBusy(false)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchRequestLog()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('transactionType'),
      template: r => <>{r?.transactionTypeDescription}</>,
    },
    {
      label: localize('posTerminal'),
      template: r => <>{r?.posTerminal}</>,
    },
    {
      label: localize('posTerminalMSISDN'),
      template: r => <>{r?.posTerminalMSISDN}</>,
    },
    {
      label: localize('cardNumber'),
      template: r => <>{r?.cardNumber}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r?.transactionCode}</>,
    },
    {
      label: localize('transactionAmount'),
      template: r => <>{formatCurrency(r?.transactionAmount)}</>,
    },
    {
      label: localize('customer'),
      template: r => <>{r?.customer}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.serialNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.memberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.accountNumber}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.emailAddress}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.mobileLine}</>,
    },
    {
      label: localize('duty/Workstation'),
      template: r => <>{r?.dutystation}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r?.employer}</>,
    },
    {
      label: localize('ipnEnabled') + '?',
      template: r => (
        <span className="text-capitalize">{r?.ipnEnabled.toString()}</span>
      ),
    },
    {
      label: localize('ipnStatus'),
      template: r => <>{r?.ipnStatusDescription}</>,
    },
    {
      label: localize('ipnResponse'),
      template: r => <>{r?.ipnResponse}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'transactionTypeDescription' },
    { value: 1, column: 'cardNumber' },
    { value: 2, column: 'statusDescription' },
    { value: 3, column: 'posTerminal' },
  ]

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalMode('auditLogs')
  }

  const handleCloseModal = () => {
    setModalMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('channelRequestLog'),
          localize('agency/webPortalRequests'),
        ]}
      />
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={modalMode}
          data={auditLogsDetails}
          title={auditLogsDetails?.transactionCode}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : error ? (
            <div className="col-12 d-flex align-items-center justify-content-center text-danger">
              <i className="uil uil-exclamation-circle m-2 fs-4"></i>
              <div className="fs-5 text-danger">Failed to Load Data</div>
            </div>
          ) : (
            <>
              <div className=" d-flex row align-items-center mb-3">
                <RecordsPerPage
                  className="col-2"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex col-3 align-items-center">
                  <label className="text-capitalize me-2">
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    enumsKey="BrokerRequestPropertyGroup"
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: 'fit-content',
                    }}
                    value={groupBy?.value}
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                    sort
                  />
                </div>
                <div className="d-flex col-3 align-items-center">
                  <label htmlFor="search" className="me-1 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
                <div className="d-flex col-4 align-items-center justify-content-end ">
                  <label className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      maxWidth: 'fit-content',
                      minWidth: '150px',
                    }}
                    value={reqParams?.brokerTransactionRequestFilter}
                    enumsKey="BrokerTransactionRequestFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        brokerTransactionRequestFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control ms-2"
                    style={{ width: '100px' }}
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchRequestLog()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary ms-1"
                    onClick={() => {
                      fetchRequestLog()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>

              <GroupedTable
                columns={columns}
                data={requestLogs?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleViewAuditLogs(r)
                      }}
                    >
                      <i className="uil uil-server"></i>
                      <span>{localize('viewAuditLog')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={requestLogs?.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AgencyAndWebPortalRequest
