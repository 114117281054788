/** @format */
import localize from '../../../../../Global/Localize'
import { useState } from 'react'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import { MaskPercentage } from '../../../../../Components/InputMasks/MaskPercentage'
import { ChargeSplitEntries } from './ChargeSplitEntries'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
export const AddChargeSplit = ({ selectedCharge, setSelectedCharge, mode }) => {
  const [chargeSplitModel, setChargeSplitModel] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const handleAddChargeSplit = async () => {
    setIsBusy(true)

    const { success, data } = await postData(
      '/Commission/add-commission-split-entry',
      {
        commissionSplit: chargeSplitModel,
        chartOfAccount: chargeSplitModel?.chartOfAccount,
        commissionSplits: selectedCharge?.commissionSplits || [],
      },
      false,
    )
    if (success) {
      setSelectedCharge({
        ...selectedCharge,
        commissionSplits: data?.commissionSplits,
      })
      setChargeSplitModel(null)
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('chargeDetails')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize me-3">
              {localize('glAccount')}
            </label>
            <ChartOfAccountsLookup
              displayValue={chargeSplitModel?.chartOfAccount?.name}
              onSelect={e => {
                setChargeSplitModel({
                  ...chargeSplitModel,
                  chartOfAccount: { ...e },
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize me-3">
              {localize('name')}
            </label>
            <input
              className="form-control"
              value={chargeSplitModel?.description || ''}
              onChange={e => {
                setChargeSplitModel({
                  ...chargeSplitModel,
                  description: e.target.value,
                })
              }}
            />
          </div>
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={
              validationBag?.['CommissionSplit.Percentage']?.message
            }
          >
            <label htmlFor="" className="text-capitalize me-3">
              {localize('percentage')}
            </label>
            <MaskPercentage
              defaultMaskValue={chargeSplitModel?.percentage || ''}
              onMaskChange={e => {
                clearValidation('CommissionSplit.Percentage')
                setChargeSplitModel({
                  ...chargeSplitModel,
                  percentage: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="d-flex align-content-end justify-content-end">
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'is-charge-leviable'}
              checked={chargeSplitModel?.leviable}
              onCheck={v => {
                setChargeSplitModel({ ...chargeSplitModel, leviable: v })
              }}
            />
            <label
              htmlFor="is-charge-leviable"
              className="text-capitalize ms-2"
            >
              {localize('leviable')}?
            </label>
          </div>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <div
            className="bg-light d-flex justify-content-end align-items-center p-2 mt-2"
            style={{ borderRadius: 5 }}
          >
            <button
              onClick={handleAddChargeSplit}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('add')}
            </button>
          </div>
        )}

        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 120,
              marginBottom: -10,
            }}
          >
            {localize('chargeSplitting')}
          </div>
          <ChargeSplitEntries
            mode={mode}
            setSelectedCharge={setSelectedCharge}
            selectedCharge={selectedCharge}
          />
        </div>
      </div>
    </>
  )
}
