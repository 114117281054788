/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import localize from '../../../Global/Localize'
import { getData } from '../../../Helpers/webApi'
import { useDebounce } from '../../../Hooks/useDebounce'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import Loader from '../../Loaders/Loader'
import CustomersLookup from '../../Lookup/CustomersLookup'
import { formatDate } from '../../../Helpers/formatters'
import NonBlockingLoader from '../../Loaders/NonBlockingLoader'

export const CustomerPartial = ({
  onCustomerLookup,
  customer,
  message,
  mode,
  setCustomRemarks,
  customRemarks,
  remarksVisible,
  refreshFromServer,
  displayExtraRow,
  renderConditionalField,
}) => {
  const [customerData, setCustomerData] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyLoadingCustomers, setIsBusyLoadingCustomers] = useState(false)
  const [customers, setCustomers] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 50,
    customerFilter: 0,
    filterText: '',
  })

  const value = useDebounce(reqParams.filterText, 1000)

  const onChange = e => {
    onCustomerLookup(e.value)
    setCustomers([])
  }

  const fetchCustomers = async () => {
    if (!reqParams.filterText) return setCustomers([])
    setIsBusyLoadingCustomers(true)

    const url = '/lookup/customer/find-customers-by-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
      },
      false
    )

    if (success) {
      setCustomers(
        data?.pageCollection?.map(d => ({
          label: `${d?.fullName} - ${d?.memberNumber}`,
          value: d,
        }))
      )
    }

    setIsBusyLoadingCustomers(false)
  }

  const fetchCustomer = async () => {
    setIsBusy(true)
    const url = '/Customer/find-customer-by-id'

    const { success, data } = await getData(
      url,
      {
        customerId: customer?.id,
      },
      false
    )

    if (success) {
      setCustomerData(data?.customer)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (refreshFromServer && customer?.id) {
      fetchCustomer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFromServer, customer?.id])

  useEffect(() => {
    if (value) fetchCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className="relative">
      <div className="row mb-2">
        {mode === 'edit' ? (
          <>
            <div className="col d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={customerData?.fullName || customer?.fullName || ''}
              />
            </div>
          </>
        ) : (
          <div
            validation-message={message}
            className="col-3 d-flex align-items-center with-validation"
          >
            <label className="col-4 text-capitalize">
              {localize('customerName')}
            </label>
            <CustomersLookup
              displayValue={customer?.fullName || customerData?.fullName || ''}
              onSelect={r => {
                onCustomerLookup(r)
              }}
            />
          </div>
        )}
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={
              customerData?.membershipClassCustomerTypeDescription ||
              customer?.membershipClassCustomerTypeDescription ||
              ''
            }
          />
        </div>

        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('membershipStatus')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={
              customerData?.membershipStatusDescription ||
              customer?.membershipStatusDescription ||
              ''
            }
          />
        </div>
        {renderConditionalField ? (
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('membershipWithdrawalDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(customerData?.membershipTerminationDate) ||
                formatDate(customer?.membershipTerminationDate) ||
                ''
              }
            />
          </div>
        ) : (
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerSerial#')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customerData?.paddedSerialNumber ||
                customer?.paddedSerialNumber ||
                ''
              }
            />
          </div>
        )}
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('identification#')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={
              customerData?.identificationNumber ||
              customer?.identificationNumber ||
              ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customerData?.reference2 || customer?.reference2 || ''}
          />
        </div>

        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customerData?.reference1 || customer?.reference1 || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={customerData?.reference3 || customer?.reference3 || ''}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-4">
            {localize('domicileBranch')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={
              customerData?.branchDescription ||
              customer?.branchDescription ||
              ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-4">
            {localize('employerName')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={
              customerData?.employerDescription ||
              customer?.employerDescription ||
              ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-4">
            {localize('dutyWorkStation')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={
              customerData?.dutyStationDescription ||
              customer?.dutyStationDescription ||
              ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="text-capitalize col-4">
            {localize('adminDivision')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={
              customerData?.administrativeDivisionDescription ||
              customer?.administrativeDivisionDescription ||
              ''
            }
          />
        </div>
      </div>
      {mode !== 'edit' && !displayExtraRow && (
        <div className="row mb-2 mt-1">
          <div className="col-md-3 d-flex align-items-center">
            <label className="text-capitalize col-4">
              {localize('filterCriteria')}
            </label>
            <EnumsServerCombo
              enumsKey="CustomerAutoCompleteFilter"
              value={reqParams.customerFilter}
              sort
              customContainerStyle={{
                width: '100%',
              }}
              onChange={e => {
                setReqParams({
                  ...reqParams,
                  customerFilter: e.value,
                })
              }}
            />
          </div>
          <Select
            options={customers}
            style={{ position: 'relative', minWidth: '100%' }}
            onChange={onChange}
            className={`text-capitalize col-md-9`}
            onInputChange={e => {
              setReqParams({
                ...reqParams,
                filterText: e,
              })
            }}
            isLoading={isBusyLoadingCustomers}
            loadingMessage={() => 'Loading...'}
            placeholder={localize('search')}
            noOptionsMessage={() => 'Type to search...'}
            value={reqParams.filterText ? null : ''}
          />
        </div>
      )}
      <div className="row mb-2">
        {remarksVisible && (
          <>
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="col-1  text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  value={customRemarks?.remarks || ''}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    setCustomRemarks({
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}
