/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { generateGuid } from '../../../../../Helpers/extensions'
import Swal from 'sweetalert2'
import Loader from '../../../../../Components/Loaders/Loader'

function Cycles({ disabled, loanProduct }) {
  const [isBusy, setIsBusy] = useState(false)
  const [cycles, setCycles] = useState([])
  const [error, setError] = useState(false)

  const [cycle, setCycle] = useState({
    range: 0,
    multiplierFactor: 0,
    maximumTermInMonths: 0,
  })

  useEffect(() => {
    loadCycles()
    // eslint-disable-next-line
  }, [loanProduct])

  async function loadCycles() {
    setIsBusy(true)
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-loan-cycles-by-loan-product-id',
      { loanProductId: loanProduct?.id },
      false
    )
    if (success) {
      setIsBusy(false)
      setCycles(data?.result)
    } else {
      setError(true)
    }
    setIsBusy(false)
  }

  async function updateCycles() {
    setIsBusy(true)
    const { success } = await putData(
      `/loanProduct/update-loan-cycles-by-loan-product-id?loanProductId=${loanProduct?.id}`,
      cycles,
      false,
      'Loan Cycles Updated!'
    )
    if (success) {
      loadCycles()
    }
    setIsBusy(false)
  }

  function addCycle() {
    let lowerLimit = 0
    if (cycles.length > 0) {
      lowerLimit = Number(cycles[cycles.length - 1].rangeUpperLimit) + 1.99
    }
    const newCycle = {
      id: generateGuid(),
      rangeLowerLimit: lowerLimit,
      rangeUpperLimit: cycle.range,
      multiplierFactor: cycle.multiplierFactor,
      maximumTermInMonths: cycle.maximumTermInMonths,
    }

    if (lowerLimit > cycle.range) {
      alert('Lower limit should be less than upper limit')
      return
    }

    let cs = [...cycles]
    cs.push(newCycle)
    setCycles(cs)
    setCycle({
      range: 0,
      multiplierFactor: 0,
      maximumTermInMonths: 0,
    })
  }

  async function removeRange(r) {
    let cycleLength = cycles.length
    let currentIndex = cycles.findIndex(c => c.id === r.id)
    if (currentIndex < cycleLength - 1) {
      let res = await Swal.fire({
        title: 'Confirm',
        text:
          'Are you sure you want to remove this range? This will also remove the ' +
          (cycleLength - 1 - currentIndex) +
          ' ranges(s) after it to maintain a valid sequence.',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
      })
      if (res.isConfirmed) {
        let fs = [...cycles].slice(0, currentIndex)
        setCycles(fs)
      }
    } else {
      let cs = [...cycles]
      cs.splice(currentIndex, 1)
      setCycles(cs)
    }
  }

  const columns = [
    {
      label: `${localize('range')} (${localize('lowerLimit')})`,
      template: r => <>{formatCurrency(r.rangeLowerLimit)}</>,
    },
    {
      label: `${localize('range')} (${localize('upperLimit')})`,
      template: r => <>{formatCurrency(r.rangeUpperLimit)}</>,
    },
    {
      label: `${localize('maximumTerm')} (${localize('months')})`,
      template: r => <>{r.maximumTermInMonths}</>,
    },
    {
      label: `${localize('multiplierFactor')}`,
      template: r => <>{r.multiplierFactor}</>,
    },
    { label: `${localize('modifiedBy')}`, template: r => <>{r.modifiedBy}</> },
    {
      label: `${localize('modifiedDate')}`,
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: `${localize('createdBy')}`, template: r => <>{r.createdBy}</> },
    {
      label: `${localize('createdDate')}`,
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return disabled ? (
    <>
      <SimpleTable columns={columns} data={cycles} loading={isBusy} />
    </>
  ) : (
    <div className="p-2">
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-1">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize me-2">
                {localize('range')} ({localize('lowerLimit')})
              </label>
              <input
                type="number"
                value={cycle?.range}
                onChange={e => setCycle({ ...cycle, range: e.target.value })}
                className="form-control"
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize me-2">
                {localize('maximumTerm')} ({localize('months')})
              </label>
              <input
                type="number"
                value={cycle?.maximumTermInMonths}
                onChange={e =>
                  setCycle({ ...cycle, maximumTermInMonths: e.target.value })
                }
                className="form-control"
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize me-2">
                {localize('multiplierFactor')}
              </label>
              <input
                type="number"
                value={cycle?.multiplierFactor}
                onChange={e =>
                  setCycle({ ...cycle, multiplierFactor: e.target.value })
                }
                className="form-control"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end p-1 mb-2">
            <button
              className="btn btn-success text-uppercase px-4"
              onClick={addCycle}
            >
              {localize('add')}
            </button>
          </div>
          <div className="row">
            <SimpleTable
              columns={columns}
              data={cycles}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-danger"
                    onClick={() => removeRange(r)}
                  >
                    <i className="uil uil-times-circle"></i>
                    <span className="text-capitalize">
                      {localize('remove')}
                    </span>
                  </div>
                </>
              )}
            />
          </div>
          <div className="d-flex align-items-center justify-content-end p-2 mt-2 bg-light">
            <button onClick={updateCycles} className="btn btn-success px-5">
              {localize('update')}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default Cycles
