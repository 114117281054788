/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import CreateTransactionType from './CreateTransactionType/CreateTransactionType'

function TransactionTypes() {
  const [refresh, setRefresh] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [transactionType, setTransactionType] = useState({})

  const columns = [
    {
      label: 'name',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'funeralHome',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'gLAccountName',
      template: r => <>{r?.chartOfAccountAccountName}</>,
    },
    {
      label: 'gLAccountCostDistributionRule',
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: 'modifiedBy',
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleCancel = () => {
    setModalMode(null)
    setTransactionType({})
  }

  return (
    <>
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateTransactionType
          handleClose={handleCancel}
          mode={modalMode}
          refresh={refresh}
          setRefresh={setRefresh}
          setTransactionType={setTransactionType}
          transactionType={transactionType}
        />
      )}

      <ListingPage
        columns={columns}
        url="/MorgueTransactionType/find-morgue-transaction-types-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        map={[localize('morgueManagement'), localize('transactionTypes')]}
        onAdd={() => setModalMode('add')}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                setTransactionType(r)
                setModalMode('edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
          </>
        )}
        refreshAt={refresh}
      />
    </>
  )
}

export default TransactionTypes
