/** @format */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import Commissions from '../../../../Components/Selectables/Plain/Commissions'
import {
  entitiesToBeChecked,
  getCommissions,
} from '../../../../Helpers/selectablesHelper'
import {
  AlternateChannelKnownChargeType,
  AlternateChannelTypeFilter,
  chargeBenefactor,
  CoopBankAlternateChannelKnownChargeType,
  PesaPepeAlternateChannelKnownChargeType,
} from '../../../../Global/enumeration'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import { getData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'

function ChargeDeterminationAlternateChannels() {
  const [isBusy, setIsBusy] = useState(false)
  const [commissions, setCommissions] = useState([])
  const [loadingCommissions, setLoadingCommissions] = useState(false)
  const [modalWaiting, setModalWaiting] = useState(false)
  const [selectedCommissions, setSelectedCommissions] = useState([])

  const [selectedAlternateChannelType, setSelectedAlternateChannelType] =
    useState('64')
  const [chargeBorneBy, setChargeBorneBy] = useState('0')

  const [
    selectedAlternateChannelKnownChargeType,
    setSelectedAlternateChannelKnownChargeType,
  ] = useState('0')
  const [alternateChannelKnownChargeType, setAlternateChannelKnownChargeType] =
    useState(PesaPepeAlternateChannelKnownChargeType)

  useEffect(() => {
    retrieveApplicableCharges(
      selectedAlternateChannelType,
      selectedAlternateChannelKnownChargeType
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const overrideAlternateChannelKnownChargeType = alternateChannelType => {
    switch (alternateChannelType) {
      case '1':
        setAlternateChannelKnownChargeType(
          CoopBankAlternateChannelKnownChargeType
        )
        setSelectedAlternateChannelKnownChargeType('10')
        break
      case '64':
        setAlternateChannelKnownChargeType(
          PesaPepeAlternateChannelKnownChargeType
        )
        setSelectedAlternateChannelKnownChargeType('0')
        break
      default:
        setAlternateChannelKnownChargeType(AlternateChannelKnownChargeType)
        setSelectedAlternateChannelKnownChargeType('3')
        break
    }
  }
  async function handleSubmit(finalData) {
    setIsBusy(true)
    setModalWaiting(true)
    const { success, data } = await putData(
      '/AlternateChannel/update-commissions-by-alternate-channel-type',
      finalData,
      false
    )

    if (success) {
      setIsBusy(false)
      setModalWaiting(false)
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Edit Alternate Channels',
        showCloseButton: true,
      })
    } else {
      setIsBusy(false)
      setModalWaiting(false)
      Swal.fire({
        icon: 'error',
        text: data.reason,
        title: 'Edit Alternate Channels',
        showCloseButton: true,
      })
    }
  }

  const retrieveApplicableCharges = async (
    alternateChannelType,
    alternateChannelKnownChargeType
  ) => {
    setIsBusy(true)
    setLoadingCommissions(true)
    const { success, data } = await getData(
      '/AlternateChannel/find-commission-by-alternate-channel-type',
      {
        alternateChannelType: alternateChannelType,
        alternateChannelKnownChargeType: alternateChannelKnownChargeType,
      },
      false
    )
    const commissions = await getCommissions()
    if (success) {
      setIsBusy(false)
      setCommissions(entitiesToBeChecked(commissions, data.result))
    }
    setLoadingCommissions(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('chargesDetermination'),
          localize('alternateChannelCharges'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-auto me-2 text-capitalize">
                    {localize('alternateChannelType')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="AlternateChannelTypeFilter"
                    onChange={async event => {
                      setSelectedAlternateChannelType(event.value)
                      overrideAlternateChannelKnownChargeType(event.value)
                      await retrieveApplicableCharges(
                        event.value,
                        selectedAlternateChannelKnownChargeType
                      )
                    }}
                    value={selectedAlternateChannelType}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-auto me-2 text-capitalize">
                    {localize('chargeType')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="AlternateChannelKnownChargeType"
                    onChange={async c => {
                      setSelectedAlternateChannelKnownChargeType(c.value)
                      await retrieveApplicableCharges(
                        selectedAlternateChannelType,
                        c.value
                      )
                    }}
                    value={selectedAlternateChannelKnownChargeType}
                    sort
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-auto text-capitalize me-2">
                    {localize('chargeBorneBy')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ChargeBenefactor"
                    value={chargeBorneBy}
                    onChange={c => {
                      setChargeBorneBy(c.value)
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <Tab
                  tabItems={[
                    {
                      label: localize('applicableCharges'),
                      item: (
                        <Commissions
                          prefix={'list'}
                          onCheckCommissions={c => {
                            setSelectedCommissions(c.filter(x => x.checked))
                            setCommissions(c)
                          }}
                          isBusy={loadingCommissions}
                          commissionsToBeSelected={commissions}
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <div className="d-flex justify-content-end mt-3">
                <button
                  onClick={async () => {
                    await handleSubmit({
                      AlternateChannelType: selectedAlternateChannelType,
                      Commissions: selectedCommissions,
                      KnownChargeType: selectedAlternateChannelKnownChargeType,
                      ChargeBenefactor: chargeBorneBy,
                    })
                  }}
                  className={`btn btn-success text-uppercase px-4 ${
                    modalWaiting ? 'disabled' : ''
                  }`}
                  type="button"
                >
                  {modalWaiting ? (
                    <i className="m-1 spinner-border spinner-sm text-light"></i>
                  ) : (
                    localize('update')
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChargeDeterminationAlternateChannels
