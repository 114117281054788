/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import FuneralHomeLookup from '../../../../../Components/Lookup/FuneralHomeLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const CreateTransactionType = ({
  mode,
  handleClose,
  refresh,
  setRefresh,
  transactionType,
  setTransactionType,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleCloseModal = () => {
    handleClose()
    setGlobalValidationErrors({})
  }

  const handleSelectFuneralHome = e => {
    if (e?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected Funeral Home is locked'
      )

    clearValidation('FuneralHomeDescription')
    setTransactionType({
      ...transactionType,
      funeralHomeId: e.id,
      funeralHomeDescription: e.description,
    })
  }

  const handleAddOrUpdate = async () => {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/MorgueTransactionType/add-morgue-transaction-type'
        : '/MorgueTransactionType/update-morgue-transaction-type'
    const message =
      mode === 'add' ? 'Create Transaction Types' : 'Edit Transaction Types'

    const { success } = await postData(url, transactionType, false)

    if (success) {
      setRefresh(!refresh)
      handleClose()
      showNotification(message, 'success')
      setGlobalValidationErrors({})
    }

    setIsBusy(false)
  }

  useEffect(() => {
    setGlobalValidationErrors({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleCloseModal}
      handleAction={handleAddOrUpdate}
      disableClose={isBusy}
      disableCreate={isBusy}
      modalTitle="transactionType"
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            id="isLockedTransactionTypeInMorgue"
            checked={transactionType?.isLocked}
            onCheck={e => {
              setTransactionType({
                ...transactionType,
                isLocked: e,
              })
            }}
          />
          <label
            className="ms-2 me-4 text-capitalize"
            htmlFor="isLockedTransactionTypeInMorgue"
          >
            {localize('isLocked')} ?
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-6">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['FuneralHomeDescription']?.message
                }
              >
                <label
                  htmlFor="funeralHome"
                  className="text-capitalize col-3 me-2"
                >
                  {localize('funeralHome')}
                </label>

                <FuneralHomeLookup
                  displayValue={transactionType?.funeralHomeDescription || ''}
                  onSelect={handleSelectFuneralHome}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['ChartOfAccountName']?.message
                }
              >
                <label htmlFor="G/LAccount" className="text-capitalize col-3">
                  {localize('g/LAccount')}
                </label>

                <ChartOfAccountsLookup
                  displayValue={
                    transactionType?.chartOfAccountAccountName || ''
                  }
                  onSelect={e => {
                    clearValidation('ChartOfAccountName')
                    setTransactionType({
                      ...transactionType,
                      chartOfAccountId: e.id,
                      chartOfAccountAccountName: e.description,
                      chartOfAccountName: e.description,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-3 me-2">
                  {localize('name')}
                </label>
                <input
                  className="form-control"
                  value={transactionType?.description}
                  onChange={e => {
                    clearValidation('Description')
                    setTransactionType({
                      ...transactionType,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateTransactionType
