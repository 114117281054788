/** @format */
import localize from '../../../../../../../../../Global/Localize'
import CustomDatePicker from '../../../../../../../../../Components/Date/custom-date-picker'
import BankBranchLookup from '../../../../../../../../../Components/Lookup/BankBranchLookup'
import { ChequeTypeLookup } from '../../../../../../../../../Components/Lookup/ChequeTypeLookup'
import BankLookup from '../../../../../../../../../Components/Lookup/BankLookup'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { postData } from '../../../../../../../../../Helpers/webApi'
import { formatDate } from '../../../../../../../../../Helpers/formatters'
import { MaskNumber } from '../../../../../../../../../Components/InputMasks/MaskNumber'
import { GlobalValidationErrors } from '../../../../../../../../../Validation/ValidationState'

export const CustomerAccountTransactionChequeDeposit = ({
  transactionModel,
  setTransactionModel,
  selectedCustomerAccount,
  selectedTransactionType,
  onGetExternalChequePayables,
  handleOnChangeChequeDepositAmount,
}) => {
  const [selectedBank, setSelectedBank] = useState(null)
  const [maturityDate, setMaturityDate] = useState(null)
  const [loading, setLoading] = useState(false)

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = key => {
    setValidationBag(g => {
      delete g[key]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [selectedTransactionType])

  const handleOnSelectChequeType = async r => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Teller/fetch-cheque-payables',
      {
        CustomerAccount: { ...selectedCustomerAccount },
        ChequeType: { ...r },
      },
      false,
      null
    )
    if (success) {
      setMaturityDate(data?.externalCheque?.maturityDate)
      onGetExternalChequePayables(data?.externalChequePayables)
      setLoading(false)
    }
  }

  return (
    <>
      <div className="row mb-2">
        <div
          validation-message={
            validationBag?.['NewExternalCheque.Drawer']?.message
          }
          className="with-validation col d-flex align-items-center"
        >
          <label className="col-3 text-capitalize">{localize('drawer')}</label>
          <input
            className="form-control"
            defaultValue={transactionModel?.chequeDeposit?.drawer}
            onChange={e => {
              clearValidation('NewExternalCheque.Drawer')
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  drawer: e.target.value,
                },
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('writeDate')}
          </label>
          <CustomDatePicker
            onDateChange={e => {
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  writeDate: e,
                },
              })
            }}
            defaultDate={
              transactionModel?.chequeDeposit?.writeDate || new Date()
            }
            name={'writeDate'}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          validation-message={
            validationBag?.['NewExternalCheque.DrawerBank']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">{localize('bank')}</label>
          <BankLookup
            displayValue={selectedBank?.description}
            onSelect={b => {
              clearValidation('NewExternalCheque.DrawerBank')
              setSelectedBank({
                ...selectedBank,
                bankId: b.id,
                description: b.description,
              })
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  drawerBank: b.description,
                },
              })
            }}
          />
        </div>
        <div
          validation-message={
            validationBag?.['NewExternalCheque.DrawerBankBranch']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('bankBranch')}
          </label>
          <BankBranchLookup
            bankId={selectedBank?.bankId}
            bankDescription={selectedBank?.bankBranchBankDescription}
            displayValue={selectedBank?.bankBranchDescription}
            onSelect={b => {
              clearValidation('NewExternalCheque.DrawerBankBranch')
              setSelectedBank({
                ...selectedBank,
                bankBranchDescription: b.description,
                bankBranchId: b.id,
                bankBranchCode: b.code,
              })
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  drawerBankBranch: b.description,
                },
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          validation-message={
            validationBag?.['NewExternalCheque.ChequeTypeId']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('chequeType')}
          </label>
          <ChequeTypeLookup
            displayValue={transactionModel?.chequeDeposit?.description}
            onSelect={r => {
              clearValidation('NewExternalCheque.ChequeTypeId')
              handleOnSelectChequeType(r)
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  description: r.description,
                  chequeTypeId: r.id,
                },
                selectedChequeType: { ...r },
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          {loading ? (
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="m-3 spinner-border text-dark"></div>
              <span className="fs-4">Please Wait</span>
            </div>
          ) : (
            <>
              <label className="col-3 text-capitalize">
                {localize('maturityDate')}
              </label>
              <input
                className="form-control"
                disabled
                value={formatDate(maturityDate, true)}
              />
            </>
          )}
        </div>
      </div>
      <div className="row mb-2">
        <div
          validation-message={validationBag?.ChequeDepositAmount?.message}
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">{localize('amount')}</label>
          <MaskNumber
            onBlur={handleOnChangeChequeDepositAmount}
            defaultMaskValue={transactionModel?.chequeDeposit?.amount}
            onMaskNumber={e => {
              clearValidation('ChequeDepositAmount')
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  amount: e.target.value,
                },
              })
            }}
          />
        </div>
        <div
          validation-message={
            validationBag?.['NewExternalCheque.Number']?.message
          }
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('chequeNumber')}
          </label>
          <input
            className="form-control"
            defaultValue={transactionModel?.chequeDeposit?.number}
            onChange={e => {
              clearValidation('NewExternalCheque.Number')
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  number: e.target.value,
                },
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-1 me-5 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            defaultValue={transactionModel?.chequeDeposit?.reference}
            onChange={e => {
              setTransactionModel({
                ...transactionModel,
                chequeDeposit: {
                  ...transactionModel.chequeDeposit,
                  reference: e.target.value,
                },
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
