/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { postData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import Loader from '../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'

const AuthorizeCashDepositRequest = ({
  mode,
  handleClose,
  request,
  setRequest,
  fetchRequests,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  const handleAuthorize = async () => {
    if (!request?.authorizationRemarks)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please enter remarks',
      })

    setIsBusy(true)

    const url = `/CashDepositRequest/authorize-cash-deposit-request?customerTransactionAuthOption=${request?.customerTransactionAuthOption}`

    const { success } = await postData(
      url,
      {
        cashDepositRequest: request,
        customerTransactionAuthOption: request?.customerTransactionAuthOption,
      },
      false
    )

    if (success) {
      fetchRequests()
      handleClose()
      showNotification('Cash Deposit Request Authorization', 'success')
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <ModalTemplate
      modalTitle="cashDepositRequestAuthorization"
      handleAction={handleAuthorize}
      cancelText="close"
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
      disableCreate={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3 ms-2">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled={isBusy}
              value={request?.authorizationRemarks || ''}
              onChange={e => {
                setRequest({
                  ...request,
                  authorizationRemarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center gap-3 me-2">
            <label className="text-capitalize">{localize('authOption')}</label>
            <EnumsServerCombo
              sort
              customContainerStyle={{
                minWidth: 150,
              }}
              disabled={isBusy}
              value={request?.customerTransactionAuthOption}
              enumsKey="CustomerTransactionAuthOption"
              onChange={({ value }) =>
                setRequest({
                  ...request,
                  customerTransactionAuthOption: value,
                })
              }
            />
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('branch')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.branchDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('fullAccountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.customerAccountFullAccountNumber}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('customerName')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.customerAccountCustomerFullName}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('amount')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(request?.amount, null, null, false)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('createdBy')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.createdBy}
                disabled
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('status')}
              </label>
              <input
                type="text"
                className="form-control"
                value={request?.statusDescription}
                disabled
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-1">
                {localize('remarks')}
              </label>
              <textarea
                type="text"
                className="form-control"
                value={request?.remarks}
                disabled
              />
            </div>
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default AuthorizeCashDepositRequest
