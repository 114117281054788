/** @format */

import SimpleLookupWithFilters from './SimpleLookupWithFilters'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import React from 'react'

export const MicroCreditOfficerLookup = ({ displayValue, onSelect }) => {
  return (
    <>
      <SimpleLookupWithFilters
        paramKey={'employeeFilter'}
        enumKey={'EmployeeFilter'}
        title={localize('microCreditOfficerLookup')}
        url="/lookup/MicroCreditOfficer/find-micro-credit-officers-by-filter-in-page"
        params={{ text: '', pageSize: 10, pageIndex: 0 }}
        columns={[
          {
            label: localize('fullName'),
            template: r => <>{r.employeeCustomerFullName}</>,
          },
          {
            label: localize('nationality'),
            template: r => (
              <>{r.employeeCustomerIndividualNationalityDescription}</>
            ),
          },
          {
            label: localize('identityCardNumber'),
            template: r => (
              <>{r.employeeCustomerIndividualIdentityCardNumber}</>
            ),
          },
          {
            label: localize('gender'),
            template: r => <>{r.employeeCustomerIndividualGenderDescription}</>,
          },
          {
            label: localize(
              'maritalStatus',
            ),
            template: r => (
              <>{r.employeeCustomerIndividualMaritalStatusDescription}</>
            ),
          },
          {
            label: localize('serialNumber'),
            template: r => <>{r.PaddedEmployeeCustomerSerialNumber}</>,
          },
          {
            label: localize('designation'),
            template: r => <>{r.employeeDesignationDescription}</>,
          },
          {
            label: localize('branch'),
            template: r => <>{r.employeeBranchDescription}</>,
          },
          {
            label: localize('department'),
            template: r => <>{r.employeeDepartmentDescription}</>,
          },
          {
            label: localize('employeeType'),
            template: r => <>{r.employeeEmployeeTypeDescription}</>,
          },
          {
            label: localize('employmentStatus'),
            template: r => <>{r.employeeEmploymentStatusDescription}</>,
          },
          { label: localize('remarks'), template: r => <>{r.remarks}</> },
          {
            label: localize('isLocked'),
            template: r => (
              <span className="text-capitalize">{r.isLocked.toString()}</span>
            ),
          },
          { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
          {
            label: localize('createdDate'),
            template: r => <>{formatDate(r.CreatedDate)}</>,
          },
        ]}
        displayValue={displayValue}
        onSelect={onSelect}
        useText
      />
    </>
  )
}
