/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { reportSpoolDateFilter } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatDate, getTodaysDate } from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'
import CreateTemplateReport from './CreateTemplateReport/CreateTemplateReport'
import ViewTemplateBasedReport from './ViewTemplateBasedReport/ViewTemplateBasedReport'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'

const initialTemplate = {
  transactionDateFilter: Object.keys(reportSpoolDateFilter)[0],
  reportSpoolDateFilter: Object.keys(reportSpoolDateFilter)[0],
  dateFilter: Object.keys(reportSpoolDateFilter)[0],
  cutOffDate: getTodaysDate(),
  durationStartDate: getTodaysDate(),
  durationEndDate: getTodaysDate(),
}

function TemplateBasedReports() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(initialTemplate)
  const [templateReports, setTemplateReports] = useState([])
  const [isBusyCreatingOrUpdating, setIsBusyCreatingOrUpdating] =
    useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const columns = [
    {
      label: localize('batchNumber'),
      template: r => r?.paddedBatchNumber,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('dateFilter'),
      template: r => r?.dateFilterDescription,
    },
    {
      label: localize('cutOffDate'),
      template: r => formatDate(r?.cutOffDate, true),
    },
    {
      label: localize('durationStartDate'),
      template: r => formatDate(r?.durationStartDate, true),
    },
    {
      label: localize('durationEndDate'),
      template: r => formatDate(r?.durationEndDate, true),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchTemplateReports = async () => {
    setIsBusy(true)
    const url = '/ReportTemplate/find-report-spools-by-filter-in-page'
    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setTemplateReports(data)
    }

    setIsBusy(false)
  }

  const handleAddButtonClick = () => {
    setSelectedTemplate(initialTemplate)
    setModalMode('add')
  }

  const handleClose = () => {
    setModalMode(null)
    setSelectedTemplate(initialTemplate)
  }

  const handleViewMenuClick = r => {
    setModalMode('view')
    setSelectedTemplate(r)
  }

  const handleCreateReport = async () => {
    setIsBusyCreatingOrUpdating(true)
    const url = '/ReportTemplate/spool-report'

    const { success } = await putData(url, selectedTemplate, false)

    if (success) {
      handleClose()
      fetchTemplateReports()
      showNotification('Report Based Reports', 'success')
    }

    setIsBusyCreatingOrUpdating(false)
  }

  useEffect(() => {
    fetchTemplateReports()
    // eslint-disable-next-line
  }, [reqParams.pageIndex, reqParams.pageSize])

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalMode('auditLogs')
  }

  return (
    <>
      {modalMode === 'add' && (
        <CreateTemplateReport
          handleAction={handleCreateReport}
          handleClose={handleClose}
          mode={modalMode}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          isBusyCreatingOrUpdating={isBusyCreatingOrUpdating}
        />
      )}

      {modalMode === 'view' && (
        <ViewTemplateBasedReport
          handleClose={handleClose}
          mode={modalMode}
          selectedTemplate={selectedTemplate}
        />
      )}

      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={modalMode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedBatchNumber}
        />
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('transactionsJournal'),
          localize('templateBasedReports'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3">
                <div className="col">
                  <AddNewButton handleClickAddButton={handleAddButtonClick} />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-md-3 d-flex align-items-center">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <label htmlFor="search" className="col-3 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams.startDate,
                      endDate: reqParams.endDate,
                    }}
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end gap-2">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize"
                      htmlFor="search-template-based-reports"
                    >
                      {localize('search')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <input
                      type="search"
                      id="search-template-based-reports"
                      className="form-control ms-2"
                      value={reqParams.filterText}
                      onChange={e => {
                        if (e.target.value === '') {
                          fetchTemplateReports()
                        }

                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          fetchTemplateReports()
                        }
                      }}
                    />
                    <button
                      id="FilterButton"
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        fetchTemplateReports()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <SimpleTable
                  columns={columns}
                  data={templateReports?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewMenuClick(r)
                        }}
                      >
                        <i className="uil uil-eye"></i>
                        <span>{localize('view')}</span>
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(r)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
              </div>
              <Pager
                itemsCount={templateReports.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default TemplateBasedReports
