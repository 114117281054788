/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const PurchaseRequisitionEntries = ({ entries }) => {
  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    { label: localize('reason'), template: r => <>{r.reason}</> },
    { label: localize('quantity'), template: r => <>{r.quantity}</> },
  ]

  return (
    <>
      <SimpleTable columns={columns} data={entries} />
    </>
  )
}
