/** @format */
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { AddPettyCashierModal } from './AddPettyCashierModal'
import { postData } from '../../../../Helpers/webApi'
import { useEffect, useState } from 'react'
import swal from 'sweetalert2'
import { EditPettyCashierModal } from './EditPettyCashierModal'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Checkbox from '../../../../Components/Checkbox/Checkbox'

function PettyCashiersModal({
  mode,
  onHideModal,
  selectedPettyCashier,
  setSelectedPettyCashier,
  onUpdateRefreshAt,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addPettyCashier()
    }
    if (mode === 'edit') {
      return editPettyCashier()
    }
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  const addPettyCashier = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/PettyCashier/add-petty-cashier',
      { ...(selectedPettyCashier || {}) },
      false,
    )
    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      swal.fire(
        'Add Petty Cashier',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }
  const editPettyCashier = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/PettyCashier/update-petty-cashier',
      { ...(selectedPettyCashier || {}) },
      false,
    )

    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      swal.fire(
        'Edit Petty Cashier',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      {mode && (
        <ModalTemplate
          modalMode={mode}
          modalTitle={localize('pettyCashier')}
          handleAction={handleSubmit}
          handleClose={onHideModal}
          disableCreate={isBusy}
          modalClassName={'modal-xl'}
          footerChildren={
            <div className="d-flex align-content-end justify-content-end">
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id={'isLocked'}
                  checked={selectedPettyCashier?.isLocked}
                  onCheck={v =>
                    setSelectedPettyCashier({
                      ...selectedPettyCashier,
                      isLocked: v,
                    })
                  }
                />
                <label htmlFor="isLocked" className="text-capitalize ms-2">
                  {localize('isLocked')} ?
                </label>
              </div>
            </div>
          }
        >
          {mode === 'add' && (
            <AddPettyCashierModal
              isBusy={isBusy}
              selectedPettyCashier={selectedPettyCashier}
              setSelectedPettyCashier={setSelectedPettyCashier}
            />
          )}
          {mode === 'edit' && (
            <EditPettyCashierModal
              isBusy={isBusy}
              selectedPettyCashier={selectedPettyCashier}
              setSelectedPettyCashier={setSelectedPettyCashier}
            />
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default PettyCashiersModal
