/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import AccountInformation from './partials/AccountInformation'
import LendorSector from './partials/LendorSector'
import Guarantors from './partials/Guarantors'
import CreditInfo from './partials/CreditInfo'
import MetroScoreTrends from './partials/MetroScoreTrends'
import MonthlyScores from './partials/MonthlyScores'
import Stakeholders from './partials/Stakeholders'
import IdentityVerification from './partials/IdentityVerification'
import IdentityScrub from './partials/IdentityScrub'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Specimen from '../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
function ViewCreditInformationQueryModal({
  mode,
  selectedCreditInformationQuery,
  closeModal,
}) {
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 1,
    filterText: '',
    interAccountTransferBatchEntryFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('creditInformationQueries')}
        handleClose={closeModal}
        modalClassName="modal-xl"
        handleAction={() => {}}
        actionText={mode === 'view' ? 'refresh' : ''}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                id="creditInfoQueryIsGuarantor"
                checked={selectedCreditInformationQuery?.isGuarantor}
              />
              <label
                htmlFor="creditInfoQueryIsGuarantor"
                className="text-capitalize"
              >
                {localize('isGuarantor')}?
              </label>
              <Checkbox
                id="creditInfoQueryHasError"
                checked={selectedCreditInformationQuery?.hasError}
              />
              <label
                htmlFor="creditInfoQueryHasError"
                className="text-capitalize"
              >
                {localize('hasError')}?
              </label>

              <Checkbox
                id="creditInfoQueryHasFraud"
                checked={selectedCreditInformationQuery?.hasFraud}
              />
              <label
                htmlFor="creditInfoQueryHasFraud"
                className="text-capitalize"
              >
                {localize('hasFraud')}?
              </label>
            </div>
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('status')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.statusDescription || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('reportType')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.reportTypeDescription || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('reportReason')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.reportReasonDescription || ''
              }
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('identityType')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.identityTypeDescription || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('identityNumber')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.identityNumber || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('delinquencyCode')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.delinquencyCode || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.customerName || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('verifiedName')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.verifiedName || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('reportedName')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.reportedName || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('phone#')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.phoneNumber || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('daysInArrears')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.daysInArrears || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('highestDaysInArrears')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.highestDaysInArrears || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('lowestDaysInArrears')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.lowestDaysInArrears || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('maximumCreditScore')}
            </label>
            <MaskNumber
              defaultMaskValue={selectedCreditInformationQuery?.maxCreditScore}
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('minimumCreditScore')}
            </label>
            <MaskNumber
              defaultMaskValue={selectedCreditInformationQuery?.minCreditScore}
              className="form-control ms-2"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOutstandingAmount')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.totalOutstandingAmount
              }
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOutstandingNPA')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.totalOutstandingNPA
              }
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOutstandingNPAHistory')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.totalOutstandingNPAHistory
              }
              className="form-control ms-2"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOutstandingPerforming')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.totalOutstandingPerforming
              }
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOverdueAmount')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.totalOverdueAmount
              }
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOverdueNPA')}
            </label>
            <MaskNumber
              defaultMaskValue={selectedCreditInformationQuery?.totalOverdueNPA}
              className="form-control ms-2"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOverdueNPAHistory')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.totalOverdueNPAHistory
              }
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('totalOverduePerforming')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.totalOverduePerforming
              }
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('incomeEstimationAmount')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.incomeEstimationAmount
              }
              className="form-control ms-2"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('bouncedCheques(last12Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.bouncedChequesLast12Months || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('bouncedCheques(last6Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.bouncedChequesLast6Months || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('bouncedCheques(last3Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.bouncedChequesLast3Months || ''
              }
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('creditApplications(last12Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.creditApplicationsLast12Months ||
                ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('creditApplications(last6Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.creditApplicationsLast6Months ||
                ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('creditApplications(last3Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.creditApplicationsLast3Months ||
                ''
              }
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('enquiries(last12Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.enquiriesLast12Months || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('enquiries(last6Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.enquiriesLast6Months || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('enquiries(last3Months)')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.enquiriesLast3Months || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('loanAmount')}
            </label>
            <MaskNumber
              defaultMaskValue={selectedCreditInformationQuery?.loanAmount}
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('negativeHistoryIndicator')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.negativeHistoryIndicator || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('NPLFlag')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.nplFlag || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-3 text-capitalize">
              {localize('outstandingBalance')}
            </label>
            <MaskNumber
              defaultMaskValue={
                selectedCreditInformationQuery?.outstandingBalance
              }
              className="form-control ms-2"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('apiCode')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.apiCode || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('apiCodeDescription')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.apiCodeDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('transactionId')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.transactionId || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('thirdPartyWalletResponse')}
            </label>
            <input
              className="form-control ms-2"
              value={
                selectedCreditInformationQuery?.thirdPartyWalletResponse || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('modifiedBy')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.modifiedBy || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedCreditInformationQuery?.createdBy || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              className="form-control ms-2"
              value={
                formatDate(selectedCreditInformationQuery?.createdDate) || ''
              }
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('modifiedDate')}
            </label>
            <input
              className="form-control ms-2"
              value={
                formatDate(selectedCreditInformationQuery?.modifiedDate) || ''
              }
              disabled
            />
          </div>
        </div>
        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('accountInformation'),
                item: (
                  <AccountInformation
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('lendorSector'),
                item: (
                  <LendorSector
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('creditInfo'),
                item: (
                  <CreditInfo
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('guarantors'),
                item: (
                  <Guarantors
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('metroScoreTrends'),
                item: (
                  <MetroScoreTrends
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('monthlyScores'),
                item: (
                  <MonthlyScores
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('stakeholders'),
                item: (
                  <Stakeholders
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('identityVerification'),
                item: (
                  <IdentityVerification
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('identityScrub'),
                item: (
                  <IdentityScrub
                    selectedCreditInformationQuery={
                      selectedCreditInformationQuery
                    }
                  />
                ),
              },
              {
                label: localize('specimen'),
                item: (
                  <Specimen
                    customer={selectedCreditInformationQuery}
                    readOnly
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}

export default ViewCreditInformationQueryModal
