/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { formatCurrency } from '../../../../../../Helpers/formatters'

export const AccountStatementsCreditScores = ({ creditScores, loading }) => {
  const columns = [
    {
      label: localize('loanCode'),
      template: r => <>{r.paddedMobileLoanCode}</>,
    },
    {
      label: localize('loanName'),
      template: r => <>{r.mobileLoanDescription}</>,
    },
    {
      label: localize('loanTerm'),
      template: r => <>{r.mobileLoanTermInMonths}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r.loanProductDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => <>{r.loanProductSectionDescription}</>,
    },
    {
      label: localize('productCategory'),
      template: r => <>{r.loanProductCategoryDescription}</>,
    },
    {
      label: localize('minimumGuarantors'),
      template: r => <>{r.minimumGuarantors}</>,
    },
    {
      label: localize('averageNetSalary'),
      template: r => <>{formatCurrency(r.averageNetSalary)}</>,
    },
    {
      label: localize('amountQualified'),
      template: r => <>{formatCurrency(r.amountQualified)}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('returnBookBalance'),
      template: r => <>{r.returnBookBalanceValueDescription}</>,
    },
    { label: localize('isLocked'), template: r => <>{r.mobileLoanIsLocked}</> },
  ]
  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable data={creditScores} columns={columns} />
      )}
    </>
  )
}
