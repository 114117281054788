/** @format */
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
/* eslint-disable */
import React from 'react'

function WithdrawalSettlementModeLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
    },
    {
      label: localize('consolidationProduct'),
      template: r => <>{r.consolidationInvestmentProductDescription}</>,
    },
    {
      label: localize('disbursementProduct'),
      template: r => <>{r.disbursementProduct}</>,
    },
    {
      label: localize('chargeUnConsolidatedBalance'),
      template: r => (
        <span className="text-capitalize">
          {r.chargeUnconsolidatedBalance?.toString()}
        </span>
      ),
    },
    {
      label: localize('suppressNettingOff'),
      template: r => <>{r.suppressNettingOff?.toString()}</>,
    },
    {
      label: localize('isDefault'),
      template: r => <>{r.isDefault?.toString()}</>,
    },
    {
      label: localize('isLocked'),
      template: r => <>{r.isLocked?.toString()}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <SimpleLookup
      title={localize('withdrawalSettlementModeLookup')}
      url="/lookup/WithdrawalSettlementMode/find-withdrawal-settlementmode-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={columns}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default WithdrawalSettlementModeLookup
