/** @format */

import React from 'react'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'

const VehicleFleetProductLookup = ({ onSelect, displayValue }) => {
  const columns = [
    {
      label: 'productType',
      template: r => <>{r?.productTypeDescription}</>,
    },
    {
      label: 'productName',
      template: r => <>{r?.targetProductDescription}</>,
    },
    {
      label: 'name',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'minimumContribution',
      template: r => <>{r?.minimumContribution}</>,
    },
    {
      label: 'priority',
      template: r => <>{r?.priority}</>,
    },
    {
      label: 'isStatutory',
      template: r => <>{r?.isStatutory?.toString()}</>,
    },
    {
      label: 'isLocked',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: 'modifiedBy',
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        onSelect={onSelect}
        displayValue={displayValue}
        url="/vehicleFleetProduct/find-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        title={localize('vehicleFleetProductLookup')}
      />
    </>
  )
}

export default VehicleFleetProductLookup
