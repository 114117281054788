/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import localize from '../../../../../../../Global/Localize'
import { getData } from '../../../../../../../Helpers/webApi'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import ViewAccountInformationHistory from './ViewAccountInformationHistory'
import Loader from '../../../../../../../Components/Loaders/Loader'
function AccountInformation({ selectedCreditInformationQuery }) {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [mode, setMode] = useState(null)
  const [entry, setEntry] = useState(null)

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/CreditReferenceBureau/find-credit-information-queries-entries-by-credit-information-query-id`,
      {
        creditInformationQueryId: selectedCreditInformationQuery.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setEntries(data.pageCollection)
    } else {
      setEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [
    selectedCreditInformationQuery?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
  ])
  const columns = [
    {
      label: localize('productType'),
      template: r => <>{r.productTypeIdDescription}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.accountNumber}</>,
    },
    {
      label: localize('currentBalance'),
      template: r => <>{formatCurrency(r.currentBalance)}</>,
    },
    {
      label: localize('dateOpened'),
      template: r => <>{formatDate(r.dateOpened)}</>,
    },
    {
      label: localize('daysInArrears'),
      template: r => <>{r.daysInArrears}</>,
    },
    {
      label: localize('delinquencyCode'),
      template: r => <>{r.delinquencyCode}</>,
    },
    {
      label: localize('isYourAccount'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.isYourAccount.toString())}
        </span>
      ),
    },
    {
      label: localize('isYourAccount'),
      template: r => <>{r.isYourAccount}</>,
    },
    {
      label: localize('lastPaymentAmount'),
      template: r => <>{formatCurrency(r.lastPaymentAmount)}</>,
    },
    {
      label: localize('lastPaymentDate'),
      template: r => <>{formatDate(r.lastPaymentDate)}</>,
    },
    {
      label: localize('loadedAt'),
      template: r => <>{r.loadedAt}</>,
    },
    {
      label: localize('originalAmount'),
      template: r => <>{formatCurrency(r.originalAmount)}</>,
    },
    {
      label: localize('overdueBalance'),
      template: r => <>{formatCurrency(r.overdueBalance)}</>,
    },
    {
      label: localize('overdueDate'),
      template: r => <>{formatDate(r.overdueDate)}</>,
    },
  ]
  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            columns={columns}
            data={entries}
            canClickOnce
            onClickOnce={async r => {
              setEntry(r)
            }}
            contextMenu={r => (
              <>
                <div
                  onClick={async () => {
                    setMode('viewhistory')
                    setEntry(r)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-eye"></i>
                  {localize('view')}
                </div>
              </>
            )}
          />
          {mode === 'viewhistory' && (
            <ViewAccountInformationHistory entry={entry} />
          )}
        </>
      )}
    </div>
  )
}
export default AccountInformation
