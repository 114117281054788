/** @format */

/* eslint-disable */
import React from 'react'
import Swal from 'sweetalert2'
import ChartOfAccountsLookup from '../../../../../../../Components/Lookup/ChartOfAccountsLookup'
import { CustomerAccountPartial } from '../../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../../../Global/Localize'
import {
  apportionTo,
  chartOfAccountCategory,
  customerAccountStatus,
} from '../../../../../../../Global/enumeration'
import Specimen from '../../../../../../Registry/Members/Partials/Customers/AddCustomerScreens/Specimen'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

const Apportionment = ({ setValues, values }) => {
  const handleSelectCustomer = customer => {
    if (!values?.paddedCreditBatchBatchNumber)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a discrepancy first.',
      })

    if (customer?.status === Object.keys(customerAccountStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected customer account is closed',
      })

    if (
      values?.creditBatchCreditTypeUnallocatedFundsCustomerAccountTypeTargetProductCode !==
      customer?.customerAccountTypeProductCode
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected customer account product code does not match with the discrepancy product code',
      })

    setValues({
      ...values,
      customer,
    })
  }

  const handleSelectChartOfAccount = chartOfAccount => {
    if (!values?.paddedCreditBatchBatchNumber)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a discrepancy first.',
      })

    if (chartOfAccount?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected account is locked',
      })

    if (chartOfAccount?.isControlAccount)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected account is a control account',
      })

    if (chartOfAccount?.isReconciliationAccount)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected account is a reconciliation account',
      })

    if (chartOfAccount?.postAutomaticallyOnly)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected account is a post automatically only',
      })

    if (chartOfAccount?.category !== Object.keys(chartOfAccountCategory)[1])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected account is not a detail account',
      })

    setValues({
      ...values,
      chartOfAccount,
      chartOfAccountId: chartOfAccount?.id,
    })
  }

  return (
    <div className="row g-3 mt-3">
      <div className="col-md-12 mb-4 pe-4">
        <div className="d-flex align-items-center">
          <label htmlFor="apportionTo" className="text-capitalize col-1">
            {localize('apportionTo')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="ApportionTo"
            value={values?.apportionTo}
            onChange={e => setValues({ ...values, apportionTo: e.value })}
            sort
          />
        </div>
      </div>
      {values?.apportionTo === Object.keys(apportionTo)[0] ? (
        <>
          <CustomerAccountPartial
            customer={values?.customer}
            onCustomerLookup={handleSelectCustomer}
          />

          <Specimen customer={values?.customer} readOnly />
        </>
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label
                htmlFor=""
                className="text-capitalize"
                style={{ width: 150 }}
              >
                {localize('chartOfAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={values?.chartOfAccount?.name}
                onSelect={handleSelectChartOfAccount}
                canSelectHeader={false}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label
                htmlFor=""
                className="text-capitalize"
                style={{ width: 150 }}
              >
                {localize('accountType')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={values?.chartOfAccount?.typeDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label
                htmlFor=""
                className="text-capitalize"
                style={{ width: 150 }}
              >
                {localize('costDistr.Rule')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={values?.chartOfAccount?.costDistributionRuleDescription}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Apportionment
