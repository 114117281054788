/** @format */

import localize from '../../../Global/Localize'
/* eslint-disable */
import React from 'react'

export const ViewCustomerAddress = ({ customer }) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine1')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressAddressLine1}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('addressLine2')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressAddressLine2}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('physicalAddress')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressStreet}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('city')}</label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressCity}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('postalCode')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressPostalCode}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('email')}</label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressEmail}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('landLine')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressLandLine}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('mobileLine')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressMobileLine}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('internet')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={customer?.addressInternet}
          />
        </div>
      </div>
    </>
  )
}
