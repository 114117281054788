/** @format */
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
/* eslint-disable */
import React from 'react'

function RevenueTaxLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('revenueTaxLookup')}
      url="/lookup/RevenueTax/find-revenue-tax-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        { label: localize('description'), template: r => <>{r.description}</> },
        { label: localize('taxRate'), template: r => <>{r.taxRate}</> },
        {
          label: localize('roundingTypeDescription'),
          template: r => <>{r.roundingTypeDescription}</>,
        },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">{r.isLocked.toString()}</span>
          ),
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default RevenueTaxLookup
