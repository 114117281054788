/** @format */

import React from 'react'
import localize from '../../../../../../../Global/Localize'

const Remark = ({ management, setManagement, title }) => {
  return (
    <div className="d-flex align-items-center">
      <label className={`${title ? 'col-2' : 'col-1'} text-capitalize`}>
        {localize(title || ' remarks')}
      </label>
      <textarea
        className="form-control"
        value={management.remark}
        onChange={({ target }) => {
          setManagement({
            ...management,
            remark: target.value,
          })
        }}
      />
    </div>
  )
}

export default Remark
