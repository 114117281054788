/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import Pager from '../../../../../Components/Tables/Pager'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import ViewAuditLogs from '../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import { ViewCustomerAccountStatement } from '../../../../../Components/ReUsables/Customer/CustomerAccountStatement/ViewCustomerAccountStatement'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import VerifyCustomerAccount from './VerifyCustomerAccount/VerifyCustomerAccount'
import { RecordStatus } from '../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import CreateCustomerAccount from './CreateCustomerAccount/CreateCustomerAccount'

const Register = () => {
  const [customerAccounts, setCustomerAccounts] = useState([])
  const [customerAccount, setCustomerAccount] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [customer, setCustomer] = useState({})
  const [groupBy, setGroupBy] = useState({
    label: localize('recordStatus'),
    column: 'customerRecordStatusDescription',
    value: 1,
  })
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageCount: 10,
    includeBalances: false,
    includeProductDescription: true,
    recordStatus: 4,
  })

  const columns = [
    {
      label: localize('recordStatus'),
      template: r => r.recordStatusDescription,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r.fullAccountNumber,
    },
    {
      label: localize('accountName'),
      template: r => r.accountName,
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('remarks'),
      template: r => r.remarks,
    },
    {
      label: localize('productName'),
      template: r => r.customerAccountTypeTargetProductDescription,
    },
    {
      label: localize('customerType'),
      template: r => r.customerMembershipClassCustomerTypeDescription,
    },
    {
      label: localize('customerName'),
      template: r => r.customerFullName,
    },
    {
      label: localize('customerMemberNumber'),
      template: r => r.formattedCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r.customerReference1,
    },
    {
      label: localize('membershipNumber'),
      template: r => r.customerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r.customerReference3,
    },
    {
      label: localize('modifiedBy'),
      template: r => r.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const groupingColumns = [
    { value: 0, column: 'statusDescription' },
    { value: 1, column: 'customerRecordStatusDescription' },
    { value: 2, column: 'createdBy' },
    { value: 3, column: 'createdBy' },
    { value: 4, column: 'modifiedBy' },
    {
      value: 5,
      column: 'customerAccountTypeProductCodeDescription',
    },
    {
      value: 6,
      column: 'customerAccountTypeTargetProductDescription',
    },
    {
      value: 7,
      column: 'customerAccountTypeTargetProductSectionDescription',
    },
    { value: 8, column: 'branchDescription' },
    { value: 9, column: 'customerMembershipClassCustomerTypeDescription' },
    { value: 10, column: 'customerIndividualIdentityCardTypeDescription' },
    { value: 11, column: 'customerIndividualSalutationDescription' },
    { value: 12, column: 'customerIndividualGenderDescription' },
    { value: 13, column: 'customerIndividualMaritalStatusDescription' },
    { value: 14, column: 'customerIndividualNationalityDescription' },
    { value: 15, column: 'customerDutyStationDescription' },
    { value: 16, column: 'customerEmployerDescription' },
    { value: 17, column: 'customerAdministrativeDivisionDescription' },
  ]

  const fetchCustomer = async () => {
    const url = '/Customer/find-customer-by-id'
    const { success, data } = await getData(
      url,
      {
        customerId: customerAccount?.customerId,
      },
      false
    )

    if (success) {
      setCustomer(data?.customer)
    }
  }

  const fetchCustomerAccounts = async () => {
    setIsBusy(true)

    const url =
      reqParams.recordStatus === 4
        ? '/CustomerAccount/find-customer-accounts-by-filter-in-page'
        : '/CustomerAccount/find-customer-accounts-by-record-status-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setCustomerAccounts(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleSelectAccount = (r, action) => {
    if (
      action === 'verify' &&
      r.recordStatus !== Object.keys(RecordStatus)[0] &&
      r.recordStatus !== Object.keys(RecordStatus)[1]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only verify records with status of new or edited',
      })

    setCustomerAccount(r)
    setMode(action)
  }

  const handleClickAddButton = () => {
    setMode('add')
    setCustomerAccount({})
  }

  const handleClose = () => {
    setMode(null)
    setCustomerAccount({})
  }

  useEffect(() => {
    fetchCustomerAccounts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageCount, reqParams.pageIndex])

  useEffect(() => {
    if (customerAccount?.customerId) {
      fetchCustomer()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAccount?.customerId])

  return (
    <>
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={mode}
          data={customerAccount}
          y
          title={`${customerAccount?.fullAccountNumber} : ${customerAccount?.customerAccountTypeTargetProductDescription} -> ${customerAccount?.customerFullName}`}
        />
      )}

      {mode === 'view' && (
        <ModalTemplate
          handleClose={handleClose}
          modalClassName="modal-xl"
          modalTitle="customerAccountStatement"
          hideUpdate
          cancelText="close"
          modalMode={mode}
        >
          <ViewCustomerAccountStatement
            selectedCustomerAccount={customerAccount}
            selectedCustomer={customer}
          />
        </ModalTemplate>
      )}

      {mode === 'verify' && (
        <VerifyCustomerAccount
          handleClose={handleClose}
          mode={mode}
          customerAccountInfo={customerAccount}
          fetchCustomerAccounts={fetchCustomerAccounts}
        />
      )}

      {mode === 'add' && (
        <CreateCustomerAccount
          handleClose={handleClose}
          mode={mode}
          fetchCustomerAccounts={fetchCustomerAccounts}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('customerAccounts'),
          localize('customerAccounts'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageCount}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageCount: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('groupBy')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 180,
                      minWidth: 180,
                    }}
                    value={groupBy?.value}
                    enumsKey="CustomerAccountPropertyGroup"
                    onChange={({ label, value }) =>
                      setGroupBy({
                        label,
                        value,
                        column: groupingColumns[value]?.column,
                      })
                    }
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('recordStatus')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.recordStatus}
                    enumsKey="RecordStatusFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        recordStatus: value,
                        pageIndex: 0,
                      })
                    }
                  />
                </div>

                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        customerFilter: value,
                        pageIndex: 0,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchCustomerAccounts()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchCustomerAccounts()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <GroupedTable
                columns={columns}
                data={customerAccounts?.pageCollection || []}
                groupBy={groupBy}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectAccount(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      {localize('view')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectAccount(r, 'verify')
                      }}
                    >
                      <i className="mdi mdi-check-decagram"></i>
                      {localize('verify')}
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectAccount(r, 'auditLogs')
                      }}
                    >
                      <i className="uil uil-server"></i>
                      {localize('viewAuditLogs')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={customerAccounts?.itemsCount}
                pageSize={reqParams.pageCount}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Register
