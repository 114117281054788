/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import localize from '../../../../../../Global/Localize'
import Loader from '../../../../../../Components/Loaders/Loader'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'

const ListSuperSaverPayables = ({
  showVerify,
  showAuthorize,
  handleAuthorize,
  handleVerify,
  handleClickAddButton,
  showCreateButton,
  reload,
  setReload,
  status,
}) => {
  const [requests, setRequests] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    dateRequestsFilter: 15,
    startDate: null,
    endDate: null,
    customDate: false,
    status: status ?? 0,
  })

  const columns = [
    {
      label: localize('voucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('verified') + '/' + localize('rejectedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const fetchRequests = async () => {
    setIsBusy(true)

    const url =
      '/SuperSaverPayable/find-super-saver-payable-by-status-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setRequests(data)
      setIsBusy(false)

      if (reload) setReload(false)
    } else setIsBusy(false)
  }

  useEffect(() => {
    fetchRequests()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  useEffect(() => {
    if (reload) fetchRequests()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <>
      {showCreateButton && (
        <div className="d-flex align-items-center mb-3">
          <AddNewButton handleClickAddButton={handleClickAddButton} />
        </div>
      )}
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between mb-3 gap-1">
            <div className="d-flex align-items-center">
              <label
                style={{
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                className="text-capitalize"
              >
                {localize('recordsPerPage')}
              </label>
              <select
                className="form-select ms-1"
                style={{ maxWidth: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e =>
                  setReqParams({ ...reqParams, pageSize: e.target.value })
                }
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            <div
              className="col d-flex align-items-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('status')}
              </label>
              <EnumsServerCombo
                sort
                customContainerStyle={{
                  maxWidth: 130,
                  minWidth: 130,
                }}
                value={reqParams?.status}
                enumsKey="SuperSaverPayableStatus"
                onChange={({ value }) =>
                  setReqParams({ ...reqParams, status: value })
                }
              />
            </div>
            <div className="d-flex align-items-center">
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('dateRange')}
              </label>
              <DatePicker
                value={{
                  startDate: reqParams?.startDate,
                  endDate: reqParams?.endDate,
                }}
                onSelect={v => {
                  setReqParams({
                    ...reqParams,
                    customDate: true,
                    startDate: v.startDate,
                    endDate: v.endDate,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center gap-1"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-1"
              >
                {localize('search')}
              </label>
              <input
                type="text"
                className="form-control"
                value={reqParams?.filterText}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                    pageIndex: 0,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    fetchRequests()
                  }
                }}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  fetchRequests()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
          <SimpleTable
            columns={columns}
            data={requests?.pageCollection}
            contextMenu={r => (
              <>
                {showVerify && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleVerify(r, 'verify')
                    }}
                  >
                    <i className="mdi mdi-check-decagram"></i>
                    <span>{localize('verify')}</span>
                  </div>
                )}

                {showAuthorize && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleAuthorize(r, 'authorize')
                    }}
                  >
                    <i className="mdi mdi-lock-open-check-outline"></i>
                    <span>{localize('authorize')}</span>
                  </div>
                )}
              </>
            )}
          />
          <Pager
            itemsCount={requests.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default ListSuperSaverPayables
