/** @format */
/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import Swal from 'sweetalert2'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import Loader from '../Loaders/Loader'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

function EmployerLookup({ displayValue, onSelect, canSelectHeader, disabled }) {
  const columns = [
    {
      label: localize('employer'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r.indentedDescription }} />
      ),
    },
    {
      label: localize('type'),
      template: r => <span>{r.typeDescription}</span>,
    },
    { label: localize('companyCode'), template: r => <>{r.companycode}</> },
    {
      label: localize('loanClassificationDate'),
      template: r => <>{formatDate(r.loanClassificationDate)}</>,
    },
    {
      label: `${localize('interestArrearsGracePeriod')} (${localize('days')})`,
      template: r => <>{r.companycode}</>,
    },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('emailAddress'), template: r => <>{r.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('enforceLoanClassificationDate') + '?',
      template: r => <>{r.enforceLoanClassificationDate}</>,
    },
    {
      label: localize('throttleScoring'),
      template: r => <>{r.htrottleScorring}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]
  const [reqParams, setReqParams] = useState({
    updateDepth: true,
    traverseTree: true,
    pageIndex: 0,
    pageSize: 10,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/employer/find-employers-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-3 text-capitalize">
            {localize('employerLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center float-right my-2 col-2">
                    <label htmlFor="">Page Size</label>
                    <select
                      className="form-select ms-3"
                      style={{ width: '30%' }}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                      defaultValue={reqParams.pageSize}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({ ...reqParams, filterText: search })
                    }}
                  >
                    <div className="d-flex align-items-center float-right my-2">
                      <label htmlFor="">Search</label>
                      <input
                        defaultValue={reqParams.filterText}
                        onChange={e => setSearch(e.target.value)}
                        type="search"
                        className="form-control ms-3"
                      />
                    </div>
                  </form>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={data?.pageCollection || []}
                    onSelect={r => {
                      if (r.type === '1' || canSelectHeader) {
                        setModalActive(false)
                        onSelect(r)
                      } else
                        Swal.fire(
                          'Invalid Item',
                          'Cannot select header entry',
                          'info'
                        )
                    }}
                    canSelect
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>

          <ModalFooter className="bg-white p-3">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default EmployerLookup
