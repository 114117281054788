/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  getTodaysDate,
  // stripNonNumericCharacters,
} from '../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import CustomDatePicker from '../../Date/custom-date-picker'
// import { MaskNumber } from '../../InputMasks/MaskNumber'
import { LoanProductLookup } from '../../Lookup/LoanProductLookup'

function LoanProductLookupPartial({
  loanProduct,
  onLookup,
  customer,
  onChangeAmounts,
  latestIncome,
  onChangeTerm,
}) {
  const [product, setProduct] = useState(loanProduct)
  const [depositsBalance, setDepositsBalance] = useState(0)
  const [loanBalance, setLoanBalance] = useState(0)
  const [maximumLoanAmountPercentage, setMaximumLoanAmountPercentage] =
    useState(100)
  const [loanTerm, setLoanTerm] = useState(0)
  const [amountApplied, setAmountApplied] = useState(0)

  useEffect(() => {
    product && customer && onLookup(product)

    setLoanTerm(product?.loanRegistrationTermInMonths)
    //eslint-disable-next-line
  }, [product, customer])

  const [gve, setGve] = GlobalValidationErrors.use()

  function clearValidation(column) {
    setGve(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    onChangeAmounts(amountApplied, loanTerm)
    // eslint-disable-next-line
  }, [amountApplied, loanTerm])

  useEffect(() => {
    if (Number(loanTerm) > Number(product?.loanRegistrationTermInMonths)) {
      onChangeTerm(loanTerm, false)
    } else if (Number(loanTerm) <= 0) {
      onChangeTerm(loanTerm, false)
    } else {
      onChangeTerm(loanTerm, true)
    }
    // eslint-disable-next-line
  }, [loanTerm])

  return (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('loanProduct')}
          </label>
          <LoanProductLookup
            displayValue={product?.description}
            onSelect={l => {
              setProduct(l)
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanProductSection')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={product?.loanRegistrationLoanProductSectionDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('annualPercentageRate')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatAsPercent(product?.loanInterestAnnualPercentageRate)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('interestCalculationMode')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={product?.loanInterestCalculationModeDescription}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('takeHomeType')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={product?.takeHomeTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('takeHomeValue')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={
              product?.takeHomeType == 1
                ? formatAsPercent(product?.takeHomePercentage)
                : formatCurrency(product?.takeHomeFixedAmount, 2, 2, false)
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(loanBalance, 2, 2, false)}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={gve['loanTermCheck']}
        >
          <label htmlFor="" className="text-capitalize col-4">
            {localize('term')} ({localize('months')})
          </label>
          <input
            style={{ textAlign: 'right' }}
            value={loanTerm}
            onBlur={e => {
              if (
                Number(e.target.value) >
                Number(product?.loanRegistrationTermInMonths)
              ) {
                setLoanTerm(product?.loanRegistrationTermInMonths)
                clearValidation('loanTermCheck')
              } else if (Number(e.target.value) <= 0) {
                setLoanTerm(product?.loanRegistrationTermInMonths)
                clearValidation('loanTermCheck')
              }
            }}
            onChange={e => {
              clearValidation('loanTermCheck')
              if (
                Number(e.target.value) >
                Number(product?.loanRegistrationTermInMonths)
              ) {
                setGve(g => ({
                  ...g,
                  loanTermCheck: `Loan term should be less than ${product?.loanRegistrationTermInMonths} months`,
                }))
              } else if (Number(e.target.value) <= 0) {
                setGve(g => ({
                  ...g,
                  loanTermCheck: `Loan term should be more than zero!`,
                }))
              }
              setLoanTerm(e.target.value)
            }}
            type="number"
            className="form-control"
          />
        </div>
      </div>
    </div>
  )
}

export default LoanProductLookupPartial
