/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { LoanProductLookup } from '../../../../Components/Lookup/LoanProductLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import TargetCustomers from './TargetCustomers'
import Loader from '../../../../Components/Loaders/Loader'

function ConditionalLendingModal({
  mode,
  onHideModal,
  loadTableData,
  onSetMode,
  selectedConditionalLending,
  setSelectedConditionalLending,
}) {
  const [gbv, setGbv] = GlobalValidationErrors.use({})
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [exemptionEntriesToBeRemoved, setExemptionEntriesToBeRemoved] =
    useState([])
  const [customRemarks, setCustomRemarks] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [conditionalLendingEntries, setConditionalLendingEntries] = useState([])
  const [loadTabData, setLoadTabData] = useState(false)
  const [reqParams, setReqParams] = useState({
    conditionalLendingId: selectedConditionalLending?.id,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (mode === 'edit') {
      getconditionalLendingEntries()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.pageIndex,
    reqParams.pageSize,
    selectedConditionalLending?.id,
    refresh,
  ])

  const getconditionalLendingEntries = async () => {
    setLoadTabData(true)
    const { success, data } = await getData(
      '/ConditionalLending/find-conditional-lending-entries-by-conditional-lending-id-in-page',
      reqParams,
      false
    )
    if (success) {
      setConditionalLendingEntries(data.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setLoadTabData(false)
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/ConditionalLending/update-conditional-lending-with-entries',
            {
              conditionalLending: selectedConditionalLending || {},
              conditionalLendingEntryDTOs: conditionalLendingEntries,
            },
            false
          )
        : await postData(
            '/ConditionalLending/create-conditional-lending-with-entries',
            {
              conditionalLending: selectedConditionalLending || {},
              conditionalLendingEntryDTOs: conditionalLendingEntries,
            },
            false
          )

    if (success) {
      onSetMode(null)
      loadTableData()
      showNotification('Conditional Lending', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const addConditionalLendingEntry = customer => {
    if (!customer) {
      return
    }

    if (
      conditionalLendingEntries.find(entry => entry.customerId === customer.id)
    ) {
      return showNotification(
        'Conditional Lending',
        'error',
        'Customer Entry Already Exists'
      )
    }
    setConditionalLendingEntries([
      ...conditionalLendingEntries,
      {
        customerId: customer.id,
        id: customer.id,
        customerFullName: customer.fullName,
        customerMembershipClassCustomerType:
          customer.membershipClassCustomerType,
        customerSerialNumber: customer.serialNumber,
        customerIndividualSalutation: customer.individualSalutation,
        customerIndividualFirstName: customer.individualFirstName,
        customerIndividualLastName: customer.individualLastName,
        customerIndividualGender: customer.individualGender,
        customerIndividualMaritalStatus: customer.individualMaritalStatus,
        customerMembershipClassCustomerTypeDescription:
          customer.membershipClassDescription,
        paddedCustomerSerialNumber: customer.paddedSerialNumber,
        customerReference1: customer.reference1,
        customerReference2: customer.reference2,
        customerReference3: customer.reference3,
        customerIndividualGenderDescription:
          customer.individualGenderDescription,
        customerIndividualMaritalStatusDescription:
          customer.individualMaritalStatusDescription,
        customerIndividualIdentityCardNumber:
          customer.individualIdentityCardNumber,
        customerIndividualNationalityDescription:
          customer.individualNationalityDescription,
        customerDutyStationDescription: customer.dutyStationDescription,
        customerAddressAddressLine1: customer.addressAddressLine1,
        customerAddressAddressLine2: customer.addressAddressLine2,
        customerAddressStreet: customer.addressStreet,
        customerAddressPostalCode: customer.addressPostalCode,
        customerAddressCity: customer.addressCity,
        customerAddressEmail: customer.addressEmail,
        customerAddressLandLine: customer.addressLandLine,
        customerAddressMobileLine: customer.addressMobileLine,
        remarks: customRemarks?.remarks,
        createdBy: customer.createdBy,
        createdDate: customer.createdDate,
      },
    ])
    setSelectedCustomer(null)
    setCustomRemarks({ remarks: '' })
  }

  const removeExemptionEntry = customer => {
    setConditionalLendingEntries(
      conditionalLendingEntries.filter(entry => entry.id !== customer.id)
    )
  }

  const removeExemptionEntryInEditMode = async () => {
    if (exemptionEntriesToBeRemoved.length === 0) {
      return showNotification(
        'Conditional Lending',
        'error',
        'Select Atleast One Entry'
      )
    }
    setLoadTabData(true)
    const { success } = await putData(
      '/ConditionalLending/remove-conditional-lending-entries',
      { ConditionalLendingEntry: exemptionEntriesToBeRemoved },
      false
    )
    if (success) {
      setExemptionEntriesToBeRemoved([])
      getconditionalLendingEntries()
      showNotification('Conditional Lending', 'success')
    }
    setLoadTabData(false)
  }

  const handleOnSelectCustomer = customer => {
    if (!customer.isLocked) {
      setSelectedCustomer({ ...customer })
    } else {
      showNotification(
        'Conditional Lending',
        'error',
        'Selected Customer is Locked'
      )
      setSelectedCustomer(null)
    }
  }

  const addConditionalLendingEntryInEditMode = async customer => {
    const customerExemptionEntry = {
      customerId: customer.id,
      conditionalLendingId: selectedConditionalLending?.id,
      customerFullName: customer.fullName,
      customerMembershipClassCustomerType: customer.membershipClassCustomerType,
      customerSerialNumber: customer.serialNumber,
      customerIndividualSalutation: customer.individualSalutation,
      customerIndividualFirstName: customer.individualFirstName,
      customerIndividualLastName: customer.individualLastName,
      customerIndividualGender: customer.individualGender,
      customerIndividualMaritalStatus: customer.individualMaritalStatus,
      id: customer.id,
      customerMembershipClassCustomerTypeDescription:
        customer.membershipClassDescription,
      paddedCustomerSerialNumber: customer.paddedSerialNumber,
      customerReference1: customer.reference1,
      customerReference2: customer.reference2,
      customerReference3: customer.reference3,
      customerIndividualGenderDescription: customer.individualGenderDescription,
      customerIndividualMaritalStatusDescription:
        customer.individualMaritalStatusDescription,
      customerIndividualIdentityCardNumber:
        customer.individualIdentityCardNumber,
      customerIndividualNationalityDescription:
        customer.individualNationalityDescription,
      customerDutyStationDescription: customer.dutyStationDescription,
      customerAddressAddressLine1: customer.addressAddressLine1,
      customerAddressAddressLine2: customer.addressAddressLine2,
      customerAddressStreet: customer.addressStreet,
      customerAddressPostalCode: customer.addressPostalCode,
      customerAddressCity: customer.addressCity,
      customerAddressEmail: customer.addressEmail,
      customerAddressLandLine: customer.addressLandLine,
      customerAddressMobileLine: customer.addressMobileLine,
      remarks: customRemarks?.remarks,
      createdBy: customer.createdBy,
      createdDate: customer.createdDate,
    }
    setLoadTabData(true)
    const { success } = await postData(
      '/ConditionalLending/create-conditional-lending-entry',
      customerExemptionEntry,
      false
    )
    if (success) {
      getconditionalLendingEntries()
      setSelectedCustomer(null)
      setCustomRemarks({ remarks: '' })
      showNotification('Conditional Lending Entry', 'success')
    }
    setLoadTabData(false)
  }

  function closeModal() {
    onHideModal()
    setSelectedCustomer(null)
    setSelectedConditionalLending(null)
    setCustomRemarks({ remarks: '' })
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalTitle={'conditionalLending'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={closeModal}
      disableClose={isBusy}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              validation-message={
                gbv['ConditionalLending.Description']?.message
              }
              className="col d-flex  align-items-center with-validation"
            >
              <label className="col-1 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedConditionalLending?.description}
                onChange={e => {
                  clearValidation('ConditionalLending.Description')
                  setSelectedConditionalLending({
                    ...selectedConditionalLending,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div
              validation-message={
                gbv['ConditionalLending.LoanProductId']?.message
              }
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-2 text-capitalize">
                {localize('loanProduct')}
              </label>
              {mode === 'edit' ? (
                <input
                  className="form-control"
                  disabled
                  defaultValue={
                    selectedConditionalLending?.loanProductDescription
                  }
                />
              ) : (
                <LoanProductLookup
                  displayValue={
                    selectedConditionalLending?.loanProductDescription
                  }
                  onSelect={r => {
                    clearValidation('ConditionalLending.LoanProductId')
                    setSelectedConditionalLending({
                      ...selectedConditionalLending,
                      loanProductId: r.id,
                      loanProductDescription: r.description,
                    })
                  }}
                />
              )}
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('targetCustomerDetails')}
            </div>
            <CustomerPartial
              customer={selectedCustomer}
              onCustomerLookup={r => {
                handleOnSelectCustomer(r)
              }}
              setCustomRemarks={setCustomRemarks}
              customRemarks={customRemarks}
              remarksVisible={true}
              displayExtraRow={true}
            />

            <div
              className="d-flex justify-content-end align-items-center p-2"
              style={{ borderRadius: 5 }}
            >
              <button
                onClick={() => {
                  if (selectedCustomer === null) {
                    return showNotification(
                      'Conditional Lending Entry',
                      'error',
                      'Sorry, but you must first select a customer'
                    )
                  } else {
                    mode === 'add'
                      ? addConditionalLendingEntry(selectedCustomer)
                      : addConditionalLendingEntryInEditMode(selectedCustomer)
                  }
                }}
                className="btn btn-success text-uppercase ms-3 px-4"
              >
                {localize('add')}
              </button>
            </div>

            {loadTabData ? (
              <Loader />
            ) : (
              <div className="row mt-12">
                <Tab
                  tabItems={[
                    {
                      label: localize('targetCustomers'),
                      item: (
                        <TargetCustomers
                          handleOnCheckCustomerExemptionEntry={r => {
                            setExemptionEntriesToBeRemoved(r)
                          }}
                          removeSelectedExemptionEntries={r => {
                            removeExemptionEntryInEditMode()
                          }}
                          mode={mode}
                          onRemoveEntry={r => {
                            removeExemptionEntry(r)
                          }}
                          targetCustomers={conditionalLendingEntries}
                          reqParams={reqParams}
                          setReqParams={setReqParams}
                          tableMeta={tableMeta}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </ModalTemplate>
  )
}
export default ConditionalLendingModal
