/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import { postData, putData } from '../../../../Helpers/webApi'
import CreateEmploymentSector from './CreateEmploymentSector/CreateEmploymentSector'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function EmploymentSectors() {
  const [modalState, setModalState] = useState(null)
  const [selectedSector, setSelectedSector] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [gbv, setGbv] = GlobalValidationErrors.use()

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('medianIncome'),
      headerTextAlign: 'right',
      template: r => (
        <div style={{ textAlign: 'right' }} className="p-0">
          {formatCurrency(r?.medianIncome)}
        </div>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    return () => setGbv({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const handleClose = () => {
    setModalState(null)
    setSelectedSector(null)
  }

  const handleClickAddButton = () => {
    setSelectedSector({
      medianIncome: 0,
    })
    setModalState('add')
  }

  async function handleUpdate() {
    setIsBusy(true)
    const { success } = await putData(
      '/employmentsector/update',
      selectedSector,
      false
    )

    if (success) {
      showNotification('Employment Sector', 'success')
      setRefresh(!refresh)
      handleClose()
    }

    setIsBusy(false)
  }

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  async function handleAdd() {
    setIsBusy(true)
    const { success } = await postData(
      '/employmentsector/create',
      selectedSector,
      false
    )

    if (success) {
      setIsBusy(false)
      showNotification('Employment Sector', 'success')
      setRefresh(!refresh)
      handleClose()
    }

    setIsBusy(false)
  }

  return (
    <>
      {modalState && (
        <CreateEmploymentSector
          handleAdd={handleAdd}
          handleClose={handleClose}
          handleUpdate={handleUpdate}
          isBusy={isBusy}
          gbv={gbv}
          clearValidation={clearValidation}
          modalMode={modalState}
          selectedSector={selectedSector}
          setSelectedSector={setSelectedSector}
        />
      )}

      <ListingPage
        map={[localize('registryManagement'), localize('employmentSectors')]}
        columns={columns}
        params={{
          filterText: '',
          pageIndex: 0,
          pageSize: 10,
        }}
        url="/employmentSector/find-by-filter-in-page"
        onAdd={handleClickAddButton}
        refreshAt={refresh}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item"
              onClick={() => {
                setModalState('edit')
                setSelectedSector(r)
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span className="text-capitalize">{localize('edit')}</span>
            </div>
          </>
        )}
      />
    </>
  )
}

export default EmploymentSectors
