/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const CreateFuneralHome = ({
  mode,
  handleClose,
  setRefresh,
  refresh,
  funeralHome,
  setFuneralHome,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const handleCloseModal = () => {
    handleClose()
    setFuneralHome(null)
  }

  const handleAddOrUpdate = async () => {
    if (!funeralHome?.description) {
      GlobalValidationErrors.set({
        Description: {
          message: 'Name is required',
        },
      })

      return
    }

    setIsBusy(true)
    const message = mode === 'add' ? 'Create Funeral Home' : 'Edit Funeral Home'

    const url =
      mode === 'add'
        ? '/FuneralHome/add-funeral-home'
        : '/FuneralHome/update-funeral-home'

    const { success } = await postData(url, funeralHome, false)

    if (success) {
      setRefresh(!refresh)
      handleCloseModal()
      showNotification(message, 'success')
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setGlobalValidationErrors({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  return (
    <ModalTemplate
      modalTitle="funeralHome"
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={handleAddOrUpdate}
      handleClose={handleCloseModal}
      modalMode={mode}
      modalClassName="modal-xl"
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            disabled={isBusy}
            id={`isLocked-funeral-home`}
            checked={funeralHome?.isLocked}
            onCheck={value => {
              setFuneralHome({
                ...funeralHome,
                isLocked: value,
              })
            }}
          />

          <label htmlFor="isLocked-funeral-home" className="text-capitalize">
            {localize('isLocked')} ?
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-12">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize me-2">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={funeralHome?.description || ''}
                  onChange={e => {
                    clearValidation('Description')
                    setFuneralHome({
                      ...funeralHome,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mt-3">
            <Tab
              tabItems={[
                {
                  label: 'Address',
                  item: (
                    <>
                      <div className="row g-3 py-3">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="addressAddressLine1"
                              className="text-capitalize col-3"
                            >
                              {localize('addressLine1')}
                            </label>

                            <input
                              type="text"
                              id="addressAddressLine1"
                              className="form-control ms-2"
                              value={funeralHome?.addressAddressLine1 || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressAddressLine1: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="addressAddressLine2"
                              className="text-capitalize col-3"
                            >
                              {localize('addressLine2')}
                            </label>

                            <input
                              type="text"
                              id="addressAddressLine2"
                              className="form-control ms-2"
                              value={funeralHome?.addressAddressLine2 || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressAddressLine2: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="physicalAddress"
                              className="text-capitalize col-3"
                            >
                              {localize('physicalAddress')}
                            </label>

                            <input
                              type="text"
                              id="physicalAddress"
                              className="form-control ms-2"
                              value={funeralHome?.addressStreet || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressStreet: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="city"
                              className="text-capitalize col-3"
                            >
                              {localize('city')}
                            </label>

                            <input
                              type="text"
                              id="city"
                              className="form-control ms-2"
                              value={funeralHome?.addressCity || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressCity: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="postalCode"
                              className="text-capitalize col-3"
                            >
                              {localize('postalCode')}
                            </label>

                            <input
                              type="text"
                              id="postalCode"
                              className="form-control ms-2"
                              value={funeralHome?.addressPostalCode || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressPostalCode: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="email"
                              className="text-capitalize col-3"
                            >
                              {localize('email')}
                            </label>

                            <input
                              type="email"
                              id="email"
                              className="form-control ms-2"
                              value={funeralHome?.addressEmail || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressEmail: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="landLine"
                              className="text-capitalize col-3"
                            >
                              {localize('landLine')}
                            </label>

                            <input
                              type="text"
                              id="landLine"
                              className="form-control ms-2"
                              value={funeralHome?.addressLandLine || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressLandLine: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="mobile"
                              className="text-capitalize col-3"
                            >
                              {localize('mobileLine')}
                            </label>

                            <input
                              type="text"
                              id="mobile"
                              className="form-control ms-2"
                              value={funeralHome?.addressMobileLine || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressMobileLine: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="internet"
                              className="text-capitalize col-3"
                            >
                              {localize('internet')}
                            </label>

                            <input
                              type="text"
                              id="internet"
                              className="form-control ms-2"
                              value={funeralHome?.addressInternet || ''}
                              onChange={e => {
                                setFuneralHome({
                                  ...funeralHome,
                                  addressInternet: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateFuneralHome
