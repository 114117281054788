/** @format */
import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'

export const AccountMetadataAlternateChannels = ({ loading, data }) => {
  const columns = [
    { label: localize('channelType'), template: r => <>{r.typeDescription}</> },
    {
      label: localize('primaryAccountNumber'),
      template: r => <>{r.cardNumber}</>,
    },
    {
      label: localize('validFrom'),
      template: r => <>{formatDate(r.validFrom)}</>,
    },
    { label: localize('expires'), template: r => <>{formatDate(r.expires)}</> },
    {
      label: localize('dailyLimit'),
      template: r => <>{formatCurrency(r.dailyLimit)}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('isLocked'), template: r => <>{r.isLocked}</> },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable columns={columns} data={data} />
      )}
    </>
  )
}
