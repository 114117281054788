/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData, postData, putData } from '../../Helpers/webApi'
import { GlobalValidationErrors } from '../../Validation/ValidationState'
import { MaskNumber } from '../../Components/InputMasks/MaskNumber'
import Checkbox from '../../Components/Checkbox/Checkbox'
import Tab from '../../Components/Tabs/Tab'
import SimpleTable from '../../Components/Tables/SimpleTable'
import { useEffect } from 'react'
import Pager from '../../Components/Tables/Pager'
import ModalTemplate from '../../Components/ModalTemplate/ModalTemplate'
import BreadCrumb from '../../Components/BreadCrumb/Index'
import Loader from '../../Components/Loaders/Loader'
import { showNotification } from '../../Helpers/Notification/ShowNotification'
import AddNewButton from '../../Components/Buttons/AddNewButton/AddNewButton'
import RecordsPerPage from '../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function NumberingSeries() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('firstNumber'),
      template: r => <>{r?.firstNumber}</>,
      sortBy: 'firstNumber',
    },
    {
      label: localize('nextNumber'),
      template: r => <>{r?.nextNumber}</>,
      sortBy: 'nextNumber',
    },
    {
      label: localize('lastNumber'),
      template: r => <>{r?.lastNumber}</>,
      sortBy: 'lastNumber',
    },
    {
      label: localize('padding'),
      template: r => <>{r?.padding}</>,
      sortBy: 'padding',
    },
    {
      label: localize('prefix'),
      template: r => <>{r?.prefix}</>,
      sortBy: 'prefix',
    },
    {
      label: localize('suffix'),
      template: r => <>{r?.suffix}</>,
      sortBy: 'suffix',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
      sortBy: 'modifiedBy',
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate, false)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, false)}</>,
    },
  ]

  const [mode, setMode] = useState(null)
  const [selectedNumberingSeries, setSelectedNumberingSeries] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/NumberingSeries/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/NumberingSeries/update',
            selectedNumberingSeries || { description: '' },
            false
          )
        : await postData(
            '/NumberingSeries/create',
            selectedNumberingSeries || { description: '' },
            false
          )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedNumberingSeries(null)
      loadTableData()
      showNotification('Numbering Series', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedNumberingSeries(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedNumberingSeries(null)
  }

  const handleViewAuditLogs = item => {
    setSelectedNumberingSeries(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('systemAdministration'), localize('numberingSeries')]}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={selectedNumberingSeries}
          title={selectedNumberingSeries?.description}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className=" d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="numberingSeriesFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item"
                            onClick={() => {
                              setMode('edit')
                              setSelectedNumberingSeries(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item"
                            onClick={async () => {
                              setMode('view')
                              setSelectedNumberingSeries(r)
                            }}
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />

                    {(mode === 'add' || mode === 'edit' || mode === 'view') && (
                      <ModalTemplate
                        modalClassName="modal-xl"
                        modalTitle={'numberingSeries'}
                        modalMode={mode}
                        hideUpdate={mode === 'view'}
                        handleAction={handleSubmit}
                        handleClose={closeModal}
                        actionText={mode === 'add' ? 'create' : 'update'}
                        footerChildren={
                          <>
                            {mode == 'edit' ? (
                              <>
                                <Checkbox
                                  id="generateNumberingSeries"
                                  onCheck={e => {
                                    setSelectedNumberingSeries({
                                      ...selectedNumberingSeries,
                                      seedSequentialNumbers: e,
                                    })
                                  }}
                                  checked={
                                    selectedNumberingSeries?.seedSequentialNumbers
                                  }
                                />
                                <label
                                  htmlFor="generateNumberingSeries"
                                  className="text-capitalize mb-3"
                                >
                                  {localize('generateSeries')}?
                                </label>
                                <div className="mx-3"></div>

                                <Checkbox
                                  id="cNumberingSeriesIsLocked"
                                  onCheck={e => {
                                    setSelectedNumberingSeries({
                                      ...selectedNumberingSeries,
                                      isLocked: e,
                                    })
                                  }}
                                  checked={selectedNumberingSeries?.isLocked}
                                />
                                <label
                                  htmlFor="cNumberingSeriesIsLocked"
                                  className="text-capitalize mb-3"
                                >
                                  {localize('isLocked')}?
                                </label>
                                <div className="mx-3"></div>
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        }
                      >
                        {isBusy ? (
                          <Loader />
                        ) : (
                          <>
                            <div className="row mb-3 g-3">
                              <div className="col-md-12">
                                <div
                                  className="with-validation d-flex align-items-center mb-2"
                                  validation-message={
                                    globalValidationErrors['Description']
                                      ?.message
                                  }
                                >
                                  <label className="text-capitalize col-1">
                                    {localize('name')}
                                  </label>
                                  <input
                                    disabled={mode === 'view'}
                                    defaultValue={
                                      selectedNumberingSeries?.description || ''
                                    }
                                    type="text"
                                    className="form-control"
                                    onChange={e => {
                                      clearValidation('Description')
                                      setSelectedNumberingSeries({
                                        ...selectedNumberingSeries,
                                        description: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-3 g-3">
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-3">
                                    {localize('firstNumber')}
                                  </label>
                                  <MaskNumber
                                    disabled={mode === 'view'}
                                    defaultMaskValue={
                                      selectedNumberingSeries?.firstNumber || 0
                                    }
                                    onMaskNumber={e => {
                                      setSelectedNumberingSeries({
                                        ...selectedNumberingSeries,
                                        firstNumber: e.target.value,
                                      })
                                    }}
                                    numeralThousandsGroupStyle="none"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-3">
                                    {localize('nextNumber')}
                                  </label>
                                  <MaskNumber
                                    disabled={mode === 'view'}
                                    defaultMaskValue={
                                      selectedNumberingSeries?.nextNumber || 0
                                    }
                                    onMaskNumber={e => {
                                      setSelectedNumberingSeries({
                                        ...selectedNumberingSeries,
                                        nextNumber: e.target.value,
                                      })
                                    }}
                                    numeralThousandsGroupStyle="none"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-3">
                                    {localize('lastNumber')}
                                  </label>
                                  <MaskNumber
                                    disabled={mode === 'view'}
                                    defaultMaskValue={
                                      selectedNumberingSeries?.lastNumber || 0
                                    }
                                    onMaskNumber={e => {
                                      setSelectedNumberingSeries({
                                        ...selectedNumberingSeries,
                                        lastNumber: e.target.value,
                                      })
                                    }}
                                    numeralThousandsGroupStyle="none"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-3 g-3">
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-3">
                                    {localize('padding')}
                                  </label>
                                  <MaskNumber
                                    disabled={mode === 'view'}
                                    defaultMaskValue={
                                      selectedNumberingSeries?.padding || 0
                                    }
                                    onMaskNumber={e => {
                                      setSelectedNumberingSeries({
                                        ...selectedNumberingSeries,
                                        padding: e.target.value,
                                      })
                                    }}
                                    numeralThousandsGroupStyle="none"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-3">
                                    {localize('prefix')}
                                  </label>
                                  <input
                                    disabled={mode === 'view'}
                                    defaultValue={
                                      selectedNumberingSeries?.prefix || ''
                                    }
                                    type="text"
                                    className="form-control"
                                    onChange={e => {
                                      setSelectedNumberingSeries({
                                        ...selectedNumberingSeries,
                                        prefix: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex align-items-center mb-2">
                                  <label className="text-capitalize col-3">
                                    {localize('suffix')}
                                  </label>
                                  <input
                                    disabled={mode === 'view'}
                                    defaultValue={
                                      selectedNumberingSeries?.suffix || ''
                                    }
                                    type="text"
                                    className="form-control"
                                    onChange={e => {
                                      setSelectedNumberingSeries({
                                        ...selectedNumberingSeries,
                                        suffix: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {mode === 'view' ? (
                              <div className="row col-12 mt-2">
                                <Tab
                                  tabItems={[
                                    {
                                      label: localize('numberingSeries'),
                                      item: (
                                        <NumberingSeriesEntries
                                          showLoader={false}
                                          selectedNumberingSeries={
                                            selectedNumberingSeries
                                          }
                                        />
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </ModalTemplate>
                    )}
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p => {
                        setReqParams({ ...reqParams, pageIndex: p })
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const NumberingSeriesEntries = ({ selectedNumberingSeries, showLoader }) => {
  const columns = [
    {
      label: localize('sequentialNumber'),
      template: r => <>{r.formattedSequentialNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const loadNumberingSeriesEntries = async () => {
    const { success, data } = await getData(
      `/NumberingSeries/find-entrties-by-filter-in-page`,
      {
        ...reqParams,
        numberingSeriesId: selectedNumberingSeries.id,
      },
      false
    )
    if (success) {
      setEntries(data)
    } else {
      setEntries([])
    }
  }

  useEffect(() => {
    loadNumberingSeriesEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNumberingSeries?.id, reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      <div className="row px-3">
        <RecordsPerPage
          className="col-2"
          reqParams={reqParams}
          setReqParams={setReqParams}
        />
      </div>
      <SimpleTable
        loading={showLoader}
        data={entries.pageCollection}
        columns={columns}
        selectables
      />
      <Pager
        itemsCount={entries.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}
export default NumberingSeries
