/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'

function CustomerLoanApplications({ customer }) {
  useEffect(() => {
    getLoanApplications()
    // eslint-disable-next-line
  }, [customer])

  const [applications, setApplications] = useState([])
  const [fetching, setFetching] = useState(false)

  const columns = [
    {
      label: localize('loanNumber'),
      template: r => <>{r?.documentReferenceNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: `${localize('ref')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r?.loanProductDescription}</>,
    },
    {
      label: localize('receivedDate'),
      template: r => <>{formatDate(r?.receivedDate, true)}</>,
    },
    {
      label: localize('amountApplied'),
      template: r => <>{formatCurrency(r?.amountApplied)}</>,
    },
    {
      label: localize('appraisedAmount'),
      template: r => <>{formatCurrency(r?.appraisedAmount)}</>,
    },
    {
      label: localize('systemAppraisalRemarks'),
      template: r => <>{r?.systemAppraisalRemarks}</>,
    },
    {
      label: localize('appraisalRemarks'),
      template: r => <>{r?.appraisalRemarks}</>,
    },
    {
      label: localize('systemAppraisedAmount'),
      template: r => <>{formatCurrency(r?.systemAppraisedAmount)}</>,
    },
    {
      label: localize('appraisedNetIncome'),
      template: r => <>{formatCurrency(r?.appraisedNetIncome)}</>,
    },
    {
      label: localize('appraisedAmountRemarks'),
      template: r => <>{r?.appraisedAmountRemarks}</>,
    },
    {
      label: localize('monthlyPaybackAmount'),
      template: r => <>{formatCurrency(r?.monthlyPaybackAmount)}</>,
    },
    {
      label: localize('totalPaybackAmount'),
      template: r => <>{formatCurrency(r?.totalPaybackAmount)}</>,
    },
    {
      label: localize('appraisedBy'),
      template: r => <>{r?.loanLossProvisionPercentage}</>,
    },
    {
      label: localize('appraisedDate'),
      template: r => <>{formatDate(r?.appraisedDate)}</>,
    },
    {
      label: localize('approvedAmount'),
      template: r => <>{formatCurrency(r?.approvedAmount)}</>,
    },
    {
      label: localize('approvedPrincipalPayment'),
      template: r => <>{formatCurrency(r?.approvedPrincipalPayment)}</>,
    },
    {
      label: localize('approvedInterestPayment'),
      template: r => <>{formatCurrency(r?.approvedInterestPayment)}</>,
    },
    {
      label: localize('approvalRemarks'),
      template: r => <>{r?.approvalRemarks}</>,
    },
    {
      label: localize('approvedBy'),
      template: r => <>{r?.approvedBy}</>,
    },
    {
      label: localize('approvedDate'),
      template: r => <>{formatDate(r?.approvedDate)}</>,
    },
    {
      label: localize('verificationRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('topUpAmount'),
      template: r => <>{formatCurrency(r?.auditTopUpAmount)}</>,
    },
    {
      label: localize('verifiedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('verifiedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('disbursedAmount'),
      template: r => <>{r?.disbursedAmount}</>,
    },
    {
      label: localize('disbursementRemarks'),
      template: r => <>{r?.disbursementRemarks}</>,
    },
    {
      label: localize('disbursedDate'),
      template: r => <>{formatDate(r?.disbursedDate)}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 (${localize('membership')} #)`,
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r?.loanPurposeDescription}</>,
    },
    {
      label: localize('annualPercentageRate'),
      template: r => (
        <div className="text-end">
          {formatAsPercent(r?.loanInterestAnnualPercentageRate)}
        </div>
      ),
    },
    {
      label: localize('interestChargeMode'),
      template: r => <>{r?.loanInterestChargeModeDescription}</>,
    },
    {
      label: localize('interestRecoveryMode'),
      template: r => <>{r?.loanInterestRecoveryModeDescription}</>,
    },
    {
      label: localize('interestCalculationMode'),
      template: r => <>{r?.loanInterestCalculationModeDescription}</>,
    },
    {
      label: localize('term') + `(${localize('months')})`,
      template: r => <>{r?.loanRegistrationTermInMonths}</>,
    },
    {
      label: localize('minimumMembershipPeriod'),
      template: r => <>{r?.loanRegistrationMinimumMembershipPeriod}</>,
    },
    {
      label: localize('paymentFrequencyPerYear'),
      template: r => (
        <>{r?.loanRegistrationPaymentFrequencyPerYearDescription}</>
      ),
    },
    {
      label: localize('reference'),
      template: r => <>{r?.reference}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  async function getLoanApplications() {
    setFetching(true)
    const { success, data } = await getData(
      '/lookup/LoanCase/find-loan-cases-by-customer-id-in-process',
      {
        customerId: customer?.id,
      },
      false
    )
    if (success) {
      setApplications(data?.result || [])
    }
    setFetching(false)
  }

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <GroupedTable
      groupBy={{
        label: localize('status'),
        column: 'statusDescription',
      }}
      columns={columns}
      data={applications}
    />
  )
}

export default CustomerLoanApplications
