/** @format */
import localize from '../../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {
  DynamicChargeInstallmentsBasisValue,
  DynamicChargeRecoveryMode,
  DynamicChargeRecoverySource,
  LoanCaseDeductionCategory,
} from '../../../../../Global/enumeration'
import Tab from '../../../../../Components/Tabs/Tab'
import Commissions from '../../../../../Components/Selectables/Plain/Commissions'
import { postData, putData } from '../../../../../Helpers/webApi'
import moment from 'moment'
import Swal from 'sweetalert2'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

function IndefiniteChargeModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  indefiniteChargeModel,
  setCommissionsToBeSelected,
  setIndefiniteChargeModel,
  commissionsToBeSelected,
}) {
  const [modalWaiting, setModalWaiting] = useState(false)
  const [finalCommissions, setFinalCommissions] = useState([])
  const [selectedRecoveryMode, setSelectedRecoveryMode] = useState('1280')
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  useEffect(() => {
    setValidationBag({})
  }, [mode])

  async function handleSubmit(finalData) {
    setModalWaiting(true)
    const { success, data } =
      mode === 'add'
        ? await postData(
            '/DynamicCharge/add-dynamic-charge-with-commissions',
            finalData
          )
        : await putData(
            '/DynamicCharge/update-dynamic-charge-with-commissions',
            finalData
          )

    if (success) {
      setModalWaiting(false)
      onSetMode(null)
      onUpdateRefreshAt()
      Swal.fire({
        icon: 'success',
        text: 'Operation Completed Successfully',
        title: 'Indefinite Charges',
        showCloseButton: true,
      })
    } else {
      setModalWaiting(false)
      if (data.type === '0') setValidationBag(data.reason)
      else {
        Swal.fire({
          icon: 'error',
          text: data.reason,
          title: 'Indefinite Charges',
          showCloseButton: true,
        })
      }
    }
  }

  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <ModalHeader className="bg-white text-capitalize d-flex align-items-center">
              {localize(mode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('indefiniteCharges')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <div className="row mb-3">
                <div
                  validation-message={
                    validationBag?.['DynamicCharge.Description']?.message
                  }
                  className="col d-flex align-items-center with-validation"
                >
                  <label className="col-2 text-capitalize">
                    {localize('name')}:
                  </label>

                  <input
                    className={`form-control`}
                    defaultValue={indefiniteChargeModel?.description}
                    onChange={c => {
                      setIndefiniteChargeModel({
                        ...indefiniteChargeModel,
                        description: c.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('deductionCategory')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="LoanCaseDeductionCategory"
                    value={indefiniteChargeModel?.deductionCategory}
                    onChange={c => {
                      setIndefiniteChargeModel({
                        ...indefiniteChargeModel,
                        deductionCategory: c.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('recoveryMode')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="DynamicChargeRecoveryMode"
                    value={indefiniteChargeModel?.recoveryMode}
                    onChange={c => {
                      setSelectedRecoveryMode(c.value)
                      setIndefiniteChargeModel({
                        ...indefiniteChargeModel,
                        recoveryMode: c.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-2  text-capitalize">
                    {localize('recoverySource')}:
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="DynamicChargeRecoverySource"
                    value={indefiniteChargeModel?.recoverySource}
                    onChange={c => {
                      setIndefiniteChargeModel({
                        ...indefiniteChargeModel,
                        recoverySource: c.value,
                      })
                    }}
                  />
                </div>
              </div>
              {selectedRecoveryMode === '1282' && (
                <div className="row mb-3">
                  <div className="col-6 d-flex align-items-center">
                    <label className="col-2 me-2 text-capitalize">
                      {localize('installments')}:
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      defaultValue={
                        indefiniteChargeModel?.installmentsBasisValue
                      }
                      onChange={c => {
                        setIndefiniteChargeModel({
                          ...indefiniteChargeModel,
                          installmentsBasisValue: c.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="col-6 d-flex align-items-center">
                    <label className="col-2 me-2 text-capitalize">
                      {localize('installmentsBasisValue')}:
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="DynamicChargeInstallmentsBasisValue"
                      value={indefiniteChargeModel?.installmentsBasisValue}
                      onChange={c => {
                        setIndefiniteChargeModel({
                          ...indefiniteChargeModel,
                          installmentsBasisValue: c.value,
                        })
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row mb-3">
                <div className="col-md-12">
                  <Tab
                    tabItems={[
                      {
                        label: localize('applicableCharges'),
                        item: (
                          <Commissions
                            prefix={'indefinite-charges'}
                            onCheckCommissions={c => {
                              setFinalCommissions(c)
                              setCommissionsToBeSelected(c)
                            }}
                            commissionsToBeSelected={commissionsToBeSelected}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id={'factor-in-loan-term'}
                  checked={indefiniteChargeModel?.factorInLoanTerm}
                  onCheck={v =>
                    setIndefiniteChargeModel({
                      ...indefiniteChargeModel,
                      factorInLoanTerm: v,
                    })
                  }
                />
                <label
                  htmlFor={'factor-in-loan-term'}
                  className="text-capitalize ms-2"
                >
                  {localize('factorInLoanTerm')}
                </label>
              </div>
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id={'compute-charge-on-top-up'}
                  checked={indefiniteChargeModel?.computeChargeOnTopUp}
                  onCheck={v =>
                    setIndefiniteChargeModel({
                      ...indefiniteChargeModel,
                      computeChargeOnTopUp: v,
                    })
                  }
                />
                <label
                  htmlFor={'compute-charge-on-top-up'}
                  className="text-capitalize ms-2"
                >
                  {localize('computeChargeOnTopUp')}
                </label>
              </div>
              <div className="d-flex align-items-center me-5">
                <Checkbox
                  id={'indefinite-islocked'}
                  checked={indefiniteChargeModel?.isLocked}
                  onCheck={v =>
                    setIndefiniteChargeModel({
                      ...indefiniteChargeModel,
                      isLocked: v,
                    })
                  }
                />
                <label
                  htmlFor={'indefinite-islocked'}
                  className="text-capitalize ms-2"
                >
                  {localize('isLocked')}
                </label>
              </div>
              <button
                className="btn btn-danger text-uppercase"
                onClick={onHideModal}
              >
                {localize('cancel')}
              </button>
              <button
                onClick={async () => {
                  await handleSubmit({
                    DynamicCharge: indefiniteChargeModel,
                    Commissions: finalCommissions.filter(x => x.checked),
                  })
                }}
                className={`btn btn-success text-uppercase px-4 ${
                  modalWaiting ? 'disabled' : ''
                }`}
                type="button"
              >
                {modalWaiting ? (
                  <i className="m-1 spinner-border spinner-sm text-light"></i>
                ) : (
                  localize(mode === 'add' ? 'create' : 'update')
                )}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  )
}

export default IndefiniteChargeModal
