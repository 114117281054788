/** @format */

import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../../../Helpers/webApi'

const ReturnSample = ({ mode, handleClose, fetchSamples, sample }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState({
    morguePatientSampleRecordStatusOption: 1,
  })

  const handleReturnSample = async () => {
    setIsBusy(true)

    if (!additionalInfo?.additionalRemarks)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please enter additional remarks'
      )

    const url = `/MorguePatientSample/return-morgue-patient-sample?option=${additionalInfo?.morguePatientSampleRecordStatusOption}`

    const { success } = await postData(
      url,
      {
        ...sample,
        additionalRemarks: additionalInfo?.additionalRemarks,
      },
      false
    )

    if (success) {
      showNotification('Return Morgue Sample', 'success')
      fetchSamples()
      handleClose()
      setAdditionalInfo({
        morguePatientSampleRecordStatusOption: 1,
      })
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      handleAction={handleReturnSample}
      actionText="update"
      handleClose={handleClose}
      modalMode={mode}
      modalTitle="returnMorguePatientSample"
      disableCreate={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-item-center gap-2">
            <div className="d-flex align-items-center">
              <label className="text-capitalize">
                {localize('additionalRemarks')}
              </label>

              <input
                type="text"
                className="form-control ms-2"
                value={additionalInfo?.additionalRemarks}
                onChange={e =>
                  setAdditionalInfo({
                    ...additionalInfo,

                    additionalRemarks: e.target.value,
                  })
                }
              />
            </div>

            <EnumsServerCombo
              sort
              enumsKey="MorguePatientSampleRecordStatusOption"
              value={additionalInfo?.morguePatientSampleRecordStatusOption}
              onChange={e => {
                setAdditionalInfo({
                  ...additionalInfo,
                  morguePatientSampleRecordStatusOption: e.value,
                })
              }}
            />
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="row g-3">
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('morguePatient')}
            </label>
            <input
              className="form-control"
              value={sample?.morguePatientFullName}
              disabled
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('technician')}
            </label>
            <input
              className="form-control"
              value={sample?.morgueTechnicianFullName}
              disabled
            />
          </div>

          <div className="col-md-12 d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('description')}
            </label>
            <textarea
              className="form-control"
              value={sample?.description}
              disabled
            />
          </div>
          <div className="col-md-12 d-flex align-items-center">
            <label className="col-1 text-capitalize">
              {localize('remarks')}
            </label>
            <textarea
              className="form-control"
              value={sample?.remarks}
              disabled
            />
          </div>
        </div>
      )}
    </ModalTemplate>
  )
}

export default ReturnSample
