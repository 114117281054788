/** @format */

import localize from '../../../../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  JournalVoucherEntryType,
  JournalVoucherType,
} from '../../../../../../../Global/enumeration'
import BranchLookup from '../../../../../../../Components/Lookup/BranchLookup'
import { useAuth } from 'react-oidc-context'
import PostingPeriodLookup from '../../../../../../../Components/Lookup/PostingPeriodLookup'
import { getData, putData } from '../../../../../../../Helpers/webApi'
import CustomerAccountLookup from '../../../../../../../Components/Lookup/CustomerAccountLookup'
import CostCentreLookup from '../../../../../../../Components/Lookup/CostCentreLookup'
import CustomDatePicker from '../../../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../../../Components/InputMasks/MaskNumber'
import { CreateModifyJournalVoucherEntries } from './JournalVoucherEntries'
import { VoucherDetailsGeneralLedger } from './VoucherDetailsGeneralLedger'
import { VoucherDetailsCustomer } from './VoucherDetailsCustomer'
import Tab from '../../../../../../../Components/Tabs/Tab'
import ChartOfAccountsLookup from '../../../../../../../Components/Lookup/ChartOfAccountsLookup'
import {
  formatCurrency,
  stripNonNumericCharacters,
} from '../../../../../../../Helpers/formatters'
import { generateGuid } from '../../../../../../../Helpers/extensions'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'

export const CreateJournalVoucherModal = ({
  actionClickedAt,
  handleOnActionClicked,
  mode,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const [prevActionClickedAt, setPrevActionClickedAt] =
    useState(actionClickedAt)

  const auth = useAuth()

  const [selectedBranch, setSelectedBranch] = useState({
    description: auth?.user.profile?.cbs_branch,
    id: '',
  })

  const [selectedJournalVoucherEntry, setSelectedJournalVoucherEntry] =
    useState(Object.keys(JournalVoucherEntryType)[0])

  const [selectedJournalVoucherType, setSelectedJournalVoucherType] = useState(
    Object.keys(JournalVoucherType)[0]
  )

  const [valueDate, setValueDate] = useState(null)

  const [selectedChartOfAccount, setSelectedChartOfAccount] = useState(null)
  const [refreshVoucherJournalDetails, setRefreshVoucherJournalDetails] =
    useState(false)
  const [currentPostingPeriod, setCurrentPostingPeriod] = useState(null)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
  const [selectedCostCenter, setSelectedCostCenter] = useState(null)
  const [journalVoucherTotalValue, setJournalVoucherTotalValue] = useState(0)
  const [journalVoucherEntryModel, setJournalVoucherEntryModel] = useState(null)
  const [journalVoucherModel, setJournalVoucherModel] = useState(null)
  const [journalVoucherEntries, setJournalVoucherEntries] = useState([])
  const [finalJournalVoucherEntryModel, setFinalJournalVoucherEntryModel] =
    useState({ type: selectedJournalVoucherType })

  const getCurrentPostingPeriod = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      null,
      false
    )
    if (success) {
      setCurrentPostingPeriod(data)
    }
    setIsBusy(false)
  }

  const handleOnChangeValueDate = async e => {
    setIsBusy(true)
    const { success, data } = await putData(
      `/PostingPeriod/evaluate-value-date-validity/?valueDate=${e}`,
      {},
      false
    )
    if (success) {
      setValueDate(e)
    } else {
      setValueDate(null)
    }
    setIsBusy(false)
  }

  if (prevActionClickedAt !== actionClickedAt && mode === 'add') {
    setPrevActionClickedAt(actionClickedAt)
    handleOnActionClicked({
      journal: {
        ...finalJournalVoucherEntryModel,
        primaryDescription: journalVoucherModel?.primaryDescription || '',
        type: selectedJournalVoucherType || '',
        secondaryDescription: journalVoucherModel?.secondaryDescription || '',
        totalValue: journalVoucherTotalValue || 0,
        reference: journalVoucherModel?.reference || '',
      },
      entries: journalVoucherEntries,
    })
  }

  useEffect(() => {
    getCurrentPostingPeriod()
  }, [])

  useEffect(() => {
    setGlobalValidationErrors({})
  }, [mode])

  const handleOnAddJournalVoucherEntry = () => {
    if (selectedCostCenter) {
      setFinalJournalVoucherEntryModel({
        ...finalJournalVoucherEntryModel,
        costCenterId: selectedCostCenter.Id,
        costCenterDescription: selectedCostCenter.Description,
      })
    }

    switch (selectedJournalVoucherEntry) {
      case Object.keys(JournalVoucherEntryType)[0]:
        if (journalVoucherEntryModel.GeneralLedgerAccount) {
          setFinalJournalVoucherEntryModel({
            ...finalJournalVoucherEntryModel,
            chartOfAccountId:
              journalVoucherEntryModel.GeneralLedgerAccount.chartOfAccountId,
            chartOfAccountDescription:
              journalVoucherEntryModel.GeneralLedgerAccount
                .chartOfAccountDescription,
            chartOfAccountAccountCode:
              journalVoucherEntryModel.GeneralLedgerAccount.code,
          })
        }
        break

      case Object.keys(JournalVoucherEntryType)[1]:
        if (journalVoucherEntryModel.CustomerInformation) {
          setFinalJournalVoucherEntryModel({
            customerAccountId: journalVoucherEntryModel.CustomerInformation.id,
            customerAccountBranchCode:
              journalVoucherEntryModel.CustomerInformation.branchCode,
            customerAccountCustomerSerialNumber:
              journalVoucherEntryModel.CustomerInformation.customerSerialNumber,
            customerAccountCustomerAccountTypeProductCode:
              journalVoucherEntryModel.CustomerInformation
                .customerAccountTypeProductCode,
            customerAccountCustomerAccountTypeTargetProductCode:
              journalVoucherEntryModel.CustomerInformation
                .customerAccountTypeTargetProductCode,
            customerAccountCustomerMembershipClassCustomerType:
              journalVoucherEntryModel.CustomerInformation
                .customerMembershipClassCustomerType,
            customerAccountCustomerIndividualSalutation:
              journalVoucherEntryModel.CustomerInformation
                .customerIndividualSalutation,
            customerAccountCustomerIndividualFirstName:
              journalVoucherEntryModel.CustomerInformation
                .customerIndividualFirstName,
            customerAccountCustomerIndividualLastName:
              journalVoucherEntryModel.CustomerInformation
                .customerIndividualLastName,
            customerAccountCustomerNonIndividualDescription:
              journalVoucherEntryModel.CustomerInformation
                .customerNonIndividualDescription,
            customerAccountCustomerReference1:
              journalVoucherEntryModel.CustomerInformation.customerReference1,
            customerAccountCustomerReference2:
              journalVoucherEntryModel.CustomerInformation.customerReference2,
            customerAccountCustomerReference3:
              journalVoucherEntryModel.CustomerInformation.customerReference3,
            chartOfAccountId:
              journalVoucherEntryModel.CustomerInformation
                .customerAccountTypeTargetProductChartOfAccountId,
            chartOfAccountAccountType:
              journalVoucherEntryModel.CustomerInformation
                .customerAccountTypeTargetProductChartOfAccountType,
            chartOfAccountAccountCode:
              journalVoucherEntryModel.CustomerInformation
                .customerAccountTypeTargetProductChartOfAccountCode,
            chartOfAccountAccountName:
              journalVoucherEntryModel.CustomerInformation
                .customerAccountTypeTargetProductChartOfAccountName,
          })
        }
        break

      default:
    }

    setJournalVoucherEntries([
      ...journalVoucherEntries,
      {
        ...journalVoucherEntryModel.CustomerInformation,
        clientId: generateGuid(),
        customerAccountId:
          selectedJournalVoucherEntry ===
          Object.keys(JournalVoucherEntryType)[0]
            ? null
            : journalVoucherEntryModel.CustomerInformation.id,
        amount:
          selectedJournalVoucherEntry ===
          Object.keys(JournalVoucherEntryType)[0]
            ? journalVoucherEntryModel.GeneralLedgerAccount
                .generalLedgerAccountAmount
            : journalVoucherEntryModel.CustomerInformation.amount,
      },
    ])

    setRefreshVoucherJournalDetails(!refreshVoucherJournalDetails)
  }

  const computeApportioned = () => {
    let apportioned = null
    let shortages = null

    apportioned = journalVoucherEntries.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) +
        Number(stripNonNumericCharacters(currentValue?.amount)),
      0
    )

    shortages = Number(journalVoucherTotalValue) - Number(apportioned)

    return {
      apportioned: apportioned ? formatCurrency(apportioned, 2, 2, false) : 0,
      shortages: shortages ? formatCurrency(shortages, 2, 2, false) : 0,
    }
  }

  const handleOnChangeJournalVoucherType = r => {
    setSelectedJournalVoucherType(r)
    setFinalJournalVoucherEntryModel({
      ...finalJournalVoucherEntryModel,
      type: r,
    })
  }

  const onRemoveJournalVoucherEntry = r => {
    setJournalVoucherEntries(
      journalVoucherEntries.filter(x => x.clientId !== r.clientId)
    )
    setRefreshVoucherJournalDetails(!refreshVoucherJournalDetails)
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="row">
              <div className="col-12">
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('voucherType')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="JournalVoucherType"
                      onChange={e => {
                        handleOnChangeJournalVoucherType(e.value)
                      }}
                      value={selectedJournalVoucherType}
                      sort
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('branch')}
                    </label>
                    <BranchLookup
                      displayValue={selectedBranch.description}
                      onSelect={r => {
                        setSelectedBranch({
                          ...selectedBranch,
                          id: r.id,
                          description: r.description,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('postingPeriod')}
                    </label>
                    <PostingPeriodLookup
                      displayValue={currentPostingPeriod?.description}
                      onSelect={r => {
                        setFinalJournalVoucherEntryModel({
                          ...finalJournalVoucherEntryModel,
                          postingPeriodId: r.id,
                        })
                        setCurrentPostingPeriod(r)
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div
                    validation-message={
                      globalValidationErrors?.[
                        'JournalVoucherDTO.ChartOfAccountId'
                      ]?.message
                    }
                    className="col d-flex align-items-center with-validation"
                  >
                    {selectedJournalVoucherType ===
                      Object.keys(JournalVoucherType)[0] ||
                    selectedJournalVoucherType ===
                      Object.keys(JournalVoucherType)[1] ? (
                      <>
                        <label className="col-3 text-capitalize">
                          {localize('chartOfAccount')}
                        </label>
                        <ChartOfAccountsLookup
                          displayValue={selectedChartOfAccount?.description}
                          onSelect={r => {
                            clearValidation(
                              'JournalVoucherDTO.ChartOfAccountId'
                            )
                            setSelectedChartOfAccount(r)
                            setFinalJournalVoucherEntryModel({
                              ...finalJournalVoucherEntryModel,
                              customerAccountId: r.id,
                              chartOfAccountId: r.id,
                              chartOfAccountAccountName: r.name,
                            })
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <label className="col-3 text-capitalize">
                          {localize('fullAccountNumber')}
                        </label>
                        <CustomerAccountLookup
                          displayValue={
                            selectedCustomerAccount?.fullAccountNumber
                          }
                          onSelect={r => {
                            clearValidation(
                              'JournalVoucherDTO.ChartOfAccountId'
                            )
                            setSelectedCustomerAccount(r)
                            setFinalJournalVoucherEntryModel({
                              ...finalJournalVoucherEntryModel,
                              customerAccountId: r.id,
                              customerAccountBranchCode: r.branchCode,
                              customerAccountCustomerSerialNumber:
                                r.customerSerialNumber,
                              customerAccountCustomerAccountTypeProductCode:
                                r.customerAccountTypeProductCode,
                              customerAccountCustomerAccountTypeTargetProductCode:
                                r.customerAccountTypeTargetProductCode,
                              chartOfAccountId:
                                r.customerAccountTypeTargetProductChartOfAccountId,
                              chartOfAccountAccountType:
                                r.customerAccountTypeTargetProductChartOfAccountId,
                              chartOfAccountAccountCode:
                                r.customerAccountTypeTargetProductChartOfAccountType,
                              chartOfAccountAccountName:
                                r.customerAccountTypeTargetProductChartOfAccountName,
                            })
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="col d-flex align-items-center">
                    {selectedJournalVoucherType ===
                      Object.keys(JournalVoucherType)[2] ||
                    selectedJournalVoucherType ===
                      Object.keys(JournalVoucherType)[3] ? (
                      <>
                        <label className="col-3 text-capitalize">
                          {localize('productName')}
                        </label>
                        <input
                          className="form-control"
                          disabled
                          value={
                            selectedCustomerAccount?.customerAccountTypeTargetProductDescription
                          }
                        />
                      </>
                    ) : (
                      <>
                        <label className="col-3 text-capitalize">
                          {localize('costDistributionRule')}
                        </label>
                        <input
                          className="form-control"
                          disabled
                          value={
                            selectedChartOfAccount?.costDistributionRuleDescription
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('costCenter')}
                    </label>
                    <CostCentreLookup
                      displayValue={selectedCostCenter?.description}
                      onSelect={r => {
                        setFinalJournalVoucherEntryModel({
                          ...finalJournalVoucherEntryModel,
                          costCenterId: r.id,
                        })
                        setSelectedCostCenter(r)
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('valueDate')}
                    </label>
                    <CustomDatePicker
                      name={'change-value-date'}
                      defaultDate={valueDate || ''}
                      onDateChange={handleOnChangeValueDate}
                    />
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('totalValue')}
                    </label>
                    <MaskNumber
                      defaultMaskValue={journalVoucherTotalValue}
                      onMaskNumber={r => {
                        setJournalVoucherTotalValue(
                          stripNonNumericCharacters(r.target.value)
                        )
                        setFinalJournalVoucherEntryModel({
                          ...finalJournalVoucherEntryModel,
                          totalValue: stripNonNumericCharacters(r.target.value),
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors?.[
                        'JournalVoucherDTO.PrimaryDescription'
                      ]?.message
                    }
                  >
                    <label className="col-3 text-capitalize">
                      {localize('primaryDescription')}
                    </label>
                    <input
                      className="form-control"
                      defaultValue={journalVoucherModel?.primaryDescription}
                      onChange={e => {
                        clearValidation('JournalVoucherDTO.PrimaryDescription')
                        setJournalVoucherModel({
                          ...journalVoucherModel,
                          primaryDescription: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors?.[
                        'JournalVoucherDTO.SecondaryDescription'
                      ]?.message
                    }
                  >
                    <label className="col-3 text-capitalize">
                      {localize('secondaryDescription')}
                    </label>
                    <input
                      className="form-control"
                      defaultValue={journalVoucherModel?.secondaryDescription}
                      onChange={e => {
                        clearValidation(
                          'JournalVoucherDTO.SecondaryDescription'
                        )
                        setJournalVoucherModel({
                          ...journalVoucherModel,
                          secondaryDescription: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors?.['JournalVoucherDTO.Reference']
                        ?.message
                    }
                  >
                    <label className="col-3 text-capitalize">
                      {localize('reference')}
                    </label>
                    <input
                      defaultValue={journalVoucherModel?.reference}
                      className="form-control"
                      onChange={e => {
                        clearValidation('JournalVoucherDTO.Reference')
                        setJournalVoucherModel({
                          ...journalVoucherModel,
                          reference: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div
                  className="p-2 mt-4"
                  style={{
                    border: 'solid 1px #0000001f',
                    borderRadius: 10,
                    position: 'relative',
                  }}
                >
                  <div
                    className="bg-white px-2 row text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 120,
                    }}
                  >
                    {localize('voucherEntryDetails')}
                  </div>
                  <div className="row mb-3" style={{ marginTop: -10 }}>
                    <div className="col d-flex align-items-center">
                      <label htmlFor="" className="text-capitalize me-3">
                        {localize('entryType')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="JournalVoucherEntryType"
                        onChange={e => {
                          setSelectedJournalVoucherEntry(e.value)
                        }}
                        value={selectedJournalVoucherEntry}
                        sort
                      />
                    </div>
                  </div>
                  {selectedJournalVoucherEntry ===
                  Object.keys(JournalVoucherEntryType)[0] ? (
                    <VoucherDetailsGeneralLedger
                      refreshVoucherJournalDetails={
                        refreshVoucherJournalDetails
                      }
                      journalVoucherEntryModel={journalVoucherEntryModel}
                      setJournalVoucherEntryModel={setJournalVoucherEntryModel}
                    />
                  ) : (
                    <VoucherDetailsCustomer
                      refreshVoucherJournalDetails={
                        refreshVoucherJournalDetails
                      }
                      journalVoucherEntryModel={journalVoucherEntryModel}
                      setJournalVoucherEntryModel={setJournalVoucherEntryModel}
                    />
                  )}
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2 mt-3 mb-3"
                    style={{ borderRadius: 5 }}
                  >
                    <button
                      onClick={handleOnAddJournalVoucherEntry}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('add')}
                    </button>
                  </div>

                  <Tab
                    tabItems={[
                      {
                        label: localize('voucherEntries'),
                        item: (
                          <CreateModifyJournalVoucherEntries
                            handleOnRemove={onRemoveJournalVoucherEntry}
                            data={journalVoucherEntries}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
                  <div className="d-flex align-items-center me-5">
                    <label htmlFor="" className="text-capitalize ms-2 me-2">
                      {localize('apportionment')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={computeApportioned().apportioned}
                    />
                  </div>
                  <div className="d-flex align-items-center me-5">
                    <label htmlFor="" className="text-capitalize ms-2 me-2">
                      {localize('shortage')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={computeApportioned().shortages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </>
  )
}
