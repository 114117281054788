/** @format */
import { CreateRefundBatchModal } from './CreateRefundBatchModal'
import { EditRefundBatchModal } from './EditRefundBatchModal'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import {
  QueuePriority,
  months,
  BatchAuditOption,
  batchAuthOptions,
} from '../../../../../../Global/enumeration'
import { useEffect, useState } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { getData, postData } from '../../../../../../Helpers/webApi'
import localize from '../../../../../../Global/Localize'
import { ViewRefundBatchModal } from './ViewRefundBatchModal'
import { VerifyRefundBatchModal } from './VerifyRefundBatchModal'
import { AuthorizeRefundBatchModal } from './AuthorizeRefundBatchModal'
import Swal from 'sweetalert2'
import signalRService from '../../../../../../Helpers/signalR'
import { COLORS } from '../../../../../../Helpers/constants'

function RefundBatchModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  setSelectedRefundBatch,
  onSetMode,
  selectedRefundBatch,
}) {
  const [finalRefundBatchModel, setFinalRefundBatchModal] = useState({
    postingPeriodId: '',
    branchId: '',
    postingPeriodDescription: '',
    reference: '',
    month: Object.keys(months).find(
      key => months[key].name === months[new Date().getMonth() + 1].name
    ),
    priority: Object.keys(QueuePriority)[3],
  })
  const [gbv, setGbv] = GlobalValidationErrors.use()

  const [verificationModels, setVerificationModels] = useState({
    auditOption: Object.keys(BatchAuditOption)[0],
    auditRemarks: '',
  })

  const [authorizationModels, setAuthorizationModels] = useState({
    authOption: Object.keys(batchAuthOptions)[0],
    authorizationRemarks: '',
  })

  const getCurrentPostingPeriod = async () => {
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      null,
      false
    )
    if (success) {
      setFinalRefundBatchModal({
        ...finalRefundBatchModel,
        postingPeriodId: data?.id,
        postingPeriodDescription: data?.description,
      })
    }
  }

  useEffect(() => {
    setGbv({})
  }, [mode])

  useEffect(() => {
    //eslint-disable-next-line
    return () => {
      getCurrentPostingPeriod()
    }
  }, [])

  const clearValidation = column => {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const showVerificationDialog = () => {
    Swal.fire({
      title: 'Refund Batch Authorization',
      text: 'Do you want to request for authorization?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: COLORS.BLUE,
      cancelButtonColor: COLORS.RED,
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.isConfirmed) {
        signalRService.notifyUsersInSystemPermissionType(
          '44996',
          `New Refund Batch Authorization Requested - # ${selectedRefundBatch?.paddedBatchNumber}`
        )
        showSuccessModal()
      } else {
        showSuccessModal()
      }
    })
  }

  const showSuccessModal = () => {
    Swal.fire({
      icon: 'success',
      title: 'Operation Successful',
      text: 'Create Journal Voucher',
    })
  }

  async function handleSubmit() {
    if (mode === 'add') {
      const { success } = await postData(
        '/RefundBatch/add-refund-batch',
        {
          ...finalRefundBatchModel,
        },
        false,
        'Refund Batch'
      )

      if (success) {
        onSetMode(null)
        onUpdateRefreshAt()
      }
    }
    if (mode === 'verify') {
      const { success } = await postData(
        '/RefundBatch/audit-refund-batch',
        {
          RefundBatch: {
            ...verificationModels,
            id: selectedRefundBatch?.id,
          },
          BatchAuditOption: verificationModels?.auditOption || 1,
        },
        false,
        'Refund Batch'
      )

      if (success) {
        showVerificationDialog()
        onSetMode(null)
        onUpdateRefreshAt()
      }
    }
    if (mode === 'authorize') {
      const { success } = await postData(
        '/RefundBatch/authorize-refund-batch',
        {
          RefundBatch: {
            ...authorizationModels,
            id: selectedRefundBatch?.id,
          },
          BatchAuthOption: authorizationModels?.authOption,
        },
        false,
        'Refund Batch'
      )

      if (success) {
        onSetMode(null)
        onUpdateRefreshAt()
      }
    }
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('refundBatch')}
            handleAction={handleSubmit}
            hideUpdate={mode === 'view' || mode === 'edit'}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateRefundBatchModal
                validationBag={gbv}
                clearValidation={clearValidation}
                finalRefundBatchModel={finalRefundBatchModel}
                setFinalRefundBatchModal={setFinalRefundBatchModal}
              />
            )}
            {mode === 'edit' && (
              <EditRefundBatchModal
                setSelectedRefundBatch={setSelectedRefundBatch}
                selectedRefundBatch={selectedRefundBatch}
              />
            )}
            {mode === 'view' && (
              <ViewRefundBatchModal selectedRefundBatch={selectedRefundBatch} />
            )}
            {mode === 'verify' && (
              <VerifyRefundBatchModal
                clearValidation={clearValidation}
                verificationModels={verificationModels}
                validationErrors={gbv}
                setVerificationModels={setVerificationModels}
                selectedRefundBatch={selectedRefundBatch}
              />
            )}
            {mode === 'authorize' && (
              <AuthorizeRefundBatchModal
                clearValidation={clearValidation}
                validationErrors={gbv}
                authorizationModels={authorizationModels}
                setAuthorizationModels={setAuthorizationModels}
                selectedRefundBatch={selectedRefundBatch}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default RefundBatchModal
