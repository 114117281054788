/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import Tab from '../../../../../../Components/Tabs/Tab'
import LedgerEntries from './LedgerEntries'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { batchAuthOptions } from '../../../../../../Global/enumeration'
import { putData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'

import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function ViewGLBatch({
  authorize,
  verify,
  batch,
  closeModal,
  disableEntriesContext,
}) {
  const [selectedBatch, setSelectedBatch] = useState(batch)
  const [gLOption, setGLOption] = useState(1) // defaulted to post

  const [gve, setGve] = GlobalValidationErrors.use()

  async function handleUpdate() {
    let url = `/generalLedger/${
      authorize ? 'authorize' : 'audit'
    }-general-ledger`
    let payload = {
      generalLedger: selectedBatch,
      generalLedgerAuthOption: gLOption,
    }
    const { success } = await putData(url, payload)
    if (success) {
      Swal.fire(
        'Success',
        `General Ledger batch ${
          authorize ? 'authorized' : 'audited'
        } successfuly`,
        'success'
      )
    }
  }

  return (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white">
        <div className="text-capitalize d-flex align-items-center mb-2">
          <h4 style={{ marginTop: 5 }}>
            {localize(authorize ? 'authorize' : verify ? 'verify' : 'view')}
          </h4>
          <i className="uil uil-angle-right mx-2"></i>
          <span className="text-muted fs-6 text-uppercase">
            {localize('generalLedger')}
          </span>
          <button type="button" className="minimize">
            <span aria-hidden="true">_</span>
          </button>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="bg-white">
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('ledger')} #
            </label>
            <input
              type="text"
              value={selectedBatch?.paddedLedgerNumber}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('postingPeriod')}
            </label>
            <input
              type="text"
              value={selectedBatch?.postingPeriodDescription}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('totalValue')}
            </label>
            <input
              type="text"
              style={{ textAlign: 'end' }}
              value={selectedBatch?.totalValueFormatted}
              className="form-control ms-3"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div
            className="col d-flex align-items-center with-validation"
            validation-message={gve[``]}
          >
            <label htmlFor="" className="text-capitalize col-1">
              {localize('remarks')}
            </label>
            <input
              type="text"
              value={selectedBatch?.remarks}
              className="ms-2 form-control"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              value={selectedBatch?.createdBy}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              value={formatDate(selectedBatch?.createdDate)}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('status')}
            </label>
            <input
              type="text"
              style={{ textAlign: 'end' }}
              value={selectedBatch?.statusDescription}
              className="form-control ms-3"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('verifiedBy')}
            </label>
            <input
              type="text"
              value={selectedBatch?.verifiedBy}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('verifiedDate')}
            </label>
            <input
              type="text"
              value={formatDate(selectedBatch?.verifiedDate)}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('verificationRemarks')}
            </label>
            <input
              type="text"
              style={{ textAlign: 'end' }}
              value={selectedBatch?.verificationRemarks}
              className="form-control ms-3"
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('authorizedBy')}
            </label>
            <input
              type="text"
              value={selectedBatch?.authorizedBy}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('authorizedDate')}
            </label>
            <input
              type="text"
              value={formatDate(selectedBatch?.authorizedDate)}
              className="form-control ms-3"
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize col-3">
              {localize('authorizationRemarks')}
            </label>
            <input
              type="text"
              style={{ textAlign: 'end' }}
              value={selectedBatch?.authorizationRemarks}
              className="form-control ms-3"
              disabled
            />
          </div>
        </div>
        <Tab
          tabItems={[
            {
              label: localize('ledgerEntries'),
              item: (
                <LedgerEntries
                  batch={selectedBatch}
                  disableContext={disableEntriesContext}
                />
              ),
            },
          ]}
          preload
        />
        {(verify || authorize) && (
          <div className="row mt-3 bg-light p-3 d-flex align-items-center justify-content-end">
            <div className="col-md-4">
              <div className="row">
                <div
                  className="col-12 d-flex align-items-center with-validation"
                  validation-message={
                    gve[
                      `GeneralLedger.${
                        authorize ? 'AuthorizationRemarks' : 'AuditRemarks'
                      }`
                    ]?.message
                  }
                >
                  <label htmlFor="" className="col-3 text-capitalize">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      authorize
                        ? selectedBatch?.authorizationRemarks
                        : selectedBatch?.auditRemarks
                    }
                    onChange={e => {
                      setGve({})
                      setSelectedBatch({
                        ...selectedBatch,
                        [authorize ? 'authorizationRemarks' : 'auditRemarks']:
                          e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col-12 d-flex align-items-center mt-2">
                  <label htmlFor="" className="col-3 text-capitalize"></label>
                  <div className="d-flex align-items-center">
                    <Checkbox
                      checked={selectedBatch?.suppressMakerCheckerValidation}
                      onCheck={v =>
                        setSelectedBatch({
                          ...selectedBatch,
                          suppressMakerCheckerValidation: v,
                        })
                      }
                    />
                    <label htmlFor="" className="text-capitalize ms-1">
                      {localize('suppressMakerCheckerValidation')}?
                    </label>
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center">
                  <label htmlFor="" className="col-3 text-capitalize">
                    {localize('action')}
                  </label>

                  <div className="col-9 d-flex align-items-center gap-2">
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="BatchAuthOptions"
                      value={gLOption}
                      onChange={v => setGLOption(v.value)}
                    />
                    <button
                      className="btn btn-success px-5"
                      onClick={handleUpdate}
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ViewGLBatch
