/** @format */

import React, { useState } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import { useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import Loader from '../../../../../../Components/Loaders/Loader'
import FlagUnFlagVoucher from './FlagUnFlagVoucher/FlagUnFlagVoucher'

const initialValue = {
  paymentVoucherManagementAction: 0,
}

const PaymentVouchers = ({ chequeBookId }) => {
  const [paymentVouchers, setPaymentVouchers] = useState([])
  const [voucher, setVoucher] = useState(initialValue)
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    chequeBookId: chequeBookId ?? null,
  })

  const columns = [
    {
      label: localize('voucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('payee'),
      template: r => <>{r.payee}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('writeDate'),
      template: r => <>{formatDate(r.writeDate)}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('paid') + '/' + localize('flaggedBy'),
      template: r => <>{r.paidBy}</>,
    },
    {
      label: localize('paid') + '/' + localize('flaggedDate'),
      template: r => <>{formatDate(r.paidDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const fetchVouchers = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/ChequeBook/find-payment-vouchers-by-cheque-book-id',
      reqParams,
      false
    )

    if (success) {
      setPaymentVouchers(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleSelectVoucher = (r, m) => {
    setVoucher({
      ...r,
      ...initialValue,
    })
    setMode(m)
  }

  const handleClose = () => {
    setMode(null)
    setVoucher(initialValue)
  }

  useEffect(() => {
    if (chequeBookId) fetchVouchers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, chequeBookId])

  return (
    <>
      {mode === 'flag' && (
        <FlagUnFlagVoucher
          fetchVouchers={fetchVouchers}
          handleClose={handleClose}
          mode={mode}
          setVoucher={setVoucher}
          voucher={voucher}
        />
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center float-right my-2">
              <label className="text-capitalize" htmlFor="checkBooksPageSize">
                {localize('recordsPerPage')}
              </label>
              <select
                id="signatoriesPageSize"
                className="form-select ms-3"
                style={{ width: 'fit-content' }}
                value={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageIndex: 0,
                    pageSize: e.target.value,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div
              className="col d-flex align-items-center justify-content-center gap-2"
              style={{ maxWidth: 'fit-content' }}
            >
              <label
                style={{ minWidth: 'fit-content' }}
                className="text-capitalize me-2"
              >
                {localize('search')}
              </label>
              <input
                type="text"
                className="form-control"
                value={reqParams?.filterText}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                    pageIndex: 0,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') fetchVouchers()
                }}
              />
              <button
                className="btn btn-primary ms-2"
                onClick={() => {
                  fetchVouchers()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
          <SimpleTable
            data={paymentVouchers?.pageCollection}
            columns={columns}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleSelectVoucher(r, 'flag')
                  }}
                >
                  <i className="mdi mdi-flag-variant"></i>
                  <span>{localize('flag')}</span>
                </div>
              </>
            )}
          />
          <Pager
            itemsCount={paymentVouchers.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default PaymentVouchers
