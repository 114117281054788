/** @format */
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'

export const Settings = ({ workflowSetting, setWorkflowSetting }) => {
  return (
    <div className="row d-flex align-items-center">
      <div className=" d-flex align-items-center col-md-12">
        <Checkbox
          id={`biometrics-custom`}
          checked={workflowSetting?.workflowSetting?.requireBiometrics}
          margined
          onCheck={c => {
            setWorkflowSetting({
              ...workflowSetting,
              workflowSetting: {
                ...workflowSetting.workflowSetting,
                requireBiometrics: c,
              },
            })
          }}
        />
        <label
          htmlFor={`biometrics-custom`}
          className={`fw-normal ms-2 cursor-pointer text-capitalize ${
            workflowSetting?.workflowSetting?.requireBiometrics
              ? 'text-primary fw-bold'
              : ''
          }`}
        >
          Require Biometrics ?
        </label>
      </div>
      <div className=" d-flex align-items-center col-md-12">
        <Checkbox
          id={`bypass-role-based-approvals-custom`}
          checked={
            workflowSetting?.workflowSetting?.bypassRoleBasedApprovalLevels
          }
          margined
          onCheck={c => {
            setWorkflowSetting({
              ...workflowSetting,
              workflowSetting: {
                ...workflowSetting.workflowSetting,
                bypassRoleBasedApprovalLevels: c,
              },
            })
          }}
        />
        <label
          htmlFor={`bypass-role-based-approvals-custom`}
          className={`fw-normal ms-2 cursor-pointer ${
            workflowSetting?.workflowSetting?.bypassRoleBasedApprovalLevels
              ? 'text-primary fw-bold'
              : ''
          }`}
        >
          Bypass Role-Based Approval Levels?
        </label>
      </div>
      <div className=" d-flex align-items-center col-md-12">
        <Checkbox
          id={`Enforce-role-based-approvals-custom`}
          checked={
            workflowSetting?.workflowSetting
              ?.enforceRoleBasedApprovalAfterDepartmentalLevels
          }
          margined
          onCheck={c => {
            setWorkflowSetting({
              ...workflowSetting,
              workflowSetting: {
                ...workflowSetting.workflowSetting,
                enforceRoleBasedApprovalAfterDepartmentalLevels: c,
              },
            })
          }}
        />
        <label
          htmlFor={`Enforce-role-based-approvals-custom`}
          className={`fw-normal ms-2 cursor-pointer ${
            workflowSetting?.workflowSetting
              ?.enforceRoleBasedApprovalAfterDepartmentalLevels
              ? 'text-primary fw-bold'
              : ''
          }`}
        >
          Enforce Role-Based Approval After Departmental Levels?
        </label>
      </div>
      <div className="col-md-8 d-flex align-items-center">
        <label className="col-3 text-capitalize">
          {localize('requiredDepartmentalApprovals')}
        </label>
        <MaskNumber
          numeralThousandsGroupStyle={'none'}
          defaultMaskValue={
            workflowSetting?.workflowSetting?.requiredDepartmentalApprovals
          }
          onMaskNumber={e =>
            setWorkflowSetting({
              ...workflowSetting,
              workflowSetting: {
                ...workflowSetting.workflowSetting,
                requiredDepartmentalApprovals: e.target.value,
              },
            })
          }
        />
      </div>
    </div>
  )
}
