/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Pager from '../../../../Components/Tables/Pager'
import MicroCreditOfficersModal from './MicroCreditOfficersModal'
import { formatDate } from '../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { employeeFilter } from '../../../../Helpers/constants'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'

function MicroCreditOfficers() {
  const [tableData, setTableData] = useState([])
  const [waiting, setWaiting] = useState(false)
  const [search, setSearch] = useState('')

  const [sortedBy, setSortedBy] = useState(null)
  const [selectedMicroCreditOfficer, setSelectedMicroCreditOfficer] =
    useState(null)

  const columns = [
    {
      label: 'employeeName',
      template: r => <>{r?.employeeCustomerFullName}</>,
    },
    {
      label: 'nationality',
      template: r => <>{r?.employeeCustomerIndividualNationalityDescription}</>,
    },
    {
      label: 'identityCardNumber',
      template: r => <>{r?.employeeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: 'gender',
      template: r => <>{r?.employeeCustomerIndividualGenderDescription}</>,
    },
    {
      label: 'maritalStatus',
      template: r => (
        <>{r?.employeeCustomerIndividualMaritalStatusDescription}</>
      ),
    },
    {
      label: 'serialNumber',
      template: r => <>{r?.paddedEmployeeCustomerSerialNumber}</>,
    },
    {
      label: 'designation',
      template: r => <>{r?.employeeDesignationDescription}</>,
    },
    {
      label: 'branch',
      template: r => <>{r?.employeeBranchDescription}</>,
    },
    {
      label: 'department',
      template: r => <>{r?.employeeDepartmentDescription}</>,
    },
    {
      label: 'employeeType',
      template: r => <>{r?.employeeEmployeeTypeDescription}</>,
    },
    {
      label: 'employmentStatus',
      template: r => <>{r?.employeeEmploymentStatusDescription}</>,
    },
    {
      label: 'remarks',
      template: r => <>{r?.remarks}</>,
    },
    {
      label: 'isLocked',
      template: r => <>{r?.isLocked.toString()}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    employeeFilter: employeeFilter.MEMBER_NUMBER,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setWaiting(true)
    const { success, data } = await getData(
      '/MicroCreditOfficer/find-micro-credit-officers-by-filter-in-page',
      reqParams,
      false,
    )
    if (success) {
      setWaiting(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setWaiting(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const handleOnEdit = r => {
    setSelectedMicroCreditOfficer({ ...r })
    setMode('edit')
  }

  const handleOnView = r => {
    setMode('view')
  }

  const handleOnAdd = async r => {
    setSelectedMicroCreditOfficer(null)
    setMode('add')
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('microCreditManagement'), localize('officers')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {waiting ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3">
                      <div className="col d-flex align-items-center">
                        <label
                          className={'text-capitalize me-1'}
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="EmployeeFilter"
                          value={reqParams?.employeeFilter}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              employeeFilter: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="btn btn-primary ms-1"
                          onClick={() => {
                            setReqParams({
                              ...reqParams,
                              text: search,
                              pageIndex: 0,
                            })
                          }}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={e => (
                        <>
                          <div
                            onClick={() => {
                              handleOnEdit(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <MicroCreditOfficersModal
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        setSelectedMicroCreditOfficer={setSelectedMicroCreditOfficer}
        selectedMicroCreditOfficer={selectedMicroCreditOfficer}
        onSetMode={m => setMode(m)}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default MicroCreditOfficers
