/** @format */

import Loader from '../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React from 'react'

export const EmolumentTypesSavingsProducts = ({
  selectedEmolumentType,
  setSelectedEmolumentType,
  prefix,
  disabled,
  isBusy,
}) => {
  const handleCheckChequeTypeCommissions = (savingsProducts, checkValue) => {
    const com = [...selectedEmolumentType?.savingsProducts].map(comm => {
      if (comm.id === savingsProducts.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    setSelectedEmolumentType({ ...selectedEmolumentType, savingsProducts: com })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedEmolumentType?.savingsProducts.map(savingsProducts => (
            <li
              key={savingsProducts.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${savingsProducts.id}`}
                checked={savingsProducts.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckChequeTypeCommissions(savingsProducts, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${savingsProducts.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  savingsProducts.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {savingsProducts.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
