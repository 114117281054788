/** @format */

import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'

function LoanCaseCollaterals({ collaterals }) {
  const [local, setLocal] = useState(collaterals)

  useEffect(() => {
    setLocal(collaterals)
  }, [collaterals])

  const columns = [
    {
      label: localize('collateralStatus'),
      template: r => <>{r?.customerDocumentCollateralStatusDescription}</>,
    },
    {
      label: localize('documentTitle'),
      template: r => <>{r?.customerDocumentFileTitle}</>,
    },
    {
      label: localize('value'),
      template: r => <>{formatCurrency(r?.value)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  return (
    <SimpleTable
      columns={columns}
      data={local}
      contextMenu={r => (
        <>
          <div className="ctxt-menu-item">
            <i className="uil uil-eye"></i>
            <span htmlFor="" className="text-capitalize">
              {localize('view')}
            </span>
          </div>
        </>
      )}
    />
  )
}

export default LoanCaseCollaterals
