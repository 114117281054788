/** @format */

import localize from '../../../../../Global/Localize'

export const RejectAlternateChannelActivationRequestModal = ({
  selectedActivationRequest,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('customerName')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedActivationRequest.customerFullName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('customerType')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              selectedActivationRequest?.customerMembershipClassCustomerTypeDescription
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('customerMemberNo')}
          </label>
          <input
            className="form-control"
            value={selectedActivationRequest.paddedCustomerSerialNumber}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('employerName')}
          </label>
          <input
            className="form-control"
            value={selectedActivationRequest.customerEmployerDescription}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('identificationNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedActivationRequest.customerAddressMobileLine}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedActivationRequest.formattedCustomerMemberNumber}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('accountNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedActivationRequest.paddedCustomerAccountNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedActivationRequest.customerPersonalFileNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('payloadScore')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              selectedActivationRequest?.activationRequestPayloadLevenshteinScorePercentage
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('IPRS Score')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              selectedActivationRequest?.populationRegisterPayloadLevenshteinScorePercentage
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('MPESA Score')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              selectedActivationRequest?.consumerToBusinessPayloadLevenshteinScorePercentage
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('overallScore')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedActivationRequest?.overallScore}
          />
        </div>
      </div>
    </>
  )
}
