/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'

function CustomerAccountBalanceSpoolRequestConstituentEntries({
  selectedCustomerAccountBalanceSpoolRequestEntry,
  refreshEntry,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const columns = [
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
      sortBy: 'monthDescription',
    },
    {
      label: localize('balance'),
      template: r => <>{formatCurrency(r.balance)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const loadCustomerAccountBalanceSpoolRequestConstituentEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/CustomerAccountBalanceSpool/find-customer-account-balance-spool-entry-constituents-by-filter-in-page`,
      {
        customerAccountBalanceSpoolEntryId:
          selectedCustomerAccountBalanceSpoolRequestEntry.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setEntries(data)
    } else {
      setEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadCustomerAccountBalanceSpoolRequestConstituentEntries()
  }, [
    selectedCustomerAccountBalanceSpoolRequestEntry?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
    refreshEntry,
  ])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row px-3 mb-2">
            <div className="d-flex align-items-center col-2">
              <label
                htmlFor="recordsPerPage"
                className=""
                style={{ marginLeft: -10, textTransform: 'capitalize' }}
              >
                {localize('recordsPerPage')}
              </label>
              <select
                name=""
                id="recordsPerPage"
                className="form-select ms-1"
                style={{ maxWidth: 'fit-content' }}
                defaultValue={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageIndex: 0,
                    pageSize: e.target.value,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="100">200</option>
                <option value="100">300</option>
                <option value="100">400</option>
                <option value="100">500</option>
                <option value="100">1000</option>
              </select>
            </div>
          </div>
          <SimpleTable
            selectables
            columns={columns}
            data={entries.pageCollection}
          />
          <Pager
            itemsCount={entries.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}
export default CustomerAccountBalanceSpoolRequestConstituentEntries
