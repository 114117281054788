/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import BankLookup from '../../../../../Components/Lookup/BankLookup'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { getData } from '../../../../../Helpers/webApi'
import { getRandomNumber } from '../../../../../Helpers/misc'
import BankBranchLookup from '../../../../../Components/Lookup/BankBranchLookup'

function BankAccounts({ accounts, readOnly, onChange }) {
  const [bankAccounts, setBankAccounts] = useState(accounts)
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [branch, setBranch] = useState(null)

  const columns = [
    // { label: 'tmp', template: r => <>{r.id}</> }, Don't remove this line
    { label: 'bankCode', template: r => <>{r.bankBranchBankCode}</> },
    { label: 'bankName', template: r => <>{r.bankBranchBankDescription}</> },
    { label: 'branchCode', template: r => <>{r.bankBranchCode}</> },
    { label: 'branchName', template: r => <>{r.bankBranchDescription}</> },
    { label: 'accountNumber', template: r => <>{r.accountNumber}</> },
    { label: 'remarks', template: r => <>{r.remarks}</> },
  ]

  useEffect(() => {
    if (!readOnly) onChange(bankAccounts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccounts])

  useEffect(() => {
    loadBranch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBankAccount?.bankBranchBankId])

  async function loadBranch() {
    setBranch(null)
    const { success, data } = await getData(
      '/bank/find-bank-branch-by-bank-id',
      { bankId: selectedBankAccount.bankBranchBankId }
    )
    if (success) {
      let br = data?.result && data?.result[0]
      if (br) {
        setBranch(br)
        setSelectedBankAccount({
          ...selectedBankAccount,
          bankBranchDescription: br.description,
          bankBranchCode: br.code,
          bankBranchId: br.id,
        })
      } else {
        setBranch(br)
      }
    }
  }

  return readOnly ? (
    <SimpleTable data={bankAccounts} columns={columns} />
  ) : (
    <div className="container-fluid">
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('bank')}
          </label>
          <BankLookup
            displayValue={selectedBankAccount?.bankBranchBankDescription}
            onSelect={b =>
              setSelectedBankAccount({
                ...selectedBankAccount,
                bankBranchBankDescription: b.description,
                bankBranchBankCode: b.code,
                bankBranchBankId: b.id,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('bankBranch')}
          </label>
          <BankBranchLookup
            bankId={selectedBankAccount?.bankBranchBankId}
            bankDescription={selectedBankAccount?.bankBranchBankDescription}
            displayValue={selectedBankAccount?.bankBranchDescription}
            onSelect={b => {
              setBranch(b)
              setSelectedBankAccount({
                ...selectedBankAccount,
                bankBranchDescription: b.description,
                bankBranchId: b.id,
                bankBranchCode: b.code,
              })
            }}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={e =>
              setSelectedBankAccount({
                ...selectedBankAccount,
                accountNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={e =>
              setSelectedBankAccount({
                ...selectedBankAccount,
                remarks: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="p-2 mt-2 bg-light d-flex align-items-center justify-content-end">
        <button
          className="btn btn-success px-4"
          onClick={() => {
            let acts = [...bankAccounts]
            acts.push({
              ...selectedBankAccount,
              id: getRandomNumber(bankAccounts.map(b => b.id)),
            })
            setBankAccounts(acts)
          }}
        >
          {localize('add')}
        </button>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('bankAccountCollection')}
        </div>
        <SimpleTable
          data={bankAccounts}
          columns={columns}
          contextMenu={ba => (
            <>
              <div
                className="ctxt-menu-item text-capitalize text-danger"
                onClick={() => {
                  let bs = [...bankAccounts]
                  let i = bs.findIndex(b => b.id === ba.id)
                  bs.splice(i, 1)
                  setBankAccounts(bs)
                }}
              >
                <i className="uil uil-times-circle"></i>
                <span>{localize('remove')}</span>
              </div>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default BankAccounts
