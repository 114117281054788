/** @format */

/* eslint-disable */
import React from 'react'
import ViewLoanCase from '../LoanApplications/ViewLoanCase'

function LoanCasePartial({ onSelectLoanCase }) {
  return (
    <ViewLoanCase
      asModal={false}
      asPartial
      onSelectLoanCase={onSelectLoanCase}
    />
  )
}

export default LoanCasePartial
