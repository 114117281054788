/** @format */

import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import React, { useState } from 'react'
import { GeneralLedgerTransactionType } from '../../../../../../Helpers/constants'
import { SundryTransactionSection } from '../SundryTransactionSection'

export const SundryReceiptsPaymentsContainer = ({
  transactionModel,
  setTransactionModel,
  loading,
  tariffs,
}) => {
  const [
    selectedGeneralLedgerTransaction,
    setSelectedGeneralLedgerTransaction,
  ] = useState(GeneralLedgerTransactionType.CASH_PAYMENT)

  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('transactionType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="GeneralTransactionType"
            value={selectedGeneralLedgerTransaction}
            onChange={e => {
              setSelectedGeneralLedgerTransaction(e.value)
              setTransactionModel({
                ...transactionModel,
                generalTransactionType: e.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <SundryTransactionSection
            tariffs={tariffs}
            loading={loading}
            transactionModel={transactionModel}
            setTransactionModel={setTransactionModel}
            selectedGeneralLedgerTransactionType={
              selectedGeneralLedgerTransaction
            }
          />
        </div>
      </div>
    </>
  )
}
