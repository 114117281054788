/** @format */

import localize from '../../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../../Components/Tables/SimpleTable'

export const CustomerAccountTransactionChargesAndLevies = ({ tariffs }) => {
  const column = [
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    { label: localize('name'), template: r => <>{r.description}</> },
    { label: localize('amount'), template: r => <>{r.amount}</> },
    {
      label: localize('borneBy'),
      template: r => <>{r.chargeBenefactorDescription}</>,
    },
  ]

  return (
    <>
      <SimpleTable columns={column} data={tariffs} />
    </>
  )
}
