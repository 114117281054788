/** @format */

/* eslint-disable */
import React from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'

const SurveyEntries = ({
  selectedEntries,
  handleSelectEntry,
  hiddenColumns = [],
  isBusy,
  surveyEntries,
  fetchEntries,
  setReqParams,
  reqParams,
  handleRemoveEntry,
  canSelect,
  data,
}) => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedEntries.find(e => e?.description === r?.description)}
          onCheck={value => handleSelectEntry(r, value)}
        />
      ),
    },
    {
      label: localize('description'),
      template: r => r?.description,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
  ]

  const columnsToDisplay = columns.filter(
    (_c, i) => !hiddenColumns?.includes(i)
  )

  return isBusy ? (
    <Loader />
  ) : (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div
          className="col d-flex align-items-center mb-3"
          style={{ maxWidth: 'fit-content' }}
        >
          <label
            style={{ minWidth: 'fit-content' }}
            className="text-capitalize me-2"
          >
            {localize('search')}
          </label>
          <input
            type="text"
            className="form-control me-2"
            value={reqParams?.filterText}
            onChange={e =>
              setReqParams({
                ...reqParams,
                filterText: e.target.value,
                pageIndex: 0,
              })
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                fetchEntries()
              }
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              fetchEntries()
            }}
          >
            <i className="uil uil-filter"></i>
          </button>
        </div>
      </div>

      <SimpleTable data={surveyEntries} columns={columnsToDisplay} />

      <Pager
        itemsCount={data?.itemsCount}
        {...reqParams}
        pageBatchSize={5}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />

      {canSelect && (
        <div className="mt-3 bg-light rounded p-2 justify-content-end d-flex">
          <button className="btn btn-success" onClick={handleRemoveEntry}>
            {localize('remove')}
          </button>
        </div>
      )}
    </>
  )
}

export default SurveyEntries
