/** @format */

import localize from '../../../../../../../Global/Localize'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { BatchEntriesViewMode } from './BatchEntries'
import {
  MismatchedImportEntriesViewMode,
} from './MismatchedImportEntries'
import { CreditBatchFlags } from './CreditBatchFlags'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { creditBatchFlagsList } from '../Extensions/creditBatchFlags'

export const ViewCreditBatchModal = ({ flags = [], selectedCreditBatch }) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('batchNumber')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.paddedBatchNumber}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('batchType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.typeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('totalValue')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatCurrency(selectedCreditBatch?.totalValue, 2, 2, false)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('creditType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.creditTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('concessionType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.concessionTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('concession')}
          </label>
          <input
            className="form-control"
            disabled
            value={
              selectedCreditBatch?.concessionPercentage ||
              selectedCreditBatch?.concessionFixedAmount
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postingPeriod')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.postingPeriodDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('referenceMonth')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.monthDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('valueDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.valueDate}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('chartOfAccountName')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.chartOfAccountName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('payrollNumberType')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.creditTypePayrollNumberTypeDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('revenueTax')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.creditTypeRevenueTaxDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('costCenter')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.costCenterDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.reference}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('checkOffCutOfFDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedCreditBatch?.checkOffCutOffDate)}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('createdBy')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.createdBy}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('createdDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedCreditBatch?.createdDate)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('priority')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.priorityDescription}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('verifiedBy')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.auditedBy}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('verifiedDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.auditedDate}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('verificationRemarks')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.auditRemarks}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('authorizedBy')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.authorizedBy}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('authorizedDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={formatDate(selectedCreditBatch?.AuthorizedDate)}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('authorizationRemarks')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedCreditBatch?.authorizationRemarks}
          />
        </div>
      </div>
      <div className="row mb-3 mt-3">
        <Tab
          preload
          tabItems={[
            {
              label: localize('batchEntries'),
              item: (
                <BatchEntriesViewMode
                  selectedBatchEntry={selectedCreditBatch}
                />
              ),
            },
            {
              label: localize('discrepancies'),
              item: (
                <MismatchedImportEntriesViewMode
                  selectedBatchEntry={selectedCreditBatch}
                />
              ),
            },
            {
              label: localize('flags'),
              item: <CreditBatchFlags flags={flags || creditBatchFlagsList}  />,
            },
          ]}
        />
      </div>
    </>
  )
}
