/** @format */

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from 'react-oidc-context'
import UserManagerClient from './Helpers/userManagerClient'
import { UserContextProvider } from './Contexts/UserContext'

// theme scss
import './assets/scss/theme.scss'

const oidcConfig = {
  userManager: UserManagerClient,
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <AuthProvider
    onSigninCallback={() => {
      window.history.replaceState({}, document.title, window.location.pathname)
    }}
    {...oidcConfig}
  >
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </AuthProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
