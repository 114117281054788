/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../../Components/Loaders/Loader'
import MorguePatientLookup from '../../../../../../Components/Lookup/MorguePatientLookup'
import MorgueServiceLookup from '../../../../../../Components/Lookup/MorgueServiceLookup'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'
import MorgueServices from './MorgueServices/MorgueServices'

const Services = () => {
  const [patient, setPatient] = useState({})
  const [service, setService] = useState({})
  const [multiplier, setMultiplier] = useState(0)
  const [amount, setAmount] = useState(0)
  const [services, setServices] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [selectedEntries, setSelectedEntries] = useState([])
  const [isBusyUpdating, setIsBusyUpdating] = useState(false)

  const handleSelectEntry = entry => {
    const exists = !!selectedEntries.find(e => e.id === entry.id)

    console.log(exists, selectedEntries, 'entries')

    if (exists) {
      const newSelectedEntries = selectedEntries.filter(e => e.id !== entry.id)
      setSelectedEntries(newSelectedEntries)
    } else {
      setSelectedEntries([...selectedEntries, entry])
    }
  }

  const fetchServices = async () => {
    setIsBusy(true)
    const url = '/MorguePatient/find-morgue-services-by-morgue-patient-id'
    const { success, data } = await getData(
      url,
      {
        morguePatientId: patient?.id,
      },
      false,
    )

    if (success) {
      setServices(data?.morgueServices)
      setAmount(data?.totalAmount)
    }

    setIsBusy(false)
  }

  const handleSelectPatient = data => {
    if (data?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected patient is locked',
      )

    setPatient(data)
  }

  const handleSelectMorgueService = data => {
    if (data?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected service is locked',
      )

    setService(data)
  }

  const handleAdd = () => {
    if (!service?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a service',
      )

    if (!multiplier)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please enter a multiplier',
      )

    if (services?.find(s => s.id === service?.id))
      return showNotification(
        'Operation not allowed',
        'error',
        'Service already added',
      )

    setServices([
      ...services,
      {
        ...service,
        multiplier,
        amount: multiplier * 10,
      },
    ])
    setAmount(parseInt(amount) + parseInt(multiplier * 10))
    setService({})
    setMultiplier(0)
  }

  const handleRemove = () => {
    if (!selectedEntries?.length)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a service',
      )

    const newServices = services.filter(
      s => !selectedEntries.find(e => e.id === s.id),
    )
    setServices(newServices)

    setAmount(
      parseInt(amount) -
        parseInt(selectedEntries?.reduce((a, b) => a + b.amount, 0)),
    )
  }

  const handleUpdate = async () => {
    if (!patient?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a patient',
      )

    setIsBusyUpdating(true)
    const url = `/MorguePatient/update-morgue-services-by-morgue-patient-id?morguePatientId=${patient?.id}`

    const { success } = await postData(url, services, false)

    if (success) {
      showNotification('Update Morgue Service', 'success')
      setAmount(0)
      setPatient({
        fullName: '',
        paddedSerialNumber: '',
      })
      setService({
        description: '',
        multiplier: 0,
        amount: 0,
      })
      setMultiplier(0)
      setSelectedEntries([])
      setServices([])
    }

    setIsBusyUpdating(false)
  }

  useEffect(() => {
    if (patient?.id) fetchServices()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.id])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('morgueManagement'),
          localize('morguePatients'),
          localize('updateMorguePatientServices'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusyUpdating ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3">
                <div className="col-md-6 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('morguePatient')}
                  </label>
                  <MorguePatientLookup
                    displayValue={patient?.fullName}
                    onSelect={handleSelectPatient}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('morgueService')}
                  </label>
                  <input
                    className="form-control"
                    value={patient?.paddedSerialNumber}
                    disabled
                  />
                </div>
              </div>
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2  row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('morgueServices')}
                </div>
                <div className="row g-3">
                  <div className="col-md-6 d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('morguePatient')}
                    </label>
                    <MorgueServiceLookup
                      displayValue={service?.description}
                      onSelect={handleSelectMorgueService}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('multiplier')}
                    </label>

                    <MaskNumber
                      defaultMaskValue={multiplier}
                      onMaskNumber={e => setMultiplier(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-2 bg-light rounded p-2 d-flex justify-content-end align-items-center">
                  <button className="btn btn-success" onClick={handleAdd}>
                    {localize('add')}
                  </button>
                </div>

                <div className="mt-4">
                  <Tab
                    preload
                    tabItems={[
                      {
                        label: localize('morgueServicesCollection'),
                        item: (
                          <>
                            <MorgueServices
                              services={services}
                              isBusy={isBusy}
                              handleSelectEntry={handleSelectEntry}
                              selectedEntries={selectedEntries}
                            />
                            <div className="mt-3 bg-light rounded p-2 d-flex gap-3 justify-content-end align-items-center">
                              <input
                                className="form-control w-25"
                                disabled
                                value={formatCurrency(
                                  amount,
                                  null,
                                  null,
                                  false,
                                )}
                              />
                              <button
                                className="btn btn-danger"
                                onClick={handleRemove}
                              >
                                {localize('remove')}
                              </button>
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="mt-2 bg-light rounded p-2 d-flex justify-content-end align-items-center">
                <button className="btn btn-success" onClick={handleUpdate}>
                  {localize('update')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Services
