/** @format */

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { CommissionsLookUp } from '../../../../Components/Lookup/CommissionsLookUp'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { CustomerPartial } from '../../../../Components/ReUsables/Customer/CustomerPartial'
import { ExceptedCustomers } from './ExceptedCustomers'
import { postData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { CustomerTypes, RecordStatus } from '../../../../Global/enumeration'
import { CreditTypeLookup } from '../../../../Components/Lookup/CreditTypeLookup'

function ConcessionExceptionsModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  concessionExceptionEntries,
  selectedConcessionException,
  setSelectedConcessionException,
  setConcessionExceptionEntries,
  onUpdateRefreshAtExceptionEntries,
}) {
  const [gbv, setGbv] = GlobalValidationErrors.use()
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [exceptionEntriesToBeRemoved, setExceptionEntriesToBeRemoved] =
    useState([])

  async function handleSubmit(finalData) {
    const { success } = await postData(
      `${
        mode === 'add'
          ? '/ConcessionException/create-concession-exception-with-exception-entries'
          : '/ConcessionException/update-concession-exception-with-exception-entries'
      }`,
      finalData,
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }

  useEffect(() => {
    setGbv({})
  }, [mode])
  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const addExceptionEntry = customer => {
    if (!customer) {
      Swal.fire({
        icon: 'error',
        text: 'Select at least one customer',
        title: 'Add Charge Exemption Entry',
        showCloseButton: true,
      })
    }

    if (
      concessionExceptionEntries.find(entry => entry.customerId === customer.id)
    ) {
      Swal.fire({
        icon: 'error',
        text: 'Customer Already Exists',
        title: 'Add Charge Exemption Entry',
        showCloseButton: true,
      })
      return
    }
    setConcessionExceptionEntries([
      ...concessionExceptionEntries,
      {
        customerId: customer.id,
        customerFullName: customer.fullName,
        customerMembershipClassCustomerType:
          customer.membershipClassCustomerType,
        customerSerialNumber: customer.serialNumber,
        customerIndividualSalutation: customer.individualSalutation,
        customerIndividualFirstName: customer.individualFirstName,
        customerIndividualLastName: customer.individualLastName,
        customerIndividualGender: customer.individualGender,
        customerIndividualMaritalStatus: customer.individualMaritalStatus,
        id: customer.id,
        customerMembershipClassCustomerTypeDescription:
          customer.membershipClassDescription,
        paddedCustomerSerialNumber: customer.paddedSerialNumber,
        customerReference1: customer.reference1,
        customerReference2: customer.reference2,
        customerReference3: customer.reference3,
        customerIndividualGenderDescription:
          customer.individualGenderDescription,
        customerIndividualMaritalStatusDescription:
          customer.individualMaritalStatusDescription,
        customerIndividualIdentityCardNumber:
          customer.individualIdentityCardNumber,
        customerIndividualNationalityDescription:
          customer.individualNationalityDescription,
        customerDutyStationDescription: customer.dutyStationDescription,
        customerAddressAddressLine1: customer.addressAddressLine1,
        customerAddressAddressLine2: customer.addressAddressLine2,
        customerAddressStreet: customer.addressStreet,
        customerAddressPostalCode: customer.addressPostalCode,
        customerAddressCity: customer.addressCity,
        customerAddressEmail: customer.addressEmail,
        customerAddressLandLine: customer.addressLandLine,
        customerAddressMobileLine: customer.addressMobileLine,
        remarks: customer.remarks,
        createdBy: customer.createdBy,
        createdDate: customer.createdDate,
      },
    ])
    setSelectedCustomer(null)
  }

  const removeExceptionEntry = customer => {
    setConcessionExceptionEntries(
      concessionExceptionEntries.filter(entry => entry.id !== customer.id)
    )
  }

  const removeExceptionEntryInEditMode = async () => {
    if (exceptionEntriesToBeRemoved.length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'Select Atleast One Entry To Remove',
        title: 'Remove Exception Entry',
        showCloseButton: true,
      })
      return
    }
    const { success } = await putData(
      '/ConcessionException/remove-concession-exception-entries',
      { concessionExceptionEntries: exceptionEntriesToBeRemoved },
      true,
      `Concession Exception Entry Removed`
    )
    if (success) {
      setExceptionEntriesToBeRemoved([])
      onUpdateRefreshAtExceptionEntries()
    }
  }

  const handleOnSelectCustomer = customer => {
    if (
      !customer.isLocked &&
      customer.recordStatus == Object.keys(RecordStatus)[2] &&
      customer.membershipClassCustomerType === Object.keys(CustomerTypes)[0]
    ) {
      setSelectedCustomer({ ...customer })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Customer is Locked or Inactive',
        title: 'Add Charge Exemption Entry',
        showCloseButton: true,
      })
      setSelectedCustomer(null)
    }
  }

  const addExceptionEntryInEditMode = async customer => {
    if (!customer) {
      return
    }

    let concessionExceptionEntry = {
      customerId: customer.id,
      concessionExceptionId: selectedConcessionException.id,
      customerFullName: customer.fullName,
      customerMembershipClassCustomerType: customer.membershipClassCustomerType,
      customerSerialNumber: customer.serialNumber,
      customerIndividualSalutation: customer.individualSalutation,
      customerIndividualFirstName: customer.individualFirstName,
      customerIndividualLastName: customer.individualLastName,
      customerIndividualGender: customer.individualGender,
      customerIndividualMaritalStatus: customer.individualMaritalStatus,
      id: customer.id,
      customerMembershipClassCustomerTypeDescription:
        customer.membershipClassDescription,
      paddedCustomerSerialNumber: customer.paddedSerialNumber,
      customerReference1: customer.reference1,
      customerReference2: customer.reference2,
      customerReference3: customer.reference3,
      customerIndividualGenderDescription: customer.individualGenderDescription,
      customerIndividualMaritalStatusDescription:
        customer.individualMaritalStatusDescription,
      customerIndividualIdentityCardNumber:
        customer.individualIdentityCardNumber,
      customerIndividualNationalityDescription:
        customer.individualNationalityDescription,
      customerDutyStationDescription: customer.dutyStationDescription,
      customerAddressAddressLine1: customer.addressAddressLine1,
      customerAddressAddressLine2: customer.addressAddressLine2,
      customerAddressStreet: customer.addressStreet,
      customerAddressPostalCode: customer.addressPostalCode,
      customerAddressCity: customer.addressCity,
      customerAddressEmail: customer.addressEmail,
      customerAddressLandLine: customer.addressLandLine,
      customerAddressMobileLine: customer.addressMobileLine,
      remarks: customer.remarks,
      createdBy: customer.createdBy,
      createdDate: customer.createdDate,
    }

    const { success } = await postData(
      '/ConcessionException/create-concession-exception-entry',
      concessionExceptionEntry,
      true,
      `${customer.fullName} - Commission Exemption Entry Created`
    )
    if (success) {
      onUpdateRefreshAtExceptionEntries()
      setSelectedCustomer(null)
    }
  }

  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <div>
            <ModalHeader className="bg-white text-capitalize">
              {localize(mode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('concessionExceptions')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <div className="row mb-3">
                <div
                  validation-message={
                    gbv['ConcessionException.Description']?.message
                  }
                  className="col d-flex  align-items-center with-validation"
                >
                  <label className="col-1 text-capitalize">
                    {localize('name')}
                  </label>
                  <input
                    className="form-control"
                    defaultValue={selectedConcessionException?.description}
                    onChange={e => {
                      clearValidation('ConcessionException.Description')
                      setSelectedConcessionException({
                        ...selectedConcessionException,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
                <div
                  validation-message={
                    gbv['ConcessionException.CreditTypeId']?.message
                  }
                  className="col d-flex align-items-center with-validation"
                >
                  <label className="col-2 text-capitalize">
                    {localize('creditType')}
                  </label>
                  {mode === 'edit' ? (
                    <input
                      className="form-control"
                      disabled
                      defaultValue={
                        selectedConcessionException?.creditTypeDescription
                      }
                    />
                  ) : (
                    <CreditTypeLookup
                      displayValue={
                        selectedConcessionException?.creditTypeDescription
                      }
                      onSelect={r => {
                        clearValidation('ConcessionException.CreditTypeId')
                        setSelectedConcessionException({
                          ...selectedConcessionException,
                          creditTypeId: r.id,
                          creditTypeDescription: r.description,
                        })
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 120,
                  }}
                >
                  {localize('concessionExceptionDetails')}
                </div>
                <CustomerPartial
                  customer={selectedCustomer}
                  onCustomerLookup={r => {
                    handleOnSelectCustomer(r)
                  }}
                />
                <div
                  className="bg-light d-flex justify-content-end align-items-center p-2"
                  style={{ borderRadius: 5 }}
                >
                  <button
                    onClick={() => {
                      mode === 'add'
                        ? addExceptionEntry(selectedCustomer)
                        : addExceptionEntryInEditMode(selectedCustomer)
                    }}
                    className="btn btn-success text-uppercase ms-3 px-4"
                  >
                    {localize('add')}
                  </button>
                </div>
                <div className="row mt-3">
                  <ExceptedCustomers
                    handleOnCheckCustomerExceptionEntry={r => {
                      setExceptionEntriesToBeRemoved(r)
                    }}
                    removeSelectedExceptionEntries={r => {
                      removeExceptionEntryInEditMode()
                    }}
                    mode={mode}
                    onRemoveEntry={r => {
                      removeExceptionEntry(r)
                    }}
                    exceptedCustomers={concessionExceptionEntries}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <button className="btn btn-danger px-4" onClick={onHideModal}>
                {localize('cancel')}
              </button>
              <button
                onClick={() => {
                  handleSubmit({
                    ConcessionException: selectedConcessionException || {},
                    ConcessionExceptionEntries: concessionExceptionEntries,
                  })
                }}
                className={`btn btn-success text-uppercase px-4`}
                type="button"
              >
                {localize(mode === 'add' ? 'create' : 'update')}
              </button>
            </ModalFooter>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ConcessionExceptionsModal
