/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _m = {
  menuCode: {
    en: 'menu code',
    sw: 'kodi ya menu',
  },
  mission: {
    en: 'mission',
    sw: 'utume',
  },
  motto: {
    en: 'motto',
    sw: 'kauli mbiu',
  },
  miniStatementsItemCap: {
    en: 'mini statement items cap',
    sw: 'taarifa ndogo',
  },
  maturityDaysCategoryDescription: {
    en: 'maturity days category',
    sw: 'maelezo ya kategoria ya siku za ukomavu',
  },
  maturityPeriod: {
    en: 'maturity period',
    sw: 'Kipindi cha Ukomavu',
  },
  months: {
    en: 'months',
    sw: 'miezi',
  },
  maximumCharge: {
    en: 'maximum charge',
    sw: 'upeo wa malipo',
  },
  minimumBalance: {
    en: 'minimum balance',
    sw: 'usawa wa chini',
  },
  maximumAllowedWithdrawal: {
    en: 'maximum allowed withdrawal',
    sw: 'uondoaji wa juu unaoruhusiwa',
  },
  maximumAllowedDeposit: {
    en: 'maximum allowed deposit',
    sw: 'amana ya juu inayoruhusiwa',
  },
  maximumOverdraftLimit: {
    en: 'maximum overdraft limit',
    sw: 'upeo wa juu wa overdraft',
  },
  modifiedBy: {
    en: 'modified by',
    sw: 'imebadilishwa na',
  },
  modifiedDate: {
    en: 'modified date',
    sw: 'tarehe iliyorekebishwa',
  },
  maximumProvidentFundReliefAmount: {
    en: 'maximum provident fund relief amount',
    sw: 'kiwango cha juu cha misaada ya mfuko wa ruzuku',
  },
  maximumInsuranceReliefAmount: {
    en: 'maximum insurance relief amount',
    sw: 'kiwango cha juu cha malipo ya bima',
  },
  maximumNHIFReliefAmount: {
    en: 'maximum nhif relief amount',
    sw: 'kiwango cha juu cha misaada ya nhif',
  },
  maximumDays: {
    en: 'maximum days',
    sw: 'siku za juu',
  },
  microcreditManagement: {
    en: 'microcredit management',
    sw: 'usimamizi wa mikopo midogo midogo',
  },
  morgueManagement: {
    en: 'morgue management',
    sw: 'usimamizi wa chumba cha maiti',
  },
  microCreditManagement: {
    en: 'micro credit management',
    sw: 'usimamizi wa mikopo midogo midogo',
  },
  membershipClasses: {
    en: 'membership classes',
    sw: 'madarasa ya uanachama',
  },
  membershipClass: {
    en: 'membership class',
    sw: 'darasa la uanachama',
  },
  membersManagement: {
    en: 'members management',
    sw: 'usimamizi wa wanachama',
  },
  memberNumber: {
    en: 'member number',
    sw: 'nambari ya mwanachama',
  },
  maritalStatus: {
    en: 'marital status',
    sw: 'hali ya ndoa',
  },
  meetingDay: {
    en: 'meeting day',
    sw: 'siku ya mkutano',
  },
  meetingTime: {
    en: 'meeting time',
    sw: 'saa ya mkutano',
  },
  meetingPlace: {
    en: 'meeting place',
    sw: 'mahali pa mkutano',
  },
  mobileLine: {
    en: 'mobile line',
    sw: 'laini ya simu',
  },
  medianIncome: {
    en: 'median income',
    sw: 'mapato ya wastani',
  },
  memberNumberPrefix: {
    en: 'member number prefix',
    sw: 'kiambishi awali cha nambari ya uanachama',
  },
  memberNumberPadding: {
    en: 'member number padding',
    sw: 'nambari pedi ya mwanachama',
  },
  membershipPeriod: {
    en: 'membership period',
    sw: 'kipindi cha uanachama',
  },
  miscellaneous: {
    en: 'miscellaneous',
    sw: 'mbalimbali',
  },
  mobileToBankTerminals: {
    en: 'mobile to bank terminals',
    sw: 'vituo vya simu hadi benki',
  },
  maximumGuarantees: {
    en: 'maximum guarantees',
    sw: 'dhamana ya juu',
  },
  mandatoryCharges: {
    en: 'mandatory charges',
    sw: 'gharama ya lazima',
  },
  maxLoanEligibilityInvestmentsMultiplier: {
    en: 'max loan eligibility investments multiplier',
    sw: 'kiwango cha juu cha uwekezaji wa ustahiki wa mkopo multiplier',
  },
  maturityDaysCategory: {
    en: 'maturity days category',
    sw: 'maelezo ya kategoria ya siku za ukomavu',
  },
  maximumProvidentFundRelief: {
    en: 'maximum provident fund relief',
    sw: 'kiwango cha juu cha misaada',
  },
  maxHeight: {
    en: 'max height',
    sw: 'urefu wa juu',
  },
  maxWidth: {
    en: 'max width',
    sw: 'upana wa juu',
  },
  maximumInsuranceRelief: {
    en: 'maximum insurance relief',
    sw: 'upeo wa misaada ya bima',
  },
  mappedGLAccountName: {
    en: 'mapped g/l account name',
    sw: 'jina la akaunti ya g/l iliyopangwa',
  },
  morgueRooms: {
    en: 'morgue rooms',
    sw: 'vyumba vya kuhifadhia maiti',
  },
  morgueBeds: {
    en: 'morgue beds',
    sw: 'vitanda vya kuhifadhia maiti',
  },
  morgueServices: {
    en: 'morgue Services',
    sw: 'huduma za chumba cha maiti',
  },
  morgueServiceIsLocked: {
    en: 'morgue service is locked',
    sw: 'huduma ya chumba cha maiti imefungwa',
  },
  minimumContribution: {
    en: 'minimum contribution',
    sw: 'mchango mdogo',
  },
  maximumBalance: {
    en: 'maximum balance',
    sw: 'msawazo wa upeo',
  },
  minimumPrincipalAmount: {
    en: 'minimum principal amount',
    sw: 'kiasi cha chini cha kanuni',
  },
  maximumPrincipalAmount: {
    en: 'maximum principal amount',
    sw: 'kiasi cha juu cha kanuni',
  },
  minimumChargeableInterestAmount: {
    en: 'minimum chargeable interest amount',
    sw: 'kiwango cha chini cha riba kinachotozwa',
  },
  minimumNetDisbursementAmount: {
    en: 'minimum net disbursement amount',
    sw: 'kiasi cha chini kabisa cha malipo',
  },
  minimumDeposits: {
    en: 'minimum deposits',
    sw: 'amana ya chini',
  },
  minimumGuarantors: {
    en: 'minimum guarantors',
    sw: 'wadhamini wa chini',
  },
  minimumMembershipPeriod: {
    en: 'minimum membership period ',
    sw: 'kipindi cha chini cha uanachama',
  },
  maximumSelfGuaranteeEligiblePercentage: {
    en: 'maximum self guarantee eligible percentage',
    sw: 'asilimia inayostahiki ya dhamana ya juu zaidi',
  },
  matchByPayrollNumberType: {
    en: 'match by payroll number type',
    sw: 'kulingana na aina ya nambari ya malipo',
  },
  matchByMSISDN: {
    en: 'match by msisdn',
    sw: 'linganisha na msisdn',
  },
  mobileLoans: {
    en: 'mobile loans',
    sw: 'mikopo ya simu',
  },
  mobileNumber: {
    en: 'mobile number',
    sw: 'nambari ya simu',
  },
  messageGroups: {
    en: 'message groups',
    sw: 'vikundi vya ujumbe',
  },
  messageGroup: {
    en: 'message group',
    sw: 'kikundi cha ujumbe',
  },
  manager: {
    en: 'manager',
    sw: 'meneja',
  },
  membershipStatus: {
    en: 'membership status',
    sw: 'hali ya uanachama',
  },
  membershipTerminationDate: {
    en: 'membership termination date',
    sw: 'tarehe ya kukomesha uanachama',
  },
  meetingFrequency: {
    en: 'meeting frequency',
    sw: 'marudio ya mkutano',
  },
  monthlyContribution: {
    en: 'monthly contribution',
    sw: 'mchango wa kila mwezi',
  },
  membershipActivation: {
    en: 'membership activation',
    sw: 'uanzishaji wa uanachama',
  },
  morgueBed: {
    en: 'morgue bed',
    sw: 'kitanda cha kuhifadhia maiti',
  },
  membersEducationVenue: {
    en: 'members education venue',
    sw: 'mahali pa elimu ya wanachama',
  },
  membersEducation: {
    en: 'members education',
    sw: 'elimu ya wanachama',
  },
  msisdnMobileNumber: {
    en: 'msisdn (mobile number)',
    sw: 'msisdn (nambari ya simu)',
  },
  messageTypeIndicators: {
    en: 'message type indicators',
    sw: 'viashiria vya aina ya ujumbe',
  },
  messageTypeIndicator: {
    en: 'message type indicator',
    sw: 'kiashiria cha aina ya ujumbe',
  },
  messageTypeIndicatorDetails: {
    en: 'message type indicator details',
    sw: 'majarida ya aina ya ujumbe',
  },
  maskTransactionValue: {
    en: 'mask transaction value',
    sw: 'ficha thamani ya shughuli',
  },
  maskAccountBalance: {
    en: 'mask account balance',
    sw: 'ficha baki la akaunti',
  },
  miMeTypef: {
    en: 'mime typef',
    sw: ' aina ya mime ',
  },
  miMeType: {
    en: 'mime type',
    sw: 'aina ya mime',
  },
  membersEducationRegister: {
    en: 'members education register',
    sw: 'rajisi ya elimu ya wanachama',
  },
  membershipWithdrawal: {
    en: 'membership withdrawal',
    sw: 'kujiondoa kwa uanachama',
  },
  maturityDate: {
    en: 'maturity date',
    sw: 'tarehe ya ukomavu',
  },
  'membershipWdr.Date': {
    en: 'membership wdr. date',
    sw: 'tarehe ya kujiondoa kwa uanachama',
  },
  membershipWithdrawalDate: {
    en: 'membership withdrawal date',
    sw: 'tarehe ya kujiondoa kwa uanachama',
  },
  morgueRoomLookup: {
    en: 'morgue room lookup',
    sw: 'tafuta chumba cha kuhifadhia maiti',
  },
  multiDestination: {
    en: 'multi destination',
    sw: 'Fikio nyingi',
  },
  middleFinger: {
    en: 'middle finger',
    sw: 'kidole cha kati',
  },
  membershipDetails: {
    en: 'membership details',
    sw: 'maelezo ya uanachama',
  },
  membershipLookup: {
    en: 'membership lookup',
    sw: 'tafuta uanachama',
  },
  membership: {
    en: 'membership',
    sw: 'uanachama',
  },
  microcredit: {
    en: 'microcredit',
    sw: 'mikopo midogo',
  },
  membershipWithdrawalSettlementBatchLookup: {
    en: 'membership withdrawal settlement batch lookup',
    sw: 'utafutaji wa kundi',
  },
  manage: {
    en: 'manage',
    sw: 'simamia',
  },
  monthlyAccountBalance: {
    en: 'monthly account balance',
    sw: 'baki la akaunti kwa mwezi',
  },
  mobileToBank: {
    en: 'mobile to bank',
    sw: 'simu hadi benki',
  },
  mandatoryParameters: {
    en: 'mandatory parameters',
    sw: 'parameta za lazima',
  },
  matchingSingly: {
    en: 'matching singly',
    sw: 'kulinganisha kwa kila moja',
  },
  matchingJointly: {
    en: 'matching jointly',
    sw: 'kulinganisha kwa pamoja',
  },
  mobileToBankCollections: {
    en: 'mobile to bank collections',
    sw: 'ukusanyaji wa simu hadi benki',
  },
  mobileToBankTerminal: {
    en: 'mobile to bank terminal',
    sw: 'terminali ya simu hadi benki',
  },
  mobileToBankTerminalLookup: {
    en: 'mobile to bank terminal lookup',
    sw: 'tafuta terminali ya simu hadi benki',
  },
  modules: {
    en: 'modules',
    sw: 'moduli',
  },
  module: {
    en: 'module',
    sw: 'moduli',
  },
  mobileToBankRequestsCatalogue: {
    en: 'mobile to bank requests (catalogue)',
    sw: 'orodha ya maombi ya simu hadi benki',
  },
  mobileToBankRequestsReconciliation: {
    en: 'mobile to bank requests (reconciliation)',
    sw: 'upatanisho wa maombi ya simu hadi benki',
  },
  mobileToBankRequestsVerification: {
    en: 'mobile to bank requests (verification)',
    sw: 'uthibitisho wa maombi ya simu hadi benki',
  },
  '(months)': {
    en: '(months)',
    sw: '(miezi)',
  },
  microCredit: {
    en: 'micro credit',
    sw: 'mikopo midogo',
  },
  minMembershipPeriod: {
    en: 'min. membership period',
    sw: 'kipindi cha chini cha uanachama',
  },
  minGuarantors: {
    en: 'min. guarantors',
    sw: 'wadhamini wa chini',
  },
  maxGuarantees: {
    en: 'max. guarantees',
    sw: 'wadhamini wa juu',
  },
  minChargeableInterestAmount: {
    en: 'min. chargeable interest amount',
    sw: 'kiwango cha chini cha riba inayotozwa',
  },
  maxSelfGuaranteeEligible: {
    en: 'max. self guarantee eligible',
    sw: 'kiwango cha juu cha kustahiki dhamana ya mtu',
  },
  minDeposits: {
    en: 'min. deposits',
    sw: 'amana ya chini',
  },
  minNetDisbursementAmount: {
    en: 'min. net disbursement amount',
    sw: 'kiwango cha chini cha malipo ya mkopo',
  },
  minPrincipalAmount: {
    en: 'min. principal amount',
    sw: 'kiwango cha chini cha kiasi cha msingi',
  },
  maxPrincipalAmount: {
    en: 'max. principal amount',
    sw: 'kiwango cha juu cha kiasi cha msingi',
  },
  maximumEligiblePercentage: {
    en: 'maximum eligible percentage',
    sw: 'asilimia ya juu inayostahiki',
  },
  maximumTerm: {
    en: 'maximum term',
    sw: 'kipindi cha juu',
  },
  multiplierFactor: {
    en: 'multiplier factor',
    sw: 'kiwango uzidishi',
  },
  monthlyBalanceSpool: {
    en: 'monthly balance spool',
    sw: 'baki la kila mwezi',
  },
  membershipNumber: {
    en: 'membership number',
    sw: 'nambari ya uanachama',
  },
  multiplier: {
    en: 'multiplier',
    sw: 'kizidishi',
  },
  mismatchedImportEntries: {
    en: 'mismatched import entries',
    sw: 'ingizo la uagizaji lisilolingana',
  },
  monthlyBalanceSpoolLookup: {
    en: 'monthly balance spool lookup',
    sw: 'tafuta baki la kila mwezi',
  },
  month: {
    en: 'month',
    sw: 'mwezi',
  },
  monthlyAccountBalances: {
    en: 'monthly account balances',
    sw: 'baki la akaunti kwa mwezi',
  },
  march: {
    en: 'march',
    sw: 'machi',
  },
  may: {
    en: 'may',
    sw: 'mei',
  },
  membershipWithdrawalNotificationLookup: {
    en: 'membership withdrawal notification lookup',
    sw: 'tafuta taarifa ya kujiondoa kwa uanachama',
  },
  mobileToBankRequestsCollections: {
    en: 'mobile to bank requests collections',
    sw: 'ukusanyaji wa maombi ya simu hadi benki',
  },
  modulesACL: {
    en: 'modules ACL',
    sw: 'moduli ACL',
  },
  membershipClassDescription: {
    en: 'membership class description',
    sw: 'maelezo ya darasa la uanachama',
  },
  membersEducationVenues: {
    en: 'members education venues',
    sw: 'mahali pa elimu ya wanachama',
  },
  membershipWithdrawalNotifications: {
    en: 'membership withdrawal notifications',
    sw: 'taarifa za kujiondoa kwa uanachama',
  },
  membershipWithdrawalSettlementBatches: {
    en: 'membership withdrawal settlement batches',
    sw: 'kundi la makazi ya kujiondoa kwa uanachama',
  },
  management: {
    en: 'management',
    sw: 'usimamizi',
  },
  mobileScore: {
    en: 'mobile score',
    sw: 'alama ya simu',
  },
  maximumEntitled: {
    en: 'maximum entitled',
    sw: 'kiwango cha juu kinachostahiki',
  },
  maximumCreditScore: {
    en: 'maximum credit score',
    sw: 'alama ya juu ya mkopo',
  },
  minimumCreditScore: {
    en: 'minimum credit score',
    sw: 'alama ya chini ya mkopo',
  },
  metroScoreTrends: {
    en: 'metro score trends',
    sw: 'mwenendo wa alama ya metro',
  },
  monthlyScores: {
    en: 'monthly scores',
    sw: 'alama za kila mwezi',
  },
  'mfb(AccountNPA)': {
    en: 'MFB (account NPA)',
    sw: 'MFB (akaunti NPA)',
  },
  'mfi(AccountNPA)': {
    en: 'MFI (account NPA)',
    sw: 'MFI (akaunti NPA)',
  },
  'mfb(AccountPerforming)': {
    en: 'MFB (account performing)',
    sw: 'MFB (akaunti inayofanya kazi)',
  },
  'mfb(AccountNPAHistory)': {
    en: 'MFB (account NPA history)',
    sw: 'MFB (akaunti NPA historia)',
  },
  'mfi(AccountPerforming)': {
    en: 'MFI (account performing)',
    sw: 'MFI (akaunti inayofanya kazi)',
  },
  'mfi(AccountNPAHistory)': {
    en: 'MFI (account NPA history)',
    sw: 'MFI (akaunti NPA historia)',
  },
  mobileAccountCountActive: {
    en: 'mobile account count active',
    sw: 'hesabu ya akaunti ya simu iliyofanya kazi',
  },
  mobileAccountCountClosed: {
    en: 'mobile account count closed',
    sw: 'hesabu ya akaunti ya simu imefungwa',
  },
  mobileAccountInArrearsCount: {
    en: 'mobile account in arrears count',
    sw: 'hesabu ya akaunti ya simu iliyopita',
  },
  mobileAccountNPACount: {
    en: 'mobile account NPA count',
    sw: 'hesabu ya akaunti ya simu NPA',
  },
  maximumAmount: {
    en: 'maximum amount',
    sw: 'kiasi cha juu',
  },
  'maximumAmount %': {
    en: 'maximum amount %',
    sw: 'asilimia ya kiasi cha juu',
  },
  monthlyPaybackAmount: {
    en: 'monthly payback amount',
    sw: 'kiasi cha malipo ya kila mwezi',
  },
  membershipStatuses: {
    en: 'membership statuses',
    sw: 'hali za uanachama',
  },
  'membershipPeriod(Months)': {
    en: 'membership period (months)',
    sw: 'kipindi cha uanachama (miezi)',
  },
  mode: {
    en: 'mode',
    sw: 'njia',
  },
  messaging: {
    en: 'messaging',
    sw: 'ujumbe',
  },
  message: {
    en: 'message',
    sw: 'ujumbe',
  },
  memorandum: {
    en: 'memorandum',
    sw: 'kumbukumbu',
  },
  messageType: {
    en: 'message type',
    sw: 'aina ya ujumbe',
  },
  messageSubject: {
    en: 'message subject',
    sw: 'mada ya ujumbe',
  },
  messageBody: {
    en: 'message body',
    sw: 'mwili wa ujumbe',
  },
  mobileOriginatedMessages: {
    en: 'mobile originated messages',
    sw: 'ujumbe ulioanzishwa na simu',
  },
  mergedStatements: {
    en: 'merged statements',
    sw: 'taarifa zilizounganishwa',
  },
  miniStatement: {
    en: 'mini statement',
    sw: 'taarifa ndogo',
  },
  MaturityDate: {
    en: 'maturity date',
    sw: 'tarehe ya ukomavu',
  },
  ManagementActionDescription: {
    en: 'management action description',
    sw: 'maelezo ya hatua ya usimamizi',
  },
  maturityAction: {
    en: 'maturity action',
    sw: 'hatua ya ukomavu',
  },
  member: {
    en: 'member',
    sw: 'mwanachama',
  },
  memberAccountNumber: {
    en: 'member account number',
    sw: 'nambari ya akaunti ya mwanachama',
  },
  memberMobileNumber: {
    en: 'member mobile number',
    sw: 'nambari ya simu ya mwanachama',
  },
  memberIdentityNumber: {
    en: 'member identity number',
    sw: 'nambari ya kitambulisho ya mwanachama',
  },
  moneyGram: {
    en: 'money gram',
    sw: 'money gram',
  },
  mpesa: {
    en: 'm-pesa',
    sw: 'm-pesa',
  },
  minimumMembers: {
    en: 'minimum members',
    sw: 'wanachama wa chini',
  },
  maximumMembers: {
    en: 'maximum members',
    sw: 'wanachama wa juu',
  },
  microCreditGroup: {
    en: 'micro credit group',
    sw: 'kikundi cha mikopo midogo',
  },
  memberDetails: {
    en: 'member details',
    sw: 'maelezo ya mwanachama',
  },
  microCreditOfficerLookup: {
    en: 'micro credit officer lookup',
    sw: 'tafuta afisa wa mikopo midogo',
  },
  microCreditGroupLookup: {
    en: 'micro credit group lookup',
    sw: 'tafuta kikundi cha mikopo midogo',
  },
  meetingFrequencyDescription: {
    en: 'meeting frequency',
    sw: 'marudio ya mkutano',
  },
  meetingDayOfWeekDescription: {
    en: 'meeting day',
    sw: 'siku ya mkutano',
  },
  microCreditOfficerEmployeeCustomerFullName: {
    en: 'credit officer',
    sw: 'afisa wa mikopo midogo',
  },
  minimumValue: {
    en: 'minimum value',
    sw: 'thamani ya chini',
  },
  mortageInterest: {
    en: 'mortage interest',
    sw: 'riba ya mkopo',
  },
  mobileBankingRequests: {
    en: 'mobile banking requests',
    sw: 'maombi ya benki ya simu',
  },
  'membership#': {
    en: 'membership number',
    sw: 'nambari ya uanachama',
  },
  maximumNhifReliefAmount: {
    en: 'maximum nhif relief amount',
    sw: 'kiwango cha juu cha misaada ya nhif',
  },
  maximumSelfGuarantee: {
    en: 'maximum self guarantee',
    sw: 'dhamana ya juu zaidi',
  },
  microCreditCustomerAccountLookup: {
    en: 'micro credit customer account lookup',
    sw: 'tafuta akaunti ya mteja wa mikopo midogo',
  },
  maximumOrder: {
    en: 'maximum order',
    sw: 'kiwango cha juu',
  },
  monthlyDemand: {
    en: 'monthly demand',
    sw: 'mahitaji ya kila mwezi',
  },
  morguePatients: {
    en: 'morgue patients',
    sw: 'wagonjwa wa chumba cha maiti',
  },
  morguePatient: {
    en: 'morgue patient',
    sw: 'mgonjwa wa chumba cha maiti',
  },
  morguePatientLookup: {
    en: 'morgue patient lookup',
    sw: 'tafuta mgonjwa wa chumba cha maiti',
  },
  morguePatientSamples: {
    en: 'morgue patient samples',
    sw: 'sampuli za mgonjwa wa chumba cha maiti',
  },
  movementCatalogue: {
    en: 'movement catalogue',
    sw: 'orodha ya mwendo',
  },
  movementHistory: {
    en: 'movement history',
    sw: 'historia ya mwendo',
  },
  moveAsset: {
    en: 'move asset',
    sw: 'hamisha mali',
  },
  monthlyPatientAdmission: {
    en: 'monthly patient admission',
    sw: 'kuingia kwa mgonjwa kila mwezi',
  },
  morguePatientDocuments: {
    en: 'morgue patient documents',
    sw: 'hati za mgonjwa wa chumba cha maiti',
  },
  mimeType: {
    en: 'MIME type',
    sw: 'aina ya MIME',
  },
  morgueService: {
    en: 'morgue service',
    sw: 'huduma ya chumba cha maiti',
  },
  morgueServicesCollection: {
    en: 'morgue services collection',
    sw: 'ukusanyaji wa huduma za chumba cha maiti',
  },
  morgueRoom: {
    en: 'morgue room',
    sw: 'chumba cha maiti',
  },
  manufacturer: {
    en: 'manufacturer',
    sw: 'mtengenezaji',
  },
  move: {
    en: 'move',
    sw: 'hamisha',
  },
  movements: {
    en: 'movements',
    sw: 'mwendo',
  },
  model: {
    en: 'model',
    sw: 'mfano',
  },
  mobilePhone: {
    en: 'mobile phone',
    sw: 'simu ya mkononi',
  },
  mobileline: {
    en: 'mobile line',
    sw: 'laini ya simu',
  },
  mitigation: {
    en: 'mitigation',
    sw: 'kupunguza',
  },
  modulesAccessControlList: {
    en: 'modules access control list',
    sw: 'orodha ya udhibiti wa ufikiaji wa moduli',
  },
  MIMEType: {
    en: 'MIME type',
    sw: 'aina ya MIME',
  },
  maximumLoanEligibilityInvestmentsMultiplier: {
    en: 'maximum loan eligibility investments multiplier',
    sw: 'kiwango cha juu cha uwekezaji wa ustahiki wa mkopo multiplier',
  },
  markAttendance: {
    en: 'mark attendance',
    sw: 'alama ya kuhudhuria',
  },
  mismatchedBatchEntries: {
    en: 'mismatched batch entries',
    sw: 'ingizo la kundi lisilolingana',
  },
  marketInterestRate: {
    en: 'market interest rate',
    sw: 'kiwango cha soko la riba',
  },
  miniStatementItemsCap: {
    en: 'mini statement items cap',
    sw: 'taarifa ndogo',
  },
  mobileLoan: {
    en: 'mobile loan',
    sw: 'mkopo wa simu',
  },
  morgueModuleManagement: {
    en: 'morgue module management',
    sw: 'usimamizi wa moduli ya chumba cha maiti',
  },
  mappedGLAccountCostDistributionRule: {
    en: 'mapped g/l account cost dist. rule',
    sw: 'jina la akaunti ya g/l iliyopangwa',
  },
}

export default _m
