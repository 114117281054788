/** @format */

import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { UnpayReasonsCommissions } from './UnpayReasonsCommissions'

export const ViewUnpayReasonModal = ({ selectedUnpayReason }) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center with-validation">
          <label className="col-2 text-capitalize">{localize('name')}</label>
          <input
            className="form-control"
            value={selectedUnpayReason?.description}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">{localize('code')}</label>
          <input
            className="form-control"
            value={selectedUnpayReason?.code}
            disabled
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            tabItems={[
              {
                label: localize('unpayReason'),
                item: (
                  <UnpayReasonsCommissions
                    selectedUnpayReason={selectedUnpayReason}
                    prefix={'view-unpay-reason-charges'}
                    disabled
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
