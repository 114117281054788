/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

function TrainingTypeLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, false)}</>,
      sortBy: 'createdDate',
    },
  ]

  return (
    <SimpleLookup
      columns={columns}
      params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
      displayValue={displayValue}
      onSelect={onSelect}
      title={localize('trainingTypeLookup')}
      url="/TrainingType/find-by-filter-in-page"
    />
  )
}

export default TrainingTypeLookup
