/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ListLoanProducts from '../../LoanProducts/ListLoanProducts'

const LoansTab = ({
  handleUpdateLoanProducts,
  handleSelectLoanProducts,
  setResetValues,
  resetValues,
  actionText
}) => {
  const [selectedLoanProducts, setSelectedLoanProducts] = useState([])

  const handleSelectLoanProduct = (loanProduct, value) => {
    if (value) {
      setSelectedLoanProducts([...selectedLoanProducts, loanProduct])
    } else {
      setSelectedLoanProducts(
        selectedLoanProducts.filter(c => c.id !== loanProduct.id)
      )
    }
  }

  const handleSelectAllLoanProducts = (loans, value) => {
    if (value) {
      setSelectedLoanProducts(loans)
    } else {
      setSelectedLoanProducts([])
    }
  }

  useEffect(() => {
    handleSelectLoanProducts(selectedLoanProducts)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLoanProducts])

  useEffect(() => {
    if (resetValues) {
      setSelectedLoanProducts([])
      setResetValues(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      <ListLoanProducts
        handleSelectAllLoanProducts={handleSelectAllLoanProducts}
        handleSelectLoanProduct={handleSelectLoanProduct}
        handleUpdateLoanProducts={handleUpdateLoanProducts}
        selectedLoanProducts={selectedLoanProducts}
        setResetValues={setResetValues}
        resetValues={resetValues}
        actionText={actionText}
      />
    </>
  )
}

export default LoansTab
