/** @format */

import React, { useState } from 'react'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { downloadReceipt } from '../../../../../Helpers/blobs'
import { funeralHomeTransactionType } from '../../../../../Helpers/constants'
import { postData } from '../../../../../Helpers/webApi'
import CashPayment from './CashPayment/CashPayment'
import PatientCashReceipt from './PatientCashReceipt/PatientCashReceipt'
import PatientRefund from './PatientRefund/PatientRefund'

const ReceiptsOrPayments = () => {
  const [funeralHomeTransaction, setFuneralHomeTransaction] = useState(
    funeralHomeTransactionType.CASH_RECEIPT_PATIENT.value
  )
  const [transaction, setTransaction] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [selectedMorguePatient, setSelectedMorguePatient] = useState({})
  const [glAccount, setGlAccount] = useState({})
  const [transactionType, setTransactionType] = useState({})
  const [mode, setMode] = useState(null)
  const [receipt, setReceipt] = useState({})

  const getTabLabel = () => {
    return funeralHomeTransaction ===
      funeralHomeTransactionType.CASH_PAYMENT.value
      ? funeralHomeTransactionType.CASH_PAYMENT.label
      : funeralHomeTransaction === funeralHomeTransactionType.CASH_RECEIPT.value
      ? funeralHomeTransactionType.CASH_RECEIPT.label
      : funeralHomeTransaction ===
        funeralHomeTransactionType.CASH_RECEIPT_PATIENT.value
      ? funeralHomeTransactionType.CASH_RECEIPT_PATIENT.label
      : funeralHomeTransaction ===
          funeralHomeTransactionType.PATIENT_REFUND.value &&
        funeralHomeTransactionType.PATIENT_REFUND.label
  }

  const getItem = () => {
    return funeralHomeTransaction ===
      funeralHomeTransactionType.CASH_PAYMENT.value ? (
      <CashPayment
        transaction={transaction}
        setTransaction={setTransaction}
        glAccount={glAccount}
        setGlAccount={setGlAccount}
        transactionType={transactionType}
        setTransactionType={setTransactionType}
      />
    ) : funeralHomeTransaction ===
      funeralHomeTransactionType.CASH_RECEIPT.value ? (
      <CashPayment
        transaction={transaction}
        setTransaction={setTransaction}
        glAccount={glAccount}
        setGlAccount={setGlAccount}
        transactionType={transactionType}
        setTransactionType={setTransactionType}
      />
    ) : funeralHomeTransaction ===
      funeralHomeTransactionType.CASH_RECEIPT_PATIENT.value ? (
      <PatientCashReceipt
        setTransaction={setTransaction}
        transaction={transaction}
        selectedMorguePatient={selectedMorguePatient}
        setSelectedMorguePatient={setSelectedMorguePatient}
        setTransactionType={setTransactionType}
        transactionType={transactionType}
      />
    ) : (
      funeralHomeTransaction ===
        funeralHomeTransactionType.PATIENT_REFUND.value && (
        <PatientRefund
          setTransaction={setTransaction}
          transaction={transaction}
          selectedMorguePatient={selectedMorguePatient}
          setSelectedMorguePatient={setSelectedMorguePatient}
          setTransactionType={setTransactionType}
          transactionType={transactionType}
        />
      )
    )
  }

  const handleClose = () => {
    setMode(null)
    setReceipt(null)
  }

  const handleUpdate = async () => {
    setIsBusy(true)
    const url = '/MorgueService/process-morgue-transaction'
    const { success, data } = await postData(
      url,
      {
        funeralHomeTransactionType: funeralHomeTransaction,
        amount: transaction?.amount,
        selectedMorgueTransactionType: transactionType,
        selectedGeneralTransactionGLAccount: glAccount,
        selectedMorguePatient: selectedMorguePatient,
      },
      false
    )

    if (success) {
      setGlAccount({})
      setTransaction({})
      setTransactionType({})
      setSelectedMorguePatient({})
      setFuneralHomeTransaction(
        funeralHomeTransactionType.CASH_RECEIPT_PATIENT.value
      )

      setReceipt(data?.message)

      Swal.fire(
        'Receipts/Payments',
        'Operation completed successfully!',
        'success'
      ).then(() => {
        setMode('print')
      })
    }

    setIsBusy(false)
  }

  const handlePrint = async () => {
    setMode(null)
    downloadReceipt(receipt, 'receipt.txt')
    setMode('re-print')

    if (mode === 're-print') {
      setMode(null)
      setReceipt({})
    }
  }

  return (
    <>
      {mode === 'print' && (
        <ActionModal
          modalTitle="receipts/Payments"
          handleClose={handleClose}
          handleAction={handlePrint}
          cancelText="no"
          confirmText="yes"
        >
          <p>Do you want print ?</p>
        </ActionModal>
      )}

      {mode === 're-print' && (
        <ActionModal
          modalTitle="receipts/Payments"
          handleClose={handleClose}
          handleAction={handlePrint}
          cancelText="no"
          confirmText="yes"
        >
          <p>Do you want re-print ?</p>
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('morgueManagement'),
          localize('accounts'),
          localize('receipts/Payments'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <div className="co-md-12 d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('transactionType')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      width: '100%',
                    }}
                    sort
                    enumsKey="FuneralHomeTransactionType"
                    value={funeralHomeTransaction}
                    onChange={({ value }) => {
                      setFuneralHomeTransaction(value)
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <Tab
                  tabItems={[
                    {
                      label: localize(getTabLabel()),
                      item: getItem(),
                    },
                  ]}
                />
              </div>
              <div className="mt-2 rounded d-flex justify-content-end align-items-center p-2 bg-light">
                <button className="btn btn-success" onClick={handleUpdate}>
                  {localize('update')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ReceiptsOrPayments
