/** @format */

import React, { useState, useEffect } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'

const SelectableDepartments = ({
  disabled,
  isBusy,
  selectedDepartments,
  prefix,
  onItemsSelection,
}) => {
  const [toggleDepartmentsSelection, setToggleDepartmentsSelection] =
    useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const handleCheckDepartment = (department, value) => {
    if (value) setSelectedItems([...selectedItems, department])
    else
      setSelectedItems(selectedItems.filter(item => item.id !== department.id))
  }

  const onCheckDepartments = checkStatus => {
    setToggleDepartmentsSelection(checkStatus)
    if (checkStatus) setSelectedItems(selectedDepartments?.departments)
    else setSelectedItems([])
  }

  useEffect(() => {
    onItemsSelection(selectedItems)

    //eslint-disable-next-line
  }, [selectedItems])

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {selectedDepartments?.departments?.map(departments => (
              <div
                key={departments.id}
                className="d-flex align-items-center gap-2 justify-content-start"
              >
                <Checkbox
                  id={`${prefix ? prefix : 'com'}-${departments.id}`}
                  checked={selectedItems?.find(
                    item => item.id === departments.id
                  )}
                  disabled={disabled}
                  margined
                  onCheck={c => {
                    handleCheckDepartment(departments, c)
                  }}
                />
                <label
                  htmlFor={`${prefix ? prefix : 'com'}-${departments.id}`}
                  className={`fw-normal ms-2 cursor-pointer ${
                    departments.checked ? 'text-primary fw-bold' : ''
                  }`}
                >
                  {departments.description}
                </label>
              </div>
            ))}
          </div>
          <div className="row mb-3 g-3 mt-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <Checkbox
                  id="toggle-departments-selection"
                  checked={toggleDepartmentsSelection}
                  onCheck={v => {
                    onCheckDepartments(v)
                  }}
                />
                <label
                  htmlFor="toggle-departments-selection"
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')} ?
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SelectableDepartments
