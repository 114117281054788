/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import { getData, putData } from '../../../../../Helpers/webApi'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

function OffsetableLoanProducts({ loan, mode }) {
  const [isBusy, setIsBusy] = useState(false)
  const readOnly = mode === 'view'
  const [loanProducts, setLoanProducts] = useState([])
  const [selectedLoanProducts, setSelectedLoanProducts] = useState([])
  const [eLoanProducts, setELoanProducts] = useState([])
  const [eInvestmentProducts, setEInvestmentProducts] = useState([])
  const [gInvestmentProducts, setGInvestmentProducts] = useState([])
  const [selectedEIP, setSelectedEIP] = useState([])

  const [eSavingsProduct, setESavingsProduct] = useState([])
  const [selectedESP, setSelectedESP] = useState([])

  const [selectedAP, setSelectedAP] = useState([])
  const [selectedAGP, setSelectedAGP] = useState([])

  const [reloadData, setreloadData] = useState(false)

  async function findLoanProducts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/loanProduct/find-loan-products',
      null,
      false
    )
    if (success) {
      setLoanProducts(data)
    }
    setIsBusy(false)
  }

  async function findInvestmentProducts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/investmentProduct/find-investment-products',
      null,
      false
    )
    if (success) {
      setEInvestmentProducts(data)
      setGInvestmentProducts(data)
    }
    setIsBusy(false)
  }

  async function findSavingsProduct() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/savingsProduct/find-savings-products',
      null,
      false
    )
    if (success) {
      setESavingsProduct(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    findLoanProducts()
    findInvestmentProducts()
    findSavingsProduct()
    findAllItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  async function findAllItems() {
    setIsBusy(true)
    const { success, data } = await getData(
      `/loanProduct/find-appraisal-products-by-loan-product-id?loanProductId=${loan?.id}`,
      null,
      false
    )
    if (success) {
      setSelectedAP(data?.investmentProductCollection || [])
      setSelectedAGP(data?.guarantorInvestmentProductCollection || [])
      setSelectedESP(
        data?.eligibleIncomeDeductionSavingsProductCollection || []
      )
      setSelectedEIP(
        data?.eligibleIncomeDeductionInvestmentProductCollection || []
      )
      setELoanProducts(data?.eligibleIncomeDeductionLoanProductCollection || [])
      setSelectedLoanProducts(data?.loanProductCollection || [])
    }
    setIsBusy(false)
  }
  async function updateLoanProducts() {
    setIsBusy(true)
    const { success } = await putData(
      `/loanProduct/update-appraisal-products-by-loan-product-id?loanProductId=${loan?.id}`,
      {
        investmentProductCollection: selectedAP,
        guarantorInvestmentProductCollection: selectedAGP,
        loanProductCollection: selectedLoanProducts,
        eligibleIncomeDeductionLoanProductCollection: eLoanProducts,
        eligibleIncomeDeductionInvestmentProductCollection: selectedEIP,
        eligibleIncomeDeductionSavingsProductCollection: selectedESP,
      },
      false,
      'Loan Products Updated!'
    )

    if (success) {
      setreloadData(!reloadData)
    }
    setIsBusy(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="row p-1 gap-2">
        <div
          className="col-2 bg-light py-3"
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 10,
          }}
        >
          <h5 className="text-capitalize mx-1">
            {localize('offsetableLoanProducts')}
          </h5>
          <label
            htmlFor=""
            className="text-capitalize mt-2"
            style={{ height: 20 }}
          >
            {localize('')}
          </label>
          <div
            style={{
              maxHeight: 'calc(60vh - 35px)',
              overflowY: 'scroll',
              border: 'solid 1px black',
              borderRadius: 5,
              flex: 1,
            }}
            className="p-2 bg-white mx-2 "
          >
            {loanProducts.map(c => (
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  disabled={readOnly}
                  id={c?.id}
                  onCheck={v => {
                    if (v) {
                      setSelectedLoanProducts([...selectedLoanProducts, c])
                    } else {
                      setSelectedLoanProducts(
                        selectedLoanProducts.filter(w => w.id !== c.id)
                      )
                    }
                  }}
                  checked={selectedLoanProducts.find(w => w.id === c.id)}
                />
                <label
                  htmlFor={c?.id}
                  className={`text-capitalize fw-normal ${
                    selectedLoanProducts.find(w => w.id === c.id)
                      ? 'text-primary fw-bold'
                      : ''
                  }`}
                >
                  {c.extendedDescription}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div
          className="col-4 bg-light py-3"
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 10,
          }}
        >
          <h5 className="text-capitalize mx-1">
            {localize('appraisalProducts')} ({localize('investments')})
          </h5>
          <div className="row gap-1" style={{ flex: 1 }}>
            <div className="col">
              <label
                htmlFor=""
                className="text-capitalize mt-2"
                style={{ height: 20 }}
              >
                {localize('loaneeProducts')}
              </label>
              <div
                style={{
                  height: 'calc(60vh - 35px)',
                  overflowY: 'scroll',
                  border: 'solid 1px black',
                  borderRadius: 5,
                }}
                className="col p-2 bg-white"
              >
                {eInvestmentProducts.map(c => (
                  <div className="d-flex align-items-center gap-2">
                    <Checkbox
                      id={`appraisalP-${c?.id}`}
                      onCheck={v => {
                        if (v) {
                          setSelectedAP([...selectedAP, c])
                        } else {
                          setSelectedAP(selectedAP.filter(w => w.id !== c.id))
                        }
                      }}
                      disabled={readOnly}
                      checked={selectedAP.find(w => w.id === c.id)}
                    />
                    <label
                      htmlFor={`appraisalP-${c?.id}`}
                      className={`text-capitalize fw-normal ${
                        selectedAP.find(w => w.id === c.id)
                          ? 'text-primary fw-bold'
                          : ''
                      }`}
                    >
                      {c.description}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="col">
              <label
                htmlFor=""
                className="text-capitalize mt-2"
                style={{ height: 20 }}
              >
                {localize('gurantorProducts')}
              </label>
              <div
                style={{
                  height: 'calc(60vh - 35px)',
                  overflowY: 'scroll',
                  border: 'solid 1px black',
                  borderRadius: 5,
                }}
                className="col p-2 bg-white"
              >
                {gInvestmentProducts.map(c => (
                  <div className="d-flex align-items-center gap-2">
                    <Checkbox
                      id={`GappraisalP-${c?.id}`}
                      onCheck={v => {
                        if (v) {
                          setSelectedAGP([...selectedAGP, c])
                        } else {
                          setSelectedAGP(selectedAGP.filter(w => w.id !== c.id))
                        }
                      }}
                      disabled={readOnly}
                      checked={selectedAGP.find(w => w.id === c.id)}
                    />
                    <label
                      htmlFor={`GappraisalP-${c?.id}`}
                      className={`text-capitalize fw-normal ${
                        selectedAGP.find(w => w.id === c.id)
                          ? 'text-primary fw-bold'
                          : ''
                      }`}
                    >
                      {c.description}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col bg-light py-3"
          style={{
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h5 className="text-capitalize mx-1" style={{ textAlign: 'center' }}>
            {localize('eligibleIncomeDeductions')}
          </h5>
          <div className="row gap-1" style={{ flex: 1 }}>
            <div className="col">
              <label
                htmlFor=""
                className="text-capitalize mt-2"
                style={{ height: 20 }}
              >
                {localize('loanProducts')}
              </label>
              <div
                style={{
                  height: 'calc(60vh - 35px)',
                  overflowY: 'scroll',
                  border: 'solid 1px black',
                  borderRadius: 5,
                }}
                className="col p-2 bg-white"
              >
                {loanProducts.map(c => (
                  <div className="d-flex align-items-center gap-2">
                    <Checkbox
                      disabled={readOnly}
                      id={`e-loan-${c?.id}`}
                      onCheck={v => {
                        if (v) {
                          setELoanProducts([...eLoanProducts, c])
                        } else {
                          setELoanProducts(
                            eLoanProducts.filter(w => w.id !== c.id)
                          )
                        }
                      }}
                      checked={eLoanProducts.find(w => w.id === c.id)}
                    />
                    <label
                      htmlFor={`e-loan-${c?.id}`}
                      className={`text-capitalize fw-normal ${
                        eLoanProducts.find(w => w.id === c.id)
                          ? 'text-primary fw-bold'
                          : ''
                      }`}
                    >
                      {c.extendedDescription}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="col">
              <label htmlFor="" className="text-capitalize mt-2">
                {localize('investmentProducts')}
              </label>
              <div
                style={{
                  height: 'calc(60vh - 35px)',
                  overflowY: 'scroll',
                  border: 'solid 1px black',
                  borderRadius: 5,
                }}
                className="col p-2 bg-white"
              >
                {eInvestmentProducts.map(c => (
                  <div className="d-flex align-items-center gap-2">
                    <Checkbox
                      disabled={readOnly}
                      id={c?.id}
                      onCheck={v => {
                        if (v) {
                          setSelectedEIP([...selectedEIP, c])
                        } else {
                          setSelectedEIP(selectedEIP.filter(w => w.id !== c.id))
                        }
                      }}
                      checked={selectedEIP.find(w => w.id === c.id)}
                    />
                    <label
                      htmlFor={c?.id}
                      className={`text-capitalize fw-normal ${
                        selectedEIP.find(w => w.id === c.id)
                          ? 'text-primary fw-bold'
                          : ''
                      }`}
                    >
                      {c.description}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="col">
              <label htmlFor="" className="text-capitalize  mt-2">
                {localize('savingsProducts')}
              </label>
              <div
                style={{
                  height: 'calc(60vh - 35px)',
                  overflowY: 'scroll',
                  border: 'solid 1px black',
                  borderRadius: 5,
                }}
                className="col p-2 bg-white"
              >
                {eSavingsProduct.map(c => (
                  <div className="d-flex align-items-center gap-2">
                    <Checkbox
                      disabled={readOnly}
                      id={c?.id}
                      onCheck={v => {
                        if (v) {
                          setSelectedESP([...selectedESP, c])
                        } else {
                          setSelectedESP(selectedESP.filter(w => w.id !== c.id))
                        }
                      }}
                      checked={selectedESP.find(w => w.id === c.id)}
                    />
                    <label
                      htmlFor={c?.id}
                      className={`text-capitalize fw-normal ${
                        selectedESP.find(w => w.id === c.id)
                          ? 'text-primary fw-bold'
                          : ''
                      }`}
                    >
                      {c.description}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {readOnly || (
        <div className=" mt-3 d-flex align-items-center justify-content-end">
          <button
            onClick={updateLoanProducts}
            className="btn btn-success px-5 ms-3"
          >
            {localize('update')}
          </button>
        </div>
      )}
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default OffsetableLoanProducts
