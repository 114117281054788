/** @format */

import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import EmployeeLookup from '../../../../../../Components/Lookup/EmployeeLookup'
import SalaryGroupLookup from '../../../../../../Components/Lookup/SalaryGroupLookup'
import localize from '../../../../../../Global/Localize'
import { postData } from '../../../../../../Helpers/webApi'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'

const AddSalaryCardModal = ({ mode, closeModal, loadData }) => {
  const [loading, setLoading] = useState(false)
  const [selectedSalaryCard, setSelectedSalaryCard] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setLoading(true)
    const { success } = await postData(
      '/SalaryCard/create-salary-card',
      selectedSalaryCard,
      false
    )

    if (success) {
      showNotification('Salary Card', 'success')
      setLoading(false)
      setSelectedSalaryCard(null)
      setGlobalValidationErrors({})
      loadData()
      handleCloseModal()
    }
    setLoading(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  function handleCloseModal() {
    closeModal()
    setGlobalValidationErrors({})
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="salaryCards"
        handleClose={handleCloseModal}
        handleAction={handleSubmit}
        modalClassName="modal-xl"
        actionText={mode === 'add' ? 'create' : ''}
        footerChildren={
          <>
            <Checkbox
              id="salaryCardIsTaxExempt"
              onCheck={e => {
                setSelectedSalaryCard({
                  ...selectedSalaryCard,
                  isTaxExempt: e,
                })
              }}
              checked={selectedSalaryCard?.isTaxExempt}
            />
            <label
              htmlFor="salaryCardIsTaxExempt"
              className="text-capitalize mb-3"
            >
              {localize('isTaxExempt')}?
            </label>
            <Checkbox
              id="salaryCardIsLocked"
              onCheck={e => {
                setSelectedSalaryCard({
                  ...selectedSalaryCard,
                  isLocked: e,
                })
              }}
              checked={selectedSalaryCard?.isLocked}
            />
            <label
              htmlFor="salaryCardIsLocked"
              className="text-capitalize mb-3"
            >
              {localize('isLocked')}?
            </label>
          </>
        }
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div
                className="col d-flex align-items-center mb-2 with-validation"
                validation-message={
                  globalValidationErrors['EmployeeId']?.message
                }
              >
                <label className="col-md-2 text-capitalize">
                  {localize('employee')}
                </label>
                <EmployeeLookup
                  displayValue={selectedSalaryCard?.employeeCustomerFullName}
                  onSelect={r => {
                    clearValidation('EmployeeId')

                    setSelectedSalaryCard({
                      ...selectedSalaryCard,
                      employeeId: r.id,
                      employeeCustomerFullName: r.customerFullName,
                    })
                  }}
                />
              </div>
              <div
                className="col d-flex align-items-center mb-2 with-validation"
                validation-message={
                  globalValidationErrors['SalaryGroupId']?.message
                }
              >
                <label className="col-md-2 text-capitalize">
                  {localize('salaryGroup')}
                </label>
                <SalaryGroupLookup
                  displayValue={selectedSalaryCard?.salaryGroupDescription}
                  onSelect={r => {
                    clearValidation('SalaryGroupId')

                    setSelectedSalaryCard({
                      ...selectedSalaryCard,
                      salaryGroupId: r.id,
                      salaryGroupDescription: r.description,
                    })
                  }}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('taxExemption')}
                </label>
                <MaskNumber
                  defaultMaskValue={selectedSalaryCard?.taxExemption || ''}
                  className="form-control"
                  onMaskNumber={e => {
                    setSelectedSalaryCard({
                      ...selectedSalaryCard,
                      taxExemption: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('insuranceRelief')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    selectedSalaryCard?.insuranceReliefAmount || ''
                  }
                  className="form-control"
                  onMaskNumber={e => {
                    setSelectedSalaryCard({
                      ...selectedSalaryCard,
                      insuranceReliefAmount: e.target.value,
                    })
                  }}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-md-2 text-capitalize">
                  {localize('mortageInterest')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    selectedSalaryCard?.mortageInterestAmount || ''
                  }
                  className="form-control"
                  onMaskNumber={e => {
                    setSelectedSalaryCard({
                      ...selectedSalaryCard,
                      mortageInterestAmount: e.target.value,
                    })
                  }}
                />
              </div>
              <div
                className="col d-flex align-items-center with-validation"
                validation-message={globalValidationErrors['Remarks']?.message}
              >
                <label className="col-md-2 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedSalaryCard?.remarks || ''}
                  onChange={e => {
                    clearValidation('Remarks')
                    setSelectedSalaryCard({
                      ...selectedSalaryCard,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default AddSalaryCardModal
