/** @format */

import React, { useState } from 'react'
import {
  ApportionedComponent,
  ApportionTo,
} from '../../../../Helpers/constants'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { postData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import { MicroCreditCustomerAccountPartial } from '../../../../Components/ReUsables/Customer/MicroCreditCustomerAccountPartial'
import { GroupApportionmentContainer } from './Partials/GroupApportionmentContainer'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'

function Apportionments() {
  const [transactionModel, setTransactionModel] = useState({
    apportionTo: ApportionTo.CUSTOMER_ACCOUNT,
    apportionedComponent: ApportionedComponent.AGGREGATE,
  })

  const [apportionMents, setApportionMents] = useState([])

  const [globalValidation, setGlobalValidation] = GlobalValidationErrors.use()
  const [loading, setLoading] = useState(false)
  const [resetEntries, setResetEntries] = useState(0)
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)

  const handleOnUpdate = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/MicroCreditGroup/process-micro-credit-group-apportionments',
      {
        apportionmentWrappers: apportionMents || [],
        totalValue: transactionModel?.amount || 0,
        SelectedMicroCreditCustomerAccount:
          transactionModel?.selectedCustomerAccount || {},
        PrimaryDescription: transactionModel?.primaryDescription || '',
        SecondaryDescription: transactionModel?.secondaryDescription || '',
        Reference: transactionModel?.reference || '',
      },
      false,
      null
    )
    if (success) {
      if (data.result) {
        await Swal.fire({
          icon: 'success',
          title: 'Operation Successful',
          text: 'Micro Credit Group Apportionment',
        })
        setResetEntries(Math.floor(Date.now() / 1000))
      }
    } else {
      if (data?.type === 0) {
        const messages = Object.values(data?.reason).map(entry => entry.message)
        const htmlContent = `<ul>${messages}</ul>`
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          html: htmlContent,
        })
      }
    }
    setLoading(false)
  }

  const handleOnSelectCustomerAccount = async customerAccount => {
    setTransactionModel({
      ...transactionModel,
      selectedCustomerAccount: { ...customerAccount },
    })
    clearValidation('SelectedCustomerAccount.Id')
    setSelectedCustomerAccount(customerAccount)
  }

  const clearValidation = column => {
    setGlobalValidation(g => {
      delete g[column]
      return g
    })
  }
  return (
    <>
      <BreadCrumb
        tree={[
          localize('microCreditManagement'),
          localize('groups'),
          localize('apportionment'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <MicroCreditCustomerAccountPartial
                  message={
                    globalValidation?.['SelectedCustomerAccount.Id']?.message
                  }
                  onClearValidation={() => {
                    clearValidation('SelectedCustomerAccount.Id')
                  }}
                  onCustomerLookup={handleOnSelectCustomerAccount}
                  customer={selectedCustomerAccount}
                  productCode={'1'}
                />
              </div>
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('totalValue')}
                  </label>
                  <MaskNumber
                    defaultMaskValue={transactionModel?.amount}
                    onMaskNumber={e => {
                      setTransactionModel({
                        ...transactionModel,
                        amount: e.target.value,
                        totalValue: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4  text-capitalize">
                    {localize('primaryDescription')}
                  </label>
                  <input
                    className="form-control"
                    value={transactionModel?.primaryDescription}
                    onChange={e => {
                      setTransactionModel({
                        ...transactionModel,
                        primaryDescription: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4  text-capitalize">
                    {localize('secondaryDescription')}
                  </label>
                  <input
                    className="form-control"
                    value={transactionModel?.secondaryDescription}
                    onChange={e => {
                      setTransactionModel({
                        ...transactionModel,
                        secondaryDescription: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4  text-capitalize">
                    {localize('reference')}
                  </label>
                  <input
                    className="form-control"
                    value={transactionModel?.reference}
                    onChange={e => {
                      setTransactionModel({
                        ...transactionModel,
                        reference: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <GroupApportionmentContainer
                    resetEntries={resetEntries}
                    onRemoveApportionment={r => {
                      setApportionMents(
                        apportionMents.filter(x => x.id !== r.id)
                      )
                    }}
                    onAddApportionMent={e => {
                      setApportionMents([...apportionMents, e])
                    }}
                    transactionModel={transactionModel}
                    setTransactionModel={setTransactionModel}
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="d-flex align-item-center justify-content-end rounded py-3 px-2 bg-light mt-2 mb-2">
                      <button
                        className="btn btn-success"
                        onClick={handleOnUpdate}
                      >
                        {localize('update')}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Apportionments
