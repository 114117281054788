/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { SystemPermissionType } from '../../../../Global/enumeration'
import localize from '../../../../Global/Localize'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Tab from '../../../../Components/Tabs/Tab'
import { AuthorizedRolesOperationsACL } from './Partials/AuthorizedRolesOperationsACL'
import { AuthorizedBranchesOperationsACL } from './Partials/AuthorizedBranchesOperationsACL'
import {
  entitiesToBeChecked,
  getBranches,
  getRoles,
} from '../../../../Helpers/selectablesHelper'
import { getData, postData } from '../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import NonBlockingLoader from '../../../../Components/Loaders/NonBlockingLoader'

function AccessControlListOperations() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedSystemPermissionType, setSelectedSystemPermissionType] =
    useState(Object.keys(SystemPermissionType)[0])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])
  const [branches, setBranches] = useState([])
  const [roles, setRoles] = useState([])

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    const branches = await getBranches()
    const roles = await getRoles()
    setBranches(branches)
    setRoles(roles)
    await fetchPermissionTypeRolesOnInitialization(
      selectedSystemPermissionType,
      roles,
    )
    await fetchPermissionTypeBranchesOnInitialization(
      selectedSystemPermissionType,
      branches,
    )
  }

  const handleOnChangeSystemPermissionType = async c => {
    setSelectedSystemPermissionType(c.value)
    await fetchPermissionTypeRoles(c.value)
    await fetchPermissionTypeBranches(c.value)
  }

  const fetchPermissionTypeRoles = async e => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/SystemPermissionTypeInRole/find-roles-by-system-permission-type',
      {
        systemPermissionType: e,
      },
      false,
    )
    if (success) {
      setIsBusy(false)
      setSelectedRoles(
        entitiesToBeChecked(
          [...roles],
          data.result && data.result.length > 0 ? data.result : [],
        ),
      )
    } else {
      setIsBusy(false)
    }
  }

  const fetchPermissionTypeBranches = async e => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/SystemPermissionTypeInBranch/find-branches-by-system-permission-type',
      {
        systemPermissionType: e,
      },
      false,
    )
    if (success) {
      setIsBusy(false)
      setSelectedBranches(
        entitiesToBeChecked(
          [...branches],
          data.result && data.result.length > 0 ? data.result : [],
        ),
      )
    } else {
      setIsBusy(false)
    }
  }

  const fetchPermissionTypeRolesOnInitialization = async (
    e,
    rolesInformaion,
  ) => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/SystemPermissionTypeInRole/find-roles-by-system-permission-type',
      {
        systemPermissionType: e,
      },
      false,
    )
    if (success) {
      setIsBusy(false)
      setSelectedRoles(
        entitiesToBeChecked(
          [...rolesInformaion],
          data.result && data.result.length > 0 ? data.result : [],
        ),
      )
    } else {
      setIsBusy(false)
    }
  }

  const fetchPermissionTypeBranchesOnInitialization = async (
    e,
    branchesInformation,
  ) => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/SystemPermissionTypeInBranch/find-branches-by-system-permission-type',
      {
        systemPermissionType: e,
      },
      false,
    )
    if (success) {
      setIsBusy(false)
      setSelectedBranches(
        entitiesToBeChecked(
          [...branchesInformation],
          data.result && data.result.length > 0 ? data.result : [],
        ),
      )
    } else {
      setIsBusy(false)
    }
  }

  const handleOnCheckRolesOperationsAcl = async (r, t) => {
    setIsBusy(false)
    const { success } = await postData(
      `${
        t
          ? '/SystemPermissionTypeInRole/add-system-permission-type-in-role'
          : '/SystemPermissionTypeInRole/remove-system-permission-type-in-role'
      }`,
      {
        SystemPermissionType: selectedSystemPermissionType,
        RoleId: r.id,
      },
      false,
      'System Permission Type In Role',
    )

    setIsBusy(false)
  }

  const handleOnCheckBranchesOperationsAcl = async (
    checkValue,
    selectedBranch,
  ) => {
    const { success } = await postData(
      checkValue
        ? '/SystemPermissionTypeInBranch/add-system-permission-type-in-branch'
        : '/SystemPermissionTypeInBranch/remove-system-permission-type-in-branch',
      {
        SystemPermissionType: selectedSystemPermissionType,
        BranchId: selectedBranch?.id,
      },
      false,
      'System Permission Type In Branch',
    )
    if (success) {
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('systemAdministration'),
          localize('operationsAccessControlList'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="relative">
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-auto me-2 text-capitalize">
                      {localize('systemPermissionType')}
                    </label>
                    <EnumsServerCombo
                      value={selectedSystemPermissionType}
                      customContainerStyle={{ width: '100%' }}
                      enumsKey={'SystemPermissionType'}
                      onChange={handleOnChangeSystemPermissionType}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('authorizedRoles'),
                        item: (
                          <AuthorizedRolesOperationsACL
                            prefix={'auth-roles'}
                            rolesToBeSelected={selectedRoles}
                            onCheckRole={(c, v, t) => {
                              setSelectedRoles(c)
                              handleOnCheckRolesOperationsAcl(v, t)
                            }}
                          />
                        ),
                      },
                      {
                        label: localize('authorizedBranches'),
                        item: (
                          <AuthorizedBranchesOperationsACL
                            branchesToBeSelected={selectedBranches}
                            onCheckBranch={(
                              checkValue,
                              filteredBranches,
                              selectedBranch,
                            ) => {
                              setSelectedBranches(filteredBranches)
                              handleOnCheckBranchesOperationsAcl(
                                checkValue,
                                selectedBranch,
                              )
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
                {isBusy && <NonBlockingLoader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccessControlListOperations
