/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import InvestmentAccounts from '../../../../../../Components/ReUsables/InvestmentAccounts/InvestmentAccounts'
import LoanAccounts from '../../../../../../Components/ReUsables/Loan/LoanAccounts/LoanAccounts'
import SavingsAccounts from '../../../../../../Components/ReUsables/SavingsAccounts/SavingsAccounts'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { ProductCode } from '../../../../../../Global/enumeration'
import { getTodaysDate } from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'

const AddEStatement = ({
  mode,
  handleClose,
  handleAction,
  statement,
  setStatement,
  savingAccounts,
  loanAccounts,
  investmentAccounts,
  handleSelectSavingAccount,
  handleSelectLoanAccount,
  handleSelectInvestmentAccount,
  selectAllLoanAccounts,
  customer,
  handleLookupCustomer,
  isBusy,
}) => {
  const [loadCustomerAccounts, setLoadCustomerAccounts] = useState(false)
  const [globalValidationErrors] = GlobalValidationErrors.use()
  const [customerAccounts, setCustomerAccounts] = useState({
    customerLoanAccounts: [],
    customerSavingsAccounts: [],
    customerInvestmentAccounts: [],
  })

  const fetchCustomerAccounts = async () => {
    setLoadCustomerAccounts(true)

    const url =
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes'

    const { data, success } = await getData(
      url,
      {
        customerId: customer?.id,
        TargetProductCodes: Object.keys(ProductCode),
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccounts(data)
      setLoadCustomerAccounts(false)
    } else {
      setLoadCustomerAccounts(false)
    }
  }

  useEffect(() => {
    if (customer?.id) {
      fetchCustomerAccounts()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id])

  return (
    <ModalTemplate
      modalTitle="createEStatementOrder"
      actionText="add"
      cancelText="close"
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      handleAction={handleAction}
      disableCreate={isBusy}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              checked={statement?.isLocked}
              onCheck={value => {
                setStatement({
                  ...statement,
                  isLocked: value,
                })
              }}
              id={`${statement?.id}-isLocked`}
            />
            <label
              className="text-capitalize me-1"
              htmlFor={`${statement?.id}-isLocked`}
            >
              {localize('isLocked') + ' ?'}
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerPartial
            customer={customer}
            onCustomerLookup={handleLookupCustomer}
          />
          <div className="row mb-2">
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('startDate')}
                </label>
                <CustomDatePicker
                  defaultDate={getTodaysDate()}
                  name={'eStatementStartDate'}
                  onDateChange={date => {
                    setStatement({ ...statement, durationStartDate: date })
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('endDate')}
                </label>
                <CustomDatePicker
                  defaultDate={getTodaysDate()}
                  name={'eStatementEndDate'}
                  onDateChange={date => {
                    setStatement({ ...statement, durationEndDate: date })
                  }}
                  minDate={statement?.durationStartDate}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label className="col-4 text-capitalize me-2">
                  {localize('frequency')}
                </label>
                <EnumsServerCombo
                  value={statement?.scheduleFrequency}
                  customContainerStyle={{ width: '100%' }}
                  enumsKey={'ScheduleFrequency'}
                  onChange={e => {
                    setStatement({
                      ...statement,
                      scheduleFrequency: e,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={globalValidationErrors['Remarks']?.message}
              >
                <label className="col-4 text-capitalize me-2">
                  {localize('remarks')}
                </label>
                <input
                  value={statement?.remarks}
                  className="form-control"
                  onChange={e => {
                    setStatement({
                      ...statement,
                      scheduleFrequency: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('loansAccounts'),
                  item: (
                    <>
                      {loadCustomerAccounts ? (
                        <Loader />
                      ) : (
                        <LoanAccounts
                          data={customerAccounts?.customerLoanAccounts?.filter(
                            x => x?.bookBalance < 0
                          )}
                          handleSelectLoanAccount={handleSelectLoanAccount}
                          selectAllLoanAccounts={selectAllLoanAccounts}
                          selectedLoanAccounts={loanAccounts}
                          customerId={customer?.id}
                          canSelect
                        />
                      )}
                    </>
                  ),
                  icon: 'uil uil-edit',
                },
                {
                  label: localize('savingsAccounts'),
                  item: (
                    <>
                      {loadCustomerAccounts ? (
                        <Loader />
                      ) : (
                        <SavingsAccounts
                          data={customerAccounts?.customerSavingsAccounts}
                          savingAccounts={savingAccounts}
                          handleSelectSaving={handleSelectSavingAccount}
                          canSelect
                        />
                      )}
                    </>
                  ),
                  icon: 'uil uil-edit',
                },
                {
                  label: localize('investmentAccounts'),
                  item: (
                    <>
                      {loadCustomerAccounts ? (
                        <Loader />
                      ) : (
                        <InvestmentAccounts
                          data={customerAccounts?.customerInvestmentAccounts}
                          investmentAccounts={investmentAccounts}
                          handleSelectInvestmentAccount={
                            handleSelectInvestmentAccount
                          }
                          canSelect
                        />
                      )}
                    </>
                  ),
                  icon: 'uil uil-edit',
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AddEStatement
