/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import DatePicker from '../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import {
  formatCurrency,
  formatDate,
  getTodaysDate,
  parseDate,
} from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { getData } from '../../../../Helpers/webApi'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'
import { loadMedia } from '../../../../Helpers/Modules/media'
import Swal from 'sweetalert2'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import CreateTrainingPeriod from './CreateTrainingPeriod'
import { viewPDF } from '../../../../Helpers/Files/PDF'

function TrainingPeriods() {
  const [periods, setPeriods] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [modalMode, setModalMode] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [pdf, setPdf] = useState(null)

  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: parseDate(getTodaysDate()),
    endDate: parseDate(getTodaysDate()),
    filterText: '',
    status: 0,
  })
  const [isBusy, setIsBusy] = useState(false)

  async function loadMediaItem(id) {
    let mediaItem = null
    setIsBusy(true)
    mediaItem = await loadMedia(id)
    setIsBusy(false)
    if (mediaItem)
      setPdf(`data:${mediaItem?.contentType};base64,${mediaItem?.content}`)
    else Swal.fire('No File', 'Cant load file', 'error')
  }

  useEffect(() => {
    loadPeriods()
    // eslint-disable-next-line
  }, [reqParams?.pageSize, reqParams?.pageIndex, refresh])

  async function loadPeriods() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/trainingPeriod/find-in-page',
      reqParams,
      false
    )
    if (success) setPeriods(data)
    setIsBusy(false)
  }

  const columns = [
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    { label: localize('name'), template: r => <>{r?.fileName}</> },
    {
      label: localize('trainingFacilitator'),
      template: r => <>{r?.trainingFacilitatorDescription}</>,
    },
    {
      label: localize('trainingType'),
      template: r => <>{r?.trainingTypeDescription}</>,
    },
    { label: localize('venue'), template: r => <>{r?.venue}</> },
    {
      label: localize('startDate'),
      template: r => <>{formatDate(r?.durationStartDate)}</>,
    },
    {
      label: localize('endDate'),
      template: r => <>{formatDate(r?.durationEndDate)}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r?.totalValue)}</>,
    },
    {
      label: localize('document') + ' #',
      template: r => <>{r?.documentNumber}</>,
    },
    { label: localize('document'), template: r => <>{r?.fileDescription}</> },
    { label: localize('title'), template: r => <>{r?.fileTitle}</> },
    { label: localize('MIMEType'), template: r => <>{r?.fileMIMEType}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('employees'),
          localize('trainingPeriods'),
        ]}
      />
      <div className="card card-body bg-white">
        <div className="d-flex align-items-center">
          <button
            onClick={() => {
              setModalMode('add')
              setSelectedPeriod(null)
            }}
            className="btn-success btn px-5"
          >
            {localize('add')}
          </button>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between my-2">
              <div className="d-flex align-items-center gap-2">
                <label
                  htmlFor=""
                  className="text-capitalize"
                  style={{ minWidth: 'fit-content' }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  value={reqParams?.pageSize}
                  onChange={e =>
                    setReqParams({ ...reqParams, pageSize: e.target.value })
                  }
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
              <div className="d-flex align-items-center gap-2">
                <label
                  htmlFor=""
                  className="text-capitalize"
                  style={{ minWidth: 'fit-content' }}
                >
                  {localize('dateRange')}
                </label>
                <DatePicker
                  value={{
                    startDate: reqParams?.startDate,
                    endDate: reqParams?.endDate,
                  }}
                  onSelect={({ startDate, endDate }) => {
                    console.log({ startDate, endDate })
                    setReqParams({ ...reqParams, startDate, endDate })
                  }}
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <label
                  htmlFor=""
                  className="text-capitalize"
                  style={{ minWidth: 'fit-content' }}
                >
                  {localize('search')}
                </label>
                <form
                  className="d-flex align-items-center gap-2"
                  onSubmit={e => {
                    e.preventDefault()
                    setRefresh(!refresh)
                  }}
                >
                  <EnumsServerCombo
                    enumsKey="TrainingPeriodStatus"
                    value={reqParams?.status}
                    onChange={l =>
                      setReqParams({ ...reqParams, status: l.value })
                    }
                  />
                  <input type="search" className="form-control" />
                  <button className="btn btn-primary">
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="border border-light  rounded">
              <SimpleTable
                columns={columns}
                data={periods?.pageCollection || []}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => loadMediaItem(r?.id)}
                    >
                      <i className="uil uil-file-download-alt"></i>
                      <span className="text-capitalize">
                        {localize('viewDocument')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedPeriod(r)
                        setModalMode('view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span className="text-capitalize">
                        {localize('view')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedPeriod(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span className="text-capitalize">
                        {localize('edit')}
                      </span>
                    </div>
                  </>
                )}
              />
            </div>
            <div className="mt-2">
              <Pager
                itemsCount={periods?.itemsCount || 0}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
                pageBatchSize={5}
              />
            </div>
            {pdf && viewPDF(pdf, false, () => setPdf(false), null)}
            {modalMode && selectedPeriod && (
              <ModalTemplate
                modalMode={modalMode}
                modalTitle={localize('trainingPeriods')}
                handleClose={() => setModalMode(null)}
                modalClassName="modal-xl"
                hideFooter
              >
                <CreateTrainingPeriod
                  mode={modalMode}
                  period={selectedPeriod}
                  refreshPeriods={() => setRefresh(!refresh)}
                  closeModal={() => setModalMode(null)}
                />
              </ModalTemplate>
            )}

            {modalMode === 'add' && !selectedPeriod && (
              <ModalTemplate
                modalMode={modalMode}
                modalTitle={localize('trainingPeriods')}
                handleClose={() => setModalMode(null)}
                modalClassName="modal-xl"
                hideUpdate
                hideFooter
              >
                <CreateTrainingPeriod
                  mode={modalMode}
                  period={selectedPeriod}
                  refreshPeriods={() => setRefresh(!refresh)}
                  closeModal={() => setModalMode(null)}
                />
              </ModalTemplate>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default TrainingPeriods
