/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import SurveyEntries from '../SurveyEntries/SurveyEntries'
import Responses from '../Responses/Responses'

const ViewSurvey = ({ handleClose, mode, survey }) => {
  const [surveyEntries, setSurveyEntries] = useState([])
  const [waitingEntries, setWaitingEntries] = useState(false)
  const [surveyMetaData, setSurveyMetaData] = useState({})
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
  })

  const fetchEntries = async () => {
    setWaitingEntries(true)
    const url = '/Survey/find-survey-entries-by-survey-id-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        surveyId: survey?.id,
      },
      false
    )

    if (success) {
      setSurveyEntries(data?.pageCollection)
      setSurveyMetaData(data)
      setWaitingEntries(false)
    } else {
      setSurveyEntries([])
      setWaitingEntries(false)
    }
  }

  useEffect(() => {
    if (survey?.id) fetchEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, survey?.id])

  return (
    <ModalTemplate
      modalTitle="viewSurvey"
      cancelText="close"
      modalClassName="modal-xl"
      handleClose={handleClose}
      modalMode={mode}
      hideUpdate
    >
      <div className="row g-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-1">
              {localize('description')}
            </label>
            <input
              type="text"
              className="form-control"
              value={survey?.description}
              disabled
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-1">
              {localize('entryQuestion')}
            </label>
            <input
              type="text"
              className="form-control"
              value={survey?.entryQuestion}
              disabled
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-1">
              {localize('entryAnswer')}
            </label>
            <input
              type="text"
              className="form-control"
              disabled
              value={survey?.entryAnswer}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('endDate')}
            </label>
            <input
              type="text"
              value={survey?.endDate}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('closingRemark')}
            </label>
            <input
              type="text"
              disabled
              className="form-control"
              value={survey?.closingRemark}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              disabled
              className="form-control"
              value={survey?.createdBy}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              disabled
              className="form-control"
              value={formatDate(survey?.createdDate)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('modifiedBy')}
            </label>
            <input
              type="text"
              disabled
              className="form-control"
              value={survey?.modifiedBy}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3">
              {localize('modifiedDate')}
            </label>
            <input
              type="text"
              disabled
              className="form-control"
              value={formatDate(survey?.modifiedDate)}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <Tab
          tabItems={[
            {
              label: localize('entries'),
              item: (
                <SurveyEntries
                  fetchEntries={fetchEntries}
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                  surveyEntries={surveyEntries}
                  isBusy={waitingEntries}
                  hiddenColumns={[0]}
                  data={surveyMetaData}
                />
              ),
            },
            {
              label: localize('responses'),
              item: <Responses surveyId={survey?.id} />,
            },
          ]}
        />
      </div>
    </ModalTemplate>
  )
}

export default ViewSurvey
