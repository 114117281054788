/** @format */

/* eslint-disable */
import React from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../../Global/Localize'
import ListRefundBatches from '../Partials/ListRefundBatches'

const BatchAuthorization = () => {
  return (
    <>
      <BreadCrumb
        tree={[
          localize('refundBatches'),
          localize('settlement'),
          localize('authorization'),
        ]}
      />
      <div className="row">
        <ListRefundBatches refundBatchMode={'authorize'} />
      </div>
    </>
  )
}

export default BatchAuthorization
