/** @format */

/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react'

import logoSm from '../../assets/images/logo-sm.png'
import logoDark from '../../assets/images/logo-dark.png'
import logoLight from '../../assets/images/logo-light.png'

// our custom sidebar css
import './customsidebar.scss'

// menu mappings
import menus from './menus'

import MainMenuItem from './MainMenuItem'
import SubMenus from './SubMenus'
import { AnimatePresence } from 'framer-motion'
import { NavigationContext } from '../../Contexts/NavigationContext'
import { dashboardMenu } from './dashboardMenu'

function Index({ onCollapsed }) {
  const {
    navigatedParentCode,
    setNavigatedParentCode,
    navigateToComponent,
    navigatedComponent,
    setActiveComponent,
  } = useContext(NavigationContext)
  const [selectedMenu, setSelectedMenu] = useState(dashboardMenu)
  const [menuCollapsed, setMenuCollapsed] = useState('unknown')

  useEffect(() => {
    onCollapsed(menuCollapsed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCollapsed])

  useEffect(() => {
    menuCollapsed && setSelectedMenu(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigatedComponent])

  useEffect(() => {
    loadInitialMenu()
  }, [])

  async function loadInitialMenu() {
    let collapsed = await localStorage.getItem('menu-collapsed')
    setMenuCollapsed(collapsed === 'true')
  }

  return menuCollapsed === 'unknown' ? (
    <></>
  ) : (
    <div className="vertical-menu cent-vertical-menu">
      {menuCollapsed || (
        <div className="navbar-brand-box">
          <a to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="24" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="24" />
            </span>
          </a>

          <a to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="24" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="24" />
            </span>
          </a>
        </div>
      )}
      <button
        onClick={async () => {
          //@TODO: work on the hide and show
          let value = !menuCollapsed
          setMenuCollapsed(value)
          await localStorage.setItem('menu-collapsed', value)
        }}
        type="button"
        className="btn btn-sm p2.8 font-size-16 header-item waves-effect vertical-menu-btn"
      >
        <i className="fa fa-fw fa-bars"></i>
      </button>
      <div
        className="sidebar-menu-scroll cent-sidebar-menu"
        style={{
          width: menuCollapsed
            ? selectedMenu && selectedMenu.code !== 0
              ? 250
              : 50
            : 250,
        }}
      >
        {dashboardMenu && (
          <>
            {menuCollapsed || (
              <li className="menu-title" style={{ listStyle: 'none' }}>
                {selectedMenu && selectedMenu.code !== 0 ? '' : 'Dashboard'}
              </li>
            )}
            <MainMenuItem
              iconClass="uil uil-home-alt"
              label="dashboard"
              subMenuOpen={
                (selectedMenu && selectedMenu.code !== 0) || menuCollapsed
              }
              navigated={navigatedParentCode === dashboardMenu.code}
              onNavigated={() => {
                setSelectedMenu(dashboardMenu)
                setNavigatedParentCode(dashboardMenu.code)
                setActiveComponent(dashboardMenu.component)
                navigateToComponent(cs => {
                  let dsh = dashboardMenu.component
                  // eslint-disable-next-line eqeqeq
                  let found = cs.find(c => c.component == dsh)
                  if (found) return cs
                  else return [...cs, dashboardMenu]
                })
              }}
              showIconsOnly={menuCollapsed}
            />
          </>
        )}
        {menuCollapsed || (
          <li className="menu-title" style={{ listStyle: 'none' }}>
            {selectedMenu && selectedMenu.code !== 0 ? '' : 'Menu'}
          </li>
        )}
        {menus.map((menu, i) => (
          <MainMenuItem
            key={`main-menu-${i}`}
            onNavigated={() => {
              setSelectedMenu(menu)
            }}
            iconClass={menu.iconClass}
            label={menu.label}
            navigated={menu.code === navigatedParentCode}
            subMenuOpen={selectedMenu && selectedMenu.code !== 0}
            showIconsOnly={menuCollapsed}
          />
        ))}
        <AnimatePresence mode="wait">
          {selectedMenu && selectedMenu.code !== 0 && (
            <SubMenus
              menu={selectedMenu}
              closeMenu={() => setSelectedMenu(null)}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Index
