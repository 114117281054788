/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import rightAlignFragment, {
  formatCurrency,
  formatDate,
} from '../../Helpers/formatters'
import SimpleLookupWithFilters from './SimpleLookupWithFilters'

function SavingsProductLookup({ onSelect, displayValue, disabled }) {
  return (
    <>
      <SimpleLookupWithFilters
        disabled={disabled}
        difaultFilter={0}
        enumKey={'ProductSection'}
        paramKey={'productSection'}
        title={localize('savingsProductLookup')}
        url="/lookup/SavingsProduct/find-savings-product-by-product-section-and-filter-in-page"
        params={{
          filterText: '',
          pageSize: 10,
          pageIndex: 0,
        }}
        columns={[
          { label: localize('code'), template: r => <>{r?.paddedCode}</> },
          {
            label: localize('billReferenceNumber'),
            template: r => <>{r?.billReferenceNumber}</>,
            sortBy: 'billReferenceNumber',
          },
          {
            label: localize('name'),
            template: r => <>{r?.description}</>,
            sortBy: 'description',
          },
          {
            label: rightAlignFragment(localize('minimumBalance')),
            template: r => <>{formatCurrency(r?.minimumBalance)}</>,
            sortBy: 'minimumBalance',
          },
          {
            label: rightAlignFragment(localize('operatingBalance')),
            template: r => <>{formatCurrency(r?.operatingBalance)}</>,
            sortBy: 'operatingBalance',
          },
          {
            label: localize('annualPercentageYield'),
            template: r => <>{r?.annualPercentageYield}</>,
          },
          {
            label: rightAlignFragment(localize('maximumAllowedWithdrawal')),
            template: r => <>{formatCurrency(r?.maximumAllowedWithdrawal)}</>,
          },
          {
            label: rightAlignFragment(localize('maximumAllowedDeposit')),
            template: r => <>{formatCurrency(r?.maximumAllowedDeposit)}</>,
          },
          {
            label: localize('withdrawalNoticePeriod'),
            template: r => <>{r?.withdrawalNoticePeriod}</>,
          },
          {
            label: localize('personalIdentificationNumber'),
            template: r => <>{r?.personalIdentificationNumber}</>,
          },
          {
            label:
              rightAlignFragment(localize('withdrawalNoticeAmount')) + ' 1',
            template: r => <>{formatCurrency(r?.withdrawalNoticeAmount)}</>,
          },
          {
            label: localize('withdrawalInterval') + ' 2',
            template: r => <>{r?.withdrawalInterval}</>,
          },
          {
            label: rightAlignFragment(localize('maximumOverdraftLimit')),
            template: r => <>{formatCurrency(r?.maximumOverdraftLimit)}</>,
          },
          {
            label: localize('chartOfAccountName'),
            template: r => <>{r?.chartOfAccountName}</>,
          },
          {
            label: localize('chartOfAccountCostDistributionRule'),
            template: r => (
              <>{r?.chartOfAccountCostDistributionRuleDescription}</>
            ),
          },
          {
            label: localize('interestExpenseChartOfAccountName'),
            template: r => <>{r?.interestExpenseChartOfAccountName}</>,
          },
          {
            label: localize(
              'interestExpenseChartOfAccountCostDistributionRule'
            ),
            template: r => (
              <>
                {
                  r?.interestExpenseChartOfAccountCostDistributionRuleDescription
                }
              </>
            ),
          },
          {
            label: localize('interestPayableChartOfAccountName'),
            template: r => <>{r?.interestPayableChartOfAccountName}</>,
          },
          {
            label: localize(
              'interestPayableChartOfAccountCostDistributionRule'
            ),
            template: r => (
              <>
                {
                  r?.interestPayableChartOfAccountCostDistributionRuleDescription
                }
              </>
            ),
          },
          {
            label: localize('recoveryPriority'),
            template: r => <>{r?.priority}</>,
          },
          {
            label: localize('isDefault'),
            template: r => (
              <span className="text-capitalize">
                {localize(`${r?.isDefault.toString()}`)}
              </span>
            ),
          },
          {
            label: localize('automateLedgerFeeCalculation'),
            template: r => (
              <span className="text-capitalize">
                {localize(`${r?.automateLedgerFeeCalculation.toString()}`)}
              </span>
            ),
          },
          {
            label: localize('throttleOverTheCounterWithdrawals'),
            template: r => (
              <span className="text-capitalize">
                {localize(`${r?.throttleOverTheCounterWithdrawals.toString()}`)}
              </span>
            ),
          },
          {
            label: localize('throttleBankToMobileWithdrawals'),
            template: r => (
              <span className="text-capitalize">
                {localize(`${r?.throttleBankToMobileWithdrawals.toString()}`)}
              </span>
            ),
          },
          {
            label: localize('throttleAgencyBankingWithdrawals'),
            template: r => (
              <span className="text-capitalize">
                {localize(`${r?.throttleAgencyBankingWithdrawals.toString()}`)}
              </span>
            ),
          },
          {
            label: localize('concealBankToMobileListing'),
            template: r => (
              <span className="text-capitalize">
                {localize(`${r?.concealBankToMobileListing.toString()}`)}
              </span>
            ),
          },
          {
            label: localize('isLocked'),
            template: r => (
              <span className="text-capitalize">
                {localize(`${r?.isLocked.toString()}`)}
              </span>
            ),
          },

          {
            label: localize('modifiedBy'),
            template: r => <>{r?.modifiedBy}</>,
          },
          {
            label: localize('modifiedDate'),
            template: r => <>{formatDate(r?.modifiedDate)}</>,
          },
          {
            label: localize('createdBy'),
            template: r => <>{r?.createdBy}</>,
          },
          {
            label: localize('createdDate'),
            template: r => <>{formatDate(r?.createdDate)}</>,
          },
        ]}
        onSelect={onSelect}
        displayValue={displayValue}
      />
    </>
  )
}
export default SavingsProductLookup
