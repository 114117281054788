/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import BankReconciliationPeriodLookup from '../../../../../Components/Lookup/BankReconciliationPeriodLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Adjustments from './Adjustments/Adjustments'
import Discrepancies from './Discrepancies/Discrepancies'
import Transactions from './Transactions/Transactions'

function Processing() {
  const [selectedProcessing, setSelectedProcessing] = useState(null)

  const handleSelect = r => {
    setSelectedProcessing(r)
  }

  const handleResetSelectedPeriod = () => {
    setSelectedProcessing(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('bankReconciliation'),
          localize('processing'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row g-3 mb-3">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="parentPeriod" className="text-capitalize col-4">
                  {localize('parentPeriod')}
                </label>

                <BankReconciliationPeriodLookup
                  displayValue={selectedProcessing?.indentedDescription}
                  handleClickOnce={handleSelect}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="startDate" className="text-capitalize col-4">
                  {localize('startDate')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(selectedProcessing?.durationStartDate)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="endDate" className="text-capitalize col-4">
                  {localize('endDate')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={formatDate(selectedProcessing?.durationEndDate)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label htmlFor="parentPeriod" className="text-capitalize col-4">
                  {localize('g/LAccountName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={selectedProcessing?.chartOfAccountName}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="bankBranchName"
                  className="text-capitalize col-4"
                >
                  {localize('bankBranchName')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="bankBranchName"
                  value={
                    selectedProcessing?.branchDescription ||
                    selectedProcessing?.bankBranchName
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="bankAccountNumber"
                  className="text-capitalize col-4"
                >
                  {localize('bankAccountNumber')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="bankAccountNumber"
                  value={selectedProcessing?.bankAccountNumber}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="bankAccountBalance"
                  className="text-capitalize col-4"
                >
                  {localize('bankAccountBalance')}
                </label>

                <input
                  type="text"
                  className="form-control text-end"
                  value={formatCurrency(
                    selectedProcessing?.bankAccountBalance,
                    null,
                    null,
                    false
                  )}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="d-flex align-items-center">
                <label htmlFor="remarks" className="text-capitalize col-2">
                  {localize('remarks')}
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="remarks"
                  value={selectedProcessing?.remarks}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('transactions'),
                  item: (
                    <>
                      <Transactions
                        bankReconciliationPeriodId={selectedProcessing?.id}
                      />
                    </>
                  ),
                },
                {
                  label: localize('adjustments'),
                  item: (
                    <>
                      <Adjustments
                        bankReconciliationPeriodId={selectedProcessing?.id}
                        handleResetSelectedPeriod={handleResetSelectedPeriod}
                      />
                    </>
                  ),
                },
                {
                  label: localize('discrepancies'),
                  item: (
                    <>
                      <Discrepancies
                        bankReconciliationPeriodId={selectedProcessing?.id}
                      />
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Processing
