/** @format */

/* eslint-disable */
import React from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { useState } from 'react'
import { putData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'

function IAEntries({
  edit,
  unpostedEntries,
  handleRemove,
  mode,
  entries,
  onSelectItem,
  loadEntries,
}) {
  const [selectedEntries, setSelectedEntries] = useState([])
  const [toggleSelection, setToggleSelection] = useState(false)

  const onCheckEntries = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedEntries([])
      setSelectedEntries([...entries])
    } else {
      setSelectedEntries([])
      setToggleSelection(checkStatus)
    }
  }

  const removeEntries = async () => {
    if (selectedEntries.length === 0) {
      Swal.fire({
        icon: 'info',
        text: 'Select Atleast One Entry',
        title: 'Ooops!',
        showCloseButton: true,
      })
      return
    }
    const { success } = await putData(
      '/InterAccountTransferBatch/remove-inter-account-transfer-batch-entries',
      selectedEntries,
      true,
      `Operation Completed Successfully`
    )
    if (success) {
      loadEntries()
    }
  }

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedEntries.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedEntries([...selectedEntries, r])
              onSelectItem([...selectedEntries, r])
            } else {
              setSelectedEntries(selectedEntries.filter(s => s.id !== r.id))
              onSelectItem(selectedEntries.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('apportionFrom'),
      template: r => <>{r.apportionFromDescription}</>,
    },
    {
      label: localize('apportionTo'),
      template: r => <>{r.apportionToDescription}</>,
    },
    {
      label: localize('apportionedComponent'),
      template: r => <>{r.apportionedComponentDescription}</>,
    },
    {
      label: localize('apportionedAmount'),
      template: r => <>{r.apportionedAmount}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('debitFullAccount'),
      template: r => <>{r.contraCustomerAccountFullAccountNumber}</>,
    },
    {
      label: localize('debitCustomerName'),
      template: r => <>{r.contraCustomerAccountCustomerFullName}</>,
    },
    {
      label: localize('debitCustomerAccountNumber'),
      template: r => <>{r.paddedContraCustomerAccountCustomerAccountNumber}</>,
    },
    {
      label: localize('debitCustomerMemberNumber'),
      template: r => (
        <>{r.formattedContraCustomerAccountCustomerMemberNumber}</>
      ),
    },
    {
      label: localize('debitCustomerPersonalFileNumber'),
      template: r => <>{r.contraCustomerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('debitChartOfAccountName'),
      template: r => <>{r.contraChartOfAccountName}</>,
    },
    {
      label: localize('debitChartOfAccountCostDistributionRule'),
      template: r => (
        <>{r.contraChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('creditFullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('creditCustomerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('creditCustomerAccountNumber'),
      template: r => <>{r.paddedCustomerAccountCustomerAccountNumber}</>,
    },
    {
      label: localize('creditCustomerMemberNumber'),
      template: r => <>{r.formattedCustomerAccountCustomerMemberNumber}</>,
    },
    {
      label: localize('creditCustomerPersonalFileNumber'),
      template: r => <>{r.customerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('creditChartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('creditChartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
  ]
  return (
    <div>
      <SimpleTable
        columns={edit ? columns : columns.slice(1, -1)}
        data={mode === 'add' ? unpostedEntries : entries}
        contextMenu={
          mode !== 'add'
            ? null
            : r => (
                <>
                  <div
                    onClick={() => {
                      handleRemove(r)
                    }}
                    className="ctxt-menu-item"
                  >
                    <i className="uil uil-trash text-danger"></i>
                    {localize('remove')}
                  </div>
                </>
              )
        }
      />
      {mode === 'edit' && (
        <>
          <div className="row mb-3 g-3 mt-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <Checkbox
                  id="toggle-selection"
                  checked={toggleSelection}
                  onCheck={v => {
                    onCheckEntries(v)
                  }}
                />
                <label
                  htmlFor="toggle-selection"
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')}
                </label>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex justify-content-end align-items-end">
                <button
                  onClick={removeEntries}
                  className="btn btn-success text-uppercase ms-3 px-4"
                >
                  {localize('remove')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default IAEntries
