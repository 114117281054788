/** @format */

import React, { useState, useEffect } from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import { getData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'

const AssetMovements = ({ asset }) => {
  const columns = [
    {
      label: localize('sourceDepartment'),
      template: r => <>{r.sourceDepartmentDescription}</>,
    },
    {
      label: localize('destinationDepartment'),
      template: r => <>{r.destinationDepartmentDescription}</>,
    },
    {
      label: localize('sourceLocation'),
      template: r => <>{r.sourceLocationDescription}</>,
    },
    {
      label: localize('destinationlocation'),
      template: r => <>{r.destinationLocationDescription}</>,
    },
    {
      label: localize('sender'),
      template: r => <>{r.sender}</>,
    },
    {
      label: localize('receipient'),
      template: r => <>{r.receipient}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [entriesLoading, setEntriesLoading] = useState(false)
  const [entries, setEntries] = useState([])

  const loadMovementHistories = async () => {
    setEntriesLoading(true)
    const { success, data } = await getData(
      `/Asset/find-assets-movement-histories-by-asset-id?assetId=${asset?.id}`,
      {},
      false
    )
    if (success) {
      setEntriesLoading(false)
      setEntries(data.result)
    } else {
      setEntriesLoading(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadMovementHistories()
    // eslint-disable-next-line
  }, [asset?.id])

  return (
    <>
      {entriesLoading ? (
        <Loader />
      ) : (
        <>
          <SimpleTable columns={columns} data={entries} />
        </>
      )}
    </>
  )
}

export default AssetMovements
