/** @format */
import React, { useState, useEffect } from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import { getData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../Helpers/formatters'

const AssetDepreciations = ({ asset }) => {
  const columns = [
    {
      label: localize('reference'),
      template: r => <>{r.paddedReference}</>,
    },
    {
      label: localize('beginningBookValue'),
      template: r => <>{formatCurrency(r.beginningBookValue)}</>,
    },
    {
      label: localize('totalDepreciationCost'),
      template: r => <>{formatCurrency(r.totalDepreciableCost)}</>,
    },
    {
      label: localize('depreciationRate'),
      template: r => <>{formatAsPercent(r.depreciationRate)}</>,
    },
    {
      label: localize('depreciationExpenses'),
      template: r => <>{formatCurrency(r.depreciationExpenses)}</>,
    },
    {
      label: localize('accumulatedDepreciation'),
      template: r => <>{formatCurrency(r.accumulatedDepreciation)}</>,
    },
    {
      label: localize('endingBookValue'),
      template: r => <>{formatCurrency(r.endingBookValue)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [entriesLoading, setEntriesLoading] = useState(false)
  const [entries, setEntries] = useState([])

  const loadAssetDepreciations = async () => {
    setEntriesLoading(true)
    const { success, data } = await getData(
      `/Asset/find-asset-depreciations-by-asset-id?assetId=${asset?.id}`,
      {},
      false
    )
    if (success) {
      setEntriesLoading(false)
      setEntries(data.result)
    } else {
      setEntriesLoading(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadAssetDepreciations()
    // eslint-disable-next-line
  }, [asset?.id])

  return (
    <>
      {entriesLoading ? (
        <Loader />
      ) : (
        <>
          <SimpleTable columns={columns} data={entries} />
        </>
      )}
    </>
  )
}

export default AssetDepreciations
