/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import GroupedTable from '../../../../Components/Tables/GroupedTable'

export const ModulesACL = ({ modulesAcl }) => {
  const columns = [
    {
      label: localize('module'),
      template: r => <>{r.module}</>,
    },
    {
      label: localize('item'),
      template: r => <span className="text-capitalize">{r.description}</span>,
    },
    {
      label: localize('parentItem'),
      template: r => <>{r.parentDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <GroupedTable
      groupBy={{
        label: localize('module'),
        column: 'module',
      }}
      selectables
      maxHeight={400}
      columns={columns}
      data={modulesAcl}
    />
  )
}
