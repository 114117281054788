/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import EmployeeTypeInvestmentProducts from './Selectables/EmployeeTypeInvestmentProducts'
import EmployeeTypeLoanProducts from './Selectables/EmployeeTypeLoanProducts'
import EmployeeTypeSavingsProducts from './Selectables/EmployeeTypeSavingsProducts'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'

function EmployeeTypeModal({
  mode,
  onHideModal,
  selectedEmployeeType,
  setSelectedEmployeeType,
  tabLoading,
  loadTableData,
  tableData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    if (tableData.find(x => x.category == selectedEmployeeType.category)) {
      setIsBusy(false)
      return showNotification(
        'Employee Type',
        'info',
        'sorry, but the selected category already exists'
      )
    }
    const url =
      mode === 'edit'
        ? '/EmployeeType/update-employee-type-with-attached-product'
        : '/EmployeeType/create-employee-type-with-attached-products'
    const { success } = await postData(
      url,
      {
        employeeType: selectedEmployeeType || {},
        productCollectionInfo: {
          eligibleIncomeDeductionInvestmentProductCollection: [],
          eligibleIncomeDeductionLoanProductCollection: [],
          eligibleIncomeDeductionSavingsProductCollection: [],
          investmentProductCollection:
            selectedEmployeeType?.investmentProductCollection.filter(
              x => x.checked
            ) || [],
          loanProductCollection:
            selectedEmployeeType?.loanProductCollection.filter(
              x => x.checked
            ) || [],
          savingsProductCollection:
            selectedEmployeeType?.savingsProductCollection.filter(
              x => x.checked
            ) || [],
        },
      },
      false
    )
    if (success) {
      setIsBusy(false)
      loadTableData()
      onHideModal()
      setSelectedEmployeeType(null)
      showNotification('Employee Type', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    onHideModal()
    setGlobalValidationErrors({})
    setSelectedEmployeeType(null)
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalClassName="modal-xl"
        modalTitle={'employeeType'}
        modalMode={mode}
        handleAction={handleSubmit}
        handleClose={closeModal}
        actionText={mode === 'add' ? localize('create') : localize('update')}
        hideUpdate={mode === 'view'}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                disabled={mode === 'view'}
                id="cExecutePayoutStandingOrders"
                onCheck={e => {
                  setSelectedEmployeeType({
                    ...selectedEmployeeType,
                    executePayoutStandingOrders: e,
                  })
                }}
                checked={selectedEmployeeType?.executePayoutStandingOrders}
              />
              <label
                htmlFor="cExecutePayoutStandingOrders"
                className="text-capitalize"
              >
                {localize('executePayoutStandingOrders')}?
              </label>
              <div className="mx-3"></div>
              <Checkbox
                disabled={mode === 'view'}
                id="cExecuteAdHocStandingOrders"
                onCheck={e => {
                  setSelectedEmployeeType({
                    ...selectedEmployeeType,
                    executeAdHocStandingOrders: e,
                  })
                }}
                checked={selectedEmployeeType?.executeAdHocStandingOrders}
              />
              <label
                htmlFor="cExecuteAdHocStandingOrders"
                className="text-capitalize"
              >
                {localize('executeAdHocStandingOrders')}?
              </label>
              <div className="mx-3"></div>
              <Checkbox
                disabled={mode === 'view'}
                id="cRecoverIndefiniteCharges"
                onCheck={e => {
                  setSelectedEmployeeType({
                    ...selectedEmployeeType,
                    recoverIndefiniteCharges: e,
                  })
                }}
                checked={selectedEmployeeType?.recoverIndefiniteCharges}
              />
              <label
                htmlFor="cRecoverIndefiniteCharges"
                className="text-capitalize"
              >
                {localize('recoverIndefiniteCharges')}?
              </label>
              <div className="mx-3"></div>
              <Checkbox
                disabled={mode === 'view'}
                id="cConsolidatePostings"
                onCheck={e => {
                  setSelectedEmployeeType({
                    ...selectedEmployeeType,
                    consolidatePostings: e,
                  })
                }}
                checked={selectedEmployeeType?.consolidatePostings}
              />
              <label htmlFor="cConsolidatePostings" className="text-capitalize">
                {localize('consolidatePostings')}?
              </label>
              <div className="mx-3"></div>
              <Checkbox
                disabled={mode === 'view'}
                id="cIsLocked"
                onCheck={e => {
                  setSelectedEmployeeType({
                    ...selectedEmployeeType,
                    isLocked: e,
                  })
                }}
                checked={selectedEmployeeType?.isLocked}
              />
              <label htmlFor="cIsLocked" className="text-capitalize">
                {localize('isLocked')}?
              </label>
              <div className="mx-3"></div>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['EmployeeType.Description']?.message
                  }
                >
                  <label className="text-capitalize col-3">
                    {localize('name')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    className="form-control"
                    defaultValue={selectedEmployeeType?.description}
                    onChange={e => {
                      clearValidation(['EmployeeType.Description'])
                      setSelectedEmployeeType({
                        ...selectedEmployeeType,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('category')}
                  </label>
                  {mode === 'view' ? (
                    <input
                      className="form-control"
                      defaultValue={selectedEmployeeType?.category}
                      disabled
                    />
                  ) : (
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="EmployeeCategory"
                      value={selectedEmployeeType?.category}
                      onChange={e =>
                        setSelectedEmployeeType({
                          ...selectedEmployeeType,
                          category: e.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['EmployeeType.ChartOfAccountName']
                      ?.message
                  }
                >
                  <label className="text-capitalize col-3">
                    {localize('chartOfAccount')}
                  </label>
                  {mode === 'view' ? (
                    <input
                      className="form-control"
                      defaultValue={selectedEmployeeType?.chartOfAccountName}
                      disabled
                    />
                  ) : (
                    <ChartOfAccountsLookup
                      displayValue={selectedEmployeeType?.chartOfAccountName}
                      onSelect={r => {
                        clearValidation(['EmployeeType.ChartOfAccountName'])
                        setSelectedEmployeeType({
                          ...selectedEmployeeType,
                          chartOfAccountId: r.id,
                          chartOfAccountName: r.description,
                        })
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('taxRelief')}
                  </label>
                  <MaskNumber
                    disabled={mode === 'view'}
                    defaultMaskValue={selectedEmployeeType?.taxReliefAmount}
                    className="form-control"
                    onMaskNumber={e => {
                      setSelectedEmployeeType({
                        ...selectedEmployeeType,
                        taxReliefAmount: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('maximumProvidentFundRelief')}
                  </label>

                  <MaskNumber
                    disabled={mode === 'view'}
                    defaultMaskValue={
                      selectedEmployeeType?.maximumProvidentFundReliefAmount
                    }
                    className="form-control"
                    onMaskNumber={e => {
                      setSelectedEmployeeType({
                        ...selectedEmployeeType,
                        maximumProvidentFundReliefAmount: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('maximumInsuranceRelief')}
                  </label>
                  <MaskNumber
                    disabled={mode === 'view'}
                    defaultMaskValue={
                      selectedEmployeeType?.maximumInsuranceReliefAmount
                    }
                    className="form-control"
                    onMaskNumber={e => {
                      setSelectedEmployeeType({
                        ...selectedEmployeeType,
                        maximumInsuranceReliefAmount: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-3">
                    {localize('maximumNHIFReliefAmount')}
                  </label>

                  <MaskNumber
                    disabled={mode === 'view'}
                    defaultMaskValue={
                      selectedEmployeeType?.maximumNHIFReliefAmount
                    }
                    className="form-control"
                    onMaskNumber={e => {
                      setSelectedEmployeeType({
                        ...selectedEmployeeType,
                        maximumNHIFReliefAmount: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            {tabLoading ? (
              <Loader />
            ) : (
              <div className="row mb-2">
                <div className="col md-12">
                  <Tab
                    tabItems={[
                      {
                        label: localize('offsetableLoanProducts'),
                        item: (
                          <EmployeeTypeLoanProducts
                            disabled={mode === 'view'}
                            setSelectedEmployeeType={setSelectedEmployeeType}
                            selectedEmployeeType={selectedEmployeeType}
                            prefix={'create-employee-type-modal'}
                          />
                        ),
                      },
                      {
                        label: localize('offsetableInvestmentProducts'),
                        item: (
                          <EmployeeTypeInvestmentProducts
                            disabled={mode === 'view'}
                            setSelectedEmployeeType={setSelectedEmployeeType}
                            selectedEmployeeType={selectedEmployeeType}
                            prefix={'create-employee-type-modal'}
                          />
                        ),
                      },
                      {
                        label: localize('offsetableSavingsProducts'),
                        item: (
                          <EmployeeTypeSavingsProducts
                            disabled={mode === 'view'}
                            setSelectedEmployeeType={setSelectedEmployeeType}
                            selectedEmployeeType={selectedEmployeeType}
                            prefix={'create-employee-type-modal'}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default EmployeeTypeModal
