/** @format */

/* eslint-disable */
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import InventoryCategoryLookup from '../../../../../Components/Lookup/InventoryCategoryLookup'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'

const CreateInventoryCategories = ({
  handleClose,
  handleAction,
  mode,
  categories,
  setCategories,
  isBusyFetching,
  globalValidationErrors,
  clearValidation,
}) => {
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      modalTitle={localize('inventoryCategory')}
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            onCheck={x => {
              setCategories({
                ...categories,
                isLocked: x,
              })
            }}
            checked={categories?.isLocked}
            id="isLocked"
          />
          <label htmlFor="isLocked" className="text-capitalize">
            {localize('isLocked') + '?'}
          </label>
        </div>
      }
    >
      {isBusyFetching ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col-6 d-flex align-items-center">
              <label htmlFor="parentCategory" className="text-capitalize col-3">
                {localize('parentCategory')}
              </label>
              <InventoryCategoryLookup
                displayValue={categories?.description}
                onSelect={e => {
                  setCategories({
                    ...categories,
                    description: e.description,
                    parentId: e.id,
                  })
                }}
                disabled={mode === 'add' ? false : true}
              />
            </div>
            <div
              className="col-6 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['parentDescription']?.message
              }
            >
              <label htmlFor="name" className="text-capitalize col-3">
                {localize('name')}
              </label>
              <input
                type="text"
                className="form-control"
                value={
                  mode === 'add'
                    ? categories.parentDescription
                    : categories.description
                }
                onChange={e => {
                  mode === 'add'
                    ? setCategories({
                        ...categories,
                        parentDescription: e?.target.value,
                      })
                    : setCategories({
                        ...categories,
                        description: e?.target.value,
                      })

                  clearValidation('parentDescription') &&
                    clearValidation('description')
                }}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div
              className="col-6 d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['glAccount']?.message}
            >
              <label htmlFor="g/lAccount" className="text-capitalize col-3">
                {localize('gLAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={categories?.chartOfAccountName}
                onSelect={e => {
                  clearValidation('glAccount')
                  setCategories({
                    ...categories,
                    chartOfAccountName: e?.name,
                    chartOfAccountId: e?.id,
                  })
                }}
                disabled={mode === 'add' ? false : true}
              />
            </div>
            <div
              className="col-6 d-flex align-items-center with-validation"
              validation-message={globalValidationErrors['expenseGl']?.message}
            >
              <label htmlFor="name" className="text-capitalize col-3">
                {localize('expenseG/LAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={categories?.expenseChartOfAccountName}
                onSelect={e => {
                  clearValidation('expenseGl')
                  setCategories({
                    ...categories,
                    expenseChartOfAccountName: e?.name,
                    expenseChartOfAccountId: e?.id,
                  })
                }}
                disabled={mode === 'add' ? false : true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col d-flex align-items-center">
              <label htmlFor="type" className="text-capitalize col-3">
                {localize('type')}
              </label>
              <EnumsServerCombo
                sort
                className="col-12"
                value={categories?.type}
                enumsKey="employerType"
                onChange={e =>
                  setCategories({
                    ...categories,
                    type: e?.value,
                  })
                }
                customContainerStyle={{
                  width: 'inherit',
                }}
              />
            </div>
            <div className="col-6 d-flex align-items-center">
              <label htmlFor="remarks" className="text-capitalize col-3">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                value={categories?.remarks}
                onChange={e =>
                  setCategories({
                    ...categories,
                    remarks: e?.target.value,
                  })
                }
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}
export default CreateInventoryCategories
