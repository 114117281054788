/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import CreateAccountClosureRequest from './CreateAccountClosureRequest/CreateAccountClosureRequest'

const Register = () => {
  const [requests, setRequests] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
    customerFilter: 0,
  })

  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('ref_1Account'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('ref_2Membership'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('ref_3PersonalFile'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('reason'),
      template: r => <>{r.reason}</>,
    },
    {
      label: localize('approval') + '/' + localize('rejectionRemarks'),
      template: r => <>{r.approvalRemarks}</>,
    },
    {
      label: localize('approved') + '/' + localize('rejectedBy'),
      template: r => <>{r.approvedBy}</>,
    },
    {
      label: localize('approved') + '/' + localize('rejectedDate'),
      template: r => <>{formatDate(r.approvedDate)}</>,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: localize('verified') + '/' + localize('rejectedBy'),
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: localize('settledBy'),
      template: r => <>{r.settledBy}</>,
    },
    {
      label: localize('settledDate'),
      template: r => <>{formatDate(r.settledDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const fetchAccountClosureRequests = async () => {
    setIsBusy(true)

    const url =
      '/AccountClosureRequest/find-account-closure-requests-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setRequests(data)
      setIsBusy(false)
    } else setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
  }

  const handleClickAddButton = () => {
    setMode('add')
  }

  useEffect(() => {
    fetchAccountClosureRequests()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      {mode === 'add' && (
        <CreateAccountClosureRequest
          mode={mode}
          handleClose={handleClose}
          fetchAccountClosureRequests={fetchAccountClosureRequests}
        />
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('accountClosure'),
          localize('accountClosureRequests'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-3">
              <AddNewButton handleClickAddButton={handleClickAddButton} />
            </div>
          </div>

          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3 mt-3 gap-1">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, customerFilter: value })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchAccountClosureRequests()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchAccountClosureRequests()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable columns={columns} data={requests?.pageCollection} />
              <Pager
                itemsCount={requests.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Register
