/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'
import CustomerAccountLookup from '../../../../../Components/Lookup/CustomerAccountLookup'
import {
  alternateChannelType,
  productCode,
} from '../../../../../Helpers/constants'
import { DebitCardRegisterLookup } from '../../../../../Components/Lookup/DebitCardRegisterLookup'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

export const ChannelLinkingCustomerAccountPartial = ({
  selectedAlternateChannelType,
  onSelectedCustomerAccount,
  accountHistoryQueryParams,
  customerAccount,
  setCustomerAccount,
  setAccountHistoryQueryParams,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }
  useEffect(() => {
    setValidationBag({})
  }, [selectedAlternateChannelType])

  const fetchCustomerAccount = async r => {
    setIsBusy(true)

    const url = '/lookup/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: r?.customerAccountId,
        includeBalances: false,
        includeProductDescription: true,
      },
      false,
    )

    if (success) {
      setCustomerAccount(data)
      onSelectedCustomerAccount({
        ...data,
        debitCardRegisterId: r?.debitCardRegisterId,
      })
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleOnSelectDebitCardRegister = async r => {
    clearValidation('DebitCardRegisterId')
    if (r?.status !== '2') {
      return swal.fire(
        'Alternate Channel Managment',
        'You can only select a debit card whose status is approved',
        'error',
      )
    }
    setAccountHistoryQueryParams({
      ...accountHistoryQueryParams,
      debitCardRegisterCustomerAccountId: r?.customerAccountId,
      debitCardRegisterId: r?.id,
    })
    await fetchCustomerAccount({ ...r, debitCardRegisterId: r.id })
  }

  const handleOnSelectCustomerAccount = r => {
    clearValidation('CustomerAccountId')
    setCustomerAccount({ ...r })
    setAccountHistoryQueryParams({
      ...accountHistoryQueryParams,
      customerAccountId: r?.id,
    })
    onSelectedCustomerAccount(r)
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <div className={`row g-2  mb-2 `}>
          <div
            validation-message={
              validationBag?.CustomerAccountId?.message ||
              validationBag?.DebitCardRegisterId?.message
            }
            className="col-3 d-flex align-items-center with-validation"
          >
            <label className={`col-4 text-capitalize`}>
              {localize('fullAccountNumber')}
            </label>
            {[
              alternateChannelType.ABCBANK.toString(),
              alternateChannelType.INTERSWITCH.toString(),
              alternateChannelType.SACCOLINK.toString(),
              alternateChannelType.SPARROW.toString(),
            ].includes(selectedAlternateChannelType) ? (
              <DebitCardRegisterLookup
                displayValue={customerAccount?.fullAccountNumber}
                onSelect={handleOnSelectDebitCardRegister}
              />
            ) : (
              <CustomerAccountLookup
                displayValue={customerAccount?.fullAccountNumber}
                productCode={productCode.SAVINGS.toString()}
                onSelect={handleOnSelectCustomerAccount}
              />
            )}
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.statusDescription || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.remarks || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('productName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customerAccount?.customerAccountTypeTargetProductDescription ||
                ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.customerFullName || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('employerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.customerEmployerDescription || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerType')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                customerAccount?.customerMembershipClassCustomerTypeDescription ||
                ''
              }
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerSerialNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.paddedCustomerSerialNumber || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('identificationNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.customerIdentificationNumber || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.formattedCustomerMemberNumber || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.formattedCustomerAccountNumber || ''}
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={customerAccount?.customerPersonalFileNumber || ''}
            />
          </div>
        </div>
      )}
    </>
  )
}
