/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { alternateChannelManagementAction } from '../../../../Helpers/constants'
import ChannelDelinking from './ChannelDelinking/ChannelDelinking'
import ChannelLinking from './ChannelLinking/ChannelLinking'
import ChannelRenewal from './ChannelRenewal/ChannelRenewal'
import ChannelReplacement from './ChannelReplacement/ChannelReplacement'
import ChannelStoppage from './ChannelStoppage/ChannelStoppage'
import { AccountHistory } from './Partials/AccountHistory'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import Loader from '../../../../Components/Loaders/Loader'

const ChannelManagement = () => {
  const [operation, setOperation] = useState(
    alternateChannelManagementAction.CHANNEL_LINKING.value?.toString(),
  )
  const [channel, setChannel] = useState({
    type: '64',
  })
  const [isBusy, setIsBusy] = useState(false)
  const [effectCharges, setEffectCharges] = useState(false)
  const [customerAccount, setCustomerAccount] = useState(null)

  const [accountHistories, setAccountHistories] = useState([])
  const [loadingAccountHistories, setLoadingAccountHistories] = useState(false)
  const [accountHistoryQueryParams, setAccountHistoryQueryParams] =
    useState(null)

  const handleOperationsChange = e => {
    setOperation(e?.value)
    setChannel({
      type: '64',
    })
  }

  const fetchAccountHistories = async () => {
    setLoadingAccountHistories(true)
    const { success, data } = await postData(
      '/ChannelManagement/fetch-alternate-channel-customer-account-history',
      {
        AlternateChannelManagementAction: operation,
        AlternateChannelType: channel?.type,
        AlternateChannelCustomerAccountId:
          accountHistoryQueryParams?.alternateChannelCustomerAccountId,
        DebitCardRegisterCustomerAccountId:
          accountHistoryQueryParams?.debitCardRegisterCustomerAccountId,
        CustomerAccountId: accountHistoryQueryParams?.customerAccountId,
      },
      false,
    )
    if (success) {
      setAccountHistories(data.result)
    }
    setLoadingAccountHistories(false)
  }

  useEffect(() => {
    if (
      accountHistoryQueryParams?.customerAccountId ||
      accountHistoryQueryParams?.debitCardRegisterCustomerAccountId ||
      accountHistoryQueryParams?.alternateChannelCustomerAccountId
    ) {
      fetchAccountHistories()
    }
  }, [
    accountHistoryQueryParams?.customerAccountId,
    accountHistoryQueryParams?.debitCardRegisterCustomerAccountId,
    accountHistoryQueryParams?.alternateChannelCustomerAccountId,
  ])

  const handleOnUpdate = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/ChannelManagement/manage-alternate-channel',
      {
        DebitCardRegisterCustomerAccountId:
          accountHistoryQueryParams?.debitCardRegisterCustomerAccountId || '',
        DebitCardRegisterId:
          accountHistoryQueryParams?.debitCardRegisterId || '',
        AlternateChannelId: accountHistoryQueryParams?.alternateChannelId || '',
        CustomerAccountId:
          accountHistoryQueryParams?.customerAccountId ||
          accountHistoryQueryParams?.alternateChannelCustomerAccountId ||
          '',
        EffectCharges: effectCharges,
        Expires: channel?.expires,
        ValidFrom: channel?.validFrom,
        RecruiterId: channel?.recruiterId,
        AlternateChannelManagementAction: operation,
        AlternateChannelType: channel?.type,
        CardNumber: channel?.cardNumber || '',
      },
      false,
    )
    if (success) {
      if (data.result) {
        swal.fire(
          'Alternate Channel Management',
          'Operation Completed Successfully',
          'success',
        )
        setCustomerAccount(null)
        setAccountHistoryQueryParams(null)
        setAccountHistories([])
        setChannel({ type: channel?.type })
      }
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('channelManagement'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <label className="col-1 text-capitalize">
                  {localize('operation')}
                </label>
                <EnumsServerCombo
                  sort
                  customContainerStyle={{
                    width: '100%',
                  }}
                  enumsKey="AlternateChannelManagementAction"
                  value={operation}
                  onChange={handleOperationsChange}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <Tab
              preload
              tabItems={[
                {
                  label: localize(
                    Object.values(alternateChannelManagementAction).find(
                      e => e?.value?.toString() === operation,
                    )?.label,
                  ),

                  item:
                    operation ===
                    Object.values(
                      alternateChannelManagementAction,
                    )[3]?.value?.toString() ? (
                      <ChannelDelinking
                        setCustomerAccount={setCustomerAccount}
                        customerAccount={customerAccount}
                        setAccountHistoryQueryParams={
                          setAccountHistoryQueryParams
                        }
                        accountHistoryQueryParams={accountHistoryQueryParams}
                        channel={channel}
                        setChannel={setChannel}
                      />
                    ) : operation ===
                      Object.values(
                        alternateChannelManagementAction,
                      )[0]?.value?.toString() ? (
                      <ChannelLinking
                        accountHistoryQueryParams={accountHistoryQueryParams}
                        setAccountHistoryQueryParams={
                          setAccountHistoryQueryParams
                        }
                        setCustomerAccount={setCustomerAccount}
                        customerAccount={customerAccount}
                        channel={channel}
                        setChannel={setChannel}
                        operation={operation}
                        setEffectCharges={setEffectCharges}
                      />
                    ) : operation ===
                      Object.values(
                        alternateChannelManagementAction,
                      )[2]?.value?.toString() ? (
                      <ChannelRenewal
                        setCustomerAccount={setCustomerAccount}
                        customerAccount={customerAccount}
                        setAccountHistoryQueryParams={
                          setAccountHistoryQueryParams
                        }
                        accountHistoryQueryParams={accountHistoryQueryParams}
                        setChannel={setChannel}
                        channel={channel}
                      />
                    ) : operation ===
                      Object.values(
                        alternateChannelManagementAction,
                      )[1]?.value?.toString() ? (
                      <ChannelReplacement
                        setCustomerAccount={setCustomerAccount}
                        customerAccount={customerAccount}
                        setAccountHistoryQueryParams={
                          setAccountHistoryQueryParams
                        }
                        accountHistoryQueryParams={accountHistoryQueryParams}
                        channel={channel}
                        setChannel={setChannel}
                      />
                    ) : (
                      <ChannelStoppage
                        setCustomerAccount={setCustomerAccount}
                        customerAccount={customerAccount}
                        setAccountHistoryQueryParams={
                          setAccountHistoryQueryParams
                        }
                        accountHistoryQueryParams={accountHistoryQueryParams}
                        channel={channel}
                        setChannel={setChannel}
                      />
                    ),
                },
                {
                  label: localize('history'),
                  item: (
                    <AccountHistory
                      accountHistories={accountHistories}
                      isBusy={loadingAccountHistories}
                    />
                  ),
                },
              ]}
            />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <div className="mt-4 rounded bg-light p-2 d-flex align-items-center justify-content-lg-end gap-3">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={effectCharges}
                  id={'effectChargesChannelDelinking'}
                  onCheck={value => setEffectCharges(value)}
                />

                <label
                  className="text-capitalize"
                  htmlFor="effectChargesChannelDelinking"
                >
                  {localize('effectCharges ')} ?
                </label>
              </div>
              <button onClick={handleOnUpdate} className="btn btn-success">
                {localize('update')}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ChannelManagement
