/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../Tables/SimpleTable'
import { getData } from '../../../../Helpers/webApi'
import { useEffect } from 'react'
import Loader from '../../../Loaders/Loader'

const LoanDeductions = ({ loanCaseId }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
    {
      label: localize('description'),
      template: r => r.description,
    },
    {
      label: localize('amount'),
      template: r => formatCurrency(r.amount),
    },
    {
      label: localize('category'),
      template: r => r.categoryDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r.fullAccountNumber,
    },
    {
      label: localize('applicationUserName'),
      template: r => r.applicationUserName,
    },
    {
      label: localize('environmentUserName'),
      template: r => r.environmentUserName,
    },
    {
      label: localize('environmentMachineName'),
      template: r => r.environmentMachineName,
    },
    {
      label: localize('environmentDomainName'),
      template: r => r.environmentDomainName,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => r.environmentOSVersion,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => r.environmentMACAddress,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => r.environmentMotherboardSerialNumber,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => r.environmentProcessorId,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => r.environmentIPAddress,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
  ]

  const fetchLoanDeductions = async () => {
    setIsBusy(true)
    const url = '/LoanCase/find-loan-case-deductions-by-loan-case-id'

    const { success, data } = await getData(url, loanCaseId, false)

    if (success) {
      setData(data?.result)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchLoanDeductions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanCaseId])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <SimpleTable columns={columns} data={data} />
        </>
      )}
    </>
  )
}

export default LoanDeductions
