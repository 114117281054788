/** @format */
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { formatCurrency } from '../../../../Helpers/formatters'
import Tab from '../../../../Components/Tabs/Tab'
import { RiskClassificationSchedules } from './Partials/RiskClassificationSchedules'

export const ViewRiskClassificationModeModal = ({
  selectedRiskClassificationMode,
  riskClassificationSchedules,
  waitingMetadata,
  mode
}) => {
  return (
    <>
      {waitingMetadata ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedRiskClassificationMode?.description}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('negligibleBalance')}
              </label>
              <input
                className="form-control text-end"
                disabled
                value={formatCurrency(
                  selectedRiskClassificationMode?.negligibleBalance,
                  2,
                  2,
                  false,
                )}
              />
            </div>

          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('riskClassificationSchedules'),
                    item: (
                      <RiskClassificationSchedules
                        mode={mode}
                        riskClassificationSchedules={
                          riskClassificationSchedules
                        }
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
