/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { generateGuid } from '../../../../../../Helpers/extensions'
import {
  batchEntryStatus,
  wireTransferBatchEntryFilter,
} from '../../../../../../Global/enumeration'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { getData } from '../../../../../../Helpers/webApi'
import Pager from '../../../../../../Components/Tables/Pager'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'

function BatchEntries({
  batch,
  readOnly,
  refreshEntries,
  onSelect,
  selectedBatches = [],
  toggleCheckAll,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const componentId = generateGuid()
  const [currentBatch, setCurrentBatch] = useState(batch)
  const [refresh, setRefresh] = useState(false)
  const [batchEntries, setBatchEntries] = useState([])

  const [batchReqParams, setBatchReqParams] = useState({
    status: 1,
    wireTransferBatchEntryFilter: 0,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refresh,
    refreshEntries,
    batchReqParams?.pageSize,
    batchReqParams?.pageIndex,
  ])

  async function loadEntries() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/wiretransferbatch/find-entries-by-wire-transfer-batch-id-in-page',
      { wireTransferBatchId: currentBatch?.id, ...batchReqParams },
      false
    )
    if (success) {
      setBatchEntries(data)
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <>
          <Checkbox
            checked={!!selectedBatches.find(b => b.id === r.id)}
            disabled={readOnly}
            onCheck={() => onSelect(r)}
          />
        </>
      ),
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    { label: localize('payee'), template: r => <>{r?.payee}</> },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.accountNumber}</>,
    },
    { label: localize('reference'), template: r => <>{r?.reference}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r?.productDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <div>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
            <RecordsPerPage reqParams={reqParams} setReqParams={setReqParams} />
            {readOnly || (
              <div className="d-flex align-items-center">
                <Checkbox
                  id={componentId + 'toggleSelection'}
                  onCheck={() =>
                    toggleCheckAll(
                      batchEntries?.pageCollection || [],
                      selectedBatches
                    )
                  }
                  checked={
                    selectedBatches.length ===
                      (batchEntries?.pageCollection || []).length &&
                    (batchEntries?.pageCollection || []).length > 0
                  }
                />
                <label
                  htmlFor={componentId + 'toggleSelection'}
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')}
                </label>
              </div>
            )}
            <div className="d-flex align-items-center">
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchEntryStatus"
                value={batchReqParams?.status}
                onChange={e =>
                  setBatchReqParams({ ...batchReqParams, status: e.value })
                }
              />

              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="WireTransferBatchEntryFilter"
                value={batchReqParams?.wireTransferBatchEntryFilter}
                onChange={e =>
                  setBatchReqParams({
                    ...batchReqParams,
                    wireTransferBatchEntryFilter: e.value,
                  })
                }
              />
              <input
                value={batchReqParams?.filterText}
                onChange={e =>
                  setBatchReqParams({
                    ...batchReqParams,
                    filterText: e.target.value,
                  })
                }
                type="text"
                placeholder="Enter Text..."
                className="form-control me-2"
              />
              <div className="d-flex align-items-center gap-2 mx-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('apportioned')}
                </label>
                <input
                  className="form-control"
                  value={formatCurrency(
                    batchEntries?.totalApportioned,
                    null,
                    null,
                    false
                  )}
                  style={{
                    minWidth: 100,
                    maxWidth: 'fit-content',
                    textAlign: 'right',
                  }}
                  type="text"
                  disabled
                />
                <label htmlFor="" className="text-capitalize ms-2">
                  {localize('shortage')}
                </label>
                <input
                  className="form-control"
                  value={formatCurrency(
                    batchEntries?.totalShortage,
                    null,
                    null,
                    false
                  )}
                  style={{
                    minWidth: 100,
                    maxWidth: 'fit-content',
                    textAlign: 'right',
                  }}
                  type="text"
                  disabled
                />
              </div>
              <button
                onClick={() => setRefresh(!refresh)}
                className="btn btn-primary"
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
          <hr />
          <SimpleTable
            data={batchEntries?.pageCollection || []}
            columns={readOnly ? columns.slice(1, -1) : columns}
          />
          <Pager
            itemsCount={batchEntries?.itemsCount || 1}
            pageSize={batchReqParams?.pageSize}
            onPageChange={p =>
              setBatchReqParams({ ...batchReqParams, pageIndex: p })
            }
            pageIndex={batchReqParams?.pageIndex}
          />
        </>
      )}
    </div>
  )
}

export default BatchEntries
