/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import { getData } from '../../../../Helpers/webApi'
import { formatDate } from '../../../../Helpers/formatters'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import ReactSelect from 'react-select'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Pager from '../../../../Components/Tables/Pager'
import CreateReferral from './CreateReferral'

export default function Referrals() {
  const [isBusy, setIsBusy] = useState(false)
  const [referral, setReferral] = useState(null)
  const [referrals, setReferrals] = useState([])
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [groupBy, setGroupBy] = useState({
    label: localize('employeeName'),
    column: 'employeeCustomerFullName',
  })
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    filterText: search,
    customerFilter: 0,
  })

  useEffect(() => {
    fetchReferrals()
    // eslint-disable-next-line
  }, [refresh, reqParams?.pageSize, reqParams?.pageIndex])

  const columns = [
    {
      label: localize('employeeName'),
      template: r => <>{r?.employeeCustomerFullName}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('membership')} #)`,
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: `${localize('ref')}_1 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: localize('addressLine') + '1',
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + '2',
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.customerAddressCity}</> },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.customerBranchDescription}</>,
    },
    {
      label: localize('duty/workStation'),
      template: r => <>{r?.customerDutyStationDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r?.customerEmployerDescription}</>,
    },
    {
      label: localize('adminDivision'),
      template: r => <>{r?.administrativeDivisionDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const groupingColumns = [
    { label: localize('employeeName'), column: 'employeeCustomerFullName' },
    {
      label: localize('employeeDepartment'),
      column: 'employeeDepartmentDescription',
    },
    {
      label: localize('employeeDesignation'),
      column: 'employeeDesignationDescription',
    },
  ]

  async function fetchReferrals() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/employeeReferral/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setReferrals(data)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('humanResourceManagement'),
          localize('employees'),
          localize('employeeReferrals'),
        ]}
      />
      <div className="card card-body bg-white">
        <div className="mb-2">
          <button
            onClick={() => {
              setReferral(null)
              setModalMode('add')
            }}
            className="btn btn-success px-5"
          >
            {localize('add')}
          </button>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="mb-2 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <label
                  htmlFor=""
                  className="text-capitalize"
                  style={{ minWidth: 'max-content' }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  style={{ maxWidth: 'fit-content' }}
                  className="form-select"
                  value={reqParams?.pageSize}
                  onChange={e =>
                    setReqParams({ pageIndex: 0, pageSize: e.target.value })
                  }
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </div>
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('groupBy')}
                </label>
                <div
                  className="text-capitalize"
                  style={{ minWidth: 'fit-content' }}
                >
                  <ReactSelect
                    value={{ label: groupBy?.label, value: groupBy?.column }}
                    onChange={e =>
                      setGroupBy({ label: e.label, column: e.value })
                    }
                    options={groupingColumns.map(g => ({
                      ...g,
                      value: g?.column,
                    }))}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center gap-2">
                <label htmlFor="" className="text-capitalize">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  value={reqParams?.customerFilter}
                  enumsKey="customerFilter"
                  onChange={e =>
                    setReqParams({ ...reqParams, customerFilter: e.value })
                  }
                />
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      filterText: search,
                    })
                    setRefresh(!refresh)
                  }}
                >
                  <input
                    type="search"
                    value={search}
                    className="form-control"
                    onChange={e => setSearch(e.target.value)}
                  />
                </form>
                <button
                  className="btn btn-primary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
            <GroupedTable
              columns={columns}
              data={referrals?.pageCollection || []}
              groupBy={groupBy}
            />
            <div className="mt-2">
              <Pager
                itemsCount={referrals?.itemsCount || 0}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </div>
          </>
        )}
      </div>
      {modalMode === 'add' && !referral && (
        <CreateReferral
          refresh={() => setRefresh(!refresh)}
          close={() => {
            setModalMode(null)
            setReferral(null)
          }}
        />
      )}
    </>
  )
}
