/** @format */

/* eslint-disable */
import React from 'react'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import localize from '../../../../../Global/Localize'
function FuneralRiderClaimantDetails({
  customer,
  setSelectedFuneralRiderClaim,
  claimantNameValidationMessage,
  claimantPfNumberValidationMessage,
  claimantMobileNumberValidationMessage,
  claimantIdNumberValidationMessage,
  claimantRelationshipValidationMessage,
  clearValidation,
  mode,
}) {
  return (
    <>
      <div className="row mb-2">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={claimantNameValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('claimantNames')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.funeralRiderClaimantName || ''}
            onChange={e => {
              clearValidation('FuneralRiderClaimantName')
              setSelectedFuneralRiderClaim({
                ...customer,
                funeralRiderClaimantName: e.target.value,
              })
            }}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={claimantPfNumberValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('claimantPfNumber')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.funeralRiderClaimantPersonalFileNumber || ''}
            onChange={e => {
              clearValidation('FuneralRiderClaimantPersonalFileNumber')
              setSelectedFuneralRiderClaim({
                ...customer,
                funeralRiderClaimantPersonalFileNumber: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={claimantMobileNumberValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('claimantMobileNumber')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.funeralRiderClaimantMobileNumber || ''}
            onChange={e => {
              clearValidation('FuneralRiderClaimantMobileNumber')
              setSelectedFuneralRiderClaim({
                ...customer,
                funeralRiderClaimantMobileNumber: e.target.value,
              })
            }}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={claimantIdNumberValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('claimantIdNumber')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.funeralRiderClaimantIdentityCardNumber || ''}
            onChange={e => {
              clearValidation('FuneralRiderClaimantIdentityCardNumber')
              setSelectedFuneralRiderClaim({
                ...customer,
                funeralRiderClaimantIdentityCardNumber: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={claimantRelationshipValidationMessage}
        >
          <label className="col-4 text-capitalize">
            {localize('relationship')}
          </label>
          <input
            disabled={mode === 'view'}
            type="text"
            className="form-control"
            value={customer?.funeralRiderClaimantRelationship || ''}
            onChange={e => {
              clearValidation('FuneralRiderClaimantRelationship')
              setSelectedFuneralRiderClaim({
                ...customer,
                funeralRiderClaimantRelationship: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('signatureDate')}
          </label>
          <CustomDatePicker
            disabled={mode === 'view'}
            defaultDate={customer?.funeralRiderClaimantSignatureDate}
            name={'funeralRiderClaimantSignatureDate'}
            onDateChange={date => {
              setSelectedFuneralRiderClaim({
                ...customer,
                funeralRiderClaimantSignatureDate: date,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
export default FuneralRiderClaimantDetails
