/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import CustomerAccountByCustomerIdLookup from '../../../../../Components/Lookup/CustomerAccountByCustomerIdLookup'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import {
  ProductCode,
  customerMembershipStatus,
} from '../../../../../Global/enumeration'
import { formatCurrency } from '../../../../../Helpers/formatters'
import { postData } from '../../../../../Helpers/webApi'
import LoanGuarantorsByCustomerAccount from './LoanGuarantorsByCustomerAccount/LoanGuarantorsByCustomerAccount'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../Components/Loaders/Loader'

const Releasing = () => {
  const [customer, setCustomer] = useState({})
  const [customerAccount, setCustomerAccount] = useState({})
  const [reset, setReset] = useState(false)
  const [isBusy, setIsBusy] = useState(false)

  const onCustomerLookup = c => {
    setCustomer(c)
  }

  const handleSelectCustomerAccount = ca => {
    if (ca?.customerAccountTypeProductCode !== Object.keys(ProductCode)[1])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a loan account!',
      })

    setCustomerAccount(ca)
  }

  const handleUpdate = async () => {
    if (!customerAccount?.customerAccountTypeTargetProductId)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Please select a loan account!',
      })

    if (
      customerAccount?.bookBalance < 0 &&
      customerAccount?.customerMembershipStatus !==
        Object.keys(customerMembershipStatus)[10]
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'The account still has balances!',
      })

    setIsBusy(true)
    const url =
      '/LoanCase/release-loan-guarantors-by-loanee-customer-account?moduleNavigationItemCode=1000'

    const { success } = await postData(url, customerAccount, false)

    if (success) {
      setCustomerAccount({})
      setCustomer({})
      setReset(true)
      showNotification('Ad Hoc Guarantor Releasing', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('guarantorsManagement'),
          localize('adHocGuarantorsReleasing'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <CustomerPartial
                onCustomerLookup={onCustomerLookup}
                customer={customer}
              />

              <div className={`row mb-3`}>
                <div className="col-3 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('fullAccountNumber')}
                  </label>

                  <CustomerAccountByCustomerIdLookup
                    selectedCustomer={customer}
                    onSelect={handleSelectCustomerAccount}
                    displayValue={customerAccount?.fullAccountNumber}
                    productCode={Object.keys(ProductCode)[1]}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountStatus')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={customerAccount?.statusDescription || ''}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountRemarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={customerAccount?.remarks || ''}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('productName')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-2"
                    disabled
                    value={
                      customerAccount?.customerAccountTypeTargetProductDescription ||
                      ''
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4">
                      {localize('bookBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={formatCurrency(
                        customerAccount?.bookBalance,
                        null,
                        null,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4">
                      {localize('principalBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end ms-2"
                      value={formatCurrency(
                        customerAccount?.principalBalance,
                        null,
                        null,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4">
                      {localize('interestBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end ms-2"
                      value={formatCurrency(
                        customerAccount?.interestBalance,
                        null,
                        null,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4">
                      {localize('penaltyBalance')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-end ms-2"
                      value={formatCurrency(
                        customerAccount?.penaltyBalance,
                        null,
                        null,
                        false
                      )}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <Tab
                  tabItems={[
                    {
                      label: localize('guarantors'),
                      item: (
                        <LoanGuarantorsByCustomerAccount
                          customerAccountTypeTargetProductId={
                            customerAccount?.customerAccountTypeTargetProductId
                          }
                          customerId={customerAccount?.customerId}
                          canSelect
                          reset={reset}
                          setReset={setReset}
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <div className="d-flex justify-content-end p-2 rounded bg-light mt-3">
                <button onClick={handleUpdate} className="btn btn-success">
                  {localize('update')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Releasing
