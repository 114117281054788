/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { generateGuid } from '../../../../Helpers/extensions'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import EditReportsTemplate from './EditReportsTemplate'

function ReportTemplates() {
  const columns = [
    {
      label: localize('name'),
      template: r => (
        <div
          className="p-0"
          dangerouslySetInnerHTML={{ __html: r?.indentedDescription }}
        />
      ),
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('spreadsheetCellReference'),
      template: r => <>{r?.spreadsheetCellReference}</>,
    },
    {
      label: localize('valueComputationMode'),
      template: r => <>{r?.spreadsheetCellValueComputationModeDescription}</>,
    },
    {
      label: localize('valueComputationTag'),
      template: r => <>{r?.spreadsheetCellValueComputation}</>,
    },
    {
      label: localize('templateFileTitle'),
      template: r => <>{r?.templateFileTitle}</>,
    },
    {
      label: localize('templateFileMimeType'),
      template: r => <>{r?.templateFileMIMEType}</>,
    },
    { label: localize('absoluteCellValue'), template: r => <></> },
    {
      label: localize('isLocked') + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    updateDepth: true,
    traverseTree: true,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [reports, setReports] = useState(null)
  const [filtered, setFiltered] = useState(null)
  const [searchString, setSearchString] = useState('')
  const [modalMode, setModalMode] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const trTreeChk = generateGuid()

  async function loadReportTemplates() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/reporttemplate/find-reports',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setReports(data?.result || [])
      setFiltered(data?.result)
      setSearchString('')
    }

    setIsBusy(false)
  }

  function handleSearch() {
    const s = searchString.toLowerCase()
    const fl = [...reports].filter(
      r =>
        r.description.toLowerCase().includes(s) ||
        r.spreadsheetCellValueComputationModeDescription
          .toLowerCase()
          .includes(s) ||
        r.templateFileTitle?.toLowerCase().includes(s) ||
        r.createdBy?.toLowerCase().includes(s) ||
        r.templateFileMIMEType?.toLowerCase().includes(s)
    )
    setFiltered(fl)
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalMode('auditLogs')
  }

  const handleCloseModal = () => {
    setModalMode(null)
  }

  useEffect(() => {
    loadReportTemplates()
    //eslint-disable-next-line
  }, [reqParams, refresh])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('GLAccounts'),
          localize('reportTemplates'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex align-items-center mb-3 justify-content-between">
                    <AddNewButton
                      handleClickAddButton={() => {
                        setModalMode('add')
                        setSelectedTemplate(null)
                      }}
                    />
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSearch()
                      }}
                    >
                      <div className="d-flex align-items-center mx-5 gap-2">
                        <Checkbox
                          id={trTreeChk}
                          checked={reqParams.traverseTree}
                          onCheck={v =>
                            setReqParams({ ...reqParams, traverseTree: v })
                          }
                        />
                        <label
                          htmlFor={trTreeChk}
                          className="text-capitalize me-2"
                          style={{ minWidth: 'max-content' }}
                        >
                          {localize('traverseTree')} ?
                        </label>
                      </div>
                      <label className="text-capitalize">
                        {localize('search')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        value={searchString}
                        onChange={e => setSearchString(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          handleSearch()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <SimpleTable
                    columns={columns}
                    data={filtered}
                    contextMenu={r => (
                      <>
                        <div className="ctxt-menu-item">
                          <i className="uil uil-eye"></i>
                          <span className="text-capitalize">View</span>
                        </div>
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            setModalMode('edit')
                            setSelectedTemplate(r)
                          }}
                        >
                          <i className="uil uil-edit-alt"></i>
                          <span className="text-capitalize">Edit</span>
                        </div>
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleViewAuditLogs(r)
                          }}
                        >
                          <i className="uil uil-server"></i>
                          <span>{localize('viewAuditLog')}</span>
                        </div>
                      </>
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(modalMode === 'add' || modalMode === 'edit') && (
        <EditReportsTemplate
          template={selectedTemplate}
          mode={modalMode}
          close={() => {
            handleCloseModal()
            setSelectedTemplate(null)
          }}
          refreshTmps={() => setRefresh(!refresh)}
        />
      )}

      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={modalMode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}

export default ReportTemplates
