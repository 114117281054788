/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const AccountMetadataSignatories = ({ loading, data }) => {
  const columns = [
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    { label: localize('gender'), template: r => <>{r.genderDescription}</> },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('relationship'),
      template: r => <>{r.relationshipDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable data={data} columns={columns} />
      )}
    </>
  )
}
