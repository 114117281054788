/** @format */
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { AddRiskClassificationSchedule } from './Partials/AddRiskClassificationSchedule'

export const AddRiskClassificationModeModal = ({
  selectedRiskClassificationMode,
  setSelectedRiskClassificationMode,
  isBusy,
  riskClassificationSchedules,
  setRiskClassificationSchedules,
  waitingMetadata,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['RiskClassificationMode.Description']?.message
              }
            >
              <label className="col-1 text-capitalize">
                {localize('name')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedRiskClassificationMode?.description}
                onChange={e => {
                  clearValidation('RiskClassificationMode.Description')
                  setSelectedRiskClassificationMode({
                    ...selectedRiskClassificationMode,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('negligibleBalance')}
              </label>
              <MaskNumber
                defaultMaskValue={
                  selectedRiskClassificationMode?.negligibleBalance
                }
                onMaskNumber={e => {
                  setSelectedRiskClassificationMode({
                    ...selectedRiskClassificationMode,
                    negligibleBalance: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <AddRiskClassificationSchedule
                waitingMetadata={waitingMetadata}
                riskClassificationSchedules={riskClassificationSchedules}
                setRiskClassificationSchedules={setRiskClassificationSchedules}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
