/** @format */

import { postData, putData } from '../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { ViewFingerPrintBiometricRegister } from './ViewFingerPrintBiometricRegister'
import { EditFingerPrintBiometricRegister } from './EditFingerPrintBiometricRegister'
import { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { CreateFingerPrintBiometricRegister } from './CreateFingerPrintBiometricRegister'
import { FingerDigit } from '../../../../../Global/enumeration'
import Loader from '../../../../../Components/Loaders/Loader'

function FingerPrintRegistrationModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  acquisitionHistory,
  setReqParams,
  reqParams,
  selectedCustomer,
  fingerPrintSpecimens,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [editFingerPrintModel, setEditFingerPrintModel] = useState(null)
  const [createFingerPrintModel, setCreateFingerPrintModel] = useState(null)
  const [defaultPreferredDigit, setDefaultPreferredDigit] = useState(
    !mode || mode === 'add' || !createFingerPrintModel
      ? Object.keys(FingerDigit)[1]
      : createFingerPrintModel.preferredFingerDigit
  )

  async function handleSubmit() {
    let model =
      mode === 'add'
        ? {
            PreferredFingerDigit: createFingerPrintModel.PreferredFingerDigit,
            CustomerId: createFingerPrintModel.customerId,
            Remarks: createFingerPrintModel.remarks,
            IsLocked: createFingerPrintModel.isLocked,
          }
        : {
            customerId: selectedCustomer.customer.id,
            preferredFingerDigit: defaultPreferredDigit,
            remarks: editFingerPrintModel.remarks,
            isLocked: editFingerPrintModel.isLocked,
          }
    setIsBusy(true)
    const { success } =
      mode === 'add'
        ? await postData(
            '/FingerprintBiometricRegister/create-fingerprint-biometric-register',
            { ...model },
            false,
            'Operation Completed Successfully'
          )
        : await putData(
            '/FingerprintBiometricRegister/update-fingerprint-biometric-register',
            { ...model },
            false,
            'Operation Completed Successfully'
          )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('biometricRegistration')}
            handleAction={handleSubmit}
            hideUpdate={mode === 'view'}
            footerChildren={
              <div className="d-flex align-content-end justify-content-end">
                <div className="d-flex align-items-center me-5">
                  {mode === 'add' ||
                    (mode === 'edit' && (
                      <>
                        <Checkbox
                          id={'isLocked'}
                          checked={editFingerPrintModel?.isLocked}
                          onCheck={v =>
                            setEditFingerPrintModel({
                              ...editFingerPrintModel,
                              isLocked: v,
                            })
                          }
                        />
                        <label
                          htmlFor="isLocked"
                          className="text-capitalize ms-2"
                        >
                          {localize('isLocked')}?
                        </label>
                      </>
                    ))}
                </div>
              </div>
            }
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {isBusy ? (
              <Loader />
            ) : (
              <>
                {mode === 'view' && (
                  <ViewFingerPrintBiometricRegister
                    fingerPrintSpecimens={fingerPrintSpecimens}
                    acquisitionHistory={acquisitionHistory}
                    setReqParams={setReqParams}
                    reqParams={reqParams}
                    selectedCustomer={selectedCustomer}
                  />
                )}
                {mode === 'edit' && (
                  <EditFingerPrintBiometricRegister
                    defaultPreferredDigit={defaultPreferredDigit}
                    setDefaultPreferredDigit={setDefaultPreferredDigit}
                    editFingerPrintModel={editFingerPrintModel}
                    setEditFingerPrintModel={setEditFingerPrintModel}
                    selectedCustomer={selectedCustomer}
                  />
                )}
                {mode === 'add' && (
                  <CreateFingerPrintBiometricRegister
                    defaultPreferredDigit={defaultPreferredDigit}
                    setDefaultPreferredDigit={setDefaultPreferredDigit}
                    createFingerPrintModel={createFingerPrintModel}
                    setCreateFingerPrintModel={setCreateFingerPrintModel}
                  />
                )}
              </>
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}
export default FingerPrintRegistrationModal
