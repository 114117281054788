/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

function LoanCollaterals({ loanCase }) {
  const [loan, setLoan] = useState(loanCase)
  const [fetching, setFetching] = useState(false)
  const [collaterals, setCollaterals] = useState([])

  async function findLoanCollaterals() {
    setFetching(true)
    const { success, data } = await getData(
      '/loanCase/find-loan-collaterals-by-loan-case-id',
      { loanCaseId: loan?.id },
      false
    )
    if (success) {
      setFetching(false)
      setCollaterals(data?.result || [])
    } else {
      setFetching(false)
    }
  }

  useEffect(() => {
    findLoanCollaterals()
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      label: localize('collateralStatus'),
      template: r => <>{r?.customerDocumentCollateralStatusDescription}</>,
    },
    {
      label: localize('documentTitle'),
      template: r => <>{r?.customerDocumentFileTitle}</>,
    },
    {
      label: localize('value'),
      template: r => <>{formatCurrency(r?.value)}</>,
    },

    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return fetching ? (
    <div className="d-flex align-items-center justify-content-center p-5">
      <div className="spinner-border text-dark"></div>
    </div>
  ) : (
    <SimpleTable columns={columns} data={collaterals} />
  )
}

export default LoanCollaterals
