/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
const ViewFuneralRiderClaimPayable = ({
  mode,
  close,
  selectedFuneralRiderClaimPayable,
}) => {
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="funeralRiderClaimPayable"
        handleClose={close}
        modalClassName="modal-xl"
        hideCancel
        hideUpdate
      >
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-2  ">
              <label className="col-3 text-capitalize">
                {localize('funeralRiderClaim')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={selectedFuneralRiderClaimPayable?.customerFullName || ''}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center mb-2">
              <label className="col-2 me-5 text-capitalize">
                {localize('amount')}
              </label>
              <MaskNumber
                className="form-control me-3 ms-3"
                defaultMaskValue={selectedFuneralRiderClaimPayable?.amount || 0}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('memberAccountNumber')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedFuneralRiderClaimPayable?.funeralRiderClaimCustomerReference1 ||
                  ''
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('claimantName')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantName ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('memberMobileNumber')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantMobileNumber ||
                  ''
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('claimantNumber')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantMobileNumber ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('memberIdentityNumber')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedFuneralRiderClaimPayable?.funeralRiderClaimCustomerIndividualIdentityCardNumber ||
                  ''
                }
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('claimantIdentityNumber')}
              </label>
              <input
                type="text"
                className="form-control me-3"
                value={
                  selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantIdentityCardNumber ||
                  ''
                }
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize me-5">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control ms-3 me-3"
                value={selectedFuneralRiderClaimPayable?.remarks || ''}
                disabled
              />
            </div>
          </div>
        </>
      </ModalTemplate>
    </>
  )
}
export default ViewFuneralRiderClaimPayable
