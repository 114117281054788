/** @format */

import React, { useState, useEffect } from 'react'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from '../Loaders/Loader'
import DatePicker from '../Date/DatePicker'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

const EmolumentBatchLookup = ({ onSelect, displayValue, status }) => {
  const columns = [
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('batchNumber'),
      template: r => r.paddedBatchNumber,
    },
    {
      label: localize('postingPeriod'),
      template: r => r.postingPeriodDescription,
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('reference'),
      template: r => r.reference,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('emolumentType'),
      template: r => r.emolumentTypeDescription,
    },
    {
      label: localize('verification/rejectionRemarks'),
      template: r => r.auditRemarks,
    },
    {
      label: localize('verified/rejectedBy'),
      template: r => r.auditedBy,
    },
    {
      label: localize('verified/rejectedDate'),
      template: r => formatDate(r.auditedDate),
    },
    {
      label: localize('authorization/rejectionRemarks'),
      template: r => r.authorizationRemarks,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => r.authorizedBy,
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => formatDate(r.authorizedDate),
    },
    {
      label: localize('priority'),
      template: r => r.priorityDescription,
    },

    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const [reqParams, setReqParams] = useState({
    status: status,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    text: '',
    pageIndex: 0,
    pageSize: 10,
    filterByAuthorizedBranches: false, //TODOalex-09282023-review wcf logic when this is true
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageCount, modalActive])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/EmolumentBatch/find-emolument-batches-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('emolumentBatchLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center float-right my-2 col-3">
                    <label
                      className="text-capitalize"
                      htmlFor="emolumentBatchLookupPageSize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="emolumentBatchLookupPageSize"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageCount}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageCount: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-3 d-flex align-items-center">
                    <label htmlFor="search" className="me-1 text-capitalize">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams?.startDate,
                        endDate: reqParams?.endDate,
                      }}
                      onSelect={v => {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      }}
                    />
                  </div>
                  <div className="col-3 d-flex align-items-center"></div>

                  <form
                    className="d-flex align-items-center col-3 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        text: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 text-capitalize"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.text}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          loadData()
                        }
                      }}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="customLookupFilterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <div className="py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default EmolumentBatchLookup
