/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import BranchLookup from '../../../../../../Components/Lookup/BranchLookup'
import ChartOfAccountsLookup from '../../../../../../Components/Lookup/ChartOfAccountsLookup'
import CostCentreLookup from '../../../../../../Components/Lookup/CostCentreLookup'
import CustomerAccountByCustomerIdLookup from '../../../../../../Components/Lookup/CustomerAccountByCustomerIdLookup'
import CustomersLookup from '../../../../../../Components/Lookup/CustomersLookup'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { generalLedgerEntryType } from '../../../../../../Global/enumeration'
import { generateGuid } from '../../../../../../Helpers/extensions'
import {
  parseDate,
  stripNonNumericCharacters,
} from '../../../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../../../Helpers/webApi'
import GLEntries from './GLEntries'
import LedgerEntries from './LedgerEntries'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'

function AddGLBatch({ closeModal, mode, batch }) {
  const [isBusy, setIsBusy] = useState(false)
  const auth = useAuth()
  const [gl, setGl] = useState(batch)
  const [entries, setEntries] = useState([])
  const [entry, setEntry] = useState({
    branchId: auth?.user?.profile?.cbs_EmployeeBranchDescription,
    branchDescription: auth?.user?.profile?.cbs_EmployeeBranchDescription,
  })
  const [types, setTypes] = useState({ debit: 1, credit: 1 })
  const [refreshEntries, setRefreshEntries] = useState(false)

  useEffect(() => {
    getCurrentPostingPeriod()
    setDefaultBranch()
    //eslint-disable-next-line
  }, [])

  async function setDefaultBranch() {
    if (!gl?.branchId) {
      // alert('setting default branch to: ' + auth.user.profile.cbs_branch)
      setGl({
        ...gl,
        branchId: auth?.user?.profile?.cbs_EmployeeBranchDescription,
        branchDescription: auth?.user?.profile?.cbs_EmployeeBranchDescription,
      })
    }
  }

  const getCurrentPostingPeriod = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      false
    )
    if (success) {
      const cPeriodId = gl?.postingPeriodId
      if (!cPeriodId) {
        setGl({
          ...gl,
          postingPeriodId: data?.id,
          postingPeriodDescription: data?.description,
        })
      }
    }
    setIsBusy(false)
  }

  // eslint-disable-next-line
  async function createGL() {
    // eslint-disable-next-line
    setIsBusy(true)
    const { success, data } = await postData(
      '/generalLedger/create-general-ledger',
      gl,
      false
    )

    let createdEntries = []
    if (success) {
      for (let i = 0; i < entries.length; i++) {
        const { success, data } = await createEntry(entries[i])
        if (success) createdEntries.push(data)
      }
      Swal.fire(
        'Success',
        `General Ledger created with ${createdEntries.length}`,
        'success'
      )
    }
    setIsBusy(false)
  }

  async function createGLWithEntries() {
    setIsBusy(true)
    const { success } = await putData(
      '/generalLedger/add-general-with-entries',
      { generalLedger: gl, generalLedgerEntryDTOs: entries },
      false,
      'General Ledger Created Successfuly'
    )
    if (success) {
      closeModal()
      // triggerRefresh()
    }
    setIsBusy(false)
  }

  async function createEntry(entry) {
    const { success } = await postData(
      '/generalLedger/create-general-ledger-entry',
      entry,
      false
    )
    return success
  }

  async function postEntry() {
    setIsBusy(true)
    const { success } = await postData(
      '/generalLedger/create-general-ledger-entry',
      { ...entry, generalLedgerId: gl?.id },
      false,
      'Entry Created!'
    )
    if (success) {
      setRefreshEntries(!refreshEntries)
    }
    setIsBusy(false)
  }

  async function stageEntry() {
    let ent = [...entries]
    ent.push({ ...entry, id: generateGuid() })
    setEntries(ent)
    setEntry()
  }

  return (
    <Modal isOpen className="modal-xl">
      <ModalHeader className="bg-white">
        <div className="text-capitalize d-flex align-items-center mb-2">
          <h4 style={{ marginTop: 5 }}>
            {localize(mode === 'edit' ? 'edit' : 'add')}
          </h4>
          <i className="uil uil-angle-right mx-2"></i>
          <span className="text-muted fs-6 text-uppercase">
            {localize('generalLedger')}
          </span>
          <button type="button" className="minimize">
            <span aria-hidden="true">_</span>
          </button>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </ModalHeader>
      <ModalBody className="bg-white">
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-3 text-capitalize">
                  {localize('branch')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    gl?.id
                      ? gl?.branchDescription
                      : auth?.user?.profile?.cbs_EmployeeBranchDescription
                  }
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-3 text-capitalize">
                  {localize('postingPeriod')}
                </label>
                <PostingPeriodLookup
                  displayValue={gl?.postingPeriodDescription}
                  onSelect={p =>
                    setGl({
                      ...gl,
                      postingPeriodId: p.id,
                      postingPeriodDescription: p.description,
                    })
                  }
                />
              </div>
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="col-3 text-capitalize">
                  {localize('totalValue')}
                </label>
                <MaskNumber
                  defaultMaskValue={gl?.totalValue}
                  onMaskNumber={e =>
                    setGl({
                      ...gl,
                      totalValue: stripNonNumericCharacters(e.target.value),
                    })
                  }
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="col d-flex align-items-center">
                <label htmlFor="" className="text-capitalize col-1">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={gl?.remarks}
                  onChange={e => setGl({ ...gl, remarks: e.target.value })}
                />
              </div>
            </div>

            <div
              className="p-3 mt-3"
              style={{
                border: 'solid 1px #0005',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white text-capitalize"
                style={{ position: 'absolute', top: -18, padding: 5 }}
              >
                {localize('ledgerDetails')}
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <div
                    className="p-3"
                    style={{
                      border: 'solid 1px #0005',
                      borderRadius: 8,
                      position: 'relative',
                    }}
                  >
                    <div
                      className="bg-white text-capitalize"
                      style={{ position: 'absolute', top: -18, padding: 5 }}
                    >
                      {localize('debit')}
                    </div>

                    <div className="row">
                      <div className="col d-flex align-items-center">
                        <label htmlFor="" className="text-capitalize col-4">
                          {localize('type')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="GeneralLedgerEntryType"
                          value={types.debit}
                          onChange={e =>
                            setTypes({
                              ...types,
                              debit: e.value,
                            })
                          }
                        />
                      </div>
                      <div className="col d-flex align-items-center">
                        <label htmlFor="" className="col-4 text-capitalize">
                          {localize(generalLedgerEntryType[types.debit].name)}
                        </label>
                        {
                          //eslint-disable-next-line
                          types.debit == 1 ? (
                            <ChartOfAccountsLookup
                              displayValue={entry?.contraChartOfAccountName}
                              onSelect={c =>
                                setEntry({
                                  ...entry,
                                  contraChartOfAccountId: c.id,
                                  contraChartOfAccountName: c.name,
                                })
                              }
                            />
                          ) : (
                            <CustomersLookup
                              displayValue={
                                entry?.contraCustomerAccountCustomerFullName
                              }
                              onSelect={c =>
                                setEntry({
                                  ...entry,
                                  contraCustomerAccountCustomerFullName:
                                    c.fullName,
                                  contraCustomerAccountCustomerId: c.id,
                                })
                              }
                            />
                          )
                        }
                      </div>
                    </div>
                    {
                      //eslint-disable-next-line
                      types.debit == 2 && (
                        <div className="row mt-2">
                          <div className="col d-flex align-items-center">
                            <label htmlFor="" className="text-capitalize col-4">
                              {localize('fullAccountNumber')}
                            </label>
                            <CustomerAccountByCustomerIdLookup
                              selectedCustomer={{
                                id:
                                  entry?.contraCustomerAccountCustomerId || '',
                              }}
                              disabled={!entry?.contraCustomerAccountCustomerId}
                              displayValue={
                                entry?.contraCustomerAccountFullAccountNumber
                              }
                              onSelect={s =>
                                setEntry({
                                  ...entry,
                                  contraCustomerAccountId: s.id,
                                  contraChartOfAccountId:
                                    s.customerAccountTypeTargetProductChartOfAccountId,
                                  contraCustomerAccountFullAccountNumber:
                                    s.fullAccountNumber,
                                  contraProductDescription:
                                    s.customerAccountTypeTargetProductDescription,
                                })
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label htmlFor="" className="text-capitalize col-4">
                              {localize('productName')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={entry?.contraProductDescription}
                            />
                          </div>
                        </div>
                      )
                    }
                    <div className="row mt-2">
                      <div className="col d-flex align-items-center">
                        <label htmlFor="" className="text-capitalize col-2">
                          {localize('costCenter')}
                        </label>
                        <CostCentreLookup
                          displayValue={entry?.contraCostCenterDescription}
                          onSelect={c =>
                            setEntry({
                              ...entry,
                              contraCostCenterId: c.id,
                              contraCostCenterDescription: c.description,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="p-3"
                    style={{
                      border: 'solid 1px #0005',
                      borderRadius: 8,
                      position: 'relative',
                    }}
                  >
                    <div
                      className="bg-white text-capitalize"
                      style={{ position: 'absolute', top: -18, padding: 5 }}
                    >
                      {localize('credit')}
                    </div>

                    <div className="row">
                      <div className="col d-flex align-items-center">
                        <label htmlFor="" className="text-capitalize col-4">
                          {localize('type')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="GeneralLedgerEntryType"
                          value={types.credit}
                          onChange={e =>
                            setTypes({
                              ...types,
                              credit: e.value,
                            })
                          }
                        />
                      </div>
                      <div className="col d-flex align-items-center">
                        <label htmlFor="" className="col-4 text-capitalize">
                          {localize(generalLedgerEntryType[types.credit].name)}
                        </label>
                        {types.credit == 1 ? (
                          <ChartOfAccountsLookup
                            displayValue={entry?.chartOfAccountName}
                            onSelect={c =>
                              setEntry({
                                ...entry,
                                chartOfAccountId: c.id,
                                chartOfAccountName: c.name,
                              })
                            }
                          />
                        ) : (
                          <CustomersLookup
                            displayValue={
                              entry?.customerAccountCustomerFullName
                            }
                            onSelect={c =>
                              setEntry({
                                ...entry,
                                customerAccountCustomerId: c.id,
                                customerAccountCustomerFullName: c.fullName,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                    {
                      //eslint-disable-next-line
                      types.credit == 2 && (
                        <div className="row mt-2">
                          <div className="col d-flex align-items-center">
                            <label htmlFor="" className="text-capitalize col-4">
                              {localize('fullAccountNumber')}
                            </label>
                            <CustomerAccountByCustomerIdLookup
                              selectedCustomer={{
                                id: entry?.customerAccountCustomerId || '',
                              }}
                              disabled={!entry?.customerAccountCustomerId}
                              displayValue={
                                entry?.customerAccountFullAccountNumber
                              }
                              onSelect={s =>
                                setEntry({
                                  ...entry,
                                  customerAccountFullAccountNumber:
                                    s.fullAccountNumber,
                                  chartOfAccountId:
                                    s.customerAccountTypeTargetProductChartOfAccountId,
                                  customerAccountId: s.id,
                                  customerAccountProductDescription:
                                    s.customerAccountTypeTargetProductDescription,
                                })
                              }
                            />
                          </div>
                          <div className="col d-flex align-items-center">
                            <label htmlFor="" className="text-capitalize col-4">
                              {localize('productName')}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={entry?.customerAccountProductDescription}
                            />
                          </div>
                        </div>
                      )
                    }
                    <div className="row mt-2">
                      <div className="col d-flex align-items-center">
                        <label htmlFor="" className="text-capitalize col-2">
                          {localize('costCenter')}
                        </label>
                        <CostCentreLookup
                          displayValue={entry?.costCenterDescription}
                          onSelect={c =>
                            setEntry({
                              ...entry,
                              costCenterId: c.id,
                              costCenterDescription: c.description,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="col-4 text-capitalize">
                    {localize('branch')}
                  </label>
                  <BranchLookup
                    displayValue={entry?.branchDescription}
                    onSelect={b =>
                      setEntry({
                        ...entry,
                        branchDescription: b.description,
                        branchId: b.id,
                      })
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('amount')}
                  </label>
                  <MaskNumber
                    onMaskNumber={e =>
                      setEntry({
                        ...entry,
                        amount: stripNonNumericCharacters(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('valueDate')}
                  </label>
                  <input
                    type="date"
                    value={parseDate(entry?.valueDate)}
                    onChange={e =>
                      setEntry({ ...entry, valueDate: e.target.value })
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('primaryDescription')}
                  </label>
                  <input
                    type="text"
                    value={entry?.primaryDescription}
                    onChange={e =>
                      setEntry({
                        ...entry,
                        primaryDescription: e.target.value,
                      })
                    }
                    className="form-control"
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('secondaryDescription')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={entry?.secondaryDescription}
                    onChange={e =>
                      setEntry({
                        ...entry,
                        secondaryDescription: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-4">
                    {localize('reference')}
                  </label>
                  <input
                    type="text"
                    value={entry?.reference}
                    onChange={e =>
                      setEntry({
                        ...entry,
                        reference: e.target.value,
                      })
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end bg-light p-2 my-3">
                <button
                  className="btn btn-success px-5"
                  onClick={
                    //eslint-disable-next-line
                    mode == 'edit' ? postEntry : stageEntry
                  }
                >
                  {localize('add')}
                </button>
              </div>
              {
                //eslint-disable-next-line
                mode != 'edit' && (
                  <Tab
                    tabItems={[
                      {
                        label: localize('ledgerEntries'),
                        item: (
                          <GLEntries
                            entries={entries}
                            editable
                            onRemove={r =>
                              setEntries(entries.filter(e => e.id !== r.id))
                            }
                          />
                        ),
                      },
                    ]}
                  />
                )
              }

              {
                //eslint-disable-next-line
                mode == 'edit' && (
                  <Tab
                    tabItems={[
                      {
                        label: localize('ledgerEntries'),
                        item: (
                          <LedgerEntries
                            refreshEntries={refreshEntries}
                            batch={gl}
                            disableContext
                            canSelect
                            showRemove
                          />
                        ),
                      },
                      { label: localize('mismatchedImportEntries') },
                    ]}
                  />
                )
              }
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="bg-white">
        <button className="btn btn-success px-5" onClick={createGLWithEntries}>
          {localize(mode === 'edit' ? 'update' : 'create')}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default AddGLBatch
