/** @format */

import localize from '../../../../Global/Localize'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
/* eslint-disable */
import React, { useState } from 'react'
import {
  QueuePriority,
  SystemTransactionCode,
} from '../../../../Global/enumeration'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import { stripNonNumericCharacters } from '../../../../Helpers/formatters'
import { AccountAlertsPreference } from './AccountAlertsPreference'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'

export const AccountAlertDetails = ({
  selectedCustomer,
  currentAccountAlerts,
  setNewAccountAlerts,
  onAddNewAlert,
}) => {
  const [defaultSystemTransactionCode, setDefaultSystemTransactionCode] =
    useState(Object.keys(SystemTransactionCode)[1])
  const [defaultQueuePriority, setDefaultQueuePriority] = useState(
    Object.keys(QueuePriority)[3]
  )
  const [accountAlertDetailsModel, setAccountAlertDetailsModel] = useState({
    type: defaultSystemTransactionCode,
    priority: defaultQueuePriority,
    threshold: 0,
    customerId: selectedCustomer.id,
    typeDescription: SystemTransactionCode[defaultSystemTransactionCode].name,
    priorityDescription: QueuePriority[defaultQueuePriority].name,
    maskTransactionValue: false,
    receiveTextAlert: false,
    receiveEmailAlert: false,
    maskAccountBalance: false,
  })

  const handleOnAddAccountAlertPreference = () => {
    let finalAlerts
    if (
      !currentAccountAlerts.find(
        alert => Number(alert.type) === Number(accountAlertDetailsModel.type)
      )
    ) {
      setNewAccountAlerts(prev => {
        finalAlerts = [
          ...currentAccountAlerts,
          {
            type: accountAlertDetailsModel.type,
            priority: accountAlertDetailsModel.priority,
            threshold: accountAlertDetailsModel.threshold || 0,
            customerId: selectedCustomer.id,
            typeDescription:
              SystemTransactionCode[defaultSystemTransactionCode].name,
            priorityDescription: QueuePriority[defaultQueuePriority].name,
            maskTransactionValue:
              accountAlertDetailsModel.maskTransactionValue || false,
            receiveTextAlert:
              accountAlertDetailsModel.receiveTextAlert || false,
            receiveEmailAlert:
              accountAlertDetailsModel.receiveEmailAlert || false,
            maskAccountBalance:
              accountAlertDetailsModel.maskAccountBalance || false,
          },
        ]
        onAddNewAlert(finalAlerts)
        return finalAlerts
      })
      setAccountAlertDetailsModel({
        type: defaultSystemTransactionCode,
        priority: defaultQueuePriority,
        threshold: 0,
        customerId: selectedCustomer.id,
        typeDescription:
          SystemTransactionCode[defaultSystemTransactionCode].name,
        priorityDescription: QueuePriority[defaultQueuePriority].name,
        maskTransactionValue: false,
        receiveTextAlert: false,
        receiveEmailAlert: false,
        maskAccountBalance: false,
      })
    } else {
      Swal.fire({
        icon: 'error',
        text: `Sorry, but account alert type for ${SystemTransactionCode[defaultSystemTransactionCode].name} already exists`,
        title: 'Edit Account Alerts',
        showCloseButton: true,
      })
    }
  }

  const handleOnRemoveAccountAlertPreference = r => {
    let finalAlerts = [...currentAccountAlerts].filter(
      alert => alert.type !== r.type
    )
    onAddNewAlert(finalAlerts)
    setNewAccountAlerts(finalAlerts)
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('accountAlertDetails')}
        </div>
        <div className="row" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label className="text-capitalize me-3">{localize('type')}</label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="SystemTransactionCode"
              value={defaultSystemTransactionCode}
              onChange={e => {
                setDefaultSystemTransactionCode(e.value)
                setAccountAlertDetailsModel({
                  ...accountAlertDetailsModel,
                  type: e.value,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="text-capitalize me-3">
              {localize('priority')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="QueuePriority"
              onChange={e => {
                setDefaultQueuePriority(e.value)
                setAccountAlertDetailsModel({
                  ...accountAlertDetailsModel,
                  priority: e.value,
                })
              }}
              value={defaultQueuePriority}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="text-capitalize me-3">
              {localize('threshold')}
            </label>
            <MaskNumber
              defaultMaskValue={accountAlertDetailsModel?.threshold || 0.0}
              onMaskNumber={e => {
                setAccountAlertDetailsModel({
                  ...accountAlertDetailsModel,
                  threshold: stripNonNumericCharacters(e.target.value),
                })
              }}
            />
          </div>
        </div>
        <div
          className="bg-light d-flex justify-content-end align-items-center p-2 mt-3"
          style={{ borderRadius: 5 }}
        >
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'mask-transaction-value'}
              checked={accountAlertDetailsModel?.maskTransactionValue || ''}
              onCheck={v =>
                setAccountAlertDetailsModel({
                  ...accountAlertDetailsModel,
                  maskTransactionValue: v,
                })
              }
            />
            <label
              htmlFor="mask-transaction-value"
              className="text-capitalize ms-2"
            >
              {localize('maskTransactionValue')}?
            </label>
          </div>
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'receive-text-alert'}
              checked={accountAlertDetailsModel?.receiveTextAlert || ''}
              onCheck={v =>
                setAccountAlertDetailsModel({
                  ...accountAlertDetailsModel,
                  receiveTextAlert: v,
                })
              }
            />
            <label
              htmlFor="receive-text-alert"
              className="text-capitalize ms-2"
            >
              {localize('receiveTextAlert')}?
            </label>
          </div>
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'receive-email-alert'}
              checked={accountAlertDetailsModel?.receiveEmailAlert || ''}
              onCheck={v =>
                setAccountAlertDetailsModel({
                  ...accountAlertDetailsModel,
                  receiveEmailAlert: v,
                })
              }
            />
            <label
              htmlFor="receive-email-alert"
              className="text-capitalize ms-2"
            >
              {localize('receiveEmailAlert')}?
            </label>
          </div>
          <div className="d-flex align-items-center me-5">
            <Checkbox
              id={'mask-account-balance'}
              checked={accountAlertDetailsModel?.maskAccountBalance || ''}
              onCheck={v =>
                setAccountAlertDetailsModel({
                  ...accountAlertDetailsModel,
                  maskAccountBalance: v,
                })
              }
            />
            <label
              htmlFor="mask-account-balance"
              className="text-capitalize ms-2"
            >
              {localize('maskAccountBalance')}?
            </label>
          </div>
          <button
            onClick={handleOnAddAccountAlertPreference}
            className="btn btn-success text-uppercase ms-3 px-4"
          >
            {localize('add')}
          </button>
        </div>

        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 120,
              marginBottom: -10,
            }}
          >
            {localize('accountAlertPreferences')}
          </div>
          <AccountAlertsPreference
            addMode
            onRemove={handleOnRemoveAccountAlertPreference}
            accountAlertsPreference={currentAccountAlerts}
          />
        </div>
      </div>
    </>
  )
}
