/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'
import localize from '../../Global/Localize'
function BankLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('bankLookup')}
      url="/lookup/Bank/find-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('code'),
          template: r => <>{r.paddedCode}</>,
          sortBy: 'paddedCode',
        },
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate, false)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default BankLookup
