/** @format */

import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import localize from '../../../../Global/Localize'
import Loader from '../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Pager from '../../../../Components/Tables/Pager'
import { formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import BankersChequeBooksModal from './Partial/BankersChequeBooksModal'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'

function BankersChequeBooks() {
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [waitingMetadata, setWaitingMetadata] = useState()

  const [sortedBy, setSortedBy] = useState(null)
  const [selectedBankersChequeBook, setSelectedBankersChequeBook] =
    useState(null)

  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)

  useEffect(() => {
    loadTableData()
    //es-lint-disable-next-line
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/BankersChequeBook/find-bankers-cheque-books-by-filter-in-page',
      reqParams,
      false,
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('bankLinkageBranchDescription'),
      template: r => <>{r?.bankLinkageBranchDescription}</>,
    },
    {
      label: localize('paddedSerialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('numberOfLeaves'),
      template: r => <>{r.numberOfLeaves}</>,
    },
    {
      label: localize('initialLeafNumber'),
      template: r => <>{r.initialLeafNumber}</>,
    },
    {
      label: localize('bankLinkageChartOfAccountName'),
      template: r => <>{r.bankLinkageChartOfAccountName}</>,
    },
    {
      label: localize('bankLinkageBankName'),
      template: r => <>{r.bankLinkageBankName}</>,
    },
    {
      label: localize('bankLinkageBankBranchName'),
      template: r => <>{r.bankLinkageBankBranchName}</>,
    },
    {
      label: localize('bankLinkageBankAccountNumber'),
      template: r => <>{r.bankLinkageBankAccountNumber}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const handleOnEdit = async r => {
    setMode('edit')
    const metadata = await fetchBankersChequeBookMetadata(r?.id)
    setSelectedBankersChequeBook({
      ...r,
      commissions: metadata?.commissions || [],
    })
  }

  const handleOnAdd = async r => {
    setSelectedBankersChequeBook(null)
    setMode('add')
    const metadata = await fetchBankersChequeBookMetadata(
      '00000000-0000-0000-0000-000000000000',
    )
    setSelectedBankersChequeBook({
      commissions: metadata?.commissions || [],
    })
  }

  const handleOnViewChequeTypeMetadata = async r => {
    setMode('view')
    setSelectedBankersChequeBook(null)
    const metadata = await fetchBankersChequeBookMetadata(r?.id)
    setSelectedBankersChequeBook({
      ...r,
      commissions: metadata?.commissions || [],
      chequeLeaves: metadata?.chequeLeaves || [],
    })
  }

  const fetchBankersChequeBookMetadata = async id => {
    setWaitingMetadata(true)
    const { success, data } = await getData(
      '/BankersChequeBook/find-bankers-cheque-book-metadata',
      { bankersChequeBookId: id },
      false,
    )
    if (success) {
      setWaitingMetadata(false)
      return data
    } else {
      setWaitingMetadata(false)
      return null
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('bankersChequeBook'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-4 gap-2">
                      <div className="mb-3">
                        <AddNewButton handleClickAddButton={handleOnAdd} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="col-3 offset-6">
                      <form
                        className="col d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            text: search,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <label
                          className={'text-capitalize'}
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.text}
                          onChange={e => setSearch(e.target.value)}
                        ></input>
                        <button type="submit" className="btn btn-primary ms-1">
                          <i className="uil uil-filter"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={e => (
                        <>
                          <div
                            onClick={() => {
                              handleOnViewChequeTypeMetadata(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={() => {
                              handleOnEdit(e)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit"></i>
                            {localize('edit')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <BankersChequeBooksModal
        waitingMetadata={waitingMetadata}
        mode={mode}
        onHideModal={() => {
          setMode(null)
        }}
        setSelectedBankersChequeBook={setSelectedBankersChequeBook}
        selectedBankersChequeBook={selectedBankersChequeBook}
        onSetMode={m => setMode(m)}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
      />
    </>
  )
}

export default BankersChequeBooks
