/** @format */

import RelationshipManagers from '../../Pages/CustomerRelationshipManagement/Setup/RelationshipManagers/RelationshipManagers'
import SurveyGroups from '../../Pages/CustomerRelationshipManagement/Setup/SurveyGroups/SurveyGroups'
import MessageGroups from '../../Pages/CustomerRelationshipManagement/Setup/MessageGroups/MessageGroups'
import Tickets from '../../Pages/CustomerRelationshipManagement/Operations/HelpDesk/Tickets'
import TextAlerts from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/TextAlerts/TextAlerts'
import EmailAlerts from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/EmailAlerts/EmailAlerts'
import Memorandum from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/Memorandum/Memorandum'
import BulkMessageBatches from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/BulkMessageBatches/BulkMessageBatches'
import InstantMessaging from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/InstantMessaging/InstantMessaging'
import NotificationJobs from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/NotificationJobs/NotificationJobs'
import MobileOriginatedMessages from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/MobileOriginatedMessages/MobileOriginatedMessages'
import Surveys from '../../Pages/CustomerRelationshipManagement/Operations/Messaging/Surveys/Surveys'
import AccountStatements from '../../Pages/CustomerRelationshipManagement/Operations/Customers/AccountStatements/AccountStatements'
import AccountStatementsArchived from '../../Pages/CustomerRelationshipManagement/Operations/Customers/AccountStatementsArchived/AccountStatementsArchived'
import AccountStatistics from '../../Pages/CustomerRelationshipManagement/Operations/Customers/AccountStatistics/AccountStatistics'
import PayrollNumbers from '../../Pages/CustomerRelationshipManagement/Operations/Customers/PayrollNumbers/PayrollNumbers'
import MergedStatements from '../../Pages/CustomerRelationshipManagement/Operations/Customers/MergedStatements/MergedStatements'
import UserDefinedReports from '../../Pages/CustomerRelationshipManagement/Operations/Utilities/UserDefinedReports/UserDefinedReports'
import VisualStatistics from '../../Pages/CustomerRelationshipManagement/Operations/Utilities/VisualStatistics/VisualStatistics'
import RecurringProcedures from '../../Pages/CustomerRelationshipManagement/Operations/Utilities/RecurringProcedures/RecurringProcedures'
import EStatements from '../../Pages/CustomerRelationshipManagement/Operations/Customers/Estatements/Estatements'

const MessagingMenus = [
  {
    iconClass: 'uil uil-calendar-alt',
    label: 'customerRelationshipManagement',
    code: 7,
    setup: [
      {
        label: 'messageGroups',
        parentCode: 7,
        component: <MessageGroups menuCode={9001} />,
        code: 9001,
      },
      {
        label: 'surveyGroups',
        parentCode: 7,
        component: <SurveyGroups menuCode={9002} />,
        code: 9002,
      },
      {
        label: 'relationshipManagers',
        parentCode: 7,
        component: <RelationshipManagers menuCode={9003} />,
        code: 9003,
      },
    ],
    operations: [
      {
        label: 'helpDesk',
        parentCode: 9500,
        children: [
          {
            component: <Tickets menuCode={9501} />,
            parentCode: 9500,
            code: 9501,
            description: 'tickets',
            label: 'tickets',
          },
        ],
      },
      {
        label: 'messaging',
        parentCode: 9502,
        children: [
          {
            component: <TextAlerts menuCode={9503} />,
            parentCode: 9502,
            code: 9503,
            description: 'textAlerts',
            label: 'textAlerts',
          },
          {
            component: <EmailAlerts menuCode={9504} />,
            parentCode: 9502,
            code: 9504,
            description: 'emailAlerts',
            label: 'emailAlerts',
          },
          {
            component: <Memorandum menuCode={9510} />,
            parentCode: 9502,
            code: 9510,
            description: 'memorandum',
            label: 'memorandum',
          },
          {
            component: <BulkMessageBatches menuCode={9505} />,
            parentCode: 9502,
            code: 9505,
            description: 'bulkMessageBatches',
            label: 'bulkMessageBatches',
          },
          {
            component: <InstantMessaging menuCode={9506} />,
            parentCode: 9502,
            code: 9506,
            description: 'instantMessaging',
            label: 'instantMessaging',
          },
          {
            component: <NotificationJobs menuCode={9507} />,
            parentCode: 9502,
            code: 9507,
            description: 'notificationJobs',
            label: 'notificationJobs',
          },
          {
            component: <MobileOriginatedMessages menuCode={9508} />,
            parentCode: 9502,
            code: 9508,
            description: 'mobileOriginatedMessages',
            label: 'mobileOriginatedMessages',
          },
          {
            component: <Surveys menuCode={9509} />,
            parentCode: 9502,
            code: 9509,
            description: 'surveys',
            label: 'surveys',
          },
        ],
      },
      {
        label: 'customers',
        parentCode: 9511,
        children: [
          {
            component: <AccountStatements menuCode={9512} />,
            parentCode: 9511,
            code: 9512,
            description: 'accountStatements',
            label: 'accountStatements',
          },
          {
            component: <AccountStatementsArchived menuCode={9513} />,
            parentCode: 9511,
            code: 9513,
            description: 'accountStatementsArchived',
            label: 'accountStatementsArchived',
          },
          {
            component: <AccountStatistics menuCode={9514} />,
            parentCode: 9511,
            code: 9514,
            description: 'accountStatistics',
            label: 'accountStatistics',
          },
          {
            component: <PayrollNumbers menuCode={9515} />,
            parentCode: 9511,
            code: 9515,
            description: 'payrollNumbers',
            label: 'payrollNumbers',
          },
          {
            component: <EStatements menuCode={9516} />,
            parentCode: 9511,
            code: 9516,
            description: 'e-statement',
            label: 'e-statement',
          },
          {
            component: <MergedStatements menuCode={9517} />,
            parentCode: 9511,
            code: 9517,
            description: 'mergedStatements',
            label: 'mergedStatements',
          },
        ],
      },
      {
        label: 'utilities',
        parentCode: 9518,
        children: [
          {
            component: <UserDefinedReports menuCode={9519} />,
            parentCode: 9518,
            code: 9519,
            description: 'userDefinedReports',
            label: 'userDefinedReports',
          },
          {
            component: <RecurringProcedures menuCode={9520} />,
            parentCode: 9518,
            code: 9520,
            label: 'recurringProcedures',
            description: 'recurringProcedures',
          },
          {
            component: <VisualStatistics menuCode={9521} />,
            parentCode: 9518,
            code: 9521,
            label: 'visualStatistics',
            description: 'visualStatistics',
          },
        ],
      },
    ],
  },
]

export default MessagingMenus
