/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'

const SalaryPaySlipEntries = ({ paySlip, entryClicked, mode, close }) => {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r?.salaryHeadTypeDescription}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r?.salaryHeadCategoryDescription}</>,
    },
    {
      label: localize('isOneOff') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.salaryHeadIsOneOff?.toString())}
        </span>
      ),
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r?.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r?.interest)}</>,
    },
    {
      label: localize('roundingType'),
      template: r => <>{r?.roundingTypeDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
  ]

  const [paySlipEntriesLoading, setPaySlipEntriesLoading] = useState(false)
  const [paySlipEntries, setPaySlipEntries] = useState([])

  const loadSalaryPaySlipEntries = async () => {
    if (entryClicked) {
      setPaySlipEntriesLoading(true)
    }
    const { success, data } = await getData(
      `/PaySlip/find-payslip-entry-by-payslip-id?paySlipId=${paySlip.id}`,
      {},
      false
    )
    if (success) {
      setPaySlipEntriesLoading(false)
      setPaySlipEntries(data.result)
    } else {
      setPaySlipEntriesLoading(false)
      setPaySlipEntries([])
    }
  }

  useEffect(() => {
    setPaySlipEntriesLoading(false)
    loadSalaryPaySlipEntries()
    // eslint-disable-next-line
  }, [paySlip?.id])
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={
          `paySlipFor` + ' ' + `${paySlip.salaryCardEmployeeCustomerFullName}`
        }
        handleClose={close}
        modalClassName="modal-xl"
        hideUpdate
      >
        {paySlipEntriesLoading ? (
          <Loader />
        ) : (
          <>
            <SimpleTable columns={columns} data={paySlipEntries} selectables />
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default SalaryPaySlipEntries
