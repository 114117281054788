/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import localize from '../../../../Global/Localize'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import { CatalogueTracker } from './Partials/CatalogueTracker'
import Pager from '../../../../Components/Tables/Pager'
import { CustomerFilter } from '../../../../Global/enumeration'
import Tab from '../../../../Components/Tabs/Tab'
import { CatalogueFileMovementHistory } from './Partials/CatalogueFileMovementHistory'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'

function FileTrackingCatalogue() {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    pageIndex: 0,
    pageSize: 10,
  })

  const [clickedGetFileMovementHistory, setClickedGetFileMovementHistory] =
    useState(false)

  const [fileTrackHistory, setFileTrackHistory] = useState([])
  const [fileMovementHistory, setFileMovementHistory] = useState([])
  const [loadingFileMovementHistory, setLoadingFileMovementHistory] =
    useState(false)
  useEffect(() => {
    getFileTrackHistory()
  }, [reqParams])

  const getFileTrackHistory = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/FileRegister/find-file-file-registers-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setFileTrackHistory(data)
    }
    setIsBusy(false)
  }

  const getFileMovementHistory = async r => {
    setIsBusy(true)
    setClickedGetFileMovementHistory(true)
    setLoadingFileMovementHistory(true)
    const { success, data } = await getData(
      '/FileRegister/find-file-movement-history-by-file-register-id',
      { fileRegisterId: r.id },
      false
    )
    if (success) {
      setFileMovementHistory(data.result || [])
      setLoadingFileMovementHistory(false)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('fileTracking'),
          localize('catalogue'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3 mt-3">
                    <div className="mb-3">
                      <CatalogueTracker
                        destinations={fileTrackHistory}
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                        onGetFileMovementHistory={r => {
                          getFileMovementHistory(r)
                        }}
                      />
                    </div>
                    <Pager
                      itemsCount={fileTrackHistory.itemsCount}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={10}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                    {clickedGetFileMovementHistory && (
                      <div className="d-flex align-content-end justify-content-end mt-3 mb-3">
                        <div className="d-flex align-items-center me-5">
                          <label className="col-3 text-capitalize">
                            {localize('search')}
                          </label>
                          <EnumsServerCombo
                            customContainerStyle={{ width: '100%' }}
                            enumsKey="CustomerFilter"
                            value={reqParams?.customerFilter}
                            onChange={e => {
                              setReqParams({
                                ...reqParams,
                                customerFilter: e.value,
                              })
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center me-5">
                          <input
                            defaultValue={reqParams.filterText}
                            onChange={e =>
                              setReqParams({
                                ...reqParams,
                                filterText: e.target.value,
                              })
                            }
                            className="form-control"
                            placeholder="Enter text..."
                            type="text"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row mb-3 mt-3">
                    {clickedGetFileMovementHistory && (
                      <Tab
                        tabItems={[
                          {
                            label: localize('fileMovementHistory'),
                            item: (
                              <CatalogueFileMovementHistory
                                movementHistories={fileMovementHistory}
                                loading={loadingFileMovementHistory}
                              />
                            ),
                          },
                        ]}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FileTrackingCatalogue
