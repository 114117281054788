/** @format */

import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import React from 'react'

export const MicroCreditGroupLookup = ({ displayValue, onSelect }) => {
  const columns = [
    {
      label: localize('paddedSerialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('customerNonIndividualDescription'),
      template: r => <>{r.customerNonIndividualDescription}</>,
    },
    {
      label: localize('customerDutyStationDescription'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('typeDescription'),
      template: r => <>{r.typeDescription}</>,
    },
    { label: localize('purpose'), template: r => <>{r.purpose}</> },
    { label: localize('activities'), template: r => <>{r.activities}</> },
    {
      label: localize('meetingFrequencyDescription'),
      template: r => <>{r.meetingFrequencyDescription}</>,
    },
    {
      label: localize('meetingDayOfWeekDescription'),
      template: r => <>{r.meetingDayOfWeekDescription}</>,
    },
    { label: localize('meetingTime'), template: r => <>{r.meetingTime}</> },
    { label: localize('meetingPlace'), template: r => <>{r.meetingPlace}</> },
    {
      label: localize('minimumMembers'),
      template: r => <>{r.minimumMembers}</>,
    },
    {
      label: localize('maximumMembers'),
      template: r => <>{r.maximumMembers}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('microCreditOfficerEmployeeCustomerFullName'),
      template: r => <>{r.microCreditOfficerEmployeeCustomerFullName}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]
  return (
    <>
      <SimpleLookup
        useText
        columns={columns}
        title={localize('microCreditGroupLookup')}
        url="/lookup/MicroCreditGroup/find-micro-credit-groups-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}
