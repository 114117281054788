/** @format */

import React, { useState, useEffect } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import DirectPurchaseEntriesItems from './DirectPurchaseEntriesItems'
import { formatDate } from '../../../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

const ApproveDirectPurchase = ({
  mode,
  closeModal,
  directPurchase,
  setDirectPurchase,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [selectedAuth, setSelectedAuth] = useState(0)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/DirectPurchase/find-direct-purchase-item-by-direct-purchase-id-and-filter-in-page`,
      {
        directPurchaseId: directPurchase?.workflowRecordId,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  const findDirectPurchaseById = async () => {
    const { success, data } = await getData(
      `/DirectPurchase/find-DirectPurchase?directPurchaseId=${directPurchase?.workflowRecordId}`,
      {},
      false
    )
    if (success) {
      setDirectPurchase({ ...directPurchase, data })
    }
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [
    directPurchase?.workflowRecordId,
    reqParams.pageSize,
    reqParams.pageIndex,
  ])

  useEffect(() => {
    findDirectPurchaseById()
    // eslint-disable-next-line
  }, [directPurchase?.workflowRecordId])

  const closePage = () => {
    closeModal()
    setGlobalValidationErrors({})
  }

  const approveDirectPurchase = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/WorkFlow/approve-direct-purchase-workflow-item',
      {
        ...directPurchase,
        approvalOption: selectedAuth,
        usedBiometrics: false,
      },
      false
    )

    if (success) {
      setDirectPurchase(null)
      setIsBusy(false)
      closePage()
      loadTableData()
      showNotification('Approve Direct Purchase', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={'approve'}
      modalTitle="directPurchase"
      handleClose={closePage}
      modalClassName="modal-xl"
      handleAction={approveDirectPurchase}
      actionText={'approve'}
      footerChildren={
        <>
          <div
            className="d-flex align-items-center mb-1 with-validation px-4 mt-3"
            validation-message={
              globalValidationErrors['WorkflowItem.Remarks']?.message
            }
          >
            <label className="text-capitalize col-3">
              {localize('remarks')}
            </label>
            <input
              defaultValue={directPurchase?.remarks}
              type="text"
              className="form-control ms-2"
              onChange={e => {
                clearValidation('WorkflowItem.Remarks')
                setDirectPurchase({
                  ...directPurchase,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
          <div className="d-flex align-items-center mb-2 px-4 mt-3">
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="WorkflowApprovalOption"
              value={selectedAuth}
              onChange={e => setSelectedAuth(e.value)}
            />
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-3">
                {localize('branch')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.branchDescription}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize ">
                {localize('createdBy')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.createdBy}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('createdDate')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={formatDate(directPurchase?.createdDate)}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('modifiedBy')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.modifiedBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('modifiedDate')}
              </label>
              <input
                className="form-control "
                disabled
                defaultValue={formatDate(directPurchase?.modifiedDate, true)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('authorizedDate')}
              </label>
              <input
                className="form-control "
                disabled
                defaultValue={formatDate(directPurchase?.authorizedDate, true)}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize ">
                {localize('recordStatus')}
              </label>
              <input
                className="form-control  "
                disabled
                defaultValue={directPurchase?.recordStatusDescription}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  me-2">
                {localize('remarks')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={directPurchase?.remarks}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('directPurchaseItems'),
                  item: (
                    <DirectPurchaseEntriesItems
                      mode={mode}
                      entries={existingEntries}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ApproveDirectPurchase
