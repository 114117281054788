/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import CreateRelationshipManager from './CreateRelationshipManager/CreateRelationshipManager'

function RelationshipManagers() {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyCreating, setIsBusyCreating] = useState(false)
  const [manager, setManager] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [relationshipManagers, setRelationshipManagers] = useState([])
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    employeeFilter: 0,
  })

  const columns = [
    {
      label: 'employeeName',
      template: r => r?.employeeCustomerFullName,
    },
    {
      label: 'nationality',
      template: r => r?.employeeCustomerIndividualNationalityDescription,
    },
    {
      label: 'identityCardNumber',
      template: r => r?.employeeCustomerIndividualIdentityCardNumber,
    },
    {
      label: 'gender',
      template: r => r?.employeeCustomerIndividualGenderDescription,
    },
    {
      label: 'maritalStatus',
      template: r => r?.employeeCustomerIndividualMaritalStatusDescription,
    },
    {
      label: 'serialNumber',
      template: r => r?.paddedEmployeeCustomerSerialNumber,
    },
    {
      label: 'designation',
      template: r => r?.employeeDesignationDescription,
    },
    {
      label: 'branch',
      template: r => r?.employeeBranchDescription,
    },
    {
      label: 'department',
      template: r => r?.employeeDepartmentDescription,
    },
    {
      label: 'employeeType',
      template: r => r?.employeeEmployeeTypeDescription,
    },
    {
      label: 'employeeStatus',
      template: r => r?.employeeEmploymentStatusDescription,
    },
    {
      label: 'remarks',
      template: r => r?.remarks,
    },
    {
      label: 'isLocked',
      template: r => r?.isLocked?.toString(),
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchRelationshipManagers = async () => {
    setIsBusy(true)

    const url =
      '/RelationshipManager/find-relationship-managers-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setRelationshipManagers(data)
    }

    setIsBusy(false)
  }

  const handleCreateOrUpdate = async () => {
    setIsBusyCreating(true)
    const url =
      modalMode === 'add'
        ? '/RelationshipManager/add-relationship-manager'
        : '/RelationshipManager/update-relationship-manager'

    const { success } = await postData(url, manager, false)

    if (success) {
      setModalMode(null)
      showNotification('Relationship Managers', 'success')
      fetchRelationshipManagers()
    }

    setIsBusyCreating(false)
  }

  const handleCancel = () => {
    setModalMode(null)
    setManager({})
    setGlobalValidationErrors({})
  }

  const handleAdd = () => {
    setModalMode('add')
    setManager({})
    setGlobalValidationErrors({})
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    fetchRelationshipManagers()

    // eslint-disable-next-line
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {modalMode && (
        <CreateRelationshipManager
          clearValidation={clearValidation}
          globalValidationErrors={globalValidationErrors}
          handleClose={handleCancel}
          handleCreateOrUpdate={handleCreateOrUpdate}
          isBusy={isBusyCreating}
          manager={manager}
          mode={modalMode}
          setManager={setManager}
        />
      )}

      <BreadCrumb
        tree={[
          localize('relationshipManagement'),
          localize('relationshipManagers'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleAdd} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        pageSize: e.target.value,
                        pageIndex: 0,
                      })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>

                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 200,
                      minWidth: 200,
                    }}
                    value={reqParams?.employeeFilter}
                    enumsKey="EmployeeFilter"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, employeeFilter: value })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({ ...reqParams, text: e.target.value })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchRelationshipManagers()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchRelationshipManagers()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                data={relationshipManagers?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setManager(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span>{localize('edit')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={relationshipManagers?.itemsCount}
                {...reqParams}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default RelationshipManagers
