/** @format */

/* eslint-disable */
import React from 'react'
import { useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import { useEffect } from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import localize from '../../../../../Global/Localize'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import { cashWithdrawalRequestAuthStatus } from '../../../../../Global/enumeration'
import Swal from 'sweetalert2'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import ViewCashWithdrawalRequest from './ViewCashWithdrawalRequest/ViewCashWithdrawalRequest'
import AuthorizeCashWithdrawalRequest from './AuthorizeCashWithdrawalRequest/AuthorizeCashWithdrawalRequest'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'

const CashWithdrawalRequests = () => {
  const [requests, setRequests] = useState([])
  const [request, setRequest] = useState({
    customerTransactionAuthOption: 1,
  })
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    dateRequestsFilter: 15,
    startDate: null,
    endDate: null,
    customDate: false,
    status: 1,
    // TODO: myke- Make this true 15/09/2023
    filterByAuthorizedBranches: false,
    customerFilter: 0,
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
    {
      label: localize('serialNumber'),
      template: r => r.paddedSerialNumber,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    { label: localize('requestType'), template: r => r.typeDescription },
    {
      label: localize('requestCategory'),
      template: r => r.categoryDescription,
    },
    { label: localize('amount'), template: r => formatCurrency(r.amount) },
    {
      label: localize('fullAccountNumber'),
      template: r => r.customerAccountFullAccountNumber,
    },
    {
      label: localize('customerName'),
      template: r => r.customerAccountCustomerFullName,
    },
    {
      label: localize('accountNumber'),
      template: r => r.customerAccountCustomerReference1,
    },
    {
      label: localize('membershipNumber'),
      template: r => r.customerAccountCustomerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r.customerAccountCustomerReference3,
    },
    {
      label: localize('glAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('primaryDescription'),
      template: r => r.primaryDescription,
    },
    {
      label: localize('secondaryDescription'),
      template: r => r.secondaryDescription,
    },
    { label: localize('reference'), template: r => r.reference },
    {
      label: localize('maturityDate'),
      template: r => formatDate(r.maturityDate),
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => r.authorizationRemarks,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedBy'),
      template: r => r.authorizedBy,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => formatDate(r.authorizedDate),
    },
    { label: localize('paidBy'), template: r => r.paidBy },
    {
      label: localize('paidDate'),
      template: r => formatDate(r.paidDate),
    },
    { label: localize('createdBy'), template: r => r.createdBy },
  ]

  const fetchCashWithdrawalRequests = async () => {
    setIsBusy(true)

    const url =
      '/CashWithdrawal/find-cash-withdrawal-requests-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setRequests(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectRequest = (r, action) => {
    if (
      r.status !== Object.keys(cashWithdrawalRequestAuthStatus)[0] &&
      action === 'authorize'
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Sorry, you can only authorize pending requests.',
      })
    setRequest({
      ...r,
      customerTransactionAuthOption: 1,
    })
    setMode(action)
  }

  const handleClose = () => {
    setMode(null)
    setRequest({
      customerTransactionAuthOption: 1,
    })
  }

  useEffect(() => {
    fetchCashWithdrawalRequests()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('treasury'),
          localize('cashWithdrawalRequests'),
        ]}
      />
      {mode === 'view' && (
        <ViewCashWithdrawalRequest
          request={request}
          mode={mode}
          handleClose={handleClose}
        />
      )}

      {mode === 'authorize' && (
        <AuthorizeCashWithdrawalRequest
          request={request}
          mode={mode}
          handleClose={handleClose}
          fetchRequests={fetchCashWithdrawalRequests}
          setRequest={setRequest}
        />
      )}

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3 mt-3 gap-1">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.status}
                    enumsKey="CashWithdrawalRequestAuthStatus"
                    onChange={({ value }) =>
                      setReqParams({
                        ...reqParams,
                        status: value,
                      })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 150,
                      minWidth: 150,
                    }}
                    value={reqParams?.customerFilter}
                    enumsKey="CustomerFilter"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, customerFilter: value })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchCashWithdrawalRequests()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchCashWithdrawalRequests()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={requests?.pageCollection}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectRequest(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('view')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectRequest(r, 'authorize')
                      }}
                    >
                      <i className="mdi mdi-check-decagram"></i>
                      <span>{localize('authorize')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={requests.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CashWithdrawalRequests
