/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import CreateFuneralHome from './CreateFuneralHome/CreateFuneralHome'

function FuneralHomes() {
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [funeralHome, setFuneralHome] = useState(null)

  const columns = [
    {
      label: 'description',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'addressLine1',
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: 'addressLine2',
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: 'physicalAddress',
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: 'postalCode',
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: 'city',
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: 'emailAddress',
      template: r => <>{r?.addressEmail}</>,
    },
    {
      label: 'landLine',
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: 'mobileLine',
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: 'internetAddress',
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: 'isLocked',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: 'modifiedBy',
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleCancel = () => {
    setModalMode(null)
  }

  return (
    <>
      {modalMode && (
        <CreateFuneralHome
          mode={modalMode}
          handleClose={handleCancel}
          refresh={refresh}
          setRefresh={setRefresh}
          funeralHome={funeralHome}
          setFuneralHome={setFuneralHome}
        />
      )}
      <ListingPage
        url={'/funeralHome/find-funeral-homes-by-filter-in-page'}
        columns={columns}
        map={[localize('morgueManagement'), localize('funeralHomes')]}
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        onAdd={() => setModalMode('add')}
        useText
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                setFuneralHome(r)
                setModalMode('edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
          </>
        )}
        refreshAt={refresh}
      />
    </>
  )
}

export default FuneralHomes
