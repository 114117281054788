/** @format */

import { CustomerPartial } from '../../../Components/ReUsables/Customer/CustomerPartial'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { CustomerMembershipStatus } from '../../../Global/enumeration'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import Tab from '../../../Components/Tabs/Tab'
import { ViewCustomerSavingsAccounts } from '../../../Components/ReUsables/Customer/ViewCustomerSavingsAccounts'
import { ViewCustomerLoanAccounts } from '../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import { ViewCustomerInvestmentAccounts } from '../../../Components/ReUsables/Customer/ViewCustomerInvestmentAccounts'
import { ViewCustomerSpecimen } from '../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import { getData, putData } from '../../../Helpers/webApi'
import { ViewCustomerAddress } from '../../../Components/ReUsables/Customer/ViewCustomerAddress'
import { IndefiniteCharges } from '../../../Components/Selectables/Plain/IndefiniteCharges'
import {
  entitiesToBeChecked,
  getDynamicCharges,
} from '../../../Helpers/selectablesHelper'
import Loader from '../../../Components/Loaders/Loader'

function MembershipActivation() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [customerInvestmentAccounts, setCustomerInvestmentAccounts] = useState(
    []
  )
  const [customerSavingsAccounts, setCustomerSavingsAccounts] = useState([])
  const [customerLoanAccounts, setCustomerLoanAccounts] = useState([])
  const [indefiniteCharges, setIndefiniteCharges] = useState([])
  const [selectedIndefiniteCharges, setSelectedIndefiniteCharges] = useState([])

  const getIndefiniteCharges = async () => {
    const dynamicCharges = await getDynamicCharges()
    setIndefiniteCharges(entitiesToBeChecked(dynamicCharges || [], []))
  }

  useEffect(() => {
    getIndefiniteCharges()
  }, [])

  async function handleSubmit(data) {
    setIsBusy(true)
    const { success } = await putData(
      '/Customer/activate-membership',
      data,
      false,
      'Operation Completed Successfully'
    )
    if (success) {
      setIndefiniteCharges([])
      setSelectedCustomer(null)
    }
    setIsBusy(false)
  }

  const handleOnClickUpdate = async () => {
    if (!selectedCustomer) {
      Swal.fire({
        icon: 'error',
        text: 'Please Select At least one customer',
        title: 'Membership Activation',
        showCloseButton: true,
      })
      return
    }
    await handleSubmit({
      Customer: selectedCustomer,
      DynamicCharges: selectedIndefiniteCharges,
    })
  }

  const handleOnSelectCustomer = r => {
    if (r.membershipStatus !== Object.keys(CustomerMembershipStatus)[0]) {
      setSelectedCustomer(r)
      getCustomerAccounts(r)
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Membership Status is already active',
        title: 'Membership Status Activation',
        showCloseButton: true,
      })
    }
  }

  const getCustomerAccounts = async customer => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes',
      {
        customerId: customer.id,
        includeBalances: false,
        includeProductDescription: true,
      },
      false
    )
    if (success) {
      setCustomerSavingsAccounts(data.customerSavingsAccounts)
      setCustomerLoanAccounts(data.customerLoanAccounts)
      setCustomerInvestmentAccounts(data.customerInvestmentAccounts)
    }
    setIsBusy(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('membershipActivation'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <CustomerPartial
                    customer={selectedCustomer}
                    onCustomerLookup={r => {
                      handleOnSelectCustomer(r)
                    }}
                  />
                  <div className="col-6 d-flex align-items-center">
                    <label className="col-2 text-capitalize">
                      {localize('remarks')}
                    </label>
                    <input
                      className="form-control"
                      onChange={r => {
                        setSelectedCustomer({
                          ...selectedCustomer,
                          remarks: r.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="d-flex align-content-end justify-content-end">
                    <button
                      onClick={handleOnClickUpdate}
                      className="btn btn-success text-uppercase ms-3 px-4"
                    >
                      {localize('update')}
                    </button>
                  </div>
                  <div className="row mt-12">
                    <Tab
                      tabItems={[
                        {
                          label: localize('savingsAccount'),
                          item: (
                            <ViewCustomerSavingsAccounts
                              customerSavingsAccounts={customerSavingsAccounts}
                            />
                          ),
                        },
                        {
                          label: localize('loanAccounts'),
                          item: (
                            <ViewCustomerLoanAccounts
                              customerLoanAccounts={customerLoanAccounts}
                              hiddenColumnsIndexes={[0]}
                            />
                          ),
                        },
                        {
                          label: localize('investmentAccounts'),
                          item: (
                            <ViewCustomerInvestmentAccounts
                              customerInvestmentAccounts={
                                customerInvestmentAccounts
                              }
                            />
                          ),
                        },
                        {
                          label: localize('address'),
                          item: (
                            <ViewCustomerAddress customer={selectedCustomer} />
                          ),
                        },
                        {
                          label: localize('specimen'),
                          item: (
                            <ViewCustomerSpecimen customer={selectedCustomer} />
                          ),
                        },
                        {
                          label: localize('indefiniteCharges'),
                          item: (
                            <IndefiniteCharges
                              indefiniteChargesToSelect={indefiniteCharges}
                              onCheckIndefiniteCharge={c => {
                                setIndefiniteCharges(c)
                                setSelectedIndefiniteCharges(
                                  c.filter(x => x.checked)
                                )
                              }}
                              prefix={'indefinite-charges'}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MembershipActivation
