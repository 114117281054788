/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Tab from '../../../../../Components/Tabs/Tab'
import { CustomerAccountPartial } from '../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import { TellerChartOfAccountStatementPreview } from './Partials/AccountMetadata/Transactions/Previews/TellerChartOfAccountStatementPreview'
import { CustomerAccountPreview } from './Partials/AccountMetadata/Transactions/Previews/CustomerAccountPreview'
import { CustomerPreview } from './Partials/AccountMetadata/Transactions/Previews/CustomerPreview'
import { TellerBalancesInformation } from './Partials/AccountMetadata/TellerBalancesInformation'
import { CustomerAccounts } from './Partials/AccountMetadata/Transactions/Previews/CustomerAccounts'
import { getData, postData } from '../../../../../Helpers/webApi'
import { CustomerAccountTransactionSection } from './Partials/AccountMetadata/Transactions/CustomerAccountTransactionSection'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { getTodaysDate } from '../../../../../Helpers/formatters'

const SavingsReceiptsPayments = () => {
  const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [withdrawableAmount, setWithdrawableAmount] = useState(0)
  const [selectedTransactionType, setSelectedTransactionType] = useState('1')
  const [transactionModel, setTransactionModel] = useState({
    cashWithdrawalAmount: '',
    currencyCountModelTotalValue: '',
    paymentVoucher: {
      writeDate: getTodaysDate(),
    },
  })
  const [globalValidation, setGlobalValidation] = GlobalValidationErrors.use()
  const [selectedChequeType, setSelectedChequeType] = useState(null)
  const [externalChequePayables, setExternalChequePayables] = useState([])

  const [currencyTotalValue, setCurrencyTotalValue] = useState(0)
  const [currencyModel, setCurrencyModel] = useState({})

  const [newBookBalance, setNewBookBalance] = useState(0)
  const [newAvailableBalance, setNewAvailableBalance] = useState(0)
  const [tarrifs, setTarrifs] = useState(0)
  const [inputBlurred, setInputBlurred] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const clearValidation = column => {
    setGlobalValidation(g => {
      delete g[column]
      return g
    })
  }
  const [tellerBalanceInformation, setTellerBalanceInformation] = useState(null)

  const handleOnSelectCustomerAccount = async customerAccount => {
    setSelectedCustomerAccount(customerAccount)
    fetchCustomer(customerAccount)
    fetchWithdrawalAmount(customerAccount)
    getTariffs(selectedTransactionType, customerAccount)
  }

  useEffect(() => {
    if (!inputBlurred && selectedCustomerAccount) {
      getTariffs(selectedTransactionType, selectedCustomerAccount)
    }
  }, [selectedTransactionType, currencyTotalValue])

  const fetchCustomer = async selectedCustomerAccount => {
    const { success, data } = await getData(
      '/lookup/customer/find-customer-by-id',
      {
        customerId: selectedCustomerAccount.customerId,
      },
      false,

    )
    if (success) {
      setSelectedCustomer(data.customer)
    }
  }

  const fetchWithdrawalAmount = async selectedCustomerAccount => {
    const { success, data } = await postData(
      '/lookup/Teller/compute-over-the-counter-withdrawable-amount',
      {
        ...selectedCustomerAccount,
      },
      false,

    )
    if (success) {
      setWithdrawableAmount(data.result)
    }
  }

  const resetTransactionModel = () => {
    setTransactionModel({
      cashWithdrawalAmount: '',
      currencyCountModelTotalValue: '',
      chequeDepositAmount: '',
      chequeDeposit: {
        writeDate: new Date(),
      },
      paymentVoucher: {
        writeDate: new Date(),
      },
      reference: '',
    })
    setCurrencyModel({})
    setCurrencyTotalValue(0)
  }

  const handleOnChangeTransactionType = e => {
    setSelectedTransactionType(e)
    resetTransactionModel()
  }

  const resetSelectedCustomerAccount = () => {
    resetTransactionModel()
    setSelectedCustomerAccount(null)
  }

  const getTariffs = async (transactionType, customerAccount) => {
    const { success, data } = await postData(
      '/lookup/Teller/get-teller-balances-summary',
      {
        ChequeType: { ...selectedChequeType },
        CustomerAccount: { ...customerAccount },
        TotalValue: transactionModel?.currencyCountModelTotalValue,
        FrontOfficeTransactionType: transactionType,
        PreviousTellerBalance: tellerBalanceInformation?.previousTellerBalance,
        WithdrawalAmount: transactionModel?.cashWithdrawalAmount,
        ChequeDepositAmount: transactionModel?.chequeDeposit?.amount,
        CurrencyCountModel: { ...currencyModel, total: currencyTotalValue },
        DepositAmount: currencyTotalValue,
      },
      false,
      null
    )
    if (success) {
      setNewBookBalance(data.bookBalance)
      setNewAvailableBalance(data.availableBalance)
      setTellerBalanceInformation({
        ...tellerBalanceInformation,
        newTellerBalance: data.newTellerBalance,
      })
      setTarrifs(data.tarrifs)
    }
  }

  const handleOnSubmitInstruction = async () => {
    await getTariffs(selectedTransactionType, selectedCustomerAccount)
  }

  const handleOnBlur = async e => {
    setInputBlurred(true)
    await getTariffs(selectedTransactionType, selectedCustomerAccount)
    setInputBlurred(false)
  }

  const handleOnChangeChequeDepositAmount = async e => {
    const { success, data } = await postData(
      '/lookup/Teller/get-teller-balances-summary',
      {
        ChequeType: { ...selectedChequeType },
        CustomerAccount: { ...selectedCustomerAccount },
        TotalValue: transactionModel?.currencyCountModelTotalValue,
        FrontOfficeTransactionType: selectedTransactionType,
        PreviousTellerBalance: tellerBalanceInformation?.previousTellerBalance,
        WithdrawalAmount: 0,
        ChequeDepositAmount: e.target.value,
        CurrencyCountModel: { ...currencyModel, total: currencyTotalValue },
        DepositAmount: 0,
      },
      false,
      null
    )
    if (success) {
      setNewBookBalance(data.bookBalance)
      setNewAvailableBalance(data.availableBalance)
      setTellerBalanceInformation({
        ...tellerBalanceInformation,
        newTellerBalance: data.newTellerBalance,
      })
      setTarrifs(data.tarrifs)
    }
  }

  const handleOnChangePaymentVoucherAmount = async e => {
    const { success, data } = await postData(
      '/lookup/Teller/get-teller-balances-summary',
      {
        ChequeType: { ...selectedChequeType },
        CustomerAccount: { ...selectedCustomerAccount },
        TotalValue: transactionModel?.currencyCountModelTotalValue,
        FrontOfficeTransactionType: selectedTransactionType,
        PreviousTellerBalance: tellerBalanceInformation?.previousTellerBalance,
        WithdrawalAmount: e.target.value,
        ChequeDepositAmount: 0,
        CurrencyCountModel: { ...currencyModel, total: currencyTotalValue },
        DepositAmount: 0,
      },
      false,
      null
    )
    if (success) {
      setNewBookBalance(data.bookBalance)
      setNewAvailableBalance(data.availableBalance)
      setTellerBalanceInformation({
        ...tellerBalanceInformation,
        newTellerBalance: data.newTellerBalance,
      })
      setTarrifs(data.tarrifs)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('tellers'),
          localize('savingsReceipts'),
          localize('payments'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <CustomerAccountPartial
                message={
                  globalValidation?.['SelectedCustomerAccount.Id']?.message
                }
                onClearValidation={() => {
                  clearValidation('SelectedCustomerAccount.Id')
                }}
                onCustomerLookup={handleOnSelectCustomerAccount}
                customer={selectedCustomerAccount}
                productCode={'1'}
              />
              <div className="row mb-3">
                <div className="col-md-12">
                  <Tab
                    preload
                    tabItems={[
                      {
                        label: localize('transaction'),
                        item: (
                          <CustomerAccountTransactionSection
                            showLoading={showLoading}
                            selectedCustomerAccount={selectedCustomerAccount}
                            handleOnChangeChequeDepositAmount={
                              handleOnChangeChequeDepositAmount
                            }
                            handleOnBlur={handleOnBlur}
                            tarrifs={tarrifs}
                            setCurrencyTotalValue={setCurrencyTotalValue}
                            handleOnChangePaymentVoucherAmount={
                              handleOnChangePaymentVoucherAmount
                            }
                            transactionModel={transactionModel}
                            externalChequePayables={externalChequePayables}
                            setExternalChequePayables={
                              setExternalChequePayables
                            }
                            setTransactionModel={setTransactionModel}
                            onChangeTransactionType={
                              handleOnChangeTransactionType
                            }
                          />
                        ),
                      },
                      {
                        label: localize('tellerChartOfAccountStatement'),
                        item: (
                          <TellerChartOfAccountStatementPreview
                            onFetchTellerStatements={r => {
                              setTellerBalanceInformation(r)
                            }}
                          />
                        ),
                      },
                      {
                        label: localize('customerAccountPreview'),
                        item: (
                          <CustomerAccountPreview
                            selectedCustomerAccount={selectedCustomerAccount}
                          />
                        ),
                      },
                      {
                        label: localize('customerPreview'),
                        item: (
                          <CustomerPreview
                            selectedCustomer={selectedCustomer}
                          />
                        ),
                      },
                      {
                        label: localize('customerAccounts'),
                        item: (
                          <CustomerAccounts
                            selectedCustomer={selectedCustomer}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <TellerBalancesInformation
                    setShowLoading={setShowLoading}
                    selectedPaymentVoucher={transactionModel?.paymentVoucher}
                    onSubmitTransaction={handleOnSubmitInstruction}
                    setTransactionModel={setTransactionModel}
                    newBookBalance={newBookBalance}
                    onResultConfirmed={() => {
                      resetSelectedCustomerAccount()
                    }}
                    newAvailableBalance={newAvailableBalance}
                    selectedExternalCheque={transactionModel?.chequeDeposit}
                    selectedChequeType={transactionModel?.selectedChequeType}
                    currencyTotalValue={currencyTotalValue}
                    transactionModel={transactionModel}
                    selectedTransactionType={selectedTransactionType}
                    withdrawableAmount={withdrawableAmount}
                    selectedCustomerAccount={selectedCustomerAccount}
                    balanceSummary={tellerBalanceInformation}
                    chequeDepositAmount={
                      transactionModel?.chequeDeposit?.amount
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SavingsReceiptsPayments
