/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'

import signalRService from '../../Helpers/signalR'
import { getData } from '../../Helpers/webApi'
import { NotificationCard } from '../Notifications/NotificationCard'
import { useAuth } from 'react-oidc-context'

const NotificationDropdown = () => {
  const [menu, setMenu] = useState(false)
  const [notifications, setNotifications] = useState([])
  const auth = useAuth()

  useEffect(() => {
    const handleOnReceiveNotification = event => {
      setNotifications(prevNotifications => [
        event.detail,
        ...prevNotifications,
      ])
    }

    signalRService.addEventListener(
      'onShowNotification',
      handleOnReceiveNotification,
    )

    return () => {
      signalRService.removeEventListener(
        'onShowNotification',
        handleOnReceiveNotification,
      )
    }

    //eslint-disable-next-line
  }, [])

  const getNotifications = async () => {
    const { data } = await getData(
      '/AccessControl/find-current-user-notifications',
      {},
      false,
    )

    setNotifications(Array.isArray(data) ? data : [])
  }

  useEffect(() => {
    if (auth.isAuthenticated) {
      getNotifications()
    }
    //eslint-disable-next-line
  }, [auth.isAuthenticated])

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          <span className="badge bg-danger rounded-pill">
            {notifications.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0 bg-white overflow-auto">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16"> Notifications</h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  Mark all as read
                </a>
              </div>
            </Row>
          </div>

          <div style={{ height: '230px' }}>
            <NotificationCard notifications={notifications} />
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default NotificationDropdown
