/** @format */

import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import React from 'react'
import { formatDate } from '../../Helpers/formatters'

export const SupplierLookup = ({ displayValue, onSelect }) => {
  const columns = [
    { label: localize('description'), template: r => <>{r.description}</> },
    {
      label: localize('supplierType'),
      template: r => <>{r.supplierTypeDescription}</>,
    },
    {
      label: localize('creditPeriod(days)'),
      template: r => <>{r.creditPeriod}</>,
    },
    {
      label: localize('availableBalance'),
      template: r => <>{r.availableBalance}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileline'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r.addressInternet}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        useText
        displayValue={displayValue}
        onSelect={onSelect}
        url={'/lookup/SupplierType/find-suppliers-by-filter-in-page'}
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        title={localize('supplierLookup')}
        columns={columns}
      />
    </>
  )
}
