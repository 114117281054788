/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import SimpleTable from '../../Tables/SimpleTable'

function JournalEntries({ journalEntries }) {
  const columns = [
    {
      label: 'transactionDate',
      template: r => <>{formatDate(r?.transactionDate)}</>,
    },
    {
      label: 'amount',
      template: r => <>{formatCurrency(r?.amount)}</>,
      headerTextAlign: 'right',
    },
    {
      label: 'chartOfAccountName',
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: 'valueDate',
      template: r => <>{formatDate(r?.valueDate, true)}</>,
    },
    { label: 'remarks', template: r => <>{r?.remarks}</> },
    {
      label: 'fullAccountNumber',
      template: r => <>{r?.customerAccountFullAccountNumber}</>,
    },
    {
      label: 'customerName',
      template: r => <>{r?.customerAccountCustomerFullName}</>,
    },
    {
      label: 'memberNumber',
      template: r => <>{r?.formattedCustomerAccountCustomerMemberNumber}</>,
    },
    {
      label: 'accountNumber',
      template: r => <>{r?.paddedCustomerAccountCustomerAccountNumber}</>,
    },
    {
      label: 'personalFileNumber',
      template: r => <>{r?.customerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('membershipNumber'),
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    { label: 'costCenter', template: r => <>{r?.costCenterDescription}</> },
    {
      label: 'costDistributionRule',
      template: r => <>{r?.costDistributionRule}</>,
    },
    {
      label: 'costDistributionPercentage',
      template: r => <>{r?.costDistributionPercentage}</>,
    },
    { label: 'createdBy', template: r => <>{r?.createdBy}</> },
    {
      label: 'integrityHash',
      template: r => <>{r?.integrityHash}</>,
    },
  ]
  return (
    <>
      <SimpleTable columns={columns} data={journalEntries} />
    </>
  )
}

export default JournalEntries
