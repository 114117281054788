/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData, putData } from '../../../../../../Helpers/webApi'
import { generateGuid } from '../../../../../../Helpers/extensions'
import localize from '../../../../../../Global/Localize'
import BankBranchLookup from '../../../../../../Components/Lookup/BankBranchLookup'
import BankLookup from '../../../../../../Components/Lookup/BankLookup'
import Tab from '../../../../../../Components/Tabs/Tab'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Swal from 'sweetalert2'
import { formatDate } from '../../../../../../Helpers/formatters'
import EditableCell from '../../../../../../Components/Tables/EditableCell'
import Loader from '../../../../../../Components/Loaders/Loader'

function BankAccounts({ customer, readOnly }) {
  const [bankAccounts, setBankAccounts] = useState([])
  const [selectedBankAccount, setSelectedBankAccount] = useState(null)
  const [branch, setBranch] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const columns = [
    // { label: 'tmp', template: r => <>{r.id}</> }, Don't remove this line
    {
      label: 'bankCode',
      template: r => <EditableCell value={r?.bankBranchBankCode} />,
    },
    { label: 'bankName', template: r => <>{r?.bankBranchBankDescription}</> },
    { label: 'branchCode', template: r => <>{r?.bankBranchCode}</> },
    { label: 'branchName', template: r => <>{r?.bankBranchDescription}</> },
    { label: 'accountNumber', template: r => <>{r?.accountNumber}</> },
    { label: 'remarks', template: r => <>{r?.remarks}</> },
    { label: 'createdBy', template: r => <>{r?.createdBy}</> },
    { label: 'createdDate', template: r => <>{formatDate(r?.createdDate)}</> },
  ]

  useEffect(() => {
    loadAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    loadBranch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBankAccount?.bankBranchBankId])

  async function loadAccounts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/customer/find-bank-account-collection-by-customer-id',
      { customerId: customer?.id },
      false
    )

    if (success) {
      setIsBusy(false)
      setBankAccounts(data?.result)
    } else {
      setIsBusy(false)
    }
  }

  async function loadBranch() {
    setIsBusy(true)
    setBranch(null)
    const { success, data } = await getData(
      '/bank/find-bank-branch-by-bank-id',
      { bankId: selectedBankAccount.bankBranchBankId },
      false
    )
    if (success) {
      setIsBusy(false)
      let br = data?.result && data?.result[0]
      if (br) {
        setBranch(br)
        setSelectedBankAccount({
          ...selectedBankAccount,
          bankBranchDescription: br.description,
          bankBranchCode: br.code,
          bankBranchId: br.id,
        })
      } else {
        setIsBusy(false)
        setBranch(br)
      }
    }
  }

  async function updateInfo() {
    setIsBusy(true)
    const { success } = await putData(
      '/customer/update-bank-account-collection-by-customer-id?customerId=' +
        customer?.id,
      bankAccounts,
      false
    )
    if (success) {
      setIsBusy(false)
      await loadAccounts()
      Swal.fire(
        'Operation Successful',
        'Bank account details updated!',
        'success'
      )
    } else {
      setIsBusy(false)
    }
  }
  return isBusy ? (
    <Loader />
  ) : readOnly ? (
    <SimpleTable data={bankAccounts} columns={columns} />
  ) : (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('bank')}
          </label>
          <BankLookup
            displayValue={selectedBankAccount?.bankBranchBankDescription}
            onSelect={b =>
              setSelectedBankAccount({
                ...selectedBankAccount,
                bankBranchBankDescription: b.description,
                bankBranchBankCode: b.code,
                bankBranchBankId: b.id,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('bankBranch')}
          </label>
          <BankBranchLookup
            bankId={selectedBankAccount?.bankBranchBankId}
            bankDescription={selectedBankAccount?.bankBranchBankDescription}
            displayValue={selectedBankAccount?.bankBranchDescription}
            onSelect={b => {
              setBranch(b)
              setSelectedBankAccount({
                ...selectedBankAccount,
                bankBranchDescription: b.description,
                bankBranchId: b.id,
                bankBranchCode: b.code,
              })
            }}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={e =>
              setSelectedBankAccount({
                ...selectedBankAccount,
                accountNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-3">
            {localize('remarks')}
          </label>
          <input
            type="text"
            className="form-control"
            onChange={e =>
              setSelectedBankAccount({
                ...selectedBankAccount,
                remarks: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="p-2 mt-2 bg-light d-flex align-items-center justify-content-end">
        <button
          className="btn btn-success px-4"
          onClick={() => {
            let acts = [...bankAccounts]
            acts.push({
              ...selectedBankAccount,
              id: generateGuid(),
            })
            setBankAccounts(acts)
          }}
        >
          {localize('add')}
        </button>
      </div>
      <div className="mt-3">
        <Tab
          tabItems={[
            {
              label: localize('accountCollection'),
              item: (
                <SimpleTable
                  data={bankAccounts}
                  columns={columns}
                  contextMenu={ba => (
                    <>
                      <div
                        className="ctxt-menu-item text-capitalize text-danger"
                        onClick={() => {
                          let bs = [...bankAccounts]
                          let i = bs.findIndex(b => b.id === ba.id)
                          bs.splice(i, 1)
                          setBankAccounts(bs)
                        }}
                      >
                        <i className="uil uil-times-circle"></i>
                        <span>{localize('remove')}</span>
                      </div>
                    </>
                  )}
                />
              ),
            },
          ]}
        />
      </div>
      <div className="my-2 d-flex align-items-center justify-content-end">
        <button onClick={updateInfo} className="btn btn-success px-4">
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default BankAccounts
