/** @format */

import localize from '../../Global/Localize'
import { formatAsPercent, formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

export const FixedDepositTypeLookup = ({ onSelect, displayValue }) => {
  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRuleDescription'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('interestExpenseChartOfAccountName'),
      template: r => <>{r?.interestExpenseChartOfAccountName}</>,
    },
    {
      label: localize(
        'interestExpenseChartOfAccountCostDistributionRuleDescription'
      ),
      template: r => (
        <>{r?.interestExpenseChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    { label: localize('months'), template: r => <>{r?.months}</> },
    {
      label: localize('advanceRate'),
      template: r => <>{formatAsPercent(r?.advanceRate)}</>,
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        title={localize('fixedDepositTypeLookup')}
        columns={columns}
        url="/lookup/FixedDepositType/find-fixed-deposit-types-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        onSelect={onSelect}
        displayValue={displayValue}
      />
    </>
  )
}
