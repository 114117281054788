/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import { putData } from '../../../Helpers/webApi'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'
import EducationVenueLookup from '../../../Components/Lookup/EducationVenueLookup'
import localize from '../../../Global/Localize'
import { CustomerPartial } from '../../../Components/ReUsables/Customer/CustomerPartial'
import EducationAttendees from './EducationAttendees'
import Swal from 'sweetalert2'
import Tab from '../../../Components/Tabs/Tab'
import { ViewCustomerSpecimen } from '../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import { parseDate } from '../../../Helpers/formatters'
import Specimen from '../Members/Partials/Tabs/Specimen'

function EditEducationRegisterModal({
  mode,
  selectedEducationRegister,
  setSelectedEducationRegister,
  close,
  refreshData,
  educationAttendees,
  onCustomerChange,
  handleAddToDataTable,
  handleRemove,
  selectedCustomers,
  handleSelect,
  onSelectAll,
  onCustomerInfoChanged,
  onSetAttendeeRemarks,
}) {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [customRemarks, setCustomRemarks] = useState(null)

  async function handleEdit() {
    const { success } = await putData(
      '/EducationRegister/update-education-register-with-attendees',
      {
        educationRegisterDTO: selectedEducationRegister,
        educationAttendeeDTOs: educationAttendees,
      },
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      close()
      refreshData()
    }
  }

  const handleOnSelectCustomer = customer => {
    if (!customer.isLocked) {
      setSelectedCustomer({ ...customer })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Selected Customer is Locked',
        title: 'Education Register Attendee',
        showCloseButton: true,
      })
      setSelectedCustomer(null)
    }
  }

  function closeModal() {
    close()
    setGlobalValidationErrors({})
    setSelectedEducationRegister(null)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    onCustomerInfoChanged(selectedCustomer)
  }, [selectedCustomer])

  useEffect(() => {
    onSetAttendeeRemarks(customRemarks)
  }, [customRemarks])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="membersEducationRegister"
      handleAction={handleEdit}
      handleClose={closeModal}
      modalClassName="modal-xl"
      footerChildren={
        <>
          <Checkbox
            id="editEducationRegisterEnforceAuthorizationToken"
            onCheck={e => {
              setSelectedEducationRegister({
                ...selectedEducationRegister,
                enforceAuthorizationToken: e,
              })
            }}
            checked={selectedEducationRegister?.enforceAuthorizationToken}
          />
          <label
            htmlFor="editEducationRegisterEnforceAuthorizationToken"
            className="text-capitalize"
          >
            {localize('enforceAuthorizationToken')}?
          </label>
        </>
      }
    >
      <div className="row mb-3 g-3">
        <div className="col-md-12">
          <div
            className="with-validation d-flex align-items-center mb-2"
            validation-message={globalValidationErrors['Description']?.message}
          >
            <label className="text-capitalize col-1">{localize('name')}</label>
            <input
              defaultValue={selectedEducationRegister?.description}
              type="text"
              className="form-control"
              onChange={e => {
                clearValidation('Description')
                setSelectedEducationRegister({
                  ...selectedEducationRegister,
                  description: e.target.value,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3 g-3">
        <div className="col-md-4">
          <div
            className="with-validation d-flex align-items-center mb-2"
            validation-message={
              globalValidationErrors['EducationVenueId']?.message
            }
          >
            <label className="col-3 text-capitalize">{localize('venue')}</label>
            <EducationVenueLookup
              displayValue={
                selectedEducationRegister?.educationVenueDescription
              }
              onSelect={r => {
                clearValidation('EducationVenueId')
                setSelectedEducationRegister({
                  ...selectedEducationRegister,
                  educationVenueId: r.id,
                  educationVenueDescription: r.description,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-1">
              {localize('remarks')}
            </label>
            <input
              defaultValue={selectedEducationRegister?.remarks}
              type="text"
              className="form-control"
              onChange={e => {
                setSelectedEducationRegister({
                  ...selectedEducationRegister,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3 g-3">
        <div className="col-md-4">
          <div className="d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('postingPeriod')}
            </label>

            <input
              disabled
              defaultValue={selectedEducationRegister?.postingPeriodDescription}
              type="text"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-2">
              {localize('startDate')}
            </label>
            <input
              type="date"
              className="form-control"
              defaultValue={parseDate(
                selectedEducationRegister?.durationStartDate
              )}
              onChange={a =>
                setSelectedEducationRegister({
                  ...selectedEducationRegister,
                  durationStartDate: a.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center mb-2">
            <label className="text-capitalize col-2">
              {localize('endDate')}
            </label>
            <input
              type="date"
              className="form-control"
              defaultValue={parseDate(
                selectedEducationRegister?.durationEndDate
              )}
              onChange={a =>
                setSelectedEducationRegister({
                  ...selectedEducationRegister,
                  durationEndDate: a.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('attendeeDetails')}
        </div>
        <>
          <CustomerPartial
            customer={selectedCustomer}
            onCustomerLookup={r => {
              handleOnSelectCustomer(r)
              onCustomerChange(r)
            }}
            setCustomRemarks={setCustomRemarks}
            customRemarks={customRemarks}
            remarksVisible={true}
          />
          <div
            className="bg-light d-flex justify-content-end align-items-center p-2"
            style={{ borderRadius: 5 }}
          >
            <button
              onClick={() => {
                if (selectedCustomer === null) {
                  Swal.fire('Ooops!', 'Please Select Customer', 'info')
                } else {
                  handleAddToDataTable()
                  setSelectedCustomer(null)
                  setCustomRemarks({ remarks: '' })
                }
              }}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('add')}
            </button>
          </div>
        </>

        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('attendees'),
                item: (
                  <EducationAttendees
                    mode={mode}
                    handleRemove={r => {
                      handleRemove(r)
                    }}
                    selectedAttendees={educationAttendees}
                    selectedCustomers={selectedCustomers}
                    handleSelect={handleSelect}
                    onSelectAll={onSelectAll}
                  />
                ),
              },
              {
                label: localize('specimenIdentity'),
                item: <Specimen customer={educationAttendees} readOnly />,
              },
              {
                label: localize('specimenFingerprint'),
                item: <Specimen customer={educationAttendees} readOnly />,
              },
            ]}
          />
        </div>
      </div>
    </ModalTemplate>
  )
}
export default EditEducationRegisterModal
