/** @format */
/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import rightAlignFragment, {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../Helpers/formatters'
import SimpleLookupWithFilters from './SimpleLookupWithFilters'

function InvestmentProductLookup({
  onSelect,
  displayValue,
  defaultFilter = 0,
  disabled,
}) {
  return (
    <>
      <SimpleLookupWithFilters
        disabled={disabled}
        difaultFilter={defaultFilter}
        enumKey={'ProductSection'}
        paramKey={'ProductSection'}
        title={localize('investmentProductLookup')}
        url="/lookup/InvestmentProduct/find-investment-product-by-product-section-and-filter-in-page"
        params={{
          filterText: '',
          pageSize: 10,
          pageIndex: 0,
        }}
        columns={[
          { label: localize('code'), template: r => <>{r?.paddedCode}</> },
          {
            label: localize('billReferenceNumber'),
            template: r => <>{r?.billReferenceNumber}</>,
            sortBy: 'billReferenceNumber',
          },
          {
            label: localize('name'),
            template: r => <>{r?.description}</>,
            sortBy: 'description',
          },
          {
            label: rightAlignFragment(localize('minimumBalance')),
            template: r => <>{formatCurrency(r?.minimumBalance)}</>,
            sortBy: 'minimumBalance',
          },
          {
            label: rightAlignFragment(localize('maximumBalance')),
            template: r => <>{formatCurrency(r?.maximumBalance)}</>,
            sortBy: 'maximumBalance',
          },
          {
            label: localize('annualPercentageYield'),
            template: r => <>{formatAsPercent(r?.annualPercentageYield)}</>,
          },
          {
            label: localize('g/LAccountName'),
            template: r => <>{r?.chartOfAccountName}</>,
          },
          {
            label: localize('g/LCostDistributionRuleDescription'),
            template: r => (
              <>{r?.chartOfAccountCostDistributionRuleDescription}</>
            ),
          },
          {
            label: localize('checkoffEntryType'),
            template: r => <>{r?.checkoffEntryTypeDescription}</>,
          },
          {
            label: localize('poolGLAccountName'),
            template: r => <>{r?.poolChartOfAccountName}</>,
          },
          {
            label: localize('poolGlCostDistributionRuleDescription'),
            template: r => (
              <>{r?.poolChartOfAccountCostDistributionRuleDescription}</>
            ),
          },
          {
            label: localize('interestExpenseGLAccountName'),
            template: r => <>{r?.interestExpenseChartOfAccountName}</>,
          },
          {
            label: localize('interestExpenseGlCostDistributionRuleDescription'),
            template: r => (
              <>
                {
                  r?.interestExpenseChartOfAccountCostDistributionRuleDescription
                }
              </>
            ),
          },
          {
            label: localize('interestPayableGLAccountName'),
            template: r => <>{r?.interestPayableChartOfAccountName}</>,
          },
          {
            label: localize('interestPayableGlCostDistributionRuleDescription'),
            template: r => (
              <>
                {
                  r?.interestPayableChartOfAccountCostDistributionRuleDescription
                }
              </>
            ),
          },
          {
            label: rightAlignFragment(localize('poolAmount')),
            template: r => <>{formatCurrency(r?.poolAmount)}</>,
          },
          {
            label: localize('maturityPeriod') + localize('(days)'),
            template: r => <>{r?.maturityPeriod}</>,
          },
          {
            label: localize('recoveryPriority'),
            template: r => <>{r?.priority}</>,
          },
          {
            label: localize('isSuperSaver') + ' ?',
            template: r => (
              <span className="text-capitalize">
                {localize(r?.isSuperSaver?.toString())}
              </span>
            ),
          },
          {
            label: localize('isRefundable') + ' ?',
            template: r => (
              <span className="text-capitalize">
                {localize(r?.isRefundable.toString())}
              </span>
            ),
          },
          {
            label: localize('isPooled') + ' ?',
            template: r => (
              <span className="text-capitalize">
                {localize(r?.isPooled.toString())}
              </span>
            ),
          },
          {
            label: localize('concealBankToMobileListing') + '?',
            template: r => (
              <span className="text-capitalize">
                {localize(r?.concealBankToMobileListing.toString())}
              </span>
            ),
          },
          {
            label: localize('isInstitutionalCapital') + ' ?',
            template: r => (
              <span className="text-capitalize">
                {localize(r?.isInstitutionalCapital.toString())}
              </span>
            ),
          },
          {
            label: localize('isLocked') + ' ?',
            template: r => (
              <span className="text-capitalize">
                {localize(r?.isLocked.toString())}
              </span>
            ),
          },
          {
            label: localize('modifiedBy'),
            template: r => <>{r?.modifiedBy}</>,
          },
          {
            label: localize('modifiedDate'),
            template: r => <>{formatDate(r?.modifiedDate, true)}</>,
          },
          {
            label: localize('createdBy'),
            template: r => <>{r?.createdBy}</>,
          },
          {
            label: localize('createdDate'),
            template: r => <>{formatDate(r?.createdDate)}</>,
          },
        ]}
        onSelect={onSelect}
        displayValue={displayValue}
      />
    </>
  )
}
export default InvestmentProductLookup
