/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'

const SelectableSalaryGroups = ({
  disabled,
  isBusy,
  selectedSalaryGroups,
  prefix,
  onItemsSelection,
}) => {
  const [toggleSalaryGroupsSelection, setToggleSalaryGroupsSelection] =
    useState(false)
  const [selectedItems, setSelecteditems] = useState([])
  const handleCheckGroup = (group, value) => {
    if (value) setSelecteditems([...selectedItems, group])
    else setSelecteditems(selectedItems.filter(item => item.id !== group.id))
  }

  const onCheckSalaryGroups = checkStatus => {
    setToggleSalaryGroupsSelection(checkStatus)
    if (checkStatus) setSelecteditems(selectedSalaryGroups?.salaryGroups)
    else setSelecteditems([])
  }

  useEffect(() => {
    onItemsSelection(selectedItems)

    //eslint-disable-next-line
  }, [selectedItems])

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {selectedSalaryGroups?.salaryGroups?.map(groups => (
              <div
                key={groups.id}
                className="d-flex align-items-center gap-2 justify-content-start"
              >
                <Checkbox
                  id={`${prefix ? prefix : 'com'}-${groups.id}`}
                  checked={selectedItems?.find(item => item.id === groups.id)}
                  disabled={disabled}
                  margined
                  onCheck={c => {
                    handleCheckGroup(groups, c)
                  }}
                />
                <label
                  htmlFor={`${prefix ? prefix : 'com'}-${groups.id}`}
                  className={`fw-normal ms-2 cursor-pointer ${
                    groups.checked ? 'text-primary fw-bold' : ''
                  }`}
                >
                  {groups.description}
                </label>
              </div>
            ))}
          </div>
          <div className="row mb-3 g-3 mt-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <Checkbox
                  id="toggle-selection"
                  checked={toggleSalaryGroupsSelection}
                  onCheck={v => {
                    onCheckSalaryGroups(v)
                  }}
                />
                <label
                  htmlFor="toggle-selection"
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')} ?
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SelectableSalaryGroups
