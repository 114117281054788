/** @format */

import React, { useState } from 'react'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import IncomeAdjustments from '../Components/IncomeAdjustments'
import FormulaInput from '../../../../../Components/Formula/FormulaInput'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import AppraisalTab from './AppraisalTab'
import SecurityQualification from './SecurityQualification'
import LoanCaseHistory from './LoanCaseHistory'
import LoanCaseFixedDepositLiens from './LoanCaseFixedDepositLiens'
import LoanCaseCollaterals from './LoanCaseCollaterals'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import LoaningRemarksLookup from '../../../../../Components/Lookup/LoaningRemarksLookup'

function AppraisalPartial({
  approval,
  loanCase,
  onChangeIncomeAdjustments,
  income,
  onChangeIncome,
  isBusy,
  assessment,
  onChangeOffsetables,
  onChangeAmountAppraised,
  onChangeVoluntaryDeductibles,
  onChangeConsiderCheckOffDeductions,
  onChangeGuarantors,
  reAssessLoan,
  onAppraiseLoan,
}) {
  const [loanCaseAppraisalOption, setLoanCaseAppraisalOption] = useState(1)

  const [loanCaseAppraisalRemarks, setLoanCaseAppraisalRemarks] = useState('')

  const payoutsHistoryColumns = [
    {
      label: localize('batchNumber'),
      template: r => <>{r?.paddedCreditBatchBatchNumber}</>,
    },
    {
      label: localize('batchMonth'),
      template: r => <>{r?.creditBatchMonthDescription}</>,
    },
    {
      label: localize('batchReference'),
      template: r => <>{r?.creditBatchReference}</>,
    },
    {
      label: localize('batchStatus'),
      template: r => <>{r?.creditBatchStatusDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r?.productDescription}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r?.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r?.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r?.interest)}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const salaryHistoryColumns = [
    {
      label: localize('salaryPeriod'),
      template: r => <>{r?.salaryPeriodPostingPeriodDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r?.salaryPeriodMonthDescription}</>,
    },
    {
      label: localize('netPay'),
      template: r => <>{formatCurrency(r?.netPay)}</>,
    },
    {
      label: localize('salaryPeriodStatus'),
      template: r => <>{r?.salaryPeriodStatusDescription}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('incomeAdjustments'),
      icon: 'uil uil-edit',
      item: (
        <IncomeAdjustments
          loanCaseId={loanCase?.id}
          customerId={loanCase?.customerId}
          loanProductId={loanCase?.loanProductId}
          onChange={i => onChangeIncomeAdjustments(i)}
        />
      ),
    },
    {
      label: localize('payoutsHistory'),
      item: (
        <SimpleTable
          data={assessment?.creditBatchEntries || []}
          columns={payoutsHistoryColumns}
        />
      ),
    },
    {
      label: localize('salaryHistory'),
      item: (
        <SimpleTable
          data={assessment?.paySlips || []}
          columns={salaryHistoryColumns}
        />
      ),
    },
  ]

  const qualificationTabs = [
    {
      label: localize('incomeQualification'),
      icon: 'uil uil-edit',
      item: (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('latestIncome')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.loanProductLatestIncome,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('incomeAssessmentMode')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={assessment?.incomeAssessmentModeDescription}
                // style={{ textAlign: 'end' }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('basicIncome')}
              </label>
              <FormulaInput
                useBlur
                value={income}
                onChange={i => onChangeIncome(i)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('totalAllowances')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.totalIncomeAdditions,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('totalDeductions')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.totalIncomeDeductions,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('totalClearedEffects')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.totalClearedEffects,
                  2,
                  2,
                  false
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('totalIncome')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(assessment?.monthlyAbility, 2, 2, false)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('takeHomeValue')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.takeHomeRetention,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('abilityToPayPerPeriod')}
              </label>

              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.twoThirdsToRepayLoan,
                  2,
                  2,
                  false
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('abilityToPayOverLoanTerm')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.qualification?.totalLoanAmount,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('loanPart')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.qualification?.totalPrincipalPayment,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('interestPart')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                style={{ textAlign: 'end' }}
                value={formatCurrency(
                  assessment?.qualification?.totalInterestPayment,
                  2,
                  2,
                  false
                )}
              />
            </div>
          </div>
          <Tab
            preload
            tabItems={tabItems.slice(
              0,
              assessment?.customerIsAdministrative ? 3 : 2
            )}
          />
        </>
      ),
    },
    {
      label: localize('securityQualification'),
      icon: 'uil uil-edit',
      item: (
        <SecurityQualification
          loanCaseId={loanCase?.id}
          guarantorSecurityMode={
            loanCase?.loanRegistrationGuarantorSecurityMode
          }
          guarantorSecurityModeDescription={
            loanCase?.loanRegistrationGuarantorSecurityModeDescription
          }
          loanProductId={loanCase?.loanProductId}
          loaneeCustomerId={loanCase?.customerId}
          reAssessLoan={reAssessLoan}
          onChangeGuarantors={onChangeGuarantors}
        />
      ),
    },
    {
      label: localize('appraisal'),
      icon: 'uil uil-edit',
      item: (
        <AppraisalTab
          approval={approval}
          assessment={assessment}
          loanCase={loanCase}
          onChangeOffsetables={onChangeOffsetables}
          onChangeAmountAppraised={onChangeAmountAppraised}
          onChangeVoluntaryDeductibles={onChangeVoluntaryDeductibles}
          onChangeConsiderCheckOffDeductions={
            onChangeConsiderCheckOffDeductions
          }
          onAppraiseLoan={v => onAppraiseLoan(v)}
        />
      ),
    },
    {
      label: localize('loanCollaterals'),
      item: (
        <LoanCaseCollaterals
          collaterals={assessment?.appraisal?.loanCollaterals || []}
        />
      ),
    },
    {
      label: localize('fixedDepositLiens'),
      item: (
        <LoanCaseFixedDepositLiens
          fixedDepositLiens={assessment?.appraisal?.fixedDepositLiens || []}
        />
      ),
    },
    {
      label: localize('loanHistory'),
      item: (
        <LoanCaseHistory
          history={assessment?.appraisal?.loanCaseHistory || []}
        />
      ),
      icon: 'uil uil-calendar-alt',
    },
  ]

  return (
    <div>
      <Tab preload tabItems={qualificationTabs} />
      {approval || (
        <div className="mt-3 p-2 rounded border border-secondary bg-light d-flex align-items-center gap-2 justify-content-end">
          <label htmlFor="" className="text-capitalize">
            {localize(approval ? 'approvalAction' : 'appraisalAction')}
          </label>
          <div style={{ minWidth: 170 }}>
            <EnumsServerCombo
              value={loanCaseAppraisalOption}
              onChange={a => setLoanCaseAppraisalOption(a.value)}
              enumsKey={approval ? 'LoanApprovalOption' : 'LoanAppraisalOption'}
            />
          </div>
          {loanCaseAppraisalOption != 1 && (
            <div style={{ maxWidth: '40%' }}>
              <LoaningRemarksLookup
                displayValue={loanCaseAppraisalRemarks}
                onChange={x => setLoanCaseAppraisalRemarks(x)}
              />
            </div>
          )}
          <button
            className="btn btn-success px-5 ms-2"
            onClick={() =>
              onAppraiseLoan(
                loanCaseAppraisalOption,
                loanCaseAppraisalOption != 1 ? loanCaseAppraisalRemarks : ''
              )
            }
          >
            {localize('update')}
          </button>
        </div>
      )}
    </div>
  )
}

export default AppraisalPartial
