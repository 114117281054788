/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function MembershipClassLookup({ onSelect, displayValue, disabled }) {
  const columns = [
    {
      label: localize('code'),
      template: r => <>{r.paddedCode}</>,
      sortBy: 'code',
    },
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
    },
    {
      label: localize('numberingSeries'),
      template: r => <>{r.numberingSeriesDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerTypeDescription}</>,
    },
    {
      label: localize('terminationNoticePeriod'),
      template: r => <>{r.memberTerminationNoticePeriod}</>,
    },
    {
      label: localize('principalInvestmentProduct'),
      template: r => <>{r.principalInvestmentProductDescription}</>,
    },
    {
      label: localize('initialStatementStartDate'),
      template: r => <>{formatDate(r.initialStatementStartDate, true)}</>,
    },
    {
      label: localize('kycScoreThreshold'),
      template: r => <>{r.kycScoreThreshold}</>,
    },
    {
      label: localize('prefix'),
      template: r => <>{r.prefix}</>,
    },
    {
      label: localize('suffix'),
      template: r => <>{r.suffix}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        disabled={disabled}
        title={localize('membershipLookup')}
        url="/Lookup/MembershipClass/find-membership-class-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default MembershipClassLookup
