/** @format */

import React from 'react'
import localize from '../../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

const AccountRemark = ({ management, setManagement }) => {
  return (
    <div className="row g-3">
      <div className="col-md-12">
        <div className="d-flex align-items-center">
          <label className="col-1 text-capitalize">{localize('type')}</label>

          <EnumsServerCombo
            enumsKey="CustomerAccountRemarkType"
            sort
            customContainerStyle={{
              width: '100%',
            }}
            value={management.remarkType}
            onChange={({ value }) => {
              setManagement({
                ...management,
                remarkType: value,
              })
            }}
          />
        </div>
      </div>

      <div className="d-flex align-items-center col-md-12">
        <label className="col-1 text-capitalize">{localize('remarks')}</label>

        <textarea
          className="form-control"
          value={management.remark}
          onChange={({ target }) => {
            setManagement({
              ...management,
              remark: target.value,
            })
          }}
        />
      </div>
    </div>
  )
}

export default AccountRemark
