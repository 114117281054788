/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { putData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { loanTransferType } from '../../../../../Global/enumeration'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import CustomerLoanAccountPartial from '../../../../../Components/ReUsables/CustomerAccount/CustomerLoanAccountPartial'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

const IntraAccountLoanTransfer = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [iIntraAccountLoanTransfer, setIIntraAccountLoanTransfer] = useState({
    transferType: Object.keys(loanTransferType)[0],
  })

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedSourceLoanAccount, setSelectedSourceLoanAccount] =
    useState(null)
  const [selectedDestinationLoanAccount, setSelectedDestinationLoanAccount] =
    useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const handleOnSelectSourceLoanAccount = async account => {
    if (!account.isLocked) {
      if (account.customerAccountTypeProductCode !== '2') {
        return Swal.fire({
          icon: 'info',
          text: 'Select a Loan Account',
          title: 'Ooops!',
          showCloseButton: true,
        })
      } else {
        setSelectedSourceLoanAccount({
          ...selectedSourceLoanAccount,
          ...account,
        })
        setSelectedDestinationLoanAccount(null)
      }
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedSourceLoanAccount(null)
    }
  }
  const [lookupType, setLookupType] = useState('source')

  const handleOnSelectDestinationLoanAccount = async account => {
    if (!account.isLocked) {
      if (account.customerAccountTypeProductCode !== '2') {
        setSelectedDestinationLoanAccount(null)
        return Swal.fire({
          icon: 'info',
          text: 'Select a Loan Account',
          title: 'Ooops!',
          showCloseButton: true,
        })
      } else {
        setSelectedDestinationLoanAccount({
          ...selectedDestinationLoanAccount,
          ...account,
        })
      }
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Account is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedDestinationLoanAccount(null)
    }
  }

  async function updateIntraAccountLoanTransfer() {
    setIsBusy(true)
    const { success, data } = await putData(
      '/LoanDisbursementBatch/transfer-loan',
      {
        customerLoanAccount: selectedSourceLoanAccount,
        beneficiaryCustomerAccount: selectedDestinationLoanAccount,
        ...iIntraAccountLoanTransfer,
      },
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setIsBusy(false)
      setIIntraAccountLoanTransfer({
        transferType: Object.keys(loanTransferType)[0],
      })
      setSelectedSourceLoanAccount(null)
      setSelectedDestinationLoanAccount(null)
      setGlobalValidationErrors({})
    } else {
      setIsBusy(false)
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('backOfficeManagement'),
          localize('intraAccountLoanTransfer'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                {lookupType ? (
                  <CustomerLoanAccountPartial
                    selectedSourceLoanAccount={selectedSourceLoanAccount}
                    onCustomerAccountLookup={r => {
                      handleOnSelectSourceLoanAccount(r)
                      setSelectedCustomer(r)
                    }}
                    lookupType="source"
                  />
                ) : (
                  <CustomerLoanAccountPartial />
                )}

                <div className="mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('transferDetails(dest.)'),
                        item: (
                          <>
                            {lookupType ? (
                              <CustomerLoanAccountPartial
                                selectedDestinationLoanAccount={
                                  selectedDestinationLoanAccount
                                }
                                onCustomerAccountLookup={r => {
                                  handleOnSelectDestinationLoanAccount(r)
                                }}
                                selectedCustomer={selectedCustomer}
                                lookupType="destination"
                              />
                            ) : (
                              <CustomerLoanAccountPartial />
                            )}

                            <div className="row mb-2">
                              <div className="col-9 d-flex align-items-center mb-2">
                                <label className="col-1 text-capitalize">
                                  {localize('transferType')}
                                </label>
                                <EnumsServerCombo
                                  customContainerStyle={{ width: '100%' }}
                                  enumsKey="LoanTransferType"
                                  value={
                                    iIntraAccountLoanTransfer?.transferType
                                  }
                                  onChange={e => {
                                    setIIntraAccountLoanTransfer({
                                      ...iIntraAccountLoanTransfer,
                                      transferType: e.value,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col-3 d-flex align-items-center mb-2 ">
                                <label className="col-4 text-capitalize">
                                  {localize('excessAmount')}
                                </label>
                                <MaskNumber
                                  className="form-control ms-2"
                                  defaultMaskValue={
                                    iIntraAccountLoanTransfer?.excessAmount
                                  }
                                  onMaskNumber={e => {
                                    setIIntraAccountLoanTransfer({
                                      ...iIntraAccountLoanTransfer,
                                      excessAmount: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div
                                className="col-3 d-flex align-items-center with-validation"
                                validation-message={
                                  globalValidationErrors['PrincipalAmount']
                                    ?.message
                                }
                              >
                                <label className="col-3 text-capitalize me-1">
                                  {localize('principalAmount')}
                                </label>
                                <MaskNumber
                                  className="form-control ms-4"
                                  defaultMaskValue={
                                    iIntraAccountLoanTransfer?.principalAmount
                                  }
                                  onMaskNumber={e => {
                                    clearValidation('PrincipalAmount')
                                    setIIntraAccountLoanTransfer({
                                      ...iIntraAccountLoanTransfer,
                                      principalAmount: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                              <div className="col-3 d-flex align-items-center ">
                                <label className="col-4 text-capitalize">
                                  {localize('interestAmount')}
                                </label>
                                <MaskNumber
                                  className="form-control ms-2"
                                  defaultMaskValue={
                                    iIntraAccountLoanTransfer?.interestAmount
                                  }
                                  onMaskNumber={e => {
                                    setIIntraAccountLoanTransfer({
                                      ...iIntraAccountLoanTransfer,
                                      interestAmount: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                              <div
                                className="col-6 d-flex align-items-center with-validation"
                                validation-message={
                                  globalValidationErrors['Reference']?.message
                                }
                              >
                                <label className="col-2 text-capitalize">
                                  {localize('reference')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control ms-2"
                                  value={
                                    iIntraAccountLoanTransfer?.reference || ''
                                  }
                                  onChange={e => {
                                    clearValidation('Reference')
                                    setIIntraAccountLoanTransfer({
                                      ...iIntraAccountLoanTransfer,
                                      reference: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <button
                      onClick={() => {
                        if (!selectedSourceLoanAccount) {
                          return Swal.fire({
                            icon: 'info',
                            text: 'Select Customer Source Account First',
                            title: 'Ooops!',
                            showCloseButton: true,
                          })
                        } else if (!selectedDestinationLoanAccount) {
                          return Swal.fire({
                            icon: 'info',
                            text: 'Select Customer Destination Account First',
                            title: 'Ooops!',
                            showCloseButton: true,
                          })
                        } else {
                          updateIntraAccountLoanTransfer()
                        }
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default IntraAccountLoanTransfer
