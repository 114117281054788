/** @format */

import React from 'react'
import 'chart.js/auto'
import { Line } from 'react-chartjs-2'
import { COLORS } from '../../../Helpers/constants'

const LineChart = ({ label, labels = [], dataSet = [] }) => {
  const data = {
    labels,
    datasets: [
      {
        label,
        fill: true,
        lineTension: 0.5,
        backgroundColor: COLORS.LIGHT_BLUE,
        borderColor: COLORS.BLUE,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLORS.BLUE,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLORS.DEEP_BLUE,
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dataSet,
      },
    ],
  }
  var lineOpts = {
    scales: {
      yAxes: [
        {
          ticks: {
            max: 100,
            min: 20,
            stepSize: 10,
          },
        },
      ],
    },
  }

  return <Line width={474} height={300} data={data} options={lineOpts} />
}

export default LineChart
