/** @format */

import AppraisalSummary from '../../Pages/HumanResources/Operations/PerformanceManagement/Appraiser/AppraisalSummary/AppraisalSummary'
import Appraise from '../../Pages/HumanResources/Operations/PerformanceManagement/Appraiser/Appraise/Appraise'
import Recommendation from '../../Pages/HumanResources/Operations/PerformanceManagement/Appraiser/Recommendation/Recommendation'
import SelfAppraisal from '../../Pages/HumanResources/Operations/PerformanceManagement/SelfAppraisal'
import Authorization from '../../Pages/HumanResources/Operations/Salary/Authorization/Authorization'
import Cards from '../../Pages/HumanResources/Operations/Salary/Cards/Cards'
import Groups from '../../Pages/HumanResources/Operations/Salary/Groups/Groups'
import Heads from '../../Pages/HumanResources/Operations/Salary/Heads/Heads'
import Payslips from '../../Pages/HumanResources/Operations/Salary/Payslips/Payslips'
import Periods from '../../Pages/HumanResources/Operations/Salary/Periods/Periods'
import Processing from '../../Pages/HumanResources/Operations/Salary/Processing/Processing'
import Verification from '../../Pages/HumanResources/Operations/Salary/Verification/Verification'
import Documents from '../../Pages/HumanResources/Operations/Employees/Documents'
import Register from '../../Pages/HumanResources/Operations/Employees/Register'
import Referrals from '../../Pages/HumanResources/Operations/Employees/Referrals'
import DisciplinaryCases from '../../Pages/HumanResources/Operations/Employees/DisciplinaryCases'
import Departments from '../../Pages/HumanResources/Setup/Departments/Departments'
import Designations from '../../Pages/HumanResources/Setup/Designation/Designations'
import Holidays from '../../Pages/HumanResources/Setup/Holidays/Holidays'
import EmployeeTypes from '../../Pages/HumanResources/Setup/EmployeeTypes/EmployeeTypes'
import LeaveTypes from '../../Pages/HumanResources/Setup/LeaveTypes/LeaveTypes'
import PerformancePeriods from '../../Pages/HumanResources/Setup/PerformancePeriods/PerformancePeriods'
import PerformanceMeasures from '../../Pages/HumanResources/Setup/PerformanceMeasures/PerformanceMeasures'
import PerformancePillars from '../../Pages/HumanResources/Setup/PerformancePillars/PerformancePillars'
import PerformanceObjectives from '../../Pages/HumanResources/Setup/PerformanceObjectives/PerformanceObjectives'
import ExitInterviewQuestions from '../../Pages/HumanResources/Setup/ExitInterviewQuestions/ExitInterviewQuestions'
import TrainingFacilitators from '../../Pages/HumanResources/Setup/TrainingFacilitators/TrainingFacilitators'
import TrainingTypes from '../../Pages/HumanResources/Setup/TrainingTypes/TrainingTypes'
import TrainingPeriods from '../../Pages/HumanResources/Operations/Employees/TrainingPeriods'
import TemporaryEmployees from '../../Pages/HumanResources/Operations/Employees/TemporaryEmployees'

const humanResourceMenus = [
  {
    iconClass: 'uil uil-users-alt',
    label: 'humanResourceManagement',
    code: 2,
    setup: [
      {
        label: 'departments',
        component: <Departments />,
        code: 3001,
        parentCode: 2,
      },
      {
        label: 'designations',
        component: <Designations />,
        code: 3002,
        parentCode: 2,
      },
      {
        label: 'holidays',
        component: <Holidays menuCode={1016} />,
        code: 3003,
        parentCode: 2,
      },
      {
        label: 'employeeTypes',
        component: <EmployeeTypes />,
        code: 3004,
        parentCode: 2,
      },
      {
        label: 'leaveTypes',
        component: <LeaveTypes />,
        code: 3005,
        parentCode: 2,
      },
      {
        label: 'performancePillars',
        component: <PerformancePillars />,
        code: 3010,
        parentCode: 2,
      },
      {
        label: 'performanceObjectives',
        component: <PerformanceObjectives />,
        code: 3011,
        parentCode: 2,
      },
      {
        label: 'performancePeriods',
        component: <PerformancePeriods />,
        code: 3006,
        parentCode: 2,
      },
      {
        label: 'performanceMeasures',
        component: <PerformanceMeasures />,
        code: 3012,
        parentCode: 2,
      },
      {
        label: 'exitInterviewQuestions',
        component: <ExitInterviewQuestions />,
        code: 3007,
        parentCode: 2,
      },
      {
        label: 'trainingFacilitators',
        component: <TrainingFacilitators />,
        code: 3008,
        parentCode: 2,
      },
      {
        label: 'trainingTypes',
        component: <TrainingTypes />,
        code: 3009,
        parentCode: 2,
      },
    ],
    operations: [
      {
        label: 'employees',
        children: [
          {
            label: 'register',
            component: <Register />,
            code: 3501,
            parentCode: 2,
          },
          {
            label: 'documents',
            component: <Documents />,
            code: 3502,
            parentCode: 2,
          },
          {
            label: 'referrals',
            component: <Referrals />,
            code: 3537,
            parentCode: 2,
          },
          {
            label: 'disciplinaryCases',
            component: <DisciplinaryCases />,
            code: 3503,
            parentCode: 2,
          },
          {
            label: 'trainingPeriods',
            component: <TrainingPeriods />,
            code: 3536,
            parentCode: 2,
          },
          {
            label: 'events',
            component: <>Events</>,
            code: 3538,
            parentCode: 2,
          },
          {
            label: 'temporaryEmployees',
            component: <TemporaryEmployees />,
            code: 3539,
            parentCode: 2,
          },
        ],
      },
      {
        label: 'exit',
        children: [
          {
            label: 'exitInterviews',
            children: [
              {
                label: 'takeInterview',
                component: <>Take Interview</>,
                code: 1033,
                parentCode: 2,
              },
              {
                label: 'summary',
                component: <>Summary</>,
                code: 1034,
                parentCode: 2,
              },
            ],
          },
          {
            label: 'initiate',
            component: <>Initiate</>,
            code: 1035,
            parentCode: 2,
          },
          {
            label: 'verify',
            component: <>Verify</>,
            code: 1036,
            parentCode: 2,
          },
          {
            label: 'approve',
            component: <>Approve</>,
            code: 1037,
            parentCode: 2,
          },
        ],
      },
      {
        label: 'roster',
        children: [
          {
            label: 'regularDayProgram',
            component: <>Initiate</>,
            code: 1038,
            parentCode: 2,
          },
        ],
      },
      {
        label: 'attendance',
        children: [
          {
            label: 'register',
            component: <>Register</>,
            code: 1039,
            parentCode: 2,
          },
          {
            label: 'importData',
            component: <>Import Data</>,
            code: 1040,
            parentCode: 2,
          },
        ],
      },
      {
        label: 'leave',
        children: [
          {
            label: 'applications',
            component: <>Applications</>,
            code: 1041,
            parentCode: 2,
          },
          {
            label: 'approvals',
            component: <>Approvals</>,
            code: 1042,
            parentCode: 2,
          },
          {
            label: 'historyManagement',
            component: <>History Management</>,
            code: 1043,
            parentCode: 2,
          },
        ],
      },
      {
        label: 'salary',
        children: [
          {
            label: 'heads',
            component: <Heads />,
            code: 3527,
            parentCode: 3526,
          },
          {
            label: 'groups',
            component: <Groups />,
            code: 3528,
            parentCode: 3526,
          },
          {
            label: 'cards',
            component: <Cards />,
            code: 3529,
            parentCode: 3526,
          },
          {
            label: 'periods',
            component: <Periods />,
            code: 3530,
            parentCode: 3526,
          },
          {
            label: 'processing',
            component: <Processing />,
            code: 3531,
            parentCode: 3526,
          },
          {
            label: 'payslips',
            component: <Payslips />,
            code: 3532,
            parentCode: 3526,
          },
          {
            label: 'verification',
            component: <Verification />,
            code: 3534,
            parentCode: 3526,
          },
          {
            label: 'authorization',
            component: <Authorization />,
            code: 3533,
            parentCode: 3526,
          },
        ],
      },
      {
        label: 'performanceManagement',
        children: [
          {
            label: 'selfAppraisal',
            component: <SelfAppraisal />,
            code: 3505,
            parentCode: 3504,
          },
          {
            label: 'appraiser',
            children: [
              {
                label: 'appraise',
                component: <Appraise />,
                code: 3507,
                parentCode: 3504,
              },
              {
                label: 'recommendation',
                component: <Recommendation />,
                code: 3508,
                parentCode: 3504,
              },
              {
                label: 'appraisalSummary',
                component: <AppraisalSummary />,
                code: 3509,
                parentCode: 3504,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default humanResourceMenus
