/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import BranchLookup from '../../../../../../Components/Lookup/BranchLookup'
import LoanDisbursementModeLookup from '../../../../../../Components/Lookup/LoanDisbursementModeLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import {
  QueuePriority,
  loanProductCategory,
} from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { getData, postData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'

const CreateLoanDisbursementBatch = ({ mode, handleClose, setReload }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [batch, setBatch] = useState({
    loanProductCategory: Object.keys(loanProductCategory)[0],
    queuePriority: Object.keys(QueuePriority)[3],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const fetchCurrentUserBranch = async () => {
    setIsBusy(true)
    const url = '/AccessControl/find-current-user'

    const { success, data } = await getData(url, {}, false)

    if (success) {
      setIsBusy(false)
      setBatch({
        ...batch,
        branchId: data.employeeBranchId,
        branchDescription: data.employeeBranchDescription,
      })
    }

    setIsBusy(false)
  }

  const handleCreate = async () => {
    if (!batch.reference || !batch.loanDisbursementModeId) {
      return GlobalValidationErrors.set({
        Reference: !batch.reference && {
          message: 'Reference is required',
        },
        LoanDisbursementModeId: !batch.loanDisbursementModeId && {
          message: 'Loan Disbursement Mode is required',
        },
      })
    }
    setIsBusy(true)

    const url = '/LoanDisbursementBatch/add-loan-disbursement-batch'

    const { success } = await postData(
      url,
      {
        branchId: batch?.branchId,
        reference: batch?.reference,
        loanDisbursementModeId: batch?.loanDisbursementModeId,
        priority: batch?.queuePriority,
        loanProductCategory: batch?.loanProductCategory,
      },
      false
    )

    if (success) {
      setBatch({
        loanProductCategory: Object.keys(loanProductCategory)[0],
        queuePriority: Object.keys(QueuePriority)[3],
      })
      setReload(true)
      showNotification('Loan Batch Origination', 'success')
      handleClose()
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleClickCloseButton = () => {
    setBatch({
      loanProductCategory: Object.keys(loanProductCategory)[0],
      queuePriority: Object.keys(QueuePriority)[3],
    })
    handleClose()
  }

  useEffect(() => {
    fetchCurrentUserBranch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="createLoanDisbursementBatch"
      handleAction={handleCreate}
      handleClose={handleClickCloseButton}
      actionText="create"
      disableClose={isBusy}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('branch')}
                </label>
                <BranchLookup
                  displayValue={batch?.branchDescription}
                  onSelect={data => {
                    setBatch({
                      ...batch,
                      ...data,
                      branchId: data?.id,
                      branchDescription: data.description,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('category')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="loanProductCategory"
                  value={batch?.loanProductCategory}
                  onChange={e => {
                    setBatch({
                      ...batch,
                      loanProductCategory: e.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors?.['LoanDisbursementModeId']?.message
                }
              >
                <label className="text-capitalize col-4">
                  {localize('disbursementMode')}
                </label>
                <LoanDisbursementModeLookup
                  displayValue={batch?.modeDescription}
                  onSelect={data => {
                    clearValidation('LoanDisbursementModeId')
                    setBatch({
                      ...batch,
                      ...data,
                      modeDescription: data.description,
                      loanDisbursementModeId: data?.id,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label className="text-capitalize col-4">
                  {localize('priority')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="QueuePriority"
                  value={batch?.queuePriority}
                  onChange={e => {
                    setBatch({
                      ...batch,
                      queuePriority: e.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors?.['Reference']?.message
                }
              >
                <label className="text-capitalize col-2">
                  {localize('reference')}
                </label>
                <textarea
                  className="form-control"
                  value={batch?.reference}
                  onChange={e => {
                    clearValidation('Reference')
                    setBatch({
                      ...batch,
                      reference: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateLoanDisbursementBatch
