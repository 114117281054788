/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import ListingPage from '../../../../Components/ListingPage/Index'
import Loader from '../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import {
  customerMembershipStatus,
  messageGroupTarget,
} from '../../../../Global/enumeration'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import CustomTab from '../MessageGroups/CustomTab'
import EmployerTab from '../MessageGroups/EmployerTab'
import RolesTab from '../MessageGroups/RolesTab'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'

function SurveyGroups() {
  const [isBusy, setIsBusy] = useState(false)
  const [survey, setSurvey] = useState({
    target: Object.keys(messageGroupTarget)[1],
    membershipStatus: Object.keys(customerMembershipStatus)[0],
  })

  const [modalMode, setModalMode] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedTargetData, setSelectedTargetData] = useState([])
  const [refreshAt, setRefreshAt] = useState(false)

  const targetMessage =
    Object.entries(messageGroupTarget).find(m => m[0] === survey.target) || ''

  const columns = [
    {
      label: 'name',
      template: r => r?.description,
    },

    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
    {
      label: 'modifiedBy',
      template: r => r?.modifiedBy,
    },
    {
      label: 'modifiedDate',
      template: r => formatDate(r?.modifiedDate),
    },
  ]

  const handleClickAddButton = () => {
    setSurvey({
      target: Object.keys(messageGroupTarget)[1],
      membershipStatus: Object.keys(customerMembershipStatus)[0],
    })
    setModalMode('add')
  }

  const handleCancel = () => {
    setSurvey({
      target: Object.keys(messageGroupTarget)[1],
      membershipStatus: Object.keys(customerMembershipStatus)[0],
    })
    setModalMode(null)
    setGlobalValidationErrors({})
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleCreateOrUpdate = async () => {
    setIsBusy(true)
    const url =
      modalMode === 'add'
        ? '/SurveyGroup/add-survey-group-with-targets'
        : '/SurveyGroup/update-survey-group-with-targets'

    const { success } = await postData(
      url,
      {
        surveyGroupDTO: survey,
        surveyGroupTargets: selectedTargetData,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      handleCancel()
      setRefreshAt(!refreshAt)
      showNotification('Survey Groups', 'success')
    } else {
      setIsBusy(false)
    }
  }

  const fetchSelectedMessageGroupTargetData = async () => {
    setIsBusy(true)
    const url = '/SurveyGroup/find-survey-groups-targets'

    const { success, data } = await getData(
      url,
      {
        surveyGroupId: survey?.id,
      },
      false
    )

    if (success) {
      setSelectedTargetData(data.result)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchSelectedMessageGroupTargetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalMode])

  return (
    <>
      <ListingPage
        url="/SurveyGroup/find-survey-groups-filter-in-page"
        columns={columns}
        params={{
          text: '',
          pageIndex: 0,
          pageSize: 10,
        }}
        onAdd={handleClickAddButton}
        map={[
          localize('customerRelationshipManagement'),
          localize('surveyGroups'),
        ]}
        useText
        refreshAt={refreshAt}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                setSurvey(r)
                setModalMode('edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
          </>
        )}
      />
      {modalMode && (
        <ModalTemplate
          modalTitle="surveyGroups"
          modalMode={modalMode}
          modalClassName="modal-xl"
          handleClose={handleCancel}
          handleAction={handleCreateOrUpdate}
        >
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row g-3">
                <div className="col-md-12">
                  <div
                    className="d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['SurveyGroupDTO.Description']
                        ?.message
                    }
                  >
                    <label className="text-capitalize col-1" htmlFor="name">
                      {localize('name')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      defaultValue={survey.description || ''}
                      onChange={e => {
                        clearValidation('SurveyGroupDTO.Description')
                        setSurvey({
                          ...survey,
                          description: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-2" htmlFor="target">
                      {localize('target')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="MessageTarget"
                      onChange={e => {
                        setSurvey({
                          ...survey,
                          target: e.value,
                        })
                      }}
                      value={
                        survey.target ||
                        Object.keys(messageGroupTarget)[1] ||
                        ''
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize col-2"
                      htmlFor="membershipStatus"
                      style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {localize('membershipStatus')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="CustomerMembershipStatus"
                      onChange={e => {
                        setSurvey({
                          ...survey,
                          membershipStatus: e.value,
                        })
                      }}
                      value={
                        survey.membershipStatus ||
                        Object.keys(customerMembershipStatus)[0] ||
                        ''
                      }
                    />
                  </div>
                </div>

                <>
                  <Tab
                    tabItems={[
                      {
                        label: localize(
                          Object.values(targetMessage || messageGroupTarget)[1]
                            .name
                        ),
                        item: (
                          <>
                            {Object.values(targetMessage)[1]?.name ===
                            'Role' ? (
                              <div>
                                <RolesTab
                                  selectedRoleData={selectedTargetData}
                                  onSelectedRoleChange={(role, checked) => {
                                    if (checked) {
                                      setSelectedTargetData([
                                        ...selectedTargetData,
                                        role,
                                      ])
                                    } else {
                                      setSelectedTargetData(
                                        selectedTargetData.filter(
                                          x =>
                                            x.targetIdentifier !==
                                            role.description
                                        )
                                      )
                                    }
                                  }}
                                  onSelectAll={(roles, v) => {
                                    if (v) setSelectedTargetData(roles)
                                    else setSelectedTargetData([])
                                  }}
                                />
                              </div>
                            ) : Object.values(targetMessage)[1]?.name ===
                              'Employer' ? (
                              <div
                                style={{
                                  maxHeight: '300px',
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                                }}
                              >
                                <EmployerTab
                                  selectedEmployersData={selectedTargetData}
                                  onSelectedEmployersChange={(
                                    employee,
                                    checked
                                  ) => {
                                    if (checked) {
                                      setSelectedTargetData([
                                        ...selectedTargetData,
                                        employee,
                                      ])
                                    } else {
                                      setSelectedTargetData(
                                        selectedTargetData.filter(
                                          x =>
                                            x.targetIdentifier !== employee.id
                                        )
                                      )
                                    }
                                  }}
                                  onSelectAll={(emp, v) => {
                                    if (v) setSelectedTargetData(emp)
                                    else setSelectedTargetData([])
                                  }}
                                />
                              </div>
                            ) : (
                              Object.values(targetMessage)[1]?.name ===
                                'Custom' && (
                                <>
                                  <CustomTab
                                    onSelectAll={(cst, v) => {
                                      if (v) setSelectedTargetData(cst)
                                      else setSelectedTargetData([])
                                    }}
                                    onSelectedCustomerChange={(
                                      customer,
                                      checked
                                    ) => {
                                      if (checked) {
                                        setSelectedTargetData([
                                          ...selectedTargetData,
                                          customer,
                                        ])
                                      } else {
                                        setSelectedTargetData(
                                          selectedTargetData.filter(
                                            x =>
                                              x.targetIdentifier !== customer.id
                                          )
                                        )
                                      }
                                    }}
                                    selectedCustomData={selectedTargetData}
                                  />
                                </>
                              )
                            )}
                          </>
                        ),
                      },
                      {
                        label: localize('tips'),
                        item: <></>,
                      },
                    ]}
                  />
                </>
              </div>
            </>
          )}
        </ModalTemplate>
      )}
    </>
  )
}

export default SurveyGroups
