/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _k = {
  kycScoreThreshold: {
    en: 'kyc score threshold',
    sw: 'kizingiti cha alama cha kyc',
  },
  kYCInformation: {
    en: 'kyc information',
    sw: 'taarifa za kyc',
  },
  kYCInfor: {
    en: 'kyc infor',
    sw: 'taarifa za kyc',
  },
}
export default _k
