/** @format */

/* eslint-disable */
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'

function ActionModal({
  modalTitle,
  handleClose,
  handleAction,
  children,
  modalClassName = 'modal-md',
  confirmText,
  cancelText,
  showCloseButton,
  disableAction,
  hideCloseButton = false,
  hideActionButton = false,
  hideFooter = false,
  disableClose = false,
}) {
  return (
    <>
      <Modal isOpen backdrop="static" className={modalClassName} centered>
        <ModalHeader className="bg-white text-capitalize">
          <span className="text-muted fs-6">{localize(modalTitle)}</span>
          {showCloseButton && (
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </ModalHeader>
        <ModalBody className="bg-white">{children}</ModalBody>
        {hideFooter || (
          <ModalFooter className="bg-white">
            {hideCloseButton || (
              <button
                onClick={handleClose}
                className={'btn btn-outline-secondary'}
                disabled={disableClose}
              >
                {localize(cancelText || 'noCancel')}
              </button>
            )}

            {hideActionButton || (
              <button
                disabled={disableAction}
                className="btn btn-primary"
                onClick={handleAction}
              >
                {localize(confirmText || 'yesConfirm')}
              </button>
            )}
          </ModalFooter>
        )}
      </Modal>
    </>
  )
}

export default ActionModal
