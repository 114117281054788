/** @format */
import PostingPeriodLookup from '../../../../../Components/Lookup/PostingPeriodLookup'
import localize from '../../../../../Global/Localize'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import Swal from 'sweetalert2'
import BudgetHierarchyLookup from '../../../../../Components/Lookup/BudgetHierarchyLookup'
import { formatCurrency } from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

const CreateSupplements = ({
  handleClose,
  handleAction,
  mode,
  suppliment,
  setSuppliment,
}) => {
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText="create"
      modalTitle="budgetSupplements"
    >
      <div className="row g-3 mb-2 d-flex justify-content-between">
        <div className="col-md-6">
          <div className="d-flex align-items-center with-validation">
            <label htmlFor="sourceBudget" className="text-capitalize col-md-4">
              {localize('sourceBudget')}
            </label>
            <BudgetHierarchyLookup
              displayValue={suppliment?.sourceBudgetHierarchyDescription}
              onSelect={x => {
                setSuppliment({
                  ...suppliment,
                  sourceBudgetHierarchyId: x?.id,
                  sourceBudgetHierarchyDescription: x?.description,
                })
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label
              htmlFor="destinationBudget"
              className="text-capitalize col-md-4"
            >
              {localize('destinationBudget')}
            </label>
            <BudgetHierarchyLookup
              displayValue={suppliment?.destinationBudgetHierarchyDescription}
              onSelect={x => {
                setSuppliment({
                  ...suppliment,
                  destinationBudgetHierarchyId: x?.id,
                  destinationBudgetHierarchyDescription: x?.description,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-2 d-flex justify-content-between">
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="postingPeriod" className="text-capitalize col-md-4">
              {localize('postingPeriod')}
            </label>
            <PostingPeriodLookup
              displayValue={suppliment?.postingPeriodDescription}
              onSelect={r => {
                if (!r.isActive) {
                  return Swal.fire({
                    icon: 'error',
                    title: 'Operation not allowed',
                    text: 'Selected posting period is closed',
                  })
                } else {
                  setSuppliment({
                    ...suppliment,
                    postingPeriodId: r?.id,
                    postingPeriodDescription: r?.description,
                  })
                }
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="date" className="text-capitalize col-md-4">
              {localize('valueDate')}
            </label>
            <CustomDatePicker
              name="valuDate"
              onDateChange={date => {
                setSuppliment({
                  ...suppliment,
                  valueDate: date,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-2 d-flex justify-content-between">
        <div className="d-flex col-md-6">
          <label htmlFor="description" className="text-capitalize col-md-4">
            {localize('description')}
          </label>
          <input
            type="text"
            className="form-control "
            onChange={e => {
              setSuppliment({
                ...suppliment,
                description: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row g-3 mb-2 d-flex justify-content-between">
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label
              htmlFor="adjustmentType"
              className="text-capitalize col-md-4"
            >
              {localize('adjustmentType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BudgetAdjustmentType"
              value={suppliment?.budgetAdjustmentType}
              onChange={e =>
                setSuppliment({
                  ...suppliment,
                  budgetAdjustmentType: e.value,
                })
              }
              sort
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label htmlFor="amount" className="text-capitalize col-md-4">
              {localize('amount')}
            </label>
            <input
              type="number"
              className="form-control"
              value={formatCurrency(suppliment?.amount)}
              onChange={e =>
                setSuppliment({
                  ...suppliment,
                  amount: e.target.value,
                })
              }
            ></input>
          </div>
        </div>
      </div>
    </ModalTemplate>
  )
}

export default CreateSupplements
