/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import { getData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'

export const ViewCompanyFlags = ({ selectedCompany }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [flags, setFlags] = useState([])
  const fetchCompanyFlagsToBeSelected = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Company/find-company-flags-to-be-selected',
      {
        companyId: selectedCompany?.id,
      },
      false,
    )
    if (success) {
      setFlags(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (selectedCompany?.id) {
      fetchCompanyFlagsToBeSelected()
    }
  }, [selectedCompany?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div
            className="row mt-2"
            style={{ maxHeight: '300px', overflowY: 'auto' }}
          >
            {(flags || [])
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((t, i) => (
                <div
                  className="d-flex align-items-center col-3"
                  key={`${t.value}-${i}`}
                >
                  <Checkbox
                    id={`view-${t.value}`}
                    checked={t.checked}
                    disabled
                  />
                  <label
                    htmlFor={`view-${t.value}`}
                    className={`fw-normal ms-2 cursor-pointer ${
                      t.checked ? 'text-primary fw-bold' : ''
                    }`}
                  >
                    {t.label}
                  </label>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  )
}
