/** @format */

import { CreditTypes } from '../../../Selectables/Plain/CreditTypes'
import { useEffect, useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import Loader from '../../../Loaders/Loader'

export const CustomerMetadataCreditTypes = ({
  selectedCustomer,
  doFetchCreditTypes,
}) => {
  const [creditTypesToSelect, setCreditTypesToSelect] = useState([])
  const [loading, setLoading] = useState(false)
  const loadData = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        fetchCreditTypes: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null,
    )
    if (success) {
      setCreditTypesToSelect(data?.creditTypes || [])
    }
    setLoading(false)
  }
  useEffect(() => {
    if (doFetchCreditTypes) {
      loadData()
    }
  }, [doFetchCreditTypes])
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <CreditTypes
          prefix={'cred'}
          disabled
          creditTypesToSelect={creditTypesToSelect}
        />
      )}
    </>
  )
}
