/** @format */
import { useEffect, useState } from 'react'
import localize from '../../../../../../Global/Localize'
import { getData } from '../../../../../../Helpers/webApi'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'


export const MismatchedImportRefundBatchEntriesViewMode = ({ selectedBatchEntry }) => {
  const [prevSelectedBatchEntry, setPrevSelectedBatchEntry] =
    useState(selectedBatchEntry)

  const [discrepancies, setDiscrepancies] = useState([])

  const [reqParams, setReqParams] = useState({
    creditBatchId: selectedBatchEntry?.id,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('column1'), template: r => <>{r.column1}</> },
    { label: localize('column2'), template: r => <>{r.column2}</> },
    { label: localize('column3'), template: r => <>{r.column3}</> },
    { label: localize('column4'), template: r => <>{r.column4}</> },
    { label: localize('column5'), template: r => <>{r.column5}</> },
    { label: localize('column6'), template: r => <>{r.column6}</> },
    { label: localize('column7'), template: r => <>{r.column7}</> },
    { label: localize('column8'), template: r => <>{r.column8}</> },
    { label: localize('column9'), template: r => <>{r.column9}</> },
    { label: localize('column10'), template: r => <>{r.column10}</> },
    { label: localize('column11'), template: r => <>{r.column11}</> },
    { label: localize('column12'), template: r => <>{r.column12}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
  ]

  const fetchBatchEntries = async r => {
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batch-discrepancies-by-credit-batch-id-in-page',
      { ...reqParams, creditBatchId: r.id }
    )
    if (success) {
      setDiscrepancies(data)
    }
  }

  if (prevSelectedBatchEntry.id !== selectedBatchEntry.id) {
    setPrevSelectedBatchEntry(selectedBatchEntry)
    fetchBatchEntries(selectedBatchEntry)
  }

  useEffect(() => {
    fetchBatchEntries(selectedBatchEntry)
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      <SimpleTable columns={columns} data={discrepancies.pageCollection} />
    </>
  )
}
