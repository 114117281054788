/** @format */

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../../../Global/Localize'
import { ViewCustomer } from '../../../../Components/ReUsables/Customer/ViewCustomer'
import { AccountAlertDetails } from './AccountAlertDetails'
import { putData } from '../../../../Helpers/webApi'
import { useState } from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'

function AccountAlertsModal({
  mode,
  onHideModal,
  selectedCustomer,
  customerAccountAlerts,
  setNewAccountAlerts,
  onUpdateRefreshAt,
  onSetMode,
}) {
  const [updatedAccountAlerts, setUpdatedAccountAlerts] = useState([])

  async function handleSubmit() {
    const { success } = await putData(
      '/AccountAlert/update-account-alert',
      {
        CustomerId: selectedCustomer.id,
        AccountAlerts: updatedAccountAlerts,
      },
      true
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }

  return (
    <>
      {mode && (
        <ModalTemplate
          modalMode={mode}
          modalTitle={localize('accountAlerts')}
          handleAction={handleSubmit}
          handleClose={onHideModal}
          modalClassName={'modal-xl'}
        >
          <ViewCustomer customer={selectedCustomer} />
          <AccountAlertDetails
            onAddNewAlert={r => {
              setUpdatedAccountAlerts(r)
            }}
            currentAccountAlerts={customerAccountAlerts}
            setNewAccountAlerts={setNewAccountAlerts}
            selectedCustomer={selectedCustomer}
          />
        </ModalTemplate>
      )}
    </>
  )
}

export default AccountAlertsModal
