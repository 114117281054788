/** @format */

import React, { useState, useEffect } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../../Global/Localize'

const SelectableBranches = ({
  disabled,
  isBusy,
  selectedBranches,
  prefix,
  onItemsSelection,
}) => {
  const [toggleBranchesSelection, setToggleBranchesSelection] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  const handleCheckBranch = (branch, value) => {
    if (value) setSelectedItems([...selectedItems, branch])
    else setSelectedItems(selectedItems.filter(item => item.id !== branch.id))
  }

  const onCheckBranches = checkStatus => {
    setToggleBranchesSelection(checkStatus)
    if (checkStatus) setSelectedItems(selectedBranches?.branches)
    else setSelectedItems([])
  }

  useEffect(() => {
    onItemsSelection(selectedItems)

    //eslint-disable-next-line
  }, [selectedItems])

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {selectedBranches?.branches?.map(branches => (
              <div
                key={branches.id}
                className="d-flex align-items-center gap-2 justify-content-start"
              >
                <Checkbox
                  id={`${prefix ? prefix : 'com'}-${branches.id}`}
                  checked={selectedItems?.find(item => item.id === branches.id)}
                  disabled={disabled}
                  margined
                  onCheck={c => {
                    handleCheckBranch(branches, c)
                  }}
                />
                <label
                  htmlFor={`${prefix ? prefix : 'com'}-${branches.id}`}
                  className={`fw-normal ms-2 cursor-pointer ${
                    branches.checked ? 'text-primary fw-bold' : ''
                  }`}
                >
                  {branches.description}
                </label>
              </div>
            ))}
          </div>
          <div className="row mb-3 g-3 mt-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <Checkbox
                  id="toggle-branches-selection"
                  checked={toggleBranchesSelection}
                  onCheck={v => {
                    onCheckBranches(v)
                  }}
                />
                <label
                  htmlFor="toggle-branches-selection"
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')} ?
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SelectableBranches
