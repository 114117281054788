/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListingPage from '../../../../Components/ListingPage/Index'
import localize from '../../../../Global/Localize'
import { salutation } from '../../../../Global/enumeration'
import { formatDate } from '../../../../Helpers/formatters'
import CreateTechnician from './CreateTechnician/CreateTechnician'

function Technicians() {
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [technician, setTechnician] = useState({
    salutation: Object.keys(salutation)[0],
  })

  const columns = [
    {
      label: 'name',
      template: r => r?.fullName,
    },
    {
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  const handleClose = () => {
    setModalMode(null)
    setTechnician({
      salutation: Object.keys(salutation)[0],
    })
  }

  const handleClickAddButton = () => {
    setModalMode('add')
    setTechnician({
      salutation: Object.keys(salutation)[0],
    })
  }

  return (
    <>
      <ListingPage
        url="/MorgueTechnician/find-morgue-technician-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        map={[localize('morgueManagement'), localize('technicians')]}
        onAdd={handleClickAddButton}
        useText
        refreshAt={refresh}
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item text-capitalize"
              onClick={() => {
                setTechnician(r)
                setModalMode('edit')
              }}
            >
              <i className="uil uil-edit-alt"></i>
              <span>{localize('edit')}</span>
            </div>
          </>
        )}
      />
      {modalMode && (
        <CreateTechnician
          handleClose={handleClose}
          mode={modalMode}
          refresh={refresh}
          setRefresh={setRefresh}
          setTechnician={setTechnician}
          technician={technician}
        />
      )}
    </>
  )
}

export default Technicians
