/** @format */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../Components/Date/DatePicker'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import Pager from '../../../../../Components/Tables/Pager'
import { getData } from '../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import ViewRecuringProcedureModal from './ViewRecuringProcedureModal'

const RecurringProcedures = () => {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, false)}</>,
    },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
    },
    {
      label: localize('type'),
      template: r => <>{r.typeDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{r.valueDate}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    filterText: '',
    recurringBatchFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    dateRequestsFilter: 15,
    customDate: false,
  })

  const [groupBy, setGroupBy] = useState({
    label: 'Type',
    value: 0,
    column: 'typeDescription',
  })
  const [selectedRecuringProcedure, setSelectedRecuringProcedure] =
    useState(null)
  const [mode, setMode] = useState(null)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/RecurringBatch/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setIsBusy(false)
    }
  }
  const groupingColumns = [
    {
      value: 0,
      column: 'typeDescription',
    },
    {
      value: 1,
      column: 'priorityDescription',
    },
    {
      value: 2,
      column: 'monthDescription',
    },
  ]
  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('recurringProcedures'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2 mx-0">
                  <div className="d-flex align-items-center col-2">
                    <label
                      htmlFor="recordsPerPage"
                      className=""
                      style={{ marginLeft: -10, textTransform: 'capitalize' }}
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      name=""
                      id="recordsPerPage"
                      className="form-select ms-1"
                      style={{ maxWidth: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>

                  <div className="col-3 d-flex align-items-center">
                    <label className="col-3  text-capitalize">
                      {localize('groupBy')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        width: 150,
                        minWidth: 150,
                      }}
                      value={groupBy?.value}
                      enumsKey="RecurringBatchPropertyGroup"
                      onChange={({ label, value }) =>
                        setGroupBy({
                          label,
                          value,
                          column: groupingColumns[value]?.column,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex align-items-center  me-4">
                      <label
                        className="text-capitalize col-4"
                        htmlFor="searchByDate"
                      >
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        value={{
                          startDate: reqParams?.startDate,
                          endDate: reqParams?.endDate,
                        }}
                        onSelect={v => {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <form
                    className="d-flex align-items-center col-4 col"
                    onSubmit={e => {
                      e.preventDefault()
                      setReqParams({
                        ...reqParams,
                        text: search,
                      })
                    }}
                  >
                    <label
                      className="ms-2 me-1 text-capitalize"
                      htmlFor="searchTable"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      value={reqParams?.recurringBatchFilter}
                      enumsKey={'RecurringBatchFilter'}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          recurringBatchFilter: e.value,
                        })
                      }}
                    />
                    <input
                      type="search"
                      id="searchTable"
                      className="form-control ms-2"
                      defaultValue={reqParams.filterText}
                      onChange={e => {
                        setSearch(e.target.value)
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="payrollNumberFilter Button"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </form>
                </div>
                <GroupedTable
                  groupBy={groupBy}
                  columns={columns}
                  data={data?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        onClick={async () => {
                          setMode('view')
                          setSelectedRecuringProcedure(r)
                        }}
                        className="ctxt-menu-item"
                      >
                        <i className="uil uil-eye"></i>
                        {localize('view')}
                      </div>
                    </>
                  )}
                />
                <div className="mb-2"></div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
      {mode === 'view' ? (
        <ViewRecuringProcedureModal
          mode={mode}
          selectedRecuringProcedure={selectedRecuringProcedure}
          closeModal={() => {
            setMode(null)
            setSelectedRecuringProcedure(null)
          }}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default RecurringProcedures
