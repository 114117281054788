/** @format */

import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import Loader from '../../../Components/Loaders/Loader'
import ViewAuditLogs from '../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../Components/Tables/Pager'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import localize from '../../../Global/Localize'
import { showNotification } from '../../../Helpers/Notification/ShowNotification'
import { reportTemplateCategory } from '../../../Helpers/constants'
import { formatDate } from '../../../Helpers/formatters'
import { getData, postData, putData } from '../../../Helpers/webApi'
import CreateReport from './CreateReport/CreateReport'
import ShowReport from './ShowReport'

const Reports = () => {
  const [reports, setReports] = useState([])
  const [selectedReport, setSelectedReport] = useState(null)
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyCreating, setIsBusyCreating] = useState(false)
  const [cReport, setCReport] = useState(null)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
    filterText: '',
  })

  const columns = [
    {
      label: '',
      template: r => (
        <a
          href={localize('#')}
          onClick={() => setCReport(r)}
          className="text-capitalize"
        >
          {localize('view')}
        </a>
      ),
    },
    {
      label: 'reportName',
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r.indentedReportName }}></span>
      ),
    },
    {
      label: 'category',
      template: r => r?.categoryDescription,
    },
    {
      label: 'reportPath',
      template: r => r?.reportPath,
    },
    {
      label: 'reportQuery',
      template: r => r?.reportQuery,
    },
    {
      label: 'sSRSHost',
      template: r => r?.reportHost,
    },
    {
      label: 'sSRSPort',
      template: r => r?.reportPort,
    },
    {
      label: 'depth',
      template: r => r?.depth,
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  const handleSelectReport = (r, m) => {
    setSelectedReport(r)
    setMode(m)
  }

  const fetchReports = async () => {
    setIsBusy(true)
    const url = '/Report/find-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setReports(data)
    }

    setIsBusy(false)
  }

  const handleClickAddButton = () => {
    handleSelectReport(null, 'add')
    setSelectedReport({
      category: reportTemplateCategory.HEADER_ENTRY,
    })
  }

  const handleClose = () => {
    setSelectedReport(null)
    setMode(null)
  }

  const handleCreate = async () => {
    setIsBusyCreating(true)
    const url = '/Report/create'

    const { success } = await postData(url, selectedReport, false)

    if (success) {
      showNotification('Reports', 'success')
      handleClose()
      fetchReports()
    }

    setIsBusyCreating(false)
  }

  const handleUpdate = async () => {
    setIsBusyCreating(true)
    const url = '/Report/update'

    const { success } = await putData(url, selectedReport, false)

    if (success) {
      showNotification('Reports', 'success')
      handleClose()
      fetchReports()
    }

    setIsBusyCreating(false)
  }

  useEffect(() => {
    fetchReports()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'view-audit-log' && (
        <ViewAuditLogs
          mode={mode}
          handleClose={handleClose}
          data={selectedReport}
          title={selectedReport?.reportName}
        />
      )}

      {(mode === 'add' || mode === 'edit') && (
        <CreateReport
          handleClose={handleClose}
          mode={mode}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          handleAction={mode === 'add' ? handleCreate : handleUpdate}
          isBusy={isBusyCreating}
        />
      )}

      <BreadCrumb
        tree={[localize('SystemAdministration'), localize('Reports')]}
      />

      <div className="card rounded bg-white">
        <div className="card-body">
          <div className="mb-3">
            <AddNewButton handleClickAddButton={handleClickAddButton} />
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items justify-content-between mb-3">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="d-flex align-items-center">
                  <Checkbox
                    checked={reqParams.traverseTree}
                    id="reports-traverse-tree"
                    onCheck={v =>
                      setReqParams({
                        ...reqParams,
                        traverseTree: v,
                        updateDepth: v,
                      })
                    }
                  />
                  <label
                    htmlFor="reports-traverse-tree"
                    className="text-capitalize"
                  >
                    {localize('traverseTree')}
                  </label>
                </div>
                <div className={`d-flex align-items-center gap-2`}>
                  <label className="text-capitalize me-2">
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams.filterText}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        filterText: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchReports()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => fetchReports()}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>

              <SimpleTable
                data={reports?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        setCReport(r)
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('view')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectReport(r, 'edit')
                      }}
                    >
                      <i className="uil uil-edit"></i>
                      <span>{localize('edit')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectReport(r, 'view-audit-log')
                      }}
                    >
                      <i className="uil uil-server"></i>
                      <span>{localize('viewAuditLog')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={reports?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>

      {cReport && (
        <ShowReport report={cReport} closeReport={() => setCReport(null)} />
      )}
    </>
  )
}

export default Reports
