/** @format */

import { useContext, useEffect } from 'react'
import { NavigationContext } from '../Contexts/NavigationContext'
import TabbedWorkspace from './Workspace/TabbedWorkspace'

function PageContent() {
  useEffect(() => {
    const unloadCallback = event => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)

    // eslint-disable-next-line
  }, [])
  const { navigatedComponent } = useContext(NavigationContext)
  return (
    <div className="page-content">
      <div className="container-fluid">
        <TabbedWorkspace windows={navigatedComponent} />
      </div>
    </div>
  )
}

export default PageContent
