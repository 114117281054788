/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'
import localize from '../../Global/Localize'
import DatePicker from '../Date/DatePicker'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import Loader from '../Loaders/Loader'

function PettyCashPaymentLookup({ displayValue, onSelect }) {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
    status: 2,
    text: '',
    pageIndex: 0,
    pageSize: 10,
    filterByAuthorizedBranches: true,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('VoucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    {
      label: localize('pettyCashier'),
      template: r => <>{r.pettyCashierDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDestributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('payee'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('narration'),
      template: r => <>{r.secondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    {
      label: localize('paidBy'),
      template: r => <>{r.paidBy}</>,
    },
    {
      label: localize('paidDate'),
      template: r => <>{formatDate(r.paidDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const url =
      '/PettyCashRequestLookup/find-petty-cash-requests-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
    }
    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: 'all',
          opacity: 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('monthlyBalanceSpoolLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize"
                      htmlFor="lookupRecordsPerPage"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="lookupRecordsPerPage"
                      className="form-select ms-3"
                      style={{ width: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>

                  <div className="col-4 d-flex align-item-center">
                    <label htmlFor="search" className="me-1 text-capitalize">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams?.startDate,
                        endDate: reqParams?.endDate,
                      }}
                      onSelect={v => {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center float-end my-2">
                    <label className="text-capitalize" htmlFor="filterButton">
                      {localize('search')}
                    </label>

                    <input
                      type="search"
                      id="search"
                      className="form-control col ms-2"
                      value={reqParams.text}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                        })
                      }}
                    />
                    <button
                      id="filterButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                    onSort={(c, i) => handleSort(c, i)}
                    sortedBy={sortedBy}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default PettyCashPaymentLookup
