/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'
import { MaskNumber } from '../../InputMasks/MaskNumber'
import CustomerAccountByCustomerIdLookup from '../../Lookup/CustomerAccountByCustomerIdLookup'
import CustomerAccountLookup from '../../Lookup/CustomerAccountLookup'
function CustomerAccountPartial({
  onCustomerAccountLookup,
  selectedAccount,
  selectedCustomer,
  productCode,
  pageScreen,
}) {
  return (
    <>
      <div className="row mb-2">
        {selectedCustomer ? (
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <CustomerAccountByCustomerIdLookup
              displayValue={selectedAccount?.fullAccountNumber || ''}
              type="text"
              className="form-control ms-2"
              onSelect={v => {
                onCustomerAccountLookup(v)
              }}
              selectedCustomer={selectedCustomer}
              productCode={productCode}
            />
          </div>
        ) : (
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <CustomerAccountLookup
              displayValue={selectedAccount?.fullAccountNumber || ''}
              type="text"
              className="form-control ms-2"
              onSelect={v => {
                onCustomerAccountLookup(v)
              }}
              productCode={productCode}
            />
          </div>
        )}
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountStatus')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={selectedAccount?.statusDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountRemarks')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={selectedAccount?.remarks || ''}
          />
        </div>
        {pageScreen === 'fd' ? (
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize me-2">
              {localize('availableBalance')}
            </label>
            <MaskNumber
              className="form-control"
              defaultMaskValue={selectedAccount?.availableBalance}
              disabled
            />
          </div>
        ) : (
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('productName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedAccount?.customerAccountTypeTargetProductDescription ||
                ''
              }
            />
          </div>
        )}
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerName')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedAccount?.customerFullName || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={
              selectedAccount?.customerMembershipClassCustomerTypeDescription ||
              ''
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('customerSerial#')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={selectedAccount?.paddedCustomerSerialNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('employerName')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={selectedAccount?.customerEmployerDescription || ''}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('identityCardNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selectedAccount?.customerIdentificationNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={selectedAccount?.formattedCustomerMemberNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={selectedAccount?.formattedCustomerAccountNumber || ''}
          />
        </div>
        <div className="col-3 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('personalFileNumber')}
          </label>
          <input
            type="text"
            className="form-control ms-2"
            disabled
            value={selectedAccount?.customerPersonalFileNumber || ''}
          />
        </div>
      </div>
      {pageScreen === 'fd' ? (
        <></>
      ) : (
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-md-4 text-capitalize">
              {localize('bookBalance')}
            </label>
            <MaskNumber
              className="form-control"
              defaultMaskValue={selectedAccount?.bookBalance}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-md-4 text-capitalize">
              {localize('principalBalance')}
            </label>
            <MaskNumber
              className="form-control ms-2"
              defaultMaskValue={selectedAccount?.principalBalance}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-md-4 text-capitalize">
              {localize('interestBalance')}
            </label>
            <MaskNumber
              className="form-control ms-2"
              defaultMaskValue={selectedAccount?.interestBalance}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-md-4 text-capitalize">
              {localize('penaltyBalance')}
            </label>
            <MaskNumber
              className="form-control ms-2"
              defaultMaskValue={selectedAccount?.penaltyBalance}
              disabled
            />
          </div>
        </div>
      )}
    </>
  )
}
export default CustomerAccountPartial
