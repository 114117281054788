/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../Global/Localize'

const ExportButton = ({ handleExportButton, icon, label }) => {
  return (
    <button
      onClick={handleExportButton}
      className="btn btn-primary waves-effect waves-light text-capitalize"
    >
      <i className={`${icon ?? 'uil uil-export'} me-2`}></i>
      {localize(label ?? 'export')}
    </button>
  )
}

export default ExportButton
