/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../Global/Localize'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Pager from '../../../../Components/Tables/Pager'
import { getData } from '../../../../Helpers/webApi'
import DatePicker from '../../../../Components/Date/DatePicker'
import AddEmolumentsPaymentSchedules from './partials/AddEmolumentsPaymentSchedules'
import ViewEmolumentsPaymentSchedules from './partials/ViewEmolumentsPaymentSchedules'
import { formatDate } from '../../../../Helpers/formatters'
import EditEmolumentsPaymentSchedules from './partials/EditEmolumentsPaymentSchedules'
import VerifyEmolumentsPaymentSchedules from './partials/VerifyEmolumentsPaymentSchedules'
import AuthorizeEmolumentsPaymentSchedules from './partials/AuthorizeEmolumentsPaymentSchedules'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'

const EmolumentsAndAllowancesPaymentSchedules = () => {
  const columns = [
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('batchNumber'),
      template: r => r.paddedBatchNumber,
    },
    {
      label: localize('postingPeriod'),
      template: r => r.postingPeriodDescription,
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('reference'),
      template: r => r.reference,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('emolumentType'),
      template: r => r.emolumentTypeDescription,
    },
    {
      label: localize('verification/rejectionRemarks'),
      template: r => r.auditRemarks,
    },
    {
      label: localize('verified/rejectedBy'),
      template: r => r.auditedBy,
    },
    {
      label: localize('verified/rejectedDate'),
      template: r => formatDate(r.auditedDate),
    },
    {
      label: localize('authorization/rejectionRemarks'),
      template: r => r.authorizationRemarks,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => r.authorizedBy,
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => formatDate(r.authorizedDate),
    },
    {
      label: localize('priority'),
      template: r => r.priorityDescription,
    },

    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 1,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    text: '',
    pageIndex: 0,
    pageSize: 10,
    filterByAuthorizedBranches: false, //TODOalex-09282023-review wcf logic when this is true
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [
    selectedEmolumentsPaymentSchedules,
    setSelectedEmolumentsPaymentSchedules,
  ] = useState(null)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setLoading(true)
    const { success, data } = await getData(
      '/EmolumentBatch/find-emolument-batches-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setLoading(false)
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedEmolumentsPaymentSchedules(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('emolumentsAndAllowances'),
          localize('emolumentsPaymentSchedules'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton handleClickAddButton={handleAdd} />
                  </div>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="row">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-3"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-3"
                          htmlFor="searchBystatus"
                        >
                          {localize('status')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="BatchStatus"
                          value={reqParams?.status}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              status: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-3"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-4 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label
                        className="col-2 ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="EmolumentsPaymentSchedulesTerminationFilterButton"
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('view')
                              setSelectedEmolumentsPaymentSchedules(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={async () => {
                              if (r.status !== '1' && r?.status !== '16') {
                                showNotification(
                                  'Emolument Payment Schedule',
                                  'info',
                                  'sorry, but you must first select a batch whose status is pending/deferred'
                                )
                              } else {
                                setMode('edit')
                                setSelectedEmolumentsPaymentSchedules(r)
                              }
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            onClick={async () => {
                              if (r.status !== '1') {
                                showNotification(
                                  'Emolument Payment Schedule',
                                  'info',
                                  'sorry, but you must first select a batch whose status is pending'
                                )
                              } else {
                                setMode('verify')
                                setSelectedEmolumentsPaymentSchedules(r)
                              }
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-check"></i>
                            {localize('verify')}
                          </div>
                          <div
                            onClick={async () => {
                              if (r.status !== '8') {
                                showNotification(
                                  'Emolument Payment Schedule',
                                  'info',
                                  'sorry, but you must first select a batch whose status is audited'
                                )
                              } else {
                                setMode('authorize')
                                setSelectedEmolumentsPaymentSchedules(r)
                              }
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-unlock-alt"></i>
                            {localize('authorize')}
                          </div>
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode === 'add' ? (
        <AddEmolumentsPaymentSchedules
          mode={mode}
          setMode={setMode}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      ) : (
        ''
      )}
      {mode === 'edit' ? (
        <EditEmolumentsPaymentSchedules
          selectedEmolumentsPaymentSchedule={selectedEmolumentsPaymentSchedules}
          setSelectedEmolumentsPaymentSchedule={
            setSelectedEmolumentsPaymentSchedules
          }
          mode={mode}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      ) : (
        ''
      )}
      {mode === 'verify' ? (
        <VerifyEmolumentsPaymentSchedules
          selectedEmolumentsPaymentSchedule={selectedEmolumentsPaymentSchedules}
          mode={mode}
          close={() => setMode(null)}
          loadTableData={loadTableData}
          setSelectedEmolumentsPaymentSchedule={
            setSelectedEmolumentsPaymentSchedules
          }
        />
      ) : (
        ''
      )}
      {mode === 'authorize' ? (
        <AuthorizeEmolumentsPaymentSchedules
          selectedEmolumentsPaymentSchedule={selectedEmolumentsPaymentSchedules}
          mode={mode}
          close={() => setMode(null)}
          loadTableData={loadTableData}
          setSelectedEmolumentsPaymentSchedule={
            setSelectedEmolumentsPaymentSchedules
          }
        />
      ) : (
        ''
      )}
      {mode === 'view' ? (
        <ViewEmolumentsPaymentSchedules
          selectedEmolumentsPaymentSchedule={selectedEmolumentsPaymentSchedules}
          mode={mode}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default EmolumentsAndAllowancesPaymentSchedules
