/** @format */

import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { ViewCustomer } from '../../../../../Components/ReUsables/Customer/ViewCustomer'
import { FingerDigit } from '../../../../../Global/enumeration'
import localize from '../../../../../Global/Localize'

export const EditFingerPrintBiometricRegister = ({
  selectedCustomer,
  editFingerPrintModel,
  setEditFingerPrintModel,
  defaultPreferredDigit,
  setDefaultPreferredDigit,
}) => {
  return (
    <>
      <ViewCustomer customer={selectedCustomer?.customer} />
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('preferredFingerDigit')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="FingerDigit"
            value={defaultPreferredDigit}
            onChange={e => {
              setEditFingerPrintModel({
                ...editFingerPrintModel,
                preferredFingerDigit: e.value,
              })
              setDefaultPreferredDigit(e.value)
            }}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('remarks')}</label>
          <input
            defaultValue={editFingerPrintModel?.remarks}
            className="form-control"
            onChange={e => {
              setEditFingerPrintModel({
                ...editFingerPrintModel,
                remarks: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
