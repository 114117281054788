/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../../Components/Tables/SimpleTable'

export const NextOfKinHistory = ({ nextOfKinHistory }) => {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('vitalStatusDescription'),
      template: r => <>{r.vitalStatusDescription}</>,
    },
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    { label: localize('birthDate'), template: r => <>{r.birthDate}</> },
    {
      label: localize('relationshipDescription'),
      template: r => <>{r.relationshipDescription}</>,
    },
    {
      label: localize('genderDescription'),
      template: r => <>{r.genderDescription}</>,
    },
    {
      label: localize('identityCardTypeDescription'),
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.identityCardSerialNumber}</>,
    },
    {
      label: localize('nominatedPercentage'),
      template: r => <>{r.nominatedPercentage}</>,
    },
    {
      label: localize('vitalStatusDate'),
      template: r => <>{r.vitalStatusDate}</>,
    },
    {
      label: localize('addressAddressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressAddressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: localize('addressStreet'), template: r => <>{r.addressStreet}</> },
    {
      label: localize('AddressPostalCode'),
      template: r => <>{r.AddressPostalCode}</>,
    },
    { label: localize('AddressCity'), template: r => <>{r.AddressCity}</> },
    { label: localize('addressEmail'), template: r => <>{r.addressEmail}</> },
    {
      label: localize('addressLandLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('addressMobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('addressInternet'),
      template: r => <>{r.addressInternet}</>,
    },
    { label: localize('isNextOfKin'), template: r => <>{r.isNextOfKin}</> },
    { label: localize('isNominee'), template: r => <>{r.isNominee}</> },
    {
      label: localize('isBenevolentSpouse'),
      template: r => <>{r.isBenevolentSpouse}</>,
    },
    {
      label: localize('isBenevolentChild'),
      template: r => <>{r.isBenevolentChild}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
    {
      label: localize('environmentUserName'),
      template: r => <>{r.environmentUserName}</>,
    },
    {
      label: localize('environmentMachineName'),
      template: r => <>{r.environmentMachineName}</>,
    },
    {
      label: localize('environmentDomainName'),
      template: r => <>{r.environmentDomainName}</>,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => <>{r.environmentOSVersion}</>,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => <>{r.environmentMACAddress}</>,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => <>{r.environmentMotherboardSerialNumber}</>,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => <>{r.environmentProcessorId}</>,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => <>{r.environmentIPAddress}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
  ]

  return <SimpleTable columns={columns} data={nextOfKinHistory} />
}
