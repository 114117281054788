/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'

function OriginIdentifierLookup({ disabled }) {
  return (
    <SimpleLookup
      title={localize('originIdentifier')}
      url="/lookup/PayrollNumber/find-payroll-number-types-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[]}
      disabled={disabled}
    />
  )
}

export default OriginIdentifierLookup
