/** @format */
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ContextMenuContainer from '../../../../../Components/ContextMenu/Container'

export const CatalogueTracker = ({
  destinations,
  onGetFileMovementHistory,
}) => {
  const [selectedRow, setSelectedRow] = useState(null)
  const [ctxtMenuPosition, setCtxtMenuPosition] = useState([0, 0])
  const [showCtxtMenu, setShowCtxtMenu] = useState(false)
  const [ctxParentId, setCtxParentId] = useState('x')

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('customerType'),
      template: r => <>{r.customerTypeDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('reference1'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('reference2'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: localize('reference3'),
      template: r => <>{r.customerReference3}</>,
    },
    { label: localize('fullName'), template: r => <>{r.customerFullName}</> },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.customerIndividualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('groupName'),
      template: r => <>{r.customerNonIndividualDescription}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r.customerNonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('personalIdentificationNumber'),
      template: r => <>{r.customerPersonalIdentificationNumber}</>,
    },
    {
      label: localize('dateEstablished'),
      template: r => <>{r.customerNonIndividualDateEstablished}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.customerAddressCity}</> },
    { label: localize('email'), template: r => <>{r.customerAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    {
      label: localize('dutyWorkStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('employer'),
      template: r => <>{r.customerEmployerDescription}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  function showContextMenu(e, row, parentId) {
    e.preventDefault()
    setCtxParentId(parentId)
    setShowCtxtMenu(true)
    setSelectedRow(row)
  }

  useEffect(() => {
    window.addEventListener('contextmenu', setPosition)
    window.addEventListener('click', hideCTXTMenu)

    return () => {
      window.removeEventListener('contextmenu', setPosition)
      window.removeEventListener('click', hideCTXTMenu)
    }
  }, [ctxParentId])

  function setPosition(e) {
    setCtxtMenuPosition([e.x, e.y])
  }

  function hideCTXTMenu(e) {
    e.stopPropagation()
    if (showContextMenu) {
      setShowCtxtMenu(false)
    }
  }

  return (
    <>
      {' '}
      <div style={{ margin: 0, padding: 0, overflowX: 'auto' }}>
        <table
          className="table cent-table"
          id="centrinotable"
          style={{ minWidth: '100%' }}
        >
          <tr
            style={{
              position: 'sticky',
              top: 0,
              background: 'white',
            }}
            className=""
          >
            {columns.map((c, i) => (
              <th key={i} className={`text-primary py-2 px-3`}>
                <span>{localize(c.label)}</span>
              </th>
            ))}
          </tr>
          {Array.isArray(destinations.pageCollection) &&
            destinations.pageCollection.map(r => (
              <>
                <tr
                  onClick={() => {
                    setSelectedRow(r)
                    onGetFileMovementHistory(r)
                  }}
                  key={r.id}
                  id={`row-${r.id}`}
                  className={`data-row ${
                    selectedRow?.id === r?.id ? 'selected' : ''
                  }`}
                  onContextMenu={e => showContextMenu(e, r, `row-${r.id}`)}
                >
                  {columns.map((col, i) => (
                    <td className="py-2 px-3" key={i}>
                      {col.template(r)}
                    </td>
                  ))}
                </tr>
              </>
            ))}
        </table>
      </div>
      {showCtxtMenu && (
        <ContextMenuContainer
          position={ctxtMenuPosition}
          hide={() => setShowCtxtMenu(false)}
        >
          <>
            <div
              onClick={() => {
                onGetFileMovementHistory(selectedRow)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-eye"></i>
              {localize('view')}
            </div>
          </>
        </ContextMenuContainer>
      )}
    </>
  )
}
