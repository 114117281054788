/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../Components/Loaders/Loader'
import SavingsProductLookup from '../../../../Components/Lookup/SavingsProductLookup'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { alternateChannelType } from '../../../../Global/enumeration'
import { generateGuid } from '../../../../Helpers/extensions'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'

const initialAltChannel = {
  autoLock: false,
  effectCharges: false,
  requireActivation: false,
  dailyLimit: 0,
  validityPeriod: 0,
  alternateChannelType: Object.keys(alternateChannelType)[0],
}

function AlternateChannels({ disabled, membershipClassId, onChange }) {
  const [alternateChannels, setAlternateChannels] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [selectedAltChannel, setSelectedAltChannel] =
    useState(initialAltChannel)
  const [editMode, setEditMode] = useState(false)

  async function loadAlternateChannels() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/membershipClass/find-membership-class-alternate-channels-by-membership-class-id',
      { membershipClassId },
      false
    )
    if (success) {
      setAlternateChannels(data)
    }

    setIsBusy(false)
  }

  function updateAltChannel() {
    let altIndex = [...alternateChannels?.result].findIndex(
      ac => ac.id === selectedAltChannel.id
    )

    if (altIndex > -1) {
      let cl = [...alternateChannels?.result]
      cl[altIndex] = selectedAltChannel
      setSelectedAltChannel(initialAltChannel)
      setEditMode(false)
      setAlternateChannels({ result: cl })
    } else {
      Swal.fire('Error', 'Alternate Channel Not Found', 'error')
    }
  }

  function addAltChannel() {
    setAlternateChannels({
      result: [
        ...(alternateChannels?.result || []),
        { id: generateGuid(), ...selectedAltChannel },
      ],
    })
    setSelectedAltChannel(initialAltChannel)
  }

  useEffect(() => {
    if (!disabled) onChange(alternateChannels?.result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternateChannels])

  const columns = [
    {
      label: localize('channelType'),
      template: r => <>{r?.alternateChannelTypeDescription}</>,
    },
    {
      label: `${localize('validityPeriod')} (${localize('days')})`,
      template: r => <>{r?.validityPeriod}</>,
    },
    {
      label: localize('dailyLimit'),
      template: r => <>{formatCurrency(r?.dailyLimit)}</>,
    },
    {
      label: localize('autoLock') + '?',
      template: r => (
        <span className="text-capitalize">{r?.autoLock.toString()}</span>
      ),
    },
    {
      label: localize('requireActivation') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.requireActivation.toString()}
        </span>
      ),
    },
    {
      label: localize('effectCharges') + '?',
      template: r => (
        <span className="text-capitalize">{r?.effectCharges.toString()}</span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    loadAlternateChannels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipClassId])

  return (
    <div className="">
      {isBusy ? (
        <Loader />
      ) : disabled ? (
        <SimpleTable columns={columns} data={alternateChannels?.result} />
      ) : (
        <>
          <div className="row p-2">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-2">
                {localize('alternateChannelType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="AlternateChannelType"
                value={selectedAltChannel?.alternateChannelType}
                onChange={e =>
                  setSelectedAltChannel({
                    ...selectedAltChannel,
                    alternateChannelType: e.value,
                    alternateChannelTypeDescription:
                      alternateChannelType[e.value].name,
                  })
                }
              />
            </div>
          </div>
          <div className="row px-2 my-2">
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4 me-1">
                {localize('validityPeriod')} ({localize('days')})
              </label>
              <MaskNumber
                defaultMaskValue={selectedAltChannel?.validityPeriod}
                onMaskNumber={e =>
                  setSelectedAltChannel({
                    ...selectedAltChannel,
                    validityPeriod: e.target.value,
                  })
                }
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('dailyLimit')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedAltChannel?.dailyLimit}
                onMaskNumber={e =>
                  setSelectedAltChannel({
                    ...selectedAltChannel,
                    dailyLimit: stripNonNumericCharacters(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="bg-light mx-2 p-2 d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center mx-3">
              <Checkbox
                id="editMCACAutolock"
                onCheck={v =>
                  setSelectedAltChannel({ ...selectedAltChannel, autoLock: v })
                }
                checked={selectedAltChannel?.autoLock}
              />
              <label
                className="ms-2 text-capitalize"
                htmlFor="editMCACAutolock"
              >
                {localize('autoLock')}?
              </label>
            </div>
            <div className="d-flex align-items-center mx-3">
              <Checkbox
                id="editMCACReqActivation"
                onCheck={v =>
                  setSelectedAltChannel({
                    ...selectedAltChannel,
                    requireActivation: v,
                  })
                }
                checked={selectedAltChannel?.requireActivation}
              />
              <label
                className="ms-2 text-capitalize"
                htmlFor="editMCACReqActivation"
              >
                {localize('requireActivation')}?
              </label>
            </div>
            <div className="d-flex align-items-center mx-3">
              <Checkbox
                id="editMCACEffectCharges"
                onCheck={v =>
                  setSelectedAltChannel({
                    ...selectedAltChannel,
                    effectCharges: v,
                  })
                }
                checked={selectedAltChannel?.effectCharges}
              />
              <label
                className="ms-2 text-capitalize"
                htmlFor="editMCACEffectCharges"
              >
                {localize('effectCharges')}?
              </label>
            </div>
            {editMode ? (
              <>
                <button
                  className="btn btn-outline-danger me-2 px-4"
                  onClick={() => {
                    setSelectedAltChannel(initialAltChannel)
                    setEditMode(false)
                  }}
                >
                  {localize('cancel')}
                </button>
                <button
                  className="btn btn-success px-4"
                  onClick={updateAltChannel}
                >
                  {localize('update')}
                </button>
              </>
            ) : (
              <button className="btn btn-success px-5" onClick={addAltChannel}>
                {localize('add')}
              </button>
            )}
          </div>

          <div
            className="p-2 mt-2"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 5,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('alternateChannels')}
            </div>
            <SimpleTable
              columns={columns}
              data={alternateChannels?.result}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item"
                    onClick={() => {
                      setSelectedAltChannel(r)
                      setEditMode(true)
                    }}
                  >
                    <i className="uil uil-edit-alt"></i>
                    <span>{localize('edit')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-danger"
                    onClick={() => {
                      let rss = alternateChannels?.result
                      let ind = rss.findIndex(item => item.id === r?.id)
                      rss.splice(ind, 1)
                      setAlternateChannels({
                        ...alternateChannels,
                        result: [...rss],
                      })
                    }}
                  >
                    <i className="uil uil-times-circle"></i>
                    <span>{localize('remove')}</span>
                  </div>
                </>
              )}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default AlternateChannels
