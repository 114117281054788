/** @format */
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { CarryFowardsStatement } from './CarryFowardsStatement'
import Tab from '../../../../../../Components/Tabs/Tab'
import { useEffect, useState } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import { stripNonNumericCharacters } from '../../../../../../Helpers/formatters'

export const CarryFowardsAdjustmentManagement = ({
  commissionCharges,
  commissionChargesKvP,
  selectedStandingOrder,
  selectedDynamicCharge,
}) => {
  const [kvp, setKvp] = useState(null)
  const [customerCarryFowardEntries, setCustomerCarryFowardEntries] = useState(
    []
  )

  const [
    selectedBeneficiaryChartOfAccount,
    setSelectedBeneficiaryChartOfAccount,
  ] = useState(null)
  const [adjustCarryFowardModel, setAdjustCarryFowardModel] = useState(null)

  const getCustomerAccountCarryFowards = async r => {
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-account-carry-forwards-by-beneficiary-customer-account-id-and-chart-of-account-id',
      {
        beneficiaryCustomerAccountId:
          selectedStandingOrder?.beneficiaryCustomerAccountId,
        beneficiaryChartOfAccountId: r,
      }
    )
    if (success) {
      setCustomerCarryFowardEntries(data.result)
    }
  }

  useEffect(() => {
    if (commissionCharges && commissionCharges.length !== 0) {
      getCustomerAccountCarryFowards(commissionCharges[0].chartOfAccountId)
      setSelectedBeneficiaryChartOfAccount(
        commissionCharges[0].chartOfAccountId
      )
    }
  }, [commissionChargesKvP])

  const addCustomerAccountCarryFoward = async () => {
    const { success, data } = await postData(
      '/CustomerAccount/create-customer-account-carry-forward',
      {
        ...adjustCarryFowardModel,
        beneficiaryCustomerAccountId:
          selectedStandingOrder?.beneficiaryCustomerAccountId,
        benefactorCustomerAccountId:
          selectedStandingOrder?.benefactorCustomerAccountId,
        beneficiaryChartOfAccountId: selectedBeneficiaryChartOfAccount,
      },
      false,
      'Customer Account Carry Forward'
    )
    if (success) {
      setAdjustCarryFowardModel(null)
      getCustomerAccountCarryFowards(selectedBeneficiaryChartOfAccount)
    }
  }

  return (
    <>
      <div className="row mb-3 mt-3" style={{ marginTop: -10 }}>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize me-3 col-4">
            {localize('beneficiaryChartOfAccountName')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="CommissionChargesKvP"
            onChange={e => {
              setKvp(e.value)
              setSelectedBeneficiaryChartOfAccount(
                commissionChargesKvP[e.value].chartOfAccountId
              )
              getCustomerAccountCarryFowards(
                commissionChargesKvP[e.value].chartOfAccountId
              )
            }}
            value={kvp}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize me-3 col-4">
            {localize('adjustmentType')}
          </label>
          <EnumsServerCombo
            value={adjustCarryFowardModel?.adjustmentType || ''}
            customContainerStyle={{ width: '100%' }}
            enumsKey={'CarryForwardAdjustmentType'}
            onChange={e => {
              setAdjustCarryFowardModel({
                ...adjustCarryFowardModel,
                adjustmentType: e.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: -10 }}>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize me-3 col-4">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            defaultValue={adjustCarryFowardModel?.reference || ''}
            onChange={e => {
              setAdjustCarryFowardModel({
                ...adjustCarryFowardModel,
                reference: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize me-3 col-4">
            {localize('amount')}
          </label>
          <MaskNumber
            defaultMaskValue={adjustCarryFowardModel?.amount || 0}
            onMaskNumber={e => {
              setAdjustCarryFowardModel({
                ...adjustCarryFowardModel,
                amount: stripNonNumericCharacters(e.target.value),
              })
            }}
          />
        </div>
      </div>
      <div
        className="bg-light d-flex justify-content-end align-items-center p-2 mt-3 mb-3"
        style={{ borderRadius: 5 }}
      >
        <button
          onClick={addCustomerAccountCarryFoward}
          className="btn btn-success text-uppercase ms-3 px-4"
        >
          {localize('update')}
        </button>
      </div>

      <Tab
        preload
        tabItems={[
          {
            label: localize('carryFowardsStatement'),
            item: (
              <CarryFowardsStatement
                customerCarryFowardEntries={customerCarryFowardEntries}
                kvp={kvp}
                selectedKvp={commissionChargesKvP}
                setKvp={setKvp}
              />
            ),
          },
        ]}
      />
    </>
  )
}
