/** @format */
/* eslint-disable */
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import DatePicker from '../../Date/DatePicker'
import {
  batchStatus,
  CreditBatchType,
  JournalVoucherEntryStatus,
} from '../../../Global/enumeration'
import SimpleTable from '../../Tables/SimpleTable'
import Pager from '../../Tables/Pager'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../Helpers/webApi'
import { FiltersContainer } from '../../Tables/FiltersContainer'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import Loader from '../../Loaders/Loader'
export const ListCreditBatches = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    status: Object.keys(batchStatus)[0],
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    type: Object.keys(CreditBatchType)[0],
  })

  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [sortedBy, setSortedBy] = useState(null)

  const [tableData, setTableData] = useState([])
  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    { label: localize('batchType'), template: r => <>{r.typeDescription}</> },
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    { label: localize('month'), template: r => <>{r.monthDescription}</> },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.valueDate)}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    { label: localize('totalValue'), template: r => <>{r.totalValue}</> },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
    },
    {
      label: localize('creditType'),
      template: r => <>{r.creditTypeDescription}</>,
    },
    {
      label: localize('payrollNumber'),
      template: r => <>{r.creditTypePayrollNumberTypeDescription}</>,
    },
    {
      label: localize('revenueTax'),
      template: r => <>{r.creditTypeRevenueTaxDescription}</>,
    },
    {
      label: localize('revenueTaxRate'),
      template: r => <>{r.creditTypeRevenueTaxTaxRate}</>,
    },
    {
      label: localize('concessionType'),
      template: r => <>{r.concessionTypeDescription}</>,
    },
    {
      label: localize('concessionPercentage'),
      template: r => <>{r.concessionPercentage}</>,
    },
    {
      label: localize('concessionFixedAmount'),
      template: r => <>{r.concessionFixedAmount}</>,
    },
    {
      label: localize('checkOffCutOffDate'),
      template: r => <>{formatDate(r.checkOffCutOffDate)}</>,
    },
    {
      label: localize('enforceCheckoffCutOffDate'),
      template: r => (
        <span className="text-capitalize">
          {r.enforceCheckoffCutOffDate.toString()}
        </span>
      ),
    },
    {
      label: localize('preserveAccountBalance'),
      template: r => (
        <span className="text-capitalize">
          {r.preserveAccountBalance.toString()}
        </span>
      ),
    },
    {
      label: localize('fuzzyMatching'),
      template: r => (
        <span className="text-capitalize">{r.fuzzyMatching.toString()}</span>
      ),
    },
    { label: localize('auditRemarks'), template: r => <>{r.auditRemarks}</> },
    { label: localize('auditedBy'), template: r => <>{r.auditedBy}</> },
    {
      label: localize('auditedDate'),
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: localize('authorizationRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    { label: localize('authorizedBy'), template: r => <>{r.authorizedBy}</> },
    {
      label: localize('authorizedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    {
      label: localize('priorityDescription'),
      template: r => <>{r.priorityDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CreditBatch/find-credit-batches-by-status-and-type-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadTableData()
  }, [])

  useEffect(() => {
    loadTableData()
  }, [
    reqParams.pageSize,
    reqParams.pageIndex,
    reqParams.dateRequestsFilter,
    reqParams.customDate,
    reqParams.type,
  ])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <FiltersContainer>
            <div className="d-flex align-items-center">
              <label className="text-capitalize me-3">{localize('type')}</label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CreditBatchType"
                value={reqParams?.type}
                onChange={e => {
                  setReqParams({ ...reqParams, type: e.value })
                }}
              />
            </div>

            <div className="d-flex align-items-center">
              <label className="text-capitalize me-3">
                {localize('pickDate')}
              </label>
              <DatePicker
                onSelect={v => {
                  if (v.custom) {
                    setReqParams({
                      ...reqParams,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                    })
                  } else {
                    setReqParams({
                      ...reqParams,
                      customDate: false,
                      dateRequestsFilter: v.value,
                    })
                  }
                }}
              />
            </div>

            <div className="d-flex align-items-center">
              <label className="text-capitalize me-3">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="JournalVoucherEntryStatus"
                value={reqParams?.status}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }}
              />
            </div>

            <div className="d-flex align-items-center">
              <div className="input-group">
                <input
                  defaultValue={reqParams.filterText}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }
                  className="form-control me-2"
                  placeholder="Enter text..."
                  type="text"
                />
                <button
                  id="customLookupFilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    loadTableData()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </FiltersContainer>

          <div className="row col-12 mt-2">
            <SimpleTable
              data={tableData}
              columns={columns}
              onSort={(c, i) => handleSort(c, i)}
              sortedBy={sortedBy}
            />
          </div>
          <div className="row col-12 mt-2">
            <Pager
              {...tableMeta}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </div>
        </>
      )}
    </>
  )
}
