/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'

function WireTransferTypeLookup({ displayValue, onSelect }) {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r.chartOfAccountName}</>,
      sortBy: 'chartOfAccountName',
    },
    {
      label: localize('chartOfAccountCostDistributionRule'),
      template: r => <>{r.chartOfAccountCostDistributionRuleDescription}</>,
      sortBy: 'chartOfAccountCostDistributionRuleDescription',
    },
    {
      label: localize('transactionOwnership'),
      template: r => <>{r.transactionOwnershipDescription}</>,
      sortBy: 'transactionOwnershipDescription',
    },
    {
      label: localize('isDefault'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.isDefault.toString())}
        </span>
      ),
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">
          {localize(r.isLocked.toString())}
        </span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const reqParams = {
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  }

  return (
    <SimpleLookup
      title={localize('wireTransferTypeLookup')}
      url={'/lookup/WireTranferType/find-by-filter-in-page'}
      params={reqParams}
      columns={columns}
      displayValue={displayValue}
      onSelect={onSelect}
    />
  )
}

export default WireTransferTypeLookup
