/** @format */

import React, { useState } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import swal from 'sweetalert2'
import { postData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

export const EditMemberDetails = ({
  microCreditGroupMember,
  setMicroCreditGroupMember,
  microCreditGroupMemberEntries,
  selectedMicroCreditGroup,
  onAddNew,
}) => {
  const [loading, setLoading] = useState(false)
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const handleOnSelectCustomer = r => {
    if (
      !r.isLocked &&
      r.recordStatus === '2' &&
      r.membershipClassCustomerType === '0'
    ) {
      setMicroCreditGroupMember({
        ...microCreditGroupMember,
        selectedCustomer: { ...r },
      })
      clearValidation('SelectedCustomer.Id')
    } else {
      swal.fire(
        'Create Micro Credit Group',
        'Selected Customer must be of type micro credit and record status must be approved',
        'error',
      )
    }
  }

  const handleOnAddGroupMember = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/MicroCreditGroup/construct-micro-credit-group-member-information-edit-mode',
      {
        SelectedCustomer: microCreditGroupMember?.selectedCustomer || {},
        MicroCreditGroupMemberDesignation:
          microCreditGroupMember?.microCreditGroupMemberDesignation,
        MicroCreditGroupMembers: microCreditGroupMemberEntries,
        MicroCreditGroupId: selectedMicroCreditGroup?.id,
      },
      false,
      null,
    )
    if (success) {
      await swal.fire('Add MicroCredit Group Member', 'Added Successfully', 'success')
      onAddNew()
    }
    setLoading(false)
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('memberDetails')}
        </div>
        <div className="row mb-3" style={{ marginTop: -10 }}>
          <CustomerPartial
            message={validationBag?.['SelectedCustomer.Id']?.message}
            customer={microCreditGroupMember?.selectedCustomer}
            onCustomerLookup={handleOnSelectCustomer}
          />
        </div>
        <div className="row mb-3" style={{ marginTop: -10 }}>
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('designation')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="MicroCreditGroupMemberDesignation"
              value={microCreditGroupMember?.microCreditGroupMemberDesignation}
              onChange={e => {
                setMicroCreditGroupMember({
                  ...microCreditGroupMember,
                  microCreditGroupMemberDesignation: e.value,
                })
              }}
              sort
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('loanCycle')}
            </label>
            <input
              type="number"
              className="form-control"
              value={microCreditGroupMember?.loanCycle || ''}
              onChange={e => {
                setMicroCreditGroupMember({
                  ...microCreditGroupMember,
                  loanCycle: e.target.value,
                })
              }}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('remarks')}
            </label>
            <input
              className="form-control"
              value={microCreditGroupMember?.remarks || ''}
              onChange={e => {
                setMicroCreditGroupMember({
                  ...microCreditGroupMember,
                  remarks: e.target.value,
                })
              }}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div
            className="bg-light d-flex justify-content-end align-items-center p-2"
            style={{ borderRadius: 5 }}
          >
            <button
              onClick={handleOnAddGroupMember}
              className="btn btn-success text-uppercase ms-3 px-4"
            >
              {localize('add')}
            </button>
          </div>
        )}
      </div>
    </>
  )
}
