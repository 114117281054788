/** @format */

import React, { useState } from 'react'
import localize from '../../../../../Global/Localize'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { verificationOption } from '../../../../../Global/enumeration'
import { postData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
const VerifyFuneralRiderClaimPayable = ({
  mode,
  close,
  selectedFuneralRiderClaimPayable,
  setSelectedFuneralRiderClaimPayable,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(verificationOption)[0]
  )
  const [loading, setLoading] = useState(false)

  async function handleSubmit() {
    setLoading(true)
    const { success } = await postData(
      '/FuneralRiderClaim/audit-funeral-rider-claim-payable',
      {
        funeralRiderClaimPayable: {
          ...selectedFuneralRiderClaimPayable,
          id: selectedFuneralRiderClaimPayable?.id,
        },
        verificationOption: selectedAuth,
      },
      false
    )

    if (success) {
      showNotification(
        'Funeral Rider Claim Payables',
        'success',
        'Operation completed successfully!'
      )
      setSelectedFuneralRiderClaimPayable(null)
      loadTableData()
      close()
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="funeralRiderClaimPayable"
        handleClose={close}
        modalClassName="modal-xl"
        handleAction={handleSubmit}
        footerChildren={
          <>
            <div className="col-md-3 mt-3">
              <div
                className="d-flex align-items-center mb-1 with-validation"
                validation-message={
                  globalValidationErrors[
                    'FuneralRiderClaimPayable.AuditRemarks'
                  ]?.message
                }
              >
                <label className="text-capitalize col-2 me-2">
                  {localize('remarks')}
                </label>
                <input
                  defaultValue={selectedFuneralRiderClaimPayable?.auditRemarks}
                  type="text"
                  className="form-control ms-4"
                  onChange={e => {
                    clearValidation('FuneralRiderClaimPayable.AuditRemarks')
                    setSelectedFuneralRiderClaimPayable({
                      ...selectedFuneralRiderClaimPayable,
                      auditRemarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-1 mt-3">
              <div className="d-flex align-items-center mb-2">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="VerificationOption"
                  value={selectedAuth}
                  onChange={e => setSelectedAuth(e.value)}
                />
              </div>
            </div>
          </>
        }
      >
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-2">
                <div className="col d-flex align-items-center mb-2  ">
                  <label className="col-3 text-capitalize">
                    {localize('funeralRiderClaim')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={selectedFuneralRiderClaimPayable?.customerFullName}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center mb-2">
                  <label className="col-2 me-5 text-capitalize">
                    {localize('amount')}
                  </label>
                  <MaskNumber
                    className="form-control me-3 ms-3"
                    defaultMaskValue={selectedFuneralRiderClaimPayable?.amount}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('memberAccountNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedFuneralRiderClaimPayable?.funeralRiderClaimCustomerReference1 ||
                      ''
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('claimantName')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantName ||
                      ''
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('memberMobileNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantMobileNumber ||
                      ''
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('claimantNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantMobileNumber ||
                      ''
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('memberIdentityNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedFuneralRiderClaimPayable?.funeralRiderClaimCustomerIndividualIdentityCardNumber ||
                      ''
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('claimantIdentityNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedFuneralRiderClaimPayable?.funeralRiderClaimFuneralRiderClaimantIdentityCardNumber ||
                      ''
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-1 text-capitalize me-5">
                    {localize('remarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control ms-3 me-3"
                    value={selectedFuneralRiderClaimPayable?.remarks || ''}
                    disabled
                  />
                </div>
              </div>
            </>
          )}
        </>
      </ModalTemplate>
    </>
  )
}
export default VerifyFuneralRiderClaimPayable
