/** @format */

import React from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import GroupedTable from '../../../../../../Components/Tables/GroupedTable'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'

const ProductsListing = ({ products, onSelect, isBusyLoadingProducts }) => {
  const groupBy = {
    label: 'productType',
    column: 'vehicleFleetProductProductTypeDescription',
  }

  const columns = [
    {
      label: localize('productName'),
      template: r => <>{r?.vehicleFleetProductDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('contribution'),
      template: r => <>{r?.contribution}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  return isBusyLoadingProducts ? (
    <Loader />
  ) : (
    <GroupedTable
      groupBy={groupBy}
      columns={columns}
      data={products}
      onClickOnce={onSelect}
      canClickOnce
    />
  )
}

export default ProductsListing
