/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { getData, postData } from '../../../../../Helpers/webApi'
import { PleaseWait } from '../../../../../Components/Loaders/PleaseWait'
import { generateGuid } from '../../../../../Helpers/extensions'

function CreateEmail({ close }) {
  const [messageGroups, setMessageGroups] = useState([])
  const [selectedMessageGroups, setSelectedMessageGroups] = useState([])
  const [isBusyM, setIsBusyM] = useState(false)
  const [isBusyC, setIsBusyC] = useState(false)
  const [email, setEmail] = useState(null)

  const htmlId = generateGuid()

  useEffect(() => {
    findMessageGroups()
  }, [])

  async function createEmail() {
    setIsBusyC(true)
    const { success, data } = await postData(
      '/bulkMessageBatch/add-email-alerts-with-history',
      {
        ...email,
        recipients: selectedMessageGroups.map(m => m.id).join(','),
      },
      false
    )
    if (success) {
      console.log('email payload', data)
    }
    setIsBusyC(false)
  }

  async function findMessageGroups() {
    setIsBusyM(true)
    const { success, data } = await getData(
      '/lookup/messageGroup/find-message-groups',
      {},
      false
    )
    if (success) setMessageGroups(data?.result)
    setIsBusyM(false)
  }

  return (
    <ModalTemplate
      modalTitle={'Group E-Mail Message'}
      modalMode={'create'}
      handleClose={close}
      actionText={localize('create')}
      handleAction={createEmail}
      modalClassName="modal-lg"
    >
      <div className="d-flex align-items-center mt-2">
        <label htmlFor="" className="text-capitalize col-2">
          {localize('subject')}
        </label>
        <input
          type="text"
          className="form-control ms-2"
          value={email?.mailMessageSubject}
          onChange={e =>
            setEmail({ ...email, mailMessageSubject: e.target.value })
          }
        />
      </div>
      <div className="d-flex  mt-2">
        <label htmlFor="" className="text-capitalize col-2">
          {localize('message')}
        </label>
        <textarea
          rows={10}
          type="text"
          className="form-control ms-2"
          value={email?.mailMessageBody}
          onChange={e =>
            setEmail({ ...email, mailMessageBody: e.target.value })
          }
        />
      </div>
      <div className="d-flex  mt-2">
        <label htmlFor="" className="text-capitalize col-2">
          {localize('recipients')}
        </label>
        {isBusyM ? (
          <PleaseWait />
        ) : (
          <div
            type="text"
            className="form-control ms-2"
            style={{ maxHeight: '20rem', overflow: 'scroll' }}
          >
            {messageGroups.map(m => (
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id={m.id}
                  checked={selectedMessageGroups.find(g => g.id === m.id)}
                  onCheck={() => {
                    const isIn = [...selectedMessageGroups].find(
                      g => g.id === m.id
                    )
                    if (isIn) {
                      setSelectedMessageGroups(
                        [...selectedMessageGroups].filter(g => g.id !== m.id)
                      )
                    } else {
                      setSelectedMessageGroups([...selectedMessageGroups, m])
                    }
                  }}
                />
                <label
                  htmlFor={m.id}
                  className={`text-capitalize ${
                    selectedMessageGroups.find(g => g.id === m.id)
                      ? 'text-primary'
                      : ''
                  }`}
                >
                  {m.description}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="d-flex mt-2 rounded p-2 bg-light gap-2 justify-content-end align-items-center">
        <Checkbox
          id={htmlId}
          onCheck={v => setEmail({ ...email, mailMessageIsBodyHtml: v })}
          checked={email?.mailMessageIsBodyHtml}
        />
        <label htmlFor={htmlId} className="text-capitalize">
          {localize('isBodyHTML')}?
        </label>
      </div>
    </ModalTemplate>
  )
}

export default CreateEmail
