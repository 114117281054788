/** @format */

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../../../Global/Localize'
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { CommissionsLookUp } from '../../../../Components/Lookup/CommissionsLookUp'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import { CustomerPartial } from '../../../../Components/ReUsables/Customer/CustomerPartial'
import { ExemptedCustomers } from './ExemptedCustomers'
import { postData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { CustomerTypes, RecordStatus } from '../../../../Global/enumeration'

function ChargesExemptionsModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  chargeExemptionEntries,
  selectedCommissionExemption,
  setSelectedChargeExemption,
  setChargeExemptionEntries,
  onUpdateRefreshExemptionEntries,
}) {
  const [gbv, setGbv] = GlobalValidationErrors.use()
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [exemptionEntriesToBeRemoved, setExemptionEntriesToBeRemoved] =
    useState([])

  async function handleSubmit(finalData) {
    const { success } = await postData(
      `${
        mode === 'add'
          ? '/CommissionExemption/add-commission-with-commission-exemption'
          : '/CommissionExemption/update-commission-with-commission-exemption'
      }`,
      finalData,
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }

  useEffect(() => {
    setGbv({})
  }, [mode])
  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const addExemptionEntry = customer => {
    if (!customer) {
      Swal.fire({
        icon: 'error',
        text: 'Select At least One Customer',
        title: 'Add Charge Exemption Entry',
        showCloseButton: true,
      })
      return
    }

    if (
      chargeExemptionEntries.find(entry => entry.customerId === customer.id)
    ) {
      Swal.fire({
        icon: 'error',
        text: 'Customer Already Exists',
        title: 'Add Charge Exemption Entry',
        showCloseButton: true,
      })
      return
    }
    setChargeExemptionEntries([
      ...chargeExemptionEntries,
      {
        customerId: customer.id,
        customerFullName: customer.fullName,
        customerMembershipClassCustomerType:
          customer.membershipClassCustomerType,
        customerSerialNumber: customer.serialNumber,
        customerIndividualSalutation: customer.individualSalutation,
        customerIndividualFirstName: customer.individualFirstName,
        customerIndividualLastName: customer.individualLastName,
        customerIndividualGender: customer.individualGender,
        customerIndividualMaritalStatus: customer.individualMaritalStatus,
        id: customer.id,
        customerMembershipClassCustomerTypeDescription:
          customer.membershipClassDescription,
        paddedCustomerSerialNumber: customer.paddedSerialNumber,
        customerReference1: customer.reference1,
        customerReference2: customer.reference2,
        customerReference3: customer.reference3,
        customerIndividualGenderDescription:
          customer.individualGenderDescription,
        customerIndividualMaritalStatusDescription:
          customer.individualMaritalStatusDescription,
        customerIndividualIdentityCardNumber:
          customer.individualIdentityCardNumber,
        customerIndividualNationalityDescription:
          customer.individualNationalityDescription,
        customerDutyStationDescription: customer.dutyStationDescription,
        customerAddressAddressLine1: customer.addressAddressLine1,
        customerAddressAddressLine2: customer.addressAddressLine2,
        customerAddressStreet: customer.addressStreet,
        customerAddressPostalCode: customer.addressPostalCode,
        customerAddressCity: customer.addressCity,
        customerAddressEmail: customer.addressEmail,
        customerAddressLandLine: customer.addressLandLine,
        customerAddressMobileLine: customer.addressMobileLine,
        remarks: customer.remarks,
        createdBy: customer.createdBy,
        createdDate: customer.createdDate,
      },
    ])
    setSelectedCustomer(null)
  }

  const removeExemptionEntry = customer => {
    setChargeExemptionEntries(
      chargeExemptionEntries.filter(entry => entry.id !== customer.id)
    )
  }

  const removeExemptionEntryInEditMode = async () => {
    if (exemptionEntriesToBeRemoved.length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'Select Atleast One Entry To Remove',
        title: 'Add Charge Exemption Entry',
        showCloseButton: true,
      })
      return
    }
    const { success } = await putData(
      '/CommissionExemption/remove-commission-exemption-entries',
      { commissionExemptionEntries: exemptionEntriesToBeRemoved },
      true,
      `Commission Exemption Entries Removed`
    )
    if (success) {
      setExemptionEntriesToBeRemoved([])
      onUpdateRefreshExemptionEntries()
    }
  }

  const handleOnSelectCustomer = customer => {
    if (
      !customer.isLocked &&
      customer.recordStatus == Object.keys(RecordStatus)[2] &&
      customer.membershipClassCustomerType === Object.keys(CustomerTypes)[0]
    ) {
      setSelectedCustomer({ ...customer })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Customer is Locked or Inactive',
        title: 'Add Charge Exemption Entry',
        showCloseButton: true,
      })
      setSelectedCustomer(null)
    }
  }

  const addExemptionEntryInEditMode = async customer => {
    if (!customer) {
      return
    }
    let customerExemptionEntry = {
      customerId: customer.id,
      commissionExemptionId: selectedCommissionExemption.id,
      customerFullName: customer.fullName,
      customerMembershipClassCustomerType: customer.membershipClassCustomerType,
      customerSerialNumber: customer.serialNumber,
      customerIndividualSalutation: customer.individualSalutation,
      customerIndividualFirstName: customer.individualFirstName,
      customerIndividualLastName: customer.individualLastName,
      customerIndividualGender: customer.individualGender,
      customerIndividualMaritalStatus: customer.individualMaritalStatus,
      id: customer.id,
      customerMembershipClassCustomerTypeDescription:
        customer.membershipClassDescription,
      paddedCustomerSerialNumber: customer.paddedSerialNumber,
      customerReference1: customer.reference1,
      customerReference2: customer.reference2,
      customerReference3: customer.reference3,
      customerIndividualGenderDescription: customer.individualGenderDescription,
      customerIndividualMaritalStatusDescription:
        customer.individualMaritalStatusDescription,
      customerIndividualIdentityCardNumber:
        customer.individualIdentityCardNumber,
      customerIndividualNationalityDescription:
        customer.individualNationalityDescription,
      customerDutyStationDescription: customer.dutyStationDescription,
      customerAddressAddressLine1: customer.addressAddressLine1,
      customerAddressAddressLine2: customer.addressAddressLine2,
      customerAddressStreet: customer.addressStreet,
      customerAddressPostalCode: customer.addressPostalCode,
      customerAddressCity: customer.addressCity,
      customerAddressEmail: customer.addressEmail,
      customerAddressLandLine: customer.addressLandLine,
      customerAddressMobileLine: customer.addressMobileLine,
      remarks: customer.remarks,
      createdBy: customer.createdBy,
      createdDate: customer.createdDate,
    }

    const { success } = await postData(
      '/CommissionExemption/create-commission-exemption-entry',
      customerExemptionEntry,
      true,
      `${customer.fullName} - Commission Exemption Entry Created`
    )
    if (success) {
      onUpdateRefreshExemptionEntries()
      setSelectedCustomer(null)
    }
  }

  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <ModalHeader className="bg-white text-capitalize">
              {localize(mode)}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('chargesExemptions')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <div className="row mb-3">
                <div
                  validation-message={
                    gbv['CommissionExemption.Description']?.message
                  }
                  className="col d-flex  align-items-center with-validation"
                >
                  <label className="col-1 text-capitalize">
                    {localize('name')}
                  </label>
                  <input
                    className="form-control"
                    defaultValue={selectedCommissionExemption?.description}
                    onChange={e => {
                      clearValidation('CommissionExemption.Description')
                      setSelectedChargeExemption({
                        ...selectedCommissionExemption,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
                <div
                  validation-message={
                    gbv['CommissionExemption.CommissionId']?.message
                  }
                  className="col d-flex align-items-center with-validation"
                >
                  <label className="col-1 text-capitalize">
                    {localize('charge')}
                  </label>
                  {mode === 'edit' ? (
                    <input
                      className="form-control"
                      disabled
                      defaultValue={
                        selectedCommissionExemption?.commissionDescription
                      }
                    />
                  ) : (
                    <CommissionsLookUp
                      displayValue={
                        selectedCommissionExemption?.commissionDescription
                      }
                      onSelect={r => {
                        clearValidation('CommissionExemption.CommissionId')
                        setSelectedChargeExemption({
                          ...selectedCommissionExemption,
                          commissionId: r.id,
                          commissionDescription: r.description,
                        })
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 120,
                  }}
                >
                  {localize('exemptCustomerDetails')}
                </div>
                <CustomerPartial
                  customer={selectedCustomer}
                  onCustomerLookup={r => {
                    handleOnSelectCustomer(r)
                  }}
                />
                <div
                  className="bg-light d-flex justify-content-end align-items-center p-2"
                  style={{ borderRadius: 5 }}
                >
                  <button
                    onClick={() => {
                      mode === 'add'
                        ? addExemptionEntry(selectedCustomer)
                        : addExemptionEntryInEditMode(selectedCustomer)
                    }}
                    className="btn btn-success text-uppercase ms-3 px-4"
                  >
                    {localize('add')}
                  </button>
                </div>
                <div className="row mt-3">
                  <ExemptedCustomers
                    handleOnCheckCustomerExemptionEntry={r => {
                      setExemptionEntriesToBeRemoved(r)
                    }}
                    removeSelectedExemptionEntries={r => {
                      removeExemptionEntryInEditMode()
                    }}
                    mode={mode}
                    onRemoveEntry={r => {
                      removeExemptionEntry(r)
                    }}
                    exemptedCustomers={chargeExemptionEntries}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <button className="btn btn-danger px-4" onClick={onHideModal}>
                {localize('cancel')}
              </button>
              <button
                onClick={() => {
                  handleSubmit({
                    CommissionExemption: selectedCommissionExemption || {},
                    CommissionExemptionEntries: chargeExemptionEntries,
                  })
                }}
                className={`btn btn-success text-uppercase px-4`}
                type="button"
              >
                {localize(mode === 'add' ? 'create' : 'update')}
              </button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </>
  )
}

export default ChargesExemptionsModal
