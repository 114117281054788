/** @format */

import React from 'react'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

const RequisitionItemLookup = ({
  onSelect,
  displayValue,
  requisitionId,
  requisitionReference,
}) => {
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('requisitionItemsLookup')} </>
        </>
      }
      url="/lookup/Requisition/find-requisitions-items-by-requisition-id-filter-in-page"
      params={{
        requisitionId,
      }}
      conditionalField="requisitionId"
      columns={[
        {
          label: localize('item'),
          template: r => <>{r.itemRegisterDescription}</>,
        },
        {
          label: localize('inventoryCategory'),
          template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
        },
        {
          label: localize('assetType'),
          template: r => <>{r.itemRegisterAssetTypeDescription}</>,
        },
        {
          label: localize('unitOfMeasure'),
          template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
        },
        {
          label: localize('quantityRequested'),
          template: r => <>{r.quantity}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default RequisitionItemLookup
