/** @format */
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { CheckOffDataPeriodLookup } from '../../../../../Components/Lookup/CheckOffDataPeriodLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import { AddDataEntryDetails } from './Partials/AddDataEntryDetails'
import { useState } from 'react'

function Processing() {
  const [dataProcessingModel, setDataProcessingModel] = useState(null)

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('checkOffDataProcessing'),
          localize('processing'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('dataPeriod')}
                  </label>
                  <CheckOffDataPeriodLookup
                    displayValue={dataProcessingModel?.postingPeriodDescription}
                    onSelect={r => {
                      setDataProcessingModel({
                        ...dataProcessingModel,
                        ...r,
                        postingPeriodDescription: r.postingPeriodDescription,
                        creditTypeDescription: r.creditTypeDescription,
                        remarks: r.remarks,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('creditType')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={dataProcessingModel?.creditTypeDescription}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('remarks')}
                  </label>
                  <input
                    disabled
                    className="form-control"
                    value={dataProcessingModel?.remarks}
                  />
                </div>
                <div className="col-md-12 mb-3 mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('dataEntryDetails'),
                        item: (
                          <AddDataEntryDetails
                            dataProcessingModel={dataProcessingModel}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Processing
