/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../../../Global/Localize'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { JournalVoucherStatus } from '../../../../../../Global/enumeration'
import { getData } from '../../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import JournalVoucherVerificationModal from './Partials/JournalVoucherVerificationModal'
import Loader from '../../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'
import ViewAuditLogs from '../../../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function Voucher() {
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [selectedJournalVoucher, setSelectedJournalVoucher] = useState(null)
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedVouchers, setSelectedVouchers] = useState([])
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedVouchers.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedVouchers([...selectedVouchers, r])
            } else {
              setSelectedVouchers(selectedVouchers.filter(s => s.id !== r.id))
            }
          }}
        />
      ),
    },
    { label: localize('status'), template: r => <>{r?.statusDescription}</> },
    {
      label: localize('voucherNumber'),
      template: r => <>{r?.paddedVoucherNumber}</>,
    },
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    {
      label: localize('postingPeriod'),
      template: r => <>{r?.postingPeriodDescription}</>,
    },
    {
      label: localize('voucherType'),
      template: r => <>{r?.typeDescription}</>,
    },
    {
      label: localize('chartOfAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('chartOfAccountCostDistributionRuleDescription'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('costCenter'),
      template: r => <>{r?.costCenterDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerAccountCustomerFullName}</>,
    },
    {
      label: `${localize('ref')}_1 ${localize('account')}#`,
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('ref')}_2 ${localize('membership')}#`,
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('ref')}_3 ${localize('personalFile')}#`,
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r?.primaryDescription}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r?.secondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r?.reference}</> },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r?.totalValue)}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r?.valueDate)}</>,
    },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    {
      label: `${localize('verification')}/${localize('rejection')} ${localize(
        'remarks'
      )}`,
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'by'
      )}`,
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'date'
      )}`,
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: `${localize('authorization')}/${localize('rejection')} ${localize(
        'remarks'
      )}`,
      template: r => <>{r?.authorizationRemarks}</>,
    },
    {
      label: `${localize('authorized')}/${localize('rejected')} ${localize(
        'by'
      )}`,
      template: r => <>{r?.authorizedBy}</>,
    },
    {
      label: `${localize('authorized')}/${localize('rejected')} ${localize(
        'date'
      )}`,
      template: r => <>{formatDate(r?.authorizedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageSize: 10,
    pageIndex: 0,
    status: Object.keys(JournalVoucherStatus)[0],
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/JournalVoucher/find-journal-vouchers-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleOnVerify = r => {
    setSelectedJournalVoucher(r)
    setMode('verify')
  }

  const handleOnView = r => {
    setSelectedJournalVoucher(r)
    setMode('view')
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  const handleCloseModal = () => {
    setMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('verification'),
          localize('voucher'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <>
                {isBusy ? (
                  <Loader />
                ) : (
                  <>
                    <div className="row mb-2">
                      <div className="col-md-4 gap-2">
                        <div className="mb-3"></div>
                      </div>
                    </div>
                    <div className="row">
                      <RecordsPerPage
                        className="col-3"
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                      <div className="col-3">
                        <div
                          className="d-flex align-items-center me-5"
                          style={{ width: '300px' }}
                        >
                          <label className="col-3 text-capitalize">
                            {localize('pickDate')}
                          </label>
                          <DatePicker
                            onSelect={v => {
                              if (v.custom) {
                                setReqParams({
                                  ...reqParams,
                                  customDate: true,
                                  startDate: v.startDate,
                                  endDate: v.endDate,
                                })
                              } else {
                                setReqParams({
                                  ...reqParams,
                                  customDate: false,
                                  dateRequestsFilter: v.value,
                                })
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="JournalVoucherStatus"
                          value={reqParams.status}
                          onChange={e => {
                            setReqParams({
                              ...reqParams,
                              status: e.value,
                              pageIndex: 0,
                            })
                          }}
                          sort
                        />
                      </div>
                      <div className="col-3">
                        <form
                          className="col d-flex align-items-center"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              filterText: search,
                            })
                          }}
                        >
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams.filterText}
                            onChange={e => setSearch(e.target.value)}
                          ></input>
                          <button
                            type="button"
                            className="btn btn-primary ms-2"
                            onClick={() => {
                              setReqParams({
                                ...reqParams,
                                filterText: search,
                              })
                            }}
                          >
                            <i className="uil uil-filter"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="row col-12 mt-2">
                      <SimpleTable
                        contextMenu={e => (
                          <>
                            <div
                              onClick={() => {
                                handleOnView(e)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-eye"></i>
                              {localize('view')}
                            </div>
                            <div
                              onClick={() => {
                                handleOnVerify(e)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="mdi mdi-check-decagram"></i>
                              {localize('verify')}
                            </div>
                            <div
                              className="ctxt-menu-item text-capitalize"
                              onClick={() => {
                                handleViewAuditLogs(e)
                              }}
                            >
                              <i className="uil uil-server"></i>
                              <span>{localize('viewAuditLog')}</span>
                            </div>
                          </>
                        )}
                        data={tableData}
                        columns={columns}
                        onSort={(c, i) => handleSort(c, i)}
                        sortedBy={sortedBy}
                      />
                    </div>
                    <div className="row col-12 mt-2">
                      <Pager
                        {...tableMeta}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      <JournalVoucherVerificationModal
        selectedJournalVoucher={selectedJournalVoucher}
        onUpdateRefreshAt={() => {
          setRefreshAt(!refreshAt)
        }}
        onSetMode={m => {
          setMode(m)
        }}
        mode={mode}
        onHideModal={m => {
          setMode(null)
        }}
      />
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleCloseModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedVoucherNumber}
        />
      )}
    </>
  )
}

export default Voucher
