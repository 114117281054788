/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function BankLinkageLookup({ onSelect, displayValue }) {
  return (
    <>
      <SimpleLookup
        title={localize('bankLinkageLookup')}
        url="/lookup/BankLinkage/find-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={[
          {
            label: localize('branchDescription'),
            template: r => <>{r.branchDescription}</>,
          },
          {
            label: localize('chartOfAccountName'),
            template: r => <>{r.chartOfAccountName}</>,
          },
          {
            label: localize('chartOfAccountCostDistributionRuleDescription'),
            template: r => (
              <>{r.chartOfAccountCostDistributionRuleDescription}</>
            ),
          },
          {
            label: localize('chartOfAccountCurrencyDescription'),
            template: r => <>{r.chartOfAccountCurrencyDescription} </>,
          },
          { label: localize('bankName'), template: r => <>{r.bankName}</> },
          {
            label: localize('bankAccountNumber'),
            template: r => <>{r.bankAccountNumber}</>,
          },
          { label: localize('remarks'), template: r => <>{r.remarks}</> },
          {
            label: localize('isLocked') + '?',
            template: r => (
              <span className="text-capitalize">{r.isLocked}</span>
            ),
          },
          {
            label: localize('createdDate'),
            template: r => <>{formatDate(r.createdDate)}</>,
          },
        ]}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default BankLinkageLookup
