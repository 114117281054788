/** @format */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import InvoiceEntriesItems from './InvoiceEntriesItems'
import { formatDate } from '../../../../../../Helpers/formatters'
import InvoiceApprovals from './InvoiceApprovals'

const ViewInvoice = ({ mode, closeModal, invoice }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [invoiceApprovalEntries, setInvoiceApprovalEntries] = useState([])

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/Invoice/find-invoices-items-by-invoice-id-filter-in-page`,
      {
        invoiceId: invoice?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  const loadApprovalEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Invoice/find-invoice-approvals',
      {
        invoiceId: invoice?.id,
      },
      false
    )
    if (success) {
      setInvoiceApprovalEntries(data?.result)
      setIsBusy(false)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    loadApprovalEntries()
    // eslint-disable-next-line
  }, [invoice?.id, reqParams.pageSize, reqParams.pageIndex])

  const closePage = () => {
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={'view'}
      modalTitle="invoice"
      handleClose={closePage}
      modalClassName="modal-xl"
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={invoice?.paddedReferenceNumber}
              />
            </div>
            <div className="col d-flex align-items-center mb-2  ">
              <label className="text-capitalize col me-3">
                {localize('supplier')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.supplierDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize me-3">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.supplierAddressMobileLine}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('creditPeriod')}
              </label>
              <input
                className="form-control ms-2"
                disabled
                value={invoice?.supplierCreditPeriod}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-md-1 text-capitalize me-3">
                {localize('invoiceDate')}
              </label>
              <input
                className="form-control ms-4"
                disabled
                value={invoice?.invoiceDate}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-3">
                {localize('branch')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={invoice?.branchDescription}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-3">
                {localize('createdBy')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={invoice?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('createdDate')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={formatDate(invoice?.createdDate)}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('invoice#')}
              </label>
              <input
                className="form-control ms-4"
                disabled
                value={invoice?.invoiceNumber}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('document')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.fileName}
              />
            </div>

            <div className="col d-flex align-items-center  ">
              <label className="text-capitalize me-2">
                {localize('title')}
              </label>
              <input
                className="form-control ms-5"
                disabled
                value={invoice?.fileTitle}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize">
                {localize('totalValue')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                value={invoice?.totalValue}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col d-flex align-items-center ">
              <label className="text-capitalize">
                {localize('description')}
              </label>
              <textarea
                className="form-control ms-3"
                disabled
                value={invoice?.fileDescription}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('invoiceItems'),
                  item: (
                    <InvoiceEntriesItems
                      mode={mode}
                      entries={existingEntries}
                    />
                  ),
                },
                {
                  label: localize('approvals'),
                  item: <InvoiceApprovals entries={invoiceApprovalEntries} />,
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ViewInvoice
