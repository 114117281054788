/** @format */

import localize from '../../../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../../../Components/ComboBox/EnumsServerCombo'
/* eslint-disable */
import React, { useState } from 'react'
import Tab from '../../../../../../../../Components/Tabs/Tab'
import { CustomerAccountTransactionCashWithdrawal } from './CustomerAccountTransactionCashWithdrawal'
import { CustomerAccountTransactionChargesAndLevies } from './CustomerAccountTransactionChargesAndLevies'
import { CustomerAccountTransactionPaymentVoucher } from './CustomerAccountTransactionPaymentVoucher'
import { CustomerAccountTransactionChequeDeposit } from './ChequeDeposit/CustomerAccountTransactionChequeDeposit'
import { CustomerAccountTransactionChequePayables } from './ChequeDeposit/CustomerAccountTransactionChequePayables'
import { CustomerAccountTransactionCashDeposit } from './CustomerAccountTransactionCashDeposit'

export const CustomerAccountTransactionSection = ({
  onChangeTransactionType,
  transactionModel,
  setTransactionModel,
  handleOnBlur,
  showLoading,
  setCurrencyTotalValue,
  handleOnChangeChequeDepositAmount,
  selectedCustomerAccount,
  setExternalChequePayables,
  externalChequePayables,
  tarrifs,
  submittedInstructions,
  validationBag,
  clearValidation,
  handleOnChangePaymentVoucherAmount,
}) => {
  const [selectedTransactionType, setSelectedTransactionType] = useState('1')

  let tabsToShow = []

  if (selectedTransactionType === '2') {
    tabsToShow = [
      {
        label: localize('cashDeposit'),
        item: (
          <CustomerAccountTransactionCashDeposit
            onGetTotalValue={r => {
              setCurrencyTotalValue(r)
            }}
            transactionModel={transactionModel}
            setTransactionModel={setTransactionModel}
            selectedTransactionType={selectedTransactionType}
          />
        ),
      },
      {
        label: localize('chargesAndLevies'),
        item: <CustomerAccountTransactionChargesAndLevies tariffs={tarrifs} />,
      },
    ]
  } else if (selectedTransactionType === '4') {
    tabsToShow = [
      {
        label: localize('paymentVoucher'),
        item: (
          <CustomerAccountTransactionPaymentVoucher
            selectedTransactionType={selectedTransactionType}
            transactionModel={transactionModel}
            setTransactionModel={setTransactionModel}
            handleOnChangePaymentVoucherAmount={
              handleOnChangePaymentVoucherAmount
            }
          />
        ),
      },
      {
        label: localize('chargesAndLevies'),
        item: <CustomerAccountTransactionChargesAndLevies tariffs={tarrifs} />,
      },
    ]
  } else if (selectedTransactionType === '3') {
    tabsToShow = [
      {
        label: localize('chequeDeposit'),
        item: (
          <CustomerAccountTransactionChequeDeposit
            handleOnChangeChequeDepositAmount={
              handleOnChangeChequeDepositAmount
            }
            submittedInstructions={submittedInstructions}
            selectedTransactionType={selectedTransactionType}
            validationBag={validationBag}
            clearValidation={clearValidation}
            selectedCustomerAccount={selectedCustomerAccount}
            transactionModel={transactionModel}
            setTransactionModel={setTransactionModel}
            onGetExternalChequePayables={r => {
              setExternalChequePayables(r)
            }}
          />
        ),
      },
      {
        label: localize('chequePayables'),
        item: (
          <CustomerAccountTransactionChequePayables
            onSelectChequePayables={r => {
              // TODO: myke fix this 08/10/2023
            }}
            chequePayables={externalChequePayables}
            transactionModel={transactionModel}
            setTransactionModel={setTransactionModel}
          />
        ),
      },
      {
        label: localize('chargesAndLevies'),
        item: <CustomerAccountTransactionChargesAndLevies tariffs={tarrifs} />,
      },
    ]
  } else {
    tabsToShow = [
      {
        label: localize('cashWithdrawal'),
        item: (
          <CustomerAccountTransactionCashWithdrawal
            handleOnBlur={handleOnBlur}
            transactionModel={transactionModel}
            setTransactionModel={setTransactionModel}
          />
        ),
      },
      {
        label: localize('chargesAndLevies'),
        item: <CustomerAccountTransactionChargesAndLevies tariffs={tarrifs} />,
      },
    ]
  }
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-1 text-capitalize">
            {localize('transactionType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="FrontOfficeTransactionType"
            value={selectedTransactionType}
            onChange={e => {
              setSelectedTransactionType(e.value)
              onChangeTransactionType(e.value)
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col md-12">
          <div
            className={`${
              showLoading ? '' : 'd-none'
            } col-12 d-flex align-items-center justify-content-center`}
          >
            <div className="m-3 spinner-border text-dark"></div>
            <span className="fs-4">Please Wait</span>
          </div>
          <div className={`${showLoading ? 'd-none' : ''}`}>
            <Tab preload tabItems={tabsToShow} />
          </div>
        </div>
      </div>
    </>
  )
}
