/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

function TransactionTypeLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: 'name',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'funeralHome',
      template: r => <>{r?.funeralHomeDescription}</>,
    },
    {
      label: 'gLAccountName',
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: 'gLAccountCostDistributionRule',
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: 'isLocked',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: 'modifiedBy',
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        columns={columns}
        useText
        title={localize('transactionTypeLookup')}
        url="/MorgueTransactionType/find-morgue-transaction-types-by-filter-in-page"
        params={{ text: '', pageIndex: 0, pageSize: 10 }}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default TransactionTypeLookup
