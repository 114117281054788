/** @format */

import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ActionModal from '../../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import ElectronicJournalLookup from '../../../../../../Components/Lookup/ElectronicJournalLookup'
import UnPayReasonLookup from '../../../../../../Components/Lookup/UnPayReasonLookup'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { ELECTRONIC_JOURNAL_STATUS } from '../../../../../../Helpers/constants'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ChequeSpecimen from '../Partials/ChequeSpecimen/ChequeSpecimen'
import AccountPreview from './AccountPreview/AccountPreview'

const AutomatedChequeProcessing = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [truncatedCheques, setTruncatedCheques] = useState([])
  const [truncatedCheque, setTruncatedCheque] = useState({})
  const [mode, setMode] = useState(null)
  const [electronicJournal, setElectronicJournal] = useState({})
  const [reason, setReason] = useState({})
  const [clearOption, setClearOption] = useState(1)
  const [isBusyMatching, setIsBusyMatching] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
  })

  const columns = [
    {
      label: localize('status'),
      template: item => <>{item?.statusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: item => <>{item?.serialNumber}</>,
    },
    {
      label: localize('destinationAccountBank'),
      template: item => <>{item?.destinationAccountBank}</>,
    },
    {
      label: localize('destinationAccountBranch'),
      template: item => <>{item?.destinationAccountBranch}</>,
    },
    {
      label: localize('destinationAccount'),
      template: item => <>{item?.destinationAccountAccount}</>,
    },
    {
      label: localize('value'),
      template: item => <>{formatCurrency(item?.value)}</>,
    },
    {
      label: localize('reasonForReturn'),
      template: item => <>{item?.reasonForReturnCodeDescription}</>,
    },
    {
      label: localize('destinationAccountCheckDigit'),
      template: item => <>{item?.destinationAccountCheckDigit}</>,
    },
    {
      label: localize('destinationAccountCurrencyCode'),
      template: item => <>{item?.destinationAccountCurrencyCode}</>,
    },
    {
      label: localize('collectionAccountDetail'),
      template: item => <>{item?.collectionAccountDetail}</>,
    },
    {
      label: localize('presentingBank'),
      template: item => <>{item?.presentingBank}</>,
    },
    {
      label: localize('presentingBranch'),
      template: item => <>{item?.presentingBranch}</>,
    },
    {
      label: localize('documentReferenceNumber'),
      template: item => <>{item?.documentReferenceNumber}</>,
    },
    {
      label: localize('remarks'),
      template: item => <>{item?.remarks}</>,
    },
    {
      label: localize('unPaidCode'),
      template: item => <>{item?.unPaidCode}</>,
    },
    {
      label: localize('unPaidReason'),
      template: item => <>{item?.unPaidReason}</>,
    },
    {
      label: localize('processedBy'),
      template: item => <>{item?.processedBy}</>,
    },
    {
      label: localize('processedDate'),
      template: item => <>{formatDate(item?.processedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdDate)}</>,
    },
  ]

  const handleSelectReason = reason => {
    if (reason?.isLocked)
      return Swal.fire(
        'Operation not allowed',
        'Selected reason is locked',
        'error'
      )

    setReason(reason)
  }

  const handleSelectTruncatedCheque = (truncatedCheque, mode) => {
    setTruncatedCheque(truncatedCheque)
    setMode(mode)
  }

  const handleClickOnce = cheque => {
    setTruncatedCheque(cheque)
  }

  const handleClose = () => {
    setMode(null)
    setTruncatedCheque({})
  }

  const handleRematch = async () => {
    setIsBusyMatching(true)

    const url = '/ElectronicJournal/match-truncated-cheque-payment-voucher'

    const { success } = await postData(url, truncatedCheque, false)

    if (success) {
      showNotification('Rematch Payment Voucher', 'success')
      fetchVouchers()
      handleClose()
    }

    setIsBusyMatching(false)
  }

  const fetchVouchers = async () => {
    if (!electronicJournal?.id) return

    setIsBusy(true)

    const url =
      '/ElectronicJournal/find-truncated-cheques-by-electronic-journal-id-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        electronicJournalId: electronicJournal?.id,
      },
      false
    )

    if (success) {
      setTruncatedCheques(data)
    }

    setIsBusy(false)
  }

  const handleSelectElectronicJournal = journal => {
    if (journal?.status !== ELECTRONIC_JOURNAL_STATUS.OPEN?.toString())
      return Swal.fire(
        'Operation not allowed!',
        'Selected journal is closed!',
        'error'
      )

    setElectronicJournal(journal)
  }

  const handleUpdate = async () => {
    if (!truncatedCheque?.id)
      return Swal.fire(
        'Operation not allowed!',
        'Please select a voucher',
        'error'
      )

    if (!reason?.id)
      return Swal.fire(
        'Operation not allowed!',
        'Please select a reason',
        'error'
      )

    setIsBusy(true)

    const url = '/ElectronicJournal/clear-truncated-cheque-journal'

    const { success } = await postData(
      url,
      {
        ...truncatedCheque,
        ...reason,
        electronicJournalId: electronicJournal?.id,
        truncatedChequeProcessingOption: clearOption,
        unPayReasonId: reason?.id,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      showNotification('Clear Truncated Cheque', 'success')
      fetchVouchers()
      setClearOption(1)
      setReason({})
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (electronicJournal?.id) fetchVouchers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electronicJournal?.id, reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'rematch' && (
        <ActionModal
          modalTitle="Rematch Voucher"
          cancelText="no"
          handleAction={handleRematch}
          confirmText="yes"
          handleClose={handleClose}
          disableAction={isBusyMatching}
        >
          {isBusyMatching ? (
            <Loader />
          ) : (
            <p>Are you sure you want to rematch this voucher?</p>
          )}
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('cheques'),
          localize('automatedClearing'),
          localize('truncatedChequesProcessing'),
        ]}
      />

      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="mb-3 row ">
                <div className="col-md-4 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('electronicJournal')}
                  </label>
                  <ElectronicJournalLookup
                    displayValue={electronicJournal?.fileName}
                    onSelect={handleSelectElectronicJournal}
                  />
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('fileSerialNumber')}
                  </label>
                  <input
                    className="form-control"
                    value={electronicJournal?.headerRecordFileSerialNumber}
                    disabled
                  />
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('dateOfFileExchange')}
                  </label>
                  <input
                    className="form-control"
                    value={formatDate(
                      electronicJournal?.headerRecordDateOfFileExchange,
                      true
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex row align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center col-md-3">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-2"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col-md-4 d-flex align-items-center justify-content-end gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('search')}
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchVouchers()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchVouchers()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>

              <SimpleTable
                data={truncatedCheques?.pageCollection}
                columns={columns}
                canClickOnce
                onClickOnce={handleClickOnce}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleSelectTruncatedCheque(r, 'rematch')
                      }}
                    >
                      <i className="uil uil-sync"></i>
                      {localize('rematchVoucher')}
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={truncatedCheques.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>

      <div className="card bg-white rounded">
        <div className="card-body">
          <Tab
            tabItems={[
              {
                label: localize('chequeImages'),
                item: <ChequeSpecimen journal={truncatedCheque} />,
              },
              {
                label: localize('accountPreview'),
                item: (
                  <AccountPreview
                    customerAccountId={
                      truncatedCheque?.paymentVoucherChequeBookCustomerAccountId
                    }
                  />
                ),
              },
              {
                label: localize('pay/unpay'),
                item: (
                  <div className="row mt-2">
                    <div className="d-flex align-items-center">
                      <label className="col-1 text-capitalize">
                        {localize('reason')}
                      </label>
                      <UnPayReasonLookup
                        displayValue={reason?.description}
                        onSelect={handleSelectReason}
                      />
                    </div>

                    <div className="col-md-12">
                      <div className="d-flex justify-content-end gap-3 align-items-center mt-3 bg-light rounded p-2">
                        <div className="d-flex align-items-center">
                          <label className="text-capitalize me-2">
                            {localize('option')}
                          </label>

                          <EnumsServerCombo
                            disabled={isBusy}
                            customContainerStyle={{
                              minWidth: '120px',
                              maxWidth: 'fit-content',
                            }}
                            enumsKey="ExternalChequeClearanceOption"
                            value={clearOption}
                            onChange={({ value }) => {
                              setClearOption(value)
                            }}
                            sort
                          />
                        </div>

                        <button
                          onClick={handleUpdate}
                          className="btn btn-success"
                          disabled={isBusy}
                        >
                          {localize('update')}
                        </button>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default AutomatedChequeProcessing
