/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import EmploymentSectorLookup from '../../../../../../Components/Lookup/EmploymentSectorLookup'
import { parseDate } from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function IndividualParticulars({ customer, readOnly, onChange }) {
  const [ind, setInd] = useState(customer)
  useEffect(() => {
    if (!readOnly) {
      onChange(ind)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ind])

  return (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('individualType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IndividualType"
            value={ind?.individualType}
            onChange={e => setInd({ ...ind, individualType: e.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('nationality')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Nationality"
            value={ind?.individualNationality}
            onChange={e => setInd({ ...ind, individualNationality: e.value })}
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('gender')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            value={ind?.individualGender}
            onChange={e => setInd({ ...ind, individualGender: e.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Salutation"
            value={ind?.individualSalutation}
            onChange={e => setInd({ ...ind, individualSalutation: e.value })}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={ind?.individualFirstName}
            onChange={e =>
              setInd({ ...ind, individualFirstName: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('otherNames')}
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={ind?.individualLastName}
            onChange={e =>
              setInd({ ...ind, individualLastName: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('birthDate')}
          </label>
          <input
            disabled={readOnly}
            type="date"
            className="form-control"
            value={parseDate(ind?.individualBirthDate)}
            onChange={e =>
              setInd({ ...ind, individualBirthDate: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('educationLevel')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="EducationLevel"
            value={ind?.individualEducationLevel}
            onChange={e =>
              setInd({ ...ind, individualEducationLevel: e.value })
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            value={ind?.individualIdentityCardType}
            onChange={e =>
              setInd({ ...ind, individualIdentityCardType: e.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('identityCard')} #
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={ind?.individualIdentityCardNumber}
            onChange={e =>
              setInd({ ...ind, individualIdentityCardNumber: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('identityCardSerial')} #
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={ind?.individualIdentityCardSerialNumber}
            onChange={e =>
              setInd({
                ...ind,
                individualIdentityCardSerialNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('pin')} #
          </label>
          <input type="text" className="form-control" disabled={readOnly} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('employmentSector')}
          </label>
          {readOnly ? (
            <input
              className="form-control"
              disabled
              value={ind?.employmentSectorDescription}
            />
          ) : (
            <EmploymentSectorLookup
              displayValue={ind.employmentSectorDescription}
              onSelect={v =>
                setInd({
                  ...ind,
                  employmentSectorId: v.id,
                  employmentSectorDescription: v.description,
                })
              }
            />
          )}
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('employmentTerms')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="TermsOfService"
            value={ind?.individualEmploymentTermsOfService}
            onChange={e =>
              setInd({
                ...ind,
                individualEmploymentTermsOfService: e.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('employmentDesignation')}
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={ind?.individualEmploymentDesignation}
            onChange={e =>
              setInd({
                ...ind,
                individualEmploymentDesignation: e.target.value,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('employmentDate')}
          </label>
          <input
            disabled={readOnly}
            type="date"
            className="form-control"
            value={parseDate(ind?.individualEmploymentDate)}
            onChange={e =>
              setInd({ ...ind, individualEmploymentDate: e.target.value })
            }
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('maritalStatus')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="MaritalStatus"
            value={ind?.individualMaritalStatus}
            onChange={e => setInd({ ...ind, individualMaritalStatus: e.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('disabilityType')}
          </label>
          {/* <select
            disabled={readOnly}
            className="form-select"
            value={ind?.individualDisabilityType}
            onChange={e =>
              setInd({ ...ind, individualDisabilityType: e.target.value })
            }
          >
            {customerDisabilityType.map((d, i) => (
              <option key={`custdis_${i}`} value={i}>
                {d}
              </option>
            ))}
          </select> */}
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="CustomerDisabilityType"
            value={ind?.individualDisabilityType}
            onChange={e =>
              setInd({ ...ind, individualDisabilityType: e.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('huduma')} #
          </label>
          <input
            disabled={readOnly}
            type="text"
            className="form-control"
            value={ind?.individualHudumaNumber}
            onChange={e =>
              setInd({ ...ind, individualHudumaNumber: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('retirementAge')}
          </label>
          <input
            disabled={readOnly}
            type="number"
            className="form-control"
            value={ind?.individualRetirementAge}
            onChange={e =>
              setInd({ ...ind, individualRetirementAge: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default IndividualParticulars
