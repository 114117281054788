/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../Helpers/formatters'

const GLAccountSummary = ({ summary }) => {
  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="px-2 bg-light text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('g/LAccountSummary')}
        </div>
        <div className="row g-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="balanceB/F" className="text-capitalize col-4">
                {localize('balanceB/F')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(
                  summary?.balanceBroughtFoward,
                  null,
                  null,
                  false
                )}
                disabled
                id="balanceB/F"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="debits" className="text-capitalize col-4">
                {localize('debits')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(summary?.TotalDebits, null, null, false)}
                disabled
                id="debits"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="credits" className="text-capitalize col-4">
                {localize('credits')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(summary?.totalCredits, null, null, false)}
                disabled
                id="credits"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="balanceC/F" className="text-capitalize col-4">
                {localize('balanceC/F')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(
                  summary?.balanceCarriedForward,
                  null,
                  null,
                  false
                )}
                disabled
                id="balanceC/F"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="unreconciledDiscrepancies"
                className="text-capitalize col-4"
              >
                {localize('unreconciledDiscrepancies')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(
                  summary?.unreconciledDiscrepancyBalance,
                  null,
                  null,
                  false
                )}
                disabled
                id="unreconciledDiscrepancies"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="reconciledItems"
                className="text-capitalize col-4"
              >
                {localize('reconciledItems')}
              </label>
              <input
                type="text"
                className="form-control"
                value={summary?.statusEntries}
                disabled
                id="reconciledItems"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="reconciledBalance"
                className="text-capitalize col-4"
              >
                {localize('reconciledBalance')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(
                  summary?.reconciledBalance,
                  null,
                  null,
                  false
                )}
                disabled
                id="reconciledBalance"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center">
              <label
                htmlFor="unReconciledBalance"
                className="text-capitalize col-4"
              >
                {localize('unReconciledBalance')}
              </label>
              <input
                type="text"
                className="form-control text-end"
                value={formatCurrency(
                  summary?.unreconciledBalance,
                  null,
                  null,
                  false
                )}
                disabled
                id="unReconciledBalance"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GLAccountSummary
