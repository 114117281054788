/** @format */

import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

function LoanRequestOffsetableLoans({ loanRequest }) {
  const [attachedLoans, setAttachedLoans] = useState([])
  const [isWaiting, setIsWaiting] = useState(false)

  const columns = [
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.paddedCustomerAccountCustomerAccountNumber}</>,
    },
    {
      label: `${localize('Ref')}_1 (${localize('account')} #)`,
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: `${localize('Ref')}_2 (${localize('membership')} #)`,
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: `${localize('Ref')}_3 (${localize('personalFile')} #)`,
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    loadAttachedLoans()
    // eslint-disable-next-line
  }, [loanRequest])

  async function loadAttachedLoans() {
    setIsWaiting(true)
    const { success, data } = await getData(
      '/lookup/loanRequest/find-loan-request-attached-loans-by-loan-case-id',
      { loanRequestId: loanRequest?.id },
      false
    )
    if (success) {
      setAttachedLoans(data?.result || [])
    }
    setIsWaiting(false)
  }
  return isWaiting ? (
    <Loader />
  ) : (
    <div>
      <SimpleTable columns={columns} data={attachedLoans} />
    </div>
  )
}

export default LoanRequestOffsetableLoans
