/** @format */
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { useEffect, useState } from 'react'
import { AddExternalAgencyModal } from './AddExternalAgencyModal'
import { EditExternalAgencyModal } from './EditExternalAgencyModal'
import { postData } from '../../../../../Helpers/webApi'
import { ExternalAgencyType } from '../../../../../Helpers/constants'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import swal from 'sweetalert2'

function ExternalAgenciesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  setSelectedExternalAgency,
  selectedExternalAgency,
  onSetMode,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addExternalAgency()
    }
    if (mode === 'edit') {
      return editExternalAgency()
    }
  }

  const addExternalAgency = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/ExternalAgency/add-external-agency',
      {
        ...(selectedExternalAgency || {}),
      },
      false,
    )
    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      setSelectedExternalAgency({
        agencyType: ExternalAgencyType.MPESA,
      })
      swal.fire(
        'Add External Agency',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editExternalAgency = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/ExternalAgency/update-external-agency',
      {
        ...(selectedExternalAgency || {}),
      },
      false,
    )
    if (success) {
      onUpdateRefreshAt()
      onHideModal()
      setSelectedExternalAgency({
        agencyType: ExternalAgencyType.MPESA,
      })
      swal.fire(
        'Edit External Agency',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('externalAgency')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <AddExternalAgencyModal
                setSelectedExternalAgency={setSelectedExternalAgency}
                selectedExternalAgency={selectedExternalAgency}
                isBusy={isBusy}
              />
            )}
            {mode === 'edit' && (
              <EditExternalAgencyModal
                setSelectedExternalAgency={setSelectedExternalAgency}
                selectedExternalAgency={selectedExternalAgency}
                isBusy={isBusy}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default ExternalAgenciesModal
