/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../../../../../Helpers/webApi'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import localize from '../../../../../../Global/Localize'
import Swal from 'sweetalert2'

function CreditTypes({ customer, readOnly }) {
  const [creditTypes, setCreditTypes] = useState([])
  const [ticked, setTicked] = useState([])

  useEffect(() => {
    loadCreditTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadCreditTypes() {
    const { success, data } = await getData('/creditType/find-credit-types')
    if (success) {
      setCreditTypes(data?.result || [])
      loadSelectedCreditTypes()
    }
  }

  async function loadSelectedCreditTypes() {
    const { success, data } = await getData(
      `/customer/find-credit-types-by-customer-id`,
      { customerId: customer?.id }
    )
    if (success) {
      setTicked(data?.result)
    }
  }

  async function itemClick(cType, value) {
    if (value) {
      let found = ticked.find(c => c.id === cType.id)
      if (!found) {
        setTicked([...ticked, cType])
      }
    } else {
      setTicked([...ticked.filter(c => c.id !== cType.id)])
    }
  }

  async function updateInfo() {
    const { success } = await putData(
      `/customer/update-credit-type-by-customer-id?customerId=${customer?.id}`,
      ticked
    )
    if (success) {
      await loadCreditTypes()
      Swal.fire('Operation Successful', 'Credit Types Updated', 'success')
    }
  }

  return (
    <div>
      <h4 className="text-capitalize mb-3">
        {readOnly ? 'view' : 'check'} credit types
      </h4>
      <div
        className="p-3"
        style={{
          border: 'solid 1px black',
          maxHeight: '60vh',
          overflowY: 'scroll',
          borderRadius: 10,
        }}
      >
        {creditTypes.map(c => (
          <CreditType
            creditType={c}
            // eslint-disable-next-line eqeqeq
            selected={!!ticked.find(t => t.id == c.id)}
            onCheck={itemClick}
            disabled={readOnly}
          />
        ))}
      </div>
      {readOnly || (
        <div className="my-3 d-flex align-items-center justify-content-end">
          <button onClick={updateInfo} className="btn btn-success px-4">
            {localize('update')}
          </button>
        </div>
      )}
    </div>
  )
}

function CreditType({ creditType, selected, onCheck, disabled }) {
  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Checkbox
          checked={selected}
          id={`ctype_${creditType.id}`}
          onCheck={v => {
            onCheck(creditType, v)
          }}
          disabled={disabled}
        />
        <label
          htmlFor={`ctype_${creditType.id}`}
          className={`text-capitalize fw-normal ${
            selected ? 'fw-bold text-primary' : ''
          }`}
        >
          {creditType.description}
        </label>
      </div>
    </>
  )
}

export default CreditTypes
