/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../../Global/Localize'
import { putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import PostingPeriodLookup from '../../../../../Components/Lookup/PostingPeriodLookup'
import { ModalFooter } from 'reactstrap'
import { formatDate } from '../../../../../Helpers/formatters'
import Swal from 'sweetalert2'
import Loader from '../../../../../Components/Loaders/Loader'

function PostingPeriodClosing() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedPostingPeriod, setSelectedPostingPeriod] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function handleSubmit() {
    setIsBusy(true)
    const { success } = await putData(
      '/PostingPeriod/close-posting-period',
      {
        postingPeriod: {
          ...selectedPostingPeriod,
          /**@TODO: alex-stands for the branchId of the logged in user-7/13/2023 */
          branchId: '0e58e292-bcf3-ed11-849a-402343441bbc',
        },
        moduleNavigationItemCode: 1000,
      },
      false,
      'Operation Completed Successfully'
    )
    if (success) {
      setIsBusy(false)
      setSelectedPostingPeriod(null)
    } else {
      setIsBusy(false)
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('postingPeriodClosing'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2">
                  <div
                    className="col d-flex align-items-center ms-2 with-validation"
                    validation-message={
                      globalValidationErrors['PostingPeriod.Description']
                        ?.message
                    }
                  >
                    <label className="col-2 text-capitalize">
                      {localize('postingPeriod')}
                    </label>
                    <PostingPeriodLookup
                      displayValue={selectedPostingPeriod?.description}
                      onSelect={r => {
                        if (!r.isLocked) {
                          clearValidation('PostingPeriod.Description')
                          setSelectedPostingPeriod({
                            ...selectedPostingPeriod,
                            id: r.id,
                            description: r.description,
                            createdDate: r.createdDate,
                            durationStartDate: r.durationStartDate,
                            durationEndDate: r.durationEndDate,
                          })
                        } else {
                          Swal.fire(
                            'Ooops!',
                            'selected entry is locked',
                            'info'
                          )
                        }
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center ms-1 ">
                    <label className="col-2 text-capitalize">
                      {localize('createdDate')}
                    </label>
                    <input
                      value={
                        formatDate(selectedPostingPeriod?.createdDate, true) ||
                        ''
                      }
                      type="text"
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center ms-1 ">
                    <label className="col-2 text-capitalize">
                      {localize('startDate')}
                    </label>
                    <input
                      value={
                        formatDate(
                          selectedPostingPeriod?.durationStartDate,
                          true
                        ) || ''
                      }
                      type="text"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center ms-1 ">
                    <label className="col-2 text-capitalize">
                      {localize('endDate')}
                    </label>
                    <input
                      value={
                        formatDate(
                          selectedPostingPeriod?.durationEndDate,
                          true
                        ) || ''
                      }
                      type="text"
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>

                <ModalFooter className="bg-white mb-2 mt-2">
                  <button
                    className="btn btn-success text-capitalize"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    {localize('update')}
                  </button>
                </ModalFooter>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PostingPeriodClosing
