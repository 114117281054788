import { getData } from "../webApi";

export async function checkIfIsAdministrative(customerId) {
    const { success, data } = await getData(
        '/lookup/customer/customer-is-administrative',
        { customerId },
        false
    )
    if (success) {
        return data?.result
    }
    return false
}

export async function evaluateLatestIncome(customerId, loanProductId) {
    const { success, data } = await getData('/loanOrigination/evaluate-latest-income', { customerId, loanProductId }, false)
    if (success) {
        return data
    }
    return false
}