/** @format */

import { postData } from '../../../../Helpers/webApi'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { AddRolesModal } from './AddRolesModal'
import { ViewUserRoleRelationship } from './ViewUserRoleRelationship'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

function RolesModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedRole,
  setSelectedRole,
  modulesAccessControlList,
  operationsAccessControlList,
  usersInRoleList,
  tableMeta,
  setTableMeta,
  reqParams,
  setReqParams,
}) {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  function clearValidation(column) {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  async function handleSubmit() {
    const { success } = await postData(
      '/Role/create',
      {
        ...selectedRole,
      },
      true,
      'Add Role'
    )

    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('roles')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            hideUpdate={mode === 'view'}
            hideCancel={mode === 'view'}
            modalClassName={mode === 'view' ? 'modal-xl' : 'modal-lg'}
          >
            {mode === 'add' && (
              <AddRolesModal
                clearValidation={r => {
                  clearValidation(r)
                }}
                validationBag={validationBag}
                rolesModel={selectedRole}
                setRolesModel={setSelectedRole}
              />
            )}
            {mode === 'view' && (
              <ViewUserRoleRelationship
                tableMeta={tableMeta}
                setTableMeta={setTableMeta}
                reqParams={reqParams}
                setReqParams={setReqParams}
                roleBeingViewed={selectedRole}
                modulesAccessControlListDetails={modulesAccessControlList}
                operationsAccessControlListDetails={operationsAccessControlList}
                usersInRoleDetails={usersInRoleList}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default RolesModal
