/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../../Global/Localize'
import ChartOfAccountsLookup from '../../../Components/Lookup/ChartOfAccountsLookup'
import { ProductSection, checkoffEntryType } from '../../../Global/enumeration'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../Helpers/formatters'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import Tab from '../../../Components/Tabs/Tab'
import MembershipClassLookup from '../../../Components/Lookup/MembershipClassLookup'
import { getData, postData, putData } from '../../../Helpers/webApi'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import Swal from 'sweetalert2'
import moment from 'moment'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'
import NonBlockingLoader from '../../../Components/Loaders/NonBlockingLoader'
import { MaskNumber } from '../../../Components/InputMasks/MaskNumber'

function EditInvestmentProducts({
  modalMode,
  readonly,
  close,
  product,
  refresh,
}) {
  const exCols = [
    {
      label: localize('membershipClass'),
      template: r => <>{r.membershipClassDescription}</>,
    },
    {
      label: localize('maximumBalance'),
      headerTextAlign: 'end',
      template: r => <>{formatCurrency(r.maximumBalance)}</>,
    },
    {
      label: localize('appraisalMultiplier'),
      template: r => <>{r.appraisalMultiplier}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [exemps, setExemps] = useState(null)
  const [iProduct, setIProduct] = useState(product)
  const [tempExemp, setTempExemp] = useState(null)
  const [tempExempMode, setTempExempMode] = useState('add') // add or edit

  useEffect(() => {
    modalMode !== 'add' && loadProduct()
    modalMode !== 'add' && loadExemptions()
    // loadExemptions()
    // eslint-disable-next-line
  }, [])

  async function handleCreate() {
    setIsBusy(true)

    const { success } = await postData(
      '/investmentProduct/create-investment-products',
      iProduct,
      false
    )
    if (success) {
      refresh()
      close()
      Swal.fire('Successful', 'Investment Product created', 'success')
    } else {
      Swal.fire('Failed', 'Investment Product not created', 'error')
    }
    setIsBusy(false)
  }

  async function handleUpdate() {
    setIsBusy(true)
    let exs = [...exemps].map(e => {
      if (e.id.startsWith('temp')) delete e.id
      return e
    })
    const { success } = await putData(
      '/investmentProduct/update-investment-product-with-exemptions',
      { investmentProduct: iProduct, exemptions: exs },
      false
    )
    if (success) {
      refresh()
      close()
      Swal.fire('Successful', 'Investment Product Updated', 'success')
    } else {
      Swal.fire('Failed', 'Investment Product not updated', 'error')
    }
    setIsBusy(false)
  }

  async function loadProduct() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/investmentProduct/find-investment-product-by-id',
      { investmentProductId: product?.id },
      false
    )
    if (success) {
      setIProduct(data)
    }
    setIsBusy(false)
  }

  async function loadExemptions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/investmentProduct/find-investment-product-exemptions-by-investment-product-id',
      { investmentProductId: iProduct?.id },
      false
    )
    if (success) {
      setExemps(data.result)
    }
    setIsBusy(false)
  }

  async function handleTempAdd() {
    if (
      tempExemp &&
      tempExemp?.membershipClassId &&
      tempExemp?.appraisalMultiplier &&
      tempExemp?.maximumBalance
    ) {
      setExemps([
        ...exemps,
        { ...tempExemp, id: 'tempExp' + moment().format('YYMMDHHmmss') },
      ])
      setTempExemp({ maximumBalance: 0, appraisalMultiplier: 0 })
    } else {
      Swal.fire('Error', "Cant't add exemption", 'error')
    }
  }

  async function handleTempUpdate() {
    let tempExempsS = [...exemps]
    let newList = []
    for (let b of tempExempsS) {
      if (b.id === tempExemp.id) {
        newList.push(tempExemp)
      } else {
        newList.push(b)
      }
    }
    setExemps(newList)
    setTempExempMode('add')
    setTempExemp({ maximumBalance: 0, appraisalMultiplier: 0 })
  }

  const tabItems = [
    {
      label: localize('exemptions'),
      item: (
        <>
          {modalMode === 'edit' && (
            <>
              <h5 className="text-capitalize">
                {localize('exemptionDetails')}
              </h5>
              <div className="d-flex align-items-center gap-2 p-2">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize col-4">
                      {localize('membershipClass')}
                    </label>
                    <MembershipClassLookup
                      displayValue={tempExemp?.membershipClassDescription}
                      onSelect={v =>
                        setTempExemp({
                          ...tempExemp,
                          membershipClassDescription: v.description,
                          membershipClassId: v.id,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label htmlFor="" className="col-4 text-capitalize">
                      {localize('maximumBalance')}
                    </label>
                    <MaskNumber
                      type="number"
                      name=""
                      id=""
                      className="form-control"
                      defaultMaskValue={tempExemp?.maximumBalance}
                      onMaskNumber={e =>
                        setTempExemp({
                          ...tempExemp,
                          maximumBalance: stripNonNumericCharacters(
                            e.target.value
                          ),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center">
                    <label htmlFor="" className="col-4 text-capitalize">
                      {localize('appraisalMultiplier')}
                    </label>
                    <input
                      type="number"
                      name=""
                      id=""
                      className="form-control"
                      value={tempExemp?.appraisalMultiplier}
                      onChange={e =>
                        setTempExemp({
                          ...tempExemp,
                          appraisalMultiplier: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {isBusy || (
                <div className="row m-1 d-flex justify-content-end">
                  {tempExempMode === 'edit' && (
                    <button
                      className="btn btn-sm btn-outline-danger text-uppercase px-5 me-2"
                      style={{ maxWidth: 'fit-content' }}
                      onClick={() => {
                        setTempExemp(null)
                        setTempExempMode('add')
                      }}
                    >
                      {localize('cancel')}
                    </button>
                  )}
                  <button
                    className="btn btn-sm btn-success text-uppercase px-5"
                    style={{ maxWidth: 'fit-content' }}
                    onClick={
                      tempExempMode === 'add' ? handleTempAdd : handleTempUpdate
                    }
                  >
                    {localize(tempExempMode === 'add' ? 'add' : 'update')}
                  </button>
                </div>
              )}
            </>
          )}
          <div>
            <h5 className=" text-capitalize ">{localize('exemptions')}</h5>
            {exemps && (
              <div style={{ width: '100%' }}>
                <SimpleTable
                  columns={exCols}
                  data={exemps}
                  contextMenu={
                    modalMode === 'edit'
                      ? r => (
                          <>
                            <div
                              className="ctxt-menu-item"
                              onClick={() => {
                                setTempExemp(r)
                                setTempExempMode('edit')
                              }}
                            >
                              <i className="uil uil-edit-alt"></i>
                              <span className="text-capitalize">
                                {localize('edit')}
                              </span>
                            </div>
                            <div
                              className="ctxt-menu-item text-danger"
                              onClick={() => {
                                let ts = exemps
                                let found = ts.findIndex(t => t.id === r.id)
                                ts.splice(found, 1)
                                setExemps(ts)
                              }}
                            >
                              <i className="mdi mdi-delete-empty"></i>
                              <span className="text-capitalize">
                                {localize('remove')}
                              </span>
                            </div>
                          </>
                        )
                      : null
                  }
                />
              </div>
            )}
          </div>

          <div className="row"></div>
        </>
      ),
    },
    {
      label: localize('flags'),
      item: (
        <div className="row p-2">
          <div className="col">
            <div className="d-flex align-items-center gap-2 mb-3">
              <Checkbox
                disabled={readonly}
                id="iPIsRefundableEdit"
                checked={iProduct?.isRefundable}
                onCheck={v => setIProduct({ ...iProduct, isRefundable: v })}
              />
              <label htmlFor="iPIsRefundableEdit" className="text-capitalize">
                {localize('isRefundable')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="iPIsInstitutionalCapital"
                checked={iProduct?.isInstitutionalCapital}
                onCheck={v =>
                  setIProduct({ ...iProduct, isInstitutionalCapital: v })
                }
              />
              <label
                htmlFor="iPIsInstitutionalCapital"
                className="text-capitalize"
              >
                {localize('isInstitutionalCapital')}?
              </label>
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center gap-2 mb-3">
              <Checkbox
                disabled={readonly}
                id="iPIsPooled"
                checked={iProduct?.isPooled}
                onCheck={v => setIProduct({ ...iProduct, isPooled: v })}
              />
              <label htmlFor="iPIsPooled" className="text-capitalize">
                {localize('isPooled')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="iPIsLocked"
                checked={iProduct?.isLocked}
                onCheck={v => setIProduct({ ...iProduct, isLocked: v })}
              />
              <label htmlFor="iPIsLocked" className="text-capitalize">
                {localize('isLocked')}?
              </label>
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center gap-2 mb-3">
              <Checkbox
                disabled={readonly}
                id="iPIsSuperSaver"
                checked={iProduct?.isSuperSaver}
                onCheck={v => setIProduct({ ...iProduct, isSuperSaver: v })}
              />
              <label htmlFor="iPIsSuperSaver" className="text-capitalize">
                {localize('isSuperSaver')}?
              </label>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                disabled={readonly}
                id="ipConcealMBankingListing"
                checked={iProduct?.concealBankToMobileListing}
                onCheck={v =>
                  setIProduct({ ...iProduct, concealBankToMobileListing: v })
                }
              />
              <label
                htmlFor="ipConcealMBankingListing"
                className="text-capitalize"
              >
                {localize('concealMBankingListing')}?
              </label>
            </div>
          </div>
        </div>
      ),
    },
  ]

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      handleClose={close}
      modalMode={modalMode}
      modalTitle={
        modalMode === 'add'
          ? localize('investmentProduct')
          : product?.description
      }
      disableCreate={isBusy}
      handleAction={modalMode === 'add' ? handleCreate : handleUpdate}
      hideUpdate={modalMode === 'view'}
    >
      <div style={{ position: 'relative' }}>
        <div className="row mb-2">
          <div className="col">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-5">
                {localize('name')}
              </label>
              <input
                disabled={readonly}
                type="text"
                className="form-control"
                value={iProduct?.description}
                onChange={e =>
                  setIProduct({ ...iProduct, description: e.target.value })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('minimumBalance')}
              </label>
              <MaskNumber
                disabled={readonly}
                type="number"
                name=""
                id=""
                className="form-control"
                defaultMaskValue={iProduct?.minimumBalance}
                onMaskNumber={e =>
                  setIProduct({
                    ...iProduct,
                    minimumBalance: stripNonNumericCharacters(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('maximumBalance')}
              </label>
              <MaskNumber
                disabled={readonly}
                type="number"
                name=""
                id=""
                className="form-control"
                defaultMaskValue={iProduct?.maximumBalance}
                onMaskNumber={e =>
                  setIProduct({
                    ...iProduct,
                    maximumBalance: stripNonNumericCharacters(e.target.value),
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <div className="d-flex align-items-center">
              <label className="text-capitalize col-5">
                {localize('maturityPeriod')} ({localize('days')})
              </label>
              <input
                disabled={readonly}
                type="number"
                className="form-control"
                value={iProduct?.maturityPeriod}
                onChange={e =>
                  setIProduct({
                    ...iProduct,
                    maturityPeriod: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('gLAccount')}
              </label>
              <ChartOfAccountsLookup
                disabled={readonly}
                displayValue={iProduct?.chartOfAccountName}
                onSelect={c =>
                  setIProduct({
                    ...iProduct,
                    chartOfAccountName: c.description,
                    chartOfAccountId: c.id,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('annualPercentageYield')}
              </label>
              <input
                disabled={readonly}
                type="number"
                name=""
                id=""
                className="form-control"
                value={iProduct?.annualPercentageYield}
                onChange={e =>
                  setIProduct({
                    ...iProduct,
                    annualPercentageYield: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('interestExpenseGLAccount')}
              </label>
              <ChartOfAccountsLookup
                disabled={readonly}
                displayValue={iProduct?.interestExpenseChartOfAccountName}
                onSelect={c =>
                  setIProduct({
                    ...iProduct,
                    interestExpenseChartOfAccountName: c.description,
                    interestExpenseChartOfAccountId: c.id,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('interestPayableGLAccount')}
              </label>
              <ChartOfAccountsLookup
                disabled={readonly}
                displayValue={iProduct?.interestPayableChartOfAccountName}
                onSelect={c =>
                  setIProduct({
                    ...iProduct,
                    interestPayableChartOfAccountName: c.description,
                    interestPayableChartOfAccountId: c.id,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('recoveryPriority')}
              </label>
              <input
                disabled={readonly}
                type="number"
                name=""
                id=""
                className="form-control"
                value={iProduct?.recoveryPriority}
                onChange={e =>
                  setIProduct({
                    ...iProduct,
                    recoveryPriority: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('poolGLAccount')}
              </label>
              <ChartOfAccountsLookup
                disabled={readonly}
                displayValue={iProduct?.poolChartOfAccountName}
                onSelect={c =>
                  setIProduct({
                    ...iProduct,
                    poolChartOfAccountName: c.description,
                    poolChartOfAccountId: c.id,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('poolAmount')}
              </label>
              <MaskNumber
                disabled={readonly}
                type="number"
                className="form-control"
                id=""
                defaultMaskValue={iProduct?.poolAmount}
                onMaskNumber={e =>
                  setIProduct({
                    ...iProduct,
                    poolAmount: stripNonNumericCharacters(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('productSection')}
              </label>
              <EnumsServerCombo
                disabled={readonly}
                customContainerStyle={{ width: '100%' }}
                enumsKey="ProductSection"
                value={iProduct?.productSection}
                onChange={e =>
                  setIProduct({
                    ...iProduct,
                    productSection: e.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('checkoffEntryType')}
              </label>
              <EnumsServerCombo
                disabled={readonly}
                customContainerStyle={{ width: '100%' }}
                enumsKey="CheckoffEntryType"
                value={iProduct?.checkoffEntryType}
                onChange={e =>
                  setIProduct({
                    ...iProduct,
                    checkoffEntryType: e.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="text-capitalize col-5">
                {localize('billReferenceNumber')}
              </label>
              <input
                disabled={readonly}
                type="text"
                className="form-control"
                id=""
                value={iProduct?.billReferenceNumber}
                onChange={e =>
                  setIProduct({
                    ...iProduct,
                    billReferenceNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <Tab
            tabItems={
              modalMode === 'view' || modalMode === 'edit'
                ? tabItems
                : [tabItems[1]]
            }
          />
        </div>
        {isBusy && <NonBlockingLoader />}
      </div>

      {/* <Modal isOpen className="modal-xl">
      <ModalHeader className="text-capitalize bg-white">
        {localize(editMode ? 'edit' : 'add')}
        <i className="uil uil-angle-right mx-2"></i>
        <span className="text-muted fs-6 text-uppercase">
          {localize('investmentProducts')}
        </span>

        {isBusy || (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </ModalHeader>
      <ModalBody className="bg-white">
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-5">
                    {localize('name')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={iProduct?.description}
                    onChange={e =>
                      setIProduct({ ...iProduct, description: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('minimumBalance')}
                  </label>
                  <input
                    type="number"
                    name=""
                    id=""
                    className="form-control"
                    value={iProduct?.minimumBalance}
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        minimumBalance: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('maximumBalance')}
                  </label>
                  <input
                    type="number"
                    name=""
                    id=""
                    className="form-control"
                    value={iProduct?.maximumBalance}
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        maximumBalance: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label className="text-capitalize col-5">
                    {localize('maturityPeriod')} ({localize('days')})
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={iProduct?.maturityPeriod}
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        maturityPeriod: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('gLAccount')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={iProduct?.chartOfAccountName}
                    onSelect={c =>
                      setIProduct({
                        ...iProduct,
                        chartOfAccountName: c.description,
                        chartOfAccountId: c.id,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('annualPercentageYield')}
                  </label>
                  <input
                    type="number"
                    name=""
                    id=""
                    className="form-control"
                    value={iProduct?.annualPercentageYield}
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        annualPercentageYield: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('interestExpenseGLAccount')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={iProduct?.interestExpenseChartOfAccountName}
                    onSelect={c =>
                      setIProduct({
                        ...iProduct,
                        interestExpenseChartOfAccountName: c.description,
                        interestExpenseChartOfAccountId: c.id,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('interestPayableGLAccount')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={iProduct?.interestPayableChartOfAccountName}
                    onSelect={c =>
                      setIProduct({
                        ...iProduct,
                        interestPayableChartOfAccountName: c.description,
                        interestPayableChartOfAccountId: c.id,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('recoveryPriority')}
                  </label>
                  <input
                    type="number"
                    name=""
                    id=""
                    className="form-control"
                    value={iProduct?.recoveryPriority}
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        recoveryPriority: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('poolGLAccount')}
                  </label>
                  <ChartOfAccountsLookup
                    displayValue={iProduct?.poolChartOfAccountName}
                    onSelect={c =>
                      setIProduct({
                        ...iProduct,
                        poolChartOfAccountName: c.description,
                        poolChartOfAccountId: c.id,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('poolAmount')}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id=""
                    value={iProduct?.poolAmount}
                    onChange={e =>
                      setIProduct({ ...iProduct, poolAmount: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('productSection')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="ProductSection"
                    value={iProduct?.productSection}
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        productSection: e.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('checkoffEntryType')}
                  </label>
                  <select
                    className="form-select"
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        checkoffEntryType: e.target.value,
                      })
                    }
                  >
                    {checkoffEntryType.map(({ name }, i) => (
                      <option value={i}>{name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="text-capitalize col-5">
                    {localize('billReferenceNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    value={iProduct?.billReferenceNumber}
                    onChange={e =>
                      setIProduct({
                        ...iProduct,
                        billReferenceNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col"></div>
            </div>
            <div className="row">
              <Tab tabItems={editMode ? tabItems : [tabItems[1]]} />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="bg-white">
        {isBusy ? (
          <div className="d-flex align-items-center text-primary">
            <i className="m-2 spinner-border spinner-sm text-primary"></i>
            <span className="text-primary fw-bold fs-4 text-capitalize">
              {localize('pleaseWait')}...
            </span>
          </div>
        ) : (
          <>
            <button className="btn btn-danger text-uppercase" onClick={close}>
              {localize('cancel')}
            </button>
            <button
              className="btn btn-success text-uppercase px-4"
              onClick={editMode ? handleUpdate : handleCreate}
            >
              {localize(editMode ? 'update' : 'create')}
            </button>
          </>
        )}
      </ModalFooter>
    </Modal> */}
    </ModalTemplate>
  )
}

export default EditInvestmentProducts
