/** @format */

/* eslint-disable */
import React from 'react'

import './Custom.scss'

function BreadCrumb({ tree }) {
  return (
    <div className="row">
      <div className="col-12 col">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0" style={{ textTransform: 'capitalize' }}>
            {tree[tree.length - 1]}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {tree.map((t, i) => (
                <li
                  key={i}
                  style={{ textTransform: 'capitalize' }}
                  className={`breadcrumb-item ${
                    i === tree.length - 1 ? 'active' : ''
                  }`}
                >
                  <a href="/#">{t}</a>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreadCrumb
