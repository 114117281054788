/** @format */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import DatePicker from '../../../../Components/Date/DatePicker'
import Loader from '../../../../Components/Loaders/Loader'
import RelationshipManagerLookup from '../../../../Components/Lookup/RelationshipManagerLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import {
  ticketCategory,
  ticketMode,
  ticketPriority,
  ticketReferenceType,
  ticketStatus,
} from '../../../../Global/enumeration'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { CustomerPartial } from '../../../../Components/ReUsables/Customer/CustomerPartial'
import Swal from 'sweetalert2'
import EmployerLookup from '../../../../Components/Lookup/EmployerLookup'
import ViewTicketModal from './ViewTicketModal'
import ResolveTicketModal from './ResolveTicketModal'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'

const Tickets = () => {
  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, false)}</>,
    },
    {
      label: localize('reference#'),
      template: r => <>{r.paddedReferenceNumber}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r.categoryDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    {
      label: localize('mode'),
      template: r => <>{r.modeDescription}</>,
    },
    {
      label: localize('referenceType'),
      template: r => <>{r.referenceTypeDescription}</>,
    },
    {
      label: localize('title'),
      template: r => <>{r.title}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.secondaryDescription}</>,
    },
    {
      label: localize('assignedTo'),
      template: r => <>{r.relationshipManagerEmployeeCustomerFullName}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('resolvedDate'),
      template: r => <>{formatDate(r.resolvedDate, true)}</>,
    },
    {
      label: localize('resolvedBy'),
      template: r => <>{r.resolvedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    category: 3,
    status: 0,
    startDate: null,
    endDate: null,
    dateRequestsFilter: 15,
    customDate: false,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedTicket, setSelectedTicket] = useState({
    mode: Object.keys(ticketMode)[0],
    category: Object.keys(ticketCategory)[3],
    priority: Object.keys(ticketPriority)[0],
    referenceType: Object.keys(ticketReferenceType)[1],
  })
  const [refreshAt, setRefreshAt] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/Ticket/update-ticket',
            selectedTicket,
            false,
            'Operation Completed Successfully'
          )
        : await postData(
            '/Ticket/create-ticket',
            selectedTicket || {
              title: '',
            },
            false,
            'Operation Completed Successfully'
          )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedTicket(null)
      setRefreshAt(!refreshAt)
      setSelectedCustomer(null)
    } else {
      setIsBusy(false)
    }
  }

  function closeModal() {
    setMode(null)
    setSelectedTicket(null)
    setGlobalValidationErrors({})
    setSelectedCustomer(null)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Ticket/find-ticket-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedTicket({
      mode: Object.keys(ticketMode)[0],
      category: Object.keys(ticketCategory)[3],
      priority: Object.keys(ticketPriority)[0],
      referenceType: Object.keys(ticketReferenceType)[1],
    })
  }

  const handleOnSelectCustomer = customer => {
    if (!customer.isLocked) {
      setSelectedCustomer({
        ...selectedCustomer,
        ...customer,
      })
    } else {
      Swal.fire({
        icon: 'info',
        text: 'Selected Customer is Locked',
        title: 'Ooops!',
        showCloseButton: true,
      })
      setSelectedCustomer(null)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('helpDesk'),
          localize('tickets'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton
                      handleClickAddButton={() => {
                        handleAdd()
                      }}
                    />
                  </div>
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-3"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center col-2">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="TicketCategory"
                        value={reqParams?.category}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            category: e.value,
                          })
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center col-2">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="TicketStatus"
                        value={reqParams?.status}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            status: e.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex align-items-center">
                        <label
                          className="text-capitalize col-md-3"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <label
                        className="ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.filterText}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="delinquencyStatusQueryFilterButton"
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            onClick={async () => {
                              setMode('view')
                              setSelectedTicket(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          <div
                            onClick={async () => {
                              if (r.status == 3) {
                                return Swal.fire({
                                  icon: 'info',
                                  text: 'Sorry Ticket Closed!',
                                  title: 'Ooops!',
                                  showCloseButton: true,
                                })
                              } else {
                                clearValidation('AssignedToId')
                                setMode('edit')
                                setSelectedTicket(r)
                              }
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            onClick={async () => {
                              if (r.status == 3) {
                                return Swal.fire({
                                  icon: 'info',
                                  text: 'Sorry Ticket Closed!',
                                  title: 'Ooops!',
                                  showCloseButton: true,
                                })
                              } else {
                                setMode('resolve')
                                setSelectedTicket(r)
                              }
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-assistive-listening-systems"></i>
                            {localize('resolve')}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <>
          <ModalTemplate
            modalMode={mode}
            modalTitle="ticket"
            handleClose={closeModal}
            handleAction={handleSubmit}
            modalClassName="modal-xl"
            actionText={mode === 'add' ? 'create' : 'update'}
          >
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-1 text-capitalize ms-1">
                  {localize('referenceType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  value={selectedTicket?.referenceType}
                  enumsKey={'TicketReferenceType'}
                  onChange={e => {
                    setSelectedTicket({
                      ...selectedTicket,
                      referenceType: e.value,
                      referenceId: '',
                      secondaryDescription: '',
                    })
                    setSelectedCustomer(null)
                  }}
                />
              </div>
            </div>
            {selectedTicket?.referenceType == 1 ? (
              <div className="ms-2">
                <CustomerPartial
                  customer={selectedCustomer}
                  onCustomerLookup={r => {
                    clearValidation('SecondaryDescription')
                    handleOnSelectCustomer(r)
                    setSelectedTicket({
                      ...selectedTicket,
                      referenceId: r.id,
                      secondaryDescription: r.fullName,
                    })
                  }}
                />
              </div>
            ) : selectedTicket?.referenceType == 2 ? (
              <>
                <div className="row mb-2">
                  <div className="col-3 d-flex align-items-center mb-2 ms-1">
                    <label className="col-4 text-capitalize me-1">
                      {localize('employer')}
                    </label>
                    <EmployerLookup
                      displayValue={selectedTicket?.secondaryDescription}
                      onSelect={r => {
                        clearValidation('SecondaryDescription')
                        setSelectedTicket({
                          ...selectedTicket,
                          referenceId: r.id,
                          secondaryDescription: r.description,
                          assignedToEmployeeCustomerAddressEmail:
                            r.addressEmail,
                          assignedToEmployeeCustomerAddressMobileLine:
                            r.addressMobileLine,
                        })
                      }}
                    />
                  </div>
                  <div className="col-3 d-flex align-items-center mb-2">
                    <label className="col-3 text-capitalize ms-3 me-4">
                      {localize('email')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        selectedTicket?.assignedToEmployeeCustomerAddressEmail ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center mb-2">
                    <label className="col-2 text-capitalize ms-1">
                      {localize('mobileLine')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        selectedTicket?.assignedToEmployeeCustomerAddressMobileLine ||
                        ''
                      }
                      disabled
                    />
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            <div className="row mb-2">
              <div
                className="col d-flex align-items-center mb-2 ms-2 with-validation"
                validation-message={
                  globalValidationErrors['RelationshipManagerId']?.message
                }
              >
                <label className="col-4 text-capitalize me-1">
                  {localize('assignedTo')}
                </label>
                <RelationshipManagerLookup
                  displayValue={
                    selectedTicket?.relationshipManagerEmployeeCustomerFullName
                  }
                  onSelect={r => {
                    clearValidation('RelationshipManagerId')
                    setSelectedTicket({
                      ...selectedTicket,
                      relationshipManagerId: r.id,
                      assignedToId: r.id,
                      relationshipManagerEmployeeCustomerFullName:
                        r.employeeCustomerFullName,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2 ms-3">
                <label className="col-4 text-capitalize">
                  {localize('mode')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  value={selectedTicket?.mode}
                  enumsKey={'TicketMode'}
                  onChange={e => {
                    setSelectedTicket({
                      ...selectedTicket,
                      mode: e.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2 ms-2">
                <label className="col-4 text-capitalize">
                  {localize('category')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  value={selectedTicket?.category}
                  enumsKey={'TicketCategory'}
                  onChange={e => {
                    setSelectedTicket({
                      ...selectedTicket,
                      category: e.value,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2 ms-2">
                <label className="col-4 text-capitalize">
                  {localize('priority')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  value={selectedTicket?.priority}
                  enumsKey={'TicketPriority'}
                  onChange={e => {
                    setSelectedTicket({
                      ...selectedTicket,
                      priority: e.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div
                className="col d-flex align-items-center mb-2 with-validation"
                validation-message={globalValidationErrors['Title']?.message}
              >
                <label className="col-1 text-capitalize ms-1">
                  {localize('title')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedTicket?.title || ''}
                  onChange={e => {
                    clearValidation('Title')
                    setSelectedTicket({
                      ...selectedTicket,
                      title: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col d-flex align-items-center mb-2">
                <label className="col-1 text-capitalize ms-1">
                  {localize('description')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedTicket?.description || ''}
                  onChange={e => {
                    setSelectedTicket({
                      ...selectedTicket,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            {selectedTicket?.referenceType == 0 ? (
              <div className="row mb-2">
                <div
                  className="col d-flex align-items-center mb-2 with-validation"
                  validation-message={
                    globalValidationErrors['SecondaryDescription']?.message
                  }
                >
                  <label className="col-1 text-capitalize ms-1">
                    {localize('secondaryDescription')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedTicket?.secondaryDescription || ''}
                    onChange={e => {
                      clearValidation('SecondaryDescription')
                      setSelectedTicket({
                        ...selectedTicket,
                        secondaryDescription: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </ModalTemplate>
        </>
      )}
      {mode === 'view' ? (
        <ViewTicketModal
          mode={mode}
          selectedTicket={selectedTicket}
          closeModal={() => {
            setMode(null)
            setSelectedTicket(null)
          }}
        />
      ) : (
        ''
      )}
      {mode === 'resolve' ? (
        <ResolveTicketModal
          mode={mode}
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          closeModal={() => {
            setMode(null)
            setSelectedTicket(null)
          }}
          loadTableData={loadTableData}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Tickets
