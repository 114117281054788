/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import CustomersLookup from '../../../../Components/Lookup/CustomersLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import { ViewCustomerAddress } from '../../../../Components/ReUsables/Customer/ViewCustomerAddress'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../Helpers/formatters'
import { postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const DelegatesModal = ({
  mode,
  selectedDelegate,
  setSelectedDelegate,
  closeModal,
  loadTableData,
}) => {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/Delegate/update',
            selectedDelegate || {
              description: '',
            },
            false
          )
        : await postData(
            '/Delegate/create',
            selectedDelegate || {
              description: '',
            },
            false
          )

    if (success) {
      handleClose()
      loadTableData()
      showNotification('Delegates', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleClose = () => {
    closeModal()
    setGlobalValidationErrors({})
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalTitle={'delegate'}
      modalMode={mode}
      handleAction={handleSubmit}
      handleClose={handleClose}
      footerChildren={
        <>
          <div className="d-flex align-items-center me-3 gap-2">
            <Checkbox
              id="cDelegateIsAdministrative"
              onCheck={e => {
                setSelectedDelegate({
                  ...selectedDelegate,
                  isAdministrative: e,
                })
              }}
              checked={selectedDelegate?.isAdministrative}
            />
            <label
              htmlFor="cDelegateIsAdministrative"
              className="text-capitalize"
            >
              {localize('isAdministrative')}?
            </label>
            <div className="mx-3"></div>
            <Checkbox
              id="cDelegateIsLocked"
              onCheck={e => {
                setSelectedDelegate({
                  ...selectedDelegate,
                  isLocked: e,
                })
              }}
              checked={selectedDelegate?.isLocked}
            />
            <label htmlFor="cDelegateIsLocked" className="text-capitalize">
              {localize('isLocked')}?
            </label>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card bg-white">
                <div className="card-body">
                  <div className="row mb-2">
                    {mode === 'edit' ? (
                      <div
                        className="col-3 d-flex align-items-center with-validation"
                        validation-message={
                          globalValidationErrors['CustomerId']?.message
                        }
                      >
                        <label className="col-4 text-capitalize me-1">
                          {localize('customerName')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedDelegate?.fullName || ''}
                        />
                      </div>
                    ) : (
                      <div
                        className="col-3 d-flex align-items-center with-validation"
                        validation-message={
                          globalValidationErrors['CustomerId']?.message
                        }
                      >
                        <label className="col-4 text-capitalize me-1">
                          {localize('customerName')}
                        </label>

                        <CustomersLookup
                          displayValue={selectedDelegate?.fullName || ''}
                          onSelect={r => {
                            clearValidation('CustomerId')
                            setSelectedDelegate({
                              ...r,
                              customerId: r?.id,
                              customerFullName: r?.fullName,
                            })
                          }}
                        />
                      </div>
                    )}
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('customerType')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={
                          selectedDelegate?.membershipClassCustomerTypeDescription ||
                          ''
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('membershipStatus')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={
                          selectedDelegate?.membershipStatusDescription || ''
                        }
                      />
                    </div>

                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('joinDate')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={
                          formatDate(
                            selectedDelegate?.registrationDate,
                            true
                          ) || ''
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center">
                      <label className="text-capitalize col-4 me-1">
                        {localize('domicileBranch')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={selectedDelegate?.branchDescription || ''}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('employerName')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={selectedDelegate?.employerDescription || ''}
                      />
                    </div>

                    <div className="col d-flex align-items-center">
                      <label className="text-capitalize col-4">
                        {localize('dutyWorkStation')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={selectedDelegate?.dutyStationDescription || ''}
                      />
                    </div>

                    <div className="col d-flex align-items-center">
                      <label className="text-capitalize col-4">
                        {localize('adminDivision')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={
                          selectedDelegate?.administrativeDivisionDescription ||
                          ''
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize me-1">
                        {localize('identificationNumber')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={selectedDelegate?.identificationNumber || ''}
                      />
                    </div>

                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('memberNumber')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={selectedDelegate?.reference2 || ''}
                      />
                    </div>

                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('accountNumber')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={selectedDelegate?.reference1 || ''}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-4 text-capitalize">
                        {localize('personalFileNumber')}
                      </label>
                      <input
                        type="text"
                        className="form-control ms-2"
                        disabled
                        value={selectedDelegate?.reference3 || ''}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <div className="d-flex align-items-center">
                        <label className="col-1  text-capitalize">
                          {localize('remarks')}
                        </label>
                        <input
                          value={selectedDelegate?.remarks || ''}
                          type="text"
                          className="form-control"
                          onChange={e => {
                            setSelectedDelegate({
                              ...selectedDelegate,
                              remarks: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-12">
                    <Tab
                      tabItems={[
                        {
                          label: localize('address'),
                          item: (
                            <ViewCustomerAddress customer={selectedDelegate} />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default DelegatesModal
