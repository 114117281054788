/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import {
  CustomerFilter,
  batchEntryStatus,
} from '../../../../../Global/enumeration'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'
import Pager from '../../../../../Components/Tables/Pager'
import ViewCustomerAccountBalanceSpoolEntryDataModal from './ViewCustomerAccountBalanceSpoolEntryDataModal'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
function CustomerAccountBalanceSpoolRequestEntries({
  selectedCustomerAccountBalanceSpoolRequest,
  setEntryCount,
  refreshEntry,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [
    selectedCustomerAccountBalanceSpoolRequestEntry,
    setSelectedCustomerAccountBalanceSpoolRequestEntry,
  ] = useState(null)
  const [mode, setMode] = useState(null)
  const [entries, setEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    status: 1,
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
  })
  const [search, setSearch] = useState('')

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
      sortBy: 'statusDescription',
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => (
        <>{r.customerAccountCustomerAccountTypeTargetProductDescription}</>
      ),
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('january'),
      template: r => <>{r.january}</>,
    },
    {
      label: localize('february'),
      template: r => <>{r.february}</>,
    },
    {
      label: localize('march'),
      template: r => <>{r.march}</>,
    },
    {
      label: localize('april'),
      template: r => <>{r.april}</>,
    },
    {
      label: localize('may'),
      template: r => <>{r.may}</>,
    },
    {
      label: localize('june'),
      template: r => <>{r.june}</>,
    },
    {
      label: localize('july'),
      template: r => <>{r.july}</>,
    },
    {
      label: localize('august'),
      template: r => <>{r.august}</>,
    },
    {
      label: localize('september'),
      template: r => <>{r.september}</>,
    },
    {
      label: localize('october'),
      template: r => <>{r.october}</>,
    },
    {
      label: localize('november'),
      template: r => <>{r.november}</>,
    },
    {
      label: localize('december'),
      template: r => <>{r.december}</>,
    },
    {
      label: localize('ref_1(Account #)'),
      template: r => <>{r.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('ref_2(Membership #)'),
      template: r => <>{r.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('ref_3(Personal File #)'),
      template: r => <>{r.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const loadCustomerAccountBalanceSpoolRequestEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/CustomerAccountBalanceSpool/find-customer-account-balance-spool-entries-by-filter-in-page`,
      {
        customerAccountBalanceSpoolId:
          selectedCustomerAccountBalanceSpoolRequest.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setEntries(data)
      setEntryCount(data.totalItems)
    } else {
      setIsBusy(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadCustomerAccountBalanceSpoolRequestEntries()
  }, [
    selectedCustomerAccountBalanceSpoolRequest?.id,
    reqParams.pageSize,
    reqParams.pageIndex,
    refreshEntry,
  ])

  const handleReset = reg => {
    setIsBusy(true)
    const { success } = putData(
      '/CustomerAccountBalanceSpool/unpost-customer-account-balance-spool-entry',
      reg.id,
      false,
      'Operation Completed Successfully'
    )

    if (success) {
      setIsBusy(false)
      setMode(null)
      setSelectedCustomerAccountBalanceSpoolRequestEntry(null)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row px-3 mb-2">
            <div className="d-flex align-items-center col-2">
              <label
                htmlFor="recordsPerPage"
                className=""
                style={{ marginLeft: -10, textTransform: 'capitalize' }}
              >
                {localize('recordsPerPage')}
              </label>
              <select
                name=""
                id="recordsPerPage"
                className="form-select ms-1"
                style={{ maxWidth: 'fit-content' }}
                defaultValue={reqParams.pageSize}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    pageIndex: 0,
                    pageSize: e.target.value,
                  })
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="100">200</option>
                <option value="100">300</option>
                <option value="100">400</option>
                <option value="100">500</option>
                <option value="100">1000</option>
              </select>
            </div>
            <div className="d-flex align-items-center col-2">
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="BatchEntryStatus"
                value={reqParams?.status}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    status: e.value,
                  })
                }
              />
            </div>
            <form
              className="d-flex align-items-center col-8 col"
              onSubmit={e => {
                e.preventDefault()
                setReqParams({
                  ...reqParams,
                  filterText: search,
                })
              }}
            >
              <label className="ms-2 text-capitalize" htmlFor="searchTable">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerFilter"
                value={reqParams?.customerFilter}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    customerFilter: e.value,
                  })
                }
              />
              <input
                type="search"
                id="searchTable"
                className="form-control ms-2"
                defaultValue={reqParams.filterText}
                onChange={e => {
                  setSearch(e.target.value)
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                  })
                }}
              />
              <button
                id="bankToBankRequestFilterButton"
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  loadCustomerAccountBalanceSpoolRequestEntries()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </form>
          </div>
          <SimpleTable
            selectables
            columns={columns}
            data={entries.pageCollection}
            contextMenu={r => (
              <>
                <div
                  onClick={async () => {
                    setMode('view')
                    setSelectedCustomerAccountBalanceSpoolRequestEntry(r)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-eye"></i>
                  {localize('view')}
                </div>
                <div
                  onClick={async () => {
                    if (r.status == 1) {
                      Swal.fire(
                        'Ooops!',
                        'Sorry, but a pending entry can not be reset',
                        'info'
                      )
                    } else {
                      setMode('reset')
                      handleReset(r)
                    }
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-unlock-alt"></i>
                  {localize('reset')}
                </div>
              </>
            )}
          />
          <Pager
            itemsCount={entries.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          {mode === 'view' && (
            <ViewCustomerAccountBalanceSpoolEntryDataModal
              selectedCustomerAccountBalanceSpoolRequestEntry={
                selectedCustomerAccountBalanceSpoolRequestEntry
              }
              mode={mode}
              close={() => setMode(null)}
            />
          )}
        </>
      )}
    </>
  )
}
export default CustomerAccountBalanceSpoolRequestEntries
