/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { useState } from 'react'
import { AddUnpayReasonModal } from './AddUnpayReasonModal'
import { EditUnpayReasonModal } from './EditUnpayReasonModal'
import { postData } from '../../../../Helpers/webApi'
import swal from 'sweetalert2'
import { ViewUnpayReasonModal } from './ViewUnpayReasonModal'

function UnpayReasonsModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  waitingMetadata,
  selectedUnpayReason,
  setSelectedUnpayReason,
  onSetMode,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      return addUnpayReason()
    }
    if (mode === 'edit') {
      return editUnpayReason()
    }
  }

  const addUnpayReason = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/UnpayReason/add-unpay-reasons-with-commissions',
      {
        UnPayReason: { ...(selectedUnpayReason || {}) },
        Commissions:
          selectedUnpayReason.commissions.filter(x => x.checked) || [],
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedUnpayReason(null)

      swal.fire(
        'Add UnPay Reason',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editUnpayReason = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/UnpayReason/update-unpay-reasons-with-commissions',
      {
        UnPayReason: { ...(selectedUnpayReason || {}) },
        Commissions:
          selectedUnpayReason.commissions.filter(x => x.checked) || [],
      },
      false
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedUnpayReason(null)
      swal.fire(
        'Edit UnPay Reason',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('unpayReason')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <AddUnpayReasonModal
                isBusy={isBusy}
                setSelectedUnpayReason={setSelectedUnpayReason}
                selectedUnpayReason={selectedUnpayReason}
                waitingMetadata={waitingMetadata}
              />
            )}
            {mode === 'edit' && (
              <EditUnpayReasonModal
                isBusy={isBusy}
                setSelectedUnpayReason={setSelectedUnpayReason}
                selectedUnpayReason={selectedUnpayReason}
                waitingMetadata={waitingMetadata}
              />
            )}
            {mode === 'view' && (
              <ViewUnpayReasonModal selectedUnpayReason={selectedUnpayReason} />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default UnpayReasonsModal
