/** @format */
/* eslint-disable */
import React, { useEffect, useReducer, useState } from 'react'
import { customerDetailsReducer } from '../AccountStatements/Reducer/reducer'
import { AccountStatisticsTabs } from '../MergedStatements/Reducer/constants'
import { getData } from '../../../../../Helpers/webApi'
import {
  setCustomerAccountBalancesSummary,
  setCustomerCreditScores,
  setCustomersAccountListings,
  setSelectedCustomer,
} from '../AccountStatements/Reducer/actions'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { CustomerPartial } from '../../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../../Components/Tabs/Tab'
import { MergedStatementsAccountStatementsListing } from './Partials/MergedStatementsAccountStatementsListing'
import { AccountStatementsCreditScores } from '../AccountStatements/Partials/AccountStatementsCreditScores'
import { AccountMetadataLoanGuaranteed } from './Partials/AccountMetadata/AccountMetadataLoanGuaranteed'
import { AccountMetadataLoanGuarantors } from './Partials/AccountMetadata/AccountMetadataLoanGuarantors'
import { AccountMetadataLoanApplications } from './Partials/AccountMetadata/AccountMetadataLoanApplications'
import { AccountMetadataPayoutsHistory } from './Partials/AccountMetadata/AccountMetadataPayoutsHistory'
import { AccountMetadataLoanProductExemptions } from './Partials/AccountMetadata/AccountMetadataLoanProductExemptions'
import { AccountMetadataBankAccounts } from './Partials/AccountMetadata/AccountMetadataBankAccounts'
import { AccountMetadataIndividualParticulars } from './Partials/AccountMetadata/AccountMetadataIndividualParticulars'
import { ViewCustomerAddress } from '../../../../../Components/ReUsables/Customer/ViewCustomerAddress'
import { AccountMetadataReferees } from './Partials/AccountMetadata/AccountMetadataReferees'
import { ViewCustomerSpecimen } from '../../../../../Components/ReUsables/Customer/ViewCustomerSpecimen'
import { MergedStatementsEntries } from './Partials/MergedStatementsEntries'

const MergedStatements = () => {
  const customerDetails = {
    selectedCustomer: {},
    customersAccountListings: [],
    customerCreditScores: [],
    customerAddress: {},
    customerAccountBalancesSummary: {},
  }

  const [loading, setLoading] = useState({
    loadingAccountsListing: false,
    loadingCreditScores: false,
    loadingLoansGuaranteed: false,
    loadingLoanGuarantors: false,
    loadingLoanApplications: false,
    loadingPayoutsHistory: false,
    loadingLoanProductExemptions: false,
    loadingBankAccountBalances: false,
    loadingIndividualParticulars: false,
    loadingAddress: false,
    loadingReferees: false,
    loadingSpecimen: false,
  })
  const [customerDetailsState, dispatchCustomerDetails] = useReducer(
    customerDetailsReducer,
    customerDetails
  )
  const [activatedTab, setActivatedTab] = useState(
    AccountStatisticsTabs.AccountsListing
  )

  const [balanceSummaryProductSection, setBalanceSummaryProductSection] =
    useState('0')

  useEffect(() => {
    if (customerDetailsState.selectedCustomer.id) {
      fetchCustomerAccountDetails(customerDetailsState?.selectedCustomer)
    }
    //eslint-disable-next-line
  }, [balanceSummaryProductSection])

  const [loanGuaranteed, setLoanGuaranteed] = useState([])

  const [loanGuarantors, setLoanGuarantors] = useState([])
  const [loanApplications, setLoanApplications] = useState([])
  const [payoutsHistory, setPayoutsHistory] = useState([])
  const [loanProductExemptions, setLoanProductExemptions] = useState([])
  const [bankAccounts, setBankAccounts] = useState([])
  const [referees, setReferees] = useState([])

  const fetchCustomerStatisticsMetadata = async (customer, activeTab) => {
    switch (activeTab) {
      case AccountStatisticsTabs.AccountsListing:
        await fetchCustomerAccountDetails(customer)
        break
      case AccountStatisticsTabs.CreditScores:
        await fetchCustomerCreditScores(customer)
        break
      case AccountStatisticsTabs.LoansGuaranteed:
        await fetchLoanGuaranteed(customer)
        break
      case AccountStatisticsTabs.LoanGuarantors:
        await fetchLoanGuarantors(customer)
        break
      case AccountStatisticsTabs.LoanApplications:
        await fetchLoanApplications(customer)
        break
      case AccountStatisticsTabs.PayoutsHistory:
        await fetchPayoutsHistory(customer)
        break
      case AccountStatisticsTabs.BankAccountBalances:
        await fetchBankAccounts(customer)
        break
      case AccountStatisticsTabs.LoanProductExemptions:
        await fetchLoanProductExemptions(customer)
        break
      case AccountStatisticsTabs.Referees:
        await fetchReferees(customer)
        break
      default:
        break
    }
  }

  const fetchCustomerAccountDetails = async customer => {
    setLoading({ ...loading, loadingAccountDetails: true })
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id',
      {
        customerId: customer.id,
        includeBalances: true,
        includeProductDescription: true,
        balancesSummaryProductSection: balanceSummaryProductSection,
      },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingAccountDetails: false })
      dispatchCustomerDetails(setCustomersAccountListings(data))
      dispatchCustomerDetails(
        setCustomerAccountBalancesSummary({
          savingsBalance: data?.totalCustomerSavingsBalance,
          investmentsBalance: data?.totalCustomerRefundableInvestmentsBalance,
          loansBalance: data?.totalCustomerLoansBalance,
          principalArrearsBalance: data?.totalCustomerPrincipalArrearsBalance,
          interestArrearsBalance: data?.totalCustomerInterestArrearsBalance,
          netWorth: data?.totalCustomerNetWorth,
        })
      )
    }
  }

  const fetchCustomerCreditScores = async customer => {
    setLoading({ ...loading, loadingCreditScores: true })
    const { success, data } = await getData(
      '/Mobileloan/find-credit-scores-by-customer-id',
      { customerId: customer.id },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingCreditScores: false })
      dispatchCustomerDetails(setCustomerCreditScores(data.result))
    }
  }

  const handleOnChangeProductSection = r => {
    setBalanceSummaryProductSection(r.value)
  }

  const fetchLoanGuaranteed = async customer => {
    setLoading({ ...loading, loadingLoansGuaranteed: true })
    const { success, data } = await getData(
      '/LoanCase/find-loan-guarantor-by-customer-id',
      {
        customerId: customer.id,
        includeOutstandingBalance: true,
        filterClearedLoans: true,
      },
      false,
      null
    )
    if (success) {
      setLoanGuaranteed(data.result)
      setLoading({ ...loading, loadingLoansGuaranteed: false })
    }
  }

  const fetchLoanGuarantors = async customer => {
    setLoading({ ...loading, loadingLoanGuarantors: true })

    const { success, data } = await getData(
      '/LoanCase/find-loan-guarantors-by-loanee-customer-id',
      {
        loaneeCustomerId: customer.id,
        includeOutstandingBalance: true,
      },
      false,
      null
    )
    if (success) {
      setLoanGuarantors(data.result)
      setLoading({ ...loading, loadingLoanGuarantors: false })
    }
  }

  const fetchLoanApplications = async customer => {
    setLoading({ ...loading, loadingLoanApplications: true })
    const { success, data } = await getData(
      '/LoanCase/find-loan-cases-by-customer-id-in-process',
      {
        customerId: customer.id,
      },
      false,
      null
    )
    if (success) {
      setLoanApplications(data.result)
      setLoading({ ...loading, loadingLoanApplications: false })
    }
  }

  const fetchPayoutsHistory = async customer => {
    setLoading({ ...loading, loadingPayoutsHistory: true })
    const { success, data } = await getData(
      '/CreditBatch/find-credit-entries-by-customer-id',
      {
        customerId: customer.id,
        creditBatchType: '56026',
        includeProductDescription: true,
      },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingPayoutsHistory: false })
      setPayoutsHistory(data.result)
    }
  }

  const fetchLoanProductExemptions = async customer => {
    setLoading({ ...loading, loadingLoanProductExemptions: true })
    const { success, data } = await getData(
      '/LoanProductExemption/find-loan-product-exemption-entries-by-customer-id',
      {
        customerId: customer.id,
      },
      false,
      null
    )
    if (success) {
      setLoanProductExemptions(data.result)
      setLoading({ ...loading, loadingLoanProductExemptions: false })
    }
  }

  const fetchBankAccounts = async customer => {
    setLoading({ ...loading, loadingBankAccounts: true })
    const { success, data } = await getData(
      '/Customer/find-bank-account-collection-by-customer-id',
      {
        customerId: customer.id,
      },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingBankAccounts: false })
      setBankAccounts(data.result)
    }
  }

  const fetchReferees = async customer => {
    setLoading({ ...loading, loadingReferees: true })
    const { success, data } = await getData(
      '/Customer/find-referee-collection-by-customer-id',
      {
        customerId: customer.id,
      },
      false,
      null
    )
    if (success) {
      setLoading({ ...loading, loadingReferees: false })
      setReferees(data.result)
    }
  }

  const handleOnActivateTab = tabNo => {
    fetchCustomerStatisticsMetadata(
      customerDetailsState?.selectedCustomer,
      tabNo.tab
    )
    setActivatedTab(tabNo.tab)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('customerRelationshipManagement'),
          localize('customers'),
          localize('mergedStatements'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <CustomerPartial
                onCustomerLookup={r => {
                  dispatchCustomerDetails(setSelectedCustomer(r))
                  fetchCustomerStatisticsMetadata(r, activatedTab)
                }}
                customer={customerDetailsState.selectedCustomer}
              />
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('mobileLine')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      customerDetailsState?.selectedCustomer?.addressMobileLine
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize me-1">
                    {localize('reference1')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerDetailsState?.selectedCustomer?.reference1}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize me-1">
                    {localize('reference2')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerDetailsState?.selectedCustomer?.reference2}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize me-1">
                    {localize('reference3')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={customerDetailsState?.selectedCustomer?.reference3}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <Tab
                    onNavigate={handleOnActivateTab}
                    preload
                    tabItems={[
                      {
                        label: localize('mergedStatements'),
                        tab: AccountStatisticsTabs.MergedStatements,
                        item: (
                          <MergedStatementsEntries
                            selectedCustomer={
                              customerDetailsState?.selectedCustomer
                            }
                            selectedCustomerAccount={{}}
                            selectedPrintingCustomerAccount={{}}
                          />
                        ),
                      },
                      {
                        label: localize('accountsListing'),
                        tab: AccountStatisticsTabs.AccountsListing,
                        item: (
                          <MergedStatementsAccountStatementsListing
                            selectedCustomer={
                              customerDetailsState?.selectedCustomer
                            }
                            balanceSummary={
                              customerDetailsState?.customerAccountBalancesSummary
                            }
                            balanceSummaryProductSection={
                              balanceSummaryProductSection
                            }
                            accountStatementsListing={
                              customerDetailsState?.customersAccountListings
                            }
                            loading={loading.loadingAccountDetails}
                            onChangeProductSection={
                              handleOnChangeProductSection
                            }
                          />
                        ),
                      },
                      {
                        label: localize('creditScores'),
                        tab: AccountStatisticsTabs.CreditScores,
                        item: (
                          <AccountStatementsCreditScores
                            loading={loading.loadingCreditScores}
                            creditScores={
                              customerDetailsState.customerCreditScores
                            }
                          />
                        ),
                      },
                      {
                        label: localize('loansGuaranteed'),
                        tab: AccountStatisticsTabs.LoansGuaranteed,
                        item: (
                          <AccountMetadataLoanGuaranteed
                            data={loanGuaranteed}
                            loading={loading.loadingLoansGuaranteed}
                          />
                        ),
                      },
                      {
                        label: `${localize('loanGuarantors')}`,
                        tab: AccountStatisticsTabs.LoanGuarantors,
                        item: (
                          <AccountMetadataLoanGuarantors
                            data={loanGuarantors}
                            loading={loading.loadingLoanGuarantors}
                          />
                        ),
                      },
                      {
                        label: `${localize('loanApplications')}`,
                        item: (
                          <AccountMetadataLoanApplications
                            data={loanApplications}
                            loading={loading.loadingLoanApplications}
                          />
                        ),
                        tab: AccountStatisticsTabs.LoanApplications,
                      },
                      {
                        label: `${localize('payoutsHistory')}`,
                        item: (
                          <AccountMetadataPayoutsHistory
                            data={payoutsHistory}
                            loading={loading.loadingPayoutsHistory}
                          />
                        ),
                        tab: AccountStatisticsTabs.PayoutsHistory,
                      },
                      {
                        label: `${localize('loanProductExemptions')}`,
                        item: (
                          <AccountMetadataLoanProductExemptions
                            data={loanProductExemptions}
                            loading={loading.loadingLoanProductExemptions}
                          />
                        ),
                        tab: AccountStatisticsTabs.LoanProductExemptions,
                      },
                      {
                        label: `${localize('bankAccounts')}`,
                        item: (
                          <AccountMetadataBankAccounts
                            data={bankAccounts}
                            loading={loading.loadingBankAccountBalances}
                          />
                        ),
                        tab: AccountStatisticsTabs.BankAccountBalances,
                      },
                      {
                        label: `${localize('individualParticulars')}`,
                        item: (
                          <AccountMetadataIndividualParticulars
                            customer={customerDetailsState?.selectedCustomer}
                          />
                        ),
                        tab: AccountStatisticsTabs.IndividualParticulars,
                      },
                      {
                        label: `${localize('address')}`,
                        item: (
                          <ViewCustomerAddress
                            customer={customerDetailsState?.selectedCustomer}
                          />
                        ),
                        tab: AccountStatisticsTabs.Address,
                      },
                      {
                        label: `${localize('referees')}`,
                        item: (
                          <AccountMetadataReferees
                            data={referees}
                            loading={loading.loadingReferees}
                          />
                        ),
                        tab: AccountStatisticsTabs.Referees,
                      },
                      {
                        label: `${localize('specimen')}`,
                        item: (
                          <ViewCustomerSpecimen
                            readOnly
                            customer={customerDetailsState?.selectedCustomer}
                          />
                        ),
                        tab: AccountStatisticsTabs.Specimen,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MergedStatements
