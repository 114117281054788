/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import StandingOrderLookup from '../../../../../Components/Lookup/StandingOrderLookup'
import Tab from '../../../../../Components/Tabs/Tab'
import { CarryFowardsAdjustmentIndefiniteCharges } from './Partials/CarryFowardsAdjustmentIndefiniteCharges'
import { CarryFowardsAdjustmentManagement } from './Partials/CarryFowardsAdjustmentManagement'

const CarryFowardsAdjustment = () => {
  const [selectedStandingOrder, setSelectedStandingOrder] = useState(null)
  const [commissionsByDyamicCharges, setCommissionsByDyamicCharges] = useState(
    []
  )
  const [selectedDynamicCharge, setSelectedDynamicCharge] = useState(null)
  const [commissionChargesKvP, setCommissionChargesKvP] = useState({})

  const handleOnSelectStandingOrder = async r => {
    setSelectedStandingOrder(r)
  }

  const handleOnGetCommissionsByDynamicCharge = r => {
    setCommissionsByDyamicCharges(r)

    const kvp = {}

    r.forEach(item => {
      kvp[item.id] = {
        name: item.chartOfAccountName,
        chartOfAccountId: item.chartOfAccountId,
      }
    })

    setCommissionChargesKvP(kvp)
  }

  const handleOnSelectCommissionSplit = r => {
    setSelectedDynamicCharge(r)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('recurringProcedures'),
          localize('carryFowardsAdjustment'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('beneficiaryAccount') + '#'}
                  </label>
                  <StandingOrderLookup
                    displayValue={
                      selectedStandingOrder?.beneficiaryCustomerAccountCustomerFullName
                    }
                    onSelect={handleOnSelectStandingOrder}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('customerName')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      selectedStandingOrder?.beneficiaryCustomerAccountCustomerFullName
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">{`${localize(
                    'accountNumber'
                  )}`}</label>
                  <input
                    className="form-control"
                    value={
                      selectedStandingOrder?.beneficiaryCustomerAccountCustomerReference1
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">{`${localize(
                    'memberNumber'
                  )}`}</label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      selectedStandingOrder?.beneficiaryCustomerAccountCustomerReference2
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">{`${localize(
                    'pfNumber'
                  )}`}</label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      selectedStandingOrder?.beneficiaryCustomerAccountCustomerReference3
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('identityCardNumber')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      selectedStandingOrder?.beneficiaryCustomerAccountCustomerIndividualIdentityCardNumber
                    }
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('loanProduct')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={selectedStandingOrder?.beneficiaryProductDescription}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountStatus')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      selectedStandingOrder?.beneficiaryCustomerAccountStatusDescription
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountRemarks')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    value={
                      selectedStandingOrder?.beneficiaryCustomerAccountRemarks
                    }
                  />
                </div>
              </div>
              {selectedStandingOrder && (
                <div className="row mt-3 mb-3">
                  <Tab
                    preload
                    tabItems={[
                      {
                        label: localize('indefiniteCharges'),
                        item: (
                          <CarryFowardsAdjustmentIndefiniteCharges
                            onSelectedCommissionSplit={
                              handleOnSelectCommissionSplit
                            }
                            onGetCommissionsByDynamicCharge={
                              handleOnGetCommissionsByDynamicCharge
                            }
                            selectedStandingOrder={selectedStandingOrder}
                          />
                        ),
                      },
                      {
                        label: localize('management'),
                        item: (
                          <CarryFowardsAdjustmentManagement
                            selectedStandingOrder={selectedStandingOrder}
                            selectedDynamicCharge={selectedDynamicCharge}
                            commissionCharges={commissionsByDyamicCharges}
                            commissionChargesKvP={commissionChargesKvP}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CarryFowardsAdjustment
