/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import { getData } from '../../../../../Helpers/webApi'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import CreatePeriods from './Partials/CreatePeriods'
import { formatDate } from '../../../../../Helpers/formatters'
import DatePicker from '../../../../../Components/Date/DatePicker'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

const Periods = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    status: 1,
    filterText: '',
  })
  const [selectedSalaryPeriod, setSelectedSalaryPeriod] = useState(null)
  const [tableData, setTableData] = useState([])
  const [tableMeta, setTableMeta] = useState({})
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState('')

  const columns = [
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.valueDate)}</>,
    },
    {
      label: localize('employeeType'),
      template: r => <>{r.employeeTypeDescription}</>,
    },
    {
      label: localize('totalNetPay'),
      template: r => <>{r.totalNetPay}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => <>{r.verificationRemarks}</>,
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => <>{r.verifiedBy}</>,
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => <>{r.verifiedDate}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => <>{r.authorizedDate}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  useEffect(() => {
    loadTableData()

    // eslint-disable-next-line
  }, [reqParams])

  async function loadTableData() {
    setIsBusy(true)
    setError(false)
    const { success, data } = await getData(
      '/SalaryPeriod/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setError(true)
      setIsBusy(false)
    }
  }

  const handleClose = () => {
    setSelectedSalaryPeriod(null)
    setMode(null)
  }
  const handleClickAddButton = () => {
    setMode('add')
  }

  return (
    <>
      {(mode === 'add' || mode === 'edit') && (
        <CreatePeriods
          setMode={setMode}
          mode={mode}
          loadTableData={loadTableData}
          handleClose={handleClose}
          selectedSalaryPeriod={selectedSalaryPeriod}
          setSelectedSalaryPeriod={setSelectedSalaryPeriod}
        />
      )}
      <BreadCrumb tree={[localize('humanResources'), localize('periods')]} />

      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-md-4">
              <div className="mb-3">
                <AddNewButton handleClickAddButton={handleClickAddButton} />
              </div>
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : error ? (
            <div className="col-12 d-flex align-items-center justify-content-center text-danger">
              <i className="uil uil-exclamation-circle m-2 fs-4"></i>
              <div className="fs-5 text-danger">Failed to Load Data</div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="d-flex col-3 align-items-center">
                  <label htmlFor="recordsPerPage" className="text-capitalize">
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    name=""
                    id="recordsPerPage"
                    className="form-select ms-3"
                    style={{ maxWidth: 80 }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="d-flex col-3 align-items-center">
                  <label htmlFor="status" className="text-capitalize">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '80%' }}
                    className={'ms-3'}
                    enumsKey="SalaryPeriodStatus"
                    value={reqParams.status}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }}
                  />
                </div>
                <div className="d-flex col-3 align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>
                <form
                  className="col-3 d-flex align-items-center"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <label htmlFor="searchTable">Search</label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2 me-2"
                    defaultValue={reqParams.filterText}
                    onChange={e => setSearch(e.target.value)}
                  />
                  <button
                    id="salaryPeriodsSearchBtn"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setReqParams({
                        ...reqParams,
                        filterText: search,
                      })
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="row mt-2">
                <SimpleTable
                  contextMenu={r => (
                    <>
                      <div
                        onClick={async () => {
                          setMode('edit')
                          setSelectedSalaryPeriod(r)
                        }}
                        className="ctxt-menu-item text-capitalize"
                      >
                        <i className="uil uil-edit"></i>
                        {localize('edit')}
                      </div>
                    </>
                  )}
                  data={tableData}
                  columns={columns}
                />
              </div>
              <div className="row mt-2">
                <Pager
                  {...tableMeta}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Periods
