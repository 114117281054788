/** @format */

import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { CreateCheckOffDataPeriodModal } from './CreateCheckOffDataPeriodModal'
import { EditCheckOffDataPeriodModal } from './EditCheckOffDataPeriodModal'
import { useEffect, useState } from 'react'
import { postData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'

function CheckOffDataPeriodModal({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  selectedCheckOffDataPeriod,
  onSetMode,
}) {
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  useEffect(() => {
    setGlobalValidationErrors({})
  }, [mode])

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const [checkOffDataPeriodModel, setCheckOffDataPeriodModel] = useState(null)

  useEffect(() => {
    if (mode === 'edit') {
      setCheckOffDataPeriodModel(selectedCheckOffDataPeriod)
    }
  }, [mode])

  async function handleSubmit() {
    const { success } =
      mode === 'add'
        ? await postData(
            '/DataAttachmentPeriod/add-data-attachment-period',
            {
              ...checkOffDataPeriodModel,
            },
            true,
            'Data Processing Period'
          )
        : await postData(
            '/DataAttachmentPeriod/edit-data-attachment-period',
            { ...checkOffDataPeriodModel },
            true,
            'Data Processing Period'
          )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
    }
  }
  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('checkOffDataProcessing')}
            handleAction={handleSubmit}
            footerChildren={
              <div className="d-flex align-content-end justify-content-end">
                <div className="d-flex align-items-center me-5">
                  <Checkbox
                    id={'period-is-active'}
                    checked={checkOffDataPeriodModel?.isActive}
                    onCheck={v =>
                      setCheckOffDataPeriodModel({
                        ...checkOffDataPeriodModel,
                        isActive: v,
                      })
                    }
                  />
                  <label
                    htmlFor="period-is-active"
                    className="text-capitalize ms-2"
                  >
                    {localize('periodIsActive')}?
                  </label>
                </div>
              </div>
            }
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreateCheckOffDataPeriodModal
                globalValidationErrors={globalValidationErrors}
                setGlobalValidationErrors={setGlobalValidationErrors}
                clearValidation={clearValidation}
                checkOffDataPeriodModel={checkOffDataPeriodModel}
                setCheckOffDataPeriodModel={setCheckOffDataPeriodModel}
              />
            )}
            {mode === 'edit' && (
              <EditCheckOffDataPeriodModal
                selectedCheckOffDataPeriod={selectedCheckOffDataPeriod}
                globalValidationErrors={globalValidationErrors}
                clearValidation={clearValidation}
                checkOffDataPeriodModel={checkOffDataPeriodModel}
                setCheckOffDataPeriodModel={setCheckOffDataPeriodModel}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default CheckOffDataPeriodModal
