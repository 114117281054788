/** @format */

import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'
import localize from '../../../../../../Global/Localize'
import { formatDate } from '../../../../../../Helpers/formatters'
import { ItemRegisterLookup } from '../../../../../../Components/Lookup/ItemRegisterLookup'
import { EditQuotationEntries } from './EditQuotationEntries'

export const EditQuotationModal = ({
  selectedQuotation,
  quotationEntries,
  isBusy,
  setIsBusy,
  setQuotationEntries,
}) => {
  const [quotationModel, setQuotationModel] = useState(null)

  const handleOnAddQuotation = async () => {
    const { success, data } = await postData(
      '/Quotation/add-quotation-entries',
      {
        ...quotationModel,
        QuotationItems: quotationEntries || '',
      },
      false,
    )
    if (success) {
      let newEntries = [...quotationEntries, data]
      setQuotationEntries(newEntries)
      setQuotationModel(null)
    }
  }

  const [reqParams, setReqParams] = useState({
    quotationId: selectedQuotation?.id,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const fetchQuotationItems = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Quotation/find-quotations-items-by-quotation-id-filter-in-page',
      {
        ...reqParams,
        quotationId: selectedQuotation?.id,
      },
      false,
    )
    if (success) {
      setQuotationEntries(data?.pageCollection)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    fetchQuotationItems()
  }, [selectedQuotation?.id])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('supplier')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedQuotation?.supplierDescription}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('mobilePhone')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedQuotation?.addressMobileLine}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('email')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedQuotation?.addressEmail}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('quotationDate')}
              </label>
              <input
                className="form-control"
                value={formatDate(selectedQuotation?.quotationDate, true)}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('validityPeriod')}
              </label>
              <input
                disabled
                className="form-control"
                value={selectedQuotation?.period}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('documentNo')}
              </label>
              <input
                className="form-control"
                value={selectedQuotation?.documentNumber}
                disabled
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('document')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedQuotation?.fileName}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('title')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedQuotation?.fileTitle}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('description')}
              </label>
              <input
                className="form-control"
                value={selectedQuotation?.fileDescription}
                disabled
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-1 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                value={selectedQuotation?.remarks}
                disabled
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div
                className="p-2 mt-4"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 120,
                  }}
                >
                  {localize('itemDetails')}
                </div>
                <div className="row mb-3" style={{ marginTop: -10 }}>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className="col-3 text-capitalize me-3">
                      {localize('itemDescription')}
                    </label>
                    <ItemRegisterLookup
                      displayValue={
                        quotationModel?.itemRegister?.description || ''
                      }
                      onSelect={r => {
                        setQuotationModel({
                          ...quotationModel,
                          itemRegister: { ...r },
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className=" col-3 text-capitalize me-3">
                      {localize('inventoryCategory')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        quotationModel?.itemRegister
                          ?.inventoryCategoryDescription || ''
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3" style={{ marginTop: -10 }}>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className=" col-3 text-capitalize me-3">
                      {localize('unitOfMeasure')}
                    </label>
                    <input
                      className="form-control"
                      disabled
                      value={
                        quotationModel?.itemRegister
                          ?.inventoryUnitOfMeasureDescription || ''
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className="col-3 text-capitalize me-3">
                      {localize('quantity')}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={quotationModel?.quantity || ''}
                      onChange={e =>
                        setQuotationModel({
                          ...quotationModel,
                          quantity: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3" style={{ marginTop: -10 }}>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className="col-3 text-capitalize me-3">
                      {localize('unitCost')}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={quotationModel?.unitCost || ''}
                      onChange={e =>
                        setQuotationModel({
                          ...quotationModel,
                          unitCost: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label htmlFor="" className=" col-3 text-capitalize me-3">
                      {localize('daysToDeliver')}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={quotationModel?.daysToDeliver || ''}
                      onChange={e => {
                        setQuotationModel({
                          ...quotationModel,
                          daysToDeliver: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div
                  className="bg-light d-flex justify-content-end align-items-center p-2"
                  style={{ borderRadius: 5 }}
                >
                  <button
                    onClick={handleOnAddQuotation}
                    className="btn btn-success text-uppercase ms-3 px-4"
                  >
                    {localize('add')}
                  </button>
                </div>

                <div
                  className="p-2 mt-4"
                  style={{
                    border: 'solid 1px #0000001f',
                    borderRadius: 10,
                    position: 'relative',
                  }}
                >
                  <div
                    className="bg-white px-2 row text-capitalize"
                    style={{
                      position: 'relative',
                      top: -20,
                      left: 30,
                      maxWidth: 120,
                      marginBottom: -10,
                    }}
                  >
                    {localize('quotationItems')}
                  </div>
                  <EditQuotationEntries
                    setReqParams={setReqParams}
                    reqParams={reqParams}
                    entries={{ pageCollection: quotationEntries }}
                    onRefresh={() => {
                      fetchQuotationItems()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
