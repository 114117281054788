/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../Components/Loaders/Loader'
import InvestmentProductLookup from '../../../../Components/Lookup/InvestmentProductLookup'
import { LoanProductLookup } from '../../../../Components/Lookup/LoanProductLookup'
import SavingsProductLookup from '../../../../Components/Lookup/SavingsProductLookup'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { ProductCode } from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../Helpers/formatters'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

function PayrollNumberTypes() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('productCode'),
      template: r => <>{r?.customerAccountTypeProductCodeDescription}</>,
      sortBy: 'customerAccountTypeProductCodeDescription',
    },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
      sortBy: 'customerAccountTypeTargetProductDescription',
    },
    {
      label: localize('isDefault') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isDefault.toString())}
        </span>
      ),
      sortBy: 'isDefault',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  const [selectedPayrollNumberType, setSelectedPayrollNumberType] =
    useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, refresh])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/PayrollNumber/find-payroll-number-types-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }
    setIsBusy(false)
  }

  const handleAdd = () => {
    setMode('add')
    setSelectedPayrollNumberType({
      customerAccountTypeProductCode: Object.keys(ProductCode)[0],
    })
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } =
      mode === 'edit'
        ? await putData(
            '/PayrollNumber/update-payroll-number-type',
            selectedPayrollNumberType,
            false
          )
        : await postData(
            '/PayrollNumber/create-payroll-number-type',
            selectedPayrollNumberType,
            false
          )

    if (success) {
      setMode(null)
      setSelectedPayrollNumberType(null)
      showNotification('Payroll Number Types', 'success')
      loadTableData()
    }
    setIsBusy(false)
  }

  function closeModal() {
    setMode(null)
    setSelectedPayrollNumberType(null)
    setGlobalValidationErrors({})
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('miscellaneous'),
          localize('payrollNumberTypes'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center ">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>

                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="payrollNumberTypesFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setSelectedPayrollNumberType(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              handleViewAuditLogs(r)
                            }}
                          >
                            <i className="uil uil-server"></i>
                            <span>{localize('viewAuditLog')}</span>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <ModalTemplate
          modalClassName="modal-lg"
          modalTitle={'payrollNumberType'}
          modalMode={mode}
          handleAction={handleSubmit}
          handleClose={closeModal}
          actionText={mode === 'add' ? 'create' : 'update'}
          footerChildren={
            <>
              <div className="d-flex align-items-center me-3 gap-2">
                {mode === 'edit' && (
                  <>
                    <Checkbox
                      id="cPayrollNumTypeIsDefault"
                      onCheck={e => {
                        setSelectedPayrollNumberType({
                          ...selectedPayrollNumberType,
                          isDefault: e,
                        })
                      }}
                      checked={selectedPayrollNumberType?.isDefault}
                    />
                    <label
                      htmlFor="cPayrollNumTypeIsDefault"
                      className="text-capitalize"
                    >
                      {localize('isDefault')}?
                    </label>
                    <div className="mx-3"></div>
                  </>
                )}
                <Checkbox
                  id="cPayrollNumTypeIsLocked"
                  onCheck={e => {
                    setSelectedPayrollNumberType({
                      ...selectedPayrollNumberType,
                      isLocked: e,
                    })
                  }}
                  checked={selectedPayrollNumberType?.isLocked}
                />
                <label
                  htmlFor="cPayrollNumTypeIsLocked"
                  className="text-capitalize"
                >
                  {localize('isLocked')}?
                </label>
              </div>
            </>
          }
        >
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3 g-3">
                <div className="col-md-12">
                  <div
                    className="with-validation d-flex align-items-center mb-2"
                    validation-message={
                      globalValidationErrors['Description']?.message
                    }
                  >
                    <label className="text-capitalize col-1">
                      {localize('name')}
                    </label>
                    <input
                      defaultValue={selectedPayrollNumberType?.description}
                      type="text"
                      className="form-control"
                      onChange={e => {
                        clearValidation('Description')
                        setSelectedPayrollNumberType({
                          ...selectedPayrollNumberType,
                          description: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-2 align-items-center">
                <div className="col-md-6">
                  <div className="d-flex align-items-center mb-2">
                    <label className="text-capitalize col-2">
                      {localize('productCode')}
                    </label>
                    <EnumsServerCombo
                      disabled={mode === 'edit'}
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="ProductCode"
                      value={
                        selectedPayrollNumberType?.customerAccountTypeProductCode
                      }
                      onChange={e =>
                        setSelectedPayrollNumberType({
                          ...selectedPayrollNumberType,
                          customerAccountTypeProductCode: e.value,
                        })
                      }
                    />
                  </div>
                </div>

                {mode === 'edit' ? (
                  <>
                    <div className="col-md-6">
                      <div className="d-flex align-items-center mb-2">
                        <label className="text-capitalize col-2">
                          {localize('product')}
                        </label>
                        <input
                          defaultValue={
                            selectedPayrollNumberType?.customerAccountTypeTargetProductDescription
                          }
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {selectedPayrollNumberType?.customerAccountTypeProductCode ===
                      '1' && (
                      <div className="col-md-6">
                        <div
                          className="with-validation d-flex align-items-center mb-2"
                          validation-message={
                            globalValidationErrors[
                              'CustomerAccountTypeTargetProductDescription'
                            ]?.message
                          }
                        >
                          <label className="text-capitalize col-2">
                            {localize('product')}
                          </label>
                          <SavingsProductLookup
                            displayValue={
                              selectedPayrollNumberType?.customerAccountTypeTargetProductDescription
                            }
                            onSelect={v => {
                              clearValidation(
                                'CustomerAccountTypeTargetProductDescription'
                              )
                              setSelectedPayrollNumberType(prev => ({
                                ...prev,
                                customerAccountTypeTargetProductDescription:
                                  v.description,
                                customerAccountTypeTargetProductId: v.id,
                              }))
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {selectedPayrollNumberType?.customerAccountTypeProductCode ===
                      '2' && (
                      <div className="col-md-6">
                        <div
                          className="with-validation d-flex align-items-center mb-2"
                          validation-message={
                            globalValidationErrors[
                              'CustomerAccountTypeTargetProductDescription'
                            ]?.message
                          }
                        >
                          <label className="text-capitalize col-2">
                            {localize('product')}
                          </label>
                          <LoanProductLookup
                            displayValue={
                              selectedPayrollNumberType?.customerAccountTypeTargetProductDescription
                            }
                            onSelect={v => {
                              clearValidation(
                                'CustomerAccountTypeTargetProductDescription'
                              )
                              setSelectedPayrollNumberType(prev => ({
                                ...prev,
                                customerAccountTypeTargetProductDescription:
                                  v.description,
                                customerAccountTypeTargetProductId: v.id,
                              }))
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {selectedPayrollNumberType?.customerAccountTypeProductCode ===
                      '3' && (
                      <div className="col-md-6">
                        <div
                          className="with-validation d-flex align-items-center mb-2"
                          validation-message={
                            globalValidationErrors[
                              'CustomerAccountTypeTargetProductDescription'
                            ]?.message
                          }
                        >
                          <label className="text-capitalize col-2">
                            {localize('product')}
                          </label>

                          <InvestmentProductLookup
                            displayValue={
                              selectedPayrollNumberType?.customerAccountTypeTargetProductDescription
                            }
                            onSelect={v => {
                              clearValidation(
                                'CustomerAccountTypeTargetProductDescription'
                              )
                              setSelectedPayrollNumberType(prev => ({
                                ...prev,
                                customerAccountTypeTargetProductDescription:
                                  v.description,
                                customerAccountTypeTargetProductId: v.id,
                              }))
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </ModalTemplate>
      )}
      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}
export default PayrollNumberTypes
