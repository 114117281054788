/** @format */

import React from 'react'
import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../../../Helpers/formatters'

const GoodsReceivedNoteApprovals = ({ entries }) => {
  const columns = [
    {
      label: localize('approvedBy'),
      template: r => <>{r.createdBy}</>,
    },
    {
      label: localize('role'),
      template: r => <>{r.workflowItemRoleName}</>,
    },
    {
      label: localize('decision'),
      template: r => <>{r.decision}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('usedBiometrics') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.usedBiometrics?.toString())}
        </span>
      ),
    },
    {
      label: localize('approvalDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <div>
      <SimpleTable columns={columns} data={entries} />
    </div>
  )
}

export default GoodsReceivedNoteApprovals
