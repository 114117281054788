/**
 *
 *
 * @format
 */

import React from 'react'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

const MorgueTechnicianLookup = ({ onSelect, displayValue }) => {
  const columns = [
    {
      label: 'name',
      template: r => r?.fullName,
    },
    {
      // eslint-disable-next-line no-useless-concat
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]
  return (
    <>
      <SimpleLookup
        onSelect={onSelect}
        displayValue={displayValue}
        url="/MorgueTechnician/find-morgue-technician-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        columns={columns}
        title="morgueTechnicianLookup"
      />
    </>
  )
}

export default MorgueTechnicianLookup
