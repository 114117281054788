/** @format */

import React, { useState } from 'react'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import InvestmentProductsTab from '../../../../../../Components/ReUsables/RecurringProcedures/InvestmentProductsTab/InvestmentProductsTab'
import LoansTab from '../../../../../../Components/ReUsables/RecurringProcedures/LoansTab/LoansTab'
import SavingsProductTab from '../../../../../../Components/ReUsables/RecurringProcedures/SavingsProductTab/SavingsProductTab'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { RecordStatus } from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../../Helpers/webApi'

const CreateCustomerAccount = ({
  mode,
  handleClose,
  fetchCustomerAccounts,
}) => {
  const [customer, setCustomer] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [savings, setSavings] = useState([])
  const [loanProducts, setLoanProducts] = useState([])
  const [resetValues, setResetValues] = useState(false)
  const [investmentProducts, setInvestmentProducts] = useState([])

  const handleLookupCustomer = r => {
    if (r?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected account is locked'
      )

    if (r?.recordStatus !== Object.keys(RecordStatus)[2])
      return showNotification(
        'Operation not allowed',
        'error',
        'Selected account is already approved'
      )

    setCustomer(r)
  }

  const handleSelectSavings = value => {
    setSavings(value)
  }

  const handleSelectLoanProducts = value => {
    setLoanProducts(value)
  }

  const handleSelectInvestmentProducts = value => {
    setInvestmentProducts(value)
  }

  const handleCreateCustomerAccount = async mode => {
    if (!customer?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a customer'
      )

    if (mode === 'loan' && !loanProducts.length)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select at least one loan product'
      )

    if (mode === 'savings' && !savings.length)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select at least one savings product'
      )

    if (mode === 'investment' && !investmentProducts.length)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select at least one investment product'
      )

    setIsBusy(true)
    const url = '/CustomerAccount/create-customer-accounts'

    const { success } = await postData(
      url,
      {
        customer,
        savingsProducts: savings,
        investmentProducts,
        loanProducts,
      },
      false
    )

    if (success) {
      showNotification('Create customer account', 'success')
      fetchCustomerAccounts()
      handleClose()
      setCustomer({})
      setResetValues(true)
      setIsBusy(false)
      setCustomer({})
      setSavings([])
      setLoanProducts([])
      setInvestmentProducts([])
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalTitle="customerAccount"
      cancelText="close"
      modalMode={mode}
      handleClose={() => {
        handleClose()
        setCustomer({})
      }}
      modalClassName="modal-xl"
      disableCreate={isBusy}
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerPartial
            customer={customer}
            onCustomerLookup={handleLookupCustomer}
          />
          <div className="mt-4">
            <Tab
              preload
              tabItems={[
                {
                  label: localize('loanProducts'),
                  item: (
                    <LoansTab
                      handleUpdateLoanProducts={() =>
                        handleCreateCustomerAccount('loan')
                      }
                      handleSelectLoanProducts={handleSelectLoanProducts}
                      setResetValues={setResetValues}
                      resetValues={resetValues}
                      actionText="create"
                    />
                  ),
                },

                {
                  label: localize('savingsProducts'),
                  item: (
                    <SavingsProductTab
                      handleSelectSavings={handleSelectSavings}
                      handleUpdate={() =>
                        handleCreateCustomerAccount('savings')
                      }
                      setResetValues={setResetValues}
                      resetValues={resetValues}
                      actionText="create"
                    />
                  ),
                },
                {
                  label: localize('investmentProducts'),
                  item: (
                    <InvestmentProductsTab
                      handleSelectInvestmentProducts={
                        handleSelectInvestmentProducts
                      }
                      handleUpdateInvestmentProducts={() =>
                        handleCreateCustomerAccount('investment')
                      }
                      setResetValues={setResetValues}
                      resetValues={resetValues}
                      actionText="create"
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateCustomerAccount
