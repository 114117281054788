/** @format */

import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Tab from '../../../../../Components/Tabs/Tab'
import OffsetableLoans from '../PreQualification/OffsetableLoans'
import {
  formatCurrency,
  formatDate,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import VoluntaryDeductibles from '../PreQualification/VoluntaryDeductibles'

function AppraisalTab({
  approval,
  loanCase,
  assessment,
  onChangeOffsetables,
  onChangeAmountAppraised,
  onChangeVoluntaryDeductibles,
  onChangeConsiderCheckOffDeductions,
}) {
  const [considerCheckOffDeductions, setConsiderCheckOffDeductions] =
    useState(false)

  useEffect(() => {
    onChangeConsiderCheckOffDeductions(considerCheckOffDeductions)
    // eslint-disable-next-line
  }, [considerCheckOffDeductions])

  const deductionsScheduleColumn = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r?.amount)}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r?.categoryDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('environmentUserName'),
      template: r => <>{r?.environmentUserName}</>,
    },
    {
      label: localize('environmentMachineName'),
      template: r => <>{r?.environmentMachineName}</>,
    },
    {
      label: localize('environmentDomainName'),
      template: r => <>{r?.environmentDomainName}</>,
    },
    {
      label: localize('environmentOperatingSystem'),
      template: r => <>{r?.environmentOSVersion}</>,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => <>{r?.environmentMACAddress}</>,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => <>{r?.environmentMotherBoardSerialNumber}</>,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => <>{r?.environmentProcessorId}</>,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => <>{r?.environmentIPAddress}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const repaymentScheduleColumns = [
    { label: localize('period'), template: r => <>{r?.period}</> },
    {
      label: localize('dueDate'),
      template: r => <>{formatDate(r?.dueDate)}</>,
    },
    { label: localize('period'), template: r => <>{r?.period}</> },
    {
      label: localize('dueDate'),
      template: r => <>{formatDate(r?.dueDate)}</>,
    },
    {
      label: localize('startingBalance'),
      template: r => <>{formatCurrency(r?.startingBalance)}</>,
    },
    {
      label: localize('payment'),
      template: r => <>{formatCurrency(r?.payment)}</>,
    },
    {
      label: localize('interestPayment'),
      template: r => <>{formatCurrency(r?.interestPayment)}</>,
    },
    {
      label: localize('principalPayment'),
      template: r => <>{formatCurrency(r?.principalPayment)}</>,
    },
    {
      label: localize('endingBalance'),
      template: r => <>{formatCurrency(r?.endingBalance)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('offsetableLoans'),
      item: (
        <OffsetableLoans
          customerId={loanCase?.customerId}
          loanProductId={loanCase?.loanProductId}
          loanCaseId={loanCase?.id}
          permitPartialOffsetting={
            loanCase?.loanRegistrationPermitPartialOffsetting
          }
          onChangeOffsetables={x => onChangeOffsetables(x)}
        />
      ),
      icon: 'uil uil-edit',
    },
    {
      label: localize('voluntaryDeductibles'),
      notInApproval: true,
      item: (
        <VoluntaryDeductibles
          onChangeDeductibles={onChangeVoluntaryDeductibles}
          loanCaseId={loanCase?.id}
        />
      ),
      icon: 'uil uil-edit',
    },
    {
      label: localize('deductionsSchedule'),
      item: (
        <SimpleTable
          columns={deductionsScheduleColumn}
          data={assessment?.appraisal?.loanCaseDeductions || []}
        />
      ),
    },
    {
      label: localize('repaymentSchedule'),
      item: (
        <SimpleTable
          columns={repaymentScheduleColumns}
          data={assessment?.appraisal?.amortizationTable || []}
        />
      ),
    },
  ]

  return (
    <>
      <div className="row mb-1">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('amountApplied')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              assessment?.appraisal?.amountApplied,
              2,
              2,
              false
            )}
            style={{ textAlign: 'right' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('systemRecommendation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              assessment?.appraisal?.systemRecommendation,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('systemRemarks')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={assessment?.appraisal?.systemRemarks}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('securityQualification')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              assessment?.appraisal?.totalLoanCaseSecurity,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('incomeQualification')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.qualification.totalPrincipalPayment,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center"></div>
        <div className="col d-flex align-items-center"></div>
        <div className="col d-flex align-items-center"></div>
      </div>
      <div className="row mb-1">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('depositsBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              assessment?.appraisal?.loanProductInvestmentsBalance,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('depositsMultiplier')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              assessment?.appraisal?.loanRegistrationInvestmentsMultiplier,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('depositsQualification')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={formatCurrency(
              assessment?.appraisal?.maximumLoan,
              2,
              2,
              false
            )}
            style={{ textAlign: 'end' }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('outstandingLoansBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.outstandingLoansBalance,
              2,
              2,
              false
            )}
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('maximumEntitled')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.maximumEntitled,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('attachedLoanBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.attachedLoansBalance,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('attachedCarryForwardsBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.attachedLoansCarryForwardsBalance,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('attachedClearanceCharges')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.attachedLoansClearanceCharges,
              2,
              2,
              false
            )}
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('totalAttachedBalance')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.totalAttachedBalance,
              2,
              2,
              false
            )}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize(approval ? 'amountApproved' : 'amountAppraised')}
          </label>
          <MaskNumber
            onMaskNumber={c => {}}
            onBlur={c =>
              onChangeAmountAppraised(stripNonNumericCharacters(c.target.value))
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize(approval ? 'approverRemarks' : 'appraiserRemarks')}
          </label>
          <input type="text" className="form-control" />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('loanAmount')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.loanOfficerRecommendation,
              2,
              2,
              false
            )}
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('paymentPerPeriod')}
          </label>
          <input
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.amortizedMonthlyPayment,
              2,
              2,
              false
            )}
            type="text"
            className="form-control"
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('disbursementMode')}
          </label>
          <input
            value={assessment?.appraisal?.loanDisbursementMode?.description}
            type="text"
            className="form-control"
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('disbursementCategory')}
          </label>
          <input
            value={
              assessment?.appraisal?.loanDisbursementMode
                ?.disbursementCategoryDescription
            }
            type="text"
            className="form-control"
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('disbursementTarget')}
          </label>
          <input
            value={
              assessment?.appraisal?.loanDisbursementMode
                ?.disbursementTargetDescription
            }
            type="text"
            className="form-control"
            disabled
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('netDisbursementAmount')}
          </label>
          <input
            style={{ textAlign: 'end' }}
            value={formatCurrency(
              assessment?.appraisal?.netDisbursementAmount,
              2,
              2,
              false
            )}
            type="text"
            className="form-control"
            disabled
          />
        </div>
        <div className="col d-flex align-items-center"></div>
        <div className="col d-flex align-items-center"></div>
        <div className="col d-flex align-items-center"></div>
      </div>
      <div className="rounded border border-secondary pt-1 px-2 d-flex align-items-center justify-content-end bg-light gap-2">
        <Checkbox
          checked={considerCheckOffDeductions}
          onCheck={() =>
            setConsiderCheckOffDeductions(!considerCheckOffDeductions)
          }
        />
        <label
          htmlFor=""
          className="text-capitalize"
          onClick={() =>
            setConsiderCheckOffDeductions(!considerCheckOffDeductions)
          }
        >
          {localize('considerCheckOffDeductions')}
        </label>
      </div>

      <div className="mt-3">
        <Tab
          preload
          tabItems={
            approval ? tabItems.filter(x => !x.notInApproval) : tabItems
          }
        />
      </div>
    </>
  )
}

export default AppraisalTab
