/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import PayrollNumberTypeLookup from '../../../../../Components/Lookup/PayrollNumberTypeLookup'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import SelectableCommissions from '../../../../../Components/Selectables/SelectableCommissions'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

function selectedMobileToBankTerminal({
  mode,
  onHideModal,
  setSelectedMobileToBankTerminal,
  selectedMobileToBankTerminal,
  tabLoading,
  loadTableData,
}) {
  const [isBusy, setIsBusy] = useState(false)
  async function handleSubmit() {
    setIsBusy(true)
    const url =
      mode === 'add'
        ? '/MobileToBankTerminal/create-mobile-to-bank-terminal-with-commissions'
        : '/MobileToBankTerminal/update-mobile-to-bank-terminal-with-commissions'
    const { success } = await postData(
      url,
      {
        mobileToBankTerminal: selectedMobileToBankTerminal || {},
        commissionDTOs:
          selectedMobileToBankTerminal?.commissions.filter(x => x.checked) ||
          [],
      },
      false
    )

    if (success) {
      setIsBusy(false)
      loadTableData()
      closeModal()
      showNotification('Mobile To Bank Terminals', 'success')
    }
    setIsBusy(false)
  }

  function closeModal() {
    onHideModal()
    setSelectedMobileToBankTerminal(null)
    setGlobalValidationErrors({})
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalClassName="modal-xl"
        modalTitle={'mobileToBankTerminal'}
        modalMode={mode}
        handleAction={handleSubmit}
        handleClose={closeModal}
        actionText={mode === 'add' ? 'create' : 'update'}
        hideUpdate={mode === 'view'}
        footerChildren={
          <>
            <div className="d-flex align-items-center me-3 gap-2">
              <Checkbox
                disabled={mode === 'view'}
                id="MobileToBankTerminalsMatchByPayrollNumberType"
                onCheck={e => {
                  setSelectedMobileToBankTerminal({
                    ...selectedMobileToBankTerminal,
                    matchByPayrollNumberType: e,
                  })
                }}
                checked={selectedMobileToBankTerminal?.matchByPayrollNumberType}
              />
              <label
                htmlFor="MobileToBankTerminalsMatchByPayrollNumberType"
                className="text-capitalize"
              >
                {localize('matchByPayrollNumberType')}?
              </label>
              <div className="mx-3"></div>

              <Checkbox
                disabled={mode === 'view'}
                id="MobileToBankTerminalsMatchByMSISDN"
                onCheck={e => {
                  setSelectedMobileToBankTerminal({
                    ...selectedMobileToBankTerminal,
                    matchByMSISDN: e,
                  })
                }}
                checked={selectedMobileToBankTerminal?.matchByMSISDN}
              />
              <label
                htmlFor="MobileToBankTerminalsMatchByMSISDN"
                className="text-capitalize"
              >
                {localize('matchByMSISDN')}?
              </label>
              <div className="mx-3"></div>

              <Checkbox
                disabled={mode === 'view'}
                id="MobileToBankTerminalsTrackAggregateExcess"
                onCheck={e => {
                  setSelectedMobileToBankTerminal({
                    ...selectedMobileToBankTerminal,
                    trackAggregateExcess: e,
                  })
                }}
                checked={selectedMobileToBankTerminal?.trackAggregateExcess}
              />
              <label
                htmlFor="MobileToBankTerminalsTrackAggregateExcess"
                className="text-capitalize"
              >
                {localize('trackAggregateExcess')}?
              </label>
              <div className="mx-3"></div>

              <Checkbox
                disabled={mode === 'view'}
                id="IsMobileToBankTerminalsLocked"
                onCheck={e => {
                  setSelectedMobileToBankTerminal({
                    ...selectedMobileToBankTerminal,
                    isLocked: e,
                  })
                }}
                checked={selectedMobileToBankTerminal?.isLocked}
              />
              <label
                htmlFor="IsMobileToBankTerminalsLocked"
                className="text-capitalize"
              >
                {localize('isLocked')}?
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['MobileToBankTerminal.Description']
                      ?.message
                  }
                >
                  <label className="text-capitalize col-2">
                    {localize('name')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    defaultValue={selectedMobileToBankTerminal?.description}
                    className="form-control"
                    onChange={e => {
                      clearValidation(['MobileToBankTerminal.Description'])
                      setSelectedMobileToBankTerminal({
                        ...selectedMobileToBankTerminal,
                        description: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors['MobileToBankTerminal.Description']
                      ?.message
                  }
                >
                  <label className="text-capitalize col-2">
                    {localize('businessShortCode')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    defaultValue={
                      selectedMobileToBankTerminal?.businessShortCode
                    }
                    className="form-control"
                    onChange={e => {
                      clearValidation([
                        'MobileToBankTerminal.BusinessShortCode',
                      ])
                      setSelectedMobileToBankTerminal({
                        ...selectedMobileToBankTerminal,
                        businessShortCode: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-2">
                    {localize('billRefNumberPrefix')}
                  </label>
                  <input
                    disabled={mode === 'view'}
                    defaultValue={
                      selectedMobileToBankTerminal?.billRefNumberPrefix
                    }
                    className="form-control"
                    onChange={e =>
                      setSelectedMobileToBankTerminal({
                        ...selectedMobileToBankTerminal,
                        billRefNumberPrefix: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center mb-2">
                  <label className="text-capitalize col-2">
                    {localize('collectionAccountPadding')}
                  </label>
                  <MaskNumber
                    disabled={mode === 'view'}
                    defaultMaskValue={
                      selectedMobileToBankTerminal?.collectionAccountPadding ||
                      0
                    }
                    onMaskNumber={e => {
                      setSelectedMobileToBankTerminal({
                        ...selectedMobileToBankTerminal,
                        collectionAccountPadding: e.target.value,
                      })
                    }}
                    numeralThousandsGroupStyle="none"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-2 g-3">
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'MobileToBankTerminal.ChartOfAccountName'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-2">
                    {localize('chartOfAccountName')}
                  </label>
                  {mode === 'view' ? (
                    <input
                      disabled
                      defaultValue={
                        selectedMobileToBankTerminal?.chartOfAccountName
                      }
                      className="form-control"
                    />
                  ) : (
                    <ChartOfAccountsLookup
                      displayValue={
                        selectedMobileToBankTerminal?.chartOfAccountName
                      }
                      onSelect={r => {
                        clearValidation([
                          'MobileToBankTerminal.ChartOfAccountName',
                        ])
                        setSelectedMobileToBankTerminal({
                          ...selectedMobileToBankTerminal,
                          chartOfAccountId: r.id,
                          chartOfAccountName: r.description,
                        })
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="with-validation d-flex align-items-center mb-2"
                  validation-message={
                    globalValidationErrors[
                      'MobileToBankTerminal.PayrollNumberTypeDescription'
                    ]?.message
                  }
                >
                  <label className="text-capitalize col-2">
                    {localize('payrollNumberType')}
                  </label>
                  {mode === 'view' ? (
                    <input
                      disabled
                      defaultValue={
                        selectedMobileToBankTerminal?.payrollNumberTypeDescription
                      }
                      className="form-control"
                    />
                  ) : (
                    <PayrollNumberTypeLookup
                      displayValue={
                        selectedMobileToBankTerminal?.payrollNumberTypeDescription
                      }
                      onSelect={r => {
                        clearValidation([
                          'MobileToBankTerminal.PayrollNumberTypeDescription',
                        ])
                        setSelectedMobileToBankTerminal({
                          ...selectedMobileToBankTerminal,
                          payrollNumberTypeId: r.id,
                          payrollNumberTypeDescription: r.description,
                        })
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {tabLoading ? (
              <Loader />
            ) : (
              <div className="row mb-2">
                <div className="col md-12">
                  <Tab
                    tabItems={[
                      {
                        label: localize('applicableCharges'),
                        item: (
                          <SelectableCommissions
                            disabled={mode === 'view'}
                            setSelectableCommissionItem={
                              setSelectedMobileToBankTerminal
                            }
                            selectableCommissionItem={
                              selectedMobileToBankTerminal
                            }
                            prefix={'create-debit-type-modal'}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ModalTemplate>
    </>
  )
}
export default selectedMobileToBankTerminal
