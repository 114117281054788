/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../Helpers/webApi'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../Tables/SimpleTable'
import Pager from '../../../Tables/Pager'
import Checkbox from '../../../Checkbox/Checkbox'
import { formatDate } from '../../../../Helpers/formatters'
import Loader from '../../../Loaders/Loader'

const CreditType = ({
  handleSelectIndividualCredits,
  toggleSelectAllCredits,
  handleSelectAllCredits,
  handleUpdateCreditTypes,
  resetValues,
  setResetValues,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [credits, setCredits] = useState([])
  const [selectAllCredits, setSelectAllCredits] = useState(false)
  const [selectedCredits, setSelectedCredits] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <Checkbox
          checked={selectedCredits.find(c => c.id === r.id)}
          onCheck={v => {
            if (v) {
              setSelectedCredits([...selectedCredits, r])
            } else {
              setSelectedCredits(selectedCredits.filter(c => c.id !== r.id))
            }
          }}
        />
      ),
    },
    {
      label: localize('name'),
      template: r => r.description,
    },
    {
      label: localize('g/LAccountName'),
      template: r => r.chartOfAccountName,
    },
    {
      label: localize('gLAccountCostDistrRule'),
      template: r => r.chartOfAccountCostDistributionRuleDescription,
    },
    {
      label: localize('isLocked'),
      template: r => r.isLocked?.toString(),
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const fetchCredits = async () => {
    setIsBusy(true)
    const url = '/CreditType/find-by-filter-in-page'

    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setCredits(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleSelectAll = (credits, value) => {
    if (value) {
      setSelectedCredits(credits.pageCollection)
      handleSelectAllCredits(credits.pageCollection)
    } else {
      setSelectedCredits([])
      handleSelectAllCredits([])
    }
  }

  useEffect(() => {
    fetchCredits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  useEffect(() => {
    handleSelectIndividualCredits(selectedCredits)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCredits])

  useEffect(() => {
    if (resetValues) {
      setSelectedCredits([])
      setResetValues(false)
      setSelectAllCredits(false)
      setSelectAll(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2 py-3 px-2">
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="recordsPerPage"
                  className="text-capitalize"
                  style={{ marginLeft: -10, textTransform: 'capitalize' }}
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  id="recordsPerPage"
                  className="form-select ms-3"
                  style={{ maxWidth: 'fit-content' }}
                  defaultValue={reqParams.pageSize}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="col-md-4 offset-md-4">
              <div className="d-flex align-items-center gap-2 justify-content-end">
                <label htmlFor="searchCreditType" className="text-capitalize">
                  {localize('search')}
                </label>

                <input
                  type="search"
                  id="searchCreditType"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchCredits()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    fetchCredits()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              maxHeight: '50dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable columns={columns} data={credits?.pageCollection} />
          </div>

          <div className="mb-2"></div>
          <Pager
            itemsCount={credits.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          <div className="py-3 row px-1 bg-light mt-3 mx-2 rounded">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start gap-3 align-items-center">
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="selectAll"
                    checked={selectAll}
                    onCheck={v => {
                      setSelectAll(v)
                      handleSelectAll(credits, v)
                    }}
                  />

                  <label htmlFor="selectAll" className="ms-2 text-capitalize">
                    {localize('togglePageSelection')}
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="checkAll"
                    checked={selectAllCredits}
                    onCheck={v => {
                      setSelectAllCredits(v)
                      toggleSelectAllCredits(v)
                    }}
                  />

                  <label htmlFor="checkAll" className="ms-2 text-capitalize">
                    {localize('selectAllCreditTypes')}
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center float-end">
                <button
                  onClick={handleUpdateCreditTypes}
                  className="btn btn-success float-end"
                >
                  {localize('update')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CreditType
