/** @format */
import Loader from '../../../../Components/Loaders/Loader'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { ChargeLevies } from './Partials/ChargeLevies'
import React from 'react'
import { ChargeSplitEntries } from './Partials/ChargeSplitEntries'
import { ChargeTierEntries } from './Partials/ChargeTierEntries'

export const ViewChargesModal = ({
  selectedCharge,
  isBusy,
  waitingMetadata,
}) => {
  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                defaultValue={selectedCharge?.description}
                className="form-control"
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('maximumCharge')}
              </label>
              <input
                className="form-control"
                value={selectedCharge?.maximumCharge}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('roundingType')}
              </label>
              <input
                className="form-control"
                value={selectedCharge?.roundingTypeDescription}
                disabled
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                preload
                tabItems={[
                  {
                    label: localize('chargeSplitting'),
                    item: (
                      <ChargeSplitEntries
                        selectedCharge={selectedCharge}
                        mode={'view'}
                      />
                    ),
                  },
                  {
                    label: localize('tiers'),
                    item: (
                      <ChargeTierEntries
                        mode={'view'}
                        selectedCharge={selectedCharge}
                      />
                    ),
                  },
                  {
                    label: localize('levies'),
                    item: (
                      <ChargeLevies
                        selectedCharge={selectedCharge}
                        isBusy={waitingMetadata}
                        disabled
                        prefix={'view-selected-charge-levies'}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
