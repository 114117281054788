/** @format */

import { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { RecordStatus, alternateChannelType } from '../../Global/enumeration'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

const AlternateChannelLookup = ({ onSelect, displayValue, disabled }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [alternateChannels, setAlternateChannels] = useState([])
  const [modalActive, setModalActive] = useState(false)
  const [tableData, setTableData] = useState([])
  const [sortedBy, setSortedBy] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    type: Object.keys(alternateChannelType)[6],
    recordStatus: Object.keys(RecordStatus)[4],
    alternateChannelFilter: 0,
    includeProductDescription: true,
  })

  const columns = [
    {
      label: localize('channelType'),
      template: item => <>{item?.typeDescription}</>,
    },
    {
      label: localize('recordStatus'),
      template: item => <>{item?.recordStatusDescription}</>,
    },
    {
      label: localize('customerName'),
      template: item => <>{item?.customerFullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: item => (
        <>{item?.formattedCustomerAccountCustomerMemberNumber}</>
      ),
    },
    {
      label: localize('accountNumber'),
      template: item => (
        <>{item?.formattedCustomerAccountCustomerAccountNumber}</>
      ),
    },
    {
      label: localize('personalFileNumber'),
      template: item => <>{item?.customerAccountCustomerPersonalFileNumber}</>,
    },
    {
      label: localize('primaryAccountNumber'),
      template: item => <>{item?.maskedCardNumber}</>,
    },
    {
      label: localize('validFrom'),
      template: item => <>{formatDate(item?.validFrom, true)}</>,
    },
    {
      label: localize('expires'),
      template: item => <>{formatDate(item?.expires, true)}</>,
    },
    {
      label: localize('dailyLimit'),
      template: item => <>{formatCurrency(item?.dailyLimit)}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: item => <>{item?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: item => <>{item?.productDescription}</>,
    },
    {
      label: localize('remarks'),
      template: item => <>{item?.remarks}</>,
    },
    {
      label: localize('isThirdPartyNotified') + ' ?',
      template: item => <>{item?.isThirdPartyNotified?.toString()}</>,
    },
    {
      label: localize('thirdPartyResponse'),
      template: item => <>{item?.thirdPartyResponse}</>,
    },
    {
      label: localize('isLocked') + ' ?',
      template: item => <>{item?.isLocked?.toString()}</>,
    },
    {
      label: localize('recruitedBy'),
      template: item => <>{item?.recruitedBy}</>,
    },
    {
      label: localize('modifiedBy'),
      template: item => <>{item?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: item => <>{formatDate(item?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: item => <>{item?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: item => <>{formatDate(item?.createdDate)}</>,
    },
  ]

  const handleClose = () => {
    setModalActive(false)
  }

  const fetchAlternateChannels = async () => {
    const url =
      reqParams.recordStatus === Object.keys(RecordStatus)[4]
        ? '/AlternateChannel/find-alternate-channel-by-type-and-filter-in-page'
        : '/AlternateChannel/find-alternate-channel-by-record-status-and-filter-in-page'

    setIsBusy(true)

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setAlternateChannels(data)
      setTableData(data?.pageCollection)
    }

    setIsBusy(false)
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const handleSelectElectronicJournal = item => {
    setModalActive(false)
    onSelect(item)
  }

  useEffect(() => {
    if (modalActive) fetchAlternateChannels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('alternateChannelLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex row align-items-center justify-content-between mb-3">
                  <div className="d-flex align-items-center col-md-3">
                    <label
                      style={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      className="text-capitalize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      className="form-select ms-2"
                      style={{ maxWidth: 'fit-content' }}
                      value={reqParams.pageSize}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="d-flex align-items-center col-md-2">
                    <label className="text-capitalize me-2">
                      {localize('type')}
                    </label>

                    <EnumsServerCombo
                      onChange={({ value }) => {
                        setReqParams({
                          ...reqParams,
                          type: value,
                          pageIndex: 0,
                        })
                      }}
                      enumsKey="AlternateChannelType"
                      customContainerStyle={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      sort
                      value={reqParams?.type}
                    />
                  </div>
                  <div className="col-md-3 d-flex align-items-center">
                    <label htmlFor="search" className="col-3 text-capitalize">
                      {localize('recordStatus')}
                    </label>

                    <EnumsServerCombo
                      onChange={({ value }) => {
                        setReqParams({
                          ...reqParams,
                          recordStatus: value,
                          pageIndex: 0,
                        })
                      }}
                      enumsKey="RecordStatusFilter"
                      customContainerStyle={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      sort
                      value={reqParams?.recordStatus}
                    />
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-end gap-2"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('search')}
                    </label>

                    <EnumsServerCombo
                      onChange={({ value }) => {
                        setReqParams({
                          ...reqParams,
                          alternateChannelFilter: value,
                          pageIndex: 0,
                        })
                      }}
                      enumsKey="CustomerFilter"
                      customContainerStyle={{
                        width: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      sort
                      value={reqParams?.alternateChannelFilter}
                    />

                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.filterText}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          filterText: e.target.value,
                          pageIndex: 0,
                        })
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          fetchAlternateChannels()
                        }
                      }}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        fetchAlternateChannels()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <SimpleTable
                  data={alternateChannels?.pageCollection}
                  columns={columns}
                  canSelect
                  onSelect={handleSelectElectronicJournal}
                  onSort={handleSort}
                />
                <Pager
                  itemsCount={alternateChannels.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={handleClose}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default AlternateChannelLookup
