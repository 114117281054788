/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import DepartmentLookup from '../../../../../Components/Lookup/DepartmentLookup'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData, putData } from '../../../../../Helpers/webApi'
import { SingleDestinationTracker } from './Partials/SingleDestinationTracker'
import Tab from '../../../../../Components/Tabs/Tab'
import { SingleDestinationFileMovementHistory } from './Partials/SingleDestinationFileMovementHistory'
import {
  CustomerFilter,
  FileMovementStatus,
} from '../../../../../Global/enumeration'
import Pager from '../../../../../Components/Tables/Pager'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'

function FileTrackingSingleDestinationDispatch() {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    fileMovementStatus: Object.keys(FileMovementStatus)[1],
    pageIndex: 0,
    pageSize: 10,
  })

  const [fileTrackHistory, setFileTrackHistory] = useState([])
  const [fileMovementHistory, setFileMovementHistory] = useState([])
  const [loadingFileMovementHistory, setLoadingFileMovementHistory] =
    useState(false)
  const [selectedFileRegisters, setSelectedFileRegisters] = useState([])

  const [selectedFileRegistersHistory, setSelectedFileRegistersHistory] =
    useState([])

  const [singleDispatchModel, setSingleDispatchModel] = useState(null)

  const [toggleSelection, setToggleSelection] = useState(false)

  const [gbv, setGbv] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  const getFileTrackHistory = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/FileRegister/find-file-registers-by-filter-status-and-last-file-movement-destination-department-id-in-page',
      reqParams,
      false
    )
    if (success) {
      setFileTrackHistory(data)
    }
    setIsBusy(false)
  }

  const getFileMovementHistory = async r => {
    setIsBusy(true)
    setLoadingFileMovementHistory(true)
    const { success, data } = await getData(
      '/FileRegister/find-file-movement-history-by-file-register-id',
      { fileRegisterId: r.id },
      false
    )
    if (success) {
      setFileMovementHistory(data.result || [])
      setLoadingFileMovementHistory(false)
    }
    setIsBusy(false)
  }

  async function handleSubmit() {
    if (selectedFileRegisters.length === 0) {
      Swal.fire('Operation Failed', 'Please Select at least one file', 'error')
      return
    }

    setIsBusy(true)
    const { success } = await putData(
      '/FileRegister/single-destination-dispatch',
      {
        SingleDestinationModel: {
          DestinationDepartmentId: singleDispatchModel?.destinationDepartmentId,
          Remarks: singleDispatchModel?.remarks,
          Carrier: singleDispatchModel?.carrier,
        },
        FileRegisters: selectedFileRegisters,
      },

      false,
      'Operation Completed Successfully'
    )
    if (success) {
      setSingleDispatchModel(null)
      setSelectedFileRegistersHistory([])
      setSelectedFileRegisters([])
      setFileMovementHistory([])
      getFileTrackHistory()
    }
    setIsBusy(false)
  }

  const onToggleSelection = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedFileRegisters([])
      setSelectedFileRegisters([...fileTrackHistory.pageCollection])
    } else {
      setSelectedFileRegisters([])
      setToggleSelection(checkStatus)
    }
  }

  useEffect(() => {
    getFileTrackHistory()
  }, [reqParams])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('registryManagement'),
          localize('fileTracking'),
          localize('dispatch'),
          `${localize('fileDispatch')}(${localize('single')}-${localize(
            'destination'
          )})`,
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div
                      validation-message={
                        gbv?.['SingleDestinationModel.DestinationDepartmentId']
                          ?.message
                      }
                      className="col d-flex align-items-center with-validation"
                    >
                      <label className="col-2 text-capitalize">
                        {localize('destination')}
                      </label>
                      <DepartmentLookup
                        displayValue={singleDispatchModel?.description || ''}
                        onSelect={r => {
                          clearValidation(
                            'SingleDestinationModel.DestinationDepartmentId'
                          )
                          setSingleDispatchModel({
                            ...singleDispatchModel,
                            description: r.description,
                            destinationDepartmentId: r.id,
                          })
                        }}
                      />
                    </div>
                    <div
                      validation-message={
                        gbv?.['SingleDestinationModel.Remarks']?.message
                      }
                      className="col d-flex align-items-center with-validation"
                    >
                      <label className="col-2 text-capitalize">
                        {localize('remarks')}
                      </label>
                      <input
                        className="form-control"
                        value={singleDispatchModel?.remarks || ''}
                        onChange={e => {
                          clearValidation('SingleDestinationModel.Remarks')
                          setSingleDispatchModel({
                            ...singleDispatchModel,
                            remarks: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div
                      validation-message={
                        gbv?.['SingleDestinationModel.Carrier']?.message
                      }
                      className="col d-flex align-items-center with-validation"
                    >
                      <label className="col-2 text-capitalize">
                        {localize('carrier')}
                      </label>
                      <input
                        className="form-control"
                        onChange={e => {
                          clearValidation('SingleDestinationModel.Carrier')
                          setSingleDispatchModel({
                            ...singleDispatchModel,
                            carrier: e.target.value,
                          })
                        }}
                        value={singleDispatchModel?.carrier || ''}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <div className="mb-3 mt-3">
                      <div className="d-flex mt-3 mb-3">
                        <div className="d-flex align-items-start me-auto">
                          <div className="d-flex align-items-center me-5">
                            <Checkbox
                              id="toggleSelection"
                              checked={toggleSelection}
                              onCheck={v => onToggleSelection(v)}
                            />
                            <label
                              htmlFor="toggleSelection"
                              className="text-capitalize ms-2"
                            >
                              {localize('toggleSelection')}
                            </label>
                          </div>
                          <div className="d-flex align-items-center me-5">
                            <EnumsServerCombo
                              customContainerStyle={{ width: '100%' }}
                              enumsKey="CustomerFilter"
                              value={reqParams?.customerFilter}
                              onChange={e => {
                                setReqParams({
                                  ...reqParams,
                                  customerFilter: e.value,
                                })
                              }}
                            />
                          </div>
                          <div className="d-flex align-items-center me-5">
                            <label className="col-3 text-capitalize">
                              {localize('search')}
                            </label>
                            <input
                              defaultValue={reqParams.filterText}
                              onChange={e =>
                                setReqParams({
                                  ...reqParams,
                                  filterText: e.target.value,
                                })
                              }
                              className="form-control"
                              placeholder="Enter text..."
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <SingleDestinationTracker
                        destinations={fileTrackHistory}
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                        selectedFileRegistersHistory={
                          selectedFileRegistersHistory
                        }
                        setSelectedFileRegistersHistory={
                          setSelectedFileRegistersHistory
                        }
                        onGetFileMovementHistory={r => {
                          getFileMovementHistory(r)
                        }}
                        setSelectedFileRegisters={setSelectedFileRegisters}
                        selectedFileRegisters={selectedFileRegisters}
                      />
                    </div>
                    <Pager
                      itemsCount={fileTrackHistory.itemsCount}
                      pageSize={reqParams.pageSize}
                      pageIndex={reqParams.pageIndex}
                      pageBatchSize={10}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                    <div className="d-flex mt-1 mb-3 justify-content-end">
                      <button
                        onClick={handleSubmit}
                        className="btn btn-success text-uppercase px-4 ms-auto"
                      >
                        {localize('dispatch')}
                      </button>
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <Tab
                      tabItems={[
                        {
                          label: localize('fileMovementHistory'),
                          item: (
                            <SingleDestinationFileMovementHistory
                              movementHistories={fileMovementHistory}
                              loading={loadingFileMovementHistory}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FileTrackingSingleDestinationDispatch
