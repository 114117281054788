/** @format */

/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
// import { motion } from 'framer-motion'
import './userpill.scss'
import './UserProfileModal.jsx'
import localize from '../../Global/Localize'
import UserProfileModal from './UserProfileModal.jsx'
import { useAuth } from 'react-oidc-context'

function UserPill({ user, onLogout, onLock }) {
  const auth = useAuth()
  const ref = useRef(null)
  const [dropdown, setDropdown] = useState(false)
  const [modalMode, setModalMode] = useState(null)

  const handleOpenProfileModal = () => {
    setModalMode('profile')
  }

  const handleCloseProfileModal = () => {
    setModalMode(null)
  }

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {modalMode === 'profile' && (
        <UserProfileModal
          handleAction={handleCloseProfileModal}
          handleClose={handleCloseProfileModal}
          mode={modalMode}
        />
      )}
      <div className="d-inline-block dropdown user-pill" ref={ref}>
        <button
          id="page-header-user-dropdown"
          aria-haspopup="true"
          className="btn header-item waves-effect"
          aria-expanded="true"
          onClick={() => setDropdown(!dropdown)}
        >
          {/* <div className="user-avatar">
            <i className="uil uil-user"></i>
          </div> */}
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {auth?.user?.profile?.name || 'user'}
          </span>
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </button>
        <div
          tabIndex="-1"
          role="menu"
          // style="position: absolute; inset: 0px auto auto 0px; transform: translate(0px, 70px);"
          style={{
            position: 'absolute',
            inset: '0px auto auto 0px',
            transform: ' translate(0px, 70px)',
          }}
          aria-hidden="false"
          className={`dropdown-menu-end dropdown-menu bg-white ${
            dropdown ? 'show' : ''
          }`}
          data-popper-placement="bottom-start"
        >
          <span
            tabIndex="0"
            role="menuitem"
            className="dropdown-item d-flex align-items-center text-secondary"
          >
            <span>Role: </span>
            {auth?.user?.profile?.cbs_RoleDescription}
          </span>
          <span
            tabIndex="0"
            role="menuitem"
            className="dropdown-item d-flex align-items-center text-secondary"
          >
            <span>Branch: </span>
            {auth?.user?.profile?.cbs_EmployeeBranchDescription}
          </span>
          <span
            tabIndex="0"
            role="menuitem"
            className="dropdown-item d-flex align-items-center text-secondary"
          >
            <span>Dept: </span>
            {auth?.user?.profile?.cbs_EmployeeDepartmentDescription}
          </span>
          <div className="dropdown-divider"></div>
          <button
            onClick={handleOpenProfileModal}
            tabIndex="0"
            role="menuitem"
            className="dropdown-item d-flex align-items-center"
          >
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            View Profile{' '}
          </button>
          <a
            href={localize('#')}
            tabIndex="0"
            role="menuitem"
            className="dropdown-item d-flex align-items-center"
            onClick={() => onLock()}
          >
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            Lock screen
          </a>
          <div className="dropdown-divider"></div>
          <div
            onClick={onLogout}
            className="dropdown-item d-flex align-items-center"
          >
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>Log Out</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserPill
