/** @format */
import localize from '../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import React, { useEffect, useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import { getData, postData } from '../../../../../Helpers/webApi'
import swal from 'sweetalert2'

export const FlagUnflagBankersChequeBook = ({
  selectedBankersChequeLeaf,
  setSelectedBankersChequeLeaf,
  selectedBankersChequeBook,
  onFlagUnflagChequeLeaf,
}) => {
  const [bankLinkage, setBankLinkage] = useState(null)
  const [isBusy, setIsBusy] = useState(false)

  const fetchBankLinkage = async () => {
    const { success, data } = await getData(
      '/BankLinkage/find-by-id',
      {
        bankLinkageId:
          selectedBankersChequeLeaf?.bankersChequeBookBankLinkageId,
      },
      false,
    )
    if (success) {
      setBankLinkage(data)
    }
  }

  const flagUnflagBankersChequeBook = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/BankersChequeBook/flag-cheque-leaf',
      {
        ...selectedBankersChequeLeaf,
      },
      false,
    )
    if (success) {
      onFlagUnflagChequeLeaf()
      swal.fire(
        'Flag/Unflag Bankers Cheque Book',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  useEffect(() => {
    return () => {
      fetchBankLinkage()
    }
  }, [selectedBankersChequeLeaf?.bankersChequeBookBankLinkageId])

  return (
    <>
      <ModalTemplate
        handleAction={flagUnflagBankersChequeBook}
        disableCreate={isBusy}
        handleClose={() => {
          onFlagUnflagChequeLeaf()
        }}
        modalMode={'view'}
        modalTitle={'flagUnflagBankersChequeBook'}
        modalClassName={'modal-xl'}
      >
        <div
          className="p-2"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('bank')}
              </label>
              <input
                className="form-control"
                disabled
                value={bankLinkage?.bankName}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('bankBranch')}
              </label>
              <input
                className="form-control"
                value={bankLinkage?.bankBranchName}
                disabled
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('glAccountName')}
              </label>
              <input
                className="form-control"
                value={bankLinkage?.chartOfAccountName}
                disabled
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('numberOfLeaves')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedBankersChequeBook?.numberOfLeaves}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('initialLeafNumber')}
              </label>
              <input
                className="form-control"
                disabled
                value={selectedBankersChequeBook?.initialLeafNumber}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('serialNumber')}
              </label>
              <input
                className="form-control"
                value={selectedBankersChequeLeaf?.paddedSerialNumber}
                disabled
              />
            </div>
          </div>
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120,
                marginBottom: -10,
              }}
            >
              {localize('chequeLeafDetails')}
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('leafNumber')}
                </label>
                <input
                  className="form-control"
                  value={selectedBankersChequeLeaf?.paddedLeafNumber}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('status')}
                </label>
                <input
                  className="form-control"
                  value={selectedBankersChequeLeaf?.statusDescription}
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('action')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="BankersChequeLeafManagementAction"
                  value={selectedBankersChequeLeaf?.managementAction}
                  onChange={e =>
                    setSelectedBankersChequeLeaf({
                      ...selectedBankersChequeLeaf,
                      managementAction: e.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col d-flex align-items-center">
                <label className="col-1 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  className="form-control"
                  value={selectedBankersChequeLeaf?.reference}
                  onChange={e => {
                    setSelectedBankersChequeLeaf({
                      ...selectedBankersChequeLeaf,
                      reference: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </>
  )
}
