/** @format */

/* eslint-disable */
import React from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import CustomerAccountBalanceSpoolRequestConstituentEntries from './CustomerAccountBalanceSpoolRequestConstituentEntries'
import localize from '../../../../../Global/Localize'
import Tab from '../../../../../Components/Tabs/Tab'
function ViewCustomerAccountBalanceSpoolEntryDataModal({
  mode,
  selectedCustomerAccountBalanceSpoolRequestEntry,
  close,
}) {
  function closeModal() {
    close()
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="customerAccountBalanceSpool"
        handleClose={closeModal}
        modalClassName="modal-xl"
        actionText={mode === 'view' ? 'refresh' : ''}
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('batchNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.paddedCustomerAccountBalanceSpoolBatchNumber ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('dateFilter')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountBalanceSpoolDateFilterDescription ||
                ''
              }
            />
          </div>

          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('startDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountBalanceSpoolDurationStartDate ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('endDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountBalanceSpoolDurationEndDate ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('balanceStatistics')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountBalanceSpoolStatisticDescription ||
                ''
              }
            />
          </div>
          <div className="col-9 d-flex align-items-center">
            <label className="col-1 text-capitalize me-2">
              {localize('reference')}
            </label>
            <input
              type="text"
              className="form-control ms-4"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountBalanceSpoolReference ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('fullAccountNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.fullAccountNumber ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountStatus')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.statusDescription ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountRemarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.paddedCustomerAccountCustomerAccountNumber ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('productName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerAccountTypeTargetProductDescription ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerFullName ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerType')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerMembershipClassCustomerTypeDescription ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('customerSerial#')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerSerialNumber ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('employerName')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerEmployerDescription ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('identification#')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerIdentificationNumber ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('memberNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerReference2 ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('accountNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerReference1 ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('personalFileNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedCustomerAccountBalanceSpoolRequestEntry?.customerAccountCustomerReference3 ||
                ''
              }
            />
          </div>
        </div>
        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('constituentEntries'),
                item: (
                  <CustomerAccountBalanceSpoolRequestConstituentEntries
                    showLoader={false}
                    selectedCustomerAccountBalanceSpoolRequestEntry={
                      selectedCustomerAccountBalanceSpoolRequestEntry
                    }
                    mode={mode}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewCustomerAccountBalanceSpoolEntryDataModal
