/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import Tab from '../../../../../Components/Tabs/Tab'
import { mobileToBankRequestAuthOption } from '../../../../../Global/enumeration'
import MobileToBankRequestEntries from '../MobileToBankRequestEntries'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
function VerifyMobileToBankDataModal({
  mode,
  selectedMobileToBankRequest,
  setSelectedMobileToBankRequest,
  close,
  loadData,
}) {
  const [apportionedAmount, setApportionedAmount] = useState(null)
  const [shortageAmount, setShortageAmount] = useState(null)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(mobileToBankRequestAuthOption)[0]
  )

  function closeModal() {
    close()
    setSelectedMobileToBankRequest(null)
    setGlobalValidationErrors({})
  }

  async function handleSubmit() {
    const { success } = await putData(
      '/MobileToBankRequest/audit-mobile-to-bank-request-reconciliation',
      {
        mobileToBankRequest: selectedMobileToBankRequest,
        requestAuthOption: selectedAuth,
      },
      true,
      'Operation Completed Successfully'
    )

    if (success) {
      setSelectedMobileToBankRequest(null)
      loadData()
      closeModal()
    }
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="mobileToBankRequests"
        handleClose={closeModal}
        handleAction={handleSubmit}
        modalClassName="modal-xl"
        actionText={mode === 'verify' ? 'update' : ''}
        footerChildren={
          <>
            <div className="col-3 d-flex align-items-center mt-3">
              <label className="col-3 text-capitalize">
                {localize('apportioned')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={apportionedAmount || ''}
              />
            </div>

            <div className="col-3 d-flex align-items-center mt-3">
              <label className="col-2 text-capitalize">
                {localize('shortage')}
              </label>
              <input
                type="text"
                className="form-control ms-3"
                disabled
                value={shortageAmount || ''}
              />
            </div>
            <div className="col-md-3 mt-3">
              <div
                className="d-flex align-items-center mb-1 with-validation"
                validation-message={
                  globalValidationErrors['MobileToBankRequest.AuditRemarks']
                    ?.message
                }
              >
                <label className="text-capitalize col-2 me-2">
                  {localize('remarks')}
                </label>
                <input
                  defaultValue={selectedMobileToBankRequest?.auditRemarks}
                  type="text"
                  className="form-control ms-4"
                  onChange={e => {
                    clearValidation('MobileToBankRequest.AuditRemarks')
                    setSelectedMobileToBankRequest({
                      ...selectedMobileToBankRequest,
                      auditRemarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-1 mt-3">
              <div className="d-flex align-items-center mb-2">
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="MobileToBankRequestAuthOption"
                  onChange={e => setSelectedAuth(e.value)}
                />
              </div>
            </div>
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('mobileToBankTerminal')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                selectedMobileToBankRequest?.mobileToBankTerminalDescription ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionId')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.transID || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionAmount')}
            </label>
            <input
              type="number"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.transAmount || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('billReferenceNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.billRefNumber || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('mSISDN')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.msisdn || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('businessShortCode')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.businessShortCode || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('invoiceNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.invoiceNumber || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('transactionTime')}
            </label>
            <input
              type="date"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.transTime, false) || ''
              }
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('kYCInfor')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.kycInfo || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('senderType')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.senderTypeDescription || ''}
            />
          </div>
          <div className="col-6 d-flex align-items-center">
            <label className="col-2 text-capitalize">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.remarks || ''}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('status')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.statusDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('exceptionMessage')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.exceptionMessage || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('modifiedBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.modifiedBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('modifiedDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.modifiedDate, false) ||
                ''
              }
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('recordStatus')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.recordStatusDescription || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={selectedMobileToBankRequest?.createdBy || ''}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.createdDate, false) ||
                ''
              }
            />
          </div>
          <div className="col d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('valueDate')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              disabled
              value={
                formatDate(selectedMobileToBankRequest?.valueDate, false) || ''
              }
            />
          </div>
        </div>

        <div className="col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('entries'),
                item: (
                  <MobileToBankRequestEntries
                    showLoader={false}
                    selectedMobileToBankRequest={selectedMobileToBankRequest}
                    mode={mode}
                    entryStatus={1}
                    setApportioned={setApportionedAmount}
                    setShortage={setShortageAmount}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default VerifyMobileToBankDataModal
