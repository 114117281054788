/** @format */

import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { useState } from 'react'
import GroupedTable from '../../../../Components/Tables/GroupedTable'
import { formatDate } from '../../../../Helpers/formatters'

export const NextOfKinPreference = ({
  nextOfKinPreference,
  doGrouping,
  removeNextOfKin,
  addMode,
}) => {
  const [groupBy, setGroupBy] = useState({
    label: localize('vitalStatus'),
    column: 'vitalStatusDescription',
  })
  const groupingColumns = [
    {
      label: localize('genderDescription'),
      template: r => <>{r.genderDescription}</>,
    },
    {
      label: localize('identityCardTypeDescription'),
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.identityCardSerialNumber}</>,
    },
  ]

  const columns = [
    {
      label: localize('vitalStatusDescription'),
      template: r => <>{r.vitalStatusDescription}</>,
    },
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    {
      label: localize('birthDate'),
      template: r => <>{formatDate(r.birthDate)}</>,
    },
    {
      label: localize('relationshipDescription'),
      template: r => <>{r.relationshipDescription}</>,
    },
    { label: localize('gender'), template: r => <>{r.genderDescription}</> },
    {
      label: localize('identityCardType'),
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.identityCardSerialNumber}</>,
    },
    {
      label: localize('nominatedPercentage'),
      template: r => <>{r.nominatedPercentage}</>,
    },
    {
      label: localize('vitalStatusDate'),
      template: r => <>{formatDate(r.vitalStatusDate)}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: localize('addressStreet'), template: r => <>{r.addressStreet}</> },
    {
      label: localize('addressPostalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internet'),
      template: r => <>{r.addressInternet}</>,
    },
    {
      label: localize('isNextOfKin'),
      template: r => (
        <span className="text-capitalize">{r.isNextOfKin.toString()}</span>
      ),
    },
    {
      label: localize('isNominee'),
      template: r => (
        <span className="text-capitalize">{r.isNominee.toString()}</span>
      ),
    },
    {
      label: localize('isBenevolentSpouse'),
      template: r => (
        <span className="text-capitalize">
          {r.isBenevolentSpouse.toString()}
        </span>
      ),
    },
    {
      label: localize('isBenevolentChild'),
      template: r => (
        <span className="text-capitalize">
          {r.isBenevolentChild.toString()}
        </span>
      ),
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return doGrouping ? (
    <GroupedTable
      columns={columns}
      data={nextOfKinPreference}
      groupBy={groupBy}
    />
  ) : (
    <SimpleTable
      columns={columns}
      data={nextOfKinPreference}
      groupBy={groupBy}
      contextMenu={r =>
        addMode && (
          <>
            <div
              onClick={() => {
                removeNextOfKin(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash"></i>
              {localize('remove')}
            </div>
          </>
        )
      }
    />
  )
}
