/** @format */

import localize from '../../../../../../../Global/Localize'
import {
  formatCurrency,
  stripNonNumericCharacters,
} from '../../../../../../../Helpers/formatters'
/* eslint-disable */
import React, { useState } from 'react'
import { postData } from '../../../../../../../Helpers/webApi'
import ActionModal from '../../../../../../../Components/ActionModal/ActionModal'
import Swal from 'sweetalert2'

export const TellerBalancesInformation = ({
  balanceSummary,
  selectedCustomerAccount,
  withdrawableAmount,
  selectedTransactionType,
  selectedPaymentVoucher,
  currencyTotalValue,
  chequeDepositAmount,
  newBookBalance,
  newAvailableBalance,
  selectedExternalCheque,
  externalChequePayables,
  selectedChequeType,
  setShowLoading,
  onResultConfirmed,
  transactionModel,
  setTransactionModel,
  onSubmitTransaction,
}) => {
  const [showActionDialog, setShowActionDialog] = useState(false)
  const [showCashWithdrawalRequestDialog, setShowCashWithdrawalRequestDialog] =
    useState(false)

  const initiateCustomerAccountTransaction = async () => {
    setShowLoading(true)
    const { success, data } = await postData(
      '/lookup/Teller/initiate-customer-account-transaction',
      {
        frontOfficeTransactionType: selectedTransactionType,
        selectedCustomerAccount: { ...selectedCustomerAccount },
        selectedPaymentVoucher: { ...selectedPaymentVoucher },
        currencyCountModel: {
          ...currencyTotalValue,
          total: currencyTotalValue,
        },
        chequeDepositAmount: chequeDepositAmount || '',
        newExternalCheque: { ...selectedExternalCheque },
        previousTellerBalance: balanceSummary?.previousTellerBalance,
        withdrawalAmount: stripNonNumericCharacters(
          transactionModel?.cashWithdrawalAmount
        ),
        reference: transactionModel?.reference,
      },
      false,
      null
    )

    if (success) {
      if (data.result) {
        setShowActionDialog(true)
        setShowLoading(false)
        onSubmitTransaction()
      }
    } else {
      setShowLoading(false)
    }
  }

  const handleOnProceedWithTransaction = async () => {
    setShowActionDialog(false)
    if (selectedTransactionType == '4') {
      setShowCashWithdrawalRequestDialog(true)
    }
    if (selectedTransactionType != '4') {
      await performCustomerAccountTransaction()
    }
  }
  const handleOnCancelTransaction = () => {
    setShowActionDialog(false)
  }

  const performCustomerAccountTransaction = async () => {
    setShowLoading(true)
    const { success, data } = await postData(
      '/lookup/Teller/perform-customer-account-transaction',
      {
        FrontOfficeTransactionType: selectedTransactionType,
        WithdrawalAmount: stripNonNumericCharacters(
          transactionModel?.cashWithdrawalAmount
        ),
        SelectedChequeType: { ...selectedChequeType },
        SelectedCustomerAccount: { ...selectedCustomerAccount },
        SelectedPaymentVoucher: { ...selectedPaymentVoucher },
        ExternalChequePayables: externalChequePayables || [],
        CurrencyCountModel: {
          ...currencyTotalValue,
          total: currencyTotalValue,
        },
        ChequeDepositAmount: chequeDepositAmount || '',
        NewExternalCheque: { ...selectedExternalCheque },
        PreviousTellerBalance: balanceSummary?.previousTellerBalance,
      },
      false,
      null
    )
    if (success) {
      if (data.result) {
        onSubmitTransaction()
        setShowLoading(false)
        await Swal.fire({
          icon: 'success',
          title: 'Teller/Savings Receipts Payments',
          text: 'Operation Completed Successfully',
        }).then(async result => {
          if (result.isConfirmed) {
            onResultConfirmed()

            const url = 'http://localhost:8888'
            const printData = {
              TransactionType: 1,
              PrintPayload: {
                DocumentName: 'Test',
                PrintingMode: 0,
                UseDefaultPrinter: true,
                Data: data.message, // Assuming 'data' is a variable available in the scope
              },
            }

            try {
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  // Add any additional headers if needed
                },
                body: JSON.stringify(printData),
              })

              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`)
              }

              const result = await response.json()

              // Assuming the response has a 'success' property
              const { success } = result

              // Further processing based on the 'success' value
            } catch (error) {
              // Handle errors during the fetch operation
              console.error('Fetch error:', error.message)
            }
            //downloadBase64File(data.message, 'receipt.txt')
          }
        })
      }
    }
  }

  const handleOnPlaceCashWithdrawalRequest = async () => {
    setShowCashWithdrawalRequestDialog(false)
    await placeCashWithdrawalRequest()
  }

  const handleOnCancelCashWithdrawalRequest = () => {
    setShowCashWithdrawalRequestDialog(false)
  }
  const placeCashWithdrawalRequest = async () => {
    setShowLoading(true)
    const { success, data } = await postData(
      '/lookup/Teller/place-cash-withdrawal-request',
      {
        FrontOfficeTransactionType: selectedTransactionType,
        WithdrawalAmount: stripNonNumericCharacters(
          transactionModel?.cashWithdrawalAmount
        ),
        SelectedChequeType: { ...selectedChequeType },
        SelectedCustomerAccount: { ...selectedCustomerAccount },
        SelectedPaymentVoucher: { ...selectedPaymentVoucher },
        ExternalChequePayables: externalChequePayables || [],
        CurrencyCountModel: {
          ...currencyTotalValue,
          total: currencyTotalValue,
        },
        ChequeDepositAmount: chequeDepositAmount || '',
        NewExternalCheque: { ...selectedExternalCheque },
        PreviousTellerBalance: balanceSummary?.previousTellerBalance,
      },
      false,
      null
    )
    if (success) {
      if (data.result) {
        setShowLoading(false)
        await Swal.fire({
          icon: 'success',
          title: 'Teller/Savings Receipts Payments',
          text: 'Operation Completed Successfully',
        })
      }
    } else {
      onSubmitTransaction()
    }
    setShowLoading(false)
  }

  const downloadBase64File = (base64, fileName) => {
    const link = document.createElement('a')
    link.href = `data:text/plain;base64,${base64}`
    link.download = fileName
    link.click()
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 120,
          }}
        >
          {localize('summary')}
        </div>
        <div className="d-flex align-items-center mt-3 mb-3">
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('currentAvailableBalance')}:
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                selectedCustomerAccount?.availableBalance,
                2,
                2,
                false
              )}
            />
          </div>
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('newBookBalance')}:
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(newBookBalance, 2, 2, false)}
            />
          </div>
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('newAvailableBalance')}:
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(newAvailableBalance, 2, 2, false)}
            />
          </div>
        </div>
        <div className="d-flex align-items-center  mt-3 mb-3">
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('withdrawableAmount')}:
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(withdrawableAmount, 2, 2, false)}
            />
          </div>
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('currentTellerBalance')}:
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                balanceSummary?.previousTellerBalance,
                2,
                2,
                false
              )}
            />
          </div>
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('newTellerBalance')}:
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                balanceSummary?.newTellerBalance,
                2,
                2,
                false
              )}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex align-content-center justify-content-end mt-2 bg-light p-2 m-1 mb-2"
        style={{ borderRadius: 5 }}
      >
        <div className="d-flex align-items-center me-5">
          <button
            className="btn btn-success"
            onClick={initiateCustomerAccountTransaction}
          >
            {localize('update')}
          </button>
        </div>
      </div>
      {showActionDialog && (
        <ActionModal
          modalTitle={'Teller Savings Receipts/Payments'}
          handleAction={handleOnProceedWithTransaction}
          handleClose={handleOnCancelTransaction}
        >
          <div>Are you sure you want to proceed?</div>
        </ActionModal>
      )}
      {showCashWithdrawalRequestDialog && (
        <ActionModal
          modalTitle={'Teller Savings Receipts/Payments'}
          handleAction={handleOnPlaceCashWithdrawalRequest}
          handleClose={handleOnCancelCashWithdrawalRequest}
        >
          <div>
            Do you want to proceed and place a cash withdrawal authorization
            request?
          </div>
        </ActionModal>
      )}
    </>
  )
}
