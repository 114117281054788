/** @format */

/* eslint-disable */
import React from 'react'
import Swal from 'sweetalert2'
import ExportButton from '../../../../../../Components/Buttons/ExportButton/ExportButton'
import { getData } from '../../../../../../Helpers/webApi'

const ExportSystemTransactions = ({ reqParams }) => {
  const handleExport = async () => {
    const url = '/Journal/export-journals-by-transaction-code-and-filter'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      let newBlobString = `data:text/csv;base64,${data?.content}` // Fixed the closing backtick

      const element = document.createElement('a')
      element.href = newBlobString
      element.download = `systemTransactionJournal_${new Date().toLocaleDateString()}.csv`
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()
      element.remove()
    } else {
      Swal.fire('Error', 'Error exporting data', 'error')
    }
  }

  return <ExportButton handleExportButton={handleExport} />
}

export default ExportSystemTransactions
