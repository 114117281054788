/** @format */

import React, { useState, useEffect } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import localize from '../../../../../../Global/Localize'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import GoodsDispatchNoteEntriesItems from './GoodsDispatchNoteEntriesItems'
import { formatDate } from '../../../../../../Helpers/formatters'

const CancelGoodsDispatchNote = ({
  mode,
  goodsDispatchNote,
  setGoodsDispatchNote,
  closeModal,
  loadTableData,
}) => {
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [existingEntries, setExistingEntries] = useState([])
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/GoodsDispatchNote/find-goods-dispatch-note-items-by-goods-dispatch-note-id-filter-in-page`,
      {
        goodsDispatchNoteId: goodsDispatchNote?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [goodsDispatchNote?.id, reqParams.pageSize, reqParams.pageIndex])

  const closePage = () => {
    closeModal()
  }

  const cancelGoodsDispatchNote = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/GoodsDispatchNote/cancel-goods-dispatch-note',
      {
        goodsDispatchNote: { ...goodsDispatchNote, id: goodsDispatchNote?.id },
      },
      false
    )

    if (success) {
      setGoodsDispatchNote(null)
      setIsBusy(false)
      closePage()
      loadTableData()
      showNotification('Cancel Goods Dispatch Note', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <ModalTemplate
      modalMode={'cancel'}
      modalTitle="goodsDispatchNote"
      handleClose={closePage}
      modalClassName="modal-xl"
      handleAction={cancelGoodsDispatchNote}
      actionText={'cancel'}
      footerChildren={
        <>
          <div
            className="d-flex align-items-center mb-1 with-validation px-4 mt-2"
            validation-message={
              globalValidationErrors['GoodsDispatchNote.CancellationRemarks']
                ?.message
            }
          >
            <label className="text-capitalize col-3 me-2">
              {localize('remarks')}
            </label>
            <input
              defaultValue={goodsDispatchNote?.cancellationRemarks}
              type="text"
              className="form-control ms-4"
              onChange={e => {
                clearValidation('GoodsDispatchNote.CancellationRemarks')
                setGoodsDispatchNote({
                  ...goodsDispatchNote,
                  cancellationRemarks: e.target.value,
                })
              }}
            />
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('reference#')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.paddedReferenceNumber}
              />
            </div>

            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize ">
                {localize('department')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.departmentDescription}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-3">
                {localize('branch')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.branchDescription}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize me-2">
                {localize('createdBy')}
              </label>
              <input
                className="form-control ms-4"
                disabled
                defaultValue={goodsDispatchNote?.createdBy}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('createdDate')}
              </label>
              <input
                className="form-control ms-4"
                disabled
                defaultValue={formatDate(goodsDispatchNote?.createdDate)}
              />
            </div>
            <div className="col d-flex align-items-center mb-1 ">
              <label className="text-capitalize  ">
                {localize('employee')}
              </label>
              <input
                className="form-control ms-3"
                disabled
                defaultValue={goodsDispatchNote?.customerFullName}
              />
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('goodsDispatchNoteItems'),
                  item: (
                    <GoodsDispatchNoteEntriesItems
                      mode={mode}
                      entries={existingEntries}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CancelGoodsDispatchNote
