/** @format */

import UserManagerClient from './userManagerClient'
import { SESSION_KEY } from './constants'
import { User } from 'oidc-client-ts'
import { pingCompanionApp } from './companionApp'
import userManagerClient from './userManagerClient'
import signalRService from './signalR'
import { getData } from './webApi'

class AuthHelper {
  constructor() {
    this.currentUserProfile = null
    this.machineInformation = null
    this.registerAuthEvents()
  }

  registerAuthEvents() {
    this.attachMachineInformation()
    this.eventOnTokenExpiration()
    this.eventOnUserAuthenticated()
    this.getUserProfileFromStorage()
  }

  attachMachineInformation() {
    pingCompanionApp()
      .then(data => {
        this.machineInformation = data.machineInformation
        signalRService.setMachineInformation(this.machineInformation)
      })
      .catch(error => {})
  }

  getUserProfileFromStorage() {
    const oidcStorage = sessionStorage.getItem(SESSION_KEY)
    if (!oidcStorage) {
      return null
    }
    this.currentUserProfile = User.fromStorageString(oidcStorage)
    signalRService.initializeConnection()
    return this.currentUserProfile
  }

  async fetchUserClientClaims() {
    const { success, data } = await getData(
      '/AccessControl/find-current-user-menu-claims',
    )
    if (success) {
      return data
    }
  }

  async refreshSessionKey() {
    const { success, data } = await getData(
      '/AccessControl/refresh-session-key',
      false,
    )
    if (success) {
    }
  }


  eventOnUserAuthenticated() {
    UserManagerClient.events.addUserLoaded(user => {
      UserManagerClient.getUser().then(userProfile => {
        if (userProfile) {
          signalRService.initializeConnection()
          this.currentUserProfile = userProfile
          this.fetchUserClientClaims().then(menuCodes => {
            this.updateUserMenuCodes(menuCodes)
          })
        }
      })
    })
  }

  updateUserMenuCodes(menuCodes) {
    userManagerClient
      .storeUser(
        new User({
          ...this.currentUserProfile,
          profile: {
            ...this.currentUserProfile.profile,
            client_menus: menuCodes,
          },
        }),
      )
      .then(() =>
        UserManagerClient.getUser().then(updatedUserProfile => {
          this.currentUserProfile = updatedUserProfile
        }),
      )
  }

  updateUserMenuCodesWithNewUserProfile(menuCodes, userProfile) {
    userManagerClient
      .storeUser(
        new User({
          ...userProfile,
          profile: {
            ...userProfile.profile,
            client_menus: menuCodes,
          },
        }),
      )
      .then(() =>
        UserManagerClient.getUser().then(updatedUserProfile => {
          this.currentUserProfile = updatedUserProfile
          setTimeout(() => {
            return this.refreshSessionKey()
          }, 500)
        }),
      )
  }

  eventOnTokenExpiration() {
    UserManagerClient.events.addAccessTokenExpiring(() => {
      UserManagerClient.signinSilent()
        .then(userProfile => {
          this.updateUserMenuCodesWithNewUserProfile(
            this.currentUserProfile.profile.client_menus,
            userProfile,
          )
        })
        .catch(err => console.log(err))
      UserManagerClient.signinSilentCallback().catch(err =>
        console.log('callback error: ' + err),
      )
    })
  }

}

const authInfo = new AuthHelper()

export default authInfo
