/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import {
  meetingDayOfWeek,
  meetingFrequency,
} from '../../../../../../Global/enumeration'
import MemberDetailsPartial from '../Shared/MemberDetailsPartial'
import { parseDate } from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function PartnershipParticulars({
  customer,
  members,
  readOnly,
  onChange,
  noMembers,
}) {
  const [partner, setPartner] = useState(customer)

  useEffect(() => {
    if (!readOnly) {
      onChange(partner, members)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner])

  return (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('name')}
          </label>
          <input
            type="text"
            className="form-control"
            value={partner?.nonIndividualDescription}
            onChange={e =>
              setPartner({
                ...partner,
                nonIndividualDescription: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('registration')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={partner?.nonIndividualRegistrationNumber}
            onChange={e =>
              setPartner({
                ...partner,
                nonIndividualRegistrationNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('registrationSerial')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={partner?.nonIndividualRegistrationSerialNumber}
            onChange={e =>
              setPartner({
                ...partner,
                nonIndividualRegistrationSerialNumber: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('dateEstablished')}
          </label>
          <input
            type="date"
            className="form-control"
            value={parseDate(partner?.nonIndividualDateEstablished)}
            onChange={e =>
              setPartner({
                ...partner,
                nonIndividualDateEstablished: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('PIN')} #
          </label>
          <input type="text" className="form-control" disabled={readOnly} />
        </div>
        <div className="col d-flex align-items-center">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('meetingFrequency')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="MeetingFrequency"
              value={partner?.nonIndividualMeetingFrequency}
              onChange={e =>
                setPartner({
                  ...partner,
                  nonIndividualMeetingFrequency: e.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="col-4 text-capitalize">
              {localize('meetingFrequency')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="MeetingDayOfWeek"
              value={partner?.nonIndividualMeetingDayOfWeek}
              onChange={e =>
                setPartner({
                  ...partner,
                  nonIndividualMeetingDayOfWeek: e.value,
                })
              }
            />
          </div>
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('meetingTime')}
          </label>
          <input
            type="time"
            className="form-control"
            value={partner?.nonIndividualMeetingTime}
            onChange={e =>
              setPartner({
                ...partner,
                nonIndividualMeetingTime: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="col-4 text-capitalize">
            {localize('meetingPlace')}
          </label>
          <input
            type="text"
            className="form-control"
            value={partner?.nonIndividualMeetingPlace}
            onChange={e =>
              setPartner({
                ...partner,
                nonIndividualMeetingPlace: e.target.value,
              })
            }
            disabled={readOnly}
          />
        </div>
      </div>
      {!noMembers && (
        <div className="mt-2">
          <MemberDetailsPartial
            readOnly={readOnly}
            members={members}
            onChange={m => {
              onChange(partner, m)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default PartnershipParticulars
