/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ActionModal from '../../../../../Components/ActionModal/ActionModal'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../Components/Loaders/Loader'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import ViewGLAccountTransactions from '../../../../../Components/ReUsables/GLAccounts/ViewGLAccountTransactions/ViewGLAccountTransactions'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { formatDate } from '../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../Helpers/webApi'

const initialSystemAccountCode = 48842

function AccountDetermination() {
  const columns = [
    {
      label: 'systemGLAccountCode',
      template: r => <>{r?.systemGeneralLedgerAccountCodeDescription}</>,
    },
    {
      label: 'mappedGLAccountName',
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: 'mappedGLAccountCostDistributionRule',
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    { label: 'modifiedBy', template: r => <>{r?.modifiedBy}</> },
    {
      label: 'modifiedDate',
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: 'createdBy', template: r => <>{r?.createdBy}</> },
    { label: 'createdDate', template: r => <>{formatDate(r?.createdDate)}</> },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [isBusyLoadingGl, setIsBusyLoadingGl] = useState(false)
  const [data, setData] = useState(null)
  const [params, setParams] = useState({ pageIndex: 0, pageSize: 10 })
  const [systemAccountCode, setSystemAccountCode] = useState(
    initialSystemAccountCode
  )
  const [gl, setGl] = useState(null)
  const [selectedGL, setSelectedGL] = useState(false)
  const [mode, setMode] = useState(null)
  const [isBusyRemoving, setIsBusyRemoving] = useState(false)

  const handleClickRemoveButton = async r => {
    setMode('remove')
    setSelectedGL(r)
  }

  const handleClose = () => {
    setMode(null)
    setSelectedGL(null)
  }

  async function getLedgerAccountMapping() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/ChartOfAccount/get-system-general-ledger-account-mapping-for-system-general-ledger-account-code',
      { systemGeneralLedgerAccountCode: systemAccountCode }
    )
    if (success) {
      await getSystemGLAccount(data)
    }

    setIsBusy(false)
  }

  async function getSystemGLAccount({ chartOfAccountId }) {
    setIsBusyLoadingGl(true)
    const { success, data } = await getData(
      '/ChartOfAccount/find-general-ledger-account',
      { chartOfAccountId }
    )
    if (success) {
      setGl(data)
    }

    setIsBusyLoadingGl(false)
  }

  async function mapGLAccount() {
    setIsBusy(true)
    const { success } = await putData(
      `/ChartOfAccount/map-system-general-ledger-account-code-to-chart-of-account?systemGeneralLedgerAccountCode=${systemAccountCode}&chartOfAccountId=${gl?.id}`,
      {},
      false
    )

    if (success) {
      showNotification('G/L Account Determination', 'success')
      listAccounts()
      setSystemAccountCode(initialSystemAccountCode)
      getLedgerAccountMapping()
    }

    setIsBusy(false)
  }

  async function listAccounts() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/ChartOfAccount/find-system-general-ledger-account-mappings-in-page',
      params,
      false
    )
    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  async function removeMapping() {
    setIsBusyRemoving(true)
    const { success } = await putData(
      `/chartofaccount/remove-system-general-ledger-account-mapping?systemGeneralLedgerAccountMappingId=${selectedGL?.id}`,
      {},
      false
    )
    if (success) {
      showNotification('G/L Account Determination', 'success')
      listAccounts()
      handleClose()
    }

    setIsBusyRemoving(false)
  }

  useEffect(() => {
    listAccounts()
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    getLedgerAccountMapping()
    // eslint-disable-next-line
  }, [systemAccountCode])

  return (
    <>
      {mode === 'remove' && (
        <ActionModal
          modalTitle="removeGLAccountMapping"
          handleClose={handleClose}
          handleAction={() => removeMapping(selectedGL)}
          disableAction={isBusyRemoving}
          disableClose={isBusyRemoving}
        >
          {isBusyRemoving ? (
            <Loader />
          ) : (
            <p>
              Are you sure you want to remove {selectedGL?.chartOfAccountName}?
            </p>
          )}
        </ActionModal>
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('GLAccountDetermination'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white rounded">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <div className="col d-flex align-items-center gap-3">
                  <label className="text-capitalize">
                    {localize('systemGLAccountCode')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="SystemGeneralLedgerAccountCode"
                    value={systemAccountCode}
                    onChange={e => setSystemAccountCode(e.value)}
                  />
                </div>
                <div className="col d-flex align-items-center gap-3">
                  <label className="text-capitalize col-md-4 col-lg-3">
                    {localize('mappedGLAccountName')}
                  </label>
                  {isBusyLoadingGl ? (
                    <div style={{ width: 20, height: 20 }}>
                      <i className="m-1 spinner-border spinner-sm text-dark"></i>
                    </div>
                  ) : (
                    <ChartOfAccountsLookup
                      displayValue={gl?.name}
                      onSelect={a => setGl(a)}
                    />
                  )}
                </div>
              </div>
              <div className="row bg-light rounded align-items-center d-flex justify-content-end p-2 my-2">
                <button
                  className="btn btn-success text-uppercase px-4"
                  style={{ maxWidth: 'fit-content' }}
                  onClick={mapGLAccount}
                >
                  {localize('update')}
                </button>
              </div>
              <div className="m-2 my-3 d-flex align-items-center justify-content-between row">
                <RecordsPerPage
                  className="col-md-3"
                  reqParams={params}
                  setReqParams={setParams}
                />
                {/* TODO: myke- add search functionality in wcf side 12/13/2023 */}
              </div>
              <SimpleTable
                data={data?.pageCollection}
                columns={columns}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedGL(r)
                        setMode('view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span className="text-capitalize">
                        {localize('view')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item text-danger"
                      onClick={() => {
                        handleClickRemoveButton(r)
                      }}
                    >
                      <i className="uil uil-times-circle"></i>
                      <span className="text-capitalize">
                        {localize('remove')}
                      </span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={data?.itemsCount}
                pageSize={params?.pageSize}
                pageIndex={params?.pageIndex}
                onPageChange={p => setParams({ ...params, pageIndex: p })}
                pageBatchSize={5}
              />
            </>
          )}
        </div>
      </div>
      {mode === 'view' && (
        <ViewGLAccountTransactions
          glAccount={selectedGL}
          mode={mode}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default AccountDetermination
