/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import AddCustomer from '../../../../Registry/Members/Partials/Customers/AddCustomer'

const GuarantorAttachmentHistories = ({
  loanGuarantorAttachmentHistoryId,
  canSelect,
  selectedHistories,
  handleSelectHistory,
  handleSelectAllHistories,
  selectAll,
  setSelectAll,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [guarantorAttachmentHistories, setGuarantorAttachmentHistories] =
    useState([])
  const [guarantorAttachmentHistory, setGuarantorAttachmentHistory] = useState(
    {}
  )
  const [mode, setMode] = useState(null)

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          checked={selectedHistories?.find(h => h?.id === r?.id)}
          onCheck={value => {
            handleSelectHistory(r, value)
          }}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r?.fullAccountNumber,
    },
    {
      label: localize('productName'),
      template: r =>
        r?.destinationCustomerAccountCustomerAccountTypeTargetProductDescription,
    },
    {
      label: localize('loanNumber'),
      template: r =>
        r?.destinationCustomerAccountLoanCaseDocumentReferenceNumber,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.destinationCustomerAccountCustomerReference1,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.destinationCustomerAccountCustomerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.destinationCustomerAccountCustomerReference3,
    },
    {
      label: localize('principalAttached'),
      template: r => formatCurrency(r?.principalAttached),
      headerTextAlign: 'right',
    },
    {
      label: localize('interestAttached'),
      template: r => formatCurrency(r?.interestAttached),
      headerTextAlign: 'right',
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const columnsToDisplay = columns?.filter((_col, i) => i !== 0)

  const handleClose = () => {
    setMode(null)
    setGuarantorAttachmentHistory({})
  }

  const handleClickContextMenu = (r, _mode) => {
    setGuarantorAttachmentHistory(r)
    setMode('edit')
  }

  const fetchLoanGuarantorAttachmentHistories = async () => {
    setIsBusy(true)
    const url =
      '/LoanCase/find-loan-guarantor-attachment-history-entries-by-loan-guarantor-attachment-history-id'

    const { success, data } = await getData(
      url,
      {
        loanGuarantorAttachmentHistoryId,
      },
      false
    )

    if (success) {
      setGuarantorAttachmentHistories(data?.result)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (loanGuarantorAttachmentHistoryId)
      fetchLoanGuarantorAttachmentHistories()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanGuarantorAttachmentHistoryId])

  console.log('guarantorAttachmentHistories', guarantorAttachmentHistory)

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          {mode && (
            <AddCustomer
              closeModal={handleClose}
              customer={{
                id: guarantorAttachmentHistory?.destinationCustomerAccountCustomerId,
              }}
              mode="view"
              readOnly
              refreshFromServer
              onMinimize={() => {}}
            />
          )}

          <SimpleTable
            columns={canSelect ? columns : columnsToDisplay}
            data={guarantorAttachmentHistories}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleClickContextMenu(r, 'view')
                  }}
                >
                  <i className="uil uil-eye"></i>
                  <span>{localize('view')}</span>
                </div>
              </>
            )}
          />

          {canSelect && (
            <div className="d-flex justify-content-start align-items-center py-3 px-2 bg-light rounded">
              <Checkbox
                checked={selectAll}
                id={`select-all-loan-guarantor-attachment-histories`}
                onCheck={value => {
                  setSelectAll(value)
                  handleSelectAllHistories(guarantorAttachmentHistories, value)
                }}
              />
              <label
                htmlFor={`select-all-loan-guarantor-attachment-histories`}
                className="text-capitalize ms-2"
              >
                {localize('toggleSelection')}
              </label>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default GuarantorAttachmentHistories
