/** @format */

import React, { useEffect } from 'react'
import { useState } from 'react'
import { postData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const CreateSupplyCategory = ({
  mode,
  setMode,
  handleClose,
  supplyCategories,
  setSupplyCategories,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [gbv, setGbv] = GlobalValidationErrors.use()

  useEffect(() => {
    return () => setGbv({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function clearValidation(column) {
    setGbv(g => {
      delete g[column]
      return g
    })
  }

  async function handleSubmit() {
    setIsBusy(true)
    const { success } = await postData(
      '/SupplyCategory/add-supply-category',
      supplyCategories || { description: '' },
      false
    )

    if (success) {
      showNotification(
        'Supply Category',
        'success',
        'Operation completed successfully!'
      )
      setSupplyCategories(null)
      loadTableData()
      setMode(null)
    }
    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)

    const { success } = await postData(
      '/SupplyCategory/update-supply-category',
      supplyCategories,
      false
    )
    if (success) {
      showNotification('Update Supply Category', 'success')
      setMode(null)
      setSupplyCategories(null)
      loadTableData()
    }
    setIsBusy(false)
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        handleAction={mode === 'add' ? handleSubmit : handleEdit}
        modalTitle={
          mode === 'add'
            ? localize('createSupplyCategory')
            : localize('updateSupplyCategory')
        }
        handleClose={handleClose}
        footerChildren={
          <>
            <div className="d-flex align-items-center">
              <Checkbox
                id="supplyCategoryIsLocked"
                onCheck={e => {
                  setSupplyCategories({
                    ...supplyCategories,
                    isLocked: e,
                  })
                }}
                checked={supplyCategories?.isLocked}
              />
              <label
                htmlFor="supplyCategoryIsLocked"
                className="text-capitalize"
              >
                {localize('isLocked') + '?'}
              </label>
            </div>
          </>
        }
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className=" d-flex row align-items-center">
              <div
                className="d-flex col-md-12 align-items-center gap-3 with-validation"
                validation-message={gbv?.Description?.message}
              >
                <label className="text-capitalize">{localize('name')}</label>
                <input
                  className="form-control"
                  type="text"
                  value={supplyCategories?.description}
                  onChange={e => {
                    clearValidation('Description')
                    setSupplyCategories({
                      ...supplyCategories,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default CreateSupplyCategory
