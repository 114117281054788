/** @format */

import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'

export const BiometricExemptionsDetails = ({
  addMode,
  data,
  onRemoveBiometricExemptionDetail,
}) => {
  const columns = [
    {
      label: localize('transactionCode'),
      template: r => <>{r.transactionCodeDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <SimpleTable
      contextMenu={r =>
        addMode && (
          <>
            <div
              onClick={() => {
                onRemoveBiometricExemptionDetail(r)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash text-danger"></i>
              {localize('remove')}
            </div>
          </>
        )
      }
      data={data}
      columns={columns}
    />
  )
}
