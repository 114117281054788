/** @format */
import { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../Helpers/formatters'
import GroupedTable from '../../../Tables/GroupedTable'
import { postData } from '../../../../Helpers/webApi'

export const CustomerMetadataLoansGuaranteed = ({
  selectedCustomer,
  doFetchLoansGuaranteed,
}) => {
  const [groupBy, setGroupBy] = useState({
    label: localize('status'),
    column: 'statusDescription',
  })
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const columns = [
    { label: localize('status'), template: r => <>{r.statusDescription}</> },
    {
      label: localize('loanNumber'),
      template: r => <>{r.loanCaseDocumentReferenceNumber}</>,
    },
    {
      label: localize('loanStatus'),
      template: r => <>{r.loanCaseStatusDescription}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r.loanProductDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.loaneeCustomerFullName}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.loaneeCustomerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.formattedLoaneeCustomerMemberNumber}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.loaneeCustomerAddressMobileLine}</>,
    },
    {
      label: localize('amountGuaranteed'),
      template: r => <>{formatCurrency(r.amountGuaranteed)}</>,
    },
    {
      label: localize('amountPledged'),
      template: r => <>{formatCurrency(r.amountPledged)}</>,
    },
    {
      label: localize('loanApprovedAmount'),
      template: r => <>{formatCurrency(r.loanCaseApprovedAmount)}</>,
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => <>{formatCurrency(r.loanBalanceOutstanding)}</>,
    },
    {
      label: localize('loanClassification'),
      template: r => <>{r.loanClassificationDescription}</>,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => <>{formatCurrency(r.loanBalanceExpected)}</>,
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => <>{formatCurrency(r.loanPrepaidAmount)}</>,
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => <>{formatCurrency(r.loanDefaultAmount)}</>,
    },
    {
      label: localize('loanArrearsTimeline'),
      template: r => <>{r.loanDefaultTimeline}</>,
    },
    {
      label: localize('loanLossProvision'),
      template: r => <>{formatAsPercent(r.loanLossProvisionPercentage)}</>,
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => <>{formatCurrency(r.loanLossProvisionAmount)}</>,
    },
    {
      label: localize('lastLoanPaymentDueDate'),
      template: r => <>{r.loanLastPaymentDueDate}</>,
    },
    {
      label: localize('lastLoanPayment'),
      template: r => <>{r.loanLastPayment}</>,
    },
    {
      label: localize('loanPurpose'),
      template: r => <>{r.loanCaseLoanPurposeDescription}</>,
    },
    {
      label: localize('loanBranch'),
      template: r => <>{r.loanCaseBranchDescription}</>,
    },
    {
      label: localize('totalShares'),
      template: r => <>{formatCurrency(r.totalShares)}</>,
    },
    {
      label: localize('committedShares'),
      template: r => <>{formatCurrency(r.committedShares)}</>,
    },
    {
      label: localize('appraisalFactor'),
      template: r => <>{r.appraisalFactor}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  useEffect(() => {
    if (doFetchLoansGuaranteed) {
      fetchLoansGuaranteed()
    }
  }, [doFetchLoansGuaranteed])

  const fetchLoansGuaranteed = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        fetchLoansGuaranteed: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )
    if (success) {
      setData(data?.loanGuarantors)
    }
    setLoading(false)
  }
  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <GroupedTable data={data} columns={columns} groupBy={groupBy} />
      )}
    </>
  )
}
