/** @format */

import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import Loader from '../../../../Components/Loaders/Loader'
import React from 'react'
import SimpleTable from '../../../../Components/Tables/SimpleTable'

export const EditLoanRepaymentModeMappings = ({
  repaymentModeMappings,
  setRepaymentModeMappings,
  waitingMetadata,
}) => {
  const columns = [
    {
      label: localize('businessGroupEmployer'),
      template: r => <>{r?.employerDescription}</>,
    },
    {
      label: localize('loanProduct'),
      template: r => <>{r.loanProductDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {waitingMetadata ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            columns={columns}
            selectables
            data={repaymentModeMappings}
            contextMenu={e => (
              <>
                <div
                  onClick={() => {
                    setRepaymentModeMappings(
                      repaymentModeMappings.filter(r => r.id !== e.id),
                    )
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-trash"></i>
                  {localize('remove')}
                </div>
              </>
            )}
          />
        </>
      )}
    </>
  )
}
