/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _u = {
  users: {
    en: 'users',
    sw: 'watumiaji',
  },
  unpayReason: {
    en: 'unpay reason',
    sw: 'sababu ya kutolipwa',
  },
  upperLimit: {
    en: 'upper limit',
    sw: 'kikomo cha juu',
  },
  unpayReasons: {
    en: 'unpay reasons',
    sw: 'sababu za kutolipwa',
  },
  update: {
    en: 'update',
    sw: 'sasisha',
  },
  unallocatedFundsCustomerAccountTypeTargetProductDescription: {
    en: 'unallocated funds customer account type target product description',
    sw: 'fedha ambazo hazijatengwa maelezo ya bidhaa lengwa ya aina ya akaunti ya mteja',
  },
  unallocatedFundsDestination: {
    en: 'unallocated funds destination',
    sw: 'fedha ambazo hazijatengwa',
  },
  unallocatedFundsProduct: {
    en: 'unallocated funds product',
    sw: 'bidhaa za fedha ambazo hazijatengwa',
  },
  usefullLife: {
    en: 'useful life',
    sw: 'maisha kamili',
  },
  uniqueIdentifier: {
    en: 'unique identifier',
    sw: 'kitambulisho cha kipekee',
  },
  useDefaultPrinter: {
    en: 'use default printer',
    sw: 'tumia kichapishi cha chaguo-msingi',
  },
  undefined: {
    en: 'undefined',
    sw: 'isiofafanuliwa',
  },
  usefulLife: {
    en: 'useful life (yrs)',
    sw: 'yenye manufaa',
  },
  username: {
    en: 'username',
    sw: 'jina la mtumiaji',
  },
  unreconciledDiscrepancies: {
    en: 'unreconciled discrepancies',
    sw: 'tofauti zisizofanana',
  },
  unReconciledBalance: {
    en: 'unreconciled balance',
    sw: 'baki zisizofanana',
  },
  unpaidCode: {
    en: 'unpaid code',
    sw: 'msimbo',
  },
  unallocatedFundsProductName: {
    en: 'unallocated funds product name',
    sw: 'jina la bidhaa za fedha ambazo hazijatengwa',
  },
  utilities: {
    en: 'utilities',
    sw: 'huduma',
  },
  userDefinedReports: {
    en: 'user defined reports',
    sw: 'ripoti zilizofafanuliwa na mtumiaji',
  },
  userDefinedReportLookup: {
    en: 'user defined report lookup',
    sw: 'utafutaji wa ripoti zilizofafanuliwa na mtumiaji',
  },
  unclearedCheques: {
    en: 'uncleared cheques',
    sw: 'hundi zisizolipwa',
  },
  unPaidCode: {
    en: 'unpaid code',
    sw: 'msimbo',
  },
  unPaidReason: {
    en: 'unpaid reason',
    sw: 'sababu ya kutolipwa',
  },
  unpay: {
    en: 'unpay',
    sw: 'tolipa',
  },
  unpayReasonLookup: {
    en: 'unpay reason lookup',
    sw: 'utafutaji wa sababu ya kutolipwa',
  },
  updateSalaryPeriod: {
    en: 'salary period',
    sw: 'sasisha kipindi cha mshahara',
  },
  unBlock: {
    en: 'unblock',
    sw: 'ondoa kizuizi',
  },
  updateDebitCardRecord: {
    en: 'update debit card record',
    sw: 'sasisha rekodi ya kadi ya deni',
  },
  updateSalaryHeads: {
    en: 'salary heads',
    sw: 'sasisha wakuu wa mshahara',
  },
  unitOfMeasure: {
    en: 'unit of measure',
    sw: 'kipimo',
  },
  unitNetWeight: {
    en: 'unit net weight',
    sw: 'kipimo cha uzito wa mtandao',
  },
  unitOfMeasureLookup: {
    en: 'unit of measure lookup',
    sw: 'utafutaji wa kipimo',
  },
  unitGrossWeight: {
    en: 'unit gross weight',
    sw: 'kipimo cha uzito wa jumla',
  },
  unitsPerPack: {
    en: 'units per pack',
    sw: 'vipimo kwa kifurushi',
  },
  updateSupplierTypes: {
    en: 'supplier types',
    sw: 'sasisha aina za wauzaji',
  },
  updateSupplyCategory: {
    en: 'supply category',
    sw: 'sasisha jamii ya ugavi',
  },
  unitCost: {
    en: 'unit cost',
    sw: 'gharama ya kipimo',
  },
  updateMorguePatientServices: {
    en: 'update morgue patient services',
    sw: 'sasisha huduma za mgonjwa wa chumba cha kuhifadhia maiti',
  },
  usedBiometrics: {
    en: 'used biometrics',
    sw: 'alama za kibaiolojia zilizotumika',
  },
  unitPrice: {
    en: 'unit price',
    sw: 'bei ya kipimo',
  },
  updateInventoryUnitsOfMeasure: {
    en: 'inventory units of measure',
    sw: 'sasisha vipimo vya hesabu',
  },
  updateInventoryCategory: {
    en: 'inventory category',
    sw: 'sasisha jamii ya hesabu',
  },
  updateItemRegister: {
    en: 'item register',
    sw: 'sasisha daftari la bidhaa',
  },
  updateRiskRegister: {
    en: 'risk register',
    sw: 'sasisha rejista ya hatari',
  },
  usersInRole: {
    en: 'users in role',
    sw: 'watumiaji katika jukumu',
  },
  unitOfWeight: {
    en: 'unit of weight',
    sw: 'kipimo cha uzito',
  },
  updateDepth: {
    en: 'update depth',
    sw: 'sasisha kina',
  },
}

export default _u
