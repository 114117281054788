import { getData, postData } from "../webApi"

export async function loadMedia(sku) {
    const { success, data } = await getData(
        '/base64/get-media-by-sku',
        {
            sku,
        },
        false
    )
    if (success) {
        return data
    }
    return null
}

export async function uploadMedia(sku, content,) {
    const { success } = await postData(
        `/base64/post-image`,
        {
            sku,
            content,
        },
        false
    )
    if (success) {
        return true
    } else {
        return false
    }
}
