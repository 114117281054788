/** @format */
import React, { useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Loader from '../../../../Components/Loaders/Loader'

import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { getData, postData } from '../../../../Helpers/webApi'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import localize from '../../../../Global/Localize'
import { customerAutoCompleteFilter } from '../../../../Global/enumeration'
import EmolumentBatchLookup from '../../../../Components/Lookup/EmolumentBatchLookup'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import CustomerAccountPartial from '../../../../Components/ReUsables/CustomerAccount/CustomerAccountPartial'
import Tab from '../../../../Components/Tabs/Tab'
import EmolumentBatchEntries from './partials/EmolumentBatchEntries'
import { formatDate } from '../../../../Helpers/formatters'

const EmolumentsAndAllowancesPaymentProcessing = () => {
  const [reqParams, setReqParams] = useState({
    status: 2,
    text: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    includeProductDescription: true,
  })
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [entriesLoader, setEntriesLoader] = useState(false)
  const [
    selectedEmolumentsPaymentSchedule,
    setSelectedEmolumentsPaymentSchedule,
  ] = useState(null)
  const [entries, setEntries] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [emolumentBatchEntry, setEmolumentBatchEntry] = useState(null)

  const handleOnSelectAccount = account => {
    if (!account.isLocked) {
      setSelectedAccount({ ...selectedAccount, ...account })
    } else {
      showNotification(
        'Edit Emolument Batch',
        'info',
        'Selected Account is Locked!'
      )

      setSelectedAccount(null)
    }
  }

  const updateEmonumentBatchEntries = async () => {
    setLoading(true)
    if (
      entries.find(
        d => d.customerAccountId === emolumentBatchEntry.customerAccountId
      )
    ) {
      setLoading(false)
      showNotification('Emolument Batch', 'info', 'Entry already exists!')
      setEmolumentBatchEntry({ beneficiary: '', reference: '' })
      setSelectedAccount(null)
    } else {
      const { success } = await postData(
        '/EmolumentBatch/add-emolument-batch-entry',
        {
          emolumentBatchId: selectedEmolumentsPaymentSchedule?.id,
          ...emolumentBatchEntry,
        },
        false
      )

      if (success) {
        showNotification(
          'Emolument Batch Payment',
          'success',
          'Operation completed successfully!'
        )
        setLoading(false)
        setEmolumentBatchEntry({ beneficiary: '', reference: '' })
        loadEntries()
        setSelectedAccount(null)
      }
      setLoading(false)
    }
  }

  const loadEntries = async () => {
    setEntriesLoader(true)
    const { success, data } = await getData(
      `/EmolumentBatch/find-emolument-batch-entries-by-emolument-batch-id-in-page`,
      {
        emolumentBatchId: selectedEmolumentsPaymentSchedule?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setEntriesLoader(false)
      setEntries(data.pageCollection)
    } else {
      setEntriesLoader(false)
      setEntries([])
    }
  }

  const onSelectItem = (cust, checked) => {
    if (checked) {
      setEntries([...entries, cust])
    } else {
      const newEntry = entries.filter(
        data => data?.emolumentBatchId !== cust?.emolumentBatchId
      )
      setEntries(newEntry)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('paymentProcessing'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            <>
              <div className="row mb-2">
                <div className="col d-flex align-items-center mb-2  ">
                  <label className="col-3 text-capitalize">
                    {localize('batch#')}
                  </label>
                  <EmolumentBatchLookup
                    displayValue={
                      selectedEmolumentsPaymentSchedule?.paddedBatchNumber
                    }
                    onSelect={async r => {
                      setSelectedEmolumentsPaymentSchedule({
                        ...selectedEmolumentsPaymentSchedule,
                        id: r.id,
                        emolumentTypeId: r.emolumentTypeId,
                        paddedBatchNumber: r.paddedBatchNumber,
                        emolumentTypeDescription: r.emolumentTypeDescription,
                        emolumentTypeAmount: r.emolumentTypeAmount,
                        chartOfAccountName: r.chartOfAccountName,
                        chartOfAccountId: r.chartOfAccountId,
                        reference: r.reference,
                        priority: r.priority,
                        postingPeriodDescription: r.postingPeriodDescription,
                        postingPeriodId: r.postingPeriodId,
                        createBy: r.createdBy,
                        createdDate: r.createdDate,
                        auditedBy: r.auditedBy,
                        auditedDate: r.auditedDate,
                        auditRemarks: r.auditRemarks,
                        authorizedBy: r.authorizedBy,
                        authorizedDate: r.authorizedDate,
                        authorizationRemarks: r.authorizationRemarks,
                      })
                      await loadEntries()
                    }}
                    status={2}
                  />
                </div>
                <div className="col d-flex align-items-center mb-2  ">
                  <label className="col-3 text-capitalize">
                    {localize('emolumentType')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedEmolumentsPaymentSchedule?.emolumentTypeDescription
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center mb-2">
                  <label className="col-2 me-5 text-capitalize">
                    {localize('amount')}
                  </label>
                  <MaskNumber
                    className="form-control me-1 ms-4"
                    defaultMaskValue={
                      selectedEmolumentsPaymentSchedule?.emolumentTypeAmount
                    }
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('postingPeriod')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      selectedEmolumentsPaymentSchedule?.postingPeriodDescription
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('chartOfAccountName')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedEmolumentsPaymentSchedule?.chartOfAccountName
                    }
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize me-3">
                    {localize('priority')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{
                      width: '100%',
                    }}
                    value={selectedEmolumentsPaymentSchedule?.priority}
                    enumsKey={'QueuePriority'}
                    onChange={e => {
                      setSelectedEmolumentsPaymentSchedule({
                        ...selectedEmolumentsPaymentSchedule,
                        priority: e.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-md-1 text-capitalize">
                    {localize('reference')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedEmolumentsPaymentSchedule?.reference}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('createdBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={selectedEmolumentsPaymentSchedule?.createdBy}
                    disabled
                  />
                </div>
                <div className="col-4 d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('createdDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={formatDate(
                      selectedEmolumentsPaymentSchedule?.createdDate
                    )}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('verifiedBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={selectedEmolumentsPaymentSchedule?.auditedBy}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('verifiedDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={formatDate(
                      selectedEmolumentsPaymentSchedule?.auditedDate
                    )}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('verificationRemarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={selectedEmolumentsPaymentSchedule?.auditRemarks}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('authorizedBy')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={selectedEmolumentsPaymentSchedule?.authorizedBy}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('authorizedDate')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={formatDate(
                      selectedEmolumentsPaymentSchedule?.authorizedDate
                    )}
                    disabled
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-3 text-capitalize">
                    {localize('authorizationRemarks')}
                  </label>
                  <input
                    type="text"
                    className="form-control me-3"
                    value={
                      selectedEmolumentsPaymentSchedule?.authorizationRemarks
                    }
                    disabled
                  />
                </div>
              </div>

              <div
                className="p-2 mt-2"
                style={{
                  border: 'solid 1px #0000001f',
                  borderRadius: 10,
                  position: 'relative',
                }}
              >
                <div
                  className="bg-white px-2 row text-capitalize"
                  style={{
                    position: 'relative',
                    top: -20,
                    left: 30,
                    maxWidth: 'fit-content',
                  }}
                >
                  {localize('batchDetails')}
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <CustomerAccountPartial
                      selectedAccount={selectedAccount}
                      onCustomerAccountLookup={r => {
                        if (!selectedEmolumentsPaymentSchedule) {
                          showNotification(
                            'Emolument Payment Processing',
                            'info',
                            'sorry, but you must first select a batch!'
                          )
                        } else {
                          handleOnSelectAccount(r)
                          setEmolumentBatchEntry({
                            customerAccountId: r.id,
                            customerAccountCustomerReference2:
                              r.customerReference2,
                            customerAccountCustomerReference1:
                              r.customerReference1,
                            customerAccountCustomerReference3:
                              r.customerReference3,
                            customerIndividualIdentityCardNumber:
                              r.customerIndividualIdentityCardNumber,
                            customerAccountCustomerAddressMobileLine:
                              r.customerAddressMobileLine,
                            beneficiary: r.customerFullName,
                            reference: r.customerReference1,
                            filterCreteria: Object.keys(
                              customerAutoCompleteFilter
                            )[0],
                          })
                        }
                      }}
                    />
                    <div className="row mt-2">
                      <div className="col-3 d-flex align-items-center">
                        <label className="col-4 text-capitalize">
                          {localize('filterCreteria')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{
                            width: '100%',
                          }}
                          value={emolumentBatchEntry?.filterCreteria}
                          enumsKey={'CustomerAutoCompleteFilter'}
                          onChange={e => {
                            setEmolumentBatchEntry({
                              ...emolumentBatchEntry,
                              filterCreteria: e.value,
                            })
                          }}
                        />
                      </div>
                      {emolumentBatchEntry?.filterCreteria === '0' ? (
                        <div className="col-md-9 d-flex align-items-center">
                          <label className="col-md-1 text-capitalize me-4">
                            {localize('memberNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control ms-4"
                            value={
                              emolumentBatchEntry?.customerAccountCustomerReference2
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {emolumentBatchEntry?.filterCreteria === '1' ? (
                        <div className="col-md-9 d-flex align-items-center">
                          <label className="col-md-1 text-capitalize me-4">
                            {localize('accountNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control ms-4"
                            value={
                              emolumentBatchEntry?.customerAccountCustomerReference1
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {emolumentBatchEntry?.filterCreteria === '2' ? (
                        <div className="col-md-9 d-flex align-items-center">
                          <label className="col-md-1 text-capitalize me-4">
                            {localize('personalFileNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control ms-4"
                            value={
                              emolumentBatchEntry?.customerAccountCustomerReference3
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {emolumentBatchEntry?.filterCreteria === '3' ? (
                        <div className="col-md-9 d-flex align-items-center">
                          <label className="col-md-1 text-capitalize me-4">
                            {localize('identityCardNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control ms-4"
                            value={
                              emolumentBatchEntry?.customerIndividualIdentityCardNumber
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {emolumentBatchEntry?.filterCreteria === '4' ? (
                        <div className="col-md-9 d-flex align-items-center">
                          <label className="col-md-1 text-capitalize me-4">
                            {localize('mobileLineNumber')}
                          </label>
                          <input
                            type="text"
                            className="form-control ms-4"
                            value={
                              emolumentBatchEntry?.customerAccountCustomerAddressMobileLine
                            }
                            disabled
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3 d-flex align-items-center">
                        <label className="col-3 text-capitalize me-3">
                          {localize('beneficiary')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-4"
                          value={emolumentBatchEntry?.beneficiary}
                          disabled
                        />
                      </div>
                      <div className="col-md-9 d-flex align-items-center">
                        <label className="col-1 text-capitalize me-4">
                          {localize('reference')}
                        </label>
                        <input
                          type="text"
                          className="form-control ms-4"
                          value={emolumentBatchEntry?.reference}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
                      <button
                        onClick={() => {
                          if (!selectedEmolumentsPaymentSchedule) {
                            showNotification(
                              'Emolument Batch',
                              'info',
                              'sorry, but you must first select a batch!'
                            )
                          } else if (!emolumentBatchEntry) {
                            showNotification(
                              'Emolument Batch',
                              'info',
                              'sorry, but you must first select a customer account!'
                            )
                          } else {
                            updateEmonumentBatchEntries()
                          }
                        }}
                        className="btn btn-success px-5"
                      >
                        {localize('add')}
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="row col-12 mt-2">
                {entriesLoader ? (
                  <Loader />
                ) : (
                  <>
                    <Tab
                      tabItems={[
                        {
                          label: localize('batchEntries'),
                          item: (
                            <EmolumentBatchEntries
                              selectedEmolumentsPaymentSchedule={
                                selectedEmolumentsPaymentSchedule
                              }
                              loadEntries={loadEntries}
                              entries={entries}
                              reqParams={reqParams}
                              setReqParams={setReqParams}
                              search={search}
                              setSearch={setSearch}
                              onSelectItem={onSelectItem}
                            />
                          ),
                        },
                      ]}
                    />
                  </>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmolumentsAndAllowancesPaymentProcessing
