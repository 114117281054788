/** @format */
import React, { useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../Components/Loaders/Loader'
import FixedDepositLookup from '../../../../Components/Lookup/FixedDepositLookup'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import Tab from '../../../../Components/Tabs/Tab'
import localize from '../../../../Global/Localize'
import { fixedDepositAuthOption } from '../../../../Global/enumeration'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { formatAsPercent, formatDate } from '../../../../Helpers/formatters'
import { getData, postData } from '../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'

const FixedDepositsVerification = () => {
  const columns = [
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },

    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  const [isBusy, setIsBusy] = useState(false)
  const [tabWaiting, setTabWaiting] = useState(false)
  const [iFixedDepositVerification, setIFixedDepositVerification] =
    useState(null)

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [selectedAuth, setSelectedAuth] = useState(
    Object.keys(fixedDepositAuthOption)[0]
  )
  const [tableData, setTableData] = useState([])

  const loadPayablesTableData = async r => {
    setTabWaiting(true)
    const { success, data } = await getData(
      `/FixedDeposit/find-fixed-deposit-payables-by-fixed-deposit-id?fixedDepositId=${r.id}`,
      {},
      false
    )
    if (success) {
      setTableData(data.result || [])
    }
    setTabWaiting(false)
  }

  async function updateFixedDepositVerification() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/FixedDeposit/audit-fixed-deposit',
      {
        fixedDeposit: {
          ...iFixedDepositVerification,
        },
        fixedDepositAuthOption: selectedAuth,
        moduleNavigationItemCode: 1000,
      },
      false
    )

    if (success) {
      setIFixedDepositVerification(null)
      setGlobalValidationErrors({})
      showNotification('Fixed Deposit Verification', 'success')
    }
    setIsBusy(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('verifyFixedDeposit'),
        ]}
      />
      <div className="col-12">
        <div className="card bg-white">
          <div className="card-body">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row mb-2">
                  <div
                    className="col d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['FixedDeposit.Id']?.message
                    }
                  >
                    <label className="col-3 text-capitalize">
                      {localize('fixedDepositType')}
                    </label>
                    <FixedDepositLookup
                      displayValue={
                        iFixedDepositVerification?.fixedDepositTypeDescription
                      }
                      onSelect={r => {
                        clearValidation('FixedDeposit.Id')
                        setIFixedDepositVerification({
                          ...iFixedDepositVerification,
                          id: r.id,
                          fixedDepositTypeDescription:
                            r.fixedDepositTypeDescription,
                          paddedSerialNumber: r.paddedSerialNumber,
                          customerAccountFullAccountNumber:
                            r.customerAccountFullAccountNumber,
                          customerAccountCustomerFullName:
                            r.customerAccountCustomerFullName,
                          customerAccountCustomerMembershipClassCustomerTypeDescription:
                            r.customerAccountCustomerMembershipClassCustomerTypeDescription,
                          customerAccountCustomerIndividualIdentityCardNumber:
                            r.customerAccountCustomerIndividualIdentityCardNumber,
                          customerAccountCustomerReference1:
                            r.customerAccountCustomerReference1,
                          customerAccountCustomerReference2:
                            r.customerAccountCustomerReference2,
                          customerAccountCustomerReference2:
                            r.customerAccountCustomerReference2,
                          categoryDescription: r.categoryDescription,
                          value: r.value,
                          rate: r.rate,
                          term: r.term,
                          maturityActionDescription:
                            r.maturityActionDescription,
                          remarks: r.remarks,
                          createdBy: r.createdBy,
                          createdDate: r.createdDate,
                          isLocked: r.isLocked,
                        })
                        loadPayablesTableData(r)
                      }}
                      status={8}
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('fixedDepositNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositVerification?.paddedSerialNumber || ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('fullAccountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositVerification?.customerAccountFullAccountNumber ||
                        ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('customerName')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        iFixedDepositVerification?.customerAccountCustomerFullName ||
                        ''
                      }
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('customerType')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositVerification?.customerAccountCustomerMembershipClassCustomerTypeDescription ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('identityCardNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositVerification?.customerAccountCustomerIndividualIdentityCardNumber ||
                        ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('accountNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        iFixedDepositVerification?.customerAccountCustomerReference1 ||
                        ''
                      }
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('membershipNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositVerification?.customerAccountCustomerReference2 ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('personalFileNumber')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositVerification?.customerAccountCustomerReference3 ||
                        ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('category')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        iFixedDepositVerification?.categoryDescription || ''
                      }
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('value')}
                    </label>
                    <MaskNumber
                      className="form-control me-3"
                      defaultMaskValue={iFixedDepositVerification?.value || 0}
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('annualPercentageRate')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        formatAsPercent(iFixedDepositVerification?.rate) || ''
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('term(month)')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={iFixedDepositVerification?.term || ''}
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('maturityAction')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        iFixedDepositVerification?.maturityActionDescription ||
                        ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={iFixedDepositVerification?.remarks || ''}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('createdBy')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={iFixedDepositVerification?.createdBy || ''}
                      disabled
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <label className="col-3 text-capitalize">
                      {localize('createdDate')}
                    </label>
                    <input
                      type="text"
                      className="form-control me-3"
                      value={
                        formatDate(iFixedDepositVerification?.createdDate) || ''
                      }
                      disabled
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <Checkbox
                      id="fixedDepositIsLocked"
                      disabled
                      checked={iFixedDepositVerification?.isLocked}
                    />

                    <label
                      htmlFor="fixedDepositIsLocked"
                      className="text-capitalize col-3"
                    >
                      {localize('isLocked')}
                    </label>
                  </div>
                </div>
                <div className="mt-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('fixedDepositPayables'),
                        item: (
                          <>
                            {tabWaiting ? (
                              <Loader />
                            ) : (
                              <div className="row col-12 mt-2">
                                <SimpleTable
                                  data={tableData}
                                  columns={columns}
                                />
                              </div>
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="row mb-2">
                  <div
                    className="bg-light d-flex justify-content-end align-items-center p-2"
                    style={{ borderRadius: 5 }}
                  >
                    <div
                      className="d-flex align-items-center mb-1 with-validation px-4 mt-3"
                      validation-message={
                        globalValidationErrors['FixedDeposit.AuditRemarks']
                          ?.message
                      }
                    >
                      <label className="text-capitalize col-3 me-2">
                        {localize('remarks')}
                      </label>
                      <input
                        defaultValue={iFixedDepositVerification?.auditRemarks}
                        type="text"
                        className="form-control ms-4"
                        onChange={e => {
                          clearValidation('FixedDeposit.AuditRemarks')
                          setIFixedDepositVerification({
                            ...iFixedDepositVerification,
                            auditRemarks: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-2 px-4 mt-3">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="FixedDepositAuthOption"
                        value={selectedAuth}
                        onChange={e => setSelectedAuth(e.value)}
                      />
                    </div>
                    <button
                      onClick={() => {
                        updateFixedDepositVerification()
                      }}
                      className="btn btn-success text-uppercase ms-3 px-4 mt-2"
                    >
                      {localize('update')}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default FixedDepositsVerification
