/** @format */

/* eslint-disable */
import React from 'react'
import '../selectables.scss'
import Checkbox from '../../Checkbox/Checkbox'
import { PleaseWait } from '../../Loaders/PleaseWait'

function LoanProducts({
  disabled,
  isBusy,
  loanProductsToBeSelected,
  onCheckLoanProduct,
  prefix,
}) {
  const handleCheckLoanProducts = (loanProduct, checkValue) => {
    const com = [...loanProductsToBeSelected].map(comm => {
      if (comm.id === loanProduct.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })
    onCheckLoanProduct(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {loanProductsToBeSelected?.map(loanProduct => (
            <li
              key={loanProduct.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${loanProduct.id}`}
                checked={loanProduct.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckLoanProducts(loanProduct, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${loanProduct.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  loanProduct.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {loanProduct.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}

export default LoanProducts
