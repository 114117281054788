/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import LoanAgingSpoolEntries from './LoanAgingSpoolEntries'
import { formatDate } from '../../../../../Helpers/formatters'
import { postData } from '../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
function ViewLoanAgingSpoolModal({ mode, selectedLoanAgingSpool, closeModal }) {
  const [entriesTotal, setEntriesTotal] = useState(null)
  const [displayedEntries, setDisplayedEntries] = useState(null)
  const [reload, setReload] = useState(false)

  async function resetLoanSpoolEntries() {
    const { success } = await postData(
      `/LoanAgingSpool/un-post-loan-aging-spool-entries?LoanAgingSpoolId=${selectedLoanAgingSpool.id}`
    )
    if (success) {
      Swal.fire('Success', 'Operation Completed Successfully', 'success')
      setReload(true)
    }
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('loanAgingSpools')}
        handleClose={closeModal}
        modalClassName="modal-xl"
        actionText="reset"
        handleAction={resetLoanSpoolEntries}
        footerChildren={
          <>
            <div className="col-3 d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('entries')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={displayedEntries + '/' + entriesTotal || ''}
              />
            </div>
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('batchNumber')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedLoanAgingSpool?.paddedBatchNumber || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('reference')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedLoanAgingSpool?.reference || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('dateFilter')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedLoanAgingSpool?.dateFilterDescription || ''}
              disabled
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('cut-offDate')}
            </label>
            <input
              className="form-control ms-2"
              value={formatDate(selectedLoanAgingSpool?.cutOffDate) || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedLoanAgingSpool?.createdBy || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              className="form-control ms-2"
              value={formatDate(selectedLoanAgingSpool?.createdDate) || ''}
              disabled
            />
          </div>
        </div>

        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <LoanAgingSpoolEntries
                    selectedLoanAgingSpool={selectedLoanAgingSpool}
                    setEntriesTotal={setEntriesTotal}
                    setDisplayedEntries={setDisplayedEntries}
                    reload={reload}
                    setReload={setReload}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewLoanAgingSpoolModal
