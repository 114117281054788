/** @format */

import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import rightAlignFragment, { formatDate } from '../../../../Helpers/formatters'
import Pager from '../../../../Components/Tables/Pager'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Loader from '../../../../Components/Loaders/Loader'
import { showNotification } from '../../../../Helpers/Notification/ShowNotification'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import CreateInventoryUnitsOfMeasure from './Partials/CreateInventoryUnitsOfMeasure'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import ViewAuditLogs from '../../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'

const InventoryUnitsOfMeasure = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [loading, setLoading] = useState(false)
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([])
  const [units, setUnits] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    getInventoryUnitsOfMeasureTableData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refresh])

  const getInventoryUnitsOfMeasureTableData = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/InventoryUnitOfMeasure/find-inventory-unit-of-measure-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setUnitsOfMeasure(data)
    }

    setIsBusy(false)
  }

  const columns = [
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
    },
    {
      headerTextAlign: 'right',
      label: localize('contains'),
      template: r => <>{rightAlignFragment(r?.contains)}</>,
    },
    {
      label: localize('ofBaseUnits'),
      template: r => <>{r?.baseUnits}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const handleCreateOrUpdate = async () => {
    const url =
      modalMode === 'add'
        ? '/InventoryUnitOfMeasure/add-inventory-unit-of-measure'
        : '/InventoryUnitOfMeasure/update-inventory-unit-of-measure'

    if (modalMode === 'add') {
      if (!units.description) {
        GlobalValidationErrors.set({
          description: !units?.description && {
            message: 'The Name field is required.',
          },
        })
        return
      }

      setRefresh(false)
      setLoading(true)
      const { success } = await postData(
        url,
        units,
        false,
        'Operation successful'
      )

      if (success) {
        handleClose()
        setRefresh(true)
        setLoading(false)
        showNotification('Inventory Unit of Measure', 'success')
      }

      setLoading(false)
    } else if (modalMode === 'edit') {
      setRefresh(false)
      setLoading(true)
      const { success } = await putData(
        url,
        units,
        false,
        'Operation successful'
      )
      if (success) {
        setLoading(false)
        handleClose()
        showNotification('Inventory Unit of Measure', 'success')
        setRefresh(true)
      }

      setLoading(false)
    }
  }

  const handleClose = () => {
    setModalMode(null)
  }

  const handleViewAuditLogs = item => {
    setUnits(item)
    setModalMode('auditLogs')
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('inventoryUnitsOfMeasure'),
        ]}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreateInventoryUnitsOfMeasure
          handleAction={handleCreateOrUpdate}
          handleClose={handleClose}
          mode={modalMode}
          units={units}
          setUnits={setUnits}
          refresh={refresh}
          setRefresh={setRefresh}
          isLoading={loading}
          globalValidationErrors={globalValidationErrors}
          clearValidation={clearValidation}
        />
      )}
      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={modalMode}
          data={units}
          title={units?.description}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-start mb-4">
              <AddNewButton
                handleClickAddButton={() => {
                  setModalMode('add')
                  setUnits({})
                  clearValidation('description')
                }}
              />
            </div>
          </div>
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-3">
                <RecordsPerPage
                  className="col"
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
                <div className="col  d-flex align-items-center justify-content-end gap-2">
                  <div className="d-flex align-items-center ">
                    <label className=" text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                  </div>
                  <div className="d-flex align-items-center ">
                    <form
                      className="d-flex align-items-center"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                          pageIndex: 0,
                        })
                      }}
                    >
                      <input
                        type="search"
                        className="form-control ms-2"
                        defaultValue={reqParams?.filterText}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                    <button
                      type="button"
                      className="btn btn-primary ms-1"
                      onClick={getInventoryUnitsOfMeasureTableData}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <SimpleTable
                  columns={columns}
                  data={unitsOfMeasure?.pageCollection}
                  contextMenu={x => (
                    <>
                      <div
                        className="ctxt-menu-item"
                        onClick={() => {
                          setModalMode('edit')
                          setUnits(x)
                          clearValidation('description')
                        }}
                      >
                        <i className="uil uil-edit-alt"></i>
                        {localize('edit')}
                      </div>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewAuditLogs(x)
                        }}
                      >
                        <i className="uil uil-server"></i>
                        <span>{localize('viewAuditLog')}</span>
                      </div>
                    </>
                  )}
                />
              </div>
              <Pager
                itemsCount={unitsOfMeasure?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InventoryUnitsOfMeasure
