/** @format */

/* eslint-disable */
import React from 'react'

function FIleSelectLookup({ id, displayValue, onSelect, accept, multiple }) {
  return (
    <>
      <label
        htmlFor={id}
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            // maxWidth: '61%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
          title={displayValue}
        >
          {displayValue}
        </div>
        <label
          htmlFor={id}
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
        >
          <i className="uil uil-file-check-alt"></i>
        </label>
        <input
          type="file"
          onChange={onSelect}
          accept={accept}
          multiple={multiple}
          id={id}
          style={{ display: 'none' }}
        />
      </label>
    </>
  )
}

export default FIleSelectLookup
