/** @format */

import React from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import GuarantorAttachmentHistories from '../../GuarantorAttachmentHistories/GuarantorAttachmentHistories'

const ViewGuarantors = ({ mode, handleClose, selectedHistory }) => {
  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="guarantor"
      handleClose={handleClose}
      hideUpdate
      modalClassName="modal-xl"
    >
      <GuarantorAttachmentHistories
        loanGuarantorAttachmentHistoryId={selectedHistory?.id}
      />
    </ModalTemplate>
  )
}

export default ViewGuarantors
