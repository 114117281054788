/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import { corporationMemberType } from '../../../../../../Global/enumeration'
import CustomersLookup from '../../../../../../Components/Lookup/CustomersLookup'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { getRandomNumber } from '../../../../../../Helpers/misc'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

function MembershipDetailsCorporation({ members, onChange }) {
  const [selected, setSelected] = useState(null)
  const [collection, setCollection] = useState(members)

  useEffect(() => {
    onChange(collection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection])

  function addMember() {
    let c = [...collection]
    c.push({ ...selected, id: getRandomNumber(collection.map(c => c.id)) })
    setSelected(null)
    setCollection(c)
  }

  function removeMember(m) {
    setCollection([...collection].filter(c => c.id !== m.id))
  }

  return (
    <div
      className="p-2 mt-4"
      style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
    >
      <div
        className="bg-white row px-2 text-capitalize"
        style={{
          position: 'relative',
          top: -20,
          left: 30,
          maxWidth: 'fit-content',
        }}
      >
        {localize('memberDetails')}
      </div>
      <div className="d-flex align-items-center">
        <label htmlFor="" className="col-1 text-capitalize">
          {localize('memberType')}
        </label>
        <EnumsServerCombo
          customContainerStyle={{ width: '100%' }}
          enumsKey="CorporationMemberType"
          value={selected?.memberType}
          onChange={e =>
            setSelected({
              ...selected,
              memberType: e.value,
              memberTypeDescription: corporationMemberType[e.value].name,
            })
          }
        />
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerName')}
          </label>
          <CustomersLookup
            onSelect={c =>
              setSelected({
                ...c,
                corporationId: c.id,
                memberType: 0,
                memberTypeDescription: 'Internal',
                signatory: false,
              })
            }
            displayValue={selected?.fullName}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('customerType')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.membershipClassCustomerTypeDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('membershipStatus')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.membershipStatusDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('membershipWdr.Date')}
          </label>
          <input type="date" className="form-control" disabled />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('domicileBranch')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.branchDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('employerName')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.employerDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('dutyWorkStation')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.dutyStationDescription || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('adminDivision')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.administrativeDivisionDescription || ''}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identificationNumber')} #
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.individualIdentityCardNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('memberNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.memberNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('accountNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.accountNumber || ''}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('personalFileNumber')}
          </label>
          <input
            type="text"
            className="form-control"
            disabled
            value={selected?.personalFileNumber || ''}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end bg-light p-2">
        <div
          className="d-flex align-items-center"
          style={
            selected
              ? { pointerEvents: 'all', opacity: 1 }
              : { pointerEvents: 'none', opacity: 0.4 }
          }
        >
          <Checkbox
            id="corpPartSignatory"
            checked={selected?.signatory}
            onCheck={v => setSelected({ ...selected, signatory: v })}
          />
          <label
            htmlFor="corpPartSignatory"
            className="text-capitalize ms-2 me-4"
          >
            {localize('signatory')}?
          </label>
          <button onClick={addMember} className="btn btn-success px-4">
            {localize('add')}
          </button>{' '}
        </div>
      </div>
      <div
        className="p-2 mt-4"
        style={{ border: 'solid 1px #0000001f', borderRadius: 5 }}
      >
        <div
          className="bg-white row px-2 text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('memberCollection')}
        </div>
        <SimpleTable
          data={collection}
          columns={[
            {
              label: 'memberType',
              template: r => <>{r?.memberTypeDescription}</>,
            },
            { label: 'name', template: r => <>{r?.fullName}</> },
            { label: 'family', template: r => <>{r?.family}</> },
            { label: 'ethnicGroup', template: r => <>{r?.ethnicGroup}</> },
            {
              label: 'gender',
              template: r => <>{r?.customerIndividualGenderDescription}</>,
            },

            {
              label: 'identityCardType',
              template: r => <>{r?.individualIdentityCardTypeDescription}</>,
            },
            {
              label: localize('identityCard') + ' #',
              template: r => <>{r?.individualIdentityCardNumber}</>,
            },
            {
              label: localize('identityCardSerial') + ' #',
              template: r => <>{r?.individualIdentityCardSerialNumber}</>,
            },
            {
              label: localize('addressLine') + ' 1',
              template: r => <>{r?.addressAdressLine1}</>,
            },
            {
              label: localize('addressLine') + ' 2',
              template: r => <>{r?.addressAdressLine2}</>,
            },
            {
              label: 'physicalAddress',
              template: r => <>{r?.addressStreet}</>,
            },
            { label: 'postalCode', template: r => <>{r?.addressPostalCode}</> },
            { label: 'city', template: r => <>{r?.addressCity}</> },
            { label: 'emailAddress', template: r => <>{r?.addressEmail}</> },
            { label: 'mobileLine', template: r => <>{r?.addressMobileLine}</> },
            {
              label: localize('signatory') + '?',
              template: r => (
                <span className="text-capitalize">
                  {r?.signatory.toString()}
                </span>
              ),
            },
            { label: 'remarks', template: r => <>{r?.remarks}</> },
          ]}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-danger"
                onClick={() => removeMember(r)}
              >
                <i className="uil uil-times-circle"></i>
                <span className="text-capitalize">{localize('remove')}</span>
              </div>
            </>
          )}
        />
      </div>
    </div>
  )
}

export default MembershipDetailsCorporation
