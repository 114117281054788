/** @format */

import React, { useState, useEffect } from 'react'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import EditSalaryCardEntry from './EditSalaryCardEntry'
import localize from '../../../../../../Global/Localize'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

const SalaryCardEntries = ({ selectedSalaryCard, mode }) => {
  const columns = [
    {
      label: localize('salaryHead'),
      template: r => <>{r.salaryGroupEntrySalaryHeadDescription}</>,
    },
    {
      label: localize('groupValueType'),
      template: r => <>{r.salaryGroupEntryChargeTypeDescription}</>,
    },
    {
      label: localize('groupPercentageValue'),
      template: r => <>{formatAsPercent(r.salaryGroupEntryChargePercentage)}</>,
    },
    {
      label: localize('groupFixedValue'),
      template: r => <>{formatCurrency(r.salaryGroupEntryChargeFixedAmount)}</>,
    },
    {
      label: localize('cardValueType'),
      template: r => <>{r.chargeTypeDescription}</>,
    },
    {
      label: localize('cardPercentageValue'),
      template: r => <>{formatAsPercent(r.chargePercentage)}</>,
    },
    {
      label: localize('cardFixedValue'),
      template: r => <>{formatCurrency(r.chargeFixedAmount)}</>,
    },
    {
      label: localize('roundingType'),
      template: r => <>{r.salaryGroupEntryRoundingTypeDescription}</>,
    },
    {
      label: localize('groupMinimumValue'),
      template: r => <>{formatCurrency(r.salaryGroupEntryMinimumValue)}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const [entryMode, setEntryMode] = useState(null)
  const [entriesLoading, setEntriesLoading] = useState(false)
  const [entries, setEntries] = useState([])
  const [selectedEntry, setSelectedEntry] = useState(null)

  const resetEntries = async () => {
    setEntriesLoading(true)
    const { success } = await putData(
      '/SalaryCard/reset-card-entries',
      selectedSalaryCard,
      false
    )

    if (success) {
      showNotification('Reset Salary Card', 'success')
      setEntriesLoading(false)
      loadSalaryHeads()
    }
    setEntriesLoading(false)
  }

  const closeModal = () => {
    setEntryMode(null)
  }

  const loadSalaryHeads = async () => {
    setEntriesLoading(true)
    const { success, data } = await getData(
      `/SalaryCard/find-salary-card-entry-by-salary-card-id?salaryCardId=${selectedSalaryCard?.id}`,
      {},
      false
    )
    if (success) {
      setEntriesLoading(false)
      setEntries(data.result)
    } else {
      setEntriesLoading(false)
      setEntries([])
    }
  }

  useEffect(() => {
    loadSalaryHeads()
    // eslint-disable-next-line
  }, [selectedSalaryCard?.id])

  return (
    <>
      {entryMode === 'edit' ? (
        <ModalTemplate
          modalMode={mode}
          modalTitle="salaryHeads"
          modalClassName="modal-xl"
          hideCancel
          hideUpdate
        >
          <EditSalaryCardEntry
            selectedEntry={selectedEntry}
            setSelectedEntry={setSelectedEntry}
            closeModal={closeModal}
            mode={entryMode}
            loadSalaryHeads={loadSalaryHeads}
          />
        </ModalTemplate>
      ) : (
        ''
      )}
      {entriesLoading ? (
        <Loader />
      ) : (
        <>
          <SimpleTable
            columns={columns}
            data={entries}
            contextMenu={r => (
              <>
                <div
                  onClick={async () => {
                    setEntryMode('edit')
                    setSelectedEntry(r)
                  }}
                  className="ctxt-menu-item"
                >
                  <i className="uil uil-edit-alt"></i>
                  {localize('edit')}
                </div>
              </>
            )}
          />

          <>
            <div className="row mb-3 g-3 mt-2">
              <div className="col-md-12">
                <div className="d-flex justify-content-end align-items-end">
                  <button
                    onClick={() => {
                      resetEntries()
                    }}
                    className="btn btn-success text-uppercase ms-3 px-4"
                  >
                    {localize('reset')}
                  </button>
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </>
  )
}

export default SalaryCardEntries
