/** @format */

import React, { useContext, useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import './App.scss'

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js'

import { UserContext } from './Contexts/UserContext'
import { MemoContextProvider } from './Contexts/MemoContext'
import { LanugageContextProvider } from './Contexts/LanguageContext'
import { NavigationContextProvider } from './Contexts/NavigationContext'
import Header from './Components/Header/Index'
import LeftSidebar from './Components/LeftSidebar/Index'
import PageContent from './Components/PageContent'
import { getData, postData } from './Helpers/webApi'
import { ViewQRCode } from './Components/ReUsables/Auth/ViewQRCode'
import { GlobalValidationErrors } from './Validation/ValidationState'

import Loader from './Components/Loaders/Loader'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement
)

function App() {
  const { locked, loggedInUser, handleLock, authenticated, UnLock } =
    useContext(UserContext)
  const [isIdle, setIsIdle] = useState(false)
  const [remaining, setRemaining] = useState(0)
  const [menuCollapsed, setMenuCollapsed] = useState(false)
  const [otpCode, setOtpCode] = useState(null)
  const [showQrCodeImage, setShowQrCodeImage] = useState(false)
  const [qrCodeBase64, setQrCodeBase64] = useState(null)
  const [invalidOtpMessage, setInvalidOtpMessage] = GlobalValidationErrors.use()
  const [isBusy, setIsBusy] = useState(false)
  const clearValidation = column => {
    setInvalidOtpMessage(g => {
      delete g[column]
      return g
    })
  }

  const idleTime =36000000 // mils => 10 hrs

  const { getRemainingTime } = useIdleTimer({
    onIdle: () => setIsIdle(true),
    timeout: idleTime,
    throttle: 1000,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  })

  useEffect(() => {
    if (isIdle) {
      handleLock()
      setIsIdle(false)
    }
    // eslint-disable-next-line
  }, [isIdle])

  const generateOtp = async () => {
    setIsBusy(true)
    const { success, data } = await getData('/AccessControl/generate-qr-code')
    if (success) {
      setShowQrCodeImage(true)
      setQrCodeBase64(data)
    }
    setIsBusy(false)
  }

  const confirmOtp = async () => {
    setIsBusy(true)
    const { success, data } = await postData('/AccessControl/confirm-otp', {
      totp: otpCode,
    })
    if (success) {
      if (data.result) {
        setOtpCode(null)
        setQrCodeBase64(null)
        UnLock()
      }
    }
    setIsBusy(false)
  }

  return (
    <MemoContextProvider>
      <LanugageContextProvider>
        <NavigationContextProvider>
          <div className="layout-wrapper">
            <Header menuCollapsed={menuCollapsed} />
            <LeftSidebar onCollapsed={x => setMenuCollapsed(x)} />
            <div
              className="main-content"
              style={{ marginLeft: menuCollapsed ? 70 : 250 }}
            >
              <PageContent />
            </div>
          </div>
          {remaining > 0 && remaining <= 30 && authenticated && !locked && (
            <div
              style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                // background: 'rgba(255,255,255,0.5)',
                top: 0,
                left: 0,
                zIndex: 9999999999,
                // backdropFilter: 'blur(3px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                className="p-5 bg-white d-flex align-items-center gap-4 shadow-lg"
                style={{
                  borderRadius: 10,
                  // boxShadow: 'rgba(0,0,0,0.2) 0 0 20px 20px ',
                }}
              >
                <i
                  className="uil uil-clock-five text-primary"
                  style={{ fontSize: 100 }}
                ></i>
                <div className="d-flex flex-column">
                  <h2 className="text-primary">Application Idle</h2>
                  <p>
                    No Activity has been detected on the app.
                    <br /> The app will lock you out in <br />
                    <div
                      style={{ textAlign: 'center' }}
                      className="text-danger fw-bold fs-1 my -2"
                    >
                      {remaining}s
                    </div>{' '}
                  </p>
                  {locked && <p>To continue with session click below </p>}
                  <button className="btn btn-outline-primary">
                    <i className="uil uil-check me-2"></i>
                    Continue Session
                  </button>
                </div>
              </div>
            </div>
          )}
          {showQrCodeImage && (
            <ViewQRCode
              show={showQrCodeImage}
              setShow={setShowQrCodeImage}
              base64={qrCodeBase64}
            />
          )}
          {locked && (
            <div
              style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                background: 'rgba(255,255,255,0.5)',
                top: 0,
                left: 0,
                zIndex: 99999,
                backdropFilter: 'blur(3px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                className="p-4 bg-white d-flex align-items-center gap-3 shadow-lg"
                style={{
                  borderRadius: 10,
                  //   boxShadow: 'rgba(0,0,0,0.1) 0 0 20px 20px ',
                }}
              >
                <i
                  className="uil uil-lock-alt text-danger"
                  style={{ fontSize: 100 }}
                ></i>
                {isBusy ? (
                  <Loader />
                ) : (
                  <div className="d-flex flex-column">
                    <h2 className="text-danger fs-3">Application Locked!</h2>

                    <p className="fs-6">
                      To continue with this session, please use the code sent{' '}
                      <br /> to your email
                    </p>
                    <p>
                      Current User:{' '}
                      <strong className="text-capitalize">
                        {loggedInUser.name}
                      </strong>
                    </p>
                    <div className="d-flex align-items-center gap-1 with-validation">
                      <input
                        type="text"
                        className="form-control"
                        value={otpCode}
                        onChange={e => {
                          clearValidation('otp')
                          setOtpCode(e.target.value)
                        }}
                      />
                      {invalidOtpMessage?.otp?.message && (
                        <span className="invalid-feedback d-block">
                          {invalidOtpMessage?.otp?.message}
                        </span>
                      )}

                      <button
                        onClick={confirmOtp}
                        className="btn btn-success px-3"
                        style={{ minWidth: 'fit-content' }}
                      >
                        <i className="uil uil-lock-alt me-1"></i>
                        <span className="text-capitalize">unlock</span>
                      </button>
                    </div>
                    <div className="mt-2">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a className="text-primary me-2 cursor-pointer">
                        Get Code
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={generateOtp}
                        className="text-primary cursor-pointer"
                      >
                        Setup OTP
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </NavigationContextProvider>
      </LanugageContextProvider>
    </MemoContextProvider>
  )
}

export default App
