/** @format */
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { PleaseWait } from '../../../../../Components/Loaders/PleaseWait'
/* eslint-disable */
import React from 'react'
import NonBlockingLoader from '../../../../../Components/Loaders/NonBlockingLoader'

export const AuthorizedRoles = ({
  rolesToBeSelected,
  isBusy,
  onCheckRole,
  prefix,
  disabled,
  onSingleCheck,
}) => {
  const handleCheckRoles = (role, checkValue) => {
    const com = [...rolesToBeSelected].map(comm => {
      if (comm.id === role.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })
    onCheckRole(com)
  }

  return (
    <div className="px-4 relative">
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {rolesToBeSelected.map(role => (
          <li
            key={role.id}
            style={{ listStyle: 'none' }}
            className=" d-flex align-items-center"
          >
            <Checkbox
              id={`${prefix ? prefix : 'com'}-${role.id}`}
              checked={role.checked}
              disabled={disabled}
              margined
              onCheck={c => {
                handleCheckRoles(role, c)
                onSingleCheck({ selectedRole: role, checkValue: c })
              }}
            />
            <label
              htmlFor={`${prefix ? prefix : 'com'}-${role.id}`}
              className={`fw-normal ms-2 cursor-pointer ${
                role.checked ? 'text-primary fw-bold' : ''
              }`}
            >
              {role.description}
            </label>
          </li>
        ))}
      </div>
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}
