/** @format */

import React, { useEffect, useState } from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../Components/Loaders/Loader'
import EmolumentTypeLookup from '../../../../../Components/Lookup/EmolumentTypeLookup'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { getData, postData } from '../../../../../Helpers/webApi'
import { QueuePriority } from '../../../../../Global/enumeration'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import localize from '../../../../../Global/Localize'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import PostingPeriodLookup from '../../../../../Components/Lookup/PostingPeriodLookup'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'

const AddEmolumentsPaymentSchedules = ({
  mode,
  close,
  loadTableData,
  setMode,
}) => {
  useEffect(() => {
    loadCurrentPostingPeriodData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [loading, setLoading] = useState(false)
  const [
    selectedEmolumentsPaymentSchedules,
    setSelectedEmolumentsPaymentSchedules,
  ] = useState({
    priority: Object.keys(QueuePriority)[3],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  async function loadCurrentPostingPeriodData() {
    const { success, data } = await getData(
      '/PostingPeriod/find-current-posting-period',
      {},
      false
    )
    if (success) {
      setSelectedEmolumentsPaymentSchedules({
        postingPeriodDescription: data.description,
        postingPeriodId: data.id,
      })
    }
  }

  async function handleSubmit() {
    setLoading(true)
    const { success } = await postData(
      '/EmolumentBatch/add-emolument-batch',
      selectedEmolumentsPaymentSchedules,
      false
    )

    if (success) {
      showNotification(
        'Emolument Batch',
        'success',
        'Operation completed successfully!'
      )
      setLoading(false)
      setMode(null)
      setSelectedEmolumentsPaymentSchedules(null)
      setGlobalValidationErrors({})
      loadTableData()
    }
    setLoading(false)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  function handleCloseModal() {
    close()
    setGlobalValidationErrors({})
  }

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle="emolumentPaymentSchedules"
        handleClose={handleCloseModal}
        handleAction={handleSubmit}
        modalClassName="modal-xl"
        actionText={mode === 'add' ? 'create' : ''}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-2">
              <div
                className="col d-flex align-items-center mb-2 with-validation"
                validation-message={
                  globalValidationErrors['EmolumentTypeId']?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('emolumentType')}
                </label>
                <EmolumentTypeLookup
                  displayValue={
                    selectedEmolumentsPaymentSchedules?.emolumentTypeDescription
                  }
                  onSelect={r => {
                    clearValidation('EmolumentTypeId')

                    setSelectedEmolumentsPaymentSchedules({
                      ...selectedEmolumentsPaymentSchedules,
                      emolumentTypeId: r.id,
                      emolumentTypeDescription: r.description,
                      emolumentTypeAmount: r.amount,
                      chartOfAccountName: r.chartOfAccountName,
                      chartOfAccountId: r.chartOfAccountId,
                    })
                  }}
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-2 text-capitalize">
                  {localize('amount')}
                </label>
                <MaskNumber
                  className="form-control me-3"
                  defaultMaskValue={
                    selectedEmolumentsPaymentSchedules?.emolumentTypeAmount
                  }
                  disabled
                />
              </div>
              <div className="col d-flex align-items-center mb-2">
                <label className="col-2 text-capitalize">
                  {localize('priority')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    width: '100%',
                  }}
                  value={
                    selectedEmolumentsPaymentSchedules?.priorityDescription
                  }
                  enumsKey={'QueuePriority'}
                  onChange={e => {
                    setSelectedEmolumentsPaymentSchedules({
                      ...selectedEmolumentsPaymentSchedules,
                      priorityDescription: e.value,
                    })
                  }}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-4 d-flex align-items-center mb-2">
                <label className="col-3 text-capitalize">
                  {localize('postingPeriod')}
                </label>
                <PostingPeriodLookup
                  displayValue={
                    selectedEmolumentsPaymentSchedules?.postingPeriodDescription
                  }
                  onSelect={r => {
                    if (r.isLocked) {
                      showNotification(
                        'Emolument Batch',
                        'info',
                        'Sorry, but the selected posting period is locked!'
                      )
                    } else if (!r.isActive) {
                      showNotification(
                        'Emolument Batch',
                        'info',
                        'Sorry, but the selected posting period is not active!'
                      )
                    } else {
                      clearValidation('EmolumentTypeId')
                      setSelectedEmolumentsPaymentSchedules({
                        ...selectedEmolumentsPaymentSchedules,
                        postingPeriodId: r.id,
                        postingPeriodDescription: r.description,
                      })
                    }
                  }}
                />
              </div>
              <div
                className="col d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['Reference']?.message
                }
              >
                <label className="col-1 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEmolumentsPaymentSchedules?.reference || ''}
                  onChange={e => {
                    clearValidation('Reference')
                    setSelectedEmolumentsPaymentSchedules({
                      ...selectedEmolumentsPaymentSchedules,
                      reference: e.target.value,
                    })
                  }}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div
                className="col-4 d-flex align-items-center mb-2 with-validation"
                validation-message={
                  globalValidationErrors['ChartOfAccountId']?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('chartOfAccountName')}
                </label>
                <ChartOfAccountsLookup
                  displayValue={
                    selectedEmolumentsPaymentSchedules?.chartOfAccountName
                  }
                  onSelect={r => {
                    clearValidation('ChartOfAccountId')

                    setSelectedEmolumentsPaymentSchedules({
                      ...selectedEmolumentsPaymentSchedules,
                      chartOfAccountId: r.id,
                      chartOfAccountName: r.description,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default AddEmolumentsPaymentSchedules
