/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import CRMRecuringProcedureEntries from './CRMRecuringProcedureEntries'
import { formatDate } from '../../../../../Helpers/formatters'
function ViewRecuringProcedureModal({
  mode,
  selectedRecuringProcedure,
  closeModal,
}) {
  const [entriesTotal, setEntriesTotal] = useState(null)
  const [displayedEntries, setDisplayedEntries] = useState(null)
  const [reload, setReload] = useState(false)

  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={localize('recurringProcedures')}
        handleClose={closeModal}
        modalClassName="modal-xl"
        hideUpdate
        footerChildren={
          <>
            <div className="col-3 d-flex align-items-center">
              <label className="col-2 text-capitalize">
                {localize('entries')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={displayedEntries + '/' + entriesTotal || ''}
              />
            </div>
          </>
        }
      >
        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('batchNumber')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedRecuringProcedure?.paddedBatchNumber || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('dateFilter')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedRecuringProcedure?.typeDescription || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('reference')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedRecuringProcedure?.reference || ''}
              disabled
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('referenceMonth')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedRecuringProcedure?.monthDescription || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('valueDate')}
            </label>
            <input
              className="form-control ms-2"
              value={formatDate(selectedRecuringProcedure?.valueDate) || ''}
              disabled
            />
          </div>
          <div className="col d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('priority')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedRecuringProcedure?.priorityDescription || ''}
              disabled
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-4 d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('createdBy')}
            </label>
            <input
              className="form-control ms-2"
              value={selectedRecuringProcedure?.createdBy || ''}
              disabled
            />
          </div>
          <div className="col-4 d-flex align-items-center mb-2">
            <label className="col-3 text-capitalize">
              {localize('createdDate')}
            </label>
            <input
              className="form-control ms-2"
              value={formatDate(selectedRecuringProcedure?.createdDate) || ''}
              disabled
            />
          </div>
        </div>

        <div className="row col-12 mt-2">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <CRMRecuringProcedureEntries
                    selectedRecuringProcedure={selectedRecuringProcedure}
                    setEntriesTotal={setEntriesTotal}
                    setDisplayedEntries={setDisplayedEntries}
                    reload={reload}
                    setReload={setReload}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalTemplate>
    </>
  )
}
export default ViewRecuringProcedureModal
