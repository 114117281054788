/** @format */

import { getData } from './webApi'

export const getCommissions = async () => {
  const { success, data } = await getData(
    '/Commission/find-commissions',
    null,
    false
  )
  if (success) {
    return data.sort((a, b) => a.description?.localeCompare(b.description))
  }
}

export const getInvestmentProducts = async () => {
  const { success, data } = await getData(
    '/InvestmentProduct/find-investment-products',
    {},
    false
  )
  if (success) {
    return data.sort((a, b) => a.description?.localeCompare(b.description))
  }
}

export const getDirectDebits = async () => {
  const { success, data } = await getData(
    '/DirectDebit/find-direct-debits',
    {},
    false
  )
  if (success) {
    return data.result.sort((a, b) =>
      a.description?.localeCompare(b.description)
    )
  }
}

export const getLevies = async () => {
  const { success, data } = await getData('/Levy/find-levies', {}, false)
  if (success) {
    return data.sort((a, b) => a.description?.localeCompare(b.description))
  }
}

export const getLoanProducts = async () => {
  const { success, data } = await getData(
    '/LoanProduct/find-loan-products',
    {},
    false
  )
  if (success) {
    return data.sort((a, b) => a.description?.localeCompare(b.description))
  }
}

export const getSavingsProduct = async () => {
  const { success, data } = await getData(
    '/SavingsProduct/find-savings-products',
    {},
    false
  )
  if (success) {
    return data.sort((a, b) => a.description?.localeCompare(b.description))
  }
}

export const getDynamicCharges = async () => {
  const { success, data } = await getData(
    '/DynamicCharge/find-dynamic-charges',
    null,
    false
  )
  if (success) {
    return data.result.sort((a, b) =>
      a.description?.localeCompare(b.description)
    )
  }
}

export const getRoles = async () => {
  const { success, data } = await getData('/lookup/Role/find-roles', {}, false)
  if (success) {
    return data.result.sort((a, b) =>
      a.description?.localeCompare(b.description)
    )
  }
}

export const getBranches = async () => {
  const { success, data } = await getData('/Branch/find-all', {}, false)
  if (success) {
    return data.sort((a, b) => a.description?.localeCompare(b.description))
  }
}

export const entitiesToBeChecked = (entities, selectedEntities) => {
  const entitiesToBeSelectedSet = new Set(selectedEntities.map(c => c.id))

  return entities.map(entity => {
    return entitiesToBeSelectedSet.has(entity.id)
      ? { ...entity, checked: true }
      : { ...entity, checked: false }
  })
}
