/** @format */
import LoanProducts from '../../../../Components/Selectables/Plain/LoanProducts'

export const CreditTypesOffsetableLoanProducts = ({
  creditTypeOffsetableLoanProducts,
  loading,
  disabled,
  //mode,
  onCheckCreditTypeOffsetableLoanProducts,
  prefix,
  //onToggleSelection,
}) => {
  //@TODO: Toggle between FOSA and BOSA product selections
  // const [toggleBosa, setToggleBosa] = useState(false)
  // const [toggleFosa, setToggleFosa] = useState(false)

  return (
    <>
      <LoanProducts
        disabled={disabled}
        isBusy={loading}
        prefix={prefix}
        onCheckLoanProduct={r => {
          onCheckCreditTypeOffsetableLoanProducts &&
            onCheckCreditTypeOffsetableLoanProducts(r)
        }}
        loanProductsToBeSelected={creditTypeOffsetableLoanProducts}
      />
      {/*{(mode === 'edit' || mode === 'add') && (*/}
      {/*  <div className="d-flex align-items-center me-5 mt-5">*/}
      {/*    <Checkbox*/}
      {/*      id="toggle-fosa"*/}
      {/*      checked={toggleFosa}*/}
      {/*      onCheck={v => {*/}
      {/*        setToggleFosa(v)*/}
      {/*        setToggleBosa(false)*/}
      {/*        onToggleSelection({ status: v, productSection: 0 })*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <label htmlFor="toggle-fosa" className="text-capitalize ms-2">*/}
      {/*      Toggle Selection (FOSA)*/}
      {/*    </label>*/}
      {/*    <Checkbox*/}
      {/*      id="toggle-bosa"*/}
      {/*      checked={toggleBosa}*/}
      {/*      onCheck={v => {*/}
      {/*        setToggleBosa(v)*/}
      {/*        setToggleFosa(false)*/}
      {/*        onToggleSelection({ status: v, productSection: 1 })*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <label htmlFor="toggle-bosa" className="text-capitalize ms-2">*/}
      {/*      Toggle Selection (BOSA)*/}
      {/*    </label>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  )
}
