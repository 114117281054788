/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import MenuItemWithChild from './MenuItemWithChild'
import MenuItem from './MenuItem'
import localize from '../../Global/Localize'

function SubMenus({ menu, closeMenu }) {
  const depth = 0
  const [setups, setSetups] = useState(true)
  const [operations, setOperations] = useState(true)

  useEffect(() => {
    setSetups(true)
    setOperations(true)
  }, [menu])

  return (
    <motion.div
      initial={{ left: 200, opacity: 0, width: 0 }}
      animate={{ left: 50, opacity: 1, width: 200 }}
      exit={{ left: 200, opacity: 0, width: 0 }}
      className="sub-menu-container bg-white"
    >
      <div className="actions">
        <div className="action" onClick={closeMenu}>
          <i className="uil uil-angle-left"></i>
          <div>Close</div>
        </div>
      </div>
      <div className="title">{localize(menu.label)}</div>
      <div
        onClick={() => setSetups(!setups)}
        className="menu-title inside d-flex align-items-center justify-content-between my-2 mt-3"
        style={{ marginLeft: -10, marginRight: -20, marginTop: 10 }}
      >
        <span>{localize('setup')}</span>
        <i className={`uil uil-${setups ? 'minus' : 'plus'}`}></i>
      </div>
      {setups && (
        <>
          {menu.setup.map((menu, i) =>
            menu.children ? (
              <MenuItemWithChild
                depth={depth + 1}
                key={`sub-menu-setup-${menu.label}-${i}`}
                menu={menu}
                disabled={menu.disabled}
              />
            ) : (
              <MenuItem
                depth={depth + 1}
                key={`sub-menu-setup-${menu.label}-${i}`}
                menu={menu}
                disabled={menu.disabled}
              />
            )
          )}
        </>
      )}
      <div
        onClick={() => setOperations(!operations)}
        className="menu-title inside d-flex align-items-center justify-content-between mb-2 mt-3"
        style={{ marginLeft: -10, marginRight: -20 }}
      >
        <span>{localize('operations')}</span>
        <i className={`uil uil-${operations ? 'minus' : 'plus'}`}></i>
      </div>
      {operations && (
        <>
          {menu.operations.map((menu, i) =>
            menu.children ? (
              <MenuItemWithChild
                depth={depth + 1}
                key={`sub-menu-operation-${menu.label}-${i}`}
                menu={menu}
                disabled={menu.disabled}
              />
            ) : (
              <MenuItem
                depth={depth + 1}
                key={`sub-menu-operation-${menu.label}-${i}`}
                menu={menu}
                disabled={menu.disabled}
              />
            )
          )}
        </>
      )}
    </motion.div>
  )
}

export default SubMenus
