/** @format */

import localize from '../../../../Global/Localize'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'
import React from 'react'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import PointOfSaleAgencyLookup from '../../../../Components/Lookup/PointOfSaleAgencyLookup'
import { EmploymentStatus, TellerType } from '../../../../Helpers/constants'
import EmployeeLookup from '../../../../Components/Lookup/EmployeeLookup'
import swal from 'sweetalert2'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'

export const AddTellersModal = ({
  selectedTeller,
  setSelectedTeller,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('type')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="TellerType"
                value={selectedTeller?.type}
                onChange={e => {
                  setSelectedTeller({
                    ...selectedTeller,
                    type: e.value,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-bag={validationBag?.Description?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('name')}
              </label>
              <input
                value={selectedTeller?.description}
                className="form-control"
                onChange={e => {
                  clearValidation('Description')
                  setSelectedTeller({
                    ...selectedTeller,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              validation-message={validationBag?.RangeLowerLimit?.message}
              className="col d-flex align-items-center with-validation"
            >
              <label className="col-3 text-capitalize">
                {localize('lowerLimit')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedTeller?.rangeLowerLimit}
                onMaskNumber={r => {
                  clearValidation('RangeLowerLimit')
                  setSelectedTeller({
                    ...selectedTeller,
                    rangeLowerLimit: r.target.value,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('upperLimit')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedTeller?.rangeUpperLimit}
                onMaskNumber={r => {
                  setSelectedTeller({
                    ...selectedTeller,
                    rangeUpperLimit: r.target.value,
                  })
                }}
              />
            </div>
          </div>
          {selectedTeller.type === TellerType.EMPLOYEE && (
            <EmployeeSetup
              setSelectedTeller={setSelectedTeller}
              selectedTeller={selectedTeller}
            />
          )}
          {selectedTeller.type === TellerType.ATM_TERMINAL && (
            <AtmTerminalSetup
              setSelectedTeller={setSelectedTeller}
              selectedTeller={selectedTeller}
            />
          )}
          {selectedTeller.type === TellerType.POS_TERMINAL_AGENT && (
            <PointOfSaleTerminalAgencySetup
              setSelectedTeller={setSelectedTeller}
              selectedTeller={selectedTeller}
            />
          )}
          {selectedTeller.type === TellerType.POS_TERMINAL_IN_HOUSE && (
            <PosTerminalInHouse
              setSelectedTeller={setSelectedTeller}
              selectedTeller={selectedTeller}
            />
          )}
        </>
      )}
    </>
  )
}

const PointOfSaleTerminalAgencySetup = ({
  selectedTeller,
  setSelectedTeller,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div
          validation-message={validationBag?.PointOfSaleAgencyId?.message}
          className="col d-flex align-items-center with-validation"
        >
          <label className="col-3 text-capitalize">
            {localize('posAgency')}
          </label>
          <PointOfSaleAgencyLookup
            displayValue={selectedTeller?.pointOfSaleAgencyDescription}
            onSelect={r => {
              clearValidation('PointOfSaleAgencyId')
              setSelectedTeller({
                ...selectedTeller,
                pointOfSaleAgencyId: r.id,
                pointOfSaleAgencyDescription: r.description,
                employeeId: '',
                shortageChartOfAccountId: '',
                excessChartOfAccountId: '',
                chartOfAccountId: '',
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            value={selectedTeller?.reference}
            onChange={e => {
              setSelectedTeller({
                ...selectedTeller,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}

const AtmTerminalSetup = ({ selectedTeller, setSelectedTeller }) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.ChartOfAccountId?.message}
        >
          <label className="col-3 text-capitalize">
            {localize('glAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={selectedTeller?.chartOfAccountDescription}
            onSelect={r => {
              clearValidation('ChartOfAccountId')
              setSelectedTeller({
                ...selectedTeller,
                chartOfAccountId: r.id,
                chartOfAccountDescription: r.name,
                employeeId: '',
                shortageChartOfAccountId: '',
                excessChartOfAccountId: '',
                pointOfSaleAgencyId: '',
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input
            className="form-control"
            value={selectedTeller?.reference}
            onChange={e => {
              setSelectedTeller({
                ...selectedTeller,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}

const EmployeeSetup = ({ selectedTeller, setSelectedTeller }) => {
  const handleOnSelectEmployee = e => {
    if (e?.employmentStatus !== EmploymentStatus.ACTIVE || e?.isLocked) {
      swal.fire('Add Teller', 'Employee is not active or locked', 'warning')
      return
    }
    clearValidation('EmployeeId')
    setSelectedTeller({
      ...selectedTeller,
      employeeId: e.id,
      employeeDescription: e.customerFullName,
    })
  }

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.ChartOfAccountId?.message}
        >
          <label className="col-3 text-capitalize">
            {localize('glAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={selectedTeller?.chartOfAccountDescription}
            onSelect={r => {
              clearValidation('ChartOfAccountId')
              setSelectedTeller({
                ...selectedTeller,
                chartOfAccountId: r.id,
                chartOfAccountDescription: r.name,
              })
            }}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.ShortageChartOfAccountId?.message}
        >
          <label className="col-3 text-capitalize">
            {localize('shortageGlAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={selectedTeller?.shortageChartOfAccountDescription}
            onSelect={r => {
              clearValidation('ShortageChartOfAccountId')
              setSelectedTeller({
                ...selectedTeller,
                shortageChartOfAccountId: r.id,
                shortageChartOfAccountDescription: r.name,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.ExcessChartOfAccountId?.message}
        >
          <label className="col-3 text-capitalize">
            {localize('excessGlAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={selectedTeller?.excessChartOfAccountDescription}
            onSelect={r => {
              clearValidation('ExcessChartOfAccountId')
              setSelectedTeller({
                ...selectedTeller,
                excessChartOfAccountId: r.id,
                excessChartOfAccountDescription: r.name,
              })
            }}
          />
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.EmployeeId?.message}
        >
          <label className="col-3 text-capitalize">
            {localize('employee')}
          </label>
          <EmployeeLookup
            onSelect={handleOnSelectEmployee}
            displayValue={selectedTeller?.employeeDescription}
          />
        </div>
      </div>
    </>
  )
}

const PosTerminalInHouse = ({ selectedTeller, setSelectedTeller }) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      <div className="row mb-3">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={validationBag?.ChartOfAccountId?.message}
        >
          <label
            style={{ width: 12.3 + '%' }}
            className="col-1 text-capitalize"
          >
            {localize('glAccount')}
          </label>
          <ChartOfAccountsLookup
            displayValue={selectedTeller?.chartOfAccountDescription}
            onSelect={r => {
              clearValidation('ChartOfAccountId')
              setSelectedTeller({
                ...selectedTeller,
                chartOfAccountId: r.id,
                chartOfAccountDescription: r.name,
                employeeId: '',
                shortageChartOfAccountId: '',
                excessChartOfAccountId: '',
                pointOfSaleAgencyId: '',
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label
            style={{ width: 12.3 + '%' }}
            className="col-1 text-capitalize"
          >
            {localize('reference')}
          </label>
          <input
            className="form-control"
            value={selectedTeller?.reference}
            onChange={e => {
              setSelectedTeller({
                ...selectedTeller,
                reference: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
