/** @format */
import React, { useState, useEffect } from 'react'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { getData, postData } from '../../../../../../Helpers/webApi'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import LocationLookup from '../../../../../../Components/Lookup/LocationLookup'
import { ItemRegisterLookup } from '../../../../../../Components/Lookup/ItemRegisterLookup'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import DepartmentLookup from '../../../../../../Components/Lookup/DepartmentLookup'
import GoodsDispatchNoteEntriesItems from './GoodsDispatchNoteEntriesItems'
import RequisitionLookup from '../../../../../../Components/Lookup/RequisitionLookup'
import RequisitionItemLookup from '../../../../../../Components/Lookup/RequisitionItemLookup'
import EmployeeLookup from '../../../../../../Components/Lookup/EmployeeLookup'

const AddGoodsDispatchNote = ({
  mode,
  closeModal,
  goodsDispatchNote,
  setGoodsDispatchNote,
  loadTableData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [entry, setEntry] = useState(null)
  const [existingEntries, setExistingEntries] = useState([])
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [requisition, setRequisition] = useState(null)

  const loadEntries = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/GoodsDispatchNote/find-goods-dispatch-note-items-by-goods-dispatch-note-id-filter-in-page`,
      {
        goodsDispatchNoteId: goodsDispatchNote?.id,
        ...reqParams,
      },
      false
    )
    if (success) {
      setExistingEntries(data.pageCollection)
    } else {
      setExistingEntries([])
    }
    setIsBusy(false)
  }

  useEffect(() => {
    loadEntries()
    // eslint-disable-next-line
  }, [goodsDispatchNote?.id, reqParams.pageSize, reqParams.pageIndex])

  async function postGoodsDispatchNote() {
    setIsBusy(true)
    const { success } = await postData(
      '/GoodsDispatchNote/add-goods-dispatch-note',
      {
        goodsDispatchNote: {
          ...goodsDispatchNote,
          status: 1,
        },
        goodsDispatchNoteItems: entries,
      },
      false
    )
    if (success) {
      showNotification('Goods Dispatch Notes', 'success')
      closePage()
      loadTableData()
      setEntry(null)
    }
    setIsBusy(false)
  }

  const onSelectItem = (cust, checked) => {
    if (checked) {
      setEntries([...entries, cust])
    } else {
      const newEntry = entries.filter(
        data => data?.goodsDispatchNoteId !== cust?.goodsDispatchNoteId
      )
      setEntries(newEntry)
    }
  }

  async function stageEntry() {
    if (entries.find(d => d.requisitionItemId === entry.requisitionItemId)) {
      showNotification(
        'Goods Dispatch Notes',
        'info',
        'Sorry, but the selected requisition item is already added !'
      )
    } else {
      setEntries([
        ...entries,
        {
          goodsDispatchNoteId: goodsDispatchNote?.id,
          requisitionPaddedReferenceNumber:
            entry?.requisitionPaddedReferenceNumber,
          requisitionItemRequisitionId: entry?.requisitionItemRequisitionId,
          requisitionItemId: entry?.requisitionItemId,
          quantity: entry?.quantity,
          itemRegisterId: entry?.itemRegisterId,
          itemRegisterDescription: entry?.itemRegisterDescription,
          itemRegisterItemType: entry?.itemRegisterItemType,
          itemRegisterItemTypeDescription:
            entry?.itemRegisterItemTypeDescription,
          itemRegisterInventoryCategoryDescription:
            entry?.itemRegisterInventoryCategoryDescription,
          itemRegisterAssetTypeDescription:
            entry?.itemRegisterAssetTypeDescription,
        },
      ])
      setEntry(null)
      setEntry({ quantity: '' })
    }
  }
  const handleRemove = entry => {
    const filteredData = entries.filter(d => d.id !== entry.id)
    setEntries(filteredData)
  }

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  const closePage = () => {
    setGlobalValidationErrors({})
    closeModal()
  }

  return (
    <ModalTemplate
      modalMode={'add'}
      modalTitle="goodsDispatchNote"
      handleClose={closePage}
      modalClassName="modal-xl"
      actionText={'dispatch'}
      handleAction={() => {
        postGoodsDispatchNote()
      }}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center mb-2 with-validation"
              validation-message={
                globalValidationErrors['GoodsDispatchNote.DepartmentId']
                  ?.message
              }
            >
              <label className="text-capitalize col me-4">
                {localize('department')}
              </label>
              <DepartmentLookup
                displayValue={goodsDispatchNote?.departmentDescription}
                onSelect={r => {
                  clearValidation('GoodsDispatchNote.DepartmentId')
                  setGoodsDispatchNote({
                    ...goodsDispatchNote,
                    departmentId: r.id,
                    departmentDescription: r.description,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div
              className="col d-flex align-items-center mb-2 with-validation"
              validation-message={
                globalValidationErrors['GoodsDispatchNote.LocationId']?.message
              }
            >
              <label className="text-capitalize col me-5">
                {localize('location')}
              </label>
              <LocationLookup
                displayValue={goodsDispatchNote?.locationDescription}
                onSelect={r => {
                  clearValidation('GoodsDispatchNote.LocationId')
                  setGoodsDispatchNote({
                    ...goodsDispatchNote,
                    locationId: r.id,
                    locationDescription: r.description,
                  })
                }}
              />
            </div>

            <div
              className="col d-flex align-items-center mb-2 with-validation"
              validation-message={
                globalValidationErrors['GoodsDispatchNote.EmployeeId']?.message
              }
            >
              <label className="text-capitalize col-md-2">
                {localize('employee')}
              </label>
              <EmployeeLookup
                displayValue={goodsDispatchNote?.customerFullName}
                onSelect={r => {
                  clearValidation('GoodsDispatchNote.EmployeeId')
                  setGoodsDispatchNote({
                    ...goodsDispatchNote,
                    employeeId: r.id,
                    customerFullName: r.customerFullName,
                  })
                }}
              />
            </div>
          </div>
          <>
            <div
              className="p-2 mt-2"
              style={{
                border: 'solid 1px #0000001f',
                borderRadius: 10,
                position: 'relative',
              }}
            >
              <div
                className="bg-white px-2 row text-capitalize"
                style={{
                  position: 'relative',
                  top: -20,
                  left: 30,
                  maxWidth: 'fit-content',
                }}
              >
                {localize('itemDetails')}
              </div>

              <>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="text-capitalize col-md-1 me-3">
                      {localize('requisition')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: goodsDispatchNote ? 1 : 0.6,
                        pointerEvents: goodsDispatchNote ? 'all' : 'none',
                      }}
                    >
                      <RequisitionLookup
                        displayValue={entry?.requisitionPaddedReferenceNumber}
                        onSelect={v => {
                          setEntry(prev => ({
                            ...prev,
                            requisitionPaddedReferenceNumber:
                              v.paddedReferenceNumber,
                            requisitionItemRequisitionId: v.id,
                          }))
                          setRequisition(v.id)
                        }}
                        departmentId={goodsDispatchNote?.departmentId}
                        departmentDescription={
                          goodsDispatchNote?.departmentDescription
                        }
                      />
                    </div>
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="text-capitalize col-md-2">
                      {localize('itemDescription')}
                    </label>
                    <div
                      style={{
                        width: '100%',
                        opacity: entry?.requisitionItemRequisitionId ? 1 : 0.6,
                        pointerEvents: entry?.requisitionItemRequisitionId
                          ? 'all'
                          : 'none',
                      }}
                    >
                      <RequisitionItemLookup
                        displayValue={entry?.requisitionReference}
                        onSelect={v => {
                          setEntry(prev => ({
                            ...prev,
                            requisitionReference: v.itemRegisterDescription,
                            requisitionItemId: v.id,
                            quantity: v.quantity,
                          }))
                        }}
                        requisitionId={requisition}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col d-flex align-items-center ms-2">
                    <label className="col-md-1 text-capitalize">
                      {localize('quantity')}
                    </label>
                    <MaskNumber
                      className="form-control ms-3"
                      defaultMaskValue={entry?.quantity}
                      onMaskNumber={e => {
                        setEntry({
                          ...entry,
                          quantity: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-center">
                    <label className="col-md-2 text-capitalize">
                      {localize('assetType')}
                    </label>
                    <ItemRegisterLookup
                      displayValue={entry?.itemRegisterDescription}
                      onSelect={r => {
                        setEntry({
                          ...entry,
                          itemRegisterId: r.id,
                          itemRegisterDescription: r.description,
                          itemRegisterItemType: r.itemType,
                          itemRegisterItemTypeDescription:
                            r.itemTypeDescription,
                          itemRegisterInventoryCategoryDescription:
                            r.inventoryCategoryDescription,
                          itemRegisterAssetTypeDescription:
                            r.assetTypeDescription,
                        })
                      }}
                    />
                  </div>
                </div>
              </>
            </div>

            <div className="mt-2 bg-light p-2 d-flex align-items-center justify-content-end">
              <button
                onClick={() => {
                  if (!entry?.requisitionItemId) {
                    showNotification(
                      'Goods Dispatch Notes',
                      'info',
                      'please select requisition item'
                    )
                  } else if (!entry?.itemRegisterDescription) {
                    showNotification(
                      'Goods Dispatch Notes',
                      'info',
                      'please select asset type'
                    )
                  } else if (!entry?.quantity) {
                    showNotification(
                      'Goods Dispatch Notes',
                      'info',
                      'please enter quantity'
                    )
                  } else {
                    stageEntry()
                  }
                }}
                className="btn btn-success px-5"
              >
                {localize('add')}
              </button>
            </div>
            <div className="mt-3">
              <Tab
                tabItems={[
                  {
                    label: localize('goodsDispatchNoteItems'),
                    item: (
                      <GoodsDispatchNoteEntriesItems
                        unpostedEntries={entries}
                        handleRemove={handleRemove}
                        mode={mode}
                        entries={existingEntries}
                        onSelectItem={onSelectItem}
                        loadEntries={loadEntries}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        </>
      )}
    </ModalTemplate>
  )
}

export default AddGoodsDispatchNote
