/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import ListCustomer from '../../../Components/ReUsables/Customer/ListCustomer'
import localize from '../../../Global/Localize'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import { ViewAccountAlertPreference } from './Partials/ViewAccountAlertPreference'
import { getData } from '../../../Helpers/webApi'
import Tab from '../../../Components/Tabs/Tab'
import AccountAlertsModal from './Partials/AccountAlertsModal'

function AccountAlerts() {
  const [customerAccountAlerts, setCustomerAccountAlerts] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)
  const [mode, setMode] = useState(null)
  const [clickedOnce, setClickedOnce] = useState(false)

  const fetchCustomerAccountAlerts = async customer => {
    const { success, data } = await getData(
      '/AccountAlert/find-account-alerts-by-customer-id',
      {
        customerId: customer.id,
      }
    )
    if (success) {
      setCustomerAccountAlerts(data.result || [])
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[localize('registryManagement'), localize('accountAlerts')]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <ListCustomer
                canClickOnce
                contextMenu={r => (
                  <>
                    <div
                      onClick={() => {
                        setClickedOnce(false)
                        setMode('edit')
                        setCustomerAccountAlerts([])
                        setSelectedCustomer(r)
                        fetchCustomerAccountAlerts(r)
                      }}
                      className="ctxt-menu-item"
                    >
                      <i className="uil uil-eye"></i>
                      {localize('edit')}
                    </div>
                  </>
                )}
                onClickOnce={r => {
                  setSelectedCustomer(r)
                  setClickedOnce(true)
                  fetchCustomerAccountAlerts(r)
                }}
              />
              {clickedOnce && (
                <div className="row mb-3">
                  <Tab
                    tabItems={[
                      {
                        label: localize('accountAlertPreferences'),
                        item: (
                          <ViewAccountAlertPreference
                            accountAlertPreferences={customerAccountAlerts}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AccountAlertsModal
        customerAccountAlerts={customerAccountAlerts}
        setNewAccountAlerts={setCustomerAccountAlerts}
        mode={mode}
        onHideModal={() => setMode(null)}
        selectedCustomer={selectedCustomer}
        onUpdateRefreshAt={() => setRefreshAt(!refreshAt)}
        onSetMode={m => {
          setMode(m)
        }}
      />
    </>
  )
}

export default AccountAlerts
