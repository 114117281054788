/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb/Index'
import AddNewButton from '../../../../Components/Buttons/AddNewButton/AddNewButton'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import localize from '../../../../Global/Localize'
import { formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import EditDesignationModal from './EditDesignationModal'
import ViewDesignationModal from './ViewDesignationModal'

function Designations() {
  const columns = [
    {
      label: localize('name'),
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r?.indentedDescription }} />
      ),
      sortBy: 'description',
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r?.isLocked?.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
      sortBy: 'createdBy',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [selectedDesignation, setSelectedDesignation] = useState(null)
  const [tableData, setTableData] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [refresh, setRefresh] = useState(false)

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/Designation/find-designation-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.pageIndex,
    reqParams.pageSize,
    reqParams.updateDepth,
    reqParams.traverseTree,
    refresh,
  ])

  const handleAdd = () => {
    setMode('add')
    setSelectedDesignation(null)
  }
  return (
    <>
      <BreadCrumb
        tree={[localize('humanResourceManagement'), localize('designations')]}
      />
      <div className="row">
        <div className="col12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <AddNewButton handleClickAddButton={handleAdd} />
                </div>
              </div>
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center ">
                      <RecordsPerPage
                        reqParams={reqParams}
                        setReqParams={setReqParams}
                      />
                    </div>
                    <div className="d-flex align-items-center ">
                      <Checkbox
                        id="designationsTraverseTreeCheckbox"
                        checked={reqParams.traverseTree}
                        onCheck={value => {
                          setReqParams({
                            ...reqParams,
                            traverseTree: value,
                          })
                        }}
                      />
                      <label
                        htmlFor="designationsTraverseTreeCheckbox"
                        className="text-capitalize me-2"
                      >
                        {localize('traverseTree')}
                      </label>
                    </div>
                    <div className="d-flex align-item-center gap-2">
                      <form
                        className="d-flex align-items-center"
                        onSubmit={e => {
                          e.preventDefault()
                          setRefresh(!refresh)
                        }}
                      >
                        <label
                          className="text-capitalize"
                          htmlFor="searchTable"
                        >
                          {localize('search')}
                        </label>
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control ms-2"
                          defaultValue={reqParams.filterText}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              filterText: e.target.value,
                              pageIndex: 0,
                            })
                          }
                        />
                      </form>
                      <button
                        id="departmentsFilterButton"
                        className="btn btn-primary ms-1"
                        onClick={() => setRefresh(!refresh)}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      data={tableData}
                      columns={columns}
                      contextMenu={r => (
                        <>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('edit')
                              setSelectedDesignation(r)
                            }}
                          >
                            <i className="uil uil-edit-alt"></i>
                            {localize('edit')}
                          </div>
                          <div
                            className="ctxt-menu-item text-capitalize"
                            onClick={() => {
                              setMode('view')
                              setSelectedDesignation(r)
                            }}
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {mode && (
        <EditDesignationModal
          selectedDesignation={selectedDesignation}
          mode={mode}
          editMode={mode === 'edit'}
          close={() => setMode(null)}
          loadTableData={loadTableData}
        />
      )}
      {mode === 'view' && (
        <ViewDesignationModal
          selectedDesignation={selectedDesignation}
          mode={mode}
          close={() => setMode(null)}
          setSelectedDesignation={setSelectedDesignation}
        />
      )}
    </>
  )
}

export default Designations
