/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import DatePicker from '../Date/DatePicker'
import Loader from '../Loaders/Loader'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

function AccountClosureRequestLookup({
  disabled,
  displayValue,
  onSelect,
  status,
}) {
  const [modalActive, setModalActive] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    customerFilter: 0,
    status: status ?? 1,
    startDate: null,
    endDate: null,
    includeProductDescription: true,
    customDateFilter: 15,
  })

  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.customerAccountFullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.customerAccountCustomerFullName}</>,
    },
    {
      label: localize('ref_1Account'),
      template: r => <>{r?.customerAccountCustomerReference1}</>,
    },
    {
      label: localize('ref_2Membership'),
      template: r => <>{r?.customerAccountCustomerReference2}</>,
    },
    {
      label: localize('ref_3PersonalFile'),
      template: r => <>{r?.customerAccountCustomerReference3}</>,
    },
    {
      label: localize('reason'),
      template: r => <>{r?.reason}</>,
    },
    {
      label: localize('approval') + '/' + localize('rejectionRemarks'),
      template: r => <>{r?.approvalRemarks}</>,
    },
    {
      label: localize('approved') + '/' + localize('rejectedBy'),
      template: r => <>{r?.approvedBy}</>,
    },
    {
      label: localize('approved') + '/' + localize('rejectedDate'),
      template: r => <>{formatDate(r?.approvedDate, true)}</>,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => <>{r?.auditRemarks}</>,
    },
    {
      label: localize('verified') + '/' + localize('rejectedBy'),
      template: r => <>{r?.auditedBy}</>,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => <>{formatDate(r?.auditedDate)}</>,
    },
    {
      label: localize('settledBy'),
      template: r => <>{r?.settledBy}</>,
    },
    {
      label: localize('settledDate'),
      template: r => <>{formatDate(r?.settledDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  async function loadData() {
    setIsBusy(true)

    const { success, data } = await getData(
      '/AccountClosureRequest/find-account-closure-requests-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data)
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('accountClosureRequestLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between mb-3 mt-3 gap-1">
                  <div className="d-flex align-items-center">
                    <label
                      style={{
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                      }}
                      className="text-capitalize"
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      className="form-select ms-2"
                      style={{ maxWidth: 'fit-content' }}
                      value={reqParams.pageSize}
                      onChange={e =>
                        setReqParams({ ...reqParams, pageSize: e.target.value })
                      }
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div
                    className="col d-flex align-items-center gap-2"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        maxWidth: 150,
                        minWidth: 150,
                      }}
                      value={reqParams?.status}
                      enumsKey="AccountClosureRequestStatus"
                      onChange={({ value }) =>
                        setReqParams({
                          ...reqParams,
                          status: value,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      value={{
                        startDate: reqParams?.startDate,
                        endDate: reqParams?.endDate,
                      }}
                      onSelect={v => {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      }}
                    />
                  </div>
                  <div
                    className="col d-flex align-items-center gap-2"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <label
                      style={{ minWidth: 'fit-content' }}
                      className="text-capitalize me-2"
                    >
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      sort
                      customContainerStyle={{
                        maxWidth: 150,
                        minWidth: 150,
                      }}
                      value={reqParams?.customerFilter}
                      enumsKey="CustomerFilter"
                      onChange={({ value }) =>
                        setReqParams({ ...reqParams, customerFilter: value })
                      }
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={reqParams?.text}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          text: e.target.value,
                          pageIndex: 0,
                        })
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          loadData()
                        }
                      }}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={tableData?.pageCollection}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                  />
                </div>
                <Pager
                  itemsCount={tableData.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default AccountClosureRequestLookup
