/** @format */

import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Loader from '../../../../../../../Components/Loaders/Loader'
import Pager from '../../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import { loadMedia } from '../../../../../../../Helpers/Modules/media'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../Helpers/formatters'
import { getData } from '../../../../../../../Helpers/webApi'
import ViewDisciplinaryCase from '../../../DisciplinaryCases/Partials/ViewDisciplinaryCase'

const PersonnelDisciplinaryCases = ({ vehiclePersonnel }) => {
  const [disciplinaryCase, setDisciplinaryCase] = useState({})
  const [mode, setMode] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [disciplinaryCases, setDisciplinaryCases] = useState([])
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })

  const columns = [
    {
      label: localize('type'),
      template: r => <>{r?.vehiclePersonnelTypeDescription}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r?.vehiclePersonnelCustomerFullName}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r?.vehiclePersonnelCustomerIdentificationNumber}</>,
    },
    {
      label: localize('customerType'),
      template: r => (
        <>{r?.vehiclePersonnelCustomerMembershipClassCustomerTypeDescription}</>
      ),
    },
    {
      label: localize('vehicleRegistrationNumber'),
      template: r => <>{r?.vehiclePersonnelVehicleRegistrationNumber}</>,
    },
    {
      label: localize('badgeImage'),
      template: r => <>{r?.vehiclePersonnelBadgeNumber}</>,
    },
    {
      label: localize('badgeExpiryDate'),
      template: r => (
        <>{formatDate(r?.vehiclePersonnelBadgeExpiryDate, true)}</>
      ),
    },
    {
      label: localize('incidentDate'),
      template: r => <>{formatDate(r?.incidentDate, true)}</>,
    },
    {
      label: localize('court'),
      template: r => <>{r?.court}</>,
    },
    {
      label: localize('courtDate'),
      template: r => <>{formatDate(r?.courtDate, true)}</>,
    },
    {
      label: localize('cashBail'),
      template: r => <>{formatCurrency(r?.cashBail)}</>,
    },
    {
      label: localize('sentForRefunds'),
      template: r => <>{formatCurrency(r?.sentForRefunds)}</>,
    },
    {
      label: localize('refunded'),
      template: r => <>{formatCurrency(r?.refunded)}</>,
    },
    {
      label: localize('fileTitle'),
      template: r => <>{r?.fileName}</>,
    },
    {
      label: localize('fileMIMEType'),
      template: r => <>{r?.fileMIMEType}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{r?.modifiedDate}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate, true)}</>,
    },
  ]

  const fetchDisciplinaryCases = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      `/VehiclePersonnelDisciplinaryCase/find-by-personnel-id-and-filter-in-page`,
      {
        ...reqParams,
        vehiclePersonnelId: vehiclePersonnel?.id,
      },
      false
    )

    if (success) {
      setDisciplinaryCases(data)
    }

    setIsBusy(false)
  }

  const handleExportDocument = async r => {
    setIsBusy(true)

    const media = await loadMedia(r?.id)

    if (media) {
      const linkSource = `data:${media?.contentType};base64,${media?.content}`
      const downloadLink = document.createElement('a')
      const fileName = media?.fileName
      downloadLink.href = linkSource
      downloadLink.download = fileName
      document.body.appendChild(downloadLink) // Required for this to work in some browsers
      downloadLink.click()
      downloadLink.remove()
    } else Swal.fire('Operation failed', 'Cant load file', 'error')

    setIsBusy(false)
  }

  const handleSelectCase = (r, mode) => {
    if (mode === 'viewDocument') {
      handleExportDocument(r)
      return
    }

    setDisciplinaryCase(r)
    setMode(mode)
  }

  const handleClose = () => {
    setMode(null)
    setDisciplinaryCase({})
  }

  useEffect(() => {
    if (vehiclePersonnel?.id) fetchDisciplinaryCases()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePersonnel?.id])

  return (
    <>
      {mode === 'view' && (
        <ViewDisciplinaryCase
          handleClose={handleClose}
          disciplinaryCase={disciplinaryCase}
          mode={mode}
        />
      )}

      <>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center my-2">
                <label
                  className="text-capitalize"
                  htmlFor="personnelDisciplinaryCases"
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  id="personnelDisciplinaryCases"
                  className="form-select ms-3"
                  style={{ width: 'fit-content' }}
                  defaultValue={reqParams.pageSize}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="d-flex align-items-center">
                <label
                  className="ms-2 text-capitalize me-3"
                  htmlFor="searchTable"
                >
                  {localize('search')}
                </label>
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-3"
                  defaultValue={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                      pageIndex: 0,
                    })
                  }}
                />
                <button
                  type="submit"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchDisciplinaryCases()
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchDisciplinaryCases()
                    }
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>

            <SimpleTable
              data={disciplinaryCases?.pageCollection}
              columns={columns}
              contextMenu={r => (
                <>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectCase(r, 'viewDocument')
                    }}
                  >
                    <i className="uil uil-books"></i>
                    <span>{localize('viewDocument')}</span>
                  </div>
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectCase(r, 'view')
                    }}
                  >
                    <i className="uil uil-eye"></i>
                    <span>{localize('view')}</span>
                  </div>
                </>
              )}
            />
            <Pager
              itemsCount={disciplinaryCases?.itemsCount}
              pageSize={reqParams?.pageSize}
              pageIndex={reqParams?.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </>
        )}
      </>
    </>
  )
}

export default PersonnelDisciplinaryCases
