/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import { getData } from '../../../../../Helpers/webApi'
import Pager from '../../../../../Components/Tables/Pager'
import GroupedTable from '../../../../../Components/Tables/GroupedTable'
import DatePicker from '../../../../../Components/Date/DatePicker'
import {
  directDebitRequestFilter,
  directDebitRequestPropertyGroup,
  directDebitRequestStatus,
} from '../../../../../Global/enumeration'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'

function Catalogue() {
  const [isBusy, setIsBusy] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [search, setSearch] = useState('')
  const [reqParams, setReqParams] = useState({
    status: Object.keys(directDebitRequestStatus)[0],
    startDate: null,
    endDate: null,
    filterText: '',
    directDebitRequestFilter: Object.keys(directDebitRequestFilter)[20],
    pageIndex: 0,
    pageSize: 10,
    customDate: false,
    dateRequestsFilter: 15,
  })
  const [groupBy, setGroupBy] = useState({
    label: 'type',
    column: 'typeDescription',
  })

  const columns = [
    {
      label: localize('resultCode'),
      template: r => r.resultCode,
    },
    {
      label: localize('reason'),
      template: r => r.reason,
    },
    {
      label: localize('type'),
      template: r => r.typeDescription,
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('customerName'),
      template: r => r.customerFullName,
    },
    {
      label: localize('memberNumber'),
      template: r => r.customerAccountCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r.customerAccountCustomerAccountNumber,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r.customerAccountCustomerPersonalFileNumber,
    },
    {
      label: localize('currency'),
      template: r => r.currency,
    },
    {
      label: localize('refNumber'),
      template: r => r.refNumber,
    },
    {
      label: localize('drAccount'),
      template: r => r.drAccount,
    },
    {
      label: localize('drAmount'),
      template: r => formatCurrency(r.drAmount),
    },
    {
      label: localize('crAccount'),
      template: r => r.crAccount,
    },
    {
      label: localize('crAmount'),
      template: r => formatCurrency(r.crAmount),
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r.fullAccountNumber,
    },
    {
      label: localize('postingDate'),
      template: r => r.postingDate,
    },
    {
      label: localize('narrative'),
      template: r => r.narrative,
    },
    {
      label: localize('mICR'),
      template: r => r.micr,
    },
    {
      label: localize('chequeNumber'),
      template: r => r.chequeNumber,
    },
    {
      label: localize('inputter'),
      template: r => r.inputter,
    },
    {
      label: localize('branchNumber'),
      template: r => r.branchNumber,
    },
    {
      label: localize('comments'),
      template: r => r.comments,
    },
    {
      label: localize('indicator'),
      template: r => r.indicator,
    },
    {
      label: localize('unpaidCode'),
      template: r => r.unpaidCode,
    },
    {
      label: localize('transactionType'),
      template: r => r.transactionType,
    },
    {
      label: localize('modifiedBy'),
      template: r => r.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/DirectDebitRequest/find-by-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setIsBusy(false)
      setError(true)
    }
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('directDebitRequests'),
          localize('directDebitCatalogue'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="col-md-6 d-flex align-items-center">
                    <label htmlFor="recordsPerPage" className="text-capitalize">
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      id="recordsPerPage"
                      className="form-select ms-3"
                      style={{ maxWidth: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6 d-flex align-items-center ms-4">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <div className="d-flex align-items-center ms-3">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="DirectDebitRequestFilter"
                        value={reqParams.directDebitRequestFilter}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            directDebitRequestFilter: e.value,
                          })
                        }
                        sort
                      />
                    </div>
                    <div className="d-flex align-items-center ms-2">
                      <form
                        className="d-flex align-items-center"
                        style={{ width: 185 }}
                        onSubmit={e => {
                          e.preventDefault()
                          setReqParams({
                            ...reqParams,
                            filterText: search,
                          })
                        }}
                      >
                        <input
                          type="search"
                          id="searchTable"
                          className="form-control"
                          defaultValue={reqParams.filterText}
                          onChange={e => setSearch(e.target.value)}
                        />
                      </form>
                      <button
                        id="FilterButton"
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={() => {
                          loadData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="col-md-4 d-flex align-items-center">
                    <label className="text-capitalize me-5" htmlFor="groupBy">
                      {localize('groupBy')}
                    </label>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="DirectDebitRequestPropertyGroup"
                          value={groupBy?.column === 'typeDescription' ? 0 : 1}
                          onChange={e => {
                            setGroupBy(
                              e.value == 0
                                ? { column: 'typeDescription', label: 'type' }
                                : {
                                    column: 'statusDescription',
                                    label: 'status',
                                  }
                            )
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-center gap-2">
                    <label className="text-capitalize" htmlFor="status">
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="DirectDebitRequestStatus"
                      value={reqParams.status}
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          status: parseInt(e.value),
                        })
                      }
                      sort
                    />
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <label
                      htmlFor="dateRange"
                      className="col-3 text-capitalize"
                    >
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                          })
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <GroupedTable
                groupBy={groupBy}
                columns={columns}
                data={data?.pageCollection}
                canClickOnce
                onClickOnce={() => {}}
              />
              <div className="mb-4"></div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Catalogue
