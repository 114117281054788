/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getData } from '../../../../../../Helpers/webApi'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Loader from '../../../../../../Components/Loaders/Loader'
import Tab from '../../../../../../Components/Tabs/Tab'

function ViewStandingOrder({ standingOrderId, onClose }) {
  const [standingOrder, setStandingOrder] = useState(null)
  const [executionHistory, setExecutionHistory] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({ pageIndex: 0, pageSize: 10 })

  async function loadStandingOrderById() {
    setIsBusy(true)

    const { success, data } = await getData(
      '/standingOrder/find-by-id',
      {
        standingOrderId,
      },
      false
    )
    if (success) {
      setStandingOrder(data)
    } else {
      setIsBusy(false)
    }
  }

  async function loadExecutionHistory() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/standingOrder/find-history-by-standing-order-id-in-page',
      { ...reqParams, standingOrderId },
      false
    )
    if (success) {
      setExecutionHistory(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    loadStandingOrderById()

    // eslint-disable-next-line
  }, [standingOrderId])

  useEffect(() => {
    loadExecutionHistory()
    // eslint-disable-next-line
  }, [reqParams])

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r?.postingPeriodDescription}</>,
    },
    { label: localize('month'), template: r => <>{r?.monthDescription}</> },
    {
      label: localize('beneficiaryProductName'),
      template: r => <>{r?.beneficiaryProductDescription}</>,
    },
    {
      label: localize('expectedPrincipal'),
      template: r => <>{formatCurrency(r?.expectedPrincipal)}</>,
    },
    {
      label: localize('expectedInterest'),
      template: r => <>{formatCurrency(r?.expectedInterest)}</>,
    },
    {
      label: localize('actualPrincipal'),
      template: r => <>{formatCurrency(r?.actualPrincipal)}</>,
    },
    {
      label: localize('actualInterest'),
      template: r => <>{formatCurrency(r?.actualInterest)}</>,
    },
    {
      label: localize('residualPrincipal'),
      template: r => <>{formatCurrency(r?.residualPrincipal)}</>,
    },
    {
      label: localize('residualInterest'),
      template: r => <>{formatCurrency(r?.residualInterest)}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r?.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r?.chargePercentage)}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{formatCurrency(r?.chargeFixedAmount)}</>,
    },
    { label: localize('trigger'), template: r => <>{r?.triggerDescription}</> },
    {
      label: localize('beneficiaryFullAccountNumber'),
      template: r => <>{r?.beneficiaryFullAccountNumber}</>,
    },
    {
      label: localize('benefactorFullAccountNumber'),
      template: r => <>{r?.benefactorFullAccountNumber}</>,
    },
    {
      label: localize('benefactorProductName'),
      template: r => <>{r?.benefactorProductDescription}</>,
    },
    {
      label: localize('benefactorName'),
      template: r => <>{r?.benefactorCustomerAccountCustomerFullName}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('transactionCode'),
      template: r => <>{r?.transactionCodeDescription}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  const tabItems = [
    {
      label: localize('executionHistory'),
      item: (
        <>
          <div className="d-flex mt-1 mb-2 align-items-center">
            <div className="d-flex align-items-center">
              <label
                className="text-capitalize"
                style={{ width: 'fit-content' }}
              >
                {localize('recordsPerPage')}
              </label>
              <select
                className="form-select ms-2"
                style={{ width: 'fit-content' }}
                value={reqParams?.pageSize}
                onChange={v =>
                  setReqParams({ ...reqParams, pageSize: v.target.value })
                }
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">100</option>
              </select>
            </div>
          </div>
          <SimpleTable
            columns={columns}
            data={executionHistory?.pageCollection || []}
          />
          <div className="mt-2">
            {executionHistory && (
              <Pager
                itemsCount={executionHistory?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            )}
          </div>
        </>
      ),
    },
  ]

  return (
    <ModalTemplate
      handleClose={onClose}
      modalTitle={localize('standingOrder')}
      modalClassName="modal-xl"
      modalMode={'view'}
      hideUpdate
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('benefactorAccount')} #
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.benefactorFullAccountNumber}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('benefactorProduct')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.benefactorProductDescription}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('benefactorAccountName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.benefactorCustomerAccountAccountName}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('beneficiaryAccount')} #
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.beneficiaryFullAccountNumber}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('benefactorProduct')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.beneficiaryProductDescription}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('beneficiaryAccountName')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.beneficiaryCustomerAccountAccountName}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('startDate')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={formatDate(standingOrder?.durationStartDate)}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('endDate')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={formatDate(standingOrder?.durationEndDate)}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('frequency')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.scheduleFrequencyDescription}
              />
            </div>
          </div>
          <hr />
          <div className="row mb-2">
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('paymentPerPeriod')}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'end' }}
                disabled
                value={formatCurrency(
                  standingOrder?.paymentPerPeriod,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('principal')}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'end' }}
                disabled
                value={formatCurrency(standingOrder?.principal, 2, 2, false)}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('interest')}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'end' }}
                disabled
                value={formatCurrency(standingOrder?.interest, 2, 2, false)}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('capitalizedInterest')}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'end' }}
                disabled
                value={formatCurrency(
                  standingOrder?.capitalizedInterest,
                  2,
                  2,
                  false
                )}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('trigger')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.triggerDescription}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('disbursedAmount')}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'end' }}
                disabled
                value={formatCurrency(
                  standingOrder?.beneficiaryCustomerAccountLoanCaseDisbursedAmount,
                  2,
                  2,
                  false
                )}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('disbursedDate')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={formatDate(
                  standingOrder?.beneficiaryCustomerAccountLoanCaseDisbursedDate,
                  true
                )}
              />
            </div>
            <div className="col d-flex align-items center">
              <label htmlFor="" className="text-capitalize col-4">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={standingOrder?.remarks}
              />
            </div>
          </div>
          <Tab tabItems={tabItems} />
        </>
      )}
    </ModalTemplate>
  )
}

export default ViewStandingOrder
