/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import PostingPeriodLookup from '../../../../../../Components/Lookup/PostingPeriodLookup'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import EmployeeTypeLookup from '../../../../../../Components/Lookup/EmployeeTypeLookup'
import { postData, putData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import Loader from '../../../../../../Components/Loaders/Loader'

const CreatePeriods = ({
  mode,
  setMode,
  loadTableData,
  handleClose,
  selectedSalaryPeriod,
  setSelectedSalaryPeriod,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    setIsBusy(true)
    const { success } = await postData(
      '/salaryperiod/create',
      { ...selectedSalaryPeriod },
      false
    )

    if (success) {
      showNotification(
        'Create Salary Period',
        'success',
        'Operation completed successfully!'
      )
      setIsBusy(false)
      setMode(null)
      setSelectedSalaryPeriod(null)
      loadTableData()
    }
    setIsBusy(false)
  }
  async function handleEdit() {
    setIsBusy(true)
    const { success } = await putData(
      '/salaryperiod/update',
      {
        ...selectedSalaryPeriod,
      },
      false
    )
    if (success) {
      showNotification(
        'Update Salary Period',
        'success',
      )
      setIsBusy(false)
      setMode(null)
      setSelectedSalaryPeriod(null)
      loadTableData()
    }
  }
  return (
    <>
      <ModalTemplate
        modalMode={mode}
        modalTitle={
          mode === 'add'
            ? localize('createSalaryPeriod')
            : localize('updateSalaryPeriod')
        }
        actionText={mode === 'add' ? 'create' : 'update'}
        handleAction={mode === 'add' ? handleSubmit : handleEdit}
        handleClose={() => {
          handleClose()
        }}
        modalClassName="modal-xl"
      >
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div className="row mb-3 align-items-center">
              <div className="d-flex col-4 align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('postingPeriod')}
                </label>
                <PostingPeriodLookup
                  displayValue={selectedSalaryPeriod?.postingPeriodDescription}
                  onSelect={r => {
                    setSelectedSalaryPeriod({
                      ...selectedSalaryPeriod,
                      postingPeriodId: r.id,
                      postingPeriodDescription: r.description,
                      postingPeriodDurationEndDate: r.durationEndDate,
                    })
                  }}
                />
              </div>
              <div className="d-flex col-4 align-items-center">
                <label className=" col-3 text-capitalize">
                  {localize('referenceMonth')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  className={'ms-3'}
                  enumsKey={'Month'}
                  value={selectedSalaryPeriod?.month}
                  onChange={e => {
                    setSelectedSalaryPeriod({
                      ...selectedSalaryPeriod,
                      month: e.value,
                    })
                  }}
                />
              </div>
              <div className="d-flex col-4 align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('valueDate')}
                </label>
                <CustomDatePicker
                  defaultDate={selectedSalaryPeriod?.valueDate}
                  name={'valueDate'}
                  onDateChange={date => {
                    setSelectedSalaryPeriod({
                      ...selectedSalaryPeriod,
                      valueDate: date,
                    })
                  }}
                />
              </div>
            </div>

            <div className="row align-items-center">
              <div className="d-flex col-4 align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('employeeType')}
                </label>
                <EmployeeTypeLookup
                  displayValue={selectedSalaryPeriod?.employeeTypeDescription}
                  onSelect={r => {
                    setSelectedSalaryPeriod({
                      ...selectedSalaryPeriod,
                      branchId: r.branchId,
                      employeeTypeId: r.id,
                      employeeTypeDescription: r.description,
                      employeeTypeChartOfAccountId: r.chartOfAccountId,
                      employeeTypeCategoryDescription: r.categoryDescription,
                      employeeTypeExecutePayoutStandingOrders:
                        r.executePayoutStandingOrders,
                      employeeTypeExecuteAdhocStandingOrders:
                        r.executeAdHocStandingOrders,
                      employeeTypeRecoverIndefiniteCharges:
                        r.recoverIndefiniteCharges,
                      employeeTypeConsolidatePostings: r.consolidatePostings,
                      employeeTypeCategory: r.category,
                    })
                  }}
                />
              </div>
              <div className="d-flex col-8 align-items-center">
                <label className="text-capitalize col-1 me-2">
                  {localize('remarks')}
                </label>
                <input
                  value={selectedSalaryPeriod?.remarks}
                  className="form-control ms-5"
                  onChange={e => {
                    setSelectedSalaryPeriod({
                      ...selectedSalaryPeriod,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ModalTemplate>
    </>
  )
}

export default CreatePeriods
