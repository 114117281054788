/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import SavingsProductLookup from '../../../../../Components/Lookup/SavingsProductLookup'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import { salaryHeadType } from '../../../../../Global/enumeration'
import { LoanProductLookup } from '../../../../../Components/Lookup/LoanProductLookup'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'

const CreateHeads = ({
  handleClose,
  handleAction,
  mode,
  heads,
  setHeads,
  isLoading,
}) => {
  const [selectedHeadType, setSelectedHeadType] = useState(null)
  const [savingsProduct, setSavingsProduct] = useState({})
  const [loanProduct, setLoanProduct] = useState({})
  const [investmentProduct, setInvestmentProduct] = useState({})
  const [selectedProduct, setSelectedProduct] = useState({})

  useEffect(() => {
    handleSelectHeadType(selectedProduct)
  }, [savingsProduct, loanProduct, investmentProduct])

  const handleSelectHeadType = x => {
    switch (x.value) {
      case Object.keys(salaryHeadType)[5]:
        setSelectedHeadType(
          <LoanProductLookup
            className="form-select"
            displayValue={
              loanProduct.chartOfAccountAccountName ||
              heads.productChartOfAccountName
            }
            onSelect={e =>
              setLoanProduct({
                ...loanProduct,
                product: e,
                customerAccountTargetProductId: e?.id,
                chartOfAccountAccountName: e?.description,
                chartOfAccountId: e?.chartOfAccountId,
              })
            }
          />
        )
        setHeads({
          ...heads,
          type: selectedProduct?.value,
          customerAccountTargetProductId:
            loanProduct?.customerAccountTargetProductId,
          chartOfAccountId: loanProduct?.chartOfAccountId,
        })
        break
      case Object.keys(salaryHeadType)[6]:
        setSelectedHeadType(
          <InvestmentProductLookup
            className="form-select"
            displayValue={
              investmentProduct?.chartOfAccountAccountName ||
              heads?.productChartOfAccountName
            }
            onSelect={e =>
              setInvestmentProduct({
                ...investmentProduct,
                customerAccountTargetProductId: e?.id,
                chartOfAccountAccountName: e?.description,
                chartOfAccountId: e?.chartOfAccountId,
              })
            }
          />
        )
        setHeads({
          ...heads,
          type: selectedProduct?.value,
          customerAccountTargetProductId:
            investmentProduct?.customerAccountTargetProductId,
          chartOfAccountId: investmentProduct?.chartOfAccountId,
        })
        break
      default:
        setSelectedHeadType(
          <SavingsProductLookup
            className="form-select"
            displayValue={
              savingsProduct.chartOfAccountAccountName ||
              heads.productChartOfAccountName
            }
            onSelect={e =>
              setSavingsProduct({
                ...savingsProduct,
                customerAccountTargetProductId: e?.id,
                chartOfAccountAccountName: e?.description,
                chartOfAccountId: e?.chartOfAccountId,
              })
            }
          />
        )
        setHeads({
          ...heads,
          type: selectedProduct?.value,
          customerAccountTargetProductId:
            savingsProduct?.customerAccountTargetProductId,
          chartOfAccountId: savingsProduct?.chartOfAccountId,
        })
    }
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalTitle={
        mode === 'add' ? localize('salaryHeads') : localize('salaryHeads')
      }
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            onCheck={x => {
              x
                ? setHeads({
                    ...heads,
                    isOneOff: true,
                  })
                : setHeads({
                    ...heads,
                    isOneOff: false,
                  })
            }}
            checked={heads?.isOneOff}
            id="isOneOff"
          />
          <label htmlFor="isOneOff" className="text-capitalize">
            {localize('isOneOff') + '?'}
          </label>
        </div>
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row g-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="type" className="text-capitalize col-2">
                  {localize('type')}
                </label>
                {mode === 'add' ? (
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="SalaryHeadType"
                    value={selectedProduct?.value}
                    onChange={e => {
                      setSelectedProduct(e)
                      handleSelectHeadType(e)
                    }}
                    sort
                  />
                ) : (
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    value={heads?.typeDescription}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="product" className="text-capitalize col-2">
                  {localize('product')}
                </label>
                {selectedHeadType === null ? (
                  <SavingsProductLookup
                    className="form-select"
                    displayValue={savingsProduct?.chartOfAccountAccountName}
                    onSelect={e =>
                      setSavingsProduct({
                        ...savingsProduct,
                        customerAccountTargetProductId: e?.id,
                        chartOfAccountAccountName: e?.description,
                        chartOfAccountId: e?.chartOfAccountId,
                      })
                    }
                  />
                ) : (
                  selectedHeadType
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="name" className="text-capitalize col-2">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={heads.description}
                  onChange={e =>
                    setHeads({
                      ...heads,
                      description: e?.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <label htmlFor="lookup" className="text-capitalize col-2">
                  {localize('gLAccount')}
                </label>
                <ChartOfAccountsLookup
                  displayValue={heads?.chartOfAccountName}
                  onSelect={e => {
                    setHeads({
                      ...heads,
                      chartOfAccountName: e?.name,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}
export default CreateHeads
