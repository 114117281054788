/** @format */

import localize from '../../../../../Global/Localize'
import { formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import React, { useState } from 'react'
import swal from 'sweetalert2'
import { postData } from '../../../../../Helpers/webApi'
import Loader from '../../../../../Components/Loaders/Loader'

export const GroupMembersEntriesAddMode = ({ entries, handleOnRemove }) => {
  const columns = [
    {
      label: localize('memberType'),
      template: r => <>{r.memberTypeDescription}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.groupMemberName}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r.designationDescription}</>,
    },
    { label: localize('loanCycle'), template: r => <>{r.loanCycle}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r.customerMembershipStatusDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('dutyStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    { label: localize('family'), template: r => <>{r.family}</> },
    { label: localize('clan'), template: r => <>{r.clan}</> },
    { label: localize('ethnicGroup'), template: r => <>{r.ethnicGroup}</> },
    {
      label: localize('genderDescription'),
      template: r => <>{r.ethnicGroup}</>,
    },
    {
      label: localize('relationshipDescription'),
      template: r => <>{r.relationshipDescription}</>,
    },
    {
      label: localize('nationalityDescription'),
      template: r => <>{r.nationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.identityCardSerialNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: localize('street'), template: r => <>{r.addressStreet}</> },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internet'),
      template: r => <>{r.addressInternet}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleTable
        contextMenu={e => (
          <>
            <div
              onClick={() => {
                handleOnRemove(e)
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash"></i>
              {localize('remove')}
            </div>
          </>
        )}
        selectables
        data={entries}
        columns={columns}
      />
    </>
  )
}

export const GroupMembersEntriesEditMode = ({ entries, onRemove }) => {
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([])
  const [loading, setLoading] = useState(false)

  const handleOnRemove = async () => {
    if (selectedGroupMembers.length === 0) {
      swal.fire(
        'Edit Micro Credit Group',
        'Please Select At Least One Entry',
        'warning',
      )
      return
    }

    setLoading(true)
    const { success, data } = await postData(
      '/MicroCreditGroup/remove-micro-credit-group-members',
      {
        MicroCreditGroupMember: selectedGroupMembers,
      },
      false,
      null,
    )

    if (success) {
      swal.fire(
        'Remove Micro Credit Group Member',
        'Removed Successfully',
        'success',
      )
      onRemove()
    }
    setLoading(false)
  }

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <Checkbox
          checked={selectedGroupMembers.find(x => x.id === r.id)}
          onCheck={c => {
            if (c) {
              setSelectedGroupMembers([...selectedGroupMembers, r])
            } else {
              setSelectedGroupMembers(
                selectedGroupMembers.filter(s => s.id !== r.id),
              )
            }
          }}
        />
      ),
    },
    {
      label: localize('memberType'),
      template: r => <>{r.memberTypeDescription}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r.groupMemberName}</>,
    },
    {
      label: localize('designation'),
      template: r => <>{r.designationDescription}</>,
    },
    { label: localize('loanCycle'), template: r => <>{r.loanCycle}</> },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r.customerMembershipStatusDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedCustomerSerialNumber}</>,
    },
    {
      label: localize('dutyStation'),
      template: r => <>{r.customerDutyStationDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.customerAddressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r.customerAddressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r.customerAddressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.customerAddressMobileLine}</>,
    },
    { label: localize('fullName'), template: r => <>{r.fullName}</> },
    { label: localize('family'), template: r => <>{r.family}</> },
    { label: localize('clan'), template: r => <>{r.clan}</> },
    { label: localize('ethnicGroup'), template: r => <>{r.ethnicGroup}</> },
    {
      label: localize('genderDescription'),
      template: r => <>{r.ethnicGroup}</>,
    },
    {
      label: localize('relationshipDescription'),
      template: r => <>{r.relationshipDescription}</>,
    },
    {
      label: localize('nationalityDescription'),
      template: r => <>{r.nationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    {
      label: localize('identificationNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r.identityCardSerialNumber}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r.addressAddressLine2}</>,
    },
    { label: localize('street'), template: r => <>{r.addressStreet}</> },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addressCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
    {
      label: localize('internet'),
      template: r => <>{r.addressInternet}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SimpleTable selectables data={entries} columns={columns} />

          <div className="d-flex align-content-end justify-content-end">
            <div className="d-flex align-items-center me-5">
              <button className="btn btn-success" onClick={handleOnRemove}>
                {localize('remove')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}
