/** @format */

import localize from '../../../../../../Global/Localize'
import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import { downloadReceipt } from '../../../../../../Helpers/blobs'
import { formatCurrency } from '../../../../../../Helpers/formatters'
import { GeneralLedgerTransactionType } from '../../../../../../Helpers/constants'

export const SundrySummary = ({
  transactionModel,
  setTransactionModel,
  setLoading,
  generalTransactionType,
}) => {
  const [tellerSummaries, setTellerSummaries] = useState(null)

  const postTransaction = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Teller/perform-sundry-transaction',
      {
        ...transactionModel,
      },
      false,
      null
    )
    if (success) {
      if (data.result) {
        await Swal.fire({
          icon: 'success',
          title: 'Operation Successful',
          text: 'Teller Sundry Receipts/Payments',
        }).then(result => {
          if (result.isConfirmed) {
            downloadReceipt(data.message, 'receipt.txt')
            setTransactionModel({
              GeneralTransactionType: GeneralLedgerTransactionType.CASH_PAYMENT,
            })
          }
        })
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchTellerSundrySummaries()
  }, [generalTransactionType])

  const fetchTellerSundrySummaries = async () => {
    const { success, data } = await getData(
      '/lookup/Teller/get-teller-sundry-summary',
      false,
      null
    )
    if (success) {
      setTellerSummaries(data)
    }
  }
  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 120,
          }}
        >
          {localize('summary')}
        </div>
        <div className="d-flex align-items-center mt-3 mb-3">
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('agencyBalance')}({localize('westernUnion')}):
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                tellerSummaries?.previousWesternUnionAgencyBalance,
                2,
                2,
                false
              )}
            />
          </div>
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('agencyBalance')}({localize('moneyGram')}):
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                tellerSummaries?.previousMoneyGramAgencyBalance,
                2,
                2,
                false
              )}
            />
          </div>
          <div className="d-flex align-items-center gap-2 col">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('agencyBalance')}({localize('mpesa')}):
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                tellerSummaries?.previousMpesaAgencyBalance,
                2,
                2,
                false
              )}
            />
          </div>
        </div>
        <div className="d-flex align-items-center  mt-3 mb-3">
          <div className="d-flex align-items-center gap-2 col-4">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('agencyBalance')}({localize('coopBank')}):
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                tellerSummaries?.previousCoopBankAgencyBalance,
                2,
                2,
                false
              )}
            />
          </div>
          <div className="d-flex align-items-center gap-2 col-4">
            <label htmlFor="" className="text-capitalize ms-2 me-2 col-4">
              {localize('tellerBalance')}:
            </label>
            <input
              className="form-control"
              disabled
              value={formatCurrency(
                tellerSummaries?.previousTellerBalance,
                2,
                2,
                false
              )}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex align-content-center justify-content-end mt-2 bg-light p-2 m-1 mb-2"
        style={{ borderRadius: 5 }}
      >
        <div className="d-flex align-items-center me-5">
          <button className="btn btn-success" onClick={postTransaction}>
            {localize('update')}
          </button>
        </div>
      </div>
    </>
  )
}
