/** @format */

import React from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import CustomDatePicker from '../../../../Components/Date/custom-date-picker'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'

const ViewRiskRegister = ({
  riskRegister,
  setRiskRegister,
  handleClose,
  mode,
}) => {
  /** @format */

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={localize('viewRiskRegister')}
      actionText={localize('view')}
      hideUpdate
      handleClose={() => {
        handleClose()
      }}
      modalClassName="modal-xl"
    >
      <div className="row d-flex align-items-center mb-3">
        <div className="col-6 d-flex align-items-center">
          <label className="text-capitalize col-2">
            {localize('description')}
          </label>
          <input
            type="text"
            className="form-control"
            value={riskRegister?.description}
            placeholder="Enter Text..."
            onChange={e => {
              setRiskRegister({
                ...riskRegister,
                description: e.target.value,
              })
            }}
            disabled
          />
        </div>
        <div className="col-6 d-flex align-items-center">
          <label className="text-capitalize col-2">{localize('owner')}</label>
          <input
            type="text"
            className="form-control"
            value={riskRegister?.owner}
            placeholder="Enter Text..."
            onChange={e => {
              setRiskRegister({
                ...riskRegister,
                owner: e.target.value,
              })
            }}
            disabled
          />
        </div>
      </div>
      <div className="row d-flex align-items-cente mb-3">
        <div className="col-6 d-flex align-items-center">
          <label className="text-capitalize col-2">
            {localize('occurrenceDate')}
          </label>
          <CustomDatePicker
            name="occurrenceDate"
            defaultDate={riskRegister?.occurrenceDate}
            onDateChange={e => {
              setRiskRegister({
                ...riskRegister,
                occurrenceDate: e,
              })
            }}
            disabled
          />
        </div>
        <div className="col-6 d-flex align-items-center">
          <label className="text-capitalize col-2">
            {localize('frequency')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="RiskLevel"
            value={riskRegister?.frequency}
            onChange={e => {
              setRiskRegister({
                ...riskRegister,
                frequency: e.value,
                frequencyDescription: e.label,
              })
            }}
            disabled={mode === 'view'}
          />
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-6 d-flex align-items-center">
          <label className="text-capitalize col-2">{localize('impact')}</label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="RiskLevel"
            value={riskRegister?.impact}
            onChange={e => {
              setRiskRegister({
                ...riskRegister,
                impact: e.value,
                impactDescription: e.label,
              })
            }}
            disabled
          />
        </div>
        <div className="col-6 d-flex align-items-center">
          <label className="text-capitalize col-2">
            {localize('severity')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="RiskLevel"
            value={riskRegister?.severity}
            onChange={e => {
              setRiskRegister({
                ...riskRegister,
                severity: e.value,
                severityDescription: e.label,
              })
            }}
            disabled
          />
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-6 text-capitalize d-flex">
          <label className="text-capitalize col-2">
            {localize('modifiedDate')}
          </label>
          <CustomDatePicker
            name="modifiedDate"
            defaultDate={riskRegister?.modifiedDate}
            disabled
          />
        </div>
        <div className="col-6 text-capitalize d-flex">
          <label className="text-capitalize col-2">
            {localize('modifiedBy')}
          </label>
          <input
            type="text"
            className="form-control"
            value={riskRegister?.modifiedBy}
            disabled
          />
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-6 text-capitalize d-flex">
          <label className="text-capitalize col-2">
            {localize('createdDate')}
          </label>
          <CustomDatePicker
            name="createdDate"
            defaultDate={riskRegister?.createdDate}
            disabled
          />
        </div>
        <div className="col-6 text-capitalize d-flex">
          <label className="text-capitalize col-2">
            {localize('createdBy')}
          </label>
          <input
            type="text"
            className="form-control"
            value={riskRegister?.createdBy}
            disabled
          />
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-12 text-capitalize d-flex">
          <label className="text-capitalize col-1">
            {localize('mitigation')}
          </label>
          <textarea
            className="form-control"
            placeholder="Enter Text..."
            rows="2"
            value={riskRegister?.mitigation}
            onChange={e => {
              setRiskRegister({
                ...riskRegister,
                mitigation: e.target.value,
              })
            }}
            disabled
          />
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-12 text-capitalize d-flex">
          <label className="text-capitalize col-1">{localize('remarks')}</label>
          <textarea
            className="form-control"
            placeholder="Enter Text..."
            rows="2"
            value={riskRegister?.remarks}
            onChange={e => {
              setRiskRegister({
                ...riskRegister,
                remarks: e.target.value,
              })
            }}
            disabled
          />
        </div>
      </div>
    </ModalTemplate>
  )
}

export default ViewRiskRegister
