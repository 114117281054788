/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import SimpleTableWithSearchAndPaging from '../../../Components/Tables/SimpleTableWithSearchAndPaging'
import { getData } from '../../../Helpers/webApi'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import Tab from '../../../Components/Tabs/Tab'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import SimpleTable from '../../../Components/Tables/SimpleTable'
import EditSavings from './EditSavings'
import Loader from '../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import AddNewButton from '../../../Components/Buttons/AddNewButton/AddNewButton'
import ViewAuditLogs from '../../../Components/ReUsables/ViewAuditLogs/ViewAuditLogs'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../Components/Tables/Pager'
import EditSavingsProduct from './SavingsProducts/EditSavingsProduct'
import NonBlockingLoader from '../../../Components/Loaders/NonBlockingLoader'

function Savings() {
  const [params, setParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    productSection: 0,
  })
  const [modalMode, setModalMode] = useState(null)
  const [selectedSaving, setSelectedSaving] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [savings, setSavings] = useState(null)
  const [viewedSaving, setViewedSaving] = useState(null)
  const [chargeType, setChargeType] = useState(0)
  const [extras, setExtras] = useState({ wellKnownCharges: [], exemptions: [] })
  const [extrasWaiting, setExtrasWaiting] = useState(false)
  const [commissions, setCommissions] = useState([])
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const columns = [
    {
      label: localize('code'),
      template: r => <>{r?.paddedCode}</>,
      sortBy: 'paddedCode',
    },
    {
      label: localize('billReferenceNumber'),
      template: r => <>{r?.billReferenceNumber}</>,
      sortBy: 'billReferenceNumber',
    },
    {
      label: localize('name'),
      template: r => <>{r?.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('minimumBalance'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.minimumBalance)}</>,
    },
    {
      label: localize('operatingBalance'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.operatingBalance)}</>,
    },
    {
      label: localize('annualPercentageYield'),
      template: r => <>{r?.annualPercentageYield}%</>,
    },
    {
      label: localize('maximumAllowedWithdrawal'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.maximumAllowedWithdrawal)}</>,
    },
    {
      label: localize('maximumAllowedDeposit'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.maximumAllowedDeposit)}</>,
    },
    {
      label: `${localize('withdrawalNotice')} (${localize('days')})`,
      template: r => <>{r?.withdrawalNoticePeriod}</>,
    },
    {
      label: localize('withdrawalNoticeAmount'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.withdrawalNoticeAmount)}</>,
    },
    {
      label: `${localize('withdrawalInterval')} (${localize('days')})`,
      template: r => <>{r?.withrawalInterval}</>,
    },
    {
      label: localize('maximumOverdraftLimit'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.maximumOverdraftLimit)}</>,
    },
    {
      label: localize('gLAccountName'),
      template: r => <>{r?.chartOfAccountName}</>,
    },
    {
      label: localize('GLAccountCostDistributionRule'),
      template: r => <>{r?.chartOfAccountCostDistributionRuleDescription}</>,
    },
    {
      label: localize('interestExpenseGLAccountName'),
      template: r => <>{r?.interestExpenseChartOfAccountName}</>,
    },
    {
      label: localize('interestExpenseGLAccountCostDistributionRule'),
      template: r => (
        <>{r?.interestExpenseChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    {
      label: localize('interestPayableGLAccountName'),
      template: r => <>{r?.interestPayableChartOfAccountName}</>,
    },
    {
      label: localize('interestPayableGLAccountCostDistributionRule'),
      template: r => (
        <>{r?.interestPayableChartOfAccountCostDistributionRuleDescription}</>
      ),
    },
    { label: localize('recoveryPriority'), template: r => <>{r.priority}</> },
    {
      label: localize('isDefault') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isDefault.toString()}</span>
      ),
    },
    {
      label: localize('automateLedgerFeeCalculation') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.automateLedgerFeeCalculation.toString()}
        </span>
      ),
    },
    {
      label: localize('throttleOverTheCounterWithdrawals') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.throttleOverTheCounterWithdrawals.toString()}
        </span>
      ),
    },
    {
      label: localize('throttleMBankingWithdrawals') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.throttleBankToMobileWithdrawals.toString()}
        </span>
      ),
    },
    {
      label: localize('throttleAgencyBankingWithdrawals') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.throttleAgencyBankingWithdrawals.toString()}
        </span>
      ),
    },
    {
      label: localize('concealMBankingListing') + '?',
      template: r => (
        <span className="text-capitalize">
          {r?.concealBankToMobileListing.toString()}
        </span>
      ),
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked.toString()}</span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
      sortBy: 'modifiedDate',
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
      sortBy: 'createdDate',
    },
  ]

  const exColumns = [
    { label: localize('branch'), template: r => <>{r?.branchDescription}</> },
    {
      label: localize('minimumBalance'),
      template: r => <>{formatCurrency(r?.minimumBalance)}</>,
    },
    {
      label: localize('operatingBalance'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.operatingBalance)}</>,
    },
    {
      label: localize('annualPercentageYield'),
      template: r => <>{r?.annualPercentageYield}%</>,
    },
    {
      label: localize('maximumWithdrawal'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.maximumAllowedWithdrawal)}</>,
    },
    {
      label: localize('maximumAllowedDeposit'),
      headerTextAlign: 'right',
      template: r => <>{formatCurrency(r?.maximumAllowedDeposit)}</>,
    },
    {
      label: `${localize('withdrawalNoticePeriod')} (${localize('days')})`,
      template: r => <>{r?.withdrawalNoticePeriod}</>,
    },
    {
      label: localize('withdrawalNoticeAmount'),
      template: r => <>{r?.withdrawalNoticeAmount}</>,
    },
    {
      label: `${localize('withdrawalInterval')} (${localize('days')})`,
      template: r => <>{r?.withrawalInterval}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const tabItems = [
    {
      label: localize('wellKnownCharges'),
      item: (
        <>
          <div className="d-flex align-items-center">
            <label className="text-capitalize" htmlFor="savingsCharType">
              {localize('chargeType')}
            </label>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="SavingsProductKnownChargeType"
              onChange={e => setChargeType(e.value)}
            />
          </div>
          <div
            className="p-2 mt-2"
            style={{ maxHeight: '60vh', overflowY: 'scroll' }}
          >
            {commissions.map(c => (
              <div className="d-flex align-items-center">
                <div style={{ transform: 'translateY(5px)', marginRight: 5 }}>
                  <Checkbox
                    checked={
                      extras.wellKnownCharges &&
                      !!extras?.wellKnownCharges.find(e => e.id === c.id)
                    }
                    disabled={true}
                  />
                </div>
                <span>{c.description}</span>
              </div>
            ))}
          </div>
        </>
      ),
    },
    {
      label: localize('exemptions'),
      item: (
        <>
          <SimpleTable
            columns={exColumns}
            data={extras.exemptions}
            onSelect={() => {}}
            loading={extrasWaiting}
          />
        </>
      ),
    },
    {
      label: localize('flags'),
      item: (
        <>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editSPIsDefault"
                  checked={viewedSaving?.isDefault}
                  disabled
                />
                <label htmlFor="editSPIsDefault" className="text-capitalize">
                  {localize('isDefault')}?
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editSPIsLockedFlag"
                  checked={viewedSaving?.isLocked}
                  disabled
                />
                <label htmlFor="editSPIsLockedFlag" className="text-capitalize">
                  {localize('isLocked')}?
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editThrottleABWithFlag"
                  checked={viewedSaving?.throttleAgencyBankingWithdrawals}
                  disabled
                />
                <label
                  htmlFor="editThrottleABWithFlag"
                  className="text-capitalize"
                >
                  {localize('throttleAgencyBankingWithdrawals')}?
                </label>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editSPALFCalculation"
                  checked={viewedSaving?.automateLedgerFeeCalculation}
                  disabled
                />
                <label
                  htmlFor="editSPALFCalculation"
                  className="text-capitalize"
                >
                  {localize('automateLedgerFeeCalculation')}?
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editSuppressAuthoriFlag"
                  checked={
                    viewedSaving?.suppressAuthorizationForCashWithdrawalByArtificalEntity
                  }
                  disabled
                />
                <label
                  htmlFor="editSuppressAuthoriFlag"
                  className="text-capitalize"
                >
                  {localize('suppressAuthorization')} (
                  {localize('wdr. by artificial entity')})?
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editThrottleOTCWithdrawalsFlag"
                  checked={viewedSaving?.throttleOverTheCounterWithdrawals}
                  disabled
                />
                <label
                  htmlFor="editThrottleOTCWithdrawalsFlag"
                  className="text-capitalize"
                >
                  {localize('throttleOverTheCounterWithdrawals')}?
                </label>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editPermitMultipleCAFlag"
                  checked={viewedSaving?.permitMultipleCustomerAccounts}
                  disabled
                />
                <label
                  htmlFor="editPermitMultipleCAFlag"
                  className="text-capitalize"
                >
                  {localize('permitMultipleCustomerAccounts')}?
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editConcealMBLimitFlag"
                  checked={viewedSaving?.concealBankToMobileListing}
                  disabled
                />
                <label
                  htmlFor="editConcealMBLimitFlag"
                  className="text-capitalize"
                >
                  {localize('concealMobileBankingLimit')}?
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  id="editThrottleMBWIthDRFlag"
                  checked={viewedSaving?.throttleBankToMobileWithdrawals}
                  disabled
                />
                <label
                  htmlFor="editThrottleMBWIthDRFlag"
                  className="text-capitalize"
                >
                  {localize('throttleMBankingWithdrawals')}?
                </label>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    loadData()
    loadCommissions()
    // eslint-disable-next-line
  }, [params?.pageSize, params?.pageIndex, refresh])

  async function loadCommissions() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/commission/find-commissions',
      false,
      null
    )
    if (success) {
      setIsBusy(false)
      setCommissions(
        data.sort((a, b) => a.description.localeCompare(b.description))
      )
    } else {
      setIsBusy(false)
    }
  }

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/savingsProduct/find-savings-product-by-product-section-and-filter-in-page',
      params,
      false
    )
    if (success) {
      setIsBusy(false)
      setSavings(data)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    loadExtras()
  }, [viewedSaving, chargeType])

  async function loadExtras() {
    setIsBusy(true)
    setExtrasWaiting(true)
    const commissions = await getData(
      '/savingsProduct/find-commissions-by-savings-product-id',
      {
        savingsProductId: viewedSaving?.id,
        savingsPRoductKnownChargeType: chargeType,
      },
      false
    )

    if (commissions.success) {
      setIsBusy(false)
      setExtrasWaiting(false)
      setExtras({ ...extras, wellKnownCharges: commissions.data.result })
    } else {
      setIsBusy(false)
    }

    const exemptions = await getData(
      '/savingsProduct/find-savings-product-exemption-by-savings-product-id',
      { savingsProductId: viewedSaving?.id },
      false
    )

    if (exemptions.success) {
      setIsBusy(false)
      setExtrasWaiting(false)
      //   setExtras({ ...extras, exemptions: exemptions.result }) Todo: Remove comments @Job
    } else {
      setIsBusy(false)
    }
  }

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setModalMode('auditLogs')
  }

  const closeModal = () => {
    setModalMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('financialProducts'),
          localize('savingsProducts'),
        ]}
      />
      <div className="card">
        <div className="card-body bg-white" style={{ position: 'relative' }}>
          <div className="mb-3">
            <AddNewButton
              handleClickAddButton={() => {
                setModalMode('add')
                setSelectedSaving(null)
              }}
            />
          </div>
          <div className="d-flex ps-3 pb-3 align-items-center justify-content-between">
            <RecordsPerPage reqParams={params} setReqParams={setParams} />
            <div className="d-flex align-items-center gap-2">
              <label htmlFor="" className="text-capitalize">
                {localize('productSection')}
              </label>
              <EnumsServerCombo
                value={params?.productSection}
                onChange={p =>
                  setParams({ ...params, productSection: p.value })
                }
                enumsKey="productSection"
              />
            </div>
            <div className="d-flex align-items-center"></div>
            <form
              onSubmit={e => {
                e.preventDefault()
                setParams({ ...params, pageIndex: 0 })
                setRefresh(!refresh)
              }}
              className=" d-flex gap-2 align-items-center"
            >
              <label htmlFor="" className="text-capitalize">
                {localize('search')}
              </label>
              <input
                value={params?.filterText}
                onChange={e =>
                  setParams({
                    ...params,
                    filterText: e?.target?.value,
                  })
                }
                type="search"
                className="form-control"
              />
              <button className="btn btn-primary">
                <i className="uil uil-filter"></i>
              </button>
            </form>
          </div>
          <SimpleTable
            columns={columns}
            data={savings?.pageCollection || []}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item"
                  onClick={() => {
                    setSelectedSaving(r)
                    setModalMode('view')
                  }}
                >
                  <i className="uil uil-eye"></i>
                  <span className="text-capitalize">{localize('view')}</span>
                </div>
                <div
                  className="ctxt-menu-item"
                  onClick={() => {
                    setSelectedSaving(r)
                    setModalMode('edit')
                  }}
                >
                  <i className="uil uil-edit-alt"></i>
                  <span className="text-capitalize">{localize('edit')}</span>
                </div>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleViewAuditLogs(r)
                  }}
                >
                  <i className="uil uil-server"></i>
                  <span>{localize('viewAuditLog')}</span>
                </div>
              </>
            )}
          />
          <div className="pt-2">
            <Pager
              itemsCount={savings?.itemsCount}
              pageSize={params?.pageSize}
              pageIndex={params?.pageIndex}
              onPageChange={p => setParams({ ...params, pageIndex: p })}
            />
          </div>
          {/* <SimpleTableWithSearchAndPaging
                onSelect={() => {}}
                data={savings || []}
                columns={columns}
                onSearch={s => setParams({ ...params, filterText: s })}
                searchString={params?.filterText}
                onPageNavigate={p => setParams({ ...params, pageIndex: p })}
                currentPage={params.pageIndex}
                pageSize={params.pageSize}
                onPageSizeChanged={ps => setParams({ ...params, pageSize: ps })}
                isBusy={isBusy}
                canClickOnce
                onClickOnce={r => {
                  setViewedSaving(r)
                }}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item"
                      onClick={() => {
                        setSelectedSaving(r)
                        setModalMode('edit')
                      }}
                    >
                      <i className="uil uil-edit-alt"></i>
                      <span className="text-capitalize">
                        {localize('edit')}
                      </span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleViewAuditLogs(r)
                      }}
                    >
                      <i className="uil uil-server"></i>
                      <span>{localize('viewAuditLog')}</span>
                    </div>
                  </>
                )}
                showIncludeBalances={false}
                showUpdateDepth={false}
              /> */}
          {isBusy && <NonBlockingLoader />}
        </div>
      </div>
      {/* <div className="card">
        {viewedSaving && (
          <div className="card-body bg-white">
            {extrasWaiting ? (
              <span>{localize('pleaseWait')}...</span>
            ) : (
              <Tab tabItems={tabItems} />
            )}
          </div>
        )}
      </div> */}

      {/* {(modalMode === 'add' || modalMode === 'edit') && (
        <EditSavings
          editMode={modalMode === 'edit' || modalMode === 'add'}
          close={closeModal}
          savingsProduct={selectedSaving}
          refresh={() => setRefresh(!refresh)}
        />
      )} */}
      {modalMode && (
        <EditSavingsProduct
          readonly={modalMode === 'view'}
          refreshData={() => setRefresh(!refresh)}
          mode={modalMode}
          product={modalMode === 'add' ? null : selectedSaving}
          close={() => {
            setModalMode(null)
            setSelectedSaving(null)
          }}
          setProduct={p => setSelectedSaving(p)}
          changeMode={m => setModalMode(m)}
        />
      )}

      {modalMode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={closeModal}
          mode={modalMode}
          data={auditLogsDetails}
          title={auditLogsDetails?.description}
        />
      )}
    </>
  )
}

export default Savings
