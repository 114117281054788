/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../Global/Localize'
import { months } from '../../../Global/enumeration'
import { formatCurrency, parseDate } from '../../../Helpers/formatters'
import { getData, putData } from '../../../Helpers/webApi'
import CustomDatePicker from '../../Date/custom-date-picker'
import { MaskPercentage } from '../../InputMasks/MaskPercentage'
import ModalTemplate from '../../ModalTemplate/ModalTemplate'
import Tab from '../../Tabs/Tab'
import StandingOrderExecutionHistoryTab from './StandingOrderExecutionHistoryTab/StandingOrderExecutionHistoryTab'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import Loader from '../../Loaders/Loader'
import { MaskNumber } from '../../InputMasks/MaskNumber'
import { showNotification } from '../../../Helpers/Notification/ShowNotification'

const getValueOfCurrentMonth = () => {
  const currentMonth = new Date().getMonth() + 1
  return currentMonth
}

function AdHocLoanInterestCapitalization({
  modalMode,
  handleClose,
  data,
  loanAccount,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [isBusyLoadingValueDate, setIsBusyLoadingValueDate] = useState(false)
  const [customerData, setCustomerData] = useState({})
  const [interestCapitalization, setInterestCapitalization] = useState({
    month: getValueOfCurrentMonth(),
    adhocInterestCapitalizationBalance: '',
    interestCapitalizationMonths: 1,
  })
  const [valueDate, setValueDate] = useState(null)
  const [updateDate, setUpdateDate] = useState(false)
  const [standingOrders, setStandingOrders] = useState([])

  const fetchCustomerData = async () => {
    setIsBusy(true)
    const url = '/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: loanAccount?.id,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerData({
        ...data,
        adhocInterestCapitalizationBalance:
          customerData.adhocInterestCapitalizationBalance,
      })
      setInterestCapitalization({
        ...interestCapitalization,
        adhocInterestCapitalizationBalance: Math.abs(
          data.adhocInterestCapitalizationBalance
        ),
        annualPercentageRate:
          loanAccount?.customerAccountTypeTargetProductLoanInterestCapitalizePerLoanCaseParameters &&
          loanAccount?.loanCaseLoanInterestAnnualPercentageRate
            ? loanAccount?.loanCaseLoanInterestAnnualPercentageRate
            : loanAccount?.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate,
      })
    }

    setIsBusy(false)
  }

  const fetchLoanStandingOrders = async () => {
    setIsBusy(true)
    const url = '/StandingOrder/find-by-beneficiary-customer-account-id'

    const { success, data } = await getData(
      url,
      {
        beneficiaryCustomerAccountId: customerData?.id,
        includeProductDescription: false,
      },
      false
    )

    if (success && data?.result?.length > 0) {
      setStandingOrders(data?.result)
    }

    setIsBusy(false)
  }

  const tabItems = [
    {
      label: localize('standingOrderExecutionHistory'),
      item: (
        <StandingOrderExecutionHistoryTab
          standingOrderId={customerData?.standingOrderId}
        />
      ),
    },
  ]

  const handleSave = async () => {
    if (customerData?.adhocInterestCapitalizationBalance === 0)
      return showNotification(
        'Operation not allowed',
        'error',
        'Interest capitalization balance cannot be zero!'
      )

    setIsBusy(true)
    const url = '/RecurringBatch/capitalize-interest-by-customer-account'

    const { success } = await putData(
      url,

      {
        customerAccount: customerData,
        month: interestCapitalization?.month,
        interestCapitalizationMonths:
          interestCapitalization?.interestCapitalizationMonths,
        interestCapitalizationAnnualPercentageRate:
          interestCapitalization?.annualPercentageRate,
        valueDate: interestCapitalization?.valueDate,
      },
      false
    )

    if (success) {
      handleClose()
      showNotification('Ad hoc Loan Interest Capitalization', 'success')
    }

    setIsBusy(false)
  }

  const evaluateValueDate = async () => {
    setIsBusyLoadingValueDate(true)
    const url = `/PostingPeriod/evaluate-value-date-validity?valueDate=${valueDate}`
    const { success, data } = await putData(url, null, false)
    if (success && data.result) {
      setIsBusyLoadingValueDate(false)
      setUpdateDate(false)
      setInterestCapitalization({
        ...interestCapitalization,
        valueDate: parseDate(valueDate),
      })
    } else {
      setIsBusyLoadingValueDate(false)
      setValueDate(null)
      setUpdateDate(true)
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected posting month is closed',
      })
    }
  }

  const getStandingOrderId = () => {
    const standingOrder = standingOrders?.find(
      order =>
        order?.benefactorCustomerAccountCustomerId ===
        order?.beneficiaryCustomerAccountCustomerId
    )

    setCustomerData({
      ...customerData,
      standingOrderId: standingOrder?.id,
      standingOrderTrigger: standingOrder?.trigger,
      standingOrderDurationStartDate: standingOrder?.durationStartDate,
      standingOrderDurationEndDate: standingOrder?.durationEndDate,
      standingOrderPrincipal: standingOrder?.principal,
      standingOrderInterest: standingOrder?.interest,
    })
  }

  useEffect(() => {
    if (loanAccount?.id) {
      fetchCustomerData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanAccount?.id])

  useEffect(() => {
    if (valueDate) evaluateValueDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDate])

  useEffect(() => {
    if (customerData?.id) {
      fetchLoanStandingOrders()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData?.id])

  useEffect(() => {
    if (standingOrders?.length > 0) {
      getStandingOrderId()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standingOrders])

  return (
    <ModalTemplate
      actionText={'save'}
      modalMode={modalMode}
      handleAction={handleSave}
      handleClose={handleClose}
      modalTitle={localize('adhocLoanInterestCapitalization')}
      modalClassName="modal-xl"
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3 py-2">
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="fullAccountNumber"
                  className="col-4 text-capitalize"
                >
                  {localize('fullAccountNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.fullAccountNumber || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="accountStatus"
                  className="col-4 text-capitalize"
                >
                  {localize('accountStatus')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.statusDescription || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="remarks" className="col-4 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.remarks || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="productName" className="col-4 text-capitalize">
                  {localize('productName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.customerAccountTypeTargetProductDescription ||
                    ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="customerName" className="col-4 text-capitalize">
                  {localize('customerName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.customerFullName || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="customerType" className="col-4 text-capitalize">
                  {localize('customerType')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.customerMembershipClassCustomerTypeDescription ||
                    ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="customerMember#"
                  className="col-4 text-capitalize"
                >
                  {localize('customerMember#')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.paddedCustomerSerialNumber || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="employerName" className="col-4 text-capitalize">
                  {localize('employerName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.customerEmployerDescription || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="identification#"
                  className="col-4 text-capitalize"
                >
                  {localize('identification#')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.customerIdentificationNumber || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="memberNumber" className="col-4 text-capitalize">
                  {localize('memberNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.formattedCustomerMemberNumber || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="accountNumber"
                  className="col-4 text-capitalize"
                >
                  {localize('accountNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.formattedCustomerAccountNumber || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="personalFileNumber"
                  className="col-4 text-capitalize"
                >
                  {localize('personalFileNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.customerPersonalFileNumber || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="productSection"
                  className="col-4 text-capitalize"
                >
                  {localize('productSection')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    customerData?.customerAccountTypeTargetProductSectionDescription ||
                    ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="bookBalance" className="col-4 text-capitalize">
                  {localize('bookBalance')}
                </label>
                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={
                    formatCurrency(
                      customerData?.bookBalance,
                      null,
                      null,
                      false
                    ) || ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="principalBalance"
                  className="col-4 text-capitalize pe-2"
                >
                  {localize('principalBalance')}
                </label>
                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={
                    formatCurrency(
                      customerData?.principalBalance,
                      null,
                      null,
                      false
                    ) || ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="interestBalance"
                  className="col-4 text-capitalize"
                >
                  {localize('interestBalance')}
                </label>
                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={
                    formatCurrency(
                      customerData?.interestBalance,
                      null,
                      null,
                      false
                    ) || ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="capitalizationBalance"
                  className="col-4 text-capitalize"
                >
                  {localize('capitalizationBalance')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    interestCapitalization?.adhocInterestCapitalizationBalance
                  }
                  onMaskNumber={e => {
                    setInterestCapitalization({
                      ...interestCapitalization,
                      adhocInterestCapitalizationBalance: e.target.rawValue,
                    })

                    setCustomerData({
                      ...customerData,
                      adhocInterestCapitalizationBalance: e.target.rawValue,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="standingOrderTrigger"
                  className="col-4 text-capitalize"
                >
                  {localize('standingOrderTrigger')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={customerData?.standingOrderTriggerDescription || ''}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="standingOrderPrincipal"
                  className="col-4 text-capitalize"
                >
                  {localize('standingOrderPrincipal')}
                </label>
                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={
                    formatCurrency(
                      customerData?.standingOrderPrincipal,
                      null,
                      null,
                      false
                    ) || ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="standingOrderInterest"
                  className="col-4 text-capitalize"
                >
                  {localize('standingOrderInterest')}
                </label>
                <input
                  type="text"
                  className="form-control text-end"
                  disabled
                  value={
                    formatCurrency(
                      customerData?.standingOrderInterest,
                      null,
                      null,
                      false
                    ) || ''
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="referenceMonth"
                  className="col-4 text-capitalize"
                >
                  {localize('referenceMonth')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="Month"
                  value={
                    interestCapitalization?.month ||
                    Object.keys(months)[interestCapitalization?.month]
                  }
                  onChange={e => {
                    setInterestCapitalization({
                      ...interestCapitalization,
                      month: e.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label htmlFor="valueDate" className="col-4 text-capitalize">
                  {localize('valueDate')}
                </label>
                {isBusyLoadingValueDate ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <CustomDatePicker
                    defaultDate={valueDate}
                    name={'adHocStandingOrderExecutionValueDate'}
                    onDateChange={date => setValueDate(date)}
                    setDateToNull={updateDate}
                    setUpdateDate={setUpdateDate}
                  />
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="capitalizationMonths"
                  className="col-4 text-capitalize"
                >
                  {localize('capitalizationMonths')}
                </label>
                <MaskNumber
                  defaultMaskValue={
                    interestCapitalization?.interestCapitalizationMonths
                  }
                  onMaskNumber={e => {
                    setInterestCapitalization({
                      ...interestCapitalization,
                      interestCapitalizationMonths: e.target.rawValue,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="annualPercentageRate"
                  className="col-4 text-capitalize"
                >
                  {localize('annualPercentageRate')}
                </label>
                <MaskPercentage
                  defaultMaskValue={
                    customerData?.customerAccountTypeTargetProductLoanInterestAnnualPercentageRate ||
                    customerData?.annualPercentageRate
                  }
                  onMaskChange={e => {
                    setCustomerData({
                      ...customerData,
                      annualPercentageRate: e.target.value,
                    })
                    setInterestCapitalization({
                      ...interestCapitalization,
                      annualPercentageRate: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row py-">
            <div className="col-md-12">
              <Tab tabItems={tabItems} />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default AdHocLoanInterestCapitalization
