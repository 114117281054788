/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../Components/BreadCrumb/Index'
import localize from '../../Global/Localize'
import { formatAsPercent, formatCurrency } from '../../Helpers/formatters'

function Dashboard() {
  const [topData, setTopData] = useState([])
  const data = null

  useEffect(() => {
    loadTopData()
    //eslint-disable-next-line
  }, [])

  function loadTopData() {
    let td = [
      { label: 'Total Revenue', amount: 550000, percDiff: 2.65 },
      { label: 'orders', amount: 6584, percDiff: -0.82 },
      { label: 'customers', amount: 56782, percDiff: 18.4 },
      { label: 'growth', amount: 16.3, percDiff: 4.54, isPerc: true },
    ]
    setTopData(td)
  }

  return (
    <div>
      <BreadCrumb tree={[localize('dashboard')]} />
      {data && (
        <>
          <div className="row">
            {topData.map((td, i) => (
              <div className="col-md-6 col-lg-3" key={`dash-widget-${i}`}>
                <div className="card">
                  <div className="card-body bg-white">
                    <div className="float-end mt-2">Graph Data</div>
                    <div>
                      <h4 className="mb-1 mt-1">
                        <span>
                          {td?.isPerc
                            ? formatAsPercent(td?.amount)
                            : formatCurrency(td?.amount, null, null, false)}
                        </span>
                      </h4>
                      <p className="text-muted mb-0 text-capitalize">
                        {td?.label}
                      </p>
                    </div>
                    <p className="text-muted mt-3 mb-0 text-lowercase">
                      <span
                        className={`text-${
                          td?.percDiff < 0 ? 'danger' : 'success'
                        } me-1`}
                      >
                        <i
                          className={`mdi mdi-arrow-${
                            td?.percDiff < 0 ? 'down' : 'up'
                          }-bold me-1`}
                        ></i>
                        {Math.abs(td?.percDiff)}%
                      </span>
                      {localize('since last week')}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body bg-white">
                  <div className="float-end">
                    <div className="dropdown">
                      <div
                        id="dropdownMenuButton5"
                        aria-haspopup="true"
                        className="text-reset dropdown-toggle"
                        aria-expanded="false"
                      >
                        <span className="fw-semibold me-2">Group By:</span>
                        <span className="text-muted">
                          Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 h4 card-title">
                    Transaction Analytics
                  </div>
                  <div className="mt-1">
                    <ul className="list-inline main-chart mb-0">
                      <li className="list-inline-item chart-border-left me-0 border-0">
                        <h3 className="text-primary">
                          <span>
                            <span>2,371,782.46</span>
                          </span>
                          <span className="text-muted d-inline-block font-size-15 ms-3">
                            Income
                          </span>
                        </h3>
                      </li>
                      <li className="list-inline-item chart-border-left me-0">
                        <h3>
                          <span data-plugin="counterup">
                            <span>258,672</span>
                          </span>
                          <span className="text-muted d-inline-block font-size-15 ms-3">
                            Transactions
                          </span>
                        </h3>
                      </li>
                      <li className="list-inline-item chart-border-left me-0">
                        <h3>
                          <span data-plugin="counterup">
                            <span>3.6%</span>
                          </span>
                          <span className="text-muted d-inline-block font-size-15 ms-3">
                            Conversation Ratio
                          </span>
                        </h3>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Dashboard
