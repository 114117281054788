/** @format */

/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import Loader from '../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../Global/Localize'
import { CustomerFilter } from '../../../../../Global/enumeration'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import { getData } from '../../../../../Helpers/webApi'
import Relieve from './Relieve/Relieve'
import ViewGuarantors from './ViewGuarantors/ViewGuarantors'

const Relieving = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [histories, setHistories] = useState([])
  const [selectedHistory, setSelectedHistory] = useState({})
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[0],
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })

  const columns = [
    {
      label: localize('fullAccountNumber'),
      template: r => r?.fullAccountNumber,
    },
    {
      label: localize('productName'),
      template: r =>
        r?.sourceCustomerAccountCustomerAccountTypeTargetProductDescription,
    },
    {
      label: localize('loanNumber'),
      template: r => r?.sourceCustomerAccountLoanCaseDocumentReferenceNumber,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.sourceCustomerAccountCustomerReference1,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.sourceCustomerAccountCustomerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.sourceCustomerAccountCustomerReference3,
    },
    {
      label: localize('principalBalance'),
      template: r => formatCurrency(r?.principalBalance),
    },
    {
      label: localize('interestBalance'),
      template: r => formatCurrency(r?.interestBalance),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchAttachmentHistories = async () => {
    setIsBusy(true)
    const url =
      '/LoanCase/find-loan-guarantor-attachment-histories-by-date-range-and-filter-in-page'

    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setHistories(data)
    } else {
      setIsBusy(false)
    }
  }

  const handleClickContextMenu = (r, action) => {
    setMode(action)
    setSelectedHistory(r)
  }

  const handleClose = () => {
    setMode(null)
    setSelectedHistory({})
  }

  useEffect(() => {
    fetchAttachmentHistories()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      {mode === 'relive' && (
        <Relieve
          handleClose={handleClose}
          mode={mode}
          selectedHistory={selectedHistory}
          fetchAttachmentHistories={fetchAttachmentHistories}
        />
      )}

      {mode === 'view' && (
        <ViewGuarantors
          handleClose={handleClose}
          mode={mode}
          selectedHistory={selectedHistory}
        />
      )}

      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('guarantorsManagement'),
          localize('guarantorAttachmentHistory'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row gx-3 mb-2 mt-2 d-flex align-items-center flex-wrap">
                <div className="col-md-3">
                  <RecordsPerPage
                    reqParams={reqParams}
                    setReqParams={setReqParams}
                  />
                </div>

                <div className="col-md-4 d-flex align-items-center">
                  <label htmlFor="search" className="col-3 text-capitalize">
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams.startDate,
                      endDate: reqParams.endDate,
                    }}
                    onSelect={v => {
                      if (v.custom) {
                        setReqParams({
                          ...reqParams,
                          customDate: true,
                          startDate: v.startDate,
                          endDate: v.endDate,
                        })
                      } else {
                        setReqParams({
                          ...reqParams,
                          customDate: false,
                          dateRequestsFilter: v.value,
                        })
                      }
                    }}
                  />
                </div>

                <div className="col-md-5">
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <label htmlFor="search" className="text-capitalize ms-2">
                      {localize('search')}
                    </label>

                    <div className="d-flex align-items-center justify-content-end">
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="CustomerFilter"
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            customerFilter: e.value,
                          })
                        }}
                        value={reqParams.customerFilter}
                        sort
                      />

                      <input
                        type="search"
                        id="search"
                        className="form-control ms-2"
                        value={reqParams.filterText}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                            pageIndex: 0,
                          })
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            fetchAttachmentHistories()
                          }
                        }}
                      />
                      <button
                        id="FilterButton"
                        type="button"
                        className="btn btn-primary ms-2"
                        onClick={e => {
                          fetchAttachmentHistories()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <SimpleTable
                columns={columns}
                data={histories?.pageCollection}
                contextMenu={r => (
                  <>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleClickContextMenu(r, 'view')
                      }}
                    >
                      <i className="uil uil-eye"></i>
                      <span>{localize('viewGuarantors')}</span>
                    </div>
                    <div
                      className="ctxt-menu-item text-capitalize"
                      onClick={() => {
                        handleClickContextMenu(r, 'relive')
                      }}
                    >
                      <i className="uil uil-corner-down-left"></i>
                      <span>{localize('relieve')}</span>
                    </div>
                  </>
                )}
              />
              <Pager
                itemsCount={histories?.itemsCount}
                pageSize={reqParams?.pageSize}
                pageIndex={reqParams?.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Relieving
