/** @format */

import React, { useState } from 'react'
import Swal from 'sweetalert2'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../../Components/Loaders/Loader'
import { CreditTypeLookup } from '../../../../../../Components/Lookup/CreditTypeLookup'
import CustomerAccountLookup from '../../../../../../Components/Lookup/CustomerAccountLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import {
  ProductCode,
  customerAccountStatus,
  standingOrderDurationSpecification,
} from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { getTodaysDate } from '../../../../../../Helpers/formatters'
import { postData } from '../../../../../../Helpers/webApi'

const initialState = {
  scheduleFrequency: 12,
  standingOrderDurationSpecification: Object.keys(
    standingOrderDurationSpecification
  )[0],
  durationStartDate: getTodaysDate(),
  durationEndDate: getTodaysDate(),
  trigger: 0,
}

const CreateStandingOrder = ({ handleClose, mode, fetchStandingOrders }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [standingOrder, setStandingOrder] = useState(initialState)
  const [benefactorAccount, setBenefactorAccount] = useState({})
  const [beneficiaryAccount, setBeneficiaryAccount] = useState({})
  const [creditType, setCreditType] = useState({})

  const handleCLoseModal = () => {
    handleClose()
    setStandingOrder({})
  }

  const onBenefactorAccountLookup = r => {
    if (r?.status === Object.keys(customerAccountStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected account is closed',
      })

    if (r?.customerAccountTypeProductCode !== Object.keys(ProductCode)[0])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'You can only select a savings account',
      })

    setBenefactorAccount(r)
  }

  const onBeneficiaryAccountLookup = r => {
    if (r?.status === Object.keys(customerAccountStatus)[3])
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected account is closed',
      })

    setBeneficiaryAccount(r)
  }

  const onCreditTypeLookup = r => {
    if (r?.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected credit type is locked',
      })

    setCreditType(r)
  }

  const createStandingOrder = async () => {
    if (!benefactorAccount?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a benefactor account',
      })

    if (!beneficiaryAccount?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a beneficiary account',
      })

    if (!creditType?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a credit type',
      })

    if (
      standingOrder?.standingOrderDurationSpecification ===
        Object.keys(standingOrderDurationSpecification)[0] &&
      standingOrder?.durationStartDate > standingOrder?.durationEndDate
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Duration start date cannot be greater than duration end date',
      })

    if (
      standingOrder?.standingOrderDurationSpecification ===
        Object.keys(standingOrderDurationSpecification)[1] &&
      standingOrder?.durationInMonths <= 0
    )
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Duration in months cannot be less than or equal to zero',
      })

    setIsBusy(true)

    const url = '/StandingOrder/create'

    const { success } = await postData(
      url,
      {
        ...standingOrder,
        benefactorCustomerAccountId: benefactorAccount?.id,
        benefactorCustomerAccountBranchCode:
          benefactorAccount?.customerAccountBranchCode,
        beneficiaryCustomerAccountId: beneficiaryAccount?.id,
        beneficiaryCustomerAccountBranchCode:
          beneficiaryAccount?.customerAccountBranchCode,
        creditTypeId: creditType?.id,
      },
      false
    )

    if (success) {
      showNotification('Create Standing Order', 'success')
      fetchStandingOrders()
      setBenefactorAccount({})
      setBeneficiaryAccount({})
      setCreditType({})
      handleClose()
      setIsBusy(false)
      setStandingOrder(initialState)
    }

    setIsBusy(false)
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle={mode === 'add' ? 'createStandingOrder' : 'editStandingOrder'}
      handleClose={handleCLoseModal}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalClassName="modal-xl"
      disableCreate={isBusy}
      handleAction={createStandingOrder}
      footerChildren={
        <>
          <div className="d-flex align-items-center gap-4 justify-content-between">
            <div className="d-flex align-items-center">
              <Checkbox
                id={'isChargeableStandOrders'}
                checked={standingOrder?.chargeable}
                onCheck={value => {
                  setStandingOrder({
                    ...standingOrder,
                    chargeable: value,
                  })
                }}
              />

              <label
                className="text-capitalize ms-2"
                htmlFor="isChargeableStandOrders"
              >
                {localize('chargeable') + ' ?'}
              </label>
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                id={'isLockedStandOrder'}
                checked={standingOrder?.isLocked}
                onCheck={value => {
                  setStandingOrder({
                    ...standingOrder,
                    isLocked: value,
                  })
                }}
              />

              <label
                className="text-capitalize ms-2"
                htmlFor="isLockedStandOrder"
              >
                {localize('isLocked') + ' ?'}
              </label>
            </div>
          </div>
        </>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-4 d-flex align-items-center">
              <label className={`col-4 text-capitalize`}>
                {localize('benefactorAccountNumber')}
              </label>

              <CustomerAccountLookup
                productCode={Object.keys(ProductCode)[0]}
                displayValue={benefactorAccount?.fullAccountNumber}
                onSelect={onBenefactorAccountLookup}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('benefactorProduct')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  benefactorAccount?.customerAccountTypeTargetProductDescription
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('benefactorAccountName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={benefactorAccount?.accountName}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className={`col-4 text-capitalize`}>
                {localize('beneficiaryAccountNumber')}
              </label>

              <CustomerAccountLookup
                productCode={Object.keys(ProductCode)[0]}
                displayValue={beneficiaryAccount?.fullAccountNumber}
                onSelect={onBeneficiaryAccountLookup}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('beneficiaryProduct')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={
                  beneficiaryAccount?.customerAccountTypeTargetProductDescription
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('beneficiaryAccountName')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                disabled
                value={beneficiaryAccount?.accountName}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('creditType')}
              </label>
              <CreditTypeLookup
                onSelect={onCreditTypeLookup}
                displayValue={creditType?.description}
              />
            </div>
            <div className="col-md-8 d-flex align-items-center">
              <label className="col-2 me-1 text-capitalize">
                {localize('frequency')}
              </label>
              <EnumsServerCombo
                enumsKey="ScheduleFrequency"
                value={standingOrder?.scheduleFrequency}
                customContainerStyle={{
                  width: '100%',
                }}
                sort
                onChange={({ value }) => {
                  setStandingOrder({
                    ...standingOrder,
                    scheduleFrequency: value,
                  })
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('durationType')}
              </label>
              <EnumsServerCombo
                enumsKey="StandingOrderDurationSpecification"
                value={standingOrder?.standingOrderDurationSpecification}
                customContainerStyle={{
                  width: '100%',
                }}
                sort
                onChange={({ value }) => {
                  setStandingOrder({
                    ...standingOrder,
                    standingOrderDurationSpecification: value,
                  })
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize me-2">
                {localize('startDate')}
              </label>
              <CustomDatePicker
                name={'createStandingOrderDurationStartDate'}
                defaultDate={standingOrder?.durationStartDate}
                maxDate={
                  standingOrder?.standingOrderDurationSpecification ===
                    Object.keys(standingOrderDurationSpecification)[0] &&
                  standingOrder?.durationEndDate
                }
                onDateChange={date => {
                  setStandingOrder({
                    ...standingOrder,
                    durationStartDate: date,
                  })
                }}
              />
            </div>
            {standingOrder?.standingOrderDurationSpecification ===
            Object.keys(standingOrderDurationSpecification)[0] ? (
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize me-2">
                  {localize('endDate')}
                </label>
                <CustomDatePicker
                  name={'createStandingOrderDurationEndDate'}
                  defaultDate={standingOrder?.durationEndDate}
                  onDateChange={date => {
                    setStandingOrder({
                      ...standingOrder,
                      durationEndDate: date,
                    })
                  }}
                />
              </div>
            ) : (
              <div className="col-md-4 d-flex align-items-center">
                <label className="col-4 text-capitalize me-1">
                  {localize('duration(Months)')}
                </label>
                <MaskNumber
                  defaultMaskValue={standingOrder?.durationInMonths || 0}
                  onMaskNumber={e => {
                    setStandingOrder({
                      ...standingOrder,
                      durationInMonths: e.target.rawValue,
                    })
                  }}
                />
              </div>
            )}

            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('paymentPerPeriod')}
              </label>
              <MaskNumber
                defaultMaskValue={standingOrder?.paymentPerPeriod || 0}
                onMaskNumber={e => {
                  setStandingOrder({
                    ...standingOrder,
                    paymentPerPeriod: e.target.rawValue,
                  })
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize me-2 col-4">
                {localize('principal')}
              </label>
              <MaskNumber
                defaultMaskValue={standingOrder?.principal || 0}
                onMaskNumber={e => {
                  setStandingOrder({
                    ...standingOrder,
                    principal: e.target.rawValue,
                  })
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-4 me-2">
                {localize('interest')}
              </label>
              <MaskNumber
                defaultMaskValue={standingOrder?.interest || 0}
                onMaskNumber={e => {
                  setStandingOrder({
                    ...standingOrder,
                    interest: e.target.rawValue,
                  })
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-4">
                {localize('capitalizedInterest')}
              </label>

              <MaskNumber
                defaultMaskValue={standingOrder?.capitalizedInterest || 0}
                onMaskNumber={e => {
                  setStandingOrder({
                    ...standingOrder,
                    capitalizedInterest: e.target.rawValue,
                  })
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 me-2 text-capitalize">
                {localize('trigger')}
              </label>
              <EnumsServerCombo
                enumsKey="StandingOrderTrigger"
                value={standingOrder?.trigger}
                customContainerStyle={{
                  width: '100%',
                }}
                sort
                onChange={({ value }) => {
                  setStandingOrder({
                    ...standingOrder,
                    trigger: value,
                  })
                }}
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-4 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                type="text"
                className="form-control ms-2"
                value={standingOrder?.remarks}
                onChange={e => {
                  setStandingOrder({
                    ...standingOrder,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateStandingOrder
