/** @format */

import localize from '../../../../../../../../../../Global/Localize'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../../../../../Helpers/formatters'
import SimpleTable from '../../../../../../../../../../Components/Tables/SimpleTable'

export const CustomerAccountPreviewMiniStatement = ({ data, loading }) => {
  const columns = [
    { label: localize('branch'), template: r => <>{r.branchDescription}</> },
    {
      label: localize('transactionDate'),
      template: r => <>{formatDate(r.journalCreatedDate)}</>,
    },
    {
      label: localize('primaryDescription'),
      template: r => <>{r.journalPrimaryDescription}</>,
    },
    { label: localize('debit'), template: r => <>{formatCurrency(r.debit)}</> },
    {
      label: localize('credit'),
      template: r => <>{formatCurrency(r.credit)}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    {
      label: localize('secondaryDescription'),
      template: r => <>{r.journalSecondaryDescription}</>,
    },
    { label: localize('reference'), template: r => <>{r.journalReference}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.customerAccountNumber}</>,
    },
    {
      label: localize('customerName'),
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: `${localize('ref_2')} (${localize('personalFile')} #)`,
      template: r => <>{r.customerReference3}</>,
    },
    {
      label: localize('transactionCode'),
      template: r => <>{r.journalTransactionCodeDescription}</>,
    },
    {
      label: localize('valueDate'),
      template: r => <>{formatDate(r.journalValueDate)}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r.applicationUserName}</>,
    },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable columns={columns} data={data} />
      )}
    </>
  )
}
