/** @format */

/* eslint-disable */
import localize from '../../../../Global/Localize'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import ChangeLog from '../../../../Components/ReUsables/ChangeLog/ChangeLog'

const ViewChangeLogs = ({
  handleClose,
  mode,
  changeLogsTableData,
  changeLogsRecordDetails,
}) => {
  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleClose={handleClose}
      actionText={localize('ok')}
      modalTitle={
        localize('changeLog') +
        '  -  ' +
        localize('eventType') +
        ':  ' +
        changeLogsRecordDetails?.eventType +
        ', ' +
        localize('tableName') +
        ':  ' +
        changeLogsRecordDetails?.tableName
      }
      hideUpdate
    >
      <ChangeLog auditRecords={changeLogsTableData} />
    </ModalTemplate>
  )
}

export default ViewChangeLogs
