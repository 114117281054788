/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import React from 'react'

export const ViewPurchaseOrdersEntries = ({ entries }) => {
  const columns = [
    {
      label: localize('itemType'),
      template: r => <>{r.itemRegisterItemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    { label: localize('quantity'), template: r => <>{r.quantity}</> },
    { label: localize('unitCost'), template: r => <>{r.unitCost}</> },
    { label: localize('totalCost'), template: r => <>{r.totalCost}</> },
  ]

  return (
    <>
      <SimpleTable selectables data={entries} columns={columns} />
    </>
  )
}
