/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import SimpleLookup from './SimpleLookup'
import { formatDate } from '../../Helpers/formatters'
function LoanDisbursementModeLookup({ onSelect, displayValue }) {
  return (
    <SimpleLookup
      title={localize('loanDisbursementModeLookup')}
      url="/lookup/LoanDisbursementMode/find-loan-disbursement-mode-by-filter-in-page-async"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        {
          label: localize('name'),
          template: r => <>{r.description}</>,
          sortBy: 'description',
        },
        {
          label: localize('category'),
          template: r => <>{r.disbursementCategoryDescription}</>,
        },
        {
          label: localize('disbursementTarget'),
          template: r => <>{r.disbursementTargetDescription}</>,
        },
        {
          label: localize('isDefault') + ' ?',
          template: r => <>{r.IsDefault?.toString()}</>,
          sortBy: 'isDefault',
        },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">
              {localize(r.isLocked?.toString())}
            </span>
          ),
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
export default LoanDisbursementModeLookup
