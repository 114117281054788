/** @format */

/* eslint-disable */
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { unitOfMeasureBaseUnits } from '../../../../../Global/enumeration'

const CreateInventoryUnitsOfMeasure = ({
  handleClose,
  handleAction,
  mode,
  units,
  setUnits,
  isLoading,
  globalValidationErrors,
  clearValidation,
}) => {
  const handleSelectContainsAmount = e => {
    if (e?.target.value <= 0) {
      setUnits({
        ...units,
        contains: 0,
      })
    } else {
      setUnits({
        ...units,
        contains: e?.target.value,
      })
    }
  }

  return (
    <ModalTemplate
      modalClassName="modal-xl"
      modalMode={mode}
      handleAction={handleAction}
      handleClose={handleClose}
      actionText={mode === 'add' ? 'create' : 'update'}
      modalTitle={localize('inventoryUnitsOfMeasure')}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-2">
            <div
              className="col-12 d-flex align-items-center with-validation"
              validation-message={
                globalValidationErrors['description']?.message
              }
            >
              <label htmlFor="name" className="text-capitalize col-1">
                {localize('name')}
              </label>
              <input
                type="text"
                className="form-control"
                value={units?.description}
                onChange={e => {
                  setUnits({
                    ...units,
                    description: e?.target.value,
                  })
                  clearValidation('description')
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <label htmlFor="contains" className="text-capitalize col-2">
                {localize('contains')}
              </label>
              <input
                type="number"
                className="form-control text-end"
                value={units?.contains || 0}
                onChange={e => handleSelectContainsAmount(e)}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label htmlFor="ofBaseUnits" className="text-capitalize col-2">
                {localize('ofBaseUnits')}
              </label>
              <EnumsServerCombo
                sort
                className="col"
                value={units?.baseUnits}
                enumsKey="unitOfMeasureBaseUnits"
                onChange={e =>
                  setUnits({
                    ...units,
                    baseUnits: e?.value,
                  })
                }
                customContainerStyle={{
                  width: 'inherit',
                }}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}
export default CreateInventoryUnitsOfMeasure
