/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

function InventoryCatalogueLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('category'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('package'),
      template: r => <>{r.itemRegisterInventoryPackageTypeDescription}</>,
    },
    {
      label: localize('baseUom'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    {
      label: localize('supplier'),
      template: r => <>{r.supplierDescription}</>,
    },
    {
      label: localize('quantity'),
      template: r => <>{formatCurrency(r.quantity)}</>,
    },
    {
      label: localize('reorderPoint'),
      template: r => <>{r.itemRegisterReorderPoint}</>,
    },
    {
      label: localize('maximumOrder'),
      template: r => <>{r.itemRegisterMaximumOrder}</>,
    },
    {
      label: localize('unitsPerPack'),
      template: r => <>{r.itemRegisterUnitsPerPack}</>,
    },
    {
      label: localize('palletTi'),
      template: r => <>{r.itemRegisterPalletTI}</>,
    },
    {
      label: localize('palletHi'),
      template: r => <>{r.itemRegisterPalletHI}</>,
    },
    {
      label: localize('unitNetWeight'),
      template: r => <>{r.itemRegisterUnitNetWeight}</>,
    },
    {
      label: localize('unitGrossWeight'),
      template: r => <>{r.itemRegisterUnitGrossWeight}</>,
    },
    {
      label: localize('estimatedUnitCost'),
      template: r => <>{formatCurrency(r.itemRegisterEstimatedUnitCost)}</>,
    },
    {
      label: localize('unitPrice'),
      template: r => <>{formatCurrency(r.unitPrice)}</>,
    },
    {
      label: localize('leadDays'),
      template: r => <>{r.itemRegisterLeadDays}</>,
    },
    {
      label: localize('economicOrder'),
      template: r => <>{r.itemRegisterEconomicOrder}</>,
    },
    {
      label: localize('monthlyDemand'),
      template: r => <>{r.itemRegisterMonthlyDemand}</>,
    },
    {
      label: localize('remarks'),
      template: r => <>{r.remarks}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        columns={columns}
        title={localize('inventoryCatalogueLookup')}
        url="/Inventory/find-inventories-by-filter-in-page"
        params={{
          filterText: '',
          pageIndex: 0,
          pageSize: 10,
          showBelowReorderLevel: true,
        }}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default InventoryCatalogueLookup
