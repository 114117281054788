/** @format */

/* eslint-disable */
import React, { useEffect } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../../Global/Localize'
import { useState } from 'react'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { getData, postData } from '../../../../../../Helpers/webApi'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'
import CreateIdentityVerification from './CreateIdentityVerification/CreateIdentityVerification'
import ViewIdentityVerification from './ViewIdentityVerification/ViewIdentityVerification'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import { creditReferenceBureauQueryStatus } from '../../../../../../Global/enumeration'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'

const IdentityVerification = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [refreshAt, setRefreshAt] = useState(false)
  const [identityVerification, setIdentityVerification] = useState({})
  const [modalMode, setModalMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: 0,
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  useEffect(() => {
    // fetch data
    fetchIdentityVerification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, refreshAt])
  const fetchIdentityVerification = async () => {
    setIsBusy(true)
    const url =
      '/CreditReferenceBureau/find-identity-verification-queries-by-status-date-and-filter-in-page'
    const { data, success } = await getData(url, reqParams, false)

    if (success) {
      setIsBusy(false)
      setData(data)
      setError(false)
    } else {
      setIsBusy(false)
      setError(true)
    }
  }
  const handleAddNew = async () => {
    setIsBusy(true)
    if (modalMode === 'add') {
      // create
      const url = '/CreditReferenceBureau/add-new-identity-verification-query'
      const { success } = await postData(
        url,
        identityVerification,
        false,
        'Operation completed successfully'
      )
      if (success) {
        setIsBusy(false)
        setModalMode(null)
        setIdentityVerification(null)
        setRefreshAt(!refreshAt)
      } else {
        setIsBusy(false)
      }
    }
  }
  const handleViewIdentity = (identityVerification, mode) => {
    setModalMode(mode)
    setIdentityVerification(identityVerification)
  }
  const handleClose = () => {
    setModalMode(null)
  }
  const columns = [
    {
      label: localize('createdDate'),
      template: r => r.createdDate,
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('reportType'),
      template: r => r.reportTypeDescription,
    },
    {
      label: localize('identityType'),
      template: r => r.identityTypeDescription,
    },
    {
      label: localize('identityNumber'),
      template: r => r.identityNumber,
    },
    {
      label: localize('firstName'),
      template: r => r.firstName,
    },
    {
      label: localize('lastName'),
      template: r => r.lastName,
    },
    {
      label: localize('otherName'),
      template: r => r.otherName,
    },
    // {
    //   label: localize('surname'),
    //   template: r => r.otherName,
    // },
    {
      label: localize('gender'),
      template: r => r.gender,
    },
    // {
    //   label: localize('dateOfIssue'),
    //   template: r => r.dateOfIssue,
    // },
    {
      label: localize('dateOfBirth'),
      template: r => r.dateOfBirth,
    },
    {
      label: localize('dateOfDeath'),
      template: r => r.dateOfDeath,
    },
    {
      label: localize('citizenship'),
      template: r => r.citizenship,
    },
    {
      label: localize('hasError'),
      template: r => r.hasError,
    },
    {
      label: localize('apiCode'),
      template: r => r.apiCodeDescription,
    },
    {
      label: localize('transactionId'),
      template: r => r.transactionId,
    },
    {
      label: localize('thirdPartyWalletResponse'),
      template: r => r.thirdPartyWalletResponse,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('modifiedBy'),
      template: r => r.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => r.modifiedDate,
    },
  ]
  return (
    <>
      <BreadCrumb
        tree={[
          localize('identityVerification'),
          localize('identityVerificationQueries'),
        ]}
      />
      {modalMode === 'add' && (
        <CreateIdentityVerification
          handleAction={handleAddNew}
          handleClose={handleClose}
          mode={modalMode}
          identityVerification={identityVerification}
          setIdentityVerification={setIdentityVerification}
        />
      )}
      {modalMode === 'view' && (
        <ViewIdentityVerification
          data={data}
          handleClose={handleClose}
          mode={modalMode}
          identityVerification={identityVerification}
          setIdentityVerification={setIdentityVerification}
          title="identityVerification"
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="d-flex align-items-center justify-content-start">
                    {
                      <AddNewButton
                        handleClickAddButton={() => setModalMode('add')}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className="row mb-4 align-items-center">
                <div className="d-flex align-items-center col-md-3">
                  <label
                    htmlFor="recordsPerPage"
                    className=""
                    style={{ marginLeft: -10, textTransform: 'capitalize' }}
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-3"
                    style={{ maxWidth: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="d-flex align-items-center col-md-3">
                  <label htmlFor="status1" className="text-capitalize">
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    customContainerStyle={{ width: '100%', marginLeft: 10 }}
                    enumsKey="CreditReferenceBureauQueryStatus"
                    value={reqParams?.status}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        status: e.value,
                      })
                    }
                  />
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center">
                    <label className="text-capitalize" htmlFor="searchByDate">
                      {localize('dateRange')}
                    </label>
                    <DatePicker
                      onSelect={v => {
                        if (v.custom) {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                          })
                        } else {
                          setReqParams({
                            ...reqParams,
                            customDate: false,
                            dateRequestsFilter: v.value,
                          })
                        }
                      }}
                    />
                  </div>
                </div>
                <form
                  className="d-flex align-items-center col-3"
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      filterText: search,
                    })
                  }}
                >
                  <label className="ms-2 text-capitalize" htmlFor="searchTable">
                    {localize('search')}
                  </label>
                  <input
                    type="search"
                    id="searchTable"
                    className="form-control ms-2"
                    defaultValue={reqParams.filterText}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                  />
                  <button
                    id="identityVerificationFilterButton"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      fetchIdentityVerification()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </form>
              </div>
              <div className="row mb-4">
                <SimpleTable
                  columns={columns}
                  data={data?.pageCollection}
                  contextMenu={r => (
                    <>
                      <div
                        className="ctxt-menu-item text-capitalize"
                        onClick={() => {
                          handleViewIdentity(r, 'view')
                          setModalMode('view')
                        }}
                      >
                        <i className="uil uil-eye"></i>
                        <span>{localize('view')}</span>
                      </div>
                    </>
                  )}
                  // contextMenu={r => (
                  //   <div
                  //     className="context-menu-item"
                  //     onClick={() => {
                  //       setModalMode('view')
                  //       setIdentityVerification(r)
                  //     }}
                  //   ></div>
                  // )}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default IdentityVerification
