/** @format */

import React from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../../../Components/Loaders/Loader'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../../Helpers/formatters'

const MorgueServices = ({
  services,
  isBusy,
  selectedEntries,
  handleSelectEntry,
}) => {
  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <div className="mt-2">
          <Checkbox
            onCheck={_v => {
              handleSelectEntry(r)
            }}
            checked={selectedEntries?.find(e => e.id === r.id)}
          />
        </div>
      ),
    },
    { label: 'description', template: r => <>{r?.description}</> },
    { label: 'multiplier', template: r => <>{r?.multiplier}</> },
    { label: 'amount', template: r => <>{formatCurrency(r?.amount)}</> },
  ]

  return (
    <>
      {isBusy ? <Loader /> : <SimpleTable columns={columns} data={services} />}
    </>
  )
}

export default MorgueServices
