/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import {
  guarantorAttachmentMode,
  loanGuarantorStatus,
} from '../../../Helpers/constants'
import {
  formatAsPercent,
  formatCurrency,
  formatDate,
} from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import FormulaInput from '../../Formula/FormulaInput'
import NonBlockingLoader from '../../Loaders/NonBlockingLoader'
import GroupedTable from '../../Tables/GroupedTable'

const GroupedLoanGuarantors = ({
  customerId,
  setManualAllocationLoanGuarantorDTOs,
  attachmentMode,
  filterByAttAchedGuarantors,
  resetManualAllocationLoanGuarantorDTOs,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [loanGuarantors, setLoanGuarantors] = useState([])

  const [groupBy] = useState({
    label: localize('loanProduct'),
    column: 'loanProductDescription',
  })

  const handleEvaluatePrincipalAttachedExpression = (value, row) => {
    if (!value || value === undefined) return

    const newGuarantors = loanGuarantors.map(g => {
      if (g.id === row.id) {
        g.principalAttachedMathExpression = value
      }
      return g
    })

    setManualAllocationLoanGuarantorDTOs(newGuarantors)
  }

  const handleEvaluateInterestAttachedExpression = (value, row) => {
    if (!value || value === undefined) return

    const newGuarantors = loanGuarantors.map(g => {
      if (g.id === row.id) {
        g.interestAttachedMathExpression = value
      }

      return g
    })

    setManualAllocationLoanGuarantorDTOs(newGuarantors)
  }

  const columns = [
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('loanStatus'),
      template: r => r?.loanCaseStatusDescription,
    },
    { label: localize('customerFullName'), template: r => r?.customerFullName },
    {
      label: localize('membershipStatus'),
      template: r => r?.customerMembershipStatusDescription,
    },
    {
      label: localize('identificationNumber'),
      template: r => r?.customerIdentificationNumber,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.formattedCustomerMemberNumber,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.customerAddressMobileLine,
    },
    {
      label: localize('loanApprovedAmount'),
      template: r => formatCurrency(r?.loanCaseApprovedAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanBalanceOutstanding'),
      template: r => formatCurrency(r?.loanBalanceOutstanding),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanClassification'),
      template: r => r?.loanClassificationDescription,
    },
    {
      label: localize('loanBalanceExpected'),
      template: r => formatCurrency(r?.loanBalanceExpected),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanPrepaidAmount'),
      template: r => formatCurrency(r?.loanPrepaidAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanArrearsAmount'),
      template: r => formatCurrency(r?.loanDefaultAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanArrearsTimeline(Days)'),
      template: r => r?.loanDefaultTimeline,
    },
    {
      label: localize('loanLossProvisionPercentage'),
      template: r => formatAsPercent(r?.loanLossProvisionPercentage),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanLossProvisionAmount'),
      template: r => formatCurrency(r?.loanLossProvisionAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanLastPaymentDueDate'),
      template: r => formatDate(r?.loanLastPaymentDueDate, true),
    },
    {
      label: localize('loanLastPayment'),
      template: r => formatCurrency(r?.loanLastPayment),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanPurpose'),
      template: r => r?.loanCaseLoanPurposeDescription,
    },
    {
      label: localize('loanBranch'),
      template: r => r?.loanCaseBranchDescription,
    },
    {
      label: localize('totalShares'),
      template: r => formatCurrency(r?.totalShares),
    },
    {
      label: localize('committedShares'),
      template: r => formatCurrency(r?.committedShares),
    },
    {
      label: localize('amountGuaranteed'),
      template: r => formatCurrency(r?.amountGuaranteed),
    },
    {
      label: localize('amountPledged'),
      template: r => formatCurrency(r?.amountPledged),
      headerTextAlign: 'right',
    },
    { label: localize('appraisalFactor'), template: r => r?.appraisalFactor },
    {
      label: localize('loanPrincipalBalanceOutstanding(principal)'),
      template: r => formatCurrency(r?.loanPrincipalBalanceOutstanding),
      headerTextAlign: 'right',
    },
    {
      label: localize('loanInterestBalanceOutstanding(interest)'),
      template: r => formatCurrency(r?.loanInterestBalanceOutstanding),
    },
    {
      label: localize('principalAttached'),
      template: r => formatCurrency(r?.principalAttached),
    },
    {
      label: localize('interestAttached'),
      template: r => formatCurrency(r?.interestAttached),
      headerTextAlign: 'right',
    },
    {
      label: localize('principalAttachedFormula'),
      template: r =>
        attachmentMode === guarantorAttachmentMode.MANUAL_ALLOCATION ? (
          <FormulaInput
            useBlur
            onChange={value =>
              handleEvaluatePrincipalAttachedExpression(value, r)
            }
            style={{
              minWidth: 200,
            }}
          />
        ) : (
          r?.principalAttachedMathExpression
        ),
    },
    {
      label: localize('interestAttachedFormula'),
      template: r =>
        attachmentMode === guarantorAttachmentMode.MANUAL_ALLOCATION ? (
          <FormulaInput
            useBlur
            onChange={value =>
              handleEvaluateInterestAttachedExpression(value, r)
            }
            style={{
              minWidth: 200,
            }}
          />
        ) : (
          r?.interestAttachedMathExpression
        ),
    },
    { label: localize('createdBy'), template: r => r?.createdBy },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchLoanGuarantors = async () => {
    setIsBusy(true)
    const url = `/LoanCase/find-loan-guarantors-by-loanee-customer-id?loaneeCustomerId=${customerId}`

    const { success, data } = await getData(
      url,
      {
        includeOutstandingBalance: true,
      },
      false
    )

    if (success) {
      if (filterByAttAchedGuarantors)
        setLoanGuarantors(
          data?.result?.filter(x => x?.status === loanGuarantorStatus.ATTACHED)
        )
      else setLoanGuarantors(data?.result)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (customerId) fetchLoanGuarantors()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  useEffect(() => {
    if (resetManualAllocationLoanGuarantorDTOs) {
      setLoanGuarantors([])
    }
  }, [resetManualAllocationLoanGuarantorDTOs])

  return (
    <div className="relative">
      <GroupedTable data={loanGuarantors} groupBy={groupBy} columns={columns} />
      {isBusy && <NonBlockingLoader />}
    </div>
  )
}

export default GroupedLoanGuarantors
