/** @format */

export const ACTIONS = {
  GET_CREDIT_TYPE_COMMISSIONS: 'get_credit_type_commissions',
  GET_CREDIT_TYPE_DIRECT_DEBITS: 'get_credit_type_direct_debits',
  GET_CREDIT_TYPE_INVESTMENT_PRODUCTS: 'get_credit_type_investment_products',
  GET_CREDIT_TYPE_SAVINGS_PRODUCTS: 'get_credit_type_savings_products',
  GET_CREDIT_TYPE_LOAN_PRODUCTS: 'get_credit_type_loan_products',
  GET_CREDIT_TYPE_CONCESSION_EXEMPTION_INVESTMENT_PRODUCTS:
    'get_credit_type_concession_exemption_investment_products',
  GET_CREDIT_TYPE_CONCESSION_EXEMPTION_SAVINGS_PRODUCTS:
    'get_credit_type_concession_exemption_savings_products',
  GET_CREDIT_TYPE_CONCESSION_EXEMPTION_LOAN_PRODUCTS:
    'get_credit_type_concession_exemption_loan_products',
  GET_CREDIT_TYPE_FLAGS: 'get_credit_type_flags',
  GET_CREDIT_TYPE_BENEFACTOR_INVESTMENT_PRODUCTS:
    'get_credit_type_benefactor_investment_products',
  LOADING: 'loading',
  CREATE_CREDIT_TYPE_INVESTMENT_PRODUCTS:
    'create_credit_type_investment_products',
  CREATE_CREDIT_TYPE_SAVINGS_PRODUCTS: 'create_credit_type_savings_products',
  CREATE_CREDIT_TYPE_LOAN_PRODUCTS: 'create_credit_type_loan_products',
  CREATE_CREDIT_TYPE_DIRECT_DEBITS: 'create_credit_type_direct_debits',
  CREATE_CREDIT_TYPE_COMMISSIONS: 'create_credit_type_commissions',
  SELECT_ALL_FOSA_LOAN_PRODUCTS: 'select_all_fosa_loan_products',
  SELECT_ALL_BOSA_LOAN_PRODUCTS: 'select_all_bosa_loan_products',
  NULL_SELECTION_CREDIT_TYPE_LOAN_PRODUCTS:
    'null_selection_credit_type_loan_products',
  CHECKING_FLAG: 'checking_flag',
  CHECK_CREDIT_TYPE_FLAG: 'check_credit_type_flag',
  ADD_METADATA_CREDIT_TYPE_COMMISSIONS: 'ADD_METADATA_CREDIT_TYPE_COMMISSIONS',
  ADD_METADATA_CREDIT_TYPE_DIRECT_DEBITS:
    'ADD_METADATA_CREDIT_TYPE_DIRECT_DEBITS',
  ADD_METADATA_CREDIT_TYPE_INVESTMENT_PRODUCTS:
    ' ADD_METADATA_CREDIT_TYPE_INVESTMENT_PRODUCTS',
  ADD_METADATA_CREDIT_TYPE_SAVINGS_PRODUCTS:
    'ADD_METADATA_CREDIT_TYPE_SAVINGS_PRODUCTS',
  ADD_METADATA_CREDIT_TYPE_LOAN_PRODUCTS:
    'ADD_METADATA_CREDIT_TYPE_LOAN_PRODUCTS',
  ADD_METADATA_CREDIT_TYPE_CONCESSION_EXEMPTION_INVESTMENT_PRODUCTS:
    'ADD_METADATA_CREDIT_TYPE_CONCESSION_EXEMPTION_INVESTMENT_PRODUCTS',
  ADD_METADATA_CREDIT_TYPE_CONCESSION_EXEMPTION_SAVINGS_PRODUCTS:
    'ADD_METADATA_CREDIT_TYPE_CONCESSION_EXEMPTION_SAVINGS_PRODUCTS',
  ADD_METADATA_CREDIT_TYPE_CONCESSION_EXEMPTION_LOAN_PRODUCTS:
    'ADD_METADATA_CREDIT_TYPE_CONCESSION_EXEMPTION_LOAN_PRODUCTS',
  ADD_METADATA_CREDIT_TYPE_BENEFACTOR_INVESTMENT_PRODUCTS:
    'ADD_METADATA_CREDIT_TYPE_BENEFACTOR_INVESTMENT_PRODUCTS',
}

export function creditTypeReducer(state, action) {
  switch (action.type) {
    case ACTIONS.GET_CREDIT_TYPE_COMMISSIONS: {
      return {
        ...state,
        creditTypeCommissions: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_DIRECT_DEBITS: {
      return {
        ...state,
        creditTypeDirectDebits: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_INVESTMENT_PRODUCTS: {
      return {
        ...state,
        creditTypeInvestmentProducts: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_SAVINGS_PRODUCTS: {
      return {
        ...state,
        creditTypeSavingsProducts: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_INVESTMENT_PRODUCTS: {
      return {
        ...state,
        creditTypeConcessionExemptionInvestmentProducts: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_SAVINGS_PRODUCTS: {
      return {
        ...state,
        creditTypeConcessionExemptionSavingsProducts: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_CONCESSION_EXEMPTION_LOAN_PRODUCTS: {
      return {
        ...state,
        creditTypeConcessionExemptionLoanProducts: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_FLAGS: {
      return {
        ...state,
        creditTypeFlags: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_LOAN_PRODUCTS: {
      return {
        ...state,
        creditTypeLoanProducts: action.payload,
      }
    }
    case ACTIONS.GET_CREDIT_TYPE_BENEFACTOR_INVESTMENT_PRODUCTS: {
      return {
        ...state,
        creditTypeBenefactorInvestmentProducts: action.payload,
      }
    }

    case ACTIONS.CREATE_CREDIT_TYPE_INVESTMENT_PRODUCTS: {
      return {
        ...state,
        creditTypeInvestmentProducts: action.payload,
        creditTypeConcessionExemptionInvestmentProducts: action.payload,
        creditTypeBenefactorInvestmentProducts: action.payload,
      }
    }
    case ACTIONS.CREATE_CREDIT_TYPE_SAVINGS_PRODUCTS: {
      return {
        ...state,
        creditTypeSavingsProducts: action.payload,
        creditTypeConcessionExemptionSavingsProducts: action.payload,
      }
    }
    case ACTIONS.CREATE_CREDIT_TYPE_LOAN_PRODUCTS: {
      return {
        ...state,
        creditTypeLoanProducts: action.payload,
        creditTypeConcessionExemptionLoanProducts: action.payload,
      }
    }
    case ACTIONS.CREATE_CREDIT_TYPE_DIRECT_DEBITS: {
      return {
        ...state,
        creditTypeDirectDebits: action.payload,
      }
    }
    case ACTIONS.CREATE_CREDIT_TYPE_COMMISSIONS: {
      return {
        ...state,
        creditTypeCommissions: action.payload,
      }
    }
    case ACTIONS.SELECT_ALL_BOSA_LOAN_PRODUCTS: {
      return {
        ...state,
        creditTypeLoanProducts: state.creditTypeLoanProducts.map(val => {
          return val.loanRegistrationLoanProductSection === '1'
            ? { ...val, checked: true }
            : val
        }),
      }
    }
    case ACTIONS.SELECT_ALL_FOSA_LOAN_PRODUCTS: {
      return {
        ...state,
        creditTypeLoanProducts: state.creditTypeLoanProducts.map(val => {
          return val.loanRegistrationLoanProductSection === '0'
            ? { ...val, checked: true }
            : val
        }),
      }
    }
    case ACTIONS.NULL_SELECTION_CREDIT_TYPE_LOAN_PRODUCTS: {
      return {
        ...state,
        creditTypeLoanProducts: state.creditTypeLoanProducts.map(val => {
          return { ...val, checked: false }
        }),
      }
    }
    case ACTIONS.LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case ACTIONS.CHECK_CREDIT_TYPE_FLAG: {
      return {
        ...state,
        creditTypeFlags: action.payload,
      }
    }

    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
