/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../../Components/BreadCrumb/Index'
import ListDebitBatches from '../../../../../../Components/ReUsables/BatchProcedures/DebitBatches/ListDebitBatches'
import localize from '../../../../../../Global/Localize'
import { QueuePriority } from '../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { postData } from '../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import AddDebitModal from './AddDebitModal/AddDebitModal'

function Debit() {
  const [isBusy, setIsBusy] = useState(false)
  const [mode, setMode] = useState(null)
  const [batch, setBatch] = useState({
    priority: Object.keys(QueuePriority)[3],
  })
  const [reload, setReload] = useState(false)

  const handleClickAddButton = () => {
    setMode('add')
    setBatch({
      priority: Object.keys(QueuePriority)[3],
    })
  }

  const handleClose = () => {
    setMode(null)
    setBatch({
      priority: Object.keys(QueuePriority)[3],
    })
  }

  const handleCreateDebit = async () => {
    setIsBusy(true)
    if (!batch.debitTypeId) {
      return GlobalValidationErrors.set({
        DebitTypeId: {
          message: 'Debit type is required',
        },
      })
    }

    if (!batch.reference) {
      return GlobalValidationErrors.set({
        Reference: {
          message: 'Reference is required',
        },
      })
    }

    const url = '/DebitBatch/create-debit-batch'

    const { success } = await postData(url, batch, false)

    if (success) {
      handleClose()
      setReload(true)
      showNotification('Debit Batch', 'success')
    }

    setIsBusy(false)
  }

  return (
    <>
      {mode && (
        <AddDebitModal
          mode={mode}
          batch={batch}
          setBatch={setBatch}
          handleAction={handleCreateDebit}
          handleClose={handleClose}
          isBusyCreating={isBusy}
        />
      )}

      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('batchProcedures'),
          localize('origination'),
          localize('debit'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <ListDebitBatches
            showCreateButton
            handleClickAddButton={handleClickAddButton}
            reload={reload}
            setReload={setReload}
            showEdit
          />
        </div>
      </div>
    </>
  )
}

export default Debit
