/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import localize from '../../Global/Localize'
import ContextMenuContainer from '../ContextMenu/Container'
import './simpletable.scss'
import FloatingContextMenu from '../ContextMenu/FloatingContextMenu'
import ContextMenu2 from '../ContextMenu/ContextMenu2'

function SimpleTable({
  columns,
  selectables,
  data,
  contextMenu,
  canSelect,
  onSelect,
  onClickOnce,
  canClickOnce,
  onSort,
  sortedBy,
}) {
  const [ctxtMenuPosition, setCtxtMenuPosition] = useState([0, 0])
  const [showCtxtMenu, setShowCtxtMenu] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [ctxParentId, setCtxParentId] = useState('x')
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null)
  const [columnsData, setColumns] = useState([])
  const [asc, setAsc] = useState(false)
  const [sortColumn, setSortColumn] = useState(null)

  const [sortedData, setSortedData] = useState()

  useEffect(() => {
    setSortedData(data)
  }, [data])

  function handleColumnDragStart(e, columnIndex) {
    setDraggedColumnIndex(columnIndex)
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/plain', '') // Necessary for Firefox
  }

  function handleColumnDragOver(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
  }

  function handleColumnDrop(e, targetColumnIndex) {
    e.preventDefault()
    if (
      draggedColumnIndex !== null &&
      draggedColumnIndex !== targetColumnIndex
    ) {
      // Rearrange the columns
      const newColumns = [...columnsData]
      const [draggedColumn] = newColumns.splice(draggedColumnIndex, 1)
      newColumns.splice(targetColumnIndex, 0, draggedColumn)

      // Update the columns state
      setColumns(newColumns)

      // Reset the draggedColumnIndex
      setDraggedColumnIndex(null)
    }
  }

  async function sortDataBy(column) {
    if (column) {
      let ourData = [...sortedData]
      ourData.sort((a, b) => {
        setAsc(!asc)
        setSortColumn(column)
        let aa = a[column] || ''
        let bb = b[column] || ''
        return asc ? aa.localeCompare(bb) : bb.localeCompare(aa)
      })
      setSortedData(ourData)
    }
  }

  function showContextMenu(e, row, parentId) {
    if (contextMenu) {
      e.preventDefault()
      setCtxParentId(parentId)
      // setCtxtMenuPosition([e.clientX, e.clientY])
      setShowCtxtMenu(true)
      setSelectedRow(row)
    }
  }

  useEffect(() => {
    window.addEventListener('contextmenu', setPosition)
    window.addEventListener('click', hideCTXTMenu)

    return () => {
      window.removeEventListener('contextmenu', setPosition)
      window.removeEventListener('click', hideCTXTMenu)
    }
    //eslint-disable-next-line
  }, [ctxParentId])

  function setPosition(e) {
    setCtxtMenuPosition([e.x, e.y])
  }

  function hideContextMenu() {
    setShowCtxtMenu(false)
  }

  function hideCTXTMenu(e) {
    e.stopPropagation()
    if (showContextMenu) {
      setShowCtxtMenu(false)
    }
  }

  useEffect(() => {
    if (columns) setColumns(columns)

    //eslint-disable-next-line
  }, [columns])

  return (
    <>
      <div
        className={selectables ? 'selectables' : ''}
        style={{ margin: 0, padding: 0, overflowX: 'auto' }}
      >
        <table
          className="table cent-table"
          id="centrinotable"
          style={{ minWidth: '100%' }}
        >
          <tr
            className="bg-white"
            style={selectables ? { position: 'sticky', top: 0, zIndex: 5 } : {}}
          >
            {columnsData.map((c, i) => (
              <th
                // className="rounded"
                key={`col-${i}`}
                draggable={true}
                onDragStart={e => handleColumnDragStart(e, i)}
                onDragOver={e => handleColumnDragOver(e)}
                onDrop={e => handleColumnDrop(e, i)}
                onClick={() => {
                  sortDataBy(c?.sortBy)
                }}
                style={{
                  ...(c.sortBy ? { cursor: 'pointer' } : {}),
                  textAlign: c?.headerTextAlign || 'left',
                }}
                className={`text-primary py-2 px-3 ${
                  c.sortBy ? 'sortable' : ''
                }`}
              >
                <span>{localize(c.label)}</span>
                {/* {sortedBy && sortedBy[2] === i && (
                  <i
                    className={`uil uil-arrow-${
                      sortedBy[1] === 1 ? 'up' : 'down'
                    } fs-5 p-0 m-0`}
                  />
                )} */}

                {c.sortBy === sortColumn && (
                  <i className={`uil uil-arrow-${asc ? 'down' : 'up'}`}></i>
                )}
              </th>
            ))}
          </tr>
          {Array.isArray(sortedData) &&
            sortedData.map(r => (
              <>
                <tr
                  key={r.id}
                  id={`row-${r.id}`}
                  className={`data-row ${
                    selectedRow?.id === r?.id ? 'selected' : ''
                  }`}
                  onDoubleClick={() =>
                    canSelect ? onSelect(r) : hideContextMenu()
                  }
                  onClick={() => {
                    if (canClickOnce) {
                      onClickOnce(r)
                      setSelectedRow(r)
                    }
                  }}
                  onContextMenu={e => showContextMenu(e, r, `row-${r.id}`)}
                >
                  {columnsData.map((col, i) => (
                    <td
                      className="py-2 px-3"
                      // style={{ maxWidth: 'fit-content' }}
                      key={`${r?.id}-cell-${i}`}
                    >
                      {col.template(r)}
                    </td>
                  ))}
                  {contextMenu && (
                    <FloatingContextMenu>
                      {contextMenu(selectedRow)}
                    </FloatingContextMenu>
                  )}
                </tr>

                {canClickOnce && (
                  <UncontrolledTooltip
                    placement="auto"
                    target={`row-${r.id}`}
                    className="text-capitalize"
                  >
                    {localize('select or click row to show more details')}
                  </UncontrolledTooltip>
                )}
              </>
            ))}
        </table>
      </div>
      {showCtxtMenu && contextMenu && (
        <ContextMenu2
          position={ctxtMenuPosition}
          hide={() => setShowCtxtMenu(false)}
        >
          {contextMenu(selectedRow)}
        </ContextMenu2>
        // <ContextMenuContainer
        //   position={ctxtMenuPosition}
        //   hide={() => setShowCtxtMenu(false)}
        // >
        //   {contextMenu(selectedRow)}
        //   {/* @TODO: Intercept All Tables to show audit log
        //   <div className="ctxt-menu-item text-capitalize">
        //     <i className="uil uil-eye"></i>
        //     <span>{localize('auditLogs')}</span>
        //   </div> */}
        // </ContextMenuContainer>
      )}
    </>
  )
}

export default SimpleTable
