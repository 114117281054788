/** @format */

/* eslint-disable */
import React from 'react'
import '../selectables.scss'
import Checkbox from '../../Checkbox/Checkbox'
import { PleaseWait } from '../../Loaders/PleaseWait'

function SavingsProducts({
  disabled,
  isBusy,
  savingsProductToBeSelected,
  onCheckSavingsProduct,
  prefix,
}) {
  const handleCheckSavingsProducts = (savingsProduct, checkValue) => {
    const com = [...savingsProductToBeSelected].map(comm => {
      if (comm.id === savingsProduct.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckSavingsProduct(com)
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <PleaseWait />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {savingsProductToBeSelected?.map(savingsProduct => (
            <li
              key={savingsProduct.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${savingsProduct.id}`}
                checked={savingsProduct.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckSavingsProducts(savingsProduct, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${savingsProduct.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  savingsProduct.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {savingsProduct.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}

export default SavingsProducts
