/** @format */

import localize from '../../../../../../../Global/Localize'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'

export const AccountMetadataBankAccounts = ({ loading, data }) => {
  const columns = [
    {
      label: localize('bankCode'),
      template: r => <>{r.paddedBankBranchBankCode}</>,
    },
    {
      label: localize('bankName'),
      template: r => <>{r.bankBranchBankDescription}</>,
    },
    {
      label: localize('branchCode'),
      template: r => <>{r.paddedBankBranchCode}</>,
    },
    {
      label: localize('branchName'),
      template: r => <>{r.bankBranchDescription}</>,
    },
    { label: localize('accountNumber'), template: r => <>{r.accountNumber}</> },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{r.createdDate}</> },
  ]

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="m-3 spinner-border text-dark"></div>
          <span className="fs-4">Please Wait</span>
        </div>
      ) : (
        <SimpleTable columns={columns} data={data} />
      )}
    </>
  )
}
