/** @format */

import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

const UnPayReasonLookup = ({ onSelect, displayValue }) => {
  const columns = [
    { label: localize('code'), template: r => <>{r.paddedCode}</> },
    { label: localize('name'), template: r => <>{r.description}</> },
    {
      label: localize('isLocked') + ' ?',
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        url="/UnpayReason/find-unpay-reasons-by-filter-in-page"
        params={{
          pageIndex: 0,
          text: '',
          pageSize: 10,
          includeBalances: true,
        }}
        columns={columns}
        title={localize('unpayReasonLookup')}
        onSelect={onSelect}
        displayValue={displayValue}
      />
    </>
  )
}

export default UnPayReasonLookup
