/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../Global/Localize'
import { formatDate } from '../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../Validation/ValidationState'
import ListingPage from '../../../Components/ListingPage/Index'
import { postData, putData } from '../../../Helpers/webApi'
import Checkbox from '../../../Components/Checkbox/Checkbox'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'

function EducationVenue() {
  const columns = [
    {
      label: localize('name'),
      template: r => <>{r.description}</>,
      sortBy: 'description',
    },
    {
      label: localize('enforceInvestmentsBalance') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.enforceInvestmentsBalance.toString())}
        </span>
      ),
      sortBy: 'enforceInvestmentsBalance',
    },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(r.isLocked.toString())}
        </span>
      ),
      sortBy: 'isLocked',
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate, false)}</>,
    },
  ]

  const [mode, setMode] = useState(null)
  const [selectedEducationVenue, setSelectedEducationVenue] = useState(null)
  const [refreshAt, setRefreshAt] = useState(false)

  async function handleSubmit() {
    const { success } =
      mode === 'edit'
        ? await putData(
            '/EducationVenue/update',
            selectedEducationVenue,
            true,
            'Operation Completed Successfully'
          )
        : await postData(
            '/EducationVenue/create',
            selectedEducationVenue,
            true,
            'Operation Completed Successfully'
          )

    if (success) {
      setMode(null)
      setSelectedEducationVenue(null)
      setRefreshAt(!refreshAt)
    }
  }

  function closeModal() {
    setMode(null)
    setSelectedEducationVenue(null)
    setGlobalValidationErrors({})
  }

  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  function clearValidation(column) {
    setGlobalValidationErrors(validation => {
      delete validation[column]
      return validation
    })
  }

  return (
    <>
      <ListingPage
        onAdd={() => {
          setMode('add')
          setSelectedEducationVenue(null)
        }}
        map={[
          localize('registryManagement'),
          localize('membersEducationVenue'),
        ]}
        columns={columns}
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        url="/EducationVenue/find-by-filter-in-page"
        contextMenu={r => (
          <>
            <div
              className="ctxt-menu-item"
              onClick={() => {
                setMode('edit')
                setSelectedEducationVenue(r)
              }}
            >
              <i className="uil uil-edit-alt"></i>
              {localize('edit')}
            </div>
          </>
        )}
        refreshAt={!refreshAt}
      />

      {mode && (
        <ModalTemplate
          modalMode={mode}
          modalTitle="membersEducationVenue"
          handleAction={handleSubmit}
          handleClose={closeModal}
          modalClassName="modal-lg"
          footerChildren={
            <>
              <Checkbox
                id="educationVenueEnforceInvestmentsBalance"
                onCheck={e => {
                  setSelectedEducationVenue({
                    ...selectedEducationVenue,
                    enforceInvestmentsBalance: e,
                  })
                }}
                checked={selectedEducationVenue?.enforceInvestmentsBalance}
              />
              <label
                htmlFor="educationVenueEnforceInvestmentsBalance"
                className="text-capitalize"
              >
                {localize('enforceInvestmentsBalance')}?
              </label>
              <div className="mx-3"></div>
              <Checkbox
                id="cEducationVenueIsLocked"
                onCheck={e => {
                  setSelectedEducationVenue({
                    ...selectedEducationVenue,
                    isLocked: e,
                  })
                }}
                checked={selectedEducationVenue?.isLocked}
              />
              <label
                htmlFor="cEducationVenueIsLocked"
                className="text-capitalize"
              >
                {localize('isLocked')}?
              </label>
              <div className="mx-3"></div>
            </>
          }
        >
          <div className="row mb-3 g-3">
            <div className="col-md-12">
              <div
                className="with-validation d-flex align-items-center mb-2"
                validation-message={
                  globalValidationErrors['Description']?.message
                }
              >
                <label className="text-capitalize col-1">
                  {localize('name')}
                </label>
                <input
                  defaultValue={selectedEducationVenue?.description}
                  type="text"
                  className="form-control"
                  onChange={e => {
                    clearValidation('Description')
                    setSelectedEducationVenue({
                      ...selectedEducationVenue,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </ModalTemplate>
      )}
    </>
  )
}
export default EducationVenue
