/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../Helpers/formatters'
import RecordsPerPage from '../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../Components/Tables/Pager'
function TargetCustomers({
  targetCustomers,
  onRemoveEntry,
  mode,
  handleOnCheckCustomerExemptionEntry,
  removeSelectedExemptionEntries,
  reqParams,
  setReqParams,
  tableMeta,
  refresh,
  setRefresh,
}) {
  const [selectedExemptedCustomers, setSelectedExemptedCustomers] = useState([])
  const [toggleSelection, setToggleSelection] = useState(false)

  useEffect(() => {
    handleOnCheckCustomerExemptionEntry(selectedExemptedCustomers)
  }, [selectedExemptedCustomers])

  let columns = [
    {
      label: mode === 'edit' ? localize('select') : '',
      template: r =>
        mode === 'edit' ? (
          <Checkbox
            checked={selectedExemptedCustomers.find(obj => obj.id === r?.id)}
            onCheck={v => {
              addNewCustomerExemptEntry(r, v)
            }}
          />
        ) : (
          <></>
        ),
    },
    {
      label: localize('customerType'),
      template: r => <>{r?.customerMembershipClassCustomerTypeDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedCustomerSerialNumber}</>,
    },
    { label: localize('name'), template: r => <>{r?.customerFullName}</> },
    {
      label: localize('account'),
      template: r => <>{r?.customerReference1}</>,
    },
    {
      label: localize('membership'),
      template: r => <>{r?.customerReference2}</>,
    },
    {
      label: localize('personalFile'),
      template: r => <>{r?.customerReference3}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.customerIndividualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.customerIndividualMaritalStatusDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.customerIndividualIdentityCardNumber}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.customerIndividualNationalityDescription}</>,
    },
    {
      label: localize('dutyWorkStation'),
      template: r => <>{r?.customerDutyStationDescription}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.customerAddressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.customerAddressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r?.customerAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.customerAddressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r?.customerAddressCity}</> },
    { label: localize('email'), template: r => <>{r?.customerAddressEmail}</> },
    {
      label: localize('landLine'),
      template: r => <>{r?.customerAddressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.customerAddressMobileLine}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const onCheckCustomerExemptionEntry = checkStatus => {
    if (checkStatus) {
      setToggleSelection(checkStatus)
      setSelectedExemptedCustomers([])
      setSelectedExemptedCustomers([...targetCustomers])
    } else {
      setSelectedExemptedCustomers([])
      setToggleSelection(checkStatus)
    }
  }

  const addNewCustomerExemptEntry = (customer, checkStatus) => {
    if (checkStatus) {
      if (!selectedExemptedCustomers.some(obj => obj.id === customer?.id)) {
        setSelectedExemptedCustomers(prev => {
          return [...selectedExemptedCustomers, customer]
        })
      }
    } else {
      setSelectedExemptedCustomers(prev => {
        return prev.filter(obj => obj.id !== customer?.id)
      })
    }
  }

  return (
    <>
      {mode !== 'add' ? (
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <RecordsPerPage reqParams={reqParams} setReqParams={setReqParams} />
          </div>
          <div className="d-flex align-item-center gap-2">
            <form
              className="d-flex align-items-center"
              onSubmit={e => {
                e.preventDefault()
                setRefresh(!refresh)
              }}
            >
              <label className="text-capitalize" htmlFor="searchTable">
                {localize('search')}
              </label>
              <input
                type="search"
                id="searchTable"
                className="form-control ms-2"
                defaultValue={reqParams.filterText}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                    pageIndex: 0,
                  })
                }
              />
            </form>
            <button
              id="holidayFilterButton"
              className="btn btn-primary ms-1"
              onClick={() => setRefresh(!refresh)}
            >
              <i className="uil uil-filter"></i>
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
      <SimpleTable
        selectables
        contextMenu={r =>
          mode === 'add' && (
            <>
              <div
                onClick={() => {
                  onRemoveEntry(r)
                }}
                className="ctxt-menu-item"
              >
                <i className="uil uil-trash text-danger"></i>
                {localize('remove')}
              </div>
            </>
          )
        }
        columns={columns}
        data={targetCustomers}
      />
      <div className="row col-12 mt-2">
        <Pager
          {...tableMeta}
          onPageChange={p => {
            setReqParams({ ...reqParams, pageIndex: p })
          }}
        />
      </div>
      {mode === 'edit' && (
        <>
          <div className="row mb-3 g-3 mt-2">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-2">
                <Checkbox
                  id="toggle-selection"
                  checked={toggleSelection}
                  onCheck={v => {
                    onCheckCustomerExemptionEntry(v)
                  }}
                />
                <label
                  htmlFor="toggle-selection"
                  className="text-capitalize ms-2"
                >
                  {localize('toggleSelection')}
                </label>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex justify-content-end align-items-end">
                <button
                  onClick={removeSelectedExemptionEntries}
                  className="btn btn-success text-uppercase ms-3 px-4"
                >
                  {localize('remove')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default TargetCustomers
