/** @format */

import React, { useRef, useEffect } from 'react'
import './contextmenu.scss'

function ContextMenu2({ position, children, hide }) {
  const ref = useRef()

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      hide()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      onClick={hide}
      ref={ref}
      style={{
        position: 'fixed',
        maxWidth: 'fit-content',
        left: position[0],
        top: position[1],
        padding: 0,
        margin: 0,
      }}
      className="bg-white context-menu"
    >
      {children}
    </div>
  )
}

export default ContextMenu2
