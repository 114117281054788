/** @format */

import React, { useState } from 'react'
import { putData } from '../../../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import { ApprovePurchaseOrdersModal } from './ApprovePurchaseOrdersModal'

export const PurchaseOrderModal = ({
  mode,
  onHideModal,
  loading,
  onUpdateRefreshAt,
  onSetMode,
  selectedPurchaseOrder,
  setSelectedPurchaseOrder,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'approve') {
      approveRequisition()
    }
  }

  const approveRequisition = async () => {
    setIsBusy(true)
    const { success, data } = await putData(
      '/WorkFlow/approve-purchase-order-workflow-item',
      {
        ...selectedPurchaseOrder,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      setSelectedPurchaseOrder(null)
      swal.fire(
        'Approve Purchase Order',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('purchaseOrder')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            footerChildren={
              <div className="d-flex align-content-end justify-content-end">
                <div className="col d-flex align-items-center me-5">
                  <label className="col-3 text-capitalize me-4">
                    {localize('remarks')}
                  </label>
                  <input
                    className="form-control"
                    value={selectedPurchaseOrder?.remarks}
                    onChange={e => {
                      setSelectedPurchaseOrder({
                        ...selectedPurchaseOrder,
                        remarks: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center ms-2">
                  <EnumsServerCombo
                    value={selectedPurchaseOrder?.approvalOption}
                    onChange={e => {
                      setSelectedPurchaseOrder({
                        ...selectedPurchaseOrder,
                        approvalOption: e.value,
                      })
                    }}
                    customContainerStyle={{ width: '100%' }}
                    enumsKey="WorkflowApprovalOption"
                  />
                </div>
              </div>
            }
            hideUpdate={mode === 'view'}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
          >
            {mode === 'approve' && (
              <ApprovePurchaseOrdersModal
                isBusy={loading || isBusy}
                selectedPurchaseOrder={selectedPurchaseOrder}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}
