/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../../../Global/Localize'
import { formatDate } from '../../../../../../../Helpers/formatters'
function IdentityVerification({ selectedCreditInformationQuery }) {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('status')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.statusDescription || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('reportType')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.reportTypeDescription || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('identityType')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.identityTypeDescription || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('identity#')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.identityNumber || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('firstName')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.firstName || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('lastName')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.lastName || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('otherName')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.otherName || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('surname')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.otherName || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('gender')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.gender || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('dateOfBirth')}
          </label>
          <input
            className="form-control ms-2"
            value={
              formatDate(
                selectedCreditInformationQuery.identityVerificationQueryDTO
                  ?.dateOfBirth,
                true
              ) || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('dateOfDeath')}
          </label>
          <input
            className="form-control ms-2"
            value={
              formatDate(
                selectedCreditInformationQuery.identityVerificationQueryDTO
                  ?.dateOfDeath,
                true
              ) || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('dateOfIssue')}
          </label>
          <input
            className="form-control ms-2"
            value={
              formatDate(
                selectedCreditInformationQuery.identityVerificationQueryDTO
                  ?.dateOfIssue,
                true
              ) || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('citizenship')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.citizenship || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('clan')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.clan || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('ethnicGroup')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.ethnicGroup || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">{localize('family')}</label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.family || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('occupation')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.occupation || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('placeOfBirth')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.placeOfBirth || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('placeOfDeath')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.placeOfDeath || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('placeOfLive')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.placeOfLive || ''
            }
            disabled
          />
        </div>
        <div className="col d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('regOffice')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.regOffice || ''
            }
            disabled
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4 d-flex align-items-center mb-2">
          <label className="col-3 text-capitalize">
            {localize('serialNumber')}
          </label>
          <input
            className="form-control ms-2"
            value={
              selectedCreditInformationQuery.identityVerificationQueryDTO
                ?.serialNumber || ''
            }
            disabled
          />
        </div>
      </div>
    </>
  )
}
export default IdentityVerification
