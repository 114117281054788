/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import { batchEntryStatus } from '../../../../Global/enumeration'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import { getData } from '../../../../Helpers/webApi'
import Checkbox from '../../../Checkbox/Checkbox'
import EnumsServerCombo from '../../../ComboBox/EnumsServerCombo'
import Loader from '../../../Loaders/Loader'
import RecordsPerPage from '../../../RecordsPerPage/RecordsPerPage'
import Pager from '../../../Tables/Pager'
import SimpleTable from '../../../Tables/SimpleTable'

const DebitBatchEntries = ({
  debitBatchId,
  status,
  hiddenColumns = [],
  handleSelectAllEntries,
  showSelect,
  handleSelectEntry,
  selectedEntries,
  handleRemoveBatchEntries,
  reload,
  setReload,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [batchEntries, setBatchEntries] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [reqParams, setReqParams] = useState({
    status: status ?? Object.keys(batchEntryStatus)[0],
    pageIndex: 0,
    pageSize: 10,
    filterText: '',
    includeProductDescription: true,
  })

  const columns = [
    {
      label: localize('select') + ' ?',
      template: r => (
        <Checkbox
          onCheck={value => {
            handleSelectEntry(r, value)
          }}
          checked={selectedEntries?.find(e => e.id === r?.id)}
        />
      ),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r?.fullAccountNumber,
    },
    {
      label: localize('productName'),
      template: r => r?.productDescription,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('multiplier'),
      template: r => r?.multiplier,
    },
    {
      label: localize('basisValue'),
      template: r => formatCurrency(r?.basisValue),
      headerTextAlign: 'right',
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.customerAccountCustomerReference1,
    },
    {
      label: localize('membershipNumber'),
      template: r => r?.customerAccountCustomerReference2,
    },
    {
      label: localize('personalFileNumber'),
      template: r => r?.customerAccountCustomerReference3,
    },
    {
      label: localize('exceptionMessage'),
      template: r => r?.exceptionMessage,
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchBatchEntries = async () => {
    const url = '/DebitBatch/find-debit-batch-entries-by-debit-batch-id-in-page'

    setIsBusy(true)
    const { data, success } = await getData(
      url,
      {
        ...reqParams,
        debitBatchId,
      },
      false
    )

    if (success) {
      setBatchEntries(data)
      if (reload) setReload(false)
    }
    setIsBusy(false)
  }

  const columnsToDisplay = columns?.filter(
    (_c, i) => hiddenColumns.indexOf(i) === -1
  )

  useEffect(() => {
    fetchBatchEntries()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, reqParams.status, debitBatchId])

  useEffect(() => {
    if (reload) fetchBatchEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="py-2 px-3">
            <div className="row mb-4 d-flex align-items-center">
              <div className="d-flex align-items-center col-md-3">
                <RecordsPerPage
                  reqParams={reqParams}
                  setReqParams={setReqParams}
                />
              </div>
              {showSelect && (
                <div className="col-md-2">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      onCheck={value => {
                        setSelectAll(value)
                        handleSelectAllEntries(
                          batchEntries.pageCollection,
                          value
                        )
                      }}
                      id="toggleSelection-debit-batch-view"
                      checked={selectAll}
                    />
                    <label
                      htmlFor="toggleSelection-debit-batch-view"
                      className="ms-2"
                    >
                      {localize('toggleSelection')} ?
                    </label>
                  </div>
                </div>
              )}
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-2 text-capitalize">
                  {localize('status')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 200,
                    minWidth: 200,
                  }}
                  enumsKey="BatchEntryStatus"
                  value={reqParams?.status}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                      pageIndex: 0,
                    })
                  }}
                />
              </div>

              <div
                className={`${
                  showSelect ? 'col-md-3 offset-md-1' : 'col-md-3 offset-md-3'
                } d-flex align-items-center`}
              >
                <label htmlFor="search" className="text-capitalize">
                  {localize('search')}
                </label>

                <input
                  type="search"
                  id="search"
                  className="form-control col ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchBatchEntries()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchBatchEntries()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <SimpleTable
                columns={columnsToDisplay}
                data={batchEntries.pageCollection}
              />
              <Pager
                pageIndex={reqParams.pageIndex}
                pageSize={reqParams.pageSize}
                itemsCount={batchEntries.itemsCount}
                onPageChange={p => {
                  setReqParams({ ...reqParams, pageIndex: p })
                }}
              />
            </div>

            {showSelect && (
              <div className="row p-2 rounded bg-light mt-3 mx-1">
                <div className="col">
                  <button
                    className="btn btn-danger float-end"
                    onClick={handleRemoveBatchEntries}
                  >
                    {localize('remove')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DebitBatchEntries
