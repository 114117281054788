/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import localize from '../../../Global/Localize'
import { auditLogFilter } from '../../../Global/enumeration'
import { generateGuid } from '../../../Helpers/extensions'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import DatePicker from '../../Date/DatePicker'
import Loader from '../../Loaders/Loader'
import GroupedTable from '../../Tables/GroupedTable'
import Pager from '../../Tables/Pager'
import Tab from '../../Tabs/Tab'
import ChangeLog from '../ChangeLog/ChangeLog'

const AuditLogs = ({ recordId }) => {
  const [logs, setLogs] = useState([])
  const [log, setLog] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    recordId,
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    filterText: null,
    auditLogFilter: Object.keys(auditLogFilter)[3],
  })

  const [groupBy, setGroupBy] = useState({
    label: localize('eventType'),
    column: 'eventType',
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
    {
      label: localize('eventType'),
      template: r => r.eventType,
    },
    {
      label: localize('tableName'),
      template: r => r.tableName,
    },
    {
      label: localize('recordID'),
      template: r => r.recordID,
    },
    {
      label: localize('applicationDomainName'),
      template: r => r.applicationDomainName,
    },
    {
      label: localize('applicationUserName'),
      template: r => r.applicationUserName,
    },
    {
      label: localize('environmentUserName'),
      template: r => r.environmentUserName,
    },
    {
      label: localize('environmentMachineName'),
      template: r => r.environmentMachineName,
    },
    {
      label: localize('environmentDomainName'),
      template: r => r.environmentDomainName,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => r.environmentOSVersion,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => r.environmentMACAddress,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => r.environmentMotherboardSerialNumber,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => r.environmentProcessorId,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => r.environmentIPAddress,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
  ]

  const groupingColumns = [
    {
      label: localize('eventType'),
      column: 'EventType',
    },
    {
      label: localize('tableName'),
      column: 'TableName',
    },
    {
      label: localize('applicationDomainName'),
      column: 'ApplicationDomainName',
    },
    {
      label: localize('applicationUserName'),
      column: 'ApplicationUserName',
    },
    {
      label: localize('environmentUserName'),
      column: 'EnvironmentUserName',
    },
    {
      label: localize('environmentMachineName'),
      column: 'EnvironmentMachineName',
    },
    {
      label: localize('environmentDomainName'),
      column: 'EnvironmentDomainName',
    },
    {
      label: localize('environmentOSVersion'),
      column: 'EnvironmentOSVersion',
    },
    {
      label: localize('environmentMACAddress'),
      column: 'EnvironmentMACAddress',
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      column: 'EnvironmentMotherboardSerialNumber',
    },
    {
      label: localize('environmentProcessorId'),
      column: 'EnvironmentProcessorId',
    },
    {
      label: localize('environmentIPAddress'),
      column: 'EnvironmentIPAddress',
    },
  ]

  const fetchLogs = async () => {
    setIsBusy(true)

    const url =
      '/AuditLog/find-audit-log-by-record-id-date-range-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setLogs(data)
      setLog(
        data?.pageCollection[0]?.auditRecords?.map(r => ({
          ...r,
          id: generateGuid(),
        })) || []
      )
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    fetchLogs()
    //eslint-disable-next-line
  }, [reqParams.pageIndex, reqParams.pageSize, recordId])

  return isBusy ? (
    <Loader />
  ) : (
    <>
      <div className="p-3">
        <div className="row mb-3 g-2">
          <div className="d-flex align-items-center col">
            <label htmlFor="recordsPerPage" className="text-capitalize">
              {localize('recordsPerPage')}
            </label>
            <select
              id="recordsPerPage"
              className="form-select ms-2"
              style={{ maxWidth: 'fit-content' }}
              defaultValue={reqParams.pageSize}
              onChange={e => {
                setReqParams({
                  ...reqParams,
                  pageIndex: 0,
                  pageSize: e.target.value,
                })
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="d-flex align-items-center col">
            <label className="text-capitalize col-3" htmlFor="groupBy">
              {localize('groupBy')}
            </label>
            <select
              id="groupBy"
              className="form-select text-capitalize ms-2"
              defaultValue={groupBy?.column}
              onChange={e =>
                setGroupBy({
                  label: groupingColumns?.find(
                    f => f?.column === e.target.value
                  )?.label,
                  column: e.target.value,
                })
              }
            >
              {groupingColumns
                .sort((a, b) => a?.label?.localeCompare(b?.label))
                .map(({ label, column }) => (
                  <option
                    key={`group_catalogue_by_${column}`}
                    className="text-capitalize"
                    value={column}
                  >
                    {label}
                  </option>
                ))}
            </select>
          </div>
          <div className="d-flex align-items-center col">
            <label htmlFor="search" className="col-3 text-capitalize">
              {localize('dateRange')}
            </label>
            <DatePicker
              value={reqParams}
              onSelect={v => {
                if (v.custom) {
                  setReqParams({
                    ...reqParams,
                    customDate: true,
                    startDate: v.startDate,
                    endDate: v.endDate,
                  })
                } else {
                  setReqParams({
                    ...reqParams,
                    customDate: false,
                    dateRequestsFilter: v.value,
                  })
                }
              }}
            />
          </div>
          <div className="col d-flex align-items-center col gap-2">
            <div className="d-flex align-items-center col">
              <label className="text-capitalize me-2" htmlFor="searchTable">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{
                  maxWidth: 150,
                  minWidth: 150,
                }}
                enumsKey="AuditLogFilter"
                value={reqParams.auditLogFilter}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    auditLogFilter: e.value,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center col">
              <input
                type="search"
                id="searchTable"
                className="form-control ms-2"
                value={reqParams.filterText}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    filterText: e.target.value,
                  })
                }}
              />
              <button
                id="FilterButton"
                type="button"
                className="btn btn-primary ms-2"
                onClick={() => {
                  fetchLogs()
                }}
              >
                <i className="uil uil-filter"></i>
              </button>
            </div>
          </div>
        </div>
        <GroupedTable
          data={logs.pageCollection}
          columns={columns}
          groupBy={groupBy}
          onClickOnce={r => setLogs(r)}
        />
        <Pager
          itemsCount={logs.itemsCount}
          pageSize={reqParams.pageSize}
          pageIndex={reqParams.pageIndex}
          pageBatchSize={10}
          onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
        />
        <div className="row">
          <Tab
            tabItems={[
              {
                label: localize('changeLog'),
                item: (
                  <>
                    <ChangeLog auditRecords={log} />
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default AuditLogs
