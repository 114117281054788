/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { ListCreditBatches } from '../ReUsables/Credit/ListCreditBatches'

export const CreditBatchLookup = ({ disabled, onSelect, displayValue }) => {
  const [modalActive, setModalActive] = useState(false)

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          opacity: disabled ? 0.6 : 1,
          pointerEvents: disabled ? 'none' : 'all',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => {
            setModalActive(true)
          }}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal className="modal-xl" isOpen>
          <ModalHeader className="bg-white text-capitalize">
            {localize('creditBatchLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white">
            {modalActive && (
              <ListCreditBatches
                handleOnSelectVoucher={r => {
                  onSelect(r)
                  setModalActive(false)
                }}
              />
            )}
          </ModalBody>
          <ModalFooter className="bg-white">
            <button
              onClick={() => setModalActive(false)}
              className="btn btn-danger"
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
