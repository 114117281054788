/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../../Components/Tables/Pager'

export const ViewQuotationEntries = ({ entries,reqParams,setReqParams }) => {
  const columns = [
    {
      label: localize('itemType'),
      template: r => <>{r.itemRegisterItemTypeDescription}</>,
    },
    {
      label: localize('item'),
      template: r => <>{r.itemRegisterDescription}</>,
    },
    {
      label: localize('inventoryCategory'),
      template: r => <>{r.itemRegisterInventoryCategoryDescription}</>,
    },
    {
      label: localize('assetType'),
      template: r => <>{r.itemRegisterAssetTypeDescription}</>,
    },
    {
      label: localize('unitOfMeasure'),
      template: r => <>{r.itemRegisterInventoryUnitOfMeasureDescription}</>,
    },
    { label: localize('quantity'), template: r => <>{r.quantity}</> },
    { label: localize('unitCost'), template: r => <>{r.unitCost}</> },
    { label: localize('totalCost'), template: r => <>{r.totalCost}</> },
    { label: localize('daysToDeliver'), template: r => <>{r.daysToDeliver}</> },
  ]

  return (
    <>
      <SimpleTable selectables data={entries?.pageCollection} columns={columns} />
      <Pager
        itemsCount={entries.itemsCount}
        pageSize={reqParams.pageSize}
        pageIndex={reqParams.pageIndex}
        pageBatchSize={10}
        onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
      />
    </>
  )
}
