/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../Global/Localize'
import { batchStatus } from '../../../Global/enumeration'
import { formatDate } from '../../../Helpers/formatters'
import { getData } from '../../../Helpers/webApi'
import AddNewButton from '../../Buttons/AddNewButton/AddNewButton'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import DatePicker from '../../Date/DatePicker'
import Loader from '../../Loaders/Loader'
import RecordsPerPage from '../../RecordsPerPage/RecordsPerPage'
import Pager from '../../Tables/Pager'
import SimpleTable from '../../Tables/SimpleTable'
import EditReversalBatchModal from './EditReversalBatchModal/EditReversalBatchModal'
import ViewReversalBatchModal from './ViewReversalBatchModal/ViewReversalBatchModal'
import ViewAuditLogs from '../ViewAuditLogs/ViewAuditLogs'

const ListReversalBatches = ({
  status,
  showCreateButton,
  handleClickAddButton,
  canSelect,
  onSelect,
  showEdit,
  showVerify,
  handleVerifyReversal,
  showAuthorize,
  handleSelectAuthReversal,
  reload,
  setReload,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [reversals, setReversals] = useState([])
  const [reversal, setReversal] = useState({})
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    status: status ?? Object.keys(batchStatus)[0],
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [auditLogsDetails, setAuditLogsDetails] = useState({})

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('batchNumber'),
      template: r => r?.paddedBatchNumber,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('valueDate'),
      template: r => formatDate(r?.valueDate),
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('verification') + '/' + localize('rejectionRemarks'),
      template: r => r?.auditRemarks,
    },
    {
      label: localize('verified') + '/' + localize('rejectedBy'),
      template: r => r?.auditedBy,
    },
    {
      label: localize('verified') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.auditedDate),
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => r?.authorizationRemarks,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedBy'),
      template: r => r?.authorizedBy,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => formatDate(r?.authorizedDate),
    },
  ]

  const fetchReversals = async () => {
    setIsBusy(true)
    const url =
      '/JournalReversalBatch/find-journal-reversal-batch-by-status-and-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setReversals(data)
      if (reload) setReload(false)
    }

    setIsBusy(false)
  }

  const handleSelectReversalBatch = (r, action) => {
    if (
      action === 'edit' &&
      r?.status !== Object.keys(batchStatus)[0] &&
      r?.status !== Object.keys(batchStatus)[4]
    ) {
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Only batches with status Pending or Rejected can be edited',
      })
    }

    setReversal({
      ...reversal,
      ...r,
    })
    setMode(action)
  }

  const handleClose = () => {
    setMode(null)
    setReversal({})
  }

  useEffect(() => {
    fetchReversals()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageSize, reqParams.pageIndex])

  useEffect(() => {
    if (reload) fetchReversals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const handleViewAuditLogs = item => {
    setAuditLogsDetails(item)
    setMode('auditLogs')
  }

  return (
    <>
      {showCreateButton && (
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-start">
              <AddNewButton handleClickAddButton={handleClickAddButton} />
            </div>
          </div>
        </div>
      )}
      {mode === 'view' && (
        <ViewReversalBatchModal
          reversal={reversal}
          handleClose={handleClose}
          mode={mode}
        />
      )}

      {mode === 'edit' && (
        <EditReversalBatchModal
          reversal={reversal}
          handleClose={handleClose}
          mode={mode}
          fetchReversals={fetchReversals}
          setReversal={setReversal}
        />
      )}

      {mode === 'auditLogs' && (
        <ViewAuditLogs
          handleClose={handleClose}
          mode={mode}
          data={auditLogsDetails}
          title={auditLogsDetails?.paddedBatchNumber}
        />
      )}

      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3 d-flex align-items-center justify-content-between">
            <div className="col-md-3">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>

            <div className="col-md-2">
              <div className="d-flex align-items-center gap-2">
                <label htmlFor="status" className="text-capitalize">
                  {localize('status')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 150,
                    minWidth: 150,
                  }}
                  enumsKey="BatchStatus"
                  value={reqParams.status}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                      pageIndex: 0,
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex align-items-center gap-2">
              <label htmlFor="search" className=" text-capitalize">
                {localize('dateRange')}
              </label>
              <DatePicker
                value={{
                  startDate: reqParams.startDate,
                  endDate: reqParams.endDate,
                }}
                onSelect={v => {
                  if (v.custom) {
                    setReqParams({
                      ...reqParams,
                      customDate: true,
                      startDate: v.startDate,
                      endDate: v.endDate,
                    })
                  } else {
                    setReqParams({
                      ...reqParams,
                      customDate: false,
                      dateRequestsFilter: v.value,
                    })
                  }
                }}
              />
            </div>

            <div className="col-md-4">
              <div className="d-flex align-items-center justify-content-end">
                <label htmlFor="search" className="text-capitalize">
                  {localize('search')}
                </label>

                <input
                  type="search"
                  id="search"
                  className="form-control col ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchReversals()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>

          <SimpleTable
            columns={columns}
            data={reversals.pageCollection}
            canSelect={canSelect}
            onSelect={r => onSelect(r)}
            contextMenu={r => (
              <>
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleSelectReversalBatch(r, 'view')
                  }}
                >
                  <i className="uil uil-eye"></i>
                  <span>{localize('view')}</span>
                </div>
                {showEdit && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectReversalBatch(r, 'edit')
                    }}
                  >
                    <i className="uil uil-edit"></i>
                    <span>{localize('edit')}</span>
                  </div>
                )}
                {showVerify && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleVerifyReversal(r)
                    }}
                  >
                    <i className="mdi mdi-check-decagram"></i>
                    <span>{localize('verify')}</span>
                  </div>
                )}

                {showAuthorize && (
                  <div
                    className="ctxt-menu-item text-capitalize"
                    onClick={() => {
                      handleSelectAuthReversal(r)
                    }}
                  >
                    <i className="mdi mdi-lock-open-check-outline"></i>
                    <span>{localize('authorize')}</span>
                  </div>
                )}
                <div
                  className="ctxt-menu-item text-capitalize"
                  onClick={() => {
                    handleViewAuditLogs(r)
                  }}
                >
                  <i className="uil uil-server"></i>
                  <span>{localize('viewAuditLog')}</span>
                </div>
              </>
            )}
          />
          <Pager
            itemsCount={reversals.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
        </>
      )}
    </>
  )
}

export default ListReversalBatches
