/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../Global/Localize'
import {
  bankReconciliationEntryStatus,
  journalEntryFilter,
} from '../../../../../../Global/enumeration'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../Helpers/webApi'
import GLAccountSummary from '../../Reusables/GLAccountSummary/GLAccountSummary'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'

const Transactions = ({ bankReconciliationPeriodId }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedTransactions, setSelectedTransactions] = useState([])
  const [transactions, setTransactions] = useState([])
  const [bankReconciliationStatus, setBankReconciliationStatus] = useState({
    bankReconciliationEntryStatus: Object.keys(
      bankReconciliationEntryStatus
    )[0],
  })
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    tallyDebitCredits: false,
    journalEntryFilter: Object.keys(journalEntryFilter)[4],
  })

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <>
          <Checkbox
            checked={selectedTransactions?.find(x => x.id === r?.id)}
            onCheck={value => {
              if (value) setSelectedTransactions([...selectedTransactions, r])
              else
                setSelectedTransactions(
                  selectedTransactions?.filter(x => x.id !== r?.id)
                )
            }}
          />
        </>
      ),
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('branch'),
      template: r => r?.journalEntryJournalBranchDescription,
    },
    {
      label: localize('primaryDescription'),
      template: r => r?.journalEntryJournalPrimaryDescription,
    },
    {
      label: localize('amount'),
      template: r => formatCurrency(r?.journalEntryAmount),
      headerTextAlign: 'right',
    },
    {
      label: localize('secondaryDescription'),
      template: r => r?.journalEntryJournalSecondaryDescription,
    },
    {
      label: localize('reference'),
      template: r => r?.journalEntryJournalReference,
    },
    {
      label: localize('transactionCode'),
      template: r => r?.journalEntryJournalTransactionCodeDescription,
    },
    {
      label: localize('valueDate'),
      template: r => formatDate(r?.journalEntryValueDate, true),
    },
    {
      label: localize('transactedBy'),
      template: r => r?.journalEntryCreatedBy,
    },
    {
      label: localize('transactedDate'),
      template: r => formatDate(r?.journalEntryCreatedDate, true),
    },
    {
      label: localize('remarks'),
      template: r => r?.remarks,
    },
    {
      label: localize('modifiedBy'),
      template: r => r?.modifiedBy,
    },
    {
      label: localize('modifiedDate'),
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchTransactions = async () => {
    setIsBusy(true)

    const url =
      '/BankReconciliationPeriod/find-bank-reconciliation-entry-by-reconciliation-id-and-filter-in-page'

    const { data, success } = await getData(
      url,
      {
        ...reqParams,
        bankReconciliationPeriodId,
      },
      false
    )

    if (success) {
      setTransactions(data)
    }

    setIsBusy(false)
  }

  const handleUpdateTransactions = async () => {
    if (selectedTransactions.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Oops..',
        text: 'Please select at least one transaction to update',
      })
    setIsBusy(true)

    const url = '/BankReconciliationPeriod/update-bank-reconciliation-entries'

    const { success } = await putData(
      url,
      {
        bankReconciliationEntries: selectedTransactions,
        bankReconciliationEntryStatus:
          bankReconciliationStatus.bankReconciliationEntryStatus,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      await fetchTransactions()
      setSelectedTransactions([])
      showNotification('Update Bank Reconciliation Entries', 'success')
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (bankReconciliationPeriodId) fetchTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, bankReconciliationPeriodId])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3 mb-3 p-1">
            <RecordsPerPage
              className="col"
              reqParams={reqParams}
              setReqParams={setReqParams}
            />

            <div className="col d-flex align-items-center">
              <Checkbox
                id={`tallyDebitCredits-in`}
                checked={reqParams.tallyDebitCredits}
                onCheck={value => {
                  setReqParams({
                    ...reqParams,
                    tallyDebitCredits: value,
                  })
                }}
              />
              <label
                className="text-capitalize me-2"
                htmlFor="tallyDebitCredits"
              >
                {localize('tallyDebitCredits')}
              </label>
            </div>

            <div className="col d-flex align-items-center gap-2 justify-content-end">
              <div className="d-flex align-items-center">
                <label className="text-capitalize me-2" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 150,
                    minWidth: 150,
                  }}
                  enumsKey="JournalEntryFilter"
                  value={reqParams.journalEntryFilter}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      journalEntryFilter: e.value,
                    })
                  }
                  sort
                />
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchTransactions()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchTransactions()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            className="p-3"
            style={{
              maxHeight: 'calc(70dvh - 300px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable data={transactions.pageCollection} columns={columns} />
          </div>
          <Pager
            itemsCount={transactions.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />
          <div className="bg-light p-2 rounded">
            <div className="row mt-3 py-3">
              <div className="d-flex align-items-center col-3">
                <Checkbox
                  id="selectAll"
                  checked={
                    selectedTransactions?.length ===
                    transactions.pageCollection?.length
                  }
                  onCheck={value => {
                    if (value) {
                      setSelectedTransactions(transactions.pageCollection)
                    } else {
                      setSelectedTransactions([])
                    }
                  }}
                />
                <label className="ms-2" htmlFor="selectAll">
                  {localize('toggleSelection')}
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-end col-6 offset-3">
                <div className="d-flex align-items-center gap-2 justify-content-around">
                  <div className="d-flex align-items-center">
                    <label
                      className="text-capitalize"
                      htmlFor="bankReconciliationEntryStatus"
                    >
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="BankReconciliationEntryStatus"
                      value={
                        bankReconciliationStatus.bankReconciliationEntryStatus
                      }
                      onChange={e =>
                        setBankReconciliationStatus({
                          ...reqParams,
                          bankReconciliationEntryStatus: e.value,
                        })
                      }
                      sort
                    />
                  </div>
                  <button
                    className="btn btn-success float-end"
                    onClick={handleUpdateTransactions}
                  >
                    {localize('update')}
                  </button>
                </div>
              </div>
            </div>
            <GLAccountSummary summary={transactions} />
          </div>
        </>
      )}
    </>
  )
}

export default Transactions
