/** @format */
/**@REF
 *   Reference : https://mozilla.github.io/pdf.js/examples/
 *
 * */
import localize from '../../../Global/Localize'
import './preview-pdf.scss'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

export const PreviewPdf = ({ base64Pdf, displayValue }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [pdfDoc, setPdfDoc] = useState(null)
  const [pageNum, setPageNum] = useState(1)
  const [pageRendering, setPageRendering] = useState(false)
  const [pageNumPending, setPageNumPending] = useState(null)
  const [scale] = useState(1.5)
  const canvasRef = useRef(null)

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

  useEffect(() => {
    if (pdfDoc) {
      renderPage(pageNum)
    }
  }, [pdfDoc, pageNum])

  const processPdf = () => {
    let pdfData
    if (typeof base64Pdf === 'string') {
      if (base64Pdf.startsWith('data:application/pdf')) {
        pdfData = new Uint8Array(
          atob(base64Pdf.split(',')[1])
            .split('')
            .map(char => char.charCodeAt(0))
        )
      }
    }

    pdfjsLib.getDocument({ data: pdfData }).promise.then(pdfDoc_ => {
      setPdfDoc(pdfDoc_)
    })
  }

  const renderPage = num => {
    if (!pdfDoc) return
    setPageRendering(true)
    // Using promise to fetch the page
    pdfDoc.getPage(num).then(page => {
      let viewport = page.getViewport({ scale })
      let canvas = canvasRef.current
      let context = canvas.getContext('2d')
      canvas.height = viewport.height
      canvas.width = viewport.width
      canvas.style.marginTop = '15px'

      // Render PDF page into canvas context
      let renderContext = {
        canvasContext: context,
        viewport: viewport,
      }
      let renderTask = page.render(renderContext)

      // Wait for rendering to finish
      renderTask.promise.then(() => {
        setPageRendering(false)
        if (pageNumPending !== null) {
          // New page rendering is pending
          renderPage(pageNumPending)
          setPageNumPending(null)
        }
      })
    })
  }

  const queueRenderPage = num => {
    if (pageRendering) {
      setPageNumPending(num)
    } else {
      setPageNum(num)
    }
  }

  const onPrevPage = () => {
    if (pageNum <= 1) {
      return
    }
    setPageNum(pageNum - 1)
    queueRenderPage(pageNum - 1)
  }

  const onNextPage = () => {
    if (pageNum >= pdfDoc.numPages) {
      return
    }
    setPageNum(pageNum + 1)
    queueRenderPage(pageNum + 1)
  }

  return (
    <>
      <label
        htmlFor={'preview-pdf'}
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            // maxWidth: '61%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <label
          onClick={() => {
            setShowPreviewModal(true)
            processPdf()
          }}
          htmlFor={'preview-pdf'}
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
        >
          <i className="uil uil-file-check-alt"></i>
        </label>
      </label>
      {showPreviewModal && (
        <div tabIndex="0" className="vac-media-preview">
          <div className="vac-media-preview-container">
            <div className="btn-group" role="group" aria-label="Basic example">
              <div
                id="preview-previous-page"
                onClick={onPrevPage}
                className="btn btn-primary"
              >
                <i className="uil-backward"></i>
                {localize('previous')}
              </div>
              <div
                id="preview-next-page"
                className="btn btn-primary"
                onClick={onNextPage}
              >
                <i className="uil-forward"></i>
                {localize('next')}
              </div>
              <div id="finally-print" className="btn btn-primary">
                <i className="uil-print"></i>
                {localize('print')}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <span className="text-white h4" id="page_num">
                {pageNum}
              </span>
              <span className="text-white h3" id="page_count">
                {pageNumPending}
              </span>
            </div>

            <div className="d-flex justify-content-center">
              <div id="canvas-container">
                <canvas ref={canvasRef} />
              </div>
            </div>
          </div>
          <div
            className="vac-svg-button"
            onClick={() => setShowPreviewModal(false)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                id="vac-icon-close-outline-preview"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              ></path>
            </svg>
          </div>
        </div>
      )}
    </>
  )
}
