/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import Loader from '../../../../../Components/Loaders/Loader'
import { getData } from '../../../../../Helpers/webApi'
import { useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import Pager from '../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import DatePicker from '../../../../../Components/Date/DatePicker'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'

const CashManagementRequests = () => {
  const [requests, setRequests] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    status: 1,
  })

  const columns = [
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
    {
      label: localize('status'),
      template: r => r.statusDescription,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('transactionType'),
      template: r => r.treasuryTrailTypeDescription,
    },
    {
      label: localize('transactionSource'),
      template: r => r.treasuryTrailSourceDescription,
    },
    {
      label: localize('transactionDestination'),
      template: r => r.treasuryTrailDestinationDescription,
    },
    {
      label: localize('primaryDescription'),
      template: r => r.primaryDescription,
    },
    {
      label: localize('secondaryDescription'),
      template: r => r.secondaryDescription,
    },
    { label: localize('reference'), template: r => r.reference },
    {
      label: localize('variance'),
      template: r => formatCurrency(r.variance),
    },
    {
      label: localize('varianceKind'),
      template: r => r.varianceKindDescription,
    },
    {
      label: localize('denominationOneThousandValue'),
      template: r => formatCurrency(r.denominationOneThousandValue),
    },
    {
      label: localize('denominationFiveHundredValue'),
      template: r => formatCurrency(r.denominationFiveHundredValue),
    },
    {
      label: localize('denominationTwoHundredValue'),
      template: r => formatCurrency(r.denominationTwoHundredValue),
    },
    {
      label: localize('denominationOneHundredValue'),
      template: r => formatCurrency(r.denominationOneHundredValue),
    },
    {
      label: localize('denominationFiftyValue'),
      template: r => formatCurrency(r.denominationFiftyValue),
    },
    {
      label: localize('denominationFourtyValue'),
      template: r => formatCurrency(r.denominationFourtyValue),
    },
    {
      label: localize('denominationTwentyValue'),
      template: r => formatCurrency(r.denominationTwentyValue),
    },
    {
      label: localize('denominationTenValue'),
      template: r => formatCurrency(r.denominationTenValue),
    },
    {
      label: localize('denominationFiveValue'),
      template: r => formatCurrency(r.denominationFiveValue),
    },
    {
      label: localize('denominationOneValue'),
      template: r => formatCurrency(r.denominationOneValue),
    },
    {
      label: localize('denominationFiftyCentValue'),
      template: r => formatCurrency(r.denominationFiftyCentValue),
    },
    {
      label: localize('totalValue'),
      template: r => formatCurrency(r.totalValue),
    },
    {
      label: localize('postingPeriod'),
      template: r => r.postingPeriodDescription,
    },
    {
      label: localize('authorization') + '/' + localize('rejectionRemarks'),
      template: r => r.authorizationRemarks,
    },
    {
      label: localize('authorizedBy') + '/' + localize('rejectedBy'),
      template: r => r.authorizedBy,
    },
    {
      label: localize('authorized') + '/' + localize('rejectedDate'),
      template: r => formatDate(r.authorizedDate),
    },
    { label: localize('postedBy'), template: r => r.postedBy },
    {
      label: localize('postedDate'),
      template: r => formatDate(r.postedDate),
    },
    { label: localize('createdBy'), template: r => r.createdBy },
  ]

  const fetchCashManagementRequests = async () => {
    setIsBusy(true)

    const url =
      '/TreasuryManagementRequest/find-treasury-management-requests-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)

    if (success) {
      setRequests(data)
      setIsBusy(false)
    } else {
      setIsBusy(false)
    }
  }

  useEffect(() => {
    fetchCashManagementRequests()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('frontOfficeManagement'),
          localize('treasury'),
          localize('cashManagementRequests'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between mb-3 gap-1">
                <div className="d-flex align-items-center">
                  <label
                    style={{
                      minWidth: 'fit-content',
                      maxWidth: 'fit-content',
                    }}
                    className="text-capitalize"
                  >
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-1"
                    style={{ maxWidth: 'fit-content' }}
                    value={reqParams.pageSize}
                    onChange={e =>
                      setReqParams({ ...reqParams, pageSize: e.target.value })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div
                  className="col d-flex align-items-center gap-2"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('status')}
                  </label>
                  <EnumsServerCombo
                    sort
                    customContainerStyle={{
                      maxWidth: 130,
                      minWidth: 130,
                    }}
                    value={reqParams?.status}
                    enumsKey="TreasuryManagementRequestStatus"
                    onChange={({ value }) =>
                      setReqParams({ ...reqParams, status: value })
                    }
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-2"
                  >
                    {localize('dateRange')}
                  </label>
                  <DatePicker
                    value={{
                      startDate: reqParams?.startDate,
                      endDate: reqParams?.endDate,
                    }}
                    onSelect={v => {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    }}
                  />
                </div>
                <div
                  className="col d-flex align-items-center gap-1"
                  style={{ maxWidth: 'fit-content' }}
                >
                  <label
                    style={{ minWidth: 'fit-content' }}
                    className="text-capitalize me-1"
                  >
                    {localize('search')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reqParams?.text}
                    onChange={e =>
                      setReqParams({
                        ...reqParams,
                        text: e.target.value,
                        pageIndex: 0,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        fetchCashManagementRequests()
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      fetchCashManagementRequests()
                    }}
                  >
                    <i className="uil uil-filter"></i>
                  </button>
                </div>
              </div>
              <SimpleTable columns={columns} data={requests?.pageCollection} />
              <Pager
                itemsCount={requests.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={10}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CashManagementRequests
