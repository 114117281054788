/** @format */

import React, { useState } from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import Loader from '../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { getCommissions } from '../../../../../Helpers/selectablesHelper'
import { getData, postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import ApplicableCharges from '../../ApplicableCharges/ApplicableCharges'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { useEffect } from 'react'

const CreateMorgueService = ({
  mode,
  handleClose,
  morgueService,
  setMorgueService,
  fetchData,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [commissions, setCommissions] = useState([])
  const [selectedCommissions, setSelectedCommissions] = useState([])
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const fetchCommissions = async () => {
    setIsBusy(true)
    const data = await getCommissions()
    setCommissions(data)
    setIsBusy(false)
  }

  const handleAddOrUpdate = async () => {
    setIsBusy(true)
    const message =
      mode === 'add' ? 'Create Morgue Services' : 'Update Morgue Services'
    const url =
      mode === 'add'
        ? '/MorgueService/add-morgue-service-with-commissions'
        : '/MorgueService/update-morgue-service-with-commissions'

    const { success } = await postData(
      url,
      {
        MorgueService: { ...morgueService },
        CommissionDtos: selectedCommissions,
      },
      false
    )

    if (success) {
      handleClose()
      setSelectedCommissions([])
      fetchData()
      showNotification(message, 'success')
    }

    setIsBusy(false)
  }

  const fetchCommissionsToBeSelected = async () => {
    setIsBusy(true)

    const url = '/MorgueService/find-commissions-by-morgue-service-id'

    const { data, success } = await getData(
      url,
      {
        morgueServiceId: morgueService?.id,
      },
      false
    )

    if (success) {
      setSelectedCommissions(data?.result)
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectCommissions = (checkedCommissions, value) => {
    if (value) {
      setSelectedCommissions([...selectedCommissions, checkedCommissions])
    } else {
      setSelectedCommissions(
        selectedCommissions.filter(c => c.id !== checkedCommissions.id)
      )
    }
  }

  useEffect(() => {
    fetchCommissions()
  }, [])

  useEffect(() => {
    if (morgueService?.id) {
      fetchCommissionsToBeSelected()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [morgueService?.id])

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="morgueService"
      disableClose={isBusy}
      disableCreate={isBusy}
      handleAction={handleAddOrUpdate}
      handleClose={handleClose}
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            disabled={isBusy}
            id="morgueServiceIsLocked"
            checked={morgueService?.isLocked}
            onCheck={e => {
              setMorgueService({
                ...morgueService,
                isLocked: e,
              })
            }}
          />
          <label
            className="ms-2 me-4 text-capitalize"
            htmlFor="morgueServiceIsLocked"
          >
            {localize('isLocked')} ?
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div className="col-md-12">
              <div
                className="d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['MorgueService.Description']?.message
                }
              >
                <label className="text-capitalize col-1">
                  {localize('name')}
                </label>
                <input
                  type="text"
                  className="form-control ms-2"
                  value={morgueService?.description}
                  onChange={e => {
                    clearValidation('MorgueService.Description')
                    setMorgueService({
                      ...morgueService,
                      description: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <Tab
              tabItems={[
                {
                  label: localize('applicableCharges'),
                  item: isBusy ? (
                    <Loader />
                  ) : (
                    <ApplicableCharges
                      commissions={commissions}
                      selectedCommissions={selectedCommissions}
                      handleSelectCommissions={handleSelectCommissions}
                    />
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default CreateMorgueService
