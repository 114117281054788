/** @format */

/* eslint-disable */
import React from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import BatchEntriesTab from '../../../../../../Components/ReUsables/BatchEntries/WithdrawalSettlementBatchEntriesTab/BatchEntriesTab'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { parseDate } from '../../../../../../Helpers/formatters'

function ViewBatchModal({ handleClose, batchOrigination, title, mode }) {
  return (
    <ModalTemplate
      modalTitle={title}
      hideUpdate
      handleClose={handleClose}
      modalClassName="modal-xl"
      modalMode={mode}
    >
      <div className="row py-2 g-3">
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="branch">
              {localize('batch#')}
            </label>

            <input
              className="form-control"
              type="text"
              defaultValue={batchOrigination.batchNumber}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="category">
              {localize('category')}
            </label>
            <input
              type="text"
              className="form-control"
              id="category"
              value={batchOrigination?.categoryDescription}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="branch">
              {localize('branch')}
            </label>
            <input
              className="form-control"
              type="text"
              defaultValue={batchOrigination.branchDescription}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="priority">
              {localize('priority')}
            </label>
            <EnumsServerCombo
              disabled
              customContainerStyle={{ width: '100%' }}
              enumsKey="QueuePriority"
              value={batchOrigination?.priority}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="reference">
              {localize('reference')}
            </label>
            <input
              className={`form-control`}
              id="reference"
              value={batchOrigination?.reference}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="createdBy">
              {localize('createdBy')}
            </label>
            <input
              className={`form-control`}
              id="createdBy"
              value={batchOrigination?.createdBy}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="createdDate">
              {localize('createdDate')}
            </label>
            <input
              type="date"
              className={`form-control`}
              id="createdDate"
              value={parseDate(batchOrigination?.createdDate)}
              disabled
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label
              className="text-capitalize col-3"
              htmlFor="statusDescription"
            >
              {localize('statusDescription')}
            </label>
            <input
              type="text"
              className={`form-control`}
              id="statusDescription"
              value={batchOrigination?.statusDescription}
              disabled
            />
          </div>
        </div>

        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="verifiedBy">
              {localize('verifiedBy')}
            </label>
            <input
              type="text"
              className={`form-control`}
              id="verifiedBy"
              disabled
              value={batchOrigination?.auditedBy}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="authorizedDate">
              {localize('authorizedDate')}
            </label>
            <input
              type="text"
              className={`form-control`}
              id="authorizedDate"
              disabled
              value={batchOrigination?.authorizedDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label
              className="text-capitalize col-3"
              htmlFor="authorizationRemarks"
            >
              {localize('authorizationRemarks')}
            </label>
            <input
              type="text"
              className={`form-control`}
              id="authorizationRemarks"
              disabled
              value={batchOrigination?.authorizationRemarks}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="authorizedBy">
              {localize('authorizedBy')}
            </label>
            <input
              type="text"
              className={`form-control`}
              id="authorizedBy"
              disabled
              value={batchOrigination?.authorizedBy}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label className="text-capitalize col-3" htmlFor="verifiedDate">
              {localize('verifiedDate')}
            </label>
            <input
              type="text"
              className={`form-control`}
              id="verifiedDate"
              disabled
              value={batchOrigination?.auditedDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex align-items-center">
            <label
              className="text-capitalize col-3"
              htmlFor="verificationRemarks"
            >
              {localize('verificationRemarks')}
            </label>
            <input
              type="text"
              className={`form-control`}
              id="verificationRemarks"
              disabled
              value={batchOrigination?.auditRemarks}
            />
          </div>
        </div>
      </div>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize"
          style={{
            position: 'relative',
            top: -20,
            left: 30,
            maxWidth: 'fit-content',
          }}
        >
          {localize('batchDetails')}
        </div>
        <div className="row py-2 g-3">
          <Tab
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <div
                    style={{
                      maxHeight: '50dvh',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    <BatchEntriesTab
                      showReset
                      withdrawalSettlementBatchId={
                        batchOrigination?.id || batchOrigination?.batchId
                      }
                      hiddenColumns={[0]}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </ModalTemplate>
  )
}

export default ViewBatchModal
