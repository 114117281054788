/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { ViewCustomerAccountStatement } from '../../../../../../Components/ReUsables/Customer/CustomerAccountStatement/ViewCustomerAccountStatement'
import { getData } from '../../../../../../Helpers/webApi'

const ViewCashWithdrawalRequest = ({ request, mode, handleClose }) => {
  const [customer, setCustomer] = useState({})
  const [customerAccount, setCustomerAccount] = useState({})

  const fetchCustomer = async () => {
    const url = '/Customer/find-customer-by-id'
    const { success, data } = await getData(
      url,
      {
        customerId: request?.customerAccountCustomerId,
      },
      false
    )

    if (success) {
      setCustomer(data?.customer)
    }
  }

  const fetchCustomerAccount = async () => {
    const url = '/CustomerAccount/find-customer-account'

    const { success, data } = await getData(
      url,
      {
        customerAccountId: request?.customerAccountId,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setCustomerAccount(data)
    }
  }

  useEffect(() => {
    if (request?.customerAccountCustomerId) fetchCustomer()
    if (request?.customerAccountCustomerId) fetchCustomerAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request?.customerAccountCustomerId, request?.customerAccountId])


  return (
    <ModalTemplate
      modalTitle="viewCustomerAccountStatement"
      cancelText="close"
      hideUpdate
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
    >
      <ViewCustomerAccountStatement
        selectedCustomer={customer}
        selectedCustomerAccount={customerAccount}
      />
    </ModalTemplate>
  )
}

export default ViewCashWithdrawalRequest
