/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import localize from '../../../../Global/Localize'
import Walkthrough from '../../../../Components/Walkthrough/Walkthrough'
import BasicDetails from '../Partials/Customers/AddCustomerScreens/BasicDetails'
import { getData, postData, putData } from '../../../../Helpers/webApi'
import Swal from 'sweetalert2'
import Specimen from '../Partials/Customers/AddCustomerScreens/Specimen'
import Signatories from './Partials/Signatories'
import NextOfKin from './Partials/NextOfKin'
import { batchAuthOptions } from '../../../../Global/enumeration'
import EnumsServerCombo from '../../../../Components/ComboBox/EnumsServerCombo'

function ReceiveEnrollment({ mode, customer, closeModal, readOnly }) {
  const [activePage, setActivePage] = useState(0)
  const [selectedCustomer, setSelectedCustomer] = useState(customer)

  useEffect(() => {
    loadCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function moveNextPage() {
    setActivePage(activePage + 1)
  }

  function movePrevPage() {
    setActivePage(activePage - 1)
  }

  async function loadCustomer() {
    // eslint-disable-next-line eqeqeq
    if (mode != 'add') {
      const { success, data } = await getData(
        '/enrollmentRequest/find-enrollment-request-by-id',
        { enrollmentRequestId: customer?.id }
      )
      if (success) {
        setSelectedCustomer(data)
      }
    }
  }

  const pages = [
    {
      title: localize('details'),
      icon: '',
      component: (
        <BasicDetails
          enrollment
          mode={mode}
          readOnly={readOnly}
          onChange={c => setSelectedCustomer({ ...c })}
          customer={selectedCustomer}
        />
      ),
      skippable: !!selectedCustomer?.id,
      hidden: false,
      actions: (
        <>
          <button
            className="btn btn-primary ms-2"
            disabled={!selectedCustomer?.id}
            onClick={moveNextPage}
          >
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>
          <button
            className="btn btn-success ms-5"
            onClick={enroll}
            disabled={readOnly}
          >
            <i className="uil uil-check"></i>
            {localize(mode === 'add' ? 'receive' : 'update')}
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-2">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('signatories'),
      icon: '',
      component: (
        <Signatories readOnly={readOnly} customer={selectedCustomer} />
      ),
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('previous')}
          </button>
          <button className="btn btn-primary ms-2" onClick={moveNextPage}>
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>
          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('nextOfKin'),
      icon: '',
      component: (
        <NextOfKin readOnly={readOnly} enrollment={selectedCustomer} />
      ),
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('previous')}
          </button>
          <button className="btn btn-primary ms-2" onClick={moveNextPage}>
            {localize('next')}
            <i className="uil uil-angle-right"></i>
          </button>
          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
    {
      title: localize('specimen'),
      icon: '',
      component: <Specimen readOnly={readOnly} customer={selectedCustomer} />,
      skippable: true,
      hidden: false,
      actions: (
        <>
          <button className="btn btn-primary ms-2" onClick={movePrevPage}>
            <i className="uil uil-angle-left"></i>
            {localize('previous')}
          </button>

          <button onClick={closeModal} className="btn btn-danger ms-5">
            {localize('cancel')}
          </button>
        </>
      ),
    },
  ]

  const [authOption, setAuthOption] = useState(1)

  async function verify() {
    const { success, data } = await putData(
      `/enrollmentRequest/${
        mode === 'verify' ? 'audit' : 'authorize'
      }-enrollment-request?batchAuthOption=${authOption}`,
      {
        ...selectedCustomer,
        // @TODO: retrieve this data after wiring the auth server
        branchId: 'bd620647-beb1-ec11-8e04-000c29eb2ef3',
        branchNumberingSeriesId: '4cc6f560-7f0b-ee11-9d58-ec5c683ffd00',
      },
      true
    )

    if (success) {
      let message =
        mode === 'verify'
          ? 'Enrollment Verified!'
          : `Full Name: ${data.fullName}<br />Member Number: ${data.formattedMemberNumber}<br />Account Number: ${data.formattedAccountNumber}`
      Swal.fire('Operation Successful', message, 'success')
    }
  }

  async function enroll() {
    const payload = {
      ...selectedCustomer,
      // @TODO: retrieve this data after wiring the auth server
      branchId: '075b6f6c-60ea-ed11-b0bb-000c29d4e11d',
      branchNumberingSeriesId: '6e08a9ef-a70e-ee11-b0bd-000c29d4e11d',
    }
    const { success, data } =
      // eslint-disable-next-line eqeqeq
      mode == 'add'
        ? await postData(
            '/enrollmentRequest/create-enrollment-request',
            payload
          )
        : await putData('/enrollmentRequest/update-enrollment-request', payload)
    if (success) {
      setSelectedCustomer(data)
      const payloadString =
        mode === 'add' ? 'Enrollment Created!' : 'Enrollment Updated!'
      //   Swal.fire('Operation Successful', payloadString, 'success')
      Swal.fire({
        title: 'Operation Successful',
        html: payloadString,
        icon: 'success',
      })
      setActivePage(activePage + 1)
    }
  }

  return (
    <Modal isOpen className="modal-xl ">
      <ModalBody className="bg-white">
        <div className="text-capitalize d-flex align-items-center mb-2">
          <h4 style={{ marginTop: 5 }}>
            {localize(mode === 'add' ? 'receive' : mode)}
          </h4>
          <i className="uil uil-angle-right mx-2"></i>
          <span className="text-muted fs-6 text-uppercase">
            {localize('enrollment')}
          </span>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Walkthrough
          noActions={mode === 'verify' || mode === 'authorize'}
          pages={pages}
          activePage={activePage}
          changePage={p => setActivePage(p)}
        />
        {(mode === 'verify' || mode === 'authorize') && (
          <div
            className="p-2 d-flex align-items-center justify-content-end mt-2"
            style={{
              // border: 'solid 1px black',
              background: '#5b73e834',
              borderRadius: 5,
              // boxShadow: '#0004 0 0 20px',
            }}
          >
            <label htmlFor="" className="text-capitalize">
              {localize('remarks')}
            </label>
            <input
              className=" form-control ms-1"
              style={{ maxWidth: 200 }}
              placeholder="Enter Text..."
              value={
                mode === 'verify'
                  ? customer?.auditRemarks
                  : customer?.authorizationRemarks
              }
              onChange={e =>
                setSelectedCustomer({
                  ...customer,
                  [mode === 'verify' ? 'auditRemarks' : 'authorizationRemarks']:
                    e.target.value,
                })
              }
            ></input>
            <EnumsServerCombo
              customContainerStyle={{ width: '100%' }}
              enumsKey="BatchAuthOptions"
              value={authOption}
              onChange={e => setAuthOption(e.value)}
            />
            <button className="btn px-5 btn-success ms-3" onClick={verify}>
              {localize('update')}
            </button>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ReceiveEnrollment
