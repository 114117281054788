/** @format */
import localize from '../../../../../Global/Localize'
import BankLinkageLookup from '../../../../../Components/Lookup/BankLinkageLookup'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import Loader from '../../../../../Components/Loaders/Loader'
import Tab from '../../../../../Components/Tabs/Tab'
import { BankersChequeBookCommissions } from './BankersChequeBookCommissions'

export const AddBankersChequeBookModal = ({
  selectedBankersChequeBook,
  setSelectedBankersChequeBook,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()
  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['BankersChequeBook.BankLinkageId']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('bank')}
              </label>
              <BankLinkageLookup
                displayValue={selectedBankersChequeBook?.bankLinkageBankName}
                onSelect={r => {
                  clearValidation('BankersChequeBook.BankLinkageId')
                  setSelectedBankersChequeBook({
                    ...selectedBankersChequeBook,
                    bankLinkageId: r.id,
                    bankLinkageBankName: r.bankName,
                    bankLinkageBankBranchName: r.bankBranchName,
                    bankLinkageChartOfAccountName: r.chartOfAccountName,
                    bankLinkageBankLinkageDescription: r.description,
                    bankLinkageBranchDescription: r.branchDescription,
                  })
                }}
              />
            </div>
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('bankBranch')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  selectedBankersChequeBook?.bankLinkageBranchDescription
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('chartOfAccountName')}
              </label>
              <input
                className="form-control"
                disabled
                defaultValue={
                  selectedBankersChequeBook?.bankLinkageChartOfAccountName
                }
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['BankersChequeBook.NumberOfLeaves']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('numberOfLeaves')}
              </label>
              <MaskNumber
                numeralThousandsGroupStyle="none"
                defaultMaskValue={selectedBankersChequeBook?.numberOfLeaves}
                onMaskNumber={r => {
                  clearValidation('BankersChequeBook.NumberOfLeaves')
                  setSelectedBankersChequeBook({
                    ...selectedBankersChequeBook,
                    numberOfLeaves: r.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('remarks')}
              </label>
              <input
                className="form-control"
                defaultValue={selectedBankersChequeBook?.remarks}
                onChange={e => {
                  setSelectedBankersChequeBook({
                    ...selectedBankersChequeBook,
                    remarks: e.target.value,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={
                validationBag?.['BankersChequeBook.InitialLeafNumber']?.message
              }
            >
              <label className="col-3 text-capitalize">
                {localize('initialLeafNumber')}
              </label>
              <MaskNumber
                numeralThousandsGroupStyle="none"
                defaultMaskValue={selectedBankersChequeBook?.initialLeafNumber}
                onMaskNumber={r => {
                  clearValidation('BankersChequeBook.InitialLeafNumber')
                  setSelectedBankersChequeBook({
                    ...selectedBankersChequeBook,
                    initialLeafNumber: r.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Tab
                tabItems={[
                  {
                    label: localize('applicableCharges'),
                    item: (
                      <BankersChequeBookCommissions
                        setSelectedBankersChequeBook={
                          setSelectedBankersChequeBook
                        }
                        prefix={'create-bankers-cheque-book'}
                        selectedBankersChequeBook={selectedBankersChequeBook}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
