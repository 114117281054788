/** @format */

import TallyCounter from '../../../../../../../../Components/TallyCounter/TallyCounter'
import { useEffect, useState } from 'react'
import localize from '../../../../../../../../Global/Localize'
import { formatCurrency } from '../../../../../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../../../../../Validation/ValidationState'

export const CustomerAccountTransactionCashDeposit = ({
  onGetTotalValue,
  selectedTransactionType,
  transactionModel,
  setTransactionModel,
}) => {
  const [resetValues, setResetValues] = useState(false)
  const [currencyTotalValue, setCurrencyTotalValue] = useState(0)

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = key => {
    setValidationBag(g => {
      delete g[key]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [selectedTransactionType])

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-4">
          <TallyCounter
            setResetValues={setResetValues}
            resetValues={resetValues}
            getTally={() => {}}
            getTotal={v => {
              onGetTotalValue(v)
              clearValidation('CurrencyCountModel.Total')
              setCurrencyTotalValue(v)
            }}
          />
        </div>
        <div className="col-md-8">
          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2 row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 120,
              }}
            >
              {localize('cashDepositDetails')}
            </div>
            <div style={{ marginTop: -10 }} className="row">
              <div
                validation-message={
                  validationBag?.['CurrencyCountModel.Total']?.message
                }
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('amount')}
                </label>
                <input
                  className="form-control"
                  disabled
                  value={formatCurrency(currencyTotalValue, 2, 2, false)}
                />
              </div>
            </div>
            <div style={{ marginTop: -10 }} className="row mt-2">
              <div
                validation-message={validationBag?.Reference?.message}
                className="col d-flex align-items-center with-validation"
              >
                <label className="col-3 text-capitalize">
                  {localize('reference')}
                </label>
                <input
                  className="form-control"
                  onChange={e => {
                    clearValidation('Reference')
                    setTransactionModel({
                      ...transactionModel,
                      reference: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
