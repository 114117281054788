/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import localize from '../../../../../../Global/Localize'
import { RefundBatchLookup } from '../../../../../../Components/Lookup/RefundBatchLookup'
import { QueuePriority } from '../../../../../../Global/enumeration'
import { RefundBatchEntryDetails } from './RefundBatchEntryDetails'
import { RefundBatchEntries } from './RefundBatchEntries'
import Tab from '../../../../../../Components/Tabs/Tab'
import { MismatchedImportRefundBatchEntriesViewMode } from './MismatchedImportEntries'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const EditRefundBatchModal = ({
  selectedRefundBatch,
  setSelectedRefundBatch,
}) => {
  const [refreshBatchEntries, setRefreshBatchEntries] = useState(false)
  const [addImportBatchDetailsMode, setAddImportBatchDetailsMode] =
    useState(null)
  const handleOnClickAddBatchDetails = () => {
    setAddImportBatchDetailsMode('add')
  }

  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('batchNumber')}
          </label>
          <RefundBatchLookup
            displayValue={selectedRefundBatch?.paddedBatchNumber}
            onSelect={r => {
              setSelectedRefundBatch({ ...selectedRefundBatch, id: r.id })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('referenceMonth')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.monthDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('valueDate')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.valueDate}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('postingPeriod')}
          </label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.postingPeriodDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('branch')}</label>
          <input
            className="form-control"
            disabled
            value={selectedRefundBatch?.branchDescription}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('priority')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="QueuePriority"
            value={selectedRefundBatch?.priority}
            onChange={e => {
              setSelectedRefundBatch({
                ...selectedRefundBatch,
                priority: e.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-4 d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('reference')}
          </label>
          <input className="form-control" />
        </div>
      </div>

      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div
          className="bg-white px-2 row text-capitalize py-2"
          style={{ position: 'relative', top: -20, left: 30, maxWidth: 120 }}
        >
          {localize('creditBatch')} {localize('entryDetails')}
        </div>
        <RefundBatchEntryDetails
          selectedRefundBatch={selectedRefundBatch}
          refreshRefundBatchEntries={() => {
            setRefreshBatchEntries(!refreshBatchEntries)
          }}
        />
      </div>

      <div className="row mb-3 mt-3">
        <div className="col-md-12">
          <Tab
            preload
            tabItems={[
              {
                label: localize('batchEntries'),
                item: (
                  <RefundBatchEntries
                    selectedRefundBatchEntry={selectedRefundBatch}
                    refreshRefundBatchEntries={refreshBatchEntries}
                    onSelectRefundBatchEntry={r => {
                      console.log(r)
                    }}
                  />
                ),
              },
              {
                label: localize('mismatchedImportEntries'),
                item: (
                  <MismatchedImportRefundBatchEntriesViewMode
                    selectedBatchEntry={selectedRefundBatch}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

// export const AddImportBatchDetails = ({
//   mode,
//   onHideModal,
//   onUpdateRefreshAt,
//   onAddNewBatchEntry,
//   onSetMode,
//   selectedRefundBatch,
// }) => {
//   const ref = useRef(null)
//   const [selectedCustomerAccount, setSelectedCustomerAccount] = useState(null)
//   const [batchEntryDetailsModel, setBatchEntryDetailsModel] = useState({
//     creditBatchId: selectedRefundBatch.id,
//   })
//   const [fileName, setFileName] = useState('')
//   const [reload, setReload] = useState(false)
//   const [showImportModal, setShowImportModal] = useState(false)
//   const [newUpload, setNewUpload] = useState(false)
//   const [mismatchedEntries, setMismatchedEntries] = useState([])
//
//   const setCreditBatchReference = r => {
//     switch (selectedRefundBatch.type) {
//       case Object.keys(CreditBatchType)[0]:
//         setBatchEntryDetailsModel({
//           ...batchEntryDetailsModel,
//           reference: r.customerReference1,
//         })
//         break
//       case Object.keys(CreditBatchType)[1]:
//         setBatchEntryDetailsModel({
//           ...batchEntryDetailsModel,
//           reference: r.customerReference3,
//         })
//         break
//       case Object.keys(CreditBatchType)[4]:
//         setBatchEntryDetailsModel({
//           ...batchEntryDetailsModel,
//           reference: r.customerReference1,
//         })
//         break
//       case Object.keys(CreditBatchType)[5]:
//         setBatchEntryDetailsModel({
//           ...batchEntryDetailsModel,
//           reference: r.customerReference1,
//         })
//         break
//       default:
//         break
//     }
//   }
//
//   const handleClickImportButton = () => {
//     setShowImportModal(true)
//     setNewUpload(true)
//   }
//
//   const handleFileUploadToServer = async () => {
//     const url = `/CreditBatch/parse-credit-batch-import`
//
//     const { success, data } = await putData(
//       url,
//       {
//         creditBatchId: selectedRefundBatch?.id,
//         fileName: fileName?.fileName,
//         buffer: fileName?.buffer,
//       },
//       true,
//       'Batch entries imported successfully'
//     )
//
//     if (success) {
//       setReload(true)
//       setFileName('')
//       setMismatchedEntries(data?.result)
//       setShowImportModal(false)
//       setNewUpload(false)
//     }
//   }
//
//   const handleUpload = async () => {
//     ref?.current?.click()
//
//     ref.current.onchange = async e => {
//       let file = e.target.files[0]
//       try {
//         let b64 = await toBase64(file)
//         let buffer = b64.split(',')[1].toString()
//         setFileName({
//           buffer,
//           fileName: file.name,
//           fileExtension: 'csv',
//         })
//       } catch {
//         Swal.fire('Invalid File', 'Error loading file', 'error')
//         return
//       }
//     }
//   }
//
//   async function handleSubmit() {
//     if (!batchEntryDetailsModel?.customerAccountId) {
//       Swal.fire({
//         icon: 'error',
//         text: 'Customer Account Is Invalid',
//         title: 'Add Credit Batch Entry Details',
//         showCloseButton: true,
//       })
//       return
//     }
//     const { success } = await postData(
//       '/CreditBatch/create-credit-batch-entry',
//       { ...batchEntryDetailsModel },
//       true,
//       'Add Credit Batch Entry'
//     )
//
//     if (success) {
//       onSetMode(null)
//       setSelectedCustomerAccount(null)
//       setBatchEntryDetailsModel({
//         creditBatchId: selectedRefundBatch.id,
//       })
//     }
//   }
//
//   useEffect(() => {
//     if (fileName) handleFileUploadToServer()
//
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [fileName, selectedRefundBatch?.id, newUpload])
//
//   return (
//     <>
//       <>
//         <div className="row mb-3">
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('fullAccountNumber')}
//             </label>
//             <CustomerAccountLookup
//               displayValue={selectedCustomerAccount?.fullAccountNumber}
//               onSelect={r => {
//                 setCreditBatchReference(r)
//                 setSelectedCustomerAccount(r)
//                 setBatchEntryDetailsModel({
//                   ...batchEntryDetailsModel,
//                   customerAccountId: r.id,
//                 })
//               }}
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('accountStatus')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={selectedCustomerAccount?.statusDescription || ''}
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('accountRemarks')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={selectedCustomerAccount?.remarks || ''}
//             />
//           </div>
//         </div>
//         <div className="row mb-3">
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('customerName')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={selectedCustomerAccount?.customerFullName || ''}
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('customerType')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={
//                 selectedCustomerAccount?.customerMembershipClassCustomerTypeDescription ||
//                 ''
//               }
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('customerMemberNumber')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={
//                 selectedCustomerAccount?.formattedCustomerMemberNumber || ''
//               }
//             />
//           </div>
//         </div>
//         <div className="row mb-3">
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('employerName')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={selectedCustomerAccount?.customerEmployerDescription || ''}
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('identificationNumber')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={
//                 selectedCustomerAccount?.customerIdentificationNumber || ''
//               }
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('memberNumber')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={
//                 selectedCustomerAccount?.formattedCustomerMemberNumber || ''
//               }
//             />
//           </div>
//         </div>
//         <div className="row mb-3">
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('personalFileNumber')}
//             </label>
//             <input
//               type="text"
//               className="form-control ms-2"
//               disabled
//               value={selectedCustomerAccount?.customerPersonalFileNumber || ''}
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('costCenter')}
//             </label>
//             <CostCentreLookup
//               displayValue={batchEntryDetailsModel?.costCenterDescription}
//               onSelect={r => {
//                 setBatchEntryDetailsModel({
//                   ...batchEntryDetailsModel,
//                   costCenterDescription: r.description,
//                   costCenterId: r.id,
//                 })
//               }}
//             />
//           </div>
//           <div className="col d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('principal')}
//             </label>
//             <MaskNumber
//               onMaskNumber={e => {
//                 setBatchEntryDetailsModel({
//                   ...batchEntryDetailsModel,
//                   principal: e.target.value,
//                 })
//               }}
//               defaultMaskValue={batchEntryDetailsModel?.principal}
//             />
//           </div>
//         </div>
//         <div className="row mb-3">
//           <div className="col-4 d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('interest')}
//             </label>
//             <MaskNumber
//               onMaskNumber={e => {
//                 setBatchEntryDetailsModel({
//                   ...batchEntryDetailsModel,
//                   interest: e.target.value,
//                 })
//               }}
//               defaultMaskValue={batchEntryDetailsModel?.interest}
//             />
//           </div>
//           <div className="col-4 d-flex align-items-center">
//             <label className="col-3 text-capitalize">
//               {localize('reference')}
//             </label>
//             <input
//               className="form-control"
//               defaultValue={batchEntryDetailsModel?.reference}
//               onChange={e => {
//                 setBatchEntryDetailsModel({
//                   ...batchEntryDetailsModel,
//                   reference: e.target.value,
//                 })
//               }}
//             />
//           </div>
//         </div>
//         <div className="d-flex align-content-end justify-content-end">
//           <div className="d-flex align-items-center me-2">
//             <ImportButton handleClickImportButton={handleClickImportButton} />
//           </div>
//           <div className="d-flex align-items-center me-2">
//             <button
//               onClick={handleSubmit}
//               className="btn btn-success text-uppercase ms-3 px-4"
//             >
//               {localize('add')}
//             </button>
//           </div>
//         </div>
//       </>
//       {showImportModal && (
//         <CreditBatchImportModal
//           handleCloseCreditBatchImportModal={() => setShowImportModal(false)}
//           handleUpload={handleUpload}
//           ref={ref}
//         />
//       )}
//
//       <input
//         type="file"
//         ref={ref}
//         className="d-none"
//         accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//       />
//     </>
//   )
// }
