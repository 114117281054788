/** @format */

import localize from '../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../Helpers/formatters'
import SimpleTable from '../../../Tables/SimpleTable'
import { useEffect, useState } from 'react'
import { postData } from '../../../../Helpers/webApi'
import Loader from '../../../Loaders/Loader'

export const CustomerMetadataInvestmentAccounts = ({
  selectedCustomer,
  doFetchInvestmentAccounts,
}) => {
  const [customerInvestmentAccounts, setCustomerInvestmentAccounts] = useState(
    [],
  )
  const [loading, setLoading] = useState(false)

  const loadData = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        fetchInvestmentAccounts: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )
    if (success) {
      setCustomerInvestmentAccounts(data?.investmentAccounts)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (doFetchInvestmentAccounts) {
      loadData()
    }
  }, [doFetchInvestmentAccounts])

  const columns = [
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r.fullAccountNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r.customerAccountTypeTargetProductDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => (
        <>{r.customerAccountTypeTargetProductSectionDescription}</>
      ),
    },
    {
      label: `${localize('isRefundable')} ?`,
      template: r => (
        <>{r.customerAccountTypeTargetProductIsRefundable?.toString()}</>
      ),
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r.bookBalance)}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r.recordStatusDescription}</>,
    },
    { label: localize('modifiedBy'), template: r => <>{r.modifiedBy}</> },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r.modifiedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <SimpleTable data={customerInvestmentAccounts} columns={columns} />
      )}
    </>
  )
}
