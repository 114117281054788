/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './simpletable.scss'
import ContextMenuContainer from '../ContextMenu/Container'
import localize from '../../Global/Localize'

function NestedGroupedTable({
  columns,
  data,
  contextMenu,
  canSelect,
  onSelect,
  onClickOnce,
  canClickOnce,
  groupBy,
  groupBySecondary,
  maxHeight,
}) {
  const [ctxtMenuPosition, setCtxtMenuPosition] = useState([0, 0])
  const [showCtxtMenu, setShowCtxtMenu] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [ctxParentId, setCtxParentId] = useState('x')
  const [visibilityMap, setVisibilityMap] = useState({})

  const [nestedGroupedData, setNestedGroupedData] = useState(null)

  function showContextMenu(e, row, parentId) {
    if (contextMenu) {
      e.preventDefault()
      setCtxParentId(parentId)
      setCtxtMenuPosition([e.clientX, e.clientY])
      setShowCtxtMenu(true)
      setSelectedRow(row)
    }
  }

  useEffect(() => {
    if (data && data.length === 0) setNestedGroupedData(null)
    if (data && data.length > 0) groupData()
  }, [groupBy, groupBySecondary, data])

  function groupData() {
    const primaryColumn = groupBy.column
    const secondaryColumn = groupBySecondary.column

    const grouped = data.reduce((group, item) => {
      const primaryValue = item[primaryColumn]
      const secondaryValue = item[secondaryColumn]

      if (!group[primaryValue]) {
        group[primaryValue] = {}
      }

      if (!group[primaryValue][secondaryValue]) {
        group[primaryValue][secondaryValue] = []
      }

      group[primaryValue][secondaryValue].push(item)
      return group
    }, {})

    let vMap = {}
    for (let primaryValue in grouped) {
      vMap[primaryValue] = true
      for (let secondaryValue in grouped[primaryValue]) {
        vMap[`${primaryValue}-${secondaryValue}`] = true
      }
    }
    setVisibilityMap(vMap)
    setNestedGroupedData(grouped)
  }

  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        overflowX: 'scroll',
        maxHeight: maxHeight || '100%',
        position: 'relative',
      }}
    >
      <table
        className="table cent-table"
        id="centrinotable"
        style={{ minWidth: '100%' }}
      >
        <tr style={{ position: 'sticky', top: 0 }}>
          {columns.map(c => (
            <th className="text-dark py-2 px-3">{localize(c.label)}</th>
          ))}
        </tr>
        <>
          {nestedGroupedData &&
            Object.entries(nestedGroupedData).map(
              ([primaryValue, subGroups]) => (
                <>
                  <tr
                    className="bg-secondary text-white cursor-pointer text"
                    style={{
                      borderTop: 'solid 2px white',
                      borderBottom: 'solid 2px white',
                    }}
                    onClick={() => {
                      setVisibilityMap(prev => ({
                        ...prev,
                        [primaryValue]: !prev[primaryValue],
                      }))
                    }}
                  >
                    <td
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                      className="text-capitalize"
                      colSpan={columns.length}
                    >
                      <i
                        className="bx bx-caret-right"
                        style={{
                          transform: `rotate(${
                            visibilityMap[primaryValue] ? 45 : 0
                          }deg) scale(1.2)`,
                        }}
                      ></i>
                      {localize(groupBy.label)}: {localize(primaryValue)} (
                      {`${Object.keys(subGroups).length} ${localize('items')}`})
                    </td>
                  </tr>
                  {visibilityMap[primaryValue] &&
                    Object.entries(subGroups).map(([secondaryValue, rows]) => (
                      <>
                        <tr
                          style={{
                            borderTop: 'solid 2px white',
                            borderBottom: 'solid 2px white',
                          }}
                          className="bg-secondary text-white cursor-pointer text-capitalize"
                          onClick={() => {
                            setVisibilityMap(prev => ({
                              ...prev,
                              [`${primaryValue}-${secondaryValue}`]:
                                !prev[`${primaryValue}-${secondaryValue}`],
                            }))
                          }}
                        >
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 40,
                              margin: 0,
                            }}
                            className="text-capitalize"
                            colSpan={columns.length}
                          >
                            <i
                              className="bx bx-caret-right"
                              style={{
                                transform: `rotate(${
                                  visibilityMap[secondaryValue] ? 45 : 0
                                }deg) scale(1.2)`,
                              }}
                            ></i>
                            {localize(groupBySecondary.label)} :{' '}
                            {secondaryValue === 'null' ? '' : secondaryValue}(
                            {`${rows.length} ${localize('items')}`})
                          </td>
                        </tr>
                        {visibilityMap[`${primaryValue}-${secondaryValue}`] &&
                          rows.map(row => (
                            <tr
                              key={row.id}
                              id={`row-${row.id}`}
                              className={`data-row ${
                                selectedRow?.id === row?.id ? 'selected' : ''
                              }`}
                              onDoubleClick={() =>
                                canSelect ? onSelect(row) : onClickOnce(row)
                              }
                              onClick={() => canClickOnce && onClickOnce(row)}
                              onContextMenu={e =>
                                showContextMenu(e, row, `row-${row.id}`)
                              }
                            >
                              {columns.map(c => (
                                <td className="px-3 p-2">{c.template(row)}</td>
                              ))}
                            </tr>
                          ))}
                      </>
                    ))}
                </>
              )
            )}
        </>
      </table>
      {showCtxtMenu && contextMenu && (
        <ContextMenuContainer
          position={ctxtMenuPosition}
          hide={() => setShowCtxtMenu(false)}
        >
          {contextMenu(selectedRow)}
        </ContextMenuContainer>
      )}
    </div>
  )
}

export default NestedGroupedTable
