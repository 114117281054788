/** @format */

import React, { useState, useEffect } from 'react'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import localize from '../../../../../Global/Localize'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../Components/Date/DatePicker'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Pager from '../../../../../Components/Tables/Pager'
import ViewInvoice from './Partials/ViewInvoice'
import AddInvoice from './Partials/AddInvoice'
import { getData } from '../../../../../Helpers/webApi'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import { loadMedia } from '../../../../../Helpers/Modules/media'
import Swal from 'sweetalert2'
import AddNewButton from '../../../../../Components/Buttons/AddNewButton/AddNewButton'

function InvoicesOrigination() {
  const columns = [
    {
      label: localize('reference#'),
      template: r => r.paddedReferenceNumber,
    },
    {
      label: localize('invoiceNumber'),
      template: r => r.invoiceNumber,
    },
    {
      label: localize('supplier'),
      template: r => r.supplierDescription,
    },
    {
      label: localize('mobileLine'),
      template: r => r.supplierAddressMobileLine,
    },
    {
      label: localize('emailAddress'),
      template: r => r.supplierAddressEmail,
    },
    {
      label: localize('creditPeriod(days)'),
      template: r => r.supplierCreditPeriod,
    },
    {
      label: localize('totalValue'),
      template: r => formatCurrency(r.totalValue),
    },
    {
      label: localize('invoiceDate'),
      template: r => formatDate(r.invoiceDate),
    },
    {
      label: localize('age(inDays)'),
      template: r => r.age,
    },
    {
      label: localize('dueDate'),
      template: r => formatDate(r.dueDate),
    },
    {
      label: localize('daysRemaining'),
      template: r => r.daysRemaining,
    },
    {
      label: localize('document'),
      template: r => r.fileName,
    },
    {
      label: localize('title'),
      template: r => r.fileTitle,
    },
    {
      label: localize('mimeType'),
      template: r => r.fileMIMEType,
    },
    {
      label: localize('branch'),
      template: r => r.branchDescription,
    },
    {
      label: localize('createdBy'),
      template: r => r.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r.createdDate),
    },
  ]

  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [mode, setMode] = useState(null)
  const [reqParams, setReqParams] = useState({
    recordStatus: 0,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    text: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [isBusyDownloading, setIsBusyDownloading] = useState(false)

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize])

  async function loadTableData() {
    setLoading(true)
    const { success, data } = await getData(
      '/Invoice/find-invoices-by-record-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    }
    setLoading(false)
  }

  const handleAdd = async () => {
    setMode('add')
    setSelectedInvoice(null)
  }

  const handleSelectCase = (r, mode) => {
    if (mode === 'download') {
      handleExportDocument(r)
      return
    }

    setSelectedInvoice(r)
    setMode(mode)
  }

  const handleExportDocument = async r => {
    setIsBusyDownloading(true)
    const media = await loadMedia(r?.id)

    if (media) {
      const linkSource = `data:${media?.contentType};base64,${media?.content}`
      const downloadLink = document.createElement('a')
      const fileName = media?.fileName
      downloadLink.href = linkSource
      downloadLink.download = fileName
      document.body.appendChild(downloadLink) // Required for this to work in some browsers
      downloadLink.click()
      downloadLink.remove()
    } else Swal.fire('Operation failed', 'Cant load file', 'error')

    setIsBusyDownloading(false)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('procurementAndAssetsManagement'),
          localize('invoices'),
          localize('origination'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <AddNewButton
                      handleClickAddButton={() => {
                        handleAdd()
                      }}
                    />
                  </div>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="row">
                    <div className="d-flex align-items-center col-2">
                      <label
                        htmlFor="recordsPerPage"
                        className=""
                        style={{ marginLeft: -10, textTransform: 'capitalize' }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-3"
                        style={{ maxWidth: 'fit-content' }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-3"
                          htmlFor="searchBystatus"
                        >
                          {localize('status')}
                        </label>
                        <EnumsServerCombo
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="WorkflowRecordStatus"
                          value={reqParams?.recordStatus}
                          onChange={e =>
                            setReqParams({
                              ...reqParams,
                              recordStatus: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center  me-2">
                        <label
                          className="text-capitalize col-3"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          }}
                        />
                      </div>
                    </div>
                    <form
                      className="d-flex align-items-center col-4 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label
                        className="col-2 ms-2 text-capitalize"
                        htmlFor="searchTable"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => {
                          setSearch(e.target.value)
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                          })
                        }}
                      />
                      <button
                        id="invoiceTerminationFilterButton"
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => {
                          loadTableData()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </form>
                  </div>
                  {isBusyDownloading ? (
                    <div className="card bg-white rounded">
                      <div className="card-body">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <div className="row col-12 mt-2">
                      <SimpleTable
                        contextMenu={r => (
                          <>
                            <div
                              onClick={async () => {
                                handleSelectCase(r, 'download')
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-eye"></i>
                              {localize('viewDocument')}
                            </div>
                            <div
                              onClick={async () => {
                                setMode('view')
                                setSelectedInvoice(r)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-eye"></i>
                              {localize('view')}
                            </div>
                            <div
                              onClick={async () => {
                                if (r.recordStatus !== '0') {
                                  showNotification(
                                    'Invoice',
                                    'info',
                                    'sorry, but you must first select an invoice whosw record status is pending'
                                  )
                                } else {
                                  setMode('edit')
                                  setSelectedInvoice(r)
                                }
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-edit-alt"></i>
                              {localize('edit')}
                            </div>
                          </>
                        )}
                        data={tableData}
                        columns={columns}
                      />
                    </div>
                  )}
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {(mode === 'add' || mode === 'edit') && (
        <AddInvoice
          edit={mode === 'edit'}
          invoice={selectedInvoice}
          setInvoice={setSelectedInvoice}
          closeModal={() => {
            setMode(null)
            setSelectedInvoice(null)
          }}
          loadTableData={loadTableData}
          mode={mode}
        />
      )}
      {mode === 'view' && (
        <ViewInvoice
          mode={mode}
          invoice={selectedInvoice}
          closeModal={() => {
            setMode(null)
            setSelectedInvoice(null)
          }}
        />
      )}
    </>
  )
}

export default InvoicesOrigination
