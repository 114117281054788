/** @format */

import React, { useEffect } from 'react'
import {
  alternateChannelManagementAction,
  alternateChannelType,
  productCode,
} from '../../../../../Helpers/constants'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'
import localize from '../../../../../Global/Localize'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { useState } from 'react'
import { postData } from '../../../../../Helpers/webApi'
import { formatCurrency } from '../../../../../Helpers/formatters'
import CustomDatePicker from '../../../../../Components/Date/custom-date-picker'
import { ChannelLinkingCustomerAccountPartial } from '../Partials/ChannelLinkingCustomerAccountPartial'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'

const ChannelLinking = ({
  channel,
  setChannel,
  setAccountHistoryQueryParams,
  accountHistoryQueryParams,
  customerAccount,
  setCustomerAccount,
  operation,
  setEffectCharges,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    setValidationBag({})
  }, [channel?.type])

  const handleOnChangeAlternateChannelType = async customerAccount => {
    if (
      customerAccount?.customerAccountTypeProductCode !==
      productCode.SAVINGS.toString()
    ) {
      return showNotification(
        'Operation not allowed!',
        'error',
        'You can only link to a savings account',
      )
    } else {
      setIsBusy(true)
      const { success, data } = await postData(
        '/ChannelManagement/handle-on-change-alternate',
        {
          AlternateChannelType: channel?.type,
          AlternateChannelManagementAction:
            alternateChannelManagementAction.CHANNEL_LINKING.value,
          CustomerAccountId: customerAccount?.id,
          DebitCardRegisterId: customerAccount?.debitCardRegisterId,
        },
        false,
      )
      if (success) {
        setChannel({
          ...channel,
          cardNumber: data?.cardNumber,
          dailyLimit: data?.dailyLimit,
          expires: data?.expires,
          validFrom: data?.validFrom,
        })
      }
      clearValidation('CardNumber')
      setIsBusy(false)
    }
  }

  const handleSelectCustomer = data => {
    setChannel({
      ...channel,
      recruitedBy: data?.paddedSerialNumber,
      recruitedByDescription: data?.fullName,
      recruiterId: data?.id,
    })
  }

  const disableDates = () => {
    if (
      channel?.type === alternateChannelType.AGENCYBANKING?.toString() ||
      channel?.type === alternateChannelType.BROKER?.toString() ||
      channel?.type === alternateChannelType.CITIUS?.toString() ||
      channel?.type === alternateChannelType.MCOOPCASH?.toString() ||
      channel?.type === alternateChannelType.PESAPEPE?.toString() ||
      channel?.type === alternateChannelType.SPOTCASH?.toString()
    ) {
      return true
    }

    return false
  }

  const disableInputs = () => {
    if (disableDates()) return false

    return true
  }

  return (
    <>
      <ChannelLinkingCustomerAccountPartial
        customerAccount={customerAccount}
        setCustomerAccount={setCustomerAccount}
        accountHistoryQueryParams={accountHistoryQueryParams}
        setAccountHistoryQueryParams={setAccountHistoryQueryParams}
        onSelectedCustomerAccount={handleOnChangeAlternateChannelType}
        selectedAlternateChannelType={channel?.type}
      />
      <div className="row g-2">
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize me-2">
            {localize('recruitedBy')}
          </label>
          <CustomersLookup
            displayValue={channel?.recruitedByDescription}
            onSelect={handleSelectCustomer}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="col-4 text-capitalize me-2">
            {localize('channelType')}
          </label>

          <EnumsServerCombo
            enumsKey="AlternateChannelType"
            customContainerStyle={{
              width: '100%',
            }}
            sort
            value={channel?.type}
            onChange={e => setChannel({ ...channel, type: e.value })}
          />
        </div>
        <div
          validation-message={validationBag?.CardNumber?.message}
          className="col-md-3 d-flex align-items-center with-validation"
        >
          <label className="text-capitalize col-4 me-2">
            {localize('pan/CardNumber')}
          </label>
          <input
            disabled={disableInputs()}
            value={channel?.cardNumber || ''}
            className="form-control"
            onChange={e => {
              setChannel({ ...channel, cardNumber: e.target.value })
            }}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 me-2">
            {localize('dailyLimit')}
          </label>
          <input
            className={'text-end form-control'}
            disabled
            value={formatCurrency(channel?.dailyLimit, 2, 2, false)}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 me-2">
            {localize('validFrom')}
          </label>
          <CustomDatePicker
            disabled={disableDates()}
            name="validFromChannelLinking"
            defaultDate={channel?.validFrom || ''}
            onDateChange={date => {
              setChannel({ ...channel, validFrom: date })
            }}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <label className="text-capitalize col-4 me-2">
            {localize('expires')}
          </label>
          <CustomDatePicker
            disabled={disableDates()}
            name="expiresChannelLinking"
            defaultDate={channel?.expires || ''}
            onDateChange={date => {
              setChannel({ ...channel, expires: date })
            }}
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <label className="text-capitalize col-2">{localize('remarks')}</label>
          <input
            type="text"
            className="form-control"
            value={channel?.remarks || ''}
            onChange={e => setChannel({ ...channel, remarks: e.target.value })}
          />
        </div>
      </div>
    </>
  )
}

export default ChannelLinking
