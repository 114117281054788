/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import Loader from '../../../../../../Components/Loaders/Loader'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import JournalEntries from '../../../../../../Components/ReUsables/GLAccounts/JournalEntries'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { downloadBase64File } from '../../../../../../Helpers/blobs'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../../Helpers/webApi'

const SelectedProductDetails = ({
  vehicle,
  customerAccount,
  customer,
  customerAccountList,
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [statements, setStatements] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    text: '',
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
    journalEntryFilter: 0,
    tallyDebitsCredits: true,
  })
  const [mode, setMode] = useState(null)
  const [selectedStatement, setSelectedStatement] = useState(null)

  const trColumns = [
    { label: 'branch', template: r => <>{r.branchDescription}</> },
    {
      label: 'transactionDate',
      template: r => <>{formatDate(r.journalCreatedDate)}</>,
    },
    {
      label: 'primaryDescription',
      template: r => <>{r.journalPrimaryDescription}</>,
    },
    { label: 'debit', template: r => <>{formatCurrency(r.debit)}</> },
    { label: 'credit', template: r => <>{formatCurrency(r.credit)}</> },
    {
      label: 'runningBalance',
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    {
      label: 'secondaryDescription',
      template: r => <>{r.journalSecondaryDescription}</>,
    },
    { label: 'reference', template: r => <>{r.journalReference}</> },
    {
      label: 'fullAccountNumber',
      template: r => <>{r.customerAccountNumber}</>,
    },
    {
      label: 'customerName',
      template: r => <>{r.customerFullName}</>,
    },
    {
      label: 'accountNumber',
      template: r => <>{r.customerReference1}</>,
    },
    {
      label: 'membershipNumber',
      template: r => <>{r.customerReference2}</>,
    },
    {
      label: 'personalFileNumber',
      template: r => <>{r.customerReference3}</>,
    },
    {
      label: 'transactionCode',
      template: r => <>{r.journalTransactionCodeDescription}</>,
    },
    {
      label: 'valueDate',
      template: r => <>{formatDate(r.journalValueDate, true)}</>,
    },
    {
      label: 'localCurrency',
      template: r => <>{r.journalLocalCurrencyDescription}</>,
    },
    { label: 'appUserName', template: r => <>{r.applicationUserName}</> },
  ]

  const handleSelectStatement = statement => {
    setSelectedStatement(statement)
    setMode('view')
  }

  const fetchGeneralLedgerTransactions = async () => {
    if (
      !customerAccount?.customerAccountTypeTargetProductChartOfAccountId &&
      !vehicle?.id
    )
      return

    setIsBusy(true)

    const url =
      '/JournalEntry/find-general-ledger-transactions-by-reference-id-and-date-range-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        chartOfAccountId:
          customerAccount?.customerAccountTypeTargetProductChartOfAccountId,
        referenceId: vehicle?.id,
      },
      false
    )

    if (success) {
      setStatements(data)
    }

    setIsBusy(false)
  }

  const handlePrint = async () => {
    setIsBusy(true)

    const url =
      '/Base64/print-general-ledger-transactions-by-customer-account-id-and-date-range'

    const { success, data } = await postData(
      url,
      {
        branch: {
          id: customerAccount?.branchId,
        },
        customer: {
          ...customer,
          customerAccountId: vehicle?.customerAccountId,
        },
        customerAccountList,
        startDate: reqParams?.startDate,
        endDate: reqParams?.endDate,
        dateRequestsFilter: reqParams?.dateRequestsFilter,
      },
      false
    )

    if (success) {
      await downloadBase64File(data?.content, data?.fileType)
    }

    setIsBusy(false)
  }

  const handleClose = () => {
    setMode(null)
    setSelectedStatement(null)
  }

  useEffect(() => {
    if (
      customerAccount?.customerAccountTypeTargetProductChartOfAccountId &&
      vehicle?.id
    )
      fetchGeneralLedgerTransactions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reqParams.pageSize,
    reqParams.pageIndex,
    customerAccount?.customerAccountTypeTargetProductChartOfAccountId,
    vehicle?.id,
  ])

  return (
    <>
      {mode === 'view' && (
        <ModalTemplate
          modalMode={mode}
          hideUpdate
          handleClose={handleClose}
          cancelText="close"
          modalClassName="modal-xl"
          modalTitle={localize('journalEntries')}
        >
          <JournalEntries
            journalEntries={selectedStatement?.journalEntries || []}
          />
        </ModalTemplate>
      )}

      <div className="row g-3">
        <div className="d-flex align-items-center col-md-4">
          <label className="text-capitalize col-4">
            {localize('vehicleProductType')}
          </label>
          <input
            className="form-control"
            value={vehicle?.vehicleFleetProductProductTypeDescription}
            disabled
          />
        </div>
        <div className="d-flex align-items-center col-md-4">
          <label className="text-capitalize col-4">
            {localize('vehicleProductName')}
          </label>
          <input
            className="form-control"
            value={vehicle?.vehicleFleetProductDescription}
            disabled
          />
        </div>
        <div className="d-flex align-items-center col-md-4">
          <label className="text-capitalize col-4">
            {localize('customerAccountName')}
          </label>
          <input
            className="form-control"
            value={customerAccount?.accountName}
            disabled
          />
        </div>
        <div className="d-flex align-items-center col-md-4">
          <label className="text-capitalize col-4">
            {localize('fullAccountNumber')}
          </label>
          <input
            className="form-control"
            value={customerAccount?.fullAccountNumber}
            disabled
          />
        </div>
        <div className="d-flex align-items-center col-md-4">
          <label className="text-capitalize col-4">
            {localize('customerAccountStatus')}
          </label>
          <input
            className="form-control"
            value={customerAccount?.statusDescription}
            disabled
          />
        </div>
        <div className="d-flex align-items-center col-md-4">
          <label className="text-capitalize col-4">
            {localize('customerAccountProductName')}
          </label>
          <input
            className="form-control"
            value={customerAccount?.customerAccountTypeTargetProductDescription}
            disabled
          />
        </div>
      </div>
      <div className="mt-4 bg-light rounded d-flex justify-content-end p-2 align-items-center">
        <button className="btn btn-success" onClick={handlePrint}>
          {localize('print')}
        </button>
      </div>

      <div className="mt-4">
        <Tab
          tabItems={[
            {
              label: localize('statement'),
              item: isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-between mb-3 gap-1">
                    <div className="d-flex align-items-center">
                      <label
                        style={{
                          minWidth: 'fit-content',
                          maxWidth: 'fit-content',
                        }}
                        className="text-capitalize"
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        className="form-select ms-1"
                        style={{ maxWidth: 'fit-content' }}
                        value={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageSize: e.target.value,
                            pageIndex: 0,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                    <div
                      className="col d-flex align-items-center gap-2"
                      style={{ maxWidth: 'fit-content' }}
                    >
                      <label
                        style={{ minWidth: 'fit-content' }}
                        className="text-capitalize me-2"
                      >
                        {localize('tallyDebitsCredits')}
                      </label>
                      <Checkbox
                        checked={reqParams?.tallyDebitsCredits}
                        onCheck={value =>
                          setReqParams({
                            ...reqParams,
                            tallyDebitsCredits: value,
                            pageIndex: 0,
                          })
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <label
                        style={{ minWidth: 'fit-content' }}
                        className="text-capitalize me-2"
                      >
                        {localize('dateRange')}
                      </label>
                      <DatePicker
                        value={{
                          startDate: reqParams?.startDate,
                          endDate: reqParams?.endDate,
                        }}
                        onSelect={v => {
                          setReqParams({
                            ...reqParams,
                            customDate: true,
                            startDate: v.startDate,
                            endDate: v.endDate,
                            pageIndex: 0,
                          })
                        }}
                      />
                    </div>
                    <div
                      className="col d-flex align-items-center gap-1"
                      style={{ maxWidth: 'fit-content' }}
                    >
                      <label
                        style={{ minWidth: 'fit-content' }}
                        className="text-capitalize me-1"
                      >
                        {localize('search')}
                      </label>
                      <EnumsServerCombo
                        sort
                        customContainerStyle={{
                          maxWidth: 150,
                          minWidth: 150,
                        }}
                        value={reqParams?.journalEntryFilter}
                        enumsKey="JournalEntryFilter"
                        onChange={({ value }) =>
                          setReqParams({
                            ...reqParams,
                            journalEntryFilter: value,
                            pageIndex: 0,
                          })
                        }
                      />
                      <input
                        type="text"
                        className="form-control"
                        value={reqParams?.text}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            text: e.target.value,
                            pageIndex: 0,
                          })
                        }
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            fetchGeneralLedgerTransactions()
                          }
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          fetchGeneralLedgerTransactions()
                        }}
                      >
                        <i className="uil uil-filter"></i>
                      </button>
                    </div>
                  </div>

                  <SimpleTable
                    columns={trColumns}
                    data={statements?.pageCollection}
                    contextMenu={r => (
                      <>
                        <div
                          className="ctxt-menu-item text-capitalize"
                          onClick={() => {
                            handleSelectStatement(r)
                          }}
                        >
                          <i className="uil uil-eye"></i>
                          <span className="text-capitalize">
                            {localize('viewJournalEntries')}
                          </span>
                        </div>
                      </>
                    )}
                  />
                  <Pager
                    itemsCount={statements?.itemsCount}
                    pageSize={reqParams.pageSize}
                    pageIndex={reqParams.pageIndex}
                    pageBatchSize={5}
                    onPageChange={p =>
                      setReqParams({ ...reqParams, pageIndex: p })
                    }
                  />

                  <div className="bg-light rounded p-2">
                    <div
                      className="p-2 mt-4"
                      style={{
                        border: 'solid 1px #0000001f',
                        borderRadius: 10,
                        position: 'relative',
                      }}
                    >
                      <div
                        className="bg-light px-2  row text-capitalize"
                        style={{
                          position: 'relative',
                          top: -20,
                          left: 30,
                          maxWidth: 'fit-content',
                        }}
                      >
                        {localize('accountSummary')}
                      </div>

                      <div className="row">
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-3">
                            {localize('balanceB/F')}
                          </label>
                          <input
                            type="text"
                            className="form-control text-end"
                            value={formatCurrency(
                              statements?.balanceBroughtFoward,
                              null,
                              null,
                              false
                            )}
                            disabled
                          />
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-3">
                            {localize('debits')}
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control text-end"
                            value={formatCurrency(
                              statements?.totalDebits,
                              null,
                              null,
                              false
                            )}
                          />
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-3">
                            {localize('credits')}
                          </label>
                          <input
                            type="text"
                            disabled
                            className="form-control text-end"
                            value={formatCurrency(
                              statements?.totalCredits,
                              null,
                              null,
                              false
                            )}
                          />
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                          <label className="text-capitalize col-3">
                            {localize('balanceC/F')}
                          </label>
                          <input
                            type="text"
                            disabled
                            className="form-control text-end"
                            value={formatCurrency(
                              statements?.balanceCarriedForward,
                              null,
                              null,
                              false
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  )
}

export default SelectedProductDetails
