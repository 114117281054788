/** @format */

//LoanRescheduleRequestLookup

/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
  CustomerFilter,
  LoanRescheduleRequestStatus,
} from '../../Global/enumeration'
import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'
import {
  formatCurrency,
  formatDate,
  getTodaysDate,
} from '../../Helpers/formatters'
import DatePicker from '../Date/DatePicker'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import Loader from '../Loaders/Loader'

function LoanRescheduleRequestLookup({ displayValue, onSelect, statusFilter }) {
  const [reqParams, setReqParams] = useState({
    startDate: '2000-01-01',
    endDate: getTodaysDate(),
    status: statusFilter,
    filterText: '',
    customerFilter: Object.keys(CustomerFilter)[1],
    pageIndex: 0,
    pageSize: 10,
    filterByAuthorizedBranches: false,
    customDate: false,
    dateRequestsFilter: 15,
  })
  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (modalActive) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageCount, modalActive])

  const columns = [
    {
      label: localize('serialNumber'),
      template: r => r?.paddedCustomerAccountCustomerSerialNumber,
    },
    {
      label: localize('status'),
      template: r => r?.statusDescription,
    },
    {
      label: localize('branch'),
      template: r => r?.branchDescription,
    },
    {
      label: localize('postingPeriod'),
      template: r => r?.postingPeriodDescription,
    },
    {
      label: localize('numberOfPeriods'),
      template: r => r?.numberOfPeriods,
    },
    {
      label: localize('paymentPerPeriod'),
      template: r => formatCurrency(r?.paymentPerPeriod),
    },
    {
      label: localize('fullAccountNumber'),
      template: r => r?.fullAccountNumber,
    },
    {
      label: localize('customerName'),
      template: r => r?.customerFullName,
    },
    {
      label: localize('memberNumber'),
      template: r => r?.customerAccountCustomerMemberNumber,
    },
    {
      label: localize('accountNumber'),
      template: r => r?.customerAccountCustomerAccountNumber,
    },
    {
      label: localize('addressLine1'),
      template: r => r?.customerAccountCustomerAddressAddressLine1,
    },
    {
      label: localize('addressLine2'),
      template: r => r?.customerAccountCustomerAddressAddressLine2,
    },
    {
      label: localize('physicalAddress'),
      template: r => r?.customerAccountCustomerAddressStreet,
    },
    {
      label: localize('postalCode'),
      template: r => r?.customerAccountCustomerAddressPostalCode,
    },
    {
      label: localize('city'),
      template: r => r?.customerAccountCustomerAddressCity,
    },
    {
      label: localize('e-mailAddress'),
      template: r => r?.customerAccountCustomerAddressEmail,
    },
    {
      label: localize('landLine'),
      template: r => r?.customerAccountCustomerAddressLandLine,
    },
    {
      label: localize('mobileLine'),
      template: r => r?.customerAccountCustomerAddressMobileLine,
    },
    {
      label: localize('reference'),
      template: r => r?.reference,
    },
    {
      label: localize('verification/RejectionRemarks'),
      template: r => r?.auditRemarks,
    },
    {
      label: localize('verified/RejectedBy'),
      template: r => r?.auditedBy,
    },
    {
      label: localize('verified/RejectedDate'),
      template: r => formatDate(r?.auditedDate),
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => r?.authorizationRemarks,
    },
    {
      label: localize('authorized/RejectedBy'),
      template: r => r?.authorizedBy,
    },
    {
      label: localize('authorized/RejectedDate'),
      template: r => formatDate(r?.authorizedDate),
    },
    {
      label: localize('createdBy'),
      template: r => r?.createdBy,
    },
    {
      label: localize('createdDate'),
      template: r => formatDate(r?.createdDate),
    },
  ]

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/LoanRescheduleRequest/find-loan-reschedule-request-by-status-and-date-range-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
    } else {
      setIsBusy(false)
    }
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('loanRescheduleRequestLookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="row d-flex justify-content-between mb-4">
                  <div className="d-flex align-items-center col-2">
                    <label
                      htmlFor="recordsPerPage"
                      className="text-capitalize"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {localize('recordsPerPage')}
                    </label>
                    <select
                      name=""
                      id="recordsPerPage"
                      className="form-select ms-3"
                      style={{ maxWidth: 'fit-content' }}
                      defaultValue={reqParams.pageSize}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          pageIndex: 0,
                          pageSize: e.target.value,
                        })
                      }}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="200">200</option>
                      <option value="300">300</option>
                      <option value="400">400</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('status')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="LoanRescheduleRequestStatus"
                      value={reqParams.status}
                      sort
                      onChange={e =>
                        setReqParams({
                          ...reqParams,
                          status: e.value,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center col-3">
                    <label
                      className="text-capitalize"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {localize('dateRange')}
                    </label>
                    <div className="col-6 ms-2">
                      <DatePicker
                        onSelect={v => {
                          if (v.custom) {
                            setReqParams({
                              ...reqParams,
                              customDate: true,
                              startDate: v.startDate,
                              endDate: v.endDate,
                            })
                          } else {
                            setReqParams({
                              ...reqParams,
                              customDate: false,
                              dateRequestsFilter: v.value,
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col d-flex align-items-center space-between">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{ width: '100%' }}
                      enumsKey="CustomerFilter"
                      value={reqParams?.customerFilter}
                      onChange={e => {
                        setReqParams({
                          ...reqParams,
                          customerFilter: e.value,
                        })
                      }}
                    />
                    <form
                      className="d-flex align-items-center col-4"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          filterText: search,
                        })
                      }}
                    >
                      <input
                        type="text"
                        className="form-control ms-2"
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                    <button
                      id="FilterButton"
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        loadData()
                      }}
                    >
                      <i className="uil uil-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="py-1">
                  <SimpleTable
                    canSelect
                    onSelect={r => {
                      onSelect(r)
                      setModalActive(false)
                    }}
                    columns={columns}
                    data={data?.pageCollection}
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageCount}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={10}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>

          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
export default LoanRescheduleRequestLookup
