/** @format */

import localize from '../../Global/Localize'
import { formatCurrency, formatDate } from '../../Helpers/formatters'
import React, { useEffect, useState } from 'react'
import { getData } from '../../Helpers/webApi'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import DatePicker from '../Date/DatePicker'
import EnumsServerCombo from '../ComboBox/EnumsServerCombo'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'

export const PettyCashRequisitionLookup = ({ displayValue, onSelect }) => {
  const [reqParams, setReqParams] = useState({
    startDate: null,
    endDate: null,
    status: 2,
    text: '',
    pageIndex: 0,
    pageSize: 10,
    customDate: false,
    dateRequestsFilter: 15,
  })
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, modalActive])

  async function loadData() {
    setLoading(true)
    const { success, data } = await getData(
      '/PettyCashRequestLookup/find-petty-cash-requisitions-by-filter-in-page',
      reqParams,
      false,
      null
    )
    if (success) {
      setData(data)
      setTableData(data.pageCollection)
      setLoading(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
  }

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r.paddedSerialNumber}</>,
    },
    {
      label: localize('VoucherNumber'),
      template: r => <>{r.paddedVoucherNumber}</>,
    },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('payee'),
      template: r => <>{r.primaryDescription}</>,
    },
    {
      label: localize('narration'),
      template: r => <>{r.secondaryDescription}</>,
    },
    {
      label: localize('reference'),
      template: r => <>{r.reference}</>,
    },
    {
      label: localize('authorization/RejectionRemarks'),
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: localize('authorized/rejectedBy'),
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: localize('authorized/rejectedDate'),
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    {
      label: localize('paidBy'),
      template: r => <>{r.paidBy}</>,
    },
    {
      label: localize('paidDate'),
      template: r => <>{formatDate(r.paidDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r.createdBy}</>,
    },
  ]

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white p-2 text-capitalize">
            {localize('pettyCash')} {localize('requisition')}{' '}
            {localize('lookup')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="bg-white p-2">
            <div className="d-flex align-items-center justify-content-space-between ">
              <div className="d-flex align-items-center float-right my-2 col-3">
                <label
                  className="text-capitalize"
                  htmlFor="LoanProductLookupPageSize"
                >
                  {localize('recordsPerPage')}
                </label>
                <select
                  id="LoanProductLookupPageSize"
                  className="form-select ms-3"
                  style={{ width: 'fit-content' }}
                  defaultValue={reqParams.pageSize}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      pageIndex: 0,
                      pageSize: e.target.value,
                    })
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="col-3 d-flex align-items-center">
                <label className="text-capitalize col-2">
                  {localize('dateRange')}
                </label>
                <DatePicker
                  onSelect={v => {
                    if (v.custom) {
                      setReqParams({
                        ...reqParams,
                        customDate: true,
                        startDate: v.startDate,
                        endDate: v.endDate,
                      })
                    } else {
                      setReqParams({
                        ...reqParams,
                        customDate: false,
                        dateRequestsFilter: v.value,
                      })
                    }
                  }}
                />
              </div>
              <div className="col-3 d-flex align-items-center">
                <label className="col-auto me-3 text-capitalize">
                  {localize('status')}
                </label>
                <EnumsServerCombo
                  value={reqParams?.status}
                  customContainerStyle={{
                    width: '70%',
                    maxWidth: 'fit-content',
                  }}
                  enumsKey={'PettyCashRequisitionAuthStatus'}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      status: e.value,
                    })
                  }}
                />
              </div>
              <div className="col-3 d-flex align-items-center">
                <label
                  className="ms-2 text-capitalize me-3"
                  htmlFor="searchTable"
                >
                  {localize('search')}
                </label>
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-3"
                  defaultValue={reqParams.filterText}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      loadData()
                    }
                  }}
                  onChange={e => {
                    setSearch(e.target.value)
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                />
                <button
                  id="customLookupFilterButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    loadData()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
            <div className="py-1">
              {loading ? (
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="m-3 spinner-border text-dark"></div>
                  <span className="fs-4">Please Wait</span>
                </div>
              ) : (
                <SimpleTable
                  canSelect
                  onSelect={r => {
                    onSelect(r)
                    setModalActive(false)
                  }}
                  columns={columns}
                  data={data?.pageCollection}
                />
              )}
            </div>
            <Pager
              itemsCount={data.itemsCount}
              pageSize={reqParams.pageSize}
              pageIndex={reqParams.pageIndex}
              pageBatchSize={10}
              onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
            />
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
