/** @format */

import React, { useState } from 'react'
import { CustomerAccountPartial } from '../../../../../../Components/ReUsables/Customer/CustomerAccountPartial'
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import AddressV2 from '../../../../../../Components/Miscalleneous/AddressV2'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../../../../Components/Tabs/Tab'
import SignatoriesTab from '../SignatoriesTab/SignatoriesTab'
import { GlobalValidationErrors } from '../../../../../../Validation/ValidationState'
import { postData, putData } from '../../../../../../Helpers/webApi'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import { capitalizeFirstLetter } from '../../../../../../Helpers/formatters'
import Loader from '../../../../../../Components/Loaders/Loader'

const EditSignatories = ({ signatory, setSignatory, mode, handleClose }) => {
  const [selectedSignatories, setSelectedSignatories] = useState([])
  const [reload, setReload] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const handleSelectSignatory = data => {
    setSelectedSignatories(data)
  }

  const handleCreateSignatory = async () => {
    setIsBusy(true)

    const url = '/CustomerAccount/create-customer-account-signatory'

    const { success, data } = await postData(
      url,
      {
        ...signatory,
        customerAccountId: signatory?.id,
      },
      false
    )

    if (success) {
      setSignatory(data)
      setReload(true)
      showNotification('Edit Signatory', 'success')
      setSelectedSignatories([])
      setIsBusy(false)
    }

    setIsBusy(false)
  }

  const handleRemoveSignatories = async () => {
    if (selectedSignatories.length === 0)
      return showNotification(
        'Operation not allowed',
        'warning',
        'Please select a signatory to remove'
      )

    setIsBusy(true)

    const url = '/CustomerAccount/remove-customer-account-signatories'

    const { success } = await putData(url, selectedSignatories, false)

    if (success) {
      setReload(true)
      showNotification('Remove Signatory', 'success')
      setSelectedSignatories([])
      setIsBusy(false)
    }
  }

  return (
    <ModalTemplate
      modalMode={mode}
      handleClose={handleClose}
      modalClassName="modal-xl"
      modalTitle="editSignatories"
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomerAccountPartial readOnly customer={signatory} />

          <div
            className="p-2 mt-4"
            style={{
              border: 'solid 1px #0000001f',
              borderRadius: 10,
              position: 'relative',
            }}
          >
            <div
              className="bg-white px-2  row text-capitalize"
              style={{
                position: 'relative',
                top: -20,
                left: 30,
                maxWidth: 'fit-content',
              }}
            >
              {localize('signatoryDetails')}
            </div>

            <div className="row g-2 mb-2">
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('salutation')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="Salutation"
                  value={signatory?.salutation}
                  onChange={e =>
                    setSignatory({ ...signatory, salutation: e.value })
                  }
                />
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('gender')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="Gender"
                  value={signatory?.gender}
                  onChange={e =>
                    setSignatory({ ...signatory, gender: e.value })
                  }
                />
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('relationship')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="SignatoryRelationship"
                  value={signatory?.relationship}
                  onChange={e =>
                    setSignatory({ ...signatory, relationship: e.value })
                  }
                />
              </div>
              <div
                className="col-md-3 d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['FirstName']?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('firstName')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={signatory?.firstName}
                  onChange={e => {
                    clearValidation('FirstName')
                    setSignatory({ ...signatory, firstName: e.target.value })
                  }}
                />
              </div>
              <div
                className="col-md-3 d-flex align-items-center with-validation"
                validation-message={globalValidationErrors['LastName']?.message}
              >
                <label className="col-3 text-capitalize">
                  {localize('otherNames')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={signatory?.lastname}
                  onChange={e => {
                    clearValidation('LastName')
                    setSignatory({ ...signatory, lastName: e.target.value })
                  }}
                />
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <label className="col-3 text-capitalize">
                  {localize('identityCardType')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{ width: '100%' }}
                  enumsKey="IdentityCardType"
                  value={signatory?.identityCardType}
                  onChange={e =>
                    setSignatory({ ...signatory, identityCardType: e.value })
                  }
                />
              </div>
              <div
                className="col-md-3 d-flex align-items-center with-validation"
                validation-message={
                  globalValidationErrors['IdentityCardNumber']?.message
                }
              >
                <label className="col-3 text-capitalize">
                  {localize('identityCardNumber')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={signatory?.identityCardNumber}
                  onChange={e => {
                    clearValidation('IdentityCardNumber')
                    setSignatory({
                      ...signatory,
                      identityCardNumber: e.target.value,
                    })
                  }}
                />
              </div>
              <div
                className="col-md-3 d-flex align-items-center with-validation"
                validation-message={globalValidationErrors['Remarks']?.message}
              >
                <label className="col-3 text-capitalize">
                  {localize('remarks')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={signatory?.remarks}
                  onChange={e => {
                    clearValidation('Remarks')
                    setSignatory({
                      ...signatory,
                      remarks: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <Tab
                tabItems={[
                  {
                    label: localize('address'),
                    item: (
                      <AddressV2
                        onChange={data => {
                          setSignatory({
                            ...signatory,
                            ...data,
                          })
                        }}
                        parent={signatory}
                        clearValidation={clearValidation}
                        globalValidationErrors={globalValidationErrors}
                      />
                    ),
                    icon: 'uil uil-edit',
                  },
                ]}
              />
            </div>

            <div className="mt-2 d-flex justify-content-end align-items-center p-2 bg-light rounded">
              <button
                className="btn btn-success"
                onClick={handleCreateSignatory}
              >
                {localize('create')}
              </button>
            </div>

            <div className="mt-4">
              <Tab
                tabItems={[
                  {
                    label: localize('signatories'),
                    item: (
                      <SignatoriesTab
                        customerAccountId={signatory?.id}
                        handleSelectData={handleSelectSignatory}
                        reload={reload}
                        setReload={setReload}
                        selectedData={selectedSignatories}
                        handleRemoveSignatories={handleRemoveSignatories}
                      />
                    ),
                    icon: 'uil uil-edit',
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default EditSignatories
