/** @format */

/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import localize from '../../Global/Localize'
import { MONEY, TALLY_MODES } from '../../Helpers/constants'
import { formatCurrency } from '../../Helpers/formatters'
import ActionModal from '../ActionModal/ActionModal'
import { MaskNumber } from '../InputMasks/MaskNumber'

const TallyCounter = ({
  getTotal,
  getTally,
  resetValues,
  setResetValues,
  denomination,
  handleOnBlur,
}) => {
  const [tallyMode, setTallyMode] = useState(TALLY_MODES.TALLY_BY_COUNT)
  const [tally, setTally] = useState({})
  const [showResetModal, setShowResetModal] = useState(false)

  const handleChange = event => {
    setTallyMode(event.target.value)
  }

  const handleChangeTallyByCount = (value, name, multiplier) => {
    setTally(prevState => ({
      ...prevState,
      [name]: {
        tallyByCount: toNumber(value),
        tallyByTotal: toNumber(value) * multiplier,
      },
    }))
  }

  const toNumber = data => {
    if (data?.toString()?.includes(',')) {
      return Number(data.replace(/,/g, ''))
    } else {
      return Number(data)
    }
  }

  const handleChangeTallyByTotal = (value, name, multiplier) => {
    setTally(prevState => ({
      ...prevState,
      [name]: {
        tallyByCount: toNumber(value) / multiplier,
        tallyByTotal: value,
      },
    }))
  }

  const handleChangeTallyByCountBlur = (value, name, multiplier) => {
    let inputValue

    if (toNumber(value) % 1 !== 0) {
      if (tally[name]?.tallyByCount && denomination?.id) {
        inputValue = tally[name]?.tallyByCount
      } else {
        inputValue = 0
      }
    } else {
      inputValue = value
    }

    setTally({
      ...tally,
      [name]: {
        tallyByCount: toNumber(inputValue),
        tallyByTotal: toNumber(inputValue) * multiplier,
      },
    })

   handleOnBlur && handleOnBlur()
  }

  const getTallyByCount = useMemo(
    () => name => {
      if (tally[name]) {
        return tally[name]?.tallyByCount
      } else {
        return 0.0
      }
    },
    [tally]
  )

  const getTallyByTotal = useMemo(
    () => name => {
      if (tally[name]) {
        return toNumber(tally[name]?.tallyByTotal)
      } else {
        return 0.0
      }
    },
    [tally]
  )

  const handleChangeTallyByTotalBlur = (value, name, multiplier) => {
    const isValueValid =
      toNumber(value) % multiplier === 0 && toNumber(value) > 0

    if (isValueValid) {
      setTally(prevState => ({
        ...prevState,
        [name]: {
          tallyByCount: toNumber(value) / multiplier,
          tallyByTotal: toNumber(value),
        },
      }))
    } else {
      setTally({
        ...tally,
        [name]: {
          tallyByCount: 0,
          tallyByTotal: 0,
        },
      })
    }

   handleOnBlur && handleOnBlur()
  }

  const getTotalAmount = () => {
    let total = 0

    MONEY.forEach(item => {
      total += toNumber(getTallyByTotal(item.name))
    })

    getTotal(total)

    return total
  }

  const toggleResetModal = () => {
    setShowResetModal(!showResetModal)
  }

  const handleReset = () => {
    MONEY.forEach(item => {
      if (tally[item.name]) {
        tally[item.name].tallyByCount = 0
        tally[item.name].tallyByTotal = 0
      }
    })

    setShowResetModal(false)
    setTallyMode(TALLY_MODES.TALLY_BY_COUNT)
  }

  useEffect(() => {
    getTally(tally)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tally])

  useEffect(() => {
    if (resetValues) {
      handleReset()
      setResetValues(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  const tallyCountByDenomination = () => {
    setTallyMode(TALLY_MODES.TALLY_BY_TOTAL)

    let tallyValue = {}

    MONEY.forEach(item => {
      if (denomination[item.serverValue]) {
        tallyValue[item.name] = {
          tallyByCount: toNumber(denomination[item.serverValue]) / item.value,
          tallyByTotal: toNumber(denomination[item.serverValue]),
        }
      } else {
        tallyValue[item.name] = {
          tallyByCount: 0,
          tallyByTotal: 0,
        }
      }
    })

    setTally(tallyValue)
  }

  useEffect(() => {
    if (denomination?.id) {
      tallyCountByDenomination()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [denomination?.id])

  return (
    <div className="border rounded p-3">
      {showResetModal && (
        <ActionModal
          cancelText={'close'}
          confirmText={'reset'}
          modalTitle={'reset'}
          handleClose={toggleResetModal}
          handleAction={handleReset}
        >
          <div className="text-center">
            <h5>
              Are you sure you want to reset all values from
              {' ' + formatCurrency(getTotalAmount(), null, null, false)} to
              0.00?
            </h5>
          </div>
        </ActionModal>
      )}

      <div className="d-flex justify-content-center align-items-center gap-3 bg-light rounded px-2 py-3">
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="tallyByCount"
              value="tallyByCount"
              checked={tallyMode === 'tallyByCount'}
              onChange={handleChange}
            />
            {localize('tallyByCount')}
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name="tallyByTotal"
              value="tallyByTotal"
              checked={tallyMode === 'tallyByTotal'}
              onChange={handleChange}
            />
            {localize('tallyByTotal')}
          </Label>
        </FormGroup>
      </div>
      {MONEY.map((item, index) => (
        <div
          className="row d-flex align-items-center mt-3"
          key={`money-${index}-${item.name}`}
        >
          <div className="col-2">
            <label className="text-capitalize">{localize(item.name)}</label>
          </div>
          <div className="col-5">
            {tallyMode === TALLY_MODES.TALLY_BY_COUNT ? (
              <MaskNumber
                defaultMaskValue={getTallyByCount(item.name)}
                onMaskNumber={e =>
                  handleChangeTallyByCount(
                    e.target.rawValue,
                    item.name,
                    item.value
                  )
                }
                onBlur={e => {
                  handleChangeTallyByCountBlur(
                    e.target.rawValue,
                    item.name,
                    item.value
                  )
                }}
              />
            ) : (
              <input
                type="number"
                className={`form-control`}
                disabled
                value=""
              />
            )}
          </div>
          <div className="col-5">
            {tallyMode === TALLY_MODES.TALLY_BY_TOTAL ? (
              <MaskNumber
                defaultMaskValue={getTallyByTotal(item.name)}
                onMaskNumber={e =>
                  handleChangeTallyByTotal(
                    e.target.rawValue,
                    item.name,
                    item.value
                  )
                }
                onBlur={e => {
                  handleChangeTallyByTotalBlur(
                    e.target.rawValue,
                    item.name,
                    item.value
                  )
                }}
              />
            ) : (
              <div className="form-control text-end bg-light" disabled>
                {formatCurrency(getTallyByTotal(item.name))}
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="row mt-2">
        <div className="col-7">
          <label className="text-capitalize">
            {localize('totalCurrencyBreakdown')}
          </label>
        </div>
        <div className="col-5">
          <div className={`form-control text-end bg-light`} disabled>
            {formatCurrency(getTotalAmount())}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end  mt-2 bg-light rounded py-3 px-2">
        <button onClick={toggleResetModal} className="btn btn-success">
          {localize('reset')}
        </button>
      </div>
    </div>
  )
}

export default TallyCounter
