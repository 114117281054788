/** @format */

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import { getData } from '../../Helpers/webApi'
import Loader from '../Loaders/Loader'
import RecordsPerPage from '../RecordsPerPage/RecordsPerPage'
import Pager from '../Tables/Pager'
import SimpleTable from '../Tables/SimpleTable'

const UserDefinedReportsLookup = ({ onSelect, displayValue, disabled }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    updateDepth: true,
    traverseTree: true,
    filterText: '',
  })

  const columns = [
    {
      label: 'reportName',
      template: r => (
        <span dangerouslySetInnerHTML={{ __html: r.indentedReportName }}></span>
      ),
    },
    {
      label: 'category',
      template: r => r?.categoryDescription,
    },
    {
      label: 'reportPath',
      template: r => r?.reportPath,
    },
    {
      label: 'reportQuery',
      template: r => r?.reportQuery,
    },
    {
      label: 'sSRSHost',
      template: r => r?.reportHost,
    },
    {
      label: 'sSRSPort',
      template: r => r?.reportPort,
    },
    {
      label: 'depth',
      template: r => r?.depth,
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  async function loadData() {
    setIsBusy(true)

    const url = '/Report/find-by-filter-in-page'

    const { success, data } = await getData(url, reqParams, false)
    if (success) {
      setData(data)
    }

    setIsBusy(false)
  }

  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
          pointerEvents: disabled ? 'none' : 'all',
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {disabled ? displayValue || '--' : displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {localize('userDefinedReports')}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          <ModalBody className="bg-white p-3">
            {isBusy ? (
              <Loader />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-space-between ">
                  <div className="d-flex align-items-center my-2 col-4">
                    <RecordsPerPage
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <div>
                    <div className="d-flex align-items-center float-right my-2">
                      <label
                        className="text-capitalize"
                        htmlFor="searchUserDefinedReports"
                      >
                        {localize('search')}
                      </label>
                      <input
                        value={reqParams.filterText}
                        onChange={e =>
                          setReqParams({
                            ...reqParams,
                            filterText: e.target.value,
                          })
                        }
                        type="search"
                        className="form-control ms-3"
                        id="searchUserDefinedReports"
                      />

                      <button
                        type="submit"
                        className="btn btn-primary ms-1 text-capitalize"
                      >
                        <i className="uil uil-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" py-1">
                  <SimpleTable
                    columns={columns}
                    data={data?.pageCollection}
                    onSelect={r => {
                      setModalActive(false)
                      onSelect(r)
                    }}
                    canSelect
                  />
                </div>
                <Pager
                  itemsCount={data.itemsCount}
                  pageSize={reqParams.pageSize}
                  pageIndex={reqParams.pageIndex}
                  pageBatchSize={5}
                  onPageChange={p =>
                    setReqParams({ ...reqParams, pageIndex: p })
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter className="bg-white p-2">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default UserDefinedReportsLookup
