/** @format */

/**
 * NOTE: All dictionary values/keys must be in camel case
 */

const _n = {
  name: {
    en: 'name',
    sw: 'jina',
  },
  no: {
    en: 'no',
    sw: 'hapana',
  },
  numberOfLeaves: {
    en: 'number of leaves',
    sw: 'idadi ya Karatasi',
  },
  negligibleBalance: {
    en: 'negligible balance',
    sw: 'usawa usio na maana',
  },
  nationality: {
    en: 'nationality',
    sw: 'utaifa',
  },
  newAvailableBalance: {
    en: 'new available balance',
    sw: 'mizani mpya inayopatikana',
  },
  newTellerBalance: {
    en: 'new teller balance',
    sw: 'salio jipya la kikasha',
  },
  nonIndividualDescription: {
    en: 'non-individual description',
    sw: 'maelezo yasiyo ya mtu binafsi',
  },
  nonIndividualRegistrationNumber: {
    en: 'non-individual registration number',
    sw: 'nambari ya usajili isiyo ya mtu binafsi',
  },
  newLogo: {
    en: 'new logo',
    sw: 'nembo mpya',
  },
  nextOfKin: {
    en: 'next of kin',
    sw: 'jamaa wa karibu',
  },
  nominated: {
    en: 'nominated',
    sw: 'teuliwa',
  },
  nominee: {
    en: 'nominee',
    sw: 'mteule',
  },
  nominatedPercentage: {
    en: 'nominated percentage',
    sw: 'asilimia iliyoteuliwa',
  },
  nextOfKinPreferences: {
    en: ' next of kin preferences',
    sw: 'upendeleo wa karibu wa jamaa',
  },
  nationalSocialSecurityFundNumber: {
    en: 'national social security fund number',
    sw: 'nambari ya nssf',
  },
  nationalHospitalInsuranceFundNumber: {
    en: 'national hospital insurance fund number',
    sw: 'nambari ya nhif',
  },
  numberingSeries: {
    en: 'numbering series',
    sw: 'mfululizo wa nambari',
  },
  nextOfKinDetails: {
    en: 'next of kin details',
    sw: 'maelezo ya jamaa wa karibu',
  },
  nextOfKinHistory: {
    en: 'next of kin history',
    sw: 'historia ya jamaa wa karibu',
  },
  numberType: {
    en: 'number type',
    sw: 'aina ya nambari',
  },
  new: {
    en: 'new',
    sw: 'mpya',
  },
  netRefundable: {
    en: 'net refundable',
    sw: 'jumla inayorejeshwa',
  },
  nextNumber: {
    en: 'next number',
    sw: 'nambari inayofuata',
  },
  next: {
    en: 'next',
    sw: 'inayofuata',
  },
  narrative: {
    en: 'narrative',
    sw: 'hadithi',
  },

  noCancel: {
    en: 'no, cancel',
    sw: 'apana, funga',
  },
  november: {
    en: 'november',
    sw: 'novemba',
  },
  numberingSeriesLookup: {
    en: 'numbering series lookup',
    sw: 'utafutaji wa mfululizo wa nambari',
  },
  netDisbursementAmount: {
    en: 'net disbursement amount',
    sw: 'jumla ya malipo',
  },
  newBalance: {
    en: 'new balance',
    sw: 'baki mpya',
  },
  newAbility: {
    en: 'new ability',
    sw: 'uwezo mpya',
  },
  newAmount: {
    en: 'new amount',
    sw: 'kiasi kipya',
  },
  negativeHistoryIndicator: {
    en: 'negative history indicator',
    sw: 'kiashiria cha historia hasi',
  },
  NPLFlag: {
    en: 'NPL flag',
    sw: 'ilani ya NPL',
  },
  newValue: {
    en: 'new value',
    sw: 'thamani mpya',
  },
  notificationRefundType: {
    en: 'notification refund type',
    sw: 'aina ya marejesho ya taarifa',
  },
  notificationCustomerName: {
    en: 'notification customer name',
    sw: 'jina la mteja wa taarifa',
  },
  notificationDebitFullAccountNumber: {
    en: 'notification debit full account number',
    sw: 'nambari kamili ya akaunti ya taarifa',
  },
  notificationCreditFullAccountNumber: {
    en: 'notification credit full account number',
    sw: 'nambari kamili ya akaunti ya taarifa',
  },
  notificationTotalValue: {
    en: 'notification total value',
    sw: 'jumla ya thamani ya taarifa',
  },
  notificationPrincipal: {
    en: 'notification principal',
    sw: 'msingi wa taarifa',
  },
  notificationInterest: {
    en: 'notification interest',
    sw: 'riba ya taarifa',
  },
  notificationSerialNumber: {
    en: 'notification serial number',
    sw: 'nambari ya mfululizo ya taarifa',
  },
  notificationStatus: {
    en: 'notification status',
    sw: 'hali ya taarifa',
  },
  numberOfPeriods: {
    en: 'number of periods',
    sw: 'idadi ya vipindi',
  },
  newRepaymentSchedule: {
    en: 'new repayment schedule',
    sw: 'ratiba mpya ya malipo',
  },
  notificationJobs: {
    en: 'notification jobs',
    sw: 'kazi za taarifa',
  },
  number: {
    en: 'number',
    sw: 'nambari',
  },
  netWorth: {
    en: 'net worth',
    sw: 'thamani halisi',
  },
  narration: {
    en: 'narration',
    sw: 'maelezo',
  },
  newBookBalance: {
    en: 'new book balance',
    sw: 'salio jipya la kitabu',
  },
  Number: {
    en: 'number',
    sw: 'nambari',
  },
  numberOfVouchers: {
    en: 'number of vouchers',
    sw: 'idadi ya vocha',
  },
  netPayable: {
    en: 'net payable',
    sw: 'jumla ya kulipwa',
  },
  NSSFNumber: {
    en: 'NSSF number',
    sw: 'nambari ya NSSF',
  },
  NHIFNumber: {
    en: 'NHIF number',
    sw: 'nambari ya NHIF',
  },
  nssfNumber: {
    en: 'NSSF number',
    sw: 'nambari ya NSSF',
  },
  nhifNumber: {
    en: 'NHIF number',
    sw: 'nambari ya NHIF',
  },
  netPay: {
    en: 'net pay',
    sw: 'malipo halisi',
  },
  nhifReliefAmount: {
    en: 'nhif relief amount',
    sw: 'kiwango cha msamaha wa nhif',
  },
  nationalityDescription: {
    en: 'nationality',
    sw: 'utaifa',
  },
  nextOfKins: {
    en: 'next of kins',
    sw: 'jamaa wa karibu',
  },
  newCustodian: {
    en: 'new custodian',
    sw: 'mlezi mpya',
  },
  newLocation: {
    en: 'new location',
    sw: 'eneo jipya',
  },
  noteReference: {
    en: 'note reference',
    sw: 'marejeo ya noti',
  },
  nhifRelief: {
    en: 'NHIF relief',
    sw: 'msamaha wa NHIF',
  },
}
export default _n
