/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
/* eslint-disable */
import React from 'react'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'

export const CarryFowardsStatement = ({
  kvp,
  selectedKvp,
  setKvp,
  customerCarryFowardEntries,
}) => {
  const columns = [
    {
      label: localize('beneficiaryChartOfAccountName'),
      template: r => <>{r.beneficiaryChartOfAccountName}</>,
    },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('amount'),
      template: r => <>{formatCurrency(r.amount)}</>,
    },
    {
      label: localize('runningBalance'),
      template: r => <>{formatCurrency(r.runningBalance)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]
  return (
    <>
      <div className="d-flex align-content-end justify-content-end mb-2 mt-2">
        <div className="d-flex align-items-center me-5">
          <label className="col-auto me-3 text-capitalize">
            {localize('charge')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="SelectedKvp"
            value={kvp}
            onChange={e => {
              setKvp(e.value)
            }}
          />
        </div>
      </div>

      <SimpleTable columns={columns} data={customerCarryFowardEntries} />
    </>
  )
}
