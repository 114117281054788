/** @format */

import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
/* eslint-disable */
import React from 'react'

export const CommissionsLookUp = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('chargeLookup')}
      url="/lookup/Commission/find-commission-by-filter-in-page"
      params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
      columns={[
        { label: localize('description'), template: r => <>{r.description}</> },
        {
          label: localize('maximumCharge'),
          template: r => <>{r.maximumCharge}</>,
        },
        {
          label: localize('roundingType'),
          template: r => <>{r.roundingTypeDescription}</>,
        },
        {
          label: localize('permitOverdrawing'),
          template: r => (
            <span className="text-capitalize">
              {r.permitOverdrawing.toString()}
            </span>
          ),
        },
        {
          label: localize('isLocked'),
          template: r => (
            <span className="text-capitalize">{r.isLocked.toString()}</span>
          ),
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}
