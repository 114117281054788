/** @format */

import React, { useEffect, useState } from 'react'
import localize from '../../../../Global/Localize'
import SimpleTable from '../../../Tables/SimpleTable'
import Loader from '../../../Loaders/Loader'
import { postData } from '../../../../Helpers/webApi'

export const CustomerMetadataAssociates = ({
  selectedCustomer,
  doFetchAssociates,
}) => {
  const [associates, setAssociates] = useState([])
  const [loading, setLoading] = useState(false)

  const loadData = async () => {
    setLoading(true)
    const { success, data } = await postData(
      '/lookup/Customer/find-customer-metadata',
      {
        FetchAssociates: true,
        customerId: selectedCustomer?.id,
      },
      false,
      null
    )
    if (success) {
      setAssociates(data?.customerAssociates)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (doFetchAssociates) {
      loadData()
    }
  }, [doFetchAssociates])

  const columns = [
    { label: 'relationship', template: r => <>{r.relationshipDescription}</> },
    { label: 'name', template: r => <>{r.associateFullName}</> },
    {
      label: localize('accountNumber'),
      template: r => <>{r.associateReference1}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r.associateReference2}</>,
    },
    {
      label: localize('pfNumber'),
      template: r => <>{r.associateReference3}</>,
    },
    {
      label: 'gender',
      template: r => <>{r.associateIndividualGenderDescription}</>,
    },
    {
      label: 'identityCardType',
      template: r => <>{r.associateIdentityCardTypeDescription}</>,
    },
    {
      label: 'identityCardNumber',
      template: r => <>{r.associateIdentityCardNumber}</>,
    },
    {
      label: `${localize('addressLine')} 1`,
      template: r => <>{r.associateAddressAddressLine1}</>,
    },
    {
      label: `${localize('addressLine')} 2`,
      template: r => <>{r.associateAddressAddressLine2}</>,
    },
    {
      label: `physicalAddress`,
      template: r => <>{r.associateAddressStreet}</>,
    },
    { label: `postalCode`, template: r => <>{r.associateAddressPostalCode}</> },
    { label: `city`, template: r => <>{r.associateAddressCity}</> },
    { label: `emailAddress`, template: r => <>{r.associateAddressEmail}</> },
    { label: `landLine`, template: r => <>{r.associateAddressLandLine}</> },
    { label: `mobileLine`, template: r => <>{r.associateAddressMobileLine}</> },
    { label: `remarks`, template: r => <>{r.remarks}</> },
  ]

  return loading ? (
    <Loader />
  ) : (
    <SimpleTable data={associates} columns={columns} />
  )
}
