/** @format */

import localize from '../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import React from 'react'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import BranchLookup from '../../../../../Components/Lookup/BranchLookup'
import Loader from '../../../../../Components/Loaders/Loader'

export const AddExternalAgencyModal = ({
  selectedExternalAgency,
  setSelectedExternalAgency,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div className="col d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('agencyType')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="ExternalAgencyType"
                value={selectedExternalAgency?.agencyType}
                onChange={e =>
                  setSelectedExternalAgency({
                    ...selectedExternalAgency,
                    agencyType: e.value,
                  })
                }
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('description')}
              </label>
              <input
                className="form-control"
                value={selectedExternalAgency?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedExternalAgency({
                    ...selectedExternalAgency,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.ChartOfAccountId?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('chartOfAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={selectedExternalAgency?.chartOfAccountName}
                onSelect={e => {
                  clearValidation('ChartOfAccountId')
                  setSelectedExternalAgency({
                    ...selectedExternalAgency,
                    chartOfAccountId: e.id,
                    chartOfAccountName: e.name,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.BranchId?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <BranchLookup
                displayValue={selectedExternalAgency?.branchDescription}
                onSelect={e => {
                  clearValidation('BranchId')
                  setSelectedExternalAgency({
                    ...selectedExternalAgency,
                    branchId: e.id,
                    branchDescription: e.description,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
