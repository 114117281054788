/** @format */

/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import localize from '../../../Global/Localize'
import { CustomerFilter, CustomerTypes } from '../../../Global/enumeration'
import { toBase64 } from '../../../Helpers/blobs'
import { formatDate } from '../../../Helpers/formatters'
import { getData, postData } from '../../../Helpers/webApi'
import ImportButton from '../../Buttons/ImportButton/ImportButton'
import Checkbox from '../../Checkbox/Checkbox'
import EnumsServerCombo from '../../ComboBox/EnumsServerCombo'
import Loader from '../../Loaders/Loader'
import RecordsPerPage from '../../RecordsPerPage/RecordsPerPage'
import Pager from '../../Tables/Pager'
import SimpleTable from '../../Tables/SimpleTable'
import ImportCustomerModal from './ImportCustomers/ImportCustomerModal'

function ListCustomerByType({
  onSelect,
  canClickOnce,
  contextMenu,
  onClickOnce,
  canSelect,
  hiddenColumnsIndexes = [],
  onCustomerCheck,
  toggleSelectAllCustomers,
  onSelectAllCustomers,
  selectedCustomers,
  handleUpdate,
  setResetValues,
  resetValues,
  showImport,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const ref = useRef(null)
  const columns = [
    {
      label: localize('select') + '?',
      template: r => (
        <>
          <Checkbox
            checked={selectedCustomers.find(c => c.id === r?.id)}
            onCheck={value => onCustomerCheck(r, value)}
          />
        </>
      ),
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('serialNumber'),
      template: r => <>{r?.paddedSerialNumber}</>,
    },
    {
      label: localize('name'),
      template: r => <>{r?.fullName}</>,
    },
    {
      label: localize('memberNumber'),
      template: r => <>{r?.memberNumber}</>,
    },
    {
      label: localize('accountNumber'),
      template: r => <>{r?.accountNumber}</>,
    },
    {
      label: localize('personalFileNumber'),
      template: r => <>{r?.personalFileNumber}</>,
    },
    {
      label: localize('membershipStatus'),
      template: r => <>{r?.membershipStatusDescription}</>,
    },
    {
      label: localize('individualType'),
      template: r => <>{r?.individualTypeDescription}</>,
    },
    {
      label: localize('gender'),
      template: r => <>{r?.individualGenderDescription}</>,
    },
    {
      label: localize('maritalStatus'),
      template: r => <>{r?.individualMaritalStatusDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r?.individualNationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r?.individualIdentityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r?.individualIdentityCardNumber}</>,
    },
    {
      label: localize('identityCardSerialNumber'),
      template: r => <>{r?.individualIdentityCardSerialNumber}</>,
    },
    {
      label: localize('birthDate'),
      template: r => <>{formatDate(r?.individualBirthDate, true)}</>,
    },
    {
      label: localize('employmentSector'),
      template: r => <>{r?.individualEmploymentSectorDescription}</>,
    },
    {
      label: localize('employmentDate'),
      template: r => <>{formatDate(r?.individualEmploymentDate, true)}</>,
    },
    {
      label: localize('employmentDesignation'),
      template: r => <>{r?.individualEmploymentDesignation}</>,
    },
    {
      label: localize('employmentTermsOfService'),
      template: r => <>{r?.individualEmploymentTermsOfServiceDescription}</>,
    },
    {
      label: localize('educationLevel'),
      template: r => <>{r?.individualEducationLevelDescription}</>,
    },
    {
      label: localize('membershipClass'),
      template: r => <>{r?.membershipClassDescription}</>,
    },
    {
      label: localize('disabilityType'),
      template: r => <>{r?.individualDisabilityTypeDescription}</>,
    },
    {
      label: localize('hudumaNumber'),
      template: r => <>{r?.individualHudumaNumber}</>,
    },
    {
      label: localize('registrationNumber'),
      template: r => <>{r?.nonIndividualRegistrationNumber}</>,
    },
    {
      label: localize('registrationSerialNumber'),
      template: r => <>{r?.nonIndividualRegistrationSerialNumber}</>,
    },
    {
      label: localize('meetingFrequency'),
      template: r => <>{r?.nonIndividualMeetingFrequencyDescription}</>,
    },
    {
      label: localize('meetingDay'),
      template: r => <>{r?.nonIndividualMeetingDayOfWeekDescription}</>,
    },
    {
      label: localize('meetingTime'),
      template: r => <>{r?.nonIndividualMeetingTime}</>,
    },
    {
      label: localize('meetingPlace'),
      template: r => <>{r?.nonIndividualMeetingPlace}</>,
    },
    {
      label: localize('addressLine1'),
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine2'),
      template: r => <>{r?.addressAddressLine2}</>,
    },

    {
      label: localize('physicalAddress'),
      template: r => <>{r?.addressAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: localize('city'),
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: localize('emailAddress'),
      template: r => <>{r?.addressEmail}</>,
    },
    {
      label: localize('landLine'),
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: localize('mobileLine'),
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: localize('internetAddress'),
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: localize('monthlyContribution'),
      template: r => <>{r?.monthlyContribution}</>,
    },

    {
      label: `${localize('reference1')} ${localize('account')}`,
      template: r => <>{r?.reference1}</>,
    },
    {
      label: `${localize('reference2')} ${localize('membership')}`,
      template: r => <>{r?.reference2}</>,
    },
    {
      label: `${localize('reference3')} ${localize('personalFile')}`,
      template: r => <>{r?.reference3}</>,
    },
    {
      label: localize('branch'),
      template: r => <>{r?.branchDescription}</>,
    },
    {
      label: localize('duty') + '/' + localize('workStation'),
      template: r => <>{r?.dutyStationDescription}</>,
    },
    {
      label: localize('electoralZone'),
      template: r => <>{r?.electoralZoneDescription}</>,
    },
    {
      label: `${localize('business')}/${localize('group')}/${localize(
        'employer'
      )}`,
      template: r => <>{r?.employerDescription}</>,
    },
    {
      label: localize('retirementAge'),
      template: r => <>{r?.individualRetirementAge}</>,
    },
    {
      label: localize('terminationDate'),
      template: r => <>{r?.membershipTerminationDate}</>,
    },
    {
      label: localize('age'),
      template: r => <>{r?.age}</>,
    },
    {
      label: `${localize('membershipPeriod')}(${localize('months')})`,
      template: r => <>{r?.membershipPeriod}</>,
    },
    {
      label: localize('adminDivision'),
      template: r => <>{r?.administrativeDivisionDescription}</>,
    },
    {
      label: localize('joinDate'),
      template: r => <>{formatDate(r?.registrationDate)}</>,
    },
    {
      label: `${localize('isTaxExempt')} + '?'`,
      template: r => <>{r?.isTaxExempt?.toString()}</>,
    },
    {
      label: `${localize('inhibitGuaranteeing')} + '?'`,
      template: r => <>{r?.inhibitGuaranteeing?.toString()}</>,
    },
    {
      label: `${localize('inhibitPosting')} + '?'`,
      template: r => <>{r?.isLocked?.toString()}</>,
    },
    {
      label: localize('recruitedBy'),
      template: r => <>{r?.recruitedBy}</>,
    },

    {
      label: localize('remarks'),
      template: r => <>{r?.remarks}</>,
    },
    {
      label: localize('modifiedBy'),
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: localize('modifiedDate'),
      template: r => <>{formatDate(r?.modifiedDate)}</>,
    },
    {
      label: localize('createdBy'),
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [selectAllCustomers, setSelectAllCustomers] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [fileName, setFileName] = useState('')
  const [showImportModal, setShowImportModal] = useState(false)
  const [fileUploadDone, setFileUploadDone] = useState(false)
  const [newFileName, setNewFileName] = useState('')
  const [reqParams, setReqParams] = useState({
    filterText: '',
    customerFilter: 0,
    pageIndex: 0,
    pageSize: 10,
    type: Object.keys(CustomerTypes)[0],
  })

  async function loadData() {
    setIsBusy(true)
    const url = newFileName
      ? '/Customer/find-customer-by-file-in-page'
      : '/Customer/find-customer-by-type-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        fileName: newFileName?.fileName,
        customerType: reqParams.type,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      if (data?.pageCollection?.length > 0 && newFileName?.fileName) {
        setData(data)
        setNewFileName('')
      } else {
        setIsBusy(true)
        const url = '/Customer/find-customer-by-type-and-filter-in-page'

        const { success, data } = await getData(
          url,
          {
            ...reqParams,
          },
          false,
          null
        )
        if (success) {
          setIsBusy(false)
          setData(data)
          setNewFileName('')
        }
      }
    }
  }

  const customerColumns = columns?.filter(
    (_c, i) => hiddenColumnsIndexes?.indexOf(i) === -1
  )

  const handleClickImportButton = () => {
    setShowImportModal(true)
    setFileName('')
    setFileUploadDone(false)
  }

  const handleFileUploadToServer = async (buffer, file) => {
    setIsBusy(true)
    const url = `/Base64/media-upload-base-64`

    const { success } = await postData(
      url,
      {
        fileName: file,
        buffer,
      },
      false,
      'File uploaded successfully'
    )

    if (success) {
      setIsBusy(false)
      setShowImportModal(false)
      setFileUploadDone(true)
    }
  }

  const handleUpload = async () => {
    ref?.current?.click()

    ref.current.value = null

    ref.current.onchange = async e => {
      let file = e.target.files[0]
      try {
        let b64 = await toBase64(file)
        let buffer = b64.split(',')[1].toString()
        setFileName({
          buffer,
          fileName: file.name,
          fileExtension: file.type,
        })

        handleFileUploadToServer(buffer, file.name)
      } catch {
        Swal.fire('Invalid File', 'Error loading file', 'error')
        return
      }
    }
  }

  const fetchUploadDone = async () => {
    setIsBusy(true)
    const url = '/Base64/file-upload-done'

    const { data } = await getData(
      url,
      {
        fileName: fileName?.fileName,
      },
      false
    )

    if (data?.succeeded) {
      setIsBusy(false)
      setNewFileName(data)
      setFileUploadDone(false)
      loadData()
    } else {
      setIsBusy(false)
      Swal.fire({
        text: 'Uploaded file could not be found, upload a new file and try again',
        title: 'Operation failed',
        icon: 'error',
      })
    }
  }

  const handleCloseImportModal = () => {
    setShowImportModal(false)
    setFileName('')
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams.pageIndex, reqParams.pageSize, newFileName])

  useEffect(() => {
    if (resetValues) {
      setSelectAllCustomers(false)
      setSelectAll(false)
      setResetValues(false)
      setFileName('')
      setNewFileName('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  useEffect(() => {
    if (fileUploadDone) {
      fetchUploadDone()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadDone])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          {showImportModal && (
            <ImportCustomerModal
              handleCloseImportModal={handleCloseImportModal}
              handleUpload={handleUpload}
            />
          )}

          <input type="file" ref={ref} className="d-none" accept=".csv" />

          <div className="d-flex justify-content-between align-items-center m-2">
            <div className="d-flex align-items-center">
              <RecordsPerPage
                reqParams={reqParams}
                setReqParams={setReqParams}
              />
            </div>
            <div className="d-flex align-items-center">
              <label className="text-capitalize me-2">{localize('type')}</label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerType"
                value={reqParams.type}
                onChange={e =>
                  setReqParams({
                    ...reqParams,
                    type: e.value,
                  })
                }
              />
            </div>
            <form
              className="d-flex align-items-center justify-content-end"
              onSubmit={e => {
                e.preventDefault()
                setReqParams({
                  ...reqParams,
                  filterText: search,
                  pageIndex: 0,
                })
              }}
            >
              <label className="text-capitalize col-2" htmlFor="searchTable">
                {localize('search')}
              </label>
              <EnumsServerCombo
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerFilter"
                value={reqParams.customerFilter}
                onChange={e => {
                  setReqParams({
                    ...reqParams,
                    customerFilter: e.value,
                  })
                }}
              />

              <input
                type="search"
                id="searchTable"
                className="form-control ms-2"
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                }}
              />

              <button className="btn btn-primary ms-2">
                <i className="uil uil-filter"></i>
              </button>
            </form>
          </div>
          <div
            style={{
              maxHeight: '50dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable
              columns={customerColumns}
              data={data?.pageCollection}
              canSelect={canSelect}
              onSelect={onSelect}
              canClickOnce={canClickOnce}
              onClickOnce={onClickOnce}
              contextMenu={contextMenu}
            />
          </div>

          <div className="mb-2"></div>
          <Pager
            itemsCount={data.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />

          <div className="d-flex align-items-center justify-content-end bg-light px-3 py-3">
            {showImport && (
              <ImportButton handleClickImportButton={handleClickImportButton} />
            )}
          </div>

          <div className="py-3 row px-1 bg-light mt-3 mx-2 rounded">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start gap-3 align-items-center">
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="selectAll"
                    checked={selectAll}
                    onCheck={v => {
                      setSelectAll(v)
                      onSelectAllCustomers(data.pageCollection, v)
                    }}
                  />

                  <label htmlFor="selectAll" className="ms-2 text-capitalize">
                    {localize('togglePageSelection')}
                  </label>
                </div>
                <div className="d-flex align-items-center">
                  <Checkbox
                    id="checkAll"
                    checked={selectAllCustomers}
                    onCheck={v => {
                      setSelectAllCustomers(v)
                      toggleSelectAllCustomers(v)
                    }}
                  />

                  <label htmlFor="checkAll" className="ms-2 text-capitalize">
                    {localize(`selectAll ${data.itemsCount} customers`)}
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center float-end">
                <button
                  onClick={handleUpdate}
                  className="btn btn-success float-end"
                >
                  {localize('update')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ListCustomerByType
