/** @format */
import InvestmentProducts from '../../../../Components/Selectables/Plain/InvestmentProducts'

export const CreditTypesOffsetableInvestmentProducts = ({
  creditTypesInvestmentProducts,
  loading,
  disabled,
  onCheckCreditTypesInvestmentProducts,
  prefix,
}) => {
  return (
    <InvestmentProducts
      disabled={disabled}
      isBusy={loading}
      prefix={prefix}
      investmentProductsToBeSelected={creditTypesInvestmentProducts}
      onCheckInvestmentProduct={r => {
        onCheckCreditTypesInvestmentProducts &&
          onCheckCreditTypesInvestmentProducts(r)
      }}
    />
  )
}
