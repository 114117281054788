/** @format */

import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

const AssetLookup = ({ onSelect, displayValue }) => {
  return (
    <SimpleLookup
      title={localize('assetRegisterLookup')}
      url="/lookup/Asset/find-assets-by-filter-in-page"
      params={{
        filterText: '',
        pageIndex: 0,
        pageSize: 10,
        showBelowReorderLevel: true,
      }}
      columns={[
        {
          label: localize('tagNumber'),
          template: r => <>{r.tagNumber}</>,
        },
        {
          label: localize('name'),
          template: r => <>{r.itemRegisterDescription}</>,
        },
        {
          label: localize('assetType'),
          template: r => <>{r.itemRegisterAssetTypeDescription}</>,
        },
        {
          label: localize('serialNumber'),
          template: r => <>{r.serialNumber}</>,
        },
        {
          label: localize('model'),
          template: r => <>{r.model}</>,
        },
        {
          label: localize('location'),
          template: r => <>{r.locationDescription}</>,
        },
        {
          label: localize('status'),
          template: r => <>{r.statusDescription}</>,
        },
        {
          label: localize('remarks'),
          template: r => <>{r.remarks}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default AssetLookup
