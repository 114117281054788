/** @format */

import localize from '../../../../../../Global/Localize'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../../../Helpers/formatters'

export const WorkingTimePreferences = ({ entries, setEntries }) => {
  const columns = [
    {
      label: localize('workingDayOfWeek'),
      template: r => <>{r.workingDayOfWeekDescription}</>,
    },
    {
      label: localize('timeDurationStartTime'),
      template: r => <>{r.timeDurationStartTime}</>,
    },
    {
      label: localize('timeDurationEndTime'),
      template: r => <>{r.timeDurationEndTime}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    { label: localize('createdDate'), template: r => <>{formatDate(r.createdDate)}</> },
  ]

  return (
    <>
      <SimpleTable
        columns={columns}
        data={entries}
        contextMenu={e => (
          <>
            <div
              onClick={() => {
                setEntries(entries.filter(x => x.id !== e.id))
              }}
              className="ctxt-menu-item"
            >
              <i className="uil uil-trash"></i>
              {localize('remove')}
            </div>
          </>
        )}
      />
    </>
  )
}
