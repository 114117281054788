/** @format */

import { useEffect, useState } from 'react'
import { postData } from '../../../../../../../Helpers/webApi'
import swal from 'sweetalert2'
import ModalTemplate from '../../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../../Global/Localize'
import { CreatePurchaseOrderModal } from './CreatePurchseOrderModal'
import { ViewPurchaseOrderModal } from './ViewPurchaseOrderModal'
import { EditPurchaseOrderModal } from './EditPurchaseOrderModal'

export const PurchaseOrderModal = ({
  mode,
  onHideModal,
  onUpdateRefreshAt,
  onSetMode,
  selectedPurchaseOrder,
  setSelectedPurchaseOrder,
}) => {
  const [purchaseOrderEntries, setPurchaseOrderEntries] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      addPurchaseOrdersWithEntries()
    }
    if (mode === 'edit') {
      editRequisitionWithItems()
    }
  }

  useEffect(() => {
    return () => {
      setPurchaseOrderEntries([])
    }
  }, [mode])

  const addPurchaseOrdersWithEntries = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/PurchaseOrder/add-purchase-order-with-entries',
      {
        PurchaseOrder: selectedPurchaseOrder,
        PurchaseOrderItems: purchaseOrderEntries,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      setPurchaseOrderEntries([])
      setSelectedPurchaseOrder(null)
      swal.fire(
        'Create Purchase Order',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  const editRequisitionWithItems = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/PurchaseOrder/update-purchase-order-with-entries',
      {
        PurchaseOrder: selectedPurchaseOrder,
        PurchaseOrderItems: purchaseOrderEntries,
      },
      false,
    )
    if (success) {
      onSetMode(null)
      onUpdateRefreshAt()
      setPurchaseOrderEntries([])
      setSelectedPurchaseOrder(null)
      swal.fire(
        'Edit Purchase Order',
        'Operation Completed Successfully',
        'success',
      )
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            modalTitle={localize('purchaseOrder')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            hideUpdate={mode === 'view'}
            disableCreate={isBusy}
            modalClassName={'modal-xl'}
          >
            {mode === 'add' && (
              <CreatePurchaseOrderModal
                isBusy={isBusy}
                setIsBusy={setIsBusy}
                purchaseOrderEntries={purchaseOrderEntries}
                setPurchaseOrderEntries={setPurchaseOrderEntries}
                purchaseOrderModel={selectedPurchaseOrder}
                setPurchaseOrderModel={setSelectedPurchaseOrder}
              />
            )}
            {mode === 'view' && (
              <ViewPurchaseOrderModal
                selectedPurchaseOrder={selectedPurchaseOrder}
              />
            )}
            {mode === 'edit' && (
              <EditPurchaseOrderModal
                setIsBusy={setIsBusy}
                isBusy={isBusy}
                setSelectedPurchaseOrder={setSelectedPurchaseOrder}
                selectedPurchaseOrder={selectedPurchaseOrder}
                purchaseOrderEntries={purchaseOrderEntries}
                setPurchaseOrderEntries={setPurchaseOrderEntries}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}
