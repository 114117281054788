/** @format */

import Loader from '../../../../Components/Loaders/Loader'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import React from 'react'

export const RefundTypeCommissions = ({
  disabled,
  waiting,
  selectedRefundType,
  setSelectedRefundType,
  prefix,
}) => {
  const handleRefundTypeCommissions = (commissions, checkValue) => {
    const com = [...selectedRefundType?.commissions].map(comm => {
      if (comm.id === commissions.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    setSelectedRefundType({ ...selectedRefundType, commissions: com })
  }

  return (
    <div className="px-4">
      {waiting ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedRefundType?.commissions.map(commissions => (
            <li
              key={commissions.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${commissions.id}`}
                checked={commissions.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleRefundTypeCommissions(commissions, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${commissions.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  commissions.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {commissions.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
