/** @format */
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import localize from '../../../../Global/Localize'
import Tab from '../../../../Components/Tabs/Tab'
import { ExceptedCustomers } from './ExceptedCustomers'

function ViewConcessionExceptions({
  mode,
  onHideModal,
  concessionException,
  exceptionEntries,
}) {
  return (
    <>
      {mode && (
        <Modal isOpen className="modal-xl" backdrop={true} keyboard>
          <div>
            <ModalHeader className="bg-white text-capitalize">
              {localize('view')}
              <i className="uil uil-angle-right mx-2"></i>
              <span className="text-muted fs-6 text-uppercase">
                {localize('concessionExceptions')}
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHideModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <label className="col-1 text-capitalize">
                    {localize('name')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    defaultValue={concessionException?.description}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-2 text-capitalize">
                    {localize('creditType')}
                  </label>
                  <input
                    className="form-control"
                    disabled
                    defaultValue={concessionException?.creditTypeDescription}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <Tab
                  tabItems={[
                    {
                      label: localize('exceptedCustomers'),
                      item: (
                        <ExceptedCustomers
                          mode={'view'}
                          exceptedCustomers={exceptionEntries}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </ModalBody>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ViewConcessionExceptions
