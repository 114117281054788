/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { AuditLogFilter } from '../../../Global/enumeration'
import Pager from '../../../Components/Tables/Pager'
import BreadCrumb from '../../../Components/BreadCrumb/Index'
import localize from '../../../Global/Localize'
import GroupedTable from '../../../Components/Tables/GroupedTable'
import { getData } from '../../../Helpers/webApi'
import DatePicker from '../../../Components/Date/DatePicker'
import { formatDate } from '../../../Helpers/formatters'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../Components/Loaders/Loader'
import ExportAuditLogs from './Partials/ExportAuditLogs'
import { generateGuid } from '../../../Helpers/extensions'
import RecordsPerPage from '../../../Components/RecordsPerPage/RecordsPerPage'
import ViewChangeLogs from './Partials/ViewChangeLogs'

const AuditLogs = () => {
  const [reqParams, setReqParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    endDate: null,
    filter: '',
    auditLogFilter: Object.keys(AuditLogFilter)[0],
    customDate: false,
    dateRequestsFilter: 15,
  })

  const [exportParams, setExportParams] = useState({
    pageIndex: 0,
    pageSize: 10,
    customDate: false,
    dateRequestsFilter: 15,
  })

  const [auditLogFilter, setAuditLogFilter] = useState(
    Object.keys(AuditLogFilter)[0]
  )

  const [groupBy, setGroupBy] = useState({
    label: localize('eventType'),
    column: 'eventType',
    value: 0,
  })

  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [changeLogsTableData, setChangeLogsTableData] = useState([])
  const [modalMode, setModalMode] = useState(null)
  const [search, setSearch] = useState('')
  const [changeLogRecordDetails, setChangeLogRecordDetails] = useState({})

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams])

  const loadTableData = async () => {
    setIsBusy(true)

    const { success, data } = await getData(
      '/AuditLog/find-audit-log-by-date-range-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data?.pageCollection)
      setTableMeta({ ...reqParams, itemsCount: data?.itemsCount })
    }

    setIsBusy(false)
  }

  const groupingColumns = [
    {
      column: 'eventType',
      value: 0,
    },
    {
      column: 'tableName',
      value: 1,
    },
    {
      column: 'applicationDomainName',
      value: 4,
    },
    {
      column: 'applicationUserName',
      value: 5,
    },
    {
      column: 'environmentUserName',
      value: 6,
    },
    {
      column: 'environmentMachineName',
      value: 7,
    },
    {
      column: 'environmentDomainName',
      value: 8,
    },
    {
      column: 'environmentOSVersion',
      value: 9,
    },
    {
      column: 'environmentMACAddress',
      value: 10,
    },
    {
      column: 'environmentMotherboardSerialNumber',
      value: 11,
    },
    {
      column: 'environmentProcessorId',
      value: 11,
    },
    {
      column: 'environmentIPAddress',
      value: 12,
    },
  ]

  const columns = [
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
    { label: localize('eventType'), template: r => <>{r?.eventType}</> },
    { label: localize('tableName'), template: r => <>{r?.tableName}</> },
    { label: localize('recordId'), template: r => <>{r?.recordID}</> },
    {
      label: localize('applicationDomainName'),
      template: r => <>{r?.applicationDomainName}</>,
    },
    {
      label: localize('applicationUserName'),
      template: r => <>{r?.applicationUserName}</>,
    },
    {
      label: localize('environmentUserName'),
      template: r => <>{r?.environmentUserName}</>,
    },
    {
      label: localize('environmentMachineName'),
      template: r => <>{r?.environmentMachineName}</>,
    },
    {
      label: localize('environmentDomainName'),
      template: r => <>{r?.environmentDomainName}</>,
    },
    {
      label: localize('environmentOSVersion'),
      template: r => <>{r?.environmentOSVersion}</>,
    },
    {
      label: localize('environmentMACAddress'),
      template: r => <>{r?.environmentMACAddress}</>,
    },
    {
      label: localize('environmentMotherboardSerialNumber'),
      template: r => <>{r?.environmentMotherboardSerialNumber}</>,
    },
    {
      label: localize('environmentProcessorId'),
      template: r => <>{r?.environmentProcessorId}</>,
    },
    {
      label: localize('environmentIPAddress'),
      template: r => <>{r?.environmentIPAddress}</>,
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
  ]

  const handleGetChangeLogs = data => {
    const logsData = data.auditRecords
    setChangeLogsTableData(
      logsData.map(val => {
        return {
          ...val,
          id: generateGuid(),
        }
      })
    )
    setChangeLogRecordDetails({
      eventType: data.eventType,
      tableName: data.tableName,
    })
    setModalMode('view')
  }

  const handleDateSelect = v => {
    if (v.custom) {
      setReqParams({
        ...reqParams,
        customDate: true,
        startDate: v.startDate,
        endDate: v.endDate,
      })
      setExportParams({
        ...exportParams,
        startDate: v.startDate,
        endDate: v.endDate,
      })
    } else {
      setReqParams({
        ...reqParams,
        customDate: false,
        dateRequestsFilter: v.value,
      })
      setExportParams({
        ...exportParams,
        dateRequestsFilter: v.value,
      })
    }
  }

  const handleClose = () => {
    setModalMode(null)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('systemAdministration'),
          localize('risksAndAudit'),
          localize('auditLogs'),
        ]}
      />
      {modalMode === 'view' && (
        <ViewChangeLogs
          handleAction={handleClose}
          handleClose={handleClose}
          mode={modalMode}
          changeLogsTableData={changeLogsTableData}
          changeLogsRecordDetails={changeLogRecordDetails}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="card bg-white mb-2">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="mb-2">
                    <ExportAuditLogs
                      reqParams={reqParams}
                      isBusy={isBusy}
                      setIsBusy={setIsBusy}
                    />
                  </div>
                  <div className="row d-flex align-items-center justify-content-between">
                    <RecordsPerPage
                      className="col-2"
                      reqParams={reqParams}
                      setReqParams={setReqParams}
                    />
                    <div className="col-2">
                      <div className="d-flex align-items-center">
                        <label
                          className="text-capitalize"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          value={{
                            startDate: reqParams?.startDate,
                            endDate: reqParams?.endDate,
                          }}
                          onSelect={v => {
                            handleDateSelect(v)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="d-flex align-items-center">
                        <label className="text-capitalize">
                          {localize('groupBy')}
                        </label>
                        <EnumsServerCombo
                          value={groupBy?.value}
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="AuditLogPropertyGroup"
                          onChange={({ label, value }) =>
                            setGroupBy({
                              label,
                              value,
                              column: groupingColumns[value]?.column,
                            })
                          }
                          sort
                        />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="d-flex align-items-center justify-content-end">
                        <label
                          htmlFor="searchTable"
                          className="text-capitalize me-2"
                        >
                          {localize('search')}
                        </label>
                        <EnumsServerCombo
                          className="ms-2"
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="AuditLogFilter"
                          value={auditLogFilter}
                          onChange={e => {
                            setAuditLogFilter(e.value)
                          }}
                          sort
                        />
                        <form
                          className="d-flex align-items-center col-5 col"
                          onSubmit={e => {
                            e.preventDefault()
                            setReqParams({
                              ...reqParams,
                              filter: search,
                              auditLogFilter: auditLogFilter,
                              pageIndex: 0,
                            })
                          }}
                        >
                          <input
                            type="search"
                            id="searchTable"
                            className="form-control ms-2"
                            defaultValue={reqParams?.filter}
                            onChange={e => setSearch(e.target.value)}
                          ></input>
                        </form>
                        <button
                          type="button"
                          className="btn btn-primary ms-2"
                          onClick={loadTableData}
                        >
                          <i className="uil uil-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row col-12 mt-2">
                    <GroupedTable
                      groupBy={groupBy}
                      columns={columns}
                      data={tableData}
                      contextMenu={x => (
                        <div
                          className="ctxt-menu-item"
                          onClick={() => {
                            handleGetChangeLogs(x)
                          }}
                        >
                          <i className="uil uil-server"></i>
                          {localize('changeLog')}
                        </div>
                      )}
                    />
                    <div className="row col-12 mt-2">
                      <Pager
                        {...tableMeta}
                        onPageChange={p =>
                          setReqParams({ ...reqParams, pageIndex: p })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuditLogs
