/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../../Components/Loaders/Loader'
import RecordsPerPage from '../../../../../../../Components/RecordsPerPage/RecordsPerPage'
import Pager from '../../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import {
  bankReconciliationPeriodAuthOption,
  journalEntryFilter,
} from '../../../../../../../Global/enumeration'
import { showNotification } from '../../../../../../../Helpers/Notification/ShowNotification'
import {
  formatCurrency,
  formatDate,
  parseDate,
} from '../../../../../../../Helpers/formatters'
import { getData, putData } from '../../../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../../../Validation/ValidationState'
import GLAccountSummary from '../../GLAccountSummary/GLAccountSummary'

const BankReconciliationEntries = ({ period, showUpdate, setSelectedData }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [entries, setEntries] = useState([])
  const [entry, setEntry] = useState({
    bankReconciliationPeriodAuthOption: Object.keys(
      bankReconciliationPeriodAuthOption
    )[0],
  })
  const [reqParams, setReqParams] = useState({
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
    tallyDebitCredits: false,
    journalEntryFilter: Object.keys(journalEntryFilter)[4],
  })
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const columns = [
    {
      label: 'status',
      template: r => r?.statusDescription,
    },
    {
      label: 'branch',
      template: r => r?.journalEntryJournalBranchDescription,
    },
    {
      label: 'primaryDescription',
      template: r => r?.journalEntryJournalPrimaryDescription,
    },
    {
      label: 'amount',
      template: r => formatCurrency(r?.journalEntryAmount),
    },
    {
      label: 'secondaryDescription',
      template: r => r?.journalEntryJournalSecondaryDescription,
    },
    {
      label: 'reference',
      template: r => r?.journalEntryJournalReference,
    },
    {
      label: 'transactionCode',
      template: r => r?.journalEntryJournalTransactionCodeDescription,
    },
    {
      label: 'valueDate',
      template: r => parseDate(r?.journalEntryValueDate, true),
    },
    {
      label: 'transactedBy',
      template: r => r?.journalEntryCreatedBy,
    },
    {
      label: 'transactedDate',
      template: r => formatDate(r?.journalEntryCreatedDate, true),
    },
    {
      label: 'remarks',
      template: r => r?.remarks,
    },
    {
      label: 'modifiedBy',
      template: r => r?.modifiedBy,
    },
    {
      label: 'modifiedDate',
      template: r => formatDate(r?.modifiedDate),
    },
    {
      label: 'createdBy',
      template: r => r?.createdBy,
    },
    {
      label: 'createdDate',
      template: r => formatDate(r?.createdDate),
    },
  ]

  const fetchEntries = async () => {
    setIsBusy(true)
    const url =
      '/BankReconciliationPeriod/find-bank-reconciliation-entry-by-reconciliation-id-and-filter-in-page'

    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        bankReconciliationPeriodId: period?.id,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setEntries(data)
    }

    setIsBusy(false)
  }

  const handleUpdateEntries = async () => {
    if (!period?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select a period first'
      )

    if (!entry?.remarks)
      return GlobalValidationErrors.set({
        Remarks: {
          message: 'Remarks is required',
        },
      })

    setIsBusy(true)

    const url = '/BankReconciliationPeriod/close-bank-reconciliation-period'

    const { success } = await putData(
      url,
      {
        ...entry,
        parentId: period.id,
        id: period.id,
        chartOfAccountId: period.chartOfAccountId,
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setEntry({
        bankReconciliationPeriodAuthOption: Object.keys(
          bankReconciliationPeriodAuthOption
        )[0],
      })
      setEntries([])
      showNotification('Bank Reconciliation Period ', 'success')

      if (setSelectedData) setSelectedData(null)
    }

    setIsBusy(false)
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  useEffect(() => {
    if (period && period.id) fetchEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    period?.id,
    reqParams.tallyDebitCredits,
    reqParams.pageIndex,
    reqParams.pageSize,
  ])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3 mb-3 d-flex justify-content-between align-items-center">
            <RecordsPerPage
              className="col-md-4"
              reqParams={reqParams}
              setReqParams={setReqParams}
            />
            <div className="col-md-3 d-flex align-items-center">
              <Checkbox
                id="tallyClosingDebitCredits"
                checked={reqParams.tallyDebitCredits}
                onCheck={value => {
                  setReqParams({
                    ...reqParams,
                    tallyDebitCredits: value,
                  })
                }}
              />
              <label
                className="text-capitalize me-2"
                htmlFor="tallyClosingDebitCredits"
              >
                {localize('tallyDebitCredits')}
              </label>
            </div>

            <div className="col-md-5  d-flex align-items-center justify-content-end gap-2">
              <div className="d-flex align-items-center">
                <label className="text-capitalize me-2" htmlFor="searchTable">
                  {localize('search')}
                </label>
                <EnumsServerCombo
                  customContainerStyle={{
                    maxWidth: 180,
                    minWidth: 180,
                  }}
                  enumsKey="JournalEntryFilter"
                  value={reqParams.journalEntryFilter}
                  onChange={e =>
                    setReqParams({
                      ...reqParams,
                      journalEntryFilter: e.value,
                    })
                  }
                  sort
                />
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="search"
                  id="searchTable"
                  className="form-control ms-2"
                  value={reqParams.filterText}
                  onChange={e => {
                    setReqParams({
                      ...reqParams,
                      filterText: e.target.value,
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      fetchEntries()
                    }
                  }}
                />
                <button
                  id="FilterButton"
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    fetchEntries()
                  }}
                >
                  <i className="uil uil-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            className="p-3"
            style={{
              maxHeight: 'calc(70dvh - 300px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <SimpleTable data={entries.pageCollection} columns={columns} />
          </div>
          <Pager
            itemsCount={entries.itemsCount}
            pageSize={reqParams.pageSize}
            pageIndex={reqParams.pageIndex}
            pageBatchSize={10}
            onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
          />

          <div className="row bg-light my-3 mt-2 py-3">
            <div className="col d-flex justify-content-end gap-2 align-items-center">
              {showUpdate && (
                <>
                  <div
                    className="d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors['Remarks']?.message
                    }
                  >
                    <label htmlFor="remarks" className="text-capitalize col-3">
                      {localize('remarks')}
                    </label>
                    <input
                      type="text"
                      className="form-control ms-2"
                      onChange={e => {
                        clearValidation('Remarks')
                        setEntry({
                          ...entry,
                          remarks: e.target.value,
                        })
                      }}
                      value={entry.remarks || ''}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="reconciliationPeriodAuthOption"
                      className="text-capitalize me-2"
                    >
                      {localize('authOption')}
                    </label>
                    <EnumsServerCombo
                      customContainerStyle={{
                        maxWidth: 150,
                        minWidth: 150,
                      }}
                      enumsKey="BankReconciliationPeriodAuthOption"
                      onChange={e => {
                        setEntry({
                          ...entry,
                          bankReconciliationPeriodAuthOption: e.value,
                        })
                      }}
                      value={entry?.bankReconciliationPeriodAuthOption || ''}
                    />
                  </div>
                  <button
                    className="btn btn-success"
                    onClick={handleUpdateEntries}
                  >
                    {localize('update')}
                  </button>
                </>
              )}
            </div>

            <div className="row m-1">
              <GLAccountSummary summary={entries} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BankReconciliationEntries
