/** @format */

// use this method asyncronously e.g await toBase64(file)
export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const downloadBase64File = (base64, fileName) => {
  const link = document.createElement('a')
  link.href = `data:application/pdf;base64,${base64}`
  link.download = fileName
  link.click()
}

export const downloadReceipt = (base64, fileName) => {
  const link = document.createElement('a')
  link.href = `data:text/plain;base64,${base64}`
  link.download = fileName
  link.click()
}

export const downloadCSV = (base64, fileName) => {
  const link = document.createElement('a')
  link.href = `data:text/csv;base64,${base64}`
  link.download = fileName
  link.click()
}
