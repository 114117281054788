/** @format */

import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { BankersChequeBookCommissions } from './BankersChequeBookCommissions'
import { BankersChequeBookChequeLeaves } from './BankersChequeBookChequeLeaves'


export const ViewBankersChequeBookModal = ({
  setSelectedBankersChequeBook,
  selectedBankersChequeBook,
  waitingMetadata,
}) => {
  return (
    <>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('bank')}</label>
          <input
            className="form-control"
            value={selectedBankersChequeBook?.bankLinkageBankName}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('bankBranch')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              selectedBankersChequeBook?.bankLinkageBranchDescription
            }
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('chartOfAccountName')}
          </label>
          <input
            className="form-control"
            disabled
            defaultValue={
              selectedBankersChequeBook?.bankLinkageChartOfAccountName
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('numberOfLeaves')}
          </label>
          <input

            className="form-control"
            value={selectedBankersChequeBook?.numberOfLeaves}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('remarks')}</label>
          <input
            className="form-control"
            defaultValue={selectedBankersChequeBook?.remarks}
            disabled
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('initialLeafNumber')}
          </label>
          <input
            className="form-control"
            value={selectedBankersChequeBook?.initialLeafNumber}
            disabled
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Tab
            tabItems={[
              {
                label: localize('applicableCharges'),
                item: (
                  <BankersChequeBookCommissions
                    isBusy={waitingMetadata}
                    prefix={'view-applicable-charges'}
                    disabled
                    setSelectedBankersChequeBook={setSelectedBankersChequeBook}
                    selectedBankersChequeBook={selectedBankersChequeBook}
                    waitingMetadata={waitingMetadata}
                  />
                ),
              },
              {
                label: localize('chequeLeaves'),
                item: (
                  <BankersChequeBookChequeLeaves
                    selectedBankersChequeBook={selectedBankersChequeBook}
                    entries={selectedBankersChequeBook?.chequeLeaves}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}
