/** @format */

import React, { useState } from 'react'
import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import Walkthrough from '../../../../Components/Walkthrough/Walkthrough'
import SavingsProductSection from './SavingsProductSection'
import WellKnownChargesSection from './WellKnownChargesSection'
import ExemptionsSection from './ExemptionsSection'

function EditSavingsProduct({
  readonly,
  mode,
  close,
  refreshData,
  product,
  setProduct,
  changeMode,
}) {
  const [activePage, setActivePage] = useState(0)

  const pages = [
    {
      title: localize('savingsProduct'),
      icon: '',
      component: (
        <SavingsProductSection
          mode={mode}
          readonly={readonly}
          close={close}
          refreshData={refreshData}
          product={product}
          setProduct={setProduct}
          changeMode={changeMode}
        />
      ),
      skippable: product?.id,
    },
    {
      title: localize('wellKnownCharges'),
      icon: '',
      component: (
        <WellKnownChargesSection product={product} readonly={readonly} />
      ),
      skippable: product?.id,
    },
    {
      title: localize('exemptions'),
      icon: '',
      component: <ExemptionsSection product={product} readonly={readonly} />,
      skippable: product?.id,
    },
  ]

  return (
    <ModalTemplate
      handleClose={close}
      modalTitle={
        mode === 'add' ? localize('savingsProduct') : product?.description
      }
      modalMode={mode}
      modalClassName="modal-xl"
      hideFooter
    >
      <Walkthrough
        preload
        pages={pages}
        activePage={activePage}
        changePage={p => setActivePage(p)}
      />
    </ModalTemplate>
  )
}

export default EditSavingsProduct
