/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Loader from '../../../../../Components/Loaders/Loader'
import ListBankReconciliation from '../../../../../Components/ReUsables/BankReconciliation/ListBankReconciliation'
import localize from '../../../../../Global/Localize'
import {
  getLastDateOfMonth,
  getTodaysDate,
  parseDate,
} from '../../../../../Helpers/formatters'
import { postData, putData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import CreatePeriod from './CreatePeriod/CreatePeriod'
import { showNotification } from '../../../../../Helpers/Notification/ShowNotification'

function Periods() {
  const [isBusy, setIsBusy] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState({
    durationStartDate: getLastDateOfMonth(),
    durationEndDate: parseDate(getTodaysDate()),
  })
  const [modalMode, setModalMode] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [isBusyCreatingOrUpdating, setIsBusyCreatingOrUpdating] =
    useState(false)
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }
  const handleClose = () => {
    setSelectedPeriod(null)
    setModalMode(null)
  }

  const handleCreateOrUpdatePeriod = async () => {
    if (
      !selectedPeriod.chartOfAccountId ||
      !selectedPeriod.bankBranchName ||
      !selectedPeriod.bankAccountNumber
    ) {
      GlobalValidationErrors.set({
        ChartOfAccountId: !selectedPeriod.chartOfAccountId && {
          message: 'Chart Of Account is required',
        },
        BankBranchName: !selectedPeriod.bankBranchName && {
          message: 'Bank Branch Name is required',
        },
        BankAccountNumber: !selectedPeriod.bankAccountNumber && {
          message: 'Bank Account Number is required',
        },
      })
      return
    }
    setIsBusyCreatingOrUpdating(true)

    const url =
      modalMode === 'add'
        ? '/BankReconciliationPeriod/create-bank-reconciliation-period'
        : '/BankReconciliationPeriod/update-bank-reconciliation-period'

    if (modalMode === 'add') {
      const { success } = await postData(url, selectedPeriod, false)

      if (success) {
        setRefresh(true)
        handleClose()
        showNotification('Create Bank Reconciliation Period', 'success')
      }
    } else {
      const { success } = await putData(url, selectedPeriod, false)
      if (success) {
        handleClose()
        setRefresh(true)
        showNotification('Update Bank Reconciliation Period', 'success')
      }
    }

    setIsBusyCreatingOrUpdating(false)
  }

  const handleAddButtonClick = () => {
    setSelectedPeriod({
      durationStartDate: getLastDateOfMonth(),
      durationEndDate: parseDate(getTodaysDate()),
    })
    setModalMode('add')
  }

  const handleSelectPeriod = (r, mode) => {
    setSelectedPeriod(r)
    setModalMode(mode)
  }

  return (
    <>
      <BreadCrumb
        tree={[
          localize('accountsManagement'),
          localize('bankReconciliation'),
          localize('periods'),
        ]}
      />
      {(modalMode === 'add' || modalMode === 'edit') && (
        <CreatePeriod
          mode={modalMode}
          handleAction={handleCreateOrUpdatePeriod}
          handleClose={handleClose}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          clearValidation={clearValidation}
          globalValidationErrors={globalValidationErrors}
          isBusyCreatingOrUpdating={isBusyCreatingOrUpdating}
        />
      )}
      <div className="card bg-white rounded">
        <div className="card-body">
          {isBusy ? (
            <Loader />
          ) : (
            <ListBankReconciliation
              refresh={refresh}
              setRefresh={setRefresh}
              showAddButton
              showContextMenu
              handleSelectPeriod={handleSelectPeriod}
              handleAddButtonClick={handleAddButtonClick}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Periods
