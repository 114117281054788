/** @format */
import Loader from '../../../../Components/Loaders/Loader'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import React from 'react'

export const ChequeTypeCommissions = ({
  disabled,
  isBusy,
  selectedChequeType,
  setSelectedChequeType,
  prefix,
}) => {
  const handleCheckChequeTypeCommissions = (commissions, checkValue) => {
    const com = [...selectedChequeType?.commissions].map(comm => {
      if (comm.id === commissions.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    setSelectedChequeType({ ...selectedChequeType, commissions: com })
  }

  return (
    <div className="px-4">
      {isBusy ? (
        <Loader />
      ) : (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {selectedChequeType?.commissions.map(commissions => (
            <li
              key={commissions.id}
              style={{ listStyle: 'none' }}
              className=" d-flex align-items-center"
            >
              <Checkbox
                id={`${prefix ? prefix : 'com'}-${commissions.id}`}
                checked={commissions.checked}
                disabled={disabled}
                margined
                onCheck={c => {
                  handleCheckChequeTypeCommissions(commissions, c)
                }}
              />
              <label
                htmlFor={`${prefix ? prefix : 'com'}-${commissions.id}`}
                className={`fw-normal ms-2 cursor-pointer ${
                  commissions.checked ? 'text-primary fw-bold' : ''
                }`}
              >
                {commissions.description}
              </label>
            </li>
          ))}
        </div>
      )}
    </div>
  )
}
