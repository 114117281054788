/** @format */
import localize from '../../../../Global/Localize'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import Loader from '../../../../Components/Loaders/Loader'

export const CreateLoaningRemarkModal = ({
  selectedLoaningRemark,
  setSelectedLoaningRemark,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label className="col-1 text-capitalize">
                {localize('description')}
              </label>
              <input
                className="form-control"
                value={selectedLoaningRemark?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedLoaningRemark({
                    ...selectedLoaningRemark,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
