/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'
import SimpleLookup from './SimpleLookup'

function ElectoralZonesLookup({ displayValue, onSelect, disabled }) {
  const columns = [
    { label: localize('description'), template: r => <>{r?.description}</> },
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('isLocked') + '?',
      template: r => (
        <span className="text-capitalize">
          {localize(`${r?.isLocked?.toString()}`)}
        </span>
      ),
    },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        disabled={disabled}
        title={localize('electoralZoneLookup')}
        columns={columns}
        params={{
          filterText: '',
          pageIndex: 0,
          pageSize: 10,
          updateDepth: true,
          traverseTree: true,
        }}
        url="/lookup/electoralZones/find-by-filter-in-page"
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default ElectoralZonesLookup
