/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'

function FuneralHomeLookup({ onSelect, displayValue }) {
  const columns = [
    {
      label: 'description',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'addressLine1',
      template: r => <>{r?.addressAddressLine1}</>,
    },
    {
      label: 'addressLine2',
      template: r => <>{r?.addressAddressLine2}</>,
    },
    {
      label: 'physicalAddress',
      template: r => <>{r?.addressStreet}</>,
    },
    {
      label: 'postalCode',
      template: r => <>{r?.addressPostalCode}</>,
    },
    {
      label: 'City',
      template: r => <>{r?.addressCity}</>,
    },
    {
      label: 'emailAddress',
      template: r => <>{r?.addressEmail}</>,
    },
    {
      label: 'landLine',
      template: r => <>{r?.addressLandLine}</>,
    },
    {
      label: 'mobileLine',
      template: r => <>{r?.description}</>,
    },
    {
      label: 'mobileLine',
      template: r => <>{r?.addressMobileLine}</>,
    },
    {
      label: 'internetAddress',
      template: r => <>{r?.addressInternet}</>,
    },
    {
      label: 'isLocked' + ' ?',
      template: r => (
        <span className="text-capitalize">{r?.isLocked?.toString()}</span>
      ),
    },
    {
      label: 'modifiedBy',
      template: r => <>{r?.modifiedBy}</>,
    },
    {
      label: 'modifiedDate',
      template: r => <>{r?.modifiedDate}</>,
    },
    {
      label: 'createdBy',
      template: r => <>{r?.createdBy}</>,
    },
    {
      label: 'createdDate',
      template: r => <>{r?.createdDate}</>,
    },
  ]
  return (
    <>
      <SimpleLookup
        columns={columns}
        title={localize('funeralHomeLookUp')}
        url="/lookup/FuneralHome/find-funeral-homes-by-filter-in-page"
        params={{ filterText: '', pageIndex: 0, pageSize: 10 }}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}

export default FuneralHomeLookup
