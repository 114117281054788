/** @format */

/* eslint-disable */
import React, { useState } from 'react'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import ListLoanDisbursement from '../../../../../Components/ReUsables/LoanDisbursement/ListLoanDisbursement'
import localize from '../../../../../Global/Localize'
import CreateLoanDisbursementBatch from './CreateLoanDisbursementBatch/CreateLoanDisbursementBatch'

const BatchOrigination = () => {
  const [mode, setMode] = useState(null)
  const [reload, setReload] = useState(false)

  const handleClickAddButton = () => {
    setMode('add')
  }

  const handleClose = () => {
    setMode(null)
  }

  return (
    <>
      {mode && (
        <CreateLoanDisbursementBatch
          handleClose={handleClose}
          mode={mode}
          setReload={setReload}
        />
      )}

      <BreadCrumb
        tree={[
          localize('creditManagement'),
          localize('loanDisbursement'),
          localize('batchOrigination'),
        ]}
      />
      <div className="card bg-white rounded">
        <div className="card-body">
          <>
            <ListLoanDisbursement
              showCreateButton
              showEdit
              handleClickAddButton={handleClickAddButton}
              reload={reload}
              setReload={setReload}
            />
          </>
        </div>
      </div>
    </>
  )
}

export default BatchOrigination
