/** @format */

import { useState } from 'react'
import localize from '../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../Helpers/formatters'
import SimpleTable from '../../Tables/SimpleTable'
import ViewCustomerAccountStatementModal from './CustomerAccountStatement/ViewCustomerAccountStatementModal'

export const ViewCustomerSavingsAccounts = ({
  customerSavingsAccounts,
  canShowStatement,
}) => {
  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r?.statusDescription}</>,
    },
    { label: localize('remarks'), template: r => <>{r?.remarks}</> },
    {
      label: localize('productName'),
      template: r => <>{r?.customerAccountTypeProductCodeDescription}</>,
    },
    {
      label: localize('productSection'),
      template: r => (
        <>{r?.customerAccountTypeTargetProductSectionDescription}</>
      ),
    },
    {
      label: localize('bookBalance'),
      template: r => <>{formatCurrency(r?.bookBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('availableBalance'),
      template: r => <>{formatCurrency(r?.availableBalance)}</>,
      headerTextAlign: 'right',
    },
    {
      label: localize('recordStatus'),
      template: r => <>{r?.recordStatusDescription}</>,
    },
    {
      label: localize('isDefault') + ' ?',
      template: r => (
        <span className="text-capitalize">
          {r?.customerAccountTypeTargetProductIsDefault?.toString()}
        </span>
      ),
    },
    { label: localize('modifiedBy'), template: r => <>{r?.modifiedBy}</> },
    { label: localize('modifiedDate'), template: r => <>{r?.modifiedDate}</> },
    { label: localize('createdBy'), template: r => <>{r?.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  const [account, setAccount] = useState({})
  const [showStatement, setShowStatement] = useState(false)

  const handleViewStatement = r => {
    setAccount(r)
    setShowStatement(true)
  }

  const handleClose = () => {
    setShowStatement(false)
    setAccount({})
  }

  return (
    <>
      {showStatement && (
        <ViewCustomerAccountStatementModal
          customer={{
            id: account?.customerId,
          }}
          customerAccount={account}
          handleClose={handleClose}
        />
      )}
      <SimpleTable
        data={customerSavingsAccounts}
        columns={columns}
        contextMenu={r => (
          <>
            {canShowStatement && (
              <div
                className="ctxt-menu-item text-capitalize"
                onClick={() => {
                  handleViewStatement(r)
                }}
              >
                <i className="uil uil-eye"></i>
                <span>{localize('view')}</span>
              </div>
            )}
          </>
        )}
      />
    </>
  )
}
