/** @format */

export const FiltersContainer = ({ children }) => {
  return (
    <>
      <div className="row mt-3 mb-3 align-items-center">
        {children.map(child => (
          <div className="col-md-3">{child}</div>
        ))}
      </div>
    </>
  )
}
