/** @format */
import React from 'react'
import { formatDate } from '../../Helpers/formatters'
import ConditionalSimpleLookup from './ConditionalSimpleLookup'
import localize from '../../Global/Localize'

const RequisitionLookup = ({
  onSelect,
  displayValue,
  departmentId,
  departmentDescription,
}) => {
  return (
    <ConditionalSimpleLookup
      title={
        <>
          <>{localize('requisitionLookup')} </>
          <i className="uil uil-caret-right mx-3"></i>
          <>{departmentDescription}</>
        </>
      }
      url="/lookup/Requisition/find-requisitions-by-department-id-record-status-and-filter-in-page"
      params={{
        departmentId,
        startDate: null,
        endDate: null,
        recordStatus: 2,
        text: '',
        pageIndex: 0,
        pageSize: 10,
        dateRequestsFilter: 15,
        customDate: false,
      }}
      conditionalField="departmentId"
      columns={[
        {
          label: localize('reference#'),
          template: r => <>{r.paddedReferenceNumber}</>,
        },
        {
          label: localize('branch'),
          template: r => <>{r.branchDescription}</>,
        },
        {
          label: localize('expectedDate'),
          template: r => <>{formatDate(r.expectedDate, true)}</>,
        },
        {
          label: localize('recordStatus'),
          template: r => <>{r.recordStatusDescription}</>,
        },
        {
          label: localize('reason'),
          template: r => <>{r.reason}</>,
        },
        {
          label: localize('createdBy'),
          template: r => <>{r.createdBy}</>,
        },
        {
          label: localize('createdDate'),
          template: r => <>{formatDate(r.createdDate)}</>,
        },
      ]}
      onSelect={onSelect}
      displayValue={displayValue}
    />
  )
}

export default RequisitionLookup
