/** @format */
import localize from '../../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import React, { useState } from 'react'
import TimePicker from '../../../../../../Components/Date/TimePicker'
import { WorkingTimePreferences } from './WorkingTimePreferences'
import { postData } from '../../../../../../Helpers/webApi'
import Loader from '../../../../../../Components/Loaders/Loader'

export const CompanyWorkingTime = ({
  companyWorkingTimes,
  setCompanyWorkingTimes,
}) => {
  const [workingTime, setWorkingTime] = useState({
    systemInitializationTime: '08:00:00',
    systemLockTime: '20:00:00',
    workingDayOfWeek: '1',
  })
  const [isBusy, setIsBusy] = useState(false)

  const handleOnAddWorkTimeEntries = async () => {
    setIsBusy(true)
    const { success, data } = await postData(
      '/Company/add-company-working-time-entries',
      {
        CompanyWorkingTime: { ...workingTime },
        SystemIntilizationTime: workingTime?.systemInitializationTime,
        SystemLockTime: workingTime?.systemLockTime,
        CompanyWorkingTimes: companyWorkingTimes,
      },
      false,
    )
    if (success) {
      setCompanyWorkingTimes([...companyWorkingTimes, data])
      setWorkingTime({
        systemInitializationTime: '08:00:00',
        systemLockTime: '20:00:00',
        workingDayOfWeek: '1',
      })
    }
    setIsBusy(false)
  }

  return (
    <>
      <div
        className="p-2 mt-4"
        style={{
          border: 'solid 1px #0000001f',
          borderRadius: 10,
          position: 'relative',
        }}
      >
        <div className="row" style={{ marginTop: -10 }}>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize me-3">
              {localize('workingDayOfWeek')}
            </label>
            <EnumsServerCombo
              value={workingTime?.workingDayOfWeek}
              onChange={e => {
                setWorkingTime({
                  ...workingTime,
                  workingDayOfWeek: e.value,
                })
              }}
              customContainerStyle={{ width: '100%' }}
              enumsKey={'WorkingDayOfWeek'}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize me-3">
              {localize('systemInitializationTime')}
            </label>
            <TimePicker
              name={'initializationTime'}
              defaultTime={workingTime?.systemInitializationTime}
              onTimeChange={c => {
                setWorkingTime({
                  ...workingTime,
                  systemInitializationTime: c,
                })
              }}
            />
          </div>
          <div className="col d-flex align-items-center">
            <label htmlFor="" className="text-capitalize me-3">
              {localize('systemLockTime')}
            </label>
            <TimePicker
              name={'lockTime'}
              defaultTime={workingTime?.systemLockTime}
              onTimeChange={c => {
                setWorkingTime({
                  ...workingTime,
                  systemLockTime: c,
                })
              }}
            />
          </div>
        </div>
        {isBusy ? (
          <Loader />
        ) : (
          <>
            <div
              className="bg-light d-flex justify-content-end align-items-center p-2"
              style={{ borderRadius: 5 }}
            >
              <button
                onClick={handleOnAddWorkTimeEntries}
                className="btn btn-success text-uppercase ms-3 px-4"
              >
                {localize('add')}
              </button>
            </div>
          </>
        )}

        <div
          className="p-2 mt-4"
          style={{
            border: 'solid 1px #0000001f',
            borderRadius: 10,
            position: 'relative',
          }}
        >
          <div
            className="bg-white px-2 row text-capitalize"
            style={{
              position: 'relative',
              top: -20,
              left: 30,
              maxWidth: 120,
              marginBottom: -10,
            }}
          >
            {localize('workingTimePreferences')}
          </div>
          <WorkingTimePreferences
            entries={companyWorkingTimes}
            setEntries={setCompanyWorkingTimes}
          />
        </div>
      </div>
    </>
  )
}
