/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import ModalTemplate from '../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'

const CreateDividendCodes = ({
  handleClose,
  handleAction,
  mode,
  selectedCode,
  setSelectedCode,
  isBusy,
}) => {
  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="dividendCodes"
      handleAction={handleAction}
      handleClose={handleClose}
      disableClose={isBusy}
      disableCreate={isBusy}
      footerChildren={
        <div className="d-flex align-items-center">
          <Checkbox
            id="dividendCodesIsLocked"
            onCheck={v =>
              setSelectedCode({
                ...selectedCode,
                isLocked: v,
              })
            }
            checked={selectedCode?.isLocked}
            disabled={isBusy}
          />
          <label
            htmlFor="dividendCodesIsLocked"
            className="text-capitalize me-3"
          >
            {localize('isLocked')} ?
          </label>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <div className="d-flex align-items-center">
          <label className="text-capitalize col-3">{localize('name')}</label>
          <input
            type="text"
            className="form-control"
            required
            defaultValue={selectedCode?.description}
            onChange={e =>
              setSelectedCode({
                ...selectedCode,
                description: e.target.value,
              })
            }
          />
        </div>
      )}
    </ModalTemplate>
  )
}

export default CreateDividendCodes
