/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import EnumsServerCombo from '../../../Components/ComboBox/EnumsServerCombo'
import CustomDatePicker from '../../../Components/Date/custom-date-picker'
import { MaskNumber } from '../../../Components/InputMasks/MaskNumber'
import { MaskPercentage } from '../../../Components/InputMasks/MaskPercentage'
import Loader from '../../../Components/Loaders/Loader'
import InvestmentProductLookup from '../../../Components/Lookup/InvestmentProductLookup'
import NumberingSeriesLookup from '../../../Components/Lookup/NumberingSeriesLookup'
import ModalTemplate from '../../../Components/ModalTemplate/ModalTemplate'
import Tab from '../../../Components/Tabs/Tab'
import localize from '../../../Global/Localize'
import { showNotification } from '../../../Helpers/Notification/ShowNotification'
import { getData, postData, putData } from '../../../Helpers/webApi'
import AlternateChannels from './Partials/AlternateChannels'
import Flags from './Partials/Flags'
import InvestmentProducts from './Partials/InvestmentProducts'
import MandatoryCharges from './Partials/MandatoryCharges'
import SavingsProducts from './Partials/SavingsProducts'
import SavingsProductLookup from '../../../Components/Lookup/SavingsProductLookup'
import Swal from 'sweetalert2'

function EditMembershipClass({
  close,
  mode,
  membershipClass,
  gbv,
  clearValidation,
  readonly,
}) {
  const [selectedClass, setSelectedClass] = useState({})
  const [debitTypeDTOs, setDebitTypeDTOs] = useState([])
  const [productCollectionInfo, setProductCollectionInfo] = useState(null)
  const [
    membershipClassAlternateChannelDTOs,
    setMembershipClassAlternateChannelDTOs,
  ] = useState([])

  const [isBusy, setIsBusy] = useState(false)

  const handleSelectNumberingSeries = series => {
    if (series?.isLocked)
      return showNotification(
        'Operation not allowed',
        'info',
        'Selected numbering series is locked.'
      )

    setSelectedClass({
      ...selectedClass,
      numberingSeriesId: series.id,
      numberingSeriesDescription: series.description,
    })
  }

  const handleSelectInvestmentProduct = product => {
    if (product?.isLocked)
      return showNotification(
        'Operation not allowed',
        'info',
        'Selected investment product is locked.'
      )

    setSelectedClass({
      ...selectedClass,
      principalInvestmentProductId: product.id,
      principalInvestmentProductDescription: product.description,
    })
  }

  const handleSelectSavingsProduct = product => {
    if (product?.isLocked)
      return showNotification(
        'Operation not allowed',
        'info',
        'Selected savings product is locked.'
      )

    setSelectedClass({
      ...selectedClass,
      principalSavingsProductId: product.id,
      principalSavingsProductDescription: product.description,
    })
  }

  useEffect(() => {
    mode !== 'add' && membershipClass && fetchMembershipClass()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipClass?.id])

  async function fetchMembershipClass() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/membershipClass/find-membership-class-with-metadata-by-id',
      { membershipClassId: membershipClass?.id },
      false,
      null,
      false
    )
    if (success) {
      setSelectedClass(data?.membershipClassDTO)
      setDebitTypeDTOs(data?.debitTypeDTOs || [])
      setProductCollectionInfo(data?.productCollectionInfo)
      setMembershipClassAlternateChannelDTOs(
        data?.membershipClassAlternateChannelDTOs
      )
    }
    setIsBusy(false)
  }

  async function handleEdit() {
    setIsBusy(true)
    const { success, data } = await putData(
      '/membershipClass/update-membership-class-with-meta-data',
      {
        membershipClassDTO: selectedClass,
        productCollectionInfo,
        debitTypeDTOs,
        membershipClassAlternateChannelDTOs,
      },
      false
    )

    if (success) {
      fetchMembershipClass()
      Swal.fire('Operation Successful', 'Membership Class Edited', 'success')
    } else {
      Swal.fire(
        'Operation Not Successful',
        'Membership Class Not Edited',
        'error'
      )
    }
    setIsBusy(false)
  }

  async function handleCreate() {
    setIsBusy(true)
    const { success, data } = await postData(
      '/membershipClass/create-membership-class-with-meta-data',
      {
        membershipClassDTO: selectedClass,
        productCollectionInfo,
        debitTypeDTOs,
        membershipClassAlternateChannelDTOs,
      },
      false
    )

    if (success) {
      fetchMembershipClass()
      Swal.fire('Operation Successful', 'Membership Class Created', 'success')
    } else {
      Swal.fire(
        'Operation Not Successful',
        'Membership Class Not Created',
        'error'
      )
    }
    setIsBusy(false)
  }

  const tabItems = [
    {
      label: localize('mandatoryCharges'),
      item: (
        <MandatoryCharges
          disabled={readonly}
          prefix={'edit'}
          selected={debitTypeDTOs}
          onSelect={charges => setDebitTypeDTOs(charges)}
        />
      ),
    },
    {
      label: localize('investementProducts'),
      item: (
        <InvestmentProducts
          disabled={readonly}
          prefix={'edit'}
          selected={productCollectionInfo?.investmentProductCollection || []}
          onSelect={products =>
            setProductCollectionInfo({
              ...productCollectionInfo,
              investmentProductCollection: products,
            })
          }
        />
      ),
    },
    {
      label: localize('savingsProducts'),
      item: (
        <SavingsProducts
          disabled={readonly}
          prefix={'edit'}
          selected={productCollectionInfo?.savingsProductCollection || []}
          onSelect={products =>
            setProductCollectionInfo({
              ...productCollectionInfo,
              savingsProductCollection: products,
            })
          }
        />
      ),
    },
    {
      label: localize('alternateChannels'),
      item: (
        <AlternateChannels
          disabled={readonly}
          selected={membershipClassAlternateChannelDTOs}
          membershipClassId={membershipClass?.id}
          prefix={'edit'}
          onChange={ac => setMembershipClassAlternateChannelDTOs(ac)}
        />
      ),
    },
    {
      label: localize('flags'),
      item: (
        <Flags
          disabled={readonly}
          membershipClass={selectedClass}
          onFlag={flags => setSelectedClass({ ...selectedClass, ...flags })}
          prefix={'edit'}
        />
      ),
    },
  ]

  return (
    <ModalTemplate
      modalMode={mode}
      modalClassName="modal-xl"
      modalTitle="membershipClass"
      handleAction={mode === 'edit' ? handleEdit : handleCreate}
      handleClose={close}
      disableClose={isBusy}
      disableCreate={isBusy}
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-3">
            <div
              className="d-flex align-items-center col-md-4 with-validation"
              validation-message={
                gbv?.['MembershipClassDTO.Description']?.message
              }
            >
              <label className="text-capitalize col-5">
                {localize('name')}
              </label>
              <input
                disabled={readonly}
                type="text"
                className="form-control"
                value={selectedClass?.description}
                onChange={e => {
                  clearValidation('MembershipClassDTO.Description')
                  setSelectedClass({
                    ...selectedClass,
                    description: e.target.value,
                  })
                }}
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-5">
                {localize('prefix')}
              </label>
              <input
                disabled={readonly}
                type="text"
                className="form-control"
                value={selectedClass?.prefix}
                onChange={e =>
                  setSelectedClass({
                    ...selectedClass,
                    prefix: e.target.value,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-5">
                {localize('suffix')}
              </label>
              <input
                disabled={readonly}
                type="text"
                className="form-control"
                value={selectedClass?.suffix}
                onChange={e =>
                  setSelectedClass({
                    ...selectedClass,
                    suffix: e.target.value,
                  })
                }
              />
            </div>
            <div
              className="d-flex align-items-center col-md-4 with-validation"
              validation-message={
                gbv?.['MembershipClassDTO.NumberingSeriesId']?.message
              }
            >
              <label className="text-capitalize col-5">
                {localize('numberingSeries')}
              </label>
              <NumberingSeriesLookup
                disabled={readonly}
                displayValue={selectedClass?.numberingSeriesDescription}
                onSelect={r => {
                  clearValidation('MembershipClassDTO.NumberingSeriesId')
                  handleSelectNumberingSeries(r)
                }}
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-5">
                {localize('customerType')}
              </label>
              <EnumsServerCombo
                disabled={readonly}
                customContainerStyle={{ width: '100%' }}
                enumsKey="CustomerType"
                value={selectedClass?.customerType}
                onChange={e =>
                  setSelectedClass({
                    ...selectedClass,
                    customerType: e.value,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-5">
                {localize('terminationNoticePeriod')}
              </label>
              <MaskNumber
                disabled={readonly}
                defaultMaskValue={
                  selectedClass?.membershipTerminationNoticePeriod
                }
                onMaskNumber={e =>
                  setSelectedClass({
                    ...selectedClass,
                    membershipTerminationNoticePeriod: e.target.value,
                  })
                }
              />
            </div>
            <div
              className="d-flex align-items-center col-md-4 with-validation"
              validation-message={
                gbv?.['MembershipClassDTO.PrincipalInvestmentProductId']
                  ?.message
              }
            >
              <label className="text-capitalize col-5">
                {localize('principalInvestmentProduct')}
              </label>
              <InvestmentProductLookup
                disabled={readonly}
                onSelect={r => {
                  clearValidation(
                    'MembershipClassDTO.PrincipalInvestmentProductId'
                  )
                  handleSelectInvestmentProduct(r)
                }}
                displayValue={
                  selectedClass?.principalInvestmentProductDescription
                }
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-5">
                {localize('initialStatementDate')}
              </label>
              <CustomDatePicker
                disabled={readonly}
                name="membershipClassInitialStatementDate"
                defaultDate={selectedClass?.initialStatementDate}
                onDateChange={dt =>
                  setSelectedClass({
                    ...selectedClass,
                    initialStatementDate: dt,
                  })
                }
              />
            </div>
            <div className="d-flex align-items-center col-md-4">
              <label className="text-capitalize col-5">
                {localize('kycScoreThreshold')}
              </label>
              <MaskPercentage
                disabled={readonly}
                defaultMaskValue={selectedClass?.kycScoreThreshold}
                onMaskChange={e =>
                  setSelectedClass({
                    ...selectedClass,
                    kycScoreThreshold: e.target.value,
                  })
                }
              />
            </div>
            <div
              className="d-flex align-items-center col-md-4 with-validation"
              validation-message={
                gbv?.['MembershipClassDTO.PrincipalSavingsProductId']?.message
              }
            >
              <label className="text-capitalize col-5">
                {localize('principalSavingsProduct')}
              </label>
              <SavingsProductLookup
                disabled={readonly}
                onSelect={r => {
                  clearValidation(
                    'MembershipClassDTO.PrincipalSavingsProductId'
                  )
                  handleSelectSavingsProduct(r)
                }}
                displayValue={selectedClass?.principalSavingsProductDescription}
              />
            </div>
          </div>
          <div className="bg-white p-2 my-2">
            <Tab tabItems={tabItems} preload />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default EditMembershipClass
