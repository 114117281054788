/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ListCustomerByType from '../../Customer/ListCustomerByType'

const CustomersTab = ({
  toggleSelectAllCustomers,
  handleSelectCustomer,
  handleUpdateCustomers,
  setResetValues,
  resetValues,
  showImport,
}) => {
  const [selectedCustomers, setSelectedCustomers] = useState([])

  const onCustomerCheck = (customer, value) => {
    if (value) {
      setSelectedCustomers([...selectedCustomers, customer])
    } else {
      setSelectedCustomers(selectedCustomers.filter(c => c.id !== customer.id))
    }
  }

  const onSelectAllCustomers = (customers, value) => {
    if (value) {
      setSelectedCustomers(customers)
    } else {
      setSelectedCustomers([])
    }
  }

  useEffect(() => {
    handleSelectCustomer(selectedCustomers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomers])

  useEffect(() => {
    if (resetValues) {
      setSelectedCustomers([])
      setResetValues(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <div>
      <ListCustomerByType
        selectedCustomers={selectedCustomers}
        onCustomerCheck={onCustomerCheck}
        toggleSelectAllCustomers={toggleSelectAllCustomers}
        onSelectAllCustomers={onSelectAllCustomers}
        handleUpdate={handleUpdateCustomers}
        setResetValues={setResetValues}
        resetValues={resetValues}
        showImport={showImport}
      />
    </div>
  )
}

export default CustomersTab
