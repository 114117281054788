/** @format */

/* eslint-disable */
import React from 'react'
import localize from '../../../../../Global/Localize'
function MobileToBankCollectionAddress({
  selectedMobileToBankCollection,
  setSelectedMobileToBankCollection,
}) {
  return (
    <>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('addressLine1')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressAddressLine1}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressAddressLine1: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('addressLine2')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressAddressLine2}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressAddressLine2: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('physicalAddress')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressStreet}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressStreet: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">{localize('city')}</label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressCity}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressCity: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('postalCode')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressPostalCode}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressPostalCode: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">{localize('email')}</label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressEmail}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressEmail: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex align-items-center">
          <label className="col-3 text-capitalize">
            {localize('landLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressLandLine}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressLandLine: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('mobileLine')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressMobileLine}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressMobileLine: e.target.value,
              })
            }}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label className="col-4 text-capitalize">
            {localize('internet')}
          </label>
          <input
            type="text"
            className="form-control"
            value={selectedMobileToBankCollection?.addressInternet}
            onChange={e => {
              setSelectedMobileToBankCollection({
                ...selectedMobileToBankCollection,
                addressInternet: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </>
  )
}
export default MobileToBankCollectionAddress
