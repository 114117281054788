/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import CustomersLookup from '../../../../../Components/Lookup/CustomersLookup'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import AdHocLoanInterestCapitalization from '../../../../../Components/ReUsables/AdHocLoanInterestCapitalization/AdHocLoanInterestCapitalization'
import { ViewCustomerInvestmentAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerInvestmentAccounts'
import { ViewCustomerLoanAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerLoanAccounts'
import { ViewCustomerSavingsAccounts } from '../../../../../Components/ReUsables/Customer/ViewCustomerSavingsAccounts'
import Tab from '../../../../../Components/Tabs/Tab'
import localize from '../../../../../Global/Localize'
import { customerMembershipStatus } from '../../../../../Global/enumeration'
import { formatCurrency, parseDate } from '../../../../../Helpers/formatters'
import { getData, postData } from '../../../../../Helpers/webApi'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import AdHocStandingOrderExecutionModal from '../../../../Registry/MembershipWithdrawal/Catalogue/AddCatalogueModal/AdHocStandingOrderExecutionModal/AdHocStandingOrderExecutionModal'
import Loader from '../../../../../Components/Loaders/Loader'

function CustomerLoansNetting() {
  const [isBusy, setIsBusy] = useState(false)
  const [customer, setCustomer] = useState({})
  const [globalValidationErrors, setGlobalValidationErrors] =
    GlobalValidationErrors.use()
  const [showAdHocModal, setShowAdHocModal] = useState(false)
  const [loanAccount, setLoanAccount] = useState({})
  const [benefactor, setBenefactor] = useState({})
  const [adHocStandingOrder, setAdHocStandingOrder] = useState({})
  const [showAdHocStandingOrderModal, setShowAdHocStandingOrderModal] =
    useState(false)
  const [transferDeceasedBalances, setTransferDeceasedBalances] =
    useState(false)
  const [customerSavingsAccounts, setCustomerSavingsAccounts] = useState([])
  const [customerLoanAccounts, setCustomerLoanAccounts] = useState([])
  const [customerInvestmentAccounts, setCustomerInvestmentAccounts] = useState(
    []
  )
  const [selectedLoanAccounts, setSelectedLoanAccounts] = useState([])
  const [balance, setBalance] = useState({
    netPartialRefundable: '',
    netLoansBalance: '',
    netRefundable: '',
    netInvestmentsBalance: '',
    backOfficeInvestmentsBalance: '',
    backOfficeLoansBalance: '',
  })

  const activeMember = Object.keys(customerMembershipStatus)[0]
  const withdrawalPending = Object.keys(customerMembershipStatus)[13]
  const deceased = Object.keys(customerMembershipStatus)[10]

  const getCustomerAccounts = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/CustomerAccount/find-customer-accounts-by-customer-id-and-product-codes',
      {
        customerId: customer.id,
        includeBalances: true,
        includeProductDescription: true,
      },
      false
    )

    if (success) {
      setIsBusy(false)
      setCustomerSavingsAccounts(data.customerSavingsAccounts)
      setCustomerLoanAccounts(
        data.customerLoanAccounts.filter(
          r => r.bookBalance < 0 || r.bookBalance > 0
        )
      )
      setCustomerInvestmentAccounts(data.customerInvestmentAccounts)
      setBalance({
        netPartialRefundable: data?.netPartialRefundable,
        netLoansBalance: data?.netLoansBalance,
        netRefundable: data?.netRefundable,
        netInvestmentsBalance: data?.netInvestmentsBalance,
        backOfficeInvestmentsBalance: data?.backOfficeInvestmentsBalance,
        backOfficeLoansBalance: data?.backOfficeLoansBalance,
      })
    } else {
      setIsBusy(false)
    }
  }

  const clearValidation = column => {
    setGlobalValidationErrors(g => {
      delete g[column]
      return g
    })
  }

  const onCustomerLookup = r => {
    setCustomer(r)
  }

  const handleSelectBenefactor = r => {
    if (r.isLocked)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Selected product is locked',
      })

    setBenefactor(r)
  }

  const handleSelectLoanAccount = (account, value) => {
    if (value) {
      setSelectedLoanAccounts([...selectedLoanAccounts, account])
    } else {
      setSelectedLoanAccounts(
        selectedLoanAccounts.filter(acc => acc.id !== account.id)
      )
    }
  }

  const handleSave = async () => {
    setIsBusy(true)
    const url = '/LoanCase/net-off-customer-loans-from-investment-product'

    if (!customer?.id)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select a customer',
      })

    if (selectedLoanAccounts.length === 0)
      return Swal.fire({
        icon: 'error',
        title: 'Operation not allowed',
        text: 'Please select at least one loan account',
      })

    const { success } = await postData(
      url,
      {
        customer,
        benefactorInvestmentProductId: benefactor?.id,
        customerLoanAccountDTOs: selectedLoanAccounts,
        moduleNavigationItemCode: 1000,
      },
      false,
      'Net off customer loans from investment product executed successfully'
    )

    if (success) {
      setIsBusy(false)
      setSelectedLoanAccounts([])
      setCustomer({})
      setBenefactor({})
      setCustomerSavingsAccounts([])
      setCustomerLoanAccounts([])
      setCustomerInvestmentAccounts([])
      setBalance({
        netPartialRefundable: '',
        netLoansBalance: '',
        netRefundable: '',
        netInvestmentsBalance: '',
        backOfficeInvestmentsBalance: '',
        backOfficeLoansBalance: '',
      })
    } else {
      setIsBusy(false)
    }
  }

  const tabItems = [
    {
      label: localize('loanAccounts'),
      item: (
        <>
          <ViewCustomerLoanAccounts
            customerLoanAccounts={customerLoanAccounts}
            handleAdHocLoan={r => {
              setLoanAccount(r)
              setShowAdHocModal(true)
            }}
            handleSelectLoanAccount={handleSelectLoanAccount}
            selectedLoanAccounts={selectedLoanAccounts}
          />
        </>
      ),
    },
    {
      label: localize('savingsAccounts'),
      item: (
        <>
          <ViewCustomerSavingsAccounts
            customerSavingsAccounts={customerSavingsAccounts}
          />
        </>
      ),
    },
    {
      label: localize('investmentAccounts'),
      item: (
        <>
          <ViewCustomerInvestmentAccounts
            customerInvestmentAccounts={customerInvestmentAccounts}
            handleExecuteStandingOrder={r => {
              setAdHocStandingOrder(r)
              setShowAdHocStandingOrderModal(true)
            }}
          />
        </>
      ),
    },
  ]

  useEffect(() => {
    if (customer?.id) {
      getCustomerAccounts()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <BreadCrumb
            tree={[
              localize('accountsManagement'),
              localize('customerLoansNettingOff'),
            ]}
          />

          {showAdHocModal && (
            <AdHocLoanInterestCapitalization
              handleClose={() => {
                setShowAdHocModal(false)
              }}
              loanAccount={loanAccount}
              modalMode="execute"
            />
          )}
          {showAdHocStandingOrderModal && (
            <AdHocStandingOrderExecutionModal
              handleClose={() => {
                setShowAdHocStandingOrderModal(false)
              }}
              modalMode="execute"
              adHocStandingOrder={adHocStandingOrder}
            />
          )}

          <div className="card bg-white rounded">
            <div className="card-body">
              <div className="row mb-2">
                <div
                  validation-message={
                    globalValidationErrors['WithdrawalNotification.CustomerId']
                      ?.message
                  }
                  className="col d-flex align-items-center with-validation"
                >
                  <label className="col-4 text-capitalize">
                    {localize('customerName')}
                  </label>
                  <CustomersLookup
                    displayValue={customer?.fullName}
                    onSelect={r => {
                      clearValidation('WithdrawalNotification.CustomerId')
                      onCustomerLookup(r)
                    }}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('customerType')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={
                      customer?.membershipClassCustomerTypeDescription || ''
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('membershipStatus')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.membershipStatusDescription || ''}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('membershipWdr.Date')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={
                      customer?.membershipTerminationDate
                        ? parseDate(customer?.membershipTerminationDate)
                        : '--'
                    }
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('domicileBranch')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.branchDescription || ''}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('employerName')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.employerDescription || ''}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('dutyWorkStation')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.dutyStationDescription || ''}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('adminDivision')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.administrativeDivisionDescription || ''}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('identificationNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.identificationNumber || ''}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('memberNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.formattedMemberNumber || ''}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('accountNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.formattedAccountNumber || ''}
                  />
                </div>
                <div className="col d-flex align-items-center">
                  <label className="col-4 text-capitalize">
                    {localize('personalFileNumber')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={customer?.personalFileNumber || ''}
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-md-4">
                  <div
                    className=" d-flex align-items-center with-validation"
                    validation-message={
                      globalValidationErrors[
                        'WithdrawalNotification.TransactionReferenceId'
                      ]?.message
                    }
                  >
                    <label className="col-4 text-capitalize">
                      {localize('benefactorProduct')}
                    </label>
                    <InvestmentProductLookup
                      displayValue={benefactor?.description}
                      onSelect={handleSelectBenefactor}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <Tab tabItems={tabItems} />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('deposits(Refundable)')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        customer?.membershipStatus === activeMember ||
                          customer?.terminationCategory === withdrawalPending
                          ? balance?.netInvestmentsBalance
                          : balance?.backOfficeInvestmentsBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('loansBalance')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        customer?.membershipStatus === activeMember ||
                          customer?.terminationCategory === withdrawalPending
                          ? balance?.netLoansBalance
                          : balance?.backOfficeLoansBalance,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <label className="col-4 text-capitalize">
                      {localize('netRefundable')}
                    </label>

                    <input
                      type="text"
                      className="form-control text-end"
                      disabled
                      value={formatCurrency(
                        customer?.membershipStatus === activeMember ||
                          customer?.terminationCategory === withdrawalPending
                          ? balance?.netRefundable
                          : balance?.netPartialRefundable,
                        null,
                        null,
                        false
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="row bg-light py-2 px-1 rounded">
                <div className="col-md-12">
                  <div className="d-flex justify-content-end align-items-center gap-4">
                    {customer?.membershipStatus === deceased && (
                      <div className="d-flex align-items-center">
                        <Checkbox
                          checked={transferDeceasedBalances}
                          onCheck={e => setTransferDeceasedBalances(e)}
                        />
                        <label className="col-12 ms-2 text-capitalize">
                          {localize('transferDeceasedBalances') + ' ?'}
                        </label>
                      </div>
                    )}

                    <button onClick={handleSave} className="btn btn-success">
                      {localize('save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CustomerLoansNetting
