/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import localize from '../../../../../Global/Localize'
import { formatCurrency, formatDate } from '../../../../../Helpers/formatters'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import Loader from '../../../../../Components/Loaders/Loader'

function CustomerPayoutHistory({
  customer,
  loanProduct,
  onGetPayoutHistories,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [histories, setHistories] = useState([])

  useEffect(() => {
    loadHistories()
    // eslint-disable-next-line
  }, [customer, loanProduct])

  const columns = [
    {
      label: localize('batchNumber'),
      template: r => <>{r?.paddedCreditBatchBatchNumber}</>,
    },
    {
      label: localize('batchMonth'),
      template: r => <>{r?.creditBatchMonthDescription}</>,
    },
    {
      label: localize('batchReference'),
      template: r => <>{r?.creditBatchReference}</>,
    },
    {
      label: localize('batchStatus'),
      template: r => <>{r?.creditBatchStatusDescription}</>,
    },
    {
      label: localize('fullAccountNumber'),
      template: r => <>{r?.fullAccountNumber}</>,
    },
    {
      label: localize('productName'),
      template: r => <>{r?.productDescription}</>,
    },
    {
      label: localize('totalValue'),
      template: r => <>{formatCurrency(r?.totalValue)}</>,
    },
    {
      label: localize('principal'),
      template: r => <>{formatCurrency(r?.principal)}</>,
    },
    {
      label: localize('interest'),
      template: r => <>{formatCurrency(r?.interest)}</>,
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r?.createdDate)}</>,
    },
  ]

  useEffect(() => {
    Array.isArray(histories) && histories.length > 0
      ? onGetPayoutHistories(histories[histories.length - 1]?.totalValue)
      : onGetPayoutHistories(0)
    // eslint-disable-next-line
  }, [histories])

  async function loadHistories() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/creditBatch/find-loan-appraisal-credit-batch-entries-by-customer-id-and-loan-product-id',
      {
        customerId: customer?.id,
        loanProductId: loanProduct?.id,
        includeProductDescription: true,
      },
      false
    )
    if (success) setHistories(data?.result || [])
    setIsBusy(false)
  }

  return isBusy ? (
    <Loader />
  ) : (
    <>
      <SimpleTable data={histories} columns={columns} />
    </>
  )
}

export default CustomerPayoutHistory
