/** @format */

import React from 'react'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import localize from '../../../../../Global/Localize'

export const Roles = ({
  disabled,
  rolesToBeSelected,
  handleOnChangeRequiredApprovers,
  onCheckRole,
  prefix,
}) => {
  const handleCheckRoles = (role, checkValue) => {
    const updatedRolesList = [...rolesToBeSelected].map(comm => {
      if (comm.id === role.id) {
        return { ...comm, checked: checkValue }
      }
      return comm
    })

    onCheckRole(updatedRolesList, role, checkValue)
  }

  return (
    <div
      className="px-4 row g-0 d-flex align-items-center"
      style={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      <>
        <div className="col-md-3">
          <h5 className="text-capitalize">{localize('role')}</h5>
          <div className="row g-2">
            {rolesToBeSelected &&
              rolesToBeSelected.map(role => (
                <div
                  key={role.id}
                  className="d-flex align-items-center col-md-12"
                >
                  <Checkbox
                    id={`${prefix ? prefix : 'com'}-${role?.id}`}
                    checked={role?.checked}
                    disabled={disabled}
                    margined
                    onCheck={c => {
                      handleCheckRoles(role, c)
                    }}
                  />
                  <label
                    htmlFor={`${prefix ? prefix : 'com'}-${role?.id}`}
                    className={`fw-normal ms-2 cursor-pointer ${
                      role?.checked ? 'text-primary fw-bold' : ''
                    }`}
                  >
                    {role?.roleName}
                  </label>
                </div>
              ))}
          </div>
        </div>
        <div className="col-md-5">
          <h5 className="text-capitalize">{localize('requiredApprovers')}</h5>
          <div className="row g-2">
            {rolesToBeSelected &&
              rolesToBeSelected.length > 0 &&
              rolesToBeSelected.map(role => (
                <div className="col-12">
                  <MaskNumber
                    key={role.id}
                    numeralThousandsGroupStyle={'none'}
                    className="form-control w-50"
                    defaultMaskValue={role?.requiredApprovers}
                    onMaskNumber={e => {
                      handleOnChangeRequiredApprovers(role, e.target.value)
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </>
    </div>
  )
}
