/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../../Global/Localize'
import { getData } from '../../../../../../Helpers/webApi'
import { batchStatus } from '../../../../../../Global/enumeration'
import Pager from '../../../../../../Components/Tables/Pager'
import SimpleTable from '../../../../../../Components/Tables/SimpleTable'
import { formatDate } from '../../../../../../Helpers/formatters'
import DatePicker from '../../../../../../Components/Date/DatePicker'
import RefundBatchModal from './RefundBatchModal'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import Loader from '../../../../../../Components/Loaders/Loader'
import AddNewButton from '../../../../../../Components/Buttons/AddNewButton/AddNewButton'

function RefundBatches({
  showAddButton,
  refundBatchMode,
  handleOnSelectRefundBatch,
}) {
  const [isBusy, setIsBusy] = useState(false)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState(null)
  const [selectedRefundBatch, setSelectedRefundBatch] = useState(null)

  const [reqParams, setReqParams] = useState({
    status: Object.keys(batchStatus)[0],
    text: '',
    pageIndex: 0,
    pageSize: 10,
    startDate: null,
    dateRequestsFilter: 15,
    endDate: null,
    customDate: false,
  })
  const [tableMeta, setTableMeta] = useState({ ...reqParams, itemsCount: 0 })
  const [mode, setMode] = useState(null)
  const [refreshAt, setRefreshAt] = useState(null)
  useEffect(() => {
    loadTableData()
  }, [reqParams, refreshAt])

  async function loadTableData() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/RefundBatch/find-refund-batches-by-status-and-filter-in-page',
      reqParams,
      false
    )
    if (success) {
      setIsBusy(false)
      setTableData(data.pageCollection || [])
      setTableMeta({ ...reqParams, itemsCount: data.itemsCount })
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  const columns = [
    {
      label: localize('branch'),
      template: r => <>{r.branchDescription}</>,
    },
    {
      label: localize('batchNumber'),
      template: r => <>{r.paddedBatchNumber}</>,
    },
    {
      label: localize('postingPeriod'),
      template: r => <>{r.postingPeriodDescription}</>,
    },
    {
      label: localize('status'),
      template: r => <>{r.statusDescription}</>,
    },
    {
      label: localize('month'),
      template: r => <>{r.monthDescription}</>,
    },
    { label: localize('valueDate'), template: r => <>{r.valueDate}</> },
    { label: localize('reference'), template: r => <>{r.reference}</> },
    {
      label: localize('priority'),
      template: r => <>{r.priorityDescription}</>,
    },
    {
      label: `${localize('verification')}/${localize('rejection')} ${localize(
        'remarks'
      )}`,
      template: r => <>{r.auditRemarks}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'by'
      )}`,
      template: r => <>{r.auditedBy}</>,
    },
    {
      label: `${localize('verified')}/${localize('rejected')} ${localize(
        'date'
      )}`,
      template: r => <>{formatDate(r.auditedDate)}</>,
    },
    {
      label: `${localize('authorization')}/${localize('rejection')} ${localize(
        'remarks'
      )}`,
      template: r => <>{r.authorizationRemarks}</>,
    },
    {
      label: `${localize('authorized')}/${localize('rejected')} ${localize(
        'by'
      )}`,
      template: r => <>{r.authorizedBy}</>,
    },
    {
      label: `${localize('authorized')}/${localize('rejected')} ${localize(
        'date'
      )}`,
      template: r => <>{formatDate(r.authorizedDate)}</>,
    },
    { label: localize('createdBy'), template: r => <>{r.createdBy}</> },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  const handleOnAdd = () => {
    setMode('add')
  }
  const handleOnView = r => {
    setMode('view')
    setSelectedRefundBatch(r)
  }
  const handleOnVerify = r => {
    if (r.status !== Object.keys(batchStatus)[0]) {
      Swal.fire('Refund Batch', 'Refund Batch Status is not Pending', 'error')
      return
    }
    setMode('verify')
    setSelectedRefundBatch(r)
  }
  const handleOnAuthorize = r => {
    if (r.status !== Object.keys(batchStatus)[3]) {
      Swal.fire('Refund Batch', 'Refund Batch Status is not Audited', 'error')
      return
    }
    setMode('authorize')
    setSelectedRefundBatch(r)
  }
  const handleOnEdit = r => {
    setMode('edit')
    setSelectedRefundBatch(r)
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-2">
                    {showAddButton && (
                      <div className="col-md-4">
                        <div className="mb-3">
                          <AddNewButton handleClickAddButton={handleOnAdd} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-center col-3">
                      <label
                        htmlFor="recordsPerPage"
                        className="text-capitalize"
                        style={{ marginLeft: -10 }}
                      >
                        {localize('recordsPerPage')}
                      </label>
                      <select
                        name=""
                        id="recordsPerPage"
                        className="form-select ms-3"
                        style={{ maxWidth: 60 }}
                        defaultValue={reqParams.pageSize}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            pageIndex: 0,
                            pageSize: e.target.value,
                          })
                        }}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-3">
                      <div className="d-flex align-items-center">
                        <label
                          className="text-capitalize"
                          htmlFor="searchByDate"
                        >
                          {localize('dateRange')}
                        </label>
                        <DatePicker
                          onSelect={v => {
                            if (v.custom) {
                              setReqParams({
                                ...reqParams,
                                customDate: true,
                                startDate: v.startDate,
                                endDate: v.endDate,
                              })
                            } else {
                              setReqParams({
                                ...reqParams,
                                customDate: false,
                                dateRequestsFilter: v.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <label className="text-capitalize me-2">
                        {localize('status')}
                      </label>
                      <EnumsServerCombo
                        customContainerStyle={{ width: '100%' }}
                        enumsKey="BatchStatus"
                        value={reqParams.status}
                        onChange={e => {
                          setReqParams({
                            ...reqParams,
                            status: e.value,
                            pageIndex: 0,
                          })
                        }}
                      />
                    </div>
                    <form
                      className="d-flex align-items-center col-3 col"
                      onSubmit={e => {
                        e.preventDefault()
                        setReqParams({
                          ...reqParams,
                          text: search,
                        })
                      }}
                    >
                      <label
                        htmlFor="searchTable"
                        className="text-capitalize me-2"
                      >
                        {localize('search')}
                      </label>
                      <input
                        type="search"
                        id="searchTable"
                        className="form-control ms-2"
                        defaultValue={reqParams.text}
                        onChange={e => setSearch(e.target.value)}
                      ></input>
                    </form>
                  </div>
                  <div className="row col-12 mt-2">
                    <SimpleTable
                      canSelect
                      onSelect={r => {
                        handleOnSelectRefundBatch(r)
                      }}
                      contextMenu={r => (
                        <>
                          <div
                            onClick={() => {
                              handleOnView(r)
                            }}
                            className="ctxt-menu-item"
                          >
                            <i className="uil uil-eye"></i>
                            {localize('view')}
                          </div>
                          {refundBatchMode === 'view' && (
                            <div
                              onClick={() => {
                                handleOnView(r)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-eye"></i>
                              {localize('view')}
                            </div>
                          )}
                          {refundBatchMode === 'verify' && (
                            <div
                              onClick={() => {
                                handleOnVerify(r)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="mdi mdi-check-decagram"></i>
                              {localize('verify')}
                            </div>
                          )}
                          {refundBatchMode === 'authorize' && (
                            <div
                              onClick={() => {
                                handleOnAuthorize(r)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="mdi mdi-lock-open-check-outline"></i>
                              {localize('authorize')}
                            </div>
                          )}
                          {refundBatchMode === 'edit' && (
                            <div
                              onClick={() => {
                                handleOnEdit(r)
                              }}
                              className="ctxt-menu-item"
                            >
                              <i className="uil uil-edit-alt"></i>
                              {localize('edit')}
                            </div>
                          )}
                        </>
                      )}
                      data={tableData}
                      columns={columns}
                      onSort={(c, i) => handleSort(c, i)}
                      sortedBy={sortedBy}
                    />
                  </div>
                  <div className="row col-12 mt-2">
                    <Pager
                      {...tableMeta}
                      onPageChange={p =>
                        setReqParams({ ...reqParams, pageIndex: p })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <RefundBatchModal
        mode={mode}
        onHideModal={m => {
          setMode(null)
        }}
        onUpdateRefreshAt={r => {
          setRefreshAt(!refreshAt)
        }}
        onSetMode={m => {
          setMode(m)
        }}
        selectedRefundBatch={selectedRefundBatch}
        setSelectedRefundBatch={setSelectedRefundBatch}
      />
    </>
  )
}

export default RefundBatches
