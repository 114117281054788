/** @format */

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getData, putData } from '../../../../../../../Helpers/webApi'
import SimpleTable from '../../../../../../../Components/Tables/SimpleTable'
import localize from '../../../../../../../Global/Localize'
import {
  gender,
  identityCardType,
  nationality,
  partnershipRelationship,
  salutation,
} from '../../../../../../../Global/enumeration'
import AddressV2 from '../../../../../../../Components/Miscalleneous/AddressV2'
import Tab from '../../../../../../../Components/Tabs/Tab'
import { generateGuid } from '../../../../../../../Helpers/extensions'
import Swal from 'sweetalert2'
import EnumsServerCombo from '../../../../../../../Components/ComboBox/EnumsServerCombo'

function Partnership({ customer }) {
  const [members, setMembers] = useState(false)
  const [cMember, setCMember] = useState(null)

  const columns = [
    {
      label: localize('name'),
      template: r => (
        <>
          {r.fullName ||
            `${r.salutationDescription} ${r.firstName} ${r.lastName}`}
        </>
      ),
    },
    { label: localize('family'), template: r => <>{r.family}</> },
    { label: localize('clan'), template: r => <>{r.clan}</> },
    { label: localize('ethnicGroup'), template: r => <>{r.ethnicGroup}</> },
    { label: localize('gender'), template: r => <>{r.genderDescription}</> },
    {
      label: localize('relationship'),
      template: r => <>{r.relationshipDescription}</>,
    },
    {
      label: localize('nationality'),
      template: r => <>{r.nationalityDescription}</>,
    },
    {
      label: localize('identityCardType'),
      template: r => <>{r.identityCardTypeDescription}</>,
    },
    {
      label: localize('identityCardNumber'),
      template: r => <>{r.identityCardNumber}</>,
    },
    {
      label: localize('idenityCardSerialNumber'),
      template: r => <>{r.identityCardSerialNumber}</>,
    },
    {
      label: localize('addressLine') + ' 1',
      template: r => <>{r.addressAddressLine1}</>,
    },
    {
      label: localize('addressLine') + ' 2',
      template: r => <>{r.addressAddressLine2}</>,
    },
    {
      label: localize('physicalAddress'),
      template: r => <>{r.addressAddressStreet}</>,
    },
    {
      label: localize('postalCode'),
      template: r => <>{r.addressPostalCode}</>,
    },
    { label: localize('city'), template: r => <>{r.addrssCity}</> },
    { label: localize('email'), template: r => <>{r.addressEmail}</> },
    { label: localize('landLine'), template: r => <>{r.addressLandLine}</> },
    {
      label: localize('mobileLine'),
      template: r => <>{r.addressMobileLine}</>,
    },
  ]

  useEffect(() => {
    loadMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function addMember() {
    setMembers([...members, { id: generateGuid(), ...cMember }])
    setCMember(null)
  }

  function removeItem(id) {
    let withoutmember = [...members]
    let i = withoutmember.findIndex(m => m.id === id)
    withoutmember.splice(i, 1)
    setMembers(withoutmember)
  }

  async function loadMembers() {
    const { success, data } = await getData(
      '/customer/find-partnership-member-collection-by-partnership-id',
      { partnershipId: customer?.id }
    )
    if (success) {
      setMembers(data?.result)
    }
  }

  async function updateMembers() {
    const { success, data } = await putData(
      '/customer/update-partnership-member-collection-by-partnership-id?partnershipId=' +
        customer.id,
      members
    )
    if (success) {
      loadMembers()
      Swal.fire(
        'Operation Successful',
        'Membership details updated!',
        'success'
      )
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('relationship')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="PartnershipRelationship"
            value={
              cMember?.relationship || Object.keys(partnershipRelationship)[0]
            }
            onChange={e =>
              setCMember({
                ...cMember,
                relationship: e.value,
                relationshipDescription: partnershipRelationship[e.value].name,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('nationality')}
          </label>
          <select
            className="form-select"
            value={cMember?.nationality || nationality[0]}
            onChange={e =>
              setCMember({
                ...cMember,
                nationality: e.target.value,
                nationalityDescription: nationality[e.target.value],
              })
            }
          >
            {nationality.map((nat, i) => (
              <option key={`nationlity_${i}`} value={i}>
                {nat}
              </option>
            ))}
          </select>
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('gender')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Gender"
            value={cMember?.gender || Object.keys(gender)[0]}
            onChange={e =>
              setCMember({
                ...cMember,
                gender: e.value,
                genderDescription: gender[e.value].name,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('salutation')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="Salutation"
            value={cMember?.salutation || Object.keys(salutation)[0]}
            onChange={e =>
              setCMember({
                ...cMember,
                salutation: e.value,
                salutationDescription: salutation[e.value].name,
              })
            }
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('firstName')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.firstName || ''}
            onChange={e =>
              setCMember({ ...cMember, firstName: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('otherNames')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.lastName || ''}
            onChange={e => setCMember({ ...cMember, lastName: e.target.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('family')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.family || ''}
            onChange={e => setCMember({ ...cMember, family: e.target.value })}
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('clan')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.clan || ''}
            onChange={e => setCMember({ ...cMember, clan: e.target.value })}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('ethnicGroup')}
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.ethnicGroup || ''}
            onChange={e =>
              setCMember({ ...cMember, ethnicGroup: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCardType')}
          </label>
          <EnumsServerCombo
            customContainerStyle={{ width: '100%' }}
            enumsKey="IdentityCardType"
            value={
              cMember?.identityCardType || Object.keys(identityCardType)[0]
            }
            onChange={e =>
              setCMember({
                ...cMember,
                identityCardType: e.value,
                identityCardTypeDescription: identityCardType[e.value].name,
              })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCard')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.identityCardNumber || ''}
            onChange={e =>
              setCMember({ ...cMember, identityCardNumber: e.target.value })
            }
          />
        </div>
        <div className="col d-flex align-items-center">
          <label htmlFor="" className="text-capitalize col-4">
            {localize('identityCardSerial')} #
          </label>
          <input
            type="text"
            className="form-control"
            value={cMember?.identityCardSerialNumber || ''}
            onChange={e =>
              setCMember({
                ...cMember,
                identityCardSerialNumber: e.target.value,
              })
            }
          />
        </div>
      </div>
      <Tab
        tabItems={[
          {
            label: 'address',
            item: (
              <AddressV2
                parent={null}
                onChange={address => {
                  setCMember({ ...cMember, ...address })
                }}
              />
            ),
          },
        ]}
      />

      <div className="bg-light d-flex align-items-center justify-content-end mt-2 p-2">
        <button className="btn btn-info px-4" onClick={addMember}>
          {localize('add')}
        </button>
      </div>
      <div className="mt-3">
        <SimpleTable
          data={members}
          columns={columns}
          contextMenu={r => (
            <>
              <div
                className="ctxt-menu-item text-danger"
                onClick={() => removeItem(r.id)}
              >
                <i className="uil uil-times-circle"></i>
                <span>{localize('remove')}</span>
              </div>
            </>
          )}
        />
      </div>
      <div className="mt-3">
        <button className="btn btn-success px-4" onClick={updateMembers}>
          {localize('update')}
        </button>
      </div>
    </div>
  )
}

export default Partnership
