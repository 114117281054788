/** @format */
/* eslint-disable */
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import localize from '../../../../../Global/Localize'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { MaskNumber } from '../../../../../Components/InputMasks/MaskNumber'
import SavingsProductLookup from '../../../../../Components/Lookup/SavingsProductLookup'
import InvestmentProductLookup from '../../../../../Components/Lookup/InvestmentProductLookup'
import ChartOfAccountsLookup from '../../../../../Components/Lookup/ChartOfAccountsLookup'
import SimpleTable from '../../../../../Components/Tables/SimpleTable'
import {
  formatAsPercent,
  formatCurrency,
  stripNonNumericCharacters,
} from '../../../../../Helpers/formatters'
import { GlobalValidationErrors } from '../../../../../Validation/ValidationState'
import { generateGuid } from '../../../../../Helpers/extensions'
import Checkbox from '../../../../../Components/Checkbox/Checkbox'
import { getData } from '../../../../../Helpers/webApi'

function VoluntaryDeductibles({ onChangeDeductibles, loanCaseId }) {
  const [isBusy, setIsBusy] = useState(false)

  const [gve, setGve] = GlobalValidationErrors.use()

  const initialDeductible = {
    type: 1, // savings product
    typeDescription: 'Savings Product',
    description: '',
    targetProductId: '',
    targetProductDescription: '',
    chargeType: 1, // percentage
    chargeTypeDescription: 'percentage',
    chargePercentage: 0,
    chargeFixedAmount: 0,
  }
  const [deductible, setDeductible] = useState(initialDeductible)
  const [selectedDeductibles, setSelectedDeductibles] = useState([])
  const [deductibles, setDeductibles] = useState([])

  const columns = [
    {
      label: localize('select'),
      template: r => (
        <>
          <Checkbox
            checked={selectedDeductibles.find(x => x === r.id)}
            onCheck={() => {
              const selectedIndex = selectedDeductibles.findIndex(
                x => x === r.id
              )
              if (selectedIndex < 0) {
                setSelectedDeductibles([...selectedDeductibles, r.id])
              } else {
                setSelectedDeductibles(
                  [...selectedDeductibles].filter(s => s !== r.id)
                )
              }
            }}
          />
        </>
      ),
    },
    { label: localize('name'), template: r => <>{r?.description}</> },
    { label: localize('type'), template: r => <>{r?.typeDescription}</> },
    {
      label: localize('productName'),
      template: r => <>{r?.targetProductDescription}</>,
    },
    {
      label: localize('chargeType'),
      template: r => <>{r?.chargeTypeDescription}</>,
    },
    {
      label: localize('percentage'),
      template: r => <>{formatAsPercent(r?.chargePercentage)}</>,
    },
    {
      label: localize('fixedAmount'),
      template: r => <>{formatCurrency(r?.chargeFixedAmount)}</>,
    },
  ]

  function addDeductible() {
    if (!deductible?.description) {
      setGve({
        ...gve,
        'volDeductions.description': { message: 'Description is required' },
      })
    } else if (!deductible?.targetProductId) {
      setGve({
        ...gve,
        'volDeductions.targetProduct': {
          message: 'Target Product is required',
        },
      })
    } else if (deductible?.chargeType == 1 && !deductible.chargePercentage) {
      setGve({
        ...gve,
        'volDeductions.charge': { message: 'Charge Percentage is required' },
      })
    } else if (deductible?.chargeType != 1 && !deductible.chargeFixedAmount) {
      setGve({
        ...gve,
        'volDeductions.charge': { message: 'Charge Fixed Amount is required' },
      })
    }

    if (Object.keys(gve).find(x => x.startsWith('volDeductions'))) {
    } else {
      setDeductibles([...deductibles, { id: generateGuid(), ...deductible }])
      setDeductible(initialDeductible)
    }
  }

  function removeValidation(key) {
    let oldVal = { ...gve }
    delete oldVal[key]
    setGve({ ...oldVal })
  }

  function removeSelectedDeductibles() {
    let distinct = []
    for (let d of deductibles) {
      if (!selectedDeductibles.find(s => s == d.id)) {
        distinct.push(d)
      }
    }
    setSelectedDeductibles([])
    setDeductibles(distinct)
  }

  useEffect(() => {
    onChangeDeductibles && onChangeDeductibles(deductibles)
    // eslint-disable-next-line
  }, [deductibles])

  useEffect(() => {
    loadDeductibles()
  }, [loanCaseId])

  async function loadDeductibles() {
    setIsBusy(true)
    const { success, data } = await getData(
      '/lookup/loanCase/find-loan-case-voluntary-deductibles-by-loan-case-id',
      { loanCaseId },
      false
    )
    if (success) {
      setDeductible(data?.result)
    }
    setIsBusy(false)
  }

  return (
    <div className="p-2">
      <div className="row">
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={gve['volDeductions.description']?.message}
        >
          <label className="col-1 text-capitalize">{localize('name')}</label>
          <input
            value={deductible?.description}
            onChange={e => {
              setDeductible({ ...deductible, description: e.target.value })
              removeValidation('volDeductions.description')
            }}
            type="text"
            className="form-control"
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">{localize('type')}</label>
          <div style={{ width: '100%' }}>
            <EnumsServerCombo
              enumsKey={'LoanDeductibleProduct'}
              value={deductible?.type}
              onChange={({ label, value }) =>
                setDeductible({
                  ...deductible,
                  type: value,
                  typeDescription: label,
                })
              }
            />
          </div>
        </div>
        <div
          className="col d-flex align-items-center with-validation"
          validation-message={gve['volDeductions.targetProduct']?.message}
        >
          <label htmlFor="" className="col-2 text-capitalize">
            {localize(
              deductible?.type == 1
                ? 'savingsProduct'
                : deductible?.type == 3
                ? 'investmentProduct'
                : deductible?.type == 4
                ? 'glAccount'
                : 'unknown'
            )}
          </label>
          {deductible?.type == 1 ? (
            <SavingsProductLookup
              displayValue={
                deductible?.type == 1
                  ? deductible?.targetProductDescription
                  : ''
              }
              onSelect={t => {
                setDeductible({
                  ...deductible,
                  type: 1,
                  targetProductId: t.id,
                  targetProductDescription: t.description,
                })
                removeValidation('volDeductions.targetProduct')
              }}
            />
          ) : deductible?.type == 3 ? (
            <InvestmentProductLookup
              displayValue={
                deductible?.type == 3
                  ? deductible?.targetProductDescription
                  : ''
              }
              onSelect={t => {
                setDeductible({
                  ...deductible,
                  type: 3,
                  targetProductId: t.id,
                  targetProductDescription: t.description,
                })
                removeValidation('volDeductions.targetProduct')
              }}
            />
          ) : deductible?.type == 4 ? (
            <ChartOfAccountsLookup
              displayValue={
                deductible?.type == 4
                  ? deductible?.targetProductDescription
                  : ''
              }
              onSelect={t => {
                setDeductible({
                  ...deductible,
                  type: 4,
                  targetProductId: t.id,
                  targetProductDescription: t.name,
                })
                removeValidation('volDeductions.targetProduct')
              }}
            />
          ) : (
            'unknown'
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col d-flex align-items-center">
          <label className="col-2 text-capitalize">
            {localize('chargeType')}
          </label>
          <div style={{ width: '100%' }}>
            <EnumsServerCombo
              enumsKey={'ChargeType'}
              value={deductible?.chargeType}
              onChange={({ value, label }) =>
                setDeductible({
                  ...deductible,
                  chargeType: value,
                  chargeTypeDescription: label,
                })
              }
            />
          </div>
        </div>
        <div
          className="col d-flex align-items-center"
          style={{ width: '100%' }}
        >
          <label htmlFor="" className="col-2 text-capitalize">
            {localize('charge')}
          </label>
          <div
            className="d-flex align-items-center gap-1 with-validation"
            validation-message={gve['volDeductions.charge']?.message}
            style={{ width: '100%' }}
          >
            {deductible.chargeType == 2 && (
              <div style={{ width: '100%' }}>
                <MaskNumber
                  defaultMaskValue={
                    deductible?.chargeType == 1
                      ? deductible?.chargePercentage
                      : deductible?.chargeFixedAmount
                  }
                  onMaskNumber={v => {
                    let amount =
                      deductible?.chargeType == 1
                        ? {
                            chargePercentage: stripNonNumericCharacters(
                              v.target.value
                            ),
                            chargeFixedAmount: 0,
                          }
                        : {
                            chargeFixedAmount: stripNonNumericCharacters(
                              v.target.value
                            ),
                            chargePercentage: 0,
                          }

                    setDeductible({
                      ...deductible,
                      ...amount,
                    })
                    removeValidation('volDeductions.charge')
                  }}
                />
              </div>
            )}
            {deductible?.chargeType == 1 && (
              <>
                <input
                  value={deductible?.chargePercentage}
                  onChange={e =>
                    setDeductible({
                      ...deductible,
                      chargeFixedAmount: 0,
                      chargePercentage: Number(e.target.value),
                    })
                  }
                  style={{ textAlign: 'right' }}
                  type="number"
                  className="form-control"
                />
                <input
                  type="text"
                  className="form-control"
                  style={{ maxWidth: 40 }}
                  disabled
                  value="%"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="p-2 my-3 rounded bg-light d-flex align-items-center justify-content-end">
        <button onClick={addDeductible} className="btn btn-success px-5">
          {localize('add')}
        </button>
      </div>
      <div className="row mt-3">
        <h5 className="text-capitalize">{localize('voluntaryDeductibles')}</h5>
        <div className="px-2 pt-2">
          <SimpleTable data={deductibles} columns={columns} />
          {!!selectedDeductibles.length && (
            <div className="bg-light p-1 d-flex align-items-center justify-content-end">
              <button
                onClick={removeSelectedDeductibles}
                className="btn btn-danger px-5"
              >
                {localize('remove')} ({selectedDeductibles.length})
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VoluntaryDeductibles
