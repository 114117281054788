/** @format */

import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../../../Components/Checkbox/Checkbox'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import { CustomerPartial } from '../../../../../../Components/ReUsables/Customer/CustomerPartial'
import Tab from '../../../../../../Components/Tabs/Tab'
import localize from '../../../../../../Global/Localize'
import { parseDate } from '../../../../../../Helpers/formatters'
import BadgeSpecimen from '../AddPersonnel/BadgeSpecimen/BadgeSpecimen'
import PersonnelDisciplinaryCases from './PersonnelDisciplinaryCases/PersonnelDisciplinaryCases'

const ViewPersonnel = ({ handleClose, mode, personnelData }) => {
  const [personnel, setPersonnel] = useState({})
  const [customer, setCustomer] = useState({})
  const [vehicle, setVehicle] = useState({})

  useEffect(() => {
    if (personnelData?.id) {
      setCustomer({
        id: personnelData?.customerId,
        customerId: personnelData?.customerId,
        customerFullName: personnelData?.customerFullName,
      })
      setVehicle({
        id: personnelData?.vehicleId,
        vehicleId: personnelData?.vehicleId,
        registrationNumber: personnelData?.vehicleRegistrationNumber,
        fleetNumber: personnelData?.vehicleFleetNumber,
        documentReferenceNumber: personnelData?.vehicleDocumentReferenceNumber,
        remarks: personnelData?.vehicleRemarks,
        VehicleRegistrationNumber: personnelData?.vehicleRegistrationNumber,
      })
      setPersonnel({
        badgeNumber: personnelData?.badgeNumber,
        badgeExpiryDate: parseDate(personnelData?.badgeExpiryDate),
        type: personnelData?.type,
        badgeImageId: personnelData?.badgeImageId,
        isLocked: personnelData?.isLocked,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, personnelData?.id])

  return (
    <ModalTemplate
      hideUpdate
      modalMode={mode}
      modalClassName="modal-xl"
      modalTitle="viewPersonnel"
      handleClose={handleClose}
      footerChildren={
        <>
          <div className="d-flex align-items-center">
            <Checkbox
              id={'isLockedVehiclePersonnel'}
              checked={personnel?.isLocked}
              onCheck={v => setPersonnel(p => ({ ...p, isLocked: v }))}
              disabled
            />
            <label
              className="ms-2 text-capitalize"
              htmlFor={'isLockedVehiclePersonnel'}
            >
              {localize('isLocked') + ' ?'}
            </label>
          </div>
        </>
      }
    >
      <>
        <CustomerPartial customer={customer} mode={'edit'} refreshFromServer />
        <div className="row gy-2">
          <div className="col-md-3 d-flex align-items-center with-validation">
            <label className="col-4 text-capitalize">
              {localize('vehicle')}
            </label>
            <input
              disabled
              value={vehicle?.registrationNumber}
              className="form-control"
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('fleetNumber')}
            </label>
            <input
              className="form-control ms-2"
              value={vehicle?.fleetNumber}
              disabled
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('documentReferenceNumber')}
            </label>
            <input
              className="form-control ms-2"
              value={vehicle?.documentReferenceNumber}
              disabled
            />
          </div>
          <div className="col-md-3  d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('remarks')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              value={vehicle?.remarks}
              disabled
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
            <label className="col-4 text-capitalize">{localize('type')}</label>
            <EnumsServerCombo
              enumsKey="VehiclePersonnelType"
              value={personnel?.type}
              sort
              customContainerStyle={{ width: '100%' }}
              onChange={e => {
                setPersonnel(p => ({ ...p, type: e?.value }))
              }}
              disabled
            />
          </div>
          <div className="col-md-3  d-flex align-items-center with-validation">
            <label className="col-4 text-capitalize">
              {localize('badgeNumber')}
            </label>
            <input
              type="text"
              className="form-control ms-2"
              value={personnel?.badgeNumber}
              disabled
            />
          </div>
          <div className="col-md-3  d-flex align-items-center">
            <label className="col-4 text-capitalize">
              {localize('badgeExpiryDate')}
            </label>
            <input
              className="form-control ms-2"
              value={personnel?.badgeExpiryDate}
              disabled
            />
          </div>
        </div>
      </>

      <div className="mt-4">
        <Tab
          tabItems={[
            {
              label: localize('badge'),
              item: (
                <BadgeSpecimen
                  readOnly
                  refetchFromServer
                  vehiclePersonnel={{
                    ...personnel,
                    id: personnelData?.id,
                  }}
                  setVehiclePersonnel={setPersonnel}
                />
              ),
            },
            {
              label: localize('disciplinaryCases'),
              item: (
                <PersonnelDisciplinaryCases vehiclePersonnel={personnelData} />
              ),
            },
          ]}
        />
      </div>
    </ModalTemplate>
  )
}

export default ViewPersonnel
