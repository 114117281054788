/** @format */
import localize from '../../../../Global/Localize'
import { MaskNumber } from '../../../../Components/InputMasks/MaskNumber'
import ChartOfAccountsLookup from '../../../../Components/Lookup/ChartOfAccountsLookup'
import BranchLookup from '../../../../Components/Lookup/BranchLookup'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import swal from 'sweetalert2'
import Loader from '../../../../Components/Loaders/Loader'
import Swal from 'sweetalert2'

export const AddTreasuryModal = ({
  selectedTreasury,
  setSelectedTreasury,
  isBusy,
}) => {
  const [validationBag, setValidationBag] = GlobalValidationErrors.use()

  const clearValidation = column => {
    setValidationBag(g => {
      delete g[column]
      return g
    })
  }

  const handleOnSelectChartOfAccount = r => {
    if (r?.isLocked) {
      Swal.fire({
        icon: 'error',
        title: 'Operation not allowed!',
        text: 'Item Is Locked',
      })
      return
    }
    clearValidation('ChartOfAccountId')
    setSelectedTreasury({
      ...selectedTreasury,
      chartOfAccountId: r.id,
      chartOfAccountAccountName: r.name,
    })
  }

  return (
    <>
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.Description?.message}
            >
              <label
                style={{ width: 12.3 + '%' }}
                className="col-1 text-capitalize"
              >
                {localize('name')}
              </label>
              <input
                className="form-control"
                value={selectedTreasury?.description}
                onChange={e => {
                  clearValidation('Description')
                  setSelectedTreasury({
                    ...selectedTreasury,
                    description: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.RangeLowerLimit?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('lowerLimit')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedTreasury?.rangeLowerLimit}
                onMaskNumber={e => {
                  clearValidation('RangeLowerLimit')
                  setSelectedTreasury({
                    ...selectedTreasury,
                    rangeLowerLimit: e.target.value,
                  })
                }}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.RangeUpperLimit?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('upperLimit')}
              </label>
              <MaskNumber
                defaultMaskValue={selectedTreasury?.rangeUpperLimit}
                onMaskNumber={e => {
                  clearValidation('RangeUpperLimit')
                  setSelectedTreasury({
                    ...selectedTreasury,
                    rangeUpperLimit: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.ChartOfAccountId?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('glAccount')}
              </label>
              <ChartOfAccountsLookup
                displayValue={selectedTreasury?.chartOfAccountAccountName}
                onSelect={handleOnSelectChartOfAccount}
              />
            </div>
            <div
              className="col d-flex align-items-center with-validation"
              validation-message={validationBag?.BranchId?.message}
            >
              <label className="col-3 text-capitalize">
                {localize('branch')}
              </label>
              <BranchLookup
                displayValue={selectedTreasury?.branchDescription}
                onSelect={r => {
                  clearValidation('BranchId')
                  setSelectedTreasury({
                    ...selectedTreasury,
                    branchId: r.id,
                    branchDescription: r.description,
                  })
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
