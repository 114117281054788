/** @format */

import ModalTemplate from '../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../Global/Localize'
import { AddChargesModal } from './AddChargesModal'
import { useEffect, useState } from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import { postData } from '../../../../Helpers/webApi'
import { roundingType } from '../../../../Helpers/constants'
import { GlobalValidationErrors } from '../../../../Validation/ValidationState'
import swal from 'sweetalert2'
import { EditChargesModal } from './EditChargesModal'
import { ViewChargesModal } from './ViewChargesModal'

function ChargesModal({
  mode,
  onHideModal,
  setSelectedCharge,
  selectedCharge,
  waitingMetadata,
  onUpdateRefreshAt,
}) {
  const [isBusy, setIsBusy] = useState(false)

  async function handleSubmit() {
    if (mode === 'add') {
      addCharges()
    }
    if (mode === 'edit') {
      editCharges()
    }
  }

  useEffect(() => {
    GlobalValidationErrors.reset()
  }, [mode])

  const addCharges = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/Commission/add-commissions-with-splits-scales-and-levies',
      {
        commission: { ...(selectedCharge || {}) },
        commissionSplits: selectedCharge?.commissionSplits,
        graduatedScales: selectedCharge?.graduatedScales,
        levies: selectedCharge?.levies.filter(x => x.checked),
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedCharge({
        roundingType: roundingType.NO_ROUNDING,
        commissionSplits: [],
        graduatedScales: [],
        levies: [],
      })
      swal.fire('Add Charge', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  const editCharges = async () => {
    setIsBusy(true)
    const { success } = await postData(
      '/Commission/update-commissions-with-splits-scales-and-levies',
      {
        Commission: { ...(selectedCharge || {}) },
        CommissionSplits: selectedCharge?.commissionSplits || [],
        GraduatedScales: selectedCharge?.graduatedScales || [],
        Levies: selectedCharge?.levies.filter(x => x.checked) || [],
      },
      false,
    )
    if (success) {
      onHideModal()
      onUpdateRefreshAt()
      setSelectedCharge({
        roundingType: roundingType.NO_ROUNDING,
        commissionSplits: [],
        graduatedScales: [],
        levies: [],
      })
      swal.fire('Edit Charge', 'Operation Completed Successfully', 'success')
    }
    setIsBusy(false)
  }

  return (
    <>
      <>
        {mode && (
          <ModalTemplate
            modalMode={mode}
            disableCreate={isBusy}
            modalTitle={localize('charge')}
            handleAction={handleSubmit}
            handleClose={onHideModal}
            modalClassName={'modal-xl'}
            footerChildren={
              mode !== 'view' && (
                <>
                  <div className="d-flex align-content-end justify-content-end">
                    <div className="d-flex align-items-center me-5">
                      <Checkbox
                        id={'isLockedSelectedCharge'}
                        checked={selectedCharge?.isLocked}
                        onCheck={v => {
                          setSelectedCharge({ ...selectedCharge, isLocked: v })
                        }}
                      />
                      <label
                        htmlFor="isLockedSelectedCharge"
                        className="text-capitalize ms-2"
                      >
                        {localize('isLocked')} ?
                      </label>
                    </div>
                    <div className="d-flex align-items-center me-5">
                      <Checkbox
                        id={'permitOverDrawingSelect'}
                        checked={selectedCharge?.permitOverdrawing}
                        onCheck={v => {
                          setSelectedCharge({
                            ...selectedCharge,
                            permitOverdrawing: v,
                          })
                        }}
                      />
                      <label
                        htmlFor="permitOverDrawingSelect"
                        className="text-capitalize ms-2"
                      >
                        {localize('permitOverdrawing')}?
                      </label>
                    </div>
                  </div>
                </>
              )
            }
          >
            {mode === 'add' && (
              <AddChargesModal
                mode={mode}
                waitingMetadata={waitingMetadata}
                isBusy={isBusy}
                setSelectedCharge={setSelectedCharge}
                selectedCharge={selectedCharge}
              />
            )}
            {mode === 'edit' && (
              <EditChargesModal
                mode={mode}
                waitingMetadata={waitingMetadata}
                isBusy={isBusy}
                setSelectedCharge={setSelectedCharge}
                selectedCharge={selectedCharge}
              />
            )}
            {mode === 'view' && (
              <ViewChargesModal
                selectedCharge={selectedCharge}
                isBusy={isBusy}
                waitingMetadata={waitingMetadata}
              />
            )}
          </ModalTemplate>
        )}
      </>
    </>
  )
}

export default ChargesModal
