/** @format */

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import localize from '../../Global/Localize'
import { getData } from '../../Helpers/webApi'
import SimpleTable from '../Tables/SimpleTable'
import Pager from '../Tables/Pager'

function ConditionalSimpleLookup({
  url,
  params,
  columns,
  displayValue,
  onSelect,
  title,
  useText,
  conditionalField,
  isList,
}) {
  const [reqParams, setReqParams] = useState(params)
  const [isBusy, setIsBusy] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [sortedBy, setSortedBy] = useState('')
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    if (modalActive) loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, modalActive])

  async function loadData() {
    setIsBusy(true)
    const { success, data } = await getData(
      url,
      {
        ...reqParams,
        [conditionalField]: params[conditionalField],
      },
      false
    )
    if (success) {
      setIsBusy(false)
      setData(data)
      {
        isList ? setTableData(data) : setTableData(data.pageCollection)
      }
    } else {
      setIsBusy(false)
    }
  }

  function handleSort(column, columnIndex) {
    if (!sortedBy) {
      let sorted = tableData.sort((a, b) => a[column] > b[column])
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
        setSortedBy([column, 1, columnIndex])
      }, 10)
    } else {
      let sorted = []
      if (sortedBy[1] === 0) {
        sorted = tableData.sort((a, b) => a[column] > b[column])
        setSortedBy([column, 1, columnIndex])
      } else {
        sorted = tableData.sort((a, b) => a[column] < b[column])
        setSortedBy([column, 0, columnIndex])
      }
      setTableData([])
      setTimeout(() => {
        setTableData(sorted)
      }, 10)
    }
    // setTableData(sorted)
  }

  return (
    <>
      <div
        className="form-control d-flex align-items-center"
        style={{
          padding: 0,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => setModalActive(true)}
      >
        <div
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
          htmlFor=""
          className="ms-2"
        >
          {displayValue}
        </div>
        <button
          className="btn btn-primary"
          style={{ margin: 0, transform: 'scale(0.9)' }}
          onClick={() => setModalActive(true)}
        >
          <i className="uil uil-search"></i>
        </button>
      </div>
      {modalActive && (
        <Modal isOpen className="modal-xl">
          <ModalHeader className="bg-white text-capitalize">
            {title}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalActive(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          {isBusy ? (
            <ModalBody className="bg-white">{localize('pleaseWait')}</ModalBody>
          ) : (
            <ModalBody className="bg-white p-2">
              <div className="d-flex align-items-center justify-content-space-between ">
                <div className="d-flex align-items-center float-right my-2 col-4">
                  <label className="text-capitalize" htmlFor="">
                    {localize('recordsPerPage')}
                  </label>
                  <select
                    className="form-select ms-3"
                    style={{ width: 'fit-content' }}
                    defaultValue={reqParams.pageSize}
                    onChange={e => {
                      setReqParams({
                        ...reqParams,
                        pageIndex: 0,
                        pageSize: e.target.value,
                      })
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div style={{ flex: 1 }}></div>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setReqParams({
                      ...reqParams,
                      [useText ? 'text' : 'filterText']: search,
                    })
                  }}
                >
                  <div className="d-flex align-items-center float-right my-2">
                    <label className="text-capitalize" htmlFor="searchTable">
                      {localize('search')}
                    </label>
                    <input
                      defaultValue={
                        useText ? reqParams.text : reqParams.filterText
                      }
                      onChange={e => setSearch(e.target.value)}
                      type="search"
                      className="form-control ms-3"
                      id="searchTable"
                    />
                  </div>
                </form>
              </div>
              <div className=" py-1">
                <SimpleTable
                  columns={columns}
                  data={tableData || []}
                  onSelect={r => {
                    setModalActive(false)
                    onSelect(r)
                  }}
                  canSelect
                  onSort={(c, i) => handleSort(c, i)}
                  sortedBy={sortedBy}
                />
              </div>
              <Pager
                itemsCount={data.itemsCount}
                pageSize={reqParams.pageSize}
                pageIndex={reqParams.pageIndex}
                pageBatchSize={5}
                onPageChange={p => setReqParams({ ...reqParams, pageIndex: p })}
              />
            </ModalBody>
          )}
          <ModalFooter className="bg-white p-0">
            <button
              className="btn btn-danger  text-capitalize"
              onClick={() => setModalActive(false)}
            >
              {localize('cancel')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

export default ConditionalSimpleLookup
