/** @format */

import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
/* eslint-disable */
import React from 'react'
import { formatDate } from '../../Helpers/formatters'

export const CreditTypeLookup = ({ onSelect, displayValue }) => {
  return (
    <>
      <SimpleLookup
        title={localize('creditTypeLookup')}
        url="/lookup/CreditType/find-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={[
          {
            label: localize('description'),
            template: r => <>{r.description}</>,
          },
          {
            label: localize('chartOfAccountName'),
            template: r => <>{r.chartOfAccountName}</>,
          },
          {
            label: localize('costDistributionRule'),
            template: r => (
              <>{r.chartOfAccountCostDistributionRuleDescription}</>
            ),
          },
          {
            label: localize('currency'),
            template: r => <>{r.chartOfAccountCurrencyDescription}</>,
          },
          {
            label: localize('revenueTax'),
            template: r => <>{r.revenueTaxDescription}</>,
          },
          {
            label: localize('taxRate'),
            template: r => <>{r.revenueTaxTaxRate}</>,
          },
          {
            label: localize('payrollNumberType'),
            template: r => <>{r.payrollNumberTypeDescription}</>,
          },
          {
            label: localize('transactionOwnership'),
            template: r => <>{r.transactionOwnershipDescription}</>,
          },
          {
            label: localize('batchType'),
            template: r => <>{r.batchTypeDescription}</>,
          },
          {
            label: localize('batchFileType'),
            template: r => <>{r.batchFileTypeDescription}</>,
          },
          {
            label: localize('unallocatedFundsProductName'),
            template: r => (
              <>
                {r.unallocatedFundsCustomerAccountTypeTargetProductDescription}
              </>
            ),
          },
          {
            label: localize('roundingType'),
            template: r => <>{r.roundingTypeDescription}</>,
          },
          {
            label: localize('suppressAccountAlerts'),
            template: r => <>{r.suppressAccountAlerts}</>,
          },
          {
            label: localize('suppressMinimumBalanceValidation'),
            template: r => <>{r.suppressMinimumBalanceValidation}</>,
          },
          {
            label: localize('suppressGracePeriodValidation'),
            template: r => <>{r.suppressGracePeriodValidation}</>,
          },
          {
            label: localize('recoverIndefiniteCharges'),
            template: r => <>{r.recoverIndefiniteCharges}</>,
          },
          {
            label: localize('recoverSundryArrearages'),
            template: r => <>{r.recoverSundryArrearages}</>,
          },
          {
            label: localize('recoverCarryFowards'),
            template: r => <>{r.recoverCarryForwards}</>,
          },
          {
            label: localize('validateInterestCapitalization'),
            template: r => (
              <>{r.validateInterestCapitalizationForRecoverableLoans}</>
            ),
          },
          {
            label: localize('executeAdHocStandingOrders'),
            template: r => <>{r.executeAdhocStandingOrders}</>,
          },
          {
            label: localize('throttleScoring'),
            template: r => <>{r.throttleScoring}</>,
          },
          {
            label: localize('enableHybridLoanRecovery'),
            template: r => <>{r.enableHybridLoanRecovery}</>,
          },
          {
            label: localize('suppressMultiPayoutRecovery'),
            template: r => <>{r.suppressMultiPayoutRecovery}</>,
          },
          {
            label: localize('validateIndefiniteCharges'),
            template: r => <>{r.validateIndefiniteCharges}</>,
          },
          {
            label: localize('apportionUnallocatedFunds'),
            template: r => <>{r.apportionUnallocatedFunds}</>,
          },
          {
            label: localize('taxInclusive'),
            template: r => <>{r.taxInclusive}</>,
          },
          { label: localize('isLocked'), template: r => <>{r.isLocked}</> },
          {
            label: localize('createdDate'),
            template: r => <>{formatDate(r.createdDate)}</>,
          },
        ]}
        displayValue={displayValue}
        onSelect={onSelect}
      />
    </>
  )
}
