/** @format */

import React, { useState } from 'react'
import EnumsServerCombo from '../../../../../../Components/ComboBox/EnumsServerCombo'
import UnPayReasonLookup from '../../../../../../Components/Lookup/UnPayReasonLookup'
import ModalTemplate from '../../../../../../Components/ModalTemplate/ModalTemplate'
import localize from '../../../../../../Global/Localize'
import { showNotification } from '../../../../../../Helpers/Notification/ShowNotification'
import {
  formatCurrency,
  formatDate,
} from '../../../../../../Helpers/formatters'
import { postData } from '../../../../../../Helpers/webApi'
import Tab from '../../../../../../Components/Tabs/Tab'
import Loader from '../../../../../../Components/Loaders/Loader'
import ChequePayables from '../Partials/ChequePayables/ChequePayables'

const ClearExternalCheque = ({ handleClose, mode, cheque, fetchCheques }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [clearingOption, setClearingOption] = useState(1)
  const [unpayReason, setUnpayReason] = useState(null)
  const [selectedCheques, setSelectedCheques] = useState([])
  const [reload, setReload] = useState(false)

  const handleSelectCheques = cheques => {
    setSelectedCheques(cheques)
  }

  const clearAll = () => {
    showNotification('Clear External Cheque', 'success')
    setReload(!reload)
    handleClose()
    setClearingOption(1)
    setUnpayReason(null)
    setSelectedCheques([])
    fetchCheques()
  }

  const handleClearCheque = async () => {
    if (clearingOption === 2?.toString() && !unpayReason?.id)
      return showNotification(
        'Operation not allowed',
        'error',
        'Please select unpay reason'
      )

    setIsBusy(true)

    if (selectedCheques.length > 0) {
      const url = `/ExternalCheque/update-external-cheque-payables-by-external-cheque-id?externalChequeId=${cheque?.id}`

      setIsBusy(true)

      const { success } = await postData(
        url,

        selectedCheques.map(c => ({
          externalChequeId: cheque?.id,
          ...c,
        })),
        false
      )

      if (success) {
        const url = '/ExternalCheque/clear-external-cheque'
        await postData(
          url,
          {
            externalCheque: cheque,
            clearingOption,
            moduleNavigationItemCode: 1000,
            unPayReason: unpayReason,
          },
          false
        )

        clearAll()
      }
    } else {
      const url = '/ExternalCheque/clear-external-cheque'
      const { success } = await postData(
        url,
        {
          externalCheque: cheque,
          clearingOption,
          moduleNavigationItemCode: 1000,
          unPayReason: unpayReason,
        },
        false
      )

      if (success) {
        clearAll()
      }
    }

    setIsBusy(false)
  }

  const handleSelectUnpayReason = unpayReason => {
    if (unpayReason?.isLocked)
      return showNotification(
        'Operation not allowed',
        'error',
        'Unpay reason is locked'
      )

    setUnpayReason(unpayReason)
  }

  return (
    <ModalTemplate
      modalMode={mode}
      modalTitle="clearExternalCheque"
      handleClose={handleClose}
      modalClassName="modal-xl"
      handleAction={handleClearCheque}
      disableCreate={isBusy}
      footerChildren={
        <div className="d-flex justify-content-between gap-3 align-items-center me-5">
          {clearingOption === 2?.toString() && (
            <div className="d-flex align-items-center w-100">
              <label className="text-capitalize me-2">
                {localize('unpayReason')}
              </label>
              <UnPayReasonLookup
                displayValue={unpayReason?.description}
                onSelect={handleSelectUnpayReason}
              />
            </div>
          )}

          <div className="d-flex align-items-center">
            <label className="text-capitalize me-2">{localize('option')}</label>

            <EnumsServerCombo
              disabled={isBusy}
              customContainerStyle={{
                minWidth: '120px',
                maxWidth: 'fit-content',
              }}
              enumsKey="ExternalChequeClearanceOption"
              value={clearingOption}
              onChange={({ value }) => {
                setClearingOption(value)
              }}
              sort
            />
          </div>
        </div>
      }
    >
      {isBusy ? (
        <Loader />
      ) : (
        <>
          <div className="row g-2">
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('drawer')}
              </label>
              <input disabled value={cheque?.drawer} className="form-control" />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('drawerBank')}
              </label>
              <input
                disabled
                value={cheque?.drawerBank}
                className="form-control"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('drawerBranch')}
              </label>
              <input
                disabled
                value={cheque?.drawerBankBranch}
                className="form-control"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('chequeType')}
              </label>
              <input
                disabled
                value={cheque?.chequeTypeDescription}
                className="form-control"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('writeDate')}
              </label>
              <input
                disabled
                value={formatDate(cheque?.writeDate)}
                className="form-control"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('maturityDate')}
              </label>
              <input
                disabled
                value={formatDate(cheque?.maturityDate)}
                className="form-control"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('chequeNumber')}
              </label>
              <input disabled value={cheque?.number} className="form-control" />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('amount')}
              </label>
              <input
                disabled
                value={formatCurrency(cheque?.amount, null, null, false)}
                className="form-control text-end"
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="text-capitalize col-3">
                {localize('remarks')}
              </label>
              <input
                disabled
                value={cheque?.remarks}
                className="form-control"
              />
            </div>

            <div className="col-md-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('customer')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={cheque?.customerAccountCustomerFullName}
              />
            </div>

            <div className="col-md-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('identification#')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  cheque?.customerAccountCustomerPersonalIdentificationNumber
                }
              />
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('memberNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={cheque?.customerAccountCustomerReference1}
              />
            </div>

            <div className="col-md-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('accountNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={cheque?.customerAccountCustomerReference2}
              />
            </div>
            <div className="col-md-4 offset-md-4 d-flex align-items-center">
              <label className="col-3 text-capitalize">
                {localize('personalFileNumber')}
              </label>
              <input
                type="text"
                className="form-control"
                disabled
                value={cheque?.customerAccountCustomerReference3}
              />
            </div>
          </div>

          <div className="mt-4">
            <Tab
              tabItems={[
                {
                  label: localize('chequePayables'),
                  item: (
                    <div
                      style={{
                        maxHeight: '50dvh',
                        overflow: 'auto',
                      }}
                    >
                      <ChequePayables
                        customerId={cheque?.customerAccountCustomerId}
                        handleSelectPayables={handleSelectCheques}
                        showSelect
                        reload={reload}
                        setReload={setReload}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </ModalTemplate>
  )
}

export default ClearExternalCheque
