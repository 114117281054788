/** @format */
import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../Helpers/webApi'
import BreadCrumb from '../../../../../Components/BreadCrumb/Index'
import localize from '../../../../../Global/Localize'
import Loader from '../../../../../Components/Loaders/Loader'
import { AlternateChannelReconciliationPeriodLookup } from '../../../../../Components/Lookup/AlternateChannelReconciliationLookup'
import { formatDate } from '../../../../../Helpers/formatters'
import EnumsServerCombo from '../../../../../Components/ComboBox/EnumsServerCombo'
import { BatchImportsEntries } from './Partials/BatchImportEntries'

function ChannelReconciliationClosing() {
  const [isBusy, setIsBusy] = useState(false)
  const [reqParams, setReqParams] = useState({
    alternateChannelReconciliationEntryFilter: '21',
    filterText: '',
    pageIndex: 0,
    pageSize: 10,
  })
  const [selectedAlternateChannelPeriod, setSelectedAlternateChannelPeriod] =
    useState(null)
  const [mismatchedEntries, setMismatchedEntries] = useState([])

  const handleOnSelectAlternateChannelPeriod = r => {
    setSelectedAlternateChannelPeriod({ ...r })
  }

  const fetchDiscrepancies = async () => {
    setIsBusy(true)
    const { success, data } = await getData(
      '/AlternateChannelReconciliationPeriod/find-alternate-channel-reconciliation-entries-by-alternate-channel-reconciliation-period-id-and-filter-in-page',
      {
        ...reqParams,
        alternateChannelReconciliationPeriodId:
          selectedAlternateChannelPeriod?.id,
      },
      false,
    )
    if (success) {
      setMismatchedEntries(data)
    }
    setIsBusy(false)
  }

  useEffect(() => {
    if (selectedAlternateChannelPeriod?.id) {
      fetchDiscrepancies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqParams, selectedAlternateChannelPeriod?.id])

  return (
    <>
      <BreadCrumb
        tree={[
          localize('alternateChannelManagement'),
          localize('channelReconciliation'),
          localize('closing'),
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card bg-white">
            <div className="card-body">
              {isBusy ? (
                <Loader />
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('batchNumber')}
                      </label>
                      <AlternateChannelReconciliationPeriodLookup
                        displayValue={
                          selectedAlternateChannelPeriod?.paddedBatchNumber
                        }
                        onSelect={handleOnSelectAlternateChannelPeriod}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('startDate')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={formatDate(
                          selectedAlternateChannelPeriod?.durationStartDate,
                          true,
                        )}
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('endDate')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={formatDate(
                          selectedAlternateChannelPeriod?.durationEndDate,
                          true,
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('alternateChannelType')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedAlternateChannelPeriod?.alternateChannelTypeDescription
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('transactionBackingStore')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedAlternateChannelPeriod?.transactionBackingStoreDescription
                        }
                      />
                    </div>
                    <div className="col d-flex align-items-center">
                      <label className="col-3 text-capitalize">
                        {localize('discreteSetDifferenceMode')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          selectedAlternateChannelPeriod?.setDifferenceModeDescription
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col d-flex align-items-center">
                      <label className="col-1 text-capitalize">
                        {localize('remarks')}
                      </label>
                      <input
                        className="form-control"
                        disabled
                        value={selectedAlternateChannelPeriod?.remarks}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="p-2 mt-4"
                        style={{
                          border: 'solid 1px #0000001f',
                          borderRadius: 10,
                          position: 'relative',
                        }}
                      >
                        <div
                          className="bg-white px-2 row text-capitalize"
                          style={{
                            position: 'relative',
                            top: -20,
                            left: 30,
                            maxWidth: 120,
                          }}
                        >
                          {localize('batchImports')}
                        </div>
                        <BatchImportsEntries
                          entries={mismatchedEntries}
                          setReqParams={setReqParams}
                          reqParams={reqParams}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-3">
                    <div
                      className="bg-light d-flex justify-content-end align-items-center p-2"
                      style={{ borderRadius: 5 }}
                    >
                      <div className="col d-flex align-items-center">
                        <label className="col-3 text-capitalize">
                          {localize('remarks')}
                        </label>
                        <input className="form-control" />
                      </div>
                      <div className="col d-flex align-items-center">
                        <EnumsServerCombo
                          className="col"
                          customContainerStyle={{ width: '100%' }}
                          enumsKey="AlternateChannelReconciliationPeriodAuthOption"
                          value={selectedAlternateChannelPeriod?.authOption}
                          onChange={e => {
                            setSelectedAlternateChannelPeriod({
                              ...selectedAlternateChannelPeriod,
                              authOption: e.value,
                            })
                          }}
                          sort
                        />
                      </div>
                      <button className="btn btn-success text-uppercase ms-3 px-4 ">
                        {localize('update')}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ChannelReconciliationClosing
