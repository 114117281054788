/** @format */

/* eslint-disable */
import React from 'react'
import SimpleLookup from './SimpleLookup'
import localize from '../../Global/Localize'
import { formatDate } from '../../Helpers/formatters'

function CostCentreLookup({ displayValue, onSelect }) {
  const columns = [
    { label: localize('name'), template: r => <>{r.description}</> },
    {
      label: localize('isLocked'),
      template: r => (
        <span className="text-capitalize">{r.isLocked.toString()}</span>
      ),
    },
    {
      label: localize('createdDate'),
      template: r => <>{formatDate(r.createdDate)}</>,
    },
  ]

  return (
    <>
      <SimpleLookup
        url="/lookup/CostCenter/find-by-filter-in-page"
        params={{ filterText: '', pageSize: 10, pageIndex: 0 }}
        columns={columns}
        displayValue={displayValue}
        onSelect={onSelect}
        title={`${localize('lookUp')} > ${localize('costCentre')}`}
      />
    </>
  )
}

export default CostCentreLookup
